import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from "react";
import { TableCell, TableObject } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, check, deSelectCheckbox, getChecked, isEmptyArray, mergeObject, selectCheckbox } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/accesspermission.css';

let requestBody: any[] = []
let dataObj: any = {}

export const CreateOrEditFAQCategoryComponent = withView((props: ViewComponentProps) => {

    const categoryID = props.getParam("categoryId");
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [newCategoryName, setNewCategoryName] = useState<string>('');
    const [originalCategoryName, setOriginalCategoryName] = useState<string>('');
    const [tableDisplay, setTableDisplay] = useState<any | null>(null);
    const [table, setTable] = useState<any | null>(null);
    const [accountOptions, setAccountOptions] = useState<Array<any>>([{ value: '0', name: 'All' }]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [companyOptions, setCompanyOptions] = useState<any | null>(null);
    const [groupOptions, setGroupOptions] = useState<any | null>([{ value: '0', name: 'All' }]);
    const [groupSearchTerm, setGroupSearchTerm] = useState<string>('')
    const [filterState, setFilterState] = useState<string>("0-0-0");
    const [displayedGroups, setDisplayedGroups] = useState<Array<number>>([]);
    useEffect(() => {
        if (companyOptions === null) {
            ajax({
                url: '/api/cms/faq/category/companies',
                success: (res: any) => {
                    setCompanyOptions(res);
                    loadDefaultData();
                }
            });
        }
    }, [companyOptions]);

    function renderCheckbox(id: any, originalCheckedStatus: string, companyCode: string, groupNumber: string, accountNumber: string) {
        let content = '<input type="checkbox" ' + ' data-companyCode="' + companyCode + '" data-groupNumber="' + groupNumber + '" data-accountNumber="' + accountNumber + '" id="' + id + '" ' + (originalCheckedStatus ? 'checked' : '') + '/>';
        return content
    }

    //Load default data
    function loadDefaultData() {
        if (categoryID !== "") {
            ajax({
                url: `/api/cms/faq/category/fetchDataForCategory/${categoryID}`,
                success: (res: any) => {
                    setTable([...res.relatedGroups]);
                    setTableDisplay([...res.relatedGroups]);
                    setNewCategoryName(res.categoryName);
                    setOriginalCategoryName(res.categoryName);
                    setIsEditing(true);
                }
            });
        }
        else {
            ajax({
                url: `/api/cms/faq/category/fetchData`,
                success: (res: any) => {
                    setTable([...res]);
                    setTableDisplay([...res]);
                }
            });
        }
    }

    function reloadDefaultData(formProps: any) {
        dataObj = {};
        setGroupOptions([{ "name": "All", "value": "0" }]);
        setAccountOptions([{ "name": "All", "value": "0" }]);
        setDisplayedGroups(tableObj.getOrder());
        setGroupSearchTerm('');
        setFilterState("0-0-0");
        tableObj.filter(1, "", true, false);
        tableObj.filter(3, "", true, false);
        tableObj.filter(5, "", true, false);
        if (isEditing)
            loadDefaultData();
        else {
            setTableDisplay([...table]);
            setTable([...table]);
        }
        setIsSubmitting(false);
        props.resetForm(formProps);
    }

    const isValidGroupNumber = (groupNumber: string) => {
        return groupOptions.filter((option: any) => { return option.value === groupNumber }).length > 0;
    }

    //loading account
    const groupChangeHandler = (e: any, companyCode: string, setFieldValue: any) => {
        let groupNumber = e.target !== undefined ? e.target.value : e;
        if (groupNumber === '0' || groupNumber === '') {
            resetAccountOptions(setFieldValue);
        } else {
            if (isValidGroupNumber(groupNumber)) {
                ajax({
                    url: '/api/cms/faq/category/accountsbycompanyandgroup',
                    params: {
                        "company": companyCode,
                        "group": groupNumber
                    },
                    success: (res: any) => {
                        setAccountOptions(res);
                        setFieldValue("accountNumber", "0");
                    }
                });
            }
        }
    }

    const handleGroupSelect = (option: any, companyCode: any, setFieldValue: any) => {
        let groupNumber = option.value !== undefined ? option.value : option;
        if (groupNumber === '0' || groupNumber === '') {
            resetAccountOptions(setFieldValue);
        } else {
            if (isValidGroupNumber(groupNumber)) {
                ajax({
                    url: '/api/cms/faq/category/accountsbycompanyandgroup',
                    params: {
                        "company": companyCode,
                        "group": groupNumber
                    },
                    success: (res: any) => {
                        setAccountOptions(res);
                        setFieldValue("accountNumber", "0");
                    }
                });
            }
        }
    }

    //loading group
    const companyChangeHandler = (e: any, setFieldValue: any) => {
        const company = e.target !== undefined ? e.target.value : e;
        if (company === '0') {
            resetGroupOptions(setFieldValue);
            resetAccountOptions(setFieldValue);
        } else {
            ajax({
                url: '/api/cms/faq/category/groupsbycompany',
                params: {
                    "company": company
                },
                success: (res: any) => {
                    setGroupOptions(res);
                    setGroupSearchTerm('')
                    if (res.length === 0) {
                        setFieldValue("groupNumber", "0");
                        setAccountOptions([]);
                        setFieldValue("accountNumber", "0");
                    }
                    else
                        resetAccountOptions(setFieldValue);
                }
            });
        }
    }

    const resetGroupOptions = (setFieldValue: any) => {
        setGroupOptions([{ "name": "All", "value": "0" }]);
        setFieldValue("groupNumber", "0");
        setGroupSearchTerm('')
    }

    const resetAccountOptions = (setFieldValue: any) => {
        setAccountOptions([{ "name": "All", "value": "0" }]);
        setFieldValue("accountNumber", "0");
    }

    function selectAll() {
        checkOrUncheckAll(true);
    }

    function deselectAll() {
        checkOrUncheckAll(false);
    }

    function checkOrUncheckAll(state: boolean) {
        let objKey: any = "";
        const tempTable = [...tableDisplay];
        displayedGroups.forEach(rowIndex => {
            tempTable[rowIndex] = {...tableDisplay[rowIndex], access: state};
            objKey = tempTable[rowIndex].companyCode + "_" + tempTable[rowIndex].groupNumber + "_" + tempTable[rowIndex].accountNumber;
            dataObj[objKey] = { access: state };
        });
        setTableDisplay(tempTable);
    }

    function saveCategory(formProps: any) {
        setIsSubmitting(true);
        const categoryName = isEditing ? newCategoryName : formProps.values.categoryName;
        requestBody = [];
        if (categoryName.length < 1) {
            props.showMessage("error", props.getGlobalizedText("common.msg.category.name.required"));
            setIsSubmitting(false)
        } else {
            if (table != null) {
                for (let tempTa of table) {
                    let tempKey: any = tempTa.companyCode + "_" + tempTa.groupNumber + "_" + tempTa.accountNumber;
                    let tempObj = dataObj[tempKey]
                    if (tempObj === null || tempObj === undefined) {
                        continue
                    } else {
                        if (dataObj[tempKey].access !== tempTa.access) {
                            let temp = {
                                companyCode: tempTa.companyCode,
                                groupNumber: tempTa.groupNumber,
                                accountNumber: tempTa.accountNumber,
                                access: dataObj[tempKey].access,
                            }
                            requestBody.push(temp);
                        }
                    }
                }
            }
            if(isEmptyArray(requestBody) && categoryName === originalCategoryName) {
                setIsSubmitting(false);
                return;
            }
            if (isEditing) {
                ajax({
                    url: `/api/cms/faq/category/update`,
                    data: {
                        name: categoryName,
                        id: categoryID,
                        selectedGroups: requestBody,
                    },
                    method: 'put',
                    success: () => {
                        props.showMessage("success", props.getGlobalizedText("common.lbl.faqCategorySaved"));
                        reloadDefaultData(formProps);
                    }, error: () => {
                        props.showMessage("error", "The previous updates were unsuccessfully. The default setting is reloaded.");
                        reloadDefaultData(formProps);
                    }
                });
            }
            else {
                ajax({
                    url: `/api/cms/faq/category/create`,
                    data: {
                        name: categoryName,
                        selectedGroups: requestBody,
                    },
                    method: 'post',
                    success: () => {
                        props.showMessage("success", props.getGlobalizedText("common.lbl.faqCategorySaved"));
                        reloadDefaultData(formProps);
                    }, error: () => {
                        props.showMessage("error", "The previous updates were unsuccessfully. The default setting is reloaded.");
                        reloadDefaultData(formProps);
                    }
                })
            }
        }
    }

    const tableObj = useMemo(() => props.useTable({
        id: "groupsTable",
        option: { scrollCollapse: true, destroy: true, },
        table: {
            columns: [
                { className: "gwp-dt-detail-control", sortable: false },
                { title: 'common.lbl.companyCode', name: "companyCode" },
                { title: 'changeow.lbl.companyName', name: "companyName" },
                { title: 'common.lbl.groupNumber', name: "groupNumber" },
                { title: 'pd.lbl.groupname', name: "groupName", width: "240px" },
                { title: 'payment.lbl.accountNumber', name: 'accountNumber' },
                { title: 'common.lbl.accountName', name: 'accountName' },
                {
                    title: 'common.lbl.select', name: 'access', sortable: false, render: (data, type, rowData) => {
                        let accessid = ["access", rowData.companyCode, rowData.groupNumber, rowData.accountNumber].join("_");
                        return renderCheckbox(accessid, rowData.access, rowData.companyCode, rowData.groupNumber, rowData.accountNumber);
                    }, onChange: handleCheckbox
                }
            ],
            data: tableDisplay
        },
        onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element | null) => {
            if (childNode === null) {
                return;
            }
            let access = childNode.querySelector("input[id^=access]");
            if (access !== null) {
                check(access, getChecked(document.getElementById(access.id)));
                access.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
        },
        onRendered: () => {
            const [companyCode, groupNumber, accountNumber] = filterState.split("-");
            applyFilter(tableObj, companyCode, groupNumber, accountNumber, setDisplayedGroups);
        }
    }), [tableDisplay, props]);

    if (companyOptions === null) {
        return <></>
    }
    return <>
        <Formik initialValues={{
            categoryName: '',
            companyCode: '0',
            groupNumber: '0',
            accountNumber: '0'
        }}
            onSubmit={() => {
            }}>
            {formProps =>
                <Form>
                    <props.Row>
                        <props.Col sm="4" md="4" xs="12">
                            {isEditing ?
                                <props.TextControl maxLength={500} name="newCategoryName" id="newCategoryName" label="common.lbl.categoryName" required={true} value={newCategoryName} onChange={(e: any) => setNewCategoryName(e.target.value)} />
                                : <props.TextControl maxLength={500} name="categoryName" id="categoryName" label="common.lbl.categoryName" required={true} />
                            }
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col sm="4" md="4" xs="12">
                            <props.H5><GlobalizedText message="common.lbl.companyGroupAccount" /></props.H5>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col sm="6" lg="3">
                            <props.SelectControl sort={false} onChange={(e: any) => companyChangeHandler(e, formProps.setFieldValue)} id="companyCode" name="companyCode" label="memlist.company" options={companyOptions}></props.SelectControl>
                        </props.Col>
                        <props.Col sm="6" lg="3">
                            <props.ComboboxControl
                                onChange={(val) => {
                                    setGroupSearchTerm(val)
                                }}
                                onValueChange={(value: string) => {
                                    const options = groupOptions.filter((option: any) => option.name?.toLowerCase().indexOf(value.toLowerCase()) > -1)
                                    return options
                                }}
                                onSelect={(option) => handleGroupSelect(option, formProps.values.companyCode, formProps.setFieldValue)}
                                textField="name"
                                valueField='value'
                                hideCaret={false}
                                required
                                id="groupNumber"
                                name="groupNumber"
                                label="common.lbl.group"
                                data={groupOptions}
                                defaultValue={groupSearchTerm}
                            ></props.ComboboxControl>
                        </props.Col>
                        <props.Col sm="6" lg="3">
                            <props.SelectControl sort={false} name="accountNumber" label="common.lbl.account" options={accountOptions} />
                        </props.Col>
                        <props.Col sm="12">
                            <props.Button id="retrieve" onClick={(e: any) => {
                                const [compCode, grpNumber, accNo] = [formProps.values.companyCode, formProps.values.groupNumber, formProps.values.accountNumber];
                                applyFilter(tableObj, compCode, grpNumber, accNo, setDisplayedGroups);
                                setFilterState(`${compCode}-${grpNumber}-${accNo}`);
                            }}><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                    </props.Row>
                    <tableObj.Component />
                    <props.Row>
                        <props.Col sm="12" className="form-inline">
                            <props.Button id="selectAll" onClick={() => selectAll()}><GlobalizedText message="common.lbl.selectAll" /></props.Button>
                            <props.Button id="deselectAll" onClick={() => deselectAll()}><GlobalizedText message="common.lbl.deselectAll" /></props.Button>
                            <props.Button id="save" type="submit" disabled={isSubmitting} onClick={() => { saveCategory(formProps); }}><GlobalizedText message="common.lbl.save" /></props.Button>
                            <props.Button id="close" onClick={props.back} > <GlobalizedText message="common.lbl.close" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
    </>
});

const checkCheckboxes = (els: Array<Element>, checked: boolean) => {
    els.forEach((el) => {
        check(el, checked);
    });
}

//Update requestBody
const updateDataObj = (companyCode: string, groupNumber: string, accountNumber: number, accessEle: Array<Element>) => {
    let objKey: any = companyCode + "_" + groupNumber + "_" + accountNumber;
    let accessStatus: boolean = getChecked(accessEle[0]);
    dataObj[objKey] = { access: accessStatus ? true : false };
}

const handleCheckbox = (event: any, row: any) => {
    let accessChecked = event.currentTarget.checked;
    let accessid = ["access", row.companyCode, row.groupNumber, row.accountNumber].join("_");
    let accessEle: any = document.querySelectorAll(`#${accessid}`);
    if (event.currentTarget.getAttribute("id").startsWith("access"))
        checkCheckboxes(accessEle, accessChecked);
    updateDataObj(row.companyCode, row.groupNumber, row.accountNumber, accessEle);
}

function applyFilter(tableObj: TableObject, companyCode: string, groupNumber: string, accountNumber: string, setDisplayedGroups: React.Dispatch<React.SetStateAction<number[]>>) {
    tableObj.filter(1, companyCode === "0" ? "" : `^${companyCode}$`, true, false);
    tableObj.filter(3, groupNumber === "0" ? "" : `^${groupNumber}$`, true, false);
    tableObj.filter(5, accountNumber === "0" ? "" : `^${accountNumber}$`, true, false);
    setDisplayedGroups([...tableObj.getOrder()]);
}
