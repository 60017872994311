import React, { HTMLAttributes } from 'react';
import { Div, Span } from '..';
import GlobalizedText from '../../globalization';

export interface InformationProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * Message that would be displayed in the information, this attribute supports labels and messages.
     */
    message: string;

    /**
     * Identify whether the exclamation should be displayed or not, default is true;
     */
    showExclamationSign?: boolean;

    /**
     * Specify the font weight of the message
     */
    fontWeight?: 'bold' | 'normal';
}

export function Information({ className, ...props }: InformationProps) {
    return <Div style={props.style} className={`information gwp-information ${className ? className : ''}`}>
        <Span condition={props.showExclamationSign !== false} className="glyphicon glyphicon-exclamation-sign" aria-hidden="true" style={{ marginRight: '4px' }}></Span>
        <GlobalizedText className={props.fontWeight === 'bold' ? "gwp-bold" : ""} message={props.message} /></Div>
}