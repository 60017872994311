import { Form, Formik, setIn } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react"
import { number } from "yup";
import { TableCell, useTable } from '../shared/components';
import GlobalizedText from "../shared/globalization";
import { ajax, isEmptyStr, validate, check, deSelectCheckbox, getChecked, selectCheckbox } from "../shared/utils";
import { ViewComponentProps, withView } from "../shared/viewcomponent"
import { INVALID_MESSAGES } from "../shared/yupschema";

interface RequestBodyDataType {
    component: string,
    insuranceType: string,
    planCode: string,
    hide: boolean,
    pageID: string
}

export const PagesAndFieldsConfigComponent = withView(function (props: ViewComponentProps) {
    const [config, setConfig] = useState<any | null>(null);
    // const [insurances, setInsurances] = useState<any | null>(null);
    const [table, setTable] = useState<any | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const selectedRows = useRef<Array<any>>([]);
    const unSelectedRows = useRef<Array<any>>([]);
    const pageID = useRef<Array<any>>([]);
    const insurances = useRef<Array<any>>([]);
    const componentID = useRef<Array<any>>([]);
    const planCode = useRef<Array<any>>([]);
    const requestBody = useRef<Array<RequestBodyDataType>>([]);
    const dataObj = useRef<Array<RequestBodyDataType>>([])
    const noChangesCount = useRef<number>(0)
    let varFormPromp: any;

    function dataSchema(formValue: any) {
        let errorFlag: boolean = false;
        let validateResult: any = {};
        if (formValue.insuranceType === "-1" || isEmptyStr(formValue.insuranceType)) {
            validateResult.insuranceType = INVALID_MESSAGES.PAGE_FIELDS_INSURANCE_EMPTY
            errorFlag = true
        }
        if (formValue.planCode === "-1" || isEmptyStr(formValue.planCode)) {
            validateResult.planCode = INVALID_MESSAGES.PAGE_FIELDS_PRODUCT_EMPTY
            errorFlag = true
        }
        return validateResult;
    }
    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/pagesAndFieldsConfig/view',
                success: (res: any) => {
                    setConfig(res);
                    pageID.current = res.pages
                    componentID.current = res.values
                    insurances.current = res.insurances
                    planCode.current = res.productNames
                    // setInsurances(res.insurances)
                }
            });
        }
    }, [config]);
    //fetchDefaultSettings
    function fetchDefaultSettings(pageID: string, component: string, insuranceType: string, planCode: string) {
        ajax({
            url: '/api/pagesAndFieldsConfig/fetchSettings',
            params: {
                "pageID": pageID,
                "component": component,
                "insuranceType": insuranceType,
                "planCode": planCode
            },
            success: (res: any) => {
                dataObj.current = []
                setTable(res)
                res.forEach((row: any, index: number) => {
                    let temp1 = {
                        component: row.component,
                        insuranceType: row.insuranceType,
                        planCode: row.planCode,
                        hide: row.hide,
                        pageID: row.pageID
                    }
                    dataObj.current.push(temp1)
                });
            }
        })
    }
    //loading component
    const pageIDChangeHandler = (e: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let pageID = e.target.value;
        ajax({
            url: '/api/pagesAndFieldsConfig/getComponents',
            params: { "pageID": pageID },
            success: (res) => {
                componentID.current = res
                setFieldValue("componentID", res[0].value);
            }
        });
    }
    const insuranceTypeChangeHandler = (e: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let insuranceType = e.target.value;
        if (insuranceType === "-1") {
            planCode.current = config.productNames
            setFieldValue("planCode", "-1");
        } else {
            ajax({
                url: '/api/pagesAndFieldsConfig/getProductNames',
                params: { "insuranceType": insuranceType },
                success: (res) => {
                    planCode.current = res
                    setFieldValue("planCode", res[0].value)
                }
            });
        }
    }
    //Retrieve Data.
    const formSubmit = (values: any): void => {
        fetchDefaultSettings(values.pageID, values.componentID, values.insuranceType, values.planCode)
    }
    let tempArr: Array<any> = []
    if (table !== null) {
        table.forEach((row: any, index: number) => {
            if (row.hide === true) {
                tempArr.push(index)
            }
        });
    }
    const tableObj = useTable({
        id: "hiddenFieldTable",
        select: {
            type: "multiple",
            prefix: "hiddenFields",
            highlight: false,
            selected: tempArr,
            showSelectedInformation: false,
            onSelect: (e: any, selected: Array<number>, unSelected: Array<number>) => {
                noChangesCount.current = 1
                selectedRows.current = selected
                unSelectedRows.current = unSelected
                if (selectedRows.current.length === 0) {
                    // No rows selected
                    dataObj.current.forEach(row => row.hide = false)
                } else if (unSelectedRows.current.length === 0) {
                    // All rows selected 
                    dataObj.current.forEach(row => row.hide = true)
                } else {
                    // Some rows selected and some rows unselected
                    selectedRows.current.forEach((row: number) => {
                        dataObj.current[row].hide = true
                    })
                    unSelectedRows.current.forEach((row: number) => {
                        dataObj.current[row].hide = false
                    })
                }

            }
        },
        option: { paging: true, scrollCollapse: true },
        children: [
            <thead key={0}>
                <tr>
                    <th data-sortable={false} className="gwp-dt-detail-control"><div className='hidden'>detail control</div></th>
                    <th data-type="checkbox" className="gwp-dt-select-all" style={{ minWidth: "50px", maxWidth: "50px" }} ><input className="gwp-dt-select-all-checkbox" title="select all" type="checkbox" />{" "}<GlobalizedText message="hidefields.lbl.hide" /></th>
                    <th><GlobalizedText message="common.lbl.page" /></th>
                    <th><GlobalizedText message="hidefields.lbl.component" /></th>
                    <th><GlobalizedText message="hidefields.lbl.insurancetype" /></th>
                    <th><GlobalizedText message="pd.lbl.productname" /></th>
                </tr>
            </thead>,
            <tbody key={1}>
                {table !== null && table.map((row: any, index: number) => {
                    return <tr key={index}>
                        <td className="gwp-dt-detail-control"></td>
                        <td><input type="checkbox" data-checked={row.hide} id={"hiddenFields_" + row.pageID + row.component + row.insuranceType + row.planCode} /></td>
                        <td>{row.pageName}</td>
                        <td>{row.componentDesc}</td>
                        <td>{row.insuranceTypeDesc}</td>
                        <td>{row.planCodeDesc}</td>
                    </tr>
                })}
            </tbody>
        ]
    });
    //Save settings
    function saveSetting(formsValues: any) {
        setIsSubmitting(true)
        requestBody.current = [];
        if (noChangesCount.current === 0) {
            props.showMessage("error", props.getGlobalizedText("hidefields.msg.nochanges"));
            setIsSubmitting(false)
        } else {
            if (table !== null) {
                dataObj.current.forEach((row: any, index: number) => {
                    if (row.hide !== table[index].hide) {
                        requestBody.current.push(row)
                    }
                })
            }
            if (requestBody.current.length === 0) {
                props.showMessage("error", props.getGlobalizedText("hidefields.msg.nochanges"));
                setIsSubmitting(false)
            } else {
                //Call service
                ajax({
                    url: '/api/pagesAndFieldsConfig/saveSettings',
                    data: requestBody.current,
                    method: 'post',
                    success: () => {
                        props.showMessage("success", props.getGlobalizedText("hidefields.msg.savesuccess"));
                        setIsSubmitting(false)
                        noChangesCount.current = 0
                        //fetch default setting:
                        fetchDefaultSettings(formsValues.pageID, formsValues.componentID, formsValues.insuranceType, formsValues.planCode)
                    }, error: () => {
                        setIsSubmitting(false)
                        noChangesCount.current = 0
                        props.showMessage("error", "The previous updates were unsuccessfully.");
                        //fetch default setting:
                        fetchDefaultSettings(formsValues.pageID, formsValues.componentID, formsValues.insuranceType, formsValues.planCode)
                    }
                })

            }
        }
    }
    if (config === null) {
        return <></>
    }
    return <>
        <Formik initialValues={{
            pageID: config.pages[0].value,
            componentID: config.values[0].value,
            insuranceType: config.insurances[0].value,
            planCode: config.productNames[0].value
        }}
            enableReinitialize={true}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                return validate(dataSchema, values, props)
            }}
            onSubmit={formSubmit}
        >
            {formProps => {
                varFormPromp = formProps;
                return <Form>
                    <props.Row>
                        <props.Col sm="6" xs="12" md="6">
                            <props.SelectControl name="pageID" label="common.lbl.page" options={pageID.current} onChange={(e: any) => { pageIDChangeHandler(e, formProps.setFieldValue) }}></props.SelectControl>
                        </props.Col>
                        <props.Col sm="6" xs="12" md="6">
                            <props.SelectControl name="componentID" sort={false} label="hidefields.lbl.component" options={componentID.current.length === 0 ? [{ "name": "All", "value": "0" }] : componentID.current}></props.SelectControl>
                        </props.Col>
                    </props.Row>
                    <props.HR />
                    <props.Row>
                        <props.Col xs="12" md="3" sm="3">
                            <props.Label><GlobalizedText message="common.lbl.filterResultsBy" /></props.Label>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col xs="12" md="6" sm="6">
                            <props.SelectControl name="insuranceType" sort={false} label="hidefields.lbl.insurancetype" options={insurances.current} onChange={(e: any) => { insuranceTypeChangeHandler(e, formProps.setFieldValue) }} required={true}></props.SelectControl>
                        </props.Col>
                        <props.Col sm="6" xs="12" md="6">
                            <props.SelectControl name="planCode" sort={false} required={true} label="pd.lbl.productname" options={planCode.current.length === 0 ? [{ "name": "Please select", "value": "-1" }] : planCode.current}></props.SelectControl>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col xs="12" md="6" sm="6">
                            <props.Button type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }}
        </Formik>
        <tableObj.Component />
        <props.Row>
            <props.Col sm="12" className="form-inline">
                <props.Button id="save" condition={table !== null} disabled={isSubmitting} onClick={() => saveSetting(varFormPromp.values)}><GlobalizedText message="common.lbl.save" /></props.Button>
                <props.Button id="close" onClick={props.back} > <GlobalizedText message="common.lbl.close" /></props.Button>
            </props.Col>
        </props.Row>

    </>
});