import React, { useEffect, useMemo, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/portalcategoriesapprove.css';
const STATUS_PENDING_APPROVE = 1;
export interface PortalCategory {
    id: string,
    name: string,
    fullPath: string,
    userTypes: string[],
    userTypeNames: string[],
    lastChangeDate: string,
    contentID: string,
    statusName: string,
    status: number,
    parentID: string
}

export const PortalCategoriesApprovementComponent = withView((props: ViewComponentProps) => {

    const [view, setView] = useState<PortalCategory[]>();
    const [selected, setSelected] = useState<PortalCategory>();


    const load = () => {
        ajax({
            url: '/api/cms/portalcategory/list',
            success: (res: any) => {
                setView(res);

            }
        });
    }

    useEffect(() => {
        if (view === undefined) {
            load();
        }
    }, [view])

    const table = useMemo(() => {
        if (view !== undefined) {
            return <props.Table dateformat={props.getDateFormat()} id="portalCategoriesTable"
                select={
                    {
                        type: 'single',
                        disabled: (row: any) => { return false },
                        onSelect: (e: any, selectedRows: Array<number>, unSelectedRows: Array<number>) => {
                            let selectedRow = view[selectedRows[0]];
                            setSelected(selectedRow);
                        }

                    }
                } table={
                    {
                        columns: [
                            {
                                name: 'fullPath', title: 'portalCate.lbl.cateNameAndHierarchy', render: (data: any, type: any, row: any, meta: any) => {
                                    return row.fullPath.map((p: any, i: number) => { return `<span ${i === row.fullPath.length - 1 && i !== 0 ? 'class="current"' : ''}>${p}</span>` }).join(" / ");
                                }
                            },
                            {
                                name: 'userTypeNames', title: 'labelMessage.accessPermission', render: (data: any, type: any, row: any, meta: any) => {
                                    return row.userTypeNames !== undefined ? row.userTypeNames.map((key: string) => { return props.getGlobalizedText(key) }).join(" / ") : '';
                                }
                            },
                            { name: 'lastChangeDate', title: 'common.lbl.updateDate' },
                            {
                                name: 'contentID', title: 'portalCate.lbl.webPageAdded', render: (data: any, type: any, row: any, meta: any) => {
                                    return row.contentID ? `${props.getGlobalizedText('common.lbl.added')}` : `${props.getGlobalizedText('common.lbl.none')}`
                                }
                            },
                            {
                                name: 'statusName', title: 'common.lbl.status', render: (data: any, type: any, row: any, meta: any) => {
                                    return props.getGlobalizedText(row.statusName);
                                }
                            }
                        ],
                        data: view
                    }
                }></props.Table>
        } else {
            return <props.Div></props.Div>
        }
    }, [view]);

    if (view === undefined) {
        return <></>
    }

    return <>
        <props.Div>
            <props.Button disabled={selected?.contentID === undefined} onClick={() => {
                window.open(`${process.env.PUBLIC_URL}/content?id=${selected?.contentID}&type=preview`, '', 'height=600, width=960, top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no');
            }}><GlobalizedText message="common.lbl.preview" /></props.Button>
            <props.Button onClick={() => {
                ajax({
                    url: '/api/cms/portalcategory/approve',
                    method: 'post',
                    params: {
                        id: selected?.id,
                        reason: ''
                    }, success: () => {
                        props.showMessage("success", props.getGlobalizedText("category.approved.success").replace("[contentname]", selected ? selected?.name : ''));
                        load();
                    }, error: () => {
                        props.showMessage("error", props.getGlobalizedText("category.approved.error").replace("[contentname]", selected ? selected?.name : ''));
                    }
                });
            }} disabled={selected === undefined || selected.status !== STATUS_PENDING_APPROVE}><GlobalizedText message="Approve.for.Publication" /></props.Button>
            <props.Button onClick={() => {
                ajax({
                    url: '/api/cms/portalcategory/reject',
                    method: 'post',
                    params: {
                        id: selected?.id,
                        reason: ''
                    }, success: () => {
                        props.showMessage("success", props.getGlobalizedText("category.reject.success").replace("[contentname]", selected ? selected?.name : ''));
                        load();
                    }, error: () => {
                        props.showMessage("error", props.getGlobalizedText("category.reject.error").replace("[contentname]", selected ? selected?.name : ''));
                    }
                });
            }} disabled={selected === undefined || selected.status !== STATUS_PENDING_APPROVE}><GlobalizedText message="Portal.lbl.reject" /></props.Button>
        </props.Div>

        {table}
    </>
});