
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import GlobalizedText from '../shared/globalization';
import { useMessage } from '../shared/message';
import { ajax, isEmptyObject, removeFromStorage, saveToStorage, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/portalcontent.css';
import { Form, Formik } from 'formik';
import { closeExtraMenu, TableColumn } from '../shared/components';
import { CONTENT_PREVIEW_ID, CONTENT_PREVIEW_STORAGE_KEY, CONTENT_VIEWER_TYPE_PREVIEW } from './contentviewer';

let selectetedRowData: any = null;
let selectetedListData: any = null;

export const PortalContentComponent = withView((props: ViewComponentProps) => {
    const [config, setConfig] = useState<any>();
    const [disableButton, setDisableButton] = useState<boolean>(true);
    const [hasContent, setHasContent] = useState<boolean>(false);
    const [handlerFlag, setHandlerFlag] = useState<string | null>();
    const refFormPromp = useRef<any>();

    const tableColumns: Array<TableColumn> = [
        { name: 'name', title: 'common.lbl.portalContent', order: { by: "asc" } },
        { name: 'lastChangeDate', title: 'common.lbl.updateDate' },
        { name: 'portalScreen', title: 'common.lbl.portalScreen' },
        { name: 'position', title: 'common.lbl.position' }
    ]

    const [helpTable, setPortalContentTable] = useState<any | null>(null);
    if (helpTable === null) {
        setPortalContentTable({
            columns: tableColumns,
            data: []
        });
    }



    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: `/api/cms/portalcontent/list`,
                success: (res: any) => {
                    setConfig(res);
                    if (res.list.length > 0) {
                        setHasContent(true);
                    }
                    setPortalContentTable({
                        columns: tableColumns,
                        data: res.list.map((row: any) => {
                            return {
                                name: row.content.name,
                                lastChangeDate: row.lastChangeDate,
                                portalScreen: isEmptyObject(row.component) ? undefined : props.getGlobalizedText(row.component.name),
                                position: isEmptyObject(row.position) ? undefined : props.getGlobalizedText(row.position.name),
                                checked: false
                            }
                        })
                    });
                }
            });
        }

    }, [config]);

    if (config === undefined) {
        return <></>
    }


    // define Form Submitting function.
    const formSubmit = (values: any): void => {
        props.clearMessage();
        if (handlerFlag === 'edit') {
            edit(values);
        } else if (handlerFlag === 'add') {
            add(values);
        } else if (handlerFlag === 'delete') {
            remove(values);
        } else {
            search(values);
        }

    }
    function search(values: any) {
        ajax({
            url: '/api/cms/portalcontent/list',
            success: (res) => {
                //disable button
                setConfig(res);
                setDisableButton(true);
                selectetedListData = null;
                setHandlerFlag("search");
                if (res.list.length > 0) {
                    setHasContent(true);
                } else {
                    setHasContent(false);
                }
                setPortalContentTable({
                    columns: tableColumns,
                    data: res.list.map((row: any) => {
                        return {
                            name: row.content.name,
                            lastChangeDate: row.lastChangeDate,
                            portalScreen: isEmptyObject(row.component) ? undefined : props.getGlobalizedText(row.component.name),
                            position: isEmptyObject(row.position) ? undefined : props.getGlobalizedText(row.position.name),
                            checked: false
                        }
                    })
                });
                refFormPromp.current.setFieldValue("subject", '');
                refFormPromp.current.setFieldValue("content", '');
                refFormPromp.current.setFieldValue("subjectEdit", selectetedListData.name);
                refFormPromp.current.setFieldValue("contentEdit", selectetedListData?.content?.content);
            }
        });
    }

    function add(values: any) {
        ajax({
            url: '/api/cms/portalcontent/create',
            method: 'post',
            data: {
                "subject": values.subject,
                "content": values.content
            },
            success: (res) => {
                setHasContent(true);
                closeCreatePortalContent();
                search(values);
            },
            error: (err: any) => {
                setHasContent(true);
                closeCreatePortalContent();
                search(values);
                props.showMessage("error", "common.msg.portalContentExist");
            }
        });

    }
    function edit(values: any) {
        ajax({
            url: '/api/cms/portalcontent/update',
            method: 'put',
            data: {
                "id": selectetedListData?.id,
                "subject": values.subjectEdit,
                "content": values.contentEdit
            },
            success: (res) => {
                closeEditPortalContent();
                search(values);
            }
        });

    }

    function remove(values: any) {
        ajax({
            url: `/api/cms/portalcontent/delete`,
            params: { id: selectetedListData?.id },
            method: 'delete',
            success: () => {
                closeDeletePortalContent();
                search(values);
            }
        });
    }

    function closeCreatePortalContent() {
        props.closeModal("#createPortalContentModal");
        closeExtraMenu();
    }
    function closeEditPortalContent() {
        props.closeModal("#editPortalContentModal");
        closeExtraMenu();
    }
    function closeDeletePortalContent() {
        props.closeModal("#deletePortalContentModal");
    }

    function warnTitle(title: string) {
        return <><props.Icon type="warning" /><GlobalizedText message={title} /></>
    }
    function saveSelectedValue(row: any, list: any) {
        selectetedRowData = row;
        selectetedListData = list;
        if (selectetedRowData != null) {
            refFormPromp.current.setFieldValue("subject", '');
            refFormPromp.current.setFieldValue("content", '');
            refFormPromp.current.setFieldValue("subjectEdit", selectetedRowData?.name);
            refFormPromp.current.setFieldValue("contentEdit", selectetedRowData?.content?.content);
            //enable button
            setDisableButton(false);
        }
    }

    const createPortalContentValidateSchema = yup.object().shape({
        subject: yup.string().trim().required("common.msg.subjectRequired"),
        content: yup.string().trim().required("payment.msg.desc.req")
    });

    const editPortalContentValidateSchema = yup.object().shape({
        subjectEdit: yup.string().trim().required("common.msg.subjectRequired"),
        contentEdit: yup.string().trim().required("payment.msg.desc.req")
    });

    const createPortalContentMessage = useMessage("createPortalContent");
    const editPortalContentMessage = useMessage("editPortalContent");


    return <React.Fragment ><>

        <Formik initialValues={{
            subject: "",
            content: "",
            subjectEdit: "edit",
            contentEdit: "edit",
            helpLoca: "",
            id: "",
            portalScreen: "",
            position: ""
        }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={(values) => {
                if (handlerFlag === 'add' && (isEmptyObject(values.subject) || isEmptyObject(values.content))) {
                    return validate(createPortalContentValidateSchema, values, createPortalContentMessage.validateProps);
                }
                if (handlerFlag === 'edit' && (isEmptyObject(values.subjectEdit) || isEmptyObject(values.contentEdit))) {
                    return validate(editPortalContentValidateSchema, values, editPortalContentMessage.validateProps);
                }
            }}
            onReset={() => {
                createPortalContentMessage.validateProps.clearMessage();
                editPortalContentMessage.validateProps.clearMessage();
            }}
            onSubmit={formSubmit}
        >
            {formProps => {
                refFormPromp.current = formProps;
                return <Form>
                    <props.Div>
                        <props.Button disabled={hasContent} onClick={() => props.showModal("#createPortalContentModal")}><GlobalizedText message="common.lbl.createNewPortalContent" /></props.Button>
                        <props.Button disabled={disableButton} onClick={
                            () => {
                                ajax({
                                    url: `/api/cms/content/get/${selectetedListData?.content?.id}`,
                                    success: (res) => {
                                        saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: res.content }, false);
                                        props.showModal("#editPortalContentModal");
                                    }
                                });
                            }
                        }><GlobalizedText message="common.lbl.edit" /></props.Button>
                        <props.Button disabled={disableButton} onClick={() => props.showModal("#deletePortalContentModal")}><GlobalizedText message="common.lbl.delete" /></props.Button>
                    </props.Div>

                    <props.Modal messageService={createPortalContentMessage.messageService} id="createPortalContentModal" size="large" title="common.lbl.newPortalContent" footer={() => {
                        return <>
                            <props.Button onClick={() => {
                                window.open(`${process.env.PUBLIC_URL}/content?id=${CONTENT_PREVIEW_ID}&type=${CONTENT_VIEWER_TYPE_PREVIEW}`, '', 'height=600, width=960, top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no');
                            }}><GlobalizedText message="common.lbl.preview" /></props.Button>
                            <props.Button type="submit" onClick={() => setHandlerFlag("add")}><GlobalizedText message="common.lbl.saveandclose" /></props.Button>
                            <props.Button data-dismiss="modal" onClick={() => {
                                createPortalContentMessage.validateProps.clearMessage();
                                saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: "" }, false);
                                props.resetForm(formProps, { "subject": '', "content": '' });
                            }}><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }}>
                        <props.TextControl id="subject" name="subject" label="common.lbl.subject" required={true} />
                        <props.ContentEditor onChange={(newContent: string) => {
                            formProps.setFieldValue("content", newContent);
                            saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: newContent }, false);
                        }} {...props} enableMultipleImagesUploader={false} contentId={"help"} contentTypeId={"help"} id="content" name="content" html={formProps.values.content} label="common.lbl.coverageDescription" required={true}></props.ContentEditor>
                        <br /><GlobalizedText message="common.msg.requiredFields" />
                    </props.Modal>

                    <props.Modal id="deletePortalContentModal" size="large" title={() => { return warnTitle("common.lbl.deletePortalContent") }} footer={() => {
                        return <>
                            <props.Button onClick={() => remove(formProps.values)}><GlobalizedText message="common.lbl.ok" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }}>
                        <div className="fontMessage">{props.getGlobalizedText("common.msg.clickToDeleteQuestion").replace("name", selectetedRowData?.name)}</div><br />
                    </props.Modal>

                    <props.Modal messageService={editPortalContentMessage.messageService} id="editPortalContentModal" size="large" title="common.lbl.editPortalContent" footer={() => {
                        return <>
                            <props.Button disabled={selectetedListData?.id === undefined} onClick={() => {
                                window.open(`${process.env.PUBLIC_URL}/content?id=${CONTENT_PREVIEW_ID}&type=${CONTENT_VIEWER_TYPE_PREVIEW}`, '', 'height=600, width=960, top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no');
                            }}><GlobalizedText message="common.lbl.preview" /></props.Button>
                            <props.Button type="submit" onClick={() => {
                                setHandlerFlag("edit");
                                if (formProps.values.contentEdit === undefined) {
                                    formProps.setFieldValue("contentEdit", selectetedListData?.content.content);
                                }
                            }
                            }><GlobalizedText message="common.lbl.saveandclose" /></props.Button>
                            <props.Button
                                onClick={() => {
                                    closeEditPortalContent();
                                    props.resetForm(formProps, { "subjectEdit": selectetedRowData?.name, "contentEdit": selectetedListData?.content.content });
                                    search(formProps.values);
                                    editPortalContentMessage.validateProps.clearMessage();

                                }}><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }}>
                        <props.TextControl id="subjectEdit" name="subjectEdit" label="common.lbl.subject" required={true} />
                        <props.ContentEditor onChange={(newContent: string) => {
                            saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: newContent }, false);
                            formProps.setFieldValue("contentEdit", newContent);
                        }} {...props} enableMultipleImagesUploader={false} contentId={"faq"} contentTypeId={"faq"} id="contentEdit" name="contentEdit" html={selectetedListData?.content.content} label="common.lbl.coverageDescription" required={true}></props.ContentEditor>
                        <br /><GlobalizedText message="common.msg.requiredFields" />
                    </props.Modal>

                </Form>
            }
            }
        </Formik >

        <props.Table id="helpTable" select={{
            type: 'single', onSelect: (e: any, rows: number[]) => {
                saveSelectedValue(helpTable.data[rows[0]], config.list[rows[0]]);
                props.clearMessage();
            }
        }} table={helpTable}></props.Table>


    </>
    </React.Fragment >
});