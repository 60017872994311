import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { closeExtraMenu } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { useMessage } from '../shared/message';
import { addClass, ajax, copyObjectExcept, removeClass, toggle, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import './css/managefaqquestion4category.css';
import { FAQ, loadCategories } from './types/faq';


export const QuestionsForCategoryComponent = withView((props: ViewComponentProps) => {
    const categoryId = props.getParam("category");
    const [questions, setQuestions] = useState<Array<FAQ.Question>>();
    const [selected, setSelected] = useState<FAQ.Question>();
    const [categories, setCategories] = useState<Array<FAQ.Category>>();
    const { messageService, validateProps } = useMessage("questionForCategory");
    useEffect(() => {
        if (questions === undefined) {
            loadQuestionsForCategory();
        }
    }, [questions]);
    useEffect(() => {
        if (categories === undefined) {
            loadCategories(setCategories);
        }
    }, [categories])
    function openDeletQuestion(index: number) {
        if (questions !== undefined) {
            setSelected(questions[index]);
            props.showModal("#deleteQuestion");
        }
    }
    function openEditQuestion(index: number) {
        if (questions !== undefined) {
            setSelected(questions[index]);
            props.showModal("#editQuestion");
        }
    }
    function closeDeleteQuestion() {
        props.closeModal("#deleteQuestion");
    }
    function loadQuestionsForCategory() {
        const params: any = {site: null};
        params["categoryId"] = props.getParam("category");
        ajax({
            url: '/api/cms/faq/question/list',
            params: params,
            success: (res) => {
                props.setTitle(res.categoryName);
                setQuestions(res.questions);
            }
        });
    }
    function deleteQuestion() {
        if (questions !== undefined) {
            ajax({
                url: `/api/cms/faq/question/delete/${selected?.id}`,
                method: 'delete',
                success: () => {
                    let name = selected?.question;
                    setSelected(undefined);
                    loadQuestionsForCategory();
                    props.closeModal("#deleteQuestion");
                    props.showMessage("success", props.getGlobalizedText("common.msg.deleteQuestionSuccess").replace("[name]", name ? name : ''));
                }
            });


        }
    }
    const defaultValue = selected ? { ...selected, categories: selected.categories.map((cate: any) => cate.id) } : { question: '', answer: '', categories: [] }
    const validateSchema = yup.object().shape(
        YupSchema.faq
    );
    return <>
        {questions !== undefined && questions.map((question: FAQ.Question, index: number) => {
            let qid = "#question_" + index;
            function onCollapseShow() {
                removeClass(qid, "question-collapsed");
                addClass(qid, "question-expanded");
            }
            function onCollapseHide() {
                removeClass(qid, "question-expanded");
                addClass(qid, "question-collapsed");
            }
            return <props.Row key={index}>
                <props.Col sm="12" key={index}>
                    <props.Div className="questionContainer" >
                        <props.Div className="questionAnswerContainer">
                            <props.Div id={`question_${index}`} className="question question-collapsed" onClick={(e: any) => {
                                toggle("#editor_" + index, onCollapseShow, onCollapseHide);
                            }}><props.Span className="questionContent">{question.question}</props.Span></props.Div>
                            <props.Div className="btnGroup">
                                <props.BootstrapIcon title="common.lbl.edit" id={`updateQuestionBtn_${index}`} onClick={() => { openEditQuestion(index) }} type="pencil"></props.BootstrapIcon>
                                <props.BootstrapIcon title="common.lbl.delete" id={`deleteQuestionBtn_${index}`} onClick={() => { openDeletQuestion(index) }} type="trash"></props.BootstrapIcon>
                            </props.Div>
                        </props.Div>
                        <props.Row>
                            <props.Col sm="8">
                                <props.Div style={{ marginLeft: '25px' }} className="collapse answer" id={"editor_" + index}>
                                    {question.answer}
                                </props.Div>
                            </props.Col>
                        </props.Row>
                    </props.Div>
                </props.Col>
            </props.Row>
        })
        }
        {(questions === undefined || questions.length === 0) &&
            <GlobalizedText message="common.msg.noQuestion" />
        }
        <props.Modal id="deleteQuestion" title="common.lbl.deletQuestion" footer={
            () => {
                return <>
                    <props.Button id="deleteQuestionOKBtn" onClick={deleteQuestion}><GlobalizedText message="common.lbl.ok" /></props.Button>
                    <props.Button id="deleteQuestionCancelBtn" onClick={closeDeleteQuestion}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                </>
            }
        }>
            <props.Span>{props.getGlobalizedText("common.msg.clickToDeleteQuestion").replace("[name]", `<b>${selected?.question ? selected?.question : ''}</b>`)}</props.Span>
        </props.Modal>

        <Formik initialValues={
            defaultValue
        }
            enableReinitialize={true}
            validateOnBlur={false}
            validateOnChange={false}
            validate={(values) => {
                return validate(validateSchema, values, validateProps);
            }}
            onReset={() => validateProps.clearMessage()}
            onSubmit={(values, formikHelper) => {
                ajax({
                    url: '/api/cms/faq/question/update',
                    data: { id: selected?.id, ...values },
                    method: 'put',
                    success: () => {
                        let name = selected?.question;
                        props.closeModal("#editQuestion");
                        formikHelper.resetForm();
                        loadQuestionsForCategory();
                        closeExtraMenu();
                        props.showMessage("success", props.getGlobalizedText("common.msg.updateQuestionSuccess").replace("[name]", name ? name : ''))
                    }
                });
            }}
        >
            {formProps =>
                <Form>
                    <props.Modal messageService={messageService} size="large" id="editQuestion" title="common.lbl.editQuestion" footer={() => {
                        return <>
                            <props.Button id="editQuestionSubmitBtn" type="submit"><GlobalizedText message="common.lbl.submit" /></props.Button>
                            <props.Button id="editQuestionCloseBtn" onClick={() => { setSelected(undefined); formProps.resetForm(); closeExtraMenu(); }} data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }}>
                        <props.TextControl id="question" required={true} style={{ maxWidth: '100%' }} name="question" label="common.lbl.question"></props.TextControl>
                        <props.Label required={true}><GlobalizedText message="common.lbl.answer" /></props.Label>
                        <props.ContentEditor contentId={"faq"} contentTypeId={"faq"} onChange={(content: string) => {
                            formProps.setFieldValue("answer", content);
                        }} {...copyObjectExcept(props, false, "id")} name="answer" id="answer" html={selected?.answer}></props.ContentEditor>
                        <props.Label><GlobalizedText message="common.lbl.faqCategoryTitle" /></props.Label>
                        <props.Information showExclamationSign={false} message="common.msg.faqCategoryInformation"></props.Information>
                        <ul className="categoreis">
                            {(categories !== undefined && categories.length > 0) && categories.map((category: FAQ.Category, index: number) => {
                                return <li key={index}>
                                    <props.CheckboxControl id={"category_" + category.id} containerClassName="gwp-inline" value={category.id} name="categories"></props.CheckboxControl><props.Label htmlFor={"category_" + category.id}>{category.name}</props.Label>
                                </li>
                            })}
                        </ul>
                    </props.Modal>
                </Form>
            }
        </Formik>

        <props.Button id="backtoBtn" onClick={props.back}><GlobalizedText message="common.lbl.backTo" /> <GlobalizedText message="common.lbl.manaQuestions" /></props.Button>
    </>
});