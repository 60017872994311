export const FeatureFlags = {
    ENROLLMENT_UPDATEPAYROLL: "base.enrollment.PayrollSchedule",
    ENROLLMENT_PREMIUMTRACKING: "base.enrollment.PremiumTracking",
    DISPLAY_ANNUITY_PAYEES: "base.display.annuity.payees",
    BENEFIT_DETAIL_PAYOUTINFORMATIONSECTION: "benefit.detail.PayoutInformationSection",
    BENEFIT_DETAIL_MONEYONDEPOSITFUNDSECTION: "benefit.detail.MoneyOnDepositFundSection",
    PB_RESPONSIBILITYAMOUNT_DEDUCTIONDATE: "premium.billed.ResponsibilityAmountDeductionDate",
    AB_RESPONSIBILITYAMOUNT_DEDUCTIONDATE: "adjustment.billed.ResponsibilityAmountDeductionDate",
    PA_RESPONSIBILITYAMOUNT_DEDUCTIONDATE: "payment.applied.ResponsibilityAmountDeductionDate",
    WALLET: "base.wallet",
    POLICY_NOTES: "base.policy.notes",
    CHANGE_PAYMENT_FREQUENCY: "base.policyDetail.changePaymentFrequency",
}