import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/registernewusers.css';

export const RegisterNewUsersComponent = withView((props: ViewComponentProps) => {

    const [view, setView] = useState<any>();
    useEffect(() => {
        if (view === undefined) {
            ajax({
                url: "/api/registration/view",
                success: res => setView(res)
            });
        }
    }, [view]);


    if (view === undefined) {
        return <></>
    }

    // let register single user be the first option, this is not a final solution.
    view.registrationMethods.sort((first: any, next: any) => {
        return first.value < next.value ? 1 : -1
    });

    return <>
        <Formik initialValues={{ type: 'S', aaa: '1' }}
            onSubmit={(values, { setSubmitting }) => {
                if (values.type === 'M') {
                    props.next("/bulkRegistration");
                } else if (values.type === 'A') {
                    props.next("/adminRegistration");
                } else {
                    props.next("/userRegistration");
                }
            }}
        >
            {formProps =>
                <Form>
                    <props.Panel>
                        <props.PanelBody>
                            <props.Label className="reg-label"><GlobalizedText message="register.msg.method.or.role" /></props.Label>
                            {
                                view.registrationMethods.map((registrationMethod: any, i: number) => {
                                    return <props.RadioControl key={i} id={`type_${registrationMethod.value}`} name="type" value={registrationMethod.value} label={registrationMethod.name}></props.RadioControl>
                                })
                            }
                        </props.PanelBody>
                    </props.Panel>
                    <props.Button type="submit"><GlobalizedText message="common.button.continue" /></props.Button>
                </Form>
            }
        </Formik>
    </>
})