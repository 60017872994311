import { Form, Formik, yupToFormErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, isEmptyStr, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import * as yup from 'yup';
import './css/mocksavecreditcard.css';
import { YupSchema } from '../shared/yupschema';
export const MockSaveCreditCardComponent = withView((props: ViewComponentProps) => {
    const companyCode = props.getParam("companyCode");
    const policyNumber = props.getParam("policyNumber");
    const accountHolderName = props.getParam("accountHolderName");
    const transactionID = props.getParam("transactionID");
    const [ccTypes, setCcTypes] = useState<any>(null);
    const defaultInitialValues = {
        creditCardNumber: '',
        cvv2: '',
        expired: '',
        creditCardType: 'MC',
    };

    useEffect(() => {
        if (ccTypes === null && companyCode) {
            setCcTypes([]);
            ajax({
                url: '/api/payment/getCcTypes',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber
                },
                success: (res: any) => {
                    setCcTypes(res.ccTypes);
                }
            });
        }
    }, [ccTypes]);

    return <div id="mockcashier">
        <React.Fragment>
            <Formik initialValues={defaultInitialValues}
                validate={(values) => {
                    return validate(
                        yup.object().shape({
                            creditCardNumber: YupSchema.mocksavecard.ccNumber,
                            cvv2: YupSchema.mocksavecard.cvv2,
                            expired: YupSchema.mocksavecard.expirationDate,
                            creditCardType: YupSchema.mocksavecard.creditCardType
                        })
                        , values, props);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => {
                    props.next(`/addCreditCardSuccess?profileID=${new Date().getTime()}&creditCardNumber=${values.creditCardNumber.substring(values.creditCardNumber.length - 4)}&companyCode=${companyCode}&policyNumber=${policyNumber}&transactionID=${new Date().getTime() + 123456}&creditCardType=${values.creditCardType}&cvv2=${values.cvv2}&expirationDate=${values.expired}`)
                }}
            >
                {formProps => {
                    return <Form>
                        <props.Panel >
                            <props.PanelHeading>
                                <GlobalizedText message="Mock Saving Credit Card" />
                            </props.PanelHeading>
                            <props.PanelBody>
                                <props.Row>
                                    <props.Col xs="12" md="12">
                                        <props.DL>
                                            <props.DLI title="Account Holder Name" desc={accountHolderName.replace("+", " ")}></props.DLI>
                                        </props.DL>
                                    </props.Col>
                                </props.Row>
                                <props.Row>
                                    <props.Col xs="12" md="12">
                                        <props.DL>
                                            <   props.SelectControl name="creditCardType" required={true} label="common.lbl.cred.card.type" options={ccTypes}>
                                            </props.SelectControl>
                                            <props.TextControl name="creditCardNumber" placeholder="41111111111111111" tabIndex={1} required={true} label="Credit Card Number" ></props.TextControl>
                                            <props.TextControl name="cvv2" placeholder="123" tabIndex={1} required={true} label="CVV2" ></props.TextControl>
                                            <props.TextControl name="expired" placeholder="01/2021" tabIndex={1} required={true} label="Expiration Date (mm/yyyy)" ></props.TextControl>
                                        </props.DL>
                                    </props.Col>
                                </props.Row>
                                <props.Row>
                                    <props.Col xs="12">
                                        <props.Button type="submit" ><GlobalizedText message="payment.mock.succ" /></props.Button>
                                        <props.Button onClick={() => {
                                            props.next(`/addCreditCardFailure?transactionID=${transactionID}&companyCode=${companyCode}&policyNumber=${policyNumber}&refID=${new Date().getTime()}`)
                                        }} ><GlobalizedText message="common.lbl.failed" /></props.Button>
                                        <props.Button onClick={() => {
                                            props.next(`/addCreditCardCancel?transactionID=${transactionID}&companyCode=${companyCode}&policyNumber=${policyNumber}&refID=${new Date().getTime()}`)
                                        }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                                    </props.Col>
                                </props.Row>
                            </props.PanelBody>
                        </props.Panel >

                    </Form>
                }
                }
            </Formik>
        </React.Fragment>
    </div >
});