import React from 'react';
import { ButtonProps, ComboboxControlProps, Input, withCondition } from '../../../shared/components';
import { withErrorControl } from '../../../shared/components/lib/field';

export const Button = withCondition(({ type, className, containerClassName, withoutContainer, condition, ...props }: ButtonProps) => {
    return <div className={`gwp-btn-container ${containerClassName ? containerClassName : ''}`}><button type={type ? type : "button"} className={`btn btn-secondary gwp-btn ${className ? className : ''}`} {...props}>{props.children}</button></div>
});

export const FirstNameAutoCompleteControl = withErrorControl(({ required, readOnly, label, containerClassName, setValue, condition, roleTypeCodes, ...props }: ComboboxControlProps) => {
    return <Input readOnly={readOnly} type="text" {...props}></Input>
});

export const LastNameAutoCompleteControl = withErrorControl(({ required, readOnly, label, containerClassName, setValue, condition, roleTypeCodes, ...props }: ComboboxControlProps) => {
    return <Input readOnly={readOnly} type="text" {...props}></Input>
});

export const OrgNameAutoCompleteControl = withErrorControl(({ required, readOnly, label, containerClassName, setValue, condition, roleTypeCodes, ...props }: ComboboxControlProps) => {
    return <Input readOnly={readOnly} type="text" {...props}></Input>
});