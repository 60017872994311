import { ThemeConfig } from "../../../shared/theme";
import * as theme from './loomis';
const ThemeComponents: ThemeConfig = {
    'loomis': {
        component: theme,
        css: ['/static/customized/themes/loomis/css/extension.css', '/static/customized/themes/loomis/css/common.css', '/static/customized/themes/loomis/css/loomis.css'],
        layouts: {
            'default': theme.DefaultLayout,
            'empty': theme.EmptyLayout
        }
    }
};

export default ThemeComponents;
