import React from 'react';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { SearchSection } from './searchsection';


export const AddSecondaryAddresseeComponent = withView((props: ViewComponentProps) => {
    let companyCode = props.getQueryParam("companyCode")
    let policyNumber = props.getQueryParam("policyNumber")
    let fromPage = props.getQueryParam('fromPage')
    let clientNumberFromInsurance = props.getQueryParam('clientNumber')

    return <>
        <SearchSection {...props} changeType={3} companyCode={companyCode} policyNumber={policyNumber} fromPage={fromPage} clientNumberFromInsurance={clientNumberFromInsurance} />
    </>

});
