import React, { useState, useEffect, useRef } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { Formik, Form } from 'formik';
import { ajax, validate } from '../shared/utils';
import { TableColumn } from "../shared/components";
import GlobalizedText from '../shared/globalization';
import './css/otherAdjustments.css';
import * as yup from 'yup';

export const OtherAdjustments = withView((props: ViewComponentProps) => {
    const varFormPromp = useRef<any>();

    const [config, setConfig] = useState<any | null>(null);

    const [showAll, setShowAll] = useState<boolean>(false);
    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/otherAdjustments/view',
                params: {
                    companyCode: props.getParam("companyCode"),
                    groupNumber: props.getParam("groupNumber"),
                    accountNumber: props.getParam("accountNumber"),
                    billingFrequency: props.getParam("billingFrequency"),
                    participantIDNumber: props.getParam("participantIDNumber"),

                    agentNumber: props.getParam("agentNumber") !== undefined ? props.getParam("agentNumber") : '',
                    agentIndicator: props.getParam("agentIndicator") !== undefined ? props.getParam("agentIndicator") : '',
                    modalDateFrom: props.getParam("modalDateFrom"),
                    modalDateThrough: props.getParam("modalDateThrough"),
                    invoiceNumber: props.getParam("invoiceNumber")
                },
                success: (res: any) => {
                    setConfig(res);
                    if (res.otherAdjustList !== undefined) {
                        setOtherAdjustmentTable({
                            columns: otherAdjustmentTableColumns,
                            data: res.otherAdjustList
                        });
                        setOtherAdjustmentInvTable({
                            columns: otherAdjustmentInvTableColumns,
                            data: res.otherAdjustList
                        });
                    }
                }
            });
            return () => {
            }
        }
    }, [config]);

    const otherAdjustmentTableColumns: Array<TableColumn> = [
        { "title": "billpay.modaldate", "name": "modalDate", "sortable": true },
        { "title": "common.lbl.memberIdentifier", "name": "participantIDNumber", "sortable": true },
        { "title": "common.lbl.name", "name": "name", "sortable": true },
        { "title": "billpay.paymentApplied.appliedDate", "name": "appliedDate", "sortable": true, "type": "date" },
        { "title": "common.lbl.product", "name": "product", "sortable": true },
        { "title": "common.lbl.premium", "name": "premium", "sortable": true },
        { "title": "common.lbl.adjustmentType", "name": "adjustmentType", "sortable": true }
    ]

    const otherAdjustmentInvTableColumns: Array<TableColumn> = [
        { "title": "billpay.modaldate", "name": "modalDate", "sortable": true },
        { "title": "billpay.paymentApplied.appliedDate", "name": "appliedDate", "sortable": true, "type": "date" },
        { "title": "common.lbl.product", "name": "product", "sortable": true },
        { "title": "common.lbl.premium", "name": "premium", "sortable": true },
        { "title": "common.lbl.adjustmentType", "name": "adjustmentType", "sortable": true }
    ]

    const [otherAdjustmentTable, setOtherAdjustmentTable] = useState<any | null>({ columns: otherAdjustmentTableColumns, data: [] });
    const [otherAdjustmentInvTable, setOtherAdjustmentInvTable] = useState<any | null>({ columns: otherAdjustmentInvTableColumns, data: [] });
    const formSubmit = (values: any): void => {
        props.clearMessage();
        ajax({
            url: '/api/otherAdjustments/view',
            params: {
                companyCode: props.getParam("companyCode"),
                groupNumber: props.getParam("groupNumber"),
                accountNumber: props.getParam("accountNumber"),
                participantIDNumber: showAll === true ? props.getParam("participantIDNumber") : varFormPromp.current.values.participantIDNumber,
                lastName: showAll === true ? '' : varFormPromp.current.values.lastName,
                agentNumber: props.getParam("agentNumber") !== undefined ? props.getParam("agentNumber") : '',
                agentIndicator: props.getParam("agentIndicator") !== undefined ? props.getParam("agentIndicator") : '',
                invoiceNumber: props.getParam("invoiceNumber") !== undefined ? props.getParam("invoiceNumber") : '',
                modalDateFrom: props.getParam("modalDateFrom"),
                modalDateThrough: props.getParam("modalDateThrough")
            },
            success: (res: any) => {
                if (res.otherAdjustList !== undefined) {
                    setOtherAdjustmentTable({
                        columns: otherAdjustmentTableColumns,
                        data: res.otherAdjustList
                    });
                    setOtherAdjustmentInvTable({
                        columns: otherAdjustmentInvTableColumns,
                        data: res.otherAdjustList
                    });
                }
            }
        });
    }

    const shwoInvSchema = yup.object().shape({
        participantIDNumber: yup.string().trim()
            .test("participantIDNumber", "billpay_jumpto_mlr", (v) => {
                if (varFormPromp.current.values.participantIDNumber === '' && varFormPromp.current.values.lastName === '') {
                    return false;
                }
                return true;
            }).test("participantIDNumber", "billpay_jumpto_entereitherml", (v) => {
                if (varFormPromp.current.values.participantIDNumber !== '' && varFormPromp.current.values.lastName !== '') {
                    return false;
                }
                return true;
            }),
        lastName: yup.string().trim()
            .test("lastName", "billpay_jumpto_mlr", (v) => {
                if (varFormPromp.current.values.participantIDNumber === '' && varFormPromp.current.values.lastName === '') {
                    return false;
                }
                return true;
            }).test("lastName", "billpay_jumpto_entereitherml", (v) => {
                if (varFormPromp.current.values.participantIDNumber !== '' && varFormPromp.current.values.lastName !== '') {
                    return false;
                }
                return true;
            }),
    });

    const formValidate = (values: any): any => {
        if (showAll === false) {
            return validate(shwoInvSchema, values, props);
        } else {
            return true;
        }
    }

    return <React.Fragment>
        <Formik initialValues={{
            participantIDNumber: '',
            lastName: ''
        }}
            validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps => {
                varFormPromp.current = formProps;
                return <Form id='otherAdjustment'>
                    <props.Row>
                        <props.Col md="12" xs="12" >
                            <div className="gwp-page-header">
                                <div className="gwp-page-header-block"></div>
                                <div className="gwp-page-header-label-title gwp-label">
                                    <GlobalizedText message="billpay.modaldate.from" />
                                </div>
                                <div className="gwp-page-header-label-content gwp-bold">
                                    {config !== null ? config.modalDateFrom : ''}
                                </div>
                                <span className="rightAlign">
                                    <props.PrintButton onClick={() => props.print({ size: 'A5', orientation: 'landscape' })} />
                                </span>
                            </div>
                        </props.Col>
                    </props.Row>
                    {(config !== null && config.currency !== undefined && config.currency.currencyName !== undefined && config.currency.countryCode !== undefined) &&
                        <props.Row className="gwp-currency-margin">
                            <props.Col md="12" xs="12" sm="12">
                                <GlobalizedText message="common.lbl.allcurrencyvalue"></GlobalizedText> {config.currency.currencyName} ({config.currency.countryCode})
                            </props.Col>
                        </props.Row>
                    }
                    <props.Panel>
                        <props.PanelBody>
                            <props.Row>
                                <props.Col md="3" xs="12" sm="3" className="gwp-dl">
                                    <props.DLI title="common.lbl.group" desc={config !== null ? config.groupNumber + ' - ' + config.groupName : ''}></props.DLI>
                                    <props.DLI title="common.lbl.account" desc={config !== null ? config.accountNumber + ' - ' + config.accountName : ''}></props.DLI>
                                    <props.DLI title="common.lbl.member" condition={config !== null && config.participantIDNumber !== undefined}
                                        desc={config !== null ? config.participantIDNumber + ' - ' + config.fullName : ''}>
                                    </props.DLI>
                                    <props.DLI title="billpay.billfrequency" condition={config !== null && config.participantIDNumber === undefined}
                                        desc={config !== null ? config.billingFrequency : ''}>
                                    </props.DLI>
                                </props.Col>
                                <props.Col md="3" xs="12" sm="3" className="gwp-dl" >
                                    <props.DLI title="billpay.invoicenum" desc={props.getParam('invoiceNumber')}></props.DLI>
                                </props.Col>
                                <props.Col data-print-visible="false" md="6" xs="12" sm="6" className="panel-container-right other-adjustment-bottom" condition={props.getParam("participantIDNumber") === null || props.getParam("participantIDNumber") === undefined || props.getParam("participantIDNumber") === ""} >
                                    <props.Panel >
                                        <props.PanelHeading>
                                            <props.Div className="enterinfo"><GlobalizedText message="billpay_enter" /></props.Div>
                                            <props.Div className="jumpTo"><GlobalizedText message="common.lbl.jumpTo" /></props.Div>
                                        </props.PanelHeading>
                                        <props.PanelBody>
                                            <props.Row>
                                                <props.Div>
                                                    <props.Col md="5" sm="12" >
                                                        <props.TextControl name="participantIDNumber" id="participantIDNumber" label="common.lbl.memberID" maxLength='10' />
                                                    </props.Col>
                                                    <props.Col md="2" sm="12" className="or" >
                                                        <GlobalizedText message="common.lbl.or" />
                                                    </props.Col>
                                                    <props.Col md="5" sm="12" >
                                                        <props.TextControl name="lastName" id="lastName" label="common.lbl.lastName" />
                                                    </props.Col>
                                                </props.Div></props.Row>
                                            <props.Row>
                                                <props.Col sm="12" md="5" >
                                                    <props.Button type="submit" className="submitPlace" onClick={() => { setShowAll(false) }}>
                                                        <GlobalizedText message="common.lbl.retrieve" />
                                                    </props.Button>
                                                </props.Col >
                                            </props.Row>
                                            <props.Row>
                                                <props.Col sm="12" md="5" className='showAll'>
                                                    <props.LinkButton type="submit" onClick={() => {
                                                        formProps.setFieldValue("participantIDNumber", "");
                                                        formProps.setFieldValue("lastName", "");
                                                        setShowAll(true)
                                                    }}><GlobalizedText message="common.lbl.showall" /></props.LinkButton>
                                                </props.Col >
                                            </props.Row>
                                        </props.PanelBody>
                                    </props.Panel>
                                </props.Col>
                            </props.Row>
                        </props.PanelBody>
                    </props.Panel>
                    <props.Panel>
                        <props.PanelBody>
                            <props.Container>
                                <props.Row>
                                    <div className="gwp-sectitle">
                                        <GlobalizedText message="common.lbl.adjustments" />
                                    </div>
                                </props.Row>
                                <props.Row>
                                    <props.Col condition={props.getParam("participantIDNumber") === null || props.getParam("participantIDNumber") === undefined || props.getParam("participantIDNumber") === ""}>
                                        <props.Table id="otherAdjustmentTable" table={otherAdjustmentTable} dateformat={props.getDateFormat()}></props.Table>
                                    </props.Col>
                                    <props.Col condition={!(props.getParam("participantIDNumber") === null || props.getParam("participantIDNumber") === undefined || props.getParam("participantIDNumber") === "")}>
                                        <props.Table id="otherAdjustmentInvTable" table={otherAdjustmentInvTable} dateformat={props.getDateFormat()}></props.Table>
                                    </props.Col>
                                </props.Row>
                            </props.Container>
                        </props.PanelBody>
                    </props.Panel>
                </Form>
            }
            }
        </Formik>
    </React.Fragment >
});