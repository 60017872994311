import React, { useContext, useEffect, useRef } from 'react';
import { GlobalizationContext } from '../../globalization';
import { $, getGlobalizedText } from '../../utils';
export interface ProgressBarPagesConfig {
    pages: Array<ProgressBarPageConfig>
}
export interface ProgressBarPageConfig {
    name: string;
    status: 'current' | 'unset' | 'skipped' | 'proceeded'
}
export interface ProgressBarProps {
    id: string,
    config: ProgressBarPagesConfig
}

function isUpdated(originalProps: ProgressBarProps, props: ProgressBarProps): boolean {
    if (originalProps.id !== props.id) {
        return true;
    }
    if (originalProps.config.pages.length !== props.config.pages.length) {
        return true;
    }
    for (let i = 0; i < originalProps.config.pages.length; i++) {
        if (originalProps.config.pages[i].name !== props.config.pages[i].name || originalProps.config.pages[i].status !== props.config.pages[i].status) {
            return true;
        }
    }
    return false;
}

export function ProgressBar(props: ProgressBarProps) {
    const globalization = useContext(GlobalizationContext);
    const timer = useRef<any>();
    const originalProps = useRef<ProgressBarProps>();
    props.config.pages.forEach((pageConfig) => {
        pageConfig.name = getGlobalizedText(globalization, pageConfig.name);
    });
    useEffect(() => {
        if (props.config !== undefined) {
            if (timer.current === undefined) {
                timer.current = setInterval(() => {
                    if ($(`#${props.id}`).width() > 0) {
                        $(`#${props.id}`).formpagebar({ pages: props.config.pages });
                        clearInterval(timer.current);
                    }
                }, 10);
            }
        }
        if (originalProps.current !== undefined) {
            if (isUpdated(originalProps.current, props)) {
                $(`#${props.id}`).formpagebar({ pages: props.config.pages });
            }
        }
        originalProps.current = props;
    })
    return <>
        <div id={props?.id} className="pageBarPos"></div>
    </>
}