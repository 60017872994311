import React, { useEffect, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { HelpBanner } from '../shared/helpbanner';
import { ajax } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { OwnerInfoProps } from '../shared/components';
import './css/changebeneficiary.css';
export const ChangeBeneficiaryComponent = withView((props: ViewComponentProps) => {
    let policyNumber = props.getParam("policyNumber");
    let companyCode = props.getParam("companyCode");
    const [data, setData] = useState<any>();
    useEffect(() => {
        if (data === undefined) {
            ajax({
                url: '/core/beneficiary/view',
                data: { policyNumber: policyNumber, companyCode: companyCode },
                success: res => {
                    setData(res);
                }
            });
        }
    }, [data]);

    if (data === undefined) {
        return <></>
    }

    let ownerProps:OwnerInfoProps = {owner: { ...data.policyDetail.owner, viewOwnerPersonalInfoUrl: data.viewOwnerPersonalInfoUrl, changeOwnerUrl: data.changeOwnerUrl },...props};
    if(data.policyDetail.owner2){
        ownerProps.owner2 = { ...data.policyDetail.owner2, viewOwnerPersonalInfoUrl: data.viewOwner2PersonalInfoUrl, changeOwnerUrl: data.changeOwner2Url }
    }

    return <>
        <props.Row>
            <props.Col xs="12" md="12">
                <div className="gwp-page-header">
                    <div className="gwp-page-header-block"></div>
                    <div className="gwp-page-header-label gwp-label"><GlobalizedText message="common.lbl.policyNumber" /> {policyNumber}</div>
                </div>
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col sm="12" md="8">
                <props.OwnerInfo { ...ownerProps}></props.OwnerInfo>
            </props.Col>
            <props.Col sm="12" md="4">
                <HelpBanner {...props}></HelpBanner>
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col sm="12">
                <props.Panel>
                    <props.PanelHeading><GlobalizedText message="common.lbl.beneficiaryInfo"/></props.PanelHeading>
                    <props.PanelBody>
                        <props.Information message="bi.msg.addBeneficiaryMsg"></props.Information>
                        <props.Label readonly={false}><GlobalizedText message="pd.lbl.primarybeneficiary"/></props.Label>
                        <props.Table option={{paging:false,autowidth:false}} id="pBeneficiaryTable" table={{
                            columns:[
                                { "sortable": false, "title":"common.lbl.delete" , "className": "deleteStyle",width:'5%' },
                                { "title": "common.lbl.name", "name": "name", "sortable": false,width:'20%' },
                                { "title": "bi.lbl.sharepercentage", "name": "sharepercentage", "sortable": false,width:'25%',"className":"percentageWidth" },
                                { "title": "common.lbl.streetAddress", "name": "streetAddress", "sortable": false,width:'15%' },
                                { "title": "common.lbl.city", "name": "city", "sortable": false,width:'15%' },
                                { "title": "common.lbl.stateCountry", "name": "stateCountry", "sortable": false,width:'15%' },
                            ]
                        }}>
                            <tbody>
                            <tr className="totalTr">
                                <td></td>
                                <td className="gwp-bold"><GlobalizedText message="bi.lbl.sharepercentagetotal"/></td>
                                <td>
                                <span id="cbPercentageTotal"></span>
                                    &nbsp;&nbsp;
                                    0.00%/<span className="warnMessage" id="cbPercentageMessage"><GlobalizedText message="bi.msg.sharePercentageTotalMsg"/></span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </props.Table>
                        <props.Label readonly={false}><GlobalizedText message="pd.lbl.contingentbeneficiary"/></props.Label>
                        <props.Table option={{paging:false,autowidth:false}} table={{
                            columns:[
                                { "sortable": false, "title":"common.lbl.delete" , "className": "deleteStyle",width:'5%' },
                                { "title": "common.lbl.name", "name": "name", "sortable": false,width:'20%' },
                                { "title": "bi.lbl.sharepercentage", "name": "sharepercentage", "sortable": false,width:'25%',"className":"percentageWidth" },
                                { "title": "common.lbl.streetAddress", "name": "streetAddress", "sortable": false,width:'15%' },
                                { "title": "common.lbl.city", "name": "city", "sortable": false,width:'15%' },
                                { "title": "common.lbl.stateCountry", "name": "stateCountry", "sortable": false,width:'15%' },
                            ]
                        }} id="cBeneficiaryTable">
                             <tbody>
                            <tr className="totalTr">
                                <td></td>
                                <td className="gwp-bold"><GlobalizedText message="bi.lbl.sharepercentagetotal"/></td>
                                <td>
                                <span id="cbPercentageTotal"></span>
                                    &nbsp;&nbsp;
                                    0.00%/<span className="warnMessage" id="cbPercentageMessage"><GlobalizedText message="bi.msg.sharePercentageTotalMsg"/></span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </props.Table>
                        <props.Button onClick={()=>{props.next("/addPerson/beneficiary")}}><GlobalizedText message="common.lbl.add"></GlobalizedText></props.Button>
                        <props.Button><GlobalizedText message="common.lbl.save"></GlobalizedText></props.Button>
                        <props.Button><GlobalizedText message="common.lbl.delete"></GlobalizedText></props.Button>
                        <props.Button><GlobalizedText message="common.lbl.cancel"></GlobalizedText></props.Button>
                        <props.Button onClick={props.back}><GlobalizedText message="common.lbl.close"></GlobalizedText></props.Button>
                    </props.PanelBody>
                </props.Panel>
            </props.Col>
        </props.Row>
    </>;
});