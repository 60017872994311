import React, { useMemo, useState } from 'react';
import GlobalizedText from '../../globalization';
import { Icon, IconType } from './icon';
import { ConditionProps, withCondition } from './shared-component';

export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, ConditionProps {
    containerClassName?: string;
    withoutContainer?: boolean;
    printVisible?: boolean;
}

export interface SelectButtonOption {
    name: string;
    value: string;
    onClick?: (e: any) => void;
}

export interface SelectButtonProps {
    buttons: Array<SelectButtonOption>;
    label: string;
    defaultValue: string[];
    onValueChange: (values: Array<string>) => void;
}

export interface PrintButtonProps extends ButtonProps {

}

export interface IconButtonProps extends ButtonProps {
    iconType: IconType
    iconLabel: string
}

export const Button = withCondition(({ type, className, containerClassName, withoutContainer, condition, printVisible, ...props }: ButtonProps) => {
    printVisible = (printVisible === true || printVisible === undefined);
    if (withoutContainer !== undefined && withoutContainer === true) {
        return <button data-print-visible={`${printVisible}`} id={props.id} type={type ? type : "button"} className={`btn btn-primary gwp-btn ${className ? className : ''}`} {...props}>{props.children}</button>
    }
    return <div data-print-visible={`${printVisible}`} className={`gwp-btn-container ${containerClassName ? containerClassName : ''}`}><button type={type ? type : "button"} className={`btn btn-primary gwp-btn ${className ? className : ''}`} {...props}>{props.children}</button></div>
})

export const LinkButton = withCondition(({ type, className, containerClassName, withoutContainer, condition, printVisible, ...props }: ButtonProps) => {
    printVisible = (printVisible === true || printVisible === undefined);
    if (withoutContainer === true) {
        return <button data-print-visible={`${printVisible}`} id={props.id} type={type ? type : "button"} className={`gwp-link-btn ${className ? className : ''}`} {...props}>{props.children}</button>
    }
    return <div data-print-visible={`${printVisible}`} className={`gwp-btn-container ${containerClassName ? containerClassName : ''}`}><button type={type ? type : "button"} className={`gwp-link-btn ${className ? className : ''}`} {...props}>{props.children}</button></div>
})

export const PrintButton = withCondition(({ type, className, containerClassName, withoutContainer, condition, ...props }: PrintButtonProps) => {
    return <IconButton {...{ type, containerClassName, withoutContainer, condition, ...props, ...{ iconType: "print", iconLabel: "common.print", printVisible: false, className: `${className ? className : ''} gwp-btn-print` } }} ></IconButton>
})

export const IconButton = withCondition(({ type, className, containerClassName, withoutContainer, condition, iconLabel, iconType, ...props }: IconButtonProps) => {
    return <LinkButton {...{ type, containerClassName, withoutContainer, condition, ...props, ...{ printVisible: false, className: `${className ? className : ''} gwp-btn-print` } }} ><Icon type={iconType} size={"sm"} label={iconLabel}></Icon></LinkButton>
})

export const SelectButton = (props: SelectButtonProps) => {
    const [values, setValues] = useState<Array<string>>(props.defaultValue);
    const [count, setCount] = useState<number>(0);
    const dropDowns = useMemo(() => props.buttons.map((button: SelectButtonOption, index: number) => {
        return <li key={index}><button className={values.includes(button.value) === true ? "glyphicon glyphicon-ok gwp-link-btn" : "glyphicon glyphicon-empty gwp-link-btn"} onClick={(e) => {
            let tmp = [];
            if (values?.includes(button.value)) {
                tmp = values.filter((value: string) => { return value !== button.value });
            } else {
                tmp = values.copyWithin(-1, -1);
                tmp.push(button.value);
            }
            setValues(tmp);
            props.onValueChange(tmp);
            if (button.onClick) {
                button?.onClick(e);
            }
            setCount(count + 1);
        }} style={{ paddingLeft: 20 + "px" }}><span style={{ fontSize: '12px', fontFamily: 'Arial', paddingRight: 15 + "px" }}><GlobalizedText message={button.value} /></span></button></li>
    }), [count, props, values]);

    return <div className="btn-group">
        <button className="btn btn-default" data-toggle="dropdown"><GlobalizedText message={props.label} /></button>
        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
            <span className="caret"></span>
            <span className="sr-only">Toggle Dropdown</span>
        </button>
        <ul className="dropdown-menu">
            {dropDowns}
        </ul>
    </div>
}