import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { closeExtraMenu } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { useMessage } from '../shared/message';
import { ajax, saveToStorage, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import * as yup from 'yup';
import { CONTENT_PREVIEW_ID, CONTENT_PREVIEW_STORAGE_KEY, CONTENT_VIEWER_TYPE_PREVIEW } from './contentviewer';
import './css/portalcategories.css';

const STATUS_DRAFT = 0;
const STATUS_PENDING_APPROVE = 1;
const STATUS_PUBLISHED = 3;
const STATUS_REJECTED = 2;
export interface PortalCategory {
    id: string,
    name: string,
    fullPath: string,
    userTypes: string[],
    userTypeNames: string[],
    lastChangeDate: string,
    contentID: string,
    statusName: string,
    status: number,
    parentID: string
}

interface PortalCategoryView {
    res: PortalCategory[],
    contentTypeID: string,
    isAddPageAvailable: boolean,
    isEditPageAvailable: boolean,
    isDeletePageAvailable: boolean,
    isGetLinkAvailable: boolean,
    isSubmitApproverAvailable: boolean;
}

export const PortalCategoriesComponent = withView((props: ViewComponentProps) => {

    const [view, setView] = useState<PortalCategoryView>();
    const [selected, setSelected] = useState<PortalCategory>();
    const [content, setContent] = useState<string>("");
    const [editContent, setEditContent] = useState<any>();
    const newContentID = useRef<string>();
    const [openEdit, setOpenEdit] = useState<number>(0);
    const [openAdd, setOpenAdd] = useState<number>(0);
    const [openEditReset, setOpenEditReset] = useState<boolean>(false);
    const [openAddReset, setOpenAddReset] = useState<boolean>(false);
    const { messageService, validateProps } = useMessage("eidtPortalcategories");
    useEffect(() => {
        if (openEdit > 0) {
            props.showModal("#editWebPageModal");
        }
    }, [openEdit]);

    useEffect(() => {
        if (openAdd > 0) {
            props.showModal("#addWebPageModal");
        }
    }, [openAdd]);

    const load = () => {
        ajax({
            url: '/api/cms/portalcategory/view',
            success: (res: any) => {
                setView({
                    res: res.portalCategories,
                    contentTypeID: res.contentTypeID,
                    isAddPageAvailable: false,
                    isEditPageAvailable: false,
                    isDeletePageAvailable: false,
                    isGetLinkAvailable: false,
                    isSubmitApproverAvailable: false,
                });

            }
        });
    }

    useEffect(() => {
        if (view === undefined) {
            load();
        }
    }, [view])

    const table = useMemo(() => {
        if (view !== undefined) {
            return <props.Table dateformat={props.getDateFormat()} id="portalCategoriesTable" select={
                {
                    type: 'single', onSelect: (e: any, selectedRows: Array<number>, unSelectedRows: Array<number>) => {
                        let selectedRow = view.res[selectedRows[0]];
                        setSelected(selectedRow);
                        setView({
                            ...view,
                            isAddPageAvailable: selectedRow.contentID === undefined && selectedRow.status !== STATUS_PENDING_APPROVE,
                            isEditPageAvailable: selectedRow.contentID !== undefined && selectedRow.status !== STATUS_PENDING_APPROVE,
                            isDeletePageAvailable: selectedRow.contentID !== undefined && selectedRow.status === STATUS_DRAFT,
                            isGetLinkAvailable: selectedRow.status === STATUS_PUBLISHED && selectedRow.contentID !== undefined,
                            isSubmitApproverAvailable: selectedRow.status === STATUS_DRAFT || selectedRow.status === STATUS_REJECTED
                        });
                    }
                }
            } table={
                {
                    columns: [
                        {
                            name: 'fullPath', title: 'portalCate.lbl.cateNameAndHierarchy', render: (data: any, type: any, row: any, meta: any) => {
                                return row.fullPath.map((p: any, i: number) => { return `<span ${i === row.fullPath.length - 1 && i !== 0 ? 'class="current"' : ''}>${p}</span>` }).join(" / ");
                            }
                        },
                        {
                            name: 'userTypeNames', title: 'labelMessage.accessPermission', render: (data: any, type: any, row: any, meta: any) => {
                                return row.userTypeNames !== undefined ? row.userTypeNames.map((key: string) => { return props.getGlobalizedText(key) }).join(" / ") : '';
                            }
                        },
                        { name: 'lastChangeDate', title: 'common.lbl.updateDate' },
                        {
                            name: 'contentID', title: 'portalCate.lbl.webPageAdded', render: (data: any, type: any, row: any, meta: any) => {
                                return row.contentID ? `${props.getGlobalizedText('common.lbl.added')}` : `${props.getGlobalizedText('common.lbl.none')}`
                            }
                        },
                        {
                            name: 'statusName', title: 'common.lbl.status', render: (data: any, type: any, row: any, meta: any) => {
                                return props.getGlobalizedText(row.statusName);
                            }
                        }
                    ],
                    data: view.res
                }
            }></props.Table>
        } else {
            return <props.Div></props.Div>
        }
    }, [view?.res]);

    if (view === undefined) {
        return <></>
    }


    return <>
        <props.Div>
            <props.Button onClick={() => props.next("/managePortalCategories")}><GlobalizedText message="Manage.Portal.Categories" /></props.Button>
            <props.Button onClick={() => {
                ajax({
                    url: '/api/cms/content/init',
                    success: (res1: any) => {
                        newContentID.current = res1.id;
                        setContent("");
                        setOpenAddReset(false);
                        saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: "" }, false);
                        setOpenAdd(openAdd + 1);
                    }
                });

            }} disabled={!view.isAddPageAvailable}><GlobalizedText message="Add.Web.Page" /></props.Button>
            <props.Button disabled={!view.isEditPageAvailable} onClick={
                () => {
                    ajax({
                        url: `/api/cms/content/get/${selected?.contentID}`,
                        success: (res) => {
                            setEditContent(res);
                            setOpenEditReset(false);
                            saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: res.content }, false);
                            setOpenEdit(openEdit + 1);
                        }
                    });
                }
            }><GlobalizedText message="common.lbl.editWebPage" /></props.Button>
            <props.Button disabled={!view.isDeletePageAvailable} onClick={() => props.showModal("#deleteWebPageModal")}><GlobalizedText message="Delete.Web.Page" /></props.Button>
            <props.Button disabled={!view.isGetLinkAvailable} onClick={() => props.showModal("#getWebPageLinkModal")}><GlobalizedText message="Get.Page.Link" /></props.Button>
            <props.Button onClick={
                () => {
                    if (selected?.contentID === undefined) {
                        props.showModal("#submitForApprovalWithNoWebPageModal");
                    } else {
                        ajax({
                            url: `/api/cms/portalcategory/submitApprove`,
                            method: 'post',
                            params: {
                                id: selected?.id
                            },
                            success: () => {
                                props.showMessage("success", props.getGlobalizedText("submit.for.approval.success").replace("[contentname]", selected ? selected.name : ""));
                                load();
                            }
                        });
                    }
                }
            } disabled={!view.isSubmitApproverAvailable}><GlobalizedText message="Submit.for.Approval" /></props.Button>
        </props.Div>

        {table}

        <Formik initialValues={{
            newContent: content
        }}
            validate={(values) => {
                return validate(yup.object().shape({ newContent: YupSchema.portalcategories.content }), values, validateProps);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/cms/portalcategory/addPage',
                    method: 'post',
                    data: {
                        id: selected?.id,
                        contentID: newContentID.current,
                        content: values.newContent
                    },
                    success: () => {
                        props.closeModal("#addWebPageModal");
                        closeExtraMenu();
                        saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: "" }, false);
                        props.showMessage("success", props.getGlobalizedText("common.msg.addpagesucc"));
                        load();
                    }
                });
            }}
            validateOnBlur={false}
            validateOnChange={false}
            onReset={() => validateProps.clearMessage()}
        >
            {formProps =>
                <Form>
                    <props.Modal id="addWebPageModal" messageService={messageService} onClose={() => { setOpenAddReset(true) }} title="Add.Web.Page" size="large" footer={() => {
                        return <>
                            <props.Button disabled={newContentID.current === undefined} onClick={() => {
                                window.open(`${process.env.PUBLIC_URL}/content?id=${CONTENT_PREVIEW_ID}&type=${CONTENT_VIEWER_TYPE_PREVIEW}`, '', 'height=600, width=960, top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no');
                            }}><GlobalizedText message="common.lbl.preview" /></props.Button>
                            <props.Button type="submit"><GlobalizedText message="common.lbl.saveandclose" /></props.Button>
                            <props.Button data-dismiss="modal" onClick={() => { closeExtraMenu(); saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: "" }, false); formProps.resetForm() }}><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }}>
                        <props.ContentEditor needReset={openAddReset} modal="#addWebPageModal" html={content} contentId={newContentID.current} contentTypeId={view.contentTypeID} onChange={(newContent: string) => {
                            setContent(newContent);
                            saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: newContent }, false);
                            formProps.setFieldValue("newContent", newContent);
                        }}  {...props} name="newContent" id="addWebPageEditor" />
                    </props.Modal>
                </Form>
            }
        </Formik>

        {editContent !== undefined &&
            <Formik initialValues={{
                content: editContent.content
            }}
                validate={(values) => {
                    return validate(yup.object().shape(YupSchema.portalcategories), values, validateProps);
                }}
                onSubmit={(values) => {
                    ajax({
                        url: '/api/cms/portalcategory/editPage',
                        method: 'post',
                        data: {
                            id: selected?.id,
                            contentID: selected?.contentID,
                            content: values.content
                        },
                        success: () => {
                            props.closeModal("#editWebPageModal");
                            closeExtraMenu();
                            props.showMessage("success", props.getGlobalizedText("common.msg.editpagesucc"));
                            load();
                        }
                    });
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onReset={() => validateProps.clearMessage()}
            >
                {formProps =>
                    <Form>
                        <props.Modal id="editWebPageModal" messageService={messageService} onClose={() => {
                            setOpenEditReset(true)
                        }} title="common.lbl.editWebPage" size="large" footer={() => {
                            return <>
                                <props.Button disabled={selected?.contentID === undefined} onClick={() => {
                                    window.open(`${process.env.PUBLIC_URL}/content?id=${CONTENT_PREVIEW_ID}&type=${CONTENT_VIEWER_TYPE_PREVIEW}`, '', 'height=600, width=960, top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no');
                                }}><GlobalizedText message="common.lbl.preview" /></props.Button>
                                <props.Button type="submit"><GlobalizedText message="common.lbl.saveandclose" /></props.Button>
                                <props.Button data-dismiss="modal" onClick={() => { closeExtraMenu(); formProps.resetForm() }}><GlobalizedText message="common.lbl.close" /></props.Button>
                            </>
                        }}>
                            <props.ContentEditor needReset={openEditReset} modal="#editWebPageModal" contentId={selected?.contentID} contentTypeId={view.contentTypeID} html={editContent.content} onChange={(newContent: string) => {
                                setEditContent({ ...editContent, content: newContent });
                                saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: newContent }, false);
                                formProps.setFieldValue("content", newContent);
                            }}  {...props} id="editWebPageEditor" name="content" />
                        </props.Modal>
                    </Form>
                }
            </Formik>
        }
        <props.Modal id="deleteWebPageModal" title={() => {
            return <><props.Icon type="warning"></props.Icon><GlobalizedText message="Delete.Web.Page" /></>
        }} footer={() => {
            return <>
                <props.Button onClick={() => {
                    ajax({
                        url: '/api/cms/portalcategory/removePage',
                        method: 'post',
                        params: {
                            id: selected?.id,
                        },
                        success: () => {
                            props.closeModal("#deleteWebPageModal");
                            props.showMessage("success", props.getGlobalizedText("delete.webpage.success"));
                            load();
                        }
                    });
                }}><GlobalizedText message="common.lbl.wpdelete" /></props.Button>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Div>
                <GlobalizedText message="common.msg.selectedToDel" /><b> {`<${selected?.name}>`}</b>.<p />
                <GlobalizedText message="common.msg.confirmDelete" />
            </props.Div>
        </props.Modal>

        <props.Modal id="getWebPageLinkModal" title="Get Page Link" footer={() => {
            return <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
        }}>
            1.<GlobalizedText message="Portal.Copy.Link" />:<p />
            {`${process.env.PUBLIC_URL}/content?id=${selected?.contentID}`}<p />
            2.<GlobalizedText message="PastInUrl.When.Insert" /><p />
        </props.Modal>


        <props.Modal id="submitForApprovalWithNoWebPageModal" title={() => {
            return <><props.Icon type="warning"></props.Icon><GlobalizedText message="common.lbl.noWebPageAdded" /></>
        }} footer={() => {
            return <>
                <props.Button onClick={() => {
                    ajax({
                        url: `/api/cms/portalcategory/submitApprove`,
                        method: 'post',
                        params: {
                            id: selected?.id
                        },
                        success: () => {
                            props.showMessage("success", props.getGlobalizedText("submit.for.approval.success").replace("[contentname]", selected ? selected.name : ""));
                            props.closeModal("#submitForApprovalWithNoWebPageModal");
                            load();
                        }
                    });
                }}><GlobalizedText message="common.lbl.consubmit" /></props.Button>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Div>
                <GlobalizedText message="common.lbl.category" /> <b>{` <${selected?.name}> `}</b>
                <GlobalizedText message="common.lbl.isBeingSubmitted" /><p />
                <GlobalizedText message="common.lbl.clcconfirmSubmit" />
            </props.Div>
        </props.Modal>
    </>
});