import { Form, Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { User } from "../../shared/authentication";
import { Button, closeModal, Col, Modal, Row, showModal, TextareaControl, TextControl } from "../../shared/components";
import GlobalizedText, { GlobalizationContext } from "../../shared/globalization";
import { useMessage } from "../../shared/message";
import { ajax, getContextPath, getGlobalizedText, resetForm, validate } from "../../shared/utils";
import { YupSchema } from "../../shared/yupschema";
import "../../pages/css/submitinquiry.css";
import { SecurityContext } from "../../context/securityContext";
import {SyncOutlined} from "@ant-design/icons";
const QUESTION_MAX_LENGTH: number = 500;
const textWidthStyle = {
    maxWidth: '100%'
}

export interface SubmitYourInquiryProps {
    user: User | null,
    supportedFileTypes: String
}

const isUserChanged = (ori: User | null, newUser: User | null): boolean => {
    if (ori !== null && newUser !== null) {
        if (ori.firstName !== newUser.firstName && ori.lastName !== newUser.lastName) {
            return true;
        } else {
            return false;
        }
    }
    if (ori !== newUser) {
        return true;
    } else {
        return false;
    }

}

export const SubmitYourInquiry = (props: SubmitYourInquiryProps) => {
    const { showEnquiryForm,setShowEnquiryForm } = useContext(SecurityContext);
    const { messageService, validateProps } = useMessage("submitinquiry");
    const globalization = useContext(GlobalizationContext);
    const [config, setConfig] = useState<any>();
    const user = useRef<User | null>(null);
    const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
    const [attachment, setAttachment] = useState<any>();
    const [attachmentError, setAttachmentError] = useState<any>(false);
    const [success, setSuccess] = useState<any>(false);
    const [loader, setLoader] = useState<any>(false);
    useEffect(() => {
        if (showEnquiryForm) {
            showModal("#theme_inquiry_popup");
        }
    });
    useEffect(() => {
        if (isUserChanged(user.current, props.user)) {
            setConfig(undefined);
        }
    }, [props.user])
    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/contactForm/view',
                success: (res: any) => {
                    setConfig(res);
                    user.current = props.user;
                },
                showMask: false
            });
        }
    }, [config]);

    if (config === undefined) {
        return <></>
    }

    const submitInuqirySchema = yup.object().shape({
        s_firstName: YupSchema.submitInquiry.firstName,
        s_lastName: YupSchema.submitInquiry.lastName,
        s_contactEmail: YupSchema.submitInquiry.email,
        s_contactPhone: YupSchema.submitInquiry.phoneNumber,
        s_question: YupSchema.submitInquiry.question
    });

    let initialValues = {
        ...{ s_firstName: '', s_lastName: '', s_contactEmail: '', s_contactPhone: '', s_question: '' },
        ...config !== undefined ? {
            s_firstName: config.contactForm.firstName === undefined ? '' : config.contactForm.firstName,
            s_lastName: config.contactForm.lastName === undefined ? '' : config.contactForm.lastName,
            s_contactPhone: config.contactForm.contactPhone === undefined ? '' : config.contactForm.contactPhone,
            s_contactEmail: config.contactForm.contactEmail === undefined ? '' : config.contactForm.contactEmail
        } : {}
    }
    
    const isValidFileUploaded = (file: any) => {
        const validExtensions = props.supportedFileTypes.split(",");
        const fileExtension = file.name.split('.')[1];
        return validExtensions.includes(fileExtension.toLowerCase());
    }
    const uploadImagesHandler = (res:any, formData:any) => {
        ajax({
            url: '/api/userInquiry/upload/' + res.inquiryId,
            method: 'POST',
            data: formData,
            success: (res) => {
                setLoader(false);                
                setIsSubmitClicked(false);
                setSuccess(true);
            }, error: (error: any) => {
                messageService.showMessage("error", error.response.data.message);
            }, showMask: false
        });
    }
const isValidFileUploadedSize = (file: any) => {
    const fileSizeKiloBytes = file.size / 1024
    if(fileSizeKiloBytes > config.maxFileSize){           
        return false;
    }
    return true;
}   
let fp = {
        initialValues: initialValues,
        onSubmit: (values: any, formikHelpers: any) => {
            if (attachment !== "" && attachment !== undefined && attachment.imgCollection.length > 0) {
                setLoader(true);
            }
            setIsSubmitClicked(true)
            ajax({
                url: '/api/contactForm/submit',
                method: 'POST',
                data: {
                    firstName: values.s_firstName,
                    lastName: values.s_lastName,
                    contactEmail: values.s_contactEmail,
                    contactPhone: values.s_contactPhone,
                    question: values.s_question
                },
                success: (res) => {
                    if (attachment !== "" && attachment !== undefined && attachment.imgCollection.length > 0) {
                        let formData = new FormData();
                        for (const key of Object.keys(attachment.imgCollection)) {
                            formData.append('file', attachment.imgCollection[key])
                        }
                        uploadImagesHandler(res, formData);
                    } else{
                        setIsSubmitClicked(false);
                        setSuccess(true);
                    }
                    formikHelpers.resetForm();
                    setAttachment("");
                    setAttachmentError("");
                }, error: (error: any) => {
                    messageService.showMessage("error", error.response.data.message);
                }, showMask: false
            });

        },
        validate: (values: any) => {
            return validate(submitInuqirySchema, values, validateProps);
        },
        validateOnBlur: false,
        validateOnChange: false
    };

    const onChangeFileHandeler = (e: any) => {
        if (e.target.files !== undefined && e.target.files.length > 0) {
            const attachedFileValidationError = [];
            let fileSizeError = true;
            for (const key of Object.keys(e.target.files)) {
                fileSizeError = isValidFileUploadedSize(e.target.files[key]);
                attachedFileValidationError.push(!fileSizeError ? 'wrongsize' : true);
                attachedFileValidationError.push(isValidFileUploaded(e.target.files[key]));
            }
            if(attachedFileValidationError.includes("wrongsize")){                            
                setAttachmentError(getGlobalizedText(globalization,"common.er.invalidFileSize") + " "  + config.maxFileSize/1024 +" MB. ");            }
            else if (attachedFileValidationError.includes(false)) {
                setAttachmentError(getGlobalizedText(globalization, "common.er.invalidFile"));
                setAttachment("");
            } else {
                setAttachment({ imgCollection: e.target.files });
                setAttachmentError("");
            }
        }
    }

    return <>
        <Formik onReset={() => { messageService.clearMessage() }} {...fp}>
            {formProps =>
                <Form>
                    <Modal onClose={() => setShowEnquiryForm(false)} title={success ? "common.msg.submitted.successfully" : "common.lbl.yourInquiry" } id="theme_inquiry_popup"  footer={() => {
                        return <>
                            {!success && <>
                            <Button type="submit" disabled={isSubmitClicked || attachmentError}><GlobalizedText message="common.lbl.submit" /></Button>
                            <Button onClick={() => {
                                setAttachment("");
                                setAttachmentError("");
                                resetForm(formProps);
                                setShowEnquiryForm(false)
                            }} data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></Button>
                            </> }
                            {success && <Button data-dismiss="modal">
                                    <GlobalizedText message="common.lbl.close" />
                                    </Button> }
                        </>
                    }} messageService={messageService}>
                        {loader && <div className="loader_submit_form"><SyncOutlined spin /></div>}
                    
                        {success && <GlobalizedText message="homepage.lbl.thx.for.submit" /> }
                        {!success && <Row>
                            <Col sm="12" md="8">
                                <Col sm="12">
                                    <TextControl containerClassName="formGroup-noMargin" style={textWidthStyle} id="s_firstName" name="s_firstName" label="common.lbl.firstName" required={true}></TextControl>
                                </Col>
                                <Col sm="12">
                                    <TextControl containerClassName="formGroup-noMargin" style={textWidthStyle} id="s_lastName" name="s_lastName" label="common.lbl.lastName" required={true}></TextControl>
                                </Col>
                                <Col sm="12">
                                    <TextControl containerClassName="formGroup-noMargin" style={textWidthStyle} id="s_contactPhone" name="s_contactPhone" label="pd.lbl.phone" required={true}></TextControl>
                                </Col>
                                <Col sm="12">
                                    <TextControl containerClassName="formGroup-noMargin" style={textWidthStyle} id="s_contactEmail" name="s_contactEmail" label="pi.email.address" required={true}></TextControl>
                                </Col>
                                <Col sm="12">
                                    <TextareaControl styleOfRemaining={{ width: "66%" }} showRemaining={true} id="s_question" containerClassName="formGroup-noMargin" maxLength={QUESTION_MAX_LENGTH} style={textWidthStyle} name="s_question" label="common.lbl.question" required={true}></TextareaControl>
                                </Col>
                            </Col>
                            <Col sm="12" md="4" className="file-group">
                                <p>{attachmentError && attachmentError !== "" && attachmentError}</p>
                                <p className="attachment-placeholder">{attachment && <img src={`${getContextPath()}/static/themes/blue/images/attachment.png`} />}</p>
                                <label className="btn btn-primary gwp-btn ">
                                    <GlobalizedText message={attachment ? "common.lbl.attached" : "common.lbl.attachment"} />
                                    <input name="attachment" type="file" className="hidden-type" defaultValue={attachment} onChange={onChangeFileHandeler} multiple />
                                </label>
                            </Col>
                        </Row> }
                    </Modal>

                </Form>
            }
        </Formik>
    </>
}