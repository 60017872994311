export const VALIDATION_REGEX = {
    GOVT_ID: new RegExp(/^[0-9]{9}$/),
    Positive_Int: new RegExp(/^$|^\+?[1-9]\d*$/),
    CONTAINS_ALPHABET: new RegExp(/[a-zA-Z]{1,}/),
    PHONE_NUMBER: new RegExp(/^\d{10}$/),
    MONEY: new RegExp(/(?:^\d{1,3}(?:\.?\d{3})*(?:,\d{2})?$)|(?:^\d{1,3}(?:,?\d{3})*(?:\.\d{2})?$)/),
    NUMBER: new RegExp(/^[0-9]+$/),
    EMAIL: new RegExp(/^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@([A-Za-z0-9]+(?:-[A-Za-z0-9]+)?\.)+[A-Za-z0-9]+(?:-[A-Za-z0-9]+)?$/),
    PHONE_NUMBER_PERSONALINFO: new RegExp(/^(\s*|\d{10})$/),
    ZIP: new RegExp(/^\d{5}(-\d{4})?$/)
}
