import React, { useEffect, useRef, useState, useMemo } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, formatNumber, getFromStorage, isEmptyStr, movePageToTop, saveToStorage, trim } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/beneficiaryviewinformation.css';
import { TableCell, useTable } from '../shared/components';
import { Form, Formik } from 'formik';
import { HelpBanner } from '../shared/helpbanner';


interface RequestBody4UpdateBeneficiaryPercentage {
    companyCode: string,
    policyNumber: string,
    primaryBeneficiaries: Array<any>,
    contigentBeneficiaries: Array<any>
}
interface RequestBooday4DeleteBeneficiary {
    companyCode: string,
    policyNumber: string,
    clientNumber: string,
    roleTypeCode: string
}


export const BeneficiaryViewInformationComponent = withView((props: ViewComponentProps) => {
    let companyCode = trim(props.getQueryParam("companyCode"))
    let policyNumber = trim(props.getQueryParam("policyNumber"))
    let fromPage = trim(props.getQueryParam("fromPage"))

    const [config, setConfig] = useState<any | null>(null);
    const [primarybeneficiary, setPrimarybeneficiary] = useState<any | null>(null);
    const [contingentbeneficiary, setContingentbeneficiary] = useState<any | null>(null);
    const [disbaleDeleteBtn, setDisbaleDeleteBtn] = useState<boolean>(true);
    const [disbaleSaveBtn, setDisbaleSaveBtn] = useState<boolean>(true);

    const [totalPBenePercentage, setTotalPBenePercentage] = useState<number>(0)
    const [totalCBenePercentage, setTotalCBenePercentage] = useState<number>(0)

    const [totalPBPerMsg, setTotalPBPerMsg] = useState<string>("")
    const [totalCBPerMsg, setTotalCBPerMsg] = useState<string>("")
    const pBeneForm = useRef<any>();
    const cBeneForm = useRef<any>();
    const dataObjPBPercentage = useRef<any>({})
    const dataObjCBPercentage = useRef<any>({})
    const dataObj4DeleteBene = useRef<Array<any>>([])

    const [pbCheckBox, setPbCheckbox] = useState<boolean>(false)
    const [cbCheckBox, setCbCheckbox] = useState<boolean>(false)

    let hasPBDistributeEventFlag = getFromStorage('hasPBDistributeEvent', true)
    let hasCBDistributeEventFlag = getFromStorage('hasCBDistributeEvent', true)

    const requestBody4UpdateBeneficiary = useRef<RequestBody4UpdateBeneficiaryPercentage>({
        companyCode: companyCode,
        policyNumber: policyNumber,
        primaryBeneficiaries: [],
        contigentBeneficiaries: []
    })
    const RequestBooday4DeleteBeneficiary = useRef<RequestBooday4DeleteBeneficiary>({
        companyCode: companyCode,
        policyNumber: policyNumber,
        clientNumber: '',
        roleTypeCode: ''
    })

    useEffect(() => {
        if (config === null) {
            loadDefaultSetting()
            if (hasPBDistributeEventFlag !== null && hasPBDistributeEventFlag.result === 'true') {
                setDisbaleSaveBtn(false)
            }
            if (hasCBDistributeEventFlag !== null && hasCBDistributeEventFlag.result === 'true') {
                setDisbaleSaveBtn(false)
            }
        }
    }, [config])

    useEffect(() => {
        if (pbCheckBox === true) {
            distributeEvent('PB')
            setDisbaleSaveBtn(false)
            saveToStorage("hasPBDistributeEvent", { 'result': 'true' }, true);
        } else {
            saveToStorage("hasPBDistributeEvent", { 'result': 'false' }, true);
        }
    }, [pbCheckBox])

    useEffect(() => {
        if (cbCheckBox === true) {
            distributeEvent('CB')
            setDisbaleSaveBtn(false)
            saveToStorage("hasCBDistributeEvent", { 'result': 'true' }, true);
        } else {
            saveToStorage("hasCBDistributeEvent", { 'result': 'false' }, true);
        }
    }, [cbCheckBox])

    function returnModalTitle() {
        return <props.Col md="12" sm="12" xs="12">
            <props.Icon type='warning' /><GlobalizedText message="firupd.warning" />
        </props.Col>
    }

    function loadDefaultSetting() {
        dataObj4DeleteBene.current = []
        ajax({
            url: '/api/beneficiary/view',
            params: {
                companyCode: companyCode,
                policyNumber: policyNumber,
                fromPage: fromPage
            },
            success: (res: any) => {
                movePageToTop()
                setConfig(res)
                if (res.policyDetail.primaryBeneficiary !== undefined) {
                    let tmpCount = 0
                    setPrimarybeneficiary(res.policyDetail.primaryBeneficiary)
                    if (hasPBDistributeEventFlag !== null && hasPBDistributeEventFlag.result === 'true') {
                        let tmpLength = res.policyDetail.primaryBeneficiary.length
                        let tmpDistribute: any = formatNumber(100 / tmpLength)
                        let lastDistribute = 100 - (tmpDistribute * 1) * (tmpLength - 1)
                        for (let i = 0; i < tmpLength; i++) {
                            let tmpDel = {
                                clientNumber: res.policyDetail.primaryBeneficiary[i].clientNumber,
                                roleTypeCode: 'BEN',
                                toBeDeleted: false
                            }
                            dataObj4DeleteBene.current.push(tmpDel)
                            if (i !== (tmpLength - 1)) {
                                tmpCount += tmpDistribute * 1
                                dataObjPBPercentage.current[res.policyDetail.primaryBeneficiary[i].clientNumber] = tmpDistribute
                                pBeneForm.current.setFieldValue(res.policyDetail.primaryBeneficiary[i].clientNumber, tmpDistribute)
                            } else {
                                tmpCount += lastDistribute
                                dataObjPBPercentage.current[res.policyDetail.primaryBeneficiary[i].clientNumber] = formatNumber(lastDistribute)
                                pBeneForm.current.setFieldValue(res.policyDetail.primaryBeneficiary[i].clientNumber, formatNumber(lastDistribute))
                            }
                        }
                        setTotalPBenePercentage(tmpCount)
                        setPbCheckbox(true)
                        setDisbaleSaveBtn(false)
                    } else {
                        for (let rowData of res.policyDetail.primaryBeneficiary) {
                            dataObjPBPercentage.current[rowData.clientNumber] = formatNumber(rowData.percentage)
                            tmpCount += rowData.percentage
                            let tmpDel = {
                                clientNumber: rowData.clientNumber,
                                roleTypeCode: 'BEN',
                                toBeDeleted: false
                            }
                            dataObj4DeleteBene.current.push(tmpDel)
                            pBeneForm.current.setFieldValue(rowData.clientNumber, formatNumber(rowData.percentage))

                        }
                        if (tmpCount !== 100) {
                            setTotalPBPerMsg('bi.msg.sharePercentageTotalMsg')
                        }
                        setTotalPBenePercentage(tmpCount)
                    }
                } else {
                    setPrimarybeneficiary(null)
                    setTotalPBenePercentage(0)
                    setTotalPBPerMsg('')

                }
                if (res.policyDetail.contigentBeneficiary !== undefined) {
                    let tmpCount = 0
                    setContingentbeneficiary(res.policyDetail.contigentBeneficiary)
                    if (hasCBDistributeEventFlag !== null && hasCBDistributeEventFlag.result === 'true') {
                        let tmpLength = res.policyDetail.contigentBeneficiary.length
                        let tmpDistribute: any = formatNumber(100 / tmpLength)
                        let lastDistribute = 100 - (tmpDistribute * 1) * (tmpLength - 1)
                        for (let i = 0; i < tmpLength; i++) {
                            let tmpDel = {
                                clientNumber: res.policyDetail.contigentBeneficiary[i].clientNumber,
                                roleTypeCode: 'CNT',
                                toBeDeleted: false
                            }
                            dataObj4DeleteBene.current.push(tmpDel)
                            if (i !== (tmpLength - 1)) {
                                tmpCount += tmpDistribute * 1
                                dataObjCBPercentage.current[res.policyDetail.contigentBeneficiary[i].clientNumber] = tmpDistribute
                                cBeneForm.current.setFieldValue(res.policyDetail.contigentBeneficiary[i].clientNumber, tmpDistribute)
                            } else {
                                tmpCount += lastDistribute
                                dataObjCBPercentage.current[res.policyDetail.contigentBeneficiary[i].clientNumber] = formatNumber(lastDistribute)
                                cBeneForm.current.setFieldValue(res.policyDetail.contigentBeneficiary[i].clientNumber, formatNumber(lastDistribute))
                            }
                        }
                        setTotalCBenePercentage(tmpCount)
                        setCbCheckbox(true)
                        setDisbaleSaveBtn(false)
                    } else {
                        for (let rowData of res.policyDetail.contigentBeneficiary) {
                            dataObjCBPercentage.current[rowData.clientNumber] = formatNumber(rowData.percentage)
                            tmpCount = tmpCount + rowData.percentage
                            let tmpDel = {
                                clientNumber: rowData.clientNumber,
                                roleTypeCode: 'CNT',
                                toBeDelete: false
                            }
                            dataObj4DeleteBene.current.push(tmpDel)
                            cBeneForm.current.setFieldValue(rowData.clientNumber, formatNumber(rowData.percentage))
                        }
                        if (tmpCount !== 100) {
                            setTotalCBPerMsg('bi.msg.sharePercentageTotalMsg')
                        }
                        setTotalCBenePercentage(tmpCount)
                    }
                } else {
                    setContingentbeneficiary(null)
                    setTotalCBenePercentage(0)
                    setTotalCBPerMsg('')
                }
            }
        })
    }

    function validatePBPercentage(values: any, clietNumber: any) {
        if (dataObjPBPercentage.current[clietNumber] === formatNumber(values)) { //No date change
            // setDisbaleDeleteBtn(!disbaleDeleteBtn)
        } else {
            dataObjPBPercentage.current[clietNumber] = formatNumber(values)
            let tmpPBTotal = 0
            for (let rowData of primarybeneficiary) {
                tmpPBTotal += dataObjPBPercentage.current[rowData.clientNumber] * 1
            }
            setTotalPBenePercentage(tmpPBTotal)
            if (tmpPBTotal !== 100) {
                setTotalPBPerMsg("bi.msg.sharePercentageTotalMsg")
            } else {
                setTotalPBPerMsg("")
            }
            // setDisbaleDeleteBtn(true)
        }
    }
    function validateCBPercentage(values: any, clietNumber: any) {
        if (dataObjCBPercentage.current[clietNumber] === formatNumber(values)) { //No date change

        } else {
            dataObjCBPercentage.current[clietNumber] = formatNumber(values)
            let tmpPBTotal = 0
            for (let rowData of contingentbeneficiary) {
                tmpPBTotal += dataObjCBPercentage.current[rowData.clientNumber] * 1
            }
            setTotalCBenePercentage(tmpPBTotal)
            if (tmpPBTotal !== 100) {
                setTotalCBPerMsg("bi.msg.sharePercentageTotalMsg")
            } else {
                setTotalCBPerMsg("")
            }
            // setDisbaleDeleteBtn(true)
        }
    }

    function addBeneficiary() {
        props.next(`/addBeneficiary?companyCode=${trim(companyCode)}&policyNumber=${trim(policyNumber)}`)
    }
    function updateBeneficiary() {
        props.next(`/updatePersonalInfomation?companyCode=${trim(companyCode)}&policyNumber=${trim(policyNumber)}`)
    }
    function updatePercentage() {
        let tmpTotalPBPercentage = 0
        let tmpTotalCBPercentage = 0

        let tmpArrPB: Array<any> = []
        let tmpArrCB: Array<any> = []

        if (primarybeneficiary !== null) {
            for (let rowData of primarybeneficiary) {
                tmpTotalPBPercentage += dataObjPBPercentage.current[rowData.clientNumber] * 1
                let tmpObjPB = {
                    clientNumber: rowData.clientNumber,
                    percentage: dataObjPBPercentage.current[rowData.clientNumber] * 1
                }
                tmpArrPB.push(tmpObjPB)
            }
            requestBody4UpdateBeneficiary.current.primaryBeneficiaries = tmpArrPB
        } else {
            tmpTotalPBPercentage = 100
        }
        if (contingentbeneficiary !== null) {
            for (let rowData of contingentbeneficiary) {
                tmpTotalCBPercentage += dataObjCBPercentage.current[rowData.clientNumber] * 1
                let tmpObjCB = {
                    clientNumber: rowData.clientNumber,
                    percentage: dataObjCBPercentage.current[rowData.clientNumber] * 1
                }
                tmpArrCB.push(tmpObjCB)
            }
            requestBody4UpdateBeneficiary.current.contigentBeneficiaries = tmpArrCB
        } else {
            tmpTotalCBPercentage = 100
        }

        if (parseFloat(formatNumber(tmpTotalPBPercentage)) !== 100 || parseFloat(formatNumber(tmpTotalCBPercentage)) !== 100) {
            props.showMessage('error', 'bi.msg.sharePercentageTotalMsg')
        } else if (parseFloat(formatNumber(tmpTotalPBPercentage)) === 100 && parseFloat(formatNumber(tmpTotalCBPercentage)) === 100) {
            ajax({
                url: '/api/beneficiary/updateBeneficiary',
                method: 'POST',
                data: requestBody4UpdateBeneficiary.current,
                success: (res: any) => {
                    props.showMessage('success', 'bi.msg.updatePecentageMsg')
                    loadDefaultSetting()
                    setDisbaleSaveBtn(true)
                }, fail: (res: any) => {
                    props.showMessage('error', res.message)
                }
            })
        }
    }
    function validateCanDeleteBene() {
        let canBeDeleteBeneficiary = 0
        dataObj4DeleteBene.current.forEach((row) => {
            if (row.toBeDeleted === true) {
                canBeDeleteBeneficiary++
                RequestBooday4DeleteBeneficiary.current.clientNumber = row.clientNumber
                RequestBooday4DeleteBeneficiary.current.roleTypeCode = row.roleTypeCode
            }
        })

        if (canBeDeleteBeneficiary > 1) {
            props.showModal("#warningDeleteBene")
        } else if (canBeDeleteBeneficiary > 0 && canBeDeleteBeneficiary < 2) {
            props.showModal("#confirmDelete")
        }

    }
    function buildDataObj4DeleteBeneficiary(event: any) {
        let disableDeleteBtton = 0
        dataObj4DeleteBene.current.forEach((row) => {
            if (event.target.id === row.clientNumber) {
                row.toBeDeleted = event.target.checked
            }
        })
        dataObj4DeleteBene.current.forEach((row) => {
            if (row.toBeDeleted === true) {
                disableDeleteBtton++
            }
        })
        if (disableDeleteBtton > 0) {
            return false
        } else {
            return true
        }
    }
    function deleteBeneficiary() {
        ajax({
            url: '/api/beneficiary/deleteBeneficiary',
            method: 'DELETE',
            params: {
                companyCode: companyCode,
                policyNumber: policyNumber,
                clientNumber: RequestBooday4DeleteBeneficiary.current.clientNumber,
                roleTypeCode: RequestBooday4DeleteBeneficiary.current.roleTypeCode
            },
            success: (res: any) => {
                props.closeModal("#confirmDelete")
                loadDefaultSetting()
                props.showMessage('success', 'bi.msg.delBeneficiaryMsg')
                setDisbaleDeleteBtn(true)
            }
        })
    }

    function checkSaveButtonEabled(value: any, index: number, type: string) {
        if (type === "PB") {
            if (formatNumber(value) !== formatNumber(primarybeneficiary[index].percentage)) {
                setDisbaleSaveBtn(false)
            }
        } else if (type === "CB") {
            if (formatNumber(value) !== formatNumber(contingentbeneficiary[index].percentage)) {
                setDisbaleSaveBtn(false)
            }
        }
    }

    function distributeEvent(type: string) {
        if (config.updateBeneficiary !== false) {
            let tmpCount = 0
            if (type === 'PB') {
                tmpCount = primarybeneficiary.length
                let tmpDistribute: any = formatNumber(100 / tmpCount)
                if (tmpCount === 1) {
                    validatePBPercentage(tmpDistribute, primarybeneficiary[0].clientNumber)
                    pBeneForm.current.setFieldValue(primarybeneficiary[0].clientNumber, tmpDistribute)
                    checkSaveButtonEabled(tmpDistribute, 0, "PB")
                } else {
                    for (let i = 0; i < primarybeneficiary.length - 1; i++) {
                        validatePBPercentage(tmpDistribute, primarybeneficiary[i].clientNumber)
                        pBeneForm.current.setFieldValue(primarybeneficiary[i].clientNumber, tmpDistribute)
                        checkSaveButtonEabled(tmpDistribute, i, "PB")
                    }
                    let lastDistribute = 100 - (tmpDistribute * 1) * (tmpCount - 1)
                    validatePBPercentage(formatNumber(lastDistribute), primarybeneficiary[tmpCount - 1].clientNumber)
                    pBeneForm.current.setFieldValue(primarybeneficiary[tmpCount - 1].clientNumber, formatNumber(lastDistribute))
                }
            } else if (type === 'CB') {
                tmpCount = contingentbeneficiary.length
                let tmpDistribute: any = formatNumber(100 / tmpCount)
                if (tmpCount === 1) {
                    validateCBPercentage(tmpDistribute, contingentbeneficiary[0].clientNumber)
                    cBeneForm.current.setFieldValue(contingentbeneficiary[0].clientNumber, tmpDistribute)
                    checkSaveButtonEabled(tmpDistribute, 0, "CB")
                } else {
                    for (let i = 0; i < contingentbeneficiary.length - 1; i++) {
                        validateCBPercentage(tmpDistribute, contingentbeneficiary[i].clientNumber)
                        cBeneForm.current.setFieldValue(contingentbeneficiary[i].clientNumber, formatNumber(tmpDistribute))
                        checkSaveButtonEabled(tmpDistribute, i, "CB")
                    }
                    let lastDistribute = 100 - (tmpDistribute * 1) * (tmpCount - 1)
                    validateCBPercentage(formatNumber(lastDistribute), contingentbeneficiary[tmpCount - 1].clientNumber)
                    cBeneForm.current.setFieldValue(contingentbeneficiary[tmpCount - 1].clientNumber, formatNumber(lastDistribute))
                }
            }
        }
    }

    function validateCanBackToPreviousPage() {
        if (primarybeneficiary === null && contingentbeneficiary === null) {
            props.back()
        } else if ((primarybeneficiary !== null && primarybeneficiary.length > 0 && totalPBenePercentage === 100) || (contingentbeneficiary !== null && contingentbeneficiary.length > 0 && totalCBenePercentage === 100)) {
            props.back()
        } else {
            props.showMessage('error', 'bi.msg.sharePercentageTotalMsg')
        }
    }

    const primarybeneficiaryList = useMemo(() => useTable({
        id: 'primarybeneficiaryTable',
        next: props.next,
        columnsShows: 4,
        onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element | null) => {
            let id = '#pbPercentage' + rowNumber
            childNode?.querySelector(id)?.addEventListener("change", (e: any) => {
                checkSaveButtonEabled(e.target.value, rowNumber, "PB")
                setPbCheckbox(false)
                setDisbaleDeleteBtn(true)
                validatePBPercentage(e.target.value, rowData.clientNumber)
                pBeneForm.current.setFieldValue(rowData.clientNumber, formatNumber(e.target.value))
            });
        },
        option: { paging: false, scrollCollapse: true },
        children: [
            <thead key={0}>
                <tr>
                    <th data-sortable={false} className="gwp-dt-detail-control"></th>
                    {(config !== null && config.canChangeBeneficiary === true && config.updateBeneficiary === true && config.deleteBeneficiary === true) &&
                        <th data-sortable={false} style={{ width: "5%" }} ><GlobalizedText message="common.lbl.delete" /></th>
                    }
                    <th data-sortable={false} style={{ width: "20%" }}><GlobalizedText message="common.lbl.name" /></th>
                    <th data-sortable={false} style={{ width: "25%" }} className="percentageWidth"><GlobalizedText message="bi.lbl.sharepercentage" />
                        {(config !== null && config.policyDetail.primaryBeneficiary !== undefined && config.canChangeBeneficiary === true && config.updateBeneficiary === true) &&
                            <props.Div id="pbdeCheckDiv">
                                <input id="pbCheckbox" type="checkbox" name='deCheck' checked={pbCheckBox} onChange={() => {
                                    setPbCheckbox(!pbCheckBox)
                                }} />{" "}<GlobalizedText message="bi.lbl.distributeEvently" />
                            </props.Div>
                        }
                    </th>
                    <th data-sortable={false} style={{ width: "15%" }} ><GlobalizedText message="common.lbl.streetAddress" /></th>
                    <th data-sortable={false} style={{ width: "15%" }} ><GlobalizedText message="common.lbl.city" /></th>
                    <th data-sortable={false} style={{ width: "20%" }} ><GlobalizedText message="common.lbl.stateCountry" /></th>
                </tr>
            </thead >,
            <tbody key={1}>
                {primarybeneficiary !== null && primarybeneficiary.map((row: any, index: number) => {
                    return <tr key={index}>
                        <td className="gwp-dt-detail-control"></td>
                        {(config !== null && config.canChangeBeneficiary === true && config.updateBeneficiary === true && config.deleteBeneficiary === true) &&
                            <td align='center'><input type="checkbox" id={row.clientNumber} onChange={(e) => {
                                setDisbaleDeleteBtn(buildDataObj4DeleteBeneficiary(e))
                            }} /></td>
                        }
                        {config !== null && config.showNameAsLink === true &&
                            <td ><props.Link id={`personalInfo${index}`} to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'BEN'}&policyNumber=${policyNumber}&baseRiderCode=${0}&relationshipSequenceNumber=${row.relationSequence}`}>{row.name}</props.Link></td>
                        }
                        {config !== null && config.showNameAsLink === false &&
                            <td >{row.name}</td>
                        }
                        <td id="pbPercentage"><props.InputAddonControl id={`pbPercentage${index}`} disabled={(config.canChangeBeneficiary === true && config.updateBeneficiary === true) ? false : true} name={row.clientNumber} addonMsg="%" onBlur={(e: any) => {
                            checkSaveButtonEabled(e.target.value, index, "PB")
                            setPbCheckbox(false)
                            setDisbaleDeleteBtn(true)
                            validatePBPercentage(e.target.value, row.clientNumber)
                            pBeneForm.current.setFieldValue(row.clientNumber, formatNumber(e.target.value))
                        }} ></props.InputAddonControl></td>
                        <td >{row.streetAddresses.map((o: any, i: number) => {
                            if (isEmptyStr(o) === false) {
                                return <React.Fragment key={i}>{o}<br /></React.Fragment>
                            } else {
                                return <React.Fragment key={i}></React.Fragment>
                            }
                        })}
                        </td>
                        <td >{row.city}</td>
                        <td >{row.state}
                            {!isEmptyStr(row.state) && !isEmptyStr(row.country) &&
                                <span> / </span>
                            }
                            {row.country}
                        </td>
                    </tr>
                })}
                <tr id="pBeneTotalTR">
                    <td data-gwp-child="no" className="gwp-no-details-control hideTD"></td>
                    {(config !== null && config.canChangeBeneficiary === true && config.updateBeneficiary === true && config.deleteBeneficiary === true) &&
                        <td className="hiddenBolder"></td>
                    }
                    <td className="totalPercentage hiddenBolder"><GlobalizedText message="bi.lbl.sharepercentagetotal" /></td>
                    <td className="hiddenBolder">
                        <props.Span className={totalPBPerMsg !== '' ? 'warnMessage' : ''}>{formatNumber(totalPBenePercentage) + " %  "}
                            {(config !== null && config.canChangeBeneficiary === true && config.updateBeneficiary === true && config.deleteBeneficiary === true) &&
                                <GlobalizedText message={totalPBPerMsg} />
                            }
                        </props.Span>

                    </td>
                    <td className="hiddenBolder"></td>
                    <td className="hiddenBolder"></td>
                    <td className="hiddenBolder"></td>
                </tr>
            </tbody>
        ]
    }), [config, primarybeneficiary, totalPBenePercentage, totalPBPerMsg, pbCheckBox]);

    const contingentbeneficiaryList = useMemo(() => useTable({
        id: 'contingentbeneficiaryTable',
        next: props.next,
        columnsShows: 4,
        option: { paging: false, scrollCollapse: true },
        onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element | null) => {
            let id = '#pbPercentage' + rowNumber
            childNode?.querySelector(id)?.addEventListener("change", (e: any) => {
                checkSaveButtonEabled(e.target.value, rowNumber, "CB")
                setCbCheckbox(false)
                setDisbaleDeleteBtn(true)
                validateCBPercentage(e.target.value, rowData.clientNumber)
                cBeneForm.current.setFieldValue(rowData.clientNumber, formatNumber(e.target.value))
            });
        },
        children: [
            <thead key={0}>
                <tr>
                    <th data-sortable={false} className="gwp-dt-detail-control"></th>
                    {(config !== null && config.canChangeBeneficiary === true && config.updateBeneficiary === true && config.deleteBeneficiary === true) &&
                        <th data-sortable={false} style={{ width: "5%" }} ><GlobalizedText message="common.lbl.delete" /></th>
                    }
                    <th data-sortable={false} style={{ width: "20%" }}><GlobalizedText message="common.lbl.name" /></th>
                    <th data-sortable={false} style={{ width: "25%" }} className="percentageWidth"><GlobalizedText message="bi.lbl.sharepercentage" />
                        {(config !== null && config.policyDetail.contigentBeneficiary !== undefined && config.canChangeBeneficiary === true && config.updateBeneficiary === true) &&
                            <props.Div id="cbdeCheckDiv">
                                <input id="cbCheckbox" type="checkbox" name='deCheck' checked={cbCheckBox} onChange={() => {
                                    setCbCheckbox(!cbCheckBox)
                                }} />{" "}<GlobalizedText message="bi.lbl.distributeEvently" />
                            </props.Div>
                        }
                    </th>
                    <th data-sortable={false} style={{ width: "15%" }} ><GlobalizedText message="common.lbl.streetAddress" /></th>
                    <th data-sortable={false} style={{ width: "15%" }} ><GlobalizedText message="common.lbl.city" /></th>
                    <th data-sortable={false} style={{ width: "20%" }} ><GlobalizedText message="common.lbl.stateCountry" /></th>
                </tr>
            </thead>,
            <tbody key={1}>
                {contingentbeneficiary !== null && contingentbeneficiary.map((row: any, index: number) => {
                    return <tr key={index}>
                        <td className="gwp-dt-detail-control"></td>
                        {(config !== null && config.canChangeBeneficiary === true && config.updateBeneficiary === true && config.deleteBeneficiary === true) &&
                            <td align='center'><input type="checkbox" id={row.clientNumber} onChange={(e) => {
                                setDisbaleDeleteBtn(buildDataObj4DeleteBeneficiary(e))
                            }} /></td>
                        }
                        {config !== null && config.showNameAsLink === true &&
                            <td ><props.Link id={`personalInfo${index}`} to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'CNT'}&policyNumber=${policyNumber}&baseRiderCode=${0}&relationshipSequenceNumber=${row.relationSequence}`}>{row.name}</props.Link></td>
                        }
                        {config !== null && config.showNameAsLink === false &&
                            <td >{row.name}</td>
                        }
                        <td id="cbPercentage"><props.InputAddonControl id={`cbPercentage${index}`} disabled={(config.canChangeBeneficiary === true && config.updateBeneficiary === true) ? false : true} name={row.clientNumber} addonMsg="%" onBlur={(e: any) => {
                            checkSaveButtonEabled(e.target.value, index, "CB")
                            setCbCheckbox(false)
                            setDisbaleDeleteBtn(true)
                            validateCBPercentage(e.target.value, row.clientNumber)
                            cBeneForm.current.setFieldValue(row.clientNumber, formatNumber(e.target.value))
                        }} ></props.InputAddonControl></td>
                        <td >{row.streetAddresses.map((rowData: any, i: number) => {
                            if (isEmptyStr(rowData) === false) {
                                return <React.Fragment key={i}>{rowData}<br /></React.Fragment>
                            } else {
                                return <React.Fragment key={i}></React.Fragment>
                            }
                        })}
                        </td>
                        <td >{row.city}</td>
                        <td >{row.state}
                            {!isEmptyStr(row.state) && !isEmptyStr(row.country) &&
                                <span> / </span>
                            }
                            {row.country}
                        </td>
                    </tr>
                })}
                <tr id="cBeneTotalTR">
                    <td data-gwp-child="no" className="gwp-no-details-control hideTD"></td>
                    {(config !== null && config.canChangeBeneficiary === true && config.updateBeneficiary === true && config.deleteBeneficiary === true) &&
                        <td className="hiddenBolder"></td>
                    }
                    <td className="totalPercentage hiddenBolder"><GlobalizedText message="bi.lbl.sharepercentagetotal" /></td>
                    <td className="hiddenBolder">
                        <props.Span className={totalCBPerMsg !== '' ? 'warnMessage' : ''}>{formatNumber(totalCBenePercentage) + " %  "}
                            {(config !== null && config.canChangeBeneficiary === true && config.updateBeneficiary === true && config.deleteBeneficiary === true) &&
                                <GlobalizedText message={totalCBPerMsg} />
                            }
                        </props.Span>
                    </td>
                    <td className="hiddenBolder"></td>
                    <td className="hiddenBolder"></td>
                    <td className="hiddenBolder"></td>
                </tr>
            </tbody>
        ]
    }), [config, contingentbeneficiary, totalCBenePercentage, totalCBPerMsg, cbCheckBox]);

    const primarybeneficiaryTable = useMemo(() => <primarybeneficiaryList.Component />, [primarybeneficiaryList]);
    const contingentbeneficiaryTable = useMemo(() => <contingentbeneficiaryList.Component />, [contingentbeneficiaryList]);

    const formSubmit = (values: any): void => {

    }

    if (config === null) {
        return <></>
    }

    return <props.Div>
        <props.Row>
            <props.Col md="8" sm="8" xs="12">
                <props.PageHeader label={() => {
                    return <props.Span id="pageHeader" style={{ color: "black" }}><GlobalizedText message="common.lbl.policyNumber" />{' ' + policyNumber}</props.Span>
                }}></props.PageHeader>
            </props.Col>
            <props.Col md="8" sm="12" xs="12">
                <props.DL id="owner1" className="dl-horizontal">
                    <props.DLI title={() => { return <><GlobalizedText message="common.lbl.owner" />{config.policyDetail.owner2 !== undefined && <> 1</>}</> }} desc={() => {
                        return <>
                            {config.showNameAsLink === true && <props.Div>
                                <props.Link to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'OW1'}&policyNumber=${policyNumber}&baseRiderCode=0`}>{config.policyDetail !== undefined ? config.policyDetail.owner.name.replaceAll("<wbr/>", "") : ''}</props.Link>
                            </props.Div>}
                            {config.showNameAsLink === false && <props.Div>
                                {config.policyDetail !== undefined ? config.policyDetail.owner.name.replaceAll("<wbr/>", "") : ''}
                            </props.Div>}
                        </>
                    }}></props.DLI>
                    <props.DLI title="pd.lbl.address" desc={() => {
                        return <>
                            {config.policyDetail !== undefined ? config.policyDetail.owner.address.split("<br/>").map((o: any, i: number) => { return <React.Fragment key={i}>{o}<br /></React.Fragment> }) : <></>}
                        </>
                    }}></props.DLI>
                </props.DL>
                <props.DL id="owner2" condition={config.policyDetail.owner2 !== undefined} className="dl-horizontal" >
                    <props.DLI title={() => { return <><GlobalizedText message="common.lbl.owner" />{" 2"}</> }} desc={() => {
                        return <>
                            {config.showNameAsLink === true && <props.Div>
                                <props.Link to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'OW2'}&policyNumber=${policyNumber}&baseRiderCode=0`}>{config.policyDetail !== undefined ? config.policyDetail.owner2.name.replaceAll("<wbr/>", "") : ''}</props.Link>
                            </props.Div>}
                            {config.showNameAsLink === false && <props.Div>
                                {config.policyDetail !== undefined ? config.policyDetail.owner2.name.replaceAll("<wbr/>", "") : ''}
                            </props.Div>}
                        </>
                    }}></props.DLI>
                    <props.DLI title="pd.lbl.address" desc={() => {
                        return <>
                            {config.policyDetail !== undefined ? config.policyDetail.owner2.address.split("<br/>").map((o: any, i: number) => { return <React.Fragment key={i}>{o}<br /></React.Fragment> }) : <></>}
                        </>
                    }}></props.DLI>
                </props.DL>
            </props.Col>
            <props.Col md="4" sm="12" xs="12">
                <HelpBanner className="helpBanner" {...props}></HelpBanner>
                <br />
            </props.Col>
            <props.Col md="12" sm="12" xs="12">
                <props.Panel id="beneficiaryInfo">
                    <props.PanelHeading>
                        <GlobalizedText message="common.lbl.beneficiaryInfo" />
                    </props.PanelHeading>
                    <props.PanelBody>
                        {(config.updateBeneficiary === true && config.addBeneficiary === true && config.policyDetail.primaryBeneficiary === undefined && config.policyDetail.contigentBeneficiary === undefined) &&
                            <p>
                                <props.Span className="glyphicon glyphicon-exclamation-sign" style={{ color: "#888" }} aria-hidden="true"></props.Span>{" "}
                                <GlobalizedText className="noBeneficiaryWarning" message="bi.msg.addBeneficiaryMsg" />
                            </p>
                        }
                        <props.Label><GlobalizedText message="pd.lbl.primarybeneficiary" /></props.Label>

                        <Formik initialValues={dataObjPBPercentage.current}
                            // enableReinitialize={true}
                            validateOnBlur={false}
                            validateOnChange={false}
                            onSubmit={formSubmit}
                        >
                            {formProps => {
                                pBeneForm.current = formProps
                                return <Form id="pBeneForm" style={{ marginTop: '-15px' }}>
                                    {primarybeneficiaryTable}
                                </Form>
                            }
                            }
                        </Formik>
                        <br />
                        <props.Label><GlobalizedText message="pd.lbl.contingentbeneficiary" /></props.Label>
                        <Formik initialValues={dataObjCBPercentage.current}
                            enableReinitialize={true}
                            validateOnBlur={false}
                            validateOnChange={false}
                            onSubmit={formSubmit}
                        >
                            {formProps => {
                                cBeneForm.current = formProps
                                return <Form id="cBeneForm" style={{ marginTop: '-15px' }}>
                                    {contingentbeneficiaryTable}
                                </Form>
                            }
                            }
                        </Formik>
                        {/* 4 buttons */}
                        {(config.canChangeBeneficiary === true && config.updateBeneficiary === true) &&
                            <>
                                <props.Button condition={config.addBeneficiary === true} type="button" onClick={() => { addBeneficiary() }}><GlobalizedText message="common.lbl.add" /></props.Button>
                                <props.Button type="button" disabled={disbaleSaveBtn !== null && disbaleSaveBtn === true} onClick={() => { updatePercentage() }}><GlobalizedText message="common.lbl.save" /></props.Button>
                                <props.Button condition={config.deleteBeneficiary === true} type="button" disabled={disbaleDeleteBtn === true} onClick={() => { validateCanDeleteBene() }}><GlobalizedText message="common.lbl.delete" /></props.Button>
                                <props.Button type="button" onClick={() => { validateCanBackToPreviousPage() }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                            </>
                        }
                        <props.Button condition={config.canChangeBeneficiary === false || config.updateBeneficiary === false} type="button" onClick={() => { props.back() }}><GlobalizedText message="common.lbl.close" /></props.Button>
                    </props.PanelBody>
                </props.Panel>
            </props.Col>
        </props.Row>

        {/* Confirm delete */}
        <props.Modal id="confirmDelete" title={returnModalTitle} footer={() => {
            return <>
                <props.Button onClick={() => {
                    deleteBeneficiary()
                }}><GlobalizedText message="openenrol.lbl.confirmDelete" /></props.Button>
                <props.Button onClick={() => {
                    props.closeModal("#confirmDelete")
                }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
            </>
        }} >
            <props.Row>
                <props.Col md="12" sm="12" xs="12">
                    <GlobalizedText message="bi.msg.delBeneficiaryConfirm" />
                </props.Col>
            </props.Row>
        </props.Modal>
        {/* Warning Delete Modal */}
        <props.Modal id="warningDeleteBene" title={returnModalTitle} footer={() => {
            return <props.Button onClick={() => {
                props.closeModal("#warningDeleteBene")
            }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
        }} >
            <props.Row>
                <props.Col md="12" sm="12" xs="12">
                    <GlobalizedText message="bi.msg.onlyOneBeneficiary" />
                </props.Col>
            </props.Row>
        </props.Modal>
    </props.Div>
});
