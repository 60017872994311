import React, { useState, useEffect, useRef } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { TableCell, useTable } from "../shared/components";
import { Formik, Form } from 'formik';
import { ajax } from '../shared/utils';
import './css/annuityPayee.css';
import GlobalizedText from '../shared/globalization';

export const AnnuityPayee = withView((props: ViewComponentProps) => {
    const varFormPromp = useRef<any>();

    const [config, setConfig] = useState<any | null>(null);

    let policyNumber = props.getQueryParam("policyNumber");
    let companyCode = props.getParam("companyCode");
    let planCode = props.getQueryParam("planCode");
    let showNameAsLink = props.getQueryParam("showNameAsLink");

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/annuityPayee/view',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber,
                    planCode: planCode,
                    showNameAsLink: showNameAsLink
                },
                success: (res: any) => {
                    setConfig(res);
                }
            });
            return () => {
            }
        }
    }, [config]);

    const annuityPayeeTable = useTable({
        id: 'annuityPayeeTable',
        onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element | null) => {
            childNode?.querySelectorAll("a")?.forEach((a: HTMLAnchorElement) => {
                let href = a.getAttribute("data-href");
                a.addEventListener("click", (e: any) => {
                    if (href !== null) {
                        props.next(href);
                    }
                    e.preventDefault();
                });
            });
        },
        children: [
            <thead key={0}>
                <tr>
                    <th scope="col" data-width='3%'></th>
                    <th scope="col" data-width='19%'><GlobalizedText message="vap.lbl.payee" /></th>
                    <th scope="col" data-width='19%'><GlobalizedText message="common.lbl.status" /></th>
                    <th scope="col" data-width='19%'><GlobalizedText message="common.lbl.startDate" /></th>
                    <th scope="col" data-width='19%'><GlobalizedText message="vap.lbl.npd" /></th>
                    <th scope="col" data-width='19%'><GlobalizedText message="common.lbl.expirationDate" /></th>
                    <th scope="col" ><GlobalizedText message="vap.lbl.gpa" /></th>
                    <th className="gwp-dt-child"></th>
                </tr>
            </thead>,
            <tbody key={1}>
                {config !== null && config.annuityPayeeList.map((annuityPayee: any, index: number) => {
                    return <tr key={index}>
                        <td className="gwp-details-control"></td>
                        <td >{annuityPayee.payee.replaceAll("<wbr/>", "")}</td>
                        <td>{annuityPayee.status}</td>
                        <td>{annuityPayee.startDate}</td>
                        <td>{annuityPayee.nextPayoutDate}</td>
                        <td>{annuityPayee.expirationDate}</td>
                        <td>{annuityPayee.formattedGrossPayoutAmount}</td>
                        <td className="gwp-dt-child">
                            <div className="col-md-8 col-sm-8 col-xs-6" style={{ paddingLeft: 0 }}>
                                <dl className="gwp-dl disbursementMethod textInTable">
                                    <dt ><GlobalizedText message="vap.lbl.disb" /></dt>
                                    <dd>{annuityPayee.disbursementMethod} </dd>
                                </dl>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h3 className="panel-title"><GlobalizedText message='common.lbl.relatedLink' /></h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className='updateContactInfo'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs contractIcon"></div>&nbsp;
                                        <props.Link id="personalInformation" to={`/personalInformation?companyCode=${companyCode.trim()}&policyNumber=${policyNumber.trim()}&baseRiderCode=${annuityPayee.payeeNumber}&roleTypeCode=ANP&personalInfoType=2&isUpdateMode=false`}><GlobalizedText message='pi.contact.info' /></props.Link></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                })
                }
            </tbody >
        ]
    });

    return <React.Fragment>
        <Formik initialValues={{
        }}
            validate={() => { }}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={() => { }}
        >
            {formProps => {
                varFormPromp.current = formProps;
                return <Form id='annuityPayee'>
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <div>
                                <dl className="gwp-dl dl-horizontal">
                                    <dt style={{ textAlign: 'left' }}> <GlobalizedText message="common.lbl.policyNumber" /></dt>
                                    <dd>{policyNumber}</dd>
                                </dl>
                            </div>
                            <div>
                                <dl className="gwp-dl dl-horizontal">
                                    <dt style={{ textAlign: 'left' }}> <GlobalizedText message="vap.lbl.po" /></dt>
                                    <dd>{config !== null ? config.payoutOption : ''}</dd>
                                </dl>
                            </div>
                            {config !== null && config.payoutOptionCode !== undefined
                                && config.payoutOptionCode !== '' && config.payoutOptionCode !== 'IA' &&
                                <div>
                                    <dl className="gwp-dl dl-horizontal">
                                        <dt style={{ textAlign: 'left' }}> <GlobalizedText message="vap.lbl.pf" /></dt>
                                        <dd>{config !== null ? config.payoutFrequency : ''}</dd>
                                    </dl>
                                </div>
                            }
                        </props.Col>
                    </props.Row><br />
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12" className='textAboveTable'>
                            <GlobalizedText message="common.lbl.allcurrencyvalue" />&nbsp;Dollar (US)
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <annuityPayeeTable.Component />
                        </props.Col>
                    </props.Row>
                </Form>
            }
            }
        </Formik>

    </React.Fragment >
});