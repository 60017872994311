import React, { useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/makepaymentresult.css';
export const MakePaymentFailureComponent = withView((props: ViewComponentProps) => {
    const [timeNumber, setTimeNumber] = useState<number>(5);
    let timer = window.setInterval(() => {
        if (timeNumber > 0) {
            setTimeNumber(timeNumber - 1);
        } else {
            back();
            window.clearInterval(timer);
        }

    }, 1000);
    function back() {
        window.parent.postMessage('paymentFail', '*');
    }
    return <div id="paymentResult">
        <props.Panel>
            <props.PanelBody >
                <div className="container">
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <div role="alert" className="alert alert-danger gwp-page-message messageBanner">
                                <span aria-hidden="true" className="glyphicon glyphicon-remove messageIcon"></span>
                                <GlobalizedText message="common.lbl.payment.declined" />
                                <span className="printPlace">
                                    <props.Span className="gwp-icon gwp-icon-print gwp-icon-sm " ></props.Span>
                                    <props.LinkButton printVisible={false} onClick={() => props.print({ size: 'A5', orientation: 'landscape' })}><GlobalizedText message="common.print" /></props.LinkButton>
                                </span>
                            </div>
                        </props.Col>
                    </props.Row>
                    <props.HR className="hrstyle" />
                    <p id="currencyInfo" className="currencyInfo"></p>
                    <GlobalizedText message="common.msg.payment.process.failed" />
                    <div className="timeout">
                        <em>
                            <GlobalizedText message="common.msg.will.close" /> &nbsp;
                            <span id="timeOut">{timeNumber}</span>&nbsp;
                            <GlobalizedText message="common.msg.seconds" />
                        </em>
                    </div>
                    <div className="modal-footer2">
                        <props.Button className="btn btn-primary gwp-btn " id="close" onClick={() => { back() }}  > <GlobalizedText message="common.lbl.close" /></props.Button>
                    </div>
                </div>
            </props.PanelBody>
        </props.Panel >
    </div>
});