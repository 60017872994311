import React from 'react';
import GlobalizedText from "../../shared/globalization";
const InitialBenfitTransactionErrorDispay = (props: any) => {
    return <>
        <props.DLI condition={props.submitResult?.errorMsg !== ''} title="" desc={() => {
                            return <>
                              <strong><i><GlobalizedText message="open.enrollmentcon.errormesgdisplay"/></i></strong>
                            </>
                        }} /> 
    </>
}
export default InitialBenfitTransactionErrorDispay;
