import React, { useState } from 'react'


export const SecurityContext = React.createContext<any | null>(null);

export const SecurityContextProvider = (props: any) => {
    const [secureLinks, setSecureLinks] = useState([]);
    const [userAuth, setUserAuth] = useState([]);
    const [showEnquiryForm, setShowEnquiryForm] = useState(false);
    const list: any = [];
    if (secureLinks.length > 0) {
        /* Below nested code is to filter the URLs from menu items which handling by the Security Authorization
        * and creating the new array list where developer can utilize this globally using react context API for authenticating any page or link which handling by the Security Authorization module
        */
        secureLinks.map((item: any) => {
            if (item?.menus?.length > 0) {
                item?.menus?.map((menu: any) => {
                    if (menu?.children.length > 0) {
                        menu?.children.map((children: any) => {
                            list.push(children?.url);
                        })
                    }
                    setSecureLinks(list);
                })
            }
        });
    }

    return <>
        <SecurityContext.Provider value={{ secureLinks, setSecureLinks, userAuth, setUserAuth, showEnquiryForm, setShowEnquiryForm }}>
            {props.children}
        </SecurityContext.Provider>
    </>;
}

export default SecurityContextProvider;