import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { FormikProps } from 'formik';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { Config } from '../config/config';
import { showErrorModal } from './error';
import { MessageType } from './message';
interface CssForTheme {
    [themeName: string]: Array<any>;
}
let loadedCss: CssForTheme = {

};

export function parseBoolean(str: string | undefined) {
    if (str === undefined) {
        return false;
    }
    return str === 'true'
}

export function isEmptyStr(str: string | undefined | null) {
    return str === undefined || str === null || str.toString().trim() === '';
}

export function isEmptyArray(str: Array<any> | undefined | null) {
    return str === undefined || str === null || str.length === 0;
}

/**
 * A simple sort function for array that supports single field sort, return the copy of original array.
 * @param array array need to be sorted
 * @param field the field need to be used to sort
 * @param asc sort by asc or desc. 
 * @param getGloblization if getGlobliazation is provided, then will try to read the message from globalization before compare.
 */
export function simpleSort(array: Array<any>, field: string, asc?: boolean, getGloblization?: (key: string) => string) {
    if (array === undefined) {
        console.warn("The array need to be sorted can not be undefined.");
        return array;
    }
    let tempArray = array;
    tempArray.sort((o1: any, o2: any) => {
        if (o1[field] === o2[field]) {
            return 0;
        }
        let isAsc = asc === undefined || asc === true;
        if (getGloblization !== undefined) {
            return getGloblization(o1[field]) > getGloblization(o2[field]) ? isAsc ? 1 : -1 : isAsc ? -1 : 1
        }
        else {
            return o1[field] > o2[field] ? isAsc ? 1 : -1 : isAsc ? -1 : 1
        }
    }
    );
    return tempArray;
}

export const trimFields = (obj: any) => {
    let tmp: any = {};
    for (let field in obj) {
        if (typeof (obj[field]) === "string") {
            tmp[field] = obj[field].trim();
        }
        else {
            tmp[field] = obj[field];
        }
    }
    return tmp;
}

export function loadCss(themeName: string, href: string) {
    if (loadedCss[href] === undefined) {
        if (!href.startsWith("http://") && !href.startsWith("https://")) {
            href = getContextPath() + href;
        }
        let link = document.createElement("link");
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("type", "text/css");
        link.setAttribute("href", href);
        document.head.appendChild(link);
        if (loadedCss[themeName] === undefined) {
            loadedCss[themeName] = [];
        }
        let hasLoaded = false
        for (let lk of loadedCss[themeName]) {
            if (lk.getAttribute("link") === href) {
                hasLoaded = true;
            }
        }
        if (hasLoaded) {
            loadedCss[themeName].push(link);
        }
    }
}

export function unloadCss(themeName: string) {
    if (loadedCss[themeName] !== undefined) {
        for (let link of loadedCss[themeName]) {
            document.head.removeChild(link);
        }
    }
}

export function loadMask(option: any) {
    $(".gwp-portlet-container").ploading(option);
}

export function showMask(cssSelector: string, option?: any) {
    $(cssSelector).ploading(option ? option : { action: 'show', });
}

export function hideMask(cssSelector: string, option?: any) {
    $(cssSelector).ploading(option ? option : { action: 'hide' });
}


export function formatDate(format: string, date?: Date): string {
    if (date === undefined || date === null) {
        return "";
    }
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let lowerCaseFormat = format.toLowerCase();
    return lowerCaseFormat.replace("yyyy", `${year}`)
        .replace("mm", `${month < 10 ? '0' + month : month}`)
        .replace("MM", `${month < 10 ? '0' + month : month}`)
        .replace("dd", `${day < 10 ? '0' + day : day}`)
        ;
}

export type PrintOrientation = 'landscape' | 'portrait' | 'auto'
export type PrintSize = 'A5' | 'A4' | 'A3' | 'B5' | 'B4' | 'JIS-B5' | 'JIS-B4' | 'letter' | 'legal' | 'ledger'
export interface PrintOption {

    /**
     * Print orientation
     */
    orientation?: PrintOrientation;
    /**
     * Page size
     */
    size?: PrintSize;

    /**
     * Css selector of the target component which will be printed.
     */
    cssSelector?: string;
}

/**
 * Print size, default is auto.
 * @param selector 
 * @param size 
 */
export function print(id: string, option?: PrintOption) {
    let opt: PrintOption = option === undefined ? {} : option;
    if (opt?.orientation === undefined) {
        opt.orientation = 'auto'
    }
    $(option?.cssSelector ? option.cssSelector : "#" + id).gwpprint(opt);
}

export function triggerEvent(el: any, eventName: string, isNativeEvent: boolean) {
    if (el.fireEvent) {
        (el.fireEvent('on' + eventName));
    } else {
        let event: any = null;
        if (!isNativeEvent) {
            event = document.createEvent('Events');
            event.initEvent(eventName, true, false);
            el.dispatchEvent(event);
        } else {
            el.click();
        }

        return;
    }
}

export function generateUUID(): string {
    return uuidv4();
}

export function replaceAll(find: any, replace: string, str: string): string {
    find = find.replace(/[-\\/\\^$*+?.()|[\]{}]/g, '\\$&');
    return str.replace(new RegExp(find, 'g'), replace);
}

export function parseDate(date: string, format: string): Date | undefined {
    if (date === "") {
        return undefined;
    }
    return new Date(Date.parse(convertDate(date, format, "mm/dd/yyyy")));
}

export function showElement(el: any) {
    $(el).show();
}

export function hideElement(el: any) {
    $(el).hide();
}

export function getPureNumericFormat(fmt: string) {
    let i_m = fmt.indexOf("mm");
    i_m = i_m === -1 ? fmt.indexOf("MM") : i_m;
    let i_y = fmt.indexOf("yyyy");
    let i_d = fmt.indexOf("dd");
    let array = [i_m, i_d, i_y];
    array.sort(function (a, b) { return a - b; });
    let pFmt = "";
    for (let i = 0; i < array.length; i++) {
        switch (array[i]) {
            case i_m:
                pFmt += fmt.substring(i_m, i_m + 2);
                break;
            case i_y:
                if (i_y !== -1) {
                    pFmt += fmt.substring(i_y, i_y + 4);
                }
                break;
            case i_d:
                pFmt += fmt.substring(i_d, i_d + 2);
                break;
            default:
                break;
        }
    }
    return pFmt;
}

export function convertDate(dateVal: string, dateFormat: string, destDateFormat: string) {
    if (dateVal === "" || dateVal === undefined || dateFormat === undefined || destDateFormat === undefined) {
        return dateVal;
    }
    let beginYear = dateFormat.indexOf("yyyy");
    let beginMonth = dateFormat.indexOf("MM");
    if (beginMonth === -1) {
        beginMonth = dateFormat.indexOf("mm");
    }
    let beginDay = dateFormat.indexOf("dd");
    let mVal = dateVal.substr(beginMonth, 2);
    let dVal = dateVal.substr(beginDay, 2);
    let result;
    if (beginYear === -1) {
        result = destDateFormat.includes("yyyy") ? destDateFormat.replace("yyyy", new Date().getFullYear().toString()) : destDateFormat
    } else {
        result = destDateFormat.includes("yyyy") ? destDateFormat.replace("yyyy", dateVal.substr(beginYear, 4)) : destDateFormat;
    }

    return result.replace("MM", mVal).replace("mm", mVal).replace("dd", dVal);
}

const DEFAULT_NUMBER_FORMATTER = new Intl.NumberFormat(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
export function formatNumber(value: number, formatOption?: Intl.NumberFormatOptions) {
    if (formatOption !== undefined) {
        return new Intl.NumberFormat(navigator.language, formatOption).format(value === undefined ? 0 : value);
    }
    return DEFAULT_NUMBER_FORMATTER.format(value === undefined ? 0 : value);
}

export interface AjaxOption extends AxiosRequestConfig {
    showMask?: boolean;
    /**
     * The css selector of the element that mask displayed to, 
     */
    maskOn?: string;
    success?: (res: any) => void;
    error?: (err: any) => void;
    callback?: (res: any) => void;
    fail?: (data: any, message: string) => void;
    /**
     * indicate whether the page need to be redirect to login page when getting 401 error, default is true.
     */
    autoRedirectToLogin?: boolean;
}

export function downloadFile(url: string, showMask?: boolean, errorHandler?: (message: string) => void) {
    if (Config.DOWNLOAD_FILE_CREATE_OBJECT_URL) {
        ajax({
            url: url,
            method: 'get',
            responseType: 'blob',
            showMask: showMask,
            headers: { 'Accept': 'application/octet-stream' },
            success: res => {
                debugger
                let fileName = res.headers['content-disposition'].split('=')[1].replaceAll("\"", "");
                let blob = new Blob([res.data], { type: res.data.type })
                let downloadElement = document.createElement('a')
                let href = window.URL.createObjectURL(blob); //create download link
                downloadElement.href = href;
                downloadElement.download = fileName; //file name
                document.body.appendChild(downloadElement);
                downloadElement.click(); //click download
                document.body.removeChild(downloadElement); //remove element once downloaded
                window.URL.revokeObjectURL(href); //release blob object.

            },
            error: error => {
                if (error.response === undefined) {
                    return;
                }
                if (error.response.status === 401) {
                    window.location.href = getContextPath() + "/login?from=" + encodeURIComponent(window.location.pathname + window.location.search);
                }
                let reader = new FileReader();
                reader.onload = (e: any) => {
                    let message = "";
                    if (e.target.result.indexOf('result') !== -1) {
                        let res = JSON.parse(e.target.result);
                        if (res.result !== undefined) {
                            message = Config.SHOW_SERVER_DEBUG === true ? res.message : res.data;
                        }
                    }
                    if (errorHandler !== undefined) {
                        errorHandler(message);
                    } else {
                        showErrorModal(error.response ? error.response.status + " " + error.response.statusText : error,
                            error.response === undefined ? "Error occured when sending request to " + url :
                                "Error occured when sending request to " + url + " <br/>" + message);
                    }
                };
                reader.readAsText(error.response.data);
            }
        });
    } else {
        let downloadElement = document.createElement('a');
        downloadElement.href = url;
        //downloadElement.target = "_blank";
        document.body.appendChild(downloadElement);
        downloadElement.click(); //click download
        document.body.removeChild(downloadElement); //remove element once downloaded
    }
}

export interface UploadFileConfig {
    url: string;
    form: any;
    success?: (res: any) => void;
    error?: (error: any) => void;
    fail?: (msg: string) => void;
    showMask?: boolean;
}

export function uploadFile(config: UploadFileConfig) {
    if (config.showMask !== false) {
        loadMask({ action: 'show' });
    }
    let formData = new FormData(config.form);
    let request = axios.post(Config.SERVICE + config.url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    request.then((res: any) => {
        if (config.showMask !== false) {
            loadMask({ action: 'hide' });
        }
        if (res.data.result === true) {
            if (config.success !== undefined) {
                config.success(res.data.data);
            }
        } else {
            if (config.fail !== undefined) {
                config.fail(res.data.message);
            }
        }
    });
    if (config.error !== undefined) {
        request.catch(error => {
            if (error.response === undefined) {
                if (config.showMask !== false) {
                    loadMask({ action: 'hide' });
                }
                return;
            }
            if (error.response.status === 401) {
                window.location.href = getContextPath() + "/login?from=" + encodeURIComponent(window.location.pathname + window.location.search);
                return;
            }
            if (config.error !== undefined) {
                config.error(error);
            }
            if (config.showMask !== false) {
                loadMask({ action: 'hide' });
            }
        });
    } else {
        request.catch((error: any) => {
            if (error.response === undefined) {
                if (config.showMask !== false) {
                    loadMask({ action: 'hide' });
                }
                return;
            }
            if (error.response.status === 401) {
                window.location.href = getContextPath() + "/login?from=" + encodeURIComponent(window.location.pathname + window.location.search);
            }
            loadMask({ action: 'hide' });
            showErrorModal(error.response ? error.response.status + " " + error.response.statusText : error,
                error.response === undefined ? "Error occured when sending request to " + config.url :
                    "Error occured when sending request to " + config.url + " <br/>" + (Config.SHOW_SERVER_DEBUG === false ? error.response.data.message : error.response.data.data)
            );
        });
    }
}

/**
 * Merge objects fields
 * @param objs 
 */
export function mergeObject(...objs: Array<any>) {
    let result: any = {};
    objs.forEach((obj: any) => {
        result = { ...result, ...obj };
    });
    return result;
}

export function isMobileDevice() {
    let userAgentInfo = navigator.userAgent;
    let agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
    for (let v = 0; v < agents.length; v++) {
        if (userAgentInfo.includes(agents[v])) {
            return true;
        }
    }
    return false;
}

export function toggle(cssSelector: string, onShow?: (e: any) => void, onHide?: (e: any) => void) {
    $(cssSelector).off("show.bs.collapse");
    $(cssSelector).off("hide.bs.collapse");
    $(cssSelector).on("show.bs.collapse", onShow);
    $(cssSelector).on("hide.bs.collapse", onHide);
    $(cssSelector).collapse('toggle');
}

export function removeClass(cssSelector: string, className: string) {
    $(cssSelector).removeClass(className);
}

export function addClass(cssSelector: string, className: string) {
    $(cssSelector).addClass(className);
}

export function ajax(option: AjaxOption): CancelTokenSource {
    let uuid = generateUUID();
    const source = axios.CancelToken.source();
    let isShowMask = option.showMask === undefined || option.showMask === true ? true : false;
    if (isShowMask) {
        if (option.maskOn) {
            showMask(option.maskOn, { action: 'show', idPrefix: uuid });
        } else {
            loadMask({ action: 'show', idPrefix: uuid });
        }
    }
    if (!option) {
        throw new Error("ajax option must be specified.");
    }
    if (!option.url) {
        throw new Error("ajax url must be specified.");
    }
    
    
    let token = getFromStorage('_csrf');
    let CSRF_TOKEN = "";
    let CSRF_HEADER = "X-CSRF-TOKEN";
    if(token !== null && token!== undefined){
        CSRF_TOKEN = token.token;
        CSRF_HEADER = token.headerName;
    }
    option.url = Config.SERVICE + option.url;
    if (option.headers === undefined) {
        option.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            [CSRF_HEADER]: CSRF_TOKEN
        };
    }
    if (!option.method) {
        option.method = "get";
    }
    option.cancelToken = source.token;
    axios(option).then(response => {
        if (option.success !== undefined) {
            if (isShowMask) {
                if (option.maskOn) {
                    showMask(option.maskOn, { action: 'hide', idPrefix: uuid });
                } else {
                    loadMask({ action: 'hide', idPrefix: uuid });
                }
            }
            if (response.data instanceof Blob) {
                if (option.success !== undefined) {
                    option.success(response);
                }
            } else {
                if (response.data.result === true) {
                    if (option.success !== undefined) {
                        option.success(response.data.data);
                    }
                } else {
                    // if there is no fail hanlder defined, then show default error modal. 
                    if (option.fail !== undefined) {
                        option.fail(response.data.data, response.data.message);
                    } else {
                        showErrorModal(response.data.message);
                    }
                }
            }
            if (option.callback !== undefined) {
                option.callback(response);
            }
        }
    }).catch((error => {
        if (isShowMask) {
            if (option.maskOn) {
                showMask(option.maskOn, { action: 'hide', idPrefix: uuid });
            } else {
                loadMask({ action: 'hide', idPrefix: uuid });
            }
        }
        if (error.response === undefined) {
            if (isShowMask) {
                if (option.maskOn) {
                    showMask(option.maskOn, { action: 'hide', idPrefix: uuid });
                } else {
                    loadMask({ action: 'hide', idPrefix: uuid });
                }
            }
            return;
        }

        if (option.autoRedirectToLogin !== false) {
            if (error.response.status === 401) {
                window.history.pushState({ isRedirect: true }, "", getContextPath() + "/login");
                window.location.reload();
            }
        }

        if (option.error !== undefined) {
            option.error(error);
        } else {
            if (error.response.status !== 401)
            showErrorModal(error.response ? error.response.status + " " + error.response.statusText : error,
                    error.response === undefined ? "Error occured when sending request to " + option.url :
                        "Error occured when sending request to " + option.url + " <br/>" + (Config.SHOW_SERVER_DEBUG === false ? error.response.data.message : error.response.data.data)
            );
        }
        if (option.callback !== undefined) {
            option.callback(error);
        }
    }));
    return source;
}

export const removeFromArray = (array: Array<any>, index: number): Array<any> => {
    let result = array.slice(0, index);
    if (index + 1 < array.length) {
        for (let a of array.slice(index + 1)) {
            result.push(a);
        }
    }
    return result;
}

export interface validateProps {
    /**
     * Return globalized text of the specific message key.
     */
    getGlobalizedText: (messageKey: string) => string,
    /**
     * Show page level error message
     */
    showMessage: (type: MessageType, message: string | JSX.Element) => void,
    /**
     * clear page level error message
     */
    clearMessage: () => void,
}

/**
 * Deselect the checkbox 
 * @param target HTMLElement
 */
export const deSelectCheckbox = (target: any) => {
    check(target, false)
}

/**
 * Select the checkbox
 * @param target HTMLElement
 */
export const selectCheckbox = (target: any) => {
    check(target, true)
}

export const check = (target: any, checked: boolean) => {
    target.checked = checked;
}

/**
 * Return checked of not of the target element.
 * @param target 
 */
export const getChecked = (target: any) => {
    return target === null ? false : target.checked
}

export const callAjaxTest = (option: AjaxValidationObjectForYup | AjaxValidationObject, resolve: any) => {
    let ajaxOption: any = { url: option.url, showMask: false };

    if (option.params !== undefined) {
        ajaxOption.params = option.params;
    }
    if (option.headers !== undefined) {
        ajaxOption.headers = option.headers;
    }
    if (option.data !== undefined) {
        ajaxOption.data = option.data;
    }
    ajax({
        ...ajaxOption,
        ...{
            success: (data: any) => {
                if (option.handleResult !== undefined && data !== undefined)
                    resolve(option.handleResult(data));
                else
                    resolve(true);
            }, error: (error) => {
                if (option.handleError) {
                    resolve(option.handleError(error));
                } else {
                    resolve(false);
                }
            }, fail: (res) => {
                if (option.handleFail) {
                    resolve(option.handleFail(res));
                } else {
                    resolve(false);
                }
            }
        }
    });
}

export interface AjaxValidationObject {
    url: string
    params?: any
    data?: any
    headers?: any
    handleResult?: (res: any) => ValidationResult | true
    handleError?: (error: any) => ValidationResult
    handleFail?: (error: any) => ValidationResult
}

/**
 * Ajax test for customized validation function
 * @param option 
 */
export const ajaxTest = (option: AjaxValidationObject): Promise<ValidationResult> => {
    if (option.url === undefined) {
        throw new Error("url must be defined for ajax validation.");
    }
    return new Promise<ValidationResult>((resolve) => {
        callAjaxTest(option, resolve);
    });

}
export interface AjaxValidationObjectForYup {
    url: string
    params?: any
    data?: any
    headers?: any
    handleResult?: (res: any) => boolean
    handleError?: (error: any) => boolean | yup.ValidationError
    handleFail?: (error: any) => boolean | yup.ValidationError
}

export const decodeAmpersand = (input: string): string => {
    let output = input;
    if (!isEmptyStr(output)) {
        if (output.includes("&amp;")) {
            output = output.replaceAll("&amp;", "&")
        }
        if (output.includes("&lt;")) {
            output = output.replaceAll("&lt;", ">")
        }
        if (output.includes("&gt;")) {
            output = output.replaceAll("&gt;", ">")
        }
        if (output.includes("&nbsp;")) {
            output = output.replaceAll("&nbsp;", " ")
        }
    }
    return output;
}

/**
 * Ajax test for Yup.
 * @param option 
 */
export const ajaxTestForYup = (option: AjaxValidationObjectForYup): Promise<boolean | ValidationError> => {
    if (option.url === undefined) {
        throw new Error("url must be defined for ajax validation.");
    }
    return new Promise<boolean | ValidationError>((resolve) => {
        callAjaxTest(option, resolve);
    });

}

export const insertIntoArray = (array: Array<any>, obj: any, pos: number) => {
    let result: Array<any> = [];
    array.forEach((one: any, index: number) => {
        if (index === pos) {
            result.push(obj);
        }
        result.push(one);
    });
    array = result;
    return array;
}

export const isEmptyObject = (obj: any) => {
    return obj === null || obj === undefined || Object.keys(obj).length === 0;
}

export const compareObject = (obj1: any, obj2: any, ...fieldsIgnored: Array<string>) => {
    if (isEmptyObject(obj1) && isEmptyObject(obj2)) {
        return true;
    }

    if ((!isEmptyObject(obj1) && isEmptyObject(obj2)) || (isEmptyObject(obj1) && !isEmptyObject(obj2))) {
        return false;
    }

    if (typeof (obj1) !== typeof (obj2)) {
        return false;
    }
    if (typeof (obj1) === 'string' || typeof (obj1) === 'number' || typeof (obj1) === 'boolean') {
        return obj1 === obj2;
    }

    let newObj1 = copyObjectExcept(obj1, false, ...fieldsIgnored);
    let newObj2 = copyObjectExcept(obj2, false, ...fieldsIgnored);
    return JSON.stringify(newObj1) === JSON.stringify(newObj2);
}

export class ValidationResult {
    field: string
    message: string
    constructor(field: string, message: string) {
        this.field = field;
        this.message = message;
    }
}

export const validate = (schema: any | Function, values: any, viewProps: validateProps, pageMessageKey?: string, option?: any): Promise<any> => {
    function isAllFieldsHandled(res1: any, res2: any) {
        for (let f1 in res1) {
            if (res2[f1] === undefined) {
                return false;
            }
        }
        return true;
    }
    if (option === undefined) {
        option = { abortEarly: false };
    }
    let pageMessage: string = pageMessageKey === undefined ? viewProps.getGlobalizedText("homepage.errorFields") : viewProps.getGlobalizedText(pageMessageKey);
    //  native Promise not working for IE 11. need to invole ES5 Promise.
    let result = new Promise(resolve => {
        if (typeof schema === "object") {
            // assuming that schema is a yup schema object.
            let hasError = false;
            if (option === undefined) {
                option = { abortEarly: false };
            }
            return schema.validate(values, option).catch((err: any) => {
                let error: any = {};
                for (let innerErr of err.inner) {
                    error[innerErr.path] = innerErr.message;
                }
                hasError = true;
                viewProps.showMessage("error", pageMessage);
                resolve(error);
            }).finally(() => {
                if (hasError === false) {
                    viewProps.clearMessage();
                    resolve({});
                }
            });
        } else {
            let validationResult = schema(values);

            let error: any = {};
            let handledField: any = {};
            if (validationResult === undefined || isEmptyObject(validationResult)) {
                viewProps.clearMessage();
                resolve(error);
            }
            new Promise<any>((resolve1) => {
                for (let prop in validationResult) {
                    if (typeof (validationResult[prop]) === 'string') {
                        error[prop] = validationResult[prop];
                        handledField[prop] = true;
                        if (isAllFieldsHandled(validationResult, handledField)) {
                            viewProps.showMessage("error", pageMessage);
                            resolve(error);
                        }
                    } else {
                        (validationResult[prop] as Promise<ValidationResult | true>)
                            .then((val: ValidationResult | boolean) => {
                                if (typeof (val) === 'boolean') {
                                    handledField[prop] = true;
                                } else {
                                    error[val.field] = val.message;
                                    handledField[prop] = true;
                                }
                                if (isAllFieldsHandled(validationResult, handledField)) {
                                    if (!isEmptyObject(error)) {
                                        viewProps.showMessage("error", pageMessage);
                                    }
                                    resolve(error);
                                }
                            })
                    }
                }
            }).finally(() => {
                viewProps.showMessage("error", pageMessage);
                resolve(error);
            });

        }
    });
    return result.then((error) => {
        return error;
    });
}

export const trim = (str: string | undefined): string => {
    return str === undefined ? '' : str.trim();
}

export const getGlobalizedText = (globalization: any, messageKey: string) => {
    return globalization === null || globalization[messageKey] === undefined ? messageKey : globalization[messageKey];
}

let w: any = window;
export const win = w;
export const $: any = w.$;

export const log = (tag: string, msg?: any, ...args: any[]) => {
    if (Config.ENABLE_LOG) {
        console.log(`[${tag}] ${msg}`, args);
    }
}

/**
*  Save the data to the storage
 * @param name 
 * @param data 
 * @param isSession 
 */
export const saveToStorage = (name: string, data: any, isSession?: boolean) => {
    let storage = (isSession === true || isSession === undefined) ? sessionStorage : localStorage;
    let jsonData = JSON.stringify(data);
    storage.setItem(name, jsonData);
    log("Utils.Save", `Save ${jsonData} to ${(isSession === true || isSession === undefined) === true ? "session scope" : "application scope"}`);
}

/**
 * Load data from storage
 * @param name 
 * @param data 
 * @param isSession 
 */
export const getFromStorage = (name: string, isSession?: boolean): any | null => {
    let storage = (isSession === true || isSession === undefined) ? sessionStorage : localStorage;
    let storedStr = storage.getItem(name);
    log("Utils.fetch", `Fetch {} from {}`, storedStr, isSession ? "session scope" : "application scope");
    if (storedStr !== 'undefined' && storedStr !== null && storedStr !== 'null') {
        return JSON.parse(storedStr);
    } else {
        return null;
    }
}

/**
 * 
 * @param name remove the data from storage
 * @param isSession 
 */
export const removeFromStorage = (name: string, isSession?: boolean) => {
    let storage = (isSession === true || isSession === undefined) ? sessionStorage : localStorage;
    storage.removeItem(name);
}

const history = createBrowserHistory();

export const getPathName = () => {
    return history.location.pathname;
}

export const getHost = () => {
    return window.location.host;
}

/**
 * Copy a new object base on the first argument exception the fields identified on the second argument
 */
export const copyObjectExcept = (object: any, isFuzzyMatch: boolean, ...fields: Array<string>) => {
    let result: any = {};
    for (let field in object) {
        if (isFuzzyMatch) {
            let isMatched = false;
            for (let f of fields) {
                if (field.startsWith(f)) {
                    isMatched = true;
                    break;
                }
            }
            if (!isMatched) {
                result[field] = object[field];
            }
        } else {
            if (fields.indexOf(field) === -1) {
                result[field] = object[field];
            }
        }
    }
    return result;
}

export const getContextPath = (): string => {
    return process.env.PUBLIC_URL;
}

export function removeUndefined(array: Array<any>): Array<any> {
    let result = new Array<any>();
    for (let a of array) {
        if (a !== undefined && a.name !== undefined)
            result.push(a);
    }
    return result;
}

export function resetForm(formProps: FormikProps<any>, values?: any) {
    if (values === undefined || values === null) {
        formProps.resetForm();
    } else {
        formProps.resetForm({ values: { ...formProps.initialValues, ...values } });
    }
}
export function movePageToTop() {
    window.scrollTo({ left: 0, top: 0 });
}

/**
 * Tip messaeg register, used to show message on specified component.
* @param tipNodeCssSelector the node of css selector which is used to show Tip message on it
* @param msg message content which will be shown in tip 
* @param passedTemplate optional, used for message format
*/
export function showTooltip(tipNodeCssSelector: string, msg: string, passedTemplate?: string, parentNode?: any) {
    let tooltipTemplate = '<div class="tooltip" role="tooltip"><div class="tooltip-arrow gwp-tooltip-arrow1"></div><div class="tooltip-arrow gwp-tooltip-arrow2"></div><div class="tooltip-inner"></div></div>';

    if (passedTemplate !== undefined) {
        tooltipTemplate = passedTemplate;
    }

    let tip = (parentNode ? $(parentNode).find(tipNodeCssSelector) : $(tipNodeCssSelector)).attr('data-original-title')
    if (typeof (tip) == "undefined" || tip === '') {
        (parentNode ? $(parentNode).find(tipNodeCssSelector) : $(tipNodeCssSelector)).tooltip({ html: true, placement: 'bottom', trigger: 'click', template: tooltipTemplate });
        (parentNode ? $(parentNode).find(tipNodeCssSelector) : $(tipNodeCssSelector)).attr('data-original-title', msg);
        (parentNode ? $(parentNode).find(tipNodeCssSelector) : $(tipNodeCssSelector)).tooltip('show');
        (parentNode ? $(parentNode).find(tipNodeCssSelector) : $(tipNodeCssSelector)).mouseleave(function () {
            (parentNode ? $(parentNode).find(tipNodeCssSelector) : $(tipNodeCssSelector)).tooltip('hide');
        })
    }
}

/**
 * Remove left zeros for number
 * @param val 
 */
export function removeLeftZeroForNumber(val: string) {
    if (!/^\d*$/.test(val)) {
        // if it isn't a number, then return the value directly.
        return val;
    }
    return val.replace(/^0*/, "")
}

export function removeLeftZero(val: string) {
    return val.replace(/\b(0+)/gi, "")
}

export const generateRefreshSeed = (): number => {
    let seed = new Date().getTime();
    return seed;
}

export function leftAlignWithZero(num: string, len: number): string {
    if (num.length > len) {
        return num;
    } else if (num.length === len) {
        return num;
    } else {
        let res: string = num;
        for (let i = 0; i < len - num.length; i++) {

            res = "0" + res;
        }
        return res;
    }
}

// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
export function copyToClipboard(text: string) {
    if (w.clipboardData && w.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return w.clipboardData.setData("Text", text);

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}

// Prevent Bootstrap dialog from blocking focusin
export const preventBootStrapBlockingFocusinForTinyMCE = (e: any) => {
    if ($(e.target).closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
        e.stopImmediatePropagation();
    }
}

export const offPreventBootStrapBlockingFocusin = () => {
    $(document).off('focusin', preventBootStrapBlockingFocusinForTinyMCE);
}

export const onPreventBootStrapBlockingFocusin = () => {
    $(document).on('focusin', preventBootStrapBlockingFocusinForTinyMCE);
}
export const removeModalFadeIn = () => {
    // try to remove the modal backgroup if existed.
    $(".modal-backdrop.fade.in").remove();
    $("body").css("padding-right", "");
    $("body").removeClass("modal-open");
}
export const hideNavBarForSmallScreen = () => {
    $("#navbar").hasClass("collapse in");
    $("#navbar").removeClass("in");
}
export const adjustMarginTop = (headerCssSelector: string, cssSelector: string) => {
    setTimeout(() => {
        $(cssSelector).css("margin-top", $(headerCssSelector).height())
        $(cssSelector).show();
    }, 50);
}
/**
 * set select range of the input box.
 * @param input 
 * @param selectionStart 
 * @param selectionEnd 
 */
export function setSelectionRange(input: any, selectionStart: number, selectionEnd: number) {
    if (input.setSelectionRange) {
        input.focus();
        input.setSelectionRange(selectionStart, selectionEnd);
    }
    else if (input.createTextRange) {
        let range = input.createTextRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
}

export function formatDateTime(datetime: string, originalFormat: string, targetFormat: string): string {
    return moment(datetime, originalFormat).format(targetFormat)
}

export const validateEmptyCheck = (item:any) =>{
    if(item !== '' && item !== undefined && item !== null){
        return true;
    }
    return false;
}