import React, { useContext, useEffect, useRef, useState} from 'react';
import { UserContext } from '../../../../shared/authentication';
import { SecurityContext } from '../../../../context/securityContext';
import { Link,NotificationIcon } from '../../../../shared/components';
import GlobalizedText from '../../../../shared/globalization';
import { $, ajax, getFromStorage, win } from '../../../../shared/utils';
import logo from '../medmutual/images/MedMutualLogo.png';
import Menu from './menu';
import '../css/header.css';

function LoginButton(props: any) {
    if (props.user !== null) {
        return <></>;
    }
    return <Link to={"/login"} forceRefresh={true} className="btn-utility btn-uti-l"><GlobalizedText message="common.lbl.login" /></Link>;
}
function LogoutButton(props: any) {
	const config = getFromStorage('gwp.config', true);
    if (props.user === null) {
        return <></>;
    }
    if(config.openIdConnect === false){
        return <Link to="/login" onClick={() => {
            props.userService.signout();
            if(config.openIdConnect === false){
                updateRegButtonState((showButton: boolean) => {
                    props.setShowRegistrationButton(showButton);
                });
            }
        }} className="btn-utility btn-uti-l"><GlobalizedText message="common.lbl.logout" /></Link>;
    }else{
        return <a href="javascript:void(0);" onClick={() => {
            props.userService.signout();
        }} className="btn-utility btn-uti-l"><GlobalizedText message="common.lbl.logout" /></a>;
    }
}
function RegistrationButton(props: any) {
    if (props.user !== null) {
        return <></>;
    }
    return <Link to={"/registration"} forceRefresh={true} className="btn-utility btn-uti-l"><span><GlobalizedText message="login.lbl.signup" /></span></Link>;
}

function Name(props: any) {
    const { setUserAuth } = useContext(SecurityContext);
    if (props.user === null) {
        return <></>;
    }
    setUserAuth(props.user);
    return <Link to="/myprofile" className="u-split">{props.user.firstName} {props.user.lastName}</Link>
}

function Inquiry(props: any) {
    const { showEnquiryForm, setShowEnquiryForm } = useContext(SecurityContext);
    if (props.user === null) {
        return <></>;
    }
    return props.user.needSubmitInq && <a onClick={() => setShowEnquiryForm(!showEnquiryForm)}><GlobalizedText message="common.lbl.submitInq" /></a>
}

function Search(props: any) {
    return <></>
}

export function adjustHeaderHeight() {
    if ($(win).width() < 768) {
        $(".gwp-portlet-container").css("margin-top", ($("header").height() + "px"));
        $("#root>.gwp-page-content").css("margin-top", ($("header").height() + "px"));
    } else {
        $(".gwp-portlet-container").css("margin-top", "");
        $("#root>.gwp-page-content").css("margin-top", "");
    }
}

export function autoAdjustHeaderHeight() {
    $(win).on("resize", (e: any) => {
        adjustHeaderHeight();
    })
}

export function updateRegButtonState(callback: (showButton: boolean) => void) {
    ajax({
        url: '/api/registrationSingle/showRegistrationButton',
        success: (viewConfig) => {
            callback(viewConfig);
        }
    });
}

export function Header(props: any) {
   
	const blockUserTypes = ['13','02'];
    const timer = useRef<any>(undefined);
    const [showRegistrationButton, setShowRegistrationButton] = useState<boolean>(false);
    const [showLogoutButton, setShowLogoutButton] = useState<boolean>(false);

    useEffect(() => {
        if (timer.current === undefined) {
            timer.current = win.setInterval(() => {
                if ($("header").height() !== 0) {
                    adjustHeaderHeight();
                    if (timer.current !== undefined) {
                        win.clearInterval(timer.current);
                    }
                }
            }, 10);
            autoAdjustHeaderHeight();
            updateRegButtonState((showBtn: boolean) => {
                setShowRegistrationButton(showBtn);
            });
        }
    })

    useEffect(() => {  
	    setTimeout(()=>{
			setShowLogoutButton(true);
		},2000);		
    }) 

    return (
        <header className="gwp-header">
        <div className="gwp-header-logobar">
            <div className="container ">
                <div className="navbar-uptop">
                    <div className="nav-container">
                        <div className="navbar-logo">
                            <a rel="noopener noreferrer" ><img src={logo} /></a>
                        </div>
                        <div className="navbar-utility">
                        <UserContext.Consumer>
                                {user => (
                                    <>

                                       {user && (!blockUserTypes.includes(user.userTypeRegCode)) && <Inquiry user={user} />} 
                                        <Name user={user} />
                                        {user && <NotificationIcon user={user} />}
                                    </>
                                )}
                            </UserContext.Consumer>
                            <UserContext.Consumer>
                                {user => (
                                    <React.Fragment>
                                        {
                                            /*<SSOButton user={user} />*/
                                        }
                                        <LoginButton user={user} userService={props.userService} />
                                         { showLogoutButton && <LogoutButton user={user} userService={props.userService} />}
                                        {
                                            showRegistrationButton === true && <>
                                                <RegistrationButton user={user} />
                                            </>}
                                    </React.Fragment>
                                )}
                            </UserContext.Consumer>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="container gwp-header-menubar">
            <div className="gwp-header-menubar-header hidden-sm hidden-md hidden-lg">
                <div className="row">
                    <div className="col-xs-9">
                        <Search />
                    </div>


                    <div className="col-xs-3">
                        <button type="button" className="navbar-toggle collapsed" onClick={() => { $("#navbar").collapse('toggle') }} aria-expanded="false" aria-controls="navbar">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>
                </div>
            </div>

            <div id="navbar" className="collapse navbar-collapse gwp-header-menubar-body">
                <div className="row">
                    <Menu breadcrumb={props.breadcrumb}  isShowHome={props.userService.rootComponent.state.isShowHome} />
                    <div className="col-md-4 col-sm-5 hidden-xs">
                        <Search />
                    </div>
                </div>
            </div>
        </div>
    </header>
    );
}
