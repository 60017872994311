
import * as yup from 'yup';
import { INVALID_MESSAGES, YupSchema } from '../yupschema';
import PageProps from '../interfaces/pageProps';
import { isEmptyStr, trim } from '../utils';
import { FORMAT_MAPPER } from './lib/govtidinput';
import { VALIDATION_REGEX } from '../regex';

export let byPageOne06 = (props:PageProps,values:any) => {
    let res: any = {};
	
	if (isEmptyStr(values.firstName)) {
		res = { ...res, ...{ firstName: INVALID_MESSAGES.FIRSTNAME_IS_EMPTY } }
    }
    if (isEmptyStr(values.lastName)) {
        res = { ...res, ...{ lastName: INVALID_MESSAGES.LASTNAME_IS_EMPTY } }
    }
    if (!isEmptyStr(values.firstName) && values.firstName.trim().length > 20) {
        res = { ...res, ...{ firstName: INVALID_MESSAGES.FIRSTNAME_IS_TOO_LONG } }
    }
    if (!isEmptyStr(values.lastName) && values.lastName.trim().length > 40) {
        res = { ...res, ...{ lastName: INVALID_MESSAGES.LASTNAME_IS_TOO_LONG } }
    }
    if (!isEmptyStr(values.email) && VALIDATION_REGEX.EMAIL.test(trim(values.email)) === false) {
        res = { ...res, ...{ email: INVALID_MESSAGES.EMAIL_IS_INVALID } };
    }    
    
    if(props.regConfig.admin){
        if (isEmptyStr(values.birthDate) && props.regConfig.agentRegRequiredDOB) {           
	        let requiredMsg = props.getGlobalizedText(INVALID_MESSAGES.BIRTHDATE_IS_EMPTY) + " " + props.regConfig.dateformat.toLowerCase() + ".";
	        
	        res = { ...res, ...{ birthDate: requiredMsg } }
        }
        if(props.regConfig.agentRegRequiredDOB){
			if (!isEmptyStr(values.birthDate) && !isValidDate(values.birthDate)) {
		        let requiredMsg = props.getGlobalizedText(INVALID_MESSAGES.BIRTHDATE_IS_INVALID) + " " + props.regConfig.dateformat.toLowerCase() + ".";
		        res = { ...res, ...{ birthDate: requiredMsg} }
		    }
	    }
    } else{
        if (isEmptyStr(values.birthDate) && (props.regConfig.agentRegRequiredSelfDOB && props.regConfig.agentRegRequiredDOB)) {
            let requiredMsg = props.getGlobalizedText(INVALID_MESSAGES.BIRTHDATE_IS_EMPTY) + " " + props.regConfig.dateformat.toLowerCase() + ".";
            
            res = { ...res, ...{ birthDate: requiredMsg } }
        }
        if(props.regConfig.agentRegRequiredSelfDOB && props.regConfig.agentRegRequiredDOB){
			if (!isEmptyStr(values.birthDate) && !isValidDate(values.birthDate)) {
		        let requiredMsg = props.getGlobalizedText(INVALID_MESSAGES.BIRTHDATE_IS_INVALID) + " " + props.regConfig.dateformat.toLowerCase() + ".";
		        res = { ...res, ...{ birthDate: requiredMsg} }
		    }
	    }
    }
    
    
	if (isEmptyStr(values.govtID)) {
        res = { ...res, ...{ govtID: INVALID_MESSAGES.GOVT_ID_IS_EMPTY } }
    }
	if(!isEmptyStr(values.govtID)){
        const govID = values.govtID;
    	let format = FORMAT_MAPPER['SSN'];
        if(isNaN(govID) || govID.includes("-") || govID.length < 9 ){
            res = { ...res, ...{ govtID:  props.getGlobalizedText(INVALID_MESSAGES.GOVT_ID_IS_INVALID) } };
        }                      
    }
    if (isEmptyStr(values.agentNumber)) {
        res = { ...res, ...{ agentNumber: INVALID_MESSAGES.AGENT_NUMBER_IS_REQUIRED } }
    }
    return res;
}

function isValidDate(format:any) {
    if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(format)) {
      return false;
    }
    const parts = format.split('/').map((p:any) => parseInt(p, 10));
    parts[0] -= 1;
    const d = new Date(parts[2], parts[0], parts[1]);
    return d.getMonth() === parts[0] && d.getDate() === parts[1] && d.getFullYear() === parts[2];
  }