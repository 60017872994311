import React, { useState} from 'react';
import { ajax } from '../../../shared/utils';
import { Link } from './link';
import { BsFillBellFill } from "@react-icons/all-files/bs/BsFillBellFill";
import { UserTypeCode } from '../../constants/userTypeCode';

export const NotificationIcon = ({...props}) => {
	const [notify, setNotify] = useState(false);
	const [notifyCount, setNotifyCount] = useState(0);
	const unauthorizedUserType = [	UserTypeCode.AGENCY,
 									UserTypeCode.SECURITY_PORTAL_ADMIN,
								 	UserTypeCode.CONTENT_MANAGEMENT_ADMIN,
									UserTypeCode.CONTENT_APPROVER
								];
	if (props.user === undefined) {
		return <></>;
	}
	//As related to User Type Enum
	if (unauthorizedUserType.includes(props.user.userTypeRegCode)) {
		return <></>;
	}
	ajax({
		url: '/api/userInquiry/notifications',
		method: 'get',
		success: (response: any) => {
			const isAnyNotification = [];
			response.filter((item: any) => {
				if (item.notify) {
					return isAnyNotification.push({ messageId: item.messageId, notify: item.notify });
				}
			});
			setNotifyCount(isAnyNotification.length);

			if (isAnyNotification.length > 0) {
				setNotify(true);
			}

		}
	});
	if (notify) {
		return <><Link to="/UserInquiry" className="u-split u-social hidden-xs hidden-sm"><BsFillBellFill /><span className="u-notification">{notifyCount > 0 && notifyCount}</span></Link></>
	}
	return <></>;
}