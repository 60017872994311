import React, { useContext, useState, useEffect } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import './css/onlineforms.css';
import { AdminOnlineFormsComponent } from '../shared/onlineformfields';
import { ajax, downloadFile } from '../shared/utils';
import { UserContext } from '../shared/authentication';

interface onlineFormResponse {
    listOfReports: Array<any>,
    groupAccPath:Array<any>
}

export interface onlineforms {
    groupNumber: string
}

export const OnlineFormsComponent = withView((props: ViewComponentProps) => {
    const [onlineFormData, setOnlineFormData] = useState<any>([]);
    const userInfo = useContext(UserContext)    
    const canAccessOnlineForms = userInfo?.userTypeRegCode === '02' || userInfo?.userTypeRegCode === '13'

    const getOnlineFormData = (values: onlineforms) => {
    const onlineForms = { ...values }
        ajax({
            url: '/api/onlineForms/getAllForms',
            params: onlineForms,
            method: 'GET',
            success: (res: onlineFormResponse) => {
                setOnlineFormData(res)
            }
        })
    }

    useEffect(() => {
        if (canAccessOnlineForms) {
            const onlineforms = {
                groupNumber: ''
            };
            getOnlineFormData(onlineforms)

        }
    }, [canAccessOnlineForms])

    return (
        <React.Fragment>
            {!canAccessOnlineForms && <AdminOnlineFormsComponent props={props} getOnlineFormData={getOnlineFormData} />}

            <props.Col className='divider'></props.Col>
            
            <props.Condition><props.Information message="common.lbl.onlineformsmessage" /></props.Condition>
            <div className='container'>
                <div className="form-box">
                    {onlineFormData?.listOfReports?.length >0 && onlineFormData?.listOfReports?.map((onlineForm:any, index:any) => (
                        <div className="list-group" key={index}>
                            <a onClick={() => downloadFile(`/api/onlineForms/downloadOnlineForms/${onlineFormData.groupAccPath}${onlineForm}`)} className="list-onlineForm-item">{onlineForm}</a>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>

    )
})


