import React, { useContext, useState, useEffect} from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import './css/memberDocs.css';
import { ajax, downloadFile } from '../shared/utils';

export const MemberDocsComponent = withView((props: ViewComponentProps) => {
    const [memberDocsData, setMemberDocsData] = useState<Array<String>>([]);
    const companyCode: string = props.getParam("companyCode");
    const groupNumber: string = props.getParam("groupNumber");
    const accountNumber: number = parseInt(props.getParam("accountNumber"));
    const participantIDNumber: string = props.getParam("participantIDNumber");
    const params:any = { companyCode, groupNumber, accountNumber, participantIDNumber };

    useEffect(() => {
        if (memberDocsData.length === 0) {
            ajax({
                url: '/api/memberDocs/view',
                params,
                method: 'GET',
                success: (res: any) => {
                    setMemberDocsData(res.listOfDocs);
                }
            })
        }
    }, [memberDocsData.length]);

    return (
        <React.Fragment>
            {memberDocsData.length > 0 && <>
                    <props.Col className='divider'></props.Col>
                    <props.Condition><props.Information message="common.msg.memberDocs" /></props.Condition>
                    <div className='container'>
                        <div className="list-box">
                            {memberDocsData?.length > 0 && memberDocsData?.map((memberDocName:any, index:any) => (
                                <div className="list-doc" key={index}>
                                    <a onClick={() => downloadFile(`/api/memberDocs/downloadDoc/${memberDocName}/`)} className="list-doc-item">{memberDocName.split("|")[2]}</a>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            }
        </React.Fragment>
    )
})
