import React from "react"
import { useLocation } from "react-router-dom";
import { ViewComponentProps, withView } from "../shared/viewcomponent"

export const NoMatchComponent = withView(function (props: ViewComponentProps) {
    let location = useLocation();
    let title = (location.pathname === "/view/login")
                    ? props.getGlobalizedText("msg.login.userhasbennauthenticated")
                    : `Page not found ${location.pathname}`;
    props.setTitle(title);
    return <>
        {
            (location.pathname === "/view/login")
                ? <h3> {title} </h3>
                : <h3>Page not found <code>{location.pathname}</code></h3>
        }
    </>
});