import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import * as yup from 'yup';
import { TableCell, useTable, validateFormat } from "../../shared/components";
import { ChartColor } from "../../shared/components/lib/chart";
import GlobalizedText from "../../shared/globalization";
import { Message, useMessage } from "../../shared/message";
import { formatDateTime, isEmptyStr, saveToStorage, validate, ValidationResult } from "../../shared/utils";
import { ViewComponentProps, withView } from "../../shared/viewcomponent";
import { SearchSection } from "../searchsection"

export const SampleComponent = withView((props: ViewComponentProps) => {

    const pdfViewer = props.usePDFViewer({ ...props, ...{ url: '/api/claiminquiry/downloadEob/aaa' } });
    const [rowNumber, setRowNumber] = useState<Array<number>>();
    const [count, refresh] = useState<number>(0);
    const [count1, setCount1] = useState<number>(0)
    const { messageService, validateProps } = useMessage("personalContactInfo");
    const [groupList, setGroupList] = useState<Array<any>>();

    useEffect(() => {
        if (count1 > 0) {
            console.log(count1)
            props.showMessage('success', "TEST1")
            validateProps.showMessage('success', "TEST2")
            let gl:Array<any> = [];
            for(let i=0;i<60000;i++){
                gl.push({value:'group'+i,name:'Group '+i});
            }
            setGroupList(gl);
        }
    }, [count1])

    function increaseCount1() {
        setCount1(count1 + 1)
    }



    saveToStorage("aaa", [{ "julianYear": "18", "julianDay": "183", "sequenceNumber": "2", "suffix": "R", "accountNumber": 1070, "companyCode": "CO1 ", "groupNumber": "0000107501", "claimNumber": "181830002R", "patient": "Jane Hathaway", "memberID": "108", "doctorFacility": " Miscellaneous", "serviceDate": "2018/07/01", "total": "750.00", "paid": "580.00", "status": "Issued", "eobFlag": true, "adjustmentNumber": "0", "providerPaymentNumber": 1, "dependentSequenceNumber": 0 }, { "julianYear": "18", "julianDay": "183", "sequenceNumber": "1", "suffix": "R", "accountNumber": 1070, "companyCode": "CO1 ", "groupNumber": "0000107501", "claimNumber": "181830001R", "patient": "James Hathaway", "memberID": "108", "doctorFacility": "Johnathan Moore DDS", "serviceDate": "2018/06/29", "total": "200.00", "paid": "100.00", "status": "Issued", "eobFlag": true, "adjustmentNumber": "0", "providerPaymentNumber": 1, "dependentSequenceNumber": 1 }, { "julianYear": "18", "julianDay": "182", "sequenceNumber": "1", "suffix": "R", "accountNumber": 1070, "companyCode": "CO1 ", "groupNumber": "0000107501", "claimNumber": "181820001R", "patient": "James Hathaway", "memberID": "108", "doctorFacility": "James A Sexson", "serviceDate": "2018/06/25", "total": "750.00", "paid": "360.00", "status": "Issued", "eobFlag": true, "adjustmentNumber": "0", "providerPaymentNumber": 1, "dependentSequenceNumber": 1 }, { "julianYear": "18", "julianDay": "196", "sequenceNumber": "1", "suffix": "R", "accountNumber": 1070, "companyCode": "CO1 ", "groupNumber": "0000107501", "claimNumber": "181960001R", "patient": "Jane Hathaway", "memberID": "108", "doctorFacility": " Miscellaneous", "serviceDate": "2018/05/01 - 2018/06/01", "total": "250.00", "paid": "140.00", "status": "Issued", "eobFlag": true, "adjustmentNumber": "0", "providerPaymentNumber": 1, "dependentSequenceNumber": 0 }], true);

    const table = useMemo(() => {
        return props.useTable({
            select: {
                type: 'multiple',
                prefix: 'test1',
                onSelect: (e: any, rows: Array<number>) => {
                    setRowNumber(rows);
                }
            },
            children: [<thead key={0}>
                <tr>
                    <th className="gwp-dt-select-all" data-sorting={false}></th>
                    <th>Column1</th>
                    <th>Column2</th>
                </tr>
            </thead>,
            <tbody key={1}>
                <tr>
                    <td><input type="checkbox" name="test1" id="test11" /></td>
                    <td>data11</td>
                    <td>data12</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="test1" id="test12" /></td>
                    <td>data21</td>
                    <td>data22</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="test1" id="test13" /></td>
                    <td>data31</td>
                    <td>data32</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="test1" id="test14" /></td>
                    <td>data41</td>
                    <td>data42</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="test1" id="test15" /></td>
                    <td>data51</td>
                    <td>data52</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="test1" id="test16" /></td>
                    <td>data61</td>
                    <td>data62</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="test1" id="test17" /></td>
                    <td>data71</td>
                    <td>data72</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="test1" id="test18" /></td>
                    <td>data81</td>
                    <td>data82</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="test1" id="test19" /></td>
                    <td>data91</td>
                    <td>data92</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="test1" id="test110" /></td>
                    <td>data101</td>
                    <td>data102</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="test1" id="test111" /></td>
                    <td>data111</td>
                    <td>data112</td>
                </tr>
            </tbody>],
            id: 'testTable2',
            onRendered: () => {
                table.page(1)
            }
        })
    }, [count]);

    const tableComponent = useMemo(() => <table.Component />, [table]);

    const table3 = useTable({
        id: 'testTable3',
        delayAdjust: true,
        onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element | null) => {
            childNode?.querySelector("#test")?.addEventListener("click", () => { alert(1) });
            childNode?.querySelector("#test2")?.addEventListener("click", () => pdfViewer.showPDF("http://www.tushu007.com/ISBN-9787806137277.pdf"));
            childNode?.querySelector("#test3")?.addEventListener("click", () => pdfViewer.showPDF("http://www.tushu007.com/ISBN-9787806137277.pdf"));
        },
        option: { order: [[2, 'asc']] },
        childRows: 1,
        children: [
            <thead key={0}>
                <tr>
                    <th data-sortable={false} className="gwp-details-control"></th>
                    <th data-width="10%">Test Column</th>
                    <th data-type="numeric" data-width="10%">Test Column1</th>
                    <th data-width="10%">Test Column</th>
                    <th data-width="10%">Test Column1</th>
                    <th data-width="10%">Test Column</th>
                    <th data-width="10%">Test Column1</th>
                    <th data-width="10%">Test Column</th>
                    <th className="gwp-dt-child" data-width="0%"></th>
                </tr>

            </thead>,
            <tbody key={1}>
                <tr>
                    <td data-child={`
                `} className="gwp-details-control"></td>
                    <td data-name="name">123</td>
                    <td>3</td>
                    <td>123</td>
                    <td>456</td>
                    <td>1231234123412123412343412341</td>
                    <td>1231234123412123412343412341</td>
                    <td><props.LinkButton id="test3" onClick={() => pdfViewer.showPDF("http://www.tushu007.com/ISBN-9787806137277.pdf")}>show EOB</props.LinkButton></td>
                    <td className="gwp-dt-child">
                        <props.Button printVisible={false} id="test" onClick={() => { alert(1) }}>Test</props.Button>
                        <props.Button id="test2" onClick={() => pdfViewer.showPDF("http://www.tushu007.com/ISBN-9787806137277.pdf")}>Show PDF</props.Button>
                        <props.Row>
                            <props.Col xs={6} sm={6}>
                                <props.DL>
                                    <props.DLI title="Test" desc="Hello"></props.DLI>
                                </props.DL>
                            </props.Col>
                            <props.Col xs={6} sm={6}>
                                <props.Panel>
                                    <props.PanelHeading>
                                        Test Panel
                                </props.PanelHeading>
                                    <props.PanelBody>
                                        <props.Link to='/'><props.Icon type="view-all"></props.Icon>Home Page</props.Link>
                                    </props.PanelBody>
                                </props.Panel>
                            </props.Col>

                        </props.Row>
                    </td>
                </tr>

                <tr>
                    <td className="gwp-details-control"></td>
                    <td data-name="name">123</td>
                    <td>-1</td>
                    <td>123</td>
                    <td>456</td>
                    <td>123</td>
                    <td>456</td>
                    <td>123</td>
                    <td className="gwp-dt-child"></td>
                </tr>
                <tr>
                    <td className="gwp-details-control"></td>
                    <td data-name="name">123</td>
                    <td>5</td>
                    <td>123</td>
                    <td>456</td>
                    <td>123</td>
                    <td>456</td>
                    <td>123</td>
                    <td className="gwp-dt-child"></td>
                </tr>
                <tr>
                    <td data-gwp-child="no" className="gwp-no-details-control"></td>
                    <td data-name="name">123</td>
                    <td>8</td>
                    <td>123</td>
                    <td>456</td>
                    <td>123</td>
                    <td>456</td>
                    <td data-gwp-child-display="false">123</td>
                    <td className="gwp-dt-child"></td>
                </tr>
                <tr>
                    <td className="gwp-details-control"></td>
                    <td data-name="name">123</td>
                    <td>-2</td>
                    <td>123</td>
                    <td>456</td>
                    <td>123</td>
                    <td>456</td>
                    <td>123</td>
                    <td className="gwp-dt-child"></td>
                </tr>
                <tr>
                    <td className="gwp-details-control"></td>
                    <td data-name="name">123</td>
                    <td>-11</td>
                    <td>123</td>
                    <td>456</td>
                    <td>123</td>
                    <td>456</td>
                    <td>123</td>
                    <td className="gwp-dt-child"></td>
                </tr>
            </tbody >
        ]

    });

    const captcha = props.useCaptcha("securityCode");

    return <>
        <Formik initialValues={{ govtID: '123123123' }}
            enableReinitialize={true}
            // validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={() => {
                alert("TEST1")
            }}
        >
            {formProps => {
                // searchSectionForm.current = formProps
                return <Form id="searchSection2">
                    <props.AutoFormatControl id="govtID" formatType="SSN" valueWithFormat={false} name="govtID" label="common.lbl.govID" required={false}></props.AutoFormatControl>
                </Form>
            }}
        </Formik>

            <props.SelectControl name="dayofweek" defaultOption="Test">
                <option value="0">Sunday</option>
                <option value="1">Monday</option>
                <option value="2">Wendensday</option>
                <option value="99">Test</option>
            </props.SelectControl>

        <props.Button onClick={() => {
            increaseCount1()
        }}>ANGLITEST CLICK</props.Button>
        <props.Row>
            <Message messageService={messageService}></Message>
        </props.Row>
        <Formik initialValues={{
            govID: '233345567',
            name: '',
            firstName: '',
            lastName: '',
            orgName: '',
            groupNumber:'',
            nameType: '',
            firstName1: '',
            securityCode: '',
            confirmation: true,
            birthdate: '',
        }}
            {...props.generateNoramlFormikProps()}
            onSubmit={(values: any) => {
                // console.log(values);
            }}
            validate={(values: any) => {
                let val1 = (vals: any) => {
                    let res: any = {};
                    if (!validateFormat(vals.govID, "SSN")) {
                        res.govID = "Govt ID is not correct.";
                    }
                    if (vals.birthdate === '') {
                        res.birthdate = "Birthdate is required.";
                    }
                    if (vals.nameType === '') {
                        res.nameType = "Name type is required";
                    }
                    console.log(res)
                    return res;
                };
                //return validate(val, values, props);
                return validate(val1, values, props);
            }}
        >
            {formProps =>
                <Form>
                    <props.Row>
                        <props.Col xs="12">
                            {formProps.values.groupNumber}
                        </props.Col>
                        <props.Col xs="6">
                            <props.GroupSelectControl options={groupList} label="Group" name="groupNumber"/>
                            <props.DateTimePickerControl name="birthdate" dateformat="yyyy-mm-dd" />
                            <props.TextControl displayMode="vertical" labelClass="gwp-sectitle" name="firstName1" label="common.lbl.firstName" placeholder="common.lbl.firstName" />
                            <props.NameTypeSelectControl id="nameType" name="nameType" />
                            <props.FirstNameAutoCompleteControl roleTypeCodes={["OW1", "AGT", "OW2"]} name="firstName" required={true} />
                            <props.CheckboxControl name={`confirmation`} value={true} onChange={(e) => { console.log(e.target.getAttribute('data-form-value')) }} label="Confirmation" />
                        </props.Col>
                        <props.Col xs="6">
                            <props.LastNameAutoCompleteControl roleTypeCodes={["OW1", "AGT", "OW2"]} name="lastName" />
                            <props.OrgNameAutoCompleteControl roleTypeCodes={["OW1"]} name="orgName" />
                            <props.ComboboxControl name="name" data={["Jin", "Wang", "Chen", "Cai"]} label="Name"></props.ComboboxControl>
                            <props.AutoFormatControl name="govID" formatType="SSN" label="Government ID"></props.AutoFormatControl>
                        </props.Col>
                        <props.TextControl name="securityCode" label="Security Code" />
                        <captcha.Component />
                    </props.Row>
                    <props.Button type="submit">Submit</props.Button>
                    <props.Button onClick={() => {
                        pdfViewer.showPDF("http://www.baidu.com");
                    }}>Show PDF</props.Button>
                    <props.Button onClick={() => {
                        pdfViewer.showPDF("http://www.tushu007.com/ISBN-9787806137277.pdf");
                    }}>Show PDF1</props.Button>
                    <props.Button onClick={() => {
                        pdfViewer.showPDF();
                    }}>Show PDF3</props.Button>
                    <pdfViewer.Component></pdfViewer.Component>

                </Form>
            }
        </Formik>

        <props.HR />
        <props.Information message="Selectabl Table"></props.Information>

        <props.Button onClick={() => { table.page('previous') }}>Previous page</props.Button>        <props.Button onClick={() => { table.page('next') }}>Next page</props.Button> <props.Button onClick={() => { alert(table.page()) }}>Alert Page Number</props.Button>

        <props.Div>
            Current selected row is: {rowNumber}
        </props.Div>

        { tableComponent}

        <props.HR />
        <props.Information message="Table with child. " />

        <props.Button onClick={() => alert(table3.getOrder())}>Show Order</props.Button>
        <props.Button onClick={() => { table3.updateCell(0, 4, (Math.random() * 10000).toString()) }}>Update cell</props.Button>
        <table3.Component />

        <props.Table id="testTable4" table={
            {
                columns: [
                    { name: 'type', title: 'Type' },
                    { name: 'limit', title: 'Limit', type: 'numeric', numberFormatOptions: { minimumFractionDigits: 9 } },
                    { name: 'amount', title: 'Accumulated Amount', type: 'numeric' },
                ],
                data: [
                    { type: '010 - Annual Deductible', limit: '500', amount: '0' }
                ]
            }
        }></props.Table>

        <props.Table id="testWithCustomizedSelect" select={
            {
                type: 'multiple',
                prefix: 'test5',
                highlight: false,
                //showSelectedInformation: false,
                selected: [0, 1, 2],
                buttons: {
                    container: document.getElementById("selectBtns"),
                    selectAll: document.getElementById("selectAll"),
                    deSelectAll: document.getElementById("deSelectAll"),
                    showAlways: true,
                },
                onSelect: (e, selectedRows, unSelectedRows) => {
                    // console.log(selectedRows);
                    // console.log(unSelectedRows);
                }
            }
        } >
            <thead>
                <tr>
                    <th data-sortable={false} className="gwp-dt-detail-control"></th>
                    <th data-sortable={false} className="gwp-dt-select-all">test5<input className="gwp-dt-select-all-checkbox" type="checkbox" /></th>
                    <th>test1</th>
                    <th>test2</th>
                    <th>test3</th>
                    <th>test4</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="gwp-dt-detail-control"></td><td>1</td><td>2</td><td>3</td><td>4</td><td><input type="checkbox" id="test5_1" /></td>
                </tr>
                <tr>
                    <td className="gwp-dt-detail-control"></td><td>1</td><td>2</td><td>3</td><td>4</td><td><input type="checkbox" id="test5_2" /></td>
                </tr>
                <tr>
                    <td className="gwp-dt-detail-control"></td><td>1</td><td>2</td><td>3</td><td>4</td><td><input type="checkbox" id="test5_3" /></td>
                </tr>
                <tr>
                    <td className="gwp-dt-detail-control"></td><td>1</td><td>2</td><td>3</td><td>4</td><td><input type="checkbox" id="test5_4" /></td>
                </tr>
                <tr>
                    <td className="gwp-dt-detail-control"></td><td>1</td><td>2</td><td>3</td><td>4</td><td><input type="checkbox" id="test5_5" /></td>
                </tr>
                <tr>
                    <td className="gwp-dt-detail-control"></td><td>1</td><td>2</td><td>3</td><td>4</td><td><input type="checkbox" id="test5_6" /></td>
                </tr>
                <tr>
                    <td className="gwp-dt-detail-control"></td><td>1</td><td>2</td><td>3</td><td>4</td><td><input type="checkbox" id="test5_7" /></td>
                </tr>
                <tr>
                    <td className="gwp-dt-detail-control"></td><td>1</td><td>2</td><td>3</td><td>4</td><td><input type="checkbox" id="test5_8" /></td>
                </tr>
                <tr>
                    <td className="gwp-dt-detail-control"></td><td>1</td><td>2</td><td>3</td><td>4</td><td><input type="checkbox" id="test5_9" /></td>
                </tr>
                <tr>
                    <td className="gwp-dt-detail-control"></td><td>1</td><td>2</td><td>3</td><td>4</td><td><input type="checkbox" id="test5_10" /></td>
                </tr>
                <tr>
                    <td className="gwp-dt-detail-control"></td><td>1</td><td>2</td><td>3</td><td>4</td><td><input type="checkbox" id="test5_11" /></td>
                </tr>
            </tbody>

        </props.Table>

        <props.Div id="selectBtns">
            <props.Button id="selectAll">Select All</props.Button>
            <props.Button id="deSelectAll">Deselect All</props.Button>
        </props.Div>

        <props.Button onClick={() => { props.showModal("#testModal") }}>Show Table In Modal</props.Button>
        <props.Modal id="testModal" title="Test Modal" size="large">
            <props.Table id="tableInModal" modal="testModal" select={
                {
                    type: 'multiple',
                    onSelect: (e, rows) => {
                        alert(rows)
                    }
                }
            } table={
                {
                    columns: [
                        {
                            name: 'col1', title: 'Column 1',

                        },
                        { name: 'col2', title: 'Column 2' },
                        { name: 'col3', title: 'Column 3' },
                        { name: 'col4', title: 'Column 4' },
                        { name: 'col5', title: 'Column 5' },
                        { name: 'col6', title: 'Column 6' },
                        { name: 'col7', title: 'Column 7' },
                        {
                            name: 'col8', title: 'Column 8', render: (data: any, type: any, row: any, meta: any) => {
                                return `${row.agentNumber + row.agentName} <br/> <a id='showAllAgentNamesTableCell${meta.row}'>${'1231232'}</a>`
                            }
                        },
                        /*{ name: 'col9', title: 'Column 9' },
                        { name: 'col10', title: 'Column 10' },*/
                    ], data: [
                        { col1: 'Test Data1', col2: 'Test Data2', col3: 'Test Data3', col4: 'Test Data4', col5: 'Test Data5', col6: 'Test Data6', col7: 'Test Data7', col8: 'Test Data8', /*col9: 'Test Data9', col10: 'Test Data10'*/ }
                    ]
                }
            }></props.Table>
        </props.Modal>

        <props.HR />
        <props.Information message="Print Button" />
        <props.Button printVisible={false} onClick={() => props.print({ size: 'A3', orientation: 'landscape' })}>Print</props.Button>
        <props.PrintButton onClick={() => props.print({ size: 'A5', orientation: 'landscape' })} />

        <props.Modal id="chartModal" title="Test Chart" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.ChartViewer data={{
                datasets: [
                    {
                        data: [
                            10,
                            20,
                            30,
                            40
                        ],
                        backgroundColor: [
                            ChartColor.red,
                            ChartColor.green,
                            ChartColor.yellow,
                            ChartColor.blue,
                        ],
                        label: 'Dataset 1',
                    }
                ],
                labels: ['test1', 'test2', 'test3', 'test4']
            }
            } type='pie' options={{ legend: { labels: { boxWidth: 10, fontSize: 10, fontColor: 'red' }, position: 'right' } }}></props.ChartViewer>
        </props.Modal>

        <props.HR />
        <props.Information message="Charts" />
        <props.Button onClick={() => { props.showModal("#chartModal") }}>Show Chart In Modal</props.Button>
        <props.Button onClick={() => { props.showMessage("error", <>Error <props.Link to="/billingPaymentInquiry">Click me to billing/payment inquiry</props.Link></>) }}>Show Error With Link</props.Button>
        <props.Information message="Test"></props.Information>
        <props.Information showExclamationSign={false} message="Test without exclamation sign."></props.Information>
        <span className="gwp-icon gwp-icon-view-statement gwp-icon-sm"></span>
        <props.Div>{"test-<wbr/>"}</props.Div>
        {/* <SearchSection {...props} changeType={1} companyCode={companyCodePre} policyNumber={policyNumberPre}></SearchSection> */}
        {
            formatDateTime("13:14", "HH:mm", "hh:mm a")
        }

        <props.Button onClick={() => { props.showModal("#DividendDetailsMotal") }}>AngLiTESTModal</props.Button>
        <props.Modal id="DividendDetailsMotal" title="AngLi TEST Modal" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <p><GlobalizedText message="common.lbl.allcurrencyvalue" />{" " + "TEST123"}</p>
            <props.Table modal="DividendDetailsMotal" id="dividendDetailsTable" option={
                {
                    bSort: false,
                    bPaginate: false,
                    paging: false,
                    bLengthChange: true,
                    oLanguage: {
                        sInfoEmpty: "",
                        sInfo: ""
                    },
                    searching: false
                }
            }>
                <thead>
                    <tr>
                        <th className="visible-xs visible-sm gwp-dt-detail-control" ></th>
                        {/* <th className="gwp-dt-detail-control"></th> */}
                        <th></th>
                        <th style={{ width: "15%" }}><GlobalizedText message="pd.lbl.ondepositaccumulation" /></th>
                        <th style={{ width: "15%" }}><GlobalizedText message="pd.lbl.interestpaidtodate" /></th>
                        <th style={{ width: "15%" }}><GlobalizedText message="pd.lbl.paidupadditionsamount" /></th>
                        <th style={{ width: "15%" }}><GlobalizedText message="pd.lbl.paidupadditionsexpdate" /></th>
                        <th style={{ width: "15%" }}><GlobalizedText message="pd.lbl.oneyeartermadditionsamout" /></th>
                        <th style={{ width: "15%" }}><GlobalizedText message="pd.lbl.oneyeartermadditionsexpdate" /></th>
                    </tr>
                </thead>
                <tbody>

                    <tr>
                        <th className="visible-xs visible-sm gwp-dt-detail-control">{" "}</th>
                        {/* <th className="gwp-dt-detail-control"></th> */}
                        <th><GlobalizedText message="pd.lbl.dividendsummary" /></th>

                        <td align="right">332.00</td>
                        <td>05/14/2020</td>

                        <td align="right">0.00</td>
                        <td>{""}</td>

                        <td align="right">0.00</td>
                        <td>{""}</td>
                    </tr>


                    <tr>
                        <th className="visible-xs visible-sm gwp-dt-detail-control">{" "}</th>
                        {/* <th className="gwp-dt-detail-control"></th> */}
                        <th><GlobalizedText message="pd.lbl.couponsummary" /></th>

                        <td align="right">332.00</td>
                        <td>05/14/2020</td>

                        <td align="right">0.00</td>
                        <td>{""}</td>

                        <td align="right">0.00</td>
                        <td>{""}</td>
                    </tr>

                </tbody>

            </props.Table>

        </props.Modal>




    </>
});