import React, { ReactNode } from 'react';
import { ViewComponentProps } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import './css/agentinfopanel.css';
import { ConditionProps, withCondition } from '../shared/components';

export interface AgentInfo {
    fullName: string;
    phone: string;
    email: string;
    splitPerctage: number;
}

export interface AgentsInfoPanelProps extends ViewComponentProps, ConditionProps {
    agents: Array<AgentInfo>;
    showMaxAgent?: number;
    telLink?: boolean;
    from: string;
    children?: ReactNode;

}

const DEFAULT_SHOW_MAX_AGENTS: number = 2;
/**
 * Display the agent information 
 * @param props 
 */
export const AgentsInfoPanel = withCondition((props: AgentsInfoPanelProps) => {

    function viewAllAgent() {
        props.showModal("#allAgentsDialog")
        // alert('viewDividendDetails')
    }
    let sma = props.showMaxAgent === undefined ? DEFAULT_SHOW_MAX_AGENTS : props.showMaxAgent

    return <>
        <props.Panel id="agentInfoPanel" className="agentInfo">
            <props.PanelHeading>
                <span className="glyphicon glyphicon-user"></span>
                {props.agents.length === 1 && props.from === 'benefitDetail' &&
                    <GlobalizedText message="common.lbl.writingAgent" />
                }
                {props.agents.length === 1 && props.from !== 'benefitDetail' &&
                    <GlobalizedText message="common.lbl.agent" />
                }
                {props.agents.length > 1 && props.from === 'benefitDetail' &&
                    <GlobalizedText message="common.lbl.writingAgents" />
                }
                {props.agents.length > 1 && props.from !== 'benefitDetail' &&
                    <GlobalizedText message="pd.lbl.agents" />
                }
            </props.PanelHeading>
            <props.PanelBody>
                {
                    props.agents.map((agent: AgentInfo, index: number) => {
                        if (index < sma) {
                            return <React.Fragment key={index}>
                                <AgentInfoComponent {...props} agent={props.agents[index]} telLink={props.telLink === undefined ? true : props.telLink}></AgentInfoComponent>
                                {(props.agents.length > 1 && sma > 1 && index < sma - 1) &&
                                    <props.HR />
                                }
                            </React.Fragment>
                        } else {
                            return <React.Fragment key={index}></React.Fragment>
                        }
                    })
                }
                {props.agents.length > sma &&
                    <props.LinkButton onClick={viewAllAgent}><props.Icon type="view-all" label="common.lbl.viewAllAgents" labelClass="viewAllAgent"></props.Icon></props.LinkButton>

                }
            </props.PanelBody>
        </props.Panel >
        {/* ViewAllAgent Dialog */}
        <props.Modal id="allAgentsDialog" title="common.lbl.viewAllAgents" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                {props.agents.map((agent: any, i: number) => {
                    return <props.Col key={i} xs="12" md="6" sm="6">
                        {/* <props.DLI title={() => { return <><props.Span className="agentName">{agent.fullName}</props.Span> </> }} ></props.DLI> */}
                        <props.Label>{agent.fullName}</props.Label>
                        <props.DL className="dl-horizontal breakWord">
                            <props.DLI condition={undefined !== agent.splitPerctage && null !== agent.splitPerctage} title="common.lbl.split" desc={() => {
                                if (typeof (agent.splitPerctage) === 'number') {
                                    return <>{agent.splitPerctage.toFixed(1) + "%"} &nbsp;</>
                                } else {
                                    return <>{agent.splitPerctage + "%"}&nbsp; </>
                                }
                            }}></props.DLI>
                            <props.DLI title="pd.lbl.phone" desc={() => {
                                //return <>{agent.phone}</>
                                if (props.telLink === undefined || props.telLink) {
                                    return <a href={"tel:" + agent.phone}>{agent.phone}&nbsp;</a>
                                } else {
                                    return <>{agent.phone}&nbsp;</>
                                }
                            }}></props.DLI>
                            <props.DLI title="pd.lbl.email" desc={() => {
                                return <a href={"mailto:" + agent.email}>{agent.email}&nbsp;</a>
                            }}></props.DLI>
                        </props.DL>
                        {/* {i === agent.length - 1 &&
                            <hr className="visible-xs-*" />
                        } */}
                    </props.Col>
                })}
            </props.Row>
        </props.Modal >
    </>
});

interface AgentInfoComponentProps extends ViewComponentProps {
    agent: AgentInfo;
    telLink?: boolean;
}

const AgentInfoComponent = (props: AgentInfoComponentProps) => {
    return <>
        <props.Label>{props.agent.fullName}</props.Label>
        <props.DL className="dl-horizontal">
            {props.telLink &&
                <props.DLI condition={undefined !== props.agent.splitPerctage && null !== props.agent.splitPerctage} title="common.lbl.split" desc={() => {
                    if (typeof (props.agent.splitPerctage) === 'number') {
                        return <span className="splitPerctage">{props.agent.splitPerctage.toFixed(1) + "%"}</span>
                    } else {
                        return <span className="splitPerctage">{props.agent.splitPerctage + "%"}</span>
                    }
                }}></props.DLI>
            }

            <props.DLI title={"pd.lbl.phone"} desc={() => {
                if (props.telLink) {
                    return <props.Link className="bizTel" to={`tel:${props.agent.phone}`}>{props.agent.phone}</props.Link>
                } else {
                    return <span className="bizTel">{props.agent.phone}</span>
                }
            }}>
            </props.DLI>
            <props.DLI title={"pd.lbl.email"} desc={() => {
                return <props.Link className="agentEmail" to={`mailto:${props.agent.email}`}>{props.agent.email}</props.Link>
            }}></props.DLI>
        </props.DL>

    </>
}