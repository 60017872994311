import Chart, { ChartConfiguration, ChartOptions } from "chart.js";
import 'chartjs-plugin-datalabels';
import React, { useEffect, useMemo } from "react";
import { ConditionProps, withCondition } from "..";
import { copyObjectExcept, generateUUID } from "../../utils";

export interface ChartProps extends ChartConfiguration, ConditionProps {
    /**
     * default is 100%
     */
    width?: string;
    /**
     * default is true
     */
    showPercentageOnChart?: boolean;
    /**
     * default is white
     */
    pecentageFontColorOnChart?: string;
}

export const ChartColor = {
    tan: 'rgb(197,180,127)',
    darkgreen: 'rgb(87,149,117)',
    darkorange: 'rgb(234, 162, 40)',
    darkblue: 'rgb(75,178,197)',
    fontblue: 'rgb(89,189,223)',
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)',
}

const getContext = (el: any): any => {
    return el.getContext('2d');
}

const defaultOptions: ChartOptions = {
    plugins: {
        datalabels: {
            formatter: (value: any, ctx: any) => {
                let datasets = ctx.chart.data.datasets;
                if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                    let sum = datasets[0].data.reduce((a: any, b: any) => a + b, 0);
                    let percentage = Math.round((value / sum) * 100) + '%';
                    return percentage;
                } else {
                    return '';
                }
            },
            color: '#736c60',
        }
    },
    legend: { labels: { boxWidth: 10, fontSize: 10, fontColor: ChartColor.fontblue }, position: 'right' },
    responsive: true
}

export const ChartViewer = withCondition(({ width, showPercentageOnChart, condition, ...props }: ChartProps) => {
    const id = useMemo(() => generateUUID(), []);
    width = width ? width : "100%";
    const charAreaId = `chart-area-${id}`;
    props.options = { ...defaultOptions, ...props.options };
    if (showPercentageOnChart === false) {
        props = copyObjectExcept(props.options.plugins, false, "datalabels");
    }
    useEffect(() => {
        let ctx = getContext(document.getElementById(charAreaId));
        new Chart(ctx, props);
    });
    return <>
        <div id={id} style={{ width: width }}>
            <canvas id={charAreaId}></canvas>
        </div>
    </>
});