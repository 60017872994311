import React, { useEffect, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { ajax, getContextPath, isEmptyObject, parseBoolean } from '../shared/utils';
import GlobalizedText from '../shared/globalization';
import './css/commissionreport.css'

export const DashboardListComponent = withView((props: ViewComponentProps) => {
    const companyCode: string = props.getParam("companyCode");
    const agentNumber: string = props.getParam("agentNumber");
    const policyNumber: string = props.getParam("policyNumber");
    //const directReports: boolean = false;
    const fromPage: string = props.getParam("fromPage");
    const fromAgentSearch: boolean = fromPage === "from_agentsearch" ? true : false;

    const [config, setConfig] = useState<any>();
    const [isDir, setIsDir] = useState<boolean>(parseBoolean(props.getParam("isDir")));
    let params = {};
    if (isEmptyObject(policyNumber)) {
        params = {
            fromAgentSearch: fromAgentSearch,
            companyCode: companyCode,
            agentNumber: agentNumber
        }
    } else {
        params = {
            fromAgentSearch: fromAgentSearch,
            companyCode: companyCode,
            agentNumber: agentNumber,
            policyNumber: policyNumber
        }
    }

    const thisPage: string = "dashboardList";

    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/quickSight/listDashboards',
                params: params,
                success: (res: any) => {
                    setConfig(res);

                }
            });
        }
    }, [config])

    if (config === undefined) {
        return <></>;
    }

    if (!isEmptyObject(config?.agentName)) {
        props.setTitle(props.getGlobalizedText("common.lbl.commissionReports") + " - " + config.agentName);
    } else {
        props.setTitle(props.getGlobalizedText("common.lbl.commissionReports"));
    }
    return <>
        <props.Row>
            <props.Col xs="12" sm="12" md="12">
                <ul className="nav nav-tabs" role="tablist">
                    <li id="COMR_myRep" role="presentation" className="active" >
                        <a href="#myL" onClick={() => { setIsDir(false) }} aria-controls="myL" role="tab" data-toggle="tab" ><GlobalizedText message="common.lbl.myReports" /></a>
                    </li>
                    <props.Condition condition={config?.showDirectReports}>
                        <li id="COMR_DirRep" role="presentation">
                            <a href="#myL" onClick={() => { setIsDir(true) }} aria-controls="myL" role="tab" data-toggle="tab"><GlobalizedText message="common.lbl.directReports" /></a>
                        </li>
                    </props.Condition>
                </ul>
            </props.Col>
            {config.reports?.map((row: any, index: number) => {
                return <>
                    <props.Col xs="12" sm="8" md="6">

                        <props.Panel className="noBorder noBackground fontBlue">
                            <props.PanelHeading className="noBorder noBackground fontBlue">
                                <a href="#" onClick={() => props.next(`/dashboard?dashboardID=${row.dashboardID}&dashboardName=${row.dashboardName}&fromAgentSearch=${fromAgentSearch}&companyCode=${companyCode}&agentNumber=${agentNumber}&policyNumber=${policyNumber}&directReports=${isDir}&from=${thisPage}`)} className="gwp-page-title"><GlobalizedText message={row.dashboardName} /></a>
                            </props.PanelHeading>
                            <props.PanelBody>
                                <a href="#" onClick={() => props.next(`/dashboard?dashboardID=${row.dashboardID}&dashboardName=${row.dashboardName}&fromAgentSearch=${fromAgentSearch}&companyCode=${companyCode}&agentNumber=${agentNumber}&policyNumber=${policyNumber}&directReports=${isDir}&from=${thisPage}`)} className="gwp-page-title"><img width='100%' src={getContextPath() + row.imageUrl} /></a>
                            </props.PanelBody>
                            <props.PanelFooter className="noBorder noBackground">{row.dashboardDesc}</props.PanelFooter>
                        </props.Panel>
                    </props.Col>
                </>
            })}
        </props.Row>
    </>
});
