import React from 'react';
import { INIT_PARAM_PREFIX_TABLE, KEY_NEED_RELOAD, Link } from './components';

interface BreadCrumbState {
    count: number,
    display: boolean
}
interface BreadCrumbTracker {
    name: string,
    pathname?: string,
    search?: string,
    className?: string,
    initParams?: any | null,
}
interface BreadCrumbProps {
    breadcrumb: BreadCrumbService,
    user: any
}
export default class BreadCrumb extends React.Component<BreadCrumbProps, BreadCrumbState>{

    breadcrumbtrackers: Array<BreadCrumbTracker>;

    constructor(props: BreadCrumbProps) {
        super(props);
        this.props.breadcrumb.setBreadCrumbComponent(this);
        this.breadcrumbtrackers = [];
        this.state = {
            count: 0,
            display: true
        }
    }

    refresh() {
        this.setState({ ...this.state, count: this.state.count + 1 });
    }

    _update(breadcrumbtrackers: Array<BreadCrumbTracker>) {
        if (breadcrumbtrackers.length === 0) {
            window.sessionStorage.removeItem("breadcrumbtrackers");
        } else {
            window.sessionStorage.setItem("breadcrumbtrackers", JSON.stringify(breadcrumbtrackers));
        }
        this.breadcrumbtrackers = breadcrumbtrackers;
    }

    init(breadcrumbtracker: BreadCrumbTracker) {
        this._update([{ name: 'My Workspace' }, breadcrumbtracker]);
    }

    record(breadcrumbtracker: BreadCrumbTracker) {
        let breadcrumbtrackers = this.breadcrumbtrackers;
        if (breadcrumbtrackers.length === 0) {
            breadcrumbtrackers = JSON.parse(`${window.sessionStorage.getItem("breadcrumbtrackers")}`);
        }
        if (breadcrumbtrackers[breadcrumbtrackers.length - 1].pathname === breadcrumbtracker.pathname) {
            let popedBt = breadcrumbtrackers.pop();
            if (popedBt !== undefined) {
                breadcrumbtracker = { ...popedBt, ...breadcrumbtracker };
            }
        }
        breadcrumbtrackers.push(breadcrumbtracker);
        this._update(breadcrumbtrackers);
    }

    update(breadcrumbtracker: BreadCrumbTracker) {
        let breadcrumbtrackers = this.breadcrumbtrackers;
        if (breadcrumbtrackers.length === 0) {
            breadcrumbtrackers = JSON.parse(`${window.sessionStorage.getItem("breadcrumbtrackers")}`);
        }
        for (let i = 0; i < breadcrumbtrackers.length; i++) {
            if (breadcrumbtracker.pathname === breadcrumbtrackers[i].pathname) {
                breadcrumbtrackers[i] = breadcrumbtracker;
            }
        }
        this._update(breadcrumbtrackers);
    }

    back() {
        let pre = this.breadcrumbtrackers[this.breadcrumbtrackers.length - (this.breadcrumbtrackers.length > 2 ? 2 : 1)];
        this.moveTo(this.breadcrumbtrackers.length - 2);
        return pre;
    }

    last() {
        if (this.breadcrumbtrackers.length === 0) {
            return null;
        }
        return this.breadcrumbtrackers[this.breadcrumbtrackers.length - 1];
    }

    clear() {
        this._update([]);
    }

    moveTo(pos: number) {
        this._update(this.getBreadCrumbTrackers().slice(0, pos + 1));
    }

    getBreadCrumbTrackers() {
        if (window.sessionStorage.getItem("breadcrumbtrackers") !== null && this.breadcrumbtrackers.length === 0) {
            return JSON.parse(`${window.sessionStorage.getItem("breadcrumbtrackers")}`);
        }
        return this.breadcrumbtrackers;
    }
    show() {
        this.setState({ ...this.state, display: true });
    }
    hide() {
        this.setState({ ...this.state, display: false });
    }
    render() {
        let breadcrumbtrackers = this.getBreadCrumbTrackers();
        if (this.props.user === null || breadcrumbtrackers.length === 0 || this.props.breadcrumb.isShowBreadcrumb() === false) {
            return <></>;
        }
        return <>
            <div>
                <ol className="breadcrumb" style={{ display: this.state.display ? 'block' : 'none' }}>
                    {breadcrumbtrackers.map((bt: BreadCrumbTracker, index: number) => {
                        return <BreadCrumbTrackerComponent onClick={() => { this.moveTo(index) }} active={index === breadcrumbtrackers.length - 1} key={index} value={bt} />;
                    })}
                </ol>
            </div>
        </>
    }
}

interface BreadCrumbTrackerComponentProps {
    value: BreadCrumbTracker,
    active: boolean,
    onClick: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | undefined
}

class BreadCrumbTrackerComponent extends React.Component<BreadCrumbTrackerComponentProps>{
    render() {
        return this.props.value.pathname === undefined || this.props.active === true ?
            <li className={this.props.active === true ? "active" : ""}>{this.props.value.name}</li> :
            <li className={`${this.props.value.className}`}><Link onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                let initParam = this.props.value.initParams;
                if (initParam !== undefined) {
                    if (initParam[INIT_PARAM_PREFIX_TABLE] !== undefined) {
                        for (let tableId in initParam[INIT_PARAM_PREFIX_TABLE]) {
                            initParam[INIT_PARAM_PREFIX_TABLE][tableId][KEY_NEED_RELOAD] = true;
                        }
                    }
                    this.props.value.initParams = initParam;
                }
                if (this.props.onClick !== undefined) {
                    this.props.onClick(e);
                }
            }} to={`${this.props.value.pathname}${this.props.value.search !== null ? this.props.value.search : ''}`}>{this.props.value.name}</Link></li>
    }
}

export class BreadCrumbService {
    breadCrumbComponent: any;
    showBreadcrumb: boolean;

    constructor() {
        this.showBreadcrumb = true;
    }
    findBreadcrumb(location: { pathname: string, search: string }): number {
        let result = -1;
        let breadcrumbtrackers = this.breadCrumbComponent.getBreadCrumbTrackers();
        for (let i = 0; i < breadcrumbtrackers.length; i++) {
            if (location.pathname === breadcrumbtrackers[i].pathname && location.search === breadcrumbtrackers[i].search) {
                result = i;

                break;
            }
        }
        return result;
    }

    setBreadCrumbComponent(breadCrumbComponent: any): void {
        this.breadCrumbComponent = breadCrumbComponent;
    }
    init(breadcrumbtracker: BreadCrumbTracker): void {
        this.breadCrumbComponent.init(breadcrumbtracker);
    }
    isShowBreadcrumb(): boolean {
        return this.showBreadcrumb;
    }
    setShowBreadcrumb(showBreadcrumb: boolean): void {
        this.showBreadcrumb = showBreadcrumb;
    }
    record(breadcrumbtracker: BreadCrumbTracker): void {
        let breadcrumbtrackers = this.breadCrumbComponent.getBreadCrumbTrackers();
        if (breadcrumbtrackers.length === 0) {
            this.breadCrumbComponent.init(breadcrumbtracker);
        } else {
            this.breadCrumbComponent.record(breadcrumbtracker);
        }
    }
    readjust(): void {
        let path = window.location.pathname;
        let breadcrumbtrackers = this.breadCrumbComponent.getBreadCrumbTrackers();
        if (breadcrumbtrackers.length > 0) {
            for (let i = 0; i < breadcrumbtrackers.length; i++) {
                if (path === breadcrumbtrackers[i].url) {
                    this.breadCrumbComponent._update(breadcrumbtrackers.slice(0, i + 1));
                    break;
                }
            }
        }
    }
    setInitParams(params: any): void {
        let bc = this.last();
        bc.initParams = params;
        this.breadCrumbComponent.update(bc);
    }
    last(): BreadCrumbTracker {
        return this.breadCrumbComponent.last();
    }
    length(): number {
        return this.breadCrumbComponent.getBreadCrumbTrackers().length;
    }
    previous(): BreadCrumbTracker {
        let breadcrumbtrackers = this.breadCrumbComponent.getBreadCrumbTrackers();
        return breadcrumbtrackers[breadcrumbtrackers.length - 2];
    }
    getInitParams(): any {
        let last = this.breadCrumbComponent.last();
        return last ? last['initParams'] : {};
    }
    back(): BreadCrumbTracker {
        return this.breadCrumbComponent.back();
    }
    clear(): void {
        this.breadCrumbComponent.clear();
    }
    moveTo(pos: number): void {
        this.breadCrumbComponent.moveTo(pos);
    }
    refresh(): void {
        this.breadCrumbComponent.refresh();
    }
    show(): void {
        this.breadCrumbComponent.show();
    }
    hide(): void {
        this.breadCrumbComponent.hide();
    }
}