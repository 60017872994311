import React, { useEffect, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { ajax } from '../shared/utils';
import GlobalizedText from '../shared/globalization';
import './css/commissionreport.css'

export const AwsDashboardListComponent = withView((props: ViewComponentProps) => {
    const fromPage: string = props.getParam("fromPage");
    const thisPage: string = "awsDashboardList";

    const [config, setConfig] = useState<any>();

    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/quickSight/listDashboards',
                success: (res: any) => {
                    setConfig(res);

                }
            });
        }
    }, [config])

    if (config === undefined) {
        return <></>;
    }

    return <>
        <props.Row>

            {config?.map((row: any, index: number) => {
                return <>
                    <props.Col xs="12" sm="12" md="12">
                        <props.Label className="noBorder noBackground fontBlue"><a href="#" onClick={() => props.next(`/awsDashboard?dashboardId=${row.dashboardId}&name=${row.name}&from=${thisPage}`)} className="gwp-page-title"><GlobalizedText message={row.name} /></a></props.Label>
                    </props.Col>
                </>
            })}
        </props.Row>
    </>
});
