import React, { useEffect, useState } from "react";
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import './css/billingsummary.css'
import { ajax, removeLeftZero, trim } from '../shared/utils';

export const BillingSummaryComponent = withView((props: ViewComponentProps) => {

    //Get value from previous pages
    let companyCodePre: string = trim(props.getQueryParam("companyCode"));
    let groupNumberPre: string = trim(props.getQueryParam("groupNumber"));
    let accountNumberPre: string = trim(props.getQueryParam("accountNumber"));
    let participantIDNumberPre: string = trim(props.getQueryParam("participantIDNumber"));
    let modalDateFromPre: string = trim(props.getQueryParam("modalDateFrom"));
    let modalDateThroughPre: string = trim(props.getQueryParam("modalDateThrough"));
    let invoiceNumberPre: string = trim(props.getQueryParam("invoiceNumber"));
    let agentNumberPre: string = trim(props.getQueryParam("agentNumber"));

    //Current page
    const [config, setConfig] = useState<any | null>(null)

    useEffect(() => {
        if (config === null) {
            let initParams = props.getInitParams();
            if (initParams !== undefined && 'billingSummary' === initParams.storedPage) {
                setConfig(initParams.config)
            } else {
                ajax({
                    url: '/api/billingSummary/view',
                    params: {
                        companyCode: companyCodePre,
                        groupNumber: groupNumberPre,
                        accountNumber: parseInt(accountNumberPre),
                        participantIDNumber: participantIDNumberPre,
                        modalDateFrom: modalDateFromPre,
                        modalDateThrough: modalDateThroughPre,
                        invoiceNumber: invoiceNumberPre,
                        agentNumber: agentNumberPre
                    },
                    method: 'get',
                    success: (res: any) => {
                        setConfig(res)
                    }, error: (error: any) => {
                        props.showMessage("error", error.response.data.message);
                    }
                });
            }
        }
    }, [config]);

    // save data for current page
    function saveDataBeforeRedirect() {
        props.setInitParams({
            storedPage: 'billingSummary',
            config: config
        });
    }

    if (config === null) {
        return <></>
    }

    return <>
        <props.Row>
            <props.Col xs="8" md="8" sm="8">
                <props.PageHeader className="gwp-billingsummary-label" label={() => {
                    return <><GlobalizedText message="billpay.modaldate.from" />
                        <br /> {modalDateFromPre}
                    </>
                }} />

            </props.Col>
            <props.Col xs="4" md="4" sm="4">
                <props.Div className="gwp-float-right" style={{ marginRight: -15 + "px" }}>
                    {/* <props.Icon type="print" size="sm"></props.Icon> */}
                    <props.Span className="gwp-icon gwp-icon-print gwp-icon-sm" style={{ marginRight: -5 + "px" }}></props.Span>
                    <props.Button className="printButton" printVisible={false} onClick={() => props.print({ size: 'A5', orientation: 'landscape' })}><GlobalizedText message="common.print" /></props.Button>
                </props.Div>
            </props.Col>
        </props.Row>
        {(config.showPremium === true && config.currency !== undefined && config.currency.currencyName !== undefined && config.currency.countryCode !== undefined) &&
            <props.Row>
                <props.Col sm="12" md="12" xs="12">
                    <GlobalizedText message="common.lbl.allcurrencyvalue"></GlobalizedText> {config.currency.currencyName} ({config.currency.countryCode})
                </props.Col>
            </props.Row>
        }
        <br />
        <props.Panel className="">
            <props.PanelBody>
                <props.Row>
                    <props.Col sm="8" md="9" xs="12">
                        <props.Row>
                            <props.Col sm="6" md="6" xs="12">
                                <props.DL>
                                    {/* <props.DLI title="common.lbl.group" desc={config.groupName.replace(/\b(0+)/gi, "")}></props.DLI> */}
                                    <props.DLI title="common.lbl.group" desc={removeLeftZero(config.groupName)}></props.DLI>
                                    <props.DLI title="common.lbl.account" desc={config.accountName}></props.DLI>
                                    <props.DLI condition={config.billingFrequency !== null && config.billingFrequency !== '' && (config.participantIDNumber === null || config.participantIDNumber === '' || config.participantIDNumber === '0000000000')} title="billpay.billfrequency" desc={config.billingFrequency}></props.DLI>
                                    <props.DLI condition={config.participantIDNumber !== null && config.participantIDNumber !== ''} title="common.lbl.member" desc={config.memberName}></props.DLI>
                                </props.DL>
                            </props.Col>
                            <props.Col sm="6" md="6" xs="12">
                                <props.DL>
                                    <props.DLI title="billpay.invoicenum" desc={config.invoiceNumber}></props.DLI>
                                    <props.DLI condition={config.showPremium} title="billpay.summary.noticeType" desc={config.billingSummary.noticeType}></props.DLI>
                                </props.DL>
                            </props.Col>
                        </props.Row>
                    </props.Col>
                    <props.Col sm="4" md="3" xs="12" className="panel-container-bottom">
                        <props.Panel condition={config.billingSummary.showRelatedLinks !== undefined && config.billingSummary.showRelatedLinks === true}>
                            <props.PanelHeading><GlobalizedText message="common.lbl.relatedLink" /></props.PanelHeading>
                            <props.PanelBody>
                                <props.Row>
                                    <props.Col sm="12" md="12" xs="12">
                                        <props.Label><GlobalizedText message="billpay.summary.viewBillingDetails" /></props.Label>
                                    </props.Col>
                                </props.Row>
                                <props.Row>
                                    <props.Col sm="12" md="12" xs="12">
                                        <props.Div>
                                            <props.Icon type="view-all" size="xs"></props.Icon>{" "}
                                            <props.Span className="linkStyle"><props.Link
                                                to={"/premiumBilled?companyCode=" + companyCodePre + "&agentNumber=" + agentNumberPre + "&agentIndicator=" + config.agentIndicator + "&modalDateThrough=" + modalDateThroughPre + "&modalDateFrom=" + modalDateFromPre + "&groupNumber=" + groupNumberPre + "&accountNumber=" + accountNumberPre + "&participantIDNumber=" + participantIDNumberPre + "&invoiceNumber=" + invoiceNumberPre} onClick={() => { saveDataBeforeRedirect() }}>
                                                <GlobalizedText message="common.lbl.premiumBilled" />
                                            </props.Link></props.Span>
                                        </props.Div>
                                    </props.Col>
                                </props.Row>
                                <props.Row condition={config.billingSummary.showAdjustmentsBilled === true}>
                                    <props.Col sm="12" md="12" xs="12">
                                        {/* {use Icon comp} */}
                                        <props.Div>
                                            <props.Icon type="view-all" size="xs"></props.Icon>{" "}
                                            <props.Span className="linkStyle"><props.Link
                                                to={"/adjustmentsBilled?companyCode=" + companyCodePre + "&agentNumber=" + agentNumberPre + "&agentIndicator=" + config.agentIndicator + "&modalDateThrough=" + modalDateThroughPre + "&modalDateFrom=" + modalDateFromPre + "&groupNumber=" + groupNumberPre + "&accountNumber=" + accountNumberPre + "&participantIDNumber=" + participantIDNumberPre + "&invoiceNumber=" + invoiceNumberPre} onClick={() => { saveDataBeforeRedirect() }}>
                                                <GlobalizedText message="common.lbl.adjbilled" />
                                            </props.Link></props.Span>
                                        </props.Div>
                                    </props.Col>
                                </props.Row>
                                <props.Row condition={(config.billingSummary.showOtherAdjustments === true || config.billingSummary.showPaymentApplied === true || config.billingSummary.showToleranceAdjustment === true)}>
                                    <props.Col sm="12" md="12" xs="12">
                                        <props.Label><GlobalizedText message="billpay.summary.viewPaymentDetails" /></props.Label>
                                    </props.Col>
                                </props.Row>
                                <props.Row condition={config.billingSummary.showPaymentApplied === true}>
                                    <props.Col sm="12" md="12" xs="12">
                                        <props.Div className="gwp-inline">
                                            <props.Icon type="view-all" size="xs"></props.Icon>{" "}
                                            <props.Span className="linkStyle"><props.Link
                                                to={"/paymentapplied?companyCode=" + companyCodePre + "&agentNumber=" + agentNumberPre + "&agentIndicator=" + config.agentIndicator + "&modalDateThrough=" + modalDateThroughPre + "&modalDateFrom=" + modalDateFromPre + "&groupNumber=" + groupNumberPre + "&accountNumber=" + accountNumberPre + "&participantIDNumber=" + participantIDNumberPre + "&invoiceNumber=" + invoiceNumberPre} onClick={() => { saveDataBeforeRedirect() }}>
                                                <GlobalizedText message="billpay.summary.paymentApplied" />
                                            </props.Link></props.Span>
                                        </props.Div>
                                    </props.Col>
                                </props.Row>
                                <props.Row condition={(config.billingSummary.showToleranceAdjustment === true && config.agentIndicator !== 'P')}>
                                    <props.Col sm="12" md="12" xs="12">
                                        <props.Div>
                                            <props.Icon type="view-all" size="xs"></props.Icon>{" "}
                                            <props.Link className="linkStyle"
                                                to={`/toleranceAdjustments?companyCode=${companyCodePre}&groupNumber=${groupNumberPre}&accountNumber=${accountNumberPre}&participantIDNumber=${participantIDNumberPre}&invoiceNumber=${invoiceNumberPre}&modalDateThrough=${modalDateThroughPre}&modalDateFrom=${modalDateFromPre}&billingFrequency=${config !== null && config.billingFrequency !== undefined ? config.billingFrequency : ''}&groupName=${config.groupName}&accountName=${config.accountName}&fullName=${config.memberName}`} onClick={() => { saveDataBeforeRedirect() }}>
                                                <GlobalizedText message="billpay.summary.toleranceAdjustments" />
                                            </props.Link>
                                        </props.Div>
                                    </props.Col>
                                </props.Row>
                                <props.Row condition={config.billingSummary.showOtherAdjustments === true}>
                                    <props.Col sm="12" md="12" xs="12">
                                        <props.Div>
                                            <props.Icon type="view-all" size="xs"></props.Icon>{" "}
                                            <props.Span className="linkStyle"><props.Link
                                                to={"/otheradjustments?companyCode=" + companyCodePre + "&agentNumber=" + agentNumberPre + "&agentIndicator=" + config.agentIndicator + "&modalDateThrough=" + modalDateThroughPre + "&modalDateFrom=" + modalDateFromPre + "&groupNumber=" + groupNumberPre + "&accountNumber=" + accountNumberPre + "&participantIDNumber=" + participantIDNumberPre + "&invoiceNumber=" + invoiceNumberPre} onClick={() => { saveDataBeforeRedirect() }}>
                                                <GlobalizedText message="billpay.summary.otherAdjustments" />
                                            </props.Link></props.Span>
                                        </props.Div>
                                    </props.Col>
                                </props.Row>
                            </props.PanelBody>
                        </props.Panel>
                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel>
        <props.Row condition={config.agentIndicator === 'P'}>
            <props.Col sm="12" md="12" xs="12">
                <props.Div className="information gwp-information">
                    <props.Span className="glyphicon glyphicon-info-sign"></props.Span>{' '}
                    <GlobalizedText message="common.msg.bsm.mem.pro.info.not.dsp" />
                </props.Div>
            </props.Col>
        </props.Row>

        {config.showPremium === true &&
            <props.Row>
                <props.Col sm="12" md="12" xs="12">
                    <props.Div className="gwp-sectitle"><GlobalizedText message="common.lbl.premium" /></props.Div>
                    <props.Table delayAdjust={true} id="premiumInfoTable2" option={{ paging: false }} table={
                        {
                            columns: [
                                { className: "gwp-dt-detail-control", sortable: false },
                                { title: 'billpay.summary.current', sortable: false, name: "current", type: 'numeric' },
                                { title: 'billpay.summary.pastDue', sortable: false, name: "pastDue", type: 'numeric' },
                                { title: 'common.lbl.adjustments', sortable: false, name: "adjustments", type: 'numeric' },
                                { title: 'billpay.summary.suspense', sortable: false, name: "suspense", type: 'numeric' },
                                { title: 'common.lbl.totalDue', sortable: false, name: "totalDue", type: 'numeric' },
                            ], data: (config.agentIndicator !== 'P') ? config.billingSummary.premiums : []
                        }}
                    >
                    </props.Table>
                </props.Col>
            </props.Row>
        }
    </>
});