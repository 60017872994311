import React from 'react';

export interface PageHeaderProps {
    label: string | any;
    rightLabel?: string | any;
    className?: string | any;
}

export const PageHeader = (props: PageHeaderProps) => {
    return <div className="gwp-page-header">
        <div className="gwp-page-header-block"></div>
        {/* <div className="gwp-page-header-label gwp-label"> */}
        <div className={`${props.className === undefined ? 'gwp-page-header-label' : props.className} gwp-label`}>
            {typeof props.label === 'string' &&
                props.label
            }
            {props.label instanceof Object &&
                <props.label></props.label>
            }
        </div>
        {props.rightLabel !== undefined && (() => {
            return <div className="gwp-page-header-label-right gwp-label">
                {typeof props.rightLabel === 'string' &&
                    <>{props.rightLabel}</>
                }
                {props.rightLabel instanceof Object &&
                    <props.rightLabel></props.rightLabel>
                }
            </div>
        })()
        }

    </div>
}
