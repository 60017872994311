
import { ajax } from '../../shared/utils';

export function loadCategories(setValue, setContentType) {
    ajax({
        url: '/api/cms/faq/category/list',
        success: (res) => {
            setValue(res.categories);
            if (setContentType !== undefined) {
                setContentType(res.contentType);
            }
        }
    });
}
export function loadQuestion(categoryId, setQuestions, setTitle) {
    ajax({
        url: `/api/cms/faq/question/list/${categoryId}`,
        success: (res) => {
            if (setTitle !== undefined) {
                setTitle(res.categoryName);
            }
            setQuestions(res.questions);
        }
    });
}