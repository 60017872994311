import { FieldArray } from "formik";
import React, { useEffect, useRef, useState } from "react";
import GlobalizedText from "../../shared/globalization";
import { ajax, convertDate, formatDate, formatNumber, getContextPath, isEmptyStr, mergeObject, parseDate, trim } from "../../shared/utils";
import Enrollment from '../types/enrollmenttypes';
import './css/coverageselection.css';
import { RequiredHeader } from "./enrollment";
import { FeatureFlags } from '../../shared/featureFlags';
export const CoverageSelection = (props: Enrollment.Props) => {
    const isUpdatePremiumtracking = props.isFeatureEnabled(FeatureFlags.ENROLLMENT_PREMIUMTRACKING);
    const PAGE_URL = {
        GET_YOUR_DESISION_CHANGE: '/api/enrollment/coverageSelection/getYourDecisionChange',
        PLAN_CHANGE: '/api/enrollment/coverageSelection/getPlanChange',
        REQUEST_VOLUMN_CHANGE: '/api/enrollment/coverageSelection/getRequestedVolumeChange',
        COVERAGE_OPTION_CHANGE: '/api/enrollment/coverageSelection/getCoverageOptionChange',
        REQUEST_DATE_CHANGE: '/api/enrollment/coverageSelection/getRequestedEffDateChange',
        TERMINATION_DATE_CHANGE: '/api/enrollment/coverageSelection/getTerminationDateChange',
        INDIVIDUAL_CHANGE: '/api/enrollment/coverageSelection/getIndividulChangeValue',
        LINK_STATUSCHANGE: '/api/enrollment/coverageSelection/getLinkStatusChange'
    }
    const ERROR_INFO = {
        LINK_REQUEST_EFF_PERIO_CACU: props.getGlobalizedText("message_effective_date_cannot_perior_calculate"),
        LINK_REQUEST_EFF_LATER_PRO_TERMINATION: props.getGlobalizedText("enrollment.linkReqLateRequest"),
        LINK_REQUEST_EFF_PERIO_PRODUCT_EFF: props.getGlobalizedText("message_pterminate_precedes_effective_date"),
        LINK_DEP_TERMINATION_MU_EARLY_MEMBER: props.getGlobalizedText("message_deptermination_must_early_member"),
        LINK_TERMINATION_NO_LATER_PTERMINATION: props.getGlobalizedText("message_terminatedate_cannot_gl_pterminatedate"),
        TERMINATION_LATER_THEN_CACULATE_DATE: props.getGlobalizedText("enrollment.terminLessCacu"),
        TERMINATION_REQUIRED_WHEN_HAS_MEMBERTERM: props.getGlobalizedText("enrollment.termiEmpWhenPersonTer"),
        TERMINATION_REQUIRED_WHEN_HAS_DEPENDENT_TERMINATION: props.getGlobalizedText("enrollment.cov.termEmpywhenhasDepTer"),
        TERMINATION_EMPTY_WHEN_HAS_REASON: props.getGlobalizedText("coverage_text3"),
        TERMINATION_REQUIRED_WHEN_PRODUCT_HAS_TER: props.getGlobalizedText("enrollment.cov.terminWhenProductTermin"),
        INVALID_NUMBER: props.getGlobalizedText("message.form.invalidNumberFormat"),
        REQUIRED: " " + props.getGlobalizedText("message_information_required"),
        REQUEST_EFF_LATER_THAN_MEMBER_TERMINATION: props.getGlobalizedText("enrollment.requestEffLatMemberTer"),
        REQUEST_EFF_LATER_THAN_PERSON_TERMINATION: props.getGlobalizedText("message_terminatedate_cannot_gl_personTerminateDate"),
        REQUEST_EFF_LATER_THAN_TERMINATION: props.getGlobalizedText("enrollment.requestedEffLessTerm"),

        BENEFITOUT_CHK_MESSAGE_PRE: props.getGlobalizedText("message_benefit_out_date_add_prev"),
        BENEFITOUT_CHK_MESSAGE_SUF: props.getGlobalizedText("message_benefit_out_date_add_suff"),

        BENEFITOUT_UCHK_MESSAGE_PRE: props.getGlobalizedText("message_benefit_out_date_remove_prev"),
        BENEFITOUT_UCHK_MESSAGE_SUF: props.getGlobalizedText("message_benefit_out_date_remove_suff")


    }

    //const [indiEnable, setIndiEnable] = useState<Array<boolean>>([]);
    const FormTypeEnum = {
        ADDNEWMEMBER: "addMember",
        UPDATEENROLLMENT: "updateEnrollment",
        OPENENROLLMENT: "openEnrollment",
        EDITENROLLMENTM: "editEnrollmentM",
        LIFEEVENT: "lifeEvent",
        INITBENE: "initBenefitSelection",
        UPDATEPAYROLL: "updatePayroll"
    };

    const FormConstants = {
        EMPTY_LINK_STATUS_CODE: "N/A",
        BLANK_TERMINATION_REASON_CODE: "-1"
    }
    let requiredMsg = " " + props.getGlobalizedText("message_information_required");

    const mainArrayIdx = useRef<number>(0);
    const [formObject, setFormObject] = useState<any>(undefined);
    const [dateFormat, setDateFormat] = useState<string>("");
    const [contentID, setContentID] = useState<string>("");
    const coverageSelectionForm = useRef<any>();

    function getCoverageSelections(covList: Array<any>) {
        let arrayIdx: number = 0;
        let covSelItems: Array<any> = [];

        covList.forEach((cov: any, i: number) => {
            if (cov.benefitClassificationChecked === "true") {
                let covSelections: any = cov.coverageSelections;
                if (covSelections !== null && covSelections !== undefined && covSelections.length > 0) {
                    covSelections.forEach((covSelection: any, i: number) => {
                        let covSelItem: any = {};
                        covSelItem.benefitClassificationChecked = "true";
                        covSelItem.productSetID = cov.productSetID;
                        covSelItem.productSetDesc = cov.productSetDesc;
                        covSelItem.arrayIdx = arrayIdx;

                        covSelItem = mergeObject(covSelItem, covSelection);
                        if (covSelItem.requestedVolume !== null && covSelItem.requestedVolume != undefined) {
                            covSelItem.requestedVolume = getFormatNumber(covSelItem.requestedVolume);
                        }
                        covSelItems.push(covSelItem);
                        arrayIdx++;
                    });
                }
            }
        });

        return covSelItems;
    }
    function getLinks(covSelItems: any) {
        let arrayIdx: number = 0;
        let links: Array<any> = [];
        if (covSelItems !== null && covSelItems !== undefined && covSelItems.length > 0) {
            covSelItems.forEach((covSelItem: any, i: number) => {
                let covLinks = covSelItem.linkCovSelection;
                if (covLinks !== null && covLinks !== undefined && covLinks.length > 0) {
                    covLinks.forEach((covlink: any, j: number) => {
                        let linkItem: any = {};
                        linkItem.benefitClassificationChecked = "true";
                        linkItem.productSetID = covSelItem.productSetID;
                        linkItem.productSetDesc = covSelItem.productSetDesc;
                        linkItem.parentIdx = covSelItem.arrayIdx;
                        linkItem.arrayIdx = arrayIdx;
                        linkItem = mergeObject(linkItem, covlink);
                        if (linkItem.requestedVolume !== null && linkItem.requestedVolume != undefined) {
                            linkItem.requestedVolume = getFormatNumber(linkItem.requestedVolume);
                        }
                        links.push(linkItem);

                        arrayIdx++;
                    });

                }
            });

        }

        return links;
    }

    function getIndividuals(covSelItems: any) {
        let arrayIdx: number = 0;
        let individuals: Array<any> = [];
        // let newObj:any=indiEnable;
        if (covSelItems !== null && covSelItems !== undefined && covSelItems.length > 0) {
            covSelItems.forEach((covSelItem: any, i: number) => {
                let covIndividuals = covSelItem.indiCovSelection;
                if (covIndividuals !== null && covIndividuals !== undefined && covIndividuals.length > 0) {

                    covIndividuals.forEach((covIndividual: any, j: number) => {
                        let indiItem: any = {};
                        indiItem.benefitClassificationChecked = "true";
                        indiItem.productSetID = covSelItem.productSetID;
                        indiItem.productSetDesc = covSelItem.productSetDesc;
                        indiItem.parentIdx = covSelItem.arrayIdx;
                        indiItem.arrayIdx = arrayIdx;
                        indiItem = mergeObject(indiItem, covIndividual);
                        if (indiItem.plans !== null && indiItem.plans.length === 2 && indiItem.plans[0].planCode === "" && isEmptyStr(indiItem.planCode)) {
                            setPlanCodeExt(indiItem, indiItem.plans[1].planCode);
                        }
                        if (indiItem.requestedVolume !== null && indiItem.requestedVolume != undefined) {
                            indiItem.requestedVolume = getFormatNumber(indiItem.requestedVolume);
                        }
                        individuals.push(indiItem);
                        arrayIdx++;
                    });

                }
            });

        }
        //setIndiEnable(newObj);
        return individuals;
    }

    function isReleationMatchCoverageOption(relation: string, coverageOption: string, hasCoverageOption: string) {
        let matched: boolean = false;
        if (trim(hasCoverageOption) === "Y") {
            if (!isEmptyStr(coverageOption) && !isEmptyStr(relation)) {
                if (trim(relation) === "M") {
                    matched = true;
                } else if (trim(coverageOption) === "S" && trim(relation) === "S") {
                    matched = true;
                } else if (trim(coverageOption) === "C" && (trim(relation) === "C" || trim(relation) === "O")) {
                    matched = true;
                } else if (coverageOption === "F" && (trim(relation) === "C" || trim(relation) === "S" || trim(relation) === "O")) {
                    matched = true;
                }
            }
        } else {
            matched = true;
        }
        return matched;
    }
    function isLinkDivDisplay(covSelItem: any) {
        let shouldDisplay: boolean = false;
        if (covSelItem.productLinkIndicator === "1" || covSelItem.productLinkIndicator === "2" || covSelItem.productLinkIndicator === "3") {
            if (covSelItem.hasCoverageOption === "N") {
                shouldDisplay = true;
            } else if (covSelItem.hasCoverageOption === "Y" && (covSelItem.coverageOption === "E" || covSelItem.coverageOption === "S" || covSelItem.coverageOption === "C" || covSelItem.coverageOption === "F")) {
                shouldDisplay = true;
            }
        }

        return shouldDisplay;

    }
    const covert2StandDate = (strDate: string): string => {
        let formatedDate: string = trim(strDate);
        if (formatedDate !== "") {
            if (strDate.indexOf("-") === -1) {
                formatedDate = convertDate(strDate, dateFormat, "yyyy-mm-dd")
            }
        }
        return formatedDate;
    }
    const covert2UserDate = (strDate: string): string => {
        let formatedDate: string = trim(strDate);
        if (formatedDate !== "") {
            if (strDate.indexOf("/") === -1) {
                formatedDate = convertDate(strDate, "yyyy-mm-dd", dateFormat)
            }
        }
        return formatedDate;
    }
    const validRequestVolume = (volume: string): boolean => {
        volume = trim(volume).replaceAll(",", "");
        let REGQUESTED_VALUE: RegExp = new RegExp(/^\d+(\.\d+)?$/);
        return REGQUESTED_VALUE.test(volume);
    }
    const covSelectionItemValidate = (values: any, index: number, result: any): void => {


        let personTerminationDate: string = trim(formObject.memberInfo.terminationDate);

        if (values.yourdecision !== "A" && values.yourdecision !== "D") {
            result[`covSelItems[${values.arrayIdx}].yourdecision`] = props.getGlobalizedText("yourdecision") + " " + requiredMsg;
        }
        if (values.yourdecision === "A") {
            {/*plan code*/ }
            if (isEmptyStr(values.planCode) && !(props.formValue.covSelItems[values.arrayIdx].plans != null && props.formValue.covSelItems[values.arrayIdx].plans !== undefined && props.formValue.covSelItems[values.arrayIdx].plans.length === 1 && props.formValue.covSelItems[values.arrayIdx].plans[0].planCode === "0000000")) {
                result[`covSelItems[${values.arrayIdx}].planCode`] = props.getGlobalizedText("common.lbl.plan") + " " + requiredMsg;
            }
            if (values.planCode === "0000000" && props.formValue.covSelItems[values.arrayIdx].plans !== null && props.formValue.covSelItems[values.arrayIdx].plans !== undefined && props.formValue.covSelItems[values.arrayIdx].plans.length > 0) {
                props.formValue.covSelItems[values.arrayIdx].plans.forEach((plan: any, index: number) => {
                    if (plan.planCode !== "0000000" && !isEmptyStr(plan.planCode)) {
                        result[`covSelItems[${values.arrayIdx}].planCode`] = props.getGlobalizedText("common.lbl.plan") + " " + requiredMsg;
                    }
                })

            }

            {/*coveragePremium*/ }
            if (isUpdatePremiumtracking === true) {
                if (formObject.formType === FormTypeEnum.ADDNEWMEMBER || formObject.formType === FormTypeEnum.UPDATEENROLLMENT) {
                    if (isEmptyStr(values.premiumOption)) {
                        result[`covSelItems[${values.arrayIdx}].premiumOption`] = props.getGlobalizedText("common.coverage.premiumTracking") + " " + requiredMsg;
                    }
                }
            }
            {/*coverage Option*/ }
            if (values.hasCoverageOption === "Y") {
                if (isEmptyStr(values.coverageOption)) {
                    result[`covSelItems[${values.arrayIdx}].coverageOption`] = props.getGlobalizedText("billpay.premiumBilled.coverageOption") + " " + requiredMsg;
                }
            }
            {/*requested volume*/ }
            if (values.hasCoverageOption === "Y" && values.coverageOption !== "I") {
                if (values.planVolumeType === "I") {
                    if (values.requestedVolume === "") {
                        result[`covSelItems[${values.arrayIdx}].requestedVolume`] = props.getGlobalizedText("requestedvolume") + " " + requiredMsg;
                    } else if (!validRequestVolume(values.requestedVolume)) {
                        result[`covSelItems[${values.arrayIdx}].requestedVolume`] = props.getGlobalizedText("message.form.invalidNumberFormat");
                    }
                }
            }
            {/* requested eff date*/ }
            if (isEmptyStr(values.requestedEffDate)) {
                result[`covSelItems[${values.arrayIdx}].requestedEffDate`] = props.getGlobalizedText("requestedeffdate") + " " + requiredMsg;
            } else {
                if (!isEmptyStr(personTerminationDate) && covert2StandDate(personTerminationDate) < covert2StandDate(values.requestedEffDate)) {
                    result[`covSelItems[${values.arrayIdx}].requestedEffDate`] = props.getGlobalizedText("enrollment.persontermidatelaterthan.requestedEff");
                }
                if (!isEmptyStr(values.terminationDate) && covert2StandDate(values.terminationDate) < covert2StandDate(values.requestedEffDate)) {
                    result[`covSelItems[${values.arrayIdx}].requestedEffDate`] = props.getGlobalizedText("enrollment.requestedEffLessTerm");
                }
            }
            {/*temination date*/ }
            if (!isEmptyStr(values.terminationDate)) {
                if (values.calculatedEffDate !== "" && covert2StandDate(values.calculatedEffDate) > covert2StandDate(values.terminationDate)) {
                    result[`covSelItems[${values.arrayIdx}].terminationDate`] = props.getGlobalizedText("enrollment.terminLessCacu");
                } else if (!isEmptyStr(personTerminationDate) && covert2StandDate(values.terminationDate) > covert2StandDate(personTerminationDate)) {
                    result[`covSelItems[${values.arrayIdx}].terminationDate`] = props.getGlobalizedText("msg_gterminatedate_cannot_gl_personTerminate");
                } else if (!isEmptyStr(getLimitEndDate()) && covert2StandDate(values.terminationDate) < covert2StandDate(getLimitEndDate())) {
                    result[`covSelItems[${values.arrayIdx}].terminationDate`] = props.getGlobalizedText("common.limit.terminationDate.months.back") + " " + convertDate(getLimitEndDate(), "yyyy-mm-dd", dateFormat);
                }

            } else if (values.terminationReason !== "" && values.terminationReason !== FormConstants.BLANK_TERMINATION_REASON_CODE) {
                result[`covSelItems[${values.arrayIdx}].terminationDate`] = props.getGlobalizedText("coverage_text3");
            } else if (personTerminationDate !== "" && isEmptyStr(values.terminationDate)) {
                result[`covSelItems[${values.arrayIdx}].terminationDate`] = props.getGlobalizedText("enrollment.termiEmpWhenPersonTer");
            }
            {/*termination reason*/ }
            if (isEmptyStr(values.terminationReason) && values.terminationDate !== "") {
                result[`covSelItems[${values.arrayIdx}].terminationReason`] = props.getGlobalizedText("coverage_text2");
            }
            {/*link validate*/ }
            buildLinkItemValidate(values, result);
            buildIndiItemValidate(values, result);
        }
    }
    const getLinkMemberTerminationDate = (links: any, parentIdx: number): string => {
        let depTeminationDate: string = "";
        links.forEach((link: any, index: number) => {
            if (link.dependentIdx === 0 && link.parentIdx === parentIdx) {
                depTeminationDate = covert2StandDate(trim(link.terminationDate));
            }
        })
        return depTeminationDate;
    }
    const getPersonTerminationDate = (dependentIdx: number): string => {
        let personTerminationDate: string = "";
        if (dependentIdx === 0) {
            personTerminationDate = covert2StandDate(trim(formObject.memberInfo.terminationDate));
        }
        return personTerminationDate;
    }
    const getLimitEndDate = (): string => {
        let tmpDate: Date = new Date();
        let month: number = formObject.enrollmentTerminationDateLimit === undefined || formObject.enrollmentTerminationDateLimit == null ? 0 : formObject.enrollmentTerminationDateLimit;
        tmpDate.setMonth(tmpDate.getMonth() - month);
        let endDate: Date = new Date(tmpDate.getTime());
        let oyear: string = "" + endDate.getFullYear();
        let omonth: string = endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : "" + (endDate.getMonth() + 1);
        let oday: string = endDate.getDate() < 10 ? "0" + endDate.getDate() : "" + endDate.getDate();
        return oyear + "-" + omonth + "-" + oday;
    }
    const benifitOutDateLimit = (individual: any): boolean => {
        let result: boolean = false;
        let currentDate: string = formatDate("yyyy-MM-dd", new Date());
        if (formObject.dependents != null && formObject.dependents != undefined && formObject.dependents.length > 0) {
            formObject.dependents.forEach((dependent: any, idx: number) => {
                if (dependent.dependentIdx === individual.dependentIdx && "" + dependent.benefitOutDateCheck === "true") {
                    if (dependent.benefitOutDate !== null && dependent.benefitOutDate != undefined) {
                        let benefitOutDate = formatDate("yyyy-MM-dd", parseDate(dependent.benefitOutDate, dateFormat));
                        if (currentDate > benefitOutDate) {
                            result = true;
                        }
                    }

                }
            });
        }
        return result;
    }
    const benefitOutDateColor = (individual: any): string => {
        let color: string = "";
        if (formObject.dependents != null && formObject.dependents != undefined && formObject.dependents.length > 0) {
            formObject.dependents.forEach((dependent: any, idx: number) => {
                if (dependent.dependentIdx === individual.dependentIdx && "" + dependent.benefitOutDateCheck === "true") {
                    if (dependent.benefitOutDate !== null && dependent.benefitOutDate != undefined) {
                        color = "yellow";
                    }

                }
                if (dependent.dependentIdx === individual.dependentIdx && "" + dependent.benefitOutDateCheck !== "true" && "" + dependent.oldBenefitOutDateCheck === "true") {
                    color = "yellow";
                }

            });
        }
        return color;
    }
    function getFormatNumber(value: any) {
        let re = /^[0-9]+.?[0-9]*/;
        let result = value;
        if (re.test(value)) {
            result = value.toString().replaceAll(",", "");
            result = result.toLocaleString(undefined, { maximumFractionDigits: 2 });
            result = formatNumber(Number(result));
        }
        return trim(result);
    }


    const formatRequestedValue = (value: any, covItem: any): void => {
        props.formValue.covSelItems[covItem.arrayIdx] = mergeObject(props.formValue.covSelItems[covItem.arrayIdx], { "requestedVolume": getFormatNumber(value) });

        props.setFormValue(props.formValue);
    }
    const formatIndiRequestedValue = (value: any, indiItem: any): void => {
        props.formValue.individuals[indiItem.arrayIdx] = mergeObject(props.formValue.individuals[indiItem.arrayIdx], { "requestedVolume": getFormatNumber(value) });

        props.setFormValue(props.formValue);
    }

    {/*individual validation*/ }
    const buildIndiItemValidate = (covSelItem: any, result: any): void => {
        let memberTeminationDate: string = getPersonTerminationDate(0);

        if (covSelItem.yourdecision === "A"
            && props.formValue.individuals !== undefined
            && props.formValue.individuals !== null
            && props.formValue.individuals.length > 0
            && covSelItem.coverageOption === "I") {
            if (props.formValue.individuals && props.formValue.individuals !== undefined && props.formValue.individuals.length > 0) {
                props.formValue.individuals.forEach((indiItem: any, index: number) => {
                    if (indiItem.parentIdx === covSelItem.arrayIdx) {
                        if (indiItem.checked + "" === "true") {

                            if (isEmptyStr(indiItem.planCode)) {
                                result[`individuals[${indiItem.arrayIdx}].planCode`] = props.getGlobalizedText("common.lbl.plan") + " " + ERROR_INFO.REQUIRED;
                            }
                            if (indiItem.planVolumeType === "I" && isEmptyStr(indiItem.requestedVolume)) {
                                result[`individuals[${indiItem.arrayIdx}].requestedVolume`] = props.getGlobalizedText("requestedvolume") + " " + ERROR_INFO.REQUIRED;
                            } else if (indiItem.planVolumeType === "I" && !validRequestVolume(indiItem.requestedVolume)) {
                                result[`individuals[${indiItem.arrayIdx}].requestedVolume`] = ERROR_INFO.INVALID_NUMBER
                            }
                            {/*requested eff date*/ }
                            if (isEmptyStr(indiItem.requestedEffDate)) {
                                result[`individuals[${indiItem.arrayIdx}].requestedEffDate`] = props.getGlobalizedText("requestedeffdate") + " " + ERROR_INFO.REQUIRED;
                            } else {
                                if (!isEmptyStr(indiItem.requestedEffDate) && !isEmptyStr(indiItem.terminationDate) && covert2StandDate(indiItem.requestedEffDate) > covert2StandDate(indiItem.terminationDate)) {
                                    result[`individuals[${indiItem.arrayIdx}].requestedEffDate`] = ERROR_INFO.REQUEST_EFF_LATER_THAN_TERMINATION;
                                } else if (!isEmptyStr(indiItem.requestedEffDate) && !isEmptyStr(memberTeminationDate) && covert2StandDate(indiItem.requestedEffDate) > memberTeminationDate) {
                                    result[`individuals[${indiItem.arrayIdx}].requestedEffDate`] = ERROR_INFO.REQUEST_EFF_LATER_THAN_MEMBER_TERMINATION;
                                }
                            }
                            if (isEmptyStr(indiItem.linkStatusCode) && (covSelItem.productLinkIndicator === '1' || covSelItem.productLinkIndicator === '2' || covSelItem.productLinkIndicator === '3')) {
                                result[`individuals[${indiItem.arrayIdx}].linkStatusCode`] = props.getGlobalizedText("common.lbl.status") + " " + ERROR_INFO.REQUIRED;
                            }
                            {/*termination date*/ }
                            if (isEmptyStr(indiItem.terminationDate) && !isEmptyStr(indiItem.terminationReason)) {
                                result[`individuals[${indiItem.arrayIdx}].terminationDate`] = ERROR_INFO.TERMINATION_EMPTY_WHEN_HAS_REASON
                            } else if (isEmptyStr(indiItem.terminationDate) && !isEmptyStr(memberTeminationDate)) {
                                result[`individuals[${indiItem.arrayIdx}].terminationDate`] = ERROR_INFO.TERMINATION_REQUIRED_WHEN_HAS_MEMBERTERM;
                            } else if (!isEmptyStr(indiItem.terminationDate) && !isEmptyStr(indiItem.calculatedEffDate) && covert2StandDate(indiItem.terminationDate) < covert2StandDate(indiItem.calculatedEffDate)) {
                                result[`individuals[${indiItem.arrayIdx}].terminationDate`] = ERROR_INFO.TERMINATION_LATER_THEN_CACULATE_DATE;
                            } else if (isEmptyStr(indiItem.terminationDate) && !isEmptyStr(indiItem.memberTeminationDate)) {
                                result[`individuals[${indiItem.arrayIdx}].terminationDate`] = ERROR_INFO.TERMINATION_REQUIRED_WHEN_HAS_MEMBERTERM;
                            }
                        }
                    }
                });
            }
        }
    }
    const buildLinkItemValidate = (covSelItem: any, result: any): void => {
        if (covSelItem.yourdecision === "A"
            && props.formValue.links !== undefined
            && props.formValue.links !== null
            && props.formValue.links.length > 0
            && isLinkDivDisplay(covSelItem)
        ) {
            props.formValue.links.forEach((linkItem: any, index: number) => {
                let personTerminationDate: string = getPersonTerminationDate(0);;
                let depenentTerminationDate: string = "";
                let memberTeminationDate: string = "";
                if (linkItem.parentIdx === covSelItem.arrayIdx && benifitOutDateLimit(linkItem) === false) {
                    if (isReleationMatchCoverageOption(linkItem.relationShip, covSelItem.coverageOption, covSelItem.hasCoverageOption)) {
                        memberTeminationDate = getLinkMemberTerminationDate(props.formValue.links, linkItem.parentIdx)
                        depenentTerminationDate = getPersonTerminationDate(linkItem.dependentIdx);
                        if (FormConstants.EMPTY_LINK_STATUS_CODE !== linkItem.linkStatusCode) {
                            {/*link requested effdate*/ }
                            if (isEmptyStr(linkItem.linkStatusCode)) {
                                result[`links[${linkItem.arrayIdx}].linkStatusCode`] = props.getGlobalizedText("common.lbl.status") + " " + ERROR_INFO.REQUIRED;
                            }
                            if (isEmptyStr(linkItem.requestedEffDate)) {
                                result[`links[${linkItem.arrayIdx}].requestedEffDate`] = props.getGlobalizedText("common.lbl.effectiveDate") + " " + ERROR_INFO.REQUIRED;
                            } else {
                                if (!isEmptyStr(linkItem.orequestedEffDate)) {
                                    if (covert2StandDate(linkItem.orequestedEffDate) !== covert2StandDate(linkItem.requestedEffDate) && covert2StandDate(linkItem.requestedEffDate) < covert2StandDate(covSelItem.calculatedEffDate)) {
                                        result[`links[${linkItem.arrayIdx}].requestedEffDate`] = linkItem.fullName + ERROR_INFO.LINK_REQUEST_EFF_PERIO_CACU;
                                    }
                                } else {
                                    if (!isEmptyStr(covSelItem.calculatedEffDate) && covert2StandDate(linkItem.requestedEffDate) < covert2StandDate(covSelItem.calculatedEffDate)) {
                                        result[`links[${linkItem.arrayIdx}].requestedEffDate`] = linkItem.fullName + ERROR_INFO.LINK_REQUEST_EFF_PERIO_CACU;
                                    }
                                }
                                if (!isEmptyStr(linkItem.requestedEffDate) && !isEmptyStr(covSelItem.terminationDate) && covert2StandDate(linkItem.requestedEffDate) > covert2StandDate(covSelItem.terminationDate)) {
                                    result[`links[${linkItem.arrayIdx}].requestedEffDate`] = linkItem.fullName + ERROR_INFO.LINK_REQUEST_EFF_LATER_PRO_TERMINATION;
                                }
                            }
                            {/*link termination date*/ }
                            if (!isEmptyStr(linkItem.terminationDate)) {
                                if (!isEmptyStr(linkItem.requestedEffDate) && covert2StandDate(linkItem.terminationDate) < covert2StandDate(linkItem.requestedEffDate)) {
                                    result[`links[${linkItem.arrayIdx}].terminationDate`] = linkItem.fullName + ERROR_INFO.LINK_REQUEST_EFF_PERIO_PRODUCT_EFF;
                                } else if (linkItem.dependentIdx !== 0) {
                                    if (!isEmptyStr(covSelItem.terminationDate) && covert2StandDate(linkItem.terminationDate) > covert2StandDate(covSelItem.terminationDate)) {
                                        result[`links[${linkItem.arrayIdx}].terminationDate`] = linkItem.fullName + ERROR_INFO.LINK_TERMINATION_NO_LATER_PTERMINATION;
                                    } else if (!isEmptyStr(depenentTerminationDate) && covert2StandDate(linkItem.terminationDate) > covert2StandDate(depenentTerminationDate)) {
                                        result[`links[${linkItem.arrayIdx}].terminationDate`] = linkItem.fullName + ERROR_INFO.REQUEST_EFF_LATER_THAN_PERSON_TERMINATION;
                                    } else if (!isEmptyStr(memberTeminationDate) && covert2StandDate(linkItem.terminationDate) > covert2StandDate(memberTeminationDate)) {
                                        result[`links[${linkItem.arrayIdx}].terminationDate`] = ERROR_INFO.LINK_DEP_TERMINATION_MU_EARLY_MEMBER;
                                    }
                                } else if (!isEmptyStr(covSelItem.terminationDate) && covert2StandDate(linkItem.terminationDate) > covert2StandDate(covSelItem.terminationDate)) {
                                    result[`links[${linkItem.arrayIdx}].terminationDate`] = linkItem.fullName + ERROR_INFO.LINK_TERMINATION_NO_LATER_PTERMINATION;
                                }
                            } else {

                                if (!isEmptyStr(covSelItem.terminationDate)) {
                                    result[`links[${linkItem.arrayIdx}].terminationDate`] = ERROR_INFO.TERMINATION_REQUIRED_WHEN_PRODUCT_HAS_TER;
                                } else if (!isEmptyStr(memberTeminationDate)) {
                                    result[`links[${linkItem.arrayIdx}].terminationDate`] = ERROR_INFO.TERMINATION_REQUIRED_WHEN_HAS_MEMBERTERM;
                                } else if (linkItem.dependentIdx !== 0 && !isEmptyStr(depenentTerminationDate)) {
                                    result[`links[${linkItem.arrayIdx}].terminationDate`] = ERROR_INFO.TERMINATION_REQUIRED_WHEN_HAS_DEPENDENT_TERMINATION;
                                } else if (!isEmptyStr(personTerminationDate)) {
                                    result[`links[${linkItem.arrayIdx}].terminationDate`] = ERROR_INFO.TERMINATION_REQUIRED_WHEN_HAS_MEMBERTERM;
                                }



                            }
                        }
                    }
                }
            });
        }

    }
    const setPlanCodeExt = (item: any, planCode: string): void => {
        item.planCode = planCode;
        item.planVolumeType = "";
        item.planUnitValue = "";
        if (item.plans !== null && item.plans != undefined && item.plans.length > 0) {
            item.plans.forEach((plan: any, k: number) => {
                if (plan.planCode === planCode) {
                    item.planCode = planCode;
                    item.planVolumeType = plan.planVolumeType;
                    item.planUnitValue = plan.planUnitValue;
                }
            })
        }
    };
    const formValidate = (values: any): any => {
        let result: any = {};

        props.formValue.covSelItems.forEach((covSelItem: any, index: number) => {
            covSelectionItemValidate(covSelItem, index, result);

        })
        return result;
    }
    const buildCovSelRequestObject = (covItem: any): any => {
        let individuals: Array<any> = [];
        let links: Array<any> = [];
        let coverageSelection: any = {};
        let requestObject: any = {};
        coverageSelection.arrayIdx = covItem.arrayIdx;
        coverageSelection.benefitClassificationCheck = "true";
        coverageSelection.benefitClassificationIndex = covItem.benefitClassificationIndex;
        coverageSelection.productSetID = trim(covItem.productSetID);
        coverageSelection.productId = trim(covItem.productId);
        coverageSelection.coverageSelectionIdx = covItem.coverageSelectionIdx;
        coverageSelection.yourdecision = covItem.yourdecision;
        coverageSelection.planCode = trim(covItem.planCode);
        coverageSelection.planVolumeType = trim(covItem.planVolumeType);
        coverageSelection.planUnitValue = trim(covItem.planUnitValue);
        coverageSelection.requestedVolume = trim(covItem.requestedVolume);
        coverageSelection.premiumOption = trim(covItem.premiumOption);
        coverageSelection.coverageOption = trim(covItem.coverageOption);
        coverageSelection.hasCoverageOption = trim(covItem.hasCoverageOption);
        coverageSelection.requestedEffDate = trim(covItem.requestedEffDate);
        coverageSelection.calculatedEffDate = trim(covItem.calculatedEffDate);
        coverageSelection.terminationDate = trim(covItem.terminationDate);
        coverageSelection.terminationReason = trim(covItem.terminationReason);

        links = buildLinkRequestObjects(covItem.arrayIdx);
        individuals = buildIndiRequestObjects(covItem.arrayIdx);


        requestObject.transactionID = trim(props.transactionID);
        requestObject.coverageSelection = coverageSelection;
        requestObject.individuals = individuals;
        requestObject.links = links;
        return requestObject;
    }
    const buildALinkRequestObject = (link: any): any => {
        let linkObj: any = {};
        linkObj.dependentIdx = link.dependentIdx;
        linkObj.dependentSequenceNumber = link.dependentSequenceNumber;
        linkObj.linkStatusCode = trim(link.linkStatusCode);
        linkObj.requestedEffDate = covert2UserDate(trim(link.requestedEffDate));
        linkObj.terminationDate = covert2UserDate(trim(link.terminationDate));
        return linkObj;
    }
    const buildLinkRequestObjects = (parentIdx: number): any => {
        let linkObjects: Array<any> = [];
        if (props.formValue.links != null && props.formValue.links != undefined && props.formValue.links.length > 0) {
            props.formValue.links.forEach((link: any, idx: number) => {
                if (link.parentIdx === parentIdx) {
                    linkObjects.push(buildALinkRequestObject(link));
                }
            });
        }
        return linkObjects;
    }
    const buildAIndiRequestObject = (individual: any): any => {
        let indiObj: any = {};
        indiObj.checked = (individual.checked === "true" || individual.checked === true) ? "true" : "false";
        indiObj.dependentIdx = individual.dependentIdx;
        indiObj.dependentSequenceNumber = individual.dependentSequenceNumber;
        indiObj.planCode = trim(individual.planCode);
        indiObj.planVolumeType = trim(individual.planVolumeType);
        indiObj.planVolumeType = trim(individual.planVolumeType);
        indiObj.planUnitValue = trim(individual.planUnitValue);
        indiObj.requestedVolume = trim(individual.requestedVolume);
        indiObj.requestedEffDate = covert2UserDate(trim(individual.requestedEffDate));
        indiObj.calculatedEffDate = covert2UserDate(trim(individual.calculatedEffDate));
        indiObj.terminationDate = covert2UserDate(trim(individual.terminationDate));
        indiObj.terminationReason = trim(individual.terminationReason);
        indiObj.linkStatusCode = trim(individual.linkStatusCode);

        return indiObj;
    }
    const buildIndiRequestObjects = (parentIdx: number): any => {
        let individuals: Array<any> = [];
        if (props.formValue.individuals != null && props.formValue.individuals != undefined && props.formValue.individuals.length > 0) {
            props.formValue.individuals.forEach((individual: any, idx: number) => {
                if (individual.parentIdx === parentIdx) {
                    individuals.push(buildAIndiRequestObject(individual));
                }
            });
        }
        return individuals;
    }
    const setResponse2CovItem = (responseObject: any): void => {

        let benidx: number = responseObject.coverageSelection.benefitClassificationIndex;
        let covidx: number = responseObject.coverageSelection.coverageSelectionIdx;
        let item: any = {};
        if (props.formValue.covSelItems != null || props.formValue.covSelItems != undefined || props.formValue.covSelItems.length > 0) {
            props.formValue.covSelItems.forEach((covItem: any, i: number) => {

                if (covItem.benefitClassificationIndex === benidx && covItem.coverageSelectionIdx === covidx) {
                    props.formValue.covSelItems[i] = mergeObject(props.formValue.covSelItems[i], responseObject.coverageSelection);
                    props.formValue.covSelItems[i].plans = responseObject.coverageSelection.plans;
                    if (responseObject.coverageSelection.linkCovSelection != null && responseObject.coverageSelection.linkCovSelection != undefined && responseObject.coverageSelection.linkCovSelection.length > 0) {
                        responseObject.coverageSelection.linkCovSelection.forEach((dtLink: any, j: number) => {
                            setResponseLink(props.formValue.covSelItems[i].arrayIdx, dtLink);
                        })
                    }

                    if (responseObject.coverageSelection.indiCovSelection != null && responseObject.coverageSelection.indiCovSelection != undefined && responseObject.coverageSelection.indiCovSelection.length > 0) {
                        responseObject.coverageSelection.indiCovSelection.forEach((dtIndi: any, j: number) => {
                            setResponseIndi(props.formValue.covSelItems[i].arrayIdx, dtIndi);
                        })
                    }

                }
            });
            props.setFormValue({ covSelItems: props.formValue.covSelItems, links: props.formValue.links, individuals: props.formValue.individuals });
        }
    }
    const setResponseLink = (parentIdx: number, dtLink: any): void => {
        if (props.formValue.links !== null && props.formValue.links !== undefined && props.formValue.links.length > 0) {
            props.formValue.links.forEach((link: any, i: number) => {
                if (link.parentIdx === parentIdx && link.dependentIdx === dtLink.dependentIdx) {
                    props.formValue.links[i] = mergeObject(props.formValue.links[i], dtLink);
                }
            });
        }
    }
    const setResponseIndi = (parentIdx: number, dtIndi: any): void => {
        if (props.formValue.individuals !== null && props.formValue.individuals !== undefined && props.formValue.individuals.length > 0) {
            props.formValue.individuals.forEach((indiItem: any, i: number) => {
                if (indiItem.parentIdx === parentIdx && indiItem.dependentIdx === dtIndi.dependentIdx) {
                    props.formValue.individuals[i] = mergeObject(props.formValue.individuals[i], dtIndi);
                    props.formValue.individuals[i].plans = dtIndi.plans;
                }
            });
        }
    }
    const buildIndiRequestObject = (covItem: any, individ: any): any => {
        let requestObject: any = {};

        let coverageSelection: any = {};
        coverageSelection.arrayIdx = covItem.arrayIdx;
        coverageSelection.productSetID = trim(covItem.productSetID);
        coverageSelection.productId = trim(covItem.productId);
        coverageSelection.benefitClassificationCheck = "true";
        coverageSelection.benefitClassificationIndex = covItem.benefitClassificationIndex;
        coverageSelection.coverageSelectionIdx = covItem.coverageSelectionIdx;
        coverageSelection.yourdecision = covItem.yourdecision;
        coverageSelection.planCode = trim(covItem.planCode);
        coverageSelection.planVolumeType = trim(covItem.planVolumeType);
        coverageSelection.planUnitValue = trim(covItem.planUnitValue);
        coverageSelection.requestedVolume = trim(covItem.requestedVolume);
        coverageSelection.premiumOption = trim(covItem.premiumOption);
        coverageSelection.coverageOption = trim(covItem.coverageOption);
        coverageSelection.hasCoverageOption = trim(covItem.hasCoverageOption);
        coverageSelection.requestedEffDate = covert2UserDate(trim(covItem.requestedEffDate));
        coverageSelection.calculatedEffDate = covert2UserDate(trim(covItem.calculatedEffDate));
        coverageSelection.terminationDate = covert2UserDate(trim(covItem.terminationDate));
        coverageSelection.terminationReason = covert2UserDate(trim(covItem.terminationReason));



        let individual: any = {};
        individual.parentIdx = covItem.arrayIdx;
        individual.checked = trim("" + individ.checked);
        individual.dependentIdx = individ.dependentIdx;
        individual.dependentSequenceNumber = individ.dependentSequenceNumber;
        individual.planCode = trim(individ.planCode);
        individual.planVolumeType = trim(individ.planVolumeType);
        individual.planVolumeType = trim(individ.planVolumeType);
        individual.planUnitValue = trim(individ.planUnitValue);
        individual.requestedVolume = trim(individ.requestedVolume);
        individual.requestedEffDate = covert2UserDate(trim(individ.requestedEffDate));
        individual.calculatedEffDate = covert2UserDate(trim(individ.calculatedEffDate));
        individual.terminationDate = covert2UserDate(trim(individ.terminationDate));
        individual.terminationReason = trim(individ.terminationReason);
        individual.linkStatusCode = trim(individ.linkStatusCode);

        requestObject.transactionID = trim(props.transactionID);
        requestObject.coverageSelection = coverageSelection;
        requestObject.individual = individual;

        return requestObject;
    }
    const setResponse2Indi = (indiItem: any, arrayIdx: number): void => {
        if (indiItem !== null && indiItem !== undefined) {
            props.formValue.individuals[arrayIdx] = mergeObject(props.formValue.individuals[arrayIdx], indiItem);
            props.setFormValue({ covSelItems: props.formValue.covSelItems, links: props.formValue.links, individuals: props.formValue.individuals });
        }
    }
    const buildLinkRequestObject = (covItem: any, dependCov: any): any => {
        let coverageSelection: any = {};
        coverageSelection.arrayIdx = covItem.arrayIdx;
        coverageSelection.productSetID = covItem.productSetID;
        coverageSelection.productId = covItem.productId;
        coverageSelection.benefitClassificationCheck = "true";
        coverageSelection.benefitClassificationIndex = covItem.benefitClassificationIndex;
        coverageSelection.coverageSelectionIdx = covItem.coverageSelectionIdx;
        coverageSelection.yourdecision = covItem.yourdecision;
        coverageSelection.planCode = trim(covItem.planCode);
        coverageSelection.planVolumeType = trim(covItem.planVolumeType);
        coverageSelection.planUnitValue = trim(covItem.planUnitValue);
        coverageSelection.requestedVolume = trim(covItem.requestedVolume);
        coverageSelection.premiumOption = trim(covItem.premiumOption);
        coverageSelection.coverageOption = trim(covItem.coverageOption);
        coverageSelection.hasCoverageOption = trim(covItem.hasCoverageOption);
        coverageSelection.requestedEffDate = covert2UserDate(trim(covItem.requestedEffDate));
        coverageSelection.calculatedEffDate = covert2UserDate(trim(covItem.calculatedEffDate));
        coverageSelection.terminationDate = covert2UserDate(trim(covItem.terminationDate));
        coverageSelection.terminationReason = covert2UserDate(trim(covItem.terminationReason));



        let link: any = {};
        link.parentIdx = covItem.arrayIdx;
        link.dependentIdx = dependCov.dependentIdx;
        link.dependentSequenceNumber = dependCov.dependentSequenceNumber;
        link.linkStatusCode = trim(dependCov.linkStatusCode);


        link.requestedEffDate = covert2UserDate(trim(dependCov.requestedEffDate));
        link.terminationDate = covert2UserDate(trim(dependCov.terminationDate));

        let requestObject: any = {};
        requestObject.transactionID = trim(props.transactionID);
        requestObject.coverageSelection = coverageSelection;
        requestObject.link = link;

        return requestObject;
    }
    const setResponse2Link = (linkItem: any, arrayIdx: number): void => {
        if (linkItem !== null && linkItem !== undefined) {
            props.formValue.links[arrayIdx] = mergeObject(props.formValue.links[arrayIdx], linkItem);
            props.setFormValue({ covSelItems: props.formValue.covSelItems, links: props.formValue.links, individuals: props.formValue.individuals });
        }
    }
    const setCovSelItemMinTermination = (covSelItem: any): void => {
        let tempArr: Array<any> = new Array<any>();
        let minTermAndReason: any = {};
        if ("A" === trim(covSelItem.yourdecision)) {
            minTermAndReason.terminationDate = covert2StandDate(trim(formObject.memberInfo.terminationDate));
            minTermAndReason.terminationReason = trim(formObject.memberInfo.terminationReason);
            if (minTermAndReason.terminationDate !== "") {
                tempArr.push(minTermAndReason);
            }
            minTermAndReason = new Array<any>();
            minTermAndReason.terminationDate = covert2StandDate(trim(covSelItem.terminationDate));
            minTermAndReason.terminationReason = trim(covSelItem.terminationReason);
            if (minTermAndReason.terminationDate !== "") {
                tempArr.push(minTermAndReason);
            }
            if (tempArr.length >= 1) {
                tempArr.sort((a: any, b: any) => {
                    if (a.terminationDate > b.terminationDate) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                });
                props.formValue.covSelItems[covSelItem.arrayIdx].terminationDate = covert2UserDate(tempArr[0].terminationDate);
                props.formValue.covSelItems[covSelItem.arrayIdx].terminationReason = tempArr[0].terminationReason;

            }
        }
    }
    const getMinCovSelItemMinTermination = (covSelItem: any): Date | undefined => {
        let minDate: string = "1000-12-31";
        let memTermDate: string = covert2StandDate(trim(formObject.memberInfo.terminationDate));
        let cacuDate: string = covert2StandDate(trim(covSelItem.calculatedEffDate));
        let limitEndDate: string = getLimitEndDate();
        let tempArr: Array<string> = new Array<string>();
        if (memTermDate !== "") {
            tempArr.push(memTermDate);
        }
        if (cacuDate !== "") {
            tempArr.push(cacuDate);
        }
        tempArr.push(minDate);
        tempArr.push(limitEndDate);
        tempArr.sort((a: any, b: any) => {
            if (a.terminationDate > b.terminationDate) {
                return 1;
            }
            else {
                return 0;
            }
        });
        return parseDate(tempArr[tempArr.length - 1], "yyyy-mm-dd");
    }
    const setIndiItemMinTermination = (indiVidual: any): void => {
        let tempArr: Array<any> = new Array<any>();
        let minTermAndReason: any = new Array<any>();
        if ("true" === "" + indiVidual.checked) {
            minTermAndReason.terminationDate = covert2StandDate(trim(formObject.memberInfo.terminationDate));
            minTermAndReason.terminationReason = trim(formObject.memberInfo.terminationReason);
            if (minTermAndReason.terminationDate !== "") {
                tempArr.push(minTermAndReason);
            }
            minTermAndReason = new Array<any>();
            minTermAndReason.terminationDate = "";
            minTermAndReason.terminationReason = "";

            if (formObject.dependents !== null && formObject.dependents != undefined && formObject.dependents.length > 0) {
                formObject.dependents.forEach((dependent: any, idx: number) => {
                    if (dependent.dependentIdx === indiVidual.dependentIdx) {
                        minTermAndReason.terminationDate = covert2StandDate(trim(dependent.terminationDate));
                        minTermAndReason.terminationReason = trim(dependent.terminationReason);
                    }
                });
            }
            if (minTermAndReason.terminationDate !== "") {
                tempArr.push(minTermAndReason);
            }
            if (tempArr.length >= 1) {
                tempArr.sort((a: any, b: any) => {
                    if (a.terminationDate > b.terminationDate)
                        return 1;
                    else
                        return 0;
                });
                props.formValue.individuals[indiVidual.arrayIdx].terminationDate = covert2UserDate(tempArr[0].terminationDate);
                props.formValue.individuals[indiVidual.arrayIdx].terminationReason = tempArr[0].terminationReason;
            }
        }
    }
    {/*your decision change */ }
    const getYourDecisionChange = (covItem: any): void => {
        ajax({
            method: 'post',
            showMask: true,
            url: PAGE_URL.GET_YOUR_DESISION_CHANGE,
            data: buildCovSelRequestObject(covItem),
            success: (res) => {
                setResponse2CovItem(res);
                if (trim(covItem.oyourDecision) !== "A" && trim(covItem.yourdecision) === "D") {
                    coverageSelectionForm.current.validateForm(coverageSelectionForm.current.values)
                }

            }
        });
    }
    {/*cov plan change*/ }
    const getPlanChange = (covItem: any): void => {

        ajax({
            method: 'post',
            showMask: false,
            url: PAGE_URL.PLAN_CHANGE,
            data: buildCovSelRequestObject(covItem),
            success: (res) => {
                setResponse2CovItem(res);
            }
        });
    }
    {/*request volumn change*/ }
    const getRequestedVolumeChange = (value: any, covItem: any): void => {

        ajax({
            method: 'post',
            showMask: true,
            url: PAGE_URL.REQUEST_VOLUMN_CHANGE,
            data: buildCovSelRequestObject(covItem),
            success: (res) => {
                setResponse2CovItem(res);
                formatRequestedValue(value, covItem);
            }
        });
    }
    {/*request coverage change*/ }
    const getCoverageOptionChange = (covItem: any): void => {

        ajax({
            method: 'post',
            showMask: true,
            url: PAGE_URL.COVERAGE_OPTION_CHANGE,
            data: buildCovSelRequestObject(covItem),
            success: (res) => {
                setResponse2CovItem(res);
            }
        });
    }
    {/*request eff change*/ }
    const getRequestedEffDateChange = (covItem: any): void => {

        ajax({
            method: 'post',
            showMask: false,
            url: PAGE_URL.REQUEST_DATE_CHANGE,
            data: buildCovSelRequestObject(covItem),
            success: (res) => {
                setResponse2CovItem(res);
            }
        });
    }
    {/*termination date change*/ }
    const getTerminationDateChange = (covItem: any): void => {

        ajax({
            method: 'post',
            showMask: false,
            url: PAGE_URL.TERMINATION_DATE_CHANGE,
            data: buildCovSelRequestObject(covItem),
            success: (res) => {
                setResponse2CovItem(res);
            }
        });
    }
    {/*individual  change*/ }
    const getIndividulChangeValue = (covItem: any, individual: any): void => {
        ajax({
            method: 'post',
            showMask: false,
            url: PAGE_URL.INDIVIDUAL_CHANGE,
            data: buildIndiRequestObject(covItem, individual),
            success: (res) => {
                setResponse2Indi(res.depentCovSelection, individual.arrayIdx);
            }
        });
    }
    {/*getLinkStatusChange*/ }
    const getLinkStatusChange = (covItem: any, link: any): void => {

        ajax({
            method: 'post',
            showMask: false,
            url: PAGE_URL.LINK_STATUSCHANGE,
            data: buildLinkRequestObject(covItem, link),
            success: (res) => {
                setResponse2Link(res.depentCovSelection, link.arrayIdx);
            }
        });
    }
    {/*get benefit out message*/ }
    const getBenfitOutMessage = (covItem: any): Array<string> => {
        let benefitOutMessagefix: string = ERROR_INFO.BENEFITOUT_CHK_MESSAGE_PRE;
        let benefitOutMessagesuf: string = ERROR_INFO.BENEFITOUT_CHK_MESSAGE_SUF;
        let benefitOutUncheckMsgpre: string = ERROR_INFO.BENEFITOUT_UCHK_MESSAGE_PRE;
        let benefitOutUncheckMsgsuf: string = ERROR_INFO.BENEFITOUT_UCHK_MESSAGE_SUF;
        let messages: Array<string> = [];
        if (covItem.linkCovSelection != null && covItem.linkCovSelection != undefined && covItem.linkCovSelection.length > 0) {
            covItem.linkCovSelection.forEach((linkItem: any, idx: number) => {
                if (isReleationMatchCoverageOption(linkItem.relationShip, covItem.coverageOption, covItem.hasCoverageOption) && benifitOutDateLimit(linkItem) == false) {
                    if (formObject.dependents !== null && formObject.dependents !== undefined && formObject.dependents.length > 0) {
                        formObject.dependents.forEach((dependent: any, idx: number) => {
                            if (
                                dependent.dependentIdx === linkItem.dependentIdx &&
                                "" + dependent.benefitOutDateCheck === "true" &&
                                !isEmptyStr(dependent.benefitOutDate)
                            ) {
                                messages.push(benefitOutMessagefix + linkItem.fullName + benefitOutMessagesuf);
                            }
                            if (
                                dependent.dependentIdx === linkItem.dependentIdx &&
                                "" + dependent.oldBenefitOutDateCheck === "true" &&
                                "" + dependent.benefitOutDateCheck != "true"
                            ) {
                                messages.push(benefitOutUncheckMsgpre + linkItem.fullName + benefitOutUncheckMsgsuf);
                            }
                        })
                    }
                }
            });
        }
        return messages;
    }
    {/*benifitout termination*/ }
    const getBenfitOutTerminationClass = (linkItem: any): any => {
        let style: any = {};
        if (formObject.dependents !== null && formObject.dependents !== undefined && formObject.dependents.length > 0) {
            formObject.dependents.forEach((dependent: any, idx: number) => {
                if (
                    dependent.dependentIdx === linkItem.dependentIdx &&
                    (dependent.benefitOutDateCheck === "true" || dependent.benefitOutDateCheck === true) &&
                    !isEmptyStr(dependent.benefitOutDate)
                ) {
                    style = { "background-color": "yellow!important" };
                }
            })
        }
        return style;
    }
    const setPlanCodeExtAll = (covSelItems: any, individuals: any): void => {
        if (covSelItems !== null && covSelItems !== undefined) {
            covSelItems.forEach((covSelItem: any, i: number) => {
                if (covSelItem.yourdecision === "A") {
                    if (covSelItem.planCode !== "") {
                        if (covSelItem.plans !== null && covSelItem.plans !== undefined) {
                            covSelItem.plans.forEach((plan: any, j: number) => {
                                if (plan.planCode === covSelItem.planCode) {
                                    covSelItem.planVolumeType = plan.planVolumeType;
                                    covSelItem.planUnitValue = plan.planUnitValue;
                                }
                            });
                        }
                    }


                    {/* individual */ }
                    individuals.forEach((individual: any, k: number) => {
                        if ("" + individual.checked === "true" && individual.parentIdx === covSelItem.arrayIdx) {
                            if (individual.planCode !== "") {
                                if (individual.plans !== null && individual.plans !== undefined) {
                                    individual.plans.forEach((plan: any, j: number) => {
                                        if (plan.planCode === individual.planCode) {
                                            individual.planVolumeType = plan.planVolumeType;
                                            individual.planUnitValue = plan.planUnitValue;
                                        }
                                    });
                                }
                            }

                        }
                    });
                }

            });
        }
    }
    props.setPrepareData((values: any) => {
        setPlanCodeExtAll(values.covSelItems, values.individuals);
        if (values.covSelItems !== null && values.covSelItems !== undefined && values.covSelItems.length > 0) {
            values.covSelItems.forEach((covSelItem: any, i: number) => {
                if (covSelItem.requestedVolume !== null && covSelItem.requestedVolume !== undefined) {
                    covSelItem.requestedVolume = covSelItem.requestedVolume.replaceAll(",", "");
                }
            });
        }
        if (values.individuals !== null && values.individuals !== undefined && values.individuals.length > 0) {
            values.individuals.forEach((indi: any, i: number) => {
                if (indi.requestedVolume !== null && indi.requestedVolume !== undefined) {
                    indi.requestedVolume = indi.requestedVolume.replaceAll(",", "");
                }
                if (indi.checked === null || indi.checked === undefined || isEmptyStr(indi.checked)) {
                    indi.checked = "false";
                }
            });
        }
        if (values.links !== null && values.links !== undefined && values.links.length > 0) {
            values.links.forEach((link: any, i: number) => {
                if (link.requestedVolume !== null && link.requestedVolume !== undefined) {
                    link.requestedVolume = link.requestedVolume.replaceAll(",", "");
                }
            });
        }
        return {
            "transactionID": props.transactionID,
            "covSelItems": values.covSelItems,
            "links": values.links,
            "individuals": values.individuals

        }

    });
    props.setOperationCallback({
        next: {
            fail: (res: any, message: string): void => {
                props.showFieldError("homepage.errorFields", convertErrorMessages(res.validMessage), coverageSelectionForm.current);
            }
        }
    });
    const convertErrorMessages = (errorMessages: Array<any>): any => {
        let result: any = {};
        errorMessages.forEach((e: any) => {
            result[e.fieldName] = e.message;
        });
        return result;
    }
    props.setValidation(formValidate);
    useEffect(() => {
        if (props.formValue === undefined || formObject === undefined) {
            let params = {
                transactionID: props.transactionID
            };
            ajax({
                url: '/api/enrollment/coverageSelection/view',
                params: params,
                success: (res: any) => {
                    let covSelItems = getCoverageSelections(res.formObject.covList);
                    let links = getLinks(covSelItems);
                    let individuals = getIndividuals(covSelItems);
                    props.setFormValue({ covSelItems: covSelItems, links: links, individuals: individuals });


                    setDateFormat(res.dateFormat);
                    setFormObject(res.formObject);

                }
            });
            ajax({
                url: '/api/cms/content/getByTypeName/enrollmentHelpCoverage',
                params: params,
                success: (res: any) => {
                    if (res.id !== null && res !== undefined) {
                        setContentID(res.id);
                    }

                },
                error: (res: any): void => {

                }
            });
        }
    }, [formObject])
    if (formObject === undefined || props.formValue === undefined || props.formValue.length === 0 || props.formValue.covSelItems === undefined) {
        return <></>
    }
    return <>
        <props.Panel>
            <props.PanelHeading>
                <RequiredHeader label="coverageSel_title" {...props} />
            </props.PanelHeading>
            <props.PanelBody>
                <props.Row>
                    <props.Col xs="12" md="12" sm="12" >
                        <props.Span >
                            <props.Label><GlobalizedText message="message_coverage_text1" /></props.Label>
                        </props.Span>
                    </props.Col>
                </props.Row>
                <FieldArray name="covSelItems" render={arrayHelpers => {
                    coverageSelectionForm.current = arrayHelpers.form;
                    if (props.formValue === undefined || props.formValue.length === 0 || props.formValue.covSelItems === undefined || props.formValue.covSelItems === null) {
                        return <></>;
                    }
                    return props.formValue.covSelItems.map((covSelItem: any, i: number) => {
                        setCovSelItemMinTermination(covSelItem);

                        return <React.Fragment key={`covItem${covSelItem.arrayIdx}`}>
                            <props.Row>
                                <props.Col xs="12" md="12" sm="12" >
                                    <props.Div className="stepTitle">
                                        <props.Condition condition={formObject.hasProductSetID === "Y"}>
                                            <props.Span className="reginnertitle">{covSelItem.productSetDesc} - {covSelItem.productName}</props.Span>
                                        </props.Condition >
                                        <props.Condition condition={formObject.hasProductSetID !== "Y"}>
                                            <props.Span className="reginnertitle">{covSelItem.productName}</props.Span>
                                        </props.Condition>
                                        <props.Div className="pull-right">                                         
                                        </props.Div>
                                    </props.Div>
                                </props.Col>
                            </props.Row>
                            <props.Row>
                                <props.Col xs="12" md="6" sm="6" >
                                    <props.SelectControl arrayHelpers={arrayHelpers}
                                        name={`covSelItems[${covSelItem.arrayIdx}].yourdecision`} required={true}
                                        id={`covSelItems[${covSelItem.arrayIdx}].yourdecision`}
                                        label="yourdecision" sort={true} defaultOption="common.lbl.pleaseselect" options={
                                            (formObject.checkBoxMap == null || formObject.checkBoxMap.CVGDEC.length === 0) ? formObject.checkBoxMap : formObject.checkBoxMap.CVGDEC.map((record: any, index: number) => { return { name: record.name, value: record.value } })
                                        } disabled={covSelItem.requiredInGIAS === "Y"}
                                        onChange={(e: any) => {
                                            props.formValue.covSelItems[covSelItem.arrayIdx].yourdecision = e.target.value;
                                            if (e.target.value !== "") {
                                                getYourDecisionChange(props.formValue.covSelItems[covSelItem.arrayIdx]);
                                            }
                                        }}
                                    ></props.SelectControl>

                                    <props.Condition condition={covSelItem.plans !== null && covSelItem.plans !== undefined && !(covSelItem.plans.length == 1 && covSelItem.plans[0].planCode === "0000000")}>
                                        <props.SelectControl arrayHelpers={arrayHelpers}
                                            name={`covSelItems[${covSelItem.arrayIdx}].planCode`}
                                            id={`covSelItems[${covSelItem.arrayIdx}].planCode`}
                                            required={true} label="common.lbl.plan" sort={true} defaultOption="common.lbl.pleaseselect" options={
                                                (covSelItem.plans == null || covSelItem.plans.length === 0) ? [] : covSelItem.plans.map((record: any) => { return { name: record.planDesc, value: record.planCode } })
                                            } disabled={props.formValue.covSelItems[covSelItem.arrayIdx].yourdecision != 'A'}
                                            onChange={(e: any) => {
                                                props.formValue.covSelItems[covSelItem.arrayIdx].planCode = e.target.value;
                                                setPlanCodeExt(props.formValue.covSelItems[covSelItem.arrayIdx], e.target.value);
                                                if (!isEmptyStr(e.target.value)) {
                                                    getPlanChange(props.formValue.covSelItems[covSelItem.arrayIdx]);
                                                }
                                            }}
                                        ></props.SelectControl>
                                    </props.Condition>
                                    <props.Condition condition={props.formValue.covSelItems[covSelItem.arrayIdx].yourdecision === "A" && (props.formValue.covSelItems[covSelItem.arrayIdx].planVolumeType === "I") && props.formValue.covSelItems[covSelItem.arrayIdx].coverageOption !== "I"}>
                                        <props.TextControl arrayHelpers={arrayHelpers}
                                            name={`covSelItems[${covSelItem.arrayIdx}].requestedVolume`}
                                            id={`covSelItems[${covSelItem.arrayIdx}].requestedVolume`}
                                            required={true} label="requestedvolume"
                                            onBlur={(e: any) => {
                                                getRequestedVolumeChange(e.target.value, covSelItem);

                                            }}
                                            disabeled={trim(covSelItem.yourdecision) !== "A" || (formObject.securityMap.updateVolume === false && !isEmptyStr(covSelItem.orequestedVolume)) || covSelItem.giasUnitFreezeInd === "Y"} />
                                    </props.Condition>
                                    <props.Condition condition={(trim(formObject.formType) === FormTypeEnum.ADDNEWMEMBER || trim(formObject.formType) === FormTypeEnum.UPDATEENROLLMENT) && isUpdatePremiumtracking === true}>
                                        <props.Label className="gwp-label-required"><GlobalizedText message="common.coverage.premiumTracking" /></props.Label>
                                        {
                                            covSelItem.coveragePremiumTrackingOpts.map((coveragePremiumTrackingOpt: any, j: number) => {

                                                return <React.Fragment key={"covSelItems" + covSelItem.arrayIdx + "FGPremiumOption_" + j}>
                                                    <props.RadioControl arrayHelpers={arrayHelpers}
                                                        name={`covSelItems[${covSelItem.arrayIdx}].premiumOption`}
                                                        value={coveragePremiumTrackingOpt.key}
                                                        label={coveragePremiumTrackingOpt.label}
                                                        className="beneChkBox"
                                                        disabled={covSelItem.yourdecision !== "A"}
                                                        id={`covSelItems[${covSelItem.arrayIdx}].premiumOption${j}`}
                                                    ></props.RadioControl>
                                                </React.Fragment>
                                            })
                                        }
                                    </props.Condition>
                                    <props.Condition condition={covSelItem.hasCoverageOption === "Y"}>
                                        <props.Label className="gwp-label-required"><GlobalizedText message="billpay.premiumBilled.coverageOption" /></props.Label>
                                        {
                                            covSelItem.coverageOptions.map((coverageOption: any, j: number) => {
                                                return <React.Fragment key={"covSelItems" + covSelItem.arrayIdx + "FGCoverageOption_" + j}>
                                                    <props.RadioControl arrayHelpers={arrayHelpers}
                                                        name={`covSelItems[${covSelItem.arrayIdx}].coverageOption`}
                                                        id={`covSelItems[${covSelItem.arrayIdx}].coverageOption${j}`}
                                                        value={coverageOption.value}
                                                        label={coverageOption.name} disabled={covSelItem.yourdecision !== "A" || trim(covSelItem.ocoverageOption) === "I"}
                                                        className="beneChkBox"
                                                        onChange={(e: any) => {
                                                            props.formValue.covSelItems[covSelItem.arrayIdx].coverageOption = e.target.value;
                                                            getCoverageOptionChange(props.formValue.covSelItems[covSelItem.arrayIdx]);
                                                        }}
                                                    ></props.RadioControl>
                                                </React.Fragment>
                                            })
                                        }
                                    </props.Condition>
                                </props.Col>

                                <props.Col xs="12" md="6" sm="6" >

                                    <props.Condition condition={covSelItem.displayWaitPeriodMsg === true}>
                                        <props.Div>
                                            <props.Span className="glyphicon glyphicon-exclamation-sign"></props.Span><props.Label htmlFor={`covSelItems[${covSelItem.arrayIdx}].requestedEffDate`}><GlobalizedText message="message_request_effective_waiting" /></props.Label>
                                        </props.Div>
                                    </props.Condition>
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers}
                                        name={`covSelItems[${covSelItem.arrayIdx}].requestedEffDate`}
                                        id={`covSelItems[${covSelItem.arrayIdx}].requestedEffDate`}
                                        required={true} label="requestedeffdate"
                                        disabled={covSelItem.yourdecision != 'A' || formObject.formType === FormTypeEnum.ADDNEWMEMBER}
                                        onDateChange={(date?: Date, dateStr?: string) => {
                                            if (!isEmptyStr(formatDate(dateFormat, date))) {
                                                props.formValue.covSelItems[covSelItem.arrayIdx].requestedEffDate = formatDate(dateFormat, date);
                                                getRequestedEffDateChange(props.formValue.covSelItems[covSelItem.arrayIdx]);
                                            }
                                        }}

                                        dateformat={dateFormat} />

                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers}
                                        name={`covSelItems[${covSelItem.arrayIdx}].calculatedEffDate`}
                                        id={`covSelItems[${covSelItem.arrayIdx}].calculatedEffDate`}
                                        required={true} label="calculatedeffdate" disabled={true} dateformat={dateFormat} />
                                    <props.Condition condition={!(formObject.securityMap.allowMemberProductTerminationOnRequiredProducts !== true && covSelItem.requiredInGIAS === "Y")}>
                                        <props.DateTimePickerControl arrayHelpers={arrayHelpers}
                                            name={`covSelItems[${covSelItem.arrayIdx}].terminationDate`}
                                            id={`covSelItems[${covSelItem.arrayIdx}].terminationDate`}
                                            label="common.lbl.terminationDate" disabled={
                                                covSelItem.yourdecision !== "A" ||
                                                formObject.securityMap.updateMemberPorductTerminationDateReason !== true ||
                                                (covSelItem.requiredInGIAS == 'Y' &&
                                                    formObject.securityMap.allowMemberProductTerminationOnRequiredProducts !== true
                                                )


                                            } dateformat={dateFormat}
                                            onDateChange={(date?: Date, dateStr?: string) => {

                                                props.formValue.covSelItems[covSelItem.arrayIdx].terminationDate = formatDate(dateFormat, date);
                                                getTerminationDateChange(props.formValue.covSelItems[covSelItem.arrayIdx]);

                                            }}
                                            min={getMinCovSelItemMinTermination(covSelItem)}
                                        />
                                        <props.SelectControl arrayHelpers={arrayHelpers}
                                            name={`covSelItems[${covSelItem.arrayIdx}].terminationReason`}
                                            id={`covSelItems[${covSelItem.arrayIdx}].terminationReason`}
                                            label="terminationreason" sort={true} defaultOption="common.lbl.pleaseselect" options={
                                                (formObject.checkBoxMap == null || formObject.checkBoxMap.GIAS_CODE_03_COVERAGE.length === 0) ? formObject.checkBoxMap.GIAS_CODE_03_COVERAGE : formObject.checkBoxMap.GIAS_CODE_03_COVERAGE.map((record: any) => { return { name: record.name, value: record.value } })
                                            } disabled={
                                                covSelItem.yourdecision !== "A" ||
                                                formObject.securityMap.updateMemberPorductTerminationDateReason !== true ||
                                                (covSelItem.requiredInGIAS == 'Y' &&
                                                    formObject.securityMap.allowMemberProductTerminationOnRequiredProducts !== true
                                                )}
                                        ></props.SelectControl>
                                    </props.Condition>
                                </props.Col>

                            </props.Row>
                            <props.HR />
                            {/* link begin*/}
                            <props.Condition condition={isLinkDivDisplay(covSelItem)}>

                                <props.Condition condition={getBenfitOutMessage(covSelItem).length > 0}>
                                    <props.Row>
                                        <props.Col xs="12" sm="12" md="12"  >
                                            <props.Div className="alert alert-warning gwp-page-message">
                                                {

                                                    getBenfitOutMessage(covSelItem).map((messageItem: any, j: number) => {
                                                        return <div >{messageItem}</div>
                                                    })
                                                }
                                            </props.Div>
                                        </props.Col>
                                    </props.Row>
                                </props.Condition>
                                <props.Row>
                                    <FieldArray name="links" render={arrayHelpers => {
                                        if (props.formValue === undefined || props.formValue.length === 0 || props.formValue.links === undefined || dateFormat === null || dateFormat === "") {
                                            return <></>;
                                        }
                                        return props.formValue.links.map((linkItem: any, j: number) => {

                                            return <React.Fragment key={`linkItem${covSelItem.arrayIdx}_${linkItem.arrayIdx}_${j}`}>
                                                <props.Condition condition={
                                                    linkItem.parentIdx === covSelItem.arrayIdx &&
                                                    isReleationMatchCoverageOption(linkItem.relationShip, covSelItem.coverageOption, covSelItem.hasCoverageOption) === true &&
                                                    benifitOutDateLimit(linkItem) === false
                                                }>



                                                    <props.Col xs="12" sm="12" md="12" className="visible-xs visible-sm" >

                                                        <props.HR />
                                                    </props.Col>
                                                    <props.Col xs="12" sm="12" md="2">
                                                        <props.Div className="form-group">
                                                            <props.Condition condition={linkItem.dependentIdx === 0}>
                                                                <props.Label htmlFor={`links[${linkItem.arrayIdx}].linkStatusCode`}><GlobalizedText message="common.lbl.status" /></props.Label>
                                                            </props.Condition>
                                                            <props.Condition condition={linkItem.dependentIdx !== 0}>
                                                                <props.Div className="visible-xs ">
                                                                    <props.Label htmlFor={`links[${linkItem.arrayIdx}].linkStatusCode`}><GlobalizedText message="common.lbl.status" /></props.Label>
                                                                </props.Div>
                                                            </props.Condition>
                                                            <props.SelectControl arrayHelpers={arrayHelpers} name={`links[${linkItem.arrayIdx}].linkStatusCode`} required={true} options={
                                                                (formObject.checkBoxMap == null || formObject.checkBoxMap.PRODUCT_LINK_STATUS.length === 0) ? formObject.checkBoxMap.PRODUCT_LINK_STATUS : formObject.checkBoxMap.PRODUCT_LINK_STATUS.map((record: any) => { return { name: record.name, value: record.value } })
                                                            } disabled={covSelItem.yourdecision !== "A"}
                                                                onChange={(e: any) => {
                                                                    props.formValue.links[linkItem.arrayIdx].linkStatusCode = e.target.value;
                                                                    if (!isEmptyStr(e.target.value)) {
                                                                        getLinkStatusChange(covSelItem, linkItem);
                                                                    }
                                                                }}
                                                                sort={true} defaultOption="common.lbl.pleaseselect"
                                                            ></props.SelectControl>
                                                        </props.Div>
                                                    </props.Col>
                                                    <props.Col xs="12" sm="12" md="4">
                                                        <props.Div className="form-group">
                                                            <props.Condition condition={linkItem.dependentIdx === 0}>
                                                                <props.Label htmlFor={`txlinkfullname_${covSelItem.arrayIdx}_${linkItem.arrayIdx}`}><GlobalizedText message="common.lbl.name" /></props.Label>
                                                            </props.Condition>
                                                            <props.Condition condition={linkItem.dependentIdx !== 0}>
                                                                <props.Div className="visible-xs visible-sm">
                                                                    <props.Label htmlFor={`txlinkfullname_${covSelItem.arrayIdx}_${linkItem.arrayIdx}`}><GlobalizedText message="common.lbl.name" /></props.Label>
                                                                </props.Div>
                                                            </props.Condition>
                                                            <props.Div className="linkTextField" id={`txlinkfullname_${covSelItem.arrayIdx}_${linkItem.arrayIdx}`}>{linkItem.fullName}</props.Div>
                                                        </props.Div>
                                                    </props.Col>
                                                    <props.Col xs="12" sm="12" md="2">
                                                        <props.Div className="form-group">
                                                            <props.Condition condition={linkItem.dependentIdx === 0}>
                                                                <props.Label htmlFor={`txlinkRelation_${covSelItem.arrayIdx}_${linkItem.arrayIdx}`}><GlobalizedText message="pi.relation" /></props.Label>
                                                            </props.Condition>
                                                            <props.Condition condition={linkItem.dependentIdx !== 0}>
                                                                <props.Div className="visible-xs visible-sm">
                                                                    <props.Label htmlFor={`txlinkRelation_${covSelItem.arrayIdx}_${linkItem.arrayIdx}`}><GlobalizedText message="pi.relation" /></props.Label>
                                                                </props.Div>
                                                            </props.Condition>
                                                            <props.Div className="linkTextField" id={`txlinkRelation_${covSelItem.arrayIdx}_${linkItem.arrayIdx}`}>{linkItem.relationShipDesc}</props.Div>
                                                        </props.Div>
                                                    </props.Col>
                                                    <props.Col xs="12" sm="12" md="2">
                                                        <props.Div className="form-group">
                                                            <props.Condition condition={linkItem.dependentIdx === 0}>
                                                                <props.Label htmlFor={`links[${linkItem.arrayIdx}].requestedEffDate`}><GlobalizedText message="common.lbl.effectiveDate" /></props.Label>
                                                            </props.Condition>
                                                            <props.Condition condition={linkItem.dependentIdx !== 0}>
                                                                <props.Div className="visible-xs visible-sm">
                                                                    <props.Label htmlFor={`links[${linkItem.arrayIdx}].requestedEffDate`}><GlobalizedText message="common.lbl.effectiveDate" /></props.Label>
                                                                </props.Div>
                                                            </props.Condition>
                                                            <props.DateTimePickerControl arrayHelpers={arrayHelpers} name={`links[${linkItem.arrayIdx}].requestedEffDate`} dateformat={dateFormat}
                                                                disabled={covSelItem.yourdecision !== "A" || linkItem.linkStatusCode === "N/A" || !isEmptyStr(linkItem.orequestedEffDate)} />
                                                        </props.Div>
                                                    </props.Col>
                                                    <props.Col xs="12" sm="12" md="2">
                                                        <props.Div className="form-group">
                                                            <props.Condition condition={linkItem.dependentIdx === 0}>
                                                                <props.Label htmlFor={`links[${linkItem.arrayIdx}].terminationDate`}><GlobalizedText message="common.lbl.terminationDate" /></props.Label>
                                                            </props.Condition>
                                                            <props.Condition condition={linkItem.dependentIdx !== 0}>
                                                                <props.Div className="visible-xs visible-sm">
                                                                    <props.Label htmlFor={`links[${linkItem.arrayIdx}].terminationDate`}><GlobalizedText message="common.lbl.terminationDate" /></props.Label>
                                                                </props.Div>
                                                            </props.Condition>
                                                            <props.DateTimePickerControl arrayHelpers={arrayHelpers}
                                                                name={`links[${linkItem.arrayIdx}].terminationDate`} dateformat={dateFormat}
                                                                id={`links[${linkItem.arrayIdx}].terminationDate`}
                                                                disabled={covSelItem.yourdecision !== "A" || (linkItem.linkStatusCode === "N/A" && isEmptyStr(linkItem.orequestedEffDate))}
                                                                backgroundColor={benefitOutDateColor(linkItem)}
                                                            />
                                                        </props.Div>
                                                    </props.Col>
                                                </props.Condition>
                                            </React.Fragment>
                                        })
                                    }
                                    } />
                                </props.Row>

                            </props.Condition>
                            {/*link end*/}
                            {/* individual begin*/}

                            <props.Condition condition={props.formValue.individuals !== undefined && props.formValue.individuals !== null && covSelItem.coverageOption === "I"}>

                                <FieldArray name="individuals" render={arrayHelpers => {
                                    if (props.formValue === undefined || props.formValue.length === 0 || props.formValue.individuals === undefined || props.formValue.individuals === null || dateFormat === null || dateFormat === "") {
                                        return <></>;
                                    }
                                    return props.formValue.individuals.map((individual: any, k: number) => {
                                        setIndiItemMinTermination(individual);

                                        return <React.Fragment key={`indi_${k}`}>
                                            <props.Condition condition={individual.parentIdx === covSelItem.arrayIdx && benifitOutDateLimit(individual) === false}>
                                                <props.Condition condition={k !== 0}>
                                                    <props.HR></props.HR>
                                                </props.Condition>
                                                <props.Row>
                                                    <props.Col xs="12" sm="6" md="6">
                                                        <props.CheckboxControl arrayHelpers={arrayHelpers}
                                                            name={`individuals[${individual.arrayIdx}].checked`}
                                                            id={`individuals[${individual.arrayIdx}].checked`}
                                                            label={individual.fullName} className="indiCheckbox"

                                                            value={"true"} disabled={individual.dependentIdx === 0 || covSelItem.yourdecision !== "A"}
                                                            onChange={(e: any) => {
                                                                individual.checked = (e.target.checked === true ? "true" : "false");
                                                                props.formValue.individuals[individual.arrayIdx].checked = e.target.checked === true ? "true" : "false";
                                                                getIndividulChangeValue(covSelItem, props.formValue.individuals[individual.arrayIdx]);
                                                            }
                                                            }
                                                        ></props.CheckboxControl>
                                                        <props.Condition condition={!(individual.plans !== null && individual.plans !== undefined && individual.plans.length == 1 && individual.plans[0].planCode === "0000000")}>
                                                            <props.SelectControl arrayHelpers={arrayHelpers} name={`individuals[${individual.arrayIdx}].planCode`} required={true} options={
                                                                (individual.plans == null || individual.plans.length === 0) ? [] :
                                                                    individual.plans.map((record: any) => {
                                                                        return { name: record.planDesc, value: record.planCode, planVolumeType: record.planVolumeType }
                                                                    })
                                                            } label={`${props.getGlobalizedText("common.lbl.plan")}${individual.dependentIdx}`}
                                                                onChange={(e: any) => {
                                                                    setPlanCodeExt(props.formValue.individuals[individual.arrayIdx], e.target.value);
                                                                    getIndividulChangeValue(covSelItem, individual);
                                                                }} disabled={(individual.checked !== "true" && individual.checked !== true) || covSelItem.yourdecision !== "A"}
                                                                sort={false}
                                                            >
                                                            </props.SelectControl>
                                                        </props.Condition>
                                                        <props.Condition condition={individual.planVolumeType == 'I'}>
                                                            <props.TextControl arrayHelpers={arrayHelpers}
                                                                name={`individuals[${individual.arrayIdx}].requestedVolume`}
                                                                id={`individuals[${individual.arrayIdx}].requestedVolume`}
                                                                required={true} label="requestedvolume"
                                                                disabled={
                                                                    (individual.checked !== "true" && individual.checked !== true) ||
                                                                    covSelItem.yourdecision !== "A" ||
                                                                    (formObject.securityMap.updateVolume === false && !isEmptyStr(covSelItem.orequestedVolume)) ||
                                                                    covSelItem.giasUnitFreezeInd === "Y"
                                                                }
                                                                onBlur={(e: any) => {
                                                                    formatIndiRequestedValue(e.target.value, individual);
                                                                }}
                                                            />
                                                        </props.Condition>
                                                    </props.Col>
                                                    <props.Col xs="12" sm="6" md="6">
                                                        <props.Condition condition={individual.displayWaitPeriodMsg === true}>
                                                            <props.Div>
                                                                <props.Span className="glyphicon glyphicon-exclamation-sign"></props.Span><props.Label htmlFor={`covSelItems[${covSelItem.arrayIdx}].requestedEffDate`}><GlobalizedText message="message_request_effective_waiting" /></props.Label>
                                                            </props.Div>
                                                        </props.Condition>

                                                        <props.DateTimePickerControl arrayHelpers={arrayHelpers}
                                                            name={`individuals[${individual.arrayIdx}].requestedEffDate`}
                                                            id={`individuals[${individual.arrayIdx}].requestedEffDate`}
                                                            required={true} dateformat={dateFormat} label="common.lbl.effectiveDate"
                                                            disabled={(individual.checked !== "true" && individual.checked !== true) ||
                                                                covSelItem.yourdecision !== "A" ||
                                                                trim(formObject.formType) === FormTypeEnum.ADDNEWMEMBER
                                                            }
                                                            onDateChange={(date?: Date, dateStr?: string) => {
                                                                props.formValue.individuals[individual.arrayIdx].requestedEffDate = formatDate(dateFormat, date);
                                                                getIndividulChangeValue(covSelItem, props.formValue.individuals[individual.arrayIdx]);
                                                            }}
                                                        />
                                                        <props.DateTimePickerControl arrayHelpers={arrayHelpers}
                                                            name={`individuals[${individual.arrayIdx}].calculatedEffDate`}
                                                            id={`individuals[${individual.arrayIdx}].calculatedEffDate`}
                                                            required={true} dateformat={dateFormat} label="calculatedeffdate" disabled={true} />
                                                        <props.Condition condition={
                                                            covSelItem.productLinkIndicator === '1' ||
                                                            covSelItem.productLinkIndicator === '2' ||
                                                            covSelItem.productLinkIndicator === '3'
                                                        }>
                                                            <props.SelectControl arrayHelpers={arrayHelpers}
                                                                name={`individuals[${individual.arrayIdx}].linkStatusCode`}
                                                                id={`individuals[${individual.arrayIdx}].linkStatusCode`}
                                                                required={true}
                                                                options={
                                                                    (formObject.checkBoxMap == null || formObject.checkBoxMap.PRODUCT_LINK_STATUS.length === 0) ? formObject.checkBoxMap.PRODUCT_LINK_STATUS : formObject.checkBoxMap.PRODUCT_LINK_STATUS.map((record: any) => { return { name: record.name, value: record.value } })
                                                                } label="common.lbl.status" disabled={(individual.checked !== "true" && individual.checked !== true) || covSelItem.yourdecision !== "A"}
                                                                sort={false}
                                                            ></props.SelectControl>
                                                        </props.Condition>
                                                        <props.Condition condition={!(formObject.securityMap.allowMemberProductTerminationOnRequiredProducts !== true && covSelItem.requiredInGIAS === "Y")}>
                                                            <props.DateTimePickerControl arrayHelpers={arrayHelpers}
                                                                name={`individuals[${individual.arrayIdx}].terminationDate`}
                                                                id={`individuals[${individual.arrayIdx}].terminationDate`}
                                                                dateformat={dateFormat} label="common.lbl.terminationDate"
                                                                disabled={
                                                                    (individual.checked !== "true" && individual.checked !== true) ||
                                                                    covSelItem.yourdecision !== "A" ||
                                                                    formObject.securityMap.updateMemberPorductTerminationDateReason !== true
                                                                }
                                                                onDateChange={(date?: Date, dateStr?: string) => {
                                                                    props.formValue.individuals[individual.arrayIdx].terminationDate = formatDate(dateFormat, date);
                                                                    getIndividulChangeValue(covSelItem, props.formValue.individuals[individual.arrayIdx]);
                                                                }}
                                                            />
                                                            <props.SelectControl arrayHelpers={arrayHelpers}
                                                                name={`individuals[${individual.arrayIdx}].terminationReason`}
                                                                id={`individuals[${individual.arrayIdx}].terminationReason`}
                                                                label="terminationreason" sort={false} options={
                                                                    (formObject.checkBoxMap == null || formObject.checkBoxMap.GIAS_CODE_03_COVERAGE.length === 0) ? formObject.checkBoxMap.GIAS_CODE_03_COVERAGE : formObject.checkBoxMap.GIAS_CODE_03_COVERAGE.map((record: any) => { return { name: record.name, value: record.value } })
                                                                } disabled={
                                                                    (individual.checked !== "true" && individual.checked !== true) ||
                                                                    covSelItem.yourdecision !== "A" ||
                                                                    formObject.securityMap.updateMemberPorductTerminationDateReason !== true
                                                                }></props.SelectControl>
                                                        </props.Condition>
                                                    </props.Col>
                                                </props.Row>
                                            </props.Condition>
                                        </React.Fragment>
                                    })
                                }
                                } />
                            </props.Condition>

                            {/* individual end*/}
                        </React.Fragment>
                    })
                }
                } />
            </props.PanelBody>
        </props.Panel>

    </>
}