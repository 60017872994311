import { Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import * as yup from 'yup';
import { TableCell, useTable } from "../shared/components";
import GlobalizedText from '../shared/globalization';
import { Message } from '../shared/message';
import { ajax, leftAlignWithZero, isMobileDevice, mergeObject, validate } from '../shared/utils';
import { ViewComponentProps } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import './css/wallet.css';
let walletVarForm: any
let walletCcTableData: any
let walletEftTableData: any
let walletCompanyCode: any
let walletPolicyNumber: any
let walletContinueBtnFlag: any
let walletShowAddLink: any
let walletHasData: any
let walletMocksavecardUrl: any
let walletDataNum: any
let walletSelectCC: any
let walletSelectBank: any
let walletDelBankAccountNumber: any;
let walletDelBankBranchNumber: any;
let walletDelBankRoutingNumber: any;
let walletDeleteCcToken: any;
let walletDeleteProfileID: any;
let walletDelMerchant: any;
let walletSavedSelectedCCRowNum: number = -1;
let walletSavedSelectedBankRowNum: number = -1;
let walletSubmit: any = "";
let walletMessageService: any = {};
let walletOriginPaymentCode: any;
export interface WalletProps extends ViewComponentProps {
    walletDto?: any,
    paymentCode?: any,
    preferInfo?: any,
    handleWalletResult?: (walletData: any) => void
}

export const PAYMENT_METHOD_EFT: string = 'ET'
export const PAYMENT_METHOD_CREDITCARD: string = 'CC';
export const PAYMENT_METHOD_PREMIUM_NOTICE: string = 'IN';

export function translateCreditCardType(ccType: any) {
    ccType = ccType.trim()
    let creditCardTypeDesc: any
    if (ccType === "MC") {
        creditCardTypeDesc = "MasterCard"
    }
    else if (ccType === "DISC") {
        creditCardTypeDesc = "Discover"
    }
    else if (ccType === "VISA") {
        creditCardTypeDesc = "VISA"
    }
    return creditCardTypeDesc;
}

export function updateSelected(walletDto: any, pm: string, preferInfo: any) {

    if (pm === PAYMENT_METHOD_CREDITCARD) {
        walletDto.ccWallet.items = walletDto.ccWallet.items.map((cc: any) => {
            if (cc.clientProfileID === preferInfo.get("preClientProfileID") &&
                cc.creditCardToken === preferInfo.get("preCreditCardToken") &&
                cc.merchant === preferInfo.get("preMerchant")
            ) {
                return mergeObject(cc, { selected: "true" });
            }
            return cc;
        });
    } else {
        walletDto.eftWallet.items = walletDto.eftWallet.items.map((eft: any) => {
            if (
                eft.bankRoutingNumber === preferInfo.get("preRoutingNumber") &&
                eft.bankAccountNumber === preferInfo.get("preAccountNumber")
            ) {
                return mergeObject(eft, { selected: "true" });
            }
            return eft;
        });
    }

    return walletDto;
}

export function Wallet(props: WalletProps) {
    const events = useRef<Array<any>>([]);
    walletMessageService = props.preferInfo.get("messageService4Edit")
    walletDataNum = 0;
    walletCcTableData = props.walletDto.ccWallet.items
    walletEftTableData = props.walletDto.eftWallet.items
    walletSelectCC = null
    walletSelectBank = null
    let walletTmpSelectedCCRowNum: number = -1;
    let walletTmpSelectedBankRowNum: number = -1;
    walletCompanyCode = props.preferInfo.get("companyCode")
    walletPolicyNumber = props.preferInfo.get("policyNumber")
    walletOriginPaymentCode = props.preferInfo.get("originPaymentCode")
    if (walletEftTableData.length > 0) {
        walletCompanyCode = props.walletDto.eftWallet.items[0].companyCode
        walletPolicyNumber = props.walletDto.eftWallet.items[0].policyNumber
        walletDataNum = walletEftTableData.length

        walletSavedSelectedBankRowNum = getPreferredEftRowID();

        if (walletSavedSelectedBankRowNum !== -1) {
            walletContinueBtnFlag = true;
            walletSelectBank = walletEftTableData[walletSavedSelectedBankRowNum];
        } else {
            walletContinueBtnFlag = false;
        }

    }
    if (walletCcTableData.length > 0) {
        walletCompanyCode = props.walletDto.ccWallet.items[0].companyCode
        walletPolicyNumber = props.walletDto.ccWallet.items[0].policyNumber
        walletDataNum = walletCcTableData.length

        walletSavedSelectedCCRowNum = getPreferredCCRowID();

        if (walletSavedSelectedCCRowNum !== -1) {
            walletContinueBtnFlag = true;
            walletSelectCC = walletCcTableData[walletSavedSelectedCCRowNum];
        } else {
            walletContinueBtnFlag = false;
        }
    }

    if (walletDataNum >= 10) {
        walletShowAddLink = false;
    } else {
        walletShowAddLink = true;
    }
    if (walletDataNum > 0) {
        walletHasData = true;
    } else {
        walletHasData = false;
    }

    function clickRadio(rowID: number, typeStr: any) {
        changeContinueBtn(rowID);
        changeCancelBtn(rowID);
        if (typeStr === PAYMENT_METHOD_CREDITCARD) {
            walletTmpSelectedCCRowNum = rowID;
        } else {
            walletTmpSelectedBankRowNum = rowID;
        }
    }
    const ccTableWallet = useTable({
        id: 'ccTableWallet',
        modal: "wallet",
        option: {
            paging: true,
            lengthChange: false,
            scrollCollapse: true,
            language: { emptyTable: props.getGlobalizedText("common.no.data") },
            pageLength: 5
        },
        select: {
            type: 'single',
            prefix: 'wallet_cc_',
            onSelect: (e, selectedRows) => {
                clickRadio(selectedRows[0], 'eft');
            },
            selected: (() => {
                let res: Array<number> = [];
                for (let i = 0; i < walletCcTableData.length; i++) {
                    if (walletSavedSelectedCCRowNum !== -1 && walletSavedSelectedCCRowNum === i) {
                        res[0] = i;
                        break;
                    }
                }
                return res;
            })()
        },
        onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element | null) => {
            if (isMobileDevice()) {
                let rowID = rowData.DT_RowId;//DT_RowId: "wallet_tr_1"
                rowData = walletCcTableData[rowID.substring(10)];
            }
            childNode?.querySelector("#deletableCC")?.addEventListener("click", () => { showDeleteCc(rowData); });
            childNode?.querySelector("#updateCC")?.addEventListener("click", () => { getPaymentCreditCardURL(rowData); });
        },
        children: [
            <thead key={0}>
                <tr>
                    <th className="gwp-dt-select-all" data-sorting={false}><GlobalizedText message="common.lbl.select" /></th>
                    <th scope="col" data-sorting={false}><GlobalizedText message="common.lbl.cred.card.type" /></th>
                    <th scope="col" data-sorting={false}><GlobalizedText message="common.msg.payment.nameOnCard" /></th>
                    <th scope="col" data-sorting={false}><GlobalizedText message="common.lbl.creditcardnumber" /></th>
                    <th scope="col" data-sorting={false}><GlobalizedText message="common.lbl.expirationDate" /></th>
                    <th scope="col" data-sorting={false}><GlobalizedText message="common.lbl.editdele" /></th>
                </tr>
            </thead>,
            <tbody key={1}>
                {walletCcTableData !== null && walletCcTableData.map((row: any, index: number) => {
                    return <tr key={index} id={`wallet_tr_${index}`}>
                        <td>
                            <input type="radio" name="ccRadio" id={`wallet_cc_${index}`} />
                        </td>
                        <td>
                            {(row.deletable === "false" && row.editable === "false") &&
                                <b>{row.creditCardTypeDesc}</b>
                            }
                            {(row.deletable !== "false" || row.editable !== "false") &&
                                <div>{row.creditCardTypeDesc}</div>
                            }
                        </td>
                        <td>
                            {(row.deletable === "false" && row.editable === "false") &&
                                <b>{row.accountHolderName}</b>
                            }
                            {(row.deletable !== "false" || row.editable !== "false") &&
                                <div>{row.accountHolderName}</div>
                            }
                        </td>
                        <td>
                            {(row.deletable === "false" && row.editable === "false") &&
                                <b>{"xxxxxxxxxxxx" + row.ccNumber}</b>
                            }
                            {(row.deletable !== "false" || row.editable !== "false") &&
                                <div>{"xxxxxxxxxxxx" + row.ccNumber}</div>
                            }
                        </td>
                        <td>
                            {(row.deletable === "false" && row.editable === "false") &&
                                <b>{row.ccExpireDateDesc}</b>
                            }
                            {(row.deletable !== "false" || row.editable !== "false") &&
                                <div>{row.ccExpireDateDesc}</div>
                            }
                        </td>
                        <td>
                            {(row.editable === "false") &&
                                <span><span className="glyphicon glyphicon-pencil" ></span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            }
                            {(row.editable === "true") &&
                                <span><props.Link id='updateCC' to='' onClick={() => { getPaymentCreditCardURL(row) }}><span className="glyphicon glyphicon-pencil" ></span></props.Link>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            }
                            {(row.deletable === "false") &&
                                <span className="glyphicon glyphicon-trash" ></span>
                            }
                            {(row.deletable === "true") &&
                                <props.Link id='deletableCC' to='' onClick={() => { showDeleteCc(row) }}><span className="glyphicon glyphicon-trash" ></span></props.Link>
                            }
                        </td>
                    </tr>
                })
                }
            </tbody >
        ]
    });

    const eftTableWallet = useTable({
        id: 'eftTableWallet',
        modal: "wallet",
        option: {
            paging: true,
            lengthChange: false,
            scrollCollapse: true,
            language: { emptyTable: props.getGlobalizedText("common.no.data") },
            pageLength: 5
        },
        select: {
            type: 'single',
            prefix: 'wallet_eft_',
            onSelect: (e, selectedRows, unSelectedRows) => {
                clickRadio(selectedRows[0], 'eft');
            },
            selected: (() => {
                let res: Array<number> = [];
                for (let i = 0; i < walletEftTableData.length; i++) {
                    if (walletSavedSelectedBankRowNum !== -1 && walletSavedSelectedBankRowNum === i) {
                        res[0] = i;
                        break;
                    }
                }
                return res;
            })()
        },
        onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element | null) => {
            if (isMobileDevice()) {
                let rowID = rowData.DT_RowId;//DT_RowId: "wallet_tr_1"
                rowData = walletEftTableData[rowID.substring(10)];
            }
            childNode?.querySelector("#deletableEFT")?.addEventListener("click", () => { showDeleteBB(rowData); });
            childNode?.querySelector("#updateBankAccountEFT")?.addEventListener("click", () => { showEditBankAccount(rowData, "editBankAccount"); });

        },
        children: [
            <thead key={0}>
                <tr>
                    <th className="gwp-dt-select-all" data-sorting={false}><GlobalizedText message="common.lbl.select" /></th>
                    <th scope="col" data-sorting={false}><GlobalizedText message="common.msg.payment.nameOnAccount" /></th>
                    <th scope="col" data-sorting={false}><GlobalizedText message="common.lbl.bankName" /></th>
                    <th scope="col" data-sorting={false}><GlobalizedText message="payment.lbl.accountNumber" /></th>
                    <th scope="col" data-sorting={false}><GlobalizedText message="payment.lbl.accountType" /></th>
                    <th scope="col" data-sorting={false}><GlobalizedText message="common.lbl.editdele" /></th>
                </tr>
            </thead>,
            <tbody key={1}>
                {walletEftTableData !== null && walletEftTableData.map((row: any, index: number) => {
                    return <tr key={index} id={`wallet_tr_${index}`}>
                        <td>
                            <input type="radio" name="eftRadio"
                                id={`wallet_eft_${index}`}
                            />
                        </td>
                        <td>
                            {(row.deletable === "false" && row.editable === "false") &&
                                <b>{row.accountHolderName}</b>
                            }
                            {(row.deletable !== "false" || row.editable !== "false") &&
                                <div>{row.accountHolderName}</div>
                            }
                        </td>
                        <td>
                            {(row.deletable === "false" && row.editable === "false") &&
                                <b>{row.bankName}</b>
                            }
                            {(row.deletable !== "false" || row.editable !== "false") &&
                                <div>{row.bankName}</div>
                            }
                        </td>
                        <td>
                            {(row.deletable === "false" && row.editable === "false") &&
                                <b>{row.bankAccountNumber}</b>
                            }
                            {(row.deletable !== "false" || row.editable !== "false") &&
                                <div>{row.bankAccountNumber}</div>
                            }
                        </td>
                        <td>
                            {(row.deletable === "false" && row.editable === "false") &&
                                <b>{row.accountTypeDesc}</b>
                            }
                            {(row.deletable !== "false" || row.editable !== "false") &&
                                <div>{row.accountTypeDesc}</div>
                            }
                        </td>
                        <td>
                            {(row.editable === "false") &&
                                <span><span className="glyphicon glyphicon-pencil" ></span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            }
                            {(row.editable === "true") &&
                                <span><props.Link id='updateBankAccountEFT' to='' onClick={() => { showEditBankAccount(row, "editBankAccount") }}><span className="glyphicon glyphicon-pencil" ></span></props.Link>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            }
                            {(row.deletable === "false") &&
                                <span className="glyphicon glyphicon-trash" ></span>
                            }
                            {(row.deletable === "true") &&
                                <props.Link id='deletableEFT' to='' onClick={() => { showDeleteBB(row) }}><span className="glyphicon glyphicon-trash" ></span></props.Link>
                            }
                        </td>
                    </tr>
                })
                }
            </tbody >
        ]
    });
    function showEditBankAccount(row: any, option: any) {
        props.closeModal("#wallet", () => props.showModal("#editBank"));
        walletVarForm.setFieldValue("nameTypeEdit", row.nameType);
        walletVarForm.setFieldValue("firstNameEdit", row.firstName);
        walletVarForm.setFieldValue("lastNameEdit", row.lastName);
        walletVarForm.setFieldValue("bankNameEdit", row.bankName);
        walletVarForm.setFieldValue("bankRoutingNumberEdit", leftAlignWithZero(row.bankRoutingNumber, 9));
        walletVarForm.setFieldValue("bankAccountNumberEdit", row.bankAccountNumber);
        walletVarForm.setFieldValue("bankBranchNumber", row.bankBranchNumber);
        walletVarForm.setFieldValue("accountTypeEdit", row.accountType);
        walletVarForm.setFieldValue("obankRoutingNumber", leftAlignWithZero(row.bankRoutingNumber, 9));
        walletVarForm.setFieldValue("obankAccountNumber", row.bankAccountNumber);
        walletVarForm.setFieldValue("obankBranchNumber", row.bankBranchNumber);
        walletVarForm.setFieldValue("obankAccountNumber", row.bankAccountNumber);
        walletVarForm.setFieldValue("obankBranchNumber", row.bankBranchNumber);
        walletVarForm.setFieldValue("currencyCode", row.currencyCode);
        walletVarForm.setFieldValue("middleName", row.middleName);
        walletVarForm.setFieldValue("companyNameEdit", row.companyName);
        walletVarForm.setFieldValue("action", option);
        walletVarForm.setFieldValue("companyCode", row.companyCode);
        walletVarForm.setFieldValue("policyNumber", row.policyNumber);
        walletVarForm.setFieldValue("checkDigit", row.checkDigit);
        props.clearMessage();

    }
    function showAddBankAccount(row: any, option: any) {
        props.closeModal("#wallet", () => props.showModal("#addBank"));

        walletVarForm.setFieldValue("action", option);
        walletVarForm.setFieldValue("firstNameAdd", "");
        walletVarForm.setFieldValue("lastNameAdd", "");
        walletVarForm.setFieldValue("bankNameAdd", "");
        walletVarForm.setFieldValue("bankRoutingNumberAdd", "");
        walletVarForm.setFieldValue("bankAccountNumberAdd", "");

    }
    function showDeleteBB(row: any) {
        props.closeModal("#wallet", () => props.showModal("#deleteBank"));
        walletVarForm.setFieldValue("firstName", row.firstName);
        walletVarForm.setFieldValue("lastName", row.lastName);
        walletVarForm.setFieldValue("companyName", row.companyName);
        walletVarForm.setFieldValue("nameType", row.nameType);
        walletVarForm.setFieldValue("bankName", row.bankName);
        walletVarForm.setFieldValue("bankAccountNumber", row.bankAccountNumber);
        walletVarForm.setFieldValue("bankBranchNumber", row.bankBranchNumber);
        walletVarForm.setFieldValue("bankRoutingNumber", row.bankRoutingNumber);

    }
    function comfirmDelBB() {
        walletDelBankAccountNumber = walletVarForm.values.bankAccountNumber;
        walletDelBankBranchNumber = walletVarForm.values.bankBranchNumber;
        walletDelBankRoutingNumber = walletVarForm.values.bankRoutingNumber;
        ajax({
            url: `/api/wallet/deleteBankAccount/`,
            method: 'delete',
            params: {
                bankAccountNumber: walletVarForm.values.bankAccountNumber,
                bankBranchNumber: walletVarForm.values.bankBranchNumber,
                bankRoutingNumber: walletVarForm.values.bankRoutingNumber,
                policyNumber: walletPolicyNumber,
                companyCode: walletCompanyCode,
            },
            success: (res: any) => {
                props.closeModal("#deleteBank");
                let returnParam = new Map();
                walletSavedSelectedBankRowNum = -1;
                returnParam.set("deleteBank", true);
                walletShowAddLink = true;
                walletDataNum--;
                if (walletDataNum === 0) {
                    walletHasData = false;
                    returnParam.set("walletHasBB", false);
                    walletContinueBtnFlag = false;
                } else {
                    walletHasData = true;
                    returnParam.set("walletHasBB", true);
                    walletContinueBtnFlag = true;
                }
                if (props.handleWalletResult) {
                    props.handleWalletResult(returnParam);
                }
            }
        })
    }
    function showDeleteCc(row: any) {
        props.closeModal("#wallet", () => props.showModal("#deleteCc"));
        walletVarForm.setFieldValue("creditCardToken", row.creditCardToken);
        walletVarForm.setFieldValue("merchant", row.merchant);
        walletVarForm.setFieldValue("clientProfileID", row.clientProfileID);
        walletVarForm.setFieldValue("creditCardType", row.creditCardType);
        walletVarForm.setFieldValue("ccNumber", row.ccNumber);
        walletVarForm.setFieldValue("expirationDate", row.ccExpirationMonth + "/" + row.ccExpirationYear);

    }
    function comfirmDelCC() {
        walletDeleteCcToken = walletVarForm.values.creditCardToken;
        walletDeleteProfileID = walletVarForm.values.clientProfileID;
        walletDelMerchant = walletVarForm.values.merchant;
        ajax({
            url: `/api/wallet/deleteCreditCard/`,
            method: 'delete',
            params: {
                creditCardToken: walletVarForm.values.creditCardToken,
                merchant: walletVarForm.values.merchant,
                clientProfileID: walletVarForm.values.clientProfileID,
                policyNumber: walletPolicyNumber,
                companyCode: walletCompanyCode,
            },
            success: (res: any) => {
                props.closeModal("#deleteCc");
                walletSavedSelectedCCRowNum = -1;
                let returnParam = new Map();
                returnParam.set("deleteCc", true);
                walletShowAddLink = true;
                walletDataNum--;
                if (walletDataNum === 0) {
                    walletHasData = false;
                    walletContinueBtnFlag = false;
                    returnParam.set("walletHasCC", false);
                } else {
                    walletHasData = true;
                    walletContinueBtnFlag = true;
                    returnParam.set("walletHasCC", true);
                }
                if (props.handleWalletResult) {
                    props.handleWalletResult(returnParam);
                }
            }
        })
    }
    const byAddSchema = yup.object().shape({
        firstNameAdd: YupSchema.changePaymentMethod.firstName,
        lastNameAdd: YupSchema.changePaymentMethod.lastName,
        bankNameAdd: YupSchema.changePaymentMethod.bankName,
        bankRoutingNumberAdd: YupSchema.changePaymentMethod.routingNumber,
        bankAccountNumberAdd: YupSchema.changePaymentMethod.accountNumber
    });
    const byAddComSchema = yup.object().shape({
        companyNameAdd: YupSchema.changePaymentMethod.companyName,
        bankNameAdd: YupSchema.changePaymentMethod.bankName,
        bankRoutingNumberAdd: YupSchema.changePaymentMethod.routingNumber,
        bankAccountNumberAdd: YupSchema.changePaymentMethod.accountNumber
    });
    const byEditSchema = yup.object().shape({
        firstNameEdit: YupSchema.changePaymentMethod.firstName,
        lastNameEdit: YupSchema.changePaymentMethod.lastName,
        bankNameEdit: YupSchema.changePaymentMethod.bankName,
        bankRoutingNumberEdit: YupSchema.changePaymentMethod.routingNumber,
        bankAccountNumberEdit: YupSchema.changePaymentMethod.accountNumber
    });
    const byEditComSchema = yup.object().shape({
        companyNameEdit: YupSchema.changePaymentMethod.companyName,
        bankNameEdit: YupSchema.changePaymentMethod.bankName,
        bankRoutingNumberEdit: YupSchema.changePaymentMethod.routingNumber,
        bankAccountNumberEdit: YupSchema.changePaymentMethod.accountNumber
    });
    function submit() {
        if (walletSubmit === "addBankAccount") {
            addBankAccount();
        } else if (walletSubmit === "editBankAccount") {
            editBankAccount();
        }
        else if (walletSubmit === "comfirmDelCC") {
            comfirmDelCC();
        } else if (walletSubmit === "comfirmDelBB") {
            comfirmDelBB();
        }
    }
    function editBankAccount() {
        ajax({
            url: `/api/wallet/saveBankAccount/`,
            method: 'post',
            params: {
                accountType: walletVarForm.values.accountTypeEdit === undefined ? "C" : walletVarForm.values.accountTypeEdit,
                bankName: walletVarForm.values.bankNameEdit,
                nameType: walletVarForm.values.nameTypeEdit,
                bankAccountNumber: walletVarForm.values.bankAccountNumberEdit,
                checkDigit: walletVarForm.values.checkDigit === "" ? walletVarForm.values.bankRoutingNumberEdit : walletVarForm.values.checkDigit,
                bankBranchNumber: walletVarForm.values.bankBranchNumber === undefined ? 0 : walletVarForm.values.bankBranchNumber,
                bankRoutingNumber: walletVarForm.values.bankRoutingNumberEdit,
                policyNumber: walletPolicyNumber,
                companyCode: walletCompanyCode,
                action: "editBankAccount",
                obankRoutingNumber: walletVarForm.values.obankRoutingNumber,
                obankBranchNumber: walletVarForm.values.obankBranchNumber,
                obankAccountNumber: walletVarForm.values.obankAccountNumber,
                firstName: walletVarForm.values.firstNameEdit,
                lastName: walletVarForm.values.lastNameEdit,
                companyName: walletVarForm.values.companyNameEdit,
                middleName: walletVarForm.values.middleNameEdit,
                currencyCode: walletVarForm.values.currencyCode
            },
            success: (res: any) => {
                props.closeModal("#editBank");
                props.closeModal("#addBank");
                let returnParam = new Map();
                returnParam.set("saveBank", true);
                if (props.handleWalletResult) {
                    props.handleWalletResult(returnParam);
                }
            }
        })

    }
    function addBankAccount() {
        ajax({
            url: `/api/wallet/saveBankAccount/`,
            method: 'post',
            params: {
                accountType: walletVarForm.values.accountTypeAdd === undefined ? "C" : walletVarForm.values.accountTypeAdd,
                bankName: walletVarForm.values.bankNameAdd,
                nameType: walletVarForm.values.nameTypeAdd,
                bankAccountNumber: walletVarForm.values.bankAccountNumberAdd,
                bankBranchNumber: walletVarForm.values.bankBranchNumber === undefined ? 0 : walletVarForm.values.bankBranchNumber,
                bankRoutingNumber: walletVarForm.values.bankRoutingNumberAdd,
                checkDigit: walletVarForm.values.checkDigit === "" ? walletVarForm.values.bankRoutingNumberAdd : walletVarForm.values.checkDigit,


                policyNumber: walletPolicyNumber,
                companyCode: walletCompanyCode,
                action: "addBankAccount",
                firstName: walletVarForm.values.firstNameAdd,
                lastName: walletVarForm.values.lastNameAdd,
                companyName: walletVarForm.values.companyNameAdd,
                middleName: walletVarForm.values.middleNameAdd,
            },
            success: (res: any) => {
                props.closeModal("#addBank");
                let returnParam = new Map();
                returnParam.set("saveBank", true);
                if (props.handleWalletResult) {
                    props.handleWalletResult(returnParam);
                }
                walletDataNum++;
                if (walletDataNum >= 10) {
                    walletShowAddLink = false;
                } else {
                    walletShowAddLink = true;
                }
            }
        })

    }
    function cancelWallet() {
        let returnParam = new Map();
        returnParam.set("cancelFromWallet", true);
        returnParam.set("deleteCcToken", walletDeleteCcToken === undefined ? "" : walletDeleteCcToken);
        returnParam.set("deleteProfileID", walletDeleteProfileID === undefined ? "" : walletDeleteProfileID);
        returnParam.set("delBankAccountNumber", walletDelBankAccountNumber === undefined ? "" : walletDelBankAccountNumber);
        returnParam.set("delBankBranchNumber", walletDelBankBranchNumber === undefined ? "" : walletDelBankBranchNumber);
        returnParam.set("delBankRoutingNumber", walletDelBankRoutingNumber === undefined ? "" : walletDelBankRoutingNumber);
        returnParam.set("delMerchant", walletDelMerchant === undefined ? "" : walletDelMerchant);
        returnParam.set("editBank", walletSelectBank === undefined ? null : walletSelectBank);
        returnParam.set("editCc", walletSelectCC === undefined ? null : walletSelectCC);
        returnParam.set("eftTableData", walletEftTableData);
        returnParam.set("ccTableData", walletCcTableData);
        if (props.handleWalletResult) {
            props.handleWalletResult(returnParam);
        }
    }
    function selectItem() {
        let returnParam = new Map();
        returnParam.set("selectFromWallet", false);
        returnParam.set("showCc", walletSelectCC);
        returnParam.set("showBank", walletSelectBank);
        if (walletTmpSelectedCCRowNum !== -1) {
            walletSavedSelectedCCRowNum = walletTmpSelectedCCRowNum;
        }
        if (walletTmpSelectedBankRowNum !== -1) {
            walletSavedSelectedBankRowNum = walletTmpSelectedBankRowNum;
        }
        if (props.handleWalletResult) {
            props.handleWalletResult(returnParam);
        }
        props.closeModal("#wallet");
    }
    function showCCWallet() {
        ajax({
            url: '/api/wallet/loadCreditCards',
            params: {
                companyCode: walletCompanyCode,
                policyNumber: walletPolicyNumber,
                clientProfileID: (props.preferInfo.clientProfileID === undefined) ? "" : props.preferInfo.clientProfileID,
                creditCardToken: (props.preferInfo.creditCardToken === undefined) ? "" : props.preferInfo.creditCardToken,
                merchant: (props.preferInfo.merchant === undefined) ? "" : props.preferInfo.merchant,
            },
            success: (res) => {
                walletCcTableData = res.walletDto.ccWallet.items;
                props.closeModal("#deleteCc", () => props.showModal("#wallet"));
                walletVarForm.setFieldValue("companyCode", walletCompanyCode);

            }
        });
    }

    function showEftWallet() {
        ajax({
            url: '/api/wallet/loadBankAccounts',
            params: {
                companyCode: walletCompanyCode,
                policyNumber: walletPolicyNumber,
                bankBranchNumber: (props.preferInfo.preBranchNumber === undefined) ? "" : props.preferInfo.preBranchNumber,
                bankRoutingNumber: (props.preferInfo.preRoutingNumber === undefined) ? "" : props.preferInfo.preRoutingNumber,
                bankAccountNumber: (props.preferInfo.preAccountNumber === undefined) ? "" : props.preferInfo.preAccountNumber,
            },
            success: (res) => {
                props.closeModal("#editBank", () => props.showModal("#wallet"));
                props.closeModal("#addBank", () => props.showModal("#wallet"));
                props.closeModal("#deleteBank", () => props.showModal("#wallet"));
                walletEftTableData = res.walletDto.eftWallet.items;
                walletVarForm.setFieldValue("companyCode", walletCompanyCode);

            }
        });

    }

    function getPaymentCreditCardURL(row: any) {
        ajax({
            url: '/api/wallet/getPaymentCreditCardURL',
            params: {
                companyCode: walletCompanyCode,
                policyNumber: walletPolicyNumber,
                optionType: row === null ? "addCreditCard" : "updateCreditCard",
                portal: "1",
                description: "TL148",
                merchant: row === null ? "100" : row.merchant,
                oldClientProfileID: row === null ? "" : row.clientProfileID,
                oldCreditCardToken: row === null ? "" : row.creditCardToken,
                oldMerchant: row === null ? "100" : row.merchant,
                oldCreditCardNumber: row === null ? "" : row.ccNumber
            },
            success: (res) => {
                walletMocksavecardUrl = process.env.PUBLIC_URL + res.paymentCreditCardURL;
                let optionType = res.optionType;
                walletVarForm.setFieldValue("optionType", optionType);
                props.closeModal("#wallet", () => {
                    if (optionType === "addCreditCard") {
                        props.showModal('#addcciframe')
                    } else {
                        props.showModal('#editcciframe')
                    }
                });

            }
        });
    }

    function getPreferredCCRowID(): any {
        let rowID = -1;
        if (walletCcTableData !== null && walletCcTableData !== undefined && walletCcTableData.length !== 0) {
            for (let i = 0; i < walletCcTableData.length; i++) {
                if (walletCcTableData[i].selected === 'true') {
                    rowID = i;
                }
            }
        }
        return rowID;
    }

    function getPreferredEftRowID(): any {
        let rowID = -1;
        if (walletEftTableData !== null && walletEftTableData !== undefined && walletEftTableData.length !== 0) {
            for (let i = 0; i < walletEftTableData.length; i++) {
                if (walletEftTableData[i].selected === 'true') {
                    rowID = i;
                }
            }
        }
        return rowID;
    }

    function changeContinueBtn(rowID: any) {
        events.current.forEach(evt => {
            document.getElementById("continueBtn")?.removeEventListener("click", evt);
        });
        events.current.length = 0;
        if (walletEftTableData.length === 0) {
            walletSelectCC = walletCcTableData[rowID];
        } else {
            walletSelectBank = walletEftTableData[rowID];
        }
        walletContinueBtnFlag = true;
        document.getElementById("continueBtn")?.removeAttribute("disabled");
        document.getElementById("continueBtn")?.addEventListener("click", selectItem);
        events.current.push(selectItem);
    }
    function changeCancelBtn(rowID: any) {
        if (walletEftTableData.length === 0) {
            walletSelectCC = walletCcTableData[rowID];
        } else {
            walletSelectBank = walletEftTableData[rowID];
        }
    }

    useEffect(() => {
        if (walletContinueBtnFlag === true) {
            events.current.forEach(evt => {
                document.getElementById("continueBtn")?.removeEventListener("click", evt);
            });
            events.current.length = 0;
            document.getElementById("continueBtn")?.addEventListener("click", selectItem);
            events.current.push(selectItem);
        }

    })

    return <props.Div id="walletContainer">
        <props.Modal id="wallet" size="large" title={() => {
            return <>
                <GlobalizedText message="common.lbl.yourwallet" />
                <props.LinkButton className="wallet-link-font" condition={PAYMENT_METHOD_EFT === props.paymentCode && walletShowAddLink} id="bcAddLint" onClick={() => {
                    walletVarForm.setFieldValue("nameTypeAdd", "1");
                    walletVarForm.setFieldValue("accountTypeAdd", "C");
                    showAddBankAccount("", "addBankAccount");
                }} >
                    <props.Icon type='update' label="common.lbl.taddbankaccount" />
                </props.LinkButton>
                <props.LinkButton className="wallet-link-font" condition={PAYMENT_METHOD_CREDITCARD === props.paymentCode && walletShowAddLink} id="ccAddLint" onClick={() => { getPaymentCreditCardURL(null) }} >
                    <props.Icon type='update' label="common.lbl.addcreditcard" />
                </props.LinkButton>
            </>
        }} footer={() => {
            return <>
                <props.Col className="wallet-footer-button">
                    <props.Button id="continueBtn" name="continueBtn" disabled={!walletContinueBtnFlag}><GlobalizedText message="common.button.continue" /></props.Button>
                    <props.Button id="cancelBtn" name="cancelBtn" data-dismiss="modal" onClick={() => {
                        cancelWallet();
                    }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                </props.Col>
            </>
        }}>
            <props.Row condition={props.paymentCode !== null && PAYMENT_METHOD_EFT === props.paymentCode}>
                <props.Col xs="12" sm="12" md="12" className="wallet-efinf">
                    {walletHasData
                        && <props.Information message="common.etwallet.hint1" />
                    }
                    {walletHasData
                        && (walletOriginPaymentCode !== null && PAYMENT_METHOD_EFT === walletOriginPaymentCode) && <><br />
                            <props.Information message="common.ccwallet.hint2" showExclamationSign={false} /><br />
                            <props.Information message="common.ccwallet.hint3" showExclamationSign={false} />
                        </>
                    }
                    {
                        (walletEftTableData === null || walletEftTableData === undefined || walletEftTableData.length === 0) && !walletHasData &&
                        <props.Information message="payment.msg.clickAddBank" />
                    }
                </props.Col>
            </props.Row>
            <props.Row condition={props.paymentCode !== null && PAYMENT_METHOD_CREDITCARD === props.paymentCode}>
                <props.Col xs="12" sm="12" md="12" className="wallet-ccinf">
                    {walletHasData &&
                        <props.Information message="common.ccwallet.hint1" />
                    }
                    {walletHasData
                        && (walletOriginPaymentCode !== null && PAYMENT_METHOD_CREDITCARD === walletOriginPaymentCode) && <><br />
                            <props.Information message="common.ccwallet.hint2" showExclamationSign={false} /><br />
                            <props.Information message="common.ccwallet.hint3" showExclamationSign={false} />
                        </>
                    }
                    {
                        (walletCcTableData === null || walletCcTableData === undefined || walletCcTableData.length === 0) && !walletHasData &&
                        <props.Information message="payment.msg.clickAddCrd" />
                    }
                </props.Col>
            </props.Row>
            <props.Row>
                <props.Col xs="12" sm="12" md="12" className="wallet-information" condition={PAYMENT_METHOD_CREDITCARD === props.paymentCode}>
                    <ccTableWallet.Component />
                </props.Col>
                <props.Col xs="12" sm="12" md="12" className="wallet-information" condition={PAYMENT_METHOD_EFT === props.paymentCode}>
                    <eftTableWallet.Component />
                </props.Col>
            </props.Row>
        </props.Modal>
        <Formik
            initialValues={{
                newPaymentMethod: "",
                newPaymentFrequency: "",
                creditauthorizeBox: false,
                isAuth: false,
                nextPaymentDate: "",
                nameType: "",
                obankRoutingNumber: "",
                obankBranchNumber: "",
                obankAccountNumber: "",
                checkDigit: "",
                accountOption: "",
                action: "",
                policyNumber: "",
                companyCode: "",
                currencyCode: "",
                creditCardType: "",
                ccNumber: "",
                expirationDate: "",
                companyName: "",
                middleName: "",
                firstName: "",
                lastName: "",
                bankName: "",
                bankRoutingNumber: "",
                bankAccountNumber: "",

                companyNameAdd: "",
                middleNameAdd: "",
                firstNameAdd: "",
                lastNameAdd: "",
                bankNameAdd: "",
                bankRoutingNumberAdd: "",
                bankAccountNumberAdd: "",
                nameTypeAdd: "",
                accountTypeAdd: "",
                companyNameEdit: "",
                middleNameEdit: "",
                firstNameEdit: "",
                lastNameEdit: "",
                bankNameEdit: "",
                bankRoutingNumberEdit: "",
                bankAccountNumberEdit: "",
                nameTypeEdit: "",
                accountTypeEdit: ""
            }}
            validate={(values) => {
                if (values.action === "addBankAccount") {
                    if (values.nameTypeAdd === "1") {
                        return validate(byAddSchema, values, props);
                    }
                    else {
                        return validate(byAddComSchema, values, props);
                    }
                } else {
                    if (values.nameTypeEdit === "1") {
                        return validate(byEditSchema, values, walletMessageService.validateProps);
                    } else {
                        return validate(byEditComSchema, values, walletMessageService.validateProps);
                    }
                }
            }}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={() => { submit() }}
        >
            {formProps => {
                walletVarForm = formProps;
                return <Form id="walletstyle">
                    {/* Modal for delete cc */}
                    <props.Modal id="deleteCc" title="common.lbl.deletecreditard" size="large" footer={() => {
                        return <>
                            <props.Button onClick={(e: any) => {
                                comfirmDelCC();
                            }}><GlobalizedText message="openenrol.lbl.confirmDelete" /></props.Button>
                            <props.Button onClick={() => {
                                showCCWallet();
                            }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} >
                        <props.Panel style={{ borderColor: "white" }}>
                            <props.PanelBody >
                                <props.Row>
                                    <props.Col xs="12" sm="12" md="12">
                                        <div role="alert" className="alert alert-success gwp-page-message messageBanner">
                                            <span aria-hidden="true" className="glyphicon glyphicon-warning-sign messageIcon"></span>
                                            <GlobalizedText message="common.lbl.ccarddelconfirm" />
                                            <span className="printPlace">
                                                <props.Span className="gwp-icon gwp-icon-print gwp-icon-sm " ></props.Span>
                                                <props.LinkButton printVisible={false} onClick={() => props.print({ size: 'A5', orientation: 'landscape' })}><GlobalizedText message="common.print" /></props.LinkButton>
                                            </span>
                                        </div>
                                    </props.Col>
                                </props.Row>
                                <props.HR className="hrstyle" />
                                <props.Information className="wallet-del-info" message="common.confirmdel.hint" showExclamationSign={false} />
                                <props.Row className="payout-information-body">
                                    <props.Col xs="12" sm="12" md="12">
                                        <props.PageTitle><GlobalizedText message="common.lbl.credit.card.infor" /></props.PageTitle>
                                        <props.DL className="dl-horizontal">
                                            <props.DLI title="common.lbl.cred.card.type" desc={() => {
                                                let creditCardTypeDesc: any
                                                if (formProps.values.creditCardType === "MC") {
                                                    creditCardTypeDesc = "MasterCard"
                                                }
                                                else if (formProps.values.creditCardType === "DISC") {
                                                    creditCardTypeDesc = "Discover"
                                                }
                                                else if (formProps.values.creditCardType === "VISA") {
                                                    creditCardTypeDesc = "VISA"
                                                }
                                                return <>{creditCardTypeDesc}</>
                                            }} />
                                            <props.DLI title="common.lbl.creditcardnumber" desc={() => {
                                                return <>{"************" + formProps.values.ccNumber.substring(formProps.values.ccNumber.length - 4)}</>
                                            }} />
                                            <props.DLI title="common.lbl.expirationDate" desc={() => {
                                                return <>{formProps.values.expirationDate}</>
                                            }} />
                                        </props.DL>
                                    </props.Col>
                                </props.Row>
                            </props.PanelBody>
                        </props.Panel >
                    </props.Modal>

                    {/* Modal for delete bank */}
                    <props.Modal id="deleteBank" title="common.lbl.delbankaccount" size="large" footer={() => {
                        return <>
                            <props.Button onClick={(e: any) => {
                                comfirmDelBB();
                            }}><GlobalizedText message="openenrol.lbl.confirmDelete" /></props.Button>
                            <props.Button onClick={() => {
                                showEftWallet();
                            }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} >
                        <props.Panel style={{ borderColor: "white" }}>
                            <props.PanelBody >
                                <props.Row>
                                    <props.Col xs="12" sm="12" md="12">
                                        <div role="alert" className="alert alert-success gwp-page-message messageBanner">
                                            <span aria-hidden="true" className="glyphicon glyphicon-warning-sign messageIcon"></span>
                                            <GlobalizedText message="common.lbl.ccarddelconfirm" />
                                            <span className="printPlace">
                                                <props.Span className="gwp-icon gwp-icon-print gwp-icon-sm " ></props.Span>
                                                <props.LinkButton printVisible={false} onClick={() => props.print({ size: 'A5', orientation: 'landscape' })}><GlobalizedText message="common.print" /></props.LinkButton>
                                            </span>
                                        </div>
                                    </props.Col>
                                </props.Row>
                                <props.HR className="hrstyle" />
                                <props.Information className="wallet-del-info" message="common.confdelbankacc.hint" showExclamationSign={false} />
                                <props.Row className="payout-information-body">
                                    <props.Col xs="12" sm="12" md="12">
                                        <props.PageTitle><GlobalizedText message="common.lbl.credit.bankacc.infor" /></props.PageTitle>
                                        <props.DL className="dl-horizontal">
                                            <props.DLI title="common.lbl.accountholder" desc={() => {
                                                return <>{formProps.values.nameType === "1" ? (formProps.values.firstName + " " + formProps.values.lastName) : formProps.values.companyName}</>
                                            }} />
                                            <props.DLI title="common.lbl.bankName" desc={() => {
                                                return <>{formProps.values.bankName}</>
                                            }} />
                                            <props.DLI title="payment.lbl.accountNumber" desc={() => {
                                                return <>{formProps.values.bankAccountNumber}</>
                                            }} />
                                        </props.DL>
                                    </props.Col>
                                </props.Row>
                            </props.PanelBody>
                        </props.Panel >
                    </props.Modal>

                    {/* Modal for edit bank account */}
                    <props.Modal id="editBank" title="common.lbl.teditbankaccount" size="large" footer={() => {
                        return <>
                            <props.Button type="submit" onClick={(e: any) => {
                                walletSubmit = "editBankAccount";
                            }}><GlobalizedText message="common.lbl.save" /></props.Button>
                            <props.Button onClick={() => {
                                showEftWallet();
                            }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} messageService={walletMessageService.messageService} >
                        <props.Row className="bd-payoutinfo-content">
                            <props.Col xs="6" md="6">
                                <props.DL>
                                    <props.SelectControl name="nameTypeEdit" required={true} label="payment.lbl.nametype" options={props.walletDto.bankAcctOpt.nameType}>
                                    </props.SelectControl>
                                    <props.TextControl id="firstNameEdit" name="firstNameEdit" condition={formProps.values.nameTypeEdit === "1"} required={true} label="payment.lbl.firstName" ></props.TextControl>
                                    <props.TextControl id="lastNameEdit" name="lastNameEdit" condition={formProps.values.nameTypeEdit === "1"} required={true} label="payment.lbl.lastName" ></props.TextControl>
                                    <props.TextControl id="companyNameEdit" name="companyNameEdit" condition={formProps.values.nameTypeEdit === "2"} required={true} label="payment.lbl.companyName" ></props.TextControl>
                                </props.DL>
                            </props.Col>

                            <props.Col xs="6" md="6">
                                <props.DL >
                                    <props.TextControl id="bankNameEdit" name="bankNameEdit" required={true} label="common.lbl.bankName" ></props.TextControl>
                                    <props.TextControl id="bankRoutingNumberEdit" name="bankRoutingNumberEdit" required={true} label="common.lbl.routingNumber" ></props.TextControl>
                                    <props.TextControl id="bankAccountNumberEdit" name="bankAccountNumberEdit" required={true} label="payment.lbl.accountNumber" ></props.TextControl>
                                    <props.SelectControl name="accountTypeEdit" required={true} label="payment.lbl.accountType" options={props.walletDto.bankAcctOpt.accountType}>
                                    </props.SelectControl>
                                </props.DL>
                            </props.Col>
                        </props.Row>
                    </props.Modal>

                    {/* Modal for add bank account */}
                    <props.Modal id="addBank" title="common.lbl.taddbankaccount" size="large" footer={() => {
                        return <>
                            <props.Button type="submit" onClick={(e: any) => {
                                walletSubmit = "addBankAccount";
                            }}><GlobalizedText message="common.lbl.save" /></props.Button>
                            <props.Button onClick={() => {
                                showEftWallet();
                            }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} >
                        <props.Row className="bd-payoutinfo-content">
                            <Message messageService={props.messageService}></Message>
                            <props.Col xs="6" md="6">
                                <props.DL>
                                    <props.SelectControl name="nameTypeAdd" required={true} label="payment.lbl.nametype" options={props.walletDto.bankAcctOpt.nameType}>
                                    </props.SelectControl>
                                    <props.TextControl id="firstNameAdd" name="firstNameAdd" condition={formProps.values.nameTypeAdd === "1"} required={true} label="payment.lbl.firstName" ></props.TextControl>
                                    <props.TextControl id="lastNameAdd" name="lastNameAdd" condition={formProps.values.nameTypeAdd === "1"} required={true} label="payment.lbl.lastName" ></props.TextControl>
                                    <props.TextControl id="companyNameAdd" name="companyNameAdd" condition={formProps.values.nameTypeAdd === "2"} required={true} label="payment.lbl.companyName" ></props.TextControl>
                                </props.DL>
                            </props.Col>
                            <props.Col xs="6" md="6">
                                <props.DL >
                                    <props.TextControl id="bankNameAdd" name="bankNameAdd" required={true} label="common.lbl.bankName" ></props.TextControl>
                                    <props.TextControl id="bankRoutingNumberAdd" name="bankRoutingNumberAdd" required={true} label="common.lbl.routingNumber" ></props.TextControl>
                                    <props.TextControl id="bankAccountNumberAdd" name="bankAccountNumberAdd" required={true} label="payment.lbl.accountNumber" ></props.TextControl>
                                    <props.SelectControl name="accountTypeAdd" required={true} label="payment.lbl.accountType" options={props.walletDto.bankAcctOpt.accountType}>
                                    </props.SelectControl>
                                </props.DL>
                            </props.Col>
                        </props.Row>
                    </props.Modal>
                    {/* Modal for add Credit card */}
                    <props.Modal title="common.lbl.addcreditcard" size="large" id="addcciframe" footer={() => {
                        return <></>
                    }} >
                        <props.Row>
                            <props.Col xs="12" sm="12" md="12">
                                <iframe
                                    id='addCreateCardFrame'
                                    title='addCreateCardFrame'
                                    src={walletMocksavecardUrl}
                                    style={{ marginLeft: '15px', width: '96%', border: 'none', height: '370px' }}
                                />
                            </props.Col>
                        </props.Row>
                    </props.Modal>

                    {/* Modal for update Credit card */}
                    <props.Modal title="common.lbl.editcreditcard" size="large" id="editcciframe" footer={() => {
                        return <></>
                    }} >
                        <props.Row>
                            <props.Col >
                                <iframe
                                    id='editCreditCardFrame'
                                    title='editCreditCardFrame'
                                    src={walletMocksavecardUrl}
                                    style={{ marginLeft: '15px', width: '96%', border: 'none', height: '370px' }}
                                />
                            </props.Col>
                        </props.Row>
                    </props.Modal>
                </Form>
            }
            }
        </Formik>
    </props.Div>

}




