import React, { useEffect, useMemo, useState } from 'react';
import { ajax, getContextPath } from './utils';
import { ViewComponentProps } from './viewcomponent';


interface OnlineHelpProps extends ViewComponentProps {
    componentId: string;
}

const openOnlineHelp = (url: string) => {
    window.open(url, '', 'height=600, width=960, top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no');
}

export const OnlineHelp = (props: OnlineHelpProps) => {

    const [url, setUrl] = useState<string>();


    useEffect(() => {
        if (url === undefined) {
            ajax({
                url: `/api/cms/onlinehelp/get?component=${props.helpId ? props.helpId : props.componentId}`,
                success: (res) => {
                    if (res.contentID !== undefined) {
                        setUrl(`${getContextPath()}/onlineHelp?id=${res.contentID}`);
                    }
                },
                showMask: false
            });
        }

    }, [url]);

    const comp = useMemo(() => {
        if (props.componentId === undefined || url === undefined) {
            return <props.Div></props.Div>
        }
        return <>
            <props.LinkButton withoutContainer={true} onClick={() => openOnlineHelp(url)}><props.Icon type="help" size="xs" title={props.getGlobalizedText("common.lbl.onlineHelp")}></props.Icon></props.LinkButton>
        </>
    }, [url]);

    return <>{comp}</>
}