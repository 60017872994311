import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { INIT_PARAM_PREFIX_TABLE } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, copyObjectExcept, isEmptyStr, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { INVALID_MESSAGES } from '../shared/yupschema';

interface UpdateEnrollmentForm {
    filterBy: string,
    firstName: string,
    lastName: string,
    groupNumber: string,
    accountNumber: number,
    participantIDNumber: string,
    idNum: string,
    certiNum: string
}

const defaultInitialValues: UpdateEnrollmentForm = {
    filterBy: '',
    firstName: '',
    lastName: '',
    groupNumber: '',
    accountNumber: 0,
    participantIDNumber: '',
    idNum: '',
    certiNum: ''
};

const generateColumns = () => {
    return [
        { title: 'common.lbl.memberName', name: 'memberName' },
        { title: 'common.lbl.memberID', name: 'participantIDNumber' },
        { title: 'common.lbl.identificationNo', name: 'idNum' },
        { title: 'common.lbl.certificateNo', name: 'certiNum' },
        { title: 'common.lbl.group', name: 'group' },
        { title: 'common.lbl.account', name: 'account' },
        { title: 'common.lbl.birthDate', name: 'birthDate', type: 'date' }
    ]
}

// Define validation for by name.
const byNameSchema = (values: UpdateEnrollmentForm): any => {
    let result: any = {};
    if (isEmptyStr(values.lastName) && isEmptyStr(values.firstName)) {
        result.lastName = INVALID_MESSAGES.LASTNAME_IS_EMPTY;
        result.firstName = INVALID_MESSAGES.FIRSTNAME_IS_EMPTY;
    }
    return result;
}

// Define validation for by member ID.
const byMemberIDValidate = (values: UpdateEnrollmentForm): any => {
    let result: any = {};
    if (values.participantIDNumber.trim() === "" && values.idNum.trim() === "" && values.certiNum.trim() === "") {
        result.participantIDNumber = INVALID_MESSAGES.NEED_ONE_OF_THEM_NOT_EMPTY;
        result.idNum = INVALID_MESSAGES.NEED_ONE_OF_THEM_NOT_EMPTY;
        result.certiNum = INVALID_MESSAGES.NEED_ONE_OF_THEM_NOT_EMPTY;
    } else {
        if (!isEmptyStr(values.participantIDNumber)) {
            if (!isEmptyStr(values.certiNum)) {
                result.participantIDNumber = INVALID_MESSAGES.ENTER_IDENTIFIER;
                result.certiNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
            }
            if (!isEmptyStr(values.idNum)) {
                result.participantIDNumber = INVALID_MESSAGES.ENTER_IDENTIFIER;
                result.idNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
            }
        } else if (!isEmptyStr(values.certiNum) && !isEmptyStr(values.idNum)) {
            result.certiNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
            result.idNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
        }
    }
    return result;
}

const retrieve = (values: any, config: any, columns: any, setTable: any, setUpdateEnrollmentList: any, setDisableUpdateButton?: Function): void => {
    if (setDisableUpdateButton != undefined) {
        setDisableUpdateButton(true);
    }
    let companyAndGroup = values.groupNumber.split("|");
    let accountNumber = values.accountNumber;
    if (companyAndGroup[0] === '0') {
        companyAndGroup[0] = '';
        companyAndGroup[1] = '';
    }
    if (accountNumber == '0') {
        accountNumber = '';
    }
    let params = copyObjectExcept({ ...values, ...{ companyCode: companyAndGroup[0], groupNumber: companyAndGroup[1], accountNumber: accountNumber } }, true, INIT_PARAM_PREFIX_TABLE);
    ajax({
        url: '/api/updateEnrollment/' + values.filterBy,
        params: params,
        success: (res: any) => {
            setUpdateEnrollmentList(res);
            setTable({
                columns: columns,
                data: res
            });
        }
    });
}

export const UpdateEnrollmentComponent = withView((props: ViewComponentProps) => {
    const [config, setConfig] = useState<any | null>(null);
    const [accounts, setAccounts] = useState<Array<any>>([]);
    const [table, setTable] = useState<any | null>(null);
    const [updateEnrollmentList, setUpdateEnrollmentList] = useState<Array<any>>([]);//Update enrollment list records
    const [updateEnrollment, setUpdateEnrollment] = useState<any>(null);//Update enrollment record
    const [disableUpdateButton, setDisableUpdateButton] = useState<boolean>(true);

    // define Form Validate function.
    const formValidate = (values: any): any => {
        if (values.filterBy === config.byName) {
            return validate(byNameSchema, values, props);
        }
        if (values.filterBy === config.byMemberID) {
            return validate(byMemberIDValidate, values, props);
        }
    }

    const groupChangeHandler = (e: any | string, setFieldValue?: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target !== undefined ? e.target.value : e;
        if (companyAndGroupNumber === '0') {
            setAccounts([]);
            if (setFieldValue !== undefined) {
                setFieldValue("accountNumber", "0");
            }
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: '/api/updateEnrollment/fetchAccounts',
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setAccounts(res);
                    if (setFieldValue !== undefined) {
                        setFieldValue("accountNumber", res[0].value === undefined ? 0 : res[0].value);
                    }
                }
            });
        }
    }

    const accountChangeHandler = (e: any, setFieldValue: (fieldName: string, value: any) => void) => {
        setTable({
            columns: columns,
            data: []
        });
    }

    const openUpdateEnrollment = () => {
        let companyCode = updateEnrollment["companyCode"];
        let participantIDNumber = updateEnrollment["participantIDNumber"];
        let clientNumber = updateEnrollment["clientNumber"];
        let accountNumber = updateEnrollment["account"];
        let groupNumber = updateEnrollment["fullgroup"];
        /**
         * If Group Admin currently has Error, Incomplete, or Pending transaction for a specific SSN (member),
         * then a hard error should be displayed from Portal if the same SSN is pulled up for Update Enrollment.
         * The error should require the Group Admin to either complete the prior transaction or delete the prior
         * pending transaction and begin a new 'Update Enrollment'
         */
        ajax({
            url: '/api/updateEnrollment/checkIfIsDenied',
            params: {
                companyCode: companyCode,
                groupNumber: groupNumber,
                accountNumber: accountNumber,
                participantIDNumber: participantIDNumber
            },
            success: (res: any) => {
                props.next(`/enrollment/open?companyCode=${companyCode.trim()}&groupNumber=${groupNumber.trim()}&accountNumber=${accountNumber}&participantIDNumber=${participantIDNumber}&clientNumber=${clientNumber.trim()}&formType=updateEnrollment`);
            },
            fail: (data: any, message: string) => {
                props.showModal("#warningDialog");
            }
        });
    }
    
    const _openUpdateEnrollment = (e: any, selectedRows: Array<number>) => {
        if (selectedRows.length > 0) {
            setDisableUpdateButton(false);
            setUpdateEnrollment(updateEnrollmentList[selectedRows[0]]);
        } else {
            setDisableUpdateButton(true);
        }
    }

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/updateEnrollment/view',
                success: (res: any) => {
                    setConfig(res);
                    if (res.groups.length === 1) {
                        // if only one group selected, then try to load the accounts.
                        groupChangeHandler(res.groups[0].value);
                    }
                    let initParams = props.getInitParams();
                    if (initParams !== undefined) {
                        if (initParams.groupNumber !== '') {
                            groupChangeHandler(initParams.groupNumber);
                        }
                        retrieve({ ...defaultInitialValues, ...{ filterBy: res.byMemberID }, ...initParams }, res, generateColumns(), setTable, setUpdateEnrollmentList);
                    }
                }
            });
        }
    }, [config])

    if (config === null || (config.groups.length === 1 && accounts.length === 0)) {
        return <></>;
    }
    let columns = generateColumns();
    let filterByOptions = config.filterBy;

    // define Form Submitting function.
    const formSubmit = (values: UpdateEnrollmentForm): void => {
        props.setInitParams(values);
        retrieve(values, config, columns, setTable, setUpdateEnrollmentList, setDisableUpdateButton);
    }

    let initialValue: UpdateEnrollmentForm = copyObjectExcept(props.getInitParams() !== undefined ? props.getInitParams() : { ...defaultInitialValues, ...{ filterBy: config.byMemberID }, ...props.getInitParams() }, true, INIT_PARAM_PREFIX_TABLE);

    if (table === null) {
        setTable({
            columns: columns,
            data: []
        });
    }

    return (
        <React.Fragment>
            <Formik initialValues={initialValue}
                validate={formValidate}
                onSubmit={formSubmit}
                {...props.generateNoramlFormikProps()}
            >
                {formProps => <Form>
                    <props.Row>
                        <props.Col md="12" sm="12" xs="12">
                            <props.H5><GlobalizedText message="homepage.filterMember" /></props.H5>
                            <props.Information showExclamationSign={false} message="homepage.requestMemberSelectRetrieve" ></props.Information>
                        </props.Col>
                        <props.Col md="6" sm="6" xs="12">
                            <props.SelectControl sort={false} onChange={(e: any) => {
                                groupChangeHandler(e, formProps.setFieldValue)
                            }} name="groupNumber" label="common.lbl.group" options={config.groups}>
                            </props.SelectControl>
                            <props.SelectControl sort={false} name="accountNumber" onChange={(e: any) => {
                                accountChangeHandler(e, formProps.setFieldValue)
                            }} label="common.lbl.account" options={accounts.length === 0 ? config.accounts : accounts.map((account: any) => { return { name: account.displayValue, value: account.value } })}>
                            </props.SelectControl>
                            <props.SelectControl onChange={(e: any) => {
                                setTable({
                                    data: [],
                                    columns: columns
                                });
                                props.resetForm(formProps, { ...defaultInitialValues, ...{ filterBy: e.target.value } });
                                props.setInitParams(undefined);
                                formProps.setFieldValue("groupNumber", "0");
                                setDisableUpdateButton(true);
                            }} name="filterBy" label="homepage.filterBy" options={filterByOptions}>
                            </props.SelectControl>
                        </props.Col>

                        <props.Col md="6" sm="6" xs="12">
                            {/** search by name */}
                            <props.Condition condition={formProps.values.filterBy === config.byName}>
                                <props.TextControl id="firstName" name="firstName" label="common.lbl.firstName" maxLength="20"></props.TextControl>
                                <props.TextControl id="lastName" name="lastName" label="common.lbl.lastName" maxLength="40"></props.TextControl>
                            </props.Condition>

                            {/** search by member ID */}
                            <props.Condition condition={formProps.values.filterBy === config.byMemberID}>
                                <props.TextControl id="participantIDNumber" name="participantIDNumber" label="common.lbl.memberIdentifier" maxLength="10"></props.TextControl>
                                <props.Label><GlobalizedText className="nobold" message="common.lbl.or" /></props.Label>
                                <props.TextControl id="idNum" name="idNum" label="common.lbl.identificationNumber" maxLength="12"></props.TextControl>
                                <props.Label><GlobalizedText className="nobold" message="common.lbl.or" /></props.Label>
                                <props.TextControl id="certiNum" name="certiNum" label="benefit.certificate.number" maxLength="10"></props.TextControl>
                            </props.Condition>
                            <props.Button type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>

                    </props.Row>
                    <props.HR />
                    <props.Row>
                        <props.Col md="12" sm="12" xs="12">
                            <props.Information showExclamationSign={false} message="homepage.selectMemberUpate" ></props.Information>
                            <props.Button disabled={disableUpdateButton} onClick={(e: any) => {
                                openUpdateEnrollment()
                            }}><GlobalizedText message="common.lbl.update" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
                }
            </Formik>
            <props.Table setInitParams={props.setInitParams} getInitParams={props.getInitParams} id="transactionListTable" table={table} dateformat={props.getDateFormat()}
                select={{
                    type: 'single', onSelect: (e: any, selectedRows: Array<number>) => {
                        _openUpdateEnrollment(e, selectedRows)
                    }
                }}>
            </props.Table>
            {/**Waring Dialog */}
            < props.Modal title="firupd.warning" id="warningDialog" footer={() => {
                return <>
                    <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                </>
            }}>
                <props.Row>
                    <props.Col sm="12" md="12" xs="12">
                        <props.Div><GlobalizedText message="update.enrollment.cannot.continue" /></props.Div>
                    </props.Col>
                </props.Row>
            </props.Modal >
        </React.Fragment>
    );
});