import React, { useEffect, useState, useRef } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { Formik, Form } from 'formik';
import { ajax, validate } from '../shared/utils';
import './css/licensing.css';
import * as yup from 'yup';
import { YupSchema } from '../shared/yupschema';
import GlobalizedText from '../shared/globalization';
import { TableColumn } from '../shared/components';
type Level = 'myib' | 'drib'
export const LicensingComponent = withView((props: ViewComponentProps) => {
    const [agents, setAgents] = useState<Array<any>>([{}]);
    const [subAgents, setSubAgents] = useState<Array<any>>([{}]);
    const [config, setConfig] = useState<any | null>(null);
    const [companyCode, setCompanyCode] = useState<string>('');
    const [handlerFlag, setHandlerFlag] = useState<string | null>();
    const [from, setFrom] = useState<string>("");
    const [tableMYL, setTableMYL] = useState<any>();
    const [tableDRL, setTableDRL] = useState<any>();
    const [tab, setTab] = useState<Level>('myib');
    const [showSelectDPagent, setShowSelectDPagent] = useState<boolean>(true);
    const [showDPTable, setShowDPTable] = useState<boolean>(false);
    const defaultInitialValues = {
        fullName: "",
        companyCode: "",
        firstOpen: false,
        oneAgent: false,
        selectAgent: "",
        selectDPagent: "",
    };
    let varFormPromp: any;
    const tableColumnMYL: Array<TableColumn> = [
        { title: 'common.lbl.stateCountry', name: 'country' },
        { title: 'licensing.licensetype', name: 'licenseType' },
        { title: 'common.lbl.effectiveDate', name: 'effDate', type: 'date', sortable: true },
        { title: 'licensing.licensenumber', name: 'licenseNumber' },
        { title: 'licensing.renewaldate', name: 'renewDate', type: 'date', sortable: true },
        { title: 'common.lbl.terminationDate', name: 'termDate', type: 'date', sortable: true },
    ]
    const tableColumnDRL: Array<TableColumn> = [
        { title: 'licensing.agentname', name: 'agentName' },
        { title: 'common.lbl.stateCountry', name: 'country' },
        { title: 'licensing.licensetype', name: 'licenseType' },
        { title: 'common.lbl.effectiveDate', name: 'effDate', type: 'date', sortable: true },
        { title: 'licensing.licensenumber', name: 'licenseNumber' },
        { title: 'licensing.renewaldate', name: 'renewDate', type: 'date', sortable: true },
        { title: 'common.lbl.terminationDate', name: 'termDate', type: 'date', sortable: true },

    ]
    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/licensing/view',
                params: {
                    from: props.getQueryParam('fromPage'),
                    companyCode: props.getQueryParam('companyCode'),
                    agentNumber: props.getQueryParam('agentNumber')
                },
                success: (res: any) => {
                    setAgents(res.agents);
                    setFrom(res.from);
                    setConfig(res);
                    setCompanyCode(res.companyCode)
                    varFormPromp.setFieldValue("fullName", res.fullName);
                    if (res.oneAgent) {
                        if (res.from === "from_agentsearch") {
                            props.setTitle("Licensing - " + res.fullName);
                        }
                        retrieveLicensingMYL(res.agents[1].value + "-" + res.fullName);
                        varFormPromp.setFieldValue("oneAgent", true);
                    }
                }
            });
        }
    }, [config]);
    function retrieveLicensingMYL(values: any, setSubmitting?: Function) {
        let agentName: any = "";
        let selectAgent: any = "";
        if (typeof (values) == 'string') {//PA/CSR user: values example: A16000000-CO1-Greg Norman
            selectAgent = values.split("-");
            agentName = selectAgent[2];
        } else {
            agentName = values.fullName;
            selectAgent = values.selectAgent.split("-");//values.selectAgent example: A16000000-CO1}
        }
        ajax({
            url: '/api/licensing/fetchLicensing/',
            params: {
                companyCode: selectAgent[1],
                agentNumber: selectAgent[0],
                agentName: agentName,
                type: 'MYL',
            },
            success: (res: any) => {
                setTableMYL(res.agentLicenselist);
                retrieveSubAgents(values)
            }
        });
    }

    function retrieveLicensingDRL(values: any, setSubmitting?: Function) {
        let selectDPagent = values.selectDPagent.split("-");//values.selectAgent example: A16000000-CO1
        let agentName = '';
        if (selectDPagent.length > 3) {
            for (let i = 2; i < selectDPagent.length - 1; i++) {
                agentName = agentName + selectDPagent[i] + '-';
            }
            agentName = agentName + selectDPagent[selectDPagent.length - 1];
        } else {
            agentName = selectDPagent[2];
        }
        ajax({
            url: '/api/licensing/fetchLicensing/',
            params: {
                companyCode: selectDPagent[1],
                agentNumber: selectDPagent[0],
                agentName: agentName.trim(),
                type: 'DRL',
            },
            success: (res: any) => {
                setTableDRL(res.agentLicenselist);
            }
        });
    }

    function retrieveSubAgents(values: any, setSubmitting?: Function) {
        let selectAgent: any = []
        if (typeof (values) == 'string') {//PA/CSR user: values example: A16000000-CO1-Greg Norman
            selectAgent = values.split("-");
        } else {
            selectAgent = values.selectAgent.split("-");//values.selectAgent example: A16000000-CO1
        }
        ajax({
            url: '/api/licensing/fetchSubAgents/',
            params: {
                companyCode: selectAgent[1],
                agentNumber: selectAgent[0]
            },
            success: (res: any) => {
                setSubAgents(res.subAgents);
            }
        });
    }
    // define Form Submitting function.
    const formSubmit = (values: any): void => {
        props.clearMessage();
        if (handlerFlag === 'retrieveDRL') {
            retrieveLicensingDRL(values, setTableDRL);
        } else if (handlerFlag === 'retrieveMYL') {
            retrieveLicensingMYL(values, setTableMYL);
        }
    }
    function changeTab(tabName: Level) {
        setTab(tabName);
        if (tabName === 'drib') {
            setShowDPTable(true);
            setTableDRL(null);
            // setRefreshDR(true);
            props.resetForm(varFormPromp, varFormPromp.values);

            varFormPromp.setFieldValue("selectDPagent", [{ name: "common.lbl.pleaseselect", value: "" }]);
        } else {
            setShowDPTable(false);
        }
        props.clearMessage();
    }

    const byRetriveMYLSchema = yup.object().shape({
        selectAgent: YupSchema.licensing.selectAgent
    });
    const byRetriveDRLSchema = yup.object().shape({
        selectDPagent: YupSchema.licensing.selectDPagent
    });
    return <>
        <Formik initialValues={defaultInitialValues}
            validate={(values) => {
                if (handlerFlag === 'retrieveMYL') {
                    return validate(byRetriveMYLSchema, values, props);
                } else if (handlerFlag === 'retrieveDRL') {
                    if (varFormPromp.values.selectDPagent === "" || varFormPromp.values.selectDPagent[0].value === "") {
                        values.selectDPagent = "";
                    }
                    return validate(byRetriveDRLSchema, values, props);
                }
            }}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps => {
                varFormPromp = formProps;
                return <Form id='licensing'>
                    {/* Tab Control Panel*/}
                    <div>
                        <ul id="ibTab" className="nav nav-tabs" role="tablist">
                            <li id="INB_myApp" role="presentation" className={tab === 'myib' ? 'active' : ''}>
                                <a href="#myib" id="tab_myib" aria-controls="myib" role="tab" data-toggle="tab" onClick={() => { changeTab("myib") }} >
                                    <GlobalizedText message="licensing.mylicensing" /></a>
                            </li>
                            {subAgents !== null && subAgents !== undefined && subAgents.length > 1 &&
                                <li id="INB_dRepApp" role="presentation" className={tab === 'drib' ? 'active' : ''} >
                                    <a href="#drib" id="tab_drib" aria-controls="drib" role="tab" data-toggle="tab" onClick={() => { changeTab("drib") }} >
                                        <GlobalizedText message="licensing.directlicensing" /></a>
                                </li>
                            }
                        </ul>
                    </div>
                    {/* my agent tab */}
                    <div className="tab-content" >
                        <div role="tabpanel" className={`tab-pane fade in ${tab === 'myib' ? 'active' : ''}`} id="myib">
                            <props.Row condition={!formProps.values.oneAgent}>
                                <props.Col md="4" sm="4" xs="12">
                                    <props.SelectControl name="selectAgent" label="common.lbl.agent"
                                        sort={false} options={(config === null || config.agents === null) ? [{ name: "common.lbl.pleaseselect", value: "" }] : config.agents} />
                                </props.Col>
                                <props.Col md="8" sm="8" xs="12">
                                    <props.Button containerClassName="retriveBtn" type="submit" onClick={() => { setHandlerFlag("retrieveMYL") }}><GlobalizedText message="common.lbl.retrieve" /></props.Button></props.Col>
                            </props.Row>

                        </div>
                    </div>
                    {/* direct report agent tab */}
                    <div className="tab-content" >
                        <div role="tabpanel" className={`tab-pane fade in ${tab === 'drib' ? 'active' : ''}`} id="drib">
                            <props.Row className={"drrow"}>
                                <props.Col md="4" sm="4" xs="12">
                                    <props.SelectControl name="selectDPagent" label="common.lbl.directReport"
                                        disabled={showSelectDPagent ? false : true}
                                        sort={false} options={subAgents === null ? [{ name: "common.lbl.pleaseselect", value: "" }] : subAgents} />
                                </props.Col>
                                <props.Col md="8" sm="8" xs="12">
                                    <props.Button containerClassName="retriveBtn" type="submit" onClick={() => { setHandlerFlag("retrieveDRL") }}><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                                </props.Col>
                            </props.Row>
                        </div>
                    </div>
                </Form>
            }
            }
        </Formik>

        <props.Row>
            <props.Col sm="12" xs="12" md="12">
                <props.Table id="mylTable" condition={!showDPTable} dateformat={props.getDateFormat()} table={
                    {
                        columns: tableColumnMYL,
                        data: tableMYL,
                    }
                } ></props.Table>
                <props.Table id="drlTable" condition={showDPTable} dateformat={props.getDateFormat()} table={
                    {
                        columns: tableColumnDRL,
                        data: tableDRL,
                    }
                } ></props.Table>
            </props.Col>
        </props.Row>
    </>
})