
String.prototype.replaceAll = function (exp, str) {
    let r = this.toString();
    if (typeof exp === "object") {
        while (exp.test(r)) {
            r = r.replace(exp, str);
        }
    } else if (typeof exp === "string") {
        while (r.indexOf(exp) != -1) {
            r = r.replace(exp, str);
        }
    }
    return r;
}