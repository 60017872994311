import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ajax } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { SearchSection } from './searchsection';


export const ChangeOwnerComponent = withView((props: ViewComponentProps) => {
    let companyCode = props.getQueryParam("companyCode")
    let policyNumber = props.getQueryParam("policyNumber")
    const [config, setConfig] = useState<any>();
    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/changeRelation/view',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber,
                    changeType: 1
                },
                success: (res: any) => {
                }
            })
        }
    }, [config])

    return <>
        <SearchSection {...props} changeType={1} companyCode={companyCode} policyNumber={policyNumber} />
    </>

});
