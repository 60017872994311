import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { Combobox } from 'react-widgets';
import { ajax, copyObjectExcept, hideElement, showElement } from '../../utils';
import { ReadOnlyProps, withErrorControl, WrapperControlProps } from './field';
import { Label } from './label';

interface ComboboxConfig {
    [propName: string]: any;
    onChange: (v: string) => void,
}

interface Params {
    [key: string]: string | number | Array<any>;
}

export interface ComboboxControlProps extends Combobox.ComboboxProps, WrapperControlProps, ReadOnlyProps {
    /**
     * Specify how many records would be loaded
     */
    countTop?: number;
    /**
     * Parameter name which would be added when invoking the service endpoint
     */
    paramName?: string;
    /**
     * service endpoint url
     */
    url?: string;

    serviceParams?: Params;

    /**
     * Data that will be used to initialize the combobox
     */
    onValueChange?: (value: any) => Array<string> | void;

    /**
     * label that would be displayed on the page.
     */
    label?: string;

    /**
    * label that would not be displayed on the page.
    */
    hiddenLabel?: string;

    /**
     * hidden label for.
     */
    hiddenLabelFor?: string;
    /**
     * class name for the combobox container
     */
    containerClassName?: string;
    /**
     * name of the combobox
     */
    name: string;
    /**
     * onchange handler
     */
    onChange?: (v: any) => void;

    readOnly?: boolean;

    hideCaret?: boolean;

    /**
     * Disable the auto filter for the options.
     */
    disableFilter?: boolean;
}

type RoleTypeCode = "OW1" | "OW2" | "AGT" | "MBR" | "PAY" | "ALL"
export interface NameAutoCompleteControlProps extends ComboboxControlProps {
    /**
     * If role type is AGT, then the label will display as Agency Name.
     */
    roleTypeCodes: Array<RoleTypeCode>
}

function processPopContainer(id: string, data: any
) {
    // comments out the logic, since it may cause the popup box issue. 
    // recommand to upgrade to the new version of react widget. 
    /*if (data === undefined || data.length === 0) {
        hideElement(document.getElementById(id)?.querySelector(".rw-popup-container"));
    } else {
        if (!(document.getElementById(id)?.querySelector(".rw-popup-container")?.getAttribute("class")?.includes("rw-popup-transition-exited")
            || document.getElementById(id)?.querySelector(".rw-popup-container")?.getAttribute("class")?.includes("rw-popup-transition-exiting"))) {
            showElement(document.getElementById(id)?.querySelector(".rw-popup-container"));
        }
    }*/
}

function hidePopContainer(id: string) {
    // hideElement(document.getElementById(id)?.querySelector(".rw-popup-container"));
}

/**
 * Combobox controller, would be rendered along with a label.
 * @param props 
 */
export const ComboboxControl = withErrorControl(({ onValueChange, serviceParams, ...props }: ComboboxControlProps) => {
    const [data, setData] = useState<Array<any>>([]);
    const [count] = useState<number>(0);
    const id = props.id ? props.id : props.name;
    useEffect(() => {
        if (props.data !== undefined) {
            setData(props.data);
        }
        if (props.hideCaret !== true) {
            processPopContainer(id, props.data);
        }
    }, [count]);
    useEffect(() => {
        if (props.data)
            setData(props.data);
    }, [props.data]);
    let fieldConfig: any;
    try {
        fieldConfig = useField(props.name);
    } catch (e) {

    }
    let countTop = 10;
    if (props.countTop !== undefined) {
        countTop = props.countTop;
    }
    function loadData(value: string, parameterName: string) {
        let params: any = { countTop: countTop, ...serviceParams };
        params[parameterName] = value;
        ajax({
            url: props.url,
            params: params,
            success: (res: any) => {
                setData(res);
                if (props.hideCaret !== true) {
                    processPopContainer(id, res);
                }
            },
            showMask: false
        });
    }
    function change(val: any, onchange?: (val: any) => void) {
        let value = typeof val === "string" ? val : (props.valueField && typeof props.valueField === "string" ? val[props.valueField] : '');
        if (onchange !== undefined) {
            onchange(value);
        }
        if (props.url !== undefined && props.paramName !== undefined) {
            loadData(value, props.paramName);
        }
        if (props.disableFilter !== false) {
            if (props.data !== undefined) {
                let tmp: Array<any> = [];
                let maxMatched = 0;

                if (typeof props.data[0] === "string") {
                    tmp = props.data.filter((str: string) => { return str.toLowerCase().startsWith(value.toLowerCase()) && ++maxMatched && maxMatched <= countTop }).map((str: any) => { return str });
                } else {

                    tmp = props.data.filter((str: any) => {
                        if (props.textField !== undefined && typeof props.textField === "string") {
                            return str[props.textField].toLowerCase().startsWith(value.toLowerCase()) && ++maxMatched && maxMatched <= countTop
                        }
                    }).map((str: any) => { return str });
                }
                setData(tmp);
                if (props.hideCaret !== true) {
                    processPopContainer(id, tmp);
                }
            }
            if (onValueChange !== undefined) {
                let tmp = onValueChange(val);
                if (tmp) {
                    setData(tmp);
                    if (props.hideCaret !== true) {
                        processPopContainer(id, tmp);
                    }
                }
            }
        }
    }

    let config: ComboboxConfig = {
        name: props.name,
        data: data,
        containerClassName: `gwp-input gwp-combobox ${props.hideCaret === false ? "" : "hide-caret"}`,
        messages: {
            emptyList: "",
            emptyFilter: ""
        },
        onChange: (v: string) => { change(v, props.onChange); },
        onSelect: (v: any) => {
            if (props.onSelect !== undefined) {
                props.onSelect(v)
            }
            if (props.hideCaret !== true) {
                hidePopContainer(id);
            }
        },
        onBlur: (e: any) => {
            if (props.hideCaret !== true) {
                hidePopContainer(id);
            }
        }
    };

    if (fieldConfig !== undefined) {
        if (props.defaultValue) {
            config.value = props.defaultValue;
        } else {
            if (fieldConfig[1].initialValue !== "" && data.length) {
                config.value = fieldConfig[1].initialValue;
            }
        }
        config.onChange = (v: any) => {
            fieldConfig[2].setValue(typeof v === "string" ? v : v.value); change(typeof v === "string" ? v : v.value, props.onChange);
        }
    }
    return <>
        {(props.hiddenLabelFor !== undefined && props.hiddenLabel !== undefined) && <>
            <Label htmlFor={props.hiddenLabelFor} className="hidden">{props.hiddenLabel}</Label>
        </>
        }
        <Combobox {...copyObjectExcept(props, false, "value")} {...config} id={id} />
    </>
});

export const FirstNameAutoCompleteControl = ({ roleTypeCodes, ...props }: NameAutoCompleteControlProps) => {
    return <ComboboxControl {...{ url: "/api/common/getNames", label: "common.lbl.firstName", paramName: "name", serviceParams: { "namesType": 1, "roleTypeCodes": roleTypeCodes.join(",") }, ...props }} />
}

export const LastNameAutoCompleteControl = ({ roleTypeCodes, ...props }: NameAutoCompleteControlProps) => {
    return <ComboboxControl {...{ url: "/api/common/getNames", label: "common.lbl.lastName", paramName: "name", serviceParams: { "namesType": 2, "roleTypeCodes": roleTypeCodes.join(",") }, ...props }} />
}

export const OrgNameAutoCompleteControl = ({ roleTypeCodes, ...props }: NameAutoCompleteControlProps) => {
    return <ComboboxControl {...{ url: "/api/common/getNames", label: roleTypeCodes.includes("AGT") ? "regist.label.agency.name" : "changeow.lbl.companyName", paramName: "name", serviceParams: { "namesType": 3, "roleTypeCodes": roleTypeCodes.join(",") }, ...props }} />
}