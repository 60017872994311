import React, { useEffect, useMemo, useState } from 'react';
import { ajax, $, isMobileDevice } from './utils';
import { ViewComponentProps } from './viewcomponent';

interface PortalContentObject {
    id: string;
    content: string;
    contentType: string;
}

interface PortalContentProps extends ViewComponentProps {
    className?: string;
    component: string;
    position: string;
}

export const PortalContent = (props: PortalContentProps) => {
    const [content, setContent] = useState<PortalContentObject>();

    useEffect(() => {
        if (content === undefined) {
            ajax({
                url: `/api/cms/portalcontent/get?component=${props.component}&position=${props.position}`,
                success: (res: any) => {
                    setContent(res);
                },
                showMask: false
            });
        }
    });
    useEffect(() => {
        if (content !== undefined) {
            if (!isMobileDevice()) {
                if ($("#portalContentContainer a[href^='tel']").length > 0) {
                    $("#portalContentContainer a[href^='tel']").each(function (i: number, el: any) {
                        $(el).parent().html($(el).text());
                    });
                }
            }
        }
    }, [content])

    const comp = useMemo(() => {
        if (content === undefined) {
            return <props.Div></props.Div>
        }
        return <props.Div id="portalContentContainer"><props.ContentViewer className={props.className} html={content?.content} ></props.ContentViewer></props.Div>
    }, [content]);
    return <>{comp}</>

}