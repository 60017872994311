import React, { useEffect, useState } from 'react';
import { HelpBanner } from '../shared/helpbanner';
import { ajax, isEmptyStr, movePageToTop } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/personalInfo.css';
import { PersonalContactInfo } from './personalcontactinfo';

interface DataObjParams1 { //for OW1, OW2, Insured and Payor
    personalInfoType: number,
    companyCode: string,
    groupNumber: string,
    accountNumber: number,
    participantIDNumber: string,
    dependentSequenceNumber: number
}
interface DataObjParams2 { //for OW1, OW2, Insured and Payor
    personalInfoType: number,
    companyCode: string,
    roleTypeCode: string,
    policyNumber: string,
    baseRiderCode: number
}

interface PersonalInformationProps extends ViewComponentProps {
    fromPage: string;
    clientNumberFromInsurance: string;
}

export const PersonalInformationComponent = withView((props: ViewComponentProps) => {
    //For all type, get companycode
    let companyCode = props.getQueryParam("companyCode");
    let isUpdateMode = props.getQueryParam("isUpdateMode");
    let personalInfoType = props.getQueryParam("personalInfoType");
    //For OW1, OW2 and Payor
    let policyNumber = props.getQueryParam("policyNumber");
    let roleTypeCode = props.getQueryParam("roleTypeCode");
    let baseRiderCode = props.getQueryParam("baseRiderCode");
    //For BEN,CNT
    let relationshipSequenceNumber = props.getQueryParam("relationshipSequenceNumber");
    //For Member and Dependent
    let groupNumber = props.getQueryParam("groupNumber");
    let accountNumber = props.getQueryParam("accountNumber");
    let participantIDNumber = props.getQueryParam("participantIDNumber");
    let dependentSequenceNumber = props.getQueryParam("dependentSequenceNumber");

    const [config, setConfig] = useState<any | null>(null);
    const [personInfo, setPersonInfo] = useState<any | null>(null);
    const [updatePersonalInfoBody1, setUpdatePersonalInfoBody1] = useState<any | null>(null);
    const [updatePersonalInfoBody2, setUpdatePersonalInfoBody2] = useState<any | null>(null);

    const [message, SetMessage] = useState<any | null>(null);

    useEffect(() => {
        if (config === null) {
            fetchPersonalInfo()
        } else {
            SetMessage(null)
        }
    }, [config]);

    useEffect(() => {
        if (message !== null) {
            fetchPersonalInfo()
        }
    }, [message])

    function fetchPersonalInfo() {
        movePageToTop();
        if (personalInfoType === "1") {
            ajax({ //DrAgent
                url: '/api/personalInfo/view',
                params: {
                    isUpdateMode: isUpdateMode,
                    personalInfoType: 1,
                    companyCode: companyCode,
                    groupNumber: groupNumber,
                    accountNumber: accountNumber,
                    participantIDNumber: participantIDNumber,
                    dependentSequenceNumber: dependentSequenceNumber
                },
                showMask: true,
                success: (res: any) => {
                    setConfig(res)
                    res.person.state = res.person.stateCode
                    setPersonInfo(res.person)
                    let tmp: DataObjParams1 = {
                        personalInfoType: 1,
                        companyCode: res.companyCode,
                        groupNumber: res.groupNumber,
                        accountNumber: res.accountNumber,
                        participantIDNumber: res.participantIDNumber,
                        dependentSequenceNumber: res.dependentSequenceNumber
                    }
                    setUpdatePersonalInfoBody1(tmp)
                }
            })
        }
        if (personalInfoType === "2") {
            ajax({ //OW1, OW2, Insured and Payor
                url: '/api/personalInfo/view',
                params: (roleTypeCode === 'BEN' || roleTypeCode === 'CNT') ? {
                    isUpdateMode: isUpdateMode,
                    personalInfoType: 2,
                    companyCode: companyCode,
                    roleTypeCode: roleTypeCode,
                    policyNumber: policyNumber,
                    baseRiderCode: baseRiderCode,
                    relationshipSequenceNumber: relationshipSequenceNumber
                } : {
                    isUpdateMode: isUpdateMode,
                    personalInfoType: 2,
                    companyCode: companyCode,
                    roleTypeCode: roleTypeCode,
                    policyNumber: policyNumber,
                    baseRiderCode: baseRiderCode
                },
                showMask: true,
                success: (res: any) => {
                    res.person.newClient = false
                    setConfig(res)
                    res.person.state = res.person.stateCode
                    setPersonInfo(res.person)
                    let tmp: DataObjParams2 = {
                        personalInfoType: 2,
                        companyCode: res.companyCode,
                        roleTypeCode: res.roleTypeCode,
                        policyNumber: res.policyNumber,
                        baseRiderCode: res.baseRiderCode
                    }
                    setUpdatePersonalInfoBody2(tmp)
                }
            })
        }
    }


    return <>
        <props.Div id="minSrcHelp" className="minHide">
            <HelpBanner {...props}></HelpBanner>
        </props.Div>
        {(personalInfoType === "1" && personInfo !== null) && <>
            <props.Row>
                <props.Col md="9" sm="12" xs="12">
                    <props.Row>
                        <props.Col md="8" sm="6" xs="12">
                            <props.DL>
                                <props.DLI title="common.lbl.name" desc={personInfo.name.replaceAll("<wbr/>", "")}></props.DLI>
                                <props.DLI title="pi.relation" desc={personInfo.relationship}></props.DLI>
                                <props.DLI title="common.lbl.govID" desc={personInfo.govtID}></props.DLI>
                            </props.DL>
                        </props.Col>
                        <props.Col md="4" sm="6" xs="12">
                            <props.DL>
                                <props.DLI condition={personInfo.birthDate !== undefined} title="common.lbl.dob" desc={personInfo.birthDate}></props.DLI>
                                <props.DLI condition={personInfo.gender !== undefined && !isEmptyStr(personInfo.gender)} title="pi.gender" desc={personInfo.gender}></props.DLI>
                            </props.DL>
                        </props.Col>
                    </props.Row>
                </props.Col>
                <props.Col md="3" sm="12" xs="12">
                    <props.Div id="maxSrcHelpBanner" className="maxHide">
                        <HelpBanner {...props}></HelpBanner>
                    </props.Div>
                </props.Col>
            </props.Row>
            {updatePersonalInfoBody1 !== null &&
                <PersonalContactInfo {...props} changeType={5} personalInfo={personInfo} states={config.states} contactMethods={config.contactMethods} companyCode={config.companyCode} updateMode={isUpdateMode === "true" ? true : false} updateAble={config.updateAble} updatePersonalInfoBody={updatePersonalInfoBody1} callBackFuntion={(returnedMsg: any) => {
                    SetMessage(returnedMsg)
                }} />
            }

        </>}
        {(personalInfoType === "2" && personInfo !== null) && <>
            <props.Row>
                <props.Col md="9" sm="12" xs="12">
                    <props.Row>
                        <props.Col md="8" sm="6" xs="12">
                            <props.DL>
                                <props.DLI title="common.lbl.name" desc={personInfo.name.replaceAll("<wbr/>", "")}></props.DLI>
                                <props.DLI title="pi.relation" desc={personInfo.relationship}></props.DLI>
                                <props.DLI title="common.lbl.govID" desc={personInfo.govtID}></props.DLI>
                            </props.DL>
                        </props.Col>
                        <props.Col md="4" sm="6" xs="12">
                            <props.DL>
                                <props.DLI condition={personInfo.birthDate !== undefined} title="common.lbl.dob" desc={personInfo.birthDate}></props.DLI>
                                <props.DLI condition={personInfo.deathDate !== undefined} title="pi.death.hdate" desc={personInfo.deathDate}></props.DLI>
                                <props.DLI condition={personInfo.gender !== undefined && !isEmptyStr(personInfo.gender)} title="pi.gender" desc={personInfo.gender}></props.DLI>
                            </props.DL>
                        </props.Col>
                    </props.Row>
                </props.Col>
                <props.Col md="3" sm="12" xs="12">
                    <props.Div id="maxSrcHelpBanner" className="maxHide">
                        <HelpBanner {...props}></HelpBanner>
                    </props.Div>
                </props.Col>
            </props.Row>
            {(updatePersonalInfoBody2 !== null && (roleTypeCode !== 'BEN' && roleTypeCode !== 'CNT')) &&
                <PersonalContactInfo {...props} changeType={5} roleTypeCode={roleTypeCode} personalInfo={personInfo} states={config.states} contactMethods={config.contactMethods} companyCode={config.companyCode} policyNumber={config.policyNumber} updateMode={isUpdateMode === "true" ? true : false} updateAble={config.updateAble} updatePersonalInfoBody={updatePersonalInfoBody2} callBackFuntion={(returnedMsg: any) => {
                    SetMessage(returnedMsg)
                }} />
            }
            {(updatePersonalInfoBody2 !== null && (roleTypeCode === 'BEN' || roleTypeCode === 'CNT') && relationshipSequenceNumber !== undefined) &&
                <PersonalContactInfo {...props} changeType={5} roleTypeCode={roleTypeCode} personalInfo={personInfo} states={config.states} contactMethods={config.contactMethods} companyCode={config.companyCode} policyNumber={config.policyNumber} updateMode={isUpdateMode === "true" ? true : false} updateAble={config.updateAble} updatePersonalInfoBody={updatePersonalInfoBody2} relationshipSequenceNumber={relationshipSequenceNumber} callBackFuntion={(returnedMsg: any) => {
                    SetMessage(returnedMsg)
                }} />
            }
        </>}
    </>
});

