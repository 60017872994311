import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ComponentConfig, ComponentProps } from '../config/route-config';
import { UserContext } from './authentication';
import { BodyProps } from './body';
import { GlobalizationContext } from './globalization';
import { MessageService } from './message';
import { getContextPath, getGlobalizedText } from './utils';

export interface RouteComponentProps extends ComponentProps, RoutersProps {
    globalization: any,
    url: string,
    messageService: any,
    themeService: any,
}

export interface RoutersProps extends BodyProps {
    messageService: MessageService;
}

export default function Routers(props: RoutersProps) {

    function wrapper(Comp: any) {
        return (wrapperProps: any) => {
            if (wrapperProps.id) {
                return <div id={wrapperProps.id}><Comp {...wrapperProps}>{wrapperProps.children}</Comp></div>
            } else {
                return <Comp {...wrapperProps}>{wrapperProps.children}</Comp>
            }

        }
    }

    function generateRoutes(config: ComponentConfig | null, routersProps: RoutersProps, isAuthenticated: boolean): Array<JSX.Element> {
        const Routes = Array<JSX.Element>();
        if (config === null) {
            return Routes;
        }
        for (let url in config) {
            const compConfig = config[url];
            const Comp = compConfig.component;
            const name = getGlobalizedText(globalization, compConfig.name);
            const WrapperedComp = wrapper((wrapperProps: any) => { return <Comp {...wrapperProps} /> });
            let compProp: RouteComponentProps = {
                helpId: compConfig.id,
                ...compConfig,
                ...routersProps,
                globalization: globalization,
                url: url,
                hasBackTo: compConfig.hasBackTo,
                hasBreadcrumb: compConfig.hasBreadcrumb,
                showBreadcrumb: compConfig.showBreadcrumb,
                name: name
            }
            Routes.push(<Route exact={compConfig.exact} key={url} path={getContextPath() + url} children={<WrapperedComp {...compProp} />} />);
        }
        return Routes;
    }
    const context = useContext(UserContext);
    const globalization = useContext(GlobalizationContext);
    const isAuthen: boolean = context !== null;
    if (props.routeConfigs === null) {
        return <></>
    }
    return <Switch>
        {generateRoutes(props.routeConfigs, props, isAuthen)}
    </Switch>
}
