import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { DEFAULT_FORMAT_TYPE, INIT_PARAM_PREFIX_TABLE, NAME_TYPE_COMPANY, NAME_TYPE_PERSON } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, copyObjectExcept, isEmptyStr, mergeObject, removeLeftZeroForNumber, trim, trimFields, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { INVALID_MESSAGES, YupSchema } from '../shared/yupschema';

interface BillingPaymentInquiryForm {
    filterBy: string,
    lastName: string,
    firstName: string,
    nameType: string,
    companyName: string,
    birthDate: string,
    groupNumber: string,
    accountNumber: number,
    participantIDNumber: string,
    policyNumber: string,
    govtID: string,
    isIndividualAccount: boolean
}

const generateColumns = (config: any) => {
    return {
        byGroup: [
            {
                title: 'common.lbl.memberName', name: 'memberName', render: (data: any, type: any, row: any, meta: any) => {
                    return `<a dt-href='/billingPaymentSummary/${trim(row.companyCode)}/${trim(row.groupNumber)}/${row.accountNumber}/${trim(row.memberID)}'>${row.memberName}</a>`
                }
            },
            {
                title: 'common.lbl.memberID', name: 'memberID', render: (data: any, type: any, row: any, meta: any) => {
                    return `${removeLeftZeroForNumber(trim(row.memberID))}`;
                }
            },
            {
                title: 'common.lbl.group', name: 'groupNumber', render: (data: any, type: any, row: any, meta: any) => {
                    return `${removeLeftZeroForNumber(trim(row.groupNumber))}`;
                }
            },
            { title: 'common.lbl.account', name: 'accountNumber' },
            { title: 'billpay.billtype', name: 'billingType' },
            { title: 'billpay.billfrequency', name: 'frequency' },
        ],
        byOthers: [
            {
                title: 'ib.lbl.grid.id', name: 'identifier', render: (data: any, type: any, row: any, meta: any) => {
                    row = trimFields(row);
                    let identifier = row.identifier.replaceAll("<wbr/>", "");
                    let ids = identifier.split("-");
                    let url = '';
                    if (row.type === 'group') {
                        url = `/billingPaymentSummary/${row.companyCode}/${row.groupNumber}/${trim(ids[1])}/${row.participantIDNumber}`;
                    }
                    return row.type === 'group' ? `<a dt-href='${url}'>${row.identifier}</a>` : (config.canViewIndividualBillingPayment === true ? `<a dt-href='/billingPaymentDetail/${row.companyCode}/${identifier}'>${identifier}</a>` : identifier)
                }
            },
            {
                title: 'common.lbl.name', name: 'payorName', render: (data: any, type: any, row: any, meta: any) => {
                    row = trimFields(row);
                    let identifier: string = row.identifier.replaceAll("<wbr/>", "");
                    let ids = identifier.split("-");
                    if (row.type === 'individual' || row.type === undefined) {
                        return `<a dt-href='/personalInformation?personalInfoType=${2}&companyCode=${row.companyCode}&roleTypeCode=${'PAY'}&policyNumber=${row.identifier}&baseRiderCode=${0}'>${row.payorName}</a>`
                    } else if (row.type === 'group') {
                        return `<a dt-href='/personalInformation?personalInfoType=${1}&companyCode=${row.companyCode}&groupNumber=${row.groupNumber}&accountNumber=${ids[1]}&participantIDNumber=${row.participantIDNumber}&dependentSequenceNumber=${0}'>${row.payorName}</a>`
                    }
                }
            },
            { title: 'common.lbl.dob', name: 'birthDate', type: 'date' },
            { title: 'common.lbl.billType', name: 'billType' },
            { title: 'common.lbl.frequency', name: 'paymentFrequency' },
            { title: 'common.lbl.billedtoDate', name: 'billingToDate', type: 'date' },
            { title: 'common.lbl.paidToDate', name: 'paidToDate', type: 'date' },
            { title: 'common.lbl.premium', name: 'premiumDue', type: 'numeric' }
        ]
    }
}

const defaultInitialValues: BillingPaymentInquiryForm = {
    filterBy: '',
    lastName: '',
    firstName: '',
    nameType: NAME_TYPE_PERSON,
    companyName: '',
    birthDate: '',
    groupNumber: '',
    accountNumber: 0,
    policyNumber: '',
    participantIDNumber: '',
    govtID: '',
    isIndividualAccount: false,
};

// define validation for by name.

const byNameSchema = (values: BillingPaymentInquiryForm): any => {
    let result: any = {};
    if (values.nameType === NAME_TYPE_PERSON && isEmptyStr(values.lastName)) {
        result.lastName = INVALID_MESSAGES.LASTNAME_IS_EMPTY;
    }
    if (values.nameType === NAME_TYPE_COMPANY && isEmptyStr(values.companyName)) {
        result.companyName = INVALID_MESSAGES.COMPANY_NAME_IS_EMPTY;
    }
    return result;
}
// define validation for by policy number.
const byPolicySchema = yup.object().shape({
    policyNumber: YupSchema.policyNumber
});


const byGroupSchema = (values: BillingPaymentInquiryForm): any => {
    let result: any = {};
    if (values.groupNumber === "0" || isEmptyStr(values.groupNumber)) {
        result.groupNumber = INVALID_MESSAGES.GRUOP_IS_EMPTY;
    }
    if ((values.accountNumber + "") === "0") {
        result.accountNumber = INVALID_MESSAGES.ACCOUNT_IS_EMPTY;
    }
    if (values.isIndividualAccount) {
        if (isEmptyStr(values.firstName) && isEmptyStr(values.lastName) && isEmptyStr(values.participantIDNumber)) {
            result.firstName = INVALID_MESSAGES.FIRSTNAME_LASTNAME_OR_MEMBERID_IS_REQUIRED;
            result.lastName = INVALID_MESSAGES.FIRSTNAME_LASTNAME_OR_MEMBERID_IS_REQUIRED;
            result.participantIDNumber = INVALID_MESSAGES.FIRSTNAME_LASTNAME_OR_MEMBERID_IS_REQUIRED;
        }
        if ((!isEmptyStr(values.firstName) || !isEmptyStr(values.lastName)) && !isEmptyStr(values.participantIDNumber)) {
            result.firstName = INVALID_MESSAGES.FIRSTNAEM_LASTANEM_OR_MEMBERID_BOTH_ENTERED;
            result.lastName = INVALID_MESSAGES.FIRSTNAEM_LASTANEM_OR_MEMBERID_BOTH_ENTERED;
            result.participantIDNumber = INVALID_MESSAGES.FIRSTNAEM_LASTANEM_OR_MEMBERID_BOTH_ENTERED;
        }
        if (isEmptyStr(values.participantIDNumber) && !isEmptyStr(values.firstName) && isEmptyStr(values.lastName)) {
            result.lastName = INVALID_MESSAGES.LASTNAME_IS_EMPTY;
        }
    }
    return result;
}

const retrieve = (values: any, config: any, columns: any, setTable: any, showTable: any, next: (url: string) => void, setSubmitting?: Function, isReplay?: boolean): void => {
    let companyAndGroup = values.groupNumber.split("|");
    if (values.filterBy === config.byGroup && values.isIndividualAccount === false) {
        if (isReplay === false || isReplay === undefined) {
            next(`/billingPaymentSummary/${companyAndGroup[0]}/${companyAndGroup[1]}/${values.accountNumber}`);
        } else {
            showTable.current = false;
        }
    } else {
        let params = copyObjectExcept(values.filterBy === config.byGroup && values.isIndividualAccount === true ? { ...values, ...{ companyCode: companyAndGroup[0], groupNumber: companyAndGroup[1] } } : values, true, INIT_PARAM_PREFIX_TABLE);
        ajax({
            url: '/api/billingPaymentInquiry/' + values.filterBy,
            params: params,
            success: (res: any) => {
                showTable.current = true;
                setTable({
                    columns: values.filterBy === config.byGroup ? columns.byGroup : columns.byOthers,
                    data: res
                });
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }
}

export const BillingPaymentInquiryComponent = withView((props: ViewComponentProps) => {

    const [config, setConfig] = useState<any | null>(null);
    const [table, setTable] = useState<any | null>(null);
    const isDataLoaded = useRef<boolean>(false);
    const [accounts, setAccounts] = useState<Array<any>>([]);
    const showTable = useRef(false);
    // define validation for by govId.
    const byGovtIDSchema = yup.object().shape({
        govtID: YupSchema.govtID(DEFAULT_FORMAT_TYPE, props.getGlobalizedText)
    });
    // define Form Validate function.
    const formValidate = (values: any): any => {
        if (values.filterBy === config.byName) {
            return validate(byNameSchema, values, props);
        }
        if (values.filterBy === config.byPolicy) {
            return validate(byPolicySchema, values, props);
        }
        if (values.filterBy === config.byGovtID) {
            return validate(byGovtIDSchema, values, props);
        }
        if (values.filterBy === config.byGroup) {
            return validate(byGroupSchema, values, props);
        }
    }

    const groupChangeHandler = (e: any | string, setFieldValue?: (fieldName: string, value: any) => void, callback?: () => void) => {
        setTable({
            columns: columns.byGroup,
            data: []
        });
        let companyAndGroupNumber = e.target !== undefined ? e.target.value : e;
        if (companyAndGroupNumber === '0') {
            setAccounts([]);
            if (setFieldValue !== undefined) {
                setFieldValue("accountNumber", "0");
                setFieldValue("isIndividualAccount", false);
            }
        } else {
            let tmp = companyAndGroupNumber.split("|");
            if(tmp.length===1){
                return;
            }
            ajax({
                url: '/api/billingPaymentInquiry/fetchAccounts',
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setAccounts(res);
                    if (setFieldValue !== undefined) {
                        setFieldValue("accountNumber", res[0].value === undefined ? 0 : res[0].value);
                        setFieldValue("isIndividualAccount", res[0].accountType === 'I');
                        let st = res[0].accountType === 'I';
                        showTable.current = st;
                    }
                }, callback: callback
            });
        }
    }

    const accountChangeHandler = (e: any, setFieldValue: (fieldName: string, value: any) => void) => {
        setTable({
            columns: columns.byGroup,
            data: []
        });
        accounts.forEach((account) => {
            if (e.target.value === account.value) {
                setFieldValue("isIndividualAccount", account.accountType === 'I');
                showTable.current = account.accountType === 'I';
            }
        });
    }

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/billingPaymentInquiry/view',
                success: (res: any) => {

                    if (res.groups.length === 1 && res.groups[0].value !== '0') {
                        // if only one group selected, then try to load the accounts.
                        groupChangeHandler(res.groups[0].value);
                    }
                    let initParams = props.getInitParams();
                    if (initParams !== undefined) {
                        setConfig(res);
                        if (initParams.groupNumber !== '') {
                            groupChangeHandler(initParams.groupNumber);
                        }
                        retrieve({ ...defaultInitialValues, ...{ filterBy: res.filterBy[0].value }, ...initParams }, res, generateColumns(res), setTable, showTable, props.next, undefined, true);
                        isDataLoaded.current = true;
                    } else {
                        if (res.filterBy[0].value === res.byGroup) {
                            showTable.current = false;
                        } else {
                            showTable.current = true;
                        }
                        setConfig(res);
                    }
                }
            });
        }
    }, [config])

    let columns = generateColumns(config);

    if (config === null || (config.filterBy[0].value === config.byGroup && config.groups.length === 1 && accounts.length === 0)) {
        return <></>;
    }

    // define Form Submitting function.
    const formSubmit = (values: BillingPaymentInquiryForm): void => {
        props.setInitParams(values);
        retrieve(values, config, columns, setTable, showTable, props.next);
        isDataLoaded.current = true;
    }

    let initialValue: BillingPaymentInquiryForm = copyObjectExcept(props.getInitParams() !== undefined ? props.getInitParams() : { ...defaultInitialValues, ...{ filterBy: config.filterBy[0].value }, ...props.getInitParams() }, true, INIT_PARAM_PREFIX_TABLE);
    if (config.groups.length === 1) {
        initialValue.groupNumber = config.groups[0].value;
    }
    if (accounts.length === 1) {
        initialValue.accountNumber = accounts[0].value;
        initialValue.isIndividualAccount = accounts[0].accountType === 'I';
    }
    let filterByOptions = config.filterBy;
    if (table === null) {
        setTable({
            columns: initialValue.filterBy === config.byGroup ? columns.byGroup : columns.byOthers,
            data: []
        });
    }

    return <React.Fragment>
        <Formik initialValues={initialValue}
            validate={formValidate}
            onSubmit={formSubmit}
            {...props.generateNoramlFormikProps()}
        >
            {formProps =>
                <Form>
                    <props.Row>
                        <props.Col xs="12" sm="6" md="6">
                            <props.SelectControl onChange={(e: any) => {
                                setTable({
                                    data: [],
                                    columns: e.target.value === config.byGroup ? columns.byGroup : columns.byOthers
                                });
                                let resetTo = { ...defaultInitialValues, ...{ filterBy: e.target.value } };
                                if (config.groups.length === 1 && e.target.value === config.byGroup) {
                                    isDataLoaded.current = false;
                                    props.resetForm(formProps, resetTo);
                                    groupChangeHandler(config.groups[0].value, formProps.setFieldValue, () => {
                                        formProps.setFieldValue('groupNumber', config.groups[0].value);
                                    });
                                } else {
                                    isDataLoaded.current = false;
                                    props.resetForm(formProps, resetTo);
                                }

                                props.setInitParams(undefined);
                                setAccounts([]);
                                showTable.current = e.target.value !== config.byGroup;
                            }} name="filterBy" label="common.lbl.filterBillingPaymentBy" options={filterByOptions}>
                            </props.SelectControl>

                            <props.NameTypeSelectControl onChange={(e: any) => {
                                setTable({
                                    data: [],
                                    columns: e.target.value === config.byGroup ? columns.byGroup : columns.byOthers
                                });
                                isDataLoaded.current = false;
                                props.resetForm(formProps, { ...defaultInitialValues, ...{ nameType: e.target.value, filterBy: formProps.values.filterBy } });
                            }} condition={formProps.values.filterBy === config.byName} name="nameType" />

                            <props.GroupSelectControl sort={false} condition={formProps.values.filterBy === config.byGroup} onChange={(e: any) => { props.resetForm(formProps, mergeObject(defaultInitialValues, { filterBy: formProps.values.filterBy, groupNumber: e.target?e.target.value:e })); groupChangeHandler(e, formProps.setFieldValue) }} name="groupNumber" label="common.lbl.group" required={true} options={config.groups}>
                            </props.GroupSelectControl>
                            <props.SelectControl sort={false} condition={formProps.values.filterBy === config.byGroup} name="accountNumber" onChange={(e: any) => { props.resetForm(formProps, mergeObject(defaultInitialValues, { filterBy: formProps.values.filterBy, groupNumber: formProps.values.groupNumber, accountNumber: e.target.value })); accountChangeHandler(e, formProps.setFieldValue) }} label="common.lbl.account" required={true} options={accounts.length === 0 ? config.accounts : accounts.map((account: any) => { return { name: account.displayValue, value: account.value } })}>
                            </props.SelectControl>

                            <props.TextControl condition={formProps.values.filterBy === config.byPolicy} id="policyNumber" name="policyNumber" label="common.lbl.policyNumber" required={true}></props.TextControl>

                            <props.AutoFormatControl formatType="SSN" valueWithFormat={true} condition={formProps.values.filterBy === config.byGovtID} id="govtID" name="govtID" label="common.lbl.govID" required={true}></props.AutoFormatControl>

                            <props.Button condition={formProps.values.filterBy === config.byGovtID || formProps.values.filterBy === config.byPolicy || (formProps.values.filterBy === config.byGroup && formProps.values.isIndividualAccount === false)} type="submit">Retrieve</props.Button>

                        </props.Col>
                        <props.Col xs="12" sm="6" md="6">
                            <props.LastNameAutoCompleteControl roleTypeCodes={["OW1", "OW2", "PAY", "MBR"]} required={true} condition={formProps.values.filterBy === config.byName && formProps.values.nameType === NAME_TYPE_PERSON} htmlFor="lastName_input" id="lastName" name="lastName" />
                            <props.FirstNameAutoCompleteControl roleTypeCodes={["OW1", "OW2", "PAY", "MBR"]} condition={formProps.values.filterBy === config.byName && formProps.values.nameType === NAME_TYPE_PERSON} htmlFor="firstName_input" id="firstName" name="firstName" />
                            <props.DateTimePickerControl condition={formProps.values.filterBy === config.byName && formProps.values.nameType === NAME_TYPE_PERSON} htmlFor="birthDate_input" id="birthDate" name="birthDate" label="common.lbl.dob" dateformat={props.getDateFormat()} />
                            <props.OrgNameAutoCompleteControl required={true} roleTypeCodes={["OW1", "OW2", "PAY"]} condition={formProps.values.filterBy === config.byName && formProps.values.nameType === NAME_TYPE_COMPANY} htmlFor="companyName_input" id="companyName" name="companyName"/>

                            <props.TextControl maxLength={10} condition={formProps.values.filterBy === config.byGroup && formProps.values.isIndividualAccount === true} id="participantIDNumber" name="participantIDNumber" label="common.lbl.memberID" />
                            <props.Div condition={formProps.values.filterBy === config.byGroup && formProps.values.isIndividualAccount === true}><GlobalizedText message="common.lbl.or" /></props.Div>
                            <props.LastNameAutoCompleteControl condition={formProps.values.filterBy === config.byGroup && formProps.values.isIndividualAccount === true} roleTypeCodes={["MBR"]} htmlFor="lastName_input" id="lastName" name="lastName" />
                            <props.FirstNameAutoCompleteControl condition={formProps.values.filterBy === config.byGroup && formProps.values.isIndividualAccount === true} roleTypeCodes={["MBR"]} htmlFor="firstName_input" id="firstName" name="firstName" />

                            <props.Button condition={formProps.values.filterBy === config.byName || (formProps.values.filterBy === config.byGroup && formProps.values.isIndividualAccount === true)} type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                            <props.Button disabled={!isEmptyStr(formProps.values.firstName) || !isEmptyStr(formProps.values.lastName) || !isEmptyStr(formProps.values.participantIDNumber)} condition={formProps.values.isIndividualAccount === true && formProps.values.filterBy === config.byGroup} onClick={() => {
                                isDataLoaded.current = false;
                                let formObject = mergeObject(formProps.values, { userType: formProps.values.userType, isIndividualAccount: formProps.values.isIndividualAccount, groupNumber: formProps.values.groupNumber, accountNumber: formProps.values.accountNumber });
                                props.resetForm(formProps, formObject); formSubmit(formObject)
                            }}><GlobalizedText message="common.lbl.retrieveALLMembers" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        <props.Table isDataLoaded={isDataLoaded.current} setInitParams={props.setInitParams} getInitParams={props.getInitParams} condition={showTable.current === true} id="billingPaymentInquiryTable" next={props.next} table={table} dateformat={props.getDateFormat()}></props.Table>
    </React.Fragment >
});