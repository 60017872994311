import React, { useState, useEffect } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { Formik, Form } from 'formik';
import { ajax, decodeAmpersand } from '../shared/utils';
import GlobalizedText from '../shared/globalization';
import { TableColumn } from "../shared/components";

let selectetedRowData: any = null;

export const LabelsAndMessagesComponent = withView((props: ViewComponentProps) => {
    let varFormPromp: any;
    const [config, setConfig] = useState<any | null>(null);
    const [disabeButton, setDisabeButton] = useState<boolean>(true);
    const [handlerFlag, setHandlerFlag] = useState<string | null>();

    const tabelColumns = {
        labelColumns: [
            { "title": "memlist.id", "name": "messageId", "sortable": false },
            { "title": "labelMessage.originalLabel", "name": "originalMessageText", "sortable": false },
            { "title": "labelMessage.currentLabel", "name": "messageText", "sortable": false }
        ],
        messageColumns:
            [
                { "title": "labelMessage.messageID", "name": "messageId", "sortable": false },
                { "title": "common.lbl.originalMessage", "name": "originalMessageText", "sortable": false },
                { "title": "common.lbl.currentMessage", "name": "messageText", "sortable": false }
            ]
    };

    const pagesTableColumns: Array<TableColumn> = [
        { "title": "common.lbl.pages", "name": "effectedModules", "sortable": true }
    ]


    const [messagetable, setMessageTable] = useState<any | null>(null);
    const [relatedPagestable, setRelatedPagestable] = useState<any | null>({
        columns: pagesTableColumns,
        data: []
    });



    if (messagetable === null) {
        setMessageTable({
            columns: tabelColumns.labelColumns,
            data: []
        });
    }

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/globalization/view',
                params: {
                    messageType: 2
                },
                success: (res: any) => {
                    setConfig(res);
                    setMessageTable({
                        columns: tabelColumns.labelColumns,
                        data: res.messageList.map((row: any) => { return { ...row, ...{ checked: false } } })
                    });
                }
            });
            return () => {
            }
        }
    }, [config]);

    const searchChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        //clear data
        props.clearMessage();
        setFieldValue("pages", "0");
        setFieldValue("messageText", "");
        //disable button
        setDisabeButton(true);
        //re-search data
        ajax({
            url: '/api/globalization/search',
            params: {
                messageType: e.target.value,
                messageText: '',
                pageId: 0
            },
            success: (res) => {
                if (e.target.value === "1") {
                    setMessageTable({
                        columns: tabelColumns.messageColumns,
                        data: res.map((row: any) => { return { ...row, ...{ checked: false } } })
                    });
                } else {
                    setMessageTable({
                        columns: tabelColumns.labelColumns,
                        data: res.map((row: any) => { return { ...row, ...{ checked: false } } })
                    });
                }
            }
        });
    }

    function saveSelectedValue(row: any) {
        selectetedRowData = row;
        if (selectetedRowData != null) {
            varFormPromp.setFieldValue("selectedMessageId", selectetedRowData.messageId);
            varFormPromp.setFieldValue("updatedMessageText", selectetedRowData.messageText);

            varFormPromp.setFieldValue("selectedOriginal", selectetedRowData.originalMessageText);
            varFormPromp.setFieldValue("selectedCurrent", selectetedRowData.messageText);
            //enable button
            setDisabeButton(false);
        }
    }

    // define Form Submitting function.
    const formSubmit = (values: any): void => {
        props.clearMessage();
        if (handlerFlag === 'edit') {
            edit(values);
        } else {
            search(values);
        }

    }

    function openEditDialog(formProps: any) {
        if (selectetedRowData != null) {
            varFormPromp.setFieldValue("selectedMessageId", selectetedRowData.messageId);
            varFormPromp.setFieldValue("updatedMessageText", decodeAmpersand(selectetedRowData.messageText));
            props.showModal('#editDialog')
        }
    }

    function openRelatedPagesDialog(formProps: any) {
        if (selectetedRowData != null) {
            ajax({
                url: '/api/globalization/showAllModulesEffected',
                params: {
                    messageId: selectetedRowData.messageId
                },
                success: (res) => {
                    setRelatedPagestable({
                        columns: pagesTableColumns,
                        data: res
                    });
                }
            });

            props.showModal('#relatedPagesDialog')
        }
    }

    function search(values: any) {
        ajax({
            url: '/api/globalization/search',
            params: {
                messageType: values.labelmessageSelect,
                pageId: values.pages,
                messageText: values.messageText
            },
            success: (res) => {
                //disable button
                setDisabeButton(true);
                if (values.labelmessageSelect === "1") {
                    setMessageTable({
                        columns: tabelColumns.messageColumns,
                        data: res.map((row: any) => { return { ...row, ...{ checked: false } } })
                    });
                } else {
                    setMessageTable({
                        columns: tabelColumns.labelColumns,
                        data: res.map((row: any) => { return { ...row, ...{ checked: false } } })
                    });
                }
            }
        });
    }

    function edit(values: any) {
        ajax({
            url: '/api/globalization/update',
            method: 'post',
            data: {
                messageId: selectetedRowData.messageId,
                messageText: values.updatedMessageText,
                languageCode: 'en'
            },
            success: (res) => {
                props.closeModal('#editDialog');
                if (values.labelmessageSelect === '2') {
                    props.showMessage("success", "labelMessage.labelUpdatedSuccessful");
                } else {
                    props.showMessage("success", "labelMessage.messageUpdatedSuccessful");
                }
                search(values);
            }
        });
    }

    return <React.Fragment>
        <Formik initialValues={{
            labelmessageSelect: "2",
            pages: "0",
            messageText: "",

            selectedMessageId: '',
            updatedMessageText: '',

            selectedOriginal: '',
            selectedCurrent: ''
        }}
            //validate={ }
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps => {
                varFormPromp = formProps;
                return <Form>
                    <props.Row>
                        <props.Col xs="12" sm="5" md="4" lg="3">
                            <props.SelectControl name="labelmessageSelect" label="labelMessage.search" onChange={(e: any) => { searchChangeHandler(e, formProps.values, formProps.setFieldValue) }}>
                                <option value="2">Field label</option>
                                <option value="1">Message</option>
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="5" md="4" lg="3">
                            <props.SelectControl label="common.lbl.page" sort={false} name="pages" options={config === null ? [{ name: "common.lbl.all", value: "0" }] : config.pageList} >
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="5" md="4" lg="3">
                            <props.TextControl id="messageText" name="messageText" label="labelMessage.keyword" placeholder="Input message content" />
                        </props.Col>
                        <props.Col xs="12" sm="5" md="4" lg="3">
                            <props.Button style={{ marginTop: 19 }} name="searchBtn" type="submit" onClick={() => { setHandlerFlag("search") }} ><GlobalizedText message="labelMessage.search" /></props.Button>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <props.HR />
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <GlobalizedText message='labelMessage.searchEdit' />
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <props.Button name="operationEdit" disabled={disabeButton} onClick={() => { openEditDialog(formProps) }}><GlobalizedText message="common.lbl.edit" /></props.Button>
                            <props.Button name="operationSAME" disabled={disabeButton} onClick={() => { openRelatedPagesDialog(formProps) }}><GlobalizedText message="common.lbl.showRelatedPages" /></props.Button>
                        </props.Col>
                    </props.Row>

                    {/* Modal for Edit */}
                    <props.Modal title={() => {
                        if (formProps.values.labelmessageSelect === '2') {
                            return <GlobalizedText message="common.lbl.editLabel"></GlobalizedText>
                        } else {
                            return <>
                                <GlobalizedText message="common.lbl.edit"></GlobalizedText> <GlobalizedText message="labelMessage.message"></GlobalizedText>
                            </>
                        }
                    }} id="editDialog" footer={() => {
                        return <>
                            <props.Button type="submit" onClick={() => { setHandlerFlag("edit") }} ><GlobalizedText message="common.lbl.save" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} >
                        <props.Row>
                            <props.Col xs="12" sm="12" md="12">
                                <props.DL>
                                    <props.DLI titleClass="gwp-label-ro" title="labelMessage.messageID" desc={selectetedRowData === null ? "" : selectetedRowData.messageId}></props.DLI>
                                </props.DL>
                            </props.Col>
                        </props.Row>
                        <props.Row>
                            <props.Col xs="12" sm="12" md="12" >
                                <props.TextareaControl style={{ maxWidth: '100%' }} rows="4" id="updatedMessageText" name="updatedMessageText" label="English" maxLength={300} showRemaining={true} />
                            </props.Col>
                        </props.Row>
                    </props.Modal>

                    {/* Modal for Show Related Pages */}
                    <props.Modal title="common.lbl.showRelatedPages" id="relatedPagesDialog" footer={() => {
                        return <>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }} >
                        <props.Row>
                            <props.Col xs="12" sm="6" md="6">
                                <props.DL >
                                    <props.DLI titleClass="gwp-label-ro" title={() => {
                                        if (formProps.values.labelmessageSelect === '2') {
                                            return <GlobalizedText message="labelMessage.originalLabel"></GlobalizedText>;
                                        } else {
                                            return <GlobalizedText message="common.lbl.originalMessage"></GlobalizedText>;
                                        }

                                    }} desc={selectetedRowData === null ? "" : selectetedRowData.originalMessageText}></props.DLI>
                                </props.DL>
                            </props.Col>
                            <props.Col xs="12" sm="6" md="6">
                                <props.DL>
                                    <props.DLI titleClass="gwp-label-ro" title={() => {
                                        if (formProps.values.labelmessageSelect === '2') {
                                            return <GlobalizedText message="labelMessage.currentLabel"></GlobalizedText>;
                                        } else {
                                            return <GlobalizedText message="common.lbl.currentMessage"></GlobalizedText>;
                                        }
                                    }} desc={selectetedRowData === null ? "" : selectetedRowData.messageText}></props.DLI>
                                </props.DL>
                            </props.Col>
                        </props.Row>
                        <props.Table id="relatedPagesTable" modal="relatedPagesDialog" table={relatedPagestable}></props.Table>
                    </props.Modal>

                </Form>
            }
            }
        </Formik >

        <props.Table id="labelMessageTable" select={{
            type: 'single', onSelect: (e: any, rows: number[]) => {
                saveSelectedValue(messagetable.data[rows[0]]);
            }
        }} table={messagetable}></props.Table>

    </React.Fragment >
});