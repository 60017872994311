import React, {useContext} from 'react';
import GlobalizedText from '../shared/globalization';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import './css/managefaq.css';
import { SecurityContext } from '../context/securityContext';
import { UserTypeCode } from '../shared/constants/userTypeCode';

/**
 * Component of Manage FAQ
 */
export const ManageFAQComponent = withView((props: ViewComponentProps) => {
    const {userAuth} = useContext(SecurityContext);
    const allowedUserTypes = [UserTypeCode.PORTAL_ADMIN, UserTypeCode.CONTENT_MANAGEMENT_ADMIN];

    if(!userAuth || (userAuth.userTypeRegCode && !allowedUserTypes.includes(userAuth.userTypeRegCode)))
        return <></>

    return <>
        <props.Div className="faqmenu">
        <props.PageTitle className="faqtitle"><props.Link to="/manageFaqCategory"><GlobalizedText message="common.lbl.manageCategory" /></props.Link></props.PageTitle>
        <GlobalizedText message="common.msg.editCategory" />
        </props.Div>
        <props.Div className="faqmenu">
        <props.PageTitle className="faqtitle"><props.Link to="/manageFaqQuestion"><GlobalizedText message="common.lbl.manaQuestions" /></props.Link></props.PageTitle>
        <GlobalizedText message="common.msg.editQuestion" />
        </props.Div>
    </>
})