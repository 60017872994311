import React from 'react';
import { ajax, decodeAmpersand, getFromStorage, isEmptyObject, isEmptyStr, removeFromStorage, saveToStorage } from './utils';
import { App } from '..';

export const GlobalizationContext = React.createContext<any | null>(null);

interface GlobalizedTextState {
    messages: any | null
}

export interface GlobalizedTextPlaceHolder {
    from: string
    to: string
}

export interface GlobalizedTextProps {
    message: string;
    placeholder?: GlobalizedTextPlaceHolder[];
    className?: string;
}

export default class GlobalizedText extends React.Component<GlobalizedTextProps, GlobalizedTextState>{

    constructor(props: any) {
        super(props);
        this.state = { messages: null };
    }

    render() {
        let messages = this.context;
        let msg: string = messages === null || messages[this.props.message] === undefined ? this.props.message : messages[this.props.message];
        if (this.props.placeholder !== undefined) {
            this.props.placeholder.forEach((ph) => {
                msg = msg.replace(ph.from, ph.to);
            });
        }
        return (
            <span className={this.props.className === undefined ? '' : this.props.className} dangerouslySetInnerHTML={{ __html: msg }} />
        );
    }
}
GlobalizedText.contextType = GlobalizationContext;

let GLOBALIZATION_URL = "/api/globalization/all/en";
let GLOBALIZATION_VERSION_URL = "/api/globalization/version";

export interface Message {
    [messageKey: string]: string;
}

const loadMessagesFromStorage = (component: any) => {
    let messagesObj = getFromStorage("globalization.messages", false);
    if (messagesObj !== null && !isEmptyObject(messagesObj)) {
        component.setState(updateMessagesInState(component.state, messagesObj));
    }
    return messagesObj;
}
const loadMessagesFormServer = (component: any, expiry?: boolean) => {
    let messages: Message = {};
    ajax({
        url: GLOBALIZATION_URL,
        success: (data1: any) => {
            if (expiry) {
                removeFromStorage("globalization.messages")
            }
            for (let one of data1) {
                messages[one.messageKey.trim()] = decodeAmpersand(one.localizedText.trim());
            }
            component.setState(updateMessagesInState(component.state, messages));
            saveToStorage("globalization.messages", messages, false);
        },
        showMask: false
    });
}

export const init = (component: App) => {
    if (component?.state?.expirationTime === 0) return;  // To prevent Execution call on Default Expiration Value
    let version = getFromStorage("globalization.version", false);
    let expiration = new Date(getFromStorage("globalization.expiration", false));    
    var today = new Date();
    expiration?.setHours(expiration.getHours() + component?.state?.expirationTime);  
    let expiry = expiration === null || expiration <= today ;


    if (!isEmptyStr(version) || expiry) {
        if (isEmptyObject(loadMessagesFromStorage(component)) || expiry) {
            loadMessagesFormServer(component, expiry);
            saveToStorage("globalization.expiration", new Date(), false);
        }
    }

    ajax({
        url: GLOBALIZATION_VERSION_URL,
        success: versionOnServer => {
            if (version !== versionOnServer  || expiry) {
                saveToStorage("globalization.version", versionOnServer, false);
                loadMessagesFormServer(component, expiry);
                saveToStorage("globalization.expiration", new Date(), false);
            }
        }, showMask: false
    });
}

export const tryReload = (component: App) => {
    let version = getFromStorage("globalization.version", false);
    ajax({
        url: GLOBALIZATION_VERSION_URL,
        success: versionOnServer => {
            if (version !== versionOnServer) {
                saveToStorage("globalization.version", versionOnServer, false);
                loadMessagesFormServer(component);
            }
        },
        showMask: false
    });
}



function updateMessagesInState(state: GlobalizedTextState, messages: Message) {
    state.messages = messages;
    return state;
}