/*
	The following immutable enumerator reflects a snippet of the UserTypeEnum.java 
	class int the backend architecture.
*/
export const UserTypeCode = Object.freeze({
	AGENCY: "08",
	SECURITY_PORTAL_ADMIN: "10",
	CONTENT_MANAGEMENT_ADMIN: "11",
	CONTENT_APPROVER: "12",
	PORTAL_ADMIN: "01",
	DEPENDENT:"13",
	IND_MEM:"02",
	AGENT:"06",
	IND_MEM_ORG:"03",
	PROVIDER:"04",
	GROUP_ADMIN:"09",
	CSR:"05",
	UNKNOWNUSER:"98",
	ANONYMOUSUSER: "00"
});
