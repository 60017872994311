import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import GlobalizedText from "../../shared/globalization";
import { ajax, trim, validate } from "../../shared/utils";
import { ViewComponentProps, withView } from "../../shared/viewcomponent";
import Enrollment from '../types/enrollmenttypes';
import { Beneficiary } from "./beneficiary";
import { BenefitClassificationSelect } from "./benefitclassificationselect";
import { Confirmation } from "./confirmation";
import { CoordinationBenefits } from "./coordinationbenefits";
import { CoverageSelection } from "./coverageselection";
import './css/enrollment.css';
import { Dependent } from "./dependent";
import { LifeEvent } from "./lifeevent";
import { Member } from "./member";

const PAGES: any = {
    lifeEvent: LifeEvent,
    personalInformation: Member,
    dependent: Dependent,
    benefitClassificationSelect: BenefitClassificationSelect,
    coverageSelection: CoverageSelection,
    coordinationBenefits: CoordinationBenefits,
    beneficiary: Beneficiary,
    confirmation: Confirmation
}

interface EnrollmentSubmitViewResponse {
    transactionID: string
    result: string
    memberName: string
    memberID: string
    errorMsg: string
}

export const EnrollmentComponent = withView((props: ViewComponentProps) => {

    const formAction = props.getParam("formAction");
    const formType = props.getParam("formType");
    const companyCode = props.getParam("companyCode");
    const groupNumber = props.getParam("groupNumber");
    const accountNumber = props.getParam("accountNumber");
    const participantIDNumber = props.getParam("participantIDNumber");
    const clientNumber = props.getParam("clientNumber");
    const transactionID = props.getParam("transactionID");
    const anniversaryDate = props.getParam("anniversaryDate");
    const govtID = props.getParam("govtID");
    const fromTransactionList = props.getParam("fromTransactionList");
    const transactionIDList = props.getParam("transactionIDList");
    const payrollIdentifier = props.getParam("payrollIdentifier");
    const [formExisted, setFormExisted] = useState<string>("");

    const [view, setView] = useState<Enrollment.NextViewResponse>();
    const callbacks = useRef<any>({});
    const validations = useRef<any>({});
    const operation = useRef<Enrollment.PageOperation>();
    const formValues = useRef<any>({})
    const prepareData = useRef<(values: any) => any>(() => { return {} });
    const pdfViewer = props.usePDFViewer({ ...props });
    const [submitResult, setResult] = useState<EnrollmentSubmitViewResponse>();

    function getTitleByFormType(type: any) {
        let msgID = "";
        if (type === "lifeEvent") {
            msgID = "my.ins.act.opt.update.life.event";
        } else if (type === "openEnrollment") {
            msgID = "common.lbl.openEnrollment";
        } else if (type === "updateEnrollment") {
            msgID = "common.lbl.updateEnrollment";
        } else if (type === "initBenefitSelection") {
            msgID = "common.lbl.benefitSelection";
        } else if (type === "editEnrollmentM") {
            msgID = "common.lbl.editEnrollment";
        } else if (type === "addMember") {
            msgID = "common.lbl.addNewMember";
        } else if (type === "updatePayroll") {
            msgID = "common.lbl.updatepayrollschedule";
        }
        return props.getGlobalizedText(msgID);
    }

    useEffect(() => {
        if (view === undefined) {
            if (fromTransactionList !== undefined && fromTransactionList && "editTransaction" === trim(formAction)) {
                let url: string = `/api/enrollment/open`;
                let transactionIDListArray: Array<string> = [];
                transactionIDListArray.push(transactionIDList);
                ajax({
                    method: 'post',
                    url: url,
                    data: {
                        "formAction": formAction,
                        "formType": formType,
                        "fromTransactionList": fromTransactionList,
                        "transactionIDList": transactionIDListArray
                    },
                    success: (res) => {
                        setView(res);
                        props.setPageTitle(getTitleByFormType(res.formTypeName));
                        setFormExisted(props.getGlobalizedText("common.lbl.formExisted").replace("xxxx", participantIDNumber));
                    }
                });

            } else {
                ajax({
                    method: 'post',
                    url: `/api/enrollment/open`,
                    data: {
                        "formType": formType,
                        "companyCode": companyCode,
                        "groupNumber": groupNumber,
                        "accountNumber": accountNumber,
                        "participantIDNumber": participantIDNumber,
                        "clientNumber": clientNumber,
                        "transactionID": transactionID,
                        "anniversaryDate": anniversaryDate,
                        "govtID": govtID,
                        "payrollIdentifier": payrollIdentifier
                    },
                    success: (res) => {
                        setView(res);
                        props.setPageTitle(getTitleByFormType(formType));

                    }
                });
            }
        }
    });

    if (view === undefined) {
        return <></>
    }

    let submitModalTitle: JSX.Element;
    if (submitResult?.result === 'ok') {
        submitModalTitle = <props.BootstrapIcon type="ok" label="open.enrollment.enrolSubmit" />
    } else {
        submitModalTitle = <props.Icon type="warning" label="open.enrollment.enrolreject"></props.Icon>
    }

    const current = view.pages.findIndex((page) => {
        return page.status === 'current'
    });

    const pageName = view.pages[current].name;

    const Page = PAGES[pageName];
    const setValidation = (validation: any) => {
        validations.current[pageName] = validation;
    }

    const setOperationCallback = (callback: Enrollment.PageOperationCallback) => {
        callbacks.current[pageName] = callback;
    }

    const setPrepareData = (obj: any) => {
        prepareData.current = obj;
    }

    const setFormValue = (newFormValue: any) => {
        formValues.current[pageName] = newFormValue;
    }

    const removeDuplidatedPages = (oldPages: Array<Enrollment.Page>) => {
        let result: Array<Enrollment.Page> = [];
        let pageMap: any = {};
        oldPages.forEach((p, index) => {
            if (pageMap[p.description] !== undefined) {
                if (p.status === 'current') {
                    result.pop();
                    result.push(p);
                }
                return;
            }
            pageMap[p.description] = index;
            result.push(p);
        });
        result = result.map((p) => {
            if (p.description === oldPages[current].description) {
                p.status = 'current'
            }
            return p;
        });
        return result;
    }

    let pages = removeDuplidatedPages(view.pages);

    function exit(transID: any) {
        ajax({
            method: 'post',
            data: {
                "formType": formType,
                "formAction": formAction,
                "transactionID": transID,
                "fromTransactionList": formAction === "editTransaction" ? true : false
            },
            url: `/api/enrollment/exit`,
            success: (res) => {
                if (formAction === "editTransaction") {
                    //need to go back to previous 2 page
                    while (props.breadcrumb.length() > 2) {
                        props.back();
                    }


                } else {
                    props.back();
                }
            }
        });
    }
    return <>
        <Formik initialValues={{

        }}

            validate={(values) => {
                if (validations.current[pageName] !== undefined && operation.current !== 'previous' && operation.current !== 'submit') {

                    if(pageName === 'personalInformation' && !document.getElementById('email')){
                        delete validations.current[pageName].fields.email;
                     }
                     
                    return validate(validations.current[pageName], values, props);
                }
            }}
            validateOnChange={true}
            onSubmit={(values, { setSubmitting }) => {
                let data = prepareData.current(values);

                // Here we are correcting dependentIdx and dependentSequenceNumber for all depedents
                if(data && data.dependents && data.dependents.length > 0) {
                    let correctedDependents:any = [];
                    data.dependents.forEach((dependent: any, index: any) => {
                        // Dependents loaded from DB will remain as is
                        if(dependent.isLoadFromDB === 'Y') {
                            correctedDependents.push({...dependent});
                        }
                        else {
                            correctedDependents.push({
                                ...dependent,
                                dependentIdx: index,
                                dependentSequenceNumber: index + 1
                            })
                        }
                    });
                    data = {...data, dependents: correctedDependents};
                }

                data.transactionID = view.transactionID;
                if (operation.current === 'previous') {
                    props.clearMessage();
                    ajax({
                        method: 'post',
                        url: `/api/enrollment/${pageName}/previous`,
                        data: data,
                        success: (res) => {
                            setView(res);
                        },
                        fail: callbacks.current[pageName]?.previous?.fail,
                        callback: callbacks.current[pageName]?.previous?.callback,
                        error: callbacks.current[pageName]?.previous?.error,
                    });
                }
                if (operation.current === 'next') {
                    ajax({
                        method: 'post',
                        url: `/api/enrollment/${pageName}/next`,
                        data: data,
                        success: (res) => {
                            setView(res);
                        },
                        fail: callbacks.current[pageName]?.next?.fail,
                        callback: callbacks.current[pageName]?.next?.callback,
                        error: callbacks.current[pageName]?.next?.error,
                    });
                }
                if (operation.current === 'save') {
                    ajax({
                        method: 'post',
                        url: `/api/enrollment/${pageName}/save`,
                        data: data,
                        success: (res) => {
                            props.showModal("#saveModal");
                        },
                        fail: callbacks.current[pageName]?.save?.fail,
                        callback: callbacks.current[pageName]?.save?.callback,
                        error: callbacks.current[pageName]?.save?.error,
                    });
                }
                if (operation.current === 'submit') {
                    ajax({
                        method: 'post',
                        url: `/api/enrollment/${pageName}/submit`,
                        data: data,
                        success: (res) => {
                            setResult(res);
                            props.showModal("#submitModal");
                        },
                        fail: callbacks.current[pageName]?.submit?.fail,
                        callback: callbacks.current[pageName]?.submit?.callback,
                        error: callbacks.current[pageName]?.submit?.error,
                    });
                }
                if (operation.current === 'download') {
                    data.htmlText = document.getElementById('PDFHTMLContainer')?.innerHTML;
                    ajax({
                        method: 'post',
                        url: `/api/enrollment/${pageName}/createPDF`,
                        data: data,
                        success: (res) => {
                            pdfViewer.showPDF('/api/enrollment/confirmation/download/' + view.transactionID);
                        },
                        fail: callbacks.current[pageName]?.download?.fail,
                        callback: callbacks.current[pageName]?.download?.callback,
                        error: callbacks.current[pageName]?.download?.error,
                    });
                }
            }}
            {...props.generateNoramlFormikProps()}
        >
            {formProps => {
                if (formValues.current[pageName] !== undefined) {
                    setFormValue(formProps.values);
                }
                return <Form>
                    <props.Row condition={view.formOpened === true}>
                        <props.Col sm='12'>
                            <props.Information message={formExisted}></props.Information>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col sm='12'>
                            <props.ProgressBar id="enrollmentbar" config={{ pages: view === undefined ? [] : pages.map((page) => { return { name: page.description, status: page.status } }) }}></props.ProgressBar>
                        </props.Col>
                    </props.Row>
                    <props.Row condition={pageName === 'confirmation'}>
                        <props.Col xs="12" sm="12" md="12" className="confirmationWarnsubmit">
                            <props.Icon type='warning' /><GlobalizedText message="confirmation.msg.warnsubmit" />
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col xs="12">
                            <ButtonGroup totalPages={view.pages.length} current={current} operation={operation} validation={validations.current[pageName]} {...props} />
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col xs="12">
                            <Page setOperationCallback={setOperationCallback} formProps={formProps} transactionID={view.transactionID} formValue={formValues.current[pageName]} setFormValue={(newFormValue: any) => { setFormValue({ ...formProps.values, ...newFormValue }); formProps.setValues({ ...formProps.values, ...newFormValue }); }} setPrepareData={setPrepareData} setValidation={setValidation} {...props} />
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col xs="12">
                            <ButtonGroup totalPages={view.pages.length} current={current} operation={operation} validation={validations.current[pageName]} {...props} />
                        </props.Col>
                    </props.Row>
                </Form>
            }
            }
        </Formik>
        <pdfViewer.Component></pdfViewer.Component>
        <props.Modal id="discardModal" title={() => {
            return <props.BootstrapIcon type="ok" label="enrollment.lbl.discardTitle" />
        }} footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.button.continue" /></props.Button>
                <props.Button data-dismiss="modal" onClick={() => { exit(view.transactionID); }}><GlobalizedText message="common.button.exit" /></props.Button>
            </>
        }}>
            <p><GlobalizedText message="enrollment.continue.exited" /></p>
        </props.Modal>


        <props.Modal id="saveModal" title={() => {
            return <props.BootstrapIcon type="ok" label="enrollment.saved" />
        }} footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.button.continue" /></props.Button>
                <props.Button data-dismiss="modal" onClick={() => { exit(view.transactionID); }}><GlobalizedText message="common.button.exit" /></props.Button>
            </>
        }}>
            <p><GlobalizedText message="enrollment.saved.successfully" /></p>
            <br />
            <p><GlobalizedText message="enrollment.continue.exited" /></p>
        </props.Modal>

        <props.Modal id="submitModal" title={() => {
            return submitModalTitle
        }} footer={() => {
            return <>
                <props.Button data-dismiss="modal" onClick={() => { exit(view.transactionID); }}><GlobalizedText message="common.button.exit" /></props.Button>
            </>
        }}>
            <props.Row>
                <props.Col sm="12" md="12" xs="12">
                    <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                        <props.DLI condition={submitResult?.memberName !== ''} title="common.lbl.memberName" desc={submitResult?.memberName} />
                        <props.DLI condition={submitResult?.memberID !== ''} title="common.lbl.memberID" desc={submitResult?.memberID} />
                        <props.DLI condition={submitResult?.transactionID !== ''} title="common.lbl.transNumber" desc={submitResult?.transactionID} />
                        <props.DLI condition={submitResult?.errorMsg !== ''} title="open.enrollmentcon.errormessage" desc={() => {
                            return <>
                              <strong><i><GlobalizedText message="open.enrollmentcon.errormesgdisplay"/></i></strong>
                            </>
                        }} />
                       
                    </props.DL>
                </props.Col>
            </props.Row>
        </props.Modal>
    </>
});

interface ButtonProps extends ViewComponentProps {
    validation: any,
    operation: any,
    current: number,
    totalPages: number;
}

const ButtonGroup = (props: ButtonProps) => {
    return <>
        <PreviousButton {...props} />
        <SubmitButton {...props} />
        <SaveButton  {...props} />
        <NextButton  {...props} />
        <DiscardButton  {...props} />
        <DownloadButton {...props} />
    </>
}

const PreviousButton = (props: ButtonProps) => {
    return <props.Button condition={props.current !== 0} type="submit" onClick={() => {
        props.operation.current = "previous";
    }}><GlobalizedText message="common.button.previous" /></props.Button>;
}
const NextButton = (props: ButtonProps) => {
    return <props.Button condition={props.current !== props.totalPages - 1} type="submit" onClick={() => {
        props.operation.current = "next";
    }}><GlobalizedText message="common.button.next" /></props.Button>;
}
const DiscardButton = (props: ButtonProps) => {
    return <props.Button onClick={() => props.showModal("#discardModal")}><GlobalizedText message="discard" /></props.Button>;
}
const SaveButton = (props: ButtonProps) => {
    return <props.Button type="submit" onClick={() => {
        props.operation.current = "save";
    }}><GlobalizedText message="common.lbl.save" /></props.Button>;
}
const SubmitButton = (props: ButtonProps) => {
    return <props.Button condition={props.current === props.totalPages - 1} type="submit" onClick={() => {
        props.operation.current = "submit";
    }}><GlobalizedText message="common.lbl.submit" /></props.Button>;
}
const DownloadButton = (props: ButtonProps) => {
    return <props.Button condition={props.current === props.totalPages - 1} type="submit" onClick={() => {
        props.operation.current = "download";
    }}><GlobalizedText message="common.lbl.download" /></props.Button>;
}


export interface RequiredHeaderProps extends ViewComponentProps {
    label: string
}

export const RequiredHeader = (props: RequiredHeaderProps) => {
    return <>
        <GlobalizedText message={props.label} /> ( <props.Label required={true}><GlobalizedText message="common.lbl.requiredField" /></props.Label>)
    </>
}