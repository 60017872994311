import React from "react";

export function Header(props: any) {
    return <></>
}

export function Footer(props: any) {
    return <></>
}

export function Layout(props: any) {
    return <>
        {props?.children}
    </>
}