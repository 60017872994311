
import React, { useEffect, useRef, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, isEmptyObject, saveToStorage } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/help.css';
import { TableColumn } from '../shared/components';

let selectetedRowData: any = null;

export const HelpLocationComponent = withView((props: ViewComponentProps) => {

    const [location, setLocation] = useState<any>();
    const [disableButton, setDisableButton] = useState<boolean>(true);
    let onlineHelpID = props.getParam("id");
    let onlineHelpName = props.getParam("name");
    //let componentID = props.getParam("componentID");

    const selected = useRef<any>();

    const tableLocationColumns: Array<TableColumn> = [
        { name: 'name', title: 'common.lbl.helpLocation' },
        { name: 'label', title: 'common.lbl.helpLabel' }
    ]

    const [helpLocationTable, setHelpLocationTable] = useState<any | null>(null);
    if (helpLocationTable === null) {
        setHelpLocationTable({
            columns: tableLocationColumns,
            data: []
        });
    }

    let full = window.location.host;
    let parts = full.split(':');
    let hostname = parts[0];
    //let hostname = "portalgdemo.cnx.com";

    useEffect(() => {
        if (location === undefined) {
            ajax({
                url: `/api/cms/onlinehelp/listComponents`,
                params: { site: hostname },
                success: (res: any) => {
                    setLocation(res);
                    setHelpLocationTable({
                        columns: tableLocationColumns,
                        data: res.components.map((row: any) => {
                            return {
                                ...{
                                    name: props.getGlobalizedText(row.name)
                                    , label: isEmptyObject(row?.onlineHelp) ? undefined : props.getGlobalizedText(row?.onlineHelp.name)
                                    , checked: false
                                }
                            }
                        })
                    });
                }
            });
        }
    }, [location]);
    if (location === undefined) {
        return <></>
    }
    function selectHelpLocation() {
        ajax({
            url: `/api/cms/onlinehelp/selectLocation`,
            data:
            {
                "onlineHelpID": onlineHelpID,
                "componentID": selected.current.id
            },
            method: 'put',
            success: () => {
                saveToStorage("helplocationcallback", { 'status': 'success', 'message': props.getGlobalizedText("common.msg.success_select_help").replace("location", props.getGlobalizedText(selected.current.name)).replace("label", onlineHelpName) }, true);
                props.back();
            },
            error: () => {
                saveToStorage("helplocationcallback", { 'status': 'error', 'message': "common.msg.fail_select_help" }, true);
                props.back();
            }
        });
    }

    function saveSelectedLocationValue(loca: any) {
        if (loca !== null) {
            selectetedRowData = loca;
            setDisableButton(false);
        }
    }

    return <>
        <props.Div>
            <props.Button disabled={disableButton} onClick={() => selectHelpLocation()}><GlobalizedText message="common.lbl.add" /></props.Button>
            <props.Button onClick={props.back}><GlobalizedText message="common.lbl.close" /></props.Button>
        </props.Div>

        <props.Table id="helpLocationTable" select={{
            type: 'single',
            disabled: (row: any) => {
                let result = true;
                let status = row['label'];
                if (isEmptyObject(status)) {
                    result = false;
                }
                return result;
            },
            onSelect: (e: any, rows: number[]) => {
                saveSelectedLocationValue(helpLocationTable.data[rows[0]]);
                selected.current = location.components[rows[0]];
            }
        }} table={helpLocationTable}
            option={{ order: [[2, 'asc']] }}></props.Table>

    </>
});