import React, { useEffect, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import { ajax, formatDateTime, isEmptyObject } from '../shared/utils';
import './css/policycomments.css'

export const PolicyCommentsComponent = withView((props: ViewComponentProps) => {
    const [config, setConfig] = useState<any>();
    const [rowNum, setRowNum] = useState<number>(0);
    const companyCode: string = props.getParam("companyCode");
    const policyNumber: string = props.getParam("policyNumber");
    const thisPage: string = 'policyComments'
    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/policyNotes/view',
                params: {
                    companyCode: props.getQueryParam("companyCode"),
                    policyNumber: props.getQueryParam("policyNumber")
                },
                success: (data: any) => {
                    setConfig(data);
                }
            });
        }

    }, [config]);

    if (config === undefined) {
        return <></>
    }
    let ownerLabel = isEmptyObject(config.owner2) ? props.getGlobalizedText("common.lbl.owner") : props.getGlobalizedText("common.lbl.owner") + ' 1';

    return <div id="policyComments">
        <props.Row>
            <props.Col xs="12" sm="12" md="12">
                <div className="gwp-page-header">
                    <div className="gwp-page-header-block"></div>
                    <div className="gwp-page-header-label gwp-label"><GlobalizedText message="common.lbl.policyNumber" /> {config.policyNumber}</div>
                </div>
            </props.Col>
        </props.Row>
        {!isEmptyObject(config.owner1) && <>
            <props.Row>
                <props.Col xs="12" sm="8" md="8">
                    <props.DL className="dl-horizontal gwp-dl app-detail-dl marginLeft180px">
                        <props.DLI title={ownerLabel} desc={() => {
                            return <props.Link to={`/personalInformation?isUpdateMode=false&personalInfoType=2&companyCode=${companyCode}&roleTypeCode=${config.owner1.roleTypeCode}&policyNumber=${policyNumber}&baseRiderCode=0&from=${thisPage}`} >

                                {config.owner1.ownerName}
                            </props.Link>
                        }} />
                        <props.DLI title="pd.lbl.address" isHtml={true} desc={config.owner1.ownerAddress} />
                    </props.DL>
                </props.Col>
            </props.Row>
        </>
        }
        {!isEmptyObject(config.owner2) && <>
            <props.Row>
                <props.Col xs="12" sm="8" md="8">
                    <props.DL className="dl-horizontal gwp-dl app-detail-dl marginLeft180px">
                        <props.DLI title={props.getGlobalizedText("common.lbl.owner") + " 2"} desc={() => {
                            return <props.Link to={`/personalInformation?isUpdateMode=false&personalInfoType=2&companyCode=${companyCode}&roleTypeCode=${config.owner1.roleTypeCode}&policyNumber=${policyNumber}&baseRiderCode=0&from=${thisPage}`} >
                                {config.owner2.ownerName}
                            </props.Link>
                        }} />
                        <props.DLI title="pd.lbl.address" isHtml={true} desc={config.owner2.ownerAddress} />
                    </props.DL>
                </props.Col>
            </props.Row>
        </>
        }
        <props.Panel className="noBorder">
            <props.PanelHeading>
                <div className="app-detail-title"><GlobalizedText message="common.lbl.comments" /></div>
            </props.PanelHeading>
            <props.PanelBody className="paddingLeft0px" >
                <props.Row className="pd-policyinfo-content">
                    <props.Col xs="12" sm="12" md="12">
                        <props.Table id="policyCommentsTable" option={{ order: [[0, 'desc']] }} >
                            <thead key={0}>
                                <tr>
                                    <th scope="col" data-type="date" data-width="15%" data-order="desc" ><GlobalizedText message="common.lbl.date" /></th>
                                    <th scope="col" data-width="15%"><GlobalizedText message="common.lbl.time" /></th>
                                    <th scope="col" data-sortable={false}><GlobalizedText message="common.lbl.comments" /> </th>
                                </tr>
                            </thead>
                            <tbody key={1}>
                                {config.policyComments?.map((pr: any, index: number) => {
                                    return <tr key={index}>
                                        <td>{pr.createDate}</td>
                                        <td>{formatDateTime(pr.createTime, "HH:mm", "hh:mm a")}</td>
                                        <td>{!pr.needTrunc ? pr.comments : <> {pr.truncatedComments} < props.LinkButton onClick={() => { setRowNum(index); props.showModal("#policyComment") }}> More...</props.LinkButton></>}</td>
                                    </tr>
                                })}
                            </tbody>
                        </props.Table>

                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel>
        {/* ViewCoverageBeneficiaries Dialog */}
        <props.Modal id="policyComment" size="large" title="view.policy.comment" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                <props.Col md={12} sm={12} xs={12}>
                    <props.DL className="dl-horizontal">
                        <props.DLI title="common.lbl.policyNumber" isHtml={true} desc={config.policyNumber} />
                        <props.DLI title="common.lbl.date" isHtml={true} desc={config.policyComments[rowNum].createDate} />
                        <props.DLI title="common.lbl.time" isHtml={true} desc={formatDateTime(config.policyComments[rowNum].createTime, "HH:mm", "hh:mm a")} />
                    </props.DL>
                </props.Col>

            </props.Row>
            <props.Panel className="noBorder">
                <props.PanelHeading>
                    <div className="claim-title"><GlobalizedText message="common.lbl.comment" /></div>
                </props.PanelHeading>
                <props.PanelBody className="scroll50px border">
                    <div >{config.policyComments[rowNum].comments}</div>
                </props.PanelBody>
            </props.Panel>
        </props.Modal >

    </div >
});

