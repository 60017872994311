import React, { HTMLAttributes } from 'react';
import { useContext } from 'react';
import { ConditionProps } from '..';
import { GlobalizationContext } from '../../globalization';
import { withErrorControl, WrapperControlProps } from './field';
import { withCondition } from './shared-component';

export interface ImgSelectOption {
    category: {
        msgKey: string;
        name: string;
    };
    height?: number;
    name: string;
    msgKey: string;
    width?: number;

}
export interface ImageOptionProps extends WrapperControlProps, ConditionProps {
    condition?: boolean | Function;
    check?: any;
    imgOption: ImgSelectOption;
    onClick?: (e: any) => void;
}
export interface ImageProps extends WrapperControlProps, ConditionProps {
    category: string;
    value: string;
    height?: number;
    width?: number;
    condition?: boolean | Function;
    checked?: boolean;
    onClick?: (e: any) => void;
}

export interface ImgProps extends ConditionProps, HTMLAttributes<HTMLImageElement> {
    src?: string
    alt?: string
}

export const Img = withCondition(({ src, alt, ...props }: ImgProps) => {
    return <img alt={alt} src={process.env.PUBLIC_URL + src} {...props} />
})


export const Image = withErrorControl(({ setValue, ...props }: ImageProps) => {

    const globalization = useContext(GlobalizationContext);
    const CSS_IMG = {
        "height": '80px',
        "width": '80px',
        "top": '50%',
        "left": '50%',
        "margin-top": '-2.5px',
        "margin-left": '-2.5px'
    }

    let alt_message = globalization === null || globalization[props.value] === undefined ? props.value : globalization[props.value];
    if (props.checked) {
        return <div className="gwp-align-center" >
            <li className="secImgs checked">
                <a onClick={props.onClick}>
                    <input type='hidden' name={`${props.name}`} />
                    <img style={CSS_IMG} src={process.env.PUBLIC_URL + "/static/themes/blue/images/security_image/" + props.category + "/" + props.name + ".jpg"} alt={alt_message} />
                </a>
            </li>
        </div>

    } else {
        return <div className="col-md-4 col-xs-4">
            <li className="secImgs unchecked">
                <a onClick={props.onClick}>
                    <input type='hidden' name={`${props.name}`} />
                    <img style={CSS_IMG} src={process.env.PUBLIC_URL + "/static/themes/blue/images/security_image/" + props.category + "/" + props.name + ".jpg"} alt={alt_message} />
                </a>
            </li>
        </div>
    }
});

export const ImageOption = withErrorControl(({ setValue, ...props }: ImageOptionProps) => {

    const globalization = useContext(GlobalizationContext);
    const CSS_IMG = {
        "height": '80px',
        "width": '80px',
        "top": '50%',
        "left": '50%',
        "margin-top": '0px',
        "margin-left": '0px'
    }


    let alt_message = globalization === null || globalization[props.imgOption.name] === undefined ? props.imgOption.name : globalization[props.imgOption.name];
    if (props.check === props.imgOption.name) {
        return <>
            <li className="secImgs checked">
                <a onClick={props.onClick}>
                    <img style={CSS_IMG} src={process.env.PUBLIC_URL + "/static/themes/blue/images/security_image/" + props.imgOption.category.name + "/" + props.imgOption.name + ".jpg"} alt={alt_message} />
                </a>
            </li>
        </>

    } else {
        return <>
            <li className="secImgs unchecked">
                <a onClick={props.onClick}>
                    <img style={CSS_IMG} src={process.env.PUBLIC_URL + "/static/themes/blue/images/security_image/" + props.imgOption.category.name + "/" + props.imgOption.name + ".jpg"} alt={alt_message} />
                </a>
            </li>
        </>
    }
});



