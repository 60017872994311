import React, { ReactNode } from "react"

export interface ConditionProps {
    condition?: boolean | Function;
    children?: ReactNode
}

export function withCondition<T extends ConditionProps>(ConditionControl: (props: T) => JSX.Element): (props: T) => JSX.Element {
    return function (props: T): JSX.Element {
        if (isConditionSatisified(props.condition) === false) {
            return <></>;
        }
        return <ConditionControl {...props} />
    }
}

export function isConditionSatisified(condition: boolean | Function | undefined) {
    if (condition === undefined) {
        return true;
    }
    if (condition !== undefined) {
        return ((typeof condition === 'boolean') ? condition : condition()) === true;
    }
    return false;
}

export const Condition = function (props: ConditionProps) {
    if (isConditionSatisified(props.condition)) {
        return <React.Fragment>{props.children}</React.Fragment>
    }
    return <React.Fragment></React.Fragment>
}