import React, { useEffect, useState } from 'react';
import { ContentViewer } from '../shared/components';
import { getContextPath } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/welcome.css';

let welcomeHtml = `
<center dir="ltr">
    <div class="carousel slide control-one" data-ride="carousel" dir="ltr" id="carousel-kv">
        <div class="carousel-inner" role="listbox">
            <div class="item active left">
                <img alt="..." src="${getContextPath()}/static/themes/blue/images/homepage/banner_01.jpg">
                <div class="carousel-caption">
                    <h1 class="animated fadeInDown delay-1">Individual and Member</h1>
                    <p class="animated fadeInDown delay-3">Get access to your plan info and resources to manage your policy and track your claim status.</p>
                </div>
            </div>
            <div class="item next left">
                <img alt="..." src="${getContextPath()}/static/themes/blue/images/homepage/banner_02.jpg">
                <div class="carousel-caption">
                    <h1 class="animated fadeInDown delay-1">Agent</h1>
                    <p class="animated fadeInDown delay-3">Get access to valuable information including plan information, wellness tools and more!</p>
                </div>
            </div>
            <div class="item">
                <img alt="..." src="${getContextPath()}/static/themes/blue/images/homepage/banner_03.jpg">
                <div class="carousel-caption">
                    <h1 class="animated fadeInDown delay-1">Group Plan Administrator</h1>
                    <p class="animated fadeInDown delay-3">Get access to benefit plans and manage coverages and billing &amp; payment for your members.</p>
                </div>
            </div>
            <div class="item">
                <img alt="..." src="${getContextPath()}/static/themes/blue/images/homepage/banner_04.jpg">
                <div class="carousel-caption">
                    <h1 class="animated fadeInDown delay-1">Provider</h1>
                    <p class="animated fadeInDown delay-3">Provide health care services to your patient and manage your medical providers account.</p>
                </div>
            </div>
    </div>
    <!-- Controls -->
    <a class="left carousel-control" data-slide="prev" href="#carousel-kv" role="button" target="" title=""><span class="sr-only">Previous</span> </a> <a class="right carousel-control" data-slide="next" href="#carousel-kv" role="button" target="" title=""> <span class="sr-only">Next</span> </a></div>
</center>
<div class="inquiry-banner" dir="ltr">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-xs-12 col-sm-12">
                <h1>Request a Free, No Obligation Quote</h1>
                </div>

                <div class="col-md-6 col-xs-12 col-sm-12">
                <form>
                <div class="col-xs-6">
                    <div id="select-wrap">
                        <input id="select-product" readonly="readonly" type="text" title="Select a product" value="Select a product"/>
                        <div id="inquiry-sel">
                            <div class="s-triangle">&nbsp;</div>
                            <ul>
                                <li><a href="javascript:;" link="/wps/portal/Home/TermLife">Term Life Insurance</a></li>
                                <li><a href="javascript:;" link="/wps/portal/Home/WholeLife">Whole Life Insurance</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6">
                    <button class="btn btn-inquiry" data-hover="Get Quote" id="btn-inquiry" type="button"><span>Get Quote</span></button>
                </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row d-flex" >
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div class="insurance" dir="ltr">
                <div class="insurance-wrapper-outer">
                    <div class="insurance-wrapper">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-6">
                                <div class="insurance-item ins-01">
                                    <div class="item insurance-01">
                                        <div class="copy">
                                            <h3>Vision Insurance</h3>
                                            <p>The financial safety net for those who depend on you</p>
                                        </div>
                                        <a href="http://gwp36divtsql3.cnx.com:10039/wps/portal/Home/welcome/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zijQM9HD0svA38_P0tzAwc3ZwdTZyCjI38LU31wwkpiAJKG-AAjgZA_VFYlDgaOAUZORkbGLj7G2FVgGJGQW6EQaajoiIAgoZ-pQ!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/#" class="link"><span>Learn More</span> </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6">
                                <div class="insurance-item ins-02">
                                    <div class="item insurance-02">
                                        <div class="copy">
                                            <h3>Dental Insurance</h3>
                                            <p>Bundle now and you could save 5% extra on just auto</p>
                                        </div>
                                        <a href="http://gwp36divtsql3.cnx.com:10039/wps/portal/Home/welcome/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zijQM9HD0svA38_P0tzAwc3ZwdTZyCjI38LU31wwkpiAJKG-AAjgZA_VFYlDgaOAUZORkbGLj7G2FVgGJGQW6EQaajoiIAgoZ-pQ!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/#" class="link"><span>Learn More</span> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row secondrow">
                            <div class="col-xs-12 col-sm-6 col-md-6">
                                <div class="insurance-item ins-03">
                                    <div class="item insurance-03">
                                        <div class="copy">
                                            <h3>Medical Insurance</h3>
                                            <p>Anything you’d ever need from us is right at your fingertips</p>
                                        </div>
                                        <a href="http://gwp36divtsql3.cnx.com:10039/wps/portal/Home/welcome/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zijQM9HD0svA38_P0tzAwc3ZwdTZyCjI38LU31wwkpiAJKG-AAjgZA_VFYlDgaOAUZORkbGLj7G2FVgGJGQW6EQaajoiIAgoZ-pQ!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/#" class="link"><span>Learn More</span> </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6">
                                <div class="insurance-item ins-04">
                                    <div class="item insurance-04">
                                        <div class="copy">
                                            <h3>Disability Insurance</h3>
                                            <p>Protection for you and all the things you care about</p>
                                        </div>
                                        <a href="http://gwp36divtsql3.cnx.com:10039/wps/portal/Home/welcome/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zijQM9HD0svA38_P0tzAwc3ZwdTZyCjI38LU31wwkpiAJKG-AAjgZA_VFYlDgaOAUZORkbGLj7G2FVgGJGQW6EQaajoiIAgoZ-pQ!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/#" class="link"><span>Learn More</span> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</div> 
`

export const WelcomeComponent = withView((props: ViewComponentProps) => {

    const [html, setHtml] = useState<string>("");
    useEffect(() => {
        if (html === "") {
            setHtml(welcomeHtml);
        }
    }, [html]);

    return <>
        <ContentViewer html={html}></ContentViewer>
    </>
});