import React, { useEffect, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
//import './css/cognosreport.css';
import { ajax, isEmptyObject, parseBoolean } from '../shared/utils';
import { Form, Formik } from 'formik';
import GlobalizedText from '../shared/globalization';

let w: any = window;
export const win = w;
export const $: any = w.$;



export const AwsDashboardComponent = withView((props: ViewComponentProps) => {

    const [config, setConfig] = useState<any>();
    const dashboardId: string = props.getParam("dashboardId");
    const name: string = props.getParam("name");

    const [url, setUrl] = useState<string>("https://us-east-1.quicksight.aws.amazon.com/embed/44601140ab324aaaaec502b84c134b24/dashboards/aab9e658-2559-48e5-b52c-8d954a29c4bb?code=AYABeDbVCbDhH2Z0wOChYv6ekI0AAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4ZGLViwIvMWeq0eg2SdOyWJS86qkEBfgLEiY79AhlHtMBb3lg9uKwfa_YJe4PV6sX6AAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDFTfv0AxYYxVQ1IDsAIBEIA7M0C0FRSK5h33q_NaKPWywwpVB2M6xNWbDRnrzRWKYSisirR1UI7t0VyKkrd0CsejtV0THznqK-xoWOUCAAAAAAwAABAAAAAAAAAAAAAAAAAAtzHKo5Iy7shlYcm9_LG4L_____8AAAABAAAAAAAAAAAAAAABAAAA5cVDsbyaq4FMukabqjSI88vr5u5LBD0R-xmn_UDoKDzEM6meQb-wkRSuAC9YMyrf74fJYvMMJAuA2Boqf0ItFNcMRL3oSwiA3ebL2QwQFFpSXWzyzYtr6jOnJBi5Nf_kVCwnmyAQn7bPBFStolGYYJD-kqU9AxVuoRm24BETJsP-TS5A6L6G7Kw_XrUBfaqgOtEAPfZXOrHTrtPxJS5WDoBF-a963p-2XA8tXL9pB27E7ElY8l1HI8BEcU6Hm9MzhDlrrnBeMg21jBxGgW5Aan5g-oIzDQqnd2CsSSD1TIsYqINB1wAelot34G5DULOTRMyXjN0O&identityprovider=quicksight&isauthcode=true");
    //const [isDir, setIsDir] = useState<boolean>(false);
    //aws Poc
    //const isAwsPoc: boolean = parseBoolean(props.getParam("isAwsPoc"));
    //let awsReportUrl: string = "https://us-east-1.quicksight.aws.amazon.com/embed/44601140ab324aaaaec502b84c134b24/dashboards/aab9e658-2559-48e5-b52c-8d954a29c4bb?code=AYABeDbVCbDhH2Z0wOChYv6ekI0AAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4ZGLViwIvMWeq0eg2SdOyWJS86qkEBfgLEiY79AhlHtMBb3lg9uKwfa_YJe4PV6sX6AAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDFTfv0AxYYxVQ1IDsAIBEIA7M0C0FRSK5h33q_NaKPWywwpVB2M6xNWbDRnrzRWKYSisirR1UI7t0VyKkrd0CsejtV0THznqK-xoWOUCAAAAAAwAABAAAAAAAAAAAAAAAAAAtzHKo5Iy7shlYcm9_LG4L_____8AAAABAAAAAAAAAAAAAAABAAAA5cVDsbyaq4FMukabqjSI88vr5u5LBD0R-xmn_UDoKDzEM6meQb-wkRSuAC9YMyrf74fJYvMMJAuA2Boqf0ItFNcMRL3oSwiA3ebL2QwQFFpSXWzyzYtr6jOnJBi5Nf_kVCwnmyAQn7bPBFStolGYYJD-kqU9AxVuoRm24BETJsP-TS5A6L6G7Kw_XrUBfaqgOtEAPfZXOrHTrtPxJS5WDoBF-a963p-2XA8tXL9pB27E7ElY8l1HI8BEcU6Hm9MzhDlrrnBeMg21jBxGgW5Aan5g-oIzDQqnd2CsSSD1TIsYqINB1wAelot34G5DULOTRMyXjN0O&identityprovider=quicksight&isauthcode=true";
    /*const AWS = require('aws-sdk');
    const https = require('https');

    var quicksight = new AWS.Service({
        apiConfig: require('./quicksight-2018-04-01.min.json'),
        region: 'us-east-1',
    });*/
    //let urltest: string = "https://us-east-1.quicksight.aws.amazon.com/embed/a162c059255f4081ab6a6120653d3736/dashboards/37cdc099-c3c4-4f84-bc3d-c300678fd174?code=AYABeG-yHYKZ4n_24wnfHcMbPpAAAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4ZGLViwIvMWeq0eg2SdOyWJS86qkEBfgLEiY79AhlHtMBGoulAv21YnIx4z3a0WOaOAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDA0UMVNNt2OsM1RYoAIBEIA7T6PFC65G3AZ8Hu9Y2DjJI9SFdeAUZCWp8d1A1HNWLrduiNN8g0lQiWnothiTQPUg5l55RQcv4tKRHG4CAAAAAAwAABAAAAAAAAAAAAAAAAAATCK6pirU_Ti4NSMe33uEL_____8AAAABAAAAAAAAAAAAAAABAAAA5dgXIWzkL6acltPwxFi5a-fKCs_bOSUY3pizQY3etXRNMjK3IIeVAwpon3oDTVV53ar3mZdEDFlTc9eXoGg9TzTRCJVuxOcmIFzdLNUbTTYJ1r4zFSfJiSkduW08e9f52X8AUOTb_tbeCeGTHIX7Tdzs4d6uGKRRKL8z4nP8FG2AnPpKs49Pd6lyQTP63qmfm6CsB4qpBkUxU5SfHe6b1JddDqcDdfzrT6Zt1ARGo_Yke4__aT1IP7vB3V2p7gaxm_2AeeCM5oPyUk8vyqrinn7YQ3q1l5HQx1r5vVAVyKhMxFXkqEB788U2LqVTbM27gKnx7Wbu&identityprovider=quicksight&isauthcode=true";

    useEffect(() => {
        if (config === undefined) {
            console.log("1");
            ajax({
                url: '/api/quickSight/getDashboardEmbedUrl',
                params: {
                    "dashboardId": dashboardId
                },
                success: (res: any) => {
                    setUrl(res);
                    setConfig(res);
                }
            });
        }
    }, [config]);
    if (config === undefined) {
        return <></>;
    }
    function iframeLoad(obj: any) {
        if ($('#awsdbd').length == 0) {
            var iframe = $('<iframe></iframe>')
            iframe.attr("id", "awsdbd");
            iframe.attr("src", url.split("?")[0] + "#p.ParaAgentNumber=A13000000");
            alert(url.split("?")[0] + "#p.ParaAgentNumber=A13000000");
            iframe.attr("frameBorder", 0);
            iframe.attr("height", window.screen.availHeight);
            iframe.attr("width", "100%");
            iframe.attr("scrolling", "no");
            $('#AwsDashboardDiv').append(iframe);
        }
    }
    return <>
        <Formik initialValues={{
            url: ''
        }}
            {...props.generateNoramlFormikProps()}
            onSubmit={(values: any) => {
                // console.log(values);
                setUrl(values.url);
            }}
        >
            {formProps =>
                <Form>
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <props.Label className="noBorder noBackground fontBlue"><GlobalizedText message={name + " Without Params"} /></props.Label>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <div className="report" id="AwsRepDiv">
                                <iframe className="gwp-hiddenX" id="awsReportFrame" width="100%" scrolling="no" height={window.screen.availHeight} src={url} ></iframe>
                            </div>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <props.Label className="noBorder noBackground fontBlue"><GlobalizedText message={name + " With Params"} /></props.Label>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <div className="report" id="AwsDashboardDiv">
                                <iframe className="gwp-hidden" id="awsDashboardFrame" width="100%" scrolling="no" height={window.screen.availHeight} src={url} onLoad={(e: any) => iframeLoad(e)}></iframe>
                            </div>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
    </>
});
