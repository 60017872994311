import { withView, ViewComponentProps } from '../shared/viewcomponent';
import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import './css/billingpaymentsummary.css';
import GlobalizedText from '../shared/globalization';
import { ajax, isEmptyStr, validate, parseDate, formatNumber } from '../shared/utils';
import { INVALID_MESSAGES } from '../shared/yupschema';
import { TableCell } from '../shared/components/lib/table';

export const BillingPaymentSummaryComponent = withView((props: ViewComponentProps) => {

    const companyCodePre = props.getParam("companyCode");
    const groupNumberPre = props.getParam("groupNumber");
    const accountNumberPre = props.getParam("accountNumber");
    const participantIDNumber = props.getParam("participantIDNumber");
    const agentIndicator = props.getParam("agentIndicator")
    const agentNumber = props.getParam("agentNumber")
    const isFromIB: boolean = props.getParam("from") === "from_inforcebusiness" ? true : false
    const [config, setConfig] = useState<any | null>(null);
    const [userDateFormat, setUserDateFormat] = useState<any>("");
    const [balanceDue, setBalanceDue] = useState<any>("");
    const [cashOnHand, setCashOnHand] = useState<any>("");
    const [summaryGrid, setSummaryGrid] = useState<any | null>(null);
    const [agentList, setAgentList] = useState<Array<any>>([]);

    useEffect(() => {
        if (config === null) {
            if (isFromIB === true) {
                ajax({
                    url: '/api/billingPaymentSummary/view',
                    params: {
                        companyCode: companyCodePre,
                        groupNumber: groupNumberPre,
                        accountNumber: accountNumberPre,
                        participantIDNumber: participantIDNumber,
                        fromInforceBusiness: isFromIB,
                        // agentIndicator: agentIndicator,
                        agentNumber: agentNumber
                    },
                    method: 'get',
                    success: (res: any) => {
                        setConfig(res)
                        setUserDateFormat(res.dateFormat)
                        loadAllAgent();
                        if (!isEmptyStr(res.fromDate) && !isEmptyStr(res.throughDate)) {
                            loadSummaryGrid(res.fromDate, res.throughDate);
                        }
                    }
                });
            } else {
                ajax({
                    url: '/api/billingPaymentSummary/view',
                    params: (participantIDNumber === "") ? {
                        companyCode: companyCodePre,
                        groupNumber: groupNumberPre,
                        accountNumber: accountNumberPre,
                    } : {
                        companyCode: companyCodePre,
                        groupNumber: groupNumberPre,
                        accountNumber: accountNumberPre,
                        participantIDNumber: participantIDNumber
                    },
                    method: 'get',
                    success: (res: any) => {
                        setConfig(res)
                        setUserDateFormat(res.dateFormat)
                        loadAllAgent();
                        if (!isEmptyStr(res.fromDate) && !isEmptyStr(res.throughDate)) {
                            loadSummaryGrid(res.fromDate, res.throughDate);
                        }
                    }
                });
            }
        }
    }, [config]);

    function loadSummaryGrid(fromDate: any, throughDate: any) {
        ajax({
            url: '/api/billingPaymentSummary/retrieveGrid',
            params: {
                companyCode: companyCodePre,
                groupNumber: groupNumberPre,
                accountNumber: accountNumberPre,
                participantIDNumber: participantIDNumber,
                fromDate: fromDate,
                throughDate: throughDate,
                agentNumber: agentNumber
            },
            method: 'get',
            success: (res: any) => {
                if (res.details.length === 0) {
                    setSummaryGrid(undefined)
                } else {
                    setSummaryGrid(res.details)
                    if (res.balanceDue !== undefined) {
                        setBalanceDue(res.balanceDue)
                    }
                    if (res.cacheOnHand !== undefined) {
                        setCashOnHand(res.cacheOnHand)
                    }
                }
            }
        })
    }
    function loadAllAgent() {
        ajax({
            url: '/api/billingPaymentSummary/getAllAgents',
            params: {
                companyCode: companyCodePre,
                groupNumber: groupNumberPre,
                accountNumber: accountNumberPre,
                participantIDNumber: participantIDNumber
            },
            method: 'get',
            success: (res: any) => {
                setAgentList(res)
            }
        })
    }
    const pdfViewer = props.usePDFViewer({ ...props });

    function showInvoicePDF(invoiceNumber: any, originalBillDate: any) {
        let pdfUrl = '/api/billingPaymentSummary/showPdf?invoiceNumber=' + invoiceNumber + '&originalBillDate=' + originalBillDate;
        pdfViewer.showPDF(pdfUrl)
    }

    function dataSchema(formValue: any) {
        let errorFlag: boolean = false;
        let validateResult: any = {};
        let dateFrom: any = parseDate(formValue.fromDate, userDateFormat);
        let dateThrough: any = parseDate(formValue.throughDate, userDateFormat);
        if (isEmptyStr(formValue.fromDate)) {
            validateResult.fromDate = INVALID_MESSAGES.BILLPAY_FROM_DATE_EMPTY
            errorFlag = true
        }
        if (isEmptyStr(formValue.throughDate)) {
            validateResult.throughDate = INVALID_MESSAGES.BILLPAY_THROUGH_DATE_EMPTY
            errorFlag = true
        }
        if (dateFrom > dateThrough) {
            validateResult.fromDate = INVALID_MESSAGES.BILLPAY_DATE_WRONG
            validateResult.throughDate = INVALID_MESSAGES.BILLPAY_DATE_WRONG
            errorFlag = true
        }
        if (errorFlag === true) {
            return validateResult
        }
    }

    const formSubmit = (values: any): void => {
        props.clearMessage();
        loadSummaryGrid(values.fromDate, values.throughDate)
    }

    if (config === null) {
        return <></>
    }

    return <props.Div id="billingPaymentSummary">
        <props.Panel>
            <props.PanelBody >
                <props.Row>
                    <props.Col md="4" sm="4" xs="12">
                        <props.DL>
                            <props.DLI title="common.lbl.group" desc={config.groupName.replace(/\b(0+)/gi, "")} />
                            <props.DLI title="common.lbl.account" desc={config.accountName} />
                            <props.DLI title="common.lbl.member" condition={!isEmptyStr(participantIDNumber)} desc={config.memberName}></props.DLI>
                        </props.DL>
                    </props.Col>
                    <props.Col md="4" sm="4" xs="12">
                        <props.DL>
                            <props.DLI title="common.lbl.effectiveDate" desc={config.effectiveDate} />
                            <props.DLI title="common.lbl.terminationDate" desc={config.terminationDate} />
                        </props.DL>
                    </props.Col>
                    <props.Col md="4" sm="4" xs="12">
                        <props.DL>
                            <props.DLI condition={config.buPaidToDate !== undefined} title="common.lbl.billingunit.paidtodate" desc={config.buPaidToDate} />
                            <props.DLI condition={config.coveragePaidToDate !== undefined && config.isPaidtoDateEnabled} title="common.lbl.coverage.paidtodate" desc={config.coveragePaidToDate} />
                        </props.DL>
                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel>
        <props.Div className="gwp-float-right" style={{ marginRight: -15 + "px" }}>
            <props.Span className="gwp-icon gwp-icon-print gwp-icon-sm" style={{ marginRight: -5 + "px" }}></props.Span>
            <props.Button className="printButton" printVisible={false} onClick={() => props.print({ orientation: 'landscape' })}><GlobalizedText message="common.print" /></props.Button>
        </props.Div>
        <props.Div>
            <ul id="bpstab" className="nav nav-tabs">
                <li role="presentation" className="active"><a href="#summary" aria-controls="summary" role="tab" data-toggle="tab"><GlobalizedText message="billpay.summary" /></a></li>
                <li role="presentation" className=""><a href="#relateinfo" aria-controls="relateinfo" role="tab" data-toggle="tab"><GlobalizedText message="billpay.relateinfo" /></a></li>
            </ul>
            <props.Div className="tab-content">
                <props.Div role="tabpanel" className="tab-pane fade in active" id="summary">
                    <props.Row>
                        <Formik
                            initialValues={summaryGrid !== undefined && summaryGrid !== null && summaryGrid.length > 0 ? {
                                fromDate: config.fromDate,
                                throughDate: summaryGrid[0].modalDateThrough
                            } : {
                                fromDate: config.fromDate,
                                throughDate: ''
                            }}
                            enableReinitialize={true}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validate={values => {
                                return validate(dataSchema, values, props)
                            }}
                            onSubmit={formSubmit}
                        >
                            {formProps => {
                                return <Form id="billingPaySummary">
                                    <props.Col md="2" sm="4" xs="12">
                                        <props.Div className="form-group form-inline" style={{ minWidth: 160 + "px", maxWidth: 260 + "px" }}>
                                            <props.DateTimePickerControl name="fromDate" label="common.fromdate" dateformat={config.dateFormat} />
                                        </props.Div>
                                    </props.Col>
                                    <props.Col md="2" sm="4" xs="12">
                                        <props.Div className="form-group form-inline" style={{ minWidth: 170 + "px", maxWidth: 260 + "px" }}>
                                            <props.DateTimePickerControl name="throughDate" label="payment.lbl.endDate" dateformat={config.dateFormat} />
                                        </props.Div>
                                    </props.Col>
                                    <props.Col md="1" sm="4" xs="12">
                                        <props.Div className="retrieveBtnDIV" >
                                            <props.Button id="test1" data-print-visible="false" type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                                        </props.Div>
                                    </props.Col>
                                </Form>
                            }
                            }
                        </Formik>
                        <props.Div className="balanceDIV">
                            <props.Col md="2" sm="4" xs="12">
                                <props.DL className="gwp-bp-float-right">
                                    <props.DLI title="billpay.baldue" desc={() => {
                                        return <props.Div className="summarydd">
                                            {isEmptyStr(balanceDue) ? '' : formatNumber(balanceDue)}
                                        </props.Div>
                                    }
                                    }></props.DLI>
                                </props.DL>
                            </props.Col>
                            <props.Col md="2" sm="4" xs="12" >
                                <props.DL className="gwp-bp-float-right">
                                    <props.DLI title="billpay.cashonhand" desc={() => {
                                        return <props.Div className="summarydd">
                                            {isEmptyStr(cashOnHand) ? '' : formatNumber(cashOnHand)}
                                        </props.Div>
                                    }
                                    }></props.DLI>
                                </props.DL>
                            </props.Col>
                            <props.Col md="3" sm="4" xs="12" >
                                <props.DL className="gwp-bp-float-right">
                                    <props.DLI title="billpay.outstandbal" desc={() => {
                                        return <props.Div className="summarydd">
                                            {isEmptyStr(balanceDue) && isEmptyStr(cashOnHand) ? '' : formatNumber(balanceDue - cashOnHand)}
                                        </props.Div>
                                    }
                                    }></props.DLI>
                                </props.DL>
                            </props.Col>
                        </props.Div>
                    </props.Row>
                    <br />
                    <props.Row condition={agentIndicator === 'P' || config.agentIndicator === 'P'}>
                        <props.Col sm="12" md="12" xs="12">
                            <props.Div className="information gwp-information">
                                <props.Span className="glyphicon glyphicon-info-sign"></props.Span>{' '}
                                <GlobalizedText message="common.msg.bps.mem.pro.info.not.dsp" />
                            </props.Div>
                        </props.Col>
                    </props.Row>
                    {config.currency !== undefined && <props.Div className="summaryGridDiv2">
                        <props.Row>
                            <props.Col condition={config.currency !== undefined} md="12" sm="12" xs="12">
                                <GlobalizedText message="common.lbl.allcurrencyvalue" /> {config.currency.currencyName + " " + "(" + config.currency.countryCode + ")"}
                            </props.Col>
                        </props.Row>
                    </props.Div>
                    }
                    <props.Row>
                        <props.Col md="12" sm="12" xs="12">
                            <props.Table id="summaryGridDiv" next={props.next} delayAdjust={true} dateformat={props.getDateFormat()} table={
                                {
                                    columns: [
                                        {
                                            title: "billpay.modaldate.from",
                                            name: "modalDateFrom",
                                            type: 'date',
                                            render: (data: any, type: any, row: any, meta: any) => {
                                                return `<a>${row.modalDateFrom}</a>`
                                            },
                                            onClick: (e: any, row: any): void => {
                                                if (isFromIB) {
                                                    if (!isEmptyStr(config.participantIDNumber)) {
                                                        if (!isEmptyStr(row.invoiceNumber)) {
                                                            props.next(`/billingSummary/view?companyCode=${companyCodePre}&groupNumber=${groupNumberPre}&accountNumber=${accountNumberPre}&participantIDNumber=${participantIDNumber}&modalDateFrom=${row.modalDateFrom}&modalDateThrough=${row.modalDateThrough}&invoiceNumber=${row.invoiceNumber}&agentNumber=${agentNumber}`)
                                                        } else {
                                                            props.next(`/billingSummary/view?companyCode=${companyCodePre}&groupNumber=${groupNumberPre}&accountNumber=${accountNumberPre}&participantIDNumber=${participantIDNumber}&modalDateFrom=${row.modalDateFrom}&modalDateThrough=${row.modalDateThrough}&agentNumber=${agentNumber}`)
                                                        }
                                                    } else {
                                                        if (!isEmptyStr(row.invoiceNumber)) {
                                                            props.next(`/billingSummary/view?companyCode=${companyCodePre}&groupNumber=${groupNumberPre}&accountNumber=${accountNumberPre}&modalDateFrom=${row.modalDateFrom}&modalDateThrough=${row.modalDateThrough}&invoiceNumber=${row.invoiceNumber}&agentNumber=${agentNumber}`)
                                                        } else {
                                                            props.next(`/billingSummary/view?companyCode=${companyCodePre}&groupNumber=${groupNumberPre}&accountNumber=${accountNumberPre}&modalDateFrom=${row.modalDateFrom}&modalDateThrough=${row.modalDateThrough}&agentNumber=${agentNumber}`)
                                                        }
                                                    }
                                                } else {
                                                    if (!isEmptyStr(config.participantIDNumber)) {
                                                        if (!isEmptyStr(row.invoiceNumber)) {
                                                            props.next(`/billingSummary/view?companyCode=${companyCodePre}&groupNumber=${groupNumberPre}&accountNumber=${accountNumberPre}&participantIDNumber=${participantIDNumber}&modalDateFrom=${row.modalDateFrom}&modalDateThrough=${row.modalDateThrough}&invoiceNumber=${row.invoiceNumber}`)
                                                        } else {
                                                            props.next(`/billingSummary/view?companyCode=${companyCodePre}&groupNumber=${groupNumberPre}&accountNumber=${accountNumberPre}&participantIDNumber=${participantIDNumber}&modalDateFrom=${row.modalDateFrom}&modalDateThrough=${row.modalDateThrough}`)
                                                        }
                                                    } else {
                                                        if (!isEmptyStr(row.invoiceNumber)) {
                                                            props.next(`/billingSummary/view?companyCode=${companyCodePre}&groupNumber=${groupNumberPre}&accountNumber=${accountNumberPre}&modalDateFrom=${row.modalDateFrom}&modalDateThrough=${row.modalDateThrough}&invoiceNumber=${row.invoiceNumber}`)
                                                        } else {
                                                            props.next(`/billingSummary/view?companyCode=${companyCodePre}&groupNumber=${groupNumberPre}&accountNumber=${accountNumberPre}&modalDateFrom=${row.modalDateFrom}&modalDateThrough=${row.modalDateThrough}`)
                                                        }
                                                    }
                                                }

                                            }
                                        },
                                        { title: "billpay.modaldate.through", name: "modalDateThrough", type: 'date' },
                                        {
                                            title: "common.lbl.premiumBilled", name: "premiumBilled", render: (data: any, type: any, row: any, meta: any) => {
                                                if (config.agentIndicator === "P") {
                                                    return ""
                                                } else {
                                                    return formatNumber(row.premiumBilled)
                                                }
                                            }, type: 'numeric'
                                        },
                                        {
                                            title: "common.lbl.adjbilled", name: "adjustmentsBilled", render: (data: any, type: any, row: any, meta: any) => {
                                                if (config.agentIndicator === "P") {
                                                    return ''
                                                } else {
                                                    return formatNumber(row.adjustmentsBilled)
                                                }
                                            }, type: 'numeric'
                                        },
                                        {
                                            title: "common.lbl.totalDue", name: "totalDue", render: (data: any, type: any, row: any, meta: any) => {
                                                if (config.agentIndicator === "P") {
                                                    return ''
                                                } else {
                                                    return formatNumber(row.totalDue)
                                                }
                                            }, type: 'numeric'
                                        },
                                        {
                                            title: "billpay.payment", name: "payment", render: (data: any, type: any, row: any, meta: any) => {
                                                if (config.agentIndicator === "P") {
                                                    return ''
                                                } else {
                                                    return formatNumber(row.payment)
                                                }
                                            }, type: 'numeric'
                                        },
                                        {
                                            title: "billpay.toladjust", name: "toleranceAdjustments", render: (data: any, type: any, row: any, meta: any) => {
                                                if (config.agentIndicator === "P") {
                                                    return ''
                                                } else {
                                                    return formatNumber(row.toleranceAdjustments)
                                                }
                                            }, type: 'numeric'
                                        },
                                        {
                                            title: "common.lbl.paymentApplied", name: "paymentApplied", render: (data: any, type: any, row: any, meta: any) => {
                                                if (config.agentIndicator === "P") {
                                                    return ''
                                                } else {
                                                    return formatNumber(row.paymentApplied)
                                                }
                                            }, type: 'numeric'
                                        },
                                        {
                                            title: "billpay.invoiceadj", name: "invoiceAdjustments", render: (data: any, type: any, row: any, meta: any) => {
                                                if (config.agentIndicator === "P") {
                                                    return ''
                                                } else {
                                                    return formatNumber(row.invoiceAdjustments)
                                                }
                                            }, type: 'numeric'
                                        },
                                        {
                                            title: "billpay.baldue", name: "balanceDue", render: (data: any, type: any, row: any, meta: any) => {
                                                if (config.agentIndicator === "P") {
                                                    return ''
                                                } else {
                                                    return formatNumber(row.balanceDue)
                                                }
                                            }, type: 'numeric'
                                        },
                                        {
                                            title: "billpay.invoicenum", name: "invoiceNumber", type: 'text', onClick: (e: any, row: any) => { showInvoicePDF(row.invoiceNumber, row.originalBillDateString) }, render: (data: any, type: any, row: any, meta: any) => {
                                                if (config.showInvoiceNumber !== false) {
                                                    return `<a id="invoiceNumber">${row.invoiceNumber}</a>`
                                                } else {
                                                    return ''
                                                }
                                            },

                                        }
                                    ], data: summaryGrid
                                }
                            } onResponsiveDisplay={(rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element) => {
                                if (childNode === null) {
                                    return;
                                }
                                childNode?.querySelector("#invoiceNumber")?.addEventListener("click", () => {
                                    showInvoicePDF(rowData.invoiceNumber, rowData.originalBillDateString)
                                });
                            }}></props.Table>
                        </props.Col>
                    </props.Row>
                </props.Div>

                <props.Div role="tabpanel" className="tab-pane fade in inactive" id="relateinfo">
                    <input type="hidden" id="reshowFlag" name="reshowFlag" value="" />
                    <props.Row>
                        <props.Col md="4" sm="4" xs="12">
                            <props.DL className="relateinfo dd">
                                <props.DLI title="payment.lbl.accountType" desc={config.accountTypeDesc} />
                                <props.DLI title="billpay.lastpre" desc={config.lastPreApplied} />
                                <props.DLI title="billpay.beneoff" desc={config.benefitOffice} />
                                <props.DLI title="payment.lbl.billingAddress" desc={() => {
                                    return <>
                                        {config.address.split("<br/>").map((o: any, i: number) => { return <React.Fragment key={i}>{o}<br /></React.Fragment> })}
                                    </>
                                }} />
                            </props.DL>
                        </props.Col>
                        <props.Col md="4" sm="3" xs="12">
                            <props.DL className="relateinfo dd">
                                <props.DLI title="billpay.billtype" desc={config.billingType} />
                                <props.DLI condition={config.billingFrequency !== undefined} title="billpay.billfrequency" desc={config.billingFrequency} />
                                <props.DLI title="common.lbl.paidToDate" desc={config.paidToDate} />
                            </props.DL>
                        </props.Col>
                        <props.Col md="4" sm="5" xs="12">
                            <props.DL className="relateinfo dd">
                                <props.DLI title="billpay.ratestate" desc={config.rateState}></props.DLI>
                                <props.DLI title="common.lbl.agent" desc={() => {
                                    return <>
                                        {(config.showAgentMsg !== undefined && config.showAgentMsg === true) &&
                                            <GlobalizedText message="billpay.memprodlev" />
                                        }
                                        {(config.agentOne !== undefined && config.agentOne !== "") &&
                                            <props.Div className="agentList">
                                                <props.Span className="gwp-bold">{config.agentOne.agentName}</props.Span>
                                                <props.DL className="dl-horizontal gwp-align-left">
                                                    <props.DLI title="pd.lbl.phone" desc={() => {
                                                        return <>{config.agentOne.phoneNumber}</>
                                                    }}></props.DLI>
                                                    <props.DLI title="pd.lbl.email" desc={() => {
                                                        return <a href={"mailto:" + config.agentOne.emailAddress}>{config.agentOne.emailAddress}</a>
                                                    }}></props.DLI>
                                                </props.DL>
                                            </props.Div>
                                        }
                                        {(config.agentTwo !== undefined && config.agentTwo !== "") && <>
                                            <props.HR style={{ margin: "10px 0" }} />
                                            <props.Div className="agentList">
                                                <props.Span className="gwp-bold">{config.agentTwo.agentName}</props.Span>
                                                <props.DL className="dl-horizontal gwp-align-left">
                                                    <props.DLI title="pd.lbl.phone" desc={() => {
                                                        return <>{config.agentTwo.phoneNumber}</>
                                                    }}></props.DLI>
                                                    <props.DLI title="pd.lbl.email" desc={() => {
                                                        return <a href={"mailto:" + config.agentTwo.emailAddress}>{config.agentTwo.emailAddress}</a>
                                                    }}></props.DLI>
                                                </props.DL>
                                            </props.Div>
                                        </>
                                        }
                                        {config.showViewAllAgents === true &&
                                            <props.Div style={{ clear: 'both' }}>
                                                <props.Span className="gwp-icon gwp-icon-view-all gwp-icon-sm"></props.Span> <a href="#" data-toggle="modal" data-target="#allAgentsDialog"><GlobalizedText message="common.lbl.viewAllAgents" /></a>
                                            </props.Div>
                                        }
                                    </>
                                }}></props.DLI>
                            </props.DL >
                        </props.Col>
                    </props.Row>
                </props.Div>
            </props.Div >
        </props.Div >
        {/* ViewAllAgent Dialog */}
        < props.Modal id="allAgentsDialog" title="common.lbl.viewAllAgents" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                {agentList.map((agent: any, i: number) => {
                    return <React.Fragment key={i}> <props.Col xs="12" md="6" sm="6">
                        <props.Panel id="agentInfoPanel" className="agentPanelRemoveBoder">
                            <props.PanelBody>
                                <props.Span className="gwp-bold">{agent.agentName}</props.Span>
                                <props.DL className="dl-horizontal gwp-align-left">
                                    <props.DLI title="common.lbl.split" desc={() => {
                                        return <>{agent.split}</>
                                    }}></props.DLI>
                                    <props.DLI title="pd.lbl.phone" desc={() => {
                                        return <>{agent.phoneNumber}</>
                                    }}></props.DLI>
                                    <props.DLI title="pd.lbl.email" desc={() => {
                                        return <a href={"mailto:" + agent.emailAddress}>{agent.emailAddress}</a>
                                    }}></props.DLI>
                                </props.DL>
                            </props.PanelBody>
                        </props.Panel>
                    </props.Col>
                    </React.Fragment>
                })}
            </props.Row>
        </props.Modal >
        {/* PDF view comp */}
        < pdfViewer.Component ></pdfViewer.Component >
    </props.Div >
});