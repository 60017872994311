import React, { useEffect, useState } from "react";
import { RequiredHeader } from "./enrollment";
import Enrollment from '../types/enrollmenttypes';
import { INVALID_MESSAGES } from "../../shared/yupschema";
import GlobalizedText from "../../shared/globalization";
import { ajax, isEmptyStr } from "../../shared/utils";
import { FieldArray } from "formik";

interface LifeEventForm {
    companyCode: string,
    accountNumber: string,
    groupNumber: string,
    transactionNumber: string,
    participantIDNumber: string,
    effectiveDate: string,
    eventType: any
}

const defaultInitialValues: LifeEventForm = {
    companyCode: '',
    accountNumber: '',
    groupNumber: '',
    transactionNumber: '',
    participantIDNumber: '',
    effectiveDate: '',
    eventType: null
};
export const LifeEvent = (props: Enrollment.Props) => {
    const [memberInformation, setMemberInformation] = useState<any>(null);
    const [dateFormat, setDateFormat] = useState<any>(null);
    const [checkBoxMap, setCheckBoxMap] = useState<any | null>(null);//Check box values
    const [hideDate, setHideDate] = useState<string>("");//issure hire date

    const formValidate = (values: LifeEventForm): any => {
        let result: any = {};
        let msg = props.getGlobalizedText("common.lbl.effectiveDate") + " " + props.getGlobalizedText("common.lbl.laterThan") + " " + props.getGlobalizedText("addmember.issue.hire.date");
        let requiredMsg = props.getGlobalizedText("common.lbl.effectiveDate") + " " + props.getGlobalizedText("message_information_required");
        if (isEmptyStr(values.effectiveDate)) {
            result.effectiveDate = requiredMsg;
        } else {
            if (hideDate != null && new Date(values.effectiveDate).getTime() < new Date(hideDate).getTime()) {
                result.effectiveDate = msg+"("+hideDate+")";
            }
        }
        if (values.eventType == null || values.eventType.length == 0) {
            result.eventType = INVALID_MESSAGES.LIFEEVENT_SELECT
        }
        return result;
    }

    props.setValidation(formValidate);

    props.setPrepareData((values: any) => {
        return {
            "lifeEvent": {
                companyCode: memberInformation.companyCode,
                groupNumber: memberInformation.groupNumber,
                accountNumber: memberInformation.accountNumber,
                participantIDNumber: memberInformation.participantIDNumber,
                transactionNumber: values.transactionNumber,
                effectiveDate: values.effectiveDate,
                eventType: values.eventType
            }
        }
    });

    useEffect(() => {
        if (props.formValue === undefined || memberInformation === null) {
            let params = {
                transactionID: props.transactionID
            };
            ajax({
                url: '/api/enrollment/lifeEvent/view',
                params: params,
                success: (res: any) => {
                    setDateFormat(res.pageDateFormat);
                    setCheckBoxMap(res.checkBoxMap);
                    setMemberInformation(res.memberInformation);
                    setHideDate(res.memberInformation.hireDate);
                    if (res.lifeEvent != null) {
                        props.setFormValue(res.lifeEvent);
                    } else {
                        props.setFormValue(defaultInitialValues);
                    }
                }
            });
        }
    }, [props.formValue])

    if (props.formValue === undefined || props.formValue == null || memberInformation === null) {
        return <></>
    }

    return <>
        <props.Panel>
            <props.PanelHeading>
                <RequiredHeader label="lifeevent_title" {...props} />
            </props.PanelHeading>
            <props.PanelBody>
                <props.Row>
                    <props.Col xs="12" md="3" sm="3">
                        <props.DL>
                            <props.DLI title="common.lbl.group" desc={memberInformation.groupNumber} />
                        </props.DL>
                    </props.Col>
                    <props.Col xs="12" md="3" sm="3">
                        <props.DL>
                            <props.DLI title="common.lbl.account" desc={memberInformation.accountNumber + ""} />
                        </props.DL>
                    </props.Col>
                    <props.Col xs="12" md="3" sm="3">
                        <props.DL>
                            <props.DLI title="common.lbl.memberID" desc={memberInformation.participantIDNumber} />
                        </props.DL>
                    </props.Col>
                    <props.Col xs="12" md="3" sm="3">
                        <props.DL>
                            <props.DLI title="common.lbl.transNumber" desc={memberInformation.transactionNumber} />
                        </props.DL>
                    </props.Col>
                </props.Row>
                <props.Row>
                    <props.Col xs="12" md="12" sm="12">
                        <props.Label className="gwp-label-required"><GlobalizedText message="lb_selectreason_lifeevt" /></props.Label>
                    </props.Col>
                    <FieldArray name="eventTypes" render={arrayHelpers => {
                        return checkBoxMap.GIAS_CODE_08.map((record: any, index: number) => {
                            return <props.Col xs="12" md="3" sm="3">
                                <props.CheckboxControl className="checkboxStyle" label={record.name} id={`eventType_${record.name}`} name='eventType' value={record.value} />
                            </props.Col>
                        })
                    }
                    } />
                    <props.Col xs="12" md="12" sm="12">
                        <props.DateTimePickerControl id="effectiveDate" name="effectiveDate" label="common.lbl.effectiveDate" dateformat={dateFormat} required={true}/>
                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel>
    </>
}