import React, { useEffect, useState } from 'react';
import { ajax, getFromStorage } from '../shared/utils';
import { withView, ViewComponentProps } from '../shared/viewcomponent';

export const CONTENT_PREVIEW_STORAGE_KEY = "gwp.content.preview"
export const CONTENT_PREVIEW_ID = "preview"
export const CONTENT_VIEWER_TYPE_PREVIEW = "preview";

/**
 * Comopnent that shows the content as a page.
 */
export const ContentViewerComponent = withView(function (props: ViewComponentProps) {

    let contentId = props.getParam("id");
    let type = props.getParam("type");
    const [content, setContent] = useState<any>();
    useEffect(() => {
        if (contentId === CONTENT_PREVIEW_ID) {
            props.setTitle(props.getGlobalizedText("common.lbl.preview"));
            setContent(getFromStorage(CONTENT_PREVIEW_STORAGE_KEY, false));
            props.clearMessage();
        } else {
            ajax({
                url: `/api/cms/content/${type === 'preview' ? 'get' : 'getPublished'}/${contentId}`,
                success: (res) => {
                    setContent(res);
                    props.setTitle(res.name ? res.name : '');
                    props.clearMessage();
                }, error: (e) => {
                    props.showMessage("error", e.response.data.message);
                }
            });
        }
    }, [contentId]);

    if (content === undefined) {
        return <></>
    }

    return <props.ContentViewer html={content.content}></props.ContentViewer>
});
