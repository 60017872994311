import React, { useState, useEffect } from 'react';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import './css/appDetail.css';
//import './css/applicationDetail.css';
import { ajax, formatNumber, isEmptyObject, removeLeftZero } from '../shared/utils';
import { AgentsInfoPanel } from './agentsinfopanel';
import { TableCell, useTable } from '../shared/components';
import { Form, Formik } from 'formik';

export const ApplicationDetailComponent = withView((props: ViewComponentProps) => {
    const [config, setConfig] = useState<any>();
    const [product, setProduct] = useState<any>();
    const companyCode: string = props.getParam("companyCode");
    const groupNumber: string = props.getParam("groupNumber");
    const accountNumber: number = parseInt(props.getParam("accountNumber"));
    const participantIDNumber: string = props.getParam("participantIDNumber");
    const fromPage: string = props.getParam("fromPage");
    const thisPage: string = 'applicationDetail';

    //let applicationNumber = props.getParam("applicationNumber");
    function productAgentsDetail(productAgents: any) {
        setProduct(productAgents);
        props.showModal('#productAgentsDetail');
    }
    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/applicationDetails/view',
                params: {
                    companyCode: props.getQueryParam("companyCode"),
                    groupNumber: props.getQueryParam("groupNumber"),
                    accountNumber: props.getQueryParam("accountNumber"),
                    participantIDNumber: props.getQueryParam("participantIDNumber"),
                    applicantNumber: props.getQueryParam("applicantNumber"),
                    accountType: props.getQueryParam("accountType"),
                    agentIndicator: props.getQueryParam("agentIndicator"),
                    applicantName: props.getQueryParam("applicantName")
                },
                success: (data: any) => {
                    setConfig(data);
                }
            });
        }

    }, [config]);

    if (config === undefined) {
        return <></>
    }

    const tblProduct = useTable({
        id: 'tblProduct',
        option: {
            retrieve: true,
            bPaginate: false,
            bSort: false,
            bFilter: false,
            bLengthChange: false,
            language: { emptyTable: " " }
        },
        onResponsiveDisplay: (rowNum: number, rowData: any, cells: Array<TableCell>, childNode: Element) => {
            childNode?.querySelector("#showProductAgents")?.addEventListener("click", (e: any) => {
                productAgentsDetail(config.products[rowNum]);
            })
        },
        delayAdjust: true,
        childRows: 1,
        children: [
            <thead key={0}>
                <tr>
                    <th data-sortable={false} scope="col" className="gwp-details-control"></th>
                    <th scope="col"><GlobalizedText message="app.lbl.benefitClass" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.product" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.effectiveDate" /> </th>
                    <th scope="col"><GlobalizedText message="app.lbl.eligibleMemOrDep" /></th>
                    <th scope="col"><GlobalizedText message="app.lbl.coverageLevel" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.plan" /></th>
                    <th className="gwp-dt-child" data-width="0%"></th>
                </tr>
            </thead>,
            <tbody key={1}>
                {config.products?.map((pr: any, index: number) => {
                    let memOrDep = pr.dependentSequenceNumber === 0 ? "Member" : "Dependent";
                    return <tr key={index}>
                        <td data-gwp-child={isEmptyObject(pr.agents) ? "no" : ''} className="gwp-details-control"></td>
                        <td>{pr.benefitClass}</td>
                        <td>{pr.productID + " - " + pr.productDescription}</td>
                        <td>{pr.effectiveDate}</td>
                        <td>{pr.firstName + " " + pr.lastName + " - " + memOrDep}</td>
                        <td>{pr.coverageOption}</td>
                        <td>{pr.planDescription}</td>
                        <td className="gwp-dt-child">
                            {!isEmptyObject(pr.agents) && <>
                                <props.Row className="extInfo">
                                    {!isEmptyObject(pr.agents[0]) && <div>
                                        <props.Col md={12} sm={12} xs={12}>
                                            <props.Label><GlobalizedText message="common.lbl.agents" /></props.Label>
                                        </props.Col>
                                        <props.Col md={4} sm={6} xs={12}>
                                            <props.Label className="paddingLeft15px">{pr.agents[0].agentNumber + ' ' + pr.agents[0].agentName}</props.Label>
                                            <props.Condition condition={!isEmptyObject(pr.agents[0].agentPercent) && pr.agents.length > 2}>
                                                <br />
                                                <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="common.lbl.split" /></props.Label>{formatNumber(pr.agents[0].agentPercent, { minimumFractionDigits: 0 }) + "%"}
                                            </props.Condition>
                                            <br />
                                            <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="pd.lbl.phone" /></props.Label>{pr.agents[0].phone}
                                            <br />
                                            <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="pd.lbl.email" /></props.Label><props.Link condition={!isEmptyObject(pr.agents[0].email)} className="agentEmail" to={`mailto:${pr.agents[0].email}"`}>{pr.agents[0].email}</props.Link>

                                        </props.Col>
                                    </div>}
                                    {!isEmptyObject(pr.agents[1]) &&
                                        <props.Col md={4} sm={6} xs={12}>
                                            <props.Label className="paddingLeft15px">{pr.agents[1].agentNumber + ' ' + pr.agents[1].agentName}</props.Label>
                                            {!isEmptyObject(pr.agents[1].agentPercent) && pr.agents.length > 2 && <>
                                                <br />
                                                <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="common.lbl.split" /></props.Label>{formatNumber(pr.agents[1].agentPercent, { minimumFractionDigits: 0 }) + "%"}
                                            </>}
                                            <br />
                                            <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="pd.lbl.phone" /></props.Label>{pr.agents[1].phone}
                                            <br />
                                            <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="pd.lbl.email" /></props.Label><props.Link condition={!isEmptyObject(pr.agents[1].email)} className="agentEmail" to={`mailto:${pr.agents[1].email}"`}>{pr.agents[1].email}</props.Link>
                                        </props.Col>}
                                    {pr.agents.length > 2 &&
                                        <props.Col md={4} sm={6} xs={12}>
                                            <props.LinkButton className="paddingLeft15px" id="showProductAgents" onClick={() => { productAgentsDetail(pr) }}><props.Icon type="view-all" label="common.lbl.viewAllAgents"></props.Icon></props.LinkButton>
                                        </props.Col>}
                                </props.Row>
                            </>}
                        </td>
                    </tr>;
                })}
            </tbody>
        ]
    });

    return <>
        <Formik initialValues={{ ...config }}
            onSubmit={(values, { setSubmitting }) => {
                // props.setInitParams(values);
                //setAsOfDate(values.asOfDate);
            }}>
            {FormProps => <Form className="form-inline"><div>
                <props.Row>
                    <props.Col xs="12" sm="12" md="12">
                        <div className="gwp-page-header">
                            <div className="gwp-page-header-block"></div>
                            <div className="gwp-page-header-label gwp-label"><GlobalizedText message="common.lbl.applicationNumber" /> {config.applicationNumber}</div>
                        </div>
                    </props.Col>
                </props.Row>

                <props.Row >
                    <props.Col xs="12" sm="8" md="8">
                        <props.DL className="dl-horizontal gwp-dl app-detail-dl marginLeft180px marginButtom10px">
                            <props.DLI title="common.lbl.applicant" desc={() => {
                                return <props.Link to={`/personalInformation?isUpdateMode=false&personalInfoType=1&companyCode=${companyCode}&groupNumber=${groupNumber}&accountNumber=${accountNumber}&participantIDNumber=${participantIDNumber}&dependentSequenceNumber=0&from=${thisPage}`} >
                                    {config.ownerName}
                                </props.Link>
                            }} />
                            <props.DLI title="pd.lbl.address" isHtml={true} desc={config.ownerAddress} />
                        </props.DL>
                    </props.Col>
                    <props.Col xs="12" sm="4" md="4">
                        {!isEmptyObject(config.agents) &&
                            <AgentsInfoPanel condition={!isEmptyObject(config.agents)} {...props} showMaxAgent={1} telLink={false} agents={config.agents?.map((agentInfo: any) => {
                                return { fullName: agentInfo.agentNumber + ' ' + agentInfo.agentName, phone: agentInfo.phone, email: agentInfo.email, splitPerctage: config.agents.length === 1 ? null : formatNumber(agentInfo.agentPercent, { minimumFractionDigits: 0 }) }
                            })} from='applicationDetail' ></AgentsInfoPanel>
                        }
                    </props.Col>
                </props.Row>
                <props.Row>
                    <props.Col xs="12" sm="12" md="12">
                        <props.Panel>
                            <props.PanelHeading>
                                <div className="app-detail-title"><GlobalizedText message="app.lbl.appInfo" /></div>
                            </props.PanelHeading>
                            <props.PanelBody>
                                <props.Row className="pd-policyinfo-content">
                                    <props.Col xs="12" sm="6" md="6">
                                        <props.DL className="gwp-dl marginLeft0px">
                                            <props.DLI title="common.lbl.status" desc={config.status} />
                                            <props.DLI title="pd.lbl.currency" desc={config.currency} />
                                            <props.DLI title="app.lbl.promDesc" desc={config.promotion} />
                                            <props.DLI title="app.lbl.recievedDate" desc={config.recievedDate} />
                                            <props.DLI title="app.lbl.signDate" desc={config.signDate} />
                                        </props.DL>
                                    </props.Col>
                                    <props.Col xs="12" sm="6" md="6">
                                        <props.DL className="gwp-dl marginLeft0px">
                                            <props.DLI title="common.lbl.group" desc={removeLeftZero(config.groupNumber) + " - " + config.groupName} />
                                            <props.DLI title="common.lbl.account" desc={config.accountNumber + " - " + config.accountName} />
                                            <props.DLI title="common.lbl.memberID" desc={config.participantIDNumber} />
                                            <props.DLI title="common.lbl.dob" desc={config.birthDate} />
                                            <props.DLI title="smoker" desc={config.smoker} />
                                        </props.DL>
                                    </props.Col>
                                </props.Row>
                            </props.PanelBody>
                        </props.Panel>
                    </props.Col>
                </props.Row>
                <props.Row>
                    <props.Col xs="12" sm="12" md="12">
                        <props.Panel>
                            <props.PanelHeading>
                                <div className="app-detail-title">
                                    <GlobalizedText message="app.lbl.products" />
                                    <span id="appAct">
                                        <GlobalizedText message="app.activities.title" />
                                        <a href="#" onClick={() => props.next(`/applicationActivities?companyCode=${companyCode}&groupNumber=${groupNumber}&accountNumber=${accountNumber}&participantIDNumber=${participantIDNumber}&applicantNumber=${config.applicationNumber}&from=${thisPage}`)} className="gwp-page-title">
                                            <span style={{ cursor: "pointer" }} className="gwp-icon gwp-icon-application-activities gwp-icon-sm marginLeft5px" ></span>
                                        </a>
                                    </span>
                                </div>
                            </props.PanelHeading>
                            <props.PanelBody replaceClass={true} className="benefit-panel-body">
                                <tblProduct.Component></tblProduct.Component>
                            </props.PanelBody>
                        </props.Panel>
                    </props.Col>
                </props.Row>




                {/* ViewProductAgent Dialog */}
                <props.Modal id="productAgentsDetail" title="common.lbl.viewAllAgents" footer={() => {
                    return <>
                        <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                    </>
                }}>
                    <props.Row>
                        {!isEmptyObject(product) && product.agents?.map((agent: any, i: number) => {
                            return <props.Col key={i} xs="12" md="6" sm="6">
                                {/* <props.DLI title={() => { return <><props.Span className="agentName">{agent.fullName}</props.Span> </> }} ></props.DLI> */}
                                <props.Label>{agent.agentNumber + ' ' + agent.agentName}</props.Label>
                                <props.DL className="dl-horizontal">
                                    <props.DLI title="common.lbl.split" desc={() => {
                                        return <>{formatNumber(agent.agentPercent, { minimumFractionDigits: 0 }) + "%"} </>
                                    }}></props.DLI>
                                    <props.DLI title="pd.lbl.phone" desc={() => {
                                        return <>{agent.phone}</>
                                    }}></props.DLI>
                                    <props.DLI title="pd.lbl.email" desc={() => {
                                        return <a href={"mailto:" + agent.email}>{agent.email}</a>
                                    }}></props.DLI>
                                </props.DL>
                                {/* {i === agent.length - 1 &&
                            <hr className="visible-xs-*" />
                        } */}
                            </props.Col>
                        })}
                    </props.Row>
                </props.Modal >
            </div>

            </Form>
            }
        </Formik>
    </>
});

