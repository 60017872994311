import { Form, Formik, useFormikContext, useField } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TableCell } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import GroupSelection from '../shared/groupSelection';
import { ajax } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/securityauthorization.css';
import { SecurityContext } from "../context/securityContext";
import { AccessPermissionComponent } from './accesspermission';


type Level = 'groupSelections' | 'companyAuthorization'

let companyCode = "";
let dataObj: any = {}


export const AccessPermissionCompaniesComponent = withView(function (props: ViewComponentProps) {
    const userID = props.getParam("userId")
    const [companyConfig, setCompanyConfig] = useState<any | null>(null);
    const [companyTable, setCompanyTable] = useState<any>([]);
    const [editing, setEditing] = useState<boolean>(false);
    const [tab, setTab] = useState<Level>('companyAuthorization');
    const {userAuth } = useContext(SecurityContext);

    const getAccessPermissionCompanyViewData = () => {
        ajax({
            url: '/api/accesspermission/companies/view',
            showMask: true,
            params: {
                "userId": userID
            },
            success: (res: any) => {
                setCompanyConfig(res);
                setCompanyTable(res.companies);                    
                if (companyCode === "") {
                    companyCode = res.companyCode;
                }
            }
        });
    }
    useEffect(() => {
        if (companyConfig === null) {
            getAccessPermissionCompanyViewData();
        }
    }, [companyConfig, userID, companyTable]);

    useEffect(()=>{
        if(companyConfig !== null && companyTable.length === 0){                                     
            ajax({
                url: `/api/accesspermission/companies/saveSettings/${userID}`,
                data: companyTable,
                method:'POST',
                success: (res: any) => { 
                    getAccessPermissionCompanyViewData();
                }
            });
        }
    },[companyTable]);


    const updateCompanyAccessClickHandeler = (index:any) =>{
        const updateAccess = companyTable.map((item:any,itemIndex:any)=>{
            if(itemIndex === index){
                return {...item,access: !item.access};
            }
            return {...item};
        })
        setCompanyTable(updateAccess);
    }

    const onCancelEditClickHandeler = () =>{
        getAccessPermissionCompanyViewData();
        setEditing(!editing);
    }

    const saveSettings = () =>{                
        ajax({
            url: `/api/accesspermission/companies/saveSettings/${userID}`,
            data: companyTable,
            method:'PUT',
            success: (res: any) => { 
                props.showMessage("success", props.getGlobalizedText("common.lbl.accsetsaved"));
                setEditing(!editing);
            }
        });
    }
    if (companyConfig === null) {
        return <></>
    }
    return (
        <>
            <props.Row>
                <props.Col sm="4" md="4" xs="12">
                    <props.DL className="DLfontSize1" >
                        <props.DLI title="common.lbl.firstName" desc={companyConfig.firstName} ></props.DLI>
                    </props.DL>
                </props.Col>
                <props.Col sm="8" md="8" xs="12">
                    <props.DL className="DLfontSize1">
                        <props.DLI title="common.lbl.lastName" desc={companyConfig.lastName}></props.DLI>
                    </props.DL>
                </props.Col>
            </props.Row>
            <props.Row>
                <props.Col sm="4" md="4" xs="12" className="DLMargin DLfontSize1">
                    <props.DL>
                        <props.DLI title={tab=== 'companyAuthorization' ? 'security.lbl.userType' : 'common.lbl.companyAuthorization'} desc={companyConfig.userType}></props.DLI>
                    </props.DL>
                </props.Col>
            </props.Row>

            <props.Container>
                <props.Row>
                    <props.Col>
                        <ul id="sctab" className="nav nav-tabs" role="tablist">

                            <li role="presentation" className={tab === 'companyAuthorization' ? 'active' : ''}><a href="#companyAuthorization" onClick={() => { props.clearMessage(); setTab('companyAuthorization') }} aria-controls="companyAuthorization" role="tab" data-toggle="tab"><GlobalizedText message="common.lbl.company.authorization" /></a></li>

                            <li role="presentation" className={tab === 'groupSelections' ? 'active' : ''}><a href="#groupSelections" onClick={() => { props.clearMessage(); setTab('groupSelections') }} aria-controls="groupSelections" role="tab" data-toggle="tab"><GlobalizedText message="common.lbl.group.selection" /></a></li>
                        </ul>
                        <div id="scTabContent" className="tab-content">
                            <div role="tabpanel" className={`tab-pane fade in ${tab === 'companyAuthorization' ? 'active' : ''}`} id="companyAuthorizationTab">
                                <div id="groupDiv">
                                    {(editing !== true) &&
                                        <div>
                                            <div className="form-group btnGroupDiv">

                                                <>
                                                    <props.Button onClick={() => { setEditing(!editing) }} ><GlobalizedText message="common.lbl.edit" /></props.Button>
                                                </>
                                            </div>
                                        </div>
                                    }
                                    {(editing === true) &&
                                        <div>
                                            <div className="form-group" style={{ marginBottom: "20px" }}>
                                                <props.Button type="submit" onClick={saveSettings}><GlobalizedText message="common.button.submit" /></props.Button>
                                                <props.Button onClick={onCancelEditClickHandeler}><GlobalizedText message="common.lbl.close" /></props.Button>
                                            </div>
                                        </div>
                                    }
                                    <table className="table gwp-table table-striped table-bordered dataTable table-hover" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th><GlobalizedText message='common.lbl.companyCode' /></th>
                                                <th><GlobalizedText message='changeow.lbl.companyName' /></th>
                                                <th><GlobalizedText message='security.lbl.access' /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                companyTable.map((company: any, key: any) =>
                                                    <tr key={key}>
                                                        <td>{company.companyCode}</td>
                                                        <td>{company.companyName}</td>
                                                        <td>
                                                            {(!editing) && <>{company.access ? 'Allow' : 'Deny'}</>}
                                                            {editing && <>
                                                                <input type="checkbox" id={`allow-${company.companyCode}`} onChange={(e) => { updateCompanyAccessClickHandeler(key) }} checked={(company.access) && true} />
                                                                <label htmlFor={`allow-${company.companyCode}`}><GlobalizedText message="common.lbl.allow" /></label>
                                                                <input type="checkbox" id={`deny-${company.companyCode}`} onChange={(e) => { updateCompanyAccessClickHandeler(key) }} checked={(!company.access) && true} />
                                                                <label htmlFor={`deny-${company.companyCode}`}><GlobalizedText message="common.lbl.deny" /></label>
                                                            </>}
                                                        </td>
                                                    </tr>
                                                )
                                            }</tbody>
                                    </table>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className={`tab-pane fade in ${tab === 'groupSelections' ? 'active' : ''}`} id="systemLevelTab">
                                <div id="sysButtons" style={{ marginBottom: "20px" }}>
                                {(tab === 'groupSelections' && companyConfig.userTypeRegCode === '05') && <GroupSelection {...props} />}
                                {(tab === 'groupSelections' && companyConfig.userTypeRegCode !== '05') && <AccessPermissionComponent {...props} />}
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                    </props.Col>
                </props.Row>
            </props.Container>

        </>
    )
});


