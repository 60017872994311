import React,{useEffect,useState} from 'react'

const ContactMethodsDropdown = (props: any) => {
	const allowedList: any = ['','Cell Phone','Email','Home Phone','Regular Mail'];
	const [filterData, setFilterData] = useState([]);
	
	useEffect(()=>{
		const filter: any = [];
		if(props.contactMethods.length>0 && filter.length === 0){
			props.contactMethods.map((item:any) =>{
				if(allowedList.includes(item.name)){
					return filter.push(item);
				}
			});
			setFilterData(filter);
		}
	},[]);
	
    return (
        <props.SelectControl id="conMeth" name="conMeth" required={false} defaultOption={""} label="pi.prefered.contract.method" options={filterData}>
        </props.SelectControl>
    )
}

export default ContactMethodsDropdown;
