import React, { useState, useEffect } from "react"
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { Formik, Form } from 'formik';
import { ajax, getGlobalizedText, trim, validate } from '../shared/utils';
import { useMessage } from "../shared/message";
import GlobalizedText from '../shared/globalization';
import { TableColumn } from "../shared/components";

import { VALIDATION_REGEX } from "../shared/regex";
import './css/initialbenefitselectionperiod.css';

import { RequiredTitle, WarningTitle, IndicatedReqTitle } from "../shared/moduleTitles";

let selectetedRowData: any = null;

export const InitialBenefitSelectionperiodComponent = withView(function (props: ViewComponentProps) {
    const messageService4Add = useMessage("oepAdd");
    const messageService4Edit = useMessage("oepEdit");
    const [config, setConfig] = useState<any | null>(null);
    const [groups, setGroups] = useState<Array<any>>([{ name: props.getGlobalizedText("common.lbl.all"), value: "0" }]);
    const [accounts, setAccounts] = useState<Array<any>>([{ name: props.getGlobalizedText("common.lbl.all"), value: "0" }]);

    const [groupsAll, setGroupsAll] = useState<Array<any>>([]);
    const [accountsAll, setAccountsAll] = useState<Array<any>>([]);

    const [handlerFlag, setHandlerFlag] = useState<string | null>();
    const [disableBtnFlag, setDisableBtnFlag] = useState<boolean>(true);


    let varFormPromp: any;

    const tableColumns: Array<TableColumn> = [
        { "title": "common.lbl.group", "name": "groupDesc", "sortable": true },
        { "title": "common.lbl.account", "name": "accountDesc", "sortable": true },
        { "title": "benefit.avail.days.from.hire", "name": "days", "sortable": true }
    ]
    const formValidate = (values: any): any => {
        if (handlerFlag === 'addIBP') {
            return validate(addSchema, values, messageService4Add.validateProps);
        } else if (handlerFlag === 'editIBP') {
            return validate(editSchema, values, messageService4Edit.validateProps);
        }
    }
    let addSchema = (formValue: any) => {
        let addValidateResult: any = {};
        let errorFlag: boolean = false;
        if (trim(formValue.addDays) === "") {
            addValidateResult.addDays = "common.msg.dayRequired";
            errorFlag = true;
        } else if (VALIDATION_REGEX.NUMBER.test(trim(formValue.addDays)) === false) {
            addValidateResult.addDays = "common.msg.dayIncorrect";
            errorFlag = true;
        } else if (VALIDATION_REGEX.NUMBER.test(trim(formValue.addDays)) === true && parseInt(formValue.addDays) >= 1000000) {
            addValidateResult.addDays = "common.msg.dayTooLong";
            errorFlag = true;
        }
        if (errorFlag === true) {
            return addValidateResult;
        }

    };
    let editSchema = (formValue: any) => {
        let addValidateResult: any = {};
        let errorFlag: boolean = false;
        if (trim(formValue.editDays) === "") {
            addValidateResult.editDays = "common.msg.dayRequired";
            errorFlag = true;
        } else if (VALIDATION_REGEX.NUMBER.test(trim(formValue.editDays)) === false) {
            addValidateResult.editDays = "common.msg.dayIncorrect";
            errorFlag = true;
        } else if (VALIDATION_REGEX.NUMBER.test(trim(formValue.editDays)) === true && parseInt(formValue.editDays) >= 1000000) {
            addValidateResult.editDays = "common.msg.dayTooLong";
            errorFlag = true;
        }
        if (errorFlag === true) {
            return addValidateResult;
        }
    };
    const groupChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target.value;
        if (companyAndGroupNumber === '0') {
            setAccounts([{ "name": props.getGlobalizedText("common.lbl.all"), "value": "0" }]);
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: '/api/initialBenefitPeriod/getAccountListWithPeriod',
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setAccounts(res.accounts);
                    setFieldValue("account", res.accounts[0].value);
                }
            });
        }
    }
    const formSubmit = (values: any): void => {
        props.clearMessage();
        if (handlerFlag === 'retrieve') {
            retrieve(values, tableColumns, setTable);
        } else if (handlerFlag === 'addIBP') {
            addNewIBP(values);
        } else if (handlerFlag === 'editIBP') {
            editIBP(values);
        }
    }

    function openAddDialog(formProps: any) {
        //clear error, re-render UI
        clearAddDialog(messageService4Add.validateProps);
        setHandlerFlag("clear");
        formProps.setFieldValue("addGroupNumber", 0);
        setAccountsAll([{ "name": props.getGlobalizedText("common.lbl.all"), "value": "0" }]);
        formProps.setFieldValue("inAnnDateAdd", "");
        props.showModal('#OEP_addDialog');
    }
    function openEditDialog(formProps: any) {
        setHandlerFlag("clear");
        if (selectetedRowData != null) {
            messageService4Edit.validateProps.clearMessage();
            varFormPromp.setFieldValue("editGroup", selectetedRowData.groupDesc);
            varFormPromp.setFieldValue("editAccount", selectetedRowData.accountDesc);
            varFormPromp.setFieldValue("editDays", "" + selectetedRowData.days);
            props.showModal('#OEP_editDialog')
        }
    }
    function openDeleteDialog(formValue: any) {
        if (selectetedRowData != null) {
            props.showModal('#confirmDelete')
        }
    }



    function saveSelectedValue(row: any) {
        selectetedRowData = row;
        if (selectetedRowData != null) {
            varFormPromp.setFieldValue("editGroup", selectetedRowData.groupDesc);
            varFormPromp.setFieldValue("editAccount", selectetedRowData.accountDesc);
            varFormPromp.setFieldValue("daysEdit", selectetedRowData.days);
            setDisableBtnFlag(false);
        }
    }


    const groupAddChangeHandler = (e: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target.value;
        if (companyAndGroupNumber === '0') {
            setAccountsAll([{ "name": props.getGlobalizedText("common.lbl.all"), "value": "0" }]);
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: '/api/initialBenefitPeriod/getAccountList',
                showMask: false,
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setAccountsAll(res.accounts);
                    setFieldValue("addAccount", "0");
                }
            });
        }
    }
    function retrieve(values: any, columns: any, setTable: any, setSubmitting?: Function) {
        let compCode = "0";
        let groupNumber = "0";
        let accountNumber = "0";

        if (values.groupNumber != "0") {
            let companyAndGroup = values.groupNumber.split("|");
            groupNumber = companyAndGroup[1];
            compCode = companyAndGroup[0];
        }

        if (values.groupNumber != "0" && values.account != "0") {
            accountNumber = values.account;
        }

        ajax({
            url: '/api/initialBenefitPeriod/fetchInitBenefitPeriod/',
            params: {
                companyCode: compCode,
                groupNumber: groupNumber,
                accountNumber: accountNumber
            },
            success: (res: any) => {
                setDisableBtnFlag(true);
                if (res.initialBenefitPeriodVOList !== undefined) {
                    setTable({
                        columns: columns,
                        data: res.initialBenefitPeriodVOList.map((row: any) => { return { ...row, ...{ checked: false } } })
                    });
                }
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }
    function clearAddDialog(viewProps: any) {
        varFormPromp.setFieldValue("addGroupNumber", '0');
        varFormPromp.setFieldValue("addAccount", '0');
        varFormPromp.setFieldValue("addDays", '');
        viewProps.clearMessage();
    }
    function addNewIBP(formValue: any) {
        messageService4Add.validateProps.clearMessage();

        let groupNum = "";
        let compStr = "0";
        let accountNum = "0";

        if (formValue.addGroupNumber != 0) {
            let companyAndGroup = formValue.addGroupNumber.split("|");
            groupNum = companyAndGroup[1];
            compStr = companyAndGroup[0];
            accountNum = formValue.addAccount;
        }
        let days = formValue.addDays;
        ajax({
            url: '/api/initialBenefitPeriod/add',
            method: 'post',
            data: {
                companyCode: compStr,
                groupNumber: groupNum,
                accountNumber: accountNum,
                days: days

            },
            success: (res: any) => {
                props.closeModal("#OEP_addDialog");
                setDisableBtnFlag(true);
                props.showMessage("success", "initial.benefit.msg.add.success");
                setConfig(null);

            },
            fail: (res: any) => {
                messageService4Add.validateProps.showMessage("error", "initial.benefit.msg.group.account.exists");
            },
            callback: () => {
            }
        });
    }
    function editIBP(formValue: any) {
        let days = trim(formValue.editDays);

        ajax({
            url: `/api/initialBenefitPeriod/update/${selectetedRowData.recid}`,
            method: 'post',
            data: {
                days: days
            },
            success: (res: any) => {
                retrieve(formValue, tableColumns, setTable);
                props.closeModal("#OEP_editDialog");
                setDisableBtnFlag(true);
                props.showMessage("success", "initial.benefit.msg.update.success");
            },
            fail: (res: any) => {
            },
            callback: () => {
            }
        });
    }
    function delIBP(formValue: any) {
        ajax({
            url: `/api/initialBenefitPeriod/delete/${selectetedRowData.recid}`,
            method: 'delete',
            success: (res: any) => {
                setDisableBtnFlag(true);
                props.showMessage("success", "initial.benefit.msg.delete.success");
                setConfig(null);
            },
            callback: () => {
            }
        });
    }
    const [initialBenefitPeriodtable, setTable] = useState<any | null>({ columns: tableColumns, data: [] });

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/initialBenefitPeriod/view',
                success: (res: any) => {
                    setConfig(res);
                    varFormPromp.setFieldValue("groupNumber", 0);
                    setAccounts(res.accounts);
                    setGroups(res.groups);

                    setAccountsAll(res.accountsAll);
                    setGroupsAll(res.groupsAll);

                    varFormPromp.setFieldValue("account", 0);

                    if (res.initialBenefitPeriodVOList !== undefined) {
                        setTable({
                            columns: tableColumns,
                            data: res.initialBenefitPeriodVOList.map((row: any) => { return { ...row, ...{ checked: false } } })
                        });
                    }
                }
            });
            return () => {
            }
        }
    }, [config]);



    return <React.Fragment>
        <Formik initialValues={{
            groupNumber: '',
            account: '0',

            addGroupNumber: '0',
            addAccount: '0',
            addDays: '',


            editGroup: '',
            editAccount: '',
            editDays: ''
        }}
            validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps => {
                varFormPromp = formProps;
                return <Form>
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <props.Label><GlobalizedText message='common.lbl.groupaccount' /></props.Label>
                        </props.Col>
                        <props.Col xs="12" sm="6" md="6">
                            <props.SelectControl name="groupNumber" defaultOption={"common.lbl.all"} sortByName={false} sort={true} label="common.lbl.group" onChange={(e: any) => { groupChangeHandler(e, formProps.values, formProps.setFieldValue) }} options={config === null ? [{ name: props.getGlobalizedText("common.lbl.all"), value: "0" }] : config.groups}>
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="6" md="6">
                            <props.SelectControl label="common.lbl.account" sort={false} name="account" options={accounts} >
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <props.Button type="submit" onClick={() => { setHandlerFlag("retrieve") }}><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <props.HR />
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <props.Button name="btnAdd" type="submit" onClick={() => { openAddDialog(formProps) }} ><GlobalizedText message="common.lbl.add" /></props.Button>
                            <props.Button name="btnEdit" type="submit" disabled={disableBtnFlag} onClick={() => { openEditDialog(formProps) }} ><GlobalizedText message="common.lbl.edit" /></props.Button>
                            <props.Button name="btnDelete" disabled={disableBtnFlag} onClick={() => { openDeleteDialog(formProps.values) }}  ><GlobalizedText message="common.lbl.delete" /></props.Button>
                        </props.Col>

                    </props.Row>

                    {/* Delete Dialog */}
                    <props.Modal title={() => {
                        return WarningTitle('common.lbl.deleteInitialBenefitSelectionPeriod');
                    }
                    } id="confirmDelete" footer={() => {
                        return <>
                            <props.Button onClick={() => { delIBP(formProps.values) }} data-toggle="modal" data-target="#confirmDelete" id='removeAccess'><GlobalizedText message="common.lbl.delete" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} >
                        <props.Row>
                            <props.Col xs="12" sm="6" md="6">
                                <props.DL>
                                    <props.DLI titleClass="gwp-label-ro" title="common.lbl.group" desc={selectetedRowData === null ? "" : selectetedRowData.groupDesc}></props.DLI>
                                </props.DL>
                                <props.DL>
                                    <props.DLI titleClass="gwp-label-ro" title="common.lbl.account" desc={selectetedRowData === null ? "" : selectetedRowData.accountDesc}></props.DLI>
                                </props.DL>
                                <props.DL>
                                    <props.DLI titleClass="gwp-label-ro" title="benefit.avail.days.from.hire" desc={selectetedRowData === null ? "" : "" + selectetedRowData.days}></props.DLI>
                                </props.DL>

                            </props.Col>

                        </props.Row>


                    </props.Modal>

                    {/* Add Dialog */}
                    <props.Modal title={() => {
                        return RequiredTitle('common.lbl.addInitialBenefitSelectionPeriod');
                    }
                    } id="OEP_addDialog" footer={() => {
                        return <>
                            <props.Button type="submit" onClick={() => { setHandlerFlag("addIBP") }} ><GlobalizedText message="common.button.submit" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} messageService={messageService4Add.messageService} >
                        <IndicatedReqTitle message={"common.lbl.indicateRequiredFld"} props={props} />
                        <props.Row>
                            <props.Col xs="12" sm="12" md="12">
                                <props.SelectControl required={true} sort={false} name="addGroupNumber" label="common.lbl.group" onChange={(e: any) => { groupAddChangeHandler(e, formProps.setFieldValue) }} options={config === null ? [{ name: props.getGlobalizedText("common.lbl.all"), value: "0" }] : config.groupsAll}>
                                </props.SelectControl>
                                <props.SelectControl required={true} sort={false} label="common.lbl.account" name="addAccount" options={accountsAll}>
                                </props.SelectControl>
                                <props.TextControl required={true} label="benefit.avail.days.from.hire" id="addDays" name="addDays"></props.TextControl>

                            </props.Col>
                        </props.Row>
                    </props.Modal>

                    {/* Edit Dialog */}
                    <props.Modal title={() => {
                        return RequiredTitle('common.lbl.editInitialBenefitSelectionPeriod');
                    }
                    } id="OEP_editDialog" footer={() => {
                        return <>
                            <props.Button type="submit" onClick={() => { setHandlerFlag("editIBP") }} ><GlobalizedText message="common.button.submit" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} messageService={messageService4Edit.messageService}>
                        <IndicatedReqTitle message={"common.lbl.indicateRequiredFld"} props={props} />
                        <props.Row>
                            <props.Col xs="12" sm="12" md="12">
                                <props.DL>
                                    <props.DLI titleClass="gwp-label-ro" title="common.lbl.group" desc={selectetedRowData === null ? "" : selectetedRowData.groupDesc}></props.DLI>
                                </props.DL>
                                <props.DL>
                                    <props.DLI titleClass="gwp-label-ro" title="common.lbl.account" desc={selectetedRowData === null ? "" : selectetedRowData.accountDesc}></props.DLI>
                                </props.DL>
                                <props.TextControl required={true} label="benefit.avail.days.from.hire" id="editDays" name="editDays"></props.TextControl>
                            </props.Col>

                        </props.Row>
                    </props.Modal>
                </Form>
            }
            }
        </Formik>
        <props.Table id="initialBenefitPeriodResult" select={{ type: 'single', onSelect: (e: any, rows: number[]) => { saveSelectedValue(initialBenefitPeriodtable.data[rows[0]]) } }} table={initialBenefitPeriodtable} dateformat="MM/DD"></props.Table>
    </React.Fragment >
});