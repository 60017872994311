import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, convertDate, isEmptyStr, validate } from '../shared/utils';
import { ViewComponentProps } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import * as yup from 'yup';
import { PersonalContactInfo } from './personalcontactinfo';
import './css/searchsection.css';
import { HelpBanner } from '../shared/helpbanner';

export const NAME_TYPE_PERSON = "1";
export const NAME_TYPE_COMPANY = "2";
const HYPHEN = "-";
const DEFAULT_MASK_SYMBOL = "*";

export type ChangeType = 1 //Change Onwer
    | 2 // CHange Bene
    | 3 //Add second Addresee
    | 4 // change second
    | 5 // personal infor

export interface SearchSectionProps extends ViewComponentProps {
    changeType: ChangeType,
    companyCode: string,
    policyNumber: string,
    fromPage?: string,
    clientNumberFromInsurance?: string,
}
interface SearchSectionForm {
    sel_beneType: string,
    sel_ownerType: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    companyName: string,
    govtID: string
}
interface SearchSectionForm2 {
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    relationship: string,
    govtID: string
}


export const SearchSection = (props: SearchSectionProps) => {
    const companyCodePre = props.companyCode
    const policyNumberPre = props.policyNumber

    const [config, setConfig] = useState<any | null>(null);
    const [serachSection, setSerachSection] = useState<boolean>(true);
    const [isNewClient, setIsNewClient] = useState<boolean>(true);
    const searchSectionForm = useRef<any>();
    const contactMethods = useRef<Array<any>>([])
    const [roleTypeCode, setRoleTypeCode] = useState<any | null>(null);
    const [nameType, setNameType] = useState<any | null>(null);
    const [gender, setGender] = useState<any | null>(null);
    const [middleName, setMiddleName] = useState<any | null>(null);
    const [govtID, setGovtID] = useState<any | null>(null);

    const beneTypeOption: Array<any> = [
        { name: "addbene.lbl.priBene", value: "1" },
        { name: "addbene.lbl.conBene", value: "2" }
    ]
    const ownerTypeOption: Array<any> = [
        { name: "changeow.lbl.person", value: "1" },
        { name: "memlist.company", value: "2" }
    ]
    const genderOption: Array<any> = [
        { name: "", value: "0" },
        { name: "changeow.lbl.genderMale", value: "1" },
        { name: "changeow.lbl.genderFemale", value: "2" }
    ]
    const defaultInitialValues: SearchSectionForm = {
        sel_beneType: "1",
        sel_ownerType: "1",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        companyName: "",
        govtID: ""
    };
    const defaultInitialValues2: SearchSectionForm2 = {
        firstName: config !== null ? config.clientInfo.firstName : '',
        lastName: config !== null ? config.clientInfo.lastName : '',
        dateOfBirth: config !== null ? convertDate(config.clientInfo.birthDate, "yyyy-MM-dd", props.getDateFormat()) : '',
        relationship: config !== null ? config.clientInfo.relationship : '',
        govtID: config !== null ? config.clientInfo.ssn : '',
    };

    let initialValue_searchSection: SearchSectionForm = props.getInitParams() !== undefined ? props.getInitParams() : defaultInitialValues;
    let initialValue_searchSection2: SearchSectionForm2 = props.getInitParams() !== undefined ? props.getInitParams() : defaultInitialValues2;

    const byPersonalWithoutSSN = yup.object().shape({
        firstName: YupSchema.searchSection.firstName,
        lastName: YupSchema.searchSection.lastName,
        dateOfBirth: YupSchema.searchSection.birthDate
    });
    const byPersonalWithSSN = yup.object().shape({
        firstName: YupSchema.searchSection.firstName,
        lastName: YupSchema.searchSection.lastName,
        dateOfBirth: YupSchema.searchSection.birthDate,
        govtID: YupSchema.govtID("SSN", props.getGlobalizedText)
    });

    const byCompanyWithEIN = yup.object().shape({
        companyName: YupSchema.searchSection.companyName,
        govtID: YupSchema.govtID("EIN", props.getGlobalizedText)
    });
    const byCompany = yup.object().shape({
        companyName: YupSchema.searchSection.companyName
    });

    //govtID: YupSchema.searchSection.govtID
    const byChangeOwner = yup.object().shape({
        firstName: YupSchema.searchSection.firstName,
        lastName: YupSchema.searchSection.lastName,
        dateOfBirth: YupSchema.searchSection.birthDate,
        govtID: YupSchema.govtID("SSN", props.getGlobalizedText)
    })

    function setMidNameProps(value: any) {
        setMiddleName(value)
    }
    function setGenderProps(value: any) {
        setGender(value)
    }
    function setGovtIDProps(value: any) {
        setGovtID(value)
    }

    const formValidate = (values: SearchSectionForm): any => {
        if (values.sel_ownerType === "1") {
            if (props.changeType === 1) {
                return validate(byChangeOwner, values, props);
            }
            if (!isEmptyStr(values.govtID)) {
                return validate(byPersonalWithSSN, values, props);
            } else {
                return validate(byPersonalWithoutSSN, values, props);
            }

        } else if (values.sel_ownerType === "2") {
            if (!isEmptyStr(values.govtID)) {
                return validate(byCompanyWithEIN, values, props);
            } else {
                return validate(byCompany, values, props);
            }
        }
    }

    function handleSuccess(res: any) {
        setIsNewClient(res.clientInfo.newClient)
        contactMethods.current = res.states
        setConfig(res)
        setSerachSection(false)
    }
    function maskGovtID() {
        if (config.clientInfo.ssn.length < 4) {
            return config.clientInfo.ssn
        }
        let res = '';
        for (let i = 0; i < config.clientInfo.ssn.length - 4; i++) {
            res += (config.clientInfo.ssn[i] === HYPHEN ? HYPHEN : DEFAULT_MASK_SYMBOL);
        }
        res += config.clientInfo.ssn.substring(config.clientInfo.ssn.length - 4);
        return res;
    }
    function changeOwnerType() {
        searchSectionForm.current.setErrors({})
        searchSectionForm.current.setFieldValue('firstName', '')
        searchSectionForm.current.setFieldValue('lastName', '')
        searchSectionForm.current.setFieldValue('dateOfBirth', '')
        searchSectionForm.current.setFieldValue('companyName', '')
        searchSectionForm.current.setFieldValue('govtID', '')
        props.clearMessage()
    }

    const formSubmit = (values: SearchSectionForm): void => {
        setNameType(values.sel_ownerType)
        if (props.changeType === 1) { //Change Owner
            setRoleTypeCode("OW1")
            ajax({
                url: '/api/changeRelation/searchNewClient',
                method: "GET",
                params: {
                    companyCode: companyCodePre,
                    policyNumber: policyNumberPre,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    birthDate: values.dateOfBirth,
                    companyName: values.sel_ownerType === "1" ? '' : values.companyName,
                    govtID: values.govtID,
                    nameType: values.sel_ownerType,
                    roleTypeCode: "OW1",
                    changeType: 1
                },
                success: handleSuccess,
                fail: (res: any, errorMsg: any) => {
                    props.showMessage('error', errorMsg)
                },
                error: (error: any) => {
                    props.showMessage('error', error.response.data.message)
                }
            })
        } else if (props.changeType === 2) { //Change Beneficiary
            setRoleTypeCode(values.sel_beneType === "1" ? "BEN" : "CNT")
            ajax({
                url: '/api/changeRelation/searchNewClient',
                method: "GET",
                params: {
                    companyCode: companyCodePre,
                    policyNumber: policyNumberPre,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    birthDate: values.dateOfBirth,
                    companyName: values.sel_ownerType === "1" ? '' : values.companyName,
                    govtID: values.govtID,
                    nameType: values.sel_ownerType,
                    roleTypeCode: values.sel_beneType === "1" ? "BEN" : "CNT",
                    changeType: 2
                },
                success: handleSuccess,
                fail: (res: any, errorMsg: any) => {
                    props.showMessage('error', errorMsg)
                },
                error: (error: any) => {
                    props.showMessage('error', error.response.data.message)
                }
            })
        } else if (props.changeType === 3) {
            setRoleTypeCode("SEC")
            ajax({
                url: '/api/changeRelation/searchNewClient',
                method: "GET",
                params: {
                    companyCode: companyCodePre,
                    policyNumber: policyNumberPre,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    birthDate: values.dateOfBirth,
                    companyName: values.sel_ownerType === "1" ? '' : values.companyName,
                    govtID: values.govtID,
                    nameType: values.sel_ownerType,
                    roleTypeCode: "SEC",
                    changeType: 3
                },
                success: handleSuccess,
                fail: (res: any, errorMsg: any) => {
                    props.showMessage('error', errorMsg)
                },
                error: (error: any) => {
                    props.showMessage('error', error.response.data.message)
                }
            })
        } else if (props.changeType === 4) {
            setRoleTypeCode("SEC")
            ajax({
                url: '/api/changeRelation/searchNewClient',
                method: "GET",
                params: {
                    companyCode: companyCodePre,
                    policyNumber: policyNumberPre,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    birthDate: values.dateOfBirth,
                    companyName: values.sel_ownerType === "1" ? '' : values.companyName,
                    govtID: values.govtID,
                    nameType: values.sel_ownerType,
                    roleTypeCode: "SEC",
                    changeType: 4
                },
                success: handleSuccess,
                fail: (res: any, errorMsg: any) => {
                    props.showMessage('error', errorMsg)
                },
                error: (error: any) => {
                    props.showMessage('error', error.response.data.message)
                }
            })
        }
    }
    const formSubmit2 = (values: SearchSectionForm2): void => {
    }

    return <props.Div id="searchSection">
        <props.Div id="minSrcHelp" className="minHide">
            <HelpBanner {...props}></HelpBanner>
        </props.Div>
        {serachSection === true && <>
            <Formik initialValues={initialValue_searchSection}
                enableReinitialize={true}
                validate={formValidate}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={formSubmit}>
                {formProps => {
                    searchSectionForm.current = formProps
                    return <Form id="searchSection">
                        <props.Row>
                            <props.Col sm="12" md="9" xs="12">
                                <props.Div className="information gwp-information">
                                    <props.Span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></props.Span>{" "}
                                    <props.Span><GlobalizedText message="changeow.msg.info.start" /></props.Span>
                                </props.Div>
                                <props.Row>
                                    <props.Col sm="12" md="12" xs="12">
                                        {props.changeType === 2 && <>
                                            <props.SelectControl id="sel_beneType" name="sel_beneType" label="addbene.lbl.choseBeneType" defaultOption={"addbene.lbl.priBene"} options={beneTypeOption}></props.SelectControl>
                                            <props.SelectControl id="sel_ownerType" name="sel_ownerType" label="addbene.lbl.choseType" defaultOption={"changeow.lbl.person"} options={ownerTypeOption}></props.SelectControl>
                                        </>
                                        }
                                        {props.changeType === 1 &&
                                            <props.SelectControl id="sel_ownerType" name="sel_ownerType" label="changeow.msg.info.choseType" defaultOption={"changeow.lbl.person"} options={ownerTypeOption} onChange={changeOwnerType}></props.SelectControl>
                                        }
                                        {(props.changeType === 3 || props.changeType === 4) &&
                                            <props.SelectControl id="sel_ownerType" name="sel_ownerType" label="addbene.lbl.choseType" defaultOption={"changeow.lbl.person"} options={ownerTypeOption} onChange={changeOwnerType}></props.SelectControl>
                                        }
                                    </props.Col>
                                </props.Row>
                                <props.Row>
                                    {
                                        formProps.values.sel_ownerType === NAME_TYPE_PERSON && <>
                                            <props.Col sm="4" md="4" xs="12">
                                                <props.TextControl id="searchSection_firstName" name="firstName" label="common.lbl.firstName" required={true} />
                                            </props.Col>
                                            <props.Col sm="4" md="4" xs="12">
                                                <props.TextControl id="searchSection_lastName" name="lastName" label="common.lbl.lastName" required={true} />
                                            </props.Col>
                                            <props.Col sm="4" md="4" xs="12">
                                                <props.DateTimePickerControl htmlFor="searchSection_DOB_input" id="searchSection_DOB" name="dateOfBirth" label="common.lbl.dob" dateformat={props.getDateFormat()} required={true} />
                                            </props.Col>
                                        </>
                                    }
                                    {
                                        formProps.values.sel_ownerType === NAME_TYPE_COMPANY && <>
                                            <props.Col sm="4" md="4" xs="12">
                                                <props.TextControl id="searchSection_companyName" required={true} name="companyName" label="changeow.lbl.companyName" />
                                            </props.Col>
                                        </>
                                    }
                                </props.Row>
                                <props.Row>
                                    {
                                        formProps.values.sel_ownerType === NAME_TYPE_PERSON && <>
                                            <props.Col sm="4" md="4" xs="12">
                                                <props.AutoFormatControl formatType="SSN" valueWithFormat={true} id="govtID" name="govtID" label="common.lbl.govID" required={props.changeType === 1 ? true : false}></props.AutoFormatControl>
                                            </props.Col>
                                        </>
                                    }
                                    {
                                        formProps.values.sel_ownerType === NAME_TYPE_COMPANY && <>
                                            <props.Col sm="4" md="4" xs="12">
                                                <props.AutoFormatControl formatType="EIN" valueWithFormat={true} id="govtID" name="govtID" label="common.lbl.govID" required={props.changeType === 2 ? false : true}></props.AutoFormatControl>
                                            </props.Col>
                                        </>
                                    }
                                </props.Row>
                                <props.Row>
                                    <props.Col sm="12" md="12" xs="12">
                                        <props.Button type="submit" ><GlobalizedText message="common.button.continue" /></props.Button>
                                        <props.Button onClick={() => { props.back() }} ><GlobalizedText message="common.lbl.cancel" /></props.Button>
                                    </props.Col>
                                </props.Row>
                            </props.Col>
                            <props.Col sm="12" md="3" xs="12">
                                <props.Div id="maxSrcHelpBanner" className="maxHide">
                                    <HelpBanner {...props}></HelpBanner>
                                </props.Div>
                            </props.Col>
                        </props.Row>
                    </Form>
                }}
            </Formik>
        </>
        }
        {/* New Client */}
        {
            serachSection === false && isNewClient === true && <>
                <props.Row>
                    <props.Col id="WarnMessage" sm="12" md="9" xs="12">
                        {props.changeType === 1 && <>
                            <props.Span className="glyphicon glyphicon-exclamation-sign warnInfo"></props.Span>{' '}
                            <props.Span className="warnInfo">
                                <GlobalizedText message="changeow.msg.info.clientNotFound" />
                            </props.Span>
                        </>}
                        {props.changeType === 2 && <>
                            <props.Span className="glyphicon glyphicon-exclamation-sign warnInfo"></props.Span>{' '}
                            <props.Span className="warnInfo">
                                <GlobalizedText message="addbene.msg.info.clientNotFound" />
                            </props.Span>
                        </>}
                        {props.changeType === 3 && <>
                            <props.Span className="glyphicon glyphicon-exclamation-sign warnInfo"></props.Span>{' '}
                            <props.Span className="warnInfo">
                                <GlobalizedText message="addsecaddr.msg.info.clientNotFound" />
                            </props.Span>
                        </>}
                        {props.changeType === 4 && <>
                            <props.Span className="glyphicon glyphicon-exclamation-sign warnInfo"></props.Span>{' '}
                            <props.Span className="warnInfo">
                                <GlobalizedText message="addsecaddr.msg.info.change.clientNotFound" />
                            </props.Span>
                        </>}
                        {(config.clientInfo.firstName !== undefined && config.clientInfo.lastName !== undefined && config.clientInfo.birthDate !== undefined) &&
                            <props.Row>
                                <Formik initialValues={initialValue_searchSection2}
                                    enableReinitialize={true}
                                    // validate={formValidate}
                                    validateOnBlur={false}
                                    validateOnChange={false}
                                    onSubmit={formSubmit2}
                                >
                                    {formProps => {
                                        // searchSectionForm.current = formProps
                                        return <Form id="searchSection2">
                                            <props.Col sm="6" md="8" xs="12">
                                                <props.Row>
                                                    <props.Col sm="4" md="4" xs="12">
                                                        <props.TextControl id="firstName" name="firstName" label="common.lbl.firstName" disabled={true} />
                                                    </props.Col>
                                                    <props.Col sm="4" md="4" xs="12">
                                                        <props.TextControl id="lastName" name="lastName" label="common.lbl.lastName" readOnly={true} disabled={true} />
                                                    </props.Col>
                                                    <props.Col id="middleNameCol" sm="4" md="4" xs="12">
                                                        <props.TextControl id="middleName" className="TEST1" name="middleName" readOnly={false} onChange={(e: any) => setMidNameProps(e.target.value)} label="changeow.lbl.midIni" />
                                                    </props.Col>
                                                </props.Row>
                                                <props.Row>
                                                    <props.Col sm="12" md="12" xs="12">
                                                        <props.TextControl id="relationship" name="relationship" label="pi.relation" readOnly={true} disabled={true}></props.TextControl>
                                                    </props.Col>
                                                </props.Row>
                                                <props.Row>
                                                    <props.Col id={isEmptyStr(config.clientInfo.ssn) === true ? 'govtIDCol' : ''} sm="12" md="12" xs="12">
                                                        <props.AutoFormatControl id='govtID' formatType="SSN" valueWithFormat={true} name="govtID" label="common.lbl.govID" required={false} disabled={isEmptyStr(config.clientInfo.ssn) === true ? false : true} readOnly={false}></props.AutoFormatControl>
                                                    </props.Col>
                                                </props.Row>
                                                {/* 4 fields need to bind value, firstName, LastName, middle name and Gender */}
                                            </props.Col>
                                            <props.Col sm="6" md="4" xs="12">
                                                <props.TextControl id="dateOfBirth" name="dateOfBirth" label="common.lbl.dob" readOnly={true} disabled={true}></props.TextControl>
                                            </props.Col>
                                            <props.Col id="genderCol" sm="6" md="4" xs="12">
                                                <props.SelectControl id="gender" name="gender" label="pi.gender" options={genderOption} onChange={(e: any) => { setGenderProps(e.target.value) }}></props.SelectControl>
                                            </props.Col>
                                        </Form>
                                    }}
                                </Formik>
                            </props.Row>
                        }
                        {(config.clientInfo.firstName === undefined && config.clientInfo.lastName === undefined && config.clientInfo.birthDate === undefined) &&
                            <props.Row>
                                <props.Col sm="6" md="6" xs="12">
                                    <props.DL>
                                        <props.DLI title="common.lbl.name" desc={() => {
                                            return <>
                                                {config.clientInfo.name}
                                            </>
                                        }}>
                                        </props.DLI>
                                        <props.DLI title="pi.relation" desc={() => {
                                            return <>
                                                {config.clientInfo.relationship}
                                            </>
                                        }}>
                                        </props.DLI>
                                        <props.DLI title="common.lbl.govID" desc={maskGovtID}>
                                        </props.DLI>
                                    </props.DL>
                                </props.Col>
                            </props.Row>
                        }
                    </props.Col>
                    <props.Col sm="12" md="3" xs="12">
                        <props.Div id="maxSrcHelpBanner" className="maxHide">
                            <HelpBanner {...props}></HelpBanner>
                        </props.Div>
                    </props.Col>
                </props.Row>
                <br />
                <PersonalContactInfo {...props} changeType={props.changeType} roleTypeCode={roleTypeCode} personalInfo={config.clientInfo} states={config.states} contactMethods={config.contactMethods} middleName={middleName} govtID={govtID} gender={gender} companyCode={companyCodePre} policyNumber={policyNumberPre} nameType={nameType} fromPage={props.fromPage} clientNumberFromInsurance={props.clientNumberFromInsurance} updateMode={true} />
            </>
        }
        {/* Exist Client */}
        {
            (serachSection === false && isNewClient === false) && <>
                <props.Row>
                    <props.Col id="WarnMessage" sm="12" md="9" xs="12">
                        {props.changeType === 1 && <>
                            <props.Span className="glyphicon glyphicon-exclamation-sign warnInfo"></props.Span>{' '}
                            <props.Span className="warnInfo">
                                <GlobalizedText message="changeow.msg.info.clientMatched" />
                            </props.Span>
                        </>}
                        {props.changeType === 2 && <>
                            <props.Span className="glyphicon glyphicon-exclamation-sign warnInfo"></props.Span>{' '}
                            <props.Span className="warnInfo">
                                <GlobalizedText message="addbene.msg.info.clientFound" />
                            </props.Span>
                        </>}
                        {props.changeType === 3 && <>
                            <props.Span className="glyphicon glyphicon-exclamation-sign warnInfo"></props.Span>{' '}
                            <props.Span className="warnInfo">
                                <GlobalizedText message="addsecaddr.msg.info.change.clientFound" />
                            </props.Span>
                        </>}
                        {props.changeType === 4 && <>
                            <props.Span className="glyphicon glyphicon-exclamation-sign warnInfo"></props.Span>{' '}
                            <props.Span className="warnInfo">
                                <GlobalizedText message="addsecaddr.msg.info.clientFound" />
                            </props.Span>
                        </>}
                        <props.Row>
                            <props.Col sm="6" md="9" xs="12">
                                <props.DL>
                                    <props.DLI title="common.lbl.name" desc={() => {
                                        if (config.clientInfo.firstName === undefined && config.clientInfo.lastName === undefined) {
                                            return <>{config.clientInfo.name}</>
                                        } else {
                                            return <>
                                                {config.clientInfo.firstName} {config.clientInfo.midName} {config.clientInfo.lastName}
                                            </>
                                        }
                                    }}>
                                    </props.DLI>
                                    <props.DLI title="pi.relation" desc={() => {
                                        return <>
                                            {config.clientInfo.relationship}
                                        </>
                                    }}>
                                    </props.DLI>
                                    <props.DLI title="common.lbl.govID" desc={maskGovtID}>
                                    </props.DLI>
                                </props.DL>
                            </props.Col>
                            {(config.clientInfo.firstName !== undefined && config.clientInfo.lastName !== undefined && config.clientInfo.birthDate !== undefined) && <props.Col sm="6" md="3" xs="12">
                                <props.DL>
                                    <props.DLI title="common.lbl.dob" desc={() => {
                                        return <>
                                            {convertDate(config.clientInfo.birthDate, "yyyy-MM-dd", props.getDateFormat())}
                                        </>
                                    }}>
                                    </props.DLI>
                                    {isEmptyStr(config.clientInfo.gender) && isEmptyStr(config.clientInfo.genderCode) &&
                                        <props.SelectControl id="gender" name="gender" label="pi.gender" options={genderOption} onChange={(e: any) => { setGenderProps(e.target.value) }}></props.SelectControl>
                                    }
                                    {!isEmptyStr(config.clientInfo.gender) && !isEmptyStr(config.clientInfo.genderCode) &&
                                        <props.DLI title="pi.gender" desc={() => {
                                            return <>
                                                {config.clientInfo.gender}
                                            </>
                                        }}>
                                        </props.DLI>
                                    }
                                </props.DL>
                            </props.Col>
                            }
                        </props.Row>
                    </props.Col>
                    <props.Col sm="12" md="3" xs="12">
                        <props.Div id="maxSrcHelpBanner" className="maxHide">
                            <HelpBanner {...props}></HelpBanner>
                        </props.Div>
                    </props.Col>
                </props.Row>
                <br />
                <PersonalContactInfo {...props} changeType={props.changeType} roleTypeCode={roleTypeCode} personalInfo={config.clientInfo} states={config.states} contactMethods={config.contactMethods} companyCode={companyCodePre} policyNumber={policyNumberPre} nameType={nameType} gender={gender} fromPage={props.fromPage} clientNumberFromInsurance={props.clientNumberFromInsurance} updateMode={true} />
            </>
        }
    </props.Div>
};