import React, { useEffect, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { ajax, getContextPath, isEmptyObject } from '../shared/utils';
import GlobalizedText from '../shared/globalization';
import './css/productionreport.css'


export const ProductionReportsComponent = withView((props: ViewComponentProps) => {

    const companyCode: string = props.getParam("companyCode");
    const agentNumber: string = props.getParam("agentNumber");
    const policyNumber: string = props.getParam("policyNumber");
    //const directReports: string = props.getParam("directReports");
    const fromPage: string = props.getParam("fromPage");
    const thisPage: string = "productionReport";
    const fromAgentSearch: boolean = fromPage === "from_agentsearch" ? true : false;
    const reportKey: string = "cogrep_006";

    const [config, setConfig] = useState<any>();
    const [isDir, setIsDir] = useState<boolean>(false);

    let params = {};
    if (isEmptyObject(policyNumber)) {
        params = {
            fromAgentSearch: fromAgentSearch,
            companyCode: companyCode,
            agentNumber: agentNumber,
            reportCategory: "prod"
        }
    } else {
        params = {
            fromAgentSearch: fromAgentSearch,
            companyCode: companyCode,
            agentNumber: agentNumber,
            policyNumber: policyNumber,
            reportCategory: "prod"
        }
    }


    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/cognosReport/view',
                params: params,
                success: (res: any) => {
                    setConfig(res);
                }
            });
        }
    }, [config])

    if (config === undefined) {
        return <></>;
    }

    if (!isEmptyObject(config?.agentName)) {
        props.setTitle(props.getGlobalizedText("common.lbl.productionReports") + " - " + config.agentName);
    }
    let reports: Array<any> = [];
    if (!isEmptyObject(config.reports?.cogrep_006)) { reports.push({ "reportKey": "cogrep_006", ...config.reports?.cogrep_006 }); }

    return <>
        <props.Row>
            <props.Col xs="12" sm="12" md="12">
                <ul className="nav nav-tabs" role="tablist">
                    <li id="COMR_myRep" role="presentation" className="active" >
                        <a href="#myL" onClick={() => { setIsDir(false) }} aria-controls="myL" role="tab" data-toggle="tab" ><GlobalizedText message="common.lbl.myReports" /></a>
                    </li>
                    <props.Condition condition={config?.showDirectReports}>
                        <li id="COMR_DirRep" role="presentation">
                            <a href="#myL" onClick={() => { setIsDir(true) }} aria-controls="myL" role="tab" data-toggle="tab"><GlobalizedText message="common.lbl.directReports" /></a>
                        </li>
                    </props.Condition>
                </ul>
            </props.Col>

            {reports?.map((row: any, index: number) => {
                return <>
                    <props.Col xs="12" sm="6" md="4">
                        <props.Panel className="noBorder noBackground">
                            <props.PanelHeading className="noBorder noBackground fontBlue"><a href="#" onClick={() => props.next(`/cognosReport?fromAgentSearch=${fromAgentSearch}&companyCode=${config.companyCode}&agentNumber=${config.agentNumber}&policyNumber=${config.policyNumber}&reportCategory=prod&reportKey=${reportKey}&directReports=${isDir}&from=${thisPage}`)} className="gwp-page-title"><GlobalizedText message={row.messageID} /></a></props.PanelHeading>
                            <props.PanelBody>
                                <a href="#" onClick={() => props.next(`/cognosReport?fromAgentSearch=${fromAgentSearch}&companyCode=${config.companyCode}&agentNumber=${config.agentNumber}&policyNumber=${config.policyNumber}&reportCategory=prod&reportKey=${reportKey}&directReports=${isDir}&from=${thisPage}`)} className="gwp-page-title"><img width='100%' src={getContextPath() + row.imgUrl} /></a>
                            </props.PanelBody>
                            <props.PanelFooter className="noBorder noBackground">{row.description}</props.PanelFooter>
                        </props.Panel>
                    </props.Col>
                </>
            })}
        </props.Row>
    </>
});
