import React, { useEffect, useState, useRef } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { Formik, Form } from 'formik';
import { ajax, isMobileDevice, validate, getFromStorage, removeFromStorage } from '../shared/utils';
import './css/directdeposit.css';
import * as yup from 'yup';
import { Message } from "../shared/message";
import { HelpBanner } from '../shared/helpbanner';
import { YupSchema } from '../shared/yupschema';
import GlobalizedText from '../shared/globalization';

export const DirectDepositComponent = withView((props: ViewComponentProps) => {
    const [agents, setAgents] = useState<Array<any>>([{}]);
    const [config, setConfig] = useState<any | null>(null);
    const [agentFilter, setAgentFilter] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [bankName, setBankName] = useState<string>('');
    const [companyCode, setCompanyCode] = useState<string>('');
    const [agentCompanyCode, setAgentCompanyCode] = useState<string>('');
    const [agentNumber, setAgentNumber] = useState<string>('');
    const [initAgentNumber, setInitAgentNumber] = useState<string>('');
    const [clientNumber, setClientNumber] = useState<string>('');
    const [accountNumber, setAccountNumber] = useState<string>('');
    const [maskAccountNumber, setMaskAccountNumber] = useState<string>('');
    const [accountType, setAccountType] = useState<string>('');
    const [routingNumber, setRoutingNumber] = useState<string>('');
    const [handlerFlag, setHandlerFlag] = useState<string | null>();
    const [personalInfoFlag, setPersonalInfoFlag] = useState<string>('');
    const [companyCode4PersonalInfo, setCompanyCode4PersonalInfo] = useState<string>('');
    const [agentNumber4PersonalInfo, setAgentNumber4PersonalInfo] = useState<string>('');
    const [fromPage, setFromPage] = useState<string>("");
    let closeFromAgentInfo = getFromStorage('closeFromAgentInfo', true)

    const defaultInitialValues = {
        agentFilter: "",
        bankName: "",
        accountNumber: "",
        accountType: "",
        routingNumber: "",
        fullName: "",
        address: "",
        companyCode: "",
        isEdit: false,
        hasDDR: false,
        firstOpen: false,
        oneAgent: false,
        updateAllowed: false,
        addAllowed: false,
    };
    const varFormPromp = useRef<any>();
    useEffect(() => {
        if (config === null) {
            initialPage()
        }
    }, [config]);

    function initialPage() {
        let initParams = props.getInitParams();
        ajax({
            url: '/api/directDeposit/view',
            params: {
                fromPage: props.getQueryParam('fromPage'),
                companyCode: props.getQueryParam('companyCode'),
                agentNumber: props.getQueryParam('agentNumber')
            },
            success: (res: any) => {
                setAgents(res.agents);
                setFromPage(res.fromPage);
                setConfig(res);
                setCompanyCode(res.companyCode);
                setClientNumber(res.clientNumber);
                setInitAgentNumber(res.agentNumber);
                setAgentCompanyCode(res.agentCompanyCode);
                populateAccountType(res.accountType);
                varFormPromp.current.setFieldValue("fullName", res.fullName);
                varFormPromp.current.setFieldValue("addAllowed", res.addAllowed === undefined ? true : res.addAllowed);
                varFormPromp.current.setFieldValue("updateAllowed", res.updateAllowed === undefined ? true : res.updateAllowed);
                varFormPromp.current.setFieldValue("isEdit", false);
                varFormPromp.current.setFieldValue("hasDDR", false);
                varFormPromp.current.setFieldValue("firstOpen", true);
                varFormPromp.current.setFieldValue("companyCode", res.companyCode);
                if (res.oneAgent === true) {
                    if (res.fromPage === "from_agentsearch") {
                        props.setTitle("Direct Deposit - " + res.fullName);
                        setPersonalInfoFlag('from_agentsearch');
                    }
                    retrieve(res.agents[1].value);
                    varFormPromp.current.setFieldValue("oneAgent", true);
                }
                if (res.oneAgent === false && closeFromAgentInfo !== null) {
                    if (initParams !== undefined && 'directDeposit' === initParams.storedPage) {
                        setCompanyCode(initParams.agentFilter.split("-")[1])
                        setAgentNumber(initParams.agentFilter.split("-")[0])
                        varFormPromp.current.setFieldValue("agentFilter", initParams.agentFilter);
                        retrieve(initParams.agentFilter);
                    } else {
                        setCompanyCode(closeFromAgentInfo[1]);
                        setAgentNumber(closeFromAgentInfo[0])
                        let agentValue = closeFromAgentInfo[0] + "-" + closeFromAgentInfo[1];
                        varFormPromp.current.setFieldValue("agentFilter", agentValue);
                        retrieve(closeFromAgentInfo);
                    }
                    removeFromStorage("closeFromAgentInfo", true);
                }

            }
        });

    }
    // save data for current page
    function saveDataBeforeRedirect() {
        props.setInitParams({
            storedPage: 'directDeposit',
            agentFilter: varFormPromp.current.values.agentFilter
        })
    }
    const byAddUpdateSchema = yup.object().shape({
        bankName: YupSchema.directDeposit.bankName,
        routingNumber: YupSchema.directDeposit.routingNumber,
        accountNumber: YupSchema.directDeposit.accountNumber
    });
    const byRetriveSchema = yup.object().shape({
        agentFilter: YupSchema.directDeposit.agentFilter
    });
    function retrieve(values: any, setSubmitting?: Function) {
        varFormPromp.current.setFieldValue("isEdit", false);
        let compCode = companyCode;
        let agentNum = agentNumber;
        let from = "";
        if (typeof (values) == 'string') {//PA/CSA user will retrieve DDR once open page
            let agentAndCom = values.split("-");
            agentNum = agentAndCom[0];
            compCode = agentAndCom[1];
            from = "from_agentsearch";
        } else if (agentFilter != undefined && agentFilter != "") {//AGENT user will retrive DDR only if retrive button is clicked
            for (let i = 0; i < agents.length; i++) {
                if (agents[i].name == agentFilter) {
                    let agentValue = agents[i].value.split("-");
                    agentNum = agentValue[0];
                    compCode = agentValue[1];
                } else if (agents[i].value == agentFilter) {
                    let agentValue = agents[i].value.split("-");
                    agentNum = agentValue[0];
                    compCode = agentValue[1];
                }
            }
        } else if (values.agentFilter != "") {
            if (values.length == 2) {
                agentNum = values[0];
                compCode = values[1];
            } else {
                for (let i = 0; i < agents.length; i++) {
                    if (agents[i].name == values.agentFilter) {
                        let agentValue = agents[i].value.split("-");
                        agentNum = agentValue[0];
                        compCode = agentValue[1];
                    } else if (agents[i].value == values.agentFilter) {
                        let agentValue = agents[i].value.split("-");
                        agentNum = agentValue[0];
                        compCode = agentValue[1];
                    }
                }
            }
        }
        setAgentNumber(agentNum);
        // setAgentFilter()
        ajax({
            url: '/api/directDeposit/fetchDirectDeposit/',
            params: {
                companyCode: compCode,
                agentNumber: agentNum,
                fromPage: from
            },
            success: (res: any) => {
                varFormPromp.current.setFieldValue("bankName", res.bankName);
                varFormPromp.current.setFieldValue("accountNumber", res.accountNumber);
                varFormPromp.current.setFieldValue("accountType", res.accountType);
                varFormPromp.current.setFieldValue("routingNumber", res.routingNumber);
                setAddress(res.address);
                setBankName(res.bankName);
                setAccountNumber(res.accountNumber);
                setMaskAccountNumber(res.maskAccountNumber);
                populateAccountType(res.accountType);
                setRoutingNumber(res.routingNumber);
                if (res.bankName != "") {
                    varFormPromp.current.setFieldValue("hasDDR", true);
                } else {
                    varFormPromp.current.setFieldValue("hasDDR", false);
                }
                if (varFormPromp.current.values.agentFilter == "") {
                    varFormPromp.current.setFieldValue("firstOpen", true);
                } else {
                    varFormPromp.current.setFieldValue("firstOpen", false);
                }
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }
    function populateAccountType(type: any) {
        if (type === "S" || type === "saving" || type === "Saving") {
            setAccountType("Saving");
            varFormPromp.current.setFieldValue("accountType", "saving");
        }
        else if (type === "C" || type === "checking" || type === "Checking") {
            setAccountType("Checking");
            varFormPromp.current.setFieldValue("accountType", "checking");
        }
    }
    function addUpdate(values: any, setSubmitting?: Function) {
        props.clearMessage();
        let compCode = companyCode;
        let agentNum = agentNumber;
        let from = fromPage;

        if (agentFilter != "") {
            for (let i = 0; i < agents.length; i++) {
                if (agents[i].name == agentFilter) {
                    let agentValue = agents[i].value.split("-");
                    agentNum = agentValue[0];
                    compCode = agentValue[1];
                } else if (agents[i].value == agentFilter) {
                    let agentValue = agents[i].value.split("-");
                    agentNum = agentValue[0];
                    compCode = agentValue[1];
                }
            }
        } else if (values.agentFilter != "") {
            for (let i = 0; i < agents.length; i++) {
                if (agents[i].name == values.agentFilter) {
                    let agentValue = agents[i].value.split("-");
                    agentNum = agentValue[0];
                    compCode = agentValue[1];
                } else if (agents[i].value == values.agentFilter) {
                    let agentValue = agents[i].value.split("-");
                    agentNum = agentValue[0];
                    compCode = agentValue[1];
                }
            }
        }
        let accountNumber = values.accountNumber;
        let routingNumber = values.routingNumber;
        let bankName = values.bankName;
        let fullName = values.fullName;
        ajax({
            url: `/api/directDeposit/${values.hasDDR === true ? 'update' : 'add'}`,
            method: 'post',
            data: {
                companyCode: compCode,
                agentNumber: agentNum,
                bankName: bankName,
                accountNumber: accountNumber,
                accountType: values.accountType === undefined ? accountType : values.accountType,
                routingNumber: routingNumber,
                fullName: fullName,
                fromPage: from
            },
            success: (res: any) => {
                varFormPromp.current.setFieldValue("bankName", res.bankName);
                varFormPromp.current.setFieldValue("accountNumber", res.accountNumber);
                varFormPromp.current.setFieldValue("accountType", res.accountType === undefined ? accountType : res.accountType);
                varFormPromp.current.setFieldValue("routingNumber", res.routingNumber);
                setBankName(res.bankName);
                setAccountNumber(res.accountNumber);
                setMaskAccountNumber(res.maskAccountNumber);
                populateAccountType(res.accountType);
                setRoutingNumber(res.routingNumber);
                if (!varFormPromp.current.values.hasDDR) {
                    props.showMessage("success", "diredep.addsuccess");
                } else {
                    let messageString = props.getGlobalizedText("diredep.updatedinfo") + '</br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + props.getGlobalizedText("diredep.updatedhint");
                    props.showMessage("success", messageString);
                }
                varFormPromp.current.setFieldValue("isEdit", false);
                varFormPromp.current.setFieldValue("hasDDR", true);
            },
            fail: (res: any) => {
                varFormPromp.current.setFieldValue("firstOpen", true);
                props.showMessage("error", res.message);
            },
            callback: () => {
            }
        });
    }

    const onEdit = (formPromp: any): void => {
        props.clearMessage();
        props.resetForm(formPromp, formPromp.values);
        varFormPromp.current.setFieldValue("isEdit", true);
        varFormPromp.current.setFieldValue("accountNumber", accountNumber);
        populateAccountType(formPromp.values.accountType);
    }

    const onAdd = (formPromp: any): void => {
        props.clearMessage();
        props.resetForm(formPromp, formPromp.values);
        setAccountType("Checking");
        varFormPromp.current.setFieldValue("accountNumber", "");
        varFormPromp.current.setFieldValue("isEdit", true);
        varFormPromp.current.setFieldValue("accountType", "Checking");
    }

    function closeEdit() {
        varFormPromp.current.setFieldValue("isEdit", false);
        if (varFormPromp.current.values.bankName != "") {
            varFormPromp.current.setFieldValue("hasDDR", true);
        } else {
            varFormPromp.current.setFieldValue("hasDDR", false);
        }
        if (varFormPromp.current.values.agentFilter !== '') {
            retrieve(varFormPromp.current.values);
            saveData4PersonalInfo();
        } else {
            if (fromPage !== 'from_agentsearch') {
                clearAllFormValuesWhenSelectNoAgent();
                retrieve(varFormPromp.current.values);
            }
        }
        props.clearMessage();

    }

    function getSelectedAgentInfo(flag: any): any {
        let selectedAgent = varFormPromp.current.values.agentFilter;
        let agentAndCom = selectedAgent.split("-");
        if (flag === 'agentNumber') {
            return agentAndCom[0];
        } else {
            return agentAndCom[1];
        }
    }

    // define Form Submitting function.
    const formSubmit = (values: any): void => {
        props.clearMessage();
        if (handlerFlag === 'addUpdate') {
            addUpdate(values);
        } else if (handlerFlag === 'retrieve') {
            retrieve(values);
        }
    }

    function saveData4PersonalInfo() {
        setPersonalInfoFlag('retrieve');
        setCompanyCode4PersonalInfo(getSelectedAgentInfo('companyCode'));
        setAgentNumber4PersonalInfo(getSelectedAgentInfo('agentNumber'));
    }

    function clearAllFormValuesWhenSelectNoAgent() {
        setPersonalInfoFlag('')
        if (!config.oneAgent) {
            varFormPromp.current.setFieldValue("bankName", '');
            varFormPromp.current.setFieldValue("accountNumber", '');
            varFormPromp.current.setFieldValue("accountType", '');
            varFormPromp.current.setFieldValue("routingNumber", '');
            setAddress('')
            setBankName('')
            setMaskAccountNumber('')
            setAccountType('')
            setRoutingNumber('')
        }
    }

    function clearAllInfoWhenSelectNoAgent() {
        initialPage()
        clearAllFormValuesWhenSelectNoAgent()
    }

    return <div id="directdeposit">
        <React.Fragment>
            <Formik initialValues={defaultInitialValues}
                validate={(values) => {
                    if (handlerFlag === 'addUpdate') {
                        return validate(byAddUpdateSchema, values, props);
                    } else if (handlerFlag === 'retrieve') {
                        return validate(byRetriveSchema, values, props);
                    }
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={formSubmit}
            >
                {formProps => {
                    varFormPromp.current = formProps;
                    return <Form>
                        {formProps.values.oneAgent && <>
                            <props.HR />
                        </>}
                        {!formProps.values.oneAgent && <>
                            <props.Row>
                                <props.Col sm="7">

                                    <props.SelectControl containerClassName="gwp-inline" sort={false} label="common.lbl.agent" name="agentFilter"
                                        options={(config === null || config.agents === null) ? [{ name: "common.lbl.pleaseselect", value: "" }] : agents} onChange={(e) => {
                                            if (e.target.value === '') {
                                                clearAllInfoWhenSelectNoAgent()
                                            }
                                        }}>
                                    </props.SelectControl>
                                    <props.Button className="retrieveBtn" type="submit" onClick={() => { setHandlerFlag("retrieve"); saveData4PersonalInfo(); }}><GlobalizedText message="common.lbl.retrieve" /></props.Button>

                                </props.Col>
                                <props.Col sm="5" xs="12">
                                    <HelpBanner className="helpBanner" {...props}></HelpBanner>
                                </props.Col>
                            </props.Row>
                            <props.HR />
                        </>}
                        <props.Row >
                            <props.Col xs="12" sm="7" md="7">
                                <div className="fullNameAndAddr">
                                    <div className="mi-list-row-title" >
                                        {personalInfoFlag === 'retrieve' &&
                                            <props.Link to={`/agentPersonalInformation?personalInfoType=3&companyCode=${companyCode4PersonalInfo}&clientNumber=${clientNumber}&agentNumber=${agentNumber4PersonalInfo}&fromPage=from_directdeposit`} onClick={() => {
                                                saveDataBeforeRedirect()
                                            }} >{formProps.values.fullName}</props.Link>
                                        }
                                        {personalInfoFlag === '' &&
                                            <props.Link to={`/agentPersonalInformation?personalInfoType=3`} >{formProps.values.fullName}</props.Link>
                                        }
                                        {personalInfoFlag === 'from_agentsearch' &&
                                            <props.Link to={`/agentPersonalInformation?personalInfoType=3&companyCode=${props.getQueryParam('companyCode')}&clientNumber=${clientNumber}&agentNumber=${props.getQueryParam('agentNumber')}&fromPage=from_directdeposit`} >{formProps.values.fullName}</props.Link>
                                        }
                                    </div>
                                    <div className="address" dangerouslySetInnerHTML={{ __html: address }} />
                                </div>
                            </props.Col>
                            {formProps.values.oneAgent && !isMobileDevice() && <>
                                <props.Col className="helpBanner-oneagent">
                                    <HelpBanner  {...props}></HelpBanner>
                                </props.Col>
                            </>}
                            {formProps.values.oneAgent && isMobileDevice() && <>
                                <props.Col sm="4">
                                    <HelpBanner {...props}></HelpBanner>
                                </props.Col>
                            </>}
                        </props.Row>
                        <props.Row>
                            <Message messageService={props.messageService}></Message>

                            <props.Col xs="12" sm="12" md="12">
                                {
                                    (!(formProps.values.firstOpen && !formProps.values.oneAgent) && !formProps.values.hasDDR) && <>
                                        <props.Information message={props.getGlobalizedText("diredep.info")}>
                                        </props.Information>
                                    </>
                                }
                            </props.Col>
                        </props.Row>
                        <props.Row>
                            <props.Col xs="12">
                                <props.InnerTitle>
                                    <GlobalizedText message="common.lbl.accountInfo" />
                                    {((!formProps.values.firstOpen || formProps.values.oneAgent) && !formProps.values.isEdit && formProps.values.hasDDR && formProps.values.updateAllowed) && <a onClick={() => { onEdit(formProps) }} >   <props.Icon type='update' label='common.lbl.update' />
                                    </a>}
                                    {(((!formProps.values.firstOpen && !formProps.values.isEdit) || formProps.values.oneAgent) && !formProps.values.hasDDR && formProps.values.addAllowed) && <a onClick={() => { onAdd(formProps) }} >   <props.Icon type='update' label='common.lbl.add' />
                                    </a>}
                                    {(!formProps.values.oneAgent && formProps.values.firstOpen) && <a onClick={() => { onAdd(formProps) }} >   <props.Icon type='update' />
                                    </a>}
                                    {/* {(formProps.values.oneAgent && !formProps.values.hasDDR && formProps.values.addAllowed) && <a onClick={() => { onAdd(formProps) }} >   <props.Icon type='update' label='common.lbl.update' />
                                    </a>} */}
                                </props.InnerTitle>
                            </props.Col>
                        </props.Row>
                        <props.Row>
                            {(!formProps.values.isEdit &&
                                ((formProps.values.firstOpen && !formProps.values.oneAgent) || formProps.values.hasDDR)) && <>
                                    <props.Col xs="12" sm="6" md="6">
                                        <props.DL>
                                            <props.DLI titleClass="gwp-label-ro" title="common.lbl.bankName" desc={bankName === null ? "" : bankName}></props.DLI>
                                        </props.DL>
                                        <props.DL>
                                            <props.DLI titleClass="gwp-label-ro" title="payment.lbl.accountNumber" desc={(maskAccountNumber === null || maskAccountNumber === undefined) ? "" : maskAccountNumber}></props.DLI>
                                        </props.DL>
                                    </props.Col>
                                    <props.Col xs="12" sm="6" md="6">
                                        <props.DL>
                                            <props.DLI titleClass="gwp-label-ro" title="common.lbl.routingNumber" desc={routingNumber === null ? "" : routingNumber}></props.DLI>
                                        </props.DL>
                                        <props.DL>
                                            <props.DLI titleClass="gwp-label-ro" title="payment.lbl.accountType" desc={accountType === null ? "" : accountType}></props.DLI>
                                        </props.DL>
                                    </props.Col>
                                </>
                            }
                            {formProps.values.isEdit && <>
                                <props.Col sm='6'>
                                    <props.DL className="dl-horizontal">
                                        <props.TextControl id="bankName" name="bankName" required={true} label="common.lbl.bankName" ></props.TextControl>
                                        <props.AutoFormatControl id="accountNumber" name="accountNumber" showFormat={false} formatType={"ACCT"} required={true} label="payment.lbl.accountNumber"></props.AutoFormatControl>
                                    </props.DL>
                                </props.Col>
                                <props.Col sm='6'>
                                    <props.DL className="dl-horizontal">
                                        <props.TextControl id="routingNumber" name="routingNumber" required={true} label="common.lbl.routingNumber" ></props.TextControl>
                                        <props.SelectControl id="accountType" name="accountType" required={true} label="payment.lbl.accountType" options={config.accountTypes}>
                                        </props.SelectControl>
                                    </props.DL>
                                </props.Col>
                            </>
                            }
                        </props.Row>
                        <props.Row>
                            {formProps.values.isEdit && <>
                                <props.Col sm='6'>
                                    <props.Button type="submit" onClick={() => { setHandlerFlag("addUpdate") }}><GlobalizedText message="common.button.submit" /></props.Button>
                                    <props.Button onClick={() => { closeEdit() }} ><GlobalizedText message="common.lbl.cancel" /></props.Button>
                                </props.Col>
                            </>
                            }
                        </props.Row>
                        <div id="norecord" className="no-record">
                            {!formProps.values.hasDDR && !formProps.values.isEdit && !(formProps.values.firstOpen && !formProps.values.oneAgent) && <>
                                <GlobalizedText message="diredep.norecord" />
                            </>
                            }
                        </div>
                    </Form>
                }
                }
            </Formik>
        </React.Fragment>
    </div >
})