import { ComponentConfig } from '../../../config/route-config';
import {
    WelcomeComponent
} from "../../../pages/welcome";
export const authenticated: ComponentConfig = {
};
export const unauthenticated: ComponentConfig = {
};
export const all: ComponentConfig = {
};
