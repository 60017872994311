import React, { useState, useEffect, useMemo } from 'react';
import { HelpBannerObject } from '../pages/helpbannereditor';
import { ajax, $, isMobileDevice } from './utils';
import { ViewComponentProps } from './viewcomponent';

export interface HelpBannerProps extends ViewComponentProps {
    className?: string;
}

export const HelpBanner = (props: HelpBannerProps) => {
    const [content, setContent] = useState<HelpBannerObject>();
    useEffect(() => {
        if (content === undefined) {
            ajax({
                url: '/api/cms/helpbanner/view',
                success: (res: any) => {
                    setContent(res);
                },
                showMask: false
            });
        }
    });
    useEffect(() => {
        if (content !== undefined) {
            if (!isMobileDevice()) {
                if ($("#helpBannerContainer a[href^='tel']").length > 0) {
                    $("#helpBannerContainer a[href^='tel']").each(function (i: number, el: any) {
                        $(el).parent().html($(el).text());
                    });
                }
            }
        }
    }, [content])
    const comp = useMemo(() => {
        if (content === undefined) {
            return <props.Div></props.Div>
        }
        return <props.Div id="helpBannerContainer"><props.ContentViewer className={props.className} html={content?.content} ></props.ContentViewer></props.Div>
    }, [content]);
    return <>{comp}</>
}