import React, { useContext, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GlobalizedText, { GlobalizationContext } from '../../globalization';
import { $, formatNumber, getContextPath, insertIntoArray, replaceAll, win } from '../../utils';
import { Button } from './button';
import { Div } from './layout';
import { ConditionProps, withCondition } from './shared-component';
export const INIT_PARAM_PREFIX_TABLE = "table_alltables";
export const KEY_NEED_RELOAD = "reload";
const KEY_PAGE = "page";
const KEY_ORDER = "order";
const DEFAULT_COLUMNS_SHOWS = 2;
export interface TableOrder {
    by: "asc" | "desc";
}
export interface TableLinkEvent {
    selector?: string,
    name: string,
    event: (e: any) => void,
    paramNames?: Array<string>,
    params?: any,
}
export interface TableLink {
    isPathParam: boolean;
    isQueryParam: boolean;
    params: Array<string>;
    url?: string;
    event?: TableLinkEvent;
}
export interface TableColumn {

    /**
     * Table header, support globalized text.
     */
    title?: string;

    /**
     * Indicate which field of the data should be rendered.
     */
    name?: string;

    /**
     * Indicate the column is sortable or not, default is true
     */
    sortable?: boolean;

    /**
     * class that need to be applied to the column
     */
    className?: string;

    /**
     * Content type of the column
     */
    type?: 'date' | 'text' | 'numeric' | 'progressBar';

    /**
     * If type is numeric, format option that be used by auto format.
     */
    numberFormatOptions?: Intl.NumberFormatOptions;

    /**
     * Order by definition
     */
    order?: TableOrder;

    /**
     * Render the column as a link.
     */
    link?: TableLink;
    /**
     * Width of the column, in most case, this values should be a percentage, like 8%
     */
    width?: string;
    /**
     * To render the cell with string returned by the method, can be html, do not support React Component in render.
     * @argument data data of the cell
     * @argument type type of the column
     * @argument row data of the row
     * @argument meta meta of the column
     */
    render?: (data: any, type: any, row: any, meta: any) => string;
    /**
     * onClick MUST be used along with render attribute, be sure that the element that need to bind an onClick event must be the first child of the cell.
     * for exmaple : render:(data: any, type: any, row: any, meta: any) => {return <a>click me!</a>}, onClick:(e:any,row:any)=>{alert(row)}, click would be bind to the <a> element.
     * @argument e EventObject
     * @argument row row data
     */
    onClick?: (e: any, row: any) => void;

    /**
     * CSS selector of the target of the click event fired on.
     */
    eventTarget?: (row: any, rowNumber: number) => string;

    /**
     * onChange MUST be used along with render attribute, be sure that the element that need to bind an onChange event must be the first child of the cell.
     * for exmaple : render:(data: any, type: any, row: any, meta: any) => {return <input type="text"/>}, onChange:(e:any,row:any)=>{alert(row)}, change would be bind to the <a> element.
     * @argument e EventObject
     * @argument row row data
     */
    onChange?: (e: any, row: any) => void;


}
export interface TableConfig {
    /**
     * Table column defination
     */
    columns: TableColumn[];

    /**
     * Table data
     */
    data?: Array<any>;
}
export interface TableProps extends ConditionProps {
    id: string;
    next?: (url: string) => void;
    table?: TableConfig;
    dateformat?: string;
    children?: any;
    option?: any;
    delayInit?: boolean;
    delayAdjust?: boolean;
    modal?: string;
    showChild?: (row: any) => string;
    afterShowChild?: (row: any) => void;
    onResponsiveDisplay?: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element) => void;
    autoAdjustment?: boolean;
    select?: SelectOption;
    stateSave?: boolean;
    defaultFilter?: { col: number, regStr: string | Array<string>, isRegExp: boolean, isSmart: boolean };
    setInitParams?: (value: any) => void;
    getInitParams?: () => any;
    /**
     * The function will be executed after database rendered.
     */
    onRendered?: () => void;
    /**
     * Defines how many child rows would be rendered in this table.
     */
    childRows?: number;

    /**
     * Extra class that will be applied to this table component.
     */
    className?: string;

    /**
     * indicate whether the data is loaded or not, this indicator would be used to render the empty table message.
     * if set to true, then will show No records found., if set to false, then will show an empty line.
     */
    isDataLoaded?: boolean;

    /**
     * The numbers of columns from left to right that will always be displayed.
     * 
     */
    columnsShows?: number;
}

export interface TableCell {
    title: string;
    data: string;
    hidden: boolean;
    node: Element;
    columnIndex: number;
}

export interface SelectOption {
    /**
     * Indicate that is't single select or multiple select.
     */
    type: 'single' | 'multiple';
    /**
     * Prefix checkbox|radio buttons's ID, if not provided then will generate a prefix automatically.
     * For exmaple: if the ID is test_1, test_2, then the prefix should be test_.
     */
    prefix?: string;
    /**
     * Indicate that whether the row need to be highlighted or not.
     */
    highlight?: boolean;
    /**
     * The callback function that would be triggered once one or more rows be selected.
     */
    onSelect?: (e: any, selectedRows: Array<number>, unSelectedRows: Array<number>) => void;
    /**
     * The rows that should be prevented for selecting.
     */
    disabled?: (row: any) => boolean | number[];
    /**
     * Additional Select buttons for the table.
     */
    buttons?: SelectButtons;
    /**
     * The rows that should be selected when initializing the table.
     */
    selected?: Array<number>;
    /**
     * Indicate whether the selected information should be displayed or not, default is true.
     */
    showSelectedInformation?: boolean;
}

export interface SelectButtons {
    /**
     * Container of the buttons, usually should be a DIV, this is used for displaying or hiding the buttons automatically
     */
    container: HTMLElement | null,
    /**
     * Select all button
     */
    selectAll: HTMLElement | null,
    /**
     * Deselect all button
     */
    deSelectAll: HTMLElement | null,
    showAlways: boolean // if false, then the button only shows when column unvisible.
}

let eventBinder: any = {};

export type PageAction = 'first' | 'next' | 'previous' | 'last';

export interface TableObject {
    /**
     * Table component
     */
    Component: () => JSX.Element,
    /**
     * @param col Column Number
     * @param regStr regex expression, only support string
     * @param isRegExp using regex expression or not
     * @param isSmart
     */
    filter: (col: number, regStr: string | Array<string>, isRegExp: boolean, isSmart: boolean) => void,
    /**
     * select all/deselect all, this function will only be working with SelectOption defined
     * @see SelectOption
     */
    selectAll: (selected: boolean) => void;

    /**
     * get current page number, or go to the specific page and return the page number.
     * if page number is defined, then will draw the specfic page.
     * if page number is undefined, then return the page number directly.
     * page number index is start from 0.
     */
    page: (pageNumber?: number | PageAction) => number;
    /**
     * return the current order of the table.
     */
    getOrder: () => Array<number>;
    /**
     * return the current ordered by.
     */
    getOrderBy: () => Array<any>;
    /**
     * Update specific cell with the given value.
     */
    updateCell: (row: number | string, col: number | string, value: string) => void;
}

/**
 * Returns 
 * @param props 
 */
export const useTable = (props: TableProps): TableObject => {
    return {
        Component: () => <Table {...props}></Table>,
        filter: (col: number, regStr: string | Array<string>, isRegExp: boolean, isSmart: boolean) => {
            win[`dt_${props.id}`].filterTable(col, regStr, isRegExp, isSmart);
        },
        selectAll: (selected: boolean) => { if (props.select !== undefined) { win[`dt_${props.id}`].rows(win[`dt_${props.id}`].settings()[0].aiDisplay).select(selected) } },
        page: (page?: number | PageAction) => {
            let tb = win[`dt_${props.id}`];
            if (page !== undefined) {
                tb.page(page).draw('page');
            }
            return tb.page();
        },
        getOrderBy: () => {
            return win[`dt_${props.id}`].order();
        },
        getOrder: () => {
            return win[`dt_${props.id}`].settings()[0].aiDisplay;
        },
        updateCell: (row: number | string, col: number | string, value: string) => {
            let dt = win[`dt_${props.id}`];
            dt.row(row).data()[col] = value;
            $(dt.cell(row, col).node()).html(value);
        }
    }
}

export const Table = withCondition((props: TableProps) => {
    let globalization = useContext(GlobalizationContext);
    let order: Array<any> = [];
    let reload: boolean = false;
    let columnDefs: Array<any> = [];
    let columns: Array<any> = [];
    const history = useHistory();
    let hasChildren = props.children !== undefined && props.children[0] !== undefined && props.children[0].props.children !== undefined && (props.children[0].props.children.length !== undefined || props.children[0].props.children.props.children.length > 0);
    if (props.getInitParams !== undefined) {
        let initParams = props.getInitParams();
        if (initParams !== undefined && initParams[INIT_PARAM_PREFIX_TABLE] !== undefined) {
            let t = initParams[INIT_PARAM_PREFIX_TABLE][props.id];
            if (t[KEY_NEED_RELOAD] !== undefined) {
                reload = t[KEY_NEED_RELOAD];
            }
            if (t[KEY_ORDER] !== undefined && reload === true) {
                order = t[KEY_ORDER];
            }

        }
    }
    reload = (hasChildren || (props.table?.data !== undefined && props.table?.data !== null && props.table?.data?.length > 0)) && reload;
    let next = (url: string) => history.push(getContextPath() + url);
    if (props.next) {
        next = props.next;
    }
    let pendingRegEvent: { [key: string]: TableLinkEvent } = {};

    if (props.table !== undefined) {

        let propsColumns = props.table.columns;

        if (propsColumns.length > 0 && propsColumns[0].className !== "gwp-dt-detail-control") {
            propsColumns = insertIntoArray(propsColumns, { className: 'gwp-dt-detail-control', "sortable": false, title: "<div class='hidden'>detail control</div>", width: "2%" }, 0);
        }

        if (props.select !== undefined) {
            let inputType = props.select.type === "single" ? "radio" : "checkbox";
            propsColumns = insertIntoArray(propsColumns, {
                className: 'gwp-dt-hidden-collapsed gwp-dt-select-all', "sortable": false, title: "<div class='hidden'>select all</div>", render: (data: any, type: any, row: any, meta: any) => {
                    let inputId = props.select?.prefix !== undefined ? `${props.select?.prefix}_${meta.row}` : `${props.id}_select_${meta.row}`;
                    return `<input id="${inputId}" class="gwp-dt-select-input" title="${props.id}_select" name="${props.id}_select" type="${inputType}"/>`
                }
            }, 1);
        }

        columns = propsColumns.map((o: TableColumn, i: number) => {
            let col: any = {};
            if (o.className !== undefined) {
                col['className'] = o.className;
            }
            col['bSortable'] = o.sortable !== undefined ? o.sortable : true;
            col['defaultContent'] = '';
            if (o.title !== undefined) {
                col['title'] = globalization === null || globalization[o.title] === undefined ? o.title : globalization[o.title];
            }
            if (o.name !== undefined) {
                col['data'] = o.name;
            }
            if (o.width !== undefined) {
                col.width = o.width;
            }
            if (o.order !== undefined) {
                order.push([i, o.order.by]);
            }
            if (o.title === undefined || o.title === '') {
                columnDefs.push({
                    "targets": i,
                    "render": o.render === undefined ? function (data: any, type: any, row: any, meta: any) {
                    } : (data: any, type: any, row: any, meta: any): string => {
                        let elHtml = '';
                        if (o.render === undefined) {
                            elHtml = row.data;
                        } else {
                            elHtml = o.render(data, type, row, meta);
                            let el = $(elHtml);
                            if (el.length > 0) {
                                el.attr("dt-row", meta.row);
                                el.attr("dt-col", meta.col);
                                elHtml = el[0].outerHTML;
                            }
                        }
                        return elHtml;
                    }
                });
            } else {
                let columnDef: any = {
                    targets: i,
                    render: o.render === undefined ? function (data: any, type: any, row: any, meta: any) {
                        if (data !== undefined && data.type !== undefined) {
                            if (data.type === 'LINK') {
                                return `<a dt-href='${getContextPath()}${data.url}'>${data.value}</a>`;
                            }
                            if (data.type === 'TEXT') {
                                if (o.type === 'numeric') {
                                    return `${formatNumber(data.value, o.numberFormatOptions)}`;
                                } else {
                                    return `${data.value}`;
                                }
                            }
                        } else {
                            if (o.link !== undefined) {
                                if (o.link.event !== undefined) {
                                    let linkId = replaceAll(" ", "_", row.uniqueKey);
                                    let event = { ...{ selector: `#${linkId}`, params: row }, ...o.link.event };
                                    pendingRegEvent[`#${linkId}`] = event;
                                    return `<a id="${linkId}">${data}</a>`;
                                }
                                let url = o.link.url;
                                if (url !== undefined && o.link.params !== undefined) {
                                    for (let param of o.link.params) {
                                        url = url.replace(":" + param, row[param]);
                                    }
                                }

                                return `<a dt-href='${getContextPath()}${url}'>${data}</a>`;
                            } else {
                                if (o.type === 'numeric') {
                                    if (typeof (data) === 'string' && (isNaN(parseFloat(data)) || data.includes(","))) {
                                        return `${data}`;
                                    }
                                    return `${formatNumber(data, o.numberFormatOptions)}`;
                                } else {
                                    if (o.type === 'progressBar') {
                                        const { accumulatedAmount, limit } = row;
                                        let percentage = Math.round((accumulatedAmount / limit) * 100);
                                        return `
                                         <div class="progress percentage-progress" style=" border: 1px solid; margin-bottom:unset;
                                          border-radius: unset;" >
                                          <div class="progress-bar percentage-progress-bar" role="progressbar" style=" width: ${percentage > 100 ? 100 : percentage}%; color:black; background-color: #90EE90;" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}> ${percentage > 0 ? `${percentage <= 100 ? percentage : 100}%` : ''}</div>
                                         </div>`;
                                    } else {
                                        return `${data === undefined ? '' : data}`;
                                    }
                                }
                            }

                        }
                    } : (data: any, type: any, row: any, meta: any): string => {
                        let elHtml = '';
                        if (o.render === undefined) {
                            elHtml = row.data;
                        } else {
                            elHtml = o.render(data, type, row, meta);
                            if (o.onClick || o.onChange) {
                                let elc = $("<div></div>");
                                elc.attr("dt-row", meta.row);
                                elc.attr("dt-col", meta.col);
                                elc.append(elHtml);
                                elHtml = elc[0].outerHTML;
                            }
                        }
                        return elHtml;
                    }
                };
                if (o.type !== undefined) {
                    columnDef.type = getType(o.type);
                } else {
                    columnDef.type = "text";
                }
                columnDefs.push(columnDef);
            }

            return col;
        });
    }

    function getType(type: string) {
        if (type.startsWith("date")) {
            if (props.dateformat === undefined) {
                throw new Error("Date format must be defined for sorting.");
            }
            return type + "-" + props.dateformat;
        } else {
            return type
        }

    }

    /**initial sorting for date */
    if (props.dateformat !== undefined) {
        $.fn.dataTable.moment(props.dateformat.toUpperCase());
    }


    const destoryTable = (clearTable: boolean) => {
        let propsId = `dt_${props.id}`;
        let table = win[propsId]
        if (table !== undefined) {
            if (table instanceof $.fn.dataTable.Api) {
                table.destroy();
                if (clearTable) {
                    $("#" + props.id).html("");
                }
                win[propsId] = undefined;
            }
        }
    }

    const initTable = (clearTable: boolean) => {
        destoryTable(clearTable);
        let propsId = `dt_${props.id}`;
        let table = win[propsId]
        let option: any = {
            order: order,
            deferRender: props.select !== undefined ? false : true,
            //bStateSave: props.stateSave === undefined ? true : props.stateSave,
            responsive: true,
            language: {
                emptyTable: globalization['common.no.data'] === undefined ? 'common.no.data' : globalization['common.no.data']
            }
        };
        option = props.option !== undefined ? { ...option, ...props.option } : option;
        option.columnsShows = props.columnsShows ? props.columnsShows : DEFAULT_COLUMNS_SHOWS;
        if (props.select !== undefined) {
            option.select = { prefix: `${props.id}_select_`, ...props.select }
            if (props.select.type === 'multiple') {
                if (option.select.buttons === undefined) {
                    option.select.buttons = {
                        container: document.getElementById(`${props.id}_select_buttons`),
                        selectAll: document.getElementById(`${props.id}_select_selectall`),
                        deSelectAll: document.getElementById(`${props.id}_select_deselectall`),
                        showAlways: false
                    }
                } else {
                    option.select.buttons.selectAll?.addEventListener("click", () => {
                        win[`dt_${props.id}`].rows().select(true, true)
                    });
                    option.select.buttons.deSelectAll?.addEventListener("click", () => {
                        win[`dt_${props.id}`].rows().select(false, true)
                    });
                }
            }
        }
        if (props.childRows !== undefined) {
            option.childRows = props.childRows;
        }
        if (props.table !== undefined) {
            if (props.table.data !== undefined) {
                option.data = props.table.data;
            }
            if (props.table.columns !== undefined) {
                option.columns = columns;
                option.columnDefs = columnDefs;
            }
        }

        const registerEvent = () => {
            let elements: NodeListOf<Element> = document.querySelectorAll("a[dt-href]");
            if (elements !== null) {
                elements.forEach((e: Element, key: number, parent: NodeListOf<Element>) => {
                    let goto = e.getAttribute("dt-href");
                    if (goto !== undefined) {
                        e.addEventListener('click', () => { next(`${goto}`) });
                        return
                    }
                });
            }
            props.table?.columns.forEach((column: TableColumn, index: number) => {
                let pos = 0;
                if (props.table !== undefined) {
                    pos = columns.length - props.table.columns.length;
                }
                if (column.onClick !== undefined) {
                    let selector = `#${props.id} *[dt-col='${index + pos}']`;
                    let clickColumns: NodeListOf<Element> = document.querySelectorAll(selector);
                    clickColumns.forEach((e: Element, key: number, parent: NodeListOf<Element>) => {
                        let nodes = e.children.length === 0 ? [e] : e.children;
                        for (let i = 0; i < nodes.length; i++) {
                            let node = nodes[i];
                            let row = e.getAttribute("dt-row");
                            let binderKey = selector + row + i;
                            if (eventBinder[binderKey] !== undefined) {
                                node?.removeEventListener('click', eventBinder[binderKey].onClick);
                            }
                            const onClick = (evt: any) => {
                                if (column.onClick !== undefined && row !== null) {
                                    let rowData = win[propsId].data()[parseInt(row)];
                                    column.onClick(evt, rowData);
                                    evt.stopPropagation();
                                }
                            };
                            eventBinder[binderKey] = { onClick: onClick };
                            node?.addEventListener('click', eventBinder[binderKey].onClick);
                        }
                    });
                }
                if (column.onChange !== undefined) {
                    let selector = `*[dt-col='${index + pos}']`;
                    let changeColumns: NodeListOf<Element> = document.querySelectorAll(selector);
                    changeColumns.forEach((e: any, key: number, parent: NodeListOf<Element>) => {
                        let nodes = e.children.length === 0 ? [e] : e.children;
                        for (let i = 0; i < nodes.length; i++) {
                            let node = e.children.length === 0 ? e : e.children[0];
                            let row = e.getAttribute("dt-row");
                            let binderKey = selector + row + i;
                            if (eventBinder[binderKey] !== undefined) {
                                node?.removeEventListener('change', eventBinder[binderKey].onChange);
                            }
                            const onChange = (evt: any) => {
                                if (column.onChange !== undefined && row !== null) {
                                    let rowData = win[propsId].data()[parseInt(row)];
                                    column.onChange(evt, rowData);
                                    evt.stopPropagation();
                                }
                            };
                            eventBinder[binderKey] = { onChange: onChange };
                            node?.addEventListener('change', eventBinder[binderKey].onChange);
                        }
                    });
                }
            });
        }
        if (props.delayInit !== true) {
            table = $("#" + props.id).DataTable(option);
            let isRegEventAdded = false;
            for (let callback of table.settings()[0].aoDrawCallback) {
                if (callback.sName === 'registerEventForReact') {
                    isRegEventAdded = true;
                }
            }
            if (!isRegEventAdded) {
                table.settings()[0].aoDrawCallback.push({ sName: "registerEventForReact", fn: registerEvent });
            }
            if (props.setInitParams !== undefined && props.getInitParams !== undefined) {
                table.on('order.dt', (e: any, settings: any, newOrder: any) => {
                    if (props.setInitParams !== undefined && props.getInitParams !== undefined && newOrder.length > 0) {
                        let initParam: any = props.getInitParams();
                        if (initParam === undefined) {
                            initParam = {};
                        }
                        if (initParam[INIT_PARAM_PREFIX_TABLE] === undefined) {
                            initParam[INIT_PARAM_PREFIX_TABLE] = {};
                            initParam[INIT_PARAM_PREFIX_TABLE][props.id] = {};
                        }
                        initParam[INIT_PARAM_PREFIX_TABLE][props.id][KEY_ORDER] = [[newOrder[0]["col"], newOrder[0]["dir"]]];
                        props.setInitParams(initParam);
                    }
                });
            }
            if (props.setInitParams !== undefined && props.getInitParams !== undefined) {
                table.on('page.dt', (e: any) => {
                    if (props.setInitParams !== undefined && props.getInitParams !== undefined) {
                        let initParam: any = props.getInitParams();
                        if (initParam === undefined) {
                            initParam = {};
                        }
                        if (initParam[INIT_PARAM_PREFIX_TABLE] === undefined) {
                            initParam[INIT_PARAM_PREFIX_TABLE] = {};
                            initParam[INIT_PARAM_PREFIX_TABLE][props.id] = {};
                        }
                        initParam[INIT_PARAM_PREFIX_TABLE][props.id][KEY_PAGE] = table.page();
                        props.setInitParams(initParam);
                    }
                });
            }
            $("#" + props.id).dataTable();
            if (props.select !== undefined) {
                table.on('responsive-resize', (e: any, datatable: any, cols: boolean[]) => {
                    if (cols.filter((column) => column === false).length > 0 && table.rows()[0].length > 0 && table.settings()[0]._select.disabled.length !== table.rows()[0].length) {
                        $(`#${props.id}_select_buttons`).show();
                    } else {
                        $(`#${props.id}_select_buttons`).hide();
                    }
                    if (table.rows()[0].length === 0) {
                        table.draw();
                    }
                });
                if (table.settings()[0]._responsive) {
                    if (table.settings()[0]._responsive.s.current.filter((v: boolean) => {
                        return v === false;
                    }).length > 0 && table.rows()[0].length > 0 && table.settings()[0]._select.disabled.length !== table.rows()[0].length) {
                        $(`#${props.id}_select_buttons`).show();
                    } else {
                        $(`#${props.id}_select_buttons`).hide();
                    }
                }
                if (props.select.onSelect !== undefined) {
                    table.off('select');
                    table.on('select', (e: any, selectedRows: Array<number>, unSelectedRows: Array<number>) => {
                        if (props.select !== undefined && props.select.onSelect !== undefined) {
                            props.select.onSelect(e, selectedRows, unSelectedRows)
                        }
                    });
                }
            }
            if (props.onResponsiveDisplay !== undefined) {

                table.off("responsive-display.dt");

                table.on("responsive-display.dt", (e: any, dt: any, row: any, cols: Array<TableCell>, res: boolean, update: boolean) => {
                    if (props.onResponsiveDisplay !== undefined && res) {
                        props.onResponsiveDisplay(row[0], dt.row(row[0]).data(), cols, dt.row(row[0]).node().nextSibling);
                    }
                });
            }
            if (props.showChild) {
                let generate = props.showChild;
                $("#" + props.id + ' tbody').on('click', 'td.gwp-details-control', function (e: any) {
                    if ($("#" + props.id + '.collapsed').length === 0) {
                        let tr = $(e.target).closest('tr');
                        let row = $("#" + props.id).DataTable().row(tr);
                        if (row.child.isShown()) {
                            // This row is already open - close it
                            row.child.remove();
                            tr.removeClass('shown');
                        } else {
                            row.child(generate(row)).show();
                            tr.addClass('shown');
                            if (props.afterShowChild !== undefined) {
                                props.afterShowChild(row);
                            }
                        }
                    }
                });
            }

            const stopPropagation = (e: any) => {
                e.stopPropagation();
            }

            const showChild = () => {
                if (props.childRows !== undefined) {
                    if (table.settings()[0]._responsive) {
                        let totalHide = table.settings()[0]._responsive.s.current.filter((c: any) => { return c === false }).length;
                        let t = $(`#${props.id} td[data-gwp-child='no']`);
                        if (totalHide > props.childRows) {
                            $(`#${props.id} .gwp-dt-no-child`).off('click', stopPropagation);
                            t.removeClass("gwp-dt-no-child");
                        } else {
                            t.addClass("gwp-dt-no-child");
                            $(`#${props.id} .gwp-dt-no-child`).on('click', stopPropagation);
                        }
                    }
                }
            }

            if (props.childRows !== undefined) {
                table.on("responsive-resize", showChild);
                showChild();
            }

            win[propsId] = table;
            if (props.modal !== undefined) {
                $(`#${props.modal}`).unbind("shown.bs.modal");
                $(`#${props.modal}`).on("shown.bs.modal", () => {
                    $(`#${props.modal}`).find(".gwp-table").show();
                    $(`#${props.modal}`).find(".gwp-table").each((i: number, el: any) => {

                        win['gwp'].autoAdjustColumnWidth($(el).attr("id").replace("div_", ""));
                    });
                });
                $(`#${props.modal}`).on("hidden.bs.modal", () => {
                    $(`#${props.modal}`).find(".gwp-table").hide();
                });
            } else {
                if (props.autoAdjustment !== false) {
                    if (props.delayAdjust === true) {
                        window.setTimeout(() => win['gwp'].autoAdjustColumnWidth(props.id), 100);
                    } else {
                        win['gwp'].autoAdjustColumnWidth(props.id);
                    }

                }
            }
            win['gwp'].registerAdjustColumnForDraw(props.id);

            if (props.onRendered !== undefined) {
                props.onRendered();
            }
            if (props.getInitParams !== undefined && reload === true && props.setInitParams !== undefined) {

                let initParam = props.getInitParams();
                if (initParam !== undefined && initParam[INIT_PARAM_PREFIX_TABLE] !== undefined
                    && initParam[INIT_PARAM_PREFIX_TABLE][props.id] !== undefined && initParam[INIT_PARAM_PREFIX_TABLE][props.id][KEY_PAGE] !== undefined) {
                    table.page(initParam[INIT_PARAM_PREFIX_TABLE][props.id][KEY_PAGE]).draw('page');
                    let settings = table.settings()[0];
                    // fire pagedone event.
                    settings.oApi._fnCallbackFire(settings, null, 'pagedone', [settings]);
                    initParam[INIT_PARAM_PREFIX_TABLE][props.id][KEY_NEED_RELOAD] = false;
                    props.setInitParams(initParam);
                }
            }
            if (props.isDataLoaded === true) {
                $("#" + props.id).find(".dataTables_empty").show();
            } else {
                $("#" + props.id).find(".dataTables_empty").hide();
            }
        }
    }

    useLayoutEffect(() => {
        if (props.table !== undefined) {
            initTable(true);
        }
    }, [props.table?.data]);



    useLayoutEffect(() => {
        if (props.table === undefined && hasChildren) {
            initTable(false);
        }
    });

    useLayoutEffect(() => {
        if (win[`dt_${props.id}`] !== undefined && props.defaultFilter !== undefined) {
            win[`dt_${props.id}`].filterTable(props.defaultFilter.col, props.defaultFilter.regStr, props.defaultFilter.isRegExp, props.defaultFilter.isSmart);
        }
    })

    return <>
        {(props.select?.type === 'multiple' && props.select?.buttons === undefined) && <>
            <Div id={`${props.id}_select_buttons`} className="gwp-hidden">
                <Button id={`${props.id}_select_selectall`} onClick={() => { win[`dt_${props.id}`].rows().select(true, true) }}><GlobalizedText message="common.lbl.selectAll" /></Button>
                <Button id={`${props.id}_select_deselectall`} onClick={() => { win[`dt_${props.id}`].rows().select(false, true) }} ><GlobalizedText message="common.lbl.deselectAll" /></Button>
            </Div>
        </>
        }
        <Div condition={props.table !== undefined || props.children.length === 2} id={"div_" + props.id} className="gwp-table">
            {(() => {
                if (hasChildren) {
                    destoryTable(false);
                    return <table id={props.id} className={`table table-striped table-bordered dt-responsive dataTable dtr-inline ${props.className ? props.className : ''}`} width="100%">{props.children}</table>
                }
            })()
            }
            {props.children === undefined &&
                <table id={props.id} className={`table table-striped table-bordered dt-responsive dataTable dtr-inline ${props.className ? props.className : ''}`} width="100%"></table>
            }
        </Div></>
});


export const clearCache = () => {
    let keys: Array<string> = [];
    for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);
        if (key?.includes("DataTables_")) {
            keys.push(key);
        }
    }
    for (let key of keys) {
        localStorage.removeItem(key);
    }

}