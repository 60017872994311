import { FieldArray } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import GlobalizedText from "../../shared/globalization";
import { ajax, isEmptyStr } from "../../shared/utils";
import { RequiredHeader } from "./enrollment";
import Enrollment from '../types/enrollmenttypes';

interface Option {
    name: string
    value: string
}

interface Cob {
    dependentIdx: number
    dependentSequenceNumber: number
    fullName: string
    benefitClassifiCationCheck: string
    benefitClassificationIndex: string
    cobCoverage: string
    cobEffdate: string
    cobFollowupdate: string
    cobOthercarrier: string
    cobOthercarrierbenefit: string
    terminationDate: string
    cobVerifydate: string
    coverageType: string
    firstItem: boolean
    coverageSelectionIdx: number
}

interface CoordinationBenefitsViewResponse {
    cobList: Array<Cob>
    otherCarrierList: Array<Option>
    otherCarrierBenefitOrderList: Array<Option>
    securityMap: any
}

export const CoordinationBenefits = (props: Enrollment.Props) => {

    const [config, setConfig] = useState<CoordinationBenefitsViewResponse>();

    const cobValidate = (values: Cob, index: number, result: any): any => {
        let requiredMsg = " " + props.getGlobalizedText("message_information_required");

        if (isEmptyStr(values.cobOthercarrier) && (!isEmptyStr(values.cobOthercarrierbenefit) || !isEmptyStr(values.cobEffdate))) {
            result[`cobs[${index}].cobOthercarrier`] = props.getGlobalizedText("othercarrier") + requiredMsg;
        }

        if (isEmptyStr(values.cobOthercarrierbenefit) && (!isEmptyStr(values.cobOthercarrier) || !isEmptyStr(values.cobEffdate))) {
            result[`cobs[${index}].cobOthercarrierbenefit`] = props.getGlobalizedText("othercarrierbenefitorder") + requiredMsg;
        }

        if (isEmptyStr(values.cobEffdate) && (!isEmptyStr(values.cobOthercarrier) || !isEmptyStr(values.cobOthercarrierbenefit))) {
            result[`cobs[${index}].cobEffdate`] = props.getGlobalizedText("common.lbl.effectiveDate") + requiredMsg;
        }

        if (!isEmptyStr(values.cobEffdate) && !isEmptyStr(values.terminationDate) && new Date(values.terminationDate).getTime() < new Date(values.cobEffdate).getTime()) {
            result[`cobs[${index}].cobEffdate`] = props.getGlobalizedText("common.lbl.effectiveDate") + " " + props.getGlobalizedText("common.lbl.earlierThan") + " " + props.getGlobalizedText("common.lbl.terminationDate");
            result[`cobs[${index}].terminationDate`] = props.getGlobalizedText("common.lbl.terminationDate") + " " + props.getGlobalizedText("common.lbl.laterThan") + " " + props.getGlobalizedText("common.lbl.effectiveDate");
        }

        return result;
    }

    const formValidate = (values: any): any => {
        let result: any = {};
        props.formValue.cobs.map((cob: Cob, index: number) => {
            cobValidate(cob, index, result);
        })
        return result;
    }

    props.setValidation(formValidate);

    useEffect(() => {
        if (props.formValue === undefined || config === undefined) {
            let params = {
                transactionID: props.transactionID
            };
            ajax({
                url: '/api/enrollment/coordinationBenefits/view',
                params: params,
                success: (res: CoordinationBenefitsViewResponse) => {
                    if (res.cobList != null && res.cobList.length === 0) {
                        props.setFormValue({
                            cobs: []
                        });
                    } else {
                        let cobs = res.cobList;
                        props.setFormValue({ cobs: cobs.map((cob: any) => { return { ...defaultInitialValues, ...cob } }) });
                    }
                    setConfig(res);
                }
            });
        }
    }, [props.formValue])

    const defaultInitialValues: Cob = {
        cobEffdate: '',
        cobFollowupdate: '',
        cobOthercarrier: '',
        cobOthercarrierbenefit: '',
        terminationDate: '',
        cobVerifydate: '',
        coverageType: '',
        dependentIdx: 0,
        dependentSequenceNumber: 0,
        fullName: '',
        benefitClassifiCationCheck: '',
        benefitClassificationIndex: '',
        cobCoverage: '',
        firstItem: true,
        coverageSelectionIdx: 0
    };

    props.setPrepareData((values: any) => {
        return {
            "cobs": values.cobs
        }
    });

    return <>
        <props.Panel>
            <props.PanelHeading>
                <RequiredHeader label="cob_title" {...props} />
            </props.PanelHeading>
            <props.PanelBody>
                <FieldArray name="cobs" render={arrayHelpers => {
                    if (props.formValue === undefined || props.formValue.length === 0) {
                        return <></>;
                    }
                    return props.formValue.cobs.map((cob: Cob, index: number) => {
                        return <React.Fragment key={index}>
                            <props.Row condition={cob.firstItem === true}>
                                <props.Col xs="12" md="6" sm="6" className="panel-title"><GlobalizedText message="cob_title" />&nbsp;<GlobalizedText message="confirmation.label.otherinsurance" />
                                </props.Col>
                                <props.Col xs="12" md="6" sm="6" className="panel-title">-&nbsp;{cob.fullName}
                                </props.Col>
                            </props.Row>
                            <props.Row>
                                <props.Col xs="12" md="6" sm="6">
                                    <props.DL >
                                        <props.DLI title="lb_coverage" desc={cob.cobCoverage}></props.DLI>
                                    </props.DL>
                                    <props.SelectControl arrayHelpers={arrayHelpers} name={`cobs[${index}].cobOthercarrier`} required={cob.cobEffdate !== '' || cob.cobOthercarrier !== '' || cob.cobOthercarrierbenefit !== ''} disabled={false} label="othercarrier" sort={true} defaultOption="common.lbl.pleaseselect"
                                        options={config?.otherCarrierList}></props.SelectControl>
                                    <props.SelectControl arrayHelpers={arrayHelpers} name={`cobs[${index}].cobOthercarrierbenefit`} required={cob.cobEffdate !== '' || cob.cobOthercarrier !== '' || cob.cobOthercarrierbenefit !== ''} disabled={false} label="othercarrierbenefitorder" sort={true} defaultOption="common.lbl.pleaseselect"
                                        options={config?.otherCarrierBenefitOrderList}></props.SelectControl>
                                </props.Col>
                                <props.Col xs="12" md="6" sm="6">
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} required={cob.cobEffdate !== '' || cob.cobOthercarrier !== '' || cob.cobOthercarrierbenefit !== ''} disabled={false} name={`cobs[${index}].cobEffdate`} label="common.lbl.effectiveDate" dateformat={props.getDateFormat()} />
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} required={false} disabled={false} name={`cobs[${index}].terminationDate`} label="common.lbl.terminationDate" dateformat={props.getDateFormat()} />
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} required={false} disabled={config?.securityMap.cobVerifyFollowUpDate == false} name={`cobs[${index}].cobVerifydate`} label="verifydate" dateformat={props.getDateFormat()} />
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} required={false} disabled={config?.securityMap.cobVerifyFollowUpDate == false} name={`cobs[${index}].cobFollowupdate`} label="followupdate" dateformat={props.getDateFormat()} />
                                </props.Col>
                            </props.Row>
                            <props.HR />
                        </React.Fragment>
                    })
                }
                } />
            </props.PanelBody>
        </props.Panel>
    </>
}