import React, { useContext } from 'react';
import GlobalizedText, { GlobalizationContext } from '../../globalization';
import { getGlobalizedText } from '../../utils';
export type IconType =
    'warning'
    | 'expand'
    | 'view-all'
    | 'lock'
    | 'information'
    | 'collapse'
    | 'person-v2'
    | 'person'
    | 'update'
    | 'add'
    | 'delete'
    | 'view-statement'
    | 'dollar'
    | 'change-payment-method'
    | 'view-detail'
    | 'view-chart'
    | 'print'
    | 'application-activities'
    | 'view-claim'
    | 'refresh'
    | 'help';
export type IconSize = 'xs' | 'sm' | 'md' | 'lg';
export interface IconProps {
    type: IconType;
    size?: IconSize
    label?: string;
    labelClass?: string;
    title?: string;
    id?: string;
    onClick?: () => void;
    children?: any;
    style?: any;
}
export const Icon = (props: IconProps) => {
    const DEFAULT_SIZE = 'sm';
    let ext: any = {};
    const globalization = useContext(GlobalizationContext);
    if (props.title) {
        ext.title = getGlobalizedText(globalization, props.title);
    }
    return <>
        <span id={props.id} {...ext} onClick={props.onClick} style={{ marginRight: '2px', marginLeft: '2px', ...props.style }} className={`gwp-icon gwp-icon-${props.type} gwp-icon-${props.size === undefined ? DEFAULT_SIZE : props.size}`}>
            {props.children &&
                <span className="gwp-icon-child">{props.children}</span>
            }
        </span>
        {props.label !== undefined &&
            <GlobalizedText className={`gwp-icon-label ${props.labelClass ? props.labelClass : ''}`} message={props.label} />
        }
    </>
}

export interface BootstrapIconProps {
    type: "search" | "refresh" | "envelope" | "ok" | "pencil" | "trash" | "plus-sign";
    label?: string;
    labelClass?: string;
    id?: string;
    title?: string;
    onClick?: (e: any) => void;
}

export const BootstrapIcon = (props: BootstrapIconProps) => {
    let ext: any = {};
    const globalization = useContext(GlobalizationContext);
    if (props.title) {
        ext.title = getGlobalizedText(globalization, props.title);
    }
    if (props.id) {
        ext.id = props.id;
    }
    if (props.onClick) {
        ext.onClick = props.onClick;
    }
    return <>
        <span {...ext} className={`glyphicon glyphicon-${props.type}`} style={{ marginRight: '2px', marginLeft: '2px' }} aria-hidden="true"></span>
        {
            props.label !== undefined &&
            <GlobalizedText className={`gwp-icon-label ${props.labelClass ? props.labelClass : ''}`} message={props.label} />
        }
    </>
}
