import React, { useState, useEffect } from "react"
import { ViewComponentProps, withView } from "../shared/viewcomponent"
import { Formik, Form } from 'formik';
import { ajax, validate } from '../shared/utils';
import { useMessage } from "../shared/message";
import GlobalizedText from '../shared/globalization';
import { TableColumn } from "../shared/components";
import { RequiredTitle, WarningTitle, IndicatedReqTitle } from "../shared/moduleTitles";
let selectetedRowData: any = null;

export const OpenEnrollmentPeriodComponent = withView(function (props: ViewComponentProps) {
    const messageService4Add = useMessage("oepAdd");
    const messageService4Edit = useMessage("oepEdit");

    const [config, setConfig] = useState<any | null>(null);
    const [accounts, setAccounts] = useState<Array<any>>([{ name: "common.lbl.all", value: "0" }]);
    const [accountsAdd, setAccountsAdd] = useState<Array<any>>([]);
    const [handlerFlag, setHandlerFlag] = useState<string | null>();
    const [disableBtnFlag, setDisableBtnFlag] = useState<boolean>(true);

    let varFormPromp: any;

    const tableColumns: Array<TableColumn> = [
        { "title": "common.lbl.group", "name": "groupDesc", "sortable": true },
        { "title": "common.lbl.account", "name": "accountDesc", "sortable": true },
        { "title": "common.lbl.periodBegins", "name": "beginDate", "sortable": true, "type": "date" },
        { "title": "common.lbl.periodEnds", "name": "endDate", "sortable": true, "type": "date" },
        { "title": "common.lbl.anniversaryMonthDay", "name": "anniversaryDate", "sortable": true, "type": "date" }
    ]

    const [openEnrollmentPeriodtable, setTable] = useState<any | null>({ columns: tableColumns, data: [] });

    let addSchema = (formValue: any) => {
        let addValidateResult: any = {};
        let errorFlag: boolean = false;
        if (!isValidaDate(formValue.inBeginDateAdd)) {
            addValidateResult.inBeginDateAdd = "openenrol.msg.beginDateMsg"
            errorFlag = true;
        }

        if (!isValidaDate(formValue.inEndDateAdd)) {
            addValidateResult.inEndDateAdd = "openenrol.msg.endDateMsg"
            errorFlag = true;
        }

        if (!isValidaDate(formValue.inAnnDateAdd)) {
            addValidateResult.inAnnDateAdd = "openenrol.msg.anniversaryDateMsg"
            errorFlag = true;
        }

        let beginDateAdd = formValue.inBeginDateAdd.split("/");
        let endDateAdd = formValue.inEndDateAdd.split("/");

        let beginEndResultAdd = isValidBeginEndDate(beginDateAdd[0], beginDateAdd[1], endDateAdd[0], endDateAdd[1]);
        if (!beginEndResultAdd) {
            addValidateResult.inEndDateAdd = "openenrol.msg.beginDateEarlierMsg"
            addValidateResult.inBeginDateAdd = "openenrol.msg.beginDateEarlierMsg"
            errorFlag = true;
        }
        if (errorFlag === true) {
            return addValidateResult;
        }

    };

    let editSchema = (formValue: any) => {
        let editValidateResult: any = {};
        let errorFlag: boolean = false;
        if (!isValidaDate(formValue.inBeginDateEdit)) {
            editValidateResult.inBeginDateEdit = "openenrol.msg.beginDateMsg"
            errorFlag = true;
        }

        if (!isValidaDate(formValue.endDateEdit)) {
            editValidateResult.endDateEdit = "openenrol.msg.endDateMsg"
            errorFlag = true;
        }

        if (!isValidaDate(formValue.inAnnDateEdit)) {
            editValidateResult.inAnnDateEdit = "openenrol.msg.anniversaryDateMsg"
            errorFlag = true;
        }

        let beginDateEdit = formValue.inBeginDateEdit.split("/");
        let endDateEdit = formValue.endDateEdit.split("/");
        let beginEndResult = isValidBeginEndDate(beginDateEdit[0], beginDateEdit[1], endDateEdit[0], endDateEdit[1]);
        if (!beginEndResult) {
            editValidateResult.endDateEdit = "openenrol.msg.beginDateEarlierMsg"
            editValidateResult.inBeginDateEdit = "openenrol.msg.beginDateEarlierMsg"
            errorFlag = true;
        }
        if (errorFlag === true) {
            return editValidateResult;
        }
    };

    // define Form Validate function.
    const formValidate = (values: any): any => {
        if (handlerFlag === 'addOEP') {
            return validate(addSchema, values, messageService4Add.validateProps);
        } else if (handlerFlag === 'editOEP') {
            return validate(editSchema, values, messageService4Edit.validateProps);
        }
    }

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/openEnrollmentPeriod/view',
                success: (res: any) => {
                    setConfig(res);
                    varFormPromp.setFieldValue("groupNumber", 0);
                    setAccounts(res.accounts);
                    varFormPromp.setFieldValue("account", 0);
                    setAccountsAdd(res.accounts);
                    if (res.openEnrollmentPeriodList !== undefined) {
                        setTable({
                            columns: tableColumns,
                            data: res.openEnrollmentPeriodList.map((row: any) => { return { ...row, ...{ checked: false } } })
                        });
                    }
                }
            });
            return () => {
            }
        }
    }, [config]);

    // define Form Submitting function.
    const formSubmit = (values: any): void => {
        props.clearMessage();
        if (handlerFlag === 'addOEP') {
            addNewOEP(values);
        } else if (handlerFlag === 'editOEP') {
            editExistingOEP(values);
        } else if (handlerFlag === 'clear') {
        } else {
            retrieve(values, tableColumns, setTable);
        }
    }

    function retrieve(values: any, columns: any, setTable: any, setSubmitting?: Function) {
        let compCode = "0";
        let groupNumber = "";
        let accountNumber = "";
        //groupNumber and account can be int/string, so use != here
        if (values.groupNumber != "0") {
            let companyAndGroup = values.groupNumber.split("|");
            groupNumber = companyAndGroup[1];
            compCode = companyAndGroup[0];
        }
        if (values.groupNumber != "0" && values.account != "0") {
            accountNumber = values.account;
        }

        ajax({
            url: '/api/openEnrollmentPeriod/fetchOpenEnrollmentPeriod/',
            params: {
                companyCode: compCode,
                groupNumber: groupNumber,
                accountNumber: accountNumber
            },
            success: (res: any) => {
                setDisableBtnFlag(true);
                if (res.openEnrollmentPeriodList !== undefined) {
                    setTable({
                        columns: columns,
                        data: res.openEnrollmentPeriodList.map((row: any) => { return { ...row, ...{ checked: false } } })
                    });
                }
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }

    const groupChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target.value;
        if (companyAndGroupNumber === '0') {
            setAccounts([{ "name": "common.lbl.all", "value": "0" }]);
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: '/api/openEnrollmentPeriod/getAccountListWithPeriod',
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setAccounts(res.accounts);
                    setFieldValue("account", res.accounts[0].value);
                }
            });
        }
    }

    const groupAddChangeHandler = (e: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target.value;
        if (companyAndGroupNumber === '0') {
            setAccountsAdd([{ "name": "common.lbl.all", "value": "0" }]);
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: '/api/openEnrollmentPeriod/getAccountList',
                showMask: false,
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setAccountsAdd(res.accounts);
                    setFieldValue("addAccount", "0");
                }
            });
        }
    }

    function saveSelectedValue(row: any) {
        selectetedRowData = row;
        if (selectetedRowData != null) {
            varFormPromp.setFieldValue("editGroup", selectetedRowData.groupDesc);
            varFormPromp.setFieldValue("editAccount", selectetedRowData.accountDesc);

            varFormPromp.setFieldValue("inBeginDateEdit", selectetedRowData.beginDate);
            varFormPromp.setFieldValue("endDateEdit", selectetedRowData.endDate);
            varFormPromp.setFieldValue("inAnnDateEdit", selectetedRowData.anniversaryDate);

            setDisableBtnFlag(false);
        }
    }

    function openAddDialog(formProps: any) {
        //clear error, re-render UI
        clearAddDialog(messageService4Add.validateProps);

        setHandlerFlag("clear");

        formProps.setFieldValue("addGroupNumber", 0);
        setAccountsAdd([{ "name": "common.lbl.all", "value": "0" }]);
        formProps.setFieldValue("inBeginDateAdd", "");
        formProps.setFieldValue("inEndDateAdd", "");
        formProps.setFieldValue("inAnnDateAdd", "");

        props.showModal('#OEP_addDialog');
    }

    function openEditDialog(formProps: any) {
        setHandlerFlag("clear");
        if (selectetedRowData != null) {
            messageService4Edit.validateProps.clearMessage();

            varFormPromp.setFieldValue("editGroup", selectetedRowData.groupDesc);
            varFormPromp.setFieldValue("editAccount", selectetedRowData.accountDesc);

            varFormPromp.setFieldValue("inBeginDateEdit", selectetedRowData.beginDate);
            varFormPromp.setFieldValue("endDateEdit", selectetedRowData.endDate);
            varFormPromp.setFieldValue("inAnnDateEdit", selectetedRowData.anniversaryDate);

            props.showModal('#OEP_editDialog')
        }
    }

    function openDeleteDialog(formValue: any) {
        if (selectetedRowData != null) {
            props.showModal('#confirmDelete')
        }
    }

    function addNewOEP(formValue: any) {
        messageService4Add.validateProps.clearMessage();

        let groupNum = "";
        let compStr = "0";
        let accountNum = "0";
        //groupNumber and account can be int/string, so use != here
        if (formValue.addGroupNumber != 0) {
            let companyAndGroup = formValue.addGroupNumber.split("|");
            groupNum = companyAndGroup[1];
            compStr = companyAndGroup[0];
            accountNum = formValue.addAccount;
        }

        let beginDateAdd = formValue.inBeginDateAdd.split("/");
        let endDateAdd = formValue.inEndDateAdd.split("/");
        let annDateAdd = formValue.inAnnDateAdd.split("/");

        ajax({
            url: '/api/openEnrollmentPeriod/add',
            method: 'post',
            data: {
                companyCode: compStr,
                groupNumber: groupNum,
                accountNumber: accountNum,
                beginDateMonth: beginDateAdd[0],
                beginDateDay: beginDateAdd[1],
                endDateMonth: endDateAdd[0],
                endDateDay: endDateAdd[1],
                annDateMonth: annDateAdd[0],
                annDateDay: annDateAdd[1]
            },
            success: (res: any) => {
                props.closeModal("#OEP_addDialog");
                //disable button
                setDisableBtnFlag(true);
                props.showMessage("success", "openenrol.msg.addedSuccessfully");
                setConfig(null);
            },
            fail: (res: any) => {
                messageService4Add.validateProps.showMessage("error", "openenrol.msg.duplicatedRecord");
            },
            callback: () => {
            }
        });
    }

    function editExistingOEP(formValue: any) {
        let beginDateEdit = formValue.inBeginDateEdit.split("/");
        let endDateEdit = formValue.endDateEdit.split("/");
        let annDateEdit = formValue.inAnnDateEdit.split("/");
        ajax({
            url: `/api/openEnrollmentPeriod/update/${selectetedRowData.recid}`,
            method: 'post',
            data: {
                beginDateMonth: beginDateEdit[0],
                beginDateDay: beginDateEdit[1],
                endDateMonth: endDateEdit[0],
                endDateDay: endDateEdit[1],
                annDateMonth: annDateEdit[0],
                annDateDay: annDateEdit[1]
            },
            success: (res: any) => {
                retrieve(formValue, tableColumns, setTable);
                props.closeModal("#OEP_editDialog");
                //disable button
                setDisableBtnFlag(true);
                props.showMessage("success", "openenrol.msg.updateSuccessfully");
            },
            fail: (res: any) => {
            },
            callback: () => {
            }
        });
    }

    function deleteExistingOEP(formValue: any) {
        ajax({
            url: `/api/openEnrollmentPeriod/delete/${selectetedRowData.recid}`,
            method: 'delete',
            success: (res: any) => {
                //disable button
                setDisableBtnFlag(true);
                retrieve(formValue, tableColumns, setTable);
                props.showMessage("success", "openenrol.msg.deletedSuccessfully");
                setConfig(null);
            },
            callback: () => {
            }
        });
    }

    function isValidaDate(passedVal: any): boolean {
        var reg = new RegExp("^(0[1-9]|10|11|12)\/(0[1-9]|[12][0-9]|30|31)$");
        if (!reg.test(passedVal)) {
            return false;
        }
        return true;
    }

    function isValidBeginEndDate(beginMonth: any, beginDay: any, endMonth: any, endDay: any): boolean {
        if (beginMonth > endMonth) {
            return false;
        } else if (beginMonth === endMonth) {
            if (beginDay > endDay) {
                return false;
            }
        }
        return true;
    }

    function clearAddDialog(viewProps: any) {
        varFormPromp.setFieldValue("addGroupNumber", '0');
        varFormPromp.setFieldValue("addAccount", '0');
        varFormPromp.setFieldValue("inBeginDateAdd", '');
        varFormPromp.setFieldValue("inEndDateAdd", '');
        varFormPromp.setFieldValue("inAnnDateAdd", '');
        viewProps.clearMessage();
    }

    return <React.Fragment>
        <Formik initialValues={{
            groupNumber: '',
            account: '0',

            addGroupNumber: '0',
            addAccount: '0',
            inBeginDateAdd: '',
            inEndDateAdd: '',
            inAnnDateAdd: '',

            editGroup: '',
            editAccount: '',
            inBeginDateEdit: '',
            endDateEdit: '',
            inAnnDateEdit: ''
        }}
            validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps => {
                varFormPromp = formProps;
                return <Form>
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <props.Label><GlobalizedText message='common.lbl.groupaccount' /></props.Label>
                        </props.Col>
                        <props.Col xs="12" sm="6" md="6">
                            <props.SelectControl name="groupNumber" sort={false} label="common.lbl.group" onChange={(e: any) => { groupChangeHandler(e, formProps.values, formProps.setFieldValue) }} options={config === null ? [{ name: "common.lbl.all", value: "0" }] : config.groups}>
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="6" md="6">
                            <props.SelectControl label="common.lbl.account" sort={false} name="account" options={accounts} >
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <props.Button type="submit" onClick={() => { setHandlerFlag("retrieve") }}><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <props.HR />
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <props.Button name="btnAdd" type="submit" onClick={() => { openAddDialog(formProps) }} ><GlobalizedText message="common.lbl.add" /></props.Button>
                            <props.Button name="btnEdit" type="submit" disabled={disableBtnFlag} onClick={() => { openEditDialog(formProps) }} ><GlobalizedText message="common.lbl.edit" /></props.Button>
                            <props.Button name="btnDelete" disabled={disableBtnFlag} onClick={() => { openDeleteDialog(formProps.values) }}  ><GlobalizedText message="common.lbl.delete" /></props.Button>
                        </props.Col>

                    </props.Row>

                    {/* Delete Dialog */}
                    <props.Modal title={() => {
                        return WarningTitle('benefit.del.enrol.period');
                    }
                    } id="confirmDelete" footer={() => {
                        return <>
                            <props.Button onClick={() => { deleteExistingOEP(formProps.values) }} data-toggle="modal" data-target="#confirmDelete" id='removeAccess'><GlobalizedText message="common.lbl.wpdelete" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} >
                        <p><GlobalizedText message="openenrol.lbl.selectdToDeleteTitle" /></p>
                        <props.DL className='gwp-dl gwp-align-left'>
                            <props.DLI title="common.lbl.group" desc={selectetedRowData === null ? "" : "< " + selectetedRowData.groupDesc + " >"}></props.DLI>
                            <props.DLI title="common.lbl.account" desc={selectetedRowData === null ? "" : "< " + selectetedRowData.accountDesc + " >"}></props.DLI>
                        </props.DL>
                        <p><GlobalizedText message="openEnrollmentPeriod.msg.confirmRemove" /></p>
                    </props.Modal>

                    {/* Add Dialog */}
                    <props.Modal title={() => {
                        return RequiredTitle('common.lbl.addOpenEnrollmentPeriod');
                    }
                    } id="OEP_addDialog" footer={() => {
                        return <>
                            <props.Button type="submit" onClick={() => { setHandlerFlag("addOEP") }} ><GlobalizedText message="common.button.submit" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} messageService={messageService4Add.messageService} >
                        <IndicatedReqTitle message={"common.lbl.indicateRequiredFld"} props={props} />
                        <props.Row>

                            <props.Col xs="12" sm="6" md="6">
                                <props.SelectControl required={true} sort={false} name="addGroupNumber" label="common.lbl.group" onChange={(e: any) => { groupAddChangeHandler(e, formProps.setFieldValue) }} options={config === null ? [{ name: "common.lbl.all", value: "0" }] : config.groupsAll}>
                                </props.SelectControl>
                                <props.SelectControl required={true} sort={false} label="common.lbl.account" name="addAccount" options={accountsAdd}>
                                </props.SelectControl>
                            </props.Col>
                            <props.Col xs="12" sm="6" md="6">
                                <props.DateTimePickerControl useLocalDate={false} required={true} dateformat="MM/dd" htmlFor="inBeginDateAdd_input" id="inBeginDateAdd" name="inBeginDateAdd" label="common.lbl.beginMonthDay" />
                                <props.DateTimePickerControl useLocalDate={false} required={true} dateformat="MM/dd" htmlFor="inEndDateAdd_input" id="inEndDateAdd" name="inEndDateAdd" label="common.lbl.endMonthDay" />
                                <props.DateTimePickerControl useLocalDate={false} required={true} dateformat="MM/dd" htmlFor="inAnnDateAdd_input" id="inAnnDateAdd" name="inAnnDateAdd" label="common.lbl.anniversaryMonthDay" />
                            </props.Col>
                        </props.Row>
                    </props.Modal>

                    {/* Edit Dialog */}
                    <props.Modal title={() => {
                        return RequiredTitle('common.lbl.updateEnrollmentPeriod');
                    }
                    } id="OEP_editDialog" footer={() => {
                        return <>
                            <props.Button type="submit" onClick={() => { setHandlerFlag("editOEP") }} ><GlobalizedText message="common.button.submit" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} messageService={messageService4Edit.messageService}>
                        <IndicatedReqTitle message={"common.lbl.indicateRequiredFld"} props={props} />
                        <props.Row>
                            <props.Col xs="12" sm="6" md="6">
                                <props.DL>
                                    <props.DLI titleClass="gwp-label-ro" title="common.lbl.group" desc={selectetedRowData === null ? "" : selectetedRowData.groupDesc}></props.DLI>
                                </props.DL>
                                <props.DL>
                                    <props.DLI titleClass="gwp-label-ro" title="common.lbl.account" desc={selectetedRowData === null ? "" : selectetedRowData.accountDesc}></props.DLI>
                                </props.DL>
                            </props.Col>
                            <props.Col xs="12" sm="6" md="6">
                                <props.DateTimePickerControl useLocalDate={false} required={true} dateformat="MM/dd" htmlFor="inBeginDateEdit_input" id="inBeginDateEdit" name="inBeginDateEdit" label="common.lbl.beginMonthDay" />
                                <props.DateTimePickerControl useLocalDate={false} required={true} dateformat="MM/dd" htmlFor="endDateEdit_input" id="endDateEdit" name="endDateEdit" label="common.lbl.endMonthDay" />
                                <props.DateTimePickerControl useLocalDate={false} required={true} dateformat="MM/dd" htmlFor="inAnnDateEdit_input" id="inAnnDateEdit" name="inAnnDateEdit" label="common.lbl.anniversaryMonthDay" />
                            </props.Col>
                        </props.Row>
                    </props.Modal>
                </Form>
            }
            }
        </Formik>
        <props.Table id="openEnrollmentPeriodResult" select={{ type: 'single', onSelect: (e: any, rows: number[]) => { saveSelectedValue(openEnrollmentPeriodtable.data[rows[0]]) } }} table={openEnrollmentPeriodtable} dateformat="MM/DD"></props.Table>
    </React.Fragment >
});



