import React, { PureComponent } from 'react';
import { ComponentConfig } from '../config/route-config';
import { UserService } from './authentication';
import BreadCrumb, { BreadCrumbService } from './breadcrumb';
import { PageContent } from './components';
import { ErrorBoundary } from './error';
import Routers from './routers';
import { LayoutProps } from './theme';

export interface BodyProps extends LayoutProps {
    breadcrumb: BreadCrumbService;
    userService: UserService;
    profiles: Array<string>;
    rootComponent: any;
    routeConfigs: ComponentConfig | null;
}

export default class Body extends PureComponent<BodyProps> {
    render() {
        return (
            <>
                <BreadCrumb user={this.props.rootComponent.state.user} breadcrumb={this.props.breadcrumb} />
                <ErrorBoundary>
                    <PageContent>
                        <Routers {...this.props} messageService={this.props.rootComponent.messageService} />
                    </PageContent>
                </ErrorBoundary>
            </>
        );
    }
}