import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, isEmptyStr, trim, validate, saveToStorage } from '../shared/utils';
import { ViewComponentProps } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import * as yup from 'yup';
import { Message, useMessage } from '../shared/message';
import './css/agentcontactinfo.css';

export interface AgentContactInfoProps extends ViewComponentProps {
    agentPersonalInfo: any
    states: Array<any>
    contactMethods: Array<any>
    companyCode: string
    agentNumber: string
    personalInfoType: number
    fromPage?: string
    updateMode: boolean
    updateAble: boolean
    updatePersonalInfoBody?: any
    callBackFuntion?: Function
    myAgentLists?: Array<any>
    isAgentLoginDirectly: boolean
    returnAgentNumberAndCompany?: Function
    isDrAgentSelected?: boolean
}

interface AgentPersonalInformation {
    streetAddress0: string,
    streetAddress1: string,
    streetAddress2: string,
    city: string,
    stateContry: string,
    zipCode: string,
    cellNum: string,
    homeNum: string,
    busiNum: string,
    facNum: string,
    email1: string,
    email2: string,
    conMeth: string
}
interface UpdatePersonalDataType {
    streetAddresses: Array<string>
    city: string,
    countryCode: string,
    stateCode: string,
    zipCode: string,
    businessPhone: string,
    cellPhone: string,
    homePhone: string,
    facsimilePhone: string,
    contactMethod: string,
    emails: Array<string>
}
interface RequestBody4UpdateAGTPersonalInfo { // for Agent
    personalInfoType: number,
    companyCode: string,
    agentNumber: string,
    directReportTab: boolean,
    updatedPersonInfo: UpdatePersonalDataType
}

export const AgentContactInfo = (props: AgentContactInfoProps) => {

    const { messageService, validateProps } = useMessage("agentContactInfo");
    const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
    const AgentPersonalContactInfoForm = useRef<any>();

    const defaultState2 = useRef<Array<any>>([])
    const [showEmail2, setShowEmail2] = useState<boolean>(false);
    const [zipcodeRequirued, setZipcodeRequirued] = useState<boolean | null>(null);
    const [callCount, setCallCount] = useState<number>(0)

    useEffect(() => {
        if (zipcodeRequirued === null) {
            setIsUpdateMode(props.updateMode)
            defaultState2.current.push({ name: '', value: '' })
            if (props.isAgentLoginDirectly === false) {
                props.states.forEach((rowData: any, index: number) => {
                    if (rowData.countryCode === props.agentPersonalInfo.countryCode && rowData.stateCode === props.agentPersonalInfo.stateCode) {
                        if (isEmptyStr(rowData.zipCodeEdit) === false) {
                            setZipcodeRequirued(true)
                        } else {
                            setZipcodeRequirued(false)
                        }
                    }
                    let nameValue = {
                        name: trim(rowData.stateName),
                        value: rowData.countryCode + "|" + rowData.stateCode
                    }
                    defaultState2.current.push(nameValue)
                })
            }
        }
    }, [zipcodeRequirued])

    useEffect(() => {
        if (isUpdateMode === true) {
            props.states.forEach((rowData: any, index: number) => {
                if (rowData.countryCode === props.agentPersonalInfo.countryCode && rowData.stateCode === props.agentPersonalInfo.stateCode) {
                    if (isEmptyStr(rowData.zipCodeEdit) === false) {
                        setZipcodeRequirued(true)
                    } else {
                        setZipcodeRequirued(false)
                    }
                }
                let nameValue = {
                    name: trim(rowData.stateName),
                    value: rowData.countryCode + "|" + rowData.stateCode
                }
                defaultState2.current.push(nameValue)
            })
            requestBody4UpdateAgtPersonalInfo.current.agentNumber = props.agentNumber
            requestBody4UpdateAgtPersonalInfo.current.companyCode = props.companyCode
        }
    }, [isUpdateMode])

    function enableZipCodeRequired(value: any) {
        let tmpCountryCode = value.split("|")[0]
        let tmpStateCode = value.split("|")[1]
        let tmpZipCode = ''

        props.states.forEach((row: any, index: number) => {
            if (row.countryCode === tmpCountryCode && row.stateCode === tmpStateCode) {
                tmpZipCode = row.zipCodeEdit
            }
        })

        if (!isEmptyStr(tmpZipCode)) {
            setZipcodeRequirued(true)
        } else {
            setZipcodeRequirued(false)
        }
    }

    const defaultInitialValues = props.agentPersonalInfo !== undefined ? {
        myAgent: props.agentNumber !== undefined ? props.agentNumber + "-" + props.companyCode : '',
        streetAddress0: props.agentPersonalInfo.streetAddresses[0],
        streetAddress1: props.agentPersonalInfo.streetAddresses[1],
        streetAddress2: props.agentPersonalInfo.streetAddresses[2],
        city: props.agentPersonalInfo.city,
        stateContry: props.agentPersonalInfo.countryCode + "|" + props.agentPersonalInfo.stateCode,
        state: props.agentPersonalInfo.state,
        zipCode: props.agentPersonalInfo.zipCode,
        cellNum: props.agentPersonalInfo.cellPhone,
        homeNum: props.agentPersonalInfo.homePhone,
        busiNum: props.agentPersonalInfo.businessPhone,
        facNum: props.agentPersonalInfo.facsimilePhone,
        email1: props.agentPersonalInfo.emails[0],
        email2: props.agentPersonalInfo.emails[1],
        conMeth: props.agentPersonalInfo.contactMethod
    } : {
        streetAddress0: '',
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        stateContry: '',
        state: '',
        zipCode: '',
        cellNum: '',
        homeNum: '',
        busiNum: '',
        facNum: '',
        email1: '',
        email2: '',
        conMeth: ''
    }

    const defaultInitialValues2 = {
        myAgentView: props.agentNumber !== undefined ? props.agentNumber + "-" + props.companyCode : ''
    }

    const requestBody4UpdateAgtPersonalInfo = useRef<RequestBody4UpdateAGTPersonalInfo>({
        personalInfoType: 3,
        companyCode: props.companyCode,
        agentNumber: props.agentNumber,
        directReportTab: props.isDrAgentSelected !== undefined ? props.isDrAgentSelected : false,
        updatedPersonInfo: props.agentPersonalInfo !== undefined ? {
            streetAddresses: props.agentPersonalInfo.streetAddresses,
            city: props.agentPersonalInfo.city,
            countryCode: props.agentPersonalInfo.countryCode,
            stateCode: props.agentPersonalInfo.stateCode,
            zipCode: props.agentPersonalInfo.zipCode,
            businessPhone: props.agentPersonalInfo.businessPhone,
            cellPhone: props.agentPersonalInfo.cellPhone,
            homePhone: props.agentPersonalInfo.homePhone,
            facsimilePhone: props.agentPersonalInfo.facsimilePhone,
            contactMethod: props.agentPersonalInfo.contactMethod,
            emails: props.agentPersonalInfo.emails
        } : {
            streetAddresses: '',
            city: '',
            countryCode: '',
            stateCode: '',
            zipCode: '',
            businessPhone: '',
            cellPhone: '',
            homePhone: '',
            facsimilePhone: '',
            contactMethod: '',
            emails: ''
        }

    })
    const validateZipcopdeNotRequired = yup.object().shape({
        streetAddress0: YupSchema.personalInfomation.streetAddress0,
        city: YupSchema.personalInfomation.city,
        stateContry: YupSchema.personalInfomation.stateContry,
        cellNum: YupSchema.personalInfomation.phoneNumber,
        busiNum: YupSchema.personalInfomation.phoneNumber,
        homeNum: YupSchema.personalInfomation.phoneNumber,
        facNum: YupSchema.personalInfomation.phoneNumber,
        email1: YupSchema.email,
        email2: YupSchema.email,
        zipCode: YupSchema.personalInfomation.zip(false, props.getGlobalizedText)
    })
    const validateZipcopdeRequired = yup.object().shape({
        streetAddress0: YupSchema.personalInfomation.streetAddress0,
        city: YupSchema.personalInfomation.city,
        stateContry: YupSchema.personalInfomation.stateContry,
        cellNum: YupSchema.personalInfomation.phoneNumber,
        busiNum: YupSchema.personalInfomation.phoneNumber,
        homeNum: YupSchema.personalInfomation.phoneNumber,
        facNum: YupSchema.personalInfomation.phoneNumber,
        email1: YupSchema.email,
        email2: YupSchema.email,
        zipCode: YupSchema.personalInfomation.zip(true, props.getGlobalizedText)
    })
    const formValidate = (values: AgentPersonalInformation): any => {
        if (!isEmptyStr(values.cellNum)) {
            values.cellNum = values.cellNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        }
        if (!isEmptyStr(values.busiNum)) {
            values.busiNum = values.busiNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        }
        if (!isEmptyStr(values.homeNum)) {
            values.homeNum = values.homeNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        }
        if (!isEmptyStr(values.facNum)) {
            values.facNum = values.facNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        }
        if (zipcodeRequirued === true) {
            return validate(validateZipcopdeRequired, values, validateProps)
        } else {
            return validate(validateZipcopdeNotRequired, values, validateProps)
        }
    }
    const formSubmit2 = (values: any): void => {
        // Just use Formik to set field value
        setCallCount(0)
    }
    const formSubmit = (values: AgentPersonalInformation): void => {
        let tmpAddr: Array<string> = []
        tmpAddr.push(values.streetAddress0)
        tmpAddr.push(values.streetAddress1)
        tmpAddr.push(values.streetAddress2)
        requestBody4UpdateAgtPersonalInfo.current.updatedPersonInfo.streetAddresses = tmpAddr
        requestBody4UpdateAgtPersonalInfo.current.updatedPersonInfo.city = values.city
        requestBody4UpdateAgtPersonalInfo.current.updatedPersonInfo.countryCode = values.stateContry.split("|")[0]
        requestBody4UpdateAgtPersonalInfo.current.updatedPersonInfo.stateCode = values.stateContry.split("|")[1]
        requestBody4UpdateAgtPersonalInfo.current.updatedPersonInfo.zipCode = values.zipCode
        requestBody4UpdateAgtPersonalInfo.current.updatedPersonInfo.cellPhone = isEmptyStr(values.cellNum) ? '' : values.cellNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        requestBody4UpdateAgtPersonalInfo.current.updatedPersonInfo.homePhone = isEmptyStr(values.homeNum) ? '' : values.homeNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        requestBody4UpdateAgtPersonalInfo.current.updatedPersonInfo.businessPhone = isEmptyStr(values.busiNum) ? '' : values.busiNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        requestBody4UpdateAgtPersonalInfo.current.updatedPersonInfo.facsimilePhone = isEmptyStr(values.facNum) ? '' : values.facNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        let tmpEmails: Array<any> = []
        tmpEmails.push(values.email1)
        tmpEmails.push(showEmail2 === true ? values.email2 : '')
        requestBody4UpdateAgtPersonalInfo.current.updatedPersonInfo.emails = tmpEmails
        requestBody4UpdateAgtPersonalInfo.current.updatedPersonInfo.contactMethod = values.conMeth
        ajax({
            url: '/api/personalInfo/updatePersonalInfo',
            method: 'POST',
            data: requestBody4UpdateAgtPersonalInfo.current,
            success: (res: any) => {
                setIsUpdateMode(false)
                if (props.callBackFuntion !== undefined) {
                    let message = '<span class="gwp-bold">'
                    message += props.getGlobalizedText('pi.msg.update.title')
                    message += '</span>'
                    message += '<br/>'
                    message += '<div>'
                    message += props.getGlobalizedText('pi.msg.if.change')
                    message += '</div>'
                    validateProps.showMessage('success', message)
                    props.callBackFuntion('success')
                }
            }
        })
    }

    return <div id="agentContactInfo">
        <props.Row>
            <Message messageService={messageService}></Message>
        </props.Row>
        {isUpdateMode === false && <>
            <props.Row>
                <props.Col sm="12" md="12" xs="12">
                    <props.Div id="personalContactInfo_UpdateFalse" className="gwp-innertitle gwp-bold"><GlobalizedText message="pi.contact.info" />
                        <props.Link condition={props.updateAble === true} id="updateLink" to="" onClick={() => {
                            setIsUpdateMode(true)
                            validateProps.clearMessage()
                        }} style={{ fontSize: "12px" }}>
                            <props.Span className="gwp-icon gwp-icon-update gwp-icon-sm" style={{ marginLeft: '8px' }}></props.Span>
                            <GlobalizedText message="common.lbl.update" />
                        </props.Link>
                    </props.Div>
                </props.Col>
                <Formik initialValues={defaultInitialValues2}
                    enableReinitialize={true}
                    // validate={formValidate}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={formSubmit2}
                >
                    {formProps => {
                        // AgentPersonalContactInfoFormView.current = formProps
                        return <Form id="personalContactInfoView">
                            <props.Col condition={props.myAgentLists !== undefined} sm="12" md="12" xs="12">
                                <props.Div className="information gwp-information">
                                    <props.Span className="glyphicon glyphicon-exclamation-sign"></props.Span>{" "}<GlobalizedText message="pi.multipleAgentExist" />
                                </props.Div>
                                <props.SelectControl id="selectedMyAgent" defaultOption={isEmptyStr(props.agentNumber) ? 'common.lbl.pleaseselect' : defaultInitialValues2.myAgentView} name="myAgentView" label="common.lbl.agent" sort={false} options={props.myAgentLists !== undefined ? props.myAgentLists : []}
                                    onChange={(e: any) => {
                                        if (props.returnAgentNumberAndCompany !== undefined) {
                                            props.returnAgentNumberAndCompany(e.target.value)
                                            setCallCount(callCount + 1)
                                        }
                                    }} />
                                <br />
                            </props.Col>
                        </Form>
                    }}
                </Formik>
                <props.Col sm="6" md="6" xs="12">
                    <props.DL condition={!isEmptyStr(props.agentNumber) && !isEmptyStr(props.companyCode)} className="relateinfo dd">
                        <props.DLI title="common.lbl.streetAddress" desc={() => {
                            return <>
                                {props.agentPersonalInfo.streetAddresses.map((row: any, index: number) => {
                                    if (isEmptyStr(row)) {
                                        return <div key={index}></div>
                                    } else {
                                        return <React.Fragment key={index}>{row}<br /></React.Fragment>
                                    }
                                })}
                            </>
                        }}></props.DLI>
                        <props.DLI title="common.lbl.city" desc={props.agentPersonalInfo.city}></props.DLI>
                        <props.DLI title="common.lbl.stateCountry" desc={props.agentPersonalInfo.stateName + " " + props.agentPersonalInfo.countryCode}></props.DLI>
                        <props.DLI title="pi.zip.code" desc={props.agentPersonalInfo.zipCode}></props.DLI>
                    </props.DL>
                </props.Col>
                <props.Col sm="6" md="6" xs="12">
                    <props.DL condition={!isEmptyStr(props.agentNumber) && !isEmptyStr(props.companyCode)} className="relateinfo dd">
                        <props.DLI title="pi.phone.cell" desc={props.agentPersonalInfo.cellPhone}></props.DLI>
                        <props.DLI title="pi.phone.home" desc={props.agentPersonalInfo.homePhone}></props.DLI>
                        <props.DLI title="pi.phone.business" desc={props.agentPersonalInfo.businessPhone}></props.DLI>
                        <props.DLI title="pi.phone.facsimile" desc={props.agentPersonalInfo.facsimilePhone}></props.DLI>
                        <props.DLI title="pi.prefered.contract.method" desc={() => {
                            for (let rowData of props.contactMethods) {
                                if (props.agentPersonalInfo.contactMethod === rowData.value) {
                                    return rowData.name
                                }
                            }
                        }}></props.DLI>
                    </props.DL>
                </props.Col>
                <props.Col condition={!isEmptyStr(props.agentNumber) && !isEmptyStr(props.companyCode)} sm="12" md="12" xs="12">
                    <div className="emailSplit"></div>
                </props.Col>
                {props.agentPersonalInfo.emails.map((row: any, index: number) => {
                    return <>
                        <props.Col key={index} sm="6" md="6" xs="12">
                            <props.DL className="relateinfo dd">
                                <props.DLI title={() => {
                                    return <>
                                        <GlobalizedText message="pi.email.address" />{" " + (index + 1)}
                                    </>
                                }} desc={props.agentPersonalInfo.emails[index]}></props.DLI>
                            </props.DL>
                        </props.Col>
                    </>
                })}
                <props.Col sm="12" md="12" xs="12">
                    <props.Button id="cancelUpdatePersonalInfo" onClick={
                        () => {
                            let res: Array<string> = []
                            res.push(props.agentNumber)
                            res.push(props.companyCode)
                            saveToStorage("closeFromAgentInfo", res, true);
                            props.back();
                        }
                    }><GlobalizedText message="common.lbl.close" /></props.Button>
                </props.Col>
            </props.Row>
        </>
        }

        {
            (isUpdateMode === true && !isEmptyStr(props.agentNumber)) && <>
                <props.Row>
                    <props.Col xs="12">
                        <props.Div className="gwp-innertitle  gwp-bold">
                            <GlobalizedText message="pi.contact.info" />
                        </props.Div>
                    </props.Col>
                    <props.Col id="contactWarn" xs="12">
                        <props.Span className="glyphicon glyphicon-exclamation-sign warnInfo" aria-hidden="true"></props.Span>{' '}
                        <props.Span className="warnInfo">
                            <GlobalizedText message="pi.msg.update.tip" />
                        </props.Span>
                    </props.Col>
                </props.Row>
                <Formik initialValues={defaultInitialValues}
                    enableReinitialize={true}
                    validate={formValidate}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={formSubmit}
                >
                    {formProps => {
                        AgentPersonalContactInfoForm.current = formProps
                        return <Form id="personalContactInfo">
                            <props.Row>
                                <props.Col condition={props.myAgentLists !== undefined} sm="12" md="12" xs="12">
                                    <props.Div className="information gwp-information">
                                        <props.Span className="glyphicon glyphicon-exclamation-sign"></props.Span>{" "}<GlobalizedText message="pi.multipleAgentExist" />
                                    </props.Div>
                                    <props.SelectControl id="selectedMyAgent" name="myAgent" label="common.lbl.agent" sort={false} options={props.myAgentLists !== undefined ? props.myAgentLists : []}
                                        onChange={(e: any) => {
                                            if (props.returnAgentNumberAndCompany !== undefined) {
                                                setIsUpdateMode(false)
                                                props.returnAgentNumberAndCompany(e.target.value)
                                            }
                                        }} />
                                    <br />
                                </props.Col>
                                <props.Col sm="6" md="6" xs="12">
                                    <props.TextControl id="streetAddress0" name="streetAddress0" required={true} label="common.lbl.streetAddress"
                                    ></props.TextControl>
                                    <props.TextControl id="streetAddress1" name="streetAddress1"
                                    ></props.TextControl>
                                    <props.TextControl id="streetAddress2" name="streetAddress2"></props.TextControl>
                                    <props.TextControl id="city" name="city" required={true} label="common.lbl.city"></props.TextControl>
                                    <props.SelectControl id="stateContry" name="stateContry" required={true} defaultOption={''} label="common.lbl.stateCountry" options={defaultState2.current}
                                        onChange={(e: any) => {
                                            enableZipCodeRequired(e.target.value)
                                            formProps.setErrors({})
                                            formProps.setFieldValue('zipCode', '')
                                            validateProps.clearMessage()
                                            setIsUpdateMode(true)
                                        }} />
                                    <props.TextControl id="zipCode" name="zipCode" required={zipcodeRequirued !== null && zipcodeRequirued === true} label="pi.zip.code"></props.TextControl>
                                </props.Col>
                                <props.Col sm="6" md="6" xs="12">
                                    <props.TextControl id="cellNumber" name="cellNum" required={false} label="pi.phone.cell"></props.TextControl>
                                    <props.TextControl id="honeNumber" name="homeNum" required={false} label="pi.phone.home"></props.TextControl>
                                    <props.TextControl id="busiNumber" name="busiNum" required={false} label="pi.phone.business"></props.TextControl>
                                    <props.TextControl id="facNumber" name="facNum" required={false} label="pi.phone.facsimile"></props.TextControl>
                                    <props.SelectControl id="conMeth" name="conMeth" required={false} defaultOption={"Undecided"} label="pi.prefered.contract.method" options={props.contactMethods}>
                                    </props.SelectControl>
                                </props.Col>
                            </props.Row>
                            <props.HR />
                            <props.Row id="emailLabel">
                                <props.Col sm="6" md="6" xs="12">
                                    <props.Label><GlobalizedText message="pi.email.address" /> 1</props.Label>
                                    <props.Link id="addEmail" to="#" onClick={() => { setShowEmail2(true) }}>
                                        <props.Icon type="add" />
                                        <GlobalizedText message="common.lbl.add" />
                                    </props.Link>
                                    <props.TextControl id="email1" name="email1" required={false}></props.TextControl>
                                </props.Col>
                                <props.Col sm="6" md="6" xs="12" condition={(!isEmptyStr(formProps.values.email2) || showEmail2 === true)} className="emailControl">
                                    <props.Label><GlobalizedText message="pi.email.address" /> 2</props.Label>
                                    <props.Link id="deleteEmail" to="#" onClick={() => {
                                        setShowEmail2(false)
                                        AgentPersonalContactInfoForm.current.setFieldValue('email2', '')
                                    }}
                                    >
                                        <props.Icon type="delete" />
                                        <GlobalizedText message="common.lbl.delete" />
                                    </props.Link>
                                    <props.TextControl id="email2" name="email2" required={false}></props.TextControl>
                                </props.Col>
                            </props.Row>
                            <br />
                            <props.Row>
                                <props.Col sm="12" md="12" xs="12">
                                    <props.Button id="updatePersonalInfo" type="submit" className="form-pi btn btn-primary gwp-btn"><GlobalizedText message="common.lbl.save" /></props.Button>
                                    <props.Button id="cancelUpdatePersonalInfo" onClick={() => { setIsUpdateMode(false) }}><GlobalizedText message="common.lbl.close" /></props.Button>
                                </props.Col>
                            </props.Row >
                        </Form>
                    }}
                </Formik>
            </>
        }

    </div >
}