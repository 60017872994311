import { FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import GlobalizedText from "../../shared/globalization";
import { ajax, isEmptyStr, removeFromArray } from "../../shared/utils";
import Enrollment from '../types/enrollmenttypes';
import { RequiredHeader } from "./enrollment";

interface Option {
    name: string
    value: string
}

interface Beneficiary {
    beneficiaryIdx: number
    beneficiaryId: string
    beneficiaryName: string
    contingent: string
    coverage: string
    dateOfBirth: string
    dateRevoke: string
    effdate: string
    irrevocable: string
    percent: string
    relationship: string
    terminationDate: string
    trustee: string
    isOldBeneficiary: string
    coverageDesc: string
    relationshipDesc: string
}

const defaultInitialValues: Beneficiary = {
    beneficiaryIdx: 0,
    beneficiaryId: '',
    beneficiaryName: '',
    contingent: '',
    coverage: '',
    dateOfBirth: '',
    dateRevoke: '',
    effdate: '',
    irrevocable: '',
    percent: '',
    relationship: '',
    terminationDate: '',
    trustee: '',
    isOldBeneficiary: 'N',
    coverageDesc: '',
    relationshipDesc: '',
};

interface BeneficiaryViewResponse {
    beneficiaryList: Array<Beneficiary>
    beneficiaryCoverageList: Array<Option>
    relationshipList: Array<Option>
    securityMap: any
    hasLifePeriod: boolean
}

export const Beneficiary = (props: Enrollment.Props) => {

    const [config, setConfig] = useState<BeneficiaryViewResponse>();
    const beneficiaryForm = useRef<any>();

    const beneficiaryValidate = (values: Beneficiary, index: number, result: any): any => {
        let requiredMsg = " " + props.getGlobalizedText("message_information_required");

        if (isEmptyStr(values.coverage)) {
            result[`beneficiarys[${index}].coverage`] = props.getGlobalizedText("lb_coverage") + requiredMsg;
        }

        if (isEmptyStr(values.beneficiaryId)) {
            result[`beneficiarys[${index}].beneficiaryId`] = props.getGlobalizedText("beneficiaryid") + requiredMsg;
        }
        if (values.beneficiaryId.length > 10) {
            result[`beneficiarys[${index}].beneficiaryId`] = props.getGlobalizedText("common.lbl.beneIDNumbeTooLong");
        }

        if (isEmptyStr(values.beneficiaryName)) {
            result[`beneficiarys[${index}].beneficiaryName`] = props.getGlobalizedText("beneficiaryname") + requiredMsg;
        }

        if (isEmptyStr(values.relationship)) {
            result[`beneficiarys[${index}].relationship`] = props.getGlobalizedText("pi.relation") + requiredMsg;
        }

        if (!isEmptyStr(values.dateOfBirth) && new Date(values.dateOfBirth).getTime() > new Date().getTime()) {
            result[`beneficiarys[${index}].dateOfBirth`] = props.getGlobalizedText("common.lbl.birthdateValidate");
        }

        if (isEmptyStr(values.effdate)) {
            result[`beneficiarys[${index}].effdate`] = props.getGlobalizedText("common.lbl.effectiveDate") + requiredMsg;
        }

        if (!isEmptyStr(values.effdate) && !isEmptyStr(values.terminationDate) && new Date(values.terminationDate).getTime() < new Date(values.effdate).getTime()) {
            result[`beneficiarys[${index}].terminationDate`] = props.getGlobalizedText("message.form.EffdateLaterthanTermination");
        }

        if (isEmptyStr(values.percent)) {
            result[`beneficiarys[${index}].percent`] = props.getGlobalizedText("percent") + requiredMsg;
        }
        if (!isEmptyStr(values.percent) && /^\d+(\.\d+)?$/.test(values.percent) == false) {
            result[`beneficiarys[${index}].percent`] = props.getGlobalizedText("message.form.invalidNumberFormat");
        }
        if (!isEmptyStr(values.percent) && parseFloat(values.percent) < -0.00000001) {
            result[`beneficiarys[${index}].percent`] = props.getGlobalizedText("message.form.mustbepositive");
        }
        if (!isEmptyStr(values.percent) && parseFloat(values.percent) > 100.00000001) {
            result[`beneficiarys[${index}].percent`] = props.getGlobalizedText("message.form.mustlowerthan100");
        }

        return result;
    }

    const formValidate = (values: any): any => {
        let result: any = {};
        props.formValue.beneficiarys.map((beneficiary: any, index: number) => {
            beneficiaryValidate(beneficiary, index, result);
        })
        return result;
    }

    props.setValidation(formValidate);

    useEffect(() => {
        if (props.formValue === undefined || config === undefined) {
            let params = {
                transactionID: props.transactionID
            };
            ajax({
                url: '/api/enrollment/beneficiary/view',
                params: params,
                success: (res: BeneficiaryViewResponse) => {
                    if (res.beneficiaryList != null && res.beneficiaryList.length === 0) {
                        props.setFormValue({
                            beneficiarys: []
                        });
                    } else {
                        props.setFormValue({ beneficiarys: res.beneficiaryList });
                    }
                    setConfig(res);
                }
            });
        }
    }, [props.formValue])

    function addBeneficiary() {
        let beneficiarys = props.formValue.beneficiarys;
        defaultInitialValues.beneficiaryIdx = beneficiarys.length + 1;
        beneficiarys.push(defaultInitialValues);
        props.setFormValue(props.formValue);
    }

    function removeBeneficiary(index: number) {
        let beneficiarys = props.formValue.beneficiarys;
        beneficiarys = removeFromArray(beneficiarys, index);
        props.formValue.beneficiarys = beneficiarys;
        props.setFormValue(props.formValue);
    }

    props.setPrepareData((values: any) => {
        return {
            "beneficiarys": values.beneficiarys
        }
    });

    props.setOperationCallback({
        next: {
            fail: (res: any, message: string): void => {
                props.showFieldError("homepage.errorFields", convertErrorMessages(res.validMessage), beneficiaryForm.current);
            }
        }
    });

    const convertErrorMessages = (errorMessages: Array<any>): any => {
        let result: any = {};
        errorMessages.forEach((e: any) => {
            result[e.fieldId] = e.message;
        });
        return result;
    }

    return <>
        <props.Panel>
            <props.PanelHeading>
                <RequiredHeader label="beneficiary_title" {...props} />
                <props.Button containerClassName="gwp-float-right" condition={config?.hasLifePeriod || config?.securityMap.addUpdateBeneficiary}
                    disabled={!config?.securityMap.addUpdateBeneficiary || !config?.securityMap.displayBeneficiaryInformation}
                    onClick={addBeneficiary}><GlobalizedText message="beneficiary_add" /></props.Button>
            </props.PanelHeading>
            <props.PanelBody>
                <FieldArray name="beneficiarys" render={arrayHelpers => {
                    beneficiaryForm.current = arrayHelpers.form
                    if (props.formValue === undefined || props.formValue.length === 0) {
                        return <></>;
                    }
                    return props.formValue.beneficiarys.map((beneficiary: Beneficiary, index: number) => {
                        return <React.Fragment key={index}>
                            <props.Row>
                                <props.Col xs="12" md="12" sm="12" className="hidden-lg">
                                    <props.Button containerClassName="gwp-float-right" id={`beneficiarys[${index}].removeBeneficiaryBtn`} name={`beneficiarys[${index}].removeBeneficiaryBtn`} condition={beneficiary.isOldBeneficiary != 'Y'} onClick={() => { removeBeneficiary(index) }}><GlobalizedText message="beneficiary_delete" /></props.Button>
                                </props.Col>

                                <props.Col xs="12" md="5" sm="5">
                                    <props.SelectControl arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].coverage`} name={`beneficiarys[${index}].coverage`} required={true} disabled={beneficiary.isOldBeneficiary == 'Y'} label="lb_coverage" sort={true} defaultOption="common.lbl.pleaseselect"
                                        options={config?.beneficiaryCoverageList}></props.SelectControl>
                                    <props.TextControl arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].beneficiaryId`} name={`beneficiarys[${index}].beneficiaryId`} required={true} disabled={beneficiary.isOldBeneficiary == 'Y'} label="beneficiaryid" />
                                    <props.TextControl arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].beneficiaryName`} name={`beneficiarys[${index}].beneficiaryName`} required={true} disabled={beneficiary.isOldBeneficiary == 'Y'} label="beneficiaryname" />

                                    <props.SelectControl arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].relationship`} name={`beneficiarys[${index}].relationship`} required={true} disabled={beneficiary.isOldBeneficiary == 'Y'} label="pi.relation" sort={true} defaultOption="common.lbl.pleaseselect"
                                        options={config?.relationshipList}></props.SelectControl>
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].dateOfBirth`} name={`beneficiarys[${index}].dateOfBirth`} required={false} disabled={beneficiary.isOldBeneficiary == 'Y'} label="common.lbl.dob" dateformat={props.getDateFormat()} max={new Date()} />

                                </props.Col>
                                <props.Col xs="12" md="6" sm="6">
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].effdate`} name={`beneficiarys[${index}].effdate`} required={true} disabled={beneficiary.isOldBeneficiary == 'Y'} label="common.lbl.effectiveDate" dateformat={props.getDateFormat()} />
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].terminationDate`} name={`beneficiarys[${index}].terminationDate`} required={false} disabled={beneficiary.isOldBeneficiary == 'Y'} label="common.lbl.terminationDate" dateformat={props.getDateFormat()} />
                                    <props.TextControl arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].trustee`} name={`beneficiarys[${index}].trustee`} disabled={beneficiary.isOldBeneficiary == 'Y'} label="trustee" />

                                    <props.CheckboxControl className="beneficiaryChkBox" arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].irrevocable`} name={`beneficiarys[${index}].irrevocable`} value={"true"} disabled={beneficiary.isOldBeneficiary == 'Y'} label="irrevocable" />
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].dateRevoke`} name={`beneficiarys[${index}].dateRevoke`} disabled={beneficiary.isOldBeneficiary == 'Y'} label="daterevoked" dateformat={props.getDateFormat()} />

                                    <props.CheckboxControl className="beneficiaryChkBox" arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].contingent`} name={`beneficiarys[${index}].contingent`} value={"true"} disabled={beneficiary.isOldBeneficiary == 'Y'} label="contingent" />

                                    <props.InputAddonControl arrayHelpers={arrayHelpers} id={`beneficiarys[${index}].percent`} name={`beneficiarys[${index}].percent`} disabled={false} required={true} label="percent" addonMsg="%"></props.InputAddonControl>
                                </props.Col>
                                <props.Col xs="12" md="1" sm="1" className="hidden-md hidden-sm hidden-xs">
                                    <props.Button containerClassName="gwp-float-right" id={`beneficiarys[${index}].removeBeneficiaryBtnBottom`} name={`beneficiarys[${index}].removeBeneficiaryBtnBottom`} condition={beneficiary.isOldBeneficiary != 'Y'} onClick={() => { removeBeneficiary(index) }}><GlobalizedText message="beneficiary_delete" /></props.Button>
                                </props.Col>
                            </props.Row>
                            <props.HR />
                        </React.Fragment>
                    })
                }
                } />
            </props.PanelBody>
        </props.Panel>
    </>
}