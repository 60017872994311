import React, { useEffect, useState } from 'react';
import { UserContext } from '../../../../shared/authentication';
import { Button, DL, DLI, Modal } from '../../../../shared/components';
import GlobalizedText from '../../../../shared/globalization';
import { FooterProps } from '../../../../shared/theme';
import { ajax, getContextPath, win } from '../../../../shared/utils';
import facebook from './images/facebook.png';
import googlePlus from './images/google-plus.png';
import linkedin from './images/linkedin.png';
import twitter from './images/twitter.png';
import youtube from './images/youtube.png';
import { SubmitYourInquiry } from './submitinquiry';



export function Footer(props: FooterProps) {

    const [version, setVersion] = useState<any>();
    useEffect(() => {
        if (version === undefined) {
            ajax({
                url: '/api/version',
                success: (res) => {
                    setVersion(res);
                },
                error: (error: any) => {
                    setVersion({});
                },
                showMask: false,
            });
        }
    }, [version]);

    if (version === undefined) {
        return <></>
    }



    return <>
        <footer className="foot-container">
            <div className="container">
                <div id="foot-copyright">
                    <p>&copy; <GlobalizedText message="common.lbl.copyright" /><a href="#root" data-toggle="modal" data-target="#version_popup" className="f-version">**</a></p>
                </div>
            </div>
        </footer>
        <SubmitYourInquiry user={props.user}></SubmitYourInquiry>
        <Modal title="common.lbl.portalversion" id="version_popup" footer={() => {
            return <>
                <Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></Button></>
        }}>
            <DL>
                <DLI condition={version.productVersion !== undefined} title="common.lbl.productversion" desc={version.productVersion} descId="productVersion"></DLI>
                <DLI condition={version.buildNumber !== undefined} title="common.lbl.buildnumber" desc={version.buildNumber} descId="buildNumber"></DLI>
                <DLI condition={version.buildTime !== undefined} title="common.lbl.buildtime" desc={version.buildTime} descId="buildTimestamp"></DLI>
            </DL>
        </Modal>
    </>
}
