import React from "react";
import GlobalizedText from "../shared/globalization";
import { ViewComponentProps, withView } from "../shared/viewcomponent";

import './css/releaseinformation.css'

export const ReleaseInformationComponent = withView((props: ViewComponentProps) => {
    return <>
        <props.LinkButton onClick={() => props.showModal("#featureInformation")}>Feature List</props.LinkButton>

        <hr />

        <h2>Extension</h2>
        <dl>
            <dt>06/26/2021</dt>
            <dd><span><a href="xxx">GWPBASE-3255</a></span>Modifications for CLIC. </dd>
        </dl>

        <h2>Base</h2>

        <dl>
            <dt>06/25/2021</dt>
            <dd><span><a href="xxx">GWPBASE-3232</a></span>Add feature flag for hidden fileds </dd>
            <dd><span><a href="xxx">GWPBASE-3234</a></span>Add feature flag for wallet.</dd>
            <dd><span><a href="xxx">GWPBASE-3235</a></span>Add feature flag for worksite.</dd>
            <dd><span><a href="xxx">GWPBASE-3236</a></span>Add feature flag for policy notes.</dd>
        </dl>

        <hr />

        <dl>
            <dt>05/25/2021</dt>
            <dd>Empower 5.0 released.</dd>
        </dl>

        <props.Modal id="featureInformation" title="Feature List" footer={() => <>
            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
        </>}>
            <table className="table table-striped table-bordered dt-responsive dataTable dtr-inline">
                <caption>Shows the feature status.</caption>
                <thead>
                    <tr>
                        <th>Feature</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Billing Payment Inquiry Premium Display</td>
                        <td>ON</td>
                    </tr>
                    <tr>
                        <td>Wallet</td>
                        <td>OFF</td>
                    </tr>
                    <tr>
                        <td>Worksite</td>
                        <td>OFF</td>
                    </tr>
                    <tr>
                        <td>Hidden Fields</td>
                        <td>OFF</td>
                    </tr>
                </tbody>
            </table>
        </props.Modal>
    </>
});