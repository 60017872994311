import React, { useEffect, useRef, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import { ajax, downloadFile } from '../shared/utils';

export const ExportUserListComponent = withView((props: ViewComponentProps) => {

    const [config, setConfig] = useState<any | null>(null);
    const [disbaledBtn, setDisbaledBtn] = useState<boolean>(false);
    const [disbaledDownloadBtn, setDisbaledDownloadBtn] = useState<boolean>(false);
    const [infoMsg, setInfoMsg] = useState<string>("");
    const timer = useRef<any>();
    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/exportUserList/view',
                method: 'GET',
                success: (data: any) => {
                    if(data.lastModifiedDate !== undefined){
                        
                        let msg = props.getGlobalizedText("export.msg.success1") + " " + data.lastModifiedDate + props.getGlobalizedText("export.msg.success2")
                        setInfoMsg(msg);
                    }else{
                        setInfoMsg("export.msg.noFile");
                        setDisbaledDownloadBtn(true);
                    }
                }
            })
        }
    }, [config]);
    function exportUserList() {
        setDisbaledBtn(true);
        // disbaledBtn = true;
        ajax({
            url: '/api/exportUserList/export',
            method: 'GET',
            success: () => {
                props.clearMessage();
                props.showMessage("success", props.getGlobalizedText("export.msg.procceed"));
                buildTimer();
            }
        })
    }
    function buildTimer() {
        if (timer.current !== undefined)
            clearInterval(timer.current);
        timer.current = setInterval(() => {
            ajax({
                url: "/api/exportUserList/checkCompletedForExport",
                method: 'GET',
                success: (data: any) => {
                    if (data.status == 'S') {
                        props.clearMessage();
                        clearInterval(timer.current);
                        let msg = props.getGlobalizedText("export.msg.success1") + " " + data.lastModifiedDate + props.getGlobalizedText("export.msg.success2")
                        setInfoMsg(msg);
                        setDisbaledBtn(false);
                        setDisbaledDownloadBtn(false);
                    } else if (data.status == 'F') {
                        props.clearMessage();
                        props.showMessage("error", props.getGlobalizedText("export.msg.failed"));
                        clearInterval(timer.current);
                        setDisbaledBtn(false);
                    } else if (data.status == 'P' || data.status == 'I') {
                        props.showMessage("success", props.getGlobalizedText("export.msg.procceed"));
                        setDisbaledBtn(true);
                    } else if (data.status == 'N') {
                        props.clearMessage();
                        clearInterval(timer.current);
                        setDisbaledBtn(false);
                    }
                }
            });
        }, 10000);
    }
    return <>
        <props.Row>
            <props.Col xs="12" sm="12" md="12">
                <props.Information message={infoMsg} showExclamationSign={false} /></props.Col>
        </props.Row>
        <props.Row>
            <props.Col xs="12" sm="12" md="12">
                <props.Button disabled={disbaledBtn} onClick={() => {
                    exportUserList()
                }}><GlobalizedText message="common.lbl.createUserList" /></props.Button>

                <props.Button  disabled={disbaledDownloadBtn} onClick={() => {
                    downloadFile("/api/exportUserList/downloadExcel")
                }}><GlobalizedText message="common.lbl.download" /></props.Button>

            </props.Col>
        </props.Row>
    </>
});
