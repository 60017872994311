import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { HelpBanner } from '../shared/helpbanner';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import * as yup from 'yup';
import { validate } from '../shared/utils';
export const AddPersonComponent = withView((props:ViewComponentProps)=>{

    let role = props.getParam("role");

    useEffect(()=>{
        if(role==='beneficiary'){
            props.setTitle(props.getGlobalizedText("addbene.lbl.title"));
        }else if(role==='owner'){
            props.setTitle(props.getGlobalizedText("changeow.lbl.title"));
        }
    })

    let validationSchema = yup.object().shape({
        beneType:yup.string().required("New Beneficiary Type is required."),
        nameType:yup.string().required("Name Type is required."),
        firstName:yup.string().required("First Name is required."),
        lastName:yup.string().required("Last Name is required."),
        dateOfBirth:yup.string().required("Date of birth is required."),
    });

    return <>
        <Formik initialValues={{
            beneType:'',
            nameType:'',
            firstName:'',
            lastName:'',
            dateOfBirth:'',
            govId:''
        }}
        validate={(values)=>{
            return validate(validationSchema,values, props);
        }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values,{setSubmitting})=>{}}
        >
        {formProps=>
        <Form>
        <props.Row>
            <props.Col sm="12" md="8">
                <props.Information message="changeow.msg.info.start"></props.Information>
                <props.SelectControl name="beneType" label="addbene.lbl.choseBeneType" required={true} options={[
                    {name:'All',value:'0'}
                ]}></props.SelectControl>
                <props.SelectControl name="nameType" label="addbene.lbl.choseType" required={true} options={[
                    {name:'All',value:'0'}
                ]}></props.SelectControl>
            </props.Col>
            <props.Col sm="12" md="4">
                <HelpBanner {...props}></HelpBanner>
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col sm="12" md="3">
                <props.TextControl id="firstName" name="firstName" label="common.lbl.firstName" required={true}/>
            </props.Col>
            <props.Col sm="12" md="3">
                <props.TextControl id="lastName" name="lastName" label="common.lbl.lastName" required={true}/>
            </props.Col>
            <props.Col sm="12" md="3">
                <props.DateTimePickerControl htmlFor="dateOfBirth_input" id="dateOfBirth" name="dateOfBirth" label="common.lbl.dob" dateformat="MM/dd/yyyy" required={true}/>
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col sm="12">
                <props.TextControl id="govId" name="govId" label="common.lbl.govID"/>
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col sm="12">
                <props.Button type="submit"><GlobalizedText message="common.button.continue"/></props.Button>
                <props.Button onClick={()=>{props.back()}}><GlobalizedText message="common.lbl.cancel"/></props.Button>
            </props.Col>
        </props.Row>
        </Form>
        }
        </Formik>
    </>
});