import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../shared/authentication';
import { Button, DL, DLI, Modal } from '../../../../shared/components';
import GlobalizedText from '../../../../shared/globalization';
import { ajax, getContextPath } from '../../../../shared/utils';
import facebook from './images/facebook.png';
import googlePlus from './images/google-plus.png';
import linkedin from './images/linkedin.png';
import twitter from './images/twitter.png';
import youtube from './images/youtube.png';
import { SubmitYourInquiry } from './submitinquiry';



export function Footer() {

    const [version, setVersion] = useState<any>();
    const user = useContext(UserContext);

    useEffect(() => {
        if (version === undefined) {
            ajax({
                url: '/api/version',
                success: (res) => {
                    setVersion(res);
                },
                error: (error: any) => {
                    setVersion({});
                },
                showMask: false,
            });
        }
    }, [version]);

    if (version === undefined) {
        return <></>
    }



    return <>
        <footer className="foot-container">
            <div className="container">
                <div id="foot-copyright">
                    <p>&copy; Copyright Concentrix Insurance Administration Solutions Corporation 2014 All Rights Reserved.<a href="#top" data-toggle="modal" data-target="#version_popup" className="f-version">**</a></p>
                    <a className="f-split" href="#top">Home</a>
                    <a className="f-split" href="https://www.concentrix.com/about-us/company/">About Us</a>
                    <a className="f-split" href="#top" title="Privacy">Privacy</a>
                    <a className="f-split" href="#top" title="Terms">Terms</a>
                    <a className="f-split" href="https://www.concentrix.com/about-us/newsroom/">News</a>
                    <a className="f-split" href={`${process.env.PUBLIC_URL}/faq`}>FAQ</a>
                    <a className="f-split" href="#top" title="Make This My Home Page" id="theme_make_my_workspace" >Make This My Home Page</a>
                    <a className="f-split hidden" href="#top" id="theme_make_my_workspace_2" title="Please update profile first." >Make This My Home Page</a>
                    <a id="theme_submit_inquiry_footer" data-toggle="modal" data-target="#theme_inquiry_popup" href="#top">Submit Your Inquiry</a>
                </div>
            </div>
        </footer>
        {user === null &&
            <SubmitYourInquiry></SubmitYourInquiry>
        }
        {user !== null &&
            <SubmitYourInquiry></SubmitYourInquiry>
        }
        <Modal title="common.lbl.portalversion" id="version_popup" footer={() => {
            return <Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></Button>
        }}>
            <DL>
                <DLI condition={version.productVersion !== undefined} title="common.lbl.productversion" desc={version.productVersion} descId="productVersion"></DLI>
                <DLI condition={version.buildNumber !== undefined} title="common.lbl.buildnumber" desc={version.buildNumber} descId="buildNumber"></DLI>
                <DLI condition={version.buildTime !== undefined} title="common.lbl.buildtime" desc={version.buildTime} descId="buildTimestamp"></DLI>
            </DL>
        </Modal>
    </>
}
