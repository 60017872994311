import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useMessage } from '../shared/message';
import { ajax, copyObjectExcept, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/managefaqquestion.css';
import { FAQ, loadCategories } from './types/faq';
import * as yup from 'yup';
import { YupSchema } from '../shared/yupschema';
import GlobalizedText from '../shared/globalization';
import { closeExtraMenu } from '../shared/components';
import { SecurityContext } from '../context/securityContext';
import { UserTypeCode } from '../shared/constants/userTypeCode';

/**
 * Component of Manage FAQ Question
 */
export const ManageFAQQuestionComponent = withView((props: ViewComponentProps) => {

    const [categories, setCategories] = useState<Array<FAQ.Category>>();
    const { messageService, validateProps } = useMessage("faqQuestion");
    const [contentId, setContentId] = useState<string>();
    const contentType = useRef<string>();
    const allowedUserTypes = [UserTypeCode.PORTAL_ADMIN, UserTypeCode.CONTENT_MANAGEMENT_ADMIN];
    const {userAuth} = useContext(SecurityContext);
    const isUserNotAuthorized = (!userAuth || (userAuth.userTypeRegCode && !allowedUserTypes.includes(userAuth.userTypeRegCode)));
    useEffect(() => {
        if (categories === undefined && !isUserNotAuthorized) {
            loadCategories(setCategories, (id: string) => { contentType.current = id });
        }
    }, [categories])

    useEffect(() => {
        if (contentId !== undefined && !isUserNotAuthorized) {
            props.showModal("#createNewQuestion");
        }
    }, [contentId]);

    if(isUserNotAuthorized)
        return <></>
    function openCreateNewQuestion() {
        ajax({
            url: '/api/cms/content/init',
            success: (res) => {
                setContentId(res.id);
            }
        });
    }
    const validateSchema = yup.object().shape(YupSchema.faq);
    return <>
        <props.Button disabled={categories === undefined || categories.length === 0} onClick={openCreateNewQuestion}><GlobalizedText message="common.lbl.createNewQuestion" /></props.Button>
        <props.Button onClick={() => { props.next("/manageFaqCategory") }}><GlobalizedText message="common.lbl.manageCategory" /></props.Button>
        <props.Button onClick={() => { props.back() }}>Cancel</props.Button>
        <props.Div className="faqQuestionSubTitle"><GlobalizedText message="common.lbl.faqCategory" /></props.Div>
        <ul>
            {(categories !== undefined && categories.length > 0) && categories.map((category: FAQ.Category, index: number) => {
                return <li key={index}>
                    <props.Img src="/static/themes/blue/images/icon_item.png" /><props.Link to={'/questionsForCategory?category=' + category.id}>{category.name}</props.Link>
                </li>
            })}
            {(categories === undefined || categories.length === 0) &&
                <GlobalizedText message="common.msg.noFAQCategory" />
            }
        </ul>
        <Formik initialValues={
            {
                question: '',
                answer: '',
                categories: []
            }
        }
            validateOnBlur={false}
            validateOnChange={false}
            onReset={() => validateProps.clearMessage()}
            validate={(values) => {
                return validate(validateSchema, values, validateProps);
            }}
            onSubmit={(values, formikHelper) => {
                ajax({
                    url: '/api/cms/faq/question/create',
                    data: values,
                    method: 'post',
                    success: () => {
                        props.closeModal("#createNewQuestion");
                        formikHelper.resetForm();
                        props.showMessage("success", "common.msg.createQuestionSuccess");
                        closeExtraMenu();
                    }
                });
            }}
        >
            {formProps =>
                <Form>
                    <props.Modal size="large" messageService={messageService} id="createNewQuestion" title="common.lbl.createNewQuestion" footer={() => {
                        return <>
                            <props.Button type="submit"><GlobalizedText message="common.lbl.submit" /></props.Button>
                            <props.Button onClick={() => { formProps.resetForm(); closeExtraMenu(); }} data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }}>
                        <props.TextControl required={true} id="question" name="question" label="common.lbl.question"></props.TextControl>
                        <props.Label required={true}><GlobalizedText message="common.lbl.answer" /></props.Label>
                        <props.ContentEditor contentId={"faq"} contentTypeId={"faq"} onChange={(content: string) => {
                            formProps.setFieldValue("answer", content);
                        }} {...copyObjectExcept(props, false, "id")} id="answer" name="answer" html={formProps.values.answer}></props.ContentEditor>
                        <props.Label><GlobalizedText message="common.lbl.faqCategoryTitle" /></props.Label>
                        <props.Information showExclamationSign={false} message="common.msg.faqCategoryInformation"></props.Information>
                        <ul className="categoreis">
                            {(categories !== undefined && categories.length > 0) && categories.map((category: FAQ.Category, index: number) => {
                                return <li key={index}>
                                    <props.CheckboxControl id={"category_" + category.id} containerClassName="gwp-inline" value={category.id} name="categories"></props.CheckboxControl><props.Label htmlFor={"category_" + category.id}>{category.name}</props.Label>
                                </li>
                            })}

                        </ul>
                    </props.Modal>
                </Form>
            }
        </Formik>
    </>
})