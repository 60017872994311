import React, { useEffect, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import './css/cognosreport.css';
import { ajax, isEmptyObject, parseBoolean } from '../shared/utils';
import GlobalizedText from '../shared/globalization';

let w: any = window;
export const win = w;
export const $: any = w.$;

export const CognosReportsComponent = withView((props: ViewComponentProps) => {

    const [config, setConfig] = useState<any>();
    const companyCode: string = props.getParam("companyCode");
    const agentNumber: string = props.getParam("agentNumber");
    const policyNumber: string = props.getParam("policyNumber");
    const directReports: boolean = parseBoolean(props.getParam("directReports"));
    const reportKey: string = props.getParam("reportKey");
    const fromPage: string = props.getParam("fromPage");
    const reportCategory: string = props.getParam("reportCategory");
    const thisPage: string = "cognosReport";
    const fromAgentSearch: boolean = parseBoolean(props.getParam("reportCategory"));
    //const [isDir, setIsDir] = useState<boolean>(false);
    let params = {};
    if (isEmptyObject(policyNumber)) {
        params = {
            fromAgentSearch: true,
            companyCode: companyCode,
            agentNumber: agentNumber,
            reportCategory: reportCategory,
            directReports: directReports,
            reportKey: reportKey
        }
    } else {
        params = {
            fromAgentSearch: true,
            companyCode: companyCode,
            agentNumber: agentNumber,
            policyNumber: policyNumber,
            reportCategory: reportCategory,
            directReports: directReports,
            reportKey: reportKey
        }
    }

    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/cognosReport/fetchReport',
                params: params,
                /*{
                    fromAgentSearch: true,
                    companyCode: "CO1",
                    agentNumber: "A13000000",
                    reportCategory: "comm",
                    isDir: true,
                    reportKey: "cogrep_001"
                },*/
                success: (res: any) => {
                    setConfig(res);
                }
            });
        }
    }, [config])

    if (config === undefined) {
        return <></>;
    }

    function iframeLoad(obj: any) {
        if ($('#cr').length == 0) {
            var iframe = $('<iframe></iframe>')
            iframe.attr("id", "cr");
            iframe.attr("src", config.reportUrl);
            iframe.attr("frameBorder", 0);
            iframe.attr("height", window.screen.availHeight);
            iframe.attr("width", "100%");
            iframe.attr("scrolling", "no");
            $('#RerDiv').append(iframe);
        }
    }

    if (!isEmptyObject(config?.messageID)) {
        props.setTitle(props.getGlobalizedText(config.messageID));
    }

    return <>
        <props.Row>
            <props.Col xs="12" sm="12" md="12">
                <div className="report" id="RerDiv">
                    <iframe id="sReportFrameLogOff" className="displayNone" src={config.logOffUrl} onLoad={(e: any) => iframeLoad(e)}></iframe>
                </div>
            </props.Col>
        </props.Row>
    </>
});
