import React, { useState, useEffect } from 'react';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import { ajax, formatNumber } from '../shared/utils';
import './css/benefitdetail.css';
import { TableColumn } from '../shared/components';
import { AgentsInfoPanel } from './agentsinfopanel';
import { ChartColor } from "../shared/components/lib/chart";
import { FeatureFlags } from '../shared/featureFlags';

export const BenefitDetailComponent = withView((props: ViewComponentProps) => {
    const companyCode = props.getParam("companyCode");
    const policyNumber = props.getParam("policyNumber");
    const planCode = props.getParam("planCode");
    const baseRiderCode = props.getParam("baseRiderCode");
    const [fundName, setFundName] = useState<string>('');
    const [insuranceType, setInsuranceType] = useState<string>('');
    const [config, setConfig] = useState<any>();
    const [chartData, setChartData] = useState<Array<any>>([]);
    const [chartLabels, setChartLabels] = useState<Array<any>>([]);
    const [interestFundsVariableTable, setInterestFundsVariableTable] = useState<any>();
    const [interestFundsNonVariableTable, setInterestFundsNonVariableTable] = useState<any>();
    const [eiFundsTable, setEiFundsTable] = useState<any>();
    const [unitBasedFundsTable, setUnitBasedFundsTable] = useState<any>();
    const [loanDetailNoneTaxQualifiedTable, setLoanDetailNoneTaxQualifiedTable] = useState<any>();
    const [loanDetailTaxQualifiedTable, setLoanDetailTaxQualifiedTable] = useState<any>();
    const [additionalCoverageTable, setAdditionalCoverageTable] = useState<any>();
    const [premiumDepositFundTable, setPremiumDepositFundTable] = useState<any>();
    const [fundDetailTable, setFundDetailTable] = useState<any>();
    const [showFederalIncomeTaxWith, setFederalIncomeTaxWith] = useState<boolean>(false);
    const [showStateIncomeTaxWith, setStateIncomeTaxWith] = useState<boolean>(false);
    const [showFederalWithPerc, setFederalWithPerc] = useState<boolean>(false);
    const [showStateWithTaxPcnt, setStateWithTaxPcnt] = useState<boolean>(false);
    const [payoutJointPercentage, setPayoutJointPercentage] = useState<boolean>(false);
    const [contingentBenefit, setContingentBenefit] = useState<boolean>(false);
    const isDisplayAnnuityPayees = props.isFeatureEnabled(FeatureFlags.DISPLAY_ANNUITY_PAYEES);
    const isPayoutInformationSection = props.isFeatureEnabled(FeatureFlags.BENEFIT_DETAIL_PAYOUTINFORMATIONSECTION);
    const isPayoutMoneyOnDepositFundSection = props.isFeatureEnabled(FeatureFlags.BENEFIT_DETAIL_MONEYONDEPOSITFUNDSECTION);

    interface mod {
        calendarYearInfo: number,
        currentBalance: number,
        interestCredited: number,
        interestRate: number,
        totalContribution: number,
        payoutOption: string,

    }
    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/benefitDetail/view',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber,
                    planCode: planCode,
                    baseRiderCode: baseRiderCode
                },
                success: (res: any) => {
                    setConfig(res);
                    if (res.benefitDto.variable) {
                        setInterestFundsVariableTable(res.benefitDto.fundDetails.PI);
                    } else if (!res.benefitDto.variable) {
                        setInterestFundsNonVariableTable(res.benefitDto.fundDetails.PI);
                    }
                    setEiFundsTable(res.benefitDto.fundDetails.E);
                    setUnitBasedFundsTable(res.benefitDto.fundDetails.U);
                    setLoanDetailNoneTaxQualifiedTable(res.benefitDto.loanDetailNoneTaxQualifiedList);
                    setLoanDetailTaxQualifiedTable(res.benefitDto.loanDetailTaxQualifiedList);
                    setAdditionalCoverageTable(res.benefitDto.additionaCovs);
                    if (undefined !== res.benefitDto.premiumDepositFundDto && null !== res.benefitDto.premiumDepositFundDto) {
                        let mods: Array<mod> = [{
                            calendarYearInfo: res.benefitDto.premiumDepositFundDto.calendarYearInfo,
                            currentBalance: res.benefitDto.premiumDepositFundDto.currentBalance,
                            interestCredited: res.benefitDto.premiumDepositFundDto.interestCredited,
                            interestRate: res.benefitDto.premiumDepositFundDto.interestRate,
                            totalContribution: res.benefitDto.premiumDepositFundDto.totalContribution,
                            payoutOption: res.benefitDto.payoutOption,
                        }];
                        setPremiumDepositFundTable(mods);
                    }
                    populateChartParam(res);
                    setInsuranceType(res.benefitDto.insuranceType);
                }
            });
        }
    }, [config]);
    if (config === undefined) {
        return <></>;
    }
    let limitTableOption = {
        bPaginate: false,
        bSort: false,
        bInfo: false,
        language: { emptyTable: '' },
    };
    let fundTableOption = {
        bPaginate: false,
        bSort: false,
        bInfo: false,
        language: { emptyTable: props.getGlobalizedText("common.no.data") },
        paging: false,
        bLengthChange: false,
        scrollY: "200px",
        scrollCollapse: true,
        searching: false,
        autowidth: false,
    };
    function formatRate(formValue: number): any {
        return (formValue / 100).toFixed(5);
    }
    function addPercSign(formValue: number, byte: number): any {
        return formValue.toFixed(byte) + "%";
    }
    function showDurationTime(): boolean {
        return (config.benefitDto.payoutOptionCode === "IA" ||
            config.benefitDto.payoutOptionCode === "IP" ||
            config.benefitDto.payoutOptionCode === "FP" ||
            config.benefitDto.payoutOptionCode === "JP" ||
            config.benefitDto.payoutOptionCode === "LP");
    }
    function populateChartParam(inputRes: any) {
        let data = inputRes.fundValueData.data;
        let labels = inputRes.fundValueData.labels;
        let chartData = [];
        let chartLabels = [];
        for (var i = 0; i < data.length; i++) {
            chartData.push(parseFloat(data[i]));
            chartLabels.push(labels[i]);
        }
        setChartData(chartData);
        setChartLabels(chartLabels);
    }

    function showWithHolding(): boolean {
        let federalIncomeTaxWith = config.benefitDto.federalIncomeTaxWith;
        let stateIncomeTaxWith = config.benefitDto.stateIncomeTaxWith;
        let federalWithPerc = config.benefitDto.federalWithPerc;
        let stateWithTaxPcnt = config.benefitDto.stateWithTaxPcnt;
        if (null !== federalIncomeTaxWith && undefined !== federalIncomeTaxWith && 0.00 !== federalIncomeTaxWith) {
            setFederalIncomeTaxWith(true);
        }
        if (null !== stateIncomeTaxWith && undefined !== stateIncomeTaxWith && 0.00 !== stateIncomeTaxWith) {
            setStateIncomeTaxWith(true);
        }
        if (null !== federalWithPerc && undefined !== federalWithPerc && 0.00 !== federalWithPerc) {
            setFederalWithPerc(true);
        }
        if (null !== stateWithTaxPcnt && undefined !== stateWithTaxPcnt && 0.00 !== stateWithTaxPcnt) {
            setStateWithTaxPcnt(true);
        }
        return (showFederalIncomeTaxWith || showStateIncomeTaxWith || showFederalWithPerc || showStateWithTaxPcnt);
    }
    function showJointPerc(): boolean {
        let optionResult = config.benefitDto.payoutOptionCode === "EJ" ||
            config.benefitDto.payoutOptionCode === "JS" ||
            config.benefitDto.payoutOptionCode === "JP";
        let payoutResult: boolean = false;
        let contiResult: boolean = false;
        if (null !== config.benefitDto.payoutJointPercentage &&
            undefined !== config.benefitDto.payoutJointPercentage &&
            0.00 !== config.benefitDto.payoutJointPercentage) {
            setPayoutJointPercentage(true);
            payoutResult = true;
        }
        if (
            null !== config.benefitDto.contingentBenefit &&
            undefined !== config.benefitDto.contingentBenefit &&
            0.00 !== config.benefitDto.contingentBenefit) {
            setContingentBenefit(true);
            contiResult = true;
        }

        return optionResult && (payoutResult || contiResult);
    }

    function showAgentsPanel(): boolean {
        let result = false;
        let userTypeCode = config.userTypeCode;
        result = undefined !== userTypeCode && null !== userTypeCode && "" !== userTypeCode &&
            ('05' === userTypeCode || '08' === userTypeCode || '01' === userTypeCode || '06' === userTypeCode) &&
            undefined !== config.benefitDto.agentList && null !== config.benefitDto.agentList && config.benefitDto.agentList.length > 0;
        return result;
    }
    function openFundDetailsDialog(formProps: any) {
        ajax({
            url: '/api/benefitDetail/loadFundDetails',
            params: {
                companyCode: companyCode,
                policyNumber: policyNumber,
                fundCode: formProps.fundCode,
                baseRiderCode: baseRiderCode,
                fundIdCode: formProps.fundIdCode
            },
            success: (res) => {
                setFundDetailTable(res.policyFundList);
                setFundName(formProps.fundName);
                props.showModal('#fundDetailsDialog')
            }
        });
    }
    function toVap(formProps: any) {
        props.next(`/annuityPayee?companyCode=${companyCode.trim()}&policyNumber=${policyNumber.trim()}&planCode=${planCode.trim()}&baseRiderCode=${baseRiderCode}`);
    }
    const interestFundsVariableTableColumn: Array<TableColumn> = [
        { title: 'fd.title', name: 'fundName', sortable: false, width: '25%' },
        { title: 'fd.lbl.cfb', name: 'curBalance', sortable: false, type: 'numeric' },
        {
            title: 'common.lbl.currentInterestRate', name: 'curInterestRate', sortable: false, type: 'numeric',
            render: (data: any, type: any, row: any, meta: any) => {
                if (row.fundBalanceSegmentation === "C") {
                    let viewDetail = props.getGlobalizedText("pd.lbl.view.detail");
                    return `<div align='center'><a>${viewDetail}</ a><div>`
                } else {
                    return `<div align='center'>${(data).toFixed(4)}%<div>`;
                }
            }, onClick: (e: any, row: any): void => {
                openFundDetailsDialog(row)
            }

        },
        {
            title: 'common.lbl.assetAllocation', name: 'assetAlloc', sortable: false, type: 'numeric', render(data: any) {
                return data === undefined ? addPercSign(0, 4) : addPercSign(data * 100, 4);
            }
        },
        {
            title: 'common.lbl.premiumAllocation', name: 'premAlloc', sortable: false, type: 'numeric', render(data: any) {
                return data === undefined ? addPercSign(0, 4) : addPercSign(data, 4);
            }
        }
    ]

    const interestFundsNonVariableTableColumn: Array<TableColumn> = [
        { title: 'fd.title', name: 'fundName', sortable: false, width: '25%' },
        { title: 'fd.lbl.cfb', name: 'curBalance', sortable: false, type: 'numeric' },
        {
            title: 'common.lbl.currentInterestRate', name: 'curInterestRate', sortable: false, type: 'numeric',
            render: (data: any, type: any, row: any, meta: any) => {
                if (row.fundBalanceSegmentation === "C") {
                    let viewDetail = props.getGlobalizedText("pd.lbl.view.detail");
                    return `<div align='center'><a>${viewDetail}</ a><div>`
                } else {
                    return `<div align='center'>${(data).toFixed(4)}%<div>`;
                }
            }, onClick: (e: any, row: any): void => {
                openFundDetailsDialog(row)
            }

        },
        {
            title: 'common.lbl.premiumAllocation', name: 'premAlloc', sortable: false, type: 'numeric', render(data: any) {
                return data === undefined ? addPercSign(0, 4) : addPercSign(data, 4);
            }
        }
    ]
    const eiFundsTableColumn: Array<TableColumn> = [
        { title: 'fd.title', name: 'fundName', sortable: false },
        { title: 'fd.lbl.cfb', name: 'curBalance', sortable: false, type: 'numeric' },
        {
            title: 'common.lbl.lastPeridRate', name: 'lastIdxPeriodInterestRate', sortable: false, type: 'numeric', render(data: any) {
                return data === undefined ? 0 : data.toFixed(4);
            }
        },
        {
            title: 'common.lbl.currentCap', name: 'currentCap', sortable: false, type: 'numeric', render(data: any) {
                return data === undefined ? 0 : data.toFixed(8);
            }
        },
        {
            title: 'common.lbl.currentSpread', name: 'curSpread', sortable: false, type: 'numeric', render(data: any) {
                return data === undefined ? 0 : data.toFixed(8);
            }
        },
        {
            title: 'common.lbl.assetAllocation', name: 'assetAlloc', sortable: false, type: 'numeric', render(data: any) {
                return data === undefined ? addPercSign(0, 4) : addPercSign(data * 100, 4);
            }
        },
        {
            title: 'common.lbl.premiumAllocation', name: 'premAlloc', sortable: false, type: 'numeric', render(data: any) {
                return data === undefined ? addPercSign(0, 4) : addPercSign(data, 4);
            }
        }
    ]
    const unitBasedFundsTableColumn: Array<TableColumn> = [
        { title: 'fd.title', name: 'fundName', sortable: false },
        { title: 'common.lbl.currentBalance', name: 'curBalance', sortable: false, type: 'numeric' },
        { title: 'common.lbl.numberOfUnits', name: 'numberOfUnits', sortable: false, type: 'numeric' },
        { title: 'common.lbl.unitRate', name: 'unitRateAsOfDate', sortable: false, type: 'numeric', numberFormatOptions: { minimumFractionDigits: 9 } },
        {
            title: 'common.lbl.assetAllocation', name: 'assetAlloc', sortable: false, type: 'numeric', render(data: any) {
                return data === undefined ? addPercSign(0, 4) : addPercSign(data * 100, 4);
            }
        },
        {
            title: 'common.lbl.premiumAllocation', name: 'premAlloc', sortable: false, type: 'numeric', render(data: any) {
                return data === undefined ? addPercSign(0, 4) : addPercSign(data, 4);
            }
        }
    ]
    const loanDetailNoneTaxQualifiedTableColumn: Array<TableColumn> = [
        { title: 'pd.lbl.loanbalance', name: 'loanBalance', sortable: false, width: '25%', type: 'numeric' },
        {
            title: 'pd.lbl.annualinterestrate', name: 'annualIntrestRate', sortable: false, width: '25%', type: 'numeric', render(data: any) {
                return data === undefined ? addPercSign(0, 2) : addPercSign(data * 100, 2);
            }
        },
        { title: 'pd.lbl.interestmethod', name: 'intrestMethod', width: '25%', sortable: false },
        { title: 'pd.lbl.interestpaidtodate', name: 'intrestPaidToDate', width: '25%', sortable: false }
    ]
    const loanDetailTaxQualifiedTableColumn: Array<TableColumn> = [
        { title: 'pd.lbl.loanbalance', name: 'loanBalance', sortable: false, type: 'numeric' },
        {
            title: 'pd.lbl.annualinterestrate', name: 'annualIntrestRate', sortable: false, type: 'numeric', render(data: any) {
                return data === undefined ? addPercSign(0, 2) : addPercSign(data * 100, 2);
            }
        },
        { title: 'pd.lbl.interestmethod', name: 'intrestMethod', sortable: false },
        { title: 'pd.lbl.interestpaidtodate', name: 'intrestPaidToDate', sortable: false },
        { title: 'pd.lbl.typeofloan', name: 'typeOfLoan', sortable: false },
        { title: 'pd.lbl.loanpaymentamount', name: 'loanPaymentAmount', sortable: false, type: 'numeric' },
        { title: 'common.lbl.nextPaymentDate', name: 'nextPaymentDueDate', sortable: false },
    ]
    const additionalCoverageTableColumn: Array<TableColumn> = [

        { title: 'pd.lbl.productname', name: 'prodName', sortable: false },
        { title: 'common.lbl.insured', name: 'insured', sortable: false },
        { title: 'common.lbl.effectiveDate', name: 'effectiveDate', sortable: false },
        { title: 'common.lbl.expirationDate', name: 'expireDate', sortable: false },
        { title: 'common.lbl.status', name: 'status', sortable: false, width: '10%' },
        { title: 'common.lbl.coverageAmount', name: 'coverageAmount', sortable: false, width: '15%', type: 'numeric' },
    ]
    const covInfoTableColumn: Array<TableColumn> = [

        { title: 'common.lbl.effectiveDate', name: 'effectiveDate', sortable: false },
        {
            title: 'common.lbl.coverageDetail', name: 'coverageCode', sortable: false, render(data: Array<Element>) {
                let coverage = "";
                for (var key in data) {
                    let tmpData = '<div class="secLine">' + key + "</div>"
                    coverage = coverage + tmpData;
                }
                return coverage;
            }
        },
        {
            title: 'common.lbl.coverageDescription', name: 'coverageCode', sortable: false, render(data: any) {
                let description = "";
                for (var key in data) {
                    let tmpData = '<div class="secLine">' + data[key] + "</div>"
                    description = description + tmpData;
                }
                return description;
            }
        },
    ]
    const premiumDepositFundTableColumn: Array<TableColumn> = [

        { title: 'vap.lbl.po', name: 'payoutOption', sortable: false },
        { title: 'common.lbl.totalContri', name: 'totalContribution', sortable: false, type: 'numeric' },
        { title: 'common.lbl.intestcred', name: 'interestCredited', sortable: false, type: 'numeric' },
        { title: 'common.lbl.intestrate', name: 'interestRate', sortable: false, type: 'numeric' },
        { title: 'common.lbl.currbalance', name: 'currentBalance', sortable: false, type: 'numeric' },
        { title: 'common.lbl.cyiic', name: 'calendarYearInfo', sortable: false, type: 'numeric' },
    ]
    const fundDetailTableColumn: Array<TableColumn> = [
        { title: 'common.lbl.effectiveDate', name: 'effectiveDate', sortable: false, width: '22%' },
        { title: 'fd.lbl.cfb', name: 'currentFundBalance', type: 'numeric', sortable: false, width: '22%' },
        {
            title: 'fd.lbl.cair', name: 'guarAnnuIntRate', type: 'numeric', sortable: false, width: '22%', render(data: any) {
                return data === undefined ? addPercSign(0, 4) : addPercSign(data * 100, 4);
            }
        },
        { title: 'fd.lbl.ired', name: 'interestRateExpiryDate', sortable: false, width: '34%' }
    ]
    return <div id="benefitdetail">
        <props.Row>
            <props.Col xs="12" sm="8" md="9">
                <div className="gwp-page-header">
                    <div className="gwp-page-header-block"></div>
                    <div className="gwp-page-header-label gwp-label">{config.benefitDto.benefitDesc}
                    </div>
                </div>
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col xs="12" sm="12" md="12" className="marginbottom10px">
                <GlobalizedText message="common.lbl.allcurrencyvalue" /> {config.benefitDto.currencyDesc}
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col xs="12" sm="3" md="4">
                <props.DL className="gwp-dl">
                    <props.DLI title="common.lbl.insured" desc={() => {
                        return <props.Link to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'INS'}&policyNumber=${policyNumber}&baseRiderCode=${baseRiderCode}`}>{config.benefitDto.insured.replaceAll("<wbr/>", "")}</props.Link>
                    }} >
                    </props.DLI>
                    <props.DLI condition={config.benefitDto.hasOwnProperty("jointInsured")} title="common.lbl.jointInsured" desc={() => {
                        return <props.Link to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'JNT'}&policyNumber=${policyNumber}&baseRiderCode=${baseRiderCode}`}>{config.benefitDto.jointInsured.replaceAll("<wbr/>", "")}</props.Link>
                    }} >
                    </props.DLI>
                    <props.DLI title="common.lbl.status"
                        desc={() => {
                            return <>
                                {config.benefitDto.status}
                                <props.Link condition={config.claimType === "D"} to={`/benefitClaimDetail?companyCode=${companyCode}&policyNumber=${policyNumber}&baseRiderCode=${baseRiderCode}&planCode=${planCode}`}>
                                    <props.Icon style={{ marginLeft: 5 }} type="view-detail" label="pd.lbl.view.detail"></props.Icon>
                                </props.Link></>
                        }} >
                    </props.DLI>
                    <props.DLI title="common.lbl.issueAge" desc={() => {
                        return <>{config.benefitDto.issueAge}</>
                    }} />
                    <props.DLI condition={config.benefitDto.hasOwnProperty("riskClass")} title="common.lbl.riskClass" desc={() => {
                        return <>{config.benefitDto.riskClass}</>
                    }} />
                    <props.DLI condition={config.benefitDto.hasOwnProperty("coverageAmount") && 0 !== config.benefitDto.coverageAmount} title="common.lbl.coverageAmount" desc={() => {
                        return <>{formatNumber(config.benefitDto.coverageAmount)}</>
                    }} />
                    <props.DLI condition={config.benefitDto.hasOwnProperty("deathBenefitOpt")} title="common.lbl.deathBenefitOption" desc={() => {
                        return <>{config.benefitDto.deathBenefitOpt}</>
                    }} />
                    <props.Link condition={config.claimType === "L"} to={`/benefitClaimDetail?companyCode=${companyCode}&policyNumber=${policyNumber}&baseRiderCode=${baseRiderCode}&planCode=${planCode}`}>
                        <props.Icon type="view-detail" label="common.lbl.livingBenefit"></props.Icon>
                    </props.Link>
                </props.DL>
            </props.Col>
            <props.Col xs="12" sm="3" md="4">
                <props.DL className="gwp-dl">
                    <props.DLI title="common.lbl.effectiveDate" desc={() => {
                        return <>{config.benefitDto.effectiveDate}</>
                    }} />
                    <props.DLI title="common.lbl.expirymaturityDate" desc={() => {
                        return <>{config.benefitDto.expireDate}</>
                    }} />
                    <props.DLI condition={config.benefitDto.hasOwnProperty("paidUpToDate")} title="common.lbl.paidUpDate" desc={() => {
                        return <>{config.benefitDto.paidUpToDate}</>
                    }} />
                    <props.DLI condition={config.benefitDto.hasOwnProperty("terminateDate") && config.benefitDto.terminateDate !== ""} title="common.lbl.terminationDate" desc={() => {
                        return <>{config.benefitDto.terminateDate}</>
                    }} />
                    <props.DLI condition={config.benefitDto.hasOwnProperty("netBenefitValue") && 0 !== config.benefitDto.netBenefitValue} title="pd.lbl.netbenefitvalue" desc={() => {
                        return <>{formatNumber(config.benefitDto.netBenefitValue)}</>
                    }} />
                </props.DL>
            </props.Col>
            {config.benefitDto.agentList !== undefined &&
                <props.Col xs="12" sm="6" md="4">
                    <props.Div condition={showAgentsPanel()}>
                        <AgentsInfoPanel {...props} agents={config.benefitDto.agentList.map((agentInfo: any) => {
                            return { fullName: agentInfo.firstLastName, phone: agentInfo.phone, email: agentInfo.email, splitPerctage: agentInfo.splitPerctage, from: 'benefitdetail' }
                        })} showMaxAgent={2} from='benefitDetail'></AgentsInfoPanel>
                    </props.Div>
                    <props.Row condition={config.benefitDto.newBusiness !== true && ('C' === insuranceType || 'P' === insuranceType) && (config.showAnnuityPayee) && isDisplayAnnuityPayees}>
                        <props.Col >
                            <props.Panel className="panel-container-bottom" condition={(!config.benefitDto.newBusiness)}>
                                <props.PanelHeading><GlobalizedText message="common.lbl.relatedLink" /></props.PanelHeading>
                                <props.PanelBody>
                                    <props.LinkButton onClick={toVap}>
                                        <props.Icon type="view-all" label="vap.lbl.vap"></props.Icon>
                                    </props.LinkButton>
                                </props.PanelBody>
                            </props.Panel>
                        </props.Col>
                    </props.Row>
                </props.Col>
            }
        </props.Row>
        <props.Row>
            <props.Col xs="12">
                <props.InnerTitle condition={config.benefitDto.funded}>
                    <GlobalizedText message="common.lbl.fundSummary" />
                    {(config.benefitDto.variable) &&
                        <span className="">
                            <span className="benefit_fundChart"><a href="javascript:void(0);" onClick={() => { props.showModal("#chartModal") }} ><GlobalizedText message="common.lbl.viewByChart" /></a></span>
                        </span>}
                </props.InnerTitle>
            </props.Col>
        </props.Row>

        <props.Panel condition={undefined !== interestFundsVariableTable && null !== interestFundsVariableTable && interestFundsVariableTable.length > 0}>
            <props.PanelHeading className="benefit-panel-heading">
                <GlobalizedText message="common.lbl.interestFunds" />
            </props.PanelHeading>
            <props.PanelBody className="panel-body-table">
                <props.Table id="interestFundsVariableTable" option={limitTableOption} table={
                    {
                        columns: interestFundsVariableTableColumn,
                        data: interestFundsVariableTable,
                    }
                } ></props.Table>
            </props.PanelBody>
        </props.Panel>

        <props.Panel condition={undefined !== interestFundsNonVariableTable && null !== interestFundsNonVariableTable && interestFundsNonVariableTable.length > 0}>
            <props.PanelHeading className="benefit-panel-heading">
                <GlobalizedText message="common.lbl.interestFunds" />
            </props.PanelHeading>
            <props.PanelBody className="panel-body-table">
                <props.Table id="interestFundsNonVariableTable" option={limitTableOption} table={
                    {
                        columns: interestFundsNonVariableTableColumn,
                        data: interestFundsNonVariableTable,
                    }
                } ></props.Table>
            </props.PanelBody>
        </props.Panel>

        <props.Panel condition={undefined !== eiFundsTable && null !== eiFundsTable && eiFundsTable.length > 0}>
            <props.PanelHeading className="benefit-panel-heading">
                <GlobalizedText message="common.lbl.equityIndexFunds" />
            </props.PanelHeading>
            <props.PanelBody className="panel-body-table">
                <props.Table id="eiFundsTable" option={limitTableOption} table={
                    {
                        columns: eiFundsTableColumn,
                        data: eiFundsTable,
                    }
                } ></props.Table>
            </props.PanelBody>
        </props.Panel>

        <props.Panel condition={undefined !== unitBasedFundsTable && null !== unitBasedFundsTable && unitBasedFundsTable.length > 0}>
            <props.PanelHeading className="benefit-panel-heading">
                <GlobalizedText message="common.lbl.unitBasedFunds" />
            </props.PanelHeading>
            <props.PanelBody className="panel-body-table">
                <props.Table id="unitBasedFundsTable" option={limitTableOption} table={
                    {
                        columns: unitBasedFundsTableColumn,
                        data: unitBasedFundsTable,
                    }
                } ></props.Table>
            </props.PanelBody>
        </props.Panel>

        <props.Panel condition={undefined !== loanDetailNoneTaxQualifiedTable && null !== loanDetailNoneTaxQualifiedTable && loanDetailNoneTaxQualifiedTable.length > 0}>
            <props.PanelHeading className="benefit-panel-heading">
                <GlobalizedText message="common.lbl.loanInformation" />
            </props.PanelHeading>
            <props.PanelBody className="panel-body-table">
                <props.Table id="loanDetailNoneTaxQualifiedTable" option={limitTableOption} table={
                    {
                        columns: loanDetailNoneTaxQualifiedTableColumn,
                        data: loanDetailNoneTaxQualifiedTable,
                    }
                } ></props.Table>
            </props.PanelBody>
        </props.Panel>

        <props.Panel condition={undefined !== loanDetailTaxQualifiedTable && null !== loanDetailTaxQualifiedTable && loanDetailTaxQualifiedTable.length > 0}>
            <props.PanelHeading className="benefit-panel-heading">
                <GlobalizedText message="common.lbl.loanInformation" />
            </props.PanelHeading>
            <props.PanelBody className="panel-body-table">
                <props.Table id="loanDetailTaxQualifiedTable" option={limitTableOption} table={
                    {
                        columns: loanDetailTaxQualifiedTableColumn,
                        data: loanDetailTaxQualifiedTable,
                    }
                } ></props.Table>
            </props.PanelBody>
        </props.Panel>
        <props.Row>
            <props.Col xs="12" sm="6" md="6">
                <props.Panel condition={insuranceType === "H" && undefined !== additionalCoverageTable && null !== additionalCoverageTable && additionalCoverageTable.length > 0}>
                    <props.PanelHeading>
                        <GlobalizedText message="common.lbl.coverageInformation" />
                    </props.PanelHeading>
                    <props.PanelBody className="panel-body-table">
                        <props.Table id="covInfosTable" option={limitTableOption} table={
                            {
                                columns: covInfoTableColumn,
                                data: additionalCoverageTable,
                            }
                        } ></props.Table>
                    </props.PanelBody>
                </props.Panel>
            </props.Col>
        </props.Row>
        <props.Panel condition={insuranceType !== "H" && undefined !== additionalCoverageTable && null !== additionalCoverageTable && additionalCoverageTable.length > 0}>
            <props.PanelHeading>
                <GlobalizedText message="common.lbl.additionalCoverage" />
            </props.PanelHeading>
            <props.PanelBody className="panel-body-table">
                <props.Table id="additionalCoverageTable" option={limitTableOption} table={
                    {
                        columns: additionalCoverageTableColumn,
                        data: additionalCoverageTable,
                    }
                } ></props.Table>
            </props.PanelBody>
        </props.Panel>
        {/* Panels for payout policy*/}
        <props.Panel condition={('C' === insuranceType || 'P' === insuranceType) && isPayoutInformationSection}>
            <props.PanelHeading>
                <GlobalizedText message="common.lbl.payoutinfo" />
            </props.PanelHeading>
            <props.PanelBody className="payout-information-body">
                <props.Row className="bd-payoutinfo-content">
                    <props.Col xs="12" md="6">
                        <props.DL>
                            <props.DLI title="common.lbl.payoutoption" desc={config.benefitDto.payoutOption}></props.DLI>
                            <props.DLI condition={() => showDurationTime()} title="common.lbl.durationyears" desc={config.benefitDto.duratYears.toFixed(0)}></props.DLI>
                            <props.DLI condition={() => showDurationTime()} title="common.lbl.durationmonths" desc={config.benefitDto.durationMonths.toFixed(0)}></props.DLI>
                            <props.DLI condition={config.benefitDto.hasOwnProperty("payoutAmount")} title="common.lbl.payoutamount" desc={config.benefitDto.payoutAmount}></props.DLI>
                            <props.DLI condition={config.benefitDto.payoutOptionCode !== "IA"} title="common.lbl.payoutfrequency" desc={config.benefitDto.payoutFrequency}></props.DLI>
                            <props.DLI condition={config.benefitDto.hasOwnProperty("startDate")} title="common.lbl.startDate" desc={config.benefitDto.startDate}></props.DLI>
                            <props.DLI condition={config.benefitDto.hasOwnProperty("premium")} title="common.lbl.premium" desc={config.benefitDto.premium}></props.DLI>
                        </props.DL>
                    </props.Col>

                    <props.Col xs="12" md="6">
                        <props.DL condition={() => showWithHolding()}>
                            <props.DLI condition={showFederalIncomeTaxWith} title="common.lbl.federalwithholdingamount" desc={config.benefitDto.federalIncomeTaxWith}></props.DLI>
                            <props.DLI condition={showStateIncomeTaxWith} title="common.lbl.statewithholdingamount" desc={config.benefitDto.stateIncomeTaxWith}></props.DLI>
                            <props.DLI condition={showFederalWithPerc} title="common.lbl.federalwithholdingpercentage" desc={config.benefitDto.federalWithPerc}></props.DLI>
                            <props.DLI condition={showStateWithTaxPcnt} title="common.lbl.statewithholdingpercentage" desc={config.benefitDto.stateWithTaxPcnt}></props.DLI>
                        </props.DL>
                        <props.DL condition={() => showJointPerc()}>
                            <props.DLI condition={payoutJointPercentage} title="common.lbl.jointpercentage" desc={config.benefitDto.payoutJointPercentage}></props.DLI>
                            <props.DLI condition={contingentBenefit} title="common.lbl.contingentbenefit" desc={config.benefitDto.contingentBenefit}></props.DLI>
                        </props.DL>
                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel >

        <props.Panel condition={
            !config.benefitDto.newBusiness &&
            'C' === insuranceType &&
            ('IA' === config.benefitDto.payoutOptionCode || 'IP' === config.benefitDto.payoutOptionCode) &&
            isPayoutMoneyOnDepositFundSection
        }>
            <props.PanelHeading >
                <GlobalizedText message="common.lbl.modfs" />
            </props.PanelHeading>
            <props.PanelBody className="panel-body-table">
                <props.Table id="premiumDepositFundTable" option={limitTableOption} delayAdjust={true} table={
                    {
                        columns: premiumDepositFundTableColumn,
                        data: premiumDepositFundTable,
                    }
                } ></props.Table>
            </props.PanelBody>
        </props.Panel>

        {/* Modal for Show Fund Details */}
        <props.Modal title="common.lbl.fundDetails" id="fundDetailsDialog" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }} >
            <props.Row>
                <props.Col xs="12" sm="12" md="12" className="greenlabel gwp-bold marginbottom10px">
                    <GlobalizedText message="fd.title" />
                </props.Col>
            </props.Row>
            <props.Row>
                <props.Col xs="12" sm="12" md="12" className="marginbottom10px">
                    {fundName}
                </props.Col>
            </props.Row>
            <props.Row>
                <props.Col xs="12" sm="12" md="12" className="marginbottom10px">
                    <GlobalizedText message="common.lbl.allcurrencyvalue" /> {config.benefitDto.currencyDesc}
                </props.Col>
            </props.Row>
            <props.Table id="fundDetailTable" modal="fundDetailsDialog" option={fundTableOption} table={
                {
                    columns: fundDetailTableColumn,
                    data: fundDetailTable,
                }
            }></props.Table>
        </props.Modal>

        <props.Modal id="chartModal" title="benedetail.fundValue" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.ChartViewer condition={chartData.length > 0 && chartLabels.length > 0} data={{
                datasets: [
                    {
                        data: chartData,
                        backgroundColor: [
                            ChartColor.darkblue,
                            ChartColor.darkorange,
                            ChartColor.tan,
                            ChartColor.darkgreen
                        ],
                        label: 'fundValuePie'
                    }
                ],
                labels: chartLabels
            }
            } type='pie' width='90%'></props.ChartViewer>
        </props.Modal>
    </div >
});