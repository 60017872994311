import React, { useEffect, useRef, useState } from "react";
import { isEmptyStr, replaceAll } from "../../utils";
import { withErrorControl, WrapperControlProps } from "./field";

export type FORMAT_TYPE = "SSN" | "EIN" | "PGI" | "ACCT";
export const DEFAULT_FORMAT_TYPE = "SSN";
export const FORMAT_MAPPER = {
    "SSN": "xxx-xx-xxxx",
    "EIN": "xx-xxxxxxx",
    "PGI": "xxxxxxxxxx",// provider government id.
    "ACCT": "xxxxxxxxxxxxxxxxxxxx",//account number.
}
const DEFAULT_MASK_SYMBOL = "*";
const DEFAUTL_VALUE_WITH_FORMAT = true;
const HYPHEN = "-";

export interface AutoFormatControlProps extends WrapperControlProps {
    autoFormat?: boolean; // autoFormat the GOVT ID, default is true
    formatType?: FORMAT_TYPE // government id type
    autoMask?: boolean; // if auto mask is true, show the last 4 digits, default is true
    valueWithFormat?: boolean // 
    maskSymbol?: string;// the character that used to mask the data.
    showFormat?: boolean; // show format right to the label, default is true
    onValueChange?: (e: any) => void;


}

const handlePaste = (e: any, format: string) => {
    let data = e.clipboardData.getData('Text');
    if (!/^[\d,-]*$/.test(data.trim())) {
        e.preventDefault();
        return;
    }
    let numberValue = e.target.value.replaceAll(HYPHEN, "");
    let formattedValue = formatContent(e.target.value, format);
    e.target.value = formattedValue;
    return [numberValue, formattedValue]
}

const formatContent = (value: string, format: string) => {
    // get rid of hyphen
    let tmp = value.replaceAll(HYPHEN, "");

    if (tmp.length !== format.replaceAll(HYPHEN, "").length) {
        return value;
    }

    let result = '';
    let pos = 0;
    for (let i = 0; i < format.length; i++) {
        if (i >= pos + tmp.length) {
            break;
        }
        if (format.charAt(i) === HYPHEN) {
            result += HYPHEN;
            pos++;
        } else {
            if (tmp[i - pos] !== undefined) {
                result += tmp[i - pos]
            }
        }
    }
    if (value.endsWith(HYPHEN)) {
        result += HYPHEN;
    }
    return result;
}

const mask = (value: string, maskSymbol: string, autoMask?: boolean) => {
    if (autoMask === false) {
        return value;
    }
    if (value.length < 4) {
        return value
    }
    let res = '';
    for (let i = 0; i < value.length - 4; i++) {
        res += (value[i] === HYPHEN ? HYPHEN : maskSymbol);
    }
    res += value.substring(value.length - 4);
    return res;
}

export const validateFormat = (value: string, formatType: FORMAT_TYPE): boolean => {
    let format = FORMAT_MAPPER[formatType];
    let regex = new RegExp("^" + replaceAll("x", "\\d", format) + "$");
    return regex.test(value);
}

export const AutoFormatControl = withErrorControl(({ name, required, readonly, label, containerClassName, autoFormat, formatType, setValue, valueWithFormat, maskSymbol, disabled,
    onValueChange, ...props }: AutoFormatControlProps) => {

    const realFormat = formatType === undefined ? FORMAT_MAPPER.SSN : FORMAT_MAPPER[formatType];
    const realMasksymbol = maskSymbol === undefined ? DEFAULT_MASK_SYMBOL : maskSymbol.substring(0, 1);
    const realValueWithFormat = valueWithFormat === undefined ? DEFAUTL_VALUE_WITH_FORMAT : valueWithFormat;
    const unFormattedValue = useRef<string>("");
    const formattedValue = useRef<string>("");
    const displayValue = useRef<string>("");
    const [count, setCount] = useState<number>(0);

    useEffect(() => {
        if (unFormattedValue.current === "" && count === 0 && realValueWithFormat === true) {
            setValue(formatContent(props['data-form-value'], realFormat));
        }
    }, [count])

    return <>
        <input
            id={`${props.id}`}
            type="text"
            value={isEmptyStr(displayValue.current) ? mask(formatContent(props["data-form-value"], realFormat), realMasksymbol, props.autoMask) : displayValue.current}
            style={props.style}
            className={`form-control gwp-input ${props.className !== undefined ? props.className : ''}`}
            name={`${name}`}
            onBlur={(e: any) => {
                formattedValue.current = formatContent(e.target.value, realFormat);
                displayValue.current = mask(formattedValue.current, realMasksymbol, props.autoMask);
                setCount(count + 1);
            }}
            onFocus={(e: any) => {
                if (isEmptyStr(formattedValue.current)) {
                    formattedValue.current = formatContent(props["data-form-value"], realFormat);
                }
                displayValue.current = formattedValue.current;
                setCount(count + 1);
            }}
            onPaste={(e: any) => { handlePaste(e, realFormat); }}
            onChange={(e: any) => {
                unFormattedValue.current = e.target.value;
                displayValue.current = e.target.value;
                setValue(realValueWithFormat ? formatContent(e.target.value, realFormat) : e.target.value);
                if (onValueChange !== undefined) {
                    onValueChange(e);
                }
            }}

            autoComplete="off"
            disabled={disabled === undefined ? false : disabled}
        ></input>
    </>
});