import React, { useState,useContext,useEffect } from 'react'
import * as yup from 'yup';
import { YupSchema } from '../yupschema';
import { SecurityContext } from '../../context/securityContext';
import { UserTypeCode } from '../constants/userTypeCode';

export const SecurityQuestionsForRegistration = (props: any) => {
    const { pageProps, addFirstOption, formProps } = props;
    const [fieldIsRequired, setFieldIsRequired] = useState(!pageProps.regConfig.admin);
    const {userAuth} = useContext(SecurityContext);
    useEffect(()=>{
        if(userAuth.userTypeRegCode === UserTypeCode.PORTAL_ADMIN && formProps.values.userTypeRegCode === UserTypeCode.AGENT){
            setFieldIsRequired(true);
        }
    },[]);
    return (
        <>
            <pageProps.SelectControl name="securityQuestion1" label="regist.label.question1" required={fieldIsRequired} sort={false} options={addFirstOption(pageProps.regConfig.securityQuestions.map((item: any) => { if (item.questionID !== formProps.values.securityQuestion2 && item.questionID !== formProps.values.securityQuestion3) return { name: item.question, value: item.questionID } }), pageProps.getGlobalizedText)} />
            <pageProps.TextControl id="answer1" name="answer1" label="regist.label.answer1" required={fieldIsRequired} />

            <pageProps.SelectControl name="securityQuestion2" label="regist.label.question2" required={fieldIsRequired} sort={false} options={addFirstOption(pageProps.regConfig.securityQuestions.map((item: any) => { if (item.questionID !== formProps.values.securityQuestion1 && item.questionID !== formProps.values.securityQuestion3) return { name: item.question, value: item.questionID } }), pageProps.getGlobalizedText)} />
            <pageProps.TextControl id="answer2" name="answer2" label="regist.label.answer2" required={fieldIsRequired} />

            <pageProps.SelectControl name="securityQuestion3" label="regist.label.question3" required={fieldIsRequired} sort={false} options={addFirstOption(pageProps.regConfig.securityQuestions.map((item: any) => { if (item.questionID !== formProps.values.securityQuestion1 && item.questionID !== formProps.values.securityQuestion2) return { name: item.question, value: item.questionID } }), pageProps.getGlobalizedText)} />
            <pageProps.TextControl id="answer3" name="answer3" label="regist.label.answer3" required={fieldIsRequired} />
        </>
    )
}

export let byPageTwoForAgentOnlyValidator = (isAdmin:boolean,byPageTwoOption:any) => yup.object().shape({ ...byPageTwoOption, ...YupSchema.registration.securityQuestions });