import { WouldSelectOption} from '../components';
import selfTrim from './selfTrim';
export default function addWouldSelectOptions(wouldSelectOptionList: Array<WouldSelectOption>,rowData:any) {
    if (rowData.viewMemberDocsAccessPermission === true) {
        let tmpOption: WouldSelectOption = {
            message: 'my.ins.act.opt.view.memberDocs',
            to: `/memberDocs?companyCode=${selfTrim(rowData.companyCode)}&accountNumber=${rowData.account}&groupNumber=${selfTrim(rowData.groupNumberWithLeftZero)}&participantIDNumber=${rowData.memberIdWithLeftZero}`
        };
        wouldSelectOptionList.push(tmpOption);
    }
}
