import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, downloadFile, validate, parseDate, isEmptyStr } from '../shared/utils';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { TableColumn } from '../shared/components';
import './css/audittrail.css';
import { INVALID_MESSAGES } from '../shared/yupschema';

export const AuditTrailComponent = withView((props: ViewComponentProps) => {

    const [config, setConfig] = useState<any | null>(null);
    const [table, setTable] = useState<any>();
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [userDateFormat, setUserDateFormat] = useState<any>();
    const [allDate, setAllDate] = useState<any>(false);
    const [selectDate, setSelectDate] = useState<any>(true);

    const defaultInitialValues = {
        allDate: false,
        startDate: '',
        endDate: '',
        userDateFormat: ''
    };

    let dateSchema = (formValue: any) => {
        let addValidateResult: any = {};
        let errorFlag: boolean = false;
        if (isEmptyStr(formValue.startDate)) {
            addValidateResult.startDate = INVALID_MESSAGES.STARTDATE_IS_EMPTY;
            errorFlag = true;
        }

        if (isEmptyStr(formValue.endDate)) {
            addValidateResult.endDate = INVALID_MESSAGES.ENDDATE_IS_EMPTY;
            errorFlag = true;
        }
        let dateFrom: any = parseDate(formValue.startDate, formValue.userDateFormat);
        let dateThrough: any = parseDate(formValue.endDate, formValue.userDateFormat);
        if (dateFrom > dateThrough) {
            addValidateResult.startDate = INVALID_MESSAGES.STARTEND_DATE_WRONG;
            addValidateResult.endDate = INVALID_MESSAGES.STARTEND_DATE_WRONG;
            errorFlag = true;
        }
        if (errorFlag === true) {
            return addValidateResult;
        }

    };
    let varFormPromp: any;
    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/auditTrail/view',
                success: (res: any) => {
                    setConfig(res);
                    let offset = UTCDateToLocalDate();
                    setStartDate(res.startDate);
                    setEndDate(res.endDate);
                    setUserDateFormat(res.userDateFormat);
                    varFormPromp.setFieldValue("startDate", res.startDate);
                    varFormPromp.setFieldValue("endDate", res.endDate);
                    varFormPromp.setFieldValue("userDateFormat", res.userDateFormat);
                    let startDate = res.startDate;
                    if (startDate !== undefined) {
                        retrieve(res, offset);
                    }
                }
            });
        }
    }, [config]);
    const tableColumn: Array<TableColumn> = [
        { title: 'common.lbl.date', name: 'date', sortable: false },
        { title: 'groupAdmin.userID', name: 'userID', sortable: false },
        { title: 'common.lbl.userName', name: 'userName', sortable: false },
        { title: 'security.lbl.userType', name: 'userType', sortable: false },
        {
            title: 'common.lbl.auditMessage', name: 'message', sortable: false, render(data: any) {

                if (data !== null) {
                    if (data.search("Change Secondary Addressee") !== -1 || data.search("Change Owner") !== -1) {
                        if (data.search("Change Secondary Addressee") !== -1) {
                            data = data.replace('{', '{"changeSecondaryAddressee":"' + data.match(/Result=\s*(\w+)/)[1] + '",');
                        } else {
                            data = data.replace('{', '{"changeOwner":"' + data.match(/Result=\s*(\w+)/)[1] + '",');
                        }
                        return data.replace("\"firstName\"", "<br>\"firstName\"").replace("\"addrLine1\"", "<br>\"addrLine1\"")
                            .replace("\"city\"", "<br>\"city\"").replace("\"email1\"", "<br>\"email1\"").replace("\"recurringStartDay\"", "<br>\"recurringStartDay\"")
                            .replace(/\"addBeneficiary\":[^,]+?\,/, '').replace(/\"beneType\":[^,]+?\,/, '');
                    } else if (data.search("Change Payment Method") !== -1) {
                        return data.replace("\"accountType\"", "<br>\"accountType\"");

                    } else if (data.search("Update Enrollment") !== -1) {
                        return data.replace("\"transactionTypeCode\"", "<br>\"transactionTypeCode\"");

                    } else if (data.search("Add Secondary Addressee") !== -1) {
                        data = data.replace('{', '{"addSecondaryAddressee":"' + data.match(/Result=\s*(\w+)/)[1] + '",');
                        return data.replace("\"govtID\"", "<br>\"govtID\"").replace("\"firstName\"", "<br>\"firstName\"")
                            .replace("\"middleName\"", "<br>\"middleName\"")
                            .replace("\"lastName\"", "<br>\"lastName\"")
                            .replace("\"genderCode\"", "<br>\"genderCode\"")
                            .replace("\"addrLine1\"", "<br>\"addrLine1\"")
                            .replace("\"addrLine2\"", "<br>\"addrLine2\"")
                            .replace("\"addrLine3\"", "<br>\"addrLine3\"")
                            .replace("\"city\"", "<br>\"city\"")
                            .replace("\"cellPhone\"", "<br>\"cellPhone\"").replace("\"email1\"", "<br>\"email1\"")
                            .replace("\"addressSequenceNumber\"", "<br>\"addressSequenceNumber\"").replace("\"recurringStartDay\"", "<br>\"recurringStartDay\"")
                            .replace(/\"addBeneficiary\":[^,]+?\,/, '').replace(/\"beneType\":[^,]+?\,/, '');

                    } else if (data.search("Add Beneficiary") !== -1) {
                        return data.replace("\"firstName\"", "<br>\"firstName\"").replace("\"addrLine1\"", "<br>\"addrLine1\"")
                            .replace("\"city\"", "<br>\"city\"").replace("\"email1\"", "<br>\"email1\"").replace("\"recurringStartDay\"", "<br>\"recurringStartDay\"");

                    } else if (data.search("addCreditCard") !== -1) {
                        return data.replace("\"firstName\"", "<br>\"firstName\"").replace("\"creditCardType\"", "<br>\"creditCardType\"")
                            .replace("\"clientProfileID\"", "<br>\"clientProfileID\"").replace("\"userId\"", "<br>\"userId\"");
                    } else if (data.search("updateCreditCard") !== -1) {
                        return data.replace("\"firstName\"", "<br>\"firstName\"").replace("\"creditCardType\"", "<br>\"creditCardType\"")
                            .replace("\"clientProfileID\"", "<br>\"clientProfileID\"").replace("\"userId\"", "<br>\"userId\"");
                    } else if (data.search("Delete Secondary Addressee") !== -1) {
                        return data.replace('{', '{"deleteSecondaryAddressee":"' + data.match(/Result=\s*(\w+)/)[1] + '",');
                    } else if (data.search("Delete beneficiary") !== -1) {
                        return data.replace('{', '{"deleteBeneficiary":"' + data.match(/Result=\s*(\w+)/)[1] + '",');
                    } else if (data.search("Change beneficiary") !== -1) {
                        return data.replace('{', '{"changeBeneficiary":"' + data.match(/Result=\s*(\w+)/)[1] + '","beneType":"1",');
                    }
                }
                return data;

            }
        }
    ]

    function retrieve(values: any, offset: any, setSubmitting?: Function) {
        ajax({
            url: `/api/auditTrail/fetchAuditTrails`,
            params: {
                allDate: allDate,
                startDate: values.startDate,
                endDate: values.endDate,
                userDateFormat: values.userDateFormat ? values.userDateFormat : userDateFormat,
                timeZoneOffset: offset
            },
            success: res => {
                setStartDate(res.startDate);
                setEndDate(res.endDate);
                varFormPromp.setFieldValue("startDate", res.startDate);
                varFormPromp.setFieldValue("endDate", res.endDate);
                setTable(res.auditTrailList);
            }
        });
    }
    function UTCDateToLocalDate() {
        let offset = new Date().getTimezoneOffset() / 60;
        return -offset;
    }
    return <React.Fragment>
        <Formik initialValues={defaultInitialValues}
            validateOnBlur={false}
            validateOnChange={false}
            validate={(values: any): any => {
                if (selectDate) {
                    return validate(dateSchema, values, props);
                }
            }}
            onSubmit={(values) => {
                let offset = UTCDateToLocalDate();
                retrieve(values, offset, setTable);
                props.setInitParams({ ...values });
            }}
        >
            {formProps => {
                varFormPromp = formProps;
                return <Form id="audittrail">
                    <props.Row>
                        <props.Col md="6">
                            <props.RadioControl onChange={(v) => {
                                props.clearMessage(); setSelectDate(false); setAllDate(true);
                            }} id="allDate" label="common.lbl.all" name="allDate" checked={allDate}></props.RadioControl>
                            <props.RadioControl onChange={(v) => { props.clearMessage(); setAllDate(false); setSelectDate(true) }} id="selectDate" label="common.lbl.specifyPeriod" name="selectDate" checked={selectDate}></props.RadioControl>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col sm="4" >
                            <props.DateTimePickerControl useLocalDate={false} htmlFor="startDate_input" id="startDate" name="startDate" label="common.lbl.startDate" dateformat={formProps.values.userDateFormat} />
                        </props.Col>
                        <props.Col sm="4" >
                            <props.DateTimePickerControl useLocalDate={false} htmlFor="endDate_input" id="endDate" name="endDate" label="common.lbl.endDate" dateformat={formProps.values.userDateFormat} />
                        </props.Col>
                        <props.Col sm="4" >
                            <props.Button className="gwp-btn-ok" type="submit"><GlobalizedText message="common.lbl.ok" /></props.Button>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col sm="4">
                            <props.Button onClick={() => {
                                props.clearMessage();
                                if (selectDate) {
                                    let tempInfo = formProps.values.startDate + "+" + formProps.values.endDate + "+" + UTCDateToLocalDate();
                                    tempInfo = tempInfo.replace(/\//g, '');
                                    downloadFile(`/api/auditTrail/download/${tempInfo}`)
                                }
                                else {
                                    downloadFile(`/api/auditTrail/download/${"all"}`)
                                }
                            }}><GlobalizedText message="common.lbl.download" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
            }
        </Formik>
        <props.Table id="auditTrailTable" table={
            {
                columns: tableColumn,
                data: table,
            }
        } ></props.Table>
    </React.Fragment>
});

