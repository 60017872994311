import React, { useEffect,useContext, useMemo, useRef, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import { Formik, Form } from 'formik';
import './css/coverageSummary.css';
import { ajax, downloadFile, isEmptyObject, parseDate, trim, print, formatNumber, simpleSort, formatDate, showMask, hideMask, isEmptyStr } from '../shared/utils';
import { AgentsInfoPanel } from './agentsinfopanel';
import { date } from 'yup';
import { closeModal, Condition, showModal, TableCell, TableColumn, useTable } from '../shared/components';
import PremiumBilledVolumeThead from '../shared/components/premiumBilledVolumeThead';
import PremiumBilledVolumeTbody from '../shared/components/VolumeTbody';
import { SecurityContext } from '../context/securityContext';
import {UserTypeCode} from '../shared/constants/userTypeCode';

interface CoverageSummary {
    isProvider: boolean,
    name: string,
    userDateFormat: string,
    requestIDCardFlag: boolean,
    printIDCardFlag: boolean,
    displayBenefitLimitsFlag: boolean,
    displayClaimFlag: boolean,
    hasBenefitLimits: boolean,
    useDummyCard: boolean,
    member: {
        memberName: string,
        groupDisplayValue: string,
        accountDisplayValue: string,
        memberIDDisplayValue: string,
        payrollDefinition: string,
        futurePayrollEffectiveDate: string,
        futurePayrollDefinition: string,
        currencyName: string,
        countryCode: string
    },
    location: {
        locationNumber: number,
        locationName: string,
        effectiveDate: string,
        grade: string,
        status: string,
        category: string
    },
    policy: {
        accountNumber: number,
        accountDesc: string,
        effectiveDate: string,
        terminationDate: string,
        billing: string,
        frequency: string,
        billedToDate: string,
        buPaidToDate: string,
        coveragePaidToDate: string,
        billingAddress: string,
        benefitOffice: string
    },
    enrollments: [
        {
            clientNumber: string,
            name: string,
            gender: string,
            birthDate: string,
            govtID: string,
            dependentSequenceNumber: number,
            relationship: string,
            ext: {
                clientNumber: string,
                memberIDDisplayValue: string,
                address: string,
                mobile: string,
                email1: string,
                email2: string,
                prefMethod: string
            },
            showClaims: boolean,
            showPersonalDetails: boolean
        }
    ],
    agents: [
        {
            agentNumber: string,
            agentName: string,
            agentPercent: 0,
            email: string,
            phone: string
        }
    ],
    coverages: [
        {
            productName: string,
            benefitClassification: string,
            effectiveDate: string,
            terminationDate: string,
            planCodeDesc: string,
            coverageOption: string,
            volume: number,
            lastPremiumBilled: number,
            waivedPremium: boolean,
            memberResponsibility: boolean,
            partiallyPremiumBilled: boolean,
            lastPremiumBilledPartially: number,
            waivedPremiumPartially: boolean,
            accessPolicyDetail: boolean,
            policyNumber: string,
            benefitLimitsIndicator: boolean,
            productID: string,
            planCode: string,
            dependentSequenceNumber: number,
            agents: [
                {
                    agentNumber: string,
                    agentName: string,
                    agentPercent: 0,
                    email: string,
                    phone: string
                }
            ],
            beneficiaries: [
                {
                    primaryBeneficiary: boolean,
                    beneficiaryName: string,
                    contingentBeneficiary: string,
                    payeePercent: string,
                    showInCoverageInformation: boolean
                }
            ],
            funds: [
                {
                    fundDescription: string,
                    fundYearBeginDate: string,
                    fundYearEndDate: string,
                    submissionGraceDays: number,
                    lastTranDate: string,
                    totalContributions: number,
                    totalClaims: number,
                    targetAmount: number,
                    forfeitureAmount: number,
                    fundTransSeqNumber: number,
                    fundBalance: number
                }
            ],
            dependentCoverages: [
                {
                    beneficiaries: [
                        {
                            primaryBeneficiary: boolean,
                            beneficiaryName: string,
                            contingentBeneficiary: string,
                            payeePercent: string,
                            showInCoverageInformation: boolean
                        }
                    ],
                    showAllBeneficiaries: boolean,
                    dependentSequenceNumber: number,
                    primaryForAllProduct: boolean,
                    agents: [
                        {
                            agentNumber: string,
                            agentName: string,
                            agentPercent: 0,
                            email: string,
                            phone: string
                        }
                    ],
                    showAllAgents: boolean,
                    name: string,
                    birthDate: string,
                    relationship: string,
                    planCodeDesc: string,
                    volume: number,
                    lastPremiumBilled: number,
                    waivedPremium: boolean,
                    memberResponsibility: boolean,
                    partiallyPremiumBilled: boolean,
                    lastPremiumBilledPartially: number,
                    waivedPremiumPartially: boolean,
                    benefitLimitsIndicator: boolean,
                    planCode: string,
                    contingentForAllProduct: boolean
                }
            ],
            memberProductLinks: [
                {
                    linkStatusCodeDescription: string,
                    fullName: string,
                    relationship: string,
                    linkEffectiveDate: string,
                    linkTerminationDate: string
                }
            ],
            showAllBeneficiaries: boolean,
            primaryForAllProduct: boolean,
            contingentForAllProduct: boolean,
            showAllAgents: boolean
        }
    ],
    title: string
}

export interface AgentInfo {
    agentNumber: string;
    agentName: string;
    agentPercent: number;
    email: string;
    phone: string
}
interface AgentInfoComponentProps extends ViewComponentProps {
    agent: AgentInfo
}

interface InfoProps extends ViewComponentProps {
    coverageSummary: CoverageSummary;
    setCoverageSummary: any;
    regConfig: any;
}

/*const initPlanDetail: Array<any> = [
    {
        "keyName": "Place X if Termed"
    },
    {
        "keyName": "Group",
        "keyValue": "65000"
    }
]*/

let initialCoverageSummary: CoverageSummary = {
    isProvider: true,
    name: "",
    userDateFormat: "",
    requestIDCardFlag: true,
    printIDCardFlag: true,
    displayBenefitLimitsFlag: true,
    displayClaimFlag: true,
    hasBenefitLimits: true,
    useDummyCard: false,
    member: {
        memberName: "",
        groupDisplayValue: "",
        accountDisplayValue: "",
        memberIDDisplayValue: "",
        payrollDefinition: "",
        futurePayrollEffectiveDate: "",
        futurePayrollDefinition: "",
        currencyName: "",
        countryCode: ""
    },
    location: {
        locationNumber: 0,
        locationName: "",
        effectiveDate: "",
        grade: "",
        status: "",
        category: ""
    },
    policy: {
        accountNumber: 0,
        accountDesc: "",
        effectiveDate: "",
        terminationDate: "",
        billing: "",
        frequency: "",
        billedToDate: "",
        buPaidToDate: "",
        coveragePaidToDate: "",
        billingAddress: "",
        benefitOffice: ""
    },
    enrollments: [
        {
            clientNumber: "",
            name: "",
            gender: "",
            birthDate: "",
            govtID: "",
            dependentSequenceNumber: 0,
            relationship: "",
            ext: {
                clientNumber: "",
                memberIDDisplayValue: "",
                address: "",
                mobile: "",
                email1: "",
                email2: "",
                prefMethod: ""
            },
            showClaims: true,
            showPersonalDetails: true
        }
    ],
    agents: [
        {
            agentNumber: "",
            agentName: "",
            agentPercent: 0,
            email: "",
            phone: ""
        }
    ],
    coverages: [
        {
            productName: "",
            benefitClassification: "",
            effectiveDate: "",
            terminationDate: "",
            planCodeDesc: "",
            coverageOption: "",
            volume: 0,
            lastPremiumBilled: 0,
            waivedPremium: true,
            memberResponsibility: true,
            partiallyPremiumBilled: true,
            lastPremiumBilledPartially: 0,
            waivedPremiumPartially: true,
            accessPolicyDetail: true,
            policyNumber: "",
            benefitLimitsIndicator: true,
            productID: "",
            planCode: "",
            dependentSequenceNumber: 0,
            agents: [
                {
                    agentNumber: "",
                    agentName: "",
                    agentPercent: 0,
                    email: "",
                    phone: ""
                }
            ],
            beneficiaries: [
                {
                    primaryBeneficiary: true,
                    beneficiaryName: "",
                    contingentBeneficiary: "",
                    payeePercent: "",
                    showInCoverageInformation: true
                }
            ],
            funds: [
                {
                    fundDescription: "",
                    fundYearBeginDate: "",
                    fundYearEndDate: "",
                    submissionGraceDays: 0,
                    lastTranDate: "",
                    totalContributions: 0,
                    totalClaims: 0,
                    targetAmount: 0,
                    forfeitureAmount: 0,
                    fundTransSeqNumber: 0,
                    fundBalance: 0
                }
            ],
            dependentCoverages: [
                {
                    beneficiaries: [
                        {
                            primaryBeneficiary: true,
                            beneficiaryName: "",
                            contingentBeneficiary: "",
                            payeePercent: "",
                            showInCoverageInformation: true
                        }
                    ],
                    showAllBeneficiaries: true,
                    dependentSequenceNumber: 0,
                    primaryForAllProduct: true,
                    agents: [
                        {
                            agentNumber: "",
                            agentName: "",
                            agentPercent: 0,
                            email: "",
                            phone: ""
                        }
                    ],
                    showAllAgents: true,
                    name: "",
                    birthDate: "",
                    relationship: "",
                    planCodeDesc: "",
                    volume: 0,
                    lastPremiumBilled: 0,
                    waivedPremium: true,
                    memberResponsibility: true,
                    partiallyPremiumBilled: true,
                    lastPremiumBilledPartially: 0,
                    waivedPremiumPartially: true,
                    benefitLimitsIndicator: true,
                    planCode: "",
                    contingentForAllProduct: true
                }
            ],
            memberProductLinks: [
                {
                    linkStatusCodeDescription: "",
                    fullName: "",
                    relationship: "",
                    linkEffectiveDate: "",
                    linkTerminationDate: ""
                }
            ],
            showAllBeneficiaries: true,
            primaryForAllProduct: true,
            contingentForAllProduct: true,
            showAllAgents: true
        }
    ],
    title: ""
}
let limitTableOption = {
    bPaginate: false,
    bSort: false,
    bInfo: false
};


const retrieve = (params: any, coverageSummary: any, setConfig: any, setCoverageSummary: any, props: any): void => {
    ajax({
        url: '/api/eligibilitySummary/view',
        params: params,
        success: (data: any) => {
            setConfig(data);
            setCoverageSummary({ ...coverageSummary, ...data });
        }
    });

}
let w: any = window;
export const win = w;
export const $: any = w.$;

export const CoverageSummaryComponent = withView((props: ViewComponentProps) => {
    const [coverageSummary, setCoverageSummary] = useState<CoverageSummary>(initialCoverageSummary);
    const pdfViewer = props.usePDFViewer({ ...props });
    //const [patientAndPlanYear, setPatientAndPlanYear] = useState<any>();
    const [patientList, setPatientList] = useState<any>();
    const [planYearList, setPlanYearList] = useState<any>();
    const [config, setConfig] = useState<any>();
    const [annualData, setAnnualData] = useState<Array<any>>();
    const [familyData, setFamilyData] = useState<Array<any>>();
    const [lifetimeData, setLifetimeData] = useState<Array<any>>();
    const [otherData, setOtherData] = useState<Array<any>>();
    const [coverage, setCoverage] = useState<any>();
    const [coverageB, setCoverageB] = useState<any>();
    const [pbList, setPBList] = useState<any>();
    const [cbList, setCBList] = useState<any>();
    const [rowNumber, setRowNumber] = useState<number>(0);
    const [participantIDNumber, setParticipantIDNumber] = useState<string>(props.getParam("participantIDNumber"));
    //const [patient, setPatient] = useState<number>(0);
    const defaultDate = new date();
    const [asOfDate, setAsOfDate] = useState<string>(props.getParam("asOfDate") === undefined ? defaultDate.toString() : props.getParam("asOfDate"));
    const companyCode: string = props.getParam("companyCode");
    const groupNumber: string = props.getParam("groupNumber");
    const accountNumber: number = parseInt(props.getParam("accountNumber"));
    //const participantIDNumber: string = props.getParam("participantIDNumber");
    const dependentSequenceNumber: number = parseInt(props.getParam("dependentSequenceNumber"));
    const hasDSN: boolean = isNaN(parseInt(props.getParam("dependentSequenceNumber"))) ? false : true;
    const agentNumber: string = props.getParam("agentNumber");
    const {userAuth} = useContext(SecurityContext);
    const [dependentSequenceNumberZeroList,setDependentSequenceNumberZeroList] = useState<any>([]);
   


    //const defaultAsOfDate: new date = (isEmptyObject(asOfDate) ? parseDate(asOfDate, config.dateformat) : new date());
    //setAsOfDate(props.getParam("asOfDate") === undefined ? defaultDate.toString() : props.getParam("asOfDate"));
    const fromPage: string = props.getParam("fromPage");
    const thisPage: string = 'coveragesummary'
    const [planDetail, setPlanDetail] = useState<any>(
        /*[
        {
            "keyName": "Place X if Termed"
        },
        {
            "keyName": "Group",
            "keyValue": "65000"
        }]
        */);

    const [fundDetailCount, refreshFundDetail] = useState<number>(0);
    //const [indDetailCount, refreshIndDetail] = useState<number>(0);
    const fundsDetail = useRef<any>();
    const fundRow = useRef<any>();
    const blFormProps = useRef<any>();
	const [clientNumber, setClientNumber] = useState<any>(props.getQueryParam("clientNumber") === undefined ? '' : props.getQueryParam("clientNumber"));

    /*let tableOption = {
                                                retrieve: true,
        bPaginate: false,
        bSort: false,
        bFilter: false,
        bLengthChange: false,
        language: {emptyTable: " " }
    };*/
    const showConsolidationBilling = props.isFeatureEnabled("base.consolidated.billing");

    const columns: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "4%" },
        { title: "common.lbl.description", name: "description", width: "40%", className: "fontBold" },
        { title: "common.lbl.limit", name: "limit", width: "8%", type: 'numeric', className: "text-right" },
        { title: "common.lbl.claim.accum.amt", name: "accumulatedAmount", width: "20%", type: 'numeric', className: "text-right" },
        { title: "common.lbl.percent.met", name: "Percent Met", width: "32%", type: 'progressBar', className: "text-center" },
    ];
    const otherColumns: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "4%" },
        { title: "common.lbl.description", name: "type", width: "40%", className: "fontBold" },
        { title: "common.lbl.limit", name: "limit", width: "16%", type: 'numeric', className: "text-right" },
        { title: "", name: "", width: "40%", className: "text-right" },
    ];

    function coverageAgentsDetail(coverageAgents: any) {
        setCoverage(coverageAgents);
        props.showModal('#coverageAgentsDetail');
    }
    function coverageBeneficiariesDetail(coverageBeneficiaries: any) {
        setCoverageB(coverageBeneficiaries);
        let pbL: Array<string> = [];
        let cbL: Array<string> = [];
        coverageBeneficiaries.beneficiaries.map((priBeneRow: any, priBeneIndex: number) => {
            if (priBeneRow.primaryBeneficiary) { pbL.push(priBeneRow.beneficiaryName + " " + priBeneRow.payeePercent) }
        });
        coverageBeneficiaries.beneficiaries.map((conBeneRow: any, conBeneIndex: number) => {
            if (!conBeneRow.primaryBeneficiary) { cbL.push(conBeneRow.beneficiaryName + " " + conBeneRow.payeePercent) }
        });
        setPBList(pbL);
        setCBList(cbL);
        props.showModal('#coverageBeneficiariesDetail');
    }
    function downloadPlanFile(productID: string, planCode: string) {
		ajax({
            url: '/api/eligibilitySummary/hasPlanFile',
            params: { companyCode: companyCode, groupNumber: groupNumber, accountNumber: accountNumber, participantIDNumber: participantIDNumber, planCode: planCode, productID: productID },
            success: (res: any) => {
				if(res.fileExists) {
					downloadFile(`/api/eligibilitySummary/downloadPlanFile/${companyCode}/${groupNumber}/${accountNumber}/${productID}/${planCode}/${participantIDNumber}`);													
				} else {
					planCodePDFShow(planCode);
				}
            }
        });
	}
    function planCodePDFShow(planCode: string) {
        ajax({
            url: '/api/eligibilitySummary/downloadPlanDetail',
            params: { companyCode: companyCode, groupNumber: groupNumber, accountNumber: accountNumber, participantIDNumber: participantIDNumber, planCode: planCode },
            success: (data: any) => {
                setPlanDetail(data);
            }
        });
        if (!isEmptyObject(planCode)) {
            props.showModal('#planDetail_popup');
        }
    }
    //setConfig(testCSValue);
    useEffect(() => {
        if (config === undefined) {
            //useEffect(() => {
            ajax({
                url: '/api/eligibilitySummary/view',
                params: {
                    companyCode: companyCode ? companyCode : '',
                    groupNumber: groupNumber ? groupNumber : '',
                    accountNumber: isNaN(accountNumber) ? 0 : accountNumber,
                    participantIDNumber: participantIDNumber ? participantIDNumber : '',
                    dependentSequenceNumber: isNaN(dependentSequenceNumber) ? 0 : dependentSequenceNumber,
                    agentNumber: agentNumber ? agentNumber : '',
                    asOfDate: asOfDate,
                    fromPage: fromPage ? fromPage : ''
                },
                success: (res: any) => {
                    setConfig(res);
                    setCoverageSummary({ ...coverageSummary, ...res });
                }
            });
        }
        //}, [config, asOfDate]);
    }, [config]);
    //Insurer name to be set   
    config?.enrollments?.map((item:any)=> {       
        if(item.dependentSequenceNumber < 1){
            if(!dependentSequenceNumberZeroList.includes(item.name)){            
            setDependentSequenceNumberZeroList([...dependentSequenceNumberZeroList,item.name]);
        }
    }
    });

    function getParticipantIdNumber(dependentCoverage: any, memberProductLinks: any) {
        if(!dependentCoverage || !memberProductLinks) return "";
        const matchedMemberLink = memberProductLinks.find((memberProductLink: any) => memberProductLink.fullName === dependentCoverage.name);
        return matchedMemberLink?.dependentSequenceNumber === 0 ? matchedMemberLink?.participantIDNumber : `${matchedMemberLink?.participantIDNumber}-${matchedMemberLink?.dependentSequenceNumber}`
    }
    //Coverage Table
    const tblCoverage = useMemo(() => {
        let refreshCount = fundDetailCount + 1;
        //let refreshIndCount = indDetailCount + 1;
        if (config !== undefined) {
            const Comp = useTable({
                id: 'tblCoverage',
                option: {
                    retrieve: true,
                    bPaginate: false,
                    bSort: false,
                    bFilter: false,
                    bLengthChange: false,
                    language: { emptyTable: props.getGlobalizedText("common.msg.noCoverageAvailable") }
                },
                onResponsiveDisplay: (rowNum: number, rowData: any, cells: Array<TableCell>, childNode: Element) => {
                    let els = childNode?.querySelectorAll("#showCoverageFund");
                    if (els !== null) {
                        els.forEach((el: any, index: number) => {
                            let fr = simpleSort(config.coverages, "productName")[rowNum]['funds'][index];
                            el.addEventListener('click', () => {
                                ajax({
                                    url: '/api/eligibilitySummary/fetchFundTransactions',
                                    params: {
                                        "companyCode": companyCode ? companyCode : '',
                                        "groupNumber": groupNumber ? groupNumber : '',
                                        "accountNumber": accountNumber ? accountNumber : '',
                                        "participantIDNumber": participantIDNumber ? participantIDNumber : '',
                                        "caseSeqNumber": fr.fundTransSeqNumber
                                    },
                                    success: (res: any) => {
                                        fundRow.current = fr;
                                        fundsDetail.current = res;
                                        refreshFundDetail(refreshCount++);
                                    }
                                });
                            });
                        })
                    }
                    let elsM = childNode?.querySelectorAll("#showCoverageFundM");
                    if (elsM !== null) {
                        elsM.forEach((elM: any, indexM: number) => {
                            let frM = simpleSort(config.coverages, "productName")[rowNum]['funds'][indexM];
                            elM.addEventListener('click', () => {
                                ajax({
                                    url: '/api/eligibilitySummary/fetchFundTransactions',
                                    params: {
                                        "companyCode": companyCode ? companyCode : '',
                                        "groupNumber": groupNumber ? groupNumber : '',
                                        "accountNumber": accountNumber ? accountNumber : '',
                                        "participantIDNumber": participantIDNumber ? participantIDNumber : '',
                                        "caseSeqNumber": frM.fundTransSeqNumber
                                    },
                                    success: (res: any) => {
                                        fundRow.current = frM;
                                        fundsDetail.current = res;
                                        refreshFundDetail(refreshCount++);
                                    }
                                });
                            });
                        })
                    }
                    let elsDC = childNode?.querySelectorAll("#showBenefitLimitTD");
                    if (elsDC !== null) {
                        elsDC.forEach((elDC: any, elDCIndex: number) => {
                            let eldcr = simpleSort(config.coverages, "productName")[rowNum]['dependentCoverages'][elDCIndex];
                            elDC.addEventListener('click', () => {
                                if (eldcr.benefitLimitsIndicator) {
                                    blDialogShowInd(rowNum, elDCIndex);
                                    blFormProps.current.values.effectiveYear = new Date().getFullYear();
                                    blFormProps.current.values.dependentSequenceNumber = simpleSort(config.coverages, "productName")[rowNum]['dependentCoverages'][elDCIndex].dependentSequenceNumber;
                                    blFormProps.current.values.planCode = simpleSort(config.coverages, "productName")[rowNum]['dependentCoverages'][elDCIndex].planCode;
                                    blFormProps.current.values.planCodeDesc = simpleSort(config.coverages, "productName")[rowNum]['dependentCoverages'][elDCIndex].planCodeDesc;
                                    blFormProps.current.values.productID = simpleSort(config.coverages, "productName")[rowNum].productID;
                                    blFormProps.current.values.productName = simpleSort(config.coverages, "productName")[rowNum].productName;

                                }
                                //setPatient(simpleSort(config.coverages, "productName")[rowNum]['dependentCoverages'][index].dependentSequenceNumber);
                                //refreshIndDetail(refreshIndCount++);
                            });
                        })
                    }
                    let elsDCM = childNode?.querySelectorAll("#showBenefitLimitTDM");
                    if (elsDCM !== null) {
                        elsDCM.forEach((elDCM: any, elDCMIndex: number) => {
                            //let dcr = simpleSort(config.coverages, "productName")[rowNum]['dependentCoverages'][index];
                            elDCM.addEventListener('click', () => {
                                blDialogShowInd(rowNum, elDCMIndex);
                                blFormProps.current.values.effectiveYear = new Date().getFullYear();
                                blFormProps.current.values.dependentSequenceNumber = simpleSort(config.coverages, "productName")[rowNum]['dependentCoverages'][elDCMIndex].dependentSequenceNumber;
                                blFormProps.current.values.planCode = simpleSort(config.coverages, "productName")[rowNum]['dependentCoverages'][elDCMIndex].planCode;
                                blFormProps.current.values.planCodeDesc = simpleSort(config.coverages, "productName")[rowNum]['dependentCoverages'][elDCMIndex].planCodeDesc;
                                blFormProps.current.values.productID = simpleSort(config.coverages, "productName")[rowNum].productID;
                                blFormProps.current.values.productName = simpleSort(config.coverages, "productName")[rowNum].productName;

                                //setPatient(simpleSort(config.coverages, "productName")[rowNum]['dependentCoverages'][index].dependentSequenceNumber);
                                //refreshIndDetail(refreshIndCount++);
                            });
                        })
                    }
                    childNode?.querySelector("#showCoverageAgents")?.addEventListener("click", (e: any) => {
                        coverageAgentsDetail(simpleSort(config.coverages, "productName")[rowNum]);
                    })
                    childNode?.querySelector("#showCoverageBeneficiaries")?.addEventListener("click", (e: any) => {
                        coverageBeneficiariesDetail(simpleSort(config.coverages, "productName")[rowNum]);
                    })

                    childNode?.querySelector("#planCodeLink")?.addEventListener("click", (e: any) => {
                        planCodePDFShow(simpleSort(config.coverages, "productName")[rowNum].planCodeDesc);
                    })
                    childNode?.querySelector("#policyNumberLink")?.addEventListener("click", (e: any) => {
                        props.next(`/policyDetail/${companyCode}/${simpleSort(config.coverages, "productName")[rowNum].policyNumber}`);
                    })

                    childNode?.querySelector("#benefitLimitLink")?.addEventListener("click", (e: any) => {
                        let benefitLimit = simpleSort(config.coverages, "productName")[rowNum];
                        blFormProps.current.values.effectiveYear = new Date().getFullYear();
                        blFormProps.current.values.dependentSequenceNumber = benefitLimit.dependentSequenceNumber;
                        blFormProps.current.values.planCode = benefitLimit.planCode;
                        blFormProps.current.values.planCodeDesc = benefitLimit.planCodeDesc;
                        blFormProps.current.values.productID = benefitLimit.productID;
                        blFormProps.current.values.productName = benefitLimit.productName;
                        setRowNumber(rowNum);
                        blDialogShow(rowNum);
                                     })


                },
                delayAdjust: true,
                childRows: 1,
                children: [
                    <thead key={0}>
                        <tr>
                            <th data-sortable={false} scope="col" className="gwp-details-control"></th>
                            <th scope="col" data-width="110px"><GlobalizedText message="common.lbl.product" /></th>
                            <th scope="col" data-width="110px"><GlobalizedText message="common.lbl.insured" /></th>
                            <th scope="col" data-width="180px"><GlobalizedText message="billpay.premiumBilled.benefitClassification" /></th>
                            <th scope="col" data-width="110px"><GlobalizedText message="common.lbl.effectiveDate" /></th>
                            <th scope="col" data-width="130px"><GlobalizedText message="common.lbl.terminationDate" /></th>
                            <th scope="col" data-width="120px"><GlobalizedText message="common.lbl.productPlan" /></th>
                            <th scope="col" data-width="140px"><GlobalizedText message="billpay.premiumBilled.coverageOption" /></th>
                            <th scope="col" data-width="120px"><GlobalizedText message={showConsolidationBilling ? "common.lbl.policyNumber" : ""} /></th>
                            <th className="gwp-dt-child" data-width="0%"></th>
                        </tr>
                    </thead>,
                    <tbody key={1}>
                        {
                            simpleSort(config.coverages, "productName")?.map((row: any, index: number) => {
                                let primaryBeneList: Array<string> = [];
                                let contingentBeneList: Array<string> = [];
                                row.beneficiaries?.map((priBeneRow: any) => {
                                    if (priBeneRow.primaryBeneficiary) { primaryBeneList.push(priBeneRow.beneficiaryName + " - " + priBeneRow.payeePercent) }
                                });
                                row.beneficiaries?.map((conBeneRow: any) => {
                                    if (!conBeneRow.primaryBeneficiary) { contingentBeneList.push(conBeneRow.beneficiaryName + " - " + conBeneRow.payeePercent) }
                                });

                                return <tr key={index}>
                                    <td data-gwp-child={(isEmptyObject(row.funds) && isEmptyObject(row.dependentCoverages) && isEmptyObject(row.memberProductLinks) && isEmptyObject(row.beneficiaries) && isEmptyObject(row.agents)) ? "no" : ''} className="gwp-details-control"></td>
                                    <td>{row.productName}</td>
                                    <td>{ dependentSequenceNumberZeroList.length > 0 ? dependentSequenceNumberZeroList.map((item:any)=>item) : ""}</td>
                                    <td>{row.benefitClassification}</td>
                                    <td>{row.effectiveDate}</td>
                                    <td>{row.terminationDate}</td>
                                    <td>
                                        <span id="planCodeLink" >
                                            {(row.productName === "Vision" || !row.planDownloadingEnabled) && <>{row.planCodeDesc}</>}
                                            {row.productName !== "Vision" && row.planDownloadingEnabled && <a href="javascript:void(0);" onClick={() => { downloadPlanFile(row.productID,row.planCode) }}>{row.planCodeDesc}</a>}
                                        </span>
                                    </td>
                                    <td>{row.coverageOption}</td>                                    
                                    <td>
                                        <Condition condition={showConsolidationBilling}>
                                            <div id="policyNumberLink">
                                                {row.accessPolicyDetail && <a href="javascript:void(0);" onClick={() => { props.next(`/policyDetail/${companyCode}/${row.policyNumber}`) }} >{row.policyNumber}</a>}
                                                {!row.accessPolicyDetail && <>{row.policyNumber}</>}
                                            </div>
                                        </Condition>
                                        <div id="benefitLimitLink">
                                            {(config.displayBenefitLimitsFlag && row.benefitLimitsIndicator) &&
                                                <div className="floatRight"><a href="javascript:void(0);" onClick={() => {
                                                    blFormProps.current.values.effectiveYear = new Date().getFullYear();
                                                    blFormProps.current.values.dependentSequenceNumber = row.dependentSequenceNumber;
                                                    blFormProps.current.values.planCode = row.planCode;
                                                    blFormProps.current.values.planCodeDesc = row.planCodeDesc;
                                                    blFormProps.current.values.productID = row.productID;
                                                    blFormProps.current.values.productName = row.productName;
                                                    setRowNumber(index);
                                                    blDialogShow(index);
                                                }}><props.Icon type="dollar" /></a></div>}
                                        </div>
                                    </td>
                                    {//!(isEmptyObject(row.funds) && isEmptyObject(row.memberProductLinks) && isEmptyObject(row.beneficiaries) && isEmptyObject(row.dependentCoverages)) &&
                                        <td className="gwp-dt-child">
                                            <props.Condition condition={!isEmptyObject(row.funds)}>
                                                <props.Row className="extInfo">
                                                    <props.Col xs={12} sm={12}>
                                                        <table id="fundInfo" className="noBorder subTable">
                                                            <thead key={0}>
                                                                <tr className="noBorder">
                                                                    <th scope="col"><GlobalizedText message="common.lbl.fund" /></th>
                                                                    <th scope="col" className="paddingLeft10px"><GlobalizedText message="common.lbl.startDate" /></th>
                                                                    <th scope="col"><GlobalizedText message="common.lbl.endDate" /></th>
                                                                    <th scope="col" data-type="numeric"><GlobalizedText message="common.lbl.fundBalance" /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody key={1} className="backgroundColorWhite">
                                                                {row.funds?.map((fr: any, frIndex: number) => {
                                                                    return <tr key={frIndex} className="backgroundColorWhite">
                                                                        <td>
                                                                            <props.LinkButton className="paddingLeft0px" id="showCoverageFund" >{fr.fundDescription}</props.LinkButton></td>
                                                                        <td>{fr.fundYearBeginDate}</td>
                                                                        <td>{fr.fundYearEndDate}</td>
                                                                        <td>{formatNumber(fr.fundBalance)}</td>
                                                                    </tr>
                                                                })}
                                                            </tbody >
                                                        </table>
                                                        <div id="fundInfoMobile" className="noBorder subTableMobile">
                                                            <label><GlobalizedText message="coverage.lbl.funds.covered"></GlobalizedText></label>
                                                            <div id="div_FundsCovered">
                                                                {row.funds?.map((fr: any, frIndex: number) => {
                                                                    return <>
                                                                        <div >
                                                                            <div>
                                                                                <dl className="gwp-dl " >
                                                                                    <dt className="subTableSubTitle" ><props.HR /></dt>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="common.lbl.fund" /></dt>
                                                                                    <dd className="subTableSubField"><props.LinkButton className="paddingLeft0px" id="showCoverageFundM" >{fr.fundDescription}</props.LinkButton></dd>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="common.lbl.startDate" /></dt>
                                                                                    <dd className="subTableSubField">{fr.fundYearBeginDate}</dd>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="common.lbl.endDate" /></dt>
                                                                                    <dd className="subTableSubField">{fr.fundYearEndDate}</dd>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="common.lbl.fundBalance" /></dt>
                                                                                    <dd className="subTableSubField">{formatNumber(fr.fundBalance)}</dd>
                                                                                </dl>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                })}
                                                            </div>
                                                        </div>
                                                    </props.Col>
                                                </props.Row>
                                            </props.Condition>
                                            <props.Condition condition={row.coverageOption === "Individual"}                                        >
                                                <props.Row className="extInfo">
                                                    <props.Col xs={12} sm={12}>
                                                        <table id="CoverageIndTable" className="noBorder subTable">
                                                            <thead key={0}>
                                                                <tr className="noBorder">
                                                                    <th scope="col" style = {{width : "12%"}}><GlobalizedText message="common.lbl.name" /></th>
                                                                    <th scope="col" style = {{width : "10%"}}><GlobalizedText message="common.lbl.dependentSeqNumber" /></th>
                                                                    <th scope="col" style = {{width : "15%"}}><GlobalizedText message="pi.relation" /></th>
                                                                    <th scope="col" style = {{width : "11%"}}><GlobalizedText message="common.lbl.effectiveDate" /></th>
                                                                    <th scope="col" style = {{width : "12%"}}><GlobalizedText message="common.lbl.terminationDate" /></th>
                                                                    <th scope="col" style = {{width : "38%"}}><GlobalizedText message="common.lbl.lastPremiumBilled" /></th>
                                                                    <th scope="col" data-width="2%"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody key={1} className="backgroundColorWhite">
                                                                {row.dependentCoverages?.map((IndSubRow: any, IndSubIndex: number) => {
                                                                    return <tr className="backgroundColorWhite">
                                                                        <td style = {{width : "12%"}}>{IndSubRow.name}</td>
                                                                        <td style = {{width : "10%"}}>{getParticipantIdNumber(IndSubRow, row.memberProductLinks)}</td>
                                                                        <td style = {{width : "15%"}}>{IndSubRow.relationship}</td>
                                                                        <td style = {{width : "11%"}}>{row.effectiveDate}</td>
                                                                        <td style = {{width : "12%"}}>{row.terminationDate}</td>
                                                                        <td className="textRight" style = {{width : "38%"}}>
                                                                            <dl className="gwp-dl">
                                                                                {(IndSubRow.waivedPremium || IndSubRow.memberResponsibility || IndSubRow.partiallyPremiumBilled) &&
                                                                                    <dd className="textLeft  paddingTop0px">
                                                                                        <props.Condition condition={IndSubRow.waivedPremium}>
                                                                                            <div className="cs-div"><GlobalizedText message='common.lbl.premiumBRWaived' /></div>
                                                                                        </props.Condition>
                                                                                        <props.Condition condition={IndSubRow.memberResponsibility}>
                                                                                            <div className="cs-div"><GlobalizedText message='common.lbl.memberBRResponsible' /></div>
                                                                                        </props.Condition>
                                                                                        <props.Condition condition={//row.partiallyPremiumBilled|| ||
                                                                                            !IndSubRow.waivedPremium && !IndSubRow.memberResponsibility && IndSubRow.partiallyPremiumBilled}>
                                                                                            <div className="cs-div">&nbsp;</div>
                                                                                        </props.Condition>
                                                                                    </dd>
                                                                                }
                                                                                <dd className="textRightL  paddingTop0px">
                                                                                    <div className="cs-div paddingRight10px">{isEmptyStr(IndSubRow.lastPremiumBilled) || IndSubRow.lastPremiumBilled === 0 ? '' : formatNumber(IndSubRow.lastPremiumBilled)}</div>
                                                                                </dd>
                                                                                <props.Condition condition={IndSubRow.partiallyPremiumBilled}>

                                                                                    <dd className="textLeft  paddingTop0px">
                                                                                        <props.Condition condition={IndSubRow.waivedPremiumPartially}>
                                                                                            <div className="cs-div"><props.Span className="gwp-label-required" /></div>
                                                                                        </props.Condition>
                                                                                    </dd>
                                                                                    <dd className="textRightL  paddingTop0px">
                                                                                        <div className="cs-div paddingRight10px">{IndSubRow.lastPremiumBilledPartially === 0 ? '' : formatNumber(IndSubRow.lastPremiumBilledPartially)}</div>
                                                                                    </dd>
                                                                                </props.Condition>
                                                                            </dl>
                                                                        </td>
                                                                        <td style = {{width : "2%"}} id="showBenefitLimitTD">{config.displayBenefitLimitsFlag && IndSubRow.benefitLimitsIndicator &&
                                                                            //IndSubRow.dependentSequenceNumber === 1 &&
                                                                            <div className="floatRight"><a href="javascript:void(0);" ><props.Icon type="dollar" /></a></div>}</td>
                                                                    </tr>
                                                                })}
                                                            </tbody >
                                                        </table>

                                                        <div id="CoverageIndTableMobile" className="noBorder subTableMobile">
                                                            <label><GlobalizedText message="coverage.lbl.dependents.covered"></GlobalizedText></label>
                                                            <div id="div_DependentsCovered">
                                                                {row.dependentCoverages?.map((IndSubRow: any, IndSubIndex: number) => {
                                                                    return <>
                                                                        <div >
                                                                            <div>
                                                                                <dl id="showBenefitLimitTDM" className="gwp-dl " >
                                                                                    <dt className="subTableSubTitle" ><props.HR /><label>{IndSubRow.name}</label></dt>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="common.lbl.dob" /></dt>
                                                                                    <dd className="subTableSubField">{IndSubRow.birthDate}</dd>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="pi.relation" /></dt>
                                                                                    <dd className="subTableSubField">{IndSubRow.relationship}</dd>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="common.lbl.plan" /></dt>
                                                                                    <dd className="subTableSubField">{IndSubRow.planCodeDesc}</dd>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="billpay.premiumBilled.volume" /></dt>
                                                                                    <dd className="subTableSubField">{IndSubRow.volume === 0 ? '' : formatNumber(IndSubRow.volume)}</dd>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="common.lbl.lastPremiumBilled" /></dt>
                                                                                    <dd className="subTableSubField">
                                                                                        <div >{isEmptyStr(IndSubRow.lastPremiumBilled) || IndSubRow.lastPremiumBilled === 0 ? '' : formatNumber(IndSubRow.lastPremiumBilled)}</div>
                                                                                        <div >
                                                                                            {IndSubRow.waivedPremium && <GlobalizedText message="common.lbl.premiumBRWaived" />}
                                                                                            {IndSubRow.memberResponsibility && <GlobalizedText message="common.lbl.memberBRResponsible" />}
                                                                                            {(IndSubRow.waivedPremium || IndSubRow.memberResponsibility) && <>&nbsp;</>}
                                                                                        </div>
                                                                                        {IndSubRow.partiallyPremiumBilled && <>
                                                                                            <br />
                                                                                            <div >{IndSubRow.lastPremiumBilledPartially === 0 ? '' : formatNumber(IndSubRow.lastPremiumBilledPartially)}</div>
                                                                                            <div >{IndSubRow.waivedPremiumPartially && <><props.Span className="gwp-label-required" />										</>}									</div>
                                                                                        </>}
                                                                                    </dd>
                                                                                    {config.displayBenefitLimitsFlag && IndSubRow.benefitLimitsIndicator && <>
                                                                                        <dt className="subTableSubField"><GlobalizedText message="common.lbl.claim.benefit.limits" /></dt>
                                                                                        <dd className="subTableSubField"><a href="javascript:void(0);" ><props.Icon type="dollar" /></a></dd>
                                                                                    </>}
                                                                                </dl>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                })}
                                                            </div>
                                                        </div>
                                                    </props.Col>
                                                </props.Row>
                                            </props.Condition>
                                            <props.Condition condition={row.coverageOption !== "Individual" && !isEmptyObject(row.memberProductLinks)}>
                                                <props.Row className="extInfo">
                                                    <props.Col xs={12} sm={12}>
                                                        <table id="CoverageProTable" className="noBorder subTable">
                                                            <thead key={0}>
                                                                <tr className="noBorder">                                                                   
                                                                    <th scope="col" style = {{width : "12%"}}><GlobalizedText message="common.lbl.name" /></th>
                                                                    <th scope="col" style = {{width : "10%"}}><GlobalizedText message="common.lbl.dependentSeqNumber" /></th>
                                                                    <th scope="col" style = {{width : "15%"}}><GlobalizedText message="pi.relation" /></th>
                                                                    <th scope="col" style = {{width : "11%"}}><GlobalizedText message="common.lbl.effectiveDate" /></th>                                                                    
                                                                    <th scope="col" style = {{width : "12%"}}><GlobalizedText message="common.lbl.terminationDate" /></th>
                                                                    <th scope="col" style = {{width : "40%"}}><GlobalizedText message="common.lbl.lastPremiumBilled" /></th>
                                                                    
                                                                </tr>
                                                            </thead>
                                                            <tbody key={1} className="backgroundColorWhite">
                                                                {row.memberProductLinks?.map((ProSubRow: any) => {
                                                                    return <tr className="backgroundColorWhite">                                                                       
                                                                        <td style = {{width : "12%"}}>{ProSubRow.fullName}</td>
                                                                        <td style = {{width : "10%"}}>{ProSubRow.dependentSequenceNumber === 0 ? ProSubRow.participantIDNumber:ProSubRow.participantIDNumber + '-'+ ProSubRow.dependentSequenceNumber}</td>
                                                                        <td style = {{width : "15%"}}>{ProSubRow.relationship}</td>
                                                                        <td style = {{width : "11%"}}>{ProSubRow.dependentSequenceNumber === 0 ? row.effectiveDate : ProSubRow.linkEffectiveDate}</td>                                                                        
                                                                        <td style = {{width : "12%"}}>{ProSubRow.dependentSequenceNumber === 0 ? row.terminationDate : ProSubRow.linkTerminationDate}</td>
                                                                        <td style = {{width : "40%"}}>{ProSubRow.lastPremiumBilled === 0 ? '' : ProSubRow.lastPremiumBilled}</td>
                                                                    </tr>
                                                                })}
                                                            </tbody >
                                                        </table>

                                                        <div id="memberProductInfoMobile" className="noBorder subTableMobile">
                                                            {//<label>Member Product covered</label>
                                                            }
                                                            <div id="div_memberProductInfoCovered">
                                                                {row.memberProductLinks?.map((mr: any, mrIndex: number) => {
                                                                    return <>
                                                                        <div >
                                                                            <div>
                                                                                <dl className="gwp-dl " >
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="common.lbl.status" /></dt>
                                                                                    <dd className="subTableSubField">{mr.linkStatusCodeDescription}</dd>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="common.lbl.name" /></dt>
                                                                                    <dd className="subTableSubField">{mr.fullName}</dd>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="pi.relation" /></dt>
                                                                                    <dd className="subTableSubField">{mr.relationship}</dd>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="common.lbl.effectiveDate" /></dt>
                                                                                    <dd className="subTableSubField">{mr.linkEffectiveDate}</dd>
                                                                                    <dt className="subTableSubField" ><GlobalizedText message="common.lbl.terminationDate" /></dt>
                                                                                    <dd className="subTableSubField">{mr.linkTerminationDate}</dd>
                                                                                </dl>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                })}
                                                            </div>
                                                        </div>

                                                    </props.Col>
                                                </props.Row>
                                            </props.Condition>
                                            <props.Condition condition={!isEmptyObject(row.beneficiaries)}>
                                                {row.beneficiaries !== '' && row.beneficiaries !== undefined && <>
                                                    <props.Row className="extInfo">
                                                        <props.Col md={4} sm={6} xs={12}>
                                                            <props.DL>
                                                                <props.DLI title="pd.lbl.primarybeneficiary" isHtml={true} />
                                                                <props.Condition condition={!isEmptyObject(primaryBeneList[0])}>{primaryBeneList[0]} <br /></props.Condition>
                                                                <props.Condition condition={!isEmptyObject(primaryBeneList[1])}>{primaryBeneList[1]} <br /></props.Condition>
                                                                <props.Condition condition={row.primaryForAllProduct}><props.Label className="coverage-bl-font-black"><GlobalizedText message="common.msg.benefiDesignation" /></props.Label></props.Condition>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col md={4} sm={6} xs={12}>
                                                            <props.DL>
                                                                <props.DLI title="pd.lbl.contingentbeneficiary" />
                                                                <props.Condition condition={!isEmptyObject(contingentBeneList[0])}>{contingentBeneList[0]} <br /></props.Condition>
                                                                <props.Condition condition={!isEmptyObject(contingentBeneList[1])}>{contingentBeneList[1]} <br /></props.Condition>
                                                                <props.Condition condition={row.contingentForAllProduct}><props.Label className="coverage-bl-font-black"><GlobalizedText message="common.msg.benefiDesignation" /></props.Label></props.Condition>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col md={4} sm={6} xs={12}>
                                                            <props.Condition condition={primaryBeneList?.length > 2 || contingentBeneList?.length > 2}>
                                                                <props.LinkButton className="paddingTop6px marginTop5px" id="showCoverageBeneficiaries"><props.Icon type="view-all" label="pd.lbl.viewallbeneficiary"></props.Icon></props.LinkButton>
                                                            </props.Condition>
                                                        </props.Col>
                                                    </props.Row>
                                                </>}
                                            </props.Condition>
                                            <props.Condition condition={!isEmptyObject(row.agents) && !isEmptyObject(row.beneficiaries)}>
                                                <props.Row>
                                                    <props.Col md={12} sm={12} xs={12}>
                                                        <props.HR />
                                                    </props.Col>
                                                </props.Row>

                                            </props.Condition>
                                            <props.Condition condition={!isEmptyObject(row.agents)}>
                                                {!isEmptyObject(row.agents) && <>
                                                    <props.Row className="extInfo">
                                                        {!isEmptyObject(row.agents[0]) && <div>
                                                            <props.Col md={12} sm={12} xs={12}>
                                                                <props.Label ><GlobalizedText message="common.lbl.agents" /></props.Label>
                                                            </props.Col>
                                                            <props.Col md={4} sm={6} xs={12}>
                                                                <props.Label className="paddingLeft15px">{row.agents[0].agentNumber + ' ' + row.agents[0].agentName}</props.Label>
                                                                <props.Condition condition={!isEmptyObject(row.agents[0].agentPercent) && row.agents.length > 2}>
                                                                    <br />
                                                                    <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="common.lbl.split" /></props.Label>{formatNumber(row.agents[0].agentPercent, { minimumFractionDigits: 0 }) + "%"}
                                                                </props.Condition>
                                                                <br />
                                                                <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="pd.lbl.phone" /></props.Label>{row.agents[0].phone}
                                                                <br />
                                                                <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="pd.lbl.email" /></props.Label><props.Link condition={!isEmptyObject(row.agents[0].email)} className="agentEmail" to={`mailto:${row.agents[0].email}"`}>{row.agents[0].email}</props.Link>

                                                            </props.Col>
                                                        </div>
                                                        }
                                                        {!isEmptyObject(row.agents[1]) &&
                                                            <props.Col md={4} sm={6} xs={12}>
                                                                <props.Label className="paddingLeft15px">{row.agents[1].agentNumber + ' ' + row.agents[1].agentName}</props.Label>
                                                                {!isEmptyObject(row.agents[1].agentPercent) && row.agents.length > 2 && <>
                                                                    <br />
                                                                    <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="common.lbl.split" /></props.Label>{formatNumber(row.agents[1].agentPercent, { minimumFractionDigits: 0 }) + "%"}
                                                                </>}
                                                                <br />
                                                                <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="pd.lbl.phone" /></props.Label>{row.agents[1].phone}
                                                                <br />
                                                                <props.Label className="fontBlue paddingLeft15px"><GlobalizedText message="pd.lbl.email" /></props.Label><props.Link condition={!isEmptyObject(row.agents[1].email)} className="agentEmail" to={`mailto:${row.agents[1].email}"`}>{row.agents[1].email}</props.Link>
                                                            </props.Col>
                                                        }
                                                        {
                                                            row.agents.length > 2 &&
                                                            <props.Col md={4} sm={6} xs={12}>
                                                                <props.LinkButton className="paddingLeft15px" id="showCoverageAgents" onClick={() => { props.showModal("#allAgentsDialog") }}><props.Icon type="view-all" label="common.lbl.viewAllAgents"></props.Icon></props.LinkButton>
                                                            </props.Col>
                                                        }
                                                    </props.Row>
                                                </>
                                                }
                                            </props.Condition>
                                        </td>
                                    }
                                </tr>
                            })
                        }
                    </tbody >
                ]
            }).Component;
            return <Comp />;
        } else {
            return <props.Span></props.Span>;
        }
        //}, [config?.coverages]);
    }, [config,dependentSequenceNumberZeroList]);


    if (config === undefined) {
        return <></>;
    }

    function blDialogShowInd(row: number, depRow: number) {
        showMask("#csblDialog");
        let blDate = isEmptyObject(asOfDate) ? formatDate(config.userDateFormat, new Date()) : asOfDate.toString();
        let bl = simpleSort(config.coverages, "productName");
        let blProductID = bl[row].productID;
        let blPatient = bl[row].dependentCoverages[depRow]?.dependentSequenceNumber;
        let blPlanCode = bl[row].dependentCoverages[depRow]?.planCode;
        ajax({
            url: '/api/eligibilitySummary/getPatientAndPlanYearList',
            params: {
                companyCode: companyCode,
                groupNumber: groupNumber,
                accountNumber: accountNumber,
                participantIDNumber: participantIDNumber,
                productID: blProductID,
                dependentSequenceNumber: hasDSN ? dependentSequenceNumber : blPatient,
                asOfDate: blDate
            },
            success: (res: any) => {
                setPatientList(res.patientList);
                setPlanYearList(res.planYearList);
            }
        });
        ajax({
            url: '/api/claimdetail/fetchBenefitLimits',
            params: {
                companyCode: companyCode,
                groupNumber: groupNumber,
                accountNumber: accountNumber,
                productId: blProductID,
                planCode: blPlanCode,
                participantIDNumber: participantIDNumber,
                effectiveYear: new Date().getFullYear(),
                fromPage: "CoverageSummary",
                dependentSequenceNumber: blPatient
            },
            success: (res) => {
                setAnnualData(res.annualLimits);
                setFamilyData(res.familyAnnualLimits);
                setLifetimeData(res.lifetimeLimits);
                setOtherData(res.otherLimits);
            }
        });
        hideMask("#csblDialog");
        props.showModal('#csblDialog');
    }
    const depSequence = config?.member.memberIDDisplayValue?.indexOf("-")!==-1? config?.member.memberIDDisplayValue?.split("-")?.[1]: 0

    function blDialogShow(row: number) {
        showMask("#csblDialog");
        let blDate = formatDate(config.userDateFormat, new Date());
        let bl = simpleSort(config?.coverages, "productName");
        let blProductID = bl[row].productID;
        let blPlanCode = bl[row].planCode;
        let blAsOfDate = isEmptyObject(asOfDate) ? blDate.toString() : asOfDate.toString();
        let blPatient = bl[row].dependentSequenceNumber
        ajax({
            url: '/api/eligibilitySummary/getPatientAndPlanYearList',
            params: {
                companyCode: companyCode,
                groupNumber: groupNumber,
                accountNumber: accountNumber,
                participantIDNumber: participantIDNumber,
                productID: blProductID,
                dependentSequenceNumber: hasDSN ? dependentSequenceNumber : blPatient,//isNaN(dependentSequenceNumber) ? 0 : dependentSequenceNumber,
                asOfDate: blAsOfDate
            },
            success: (res: any) => {
                if(userAuth.userTypeRegCode === UserTypeCode.DEPENDENT) {
                    const patientList1 = res.patientList.filter((List:any, index:any) => List.name.toUpperCase() === config.member.memberName?.toUpperCase());
                    setPatientList(patientList1);
                    setPlanYearList(res.planYearList);
                } else {    
                    setPatientList(res.patientList);
                    setPlanYearList(res.planYearList);
            }
        }
        });
        ajax({
            url: '/api/claimdetail/fetchBenefitLimits',
            params: {
                companyCode: companyCode,
                groupNumber: groupNumber,
                accountNumber: accountNumber,
                productId: blProductID,
                planCode: blPlanCode,
                participantIDNumber: participantIDNumber,
                effectiveYear: new Date().getFullYear(),
                fromPage: "CoverageSummary",
                dependentSequenceNumber: depSequence ? depSequence : blPatient
            },
            success: (res) => {
                setAnnualData(res.annualLimits);
                setFamilyData(res.familyAnnualLimits);
                setLifetimeData(res.lifetimeLimits);
                setOtherData(res.otherLimits);
            }
        });
        hideMask("#csblDialog");
        props.showModal('#csblDialog');
    }

    function patientChangeHandler(e: any, formProps: any) {
        showMask("#csblDialog .modal-content");
        let patientValueAsdependentSequenceNumber = e.target.value;
        let blProductID = blFormProps.current.values.productID;
        let blPlanCode = blFormProps.current.values.planCode;
        ajax({
            url: '/api/claimdetail/fetchBenefitLimits',
            showMask: false,
            params: {
                companyCode: companyCode,
                groupNumber: groupNumber,
                accountNumber: accountNumber,
                productId: blProductID,
                planCode: blPlanCode,
                participantIDNumber: participantIDNumber,
                effectiveYear: formProps.values.effectiveYear,
                fromPage: "CoverageSummary",
                dependentSequenceNumber: patientValueAsdependentSequenceNumber
            },
            success: (res) => {
                setAnnualData(res.annualLimits);
                setFamilyData(res.familyAnnualLimits);
                setLifetimeData(res.lifetimeLimits);
                setOtherData(res.otherLimits);

            }, callback: () => {
                hideMask("#csblDialog .modal-content");
            }
        });

    }

    function yearChangeHandler(e: any, formProps: any) {
        showMask("#csblDialog .modal-content");
        let year = e.target.value;
        
        let blProductID = blFormProps.current.values.productID;
        let blPlanCode = blFormProps.current.values.planCode;
        let selectedDependentSequenceNumber = formProps.values.dependentSequenceNumber;
        
        ajax({
            url: '/api/claimdetail/fetchBenefitLimits',
            showMask: false,
            params: {
                companyCode: companyCode,
                groupNumber: groupNumber,
                accountNumber: accountNumber,
                productId: blProductID,
                planCode: blPlanCode,
                participantIDNumber: participantIDNumber,
                effectiveYear: year,
                fromPage: "CoverageSummary",
                dependentSequenceNumber: selectedDependentSequenceNumber
            },
            success: (res) => {
                setAnnualData(res.annualLimits);
                setFamilyData(res.familyAnnualLimits);
                setLifetimeData(res.lifetimeLimits);
                setOtherData(res.otherLimits);
            }, callback: () => {
                hideMask("#csblDialog .modal-content");
            }
        });

    }

    //Enrollment Table
    const tblEnrollment = useTable({
        id: 'tblEnrollment',
        option: {
            retrieve: true,
            bPaginate: false,
            bSort: false,
            bFilter: false,
            bLengthChange: false,
            language: { emptyTable: "common.coverage.no.data" }
        },
        onResponsiveDisplay: (rowNum: number, rowData: any, cells: Array<TableCell>, childNode: Element) => {

            childNode?.querySelector("#enrollmentClaimLink")?.addEventListener("click", (e: any) => {
                props.next(`/memberClaimInquiry?companyCode=${companyCode}&groupNumber=${groupNumber}&accountNumber=${accountNumber}&participantIDNumber=${participantIDNumber}&dependentSequenceNumber=${config.enrollments[rowNum].dependentSequenceNumber}&from=${thisPage}`);
            })
        },
        delayAdjust: true,
        childRows: 1,
        children: [
            <thead key={0}>
                <tr>
                    <th data-sortable={false} scope="col" className="gwp-details-control"></th>
                    <th scope="col"><GlobalizedText message="common.lbl.name" /></th>
                    <th scope="col"><GlobalizedText message="pi.relation" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.dob" /></th>
                    <th scope="col"><GlobalizedText message="pi.gender" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.govID" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.claims" /></th>
                    <th className="gwp-dt-child" data-width="0%"></th>
                </tr>
            </thead>,
            <tbody key={1}>
                {config.enrollments?.map((row: any, index: number) => {
                    return <tr key={index}>
                        <td data-gwp-child={(!isEmptyObject(row.ext) && row.showPersonalDetails) ? '' : "no"} className="gwp-details-control"></td>
                        <td>{row.showPersonalDetails && !config.isProvider &&
                            <a href="#" onClick={() => props.next(`/personalInformation?isUpdateMode=false&personalInfoType=1&companyCode=${companyCode}&groupNumber=${groupNumber}&accountNumber=${accountNumber}&participantIDNumber=${participantIDNumber}&dependentSequenceNumber=${row.dependentSequenceNumber}&from=${thisPage}`)}>
                                {row.name}
                            </a>}
                            {(!row.showPersonalDetails || config.isProvider) && <>{row.name}</>}

                        </td>
                        <td>{row.relationship}</td>
                        <td>{row.birthDate}</td>
                        <td>{row.gender}</td>
                        <td>{row.govtID}</td>
                        <td ><span id="enrollmentClaimLink">
                            {row.showClaims && config.displayClaimFlag &&
                                <a href="#" onClick={() => props.next(`/memberClaimInquiry?companyCode=${companyCode}&groupNumber=${groupNumber}&accountNumber=${accountNumber}&participantIDNumber=${participantIDNumber}&dependentSequenceNumber=${row.dependentSequenceNumber}&from=${thisPage}`)}>
                                    <props.Icon type="view-claim"></props.Icon>
                                </a>
                            }
                        </span>
                        </td>
                        <td className="gwp-dt-child">
                            <props.Row className="extInfo">
                                <props.Col md={4} sm={4} xs={12}>
                                    <props.DL>
                                        {row.relationship === 'Member' &&
                                            <props.DLI title="common.lbl.memberID" desc={row.ext.memberIDDisplayValue} />
                                        }
                                        <props.DLI title="pd.lbl.address" isHtml={true} desc={row.ext.address} />
                                        <props.DLI title="" desc="" />
                                    </props.DL>
                                </props.Col>
                                <props.Col md={4} sm={4} xs={12}>
                                    <props.DL>
                                        <props.DLI title="common.lbl.emailAddress1" desc={row.ext.email1} />
                                        <props.DLI title="common.lbl.emailAddress2" desc={row.ext.email2} />
                                    </props.DL>
                                </props.Col>
                                <props.Col md={4} sm={4} xs={12}>
                                    <props.DL>
                                        <props.DLI title="common.lbl.mobilePhone" desc={row.ext.mobile} />
                                        <props.DLI title="pi.prefered.contract.method" desc={row.ext.prefMethod} />
                                    </props.DL>
                                </props.Col>
                            </props.Row>
                        </td>
                    </tr>
                })}
            </tbody >
        ]
    });

    //Location Table
    const tblLocation = useTable({
        id: 'tblLocation',
        option: {
            retrieve: true,
            bPaginate: false,
            bSort: false,
            bFilter: false,
            bLengthChange: false,
            language: { emptyTable: " " }
        },
        delayAdjust: true,
        childRows: 1,
        children: [
            <thead key={0}>
                <tr>
                    <th scope="col" className="gwp-details-control"></th>
                    <th scope="col"><GlobalizedText message="common.lbl.locationNumberandName" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.effectiveDate" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.grade" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.status" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.category" /></th>
                </tr>
            </thead>,
            <tbody key={1}>
                <tr>
                    <td data-gwp-child="no" className="gwp-details-control"></td>
                    <td>{coverageSummary.location.locationNumber + " - " + coverageSummary.location.locationName}</td>
                    <td>{coverageSummary.location.effectiveDate}</td>
                    <td>{coverageSummary.location.grade}</td>
                    <td>{coverageSummary.location.status}</td>
                    <td>{coverageSummary.location.category}</td>
                </tr>
            </tbody >
        ]
    });

    //Payment Table
    const tblBilling = useTable({
        id: 'tblBilling',
        option: {
            retrieve: true,
            bPaginate: false,
            bSort: false,
            bFilter: false,
            bLengthChange: false,
            language: { emptyTable: props.getGlobalizedText("common.no.data") }
        },
        childRows: 1,
        delayAdjust: true,
        children: [
            <thead key={0}>
                <tr>
                    <th scope="col" className="gwp-details-control"></th>
                    <th scope="col"><GlobalizedText message="common.lbl.account" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.effectiveTerminationDate" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.billing" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.frequency" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.billedtoDate" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.billingunit.paidtodate" /></th>
                    <th scope="col" className={config.policy?.billing === "Worksite" ? "" : "displayNone"} > <GlobalizedText message="common.lbl.coverage.paidtodate" /></th>
                    <th className="gwp-dt-child" data-width="0%"></th>
                </tr>
            </thead>,
            <tbody key={1}>
                {!isEmptyObject(config.policy) &&
                    <tr>
                        <td data-gwp-child={isEmptyObject(config.policy) ? "no" : ''} className="gwp-details-control"></td>
                        <td>{isEmptyObject(config.policy?.accountDesc) ? null : config.policy?.accountNumber + ' - ' + config.policy?.accountDesc}</td>
                        <td>{isEmptyObject(config.policy?.terminationDate) ? config.policy?.effectiveDate : config.policy?.effectiveDate + ' / ' + config.policy?.terminationDate}</td>
                        <td>{config.policy?.billing}</td>
                        <td>{config.policy?.frequency}</td>
                        <td>{config.policy?.billedToDate}</td>
                        <td>{config.policy?.buPaidToDate}</td>
                        <td className={config.policy?.billing === "Worksite" ? "" : "displayNone"}>{config.policy?.coveragePaidToDate}</td>
                        <td className="gwp-dt-child">
                            <props.Row className="extInfo">
                                <props.Col md={5} sm={5} xs={12}>
                                    <props.DL>
                                        <props.DLI title="payment.lbl.billingAddress" isHtml={true} desc={config.policy?.billingAddress} />
                                    </props.DL>
                                </props.Col>
                                <props.Col md={7} sm={7} xs={12}>
                                    <props.DL>
                                        <props.DLI title="billpay.beneoff" desc={config.policy?.benefitOffice} />
                                    </props.DL>
                                </props.Col>
                            </props.Row>
                        </td>
                    </tr>
                }
            </tbody >
        ]
    });

    return <>
        <Formik initialValues={{ ...config, asOfDate: asOfDate }}
            onSubmit={(values, { setSubmitting }) => {
                // props.setInitParams(values);
                //setAsOfDate(values.asOfDate);
            }}>
            {FormProps => <Form className="form-inline">
                <div>
                    <props.Panel id="memberInfoPanel">
                        <props.PanelHeading>
                            <props.Row>
                                <props.Col sm="7" md="7" xs="12">
                                    <div className="coverage-title paddingTop6px">{config.isProvider ? config.name : config.member.memberName}</div>
                                </props.Col>
                                <props.Col sm="5" md="5" xs="12">
                                    <div className="textLeft">
                                        <props.DateTimePickerControl className="datePicker" name="asOfDate" label="common.lbl.asOfDate" dateformat={config.userDateFormat} showFormat={false} defaultValue={isEmptyObject(asOfDate) ? new Date() : parseDate(asOfDate, config.dateformat)} />
                                        <props.Button data-print-visible="false" className="marginTop5px" id="asOfDateSubmit" onClick={() => {
                                            setAsOfDate(FormProps.values.asOfDate);
                                            retrieve({
                                                companyCode: companyCode,
                                                groupNumber: groupNumber,
                                                accountNumber: accountNumber,
                                                participantIDNumber: participantIDNumber,
                                                dependentSequenceNumber: isNaN(dependentSequenceNumber) ? 0 : dependentSequenceNumber,
                                                agentNumber: agentNumber ? agentNumber : '',
                                                asOfDate: FormProps.values.asOfDate,
                                                fromPage: fromPage ? fromPage : ''
                                            }, coverageSummary, setConfig, setCoverageSummary, props);

                                        }} withoutContainer={true} type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                                    </div>
                                </props.Col>
                            </props.Row>
                        </props.PanelHeading>
                        <props.PanelBody>
                            <props.Row>
                                <props.Col sm="5" md="5" xs="12">
                                    <props.DL id="memInfo" className="dl-horizontal marginLeft180px">
                                        <props.DLI title="common.lbl.group" desc={config.member.groupDisplayValue} />
                                        <props.DLI title="common.lbl.account" desc={trim(config.member.accountDisplayValue)} />
                                        <props.DLI title={!dependentSequenceNumber? "common.lbl.memberID" : "common.lbl.dependentID"} desc={trim(config.member.memberIDDisplayValue)} />
                                        <props.DLI condition={!isEmptyObject(config.member.payrollDefinition)} title="common.lbl.currentpayrollschedule" desc={config.member.payrollDefinition} />
                                        <props.DLI condition={!isEmptyObject(config.member.futurePayrollEffectiveDate)} title="common.lbl.futurepayrollschedule.effdate" desc={config.member.futurePayrollEffectiveDate} />
                                        <props.DLI condition={!isEmptyObject(config.member.futurePayrollDefinition)} title="common.lbl.futurepayrollschedule" desc={config.member.futurePayrollDefinition} />
                                    </props.DL>
                                </props.Col>
                                <props.Col sm="3" md="3" xs="12">
                                    <props.Row>
                                        <props.Button data-print-visible="false" condition={config.requestIDCardFlag === undefined ? false : config.requestIDCardFlag && !config.isProvider} className="marginBtn" type="button" withoutContainer={true} onClick={() => requestIDCard(props)}><GlobalizedText message="common.lbl.requestIDCard" /></props.Button>
                                    </props.Row>
                                    <props.Row>
                                        <props.Button data-print-visible="false" condition={config.printIDCardFlag === undefined ? false : config.printIDCardFlag && !config.isProvider} className="marginBtn marginTop5px" type="button" withoutContainer={true} onClick={() => { coverageSummary.useDummyCard ? printDummyIDCard(pdfViewer) : printIDCard(pdfViewer, props) }}><GlobalizedText message="common.lbl.viewOrPrintIDCard" /></props.Button>
                                    </props.Row>
									<props.Row>
										<props.Button data-print-visible="false" className="marginBtn marginTop5px" type="button" withoutContainer={true} onClick={() => viewVendors(props, config)}><GlobalizedText message="my.ins.act.opt.view.carriers" /></props.Button>
                                    </props.Row>
                                </props.Col>
                                <props.Col sm="4" md="4" xs="12" className="coverage-container">
                                    <props.Condition condition={!isEmptyObject(config.agents)}>
                                        {!isEmptyObject(config.agents) && <AgentsInfoPanel {...props} showMaxAgent={1} telLink={false} agents={config.agents.map((agentInfo: any) => {
                                            return { fullName: agentInfo.agentNumber + ' ' + agentInfo.agentName, phone: agentInfo.phone, email: agentInfo.email, splitPerctage: config.agents.length === 1 ? null : formatNumber(agentInfo.agentPercent, { minimumFractionDigits: 0 }) }
                                        })} from='coverageSummary' ></AgentsInfoPanel>}
                                    </props.Condition>
                                </props.Col>
                            </props.Row>
                        </props.PanelBody>
                    </props.Panel>
                    
                    <props.Row>
                        <props.Col md="12">
                            <props.Div className="gwp-float-right" style={{ marginRight: -15 + "px" }}>
                                <props.Span className="gwp-icon gwp-icon-print gwp-icon-sm" style={{ marginRight: -5 + "px" }}></props.Span>
                                <props.Button data-print-visible="false" className="printButton" printVisible={false} onClick={() => props.print({ orientation: 'landscape' })}><GlobalizedText message="common.print" /></props.Button>
                            </props.Div>
                        </props.Col>
                    </props.Row>

                    { !dependentSequenceNumber &&
                    <props.Panel id="enrollInfoPanel">
                        <props.PanelHeading>
                            <div className="coverage-title"><GlobalizedText message="common.lbl.enrollmentInformation" /></div>
                        </props.PanelHeading>
                        <props.PanelBody replaceClass={true} className="coverage-panel-body">
                            <tblEnrollment.Component />
                        </props.PanelBody>
                    </props.Panel>
                    }
                    <props.Panel id="locationInfoPanel" condition={!isEmptyObject(config.location) && !config.isProvider}>
                        <props.PanelHeading>
                            <div className="coverage-title"><GlobalizedText message="common.lbl.locationInformation" /></div>
                        </props.PanelHeading>
                        <props.PanelBody replaceClass={true} className="coverage-panel-body">
                            <tblLocation.Component></tblLocation.Component>
                        </props.PanelBody>
                    </props.Panel>
                    { !dependentSequenceNumber &&
                    <props.Panel id="billingInfoPanel" condition={!config.isProvider && config.displayBilling}>
                        <props.PanelHeading>
                            <div className="coverage-title"><GlobalizedText message={showConsolidationBilling ? "common.lbl.coverage.billinginfo" : "pd.lbl.policyinfo"} /></div>
                        </props.PanelHeading>
                        <props.PanelBody replaceClass={true} className="coverage-panel-body">
                            <tblBilling.Component></tblBilling.Component>
                        </props.PanelBody>
                    </props.Panel>
                    }
                    <props.Panel id="coverageInfoPanel">
                        <props.PanelHeading>
                            <props.Row>
                                <props.Col sm="7" md="7" xs="12">
                                    <div className="coverage-title"><GlobalizedText message="common.lbl.coverageInformation" /></div>
                                </props.Col>
                                <props.Col sm="5" md="5" xs="12">
                                    {config.displayBenefitLimitsFlag && config.hasBenefitLimits &&
                                        <div className="floatRight marginTop5px font12px300px">
                                            <GlobalizedText message="common.lbl.select" />
                                            <props.Icon type="dollar"></props.Icon>
                                            <GlobalizedText message="common.msg.viewBenefitLimits" />
                                        </div>
                                    }
                                </props.Col>
                            </props.Row>
                        </props.PanelHeading>
                        <props.PanelBody replaceClass={true} className="coverage-panel-body">
                            <props.Row>
                                <props.Col xs="12" md="12" sm="12">
                                    <div className="coverage-summary-title noPadding">
                                        <GlobalizedText message="common.lbl.allcurrencyvalue" />&nbsp;{config.member.currencyName}&nbsp;({config.member.countryCode})
                                    </div>
                                    <div className="coverage-summary-title-message">
                                        <GlobalizedText message="common.lbl.waived" /><props.Span className="gwp-label-required"></props.Span>
                                    </div>
                                </props.Col>
                            </props.Row>
                            {tblCoverage}
                        </props.PanelBody>
                    </props.Panel>
                </div>
            </Form>
            }
        </Formik>

        {/* ViewAllplanDetail Dialog */}
        <props.Modal id="planDetail_popup" title="common.lbl.coverage.plan.detail" footer={() => {
            return <>
                <props.Button data-print-visible="false" printVisible={false} onClick={() => { print("planDetail_popup", { orientation: 'landscape', size: 'A5' }) }}><GlobalizedText message="common.print" /></props.Button>
                <props.Button data-print-visible="false" printVisible={false} onClick={() => { props.closeModal("#planDetail_popup"); }}><GlobalizedText message="common.lbl.close" /></props.Button>

            </>
        }} >
            {isEmptyObject(planDetail) && <GlobalizedText message="common.msg.noplandetail" />}
            {!isEmptyObject(planDetail) && planDetail?.map((key: any, i: number) => {
                return <div className="paddingLeft15px">
                    <props.DL className="dl-horizontal planDetail">
                        <props.DLI title={key.keyName} desc={isEmptyObject(key.keyValue) ? "" : key.keyValue}></props.DLI>
                    </props.DL>
                </div>
            })}
        </props.Modal>
        {/* ViewAllplanDetail to Print */}

        {/* ViewCoverageAgent Dialog */}
        <props.Modal id="coverageAgentsDetail" title="common.lbl.viewAllAgents" footer={() => {
            return <>
                <props.Button data-print-visible="false" data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                {!isEmptyObject(coverage) && coverage.agents?.map((agent: any, i: number) => {
                    return <props.Col key={i} xs="12" md="6" sm="6">
                        {/* <props.DLI title={() => { return <><props.Span className="agentName">{agent.fullName}</props.Span> </> }} ></props.DLI> */}
                        <props.Label>{agent.agentNumber + ' ' + agent.agentName}</props.Label>
                        <props.DL className="dl-horizontal">
                            <props.DLI title="common.lbl.split" desc={() => {
                                return <>{formatNumber(agent.agentPercent, { minimumFractionDigits: 0 }) + "%"} </>
                            }}></props.DLI>
                            <props.DLI title="pd.lbl.phone" desc={() => {
                                return <>{agent.phone}</>
                            }}></props.DLI>
                            <props.DLI title="pd.lbl.email" desc={() => {
                                return <a href={"mailto:" + agent.email}>{agent.email}</a>
                            }}></props.DLI>
                        </props.DL>
                        {/* {i === agent.length - 1 &&
                            <hr className="visible-xs-*" />
                        } */}
                    </props.Col>
                })}
            </props.Row>
        </props.Modal >

        {/* ViewCoverageBeneficiaries Dialog */}
        <props.Modal id="coverageBeneficiariesDetail" title="pd.lbl.viewallbeneficiary" footer={() => {
            return <>
                <props.Button data-print-visible="false" data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                <props.Col md={5} sm={5} xs={12}>
                    <props.DL>
                        <props.DLI title="pd.lbl.primarybeneficiary" isHtml={true} />
                        {pbList?.map((pbRow: string) => { return <>{pbRow} <br /></> })}
                        <props.Condition condition={coverageB?.primaryForAllProduct}><props.Label className="coverage-bl-font-black"><GlobalizedText message="common.msg.benefiDesignation" /></props.Label></props.Condition>
                    </props.DL>
                </props.Col>
                <props.Col md={7} sm={7} xs={12}>
                    <props.DL>
                        <props.DLI title="pd.lbl.contingentbeneficiary" />
                        {cbList?.map((cbRow: string) => { return <>{cbRow} <br /></> })}
                        <props.Condition condition={coverageB?.contingentForAllProduct}><props.Label className="coverage-bl-font-black"><GlobalizedText message="common.msg.benefiDesignation" /></props.Label></props.Condition>
                    </props.DL>
                </props.Col>
            </props.Row>

        </props.Modal >

        <React.Fragment>
            <Formik initialValues={{
                effectiveYear: new Date().getFullYear(),
                dependentSequenceNumber: isEmptyObject(patientList) ? dependentSequenceNumber : patientList[0].value
            }}
                onSubmit={() => { blDialogShow(rowNumber) }}
            >
                {formProps => {
                    blFormProps.current = formProps;
                    return <Form>
                        {/* Modal for Benefit Limits Dialog */}
                        <props.Modal title="common.lbl.claim.benefit.limits" id="csblDialog" footer={() => {
                            return <>
                                <props.Button data-print-visible="false" data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                            </>
                        }} >
                            <props.Div className="scroll650pxNo">
                                <props.Row>
                                    <props.Col xs="12" sm="12" md="12">
                                        <props.DL className="dl-horizontal gwp-dl marginLeft180px">
                                            <props.DLI title="common.lbl.group" desc={config.member.groupDisplayValue}></props.DLI>
                                            <props.DLI title="common.lbl.product" desc={() => {
                                                return <>{config.coverages === undefined ? '' : simpleSort(config.coverages, "productName")[rowNumber].productName !== undefined ? simpleSort(config.coverages, "productName")[rowNumber].productName : simpleSort(config.coverages, "productName")[rowNumber].productID}</>;
                                            }}></props.DLI>
                                            <props.DLI title="common.lbl.plan" desc={() => {
                                                return <>{config.coverages === undefined ? '' : simpleSort(config.coverages, "productName")[rowNumber].planCodeDesc !== undefined ? simpleSort(config.coverages, "productName")[rowNumber].planCodeDesc : simpleSort(config.coverages, "productName")[rowNumber].planCode}</>;
                                            }}></props.DLI>
                                            <props.SelectControl className="gwp-inline" sort={false} name="effectiveYear" onChange={(e) => { yearChangeHandler(e, formProps) }} label="common.lbl.claim.plan.year" options={planYearList} />
                                            <props.SelectControl className="gwp-inline" sort={false} name="dependentSequenceNumber" onChange={(e) => { patientChangeHandler(e, formProps) }} label="common.lbl.patient" options={patientList} />
                                        </props.DL>
                                    </props.Col>
                                </props.Row>
                                <props.Row>
                                    <props.Col xs="12" sm="10" md="10">
                                        <props.Div className="benefitMsg"><GlobalizedText message="common.lbl.claim.benft.show.not.guart.elig" /></props.Div>
                                    </props.Col>
                                    <props.Col xs="12" sm="2" md="2">
                                    </props.Col>
                                </props.Row>
                                <props.Panel>
                                    <props.PanelHeading>
                                        <div className="claim-title"><GlobalizedText message="common.lbl.annual.limits" /></div>
                                    </props.PanelHeading>
                                    <props.PanelBody className="coverage-panel-body noPadding">
                                        <props.Table modal="csblDialog" id="annual" option={limitTableOption} delayAdjust={true} table={{ data: annualData, columns: columns }}>
                                        </props.Table>
                                    </props.PanelBody>
                                </props.Panel>
                                <props.Panel>
                                    <props.PanelHeading>
                                        <div className="claim-title"><GlobalizedText message="common.lbl.family.annual.limits" /></div>
                                    </props.PanelHeading>
                                    <props.PanelBody className="coverage-panel-body noPadding">
                                        <props.Table modal="csblDialog" id="family" option={limitTableOption} table={{
                                            data: familyData,
                                            columns: columns
                                        }} delayAdjust={true}></props.Table>
                                    </props.PanelBody>
                                </props.Panel>
                                <props.Panel>
                                    <props.PanelHeading>
                                        <div className="claim-title"><GlobalizedText message="common.lbl.lifetime.limits" /></div>
                                    </props.PanelHeading>
                                    <props.PanelBody className="coverage-panel-body noPadding">
                                        <props.Table modal="csblDialog" id="lifetime" option={limitTableOption} table={{
                                            data: lifetimeData,
                                            columns: columns
                                        }} delayAdjust={true}></props.Table>
                                    </props.PanelBody>
                                </props.Panel>
                                <props.Panel>
                                    <props.PanelHeading>
                                        <div className="claim-title"><GlobalizedText message="common.lbl.other.limits" /></div>
                                    </props.PanelHeading>
                                    <props.PanelBody className="coverage-panel-body noPadding">
                                        <props.Table modal="csblDialog" id="otherLimits" option={limitTableOption} table={{
                                            data: otherData,
                                            columns: otherColumns
                                        }} delayAdjust={true}></props.Table>
                                    </props.PanelBody>
                                </props.Panel>
                            </props.Div>
                        </props.Modal>
                    </Form>
                }}
            </Formik>
            <FundDetail  {...props} count={fundDetailCount} fundRow={fundRow} fundsDetail={fundsDetail} config={config} />
            <pdfViewer.Component></pdfViewer.Component>

            
        {/* Print Card Error Display */}
        <props.Modal id="printCardErrorDisplay" title="common.lbl.viewOrPrintIDCard" footer={() => {
            return <>
                <props.Button data-print-visible="false" data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                <props.Col md={12} sm={12} xs={12} className='mt-2'>
                <div className="marginbottom10px"><props.Icon type="warning" size="xs" /><span className="information gwp-information gwp-icon-warning marginbottom10px"><GlobalizedText message="print.card.not.available" /></span></div>                    
                </props.Col>
            </props.Row>

        </props.Modal >
        </React.Fragment>
    </ >
});



function requestIDCard(viewProps: any) {
    const companyCode: string = viewProps.getParam("companyCode");
    const groupNumber: string = viewProps.getParam("groupNumber");
    const accountNumber: number = parseInt(viewProps.getParam("accountNumber"));
    const participantIDNumber: string = viewProps.getParam("participantIDNumber");
    ajax({
        url: '/api/eligibilitySummary/requestIDCard',
        params: {
            companyCode: companyCode,
            groupNumber: groupNumber,
            accountNumber: accountNumber,
            participantIDNumber: participantIDNumber
        },
        success: (res: any) => { alert(viewProps.getGlobalizedText("coverage.msg.request.id.card.success")); }
    });
}

// This function is to be used for real printcard work
function printIDCard(pdfViewer: any, viewProps: any) {
    const companyCode: string = viewProps.getParam("companyCode");
    const groupNumber: string = viewProps.getParam("groupNumber");
    const accountNumber: number = parseInt(viewProps.getParam("accountNumber"));
    const participantIDNumber: string = viewProps.getParam("participantIDNumber");
    ajax({
        url: '/api/eligibilitySummary/printMemberCard',
        params: { companyCode, groupNumber, accountNumber, participantIDNumber },
        success: (data: any) => { 
            pdfViewer.showPDF(data.pdfUrl);
        },
        error: (error) =>{
            viewProps.showModal("#printCardErrorDisplay");     
        }
    });
}

// This function is to be used for dummy printcard work
function printDummyIDCard(pdfViewer: any) {
    pdfViewer.showPDF('/api/eligibilitySummary/printDummyMemberCard', true);
}

function viewVendors(viewProps: any, config: any) {
    const companyCode: string = viewProps.getParam("companyCode");
    const groupNumber: string = viewProps.getParam("groupNumber");
    const participantIDNumber: string = viewProps.getParam("participantIDNumber");
	const clientNumber: string = config.enrollments[0].clientNumber;
	viewProps.next(`/carriers?companyCode=${companyCode}&groupNumber=${groupNumber}&participantIDNumber=${participantIDNumber}&clientNumber=${clientNumber}`);
    }


interface FundDetailProps extends ViewComponentProps {
    fundRow: any;
    fundsDetail: any;
    config: any;
    count: number;
}

const FundDetail = (fd: FundDetailProps) => {
    useEffect(() => {
        if (fd.count > 0) {
            fd.showModal("#fund_popup");
        }
    }, [fd.count]);

    return <>
        <fd.Modal id="fund_popup" size="large" title={fd.fundRow.current?.fundDescription} footer={() => {
            return <>
                <fd.Button onClick={() => { fd.closeModal("#fund_popup"); }}><GlobalizedText message="common.lbl.close" /></fd.Button>
            </>
        }} >
            <fd.Row>
                <fd.Col xs='12' sm='6' md='6'>
                    <fd.DL>
                        <fd.DLI title="common.lbl.memberName" desc={fd.config.member.memberName} />
                        <fd.DLI title="common.lbl.fundYearStartDate" desc={fd.fundRow.current?.fundYearBeginDate === undefined ? '' : fd.fundRow.current?.fundYearBeginDate} />
                        <fd.DLI title="common.lbl.fundYearEndDate" desc={fd.fundRow.current?.fundYearEndDate === undefined ? '' : fd.fundRow.current?.fundYearEndDate} />
                        <fd.DLI title="common.lbl.submissionGraceDays" desc={fd.fundRow.current?.submissionGraceDays === undefined || fd.fundRow.current?.submissionGraceDays === 0 ? '' : formatNumber(fd.fundRow.current?.submissionGraceDays, { maximumFractionDigits: 0 })} />
                        <fd.DLI title="common.lbl.lastTransactionDate" desc={fd.fundRow.current?.lastTranDate === undefined ? '' : fd.fundRow.current?.lastTranDate} />
                    </fd.DL>
                </fd.Col>
                <fd.Col xs='12' sm='6' md='6'>
                    <fd.DL>
                        <fd.DLI title="common.lbl.totals" desc='' />
                        <fd.DLI title="common.lbl.contributionAmount" desc={fd.fundRow.current?.totalContributions === undefined || fd.fundRow.current?.totalContributions === 0 ? '' : formatNumber(fd.fundRow.current?.totalContributions)} />
                        <fd.DLI title="common.lbl.claimAmount" desc={fd.fundRow.current?.totalClaims === undefined || fd.fundRow.current?.totalClaims === 0 ? '' : formatNumber(fd.fundRow.current?.totalClaims)} />
                        <fd.DLI title="common.lbl.targetAmount" desc={fd.fundRow.current?.targetAmount === undefined || fd.fundRow.current?.targetAmount === 0 ? '' : formatNumber(fd.fundRow.current?.targetAmount)} />
                        <fd.DLI title="common.lbl.forfeiture" desc={fd.fundRow.current?.forfeitureAmount === undefined || fd.fundRow.current?.forfeitureAmount === 0 ? '' : formatNumber(fd.fundRow.current?.forfeitureAmount)} />
                    </fd.DL>
                </fd.Col>
            </fd.Row>
            <fd.Row>
                <fd.Col xs='12' sm='12' md='12'>
                    <hr />
                    <fd.PageTitle ><GlobalizedText message="common.lbl.fSATransactions" /></fd.PageTitle>
                    <fd.Table modal="fund_popup" id="fundDetailInfo" setInitParams={fd.setInitParams} getInitParams={fd.getInitParams} dateformat={fd.config.userDateFormat}>
                        <thead key={0} >
                            <tr>
                                <th scope="col" data-sortable={false} className="gwp-dt-detail-control"></th>
                                <th scope="col" data-type="date"><GlobalizedText message="common.lbl.transactionDate" /></th>
                                <th scope="col" data-type="numeric"><GlobalizedText message="common.lbl.contributionAmount" /></th>
                                <th scope="col" data-type="numeric"><GlobalizedText message="common.lbl.claimAmount" /></th>
                                <th scope="col" data-type="numeric"><GlobalizedText message="common.lbl.targetAmount" /></th>
                                <th scope="col" data-type="numeric"><GlobalizedText message="common.lbl.forfeiture" /></th>
                                <th scope="col"><GlobalizedText message="common.lbl.claimNumber" /></th>
                            </tr>
                        </thead>
                        <tbody key={1} >
                            {fd.fundsDetail.current?.map((fundDetailRow: any, index: number) => {
                                return <tr key={index}>
                                    <td className="gwp-dt-detail-control"></td>
                                    <td>{fundDetailRow.transactionDate === undefined ? null : fundDetailRow.transactionDate}</td>
                                    <td>{fundDetailRow.contributionAmount === undefined ? null : formatNumber(fundDetailRow.contributionAmount)}</td>
                                    <td>{fundDetailRow.claimAmount === undefined ? null : formatNumber(fundDetailRow.claimAmount)}</td>
                                    <td>{fundDetailRow.targetAmount === undefined ? null : formatNumber(fundDetailRow.targetAmount)}</td>
                                    <td>{fundDetailRow.forfeiture === undefined ? null : formatNumber(fundDetailRow.forfeiture)}</td>
                                    <td>{fundDetailRow.claimNumber === undefined ? null : fundDetailRow.claimNumber}</td>
                                </tr>
                            })}
                        </tbody >
                    </fd.Table>
                </fd.Col>
            </fd.Row>
        </fd.Modal>
    </>
}




