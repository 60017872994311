import { useField } from 'formik';
import Moment from 'moment';
import React, { useEffect } from 'react';
import { DateTimePicker } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePickerProps } from 'react-widgets/lib/DateTimePicker';
import { convertDate, copyObjectExcept, formatDate, getPureNumericFormat, isEmptyStr, isMobileDevice, parseDate } from '../../utils';
import { withErrorControl, WrapperControlProps } from './field';
import { Input } from './input';

Moment.locale('en');
momentLocalizer();
export interface DateTimePickerControlProps extends DateTimePickerProps, WrapperControlProps {
    onKeyUp?: (event: any) => void;
    onChange?: (date?: Date, dateStr?: string) => void;
    onDateChange?: (date?: Date, dateStr?: string) => void;
    containerClassName?: string;
    dateformat: string;
    name: string;
    showFormat?: boolean;
    /**
     * whether to use the local date input or not, this will override the behavior of show local date input on mobile device.
     */
    useLocalDate?: boolean;
    backgroundColor?: string;
}

export const DateTimePickerControl = withErrorControl(({ containerClassName, setValue, ...props }: DateTimePickerControlProps) => {

    const fieldConfig = useField(props.name);

    useEffect(() => {
        if (props.backgroundColor) {

            document.getElementsByName(props.name).forEach((el: any) => {
                el.setAttribute("style", `background-color:${props.backgroundColor}`);
            })

        }
    })

    function isDate(fieldValue: string) {
        let reLiteral = props.dateformat.replace(/([$^.*+?=!:|\/\\\(\)\[\]\{\}])/g, "\\$1");
        reLiteral = reLiteral.replace("yyyy", "([0-9]{4})");
        reLiteral = reLiteral.replace("MM", "(0[1-9]|10|11|12)");
        reLiteral = reLiteral.replace("dd", "(0[1-9]|[12][0-9]|30|31)");
        reLiteral = "^" + reLiteral + "$";
        return new RegExp(reLiteral).test(fieldValue);
    }

    function formatValue(e: any, setVal: (val: string) => void, originalValue: string): string {
        let val = e.target.value.trim();
        let pureNumberFormat = getPureNumericFormat(props.dateformat);
        let regex = new RegExp(`^\\d{${pureNumberFormat.length}}$`);
        if (regex.test(val)) {
            let newVal = convertDate(val, pureNumberFormat, props.dateformat);
            e.target.value = newVal;
            if (newVal !== originalValue && Moment(val, props.dateformat.toUpperCase()).isValid()) {
                setVal(e.target.value);
                return newVal
            }
            e.preventDefault();
            return "";
        } else {
            if (isDate(val)) {
                setVal(e.target.value);
                return val;
            }
            e.target.value = val;
            e.preventDefault();
            return "";
        }
    }

    if (isMobileDevice() && props.useLocalDate === true) {
        return <Input type="date" name={props.name} onChange={(e: any) => { fieldConfig[2].setValue(convertDate(e.target.value, "yyyy-MM-dd", props.dateformat)) }} />
    } else {

        let dateVal = parseDate(fieldConfig[0].value, props.dateformat);
        let option: any = {
            onKeyUp: (e: any) => {
                let val = formatValue(e, fieldConfig[2].setValue, fieldConfig[0].value);
                if (!isEmptyStr(val) && props.onDateChange !== undefined) {
                    props.onDateChange(Moment(val, props.dateformat.toUpperCase()).toDate(), val);
                }
            },
            onChange: (date?: Date | undefined, dateStr?: string | undefined) => { fieldConfig[2].setValue(formatDate(props.dateformat, date)); if (props.onDateChange !== undefined) { props.onDateChange(date, dateStr) } },
            max: props.max,
            min: props.min,
            name: props.name,
            value: dateVal,
            containerClassName: "gwp-date gwp-input",
            format: props.dateformat.toUpperCase(),
            placeholder: props.dateformat.toLowerCase(),
            time: false,
        };

        return <DateTimePicker {...copyObjectExcept(props, false, "onDateChange")} {...option} />
    }
});