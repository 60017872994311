import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import GlobalizedText from '../shared/globalization';
import { ajax, downloadFile, uploadFile, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/bulkregistration.css';

interface BulkRegistrationResult {
    data: any
    columns: any
    passed: number
    failed: number
}

export const BulkRegistrationComponent = withView((props: ViewComponentProps) => {

    const [view, setView] = useState<any>();
    const [result, setResult] = useState<BulkRegistrationResult>({ data: [], columns: [], passed: 0, failed: 0 });
    useEffect(() => {
        if (view === undefined) {
            ajax({
                url: "/api/registrationBulk/view",
                success: res => setView(res)
            });
        }
    }, [view]);


    if (view === undefined) {
        return <></>
    }

    let validationSchema = yup.object().shape({
        file: yup.string().trim().required("register.msg.choose.file.to.update")
    });

    return <>
        <Formik initialValues={{ userType: view.defaultUserType, file: '' }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={(values) => {
                return validate(validationSchema, values, props);
            }}
            onSubmit={(values) => {
                setResult({ ...result, ...{ passed: 0, failed: 0 } });
                uploadFile({
                    url: `/api/registrationBulk/bulkRegister`,
                    form: document.getElementById("bulkRegistrationForm"),
                    success: (res: any) => {
                        let f: number = 0;
                        let p: number = 0;
                        props.showMessage("success", "common.msg.submitted.successfully");
                        res.bulkRegistrationResultBO.data.forEach((row: any) => {
                            if (row.status === 'Failed') {
                                f++;
                            }
                            if (row.status === 'Passed') {
                                p++;
                            }
                        });
                        let columns: any = [{ className: 'gwp-dt-detail-control', sortable: false }];
                        res.bulkRegistrationResultBO.structure.forEach((row: any) => {
                            if (row.data === 'birthDate') {
                                columns.push({ title: row.title, name: row.data, className: 'no-wrap' });
                            } else {
                                columns.push({ title: row.title, name: row.data });
                            }
                        })
                        setResult({
                            columns: columns,
                            data: res.bulkRegistrationResultBO.data,
                            passed: p,
                            failed: f
                        });
                        props.showModal("#bulkRegistrationResultDialog");
                    }, fail: msg => {
                        props.showMessage("error", msg);
                    }, error: (error: any) => {
                        props.showMessage("error", error.response.data.message);
                    }
                });
            }}>
            {formProps =>
                <Form id="bulkRegistrationForm" encType="multipart/form-data">
                    <props.Container>
                        <props.Row>
                            <props.Col>
                                <div className="bulk-subtitle"><GlobalizedText message="register.msg.multiple.users.1" /></div>
                                <div className="bulk-control">
                                    <props.SelectControl name="userType" options={view.userTypes} label="security.lbl.userType" />
                                </div>
                            </props.Col>
                        </props.Row>
                        <props.Row>
                            <props.Col>
                                <div className="bulk-subtitle bulk-subtitle-padding"><GlobalizedText message="register.msg.multiple.users.2" /></div>
                                <div className="bulk-control">
                                    <props.LinkButton onClick={() => { downloadFile(`/api/registrationBulk/downloadBulkRegistrationTemplate/${formProps.values.userType}`) }}><GlobalizedText message="common.lbl.downloadtemplate" /></props.LinkButton>
                                </div>
                            </props.Col>
                        </props.Row>
                        <props.Row>
                            <props.Col>
                                <div className="bulk-subtitle bulk-subtitle-padding"><GlobalizedText message="register.msg.multiple.users.3" /></div>
                            </props.Col>
                        </props.Row>
                        <props.Row>
                            <props.Col>
                                <div className="bulk-control">
                                    <props.FileControl id="file" name="file" ></props.FileControl>
                                </div>
                            </props.Col>
                        </props.Row>
                        <props.Row>
                            <props.Col>
                                <div className="bulk-control">
                                    <props.Button type="submit"><GlobalizedText message="common.button.submit" /></props.Button>
                                    <props.Button onClick={props.back}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                                </div>
                            </props.Col>
                        </props.Row>
                    </props.Container>
                </Form>
            }
        </Formik>

        <props.Modal id="bulkRegistrationResultDialog" title="common.lbl.bulkregistrationresult" size="large" footer={() => {
            return <>
                <props.Button onClick={() => {
                    downloadFile("/api/registrationBulk/downloadBulkRegistrationResult")
                }} data-dismiss="modal"><GlobalizedText message="common.lbl.download" /></props.Button>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                <props.Col md={3} sm={6}>
                    <props.DL className="dl-horizontal">
                        <props.DLI title="common.lbl.status" desc={props.getGlobalizedText("common.lbl.processed")}></props.DLI>
                        <props.DLI title="common.lbl.totals" desc={[
                            <><GlobalizedText message="common.lbl.totals" /> <span className="reg-blue">{result.passed + result.failed}</span></>,
                            <><GlobalizedText message="common.lbl.passed" /> <span className="reg-green">{result.passed}</span></>,
                            <> <GlobalizedText message="common.lbl.failed" /> <span className="reg-red">{result.failed}</span></>
                        ]
                        }></props.DLI>
                    </props.DL>
                </props.Col>
            </props.Row>
            <props.Table id="bulkRegistrationResultTable" modal="bulkRegistrationResultDialog" table={result}></props.Table>
        </props.Modal>
    </>
})