import React from 'react';
import { ajax, getFromStorage } from './utils';
import axiosRetry from "axios-retry";
import axios from 'axios';
import { Config } from '../config/config';

axiosRetry(axios, { retries: 2 });
export interface User {
    dateFormat?: string,
    defaultHome?: string,
    firstName?: string,
    lastName?: string,
    needFirstUpdate?: boolean,
    needUpdatePwd?: boolean,
    prefLang?: string
    homePageUpdatable?: boolean,
    features: Array<FeatureFlag>,
    userTypeRegCode: string
}

export interface FeatureFlag {
    featureID: string,
    enabled: boolean
    homePageUpdatable?: boolean
}

export class UserService {
    rootComponent: any;
    constructor(rootComponent: any) {
        this.rootComponent = rootComponent;
    }
    loadUser(callback?: Function | null, fail?: Function | null) {
        ajax({
            url: '/api/user/current',
            method: 'get',
            success: (response: User) => {
                callback ? callback(response) : this.rootComponent.setUser(response);
            },
            error: (error: any) => {
                if (fail) {
                    fail(error);
                }
            },
            autoRedirectToLogin: false,
            showMask: false
        });
    }
    authenticate(username: string, password: string, afterLoginedIn: (user: User) => void, fail: (data: any, message: string) => void) {
        var data = new FormData();
        data.set("username", username);
        data.set("password", password);
        ajax({
            url: '/login',
            data: data,
            method: 'post',
            headers: { 'Accept': 'application/json' },
            autoRedirectToLogin: false,
            success: (response: any) => {
                this.loadUser((res: User) => {
                    this.rootComponent.setUser(res);
                    if (afterLoginedIn) {
                        afterLoginedIn(res);
                    }
                });
            },
            error: (error: any) => {
                fail(error.response.data.data, error.response.data.message);
            }
        });
    }
    signout() {
        const config = getFromStorage('gwp.config', true);
        this.rootComponent.clearUser();
        axios.get(Config.SERVICE + '/logout') // The first request fails and the second returns 'ok'
          .then(response => {
            if(response.status === 200 && config.openIdConnect === true){
                window.location.replace(config.serviceLogoutUrl);   
            }
          });     
    }
}
export const UserContext = React.createContext<User | null>(null);