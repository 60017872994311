import React, { HTMLAttributes } from 'react';
import { Row as BsRow, Col as BsCol, Container as BsContainer, RowProps as BsRowProps, ColProps as BsColProps, ContainerProps as BsContainerProps } from 'react-bootstrap';
import GlobalizedText from '../../globalization';
import { formatNumber } from '../../utils';
import { ConditionProps, withCondition } from './shared-component';
export interface ContainerProps extends HTMLAttributes<HTMLDivElement>, BsContainerProps {

}
export interface RowProps extends HTMLAttributes<HTMLDivElement>, BsRowProps, ConditionProps {

}
export interface ColProps extends HTMLAttributes<HTMLDivElement>, BsColProps, ConditionProps {

}
export interface DLIProps extends ConditionProps {
    title: string | Function;
    titleClass?: string;
    desc?: string | number | Function | Array<any>;
    descId?: string;
    numberFormat?: string;
    children?: Array<any>;
    isHtml?: boolean;
}
export interface DLProps extends HTMLAttributes<HTMLDListElement>, ConditionProps {

}
export interface PageContent extends HTMLAttributes<HTMLDivElement> {

}
export interface DivProps extends HTMLAttributes<HTMLDivElement>, ConditionProps {
    isHtml?: boolean;
    htmlFor?: string;
}
export interface SpanProps extends HTMLAttributes<HTMLSpanElement>, ConditionProps {
    isHtml?: boolean;
    htmlFor?: string;
}
export function Container(props: ContainerProps) {
    return <BsContainer {...props}>{props.children}</BsContainer>
}

export const Row = withCondition(({ condition, ...props }: RowProps) => {
    return <BsRow {...props}>{props.children}</BsRow>
})

export const Col = withCondition(({ condition, xs, sm, md, lg, xl, className, ...props }: ColProps) => {
    let colClass = "";
    if (xs) {
        colClass += `col-xs-${xs} `
    }
    if (sm) {
        colClass += `col-sm-${sm} `
    }
    if (md) {
        colClass += `col-md-${md} `
    }
    if (lg) {
        colClass += `col-lg-${lg} `
    }
    if (xl) {
        colClass += `col-xl-${xl} `
    }
    return <div className={`${colClass}${className ? className : ''}`} {...props}>{props.children}</div>
})

export function PageContent(props: PageContent) {
    return <div className="gwp-page-content" {...props}>{props.children}</div>;
}
export const DL = withCondition(({ className, condition, ...props }: DLProps) => {
    return <dl className={`gwp-dl ${className ? className : ''}`} {...props}>{props.children}</dl>
});
export const DLI = withCondition(({ numberFormat, condition, ...props }: DLIProps) => {
    const DT = props.title;
    const DD = props.desc;
    const TITLE_CLASS = props.titleClass ? props.titleClass : '';
    return <>
        {typeof props.title === 'string' &&
            <dt className={TITLE_CLASS}><GlobalizedText message={props.title} /></dt>
        }
        {typeof DT === 'function' &&
            <dt className={TITLE_CLASS}><DT /></dt>
        }
        {(typeof DD === 'string' && props.isHtml === true) &&
            //@ts-ignore
            <dd dangerouslySetInnerHTML={{ __html: props.desc }}>
            </dd>
        }
        {(typeof DD === 'string' && props.isHtml !== true) &&
            <dd>
                {props.desc}
            </dd>
        }
        {typeof DD === 'number' &&
            <dd>
                <Number value={DD} />
            </dd>
        }
        {typeof DD === 'function' &&
            <dd><DD /> </dd>
        }
        {typeof DD === 'object' && DD.length && <>
            {DD.map((o: any, i: number) => {
                const O = o;
                if (typeof o === 'string') {
                    return <dd key={i}>{o}</dd>
                } else {
                    return <dd key={i}>{O}</dd>
                }
            })}
        </>
        }
    </>
});

export interface NumberProps {
    value: number
}

export function Number({ value }: NumberProps) {
    return <>{formatNumber(value)}</>;
}

export interface HRProps extends HTMLAttributes<HTMLHRElement> {

}

export function HR(props: HRProps) {
    return <hr {...props} />
}

export const Div = withCondition(({ condition, ...props }: DivProps) => {
    if (typeof (props.children) === "string") {
        props.dangerouslySetInnerHTML = { __html: props.children }
        props.children = undefined;
    }
    return <div {...props} >{props.children}</div>
})

export const Span = withCondition(({ condition, ...props }: SpanProps) => {
    if (typeof (props.children) === "string") {
        props.dangerouslySetInnerHTML = { __html: props.children }
        props.children = undefined;
    }
    return <span {...props} >{props.children}</span>
})

export interface InnerTitleProps extends ConditionProps {

}

export const InnerTitle = withCondition(({ condition, ...props }: InnerTitleProps) => {
    return <div className="gwp-innertitle  gwp-bold" >{props.children}</div>
})