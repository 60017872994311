import React, { useState, useEffect } from 'react';
import { getContextPath, ajax } from '../../utils';
import { ConditionProps, withCondition } from './shared-component';
import GlobalizedText from '../../globalization';

export interface ContentLinkProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, ConditionProps {
    typeName: string
    displayTxt: string
    target?: string
}

export const ContentLink = withCondition(({ typeName, target, displayTxt, ...props }: ContentLinkProps) => {
    const [contentID, setContentID] = useState<string>("");
    useEffect(() => {
        if (contentID === "") {
            ajax({
                url: '/api/cms/content/getByTypeName/' + typeName,
                success: (data) => {
                    setContentID(data.id);
                },
                error: (response: any) => {
                    setContentID("error");
                }
            })
        }
    }, [contentID]);
    if (contentID === "" || contentID === "error") {
        return <></>
    }
    return <>
        <a
            className={`${props.className !== undefined ? props.className : ''}`}
            href={`${getContextPath()}/inlineContentViewer?id=${contentID}`}
            target={`${target !== undefined ? target : ''}`}
        >
            <GlobalizedText message={`${displayTxt}`} />
        </a>
    </>


})