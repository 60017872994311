import React from 'react';
import GlobalizedText from '../../../shared/globalization';
import { Link } from './link';
export interface WouldSelectProps {
    next: (url: string) => void;
    options: Array<WouldSelectOption>;
}

export interface WouldSelectOption {
    message: string;
    to?: string;
    onClick?: () => void;
    disabled?: boolean;
}

export const WouldSelect = (props: WouldSelectProps) => {

    return <div className="dropdown">
        <button type="button" className="btn btn-default dropdown-toggle gwp-wouldSelect" data-toggle="dropdown">
            <GlobalizedText message="my.ins.act.opt.tip" /> <span className="caret"></span>
        </button>
        <ul className="dropdown-menu gwp-wouldSelect-menu" >
            {
                props.options.map((option: WouldSelectOption, index: number) => {
                    return <React.Fragment key={index}>
                        <li className={(option.disabled === undefined || option.disabled === false) ? '' : 'disabled'}>
                            <Link onClick={option.onClick} to={(option.disabled === true || option.to === undefined) ? '#' : option.to}><GlobalizedText message={option.message}></GlobalizedText>
                            </Link>
                        </li>
                    </React.Fragment>
                })
            }
        </ul>
    </div>
}