import React from 'react';
import Enrollment from '../../src/pages/types/enrollmenttypes';
import * as yup from 'yup';
import { YupSchemaITG } from "../shared/yupSchemaITG";

interface Props {
    props: Enrollment.Props;
    isShowEmail?: boolean;
    disabled: boolean
}


const EmailAddress4Personalinfo = ({isShowEmail = true, props, disabled}: Props) => {
    return isShowEmail ?  <props.TextControl id="email" name="email" required={isShowEmail} disabled={disabled}  label="Email"></props.TextControl> : null
}

export default EmailAddress4Personalinfo;