import React from 'react';
import { DEFAULT_LAYOUT } from '../shared/theme';
import { ajax, copyObjectExcept, getFromStorage, getHost, isEmptyObject, saveToStorage } from '../shared/utils';
import {CONFIG_API_URL} from '../shared/constants/apiUrls';
interface ConfigProps {
    SERVICE: string | undefined,
    SHOW_SERVER_DEBUG: boolean,
    DOWNLOAD_FILE_CREATE_OBJECT_URL: boolean,
    TRY_RELOAD_MESSAGE_ON_PAGE_RERENDERING: boolean,
    ENABLE_LOG: boolean,
    REACT_APP_SHOW_ERROR_MODAL: boolean
}

export const Config: ConfigProps = {
    SERVICE: process.env.REACT_APP_SERVER_ROOT,
    DOWNLOAD_FILE_CREATE_OBJECT_URL: process.env.REACT_APP_DOWNLOAD_FILE_CREATE_OBJECT_URL === 'true',
    SHOW_SERVER_DEBUG: process.env.REACT_APP_SHOW_SERVER_DEBUG === 'true',
    REACT_APP_SHOW_ERROR_MODAL: process.env.REACT_APP_SHOW_ERROR_MODAL === 'true',
    TRY_RELOAD_MESSAGE_ON_PAGE_RERENDERING: process.env.REACT_APP_TRY_RELOAD_MESSAGE_ON_PAGE_RERENDERING === 'true',
    ENABLE_LOG: process.env.REACT_APP_ENABEL_LOG === 'true'
}

const defaultComponentConfig = {
    components: {
        onlineHelp: { theme: "empty", layout: "empty" },
        welcome: { layout: "empty" }
    }
}
const defaultHostConfig = {
    profile: undefined,
    theme: "default"
}

export function loadConfiguration(root: any, callback: (config: any) => void) {
    let config = getFromStorage("gwp.config");
    let host = getHost();
    if (!isEmptyObject(config)) {
        let conf = { ...root.state, ...{ theme: 'default' }, ...config };
        callback(conf);
    } else {
        ajax({
            url: CONFIG_API_URL,
            success: (response: any) => {
                if (response) {
                    let conf: any = copyObjectExcept({ ...root.state, ...response }, false, "components");
                    if (response.components !== undefined) {
                        let components: any = {};
                        for (let component of response.components) {
                            components[component.componentId] = { theme: component.theme, layout: component.layout };
                        }
                        conf.components = components;
                    }
                    conf.defaultTheme = response.theme;
                    conf.supportedFileTypes = response.supportedFileTypes;
                    conf.defaultLayout = response.layout ? response.layout : DEFAULT_LAYOUT
                    saveToStorage("gwp.config", copyObjectExcept(conf, false, "messages"));
                    callback(conf);
                } else {
                    callback(root.state);
                }
            },
            error: (response: any) => {
                let siteConfig: any = {}
                siteConfig[host] = defaultHostConfig;
                let conf = { ...root.state, ...defaultComponentConfig, ...siteConfig };
                saveToStorage("gwp.config", copyObjectExcept({ ...defaultComponentConfig, ...siteConfig }, false, "messages"));
                callback(conf);
            }
        });
    }
}

export const ConfigContext = React.createContext<any | null>(null);