import React, { useState, useEffect, useRef } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { Formik, Form } from 'formik';
import { ajax, removeLeftZero } from '../shared/utils';
import { TableColumn } from "../shared/components";
import GlobalizedText from '../shared/globalization';
import './css/toleranceAdjustments.css';

export const ToleranceAdjustment = withView((props: ViewComponentProps) => {
    const varFormPromp = useRef<any>();

    const [config, setConfig] = useState<any | null>(null);

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/toleranceAdjustments/view',
                params: {
                    companyCode: props.getParam("companyCode"),
                    groupNumber: props.getParam("groupNumber"),
                    groupName: props.getParam("groupName") !== undefined ? props.getParam("groupName") : '',
                    accountNumber: props.getParam("accountNumber"),
                    accountName: props.getParam("accountName") !== undefined ? props.getParam("accountName") : '',
                    billingFrequency: props.getParam("billingFrequency") !== undefined ? props.getParam("billingFrequency") : '',
                    participantIDNumber: props.getParam("participantIDNumber") !== undefined ? props.getParam("participantIDNumber") : '',
                    fullName: props.getParam("fullName") !== undefined ? props.getParam("fullName") : '',
                    invoiceNumber: props.getParam("invoiceNumber") !== undefined ? props.getParam("invoiceNumber") : '',
                    modalDateFrom: props.getParam("modalDateFrom"),
                    modalDateThrough: props.getParam("modalDateThrough"),
                    countryCode: props.getParam("countryCode") !== undefined ? props.getParam("countryCode") : '',
                    currencyName: props.getParam("currencyName") !== undefined ? props.getParam("currencyName") : ''
                },
                success: (res: any) => {
                    setConfig(res);
                    if (res.toleranceAdjustList !== undefined) {
                        setToleranceAdjustmentTable({
                            columns: toleranceAdjustmentTableColumns,
                            data: res.toleranceAdjustList
                        });
                    }
                }
            });
            return () => {
            }
        }
    }, [config]);

    const toleranceAdjustmentTableColumns: Array<TableColumn> = [
        { "title": "billpay.modaldate", "name": "modalDate", "sortable": true },
        { "title": "billpay.paymentApplied.appliedDate", "name": "appliedDate", "sortable": true, "type": "date" },
        { "title": "common.lbl.premium", "name": "premium", "sortable": true }
    ]

    const [toleranceAdjustmentTable, setToleranceAdjustmentTable] = useState<any | null>({ columns: toleranceAdjustmentTableColumns, data: [] });

    return <React.Fragment>
        <Formik initialValues={{
        }}
            validate={() => { }}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={() => { }}
        >
            {formProps => {
                varFormPromp.current = formProps;
                return <Form id='toleranceAdjustment'>
                    <props.Row>
                        <props.Col md="12" xs="12" >
                            <div className="gwp-page-header">
                                <div className="gwp-page-header-block"></div>
                                <div className="gwp-page-header-label-title gwp-label">
                                    <GlobalizedText message="billpay.modaldate.from" />
                                </div>
                                <div className="gwp-page-header-label-content gwp-bold">
                                    {config !== null ? config.modalDate : ''}
                                </div>
                                <div className="rightAlign">
                                    <props.PrintButton onClick={() => props.print({ size: 'A5', orientation: 'landscape' })} />
                                </div>
                            </div>
                        </props.Col>
                    </props.Row>
                    <props.Row className="gwp-currency-margin currencyAlign">
                        <props.Col md="12" xs="12" sm="12">
                            <GlobalizedText message="common.lbl.allcurrencyvalue" />&nbsp;{config !== null ? config.currencyName : ''} ({config !== null ? config.countryCode : ''})
                        </props.Col>
                    </props.Row>

                    <props.Panel>
                        <props.PanelBody>
                            <props.Container>
                                <props.Row>
                                    <props.Col md="3" xs="12" sm="3" className="gwp-dl">
                                        <props.DLI title="common.lbl.group" desc={config !== null ? removeLeftZero(config.groupName.replaceAll('%20', ' ')) : ''}></props.DLI>
                                        <props.DLI title="common.lbl.account" desc={config !== null ? config.accountName.replaceAll('%20', ' ') : ''}></props.DLI>
                                        <props.DLI title="common.lbl.member" condition={config !== null && config.participantIDNumber !== undefined}
                                            desc={config !== null && config.participantIDNumber !== undefined ? config.fullName.replaceAll('%20', ' ') : ''}>
                                        </props.DLI>
                                        <props.DLI title="billpay.billfrequency" condition={config !== null && config.participantIDNumber === undefined}
                                            desc={config !== null ? config.billingFrequency.replaceAll('%20', ' ') : ''}>
                                        </props.DLI>
                                    </props.Col>
                                    <props.Col md="3" xs="12" sm="3" className="gwp-dl" >
                                        <props.DLI title="billpay.invoicenum" desc={config !== null ? config.invoiceNumber : ''}></props.DLI>
                                    </props.Col>
                                </props.Row>
                            </props.Container>
                        </props.PanelBody>
                    </props.Panel>

                    <props.Panel>
                        <props.PanelBody>
                            <props.Container>
                                <props.Row>
                                    <props.Col md="11" sm="12" >
                                        <div className="gwp-sectitle">
                                            <GlobalizedText message="common.lbl.adjustments" />
                                        </div>
                                    </props.Col>
                                </props.Row>
                                <props.Row>
                                    <props.Col xs="12" >
                                        <props.Table id="toleranceAdjustmentTable" table={toleranceAdjustmentTable} dateformat={config !== null ? config.dateFormat : 'dd/MM/yyyy'}></props.Table>
                                    </props.Col>
                                </props.Row>
                            </props.Container>
                        </props.PanelBody>
                    </props.Panel>
                </Form>
            }
            }
        </Formik>

    </React.Fragment >
});