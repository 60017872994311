import React, { useEffect, useState } from 'react';
import { ajax } from '../shared/utils';
import GlobalizedText from '../shared/globalization';
import { withView } from '../shared/viewcomponent'
import './css/accessAuthorization.css';
export const AccessAuthorization = withView((props: any) => {

    const [config, setConfig] = useState<any>();
    const [privacyWaived, setPrivacyWaived] = useState<boolean>(false);
    const [access, setAccess] = useState<boolean>(false);
    const [memberName, setMemberName] = useState<any>('');

    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/personalInfo/waiver',
                params: {},
                success: (res: any) => {
                    setConfig(res);
                    setPrivacyWaived(res.privacyWaived);
                    setMemberName(res.memberName);
                    setAccess(res.privacyWaived);
                }
            });
        }
    }, [config]);
    if (config === undefined) {
        return <></>;
    }
    const onSubmitHandeler = () => {
        ajax({
            url: '/api/personalInfo/updateWaiver',
            method: 'post',
            data: {
                privacyWaived: access,
                memberName: memberName
            },
            success: (res: any) => {
                setConfig(res);
                setPrivacyWaived(res.privacyWaived);
            }
        });
    }
    return (
        <div className="hippa-access-authorization">
            <props.Col md="12">
                <props.Row>

                    <div className="mtb-15"><GlobalizedText message='hippa.access.desc1' /></div>
                    <div className='gwp-bold'><GlobalizedText message='hippa.access.title' /></div>
                    <div className='mtb-15'><GlobalizedText message='hippa.access.desc2' /></div>
                    <div className='gwp-bold'><GlobalizedText message='hippa.access.account.title' />​</div>
                    <div className='mtb-15'><GlobalizedText message='hippa.access.desc3' /></div>
                    <table>
                        <tr>
                            <th>{memberName}</th>
                            <td>
                                <div className='group'>
                                    <input type='radio' name="access" value="grant" id="grant" checked={access === true && true} onClick={() => setAccess(true)} />
                                    <label htmlFor="grant"><GlobalizedText message='common.lbl.grant' /></label>
                                </div>
                            </td>
                            <td>
                                <div className='group'>
                                    <input type='radio' name="access" value="deny" id="deny" checked={access === false && true} onClick={() => setAccess(false)} />
                                    <label htmlFor='deny'><GlobalizedText message='common.lbl.denyaccess' /></label>
                                </div>
                            </td>
                        </tr>
                    </table>
                </props.Row>
            </props.Col>
            <props.Row>
                <props.Col sm="12">
                    <props.Button type="submit" onClick={onSubmitHandeler}><GlobalizedText message="common.lbl.save" /></props.Button>
                </props.Col>
            </props.Row>
        </div>
    )
})
