import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SecurityContext } from '../../context/securityContext';
import { UserContext } from '../../shared/authentication';
import { Button, DL, DLI, Modal, ContentLink, GoogleTranslate } from '../../shared/components';
import GlobalizedText from '../../shared/globalization';
import { FooterProps } from '../../shared/theme';
import { ajax, win } from '../../shared/utils';
import { SubmitYourInquiry } from './submitinquiry';

const PORTAL_ADMIN_USER_ROLE = "01";

export function Footer(props: FooterProps) {
    const {showEnquiryForm} = useContext(SecurityContext);
    const [version, setVersion] = useState<any>();
    useEffect(() => {
        if (version === undefined) {
            ajax({
                url: '/api/version',
                success: (res) => {
                    setVersion(res);
                },
                error: (error: any) => {
                    setVersion({});
                },
                showMask: false,
            });
        }


    }, [version]);

    if (version === undefined) {
        return <></>
    }

    return <>
		<footer className="foot-container">
			<div className="container">
				<div className="col-wrap row">
					<div className="footer-translator">
						<GoogleTranslate />
					</div>
	                <div id="foot-copyright">
	                    <p>&copy; <GlobalizedText message="common.lbl.copyright" />{props.user &&  props.user.userTypeRegCode == PORTAL_ADMIN_USER_ROLE && <a href="#root" data-toggle="modal" data-target="#version_popup" className="f-version">**</a>}</p>
	                    <a className="f-split" href="#root">Home</a>
	                    <a className="f-split" href="https://illumifin.com">About Us</a>
	                    <ContentLink typeName="Privacy" displayTxt="Privacy" className="f-split"></ContentLink>
	                    <ContentLink typeName="TermsAndCondition" displayTxt="Terms" className="f-split"></ContentLink>
	                    <a className="f-split" href="https://illumifin.com">News</a>
	                    <UserContext.Consumer>
	                        {
	                            user => {
	                                // Show FAQ Link for all logged in users except for member and dependent
	                                if (user && user.userTypeRegCode && ["09","05","01","06","08","04","10","11","12"].includes(user.userTypeRegCode))
	                                    return <Link className="f-split" to={`${process.env.PUBLIC_URL}/faq`}>FAQ</Link>
	                            }
	                        }
	                    </UserContext.Consumer>
	                    <UserContext.Consumer>
	                        {user => {
	                            if (user === null || user.homePageUpdatable === undefined || user.homePageUpdatable === false) {
	                                return <></>
	                            }
	                            if (user.needFirstUpdate || user.needUpdatePwd) {
	                                return <a className="f-split" href="#root" id="theme_make_my_workspace" onClick={(e: any) => {
	                                    e.preventDefault();
	                                }} title="Please update profile first." >Make This My Home Page</a>
	                            } else {
	                                return <a className="f-split" href="#root" title="Make This My Home Page" onClick={(e: any) => {
	                                    ajax({
	                                        url: '/api/profile/setDefaultHomepage',
	                                        method: 'PUT',
	                                        params: {
	                                            defaultHomepage: window.location.pathname
	                                        }, success: (res: string) => {
	                                            props.messageService?.showMessage("success", res);
	                                        }
	                                    });
	                                    e.preventDefault();
	                                }} id="theme_make_my_workspace" >Make This My Home Page</a>
	                            }
	                        }}
	                    </UserContext.Consumer>
	                </div>
	        	</div>
			</div>
		</footer>
        {showEnquiryForm && <SubmitYourInquiry user={props.user} supportedFileTypes={props.userService.rootComponent.state.supportedFileTypes
} />}
        <Modal title="common.lbl.portalversion" id="version_popup" footer={() => {
            return <>
                <Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></Button></>
        }}>
            <DL>
                <DLI condition={version.productVersion !== undefined} title="common.lbl.productversion" desc={version.productVersion} descId="productVersion"></DLI>
                <DLI condition={version.buildNumber !== undefined} title="common.lbl.buildnumber" desc={version.buildNumber} descId="buildNumber"></DLI>
                <DLI condition={version.buildTime !== undefined} title="common.lbl.buildtime" desc={version.buildTime} descId="buildTimestamp"></DLI>
				<dt><span>History</span></dt>
				<dd><a href="/delta">Delta File Link</a></dd>
            </DL>
        </Modal>
      </>
}