import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { INIT_PARAM_PREFIX_TABLE, TableCell } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, copyObjectExcept, isEmptyStr, validate, parseDate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { INVALID_MESSAGES } from '../shared/yupschema';
import './css/transactionlist.css'

interface TransactionListForm {
    filterBy: string,
    transactionNumber: string,
    firstName: string,
    lastName: string,
    groupNumber: string,
    accountNumber: number,
    participantIDNumber: string,
    idNum: string,
    certiNum: string,
    transactionTypeCode: string,
    transactionStatusCode: string,
    beforeDate: string,
    afterDate: string
}


const defaultInitialValues: TransactionListForm = {
    filterBy: 'byTrans',
    transactionNumber: '',
    firstName: '',
    lastName: '',
    groupNumber: '',
    accountNumber: 0,
    participantIDNumber: '',
    idNum: '',
    certiNum: '',
    transactionTypeCode: 'ALL',
    transactionStatusCode: '1',
    beforeDate: '',
    afterDate: ''
};

const generateColumns = () => {
    return [
        {
            title: 'common.lbl.transactionNo',
            name: 'transactionNumber',
            render: (data: any, type: any, row: any, meta: any) => {
                if (row.hasBeenLocked == 'true') {
                    return `<div style='text-align:left'>${row.transactionNumber}<span class='gwp-icon gwp-icon-lock gwp-icon-sm' title='Locked by ${row.lockedUser}'></span></div>`
                } else {
                    return `${row.transactionNumber}`
                }
            }
        },
        { title: 'common.lbl.memberName', name: 'memberName' },
        { title: 'common.lbl.memberID', name: 'participantIDNumber' },
        { title: 'common.lbl.group', name: 'group' },
        { title: 'common.lbl.account', name: 'account' },
        { title: 'common.lbl.identificationNo', name: 'idNum' },
        { title: 'common.lbl.certificateNo', name: 'certiNum' },
        { title: 'common.lbl.submitDate', name: 'submitDate', type: 'date' },
        { title: 'common.lbl.type', name: 'type' },
        { title: 'common.lbl.status', name: 'status' }
    ]
}

// Define validation for by name.
const byNameSchema = (values: TransactionListForm): any => {
    let result: any = {};
    if (isEmptyStr(values.lastName) && isEmptyStr(values.firstName)) {
        result.lastName = INVALID_MESSAGES.LASTNAME_IS_EMPTY;
        result.firstName = INVALID_MESSAGES.FIRSTNAME_IS_EMPTY;
    }
    return result;
}
// Define validation for by member ID.
const byMemberIDValidate = (values: TransactionListForm): any => {
    let result: any = {};
    if (values.participantIDNumber.trim() === "" && values.idNum.trim() === "" && values.certiNum.trim() === "") {
        result.participantIDNumber = INVALID_MESSAGES.NEED_ONE_OF_THEM_NOT_EMPTY;
        result.idNum = INVALID_MESSAGES.NEED_ONE_OF_THEM_NOT_EMPTY;
        result.certiNum = INVALID_MESSAGES.NEED_ONE_OF_THEM_NOT_EMPTY;
    } else {
        if (!isEmptyStr(values.participantIDNumber)) {
            if (!isEmptyStr(values.certiNum)) {
                result.participantIDNumber = INVALID_MESSAGES.ENTER_IDENTIFIER;
                result.certiNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
            }
            if (!isEmptyStr(values.idNum)) {
                result.participantIDNumber = INVALID_MESSAGES.ENTER_IDENTIFIER;
                result.idNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
            }
        } else if (!isEmptyStr(values.certiNum) && !isEmptyStr(values.idNum)) {
            result.certiNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
            result.idNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
        }
    }
    return result;
}

const retrieve = (values: any, config: any, columns: any, setTable: any, setTransactionList: any, setDisableDeleteButton?: Function, setDisableReviewButton?: Function, setDisableOpenButton?: Function): void => {
    if (setDisableDeleteButton != undefined) {
        setDisableDeleteButton(true);
    }
    if (setDisableReviewButton != undefined) {
        setDisableReviewButton(true);
    }
    if (setDisableOpenButton != undefined) {
        setDisableOpenButton(true);
    }
    let companyAndGroup = values.groupNumber.split("|");
    let accountNumber = values.accountNumber;
    let transactionTypeCode = values.transactionTypeCode;
    if (companyAndGroup[0] === '0') {
        companyAndGroup[0] = '';
        companyAndGroup[1] = '';
    }
    if (accountNumber == '0') {
        accountNumber = '';
    }
    if (values.transactionTypeCode === 'ALL') {
        transactionTypeCode = '';
    }
    let params = copyObjectExcept({ ...values, ...{ companyCode: companyAndGroup[0], groupNumber: companyAndGroup[1], accountNumber: accountNumber, transactionTypeCode: transactionTypeCode } }, true, INIT_PARAM_PREFIX_TABLE);
    ajax({
        url: '/api/transactionList/' + values.filterBy,
        params: params,
        success: (res: any) => {
            setTransactionList(res);
            setTable({
                columns: columns,
                data: res
            });

        }
    });
}
export const TransactionListComponent = withView((props: ViewComponentProps) => {
    const [config, setConfig] = useState<any | null>(null);
    const [accounts, setAccounts] = useState<Array<any>>([]);
    const [table, setTable] = useState<any | null>(null);
    const [userDateFormat, setUserDateFormat] = useState<any>("");
    const [transactionList, setTransactionList] = useState<Array<any>>([]);//transaction list records
    const [transaction, setTransaction] = useState<any>(null);//transaction record
    const [transIDs, setTransIDs] = useState<Array<string>>([]);//selected transaction ids
    const [canNotIDs, setCanNotIDs] = useState<string>("");//cannot be deleted transaction ids
    const [selectTransactionList, setSelectTransactionList] = useState<Array<any>>([]);//selected transaction records
    const [warningMsg, setWarningMsg] = useState<any>(null);
    const [deleteMsg, setDeleteMsg] = useState<any>(null);
    const [disableDeleteButton, setDisableDeleteButton] = useState<boolean>(true);
    const [disableReviewButton, setDisableReviewButton] = useState<boolean>(true);
    const [disableOpenButton, setDisableOpenButton] = useState<boolean>(true);


    // Define validation for by transaction.
    const byTransValidate = (values: TransactionListForm): any => {
        let result: any = {};
        let afterDate: any = parseDate(values.afterDate, userDateFormat);
        let beforeDate: any = parseDate(values.beforeDate, userDateFormat);
        if (!isEmptyStr(values.beforeDate) && !isEmptyStr(values.afterDate) && afterDate > beforeDate) {
            result.afterDate = INVALID_MESSAGES.AFTER_EARLIER_BEFORE_DATE
            result.beforeDate = INVALID_MESSAGES.AFTER_EARLIER_BEFORE_DATE
        }
        return result;
    }

    // define Form Validate function.
    const formValidate = (values: any): any => {
        if (values.filterBy === config.byName) {
            return validate(byNameSchema, values, props);
        }
        if (values.filterBy === config.byMemberID) {
            return validate(byMemberIDValidate, values, props);
        }
        if (values.filterBy === config.byTrans) {
            return validate(byTransValidate, values, props);
        }
    }

    const groupChangeHandler = (e: any | string, setFieldValue?: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target !== undefined ? e.target.value : e;
        if (companyAndGroupNumber === '0') {
            setAccounts([]);
            if (setFieldValue !== undefined) {
                setFieldValue("accountNumber", "0");
            }
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: '/api/transactionList/fetchAccounts',
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setAccounts(res);
                    if (setFieldValue !== undefined) {
                        setFieldValue("accountNumber", res[0].value === undefined ? 0 : res[0].value);
                    }
                }
            });
        }
    }

    const _deleteTransaction = (e: any, selectedRows: Array<number>) => {
        let tids = new Array();
        let stList = new Array();
        let ids = "";
        let cnIds = "";
        let reviewDisabled = false;
        let openDisabled = false;
        let deleteDisabled = false;
        let status = "";
        if (selectedRows.length === 0) {
            reviewDisabled = true;
            openDisabled = true;
            deleteDisabled = true;
        } else {
            selectedRows.forEach((row) => {
                stList.push(transactionList[row]);
                status = transactionList[row].status;
                ids += transactionList[row].transactionNumber + ",";
                tids.push(transactionList[row].transactionNumber);
                if (!transactionList[row].canBeUpdated) {
                    cnIds += transactionList[row].transactionNumber + ",";
                }
                if (status != 'Pending') {
                    reviewDisabled = true;
                }
                if (status != 'Incomplete' && status != 'Error') {
                    openDisabled = true;
                }
            });
            if (selectedRows.length === 1) {
                setTransaction(transactionList[selectedRows[0]]);
            }
        }
        setTransIDs(tids);
        setCanNotIDs(cnIds.substr(0, cnIds.length - 1));
        setSelectTransactionList(stList);
        if (ids.length > 0) {
            ids = ids.substr(0, ids.length - 1);
        } else {
            deleteDisabled = true;
        }

        setDisableDeleteButton(deleteDisabled);
        setDisableReviewButton(reviewDisabled);
        setDisableOpenButton(openDisabled);
    }

    const deleteTransaction = () => {
        ajax({
            url: '/api/transactionList/deleteTransaction',
            method: 'delete',
            data: transIDs,
            success: (res) => {
                let ele = document.getElementById("retrieve");
                if (ele) {
                    ele.click();
                }
                props.showMessage("success", "common.msg.transactionDeletedSuccessfully");
                props.closeModal("#deleteTransactionDialog");
            }
        });
    }

    const deleteTitle = () => {
        return <>
            <span className="gwp-icon gwp-icon-warning gwp-icon-sm"></span> <GlobalizedText message="common.lbl.deleteTransactions" />
        </>
    }

    const showDeleteDilog = () => {
        if (canNotIDs.length > 0) {
            setDeleteMsg(<GlobalizedText message="trans.lbl.not.authority.delete" />);
            setCanNotIDs(canNotIDs + ".");
        } else {
            setDeleteMsg(<GlobalizedText message="trans.lbl.all.trans.delete" />);
        }
        props.showModal('#deleteTransactionDialog');
    }

    const warnTitle = () => {
        return <>
            <span className="gwp-icon gwp-icon-warning gwp-icon-sm"></span> <GlobalizedText message="firupd.warning" />
        </>
    }

    const openTransaction = () => {
        if (transIDs.length > 1) {
            setWarningMsg(<GlobalizedText message="transaction.lbl.onlyOneEnroll" />);
            props.showModal('#warningDialog');
        } else {
            if (selectTransactionList != undefined && selectTransactionList[0].type == 'Update Member Payroll Schedule') {
                openPayrollSchedule();
            } else {
                let canBeUpdated = selectTransactionList[0].canBeUpdated;
                if (canBeUpdated != 'true') {
                    setWarningMsg(<GlobalizedText message="message_update_enrollment_cannot_update_incomplete" />);
                    props.showModal('#warningDialog');
                    return;
                }
                ajax({
                    url: '/api/transactionList/checkIfIsDenied',
                    params: {
                        companyCode: selectTransactionList[0].companyCode,
                        groupNumber: selectTransactionList[0].group,
                        accountNumber: selectTransactionList[0].account
                    },
                    success: (res: any) => {
                        _openEnrollment();
                    },
                    fail: (res: any) => {
                        setWarningMsg(<GlobalizedText message="message_update_enrollment_cannot_update_incomplete" />);
                        props.showModal('#warningDialog');
                    }
                });
            }
        }
    }

    const _openEnrollment = () => {
        let companyCode = transaction["companyCode"];
        let participantIDNumber = transaction["participantIDNumber"];
        let transactionNumber = transaction["transactionNumber"];
        let accountNumber = transaction["account"];
        let groupNumber = transaction["group"];
        props.next(`/enrollment?fromTransactionList=true&companyCode=${companyCode.trim()}&groupNumber=${groupNumber.trim()}&accountNumber=${accountNumber}&participantIDNumber=${participantIDNumber}&transactionNumber=${transactionNumber.trim()}&transactionIDList=${transactionNumber.trim()}&formAction=editTransaction`);

    }

    const openPayrollSchedule = () => {
        let companyCode = transaction["companyCode"];
        let participantIDNumber = transaction["participantIDNumber"];
        let transactionNumber = transaction["transactionNumber"];
        let accountNumber = transaction["account"];
        let groupNumber = transaction["group"];
        props.next(`/updatePayrollSchedule?fromTransactionList=true&companyCode=${companyCode.trim()}&groupNumber=${groupNumber.trim()}&accountNumber=${accountNumber}&participantIDNumber=${participantIDNumber}&transactionNumber=${transactionNumber.trim()}&transactionIDList=${transactionNumber.trim()}&formAction=editTransaction`);

    }

    const reviewTransaction = () => {

        props.next(`/transactionReview?fromTransactionList=true&transactionIDList=${transIDs}&formType=reviewTransaction`);
    }

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/transactionList/view',
                success: (res: any) => {
                    setConfig(res);
                    setUserDateFormat(res.dateFormat)
                    if (res.groups.length === 1) {
                        // if only one group selected, then try to load the accounts.
                        groupChangeHandler(res.groups[0].value);
                    }
                    let initParams = props.getInitParams();
                    if (initParams !== undefined) {
                        if (initParams.groupNumber !== '') {
                            groupChangeHandler(initParams.groupNumber);
                        }
                        retrieve({ ...defaultInitialValues, ...initParams }, res, generateColumns(), setTable, setTransactionList);
                    } else {
                        retrieve(defaultInitialValues, res, generateColumns(), setTable, setTransactionList);
                    }
                }
            });
        }
    }, [config])

    if (config === null || (config.groups.length === 1 && accounts.length === 0)) {
        return <></>;
    }
    let columns = generateColumns();
    let filterByOptions = config.filterBy;

    // define Form Submitting function.
    const formSubmit = (values: TransactionListForm): void => {
        props.setInitParams(values);
        retrieve(values, config, columns, setTable, setTransactionList, setDisableDeleteButton, setDisableReviewButton, setDisableOpenButton);
    }

    let initialValue: TransactionListForm = copyObjectExcept(props.getInitParams() !== undefined ? props.getInitParams() : { ...defaultInitialValues, ...{ filterBy: config.byTrans }, ...props.getInitParams() }, true, INIT_PARAM_PREFIX_TABLE);

    if (table === null) {
        setTable({
            columns: columns,
            data: []
        });
    }

    return (
        <React.Fragment>
            <Formik initialValues={initialValue}
                validate={formValidate}
                onSubmit={formSubmit}
                {...props.generateNoramlFormikProps()}
            >
                {formProps => <Form>
                    <props.Row>
                        <props.Col md="12" sm="12" xs="12">
                            <props.H5><GlobalizedText message="filter.transaction" /></props.H5>
                            <props.Information showExclamationSign={false} message="benefit.msg.err.field" ></props.Information>
                        </props.Col>
                        <props.Col md="4" sm="4" xs="12">
                            <props.SelectControl sort={false} onChange={(e: any) => {
                                groupChangeHandler(e, formProps.setFieldValue)
                            }} name="groupNumber" label="common.lbl.group" options={config.groups}>
                            </props.SelectControl>
                            <props.SelectControl sort={false} name="accountNumber" label="common.lbl.account" options={accounts.length === 0 ? config.accounts : accounts.map((account: any) => { return { name: account.displayValue, value: account.value } })}>
                            </props.SelectControl>
                            <props.SelectControl onChange={(e: any) => {
                                setTable({
                                    data: [],
                                    columns: columns
                                });
                                props.resetForm(formProps, { ...defaultInitialValues, ...{ filterBy: e.target.value } });
                                props.setInitParams(undefined);
                                formProps.setFieldValue("groupNumber", "0");
                                setAccounts([]);
                                setDisableDeleteButton(true);
                                setDisableReviewButton(true);
                                setDisableOpenButton(true);
                            }} name="filterBy" label="homepage.filterBy" options={filterByOptions}>
                            </props.SelectControl>
                        </props.Col>

                        <props.Col md="4" sm="4" xs="12">
                            {/** search by name */}
                            <props.Condition condition={formProps.values.filterBy === config.byName}>
                                <props.TextControl id="firstName" name="firstName" label="common.lbl.firstName" maxLength="20"></props.TextControl>
                                <props.TextControl id="lastName" name="lastName" label="common.lbl.lastName" maxLength="40"></props.TextControl>
                            </props.Condition>

                            {/** search by transaction*/}
                            <props.Condition condition={formProps.values.filterBy === config.byTrans}>
                                <props.SelectControl name="transactionStatusCode" label="benefit.transaction.status" options={config.transactionStatus}>
                                </props.SelectControl>
                                <props.SelectControl defaultOption="common.lbl.all" name="transactionTypeCode" label="benefit.transaction.type" options={config.transactionType}>
                                </props.SelectControl>
                            </props.Condition>

                            {/** search by member ID */}
                            <props.Condition condition={formProps.values.filterBy === config.byMemberID}>
                                <props.TextControl id="participantIDNumber" name="participantIDNumber" label="common.lbl.memberIdentifier" maxLength="10"></props.TextControl>
                                <props.Label><GlobalizedText className="nobold" message="common.lbl.or" /></props.Label>
                                <props.TextControl id="idNum" name="idNum" label="common.lbl.identificationNumber" maxLength="12"></props.TextControl>
                                <props.Label><GlobalizedText className="nobold" message="common.lbl.or" /></props.Label>
                                <props.TextControl id="certiNum" name="certiNum" label="benefit.certificate.number" maxLength="10"></props.TextControl>
                            </props.Condition>
                            <props.Button condition={formProps.values.filterBy === config.byName || formProps.values.filterBy === config.byMemberID} id="retrieve" type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>

                        {/** search by transaction*/}
                        <props.Col md="4" sm="4" xs="12" condition={formProps.values.filterBy === config.byTrans}>
                            <props.DateTimePickerControl max={new Date()} htmlFor="afterDate_input" name="afterDate" label="common.lbl.afterDate" dateformat={config.dateFormat} id="afterDate" />
                            <props.DateTimePickerControl max={new Date()} htmlFor="beforeDate_input" name="beforeDate" label="common.lbl.beforeDate" dateformat={config.dateFormat} id="beforeDate" />
                            <props.TextControl id="transactionNumber" name="transactionNumber" label="common.lbl.transNumber"></props.TextControl>
                            <props.Button type="submit" id="retrieve"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                    </props.Row>
                    <props.HR />
                    <props.Row>
                        <props.Col md="12" sm="12" xs="12">
                            <props.Information showExclamationSign={false} message="select.transaction.review" ></props.Information>
                            <props.Button disabled={disableReviewButton} onClick={(e: any) => {
                                reviewTransaction()
                            }}><GlobalizedText message="common.lbl.review" /></props.Button>
                            <props.Button disabled={disableDeleteButton} onClick={(e: any) => {
                                showDeleteDilog()
                            }}><GlobalizedText message="common.lbl.delete" /></props.Button>
                            <props.Button disabled={disableOpenButton} onClick={(e: any) => {
                                openTransaction()
                            }}><GlobalizedText message="common.lbl.open" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
                }
            </Formik>
            <props.Table setInitParams={props.setInitParams} getInitParams={props.getInitParams} id="transactionListTable" table={table} dateformat={props.getDateFormat()}
                select={{
                    type: 'multiple', onSelect: (e: any, selectedRows: Array<number>) => {
                        _deleteTransaction(e, selectedRows)
                    },
                    disabled: (row: any) => {
                        let result = false;
                        let status = row['status'];
                        let canBeUpdated = row['canBeUpdated'];
                        let hasBeenLocked = row['hasBeenLocked'];
                        if (status == 'Pending' || status == 'Error' || status == 'Incomplete' || status == 'Processing') {
                            if (canBeUpdated == 'false') {
                                result = true;
                            }
                            if (hasBeenLocked == 'true') {
                                result = true;
                            }
                        } else {
                            result = true;
                        }
                        return result;
                    }
                }}
            ></props.Table>

            {/**Delete transaction Dialog */}
            < props.Modal title={deleteTitle} id="deleteTransactionDialog" footer={() => {
                return <>
                    <props.Button onClick={(e: any) => { deleteTransaction() }}><GlobalizedText message="common.lbl.delete" /></props.Button>
                    <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                </>
            }}>
                <props.Row>
                    <props.Col sm="12" md="12" xs="12">
                        <props.Div>{deleteMsg}</props.Div>
                        <props.Div>{canNotIDs}</props.Div>
                        <props.Div>&nbsp;</props.Div>
                        <props.Div><GlobalizedText message="openenrol.lbl.confirmDelete"></GlobalizedText>.</props.Div>
                    </props.Col>
                </props.Row>
            </props.Modal>

            {/**Waring Dialog */}
            < props.Modal title={warnTitle} id="warningDialog" footer={() => {
                return <>
                    <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                </>
            }}>
                <props.Row>
                    <props.Col sm="12" md="12" xs="12">
                        <props.Div>{warningMsg}</props.Div>
                    </props.Col>
                </props.Row>
            </props.Modal >
        </React.Fragment >
    );
});