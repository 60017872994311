import React from 'react';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import './css/creditcardresult.css';
import { Formik, Form } from 'formik';
export const CreditCardSuccessComponent = withView((props: ViewComponentProps) => {
    const ccNumber = props.getParam("ccNumber");
    const policyNumber = props.getParam("policyNumber");
    const creditCardType = props.getParam("creditCardType");
    const expMonth = props.getParam("expMonth");
    const expYear = props.getParam("expYear");
    const creditCardToken = props.getParam("creditCardToken");
    const companyCode = props.getParam("companyCode");
    const optionType = props.getParam("optionType");
    const portal = props.getParam("portal");
    const description = props.getParam("description");
    const authOnly = props.getParam("authOnly");
    const merchant = props.getParam("merchant");
    const oldClientProfileID = props.getParam("oldClientProfileID");
    const oldCreditCardToken = props.getParam("oldCreditCardToken");
    const oldMerchant = props.getParam("oldMerchant");
    const oldCreditCardNumber = props.getParam("oldCreditCardNumber");
    const userID = props.getParam("userID");
    const portalUrlPerfix = props.getParam("portalUrlPerfix");
    const mockUrl = "/mocksavecard?optionType=" + optionType + "&portal=" + portal + "&description=" + description +
        "&authOnly=" + authOnly + "&companyCode=" + companyCode + "&policyNumber=" + policyNumber + "&merchant=" + merchant +
        "&userId=" + userID + "&portalUrlPerfix=" + portalUrlPerfix + "&oldClientProfileID=" + oldClientProfileID
        + "&oldCreditCardToken=" + oldCreditCardToken + "&oldMerchant=" + oldMerchant + "&oldCreditCardNumber=" + oldCreditCardNumber;
    let varForm: any;
    return <div id="creditcardresult">
        <React.Fragment>
            <Formik initialValues={{}}
                validate={(values) => {
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={() => {
                }}
            >
                {formProps => {
                    varForm = formProps;
                    return <Form>
                        <props.Panel>
                            <props.PanelBody >
                                <div className="container">
                                    <props.Row>
                                        <props.Col xs="12" sm="12" md="12">
                                            <div role="alert" className="alert alert-success gwp-page-message messageBanner">
                                                <span aria-hidden="true" className="glyphicon glyphicon-ok messageIcon"></span>
                                                <GlobalizedText message="common.msg.credit.card.accept" />
                                                <span className="printPlace">
                                                    <props.Span className="gwp-icon gwp-icon-print gwp-icon-sm " ></props.Span>
                                                    <props.LinkButton printVisible={false} onClick={() => props.print({ size: 'A5', orientation: 'landscape' })}><GlobalizedText message="common.print" /></props.LinkButton>
                                                </span>
                                            </div>
                                        </props.Col>
                                    </props.Row>
                                    <props.HR className="hrstyle" />
                                    <p id="currencyInfo" className="currencyInfo"></p>
                                    <props.Information message="common.msg.credit.card.update.success" showExclamationSign={false} />
                                    <props.Row className="payout-information-body">
                                        {/* <GlobalizedText className="gwp-page-title" message="common.lbl.payment.information" /> */}
                                        <props.PageTitle><GlobalizedText message="common.lbl.payment.information" /></props.PageTitle>
                                        <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                                            <props.DLI title="common.lbl.confir.num" desc={() => {
                                                return <>{creditCardToken}</>
                                            }} />
                                            <props.DLI title="common.lbl.policyNumber" desc={() => {
                                                return <>{policyNumber}</>
                                            }} />
                                            <props.DLI title="common.lbl.cred.card.type" desc={() => {
                                                let creditCardTypeDesc: any
                                                if (creditCardType === "MC") {
                                                    creditCardTypeDesc = "MasterCard"
                                                }
                                                else if (creditCardType === "DISC") {
                                                    creditCardTypeDesc = "Discover"
                                                }
                                                else if (creditCardType === "VISA") {
                                                    creditCardTypeDesc = "VISA"
                                                }
                                                return <>{creditCardTypeDesc}</>
                                            }} />
                                            <props.DLI title="common.lbl.creditcardnumber" desc={() => {
                                                return <>{"************" + ccNumber.substring(ccNumber.length - 4)}</>
                                            }} />
                                            <props.DLI title="common.lbl.expirationDate" desc={() => {
                                                return <>{expMonth + "/" + expYear}</>
                                            }} />
                                        </props.DL>
                                        <div className="modal-footer2">
                                            <props.Button className="btn btn-primary gwp-btn " id="close" onClick={() => { window.parent.postMessage('ccSuccess,' + optionType, '*'); props.next(mockUrl); }} > <GlobalizedText message="common.lbl.close" /></props.Button>
                                        </div>
                                    </props.Row>
                                </div>
                            </props.PanelBody>
                        </props.Panel >
                    </Form>
                }
                }
            </Formik>
        </React.Fragment>
    </div >
});