
import React, { useState, useEffect, useRef } from "react"
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { Formik, Form } from 'formik';
import { ajax, trim, validate } from '../shared/utils';
import { useMessage } from "../shared/message";
import GlobalizedText from '../shared/globalization';
import { TableColumn } from "../shared/components";
import { VALIDATION_REGEX } from "../shared/regex";
import { Message } from "../shared/message";

import * as yup from 'yup';
import './css/terminatedcoveragerecorddisplay.css';
import { RequiredTitle,WarningTitle } from "../shared/moduleTitles";

interface rowDataType {
    recID: number,
    accountDesc: string,
    accountNum: number,
    companyCode: string,
    duration: number,
    groupDesc: string,
    groupNum: string,

};
export const TerminatedCoverageRecordDisplayComponent = withView(function (props: ViewComponentProps) {
    const [config, setConfig] = useState<any | null>(null);
    const [groups, setGroups] = useState<Array<any>>([{}]);
    const [accounts, setAccounts] = useState<Array<any>>([{}]);

    const [groupsAll, setGroupsAll] = useState<Array<any>>([]);
    const [accountsAll, setAccountsAll] = useState<Array<any>>([]);

    const [edtGroupsAll, setEdtGroupsAll] = useState<Array<any>>([]);
    const [edtAccountsAll, setEdtAccountsAll] = useState<Array<any>>([]);

    const [handlerFlag, setHandlerFlag] = useState<string | null>();
    const [disableBtnFlag, setDisableBtnFlag] = useState<boolean>(true);


    const [mainDisplay, setMainDisplay] = useState<string>("block");
    const [addDisplay, setAddDisplay] = useState<string>("none");
    const [editDisplay, setEditDisplay] = useState<string>("none");

    const [defaultGroupOption, setDefaultGroupOption] = useState<any>({});
    const [defaultAccountOption, setDefaultAccountOption] = useState<any>({});
    let varFormPromp: any;
    let selectetedRowData = useRef<rowDataType>({
        recID: -1,
        accountDesc: "",
        accountNum: -1,
        companyCode: "",
        duration: 0,
        groupDesc: "",
        groupNum: ""
    });

    const tableColumns: Array<TableColumn> = [
        { "title": "common.lbl.group", width: "40%", "name": "groupDesc", "className": "wordbreakall", "sortable": true },
        { "title": "common.lbl.account",width: "30%", "name": "accountDesc", "className": "wordbreakall", "sortable": true },
        {
            "title": "common.lbl.duration", "name": "duration", "sortable": true, "type": "text",
            render: (data: any, type: any, row: any, meta: any): string => {
                if(data<2){
                    return data + " " + props.getGlobalizedText("common.lbl.month");
                }else{
                    return data + " " + props.getGlobalizedText("common.lbl.monthsnobrace");
                }
            }
        }
    ]
    const [terminatedCoverageTable, setTable] = useState<any | null>({ columns: tableColumns, data: [] });
    const formValidate = (values: any): any => {
        if (handlerFlag === 'addTCD') {
            return validate(addSchema, values, props);
        } else if (handlerFlag === 'editTCD') {
            return validate(editSchema, values, props);
        }
    }

    let addSchema = (formValue: any) => {
        let addValidateResult: any = {};
        let errorFlag: boolean = false;
        if (trim("" + formValue.addDuration) === "") {
            addValidateResult.addDuration = "common.msg.enterDuration";
            errorFlag = true;
        } else if (VALIDATION_REGEX.NUMBER.test(trim("" + formValue.addDuration)) === false) {
            addValidateResult.addDuration = "common.msg.durationnotnumeric";
            errorFlag = true;
        }

        if (errorFlag === true) {
            return addValidateResult;
        }

    };
    let editSchema = (formValue: any) => {
        let addValidateResult: any = {};
        let errorFlag: boolean = false;
        if (trim("" + formValue.editDuration) === "") {
            addValidateResult.editDuration = "common.msg.enterDuration";
            errorFlag = true;
        } else if (VALIDATION_REGEX.NUMBER.test(trim("" + formValue.editDuration)) === false) {
            addValidateResult.editDuration = "common.msg.durationnotnumeric";
            errorFlag = true;
        }
        if (errorFlag === true) {
            return addValidateResult;
        }
    };
    const groupChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target.value;
        if (companyAndGroupNumber === defaultGroupOption.value) {
            setAccounts([defaultAccountOption]);
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: '/api/terminatedcoverage/fetchConfiguredAccounts',
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setAccounts(res.accounts);
                    setFieldValue("account", res.accounts[0].value);
                }
            });
        }
    }
    const formSubmit = (values: any): void => {
        props.clearMessage();
        if (handlerFlag === 'retrieve') {
            retrieve(values, tableColumns, setTable);
        } else if (handlerFlag === 'addTCD') {
            addTCD(values);
        } else if (handlerFlag === 'editTCD') {
            editTCD(values);
        }
    }

    function openAddPage(formProps: any) {
        setHandlerFlag("clear");

        setMainDisplay("none");
        setAddDisplay("block");
        setEditDisplay("none");

        formProps.setFieldValue("addGroupNumber", defaultGroupOption.value);
        setAccountsAll([defaultAccountOption]);
        formProps.setFieldValue("addAccountNumber", defaultAccountOption.value);
        formProps.setFieldValue("addDuration", "");
        props.clearMessage();

    }
    function openEditPage(formProps: any) {

        setHandlerFlag("clear");

        setMainDisplay("none");
        setAddDisplay("none");
        setEditDisplay("block");

        setEdtAccountsAll([defaultAccountOption]);
        formProps.setFieldValue("editGroupNumber", defaultGroupOption.value);
        formProps.setFieldValue("editAccountNumber", defaultAccountOption.value);
        if (selectetedRowData.current.recID !== -1) {

            props.clearMessage();
            formProps.setFieldValue("editGroupNumber", trim(selectetedRowData.current.companyCode) + "|" + trim(selectetedRowData.current.groupNum));
            if (trim(selectetedRowData.current.companyCode) + "|" + trim(selectetedRowData.current.groupNum) === defaultGroupOption.value) {
                setEdtAccountsAll([defaultAccountOption]);
            } else {

                ajax({
                    url: '/api/terminatedcoverage/fetchAllAccounts',
                    showMask: false,
                    params: {
                        companyCode: trim(selectetedRowData.current.companyCode),
                        groupNumber: trim(selectetedRowData.current.groupNum)
                    },
                    success: (res) => {
                        setEdtAccountsAll(res.allAccounts);
                        formProps.setFieldValue("editAccountNumber", selectetedRowData.current.accountNum);
                    }
                });
            }

            formProps.setFieldValue("editDuration", selectetedRowData.current.duration);
        }
    }


    function saveSelectedValue(row: any) {
        selectetedRowData.current = row;

        if (selectetedRowData != null) {
            varFormPromp.setFieldValue("editGroup", selectetedRowData.current.groupNum);
            varFormPromp.setFieldValue("editAccount", selectetedRowData.current.accountNum);
            varFormPromp.setFieldValue("editDuration", selectetedRowData.current.duration);
            setDisableBtnFlag(false);
        }
    }

    const groupAddChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target.value;
        if (companyAndGroupNumber === defaultGroupOption.value) {
            setAccountsAll([defaultAccountOption]);
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: '/api/terminatedcoverage/fetchAllAccounts',
                showMask: false,
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setAccountsAll(res.allAccounts);
                    setFieldValue("addAccountNumber", defaultAccountOption.value);
                }
            });
        }
    }
    const groupEditChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target.value;
        if (companyAndGroupNumber === defaultGroupOption.value) {
            setEdtAccountsAll([defaultAccountOption]);
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: '/api/terminatedcoverage/fetchAllAccounts',
                showMask: false,
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setEdtAccountsAll(res.allAccounts);
                    setFieldValue("editAccountNumber", defaultAccountOption.value);
                }
            });
        }
    }


    function retrieve(values: any, columns: any, setTable: any, setSubmitting?: Function) {
        let compCode = "0";
        let groupNumber = "0";
        let accountNumber = "0";

        if (values.groupNumber != "0") {
            let companyAndGroup = values.groupNumber.split("|");
            groupNumber = companyAndGroup[1];
            compCode = companyAndGroup[0];
        }

        if (values.groupNumber != "0" && values.accountNumber != "0") {
            accountNumber = values.accountNumber;
        }

        ajax({
            url: '/api/terminatedcoverage/fetchDurations/',
            params: {
                companyCode: compCode,
                groupNumber: groupNumber,
                accountNumber: accountNumber
            },
            success: (res: any) => {
                setDisableBtnFlag(true);
                if (res.terminatedCoverageList !== undefined) {
                    setTable({
                        columns: columns,
                        data: res.terminatedCoverageList.map((row: any) => { return { ...row, ...{ checked: false } } })
                    });
                }
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }

    function addTCD(formValue: any) {

        props.clearMessage();

        let groupNum = "";
        let compStr = "0";
        let accountNum = "0";

        if (formValue.addGroupNumber != 0) {
            let companyAndGroup = formValue.addGroupNumber.split("|");
            groupNum = companyAndGroup[1];
            compStr = companyAndGroup[0];
            accountNum = formValue.addAccountNumber;
        }
        let duration = formValue.addDuration;
        ajax({
            url: '/api/terminatedcoverage/add',
            method: 'post',
            data: {
                companyCode: trim(compStr),
                groupNumber: trim(groupNum),
                accountNumber: trim(accountNum),
                duration: trim(duration)

            },
            success: (res: any) => {
                setMainDisplay("block");
                setAddDisplay("none");
                setEditDisplay("none");

                setDisableBtnFlag(true);
                props.showMessage("success", "common.msg.addSuccess");
                setConfig(null);

            },
            fail: (res: any) => {
                props.showMessage("error", "common.msg.recordExist");
            },
            callback: () => {
            }
        });
    }
    function editTCD(formValue: any) {
        let groupNum = "";
        let compStr = "0";
        let accountNum = "0";
        if (formValue.addGroupNumber != 0) {
            let companyAndGroup = formValue.editGroupNumber.split("|");
            groupNum = companyAndGroup[1];
            compStr = companyAndGroup[0];
            accountNum = formValue.editAccountNumber;
        }
        let duration = trim("" + formValue.editDuration);

        ajax({
            url: `/api/terminatedcoverage/update/${selectetedRowData.current.recID}`,
            method: 'post',
            data: {
                companyCode: trim(compStr),
                groupNumber: trim(groupNum),
                accountNumber: trim("" + accountNum),
                duration: duration
            },
            success: (res: any) => {
                setMainDisplay("block");
                setAddDisplay("none");
                setEditDisplay("none");
                retrieve(formValue, tableColumns, setTable);
                setDisableBtnFlag(true);
                props.showMessage("success", "common.msg.updateSuccess");
            },
            fail: (res: any) => {
                props.showMessage("error", "common.msg.recordExist");
            },
            callback: () => {
            }
        });
    }
    function closePage() {
        props.clearMessage();
        setMainDisplay("block");
        setAddDisplay("none");
        setEditDisplay("none");
    }
    function openDeleteDialog(formValue: any) {
        if (selectetedRowData != null) {
            props.showModal('#confirmDelete')
        }
    }
    function delTCD(formValue: any) {
        ajax({
            url: `/api/terminatedcoverage/delete/${selectetedRowData.current.recID}`,
            method: 'delete',
            success: (res: any) => {
                setDisableBtnFlag(true);
                props.showMessage("success", "common.msg.removeSuccess");
                setConfig(null);
            },
            callback: () => {
            }
        });
    }
    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/terminatedcoverage/view',
                success: (res: any) => {
                    setConfig(res);
                    varFormPromp.setFieldValue("groupNumber", defaultGroupOption.value);
                    setAccounts(res.accounts);
                    setGroups(res.groups);

                    setAccountsAll(res.allAccounts);
                    setGroupsAll(res.allGroups);

                    setEdtAccountsAll(res.allAccounts);
                    setEdtGroupsAll(res.allGroups);

                    setDefaultGroupOption(res.defaultGroupOption);
                    setDefaultAccountOption(res.defaultAccountOption);

                    varFormPromp.setFieldValue("account", 0);

                    if (res.terminatedCoverageList !== undefined) {
                        setTable({
                            columns: tableColumns,
                            data: res.terminatedCoverageList.map((row: any) => { return { ...row, ...{ checked: false } } })
                        });
                    }
                }
            });
            return () => {
            }
        }
    }, [config]);

    return <React.Fragment>
        <Formik initialValues={{
            groupNumber: '0|0',
            accountNumber: '0',

            addGroupNumber: '0|0',
            addAccountNumber: '0',
            addDuration: '',


            editGroupNumber: '',
            editAccountNumber: '0',
            editDuration: ''
        }}
            validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps => {
                varFormPromp = formProps;
                return <Form>

                    <props.Row style={{ display: `${mainDisplay}` }}>

                        <props.Col xs="12" sm="12" md="12">
                            <props.Label><GlobalizedText message='common.lbl.groupaccount' /></props.Label>
                        </props.Col>
                        <props.Col xs="12" sm="5" md="5">
                            <props.SelectControl name="groupNumber" sort={false} label="common.lbl.group" onChange={(e: any) => { groupChangeHandler(e, formProps.values, formProps.setFieldValue) }} options={config === null ? [defaultGroupOption] : config.groups}>
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="5" md="5">
                            <props.SelectControl label="common.lbl.account" sort={false} name="accountNumber" options={accounts} >
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="2" md="2" id="retriveButtondiv">
                            <props.Button type="submit" onClick={() => { setHandlerFlag("retrieve") }}   ><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <props.HR />
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <props.Button name="btnAdd" type="submit" onClick={() => { openAddPage(formProps) }} ><GlobalizedText message="common.lbl.add" /></props.Button>
                            <props.Button name="btnEdit" type="submit" disabled={disableBtnFlag} onClick={() => { openEditPage(formProps) }} ><GlobalizedText message="common.lbl.edit" /></props.Button>
                            <props.Button name="btnDelete" disabled={disableBtnFlag} onClick={() => { openDeleteDialog(formProps.values) }}  ><GlobalizedText message="security.lbl.remove" /></props.Button>
                        </props.Col>

                    </props.Row>



                    <props.Row style={{ display: `${addDisplay}` }}>

                        <props.Col xs="12" sm="4" md="4">
                            <props.SelectControl name="addGroupNumber" sort={false} label="common.lbl.group" required={true} onChange={(e: any) => { groupAddChangeHandler(e, formProps.values, formProps.setFieldValue) }} options={config === null ? [defaultGroupOption] : config.allGroups}>
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="4" md="4">
                            <props.SelectControl name="addAccountNumber" sort={false} label="common.lbl.account" required={true} options={accountsAll}>
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="4" md="4" id="durationdiv">
                            <props.InputAddonControl name="addDuration" id="addDuration" required={true} label="common.lbl.duration" maxLength={6} addonMsg="common.lbl.months" />
                        </props.Col>
                        <props.Col xs="12" sm="12" md="12">
                            <props.Button name="btnAddSubmit" type="submit" onClick={() => { setHandlerFlag("addTCD") }} ><GlobalizedText message="common.lbl.submit" /></props.Button>
                            <props.Button name="btnAddClose" onClick={() => { closePage() }}  ><GlobalizedText message="common.lbl.close" /></props.Button>
                        </props.Col>
                    </props.Row>


                    <props.Row style={{ display: `${editDisplay}` }}>

                        <props.Col xs="12" sm="4" md="4">
                            <props.SelectControl name="editGroupNumber" sort={false} label="common.lbl.group" required={true} onChange={(e: any) => { groupEditChangeHandler(e, formProps.values, formProps.setFieldValue) }} options={config === null ? [defaultGroupOption] : config.allGroups}>
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="4" md="4">
                            <props.SelectControl name="editAccountNumber" sort={false} label="common.lbl.account" required={true} options={edtAccountsAll}  >
                            </props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="4" md="4" id="durationdiv">
                            <props.InputAddonControl name="editDuration" id="editDuration" required={true} label="common.lbl.duration" maxLength={6} addonMsg="common.lbl.months" />
                        </props.Col>
                        <props.Col xs="12" sm="4" md="4">
                            <props.Button name="btnEditSubmit" type="submit" onClick={() => { setHandlerFlag("editTCD") }} ><GlobalizedText message="common.lbl.submit" /></props.Button>
                            <props.Button name="btnEditClose" onClick={() => { closePage() }}  ><GlobalizedText message="common.lbl.close" /></props.Button>
                        </props.Col>
                    </props.Row>

                    {/* Delete Dialog */}
                    <props.Modal title={()=>{
                            return WarningTitle('common.lbl.removeTerminatedCoverageRecordDisplay');
                        }
                    } id="confirmDelete" footer={() => {
                        return <>
                            <props.Button onClick={() => { delTCD(formProps.values) }} data-toggle="modal" data-target="#confirmDelete" id='removeAccess'><GlobalizedText message="security.lbl.remove" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }} >

                        <props.Row >
                            <props.Col xs="12" sm="12" md="12" className="hinttop">
                                <GlobalizedText message="common.msg.selectedToRemoveTC" />
                            </props.Col>
                            <props.Col xs="12" sm="12" md="12">

                                <props.DL >
                                    <props.DLI titleClass="gwp-label-ro" title="common.lbl.group" desc={"<" + (selectetedRowData === null ? "" : selectetedRowData.current.groupDesc) + ">"}></props.DLI>

                                    <props.DLI titleClass="gwp-label-ro" title="common.lbl.account" desc={"<" + (selectetedRowData === null ? "" : selectetedRowData.current.accountDesc) + ">"}></props.DLI>

                                    <props.DLI titleClass="gwp-label-ro" title="common.lbl.duration" desc={"<" + (selectetedRowData === null ? "" : "" + selectetedRowData.current.duration) + " " + props.getGlobalizedText("common.lbl.months") + ">"}></props.DLI>
                                </props.DL>
                            </props.Col>
                            <props.Col xs="12" sm="12" md="12" className="hintbottom">
                                <GlobalizedText message="common.msg.confirmRemoveTC" />
                            </props.Col>
                        </props.Row>


                    </props.Modal>

                </Form>
            }
            }

        </Formik>
        <props.Div style={{ display: `${mainDisplay}` }}>
            <props.Table id="terminatedCoverageTableResult" select={{ type: 'single', onSelect: (e: any, rows: number[]) => { saveSelectedValue(terminatedCoverageTable.data[rows[0]]) } }} table={terminatedCoverageTable} ></props.Table>
        </props.Div>
    </React.Fragment >
});