import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { DEFAULT_FORMAT_TYPE, INIT_PARAM_PREFIX_TABLE, NAME_TYPE_COMPANY, NAME_TYPE_PERSON, TableCell } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, copyObjectExcept, isEmptyStr, trimFields, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { INVALID_MESSAGES, YupSchema } from '../shared/yupschema';
import './css/coverageinquiry.css'

interface CoverageInquiryForm {
    filterBy: string,
    lastName: string,
    firstName: string,
    nameType: string,
    orgName: string,
    birthDate: string,
    groupNumber: string,
    accountNumber: number,
    participantIDNumber: string,
    idNum: string,
    certiNum: string,
    policyNumber: string,
    govtID: string,
    ci_filter_group: string,
    ci_filter_account: number
}

interface AccountFilters {
    key: any
    value: Array<any>
}
const defaultInitialValues: CoverageInquiryForm = {
    filterBy: '',
    firstName: '',
    lastName: '',
    nameType: NAME_TYPE_PERSON,
    orgName: '',
    groupNumber: '',
    accountNumber: 0,
    participantIDNumber: '',
    idNum: '',
    certiNum: '',
    policyNumber: '',
    govtID: '',
    birthDate: '',
    ci_filter_group: '0',
    ci_filter_account: 0
};

const generateColumns = (config: any): any => {
    return {
        byMemberID: [
            {
                title: 'common.lbl.memberName',
                name: 'memberName',
                render: (data: any, type: any, row: any, meta: any) => {
                    return `<a dt-href='/personalInformation?personalInfoType=${1}&companyCode=${row.companyCode}&groupNumber=${row.groupNumber}&accountNumber=${row.accountNumber}&participantIDNumber=${row.memberIDDisplayValue}&dependentSequenceNumber=${0}'>${row.memberName}</a>`
                }
            },
            { title: 'common.lbl.dob', name: 'birthDateDisplayValue', type: 'date' },
            { title: 'common.lbl.govID', name: 'govtID' },
            { title: 'common.lbl.group', name: 'groupDisplayValue' },
            { title: 'common.lbl.account', name: 'accountDisplayValue' },
            {
                title: 'common.lbl.memberIdentifier',
                name: 'memberIDDisplayValue',
                render: (data: any, type: any, row: any, meta: any) => {
                    if (row.accessMemberCoverage) {
                        return `<a dt-href='/coverageSummary?companyCode=${row.companyCode}&groupNumber=${row.groupNumber}&accountNumber=${row.accountNumber}&participantIDNumber=${row.memberIDDisplayValue}'>${row.memberIDDisplayValue}</a>`
                    } else {
                        return `${row.memberIDDisplayValue}`
                    }
                }
            },
            { title: 'common.lbl.identificationNumber', name: 'idNumDisplayValue' },
            { title: 'benefit.certificate.number', name: 'certiNumDisplayValue' },
            { title: 'common.lbl.memeffectiveDate', name: 'effectiveDate', type: 'date' },
            { title: 'common.lbl.memterminationDate', name: 'terminationDate', type: 'date' }
        ],
        byName: [
            {
                title: 'common.lbl.name',
                name: 'name',
                render: (data: any, type: any, row: any, meta: any) => {
                    return `<a dt-href='/insurance?clientNumber=${row.clientNumber}&name=${escape(row.name)}&fromPage=coverage'>${row.name}</a>`
                }
            },
            { title: 'common.lbl.dob', name: 'formatedBirthDate', type: 'date' },
            { title: 'common.lbl.govID', name: 'govtID' },
            { title: 'common.lbl.streetAddress', name: 'street' },
            { title: 'common.lbl.city', name: 'city' },
            { title: 'common.lbl.stateCountry', name: 'country' },
            { title: 'pi.zip.code', name: 'zipcode' },
            { title: 'common.lbl.memeffectiveDate', name: 'effectiveDate', type: 'date' },
            { title: 'common.lbl.memterminationDate', name: 'terminationDate', type: 'date' }
        ],
        byGovtID: [
            {
                title: 'common.lbl.name',
                name: 'name',
                render: (data: any, type: any, row: any, meta: any) => {
                    return `<a dt-href='/insurance?companyCode=${row.companyCode}&name=${row.name}&clientNumber=${row.clientNumber}&fromPage=coverage'>${row.name}</a>`
                }
            },
            { title: 'common.lbl.dob', name: 'formatedBirthDate', type: 'date' },
            { title: 'common.lbl.govID', name: 'govtID' },
            { title: 'common.lbl.streetAddress', name: 'street' },
            { title: 'common.lbl.city', name: 'city' },
            { title: 'common.lbl.stateCountry', name: 'country' },
            { title: 'pi.zip.code', name: 'zipcode' },
            { title: 'common.lbl.memeffectiveDate', name: 'effectiveDate', type: 'date' },
            { title: 'common.lbl.memterminationDate', name: 'terminationDate', type: 'date' }
        ],
        byPolicy: [
            {
                title: 'common.lbl.policyNumber',
                name: 'policyNumber',
                render: (data: any, type: any, row: any, meta: any) => {
                    row = trimFields(row);
                    if (row.viewPolicy) {
                        return `<a dt-href='/policyDetail/${row.companyCode}/${row.policyNumber}'>${row.policyNumber}</a>`
                    } else {
                        return `${row.policyNumber}`
                    }
                }
            },
            {
                title: 'my.ins.owner.name',
                name: 'name',
                render: (data: any, type: any, row: any, meta: any) => {
                    row = trimFields(row);
                    // return `<a dt-href='/personalInformation?companyCode=${row.companyCode}&policyNumber=${row.policyNumber}&roleType=PAY'>${row.name}</a>`
                    return `<a dt-href='/personalInformation?companyCode=${row.companyCode}&policyNumber=${row.policyNumber}&roleTypeCode=${'OW1'}&personalInfoType=${2}&baseRiderCode=${0}'>${row.name}</a>`
                }
            },
            { title: 'common.lbl.dob', name: 'formatedBirthDate', type: 'date' },
            { title: 'common.lbl.govID', name: 'govtID' },
            { title: 'pd.lbl.productname', name: 'productName' },
            { title: 'common.lbl.effectiveDate', name: 'effectiveDate', type: 'date' },
            { title: 'common.lbl.insured', name: 'formatedInsured' },
            { title: 'common.lbl.status', name: 'status' }
        ],
        byPatientName: [
            { title: 'common.lbl.memberIdentifier', name: 'memberIDDisplayValue' },
            {
                title: 'common.lbl.memberName',
                name: 'memberName',
                render: (data: any, type: any, row: any, meta: any) => {
                    row = trimFields(row);
                    return `<a dt-href='/coverageSummary?companyCode=${row.companyCode}&groupNumber=${row.groupNumber}&accountNumber=${row.accountNumber}&participantIDNumber=${row.memberIDDisplayValue}&dependentSequenceNumber=${row.dependentSequenceNumber}'>${row.memberName}</a>`
                }
            },
            { title: 'common.lbl.dob', name: 'birthDateDisplayValue', type: 'date' },
            { title: 'common.lbl.group', name: 'groupDisplayValue' },
            { title: 'common.lbl.account', name: 'accountDisplayValue' },
            { title: 'common.lbl.memeffectiveDate', name: 'effectiveDate', type: 'date' },
            { title: 'common.lbl.memterminationDate', name: 'terminationDate', type: 'date' }
        ],
        byMemberIDProvider: [
            {
                title: 'common.lbl.memberName',
                name: 'memberName',
                render: (data: any, type: any, row: any, meta: any) => {
                    row = trimFields(row);
                    return `<a dt-href='/coverageSummary?companyCode=${row.companyCode}&groupNumber=${row.groupNumber}&accountNumber=${row.accountNumber}&participantIDNumber=${row.memberIDDisplayValue}&dependentSequenceNumber=${row.dependentSequenceNumber}'>${row.memberName}</a>`
                }
            },
            { title: 'common.lbl.dob', name: 'birthDateDisplayValue', type: 'date' },
            { title: 'common.lbl.govID', name: 'govtID' },
            { title: 'common.lbl.group', name: 'groupDisplayValue' },
            { title: 'common.lbl.account', name: 'accountDisplayValue' },
            { title: 'common.lbl.memberIdentifier', name: 'memberIDDisplayValue' },
            { title: 'common.lbl.identificationNumber', name: 'idNumDisplayValue' },
            { title: 'benefit.certificate.number', name: 'certiNumDisplayValue' },
            { title: 'common.lbl.memeffectiveDate', name: 'effectiveDate', type: 'date' },
            { title: 'common.lbl.memterminationDate', name: 'terminationDate', type: 'date' }
        ]
    }
}

// Define validation for by name.
const byNameSchema = (values: CoverageInquiryForm): any => {
    let result: any = {};
    if (values.nameType === NAME_TYPE_PERSON) {
        if (isEmptyStr(values.lastName)) {
            result.lastName = INVALID_MESSAGES.LASTNAME_IS_EMPTY;
        }
        if (values.lastName.trim().length < 2) {
            result.lastName = INVALID_MESSAGES.LASTNAME_CONTAINS_2_CHARACTERS;
        }
    }
    if (values.nameType === NAME_TYPE_COMPANY) {
        if (isEmptyStr(values.orgName)) {
            result.orgName = INVALID_MESSAGES.COMPANY_NAME_IS_EMPTY;
        }
        if (values.orgName.trim().length < 2) {
            result.orgName = INVALID_MESSAGES.COMPANY_CONTAINS_2_CHARACTERS;
        }
    }
    return result;
}
// Define validation for by policy number.
const byPolicySchema = yup.object().shape({
    policyNumber: YupSchema.policyNumber
});

// Define validation for by member ID.
const byMemberIDValidate = (values: CoverageInquiryForm): any => {
    let result: any = {};
    if (values.participantIDNumber.trim() === "" && values.idNum.trim() === "" && values.certiNum.trim() === "") {
        result.participantIDNumber = INVALID_MESSAGES.NEED_ONE_OF_THEM_NOT_EMPTY;
        result.idNum = INVALID_MESSAGES.NEED_ONE_OF_THEM_NOT_EMPTY;
        result.certiNum = INVALID_MESSAGES.NEED_ONE_OF_THEM_NOT_EMPTY;
    } else {
        if (!isEmptyStr(values.participantIDNumber)) {
            if (!isEmptyStr(values.certiNum)) {
                result.participantIDNumber = INVALID_MESSAGES.ENTER_IDENTIFIER;
                result.certiNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
            }
            if (!isEmptyStr(values.idNum)) {
                result.participantIDNumber = INVALID_MESSAGES.ENTER_IDENTIFIER;
                result.idNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
            }
        } else if (!isEmptyStr(values.certiNum) && !isEmptyStr(values.idNum)) {
            result.certiNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
            result.idNum = INVALID_MESSAGES.ENTER_IDENTIFIER;
        }
    }
    return result;
}

// Define validation for by patient name.
const byPatientNameSchema = (values: CoverageInquiryForm): any => {
    let result: any = {};
    if (isEmptyStr(values.firstName)) {
        result.firstName = INVALID_MESSAGES.PATIENT_FIRST_NAME_REQUIRED;
    }
    if (isEmptyStr(values.lastName)) {
        result.lastName = INVALID_MESSAGES.PATIENT_LAST_NAME_REQUIRED;
    }
    if (isEmptyStr(values.birthDate)) {
        result.birthDate = INVALID_MESSAGES.PATIENT_DOB_REQUIRED;
    }
    return result;
}

// Define validation for by member ID provider.
const byMemberIDProviderValidate = (values: CoverageInquiryForm): any => {
    let result: any = {};
    if (isEmptyStr(values.firstName)) {
        result.firstName = INVALID_MESSAGES.PATIENT_FIRST_NAME_REQUIRED;
    }
    if (isEmptyStr(values.lastName)) {
        result.lastName = INVALID_MESSAGES.PATIENT_LAST_NAME_REQUIRED;
    }
    if (isEmptyStr(values.groupNumber)) {
        result.groupNumber = INVALID_MESSAGES.GROUP_NUMBER_REQUIRED;
    }
    if (isEmptyStr(values.birthDate)) {
        result.birthDate = INVALID_MESSAGES.PATIENT_DOB_REQUIRED;
    }
    let resultMemberID = byMemberIDValidate(values);
    result = { ...result, ...resultMemberID };
    return result;
}

const retrieve = (values: any, config: any, columns: any, setTable: any, next: (url: string) => void, setSubmitting?: Function, setNeedFilter?: Function, setGroupFilter?: Function, setAccountFilters?: Function, setAccountFilter?: Function, setRetrieveData?: Function,): void => {
    let companyAndGroup = values.groupNumber.split("|");
    if (companyAndGroup[0] === '0') {
        companyAndGroup[1] = 0;
    }
    let params = copyObjectExcept(values.filterBy === config.byMemberID ? { ...values, ...{ companyCode: companyAndGroup[0], groupNumber: companyAndGroup[1] } } : values, true, INIT_PARAM_PREFIX_TABLE);
    ajax({
        url: '/api/eligibilityInquiry/' + values.filterBy,
        params: params,
        success: (res: any) => {
            let data = res;
            if (values.filterBy === 'byName') {
                data = res.coverages
                if (res.needFilter) {
                    if (setNeedFilter !== undefined) {
                        setNeedFilter(true);
                    }
                    if (setGroupFilter !== undefined) {
                        setGroupFilter(res.groupFilter);
                    }
                    if (setAccountFilters !== undefined) {
                        setAccountFilters(res.accountFilter);
                    }
                    if (setAccountFilter !== undefined) {
                        if (values.ci_filter_group !== '' && values.ci_filter_group !== '0') {//Come back from the detail page (For ByName's fitler by)
                            setAccountFilter(res.accountFilter[values.ci_filter_group]);
                        } else {
                            setAccountFilter(res.accountFilter[res.groupFilter[0].value]);
                        }
                    }
                    if (setRetrieveData !== undefined) {
                        setRetrieveData(data);
                    }
                } else {
                    if (setNeedFilter !== undefined) {
                        setNeedFilter(false);
                    }
                }
            }
            if (values.ci_filter_group !== '' && values.ci_filter_group !== '0') {//Come back from the detail page (For ByName's fitler by)
                let ele = document.getElementById("filterButton");
                if (ele) {
                    ele.click();
                }
            } else {
                setTable({
                    columns: columns,
                    data: data
                });
            }
        },
        callback: () => {
            if (setSubmitting !== undefined) {
                setSubmitting(false);
            }
        }
    });
}

export const CoverageInquiryComponent = withView(function (props: ViewComponentProps) {
    const [config, setConfig] = useState<any | null>(null);
    const [accounts, setAccounts] = useState<Array<any>>([]);
    const [table, setTable] = useState<any | null>(null);
    const [retrieveData, setRetrieveData] = useState<any>(null);
    const [needFilter, setNeedFilter] = useState<boolean>(false);
    const [groupFilter, setGroupFilter] = useState<Array<any>>([]);
    const [accountFilters, setAccountFilters] = useState<AccountFilters>();
    const [accountFilter, setAccountFilter] = useState<Array<any>>([]);
    const isDataLoaded = useRef<boolean>(false);

    // define validation for by govId.
    const byGovtIDSchema = yup.object().shape({
        govtID: YupSchema.govtID(DEFAULT_FORMAT_TYPE, props.getGlobalizedText)
    });

    // define Form Validate function.
    const formValidate = (values: any): any => {
        if (values.filterBy === config.byName) {
            return validate(byNameSchema, values, props);
        }
        if (values.filterBy === config.byPolicy) {
            return validate(byPolicySchema, values, props);
        }
        if (values.filterBy === config.byGovtID) {
            return validate(byGovtIDSchema, values, props);
        }
        if (values.filterBy === config.byMemberID) {
            return validate(byMemberIDValidate, values, props);
        }
        if (values.filterBy === config.byPatientName) {
            return validate(byPatientNameSchema, values, props);
        }
        if (values.filterBy === config.byMemberIDProvider) {
            return validate(byMemberIDProviderValidate, values, props);
        }
    }

    const groupChangeHandler = (e: any | string, setFieldValue?: (fieldName: string, value: any) => void, callback?: () => void) => {
        let companyAndGroupNumber = e.target !== undefined ? e.target.value : e;
        if (companyAndGroupNumber === '0') {
            setAccounts([]);
            if (setFieldValue !== undefined) {
                setFieldValue("accountNumber", "0");
            }
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: '/api/eligibilityInquiry/fetchAccounts',
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setAccounts(res);
                    if (setFieldValue !== undefined) {
                        setFieldValue("accountNumber", res[0].value === undefined ? 0 : res[0].value);
                    }
                }, callback: callback
            });
        }
    }

    const accountChangeHandler = (e: any, setFieldValue: (fieldName: string, value: any) => void) => {
        setTable({
            columns: columns.byMemberID,
            data: []
        });
    }

    const filterGroupChangeHandler = (e: any, accountRecords: any, formProps: any): void => {
        let companyAndGroupNumber = e.target !== undefined ? e.target.value : e;
        setAccountFilter(accountRecords[companyAndGroupNumber]);
        if (formProps != null) {
            formProps.setFieldValue("ci_filter_account", accountRecords[companyAndGroupNumber][0].value);
        }
    }

    const filterRecords = (formProps: any): void => {
        let initParams = props.getInitParams();
        let groupFilterValue = formProps.values.ci_filter_group;
        let accountNum = formProps.values.ci_filter_account;
        let accountFilterValue = formProps.values.ci_filter_group + "|" + accountNum;
        initParams.ci_filter_group = groupFilterValue;
        initParams.ci_filter_account = accountNum;
        props.setInitParams(initParams);
        let companyCode = groupFilterValue.split("|")[0];
        let groupNum = groupFilterValue.split("|")[1];
        let data = new Array();
        let filterData = new Array();
        retrieveData.forEach((item: any) => {
            data.push(item);
        });
        if (companyCode === "0" && (groupNum === "0" || groupNum === undefined)) {
            data.forEach((item: any) => {
                filterData.push(item);
            });
        } else {
            if (companyCode != "0") {
                if (groupNum != "0") {
                    data.forEach((item: any) => {
                        if (item.groupNumberList != undefined && item.groupNumberList.indexOf(groupFilterValue) != -1) {
                            if (accountNum === "0") {
                                filterData.push(item);
                            } else if ((item.accountNumberList).indexOf(accountFilterValue) != -1) {
                                if (item.memberAccountEffectiveDate != undefined && item.memberAccountEffectiveDate != null && item.memberAccountEffectiveDate.length != 0
                                    && item.memberAccountTerminationDate != undefined && item.memberAccountTerminationDate != null && item.memberAccountTerminationDate.length != 0) {
                                    var effDates = item.memberAccountEffectiveDate;
                                    var terDates = item.memberAccountTerminationDate;
                                    var effDate = item.effectiveDate;
                                    var terDate = item.terminationDate;
                                    for (var j = 0; j < effDates.length; j++) {
                                        if (effDates[accountFilterValue] != undefined) {
                                            effDate = effDates[accountFilterValue].split("|")[3];
                                        }
                                    }
                                    for (var t = 0; t < terDates.length; t++) {
                                        if (terDates[accountFilterValue] != undefined) {
                                            terDate = terDates[accountFilterValue].split("|")[3];
                                        }
                                    }
                                    item.effectiveDate = effDate;
                                    item.terminationDate = terDate;
                                    filterData.push(item);
                                } else {
                                    filterData.push(item);
                                }
                            }
                        }
                    });
                } else {// csr will have companyCode here
                    data.forEach((item: any) => {
                        if (item.companyCode === companyCode) {
                            filterData.push(item);
                        }
                    });
                }

            }
        }
        setTable({
            data: filterData,
            columns: table.columns
        });
    }

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/eligibilityInquiry/view',
                success: (res: any) => {
                    setConfig(res);
                    if (!res.isProvider && res.groups.length === 1) {
                        // if only one group selected, then try to load the accounts.
                        groupChangeHandler(res.groups[0].value);
                    }
                    let initParams = props.getInitParams();
                    if (initParams !== undefined) {
                        if (!res.isProvider && initParams.groupNumber !== '') {
                            groupChangeHandler(initParams.groupNumber);
                        }
                        retrieve({ ...defaultInitialValues, ...{ filterBy: res.searchBy[0].value }, ...initParams }, res, generateColumns(config)[initParams.filterBy], setTable,
                            props.next, undefined, initParams.ci_filter_group !== '' ? setNeedFilter : undefined, setGroupFilter, setAccountFilters, setAccountFilter, initParams.ci_filter_group !== '' ? setRetrieveData : undefined);
                        isDataLoaded.current = true;
                    }
                }
            });
        }
    }, [config])

    if (config === null || (config.searchBy[0].value === config.byMemberID && config.groups.length === 1 && accounts.length === 0)) {
        return <></>;
    }
    let columns = generateColumns(config);
    let filterByOptions = config.searchBy;

    // define Form Submitting function.
    const formSubmit = (values: CoverageInquiryForm): void => {
        values.ci_filter_group = '0';
        values.ci_filter_account = 0;
        props.setInitParams(values);
        retrieve(values, config, columns[values.filterBy], setTable, props.next, undefined, setNeedFilter, setGroupFilter, setAccountFilters, setAccountFilter, setRetrieveData);
        isDataLoaded.current = true;
    }

    let initialValue: CoverageInquiryForm = copyObjectExcept(props.getInitParams() !== undefined ? props.getInitParams() : { ...defaultInitialValues, ...{ filterBy: config.searchBy[0].value }, ...props.getInitParams() }, true, INIT_PARAM_PREFIX_TABLE);

    if (table === null) {
        setTable({
            columns: columns[initialValue.filterBy],
            data: []
        });
    }

    return (
        <React.Fragment>
            <Formik initialValues={initialValue}
                validate={formValidate}
                onSubmit={formSubmit}
                {...props.generateNoramlFormikProps()}
            >
                {formProps => <Form>
                    <props.Row>
                        <props.Col md="6" sm="6" xs="12">
                            <props.SelectControl onChange={(e: any) => {
                                setTable({
                                    data: [],
                                    columns: columns[e.target.value]
                                });
                                props.resetForm(formProps, { ...defaultInitialValues, ...{ filterBy: e.target.value } });
                                isDataLoaded.current = false;
                                props.setInitParams(undefined);
                                if (e.target.value === "byMemberID") {
                                    if (config.groups.length === 1) {
                                        groupChangeHandler(config.groups[0].value, formProps.setFieldValue, () => {
                                            formProps.setFieldValue("groupNumber", config.groups[0].value);
                                        });
                                    } else {
                                        formProps.setFieldValue("groupNumber", "0");
                                    }
                                }
                                setNeedFilter(false);
                                setAccounts([]);
                            }} name="filterBy" label="sc.search.coverage.by" options={filterByOptions}>
                            </props.SelectControl>

                            {/** search by name */}
                            <props.NameTypeSelectControl onChange={(e: any) => {
                                setTable({
                                    data: [],
                                    columns: columns[formProps.values.filterBy]
                                });
                                props.resetForm(formProps, { ...defaultInitialValues, ...{ nameType: e.target.value, filterBy: formProps.values.filterBy } });
                                isDataLoaded.current = false;
                                setNeedFilter(false);
                            }} condition={formProps.values.filterBy === config.byName} name="nameType" />

                            <props.TextControl condition={formProps.values.filterBy === config.byMemberIDProvider} id="groupNumber" name="groupNumber" required={true} label="common.lbl.groupNumber" maxLength="10"></props.TextControl>
                            {/** search by patient name/member ID provider */}
                            <props.Condition condition={formProps.values.filterBy === config.byPatientName || formProps.values.filterBy === config.byMemberIDProvider}>
                                <props.TextControl required={true} id="patientFirstName" name="firstName" label="coverage.lbl.patientfirstname" maxLength="20"></props.TextControl>
                                <props.TextControl required={true} id="patientLastName" name="lastName" label="coverage.lbl.patientlastname" maxLength="40"></props.TextControl>
                            </props.Condition>
                            <props.DateTimePickerControl condition={formProps.values.filterBy === config.byMemberIDProvider} max={new Date()} required={true} name="birthDate" label="coverage.lbl.patientdateofbirth" dateformat={config.userDateFormat} id="birthDate" />

                            {/** search by member ID */}
                            <props.SelectControl sort={false} condition={formProps.values.filterBy === config.byMemberID} onChange={(e: any) => {
                                groupChangeHandler(e, formProps.setFieldValue)
                            }} name="groupNumber" label="common.lbl.group" required={true} options={config.groups}>
                            </props.SelectControl>
                            <props.SelectControl sort={false} condition={formProps.values.filterBy === config.byMemberID} name="accountNumber" onChange={(e: any) => {
                                accountChangeHandler(e, formProps.setFieldValue)
                            }} label="common.lbl.account" required={true} options={accounts.length === 0 ? config.accounts : accounts.map((account: any) => { return { name: account.name, value: account.value } })}>
                            </props.SelectControl>

                            {/** search by policy */}
                            <props.TextControl condition={formProps.values.filterBy === config.byPolicy} id="policyNumber" name="policyNumber" required={true} label="common.lbl.policyNumber"></props.TextControl>

                            {/** search by gov id */}
                            <props.AutoFormatControl formatType="SSN" valueWithFormat={true} condition={formProps.values.filterBy === config.byGovtID} id="govtID" name="govtID" label="common.lbl.govID" required={true}></props.AutoFormatControl>

                            <props.Button condition={formProps.values.filterBy === config.byPolicy || formProps.values.filterBy === config.byGovtID} type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>

                        <props.Col md="6" sm="6" xs="12">
                            {/** search by name */}
                            <props.LastNameAutoCompleteControl roleTypeCodes={["OW1", "OW2", "PAY", "MBR"]} required={true} condition={formProps.values.filterBy === config.byName && formProps.values.nameType === NAME_TYPE_PERSON} htmlFor="lastName_input" extLabel={"common.lbl.lastName2characters"} id="lastName" name="lastName" />
                            <props.FirstNameAutoCompleteControl roleTypeCodes={["OW1", "OW2", "PAY", "MBR"]} condition={formProps.values.filterBy === config.byName && formProps.values.nameType === NAME_TYPE_PERSON} htmlFor="firstName_input" id="firstName" name="firstName" />
                            <props.DateTimePickerControl condition={formProps.values.filterBy === config.byName && formProps.values.nameType === NAME_TYPE_PERSON} max={new Date()} htmlFor="birthDate_input" id="birthDate" name="birthDate" label="common.lbl.dob" dateformat={config.userDateFormat} />
                            <props.OrgNameAutoCompleteControl required={true} roleTypeCodes={["OW1", "OW2", "PAY"]} condition={formProps.values.filterBy === config.byName && formProps.values.nameType === NAME_TYPE_COMPANY} htmlFor="orgName_input" id="orgName" name="orgName" extLabel={"common.lbl.lastName2characters"} />

                            {/** search by patient name */}
                            <props.DateTimePickerControl condition={formProps.values.filterBy === config.byPatientName} max={new Date()} required={true} name="birthDate" label="coverage.lbl.patientdateofbirth" dateformat={config.userDateFormat} htmlFor="patientBirthDate_input" id="patientBirthDate" />

                            {/** search by member ID/member ID provider*/}
                            <props.Condition condition={formProps.values.filterBy === config.byMemberID || formProps.values.filterBy === config.byMemberIDProvider}>
                                <props.Information message="homepage.all.lbl.required" className="phoneSpace"></props.Information>
                                <props.TextControl id="participantIDNumber" name="participantIDNumber" label="common.lbl.memberIdentifier" maxLength="10"></props.TextControl>
                                <props.TextControl id="idNum" name="idNum" label="common.lbl.identificationNumber" maxLength="12"></props.TextControl>
                                <props.TextControl id="certiNum" name="certiNum" label="benefit.certificate.number" maxLength="10"></props.TextControl>
                            </props.Condition>

                            <props.Button condition={formProps.values.filterBy === config.byName ||
                                formProps.values.filterBy === config.byPatientName ||
                                formProps.values.filterBy === config.byMemberID ||
                                formProps.values.filterBy === config.byMemberIDProvider} type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                    </props.Row>
                    {/** filter by group/account */}
                    <props.Row condition={needFilter}>
                        <props.Col md="3" sm="3" xs="12" className="gwp-label">
                            <GlobalizedText message="common.lbl.filterResultsBy" />
                        </props.Col>
                    </props.Row>
                    <props.Row condition={needFilter}>
                        <props.Col md="6" sm="6" xs="12">
                            <props.SelectControl sort={false} onChange={(e: any) => { filterGroupChangeHandler(e, accountFilters, formProps) }} name="ci_filter_group" label="common.lbl.group" options={groupFilter}>
                            </props.SelectControl>
                        </props.Col>
                        <props.Col md="6" sm="6" xs="12">
                            <props.SelectControl sort={false} name="ci_filter_account" label="common.lbl.account" options={accountFilter}>
                            </props.SelectControl>
                            <props.Button id="filterButton" onClick={(e: any) => { filterRecords(formProps) }}><GlobalizedText message="common.lbl.filter" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
                }
            </Formik>
            <props.Table isDataLoaded={isDataLoaded.current} setInitParams={props.setInitParams} getInitParams={props.getInitParams} id="coverageInquiryTable" next={props.next} table={table} dateformat={props.getDateFormat()} onResponsiveDisplay={(rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element) => {
                childNode?.querySelectorAll("a")?.forEach((a: HTMLElement) => {
                    let href = a.attributes[0].value;
                    a.addEventListener("click", (e: any) => { if (href !== null) { props.next(href); e.preventDefault(); } });
                });
            }}></props.Table>
        </React.Fragment >
    );
});