import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { INIT_PARAM_PREFIX_TABLE, TableCell } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, copyObjectExcept, isEmptyStr, trimFields, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { INVALID_MESSAGES } from '../shared/yupschema';
import './css/policydocument.css'

interface PolicyDocumentForm {
    documentType: string,
    policyNumber: string
}

const defaultInitialValues: PolicyDocumentForm = {
    documentType: '',
    policyNumber: ''
}
const retrieve = (values: any, cols: any, setTable: any): void => {

    let params = copyObjectExcept(values, true, INIT_PARAM_PREFIX_TABLE);
    ajax({
        url: '/api/policyDocument/fetchDocuments',
        params: params,
        success: (res: any) => {
            setTable({
                columns: cols,
                data: res
            });
        }
    });
}

export const PolicyDocumentComponent = withView((props: ViewComponentProps) => {
    let policyNumber = props.getParam("policyNumber");
    const [config, setConfig] = useState<any>();
    const [table, setTable] = useState<any | null>(null);
    const isDataLoaded = useRef<boolean>(false);
    const pdfViewer = props.usePDFViewer({ ...props });

    const tableOption = {
        language: { emptyTable: props.getGlobalizedText("common.msg.noresultfound") }
    };

    const columns = [
        { title: 'common.lbl.creationdate', name: 'creationDate', type: 'date' },
        {
            title: 'common.lbl.filename',
            name: 'fileName',
            onClick: (e: any, row: any) => {
                let pdfUrl = `/api/policyDocument/viewDocument?uniqueKey=${row.uniqueKey}&policyNumber=${policyNumber}`;
                pdfViewer.showPDF(pdfUrl);
            },
            render: (data: any, type: any, row: any, meta: any) => {
                row = trimFields(row);
                return `<a>${row.fileName}</a>`
            }
        }];

    const formValidate = (values: any): any => {
        let result: any = {};
        if (isEmptyStr(values.documentType) || values.documentType == '-1') {
            result.documentType = INVALID_MESSAGES.DOCUMENT_TYPE_MUST_SELEECT;
        }
        return result;
    }

    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/policyDocument/view',
                params: { policyNumber },
                success: (res: any) => {
                    setConfig(res);
                }
            });
        }
    }, [config]);
    if (config === undefined) {
        return <></>;
    }

    if (table === null) {
        setTable({
            columns: columns,
            data: []
        });
    }
    // define Form Submitting function.
    const formSubmit = (values: PolicyDocumentForm): void => {
        props.setInitParams(values);
        retrieve(values, columns, setTable);
        isDataLoaded.current = true;
    }

    let initialValue: PolicyDocumentForm = copyObjectExcept(props.getInitParams() !== undefined ? props.getInitParams() : { ...defaultInitialValues, ...props.getInitParams(), policyNumber: policyNumber }, true, INIT_PARAM_PREFIX_TABLE);

    return (
        <React.Fragment>
            <Formik initialValues={initialValue}
                validate={formValidate}
                onSubmit={formSubmit}
                {...props.generateNoramlFormikProps()}
            >
                {formProps => <Form>
                    <props.Row>
                        <props.Col sm="8" md="8" xs="12">
                            <props.PageHeader className="gwp-billingsummary-label" label={() => {
                                return <><GlobalizedText message="common.lbl.policyNumber" /> {policyNumber}
                                </>
                            }} />
                        </props.Col>
                    </props.Row>
                    <props.Panel>
                        <props.PanelHeading>
                            <GlobalizedText message="common.lbl.choosedocumenttype" />
                        </props.PanelHeading>
                        <props.PanelBody>
                            <props.Row>
                                <props.Col md="3" sm="3" xs="12">
                                    <props.SelectControl id="documentType" sort={false} name="documentType" label="common.lbl.policydocumenttype" required={true} options={config.documentTypes}>
                                    </props.SelectControl>
                                </props.Col>
                                <props.Col md="3" sm="3" xs="12">
                                    <props.Button className="retrieve" type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                                </props.Col>
                                <props.Col md="12" sm="12" xs="12">
                                    <props.Table isDataLoaded={isDataLoaded.current} id="policyDocumentTable" setInitParams={props.setInitParams} getInitParams={props.getInitParams}
                                        next={props.next} table={table} option={tableOption} dateformat={props.getDateFormat()}
                                        onResponsiveDisplay={(rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element) => {
                                            childNode?.querySelectorAll("a")?.forEach((a: HTMLElement) => {
                                                let href = a.attributes[0].value;
                                                a.addEventListener("click", (e: any) => { if (href !== null) { props.next(href); e.preventDefault(); } });
                                            });
                                        }}></props.Table>
                                </props.Col>
                            </props.Row>
                        </props.PanelBody>
                    </props.Panel>
                    <props.Button onClick={props.back}><GlobalizedText message="common.lbl.close" /></props.Button>

                </Form>
                }
            </Formik>
            {/* PDF view comp */}
            <pdfViewer.Component></pdfViewer.Component>
        </React.Fragment >
    );
});
