import ReactGA from 'react-ga';
import { Analytics } from './analytics';

/**
 * Initialize Google Analytics.
 * @param gaTrackingID 
 */
const initialize = (gaTrackingID:string|undefined)=>{
    if(gaTrackingID){
        ReactGA.initialize(gaTrackingID);
    }
}

/**
 * Record page view
 * @param gaTrackingID 
 */
const pageView = (gaTrackingID:string|undefined)=>{
    if(gaTrackingID){
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
}

const googleAnalytics:Analytics<any> =  {
    initialize:(conf:any)=>{initialize(conf.gaTrackingId)},
    pageView:(conf:any)=>{pageView(conf.gaTrackingId)}
}

export default googleAnalytics;