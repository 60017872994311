import React, { useEffect, useState, Component } from 'react';

/*	    This implementation is used in opposition of npm/React libraries that
  		require calls to the Google Translate API with a fee per call. Normally we package a local JS library file in the
  		application build, but since this particular library is contingent upon being online, due to the breadth of 
  		translation data available, it seems to be more reasonable to address the library host directly. 
          
        Also, in this React system, adding the script import to the index.html file causes the translate widget to only
        show up intermittently between page refreshes. */
export const GoogleTranslate = () => {
    const [translate, setTranslate] = useState<string>();
    useEffect(() => {

        if(translate === undefined) {
            var addScript = document.createElement('script');
            addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
            setTranslate(addScript.outerHTML);
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = googleTranslateElementInit;
        }

    }, []);


    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
    }

    return <div id="google_translate_element"></div>;
}

export default GoogleTranslate;