import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import GlobalizedText from '../shared/globalization';
import { ajax, isMobileDevice, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import './css/mocksavecard.css';
export const MockSaveCardComponent = withView((props: ViewComponentProps) => {
    const [ccTypes, setCcTypes] = useState<any>(null);
    const companyCode = props.getParam("companyCode");
    const policyNumber = props.getParam("policyNumber");
    const optionType = props.getParam("optionType");
    const portal = props.getParam("portal");
    const description = props.getParam("description");
    const authOnly = props.getParam("authOnly");
    const merchant = props.getParam("merchant");
    const oldClientProfileID = props.getParam("oldClientProfileID");
    const oldCreditCardToken = props.getParam("oldCreditCardToken");
    const oldMerchant = props.getParam("oldMerchant");
    const oldCreditCardNumber = props.getParam("oldCreditCardNumber");
    const userID = props.getParam("userID");
    const portalUrlPerfix = props.getParam("portalUrlPerfix");
    let varForm: any;
    const defaultInitialValues = {
        clientProfileID: "",
        creditCardToken: "",
        firstName: "",
        middleName: "",
        lastName: "",
        creditCardType: "MC",
        expirationDate: "",
        ccNumber: "",
        vendor: "100"
    };
    useEffect(() => {
        if (ccTypes === null && companyCode) {
            setCcTypes([]);
            ajax({
                url: '/api/payment/getCcTypes',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber
                },
                success: (res: any) => {
                    setCcTypes(res.ccTypes);
                }
            });
        }
    }, [ccTypes]);

    if (ccTypes === null || (ccTypes !== undefined && ccTypes.length === 0)) {
        return <></>
    }
    function CreditCardFail(optionType: string, companyCode: string, policyNumber: string, errorMessage: string) {
        ajax({
            url: '/api/payment/creditCardFailure',
            params: {
                companyCode: companyCode,
                policyNumber: policyNumber,
                optionType: optionType,
                errorMessage: errorMessage
            },
            success: (res) => { }
        });
    }
    const byAddUpdateSchema = yup.object().shape({
        firstName: YupSchema.mocksavecard.firstName,
        lastName: YupSchema.mocksavecard.lastName,
        creditCardType: YupSchema.mocksavecard.creditCardType,
        ccNumber: YupSchema.mocksavecard.ccNumber,
        expirationDate: YupSchema.mocksavecard.expirationDate,
        clientProfileID: YupSchema.mocksavecard.clientProfileID,
        creditCardToken: YupSchema.mocksavecard.creditCardToken,
        vendor: YupSchema.mocksavecard.vendor
    });
    const formSubmit = (values: any): void => {
        ajax({
            url: '/api/payment/creditCardSuccess',
            method: 'post',
            params: {
                companyCode: companyCode,
                policyNumber: policyNumber,
                clientProfileID: values.clientProfileID === undefined ? "" : values.clientProfileID,
                creditCardToken: values.creditCardToken === undefined ? "" : values.creditCardToken,
                optionType: optionType,
                firstName: values.firstName === undefined ? "" : values.firstName,
                middleName: values.middleName === undefined ? "" : values.middleName,
                lastName: values.lastName === undefined ? "" : values.lastName,
                creditCardType: values.creditCardType === undefined ? "MC" : values.creditCardType,
                creditCardNumber: values.ccNumber === undefined ? "" : values.ccNumber,
                expirationDate: values.expirationDate === undefined ? "" : values.expirationDate,
                oldClientProfileID: oldClientProfileID,
                oldCreditCardToken: oldCreditCardToken,
                oldMerchant: oldMerchant,
                merchant: merchant,
            },
            success: (res: any) => {
                setCcTypes(res.ccTypes);
                let arrInfo: any
                arrInfo = varForm.values.expirationDate.split("/");
                let expYear = arrInfo[1];
                let expMonth = arrInfo[0];
                props.next("/creditcardsuccess?creditCardToken=" + varForm.values.creditCardToken + "&policyNumber=" + policyNumber
                    + "&creditCardType=" + varForm.values.creditCardType + "&ccNumber=" + varForm.values.ccNumber
                    + "&expMonth=" + expMonth + "&expYear=" + expYear + "&optionType=" + optionType + "&portal=" + portal + "&description=" + description +
                    "&authOnly=" + authOnly + "&companyCode=" + companyCode + "&policyNumber=" + policyNumber + "&merchant=" + merchant +
                    "&userId=" + userID + "&portalUrlPerfix=" + portalUrlPerfix + "&oldClientProfileID=" + oldClientProfileID
                    + "&oldCreditCardToken=" + oldCreditCardToken + "&oldMerchant=" + oldMerchant + "&oldCreditCardNumber=" + oldCreditCardNumber);
            }, fail: (res: any) => {
                props.next(process.env.PUBLIC_URL + "/creditcardfail?optionType=" + optionType + "&portal=" + portal + "&description=" + description +
                    "&authOnly=" + authOnly + "&companyCode=" + companyCode + "&policyNumber=" + policyNumber + "&merchant=" + merchant +
                    "&userId=" + userID + "&portalUrlPerfix=" + portalUrlPerfix + "&oldClientProfileID=" + oldClientProfileID
                    + "&oldCreditCardToken=" + oldCreditCardToken + "&oldMerchant=" + oldMerchant + "&oldCreditCardNumber=" + oldCreditCardNumber);
            }, error: (res: any) => {
                props.next(process.env.PUBLIC_URL + "/creditcardfail?optionType=" + optionType + "&portal=" + portal + "&description=" + description +
                    "&authOnly=" + authOnly + "&companyCode=" + companyCode + "&policyNumber=" + policyNumber + "&merchant=" + merchant +
                    "&userId=" + userID + "&portalUrlPerfix=" + portalUrlPerfix + "&oldClientProfileID=" + oldClientProfileID
                    + "&oldCreditCardToken=" + oldCreditCardToken + "&oldMerchant=" + oldMerchant + "&oldCreditCardNumber=" + oldCreditCardNumber);
            }
        });
    }
    return <div id="mocksavecard">
        <React.Fragment>
            <Formik initialValues={defaultInitialValues}
                validate={(values) => {
                    return validate(byAddUpdateSchema, values, props);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={formSubmit}
            >
                {formProps => {
                    varForm = formProps;
                    return <Form>
                        <props.Panel >
                            <props.PanelHeading>
                                <GlobalizedText message="method.mock.title" />
                            </props.PanelHeading>
                            <props.PanelBody>
                                {!isMobileDevice() && <>
                                    <props.Row>
                                        <props.Col xs="4" md="4">
                                            <props.DL>
                                                <props.TextControl name="firstName" tabIndex={1} required={true} label="common.lbl.firstName" ></props.TextControl>
                                                <props.SelectControl name="creditCardType" tabIndex={4} required={true} label="common.lbl.cred.card.type" options={ccTypes}>
                                                </props.SelectControl>
                                                <props.TextControl name="clientProfileID" tabIndex={7} required={true} label="payment.lbl.clientProfileId" ></props.TextControl>
                                            </props.DL>
                                        </props.Col>

                                        <props.Col xs="4" md="4">
                                            <props.DL >
                                                <props.TextControl name="middleName" tabIndex={2} label="payment.mock.middle" ></props.TextControl>
                                                <props.TextControl name="ccNumber" tabIndex={5} required={true} label="payment.mock.ccnumber" ></props.TextControl>
                                                <props.TextControl name="creditCardToken" tabIndex={8} required={true} label="payment.lbl.creditCardToken" ></props.TextControl>
                                            </props.DL>
                                        </props.Col>
                                        <props.Col xs="4" md="4">
                                            <props.DL>
                                                <props.TextControl name="lastName" tabIndex={3} required={true} label="common.lbl.lastName" ></props.TextControl>
                                                <props.TextControl name="expirationDate" tabIndex={6} required={true} label="payment.mock.label.expdate"></props.TextControl>
                                                <props.TextControl name="vendor" tabIndex={9} required={true} label="payment.mock.vendor" value="100" disabled={true} ></props.TextControl>
                                            </props.DL>
                                        </props.Col>
                                    </props.Row>
                                </>}
                                {isMobileDevice() && <>
                                    <props.Row>
                                        <props.Col xs="12">
                                            <props.DL>
                                                <props.TextControl name="firstName" tabIndex={1} required={true} label="common.lbl.firstName" ></props.TextControl>
                                                <props.TextControl name="middleName" tabIndex={2} label="payment.mock.middle" ></props.TextControl>
                                                <props.TextControl name="lastName" tabIndex={3} required={true} label="common.lbl.lastName" ></props.TextControl>
                                                <props.SelectControl name="creditCardType" tabIndex={4} required={true} label="common.lbl.cred.card.type" options={ccTypes}>
                                                </props.SelectControl>
                                                <props.TextControl name="ccNumber" tabIndex={5} required={true} label="payment.mock.ccnumber" ></props.TextControl>
                                                <props.TextControl name="expirationDate" tabIndex={6} required={true} label="payment.mock.label.expdate"></props.TextControl>
                                                <props.TextControl name="clientProfileID" tabIndex={7} required={true} label="payment.lbl.clientProfileId" ></props.TextControl>
                                                <props.TextControl name="creditCardToken" tabIndex={8} required={true} label="payment.lbl.creditCardToken" ></props.TextControl>
                                                <props.TextControl name="vendor" tabIndex={9} required={true} label="payment.mock.vendor" value="100" disabled={true} ></props.TextControl>
                                            </props.DL>
                                        </props.Col>
                                    </props.Row>
                                </>}
                                <props.Row className="buttonstyle">
                                    <props.Button type="submit" ><GlobalizedText message="payment.mock.succ" /></props.Button>
                                    <props.Button onClick={() => {
                                        CreditCardFail(optionType, companyCode, policyNumber, "some error");
                                        window.location.href = process.env.PUBLIC_URL + "/creditcardfail?optionType=" + optionType + "&portal=" + portal + "&description=" + description +
                                            "&authOnly=" + authOnly + "&companyCode=" + companyCode + "&policyNumber=" + policyNumber + "&merchant=" + merchant +
                                            "&userId=" + userID + "&portalUrlPerfix=" + portalUrlPerfix + "&oldClientProfileID=" + oldClientProfileID
                                            + "&oldCreditCardToken=" + oldCreditCardToken + "&oldMerchant=" + oldMerchant + "&oldCreditCardNumber=" + oldCreditCardNumber;
                                    }} ><GlobalizedText message="common.lbl.failed" /></props.Button>
                                    <props.Button onClick={() => {
                                        window.location.href = process.env.PUBLIC_URL + "/creditcardcancel?optionType=" + optionType + "&portal=" + portal + "&description=" + description +
                                            "&authOnly=" + authOnly + "&companyCode=" + companyCode + "&policyNumber=" + policyNumber + "&merchant=" + merchant +
                                            "&userId=" + userID + "&portalUrlPerfix=" + portalUrlPerfix + "&oldClientProfileID=" + oldClientProfileID
                                            + "&oldCreditCardToken=" + oldCreditCardToken + "&oldMerchant=" + oldMerchant + "&oldCreditCardNumber=" + oldCreditCardNumber;
                                    }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                                </props.Row>
                            </props.PanelBody>
                        </props.Panel >

                    </Form>
                }
                }
            </Formik>
        </React.Fragment>
    </div >
});