import React from 'react';

export const appendTHextraColumn = () => {
	const theParent = document.querySelector("#accessPermissionTable thead");
	if(theParent !== null){
	const findAppendedTr = document.querySelector(".appended-tr");
		if(findAppendedTr === null){
			const theKid = document.createElement("tr");
			theKid.classList.add('appended-tr');
			theKid.innerHTML = `
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th colspan="5" style="text-align: center;">Reports</th>
				<th></th>
				`;
		
			// append theKid to the end of theParent
			theParent.insertBefore(theKid, theParent.firstChild);
		}
	}
}

