import React, { useEffect, useState } from "react";
import GlobalizedText from "../../shared/globalization";
import { ajax } from "../../shared/utils";
import Enrollment from '../types/enrollmenttypes';
import './css/benefitClassificationSelection.css';
import { RequiredHeader } from "./enrollment";
export const BenefitClassificationSelect = (props: Enrollment.Props) => {
    const [config, setConfig] = useState<any>(undefined);

    props.setPrepareData((values: any) => {
        let beneIdx: any = values.beneIdx;
        let benefitClassificationIndex = "";
        if (typeof (beneIdx) === "string") {
            benefitClassificationIndex = beneIdx;
        } else {
            benefitClassificationIndex = beneIdx.join(",");
        }
        return { benefitClassificationIndex: benefitClassificationIndex, transactionID: props.transactionID };
    });
    useEffect(() => {
        if (props.formValue === undefined || config === undefined) {
            let params = {
                transactionID: props.transactionID
            };
            ajax({
                url: '/api/enrollment/benefitClassificationSelect/view',
                params: params,
                success: (res: any) => {
                    if (res.multiSelected === false) {
                        props.setFormValue({ beneIdx: "" });
                        res.benefitClassList.forEach((benefitClass: any, index: number) => {
                            if (benefitClass.checked === true) {
                                props.setFormValue({ beneIdx: "" + benefitClass.benefitClassificationIndex });
                            }
                        });
                        if (res.benefitClassList === null || res.benefitClassList.length === 0) {
                            setConfig({
                                multiSelected: res.multiSelected,
                                benefitClassList: [],
                                canUpdateBenefitClass: res.canUpdateBenefitClass
                            });
                        } else {
                            setConfig({
                                multiSelected: res.multiSelected,
                                benefitClassList: res.benefitClassList,
                                canUpdateBenefitClass: res.canUpdateBenefitClass
                            });
                        }

                    } else if (res.multiSelected === true) {
                        props.setFormValue({ beneIdx: [] });
                        if (res.benefitClassList === null || res.benefitClassList.length === 0) {
                            props.setFormValue({ beneIdx: [] });
                            setConfig({
                                multiSelected: res.multiSelected,
                                benefitClassList: [],
                                canUpdateBenefitClass: res.canUpdateBenefitClass
                            });

                        } else {
                            let checkedArray: Array<string> = [];
                            res.benefitClassList.map((benefitClass: any, index: number) => {
                                if (benefitClass.checked === true) {
                                    checkedArray.push("" + benefitClass.benefitClassificationIndex);
                                }
                            });
                            props.setFormValue({ beneIdx: checkedArray });
                            setConfig({
                                multiSelected: res.multiSelected,
                                benefitClassList: res.benefitClassList,
                                canUpdateBenefitClass: res.canUpdateBenefitClass
                            });

                        }

                    }
                }
            });
        }
    }, [config])
    if (config === undefined) {
        return <></>
    }
    if (config != undefined) {
        if (config.multiSelected === true) {
            let multilSelected = (values: any) => {
                let res: any = {};
                if (values.beneIdx.length === 0) {

                    config.benefitClassList.map((benefitClass: any, index: number) => {
                        res = { ...res, ...{ "beneIdx": props.getGlobalizedText("billpay.premiumBilled.benefitClassification") + " " + props.getGlobalizedText("message_information_required") } };
                    });

                }
                return res;
            }
            props.setValidation(multilSelected);
        } else if (config.multiSelected === false) {
            let singleSelected = (values: any) => {
                let res: any = {};
                if (values.beneIdx === '') {
                    config.benefitClassList.map((benefitClass: any, index: number) => {
                        res = { ...res, ...{ "beneIdx": props.getGlobalizedText("billpay.premiumBilled.benefitClassification") + " " + props.getGlobalizedText("message_information_required") } };
                    });

                }
                return res;
            }
            props.setValidation(singleSelected);
        }
    }

    return <>
        <props.Panel id="benefitClassificationSelectPanel">
            <props.PanelHeading id="benefitClassificationSelectPanelHeading">
                <RequiredHeader label="lb_coverage" {...props} />
            </props.PanelHeading>
            <props.PanelBody id="benefitClassificationSelectPanelBody">

                <props.Label id="benefitClassificationSelectLabel"><GlobalizedText message="coverage_text1" /></props.Label>
                <props.Condition condition={config.multiSelected === false}>

                    {config.benefitClassList.length > 0 && config.benefitClassList.sort((first: any, next: any) => {
                        if (first.benefitClassificationDesc === next.benefitClassificationDesc) {
                            return 0;
                        }
                        return first.benefitClassificationDesc > next.benefitClassificationDesc ? 1 : -1;
                    }).map((benefitClass: any, index: number) => {
                        return <React.Fragment key={index}>
                            <props.RadioControl name="beneIdx" id={"beneIdx_" + benefitClass.benefitClassificationIndex} value={benefitClass.benefitClassificationIndex}
                                label={benefitClass.benefitClassificationDesc}
                                className="radChkBox"
                                disabled={config.canUpdateBenefitClass === false}
                            ></props.RadioControl>

                        </React.Fragment>
                    })}
                </props.Condition>
                <props.Condition condition={config.multiSelected === true}>
                    {config.benefitClassList.length > 0 && config.benefitClassList.sort((first: any, next: any) => {
                        if (first.benefitClassificationDesc === next.benefitClassificationDesc) {
                            return 0;
                        }
                        return first.benefitClassificationDesc > next.benefitClassificationDesc ? 1 : -1;
                    }).map((benefitClass: any, index: number) => {
                        return <React.Fragment key={index}>

                            <props.CheckboxControl name="beneIdx" id={"beneIdx_" + benefitClass.benefitClassificationIndex} value={benefitClass.benefitClassificationIndex}
                                label={benefitClass.benefitClassificationDesc}
                                className="beneChkBox"
                                disabled={config.canUpdateBenefitClass === false}
                            ></props.CheckboxControl>
                        </React.Fragment>
                    })}
                </props.Condition>
            </props.PanelBody>
        </props.Panel>
    </>
}