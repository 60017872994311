import React, { useState } from 'react';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import './css/creditcardresult.css';

export const CreditCardCancelComponent = withView((props: ViewComponentProps) => {
    const companyCode = props.getParam("companyCode");
    const policyNumber = props.getParam("policyNumber");
    const optionType = props.getParam("optionType");
    const portal = props.getParam("portal");
    const description = props.getParam("description");
    const authOnly = props.getParam("authOnly");
    const merchant = props.getParam("merchant");
    const oldClientProfileID = props.getParam("oldClientProfileID");
    const oldCreditCardToken = props.getParam("oldCreditCardToken");
    const oldMerchant = props.getParam("oldMerchant");
    const oldCreditCardNumber = props.getParam("oldCreditCardNumber");
    const userID = props.getParam("userID");
    const portalUrlPerfix = props.getParam("portalUrlPerfix");
    const mockUrl = "/mocksavecard?optionType=" + optionType + "&portal=" + portal + "&description=" + description +
        "&authOnly=" + authOnly + "&companyCode=" + companyCode + "&policyNumber=" + policyNumber + "&merchant=" + merchant +
        "&userId=" + userID + "&portalUrlPerfix=" + portalUrlPerfix + "&oldClientProfileID=" + oldClientProfileID
        + "&oldCreditCardToken=" + oldCreditCardToken + "&oldMerchant=" + oldMerchant + "&oldCreditCardNumber=" + oldCreditCardNumber;
    const [timeNumber, setTimeNumber] = useState<number>(5);
    let timer = window.setInterval(() => {
        if (timeNumber > 0) {
            setTimeNumber(timeNumber - 1);
        } else {
            returnWallet();
            window.clearInterval(timer);
        }

    }, 1000);
    function returnWallet() {
        props.next(mockUrl);
        window.parent.postMessage('ccCancel,' + optionType, '*');
    }
    return <div id="creditcardresult">
        <props.Panel>
            <props.PanelBody className="payout-information-body">
                <div className="container">
                    <props.Row className="failurerow">
                        <props.Col >
                            <div role="alert" className="alert alert-success gwp-page-message messageBanner">
                                <span aria-hidden="true" className="glyphicon glyphicon-warning-sign messageIcon"></span>
                                <GlobalizedText message="common.lbl.tran.cancel" />
                            </div>
                        </props.Col>
                    </props.Row>
                    <props.HR className="hrstyle" />
                    <p id="currencyInfo" className="currencyInfo"></p>
                    <GlobalizedText message="common.msg.tran.cancel" />
                    <div className="timeout">
                        <em>
                            <GlobalizedText message="common.msg.will.close" /> &nbsp;
                            <span id="timeOut">{timeNumber}</span>&nbsp;
                            <GlobalizedText message="common.msg.seconds" />
                        </em>
                    </div>
                    <div className="modal-footer2">
                        <props.Button className="btn btn-primary gwp-btn " id="close" onClick={() => {
                            returnWallet()
                        }}> <GlobalizedText message="common.lbl.close" /></props.Button>
                    </div>
                </div>
            </props.PanelBody>
        </props.Panel >
    </div >

});