import { FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { RequiredHeader } from "./enrollment";
import Enrollment from '../types/enrollmenttypes';
import { INVALID_MESSAGES } from "../../shared/yupschema";
import { removeFromArray } from "../../shared/utils";
import GlobalizedText from "../../shared/globalization";
import { ajax, isEmptyStr, trim } from "../../shared/utils";

interface DependentForm {
    dependentIdx: number,
    dependentSequenceNumber: string,
    firstName: string,
    lastName: string,
    birthDate: string,
    gender: string,
    relation: string,
    govtID: string,
    medicareIndicator: string,
    medicareEffectiveDate: string,
    salaryMode: string,
    employeeIDNumber: string,
    inBenefitDate: string,
    benefitOutDateCheck: boolean,
    benefitOutDate: string,
    smoker: string,
    hasOtherInsCheck: boolean,
    isStudent: string,
    effectiveDate: string,
    terminationDate: string,
    isLoadFromDB: string,
    dateFormat: string
}

const defaultInitialValues: DependentForm = {
    dependentIdx: 0,
    dependentSequenceNumber: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    gender: '',
    relation: '',
    govtID: '',
    medicareIndicator: '',
    medicareEffectiveDate: '',
    salaryMode: '',
    employeeIDNumber: '',
    inBenefitDate: '',
    benefitOutDateCheck: false,
    benefitOutDate: '',
    smoker: '',
    hasOtherInsCheck: false,
    isStudent: '',
    effectiveDate: '',
    terminationDate: '',
    isLoadFromDB: '',
    dateFormat: ''
};

export const Dependent = (props: Enrollment.Props) => {
    const [dateFormat, setDateFormat] = useState<any>(null);
    const [checkBoxMap, setCheckBoxMap] = useState<any | null>(null);//Check box values
    const [updateInfo, setUpdateInfo] = useState<boolean>(false);//updateMemberDependentDemograpicInformation
    const [displayStudentInformation, setDisplayStudentInformation] = useState<boolean>(false);//displayStudentInformation
    const [memberGovtID, setMemberGovtID] = useState<string>("");//govtID of member
    const dependentValidate = (values: DependentForm, index: number, result: any): any => {
        let requiredMsg = " " + props.getGlobalizedText("message_information_required");

        if (isEmptyStr(values.firstName)) {
            result[`dependents[${index}].firstName`] = INVALID_MESSAGES.FIRSTNAME_IS_EMPTY;
        }
        if (values.firstName.length > 20) {
            result[`dependents[${index}].firstName`] = INVALID_MESSAGES.FIRSTNAME_IS_TOO_LONG;
        }

        if (isEmptyStr(values.lastName)) {
            result[`dependents[${index}].lastName`] = INVALID_MESSAGES.LASTNAME_IS_EMPTY;
        }
        if (values.lastName.length > 20) {
            result[`dependents[${index}].lastName`] = INVALID_MESSAGES.LASTNAME_IS_TOO_LONG;
        }

        if (isEmptyStr(values.birthDate)) {
            result[`dependents[${index}].birthDate`] = props.getGlobalizedText("common.lbl.dob") + requiredMsg;
        }
        if (isEmptyStr(values.gender)) {
            result[`dependents[${index}].gender`] = props.getGlobalizedText("pi.gender") + requiredMsg;
        }
        if (isEmptyStr(values.relation)) {
            result[`dependents[${index}].relation`] = props.getGlobalizedText("pi.relation") + requiredMsg;
        }

        if (values.benefitOutDateCheck == true && isEmptyStr(values.benefitOutDate)) {
            result[`dependents[${index}].benefitOutDate`] = props.getGlobalizedText("benefitoutdate") + requiredMsg;
        }
        if (!isEmptyStr(values.inBenefitDate) && !isEmptyStr(values.benefitOutDate) && new Date(values.benefitOutDate).getTime() < new Date(values.inBenefitDate).getTime()) {
            result[`dependents[${index}].inBenefitDate`] = props.getGlobalizedText("inbenebitdate") + " " + props.getGlobalizedText("common.lbl.earlierThan") + " " + props.getGlobalizedText("benefitoutdate");
            result[`dependents[${index}].benefitOutDate`] = props.getGlobalizedText("benefitoutdate") + " " + props.getGlobalizedText("common.lbl.laterThan") + " " + props.getGlobalizedText("inbenebitdate");
        }

        if (values.isStudent == 'Y' && isEmptyStr(values.effectiveDate)) {
            result[`dependents[${index}].effectiveDate`] = props.getGlobalizedText("common.lbl.effectiveDate") + requiredMsg;
        }
        if (!isEmptyStr(values.terminationDate) && isEmptyStr(values.effectiveDate)) {
            result[`dependents[${index}].effectiveDate`] = props.getGlobalizedText("common.lbl.studentEffDateMsg");
        }
        if (!isEmptyStr(values.terminationDate) && !isEmptyStr(values.effectiveDate) && new Date(values.terminationDate).getTime() < new Date(values.effectiveDate).getTime()) {
            result[`dependents[${index}].effectiveDate`] = props.getGlobalizedText("common.lbl.effectiveDate") + " " + props.getGlobalizedText("common.lbl.earlierThan") + " " + props.getGlobalizedText("common.lbl.terminationDate");
            result[`dependents[${index}].terminationDate`] = props.getGlobalizedText("common.lbl.terminationDate") + " " + props.getGlobalizedText("common.lbl.laterThan") + " " + props.getGlobalizedText("common.lbl.effectiveDate");
        }
        if (!isEmptyStr(values.govtID) && !isEmptyStr(memberGovtID) && trim(memberGovtID).replaceAll("-", "") === trim(values.govtID).replaceAll("-", "")) {
            result[`dependents[${index}].govtID`] = props.getGlobalizedText("enrollment.depGovtID.dupM");
        }
        if (!isEmptyStr(values.govtID)) {
            props.formValue.dependents.forEach((dep: any, i: number) => {
                if (!isEmptyStr(dep.govtID) && trim(dep.govtID).replaceAll("-", "") === trim(values.govtID).replaceAll("-", "") && dep.dependentIdx !== values.dependentIdx) {
                    result[`dependents[${index}].govtID`] = props.getGlobalizedText("enrollment.depGovtID.dupOD");
                }
            });
        }
        return result;
    }

    const formValidate = (values: any): any => {
        let result: any = {};
        props.formValue.dependents.map((dependent: any, index: number) => {
            dependentValidate(dependent, index, result);
        })
        return result;
    }

    props.setValidation(formValidate);

    useEffect(() => {
        if (props.formValue === undefined || dateFormat == null) {
            let params = {
                transactionID: props.transactionID
            };
            ajax({
                url: '/api/enrollment/dependent/view',
                params: params,
                success: (res: any) => {
                    setDateFormat(res.dateFormat);
                    setCheckBoxMap(res.checkBoxMap);
                    setMemberGovtID(res.memberGovtID);
                    setUpdateInfo((res.securityMap.updateMemberDependentDemograpicInformation === null || res.securityMap.updateMemberDependentDemograpicInformation === undefined) ? true : res.securityMap.updateMemberDependentDemograpicInformation);
                    setDisplayStudentInformation(res.securityMap.displayStudentInformation);
                    if (res.dependents === null || res.dependents === undefined || res.dependents.length === 0) {
                        props.setFormValue({
                            dependents: []
                        });
                    } else {
                        props.setFormValue({
                            dependents: res.dependents
                        });
                        props.formValue.dependents.map((dependent: any, index: number) => {
                            controlRemoveCoverageBtn(index, dependent.benefitOutDateCheck);
                        })
                    }
                }
            });
        }
    }, [props.formValue])


    function addDependent() {
        let dependents = props.formValue.dependents;
        defaultInitialValues.dependentIdx = dependents.length + 1;
        defaultInitialValues.dependentSequenceNumber = dependents.length + 1;
        defaultInitialValues.dateFormat = dateFormat;
        dependents.push(defaultInitialValues);
        props.setFormValue(props.formValue);
    }

    function removeDependent(index: number) {
        let dependents = props.formValue.dependents;
        dependents = removeFromArray(dependents, index);
        props.formValue.dependents = dependents;
        props.setFormValue(props.formValue);
    }

    function controlRemoveCoverageBtn(index: number, value: any) {
        let dependents = props.formValue.dependents;
        dependents[index].benefitOutDateCheck = value;
        if (value != true) {
            //clear the benefitOutDate when unselect the benefitOutDate checkbox
            dependents[index].benefitOutDate = "";
        }
        props.formValue.dependents = dependents;
        props.setFormValue(props.formValue);
    }

    function removeCoverage(index: number) {
        let dependents = props.formValue.dependents;
        dependents[index].benefitOutDateCheck = true;
        props.formValue.dependents = dependents;
        props.setFormValue(props.formValue);
        document.getElementsByName("dependents[" + index + "].removeCoverageBtn")[0].style.display = "none";
        document.getElementsByName("dependents[" + index + "].removeCoverageBtnBottom")[0].style.display = "none";
    }

    props.setPrepareData((values: any) => {
        if (values.dependents !== null && values.dependents != undefined && values.dependents.length > 0) {
            return {
                "dependents": values.dependents
            }
        } else {
            return {
                "dependents": []
            }
        }
    });

    return <>
        <props.Panel>
            <props.PanelHeading>
                <RequiredHeader label="dependent_title" {...props} />
                <props.Button containerClassName="gwp-float-right" onClick={addDependent}><GlobalizedText message="dependent_add" /></props.Button>
            </props.PanelHeading>
            <props.PanelBody>
                <FieldArray name="dependents" render={arrayHelpers => {
                    if (props.formValue === undefined || props.formValue.length === 0 || dateFormat == null) {
                        return <></>;
                    }
                    return props.formValue.dependents.map((dependent: any, index: number) => {
                        return <React.Fragment key={index}>
                            <props.Row>
                                <props.Col xs="12" md="12" sm="12" className="hidden-lg">
                                    <props.Button containerClassName="gwp-float-right" id={`dependents[${index}].deleteDependentBtn`} name={`dependents[${index}].deleteDependentBtn`} condition={dependent.isLoadFromDB != 'Y'} onClick={() => { removeDependent(index) }}>&nbsp;<GlobalizedText message="dependent_delete" /></props.Button>
                                    <props.Button containerClassName="gwp-float-right" id={`dependents[${index}].removeCoverageBtn`} name={`dependents[${index}].removeCoverageBtn`} condition={!dependent.benefitOutDateCheck} onClick={() => { removeCoverage(index) }}><GlobalizedText message="dependent_remove_coverage" /></props.Button>
                                </props.Col>
                                <props.Col xs="12" md="6" sm="6">
                                    <props.TextControl arrayHelpers={arrayHelpers} id={`dependents[${index}].firstName`} name={`dependents[${index}].firstName`} required={true} disabled={dependent.isLoadFromDB == 'Y' && !updateInfo} label="common.lbl.firstName" />
                                    <props.TextControl arrayHelpers={arrayHelpers} id={`dependents[${index}].lastName`} name={`dependents[${index}].lastName`} required={true} disabled={dependent.isLoadFromDB == 'Y' && !updateInfo} label="common.lbl.lastName" />
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} id={`dependents[${index}].birthDate`} name={`dependents[${index}].birthDate`} required={true} disabled={dependent.isLoadFromDB == 'Y' && !updateInfo} label="common.lbl.dob" dateformat={dateFormat} max={new Date()} />

                                    <props.SelectControl arrayHelpers={arrayHelpers} id={`dependents[${index}].gender`} name={`dependents[${index}].gender`} required={true} disabled={dependent.isLoadFromDB == 'Y' && !updateInfo} label="pi.gender" sort={true} defaultOption="common.lbl.pleaseselect" options={
                                        (checkBoxMap == null || checkBoxMap.SEXCD.length === 0) ? checkBoxMap : checkBoxMap.SEXCD.map((record: any) => { return { name: record.name, value: record.value } })
                                    }></props.SelectControl>
                                    <props.SelectControl arrayHelpers={arrayHelpers} id={`dependents[${index}].relation`} name={`dependents[${index}].relation`} required={true} disabled={dependent.isLoadFromDB == 'Y' && !updateInfo} label="pi.relation" sort={true} defaultOption="common.lbl.pleaseselect" options={
                                        (checkBoxMap == null || checkBoxMap.DREL.length === 0) ? checkBoxMap : checkBoxMap.DREL.map((record: any) => { return { name: record.name, value: record.value } })
                                    }></props.SelectControl>

                                    <props.AutoFormatControl arrayHelpers={arrayHelpers} id={`dependents[${index}].govtID`} name={`dependents[${index}].govtID`} formatType="SSN" autoFormat={false} valueWithFormat={false} label="common.lbl.govID"></props.AutoFormatControl>
                                    <props.SelectControl arrayHelpers={arrayHelpers} id={`dependents[${index}].medicareIndicator`} name={`dependents[${index}].medicareIndicator`} label="medicareindicator" sort={true} defaultOption="common.lbl.pleaseselect" options={
                                        (checkBoxMap == null || checkBoxMap.MCIN.length === 0) ? checkBoxMap : checkBoxMap.MCIN.map((record: any) => { return { name: record.name, value: record.value } })
                                    }></props.SelectControl>
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} id={`dependents[${index}].medicareEffectiveDate`} name={`dependents[${index}].medicareEffectiveDate`} label="medicareeffdate" dateformat={dateFormat} />
                                </props.Col>
                                <props.Col xs="12" md="5" sm="5">
                                    <props.TextControl arrayHelpers={arrayHelpers} id={`dependents[${index}].employeeIDNumber`} name={`dependents[${index}].employeeIDNumber`} label="employeeidnumber" />
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} id={`dependents[${index}].inBenefitDate`} name={`dependents[${index}].inBenefitDate`} label="inbenebitdate" dateformat={dateFormat} />

                                    <props.CheckboxControl className="checkboxStyle" arrayHelpers={arrayHelpers} id={`dependents[${index}].benefitOutDateCheck`} name={`dependents[${index}].benefitOutDateCheck`} value={true} label="benefitoutdate" onChange={(e: any) => {
                                        controlRemoveCoverageBtn(index, e.target.checked)
                                    }} />
                                    <props.DateTimePickerControl arrayHelpers={arrayHelpers} id={`dependents[${index}].benefitOutDate`} name={`dependents[${index}].benefitOutDate`} disabled={!dependent.benefitOutDateCheck} dateformat={dateFormat} />

                                    <props.SelectControl arrayHelpers={arrayHelpers} id={`dependents[${index}].smoker`} name={`dependents[${index}].smoker`} label="smoker" sort={true} defaultOption="common.lbl.pleaseselect" options={
                                        (checkBoxMap == null || checkBoxMap.SMKR.length === 0) ? checkBoxMap : checkBoxMap.SMKR.map((record: any) => { return { name: record.name, value: record.value } })
                                    }></props.SelectControl>

                                    <props.CheckboxControl className="checkboxStyle" arrayHelpers={arrayHelpers} id={`dependents[${index}].hasOtherInsCheck`} name={`dependents[${index}].hasOtherInsCheck`} value={true} label="lb_otherinscheck" />
                                    <props.Condition condition={displayStudentInformation}>
                                        <props.Label><GlobalizedText message="lb_studenttext" /></props.Label>
                                        <props.DateTimePickerControl arrayHelpers={arrayHelpers} id={`dependents[${index}].effectiveDate`} name={`dependents[${index}].effectiveDate`} required={dependent.isStudent == 'Y'} label="common.lbl.effectiveDate" dateformat={dateFormat} />
                                        <props.DateTimePickerControl arrayHelpers={arrayHelpers} id={`dependents[${index}].terminationDate`} name={`dependents[${index}].terminationDate`} label="common.lbl.terminationDate" dateformat={dateFormat} />
                                    </props.Condition>
                                </props.Col>
                                <props.Col xs="12" md="1" sm="1" className="hidden-md hidden-sm hidden-xs">
                                    <props.Button containerClassName="gwp-float-right" condition={dependent.isLoadFromDB != 'Y'} id={`dependents[${index}].deleteDependentBtnBottom`} name={`dependents[${index}].deleteDependentBtnBottom`} onClick={() => { removeDependent(index) }}>&nbsp;<GlobalizedText message="dependent_delete" /></props.Button>
                                    <props.Button containerClassName="gwp-float-right gwp-btn-plus" condition={!dependent.benefitOutDateCheck} id={`dependents[${index}].removeCoverageBtnBottom`} name={`dependents[${index}].removeCoverageBtnBottom`} onClick={() => { removeCoverage(index) }}><GlobalizedText message="dependent_remove_coverage" /></props.Button>
                                </props.Col>
                            </props.Row>
                            <props.HR />
                        </React.Fragment>
                    })
                }
                } />

            </props.PanelBody>
        </props.Panel>
    </>
}