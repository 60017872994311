import React, { useEffect, useRef, useState } from 'react';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import { Formik, Form } from 'formik';
import './css/memberlist.css';
import { ajax, hideElement, isEmptyStr, removeLeftZeroForNumber, showElement, trim, validate } from '../shared/utils';
import { useTable } from '../shared/components';
import { YupSchema } from '../shared/yupschema';
import * as yup from 'yup';

interface SelectOptionType {
    name: string,
    value: string
}

interface RequestBoday4FetchMembers {
    companyCode: string,
    groupNumber: string,
    accountNumber: string,
    agentNumber: string,
    status: string,
    memberNameOrID: Array<any>
}

export const MemberListComponent = withView((props: ViewComponentProps) => {

    const companyCodePre = props.getQueryParam('companyCode')
    const groupNumberPre = props.getQueryParam('groupNumber')
    const accountNumberPre = props.getQueryParam('accountNumber')
    const agentNumberPre = props.getQueryParam('agentNumber')

    const [memberListTable4IB, setMemberListTable4IB] = useState<any | null>(null);
    const [memberListTable4GRA, setMemberListTable4GRA] = useState<any | null>(null);
    const [canAccessCS, setCanAccessCS] = useState<boolean>(false);
    const [groupList, setGroupList] = useState<Array<SelectOptionType>>([{ name: "common.lbl.pleaseselect", value: "" }]);
    const [accountList, setAccountList] = useState<Array<SelectOptionType>>([{ name: "common.lbl.pleaseselect", value: "" }]);
    const [statusList, setStatusList] = useState<any | null>(null);
    const [config, setConfig] = useState<any | null>(null);

    const comboxData = useRef<Array<any>>([]);
    const memberListForm = useRef<any>();
    let initParams = props.getInitParams();

    // save data for current page
    function saveDataBeforeRedirect() {
        props.setInitParams({
            storedPage: 'memberList',
            memberListTable4IB: memberListTable4IB,
            memberListTable4GRA: memberListTable4GRA,
            canAccessCS: canAccessCS,
            groupList: groupList,
            accountList: accountList,
            statusList: statusList,
            config: config,
            comboxData: comboxData.current,
            ...memberListForm.current.values
        })
    }

    useEffect(() => {
        if (config === null) {
            if (initParams !== undefined && 'memberList' === initParams.storedPage) {
                setConfig(initParams.config)
                setMemberListTable4GRA(initParams.memberListTable4GRA)
                setMemberListTable4IB(initParams.memberListTable4IB)
                setCanAccessCS(initParams.canAccessCS)
                setGroupList(initParams.groupList)
                setAccountList(initParams.accountList)
                setStatusList(initParams.statusList)
                comboxData.current = initParams.comboxData
                if (agentNumberPre === undefined) {
                    memberListForm.current.setFieldValue('groupSelect', initParams.groupSelect)
                    memberListForm.current.setFieldValue('accountSelect', initParams.accountSelect)
                    memberListForm.current.setFieldValue('status', initParams.status)
                } else {
                    memberListForm.current.setFieldValue('memberName', initParams.memberName)
                    memberListTableObj.filter(1, trim(initParams.memberName), true, false);
                }
            } else {
                if (agentNumberPre !== undefined) {
                    ajax({
                        url: '/api/memberList/view',
                        params: {
                            companyCode: companyCodePre !== undefined ? companyCodePre : '',
                            groupNumber: groupNumberPre !== undefined ? groupNumberPre : '',
                            accountNumber: accountNumberPre !== undefined ? accountNumberPre : '',
                            agentNumber: agentNumberPre !== undefined ? agentNumberPre : ''
                        },
                        success: res => {
                            setConfig(res);
                            setGroupList(res.groups)
                            setAccountList(res.accounts)
                            setStatusList(res.statuses)
                            if (res.memberNameOrIDList.length > 0) {
                                for (let rowData of res.memberNameOrIDList) {
                                    comboxData.current.push(rowData.name)
                                }
                            }
                            fetchAccountsIfOnlyOneGroup(res.groups);
                            fetchAllMembersFromInforce()
                        }
                    });
                } else {
                    ajax({
                        url: '/api/memberList/view',
                        success: res => {
                            setConfig(res);
                            setGroupList(res.groups)
                            // setAccountList(res.accounts)
                            setStatusList(res.statuses);
                            fetchAccountsIfOnlyOneGroup(res.groups);
                        }
                    });
                }
            }

        }
    }, [config])

    const defaultInitialValues = {
        groupSelect: '',
        accountSelect: '',
        status: '',
        memberName: ''
    }

    function fetchAccounts(companyCode: any, groupNumber: any) {
        ajax({
            url: '/api/memberList/fetchAccounts',
            params: {
                companyCode: companyCode,
                groupNumber: groupNumber
            },
            success: res => {
                let tmpAccounts: Array<SelectOptionType> = []
                res.forEach((row: any) => {
                    let tmp: SelectOptionType = {
                        name: row.displayValue,
                        value: row.value
                    }
                    tmpAccounts.push(tmp)
                })
                setAccountList(tmpAccounts)
                if (res[0].value === "-1") {
                    fetchStatus(res[0].value)
                } else {
                    memberListForm.current.setFieldValue('accountSelect', res[0].value)
                    memberListForm.current.setFieldValue('status', '')
                    setStatusList(config.statuses)
                }
            }
        })
    }

    function fetchStatus(accountNumber: any) {
        ajax({
            url: '/api/memberList/fetchStatuses',
            params: { accountNumber: accountNumber },
            success: res => {
                let tmpStatus: Array<SelectOptionType> = []
                res.forEach((row: any) => {
                    let tmp: SelectOptionType = {
                        name: row.name,
                        value: row.value
                    }
                    tmpStatus.push(tmp)
                })
                setStatusList(tmpStatus)
                if (res.length === 1) {
                    memberListForm.current.setFieldValue('status', res[0].value)
                }
            }
        })
    }

    const groupChangeHandler = (value: any, setFieldValue: (fieldName: string, value: any) => void) => {
        if (value === "0") {
            setAccountList([{ name: "common.lbl.pleaseselect", value: "" }]);
            setStatusList(config.statuses)
            memberListForm.current.setFieldValue('status', '')
        } else {
            let tmpCompanyCode = value.split("|")[0]
            let tmpGroupNumber = value.split("|")[1]
            fetchAccounts(tmpCompanyCode, tmpGroupNumber)
        }
    };
    const accountChangeHandler = (value: any, setFieldValue: (fieldName: string, value: any) => void) => {
        fetchStatus(value)
    };

    function fetchAllMembersFromGA(values: any) {
        let tmpCompanyCode = values.groupSelect.split("|")[0]
        let tmpGroupNumber = values.groupSelect.split("|")[1]
        ajax({
            url: '/api/memberList/fetchAllMembers',
            params: {
                companyCode: tmpCompanyCode,
                groupNumber: tmpGroupNumber,
                accountNumber: values.accountSelect,
                status: values.status
            },
            success: (res: any) => {
                setCanAccessCS(res.canAccessCS)
                setMemberListTable4GRA(res.memberList)
            }
        })
    }

    function fetchAllMembersFromInforce(memberNameOrID?: any) {
        ajax({
            url: '/api/memberList/fetchAllMembers',
            params: {
                companyCode: companyCodePre !== undefined ? companyCodePre : '',
                groupNumber: groupNumberPre !== undefined ? groupNumberPre : '',
                accountNumber: accountNumberPre !== undefined ? accountNumberPre : '',
                agentNumber: agentNumberPre !== undefined ? agentNumberPre : ''
            },
            success: res => {
                if (isEmptyStr(memberNameOrID)) {
                    setCanAccessCS(res.canAccessCS)
                    setMemberListTable4IB(res.memberList)
                } else {
                    let tmpArr: Array<any> = []
                    for (let rowData of res.memberList) {
                        if (rowData.memberID === memberNameOrID) {
                            tmpArr.push(rowData)
                            continue
                        }
                        if (rowData.memberName.toLowerCase().indexOf(memberNameOrID.toLowerCase()) !== -1) {
                            tmpArr.push(rowData)
                        }
                    }
                    setMemberListTable4IB(tmpArr)
                }
            }
        })
    }

    const tableOption4GPA = {
        paging: true,
        scrollCollapse: true,
        order: [[1, 'asc']],
        "dom": "<l>t<'at-download'B><'row'<'col-xs-12 col-sm-5'i><'col-xs-12 col-sm-7 pull-right'p>>",
        "buttons": [
            {
                extend: 'csvHtml5',
                text: "Download",
                className: 'btn btn-primary gwp-btn',
                filename: 'member_list',
                exportOptions: {
                    modifier: {
                        search: 'none'
                    },
                    columns: ':visible:not(.noExport)'
                }
            }
        ],
        "preDrawCallback": function (settings: any) {
            if (settings.aoData.length == 0) {
                hideElement("div.at-download")
            } else {
                showElement("div.at-download")
            }
        }
    }
    const tableOption4IB = {
        paging: true,
        scrollCollapse: true,
        order: [[1, 'asc']]
    }

    const memberListTableObj = useTable({
        id: "memberListTable",
        option: agentNumberPre === undefined ? tableOption4GPA : tableOption4IB,
        next: props.next,
        dateformat: props.getDateFormat(),
        table: {
            columns: [
                { sortable: false, "className": "gwp-dt-detail-control" },
                {
                    title: "common.lbl.memberName", name: "memberName", sortable: true,
                    render: (data: any, type: any, row: any, meta: any) => {
                        if (canAccessCS === true) {
                            return `<a id='memberName${meta.row}'>${row.memberName}</a>`
                        } else {
                            return row.memberName
                        }
                    },
                    onClick: (e: any, row: any) => {
                        if (canAccessCS === true) {
                            saveDataBeforeRedirect();
                            let companyCode = trim(row.companyCode)
                            let groupNumber = trim(row.groupName.split("-")[0])
                            let accountNumber = trim(row.accountName.split("-")[0])
                            let participantIDNumber = trim(row.memberID)
                            props.next(`/coverageSummary?companyCode=${companyCode}&groupNumber=${groupNumber}&accountNumber=${accountNumber}&participantIDNumber=${participantIDNumber}`)
                        }
                    }
                },
                { title: "common.lbl.dob", name: "dateOfBirth", sortable: true, type: 'date' },
                {
                    title: "common.lbl.group", name: "groupName", sortable: true, render: (data: any, type: any, row: any, meta: any) => {
                        let tmpGroupNumber = trim(row.groupName.split("-")[0])
                        let tmpGroupName = trim(row.groupName.split("-")[1])
                        return removeLeftZeroForNumber(tmpGroupNumber) + " - " + tmpGroupName
                    }
                },
                { title: "common.lbl.account", name: "accountName", sortable: true },
                { title: "common.lbl.memberID", name: "memberID", sortable: true },
                { title: "common.lbl.identificationNumber", name: "idNum", sortable: true },
                { title: "benefit.certificate.number", name: "certiNum", sortable: true },
                { title: "common.lbl.govID", name: "govtID", sortable: true },
                { title: "common.lbl.paidToDate", name: "paidToDate", sortable: true, type: 'date' },
            ], data: agentNumberPre === undefined ? memberListTable4GRA : memberListTable4IB
        }
    })

    const byGRASchema = yup.object().shape({
        groupSelect: YupSchema.memberList.groupSelect,
        accountSelect: YupSchema.memberList.accountSelect
    })

    const formValidate = (values: any): any => {
        if (agentNumberPre === undefined) {
            return validate(byGRASchema, values, props);
        }
    }

    const formSubmit = (values: any): void => {
        if (agentNumberPre !== undefined) {
            fetchAllMembersFromInforce(values.memberName)
        } else {
            fetchAllMembersFromGA(values)
        }
    }
    const fetchAccountsIfOnlyOneGroup = (groups: any): void => {
        if (groups !== undefined && groups.length === 1 && trim(groups[0].value) !== "0" && trim(groups[0].value) !== "") {
            try {
                let companyCode: string = trim(groups[0].value.split("|")[0]);
                let groupNumber: string = trim(groups[0].value.split("|")[1]);
                if (!isEmptyStr(companyCode) && !isEmptyStr(groupNumber)) {
                    fetchAccounts(companyCode, groupNumber);
                }
                memberListForm.current.setFieldValue('groupSelect', groups[0].value);
            } catch (e) {

            }
        }
    }
    return <>
        <Formik initialValues={defaultInitialValues}
            enableReinitialize={true}
            validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}>
            {formProps => {
                memberListForm.current = formProps
                return <Form id="memberListForms">
                    {/* From Group Admin */}
                    <props.Row condition={agentNumberPre === undefined}>
                        <props.Col xs="12" sm="6" md="4">
                            <props.SelectControl id="groupList" name="groupSelect" label="common.lbl.group" required={true} options={groupList} sort={false} onChange={(e: any) => {
                                groupChangeHandler(e.target.value, formProps.setFieldValue)
                            }}></props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="6" md="4">
                            <props.SelectControl id="accountList" name="accountSelect" label="common.lbl.account" defaultOption={"common.lbl.pleaseselect"} required={true} options={accountList} sort={false} onChange={(e: any) => {
                                memberListForm.current.setFieldValue("status", "");
                                accountChangeHandler(e.target.value, formProps.setFieldValue)
                            }}></props.SelectControl>
                        </props.Col>
                    </props.Row>
                    <props.Row condition={agentNumberPre === undefined}>
                        <props.Col xs="12" sm="6" md="4">
                            <props.SelectControl id="statusList" name="status" label="common.lbl.status" defaultOption={""} required={false} options={statusList} sort={false}></props.SelectControl>
                        </props.Col>
                        <props.Col xs="12" sm="6" md="4">
                            <props.Button type="submit" className="btnMargin" style={{ marginTop: "10px" }} withoutContainer={true} ><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                    </props.Row>
                    {/* From InforceBusiness */}
                    <props.Row condition={agentNumberPre !== undefined}>
                        <props.Col xs="12" sm="6" md="4">
                            <props.ComboboxControl name="memberName" data={comboxData.current} label="common.lbl.member" placeholder="memlist.enter.member.name"></props.ComboboxControl>
                        </props.Col>
                        <props.Col xs="12" sm="6" md="4">
                            <props.Button type="submit" className="btnMargin" style={{ marginTop: "10px" }} withoutContainer={true} ><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }}
        </Formik >
        {/* Member list table */}
        <props.Row>
            <props.Col xs="12" sm="12" md="12">
                <memberListTableObj.Component />
            </props.Col>
        </props.Row>
    </>
});