import React, { useState, useEffect, useRef } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { YupSchema } from '../shared/yupschema';
import { ajax, validate, parseDate, convertDate, removeLeftZero } from '../shared/utils';
import './css/updatepayrollschedule.css';
import GlobalizedText from '../shared/globalization';

export const UpdatePayrollScheduleComponent = withView((props: ViewComponentProps) => {

    let passedTransactionNumber = props.getQueryParam("transactionNumber");
    let passedParticipantIDNumber = props.getQueryParam("participantIDNumber");
    let passedCompanyCode = props.getQueryParam("companyCode");
    let passedGroupNumber = props.getQueryParam("groupNumber");
    let passedAccountNumber = props.getQueryParam("accountNumber");

    const varFormPromp = useRef<any>();
    const [config, setConfig] = useState<any | null>(null);

    const [submitResult, setSubmitResult] = useState<any | null>(null);

    const [payrollScheduleInfo, setPayrollScheduleInfo] = useState<any | null>(null);
    const [maskedgovid, setMaskedgovid] = useState<any>("");
    const [previousPayrollDate, setPreviousPayrollDate] = useState<any>("");

    const [accounts, setAccounts] = useState<Array<any>>([{ name: "common.lbl.pleaseselect", value: "0" }]);
    const [payrolls, setPayrolls] = useState<Array<any>>([{ name: "common.lbl.pleaseselect", value: "0" }]);

    const [showPayrollDef, setShowPayrollDef] = useState<boolean>(false);

    const [disableGroup, setDisableGroup] = useState<boolean>(false);
    const [disableAccount, setDisableAccount] = useState<boolean>(false);
    const [disableMemberID, setDisableMemberID] = useState<boolean>(true);
    const [disablePayrolls, setDisablePayrolls] = useState<boolean>(true);
    const [disableSaveBtn, setDisableSaveBtn] = useState<boolean>(true);
    const [noAccountFlag, setNoAccountFlag] = useState<boolean>(false);
    const [clearAccountFlag, setClearAccountFlag] = useState<boolean>(false);

    const [clickRetrieveFlag, setClickRetrieveFlag] = useState<boolean>(true);
    const [refreshAfterClose, setRefreshAfterClose] = useState<boolean>(false);

    const [showUpdatePayrollSubmit, setShowUpdatePayrollSubmit] = useState<boolean>(false);

    const [minDate, setMinDate] = useState<any>("");
    const [maxDate, setMaxDate] = useState<any>("");

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/updateMemberPayroll/view',
                params: {
                    transactionNumber: passedTransactionNumber !== undefined ? passedTransactionNumber : '',
                    participantIDNumber: passedParticipantIDNumber !== undefined ? passedParticipantIDNumber : '',
                    companyCode: passedCompanyCode !== undefined ? passedCompanyCode : '',
                    groupNumber: passedGroupNumber !== undefined ? passedGroupNumber : '',
                    accountNumber: passedAccountNumber !== undefined ? passedAccountNumber : '',
                    fromPage: passedTransactionNumber !== undefined ? 'transaction list' : ''
                },

                success: (res: any) => {
                    setConfig(res);

                    setMinDate(new Date(new Date(new Date().toLocaleDateString()).getTime()));

                    let availableDate = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
                    availableDate = new Date(availableDate.setMonth(availableDate.getMonth() + Number(res.payrollSchedulePeriod !== undefined ? res.payrollSchedulePeriod : 0)));
                    setMaxDate(availableDate);

                    if (passedTransactionNumber !== undefined && refreshAfterClose === false) {
                        varFormPromp.current.setFieldValue("group", passedCompanyCode + '|' + res.groupNumber);

                        let newAccountList = res.accountList.map((account: any) => { return { ...account, name: account.displayValue } });
                        setAccounts(newAccountList);
                        varFormPromp.current.setFieldValue("account", passedAccountNumber);

                        varFormPromp.current.setFieldValue("memberID", passedParticipantIDNumber);

                        varFormPromp.current.setFieldValue("effectiveDate", res.initFuturePayrollScheduleDate);
                        setPreviousPayrollDate(res.initFuturePayrollScheduleDate);
                        setDisablePayrolls(false);

                        varFormPromp.current.setFieldValue("payroll", res.selPayroll);

                        varFormPromp.current.submitForm();
                    } else {
                        if (res.groupList.length > 1) {
                            varFormPromp.current.setFieldValue("group", "0");
                        } else {
                            varFormPromp.current.setFieldValue("group", res.groupList[0].value);
                            retrieveAccountList(res.groupList[0].value);
                        }

                        if (res.initFuturePayrollScheduleDate === undefined || res.initFuturePayrollScheduleDate === '') {
                            setDisablePayrolls(true);
                        } else {
                            varFormPromp.current.setFieldValue("effectiveDate", res.initFuturePayrollScheduleDate);
                            setPreviousPayrollDate(res.initFuturePayrollScheduleDate);
                            setDisablePayrolls(false);
                        }
                    }

                }
            });
            return () => {
            }
        }
    }, [config]);

    const formSubmit = (values: any): void => {
        props.clearMessage();
        if (clearAccountFlag === true) {
            setNoAccountFlag(false);
            setClearAccountFlag(false);
            return;
        }

        if (showUpdatePayrollSubmit) {
            let tmpGroupNumber = "";
            let tmpCompanyCode = "";
            let companyAndGroupNumber = varFormPromp.current.values.group;
            if (companyAndGroupNumber !== '0') {
                let tmp = companyAndGroupNumber.split("|");
                tmpGroupNumber = tmp[1];
                tmpCompanyCode = tmp[0];
            }
            ajax({
                url: '/api/updateMemberPayroll/submitPayrollSchedule',
                method: 'post',
                params: {
                    companyCode: tmpCompanyCode,
                    participantIDNumber: varFormPromp.current.values.memberID,
                    groupNumber: tmpGroupNumber,
                    accountNumber: varFormPromp.current.values.account,
                    fullName: payrollScheduleInfo.name,
                    currentTransactionId: config.currentTransactionId !== undefined ? config.currentTransactionId : '',
                    issuehiredate: payrollScheduleInfo.accountEffectiveDate,
                    futurePayrollEffectiveDate: varFormPromp.current.values.effectiveDate,
                    payrollIdentifier: varFormPromp.current.values.payroll
                },
                success: (result: any) => {
                    setSubmitResult(result);
                    props.showModal('#submitDialog');
                }
            });
        } else {
            showPayrollSchedule();
        }
    }

    const successTitle = (messageKey: string) => {
        return <>
            <span className="glyphicon glyphicon-ok" style={{ fontSize: '18px' }}></span>&nbsp;
            <GlobalizedText message={messageKey} />
        </>
    }

    const noAccountSchema = yup.object().shape({
        group: yup.string().trim()
            .test("group", "common.lbl.no.worksite.account", (v) => {
                if (accounts.length < 2) {
                    return false;
                }
                return true;
            }).required("addmember.group.required").notOneOf(["0"], "addmember.group.required")
    });

    const noPayrollSchema = yup.object().shape({
        account: YupSchema.updatePayrollSchedule.account,
        memberID: YupSchema.updatePayrollSchedule.memberID,
        group: yup.string().trim()
            .test("group", "common.lbl.no.worksite.account", (v) => {
                if (accounts.length < 2) {
                    return false;
                }
                return true;
            }).required("addmember.group.required").notOneOf(["0"], "addmember.group.required")
    });

    const nextPayrollDateSchema = yup.object().shape({
        effectiveDate: yup.string().trim()
            .test("effectiveDate", "common.lbl.lessthan.currentdate", (v) => {
                let currentDate = new Date(new Date(new Date().toLocaleDateString()).getTime());
                if (v !== undefined && v !== null && new Date(v).getTime() < currentDate.getTime()) {
                    return false;
                }
                return true;
            }).test("effectiveDate", "common.lbl.lessthan.memberaccounteffectivedate", (v) => {
                if (v !== undefined && v !== null &&
                    payrollScheduleInfo.accountEffectiveDate !== undefined && payrollScheduleInfo.accountEffectiveDate !== null &&
                    new Date(v).getTime() < new Date(payrollScheduleInfo.accountEffectiveDate).getTime()) {
                    return false;
                }
                return true;
            }).test("effectiveDate", "common.lbl.morethan.memberaccountterminationdate", (v) => {
                if (v !== undefined && v !== null &&
                    payrollScheduleInfo.accountTerminationDate !== undefined && payrollScheduleInfo.accountTerminationDate !== null &&
                    new Date(v).getTime() > new Date(payrollScheduleInfo.accountTerminationDate).getTime()) {
                    return false;
                }
                return true;
            }).test("effectiveDate", "common.lbl.lessthan.billedtoDate", (v) => {
                if (v !== undefined && v !== null &&
                    payrollScheduleInfo.billingUnitPaidDate !== undefined && payrollScheduleInfo.billingUnitPaidDate !== null &&
                    new Date(v).getTime() < new Date(payrollScheduleInfo.billingUnitPaidDate).getTime()) {
                    return false;
                }
                return true;
            }).test("effectiveDate", "common.lbl.lessthan.billedtoDate", (v) => {
                if (v !== undefined && v !== null &&
                    payrollScheduleInfo.coveragePaidDate !== undefined && payrollScheduleInfo.coveragePaidDate !== null &&
                    new Date(v).getTime() < new Date(payrollScheduleInfo.coveragePaidDate).getTime()) {
                    return false;
                }
                return true;
            }).test("effectiveDate", "common.lbl.morethan.availabledate", (v) => {
                let availableDate = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
                availableDate = new Date(availableDate.setMonth(availableDate.getMonth() + Number((config !== null && config.payrollSchedulePeriod !== undefined) ? config.payrollSchedulePeriod : 0)));
                if (v !== undefined && v !== null && new Date(v).getTime() > availableDate.getTime()) {
                    return false;
                }
                return true;
            }).required("common.msg.invalidDate"),
        payroll: yup.string().trim()
            .test("payroll", "payroll.futurePayrollSchedule.equal", (v) => {
                if (v !== undefined && v !== null && v.trim() === payrollScheduleInfo.futurePayrollSchedule.trim()) {
                    return false
                }
                return true;
            }).test("payroll", "payroll.currentPayrollSchedule.equal", (v) => {
                if (v !== undefined && v !== null && v.trim() === payrollScheduleInfo.currentPayrollScheduleIdentifier.trim()) {
                    return false
                }
                return true;
            }).required("payroll.futurePayrollSchedule.required").notOneOf(["0"], "payroll.futurePayrollSchedule.required")
    });

    // define Form Validate function.
    const formValidate = (values: any): any => {
        if (noAccountFlag === true && clickRetrieveFlag !== true) {
            return validate(noAccountSchema, values, props);
        }
        if (showUpdatePayrollSubmit) {
            return validate(nextPayrollDateSchema, values, props);
        } else {
            return validate(noPayrollSchema, values, props);
        }
    }
    const dateChangeHandler = (passedDate: Date) => {
        let passedPayroll = varFormPromp.current.values.payroll;
        submitChanging(passedPayroll, passedDate);

        setDisablePayrolls(false);
    }

    const payrollChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let passedPayroll = e.target.value;
        let passedDate = parseDate(varFormPromp.current.values.effectiveDate, config.dateFormat);

        submitChanging(passedPayroll, passedDate);
    }

    function submitChanging(passedPayroll: any, passedDate: any) {
        let previousPayroll = parseDate(previousPayrollDate, config.dateFormat);
        if (config !== null && passedDate !== null && passedDate !== undefined
            && previousPayrollDate !== '' && previousPayroll !== undefined
            && passedDate.toDateString() !== previousPayroll.toDateString()) {
            setDisableSaveBtn(false);
        } else {
            if (payrollScheduleInfo !== null && passedPayroll.trim() === payrollScheduleInfo.futurePayrollSchedule.trim()) {
                setDisableSaveBtn(true);
            } else {
                setDisableSaveBtn(false);
            }
        }
    }

    function checkBrowser(): boolean {
        if (navigator.userAgent.indexOf('Firefox') >= 0) {
            return true;
        }
        return false;
    }

    const groupChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target.value;
        if (companyAndGroupNumber === '0') {
            setAccounts([{ "name": "common.lbl.pleaseselect", "value": "0" }]);
        } else {
            retrieveAccountList(companyAndGroupNumber);
        }
    }

    function retrieveAccountList(companyAndGroupNumber: any) {
        let tmp = companyAndGroupNumber.split("|");
        ajax({
            url: '/api/updateMemberPayroll/getAccountList',
            params: {
                companyCode: tmp[0],
                groupNumber: tmp[1]
            },
            success: (res) => {
                let tmpRow: any[] = [{ name: "common.lbl.pleaseselect", value: "0" }];
                for (let i = 0; i < res.length; i++) {
                    if (res[i].accountType === 'W') {
                        tmpRow.push(res[i]);
                    }
                }

                setAccounts(tmpRow.map((account: any) => { return { ...account, name: account.displayValue } }));
                varFormPromp.current.setFieldValue("account", res[0].value);
                if (tmpRow.length > 1) {
                    setDisableMemberID(false);
                    if (noAccountFlag === true) {
                        setClearAccountFlag(true);
                        varFormPromp.current.submitForm();
                    }
                } else {
                    setNoAccountFlag(true);
                    setClickRetrieveFlag(false);
                    varFormPromp.current.submitForm();
                }
            }
        });
    }

    function discard() {
        props.clearMessage();

        setShowPayrollDef(false);
        setDisableGroup(false);
        setDisableAccount(false);
        setDisableMemberID(true);
        setDisablePayrolls(true);
        setShowUpdatePayrollSubmit(false);
        setDisableSaveBtn(true);

        setMaskedgovid("");
        setPayrollScheduleInfo("");

        varFormPromp.current.setFieldValue("memberID", '');

        setAccounts([{ name: "common.lbl.pleaseselect", value: "0" }]);
        setPayrolls([{ name: "common.lbl.pleaseselect", value: "0" }]);

        setRefreshAfterClose(true);

        setConfig(null);
    }

    function showPayrollSchedule() {
        props.clearMessage();
        let tmpGroupNumber = "";
        let tmpCompanyCode = "";
        let companyAndGroupNumber = varFormPromp.current.values.group;
        if (companyAndGroupNumber !== '0') {
            let tmp = companyAndGroupNumber.split("|");
            tmpGroupNumber = tmp[1];
            tmpCompanyCode = tmp[0];
        }
        ajax({
            url: '/api/updateMemberPayroll/getPayrollScheduleInfo',
            params: {
                companyCode: tmpCompanyCode,
                groupNumber: tmpGroupNumber,
                accountNumber: varFormPromp.current.values.account,
                participantIDNumber: varFormPromp.current.values.memberID
            },
            success: (res: any) => {
                setPayrollScheduleInfo(res);

                getPayrollScheduleList(tmpCompanyCode, tmpGroupNumber, config.initFuturePayrollScheduleDate, res);

                if (res.accountEffectiveDate !== undefined && res.accountEffectiveDate !== '') {
                    let tmpMaskedgovid = '';
                    if (res.governmentIdentifier !== undefined && res.governmentIdentifier !== '') {
                        if (config.maskGovernmentId === true) {
                            for (let i = 0; i < res.governmentIdentifier.length; i++) {
                                let ch = res.governmentIdentifier.charAt(i);
                                if (ch == '-' || i >= res.governmentIdentifier.length - 4) {
                                    tmpMaskedgovid += ch;
                                } else {
                                    tmpMaskedgovid += '*';
                                }
                            }
                        } else {
                            tmpMaskedgovid = res.governmentIdentifier;
                        }
                        setMaskedgovid(tmpMaskedgovid);
                    }

                    //enable buttons
                    setShowPayrollDef(true);
                    setShowUpdatePayrollSubmit(true);

                    //disable fields
                    setDisableGroup(true);
                    setDisableAccount(true);
                    setDisableMemberID(true);

                    if (config.fromPage !== 'transaction list') {
                        varFormPromp.current.setFieldValue("effectiveDate", res.futurePayrollScheduleDate);
                        setPreviousPayrollDate(res.futurePayrollScheduleDate);
                    }
                } else {
                    props.showMessage('error', 'common.lbl.norecordsfound.error');
                }
            }
        });
    }

    function getPayrollScheduleList(tmpCompanyCode: any, tmpGroupNumber: any, tmpFuturePayrollEffectiveDate: any, tmpPayrollScheduleInfo: any) {
        ajax({
            url: '/api/updateMemberPayroll/getPayrollScheduleList',
            params: {
                companyCode: tmpCompanyCode,
                groupNumber: tmpGroupNumber,
                accountNumber: varFormPromp.current.values.account,
                participantIDNumber: varFormPromp.current.values.memberID,
                futurePayrollEffectiveDate: (tmpFuturePayrollEffectiveDate !== undefined && tmpFuturePayrollEffectiveDate !== '') ? convertDate(tmpFuturePayrollEffectiveDate, config.dateFormat, 'yyyyMMdd') : 0
            },
            success: (result: any) => {
                setPayrolls(result.map((payroll: any) => { return { ...payroll, name: payroll.payrollDescription, value: payroll.payrollIdentifier.toString().trim() } }));
                if (tmpPayrollScheduleInfo !== null &&
                    tmpPayrollScheduleInfo.accountEffectiveDate !== undefined && tmpPayrollScheduleInfo.accountEffectiveDate !== '') {
                    if (config.fromPage !== 'transaction list') {
                        if (tmpPayrollScheduleInfo.futurePayrollSchedule !== undefined && tmpPayrollScheduleInfo.futurePayrollSchedule.toString().trim() !== '') {
                            varFormPromp.current.setFieldValue("payroll", tmpPayrollScheduleInfo.futurePayrollSchedule.toString().trim());
                            setDisablePayrolls(false);
                        }
                    }
                }
            }
        });
    }

    // define Form Validate function.
    const closeSubmitDialog = (): any => {
        props.closeModal('#submitDialog');

        setShowPayrollDef(false);
        setDisableGroup(false);
        setDisableAccount(false);
        setDisableMemberID(true);
        setDisablePayrolls(true);
        setShowUpdatePayrollSubmit(false);
        setDisableSaveBtn(true);

        setMaskedgovid("");
        setPayrollScheduleInfo("");

        varFormPromp.current.setFieldValue("memberID", '');

        setAccounts([{ name: "common.lbl.pleaseselect", value: "0" }]);
        setPayrolls([{ name: "common.lbl.pleaseselect", value: "0" }]);

        setRefreshAfterClose(true);

        setConfig(null);
    }

    return <>
        <Formik initialValues={{
            group: '',
            account: '',
            memberID: '',
            effectiveDate: '',
            payroll: ''
        }}
            validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps => {
                varFormPromp.current = formProps;
                return <Form id="updatepayrollschedule">
                    <props.Row>
                        <props.Col md="6" sm="6" xs="12">
                            <props.SelectControl name="group" id="group" required={true} label="common.lbl.group" disabled={disableGroup}
                                onChange={(e: any) => { groupChangeHandler(e, formProps.values, formProps.setFieldValue) }} sort={false}
                                options={config === null ? [{ name: "common.lbl.pleaseselect", value: "0" }] : config.groupList}>
                            </props.SelectControl>
                            <props.SelectControl name="account" id="account" required={true} label="common.lbl.account"
                                disabled={disableAccount} sort={false} options={accounts} >
                            </props.SelectControl>
                        </props.Col>
                        <props.Col md="6" sm="6" xs="12">
                            <props.TextControl name="memberID" id="memberID" required={true} disabled={disableMemberID} label="common.lbl.memberID" maxLength={10} ></props.TextControl>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col md="6" sm="6" xs="12">
                            <props.Button className='nextButton' id="nextSubmit" condition={showPayrollDef === false} type="submit" onClick={() => { setClickRetrieveFlag(true) }}>
                                <GlobalizedText message="common.button.next" />
                            </props.Button>
                        </props.Col>
                    </props.Row>
                    <props.Div>&nbsp;</props.Div>
                    <props.Div id='payrollScheduleDiv' className="panel panel-default" condition={showPayrollDef === true}>
                        <props.Div className="panel-heading">
                            <span><GlobalizedText message="common.lbl.payrolldefinition" /></span>
                        </props.Div>
                        <props.Div className="panel-body left-margin-4-spad">
                            <props.Row>
                                <props.Col md="7" sm="12" xs="12">
                                    <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                                        <props.DLI title="common.lbl.name" desc={payrollScheduleInfo !== null ? payrollScheduleInfo.name : ''}></props.DLI>
                                        <props.DLI title="common.lbl.govID" desc={maskedgovid}></props.DLI>
                                        <props.DLI title="common.lbl.accounteffectivedate" desc={payrollScheduleInfo !== null ? payrollScheduleInfo.accountEffectiveDate : ''}></props.DLI>
                                        <props.DLI title="common.lbl.currentpayrollschedule" desc={payrollScheduleInfo !== null ? payrollScheduleInfo.currentPayrollSchedule : ''}></props.DLI>
                                    </props.DL>
                                </props.Col>
                                <props.Col md="5" sm="12" xs="12">
                                    <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                                        <props.DLI title="common.lbl.billingunitpaiddate" desc={payrollScheduleInfo !== null ? payrollScheduleInfo.billingUnitPaidDate : ''}></props.DLI>
                                        <props.DLI title="common.lbl.coverage.paidtodate" desc={payrollScheduleInfo !== null ? payrollScheduleInfo.coveragePaidDate : ''}></props.DLI>
                                    </props.DL>
                                </props.Col>
                            </props.Row>
                            <props.Row>
                                <props.Col md="12" sm="12" xs="12">
                                    <props.DateTimePickerControl className={checkBrowser() === true ? "gwp-inline rw-widget-container4Firefox" : "gwp-inline"} name="effectiveDate" id="effectiveDate" htmlFor="effectiveDate_input" useLocalDate={false} showFormat={false}
                                        min={minDate} max={maxDate}
                                        onDateChange={(e: any) => { dateChangeHandler(e) }}
                                        dateformat={(config !== null && config.dateFormat !== undefined) ? config.dateFormat : 'MM/dd/yyyy'} label="common.lbl.futurepayrollschedule.effdate" />
                                    <props.SelectControl className={checkBrowser() === true ? "gwp-inline payrollSchedule4Firefox" : "gwp-inline payrollSchedule"} name="payroll" id="payroll" label="common.lbl.futurepayrollschedule"
                                        onChange={(e: any) => { payrollChangeHandler(e, formProps.values, formProps.setFieldValue) }}
                                        disabled={disablePayrolls} sort={false} options={payrolls} >
                                    </props.SelectControl>
                                </props.Col>
                            </props.Row>
                        </props.Div>
                    </props.Div>
                    <div className="gwp-btn-container">
                        <props.Button id="updatePayrollSubmit" disabled={disableSaveBtn} condition={showUpdatePayrollSubmit} type="submit"><GlobalizedText message="common.lbl.save" /></props.Button>
                        <props.Button id="discardSubmit" condition={showUpdatePayrollSubmit} onClick={() => { discard() }}><GlobalizedText message="discard" /></props.Button>
                    </div>

                    {/* Modal for Submit */}
                    <props.Modal title={() => {
                        return successTitle('payroll.enrollment.submitSuccessful');
                    }
                    } id="submitDialog" size='large' footer={() => {
                        return <>
                            <props.Button onClick={closeSubmitDialog} ><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }} >
                        <props.Row>
                            <props.Col xs="12" sm="12" md="12">
                                <props.DL className='dl-horizontal gwp-align-left'>
                                    <props.DLI titleClass="gwp-label-ro" title="common.lbl.memberName" desc={payrollScheduleInfo !== null ? payrollScheduleInfo.name : ''}></props.DLI>
                                    <props.DLI titleClass="gwp-label-ro" title='common.lbl.memberID' desc={submitResult !== null ? removeLeftZero(submitResult.participantIDNumber) : ''}></props.DLI>
                                    <props.DLI titleClass="gwp-label-ro" title='common.lbl.transNumber' desc={submitResult !== null ? submitResult.currentTransactionId : ''}></props.DLI>
                                </props.DL>
                            </props.Col>
                        </props.Row>
                        <props.Row>
                        </props.Row>
                    </props.Modal>
                </Form>
            }
            }
        </Formik >

    </>
});