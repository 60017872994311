import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { string } from 'yup';
import { useTable } from '../shared/components';
import { FeatureFlags } from '../shared/featureFlags';
import GlobalizedText from '../shared/globalization';
import { ajax, removeLeftZero, trim, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { INVALID_MESSAGES } from '../shared/yupschema';
import './css/premiumbilled.css';

export const PremiumBilledComponent = withView((props: ViewComponentProps) => {
    //Get value from previous pagess
    const varFormPromp = useRef<any>();
    let companyCodePre: string = trim(props.getQueryParam("companyCode"));
    let groupNumberPre: string = trim(props.getQueryParam("groupNumber"));
    let accountNumberPre: string = trim(props.getQueryParam("accountNumber"));
    let participantIDNumberPre: string = trim(props.getQueryParam("participantIDNumber"));
    let modalDateFromPre: string = trim(props.getQueryParam("modalDateFrom"));
    let modalDateThroughPre: string = trim(props.getQueryParam("modalDateThrough"));
    let invoiceNumberPre: string = trim(props.getQueryParam("invoiceNumber"));
    let agentNumberPre: string = trim(props.getQueryParam("agentNumber"));
    let agentIndicatorPre: string = trim(props.getQueryParam("agentIndicator"));

    let showFlag: boolean = (participantIDNumberPre !== "" && participantIDNumberPre !== undefined && participantIDNumberPre !== null) ? true : false;

    //Current page
    const [config, setConfig] = useState<any | null>(null);
    const [tblcol, settblcol] = useState<any[]>([]);
    const showResponsibilityAmountDeductionDate = props.isFeatureEnabled(FeatureFlags.PB_RESPONSIBILITYAMOUNT_DEDUCTIONDATE);

    let col: any[] = [];


    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/premiumbill/view',
                params: {
                    companyCode: companyCodePre,
                    groupNumber: groupNumberPre,
                    accountNumber: parseInt(accountNumberPre),
                    participantIDNumber: participantIDNumberPre,
                    agentNumber: agentNumberPre,
                    agentIndicator: agentIndicatorPre,
                    modalDateFrom: modalDateFromPre,
                    modalDateThrough: modalDateThroughPre,
                    invoiceNumber: invoiceNumberPre
                },
                method: 'get',
                success: (res: any) => {
                    setConfig(res);

                    if (res.accountType === 'W' && showFlag) {
                        col.push.apply(col, [{ className: "gwp-dt-detail-control", sortable: false },
                        { title: "billpay.modaldate", name: "modalDate" },
                        { title: "common.lbl.name", name: "name" },
                        { title: "billpay.premiumBilled.benefitClassification", name: "benefitClassification" },
                        { title: "common.lbl.product", name: "productName" },
                        { title: "billpay.premiumBilled.coverageOption", name: "coverageOptionStr" },
                        { title: "common.lbl.plan", name: "plan" },
                        { title: "billpay.premiumBilled.volume", name: "volumeStr", type: "numeric" },
                        {
                            title: "common.lbl.premium",
                            name: "premiumStr",
                            type: "numeric",
                            render: (data: any, type: any, row: any, meta: any) => {
                                let typeOfCharge = row.typeOfCharge;
                                if (typeOfCharge === 'A') {
                                    return "<span right>" + data + "&nbsp;<span style='color: red;'>*</span>";
                                } else {
                                    return "<span right>" + data + "</span>";
                                }
                            }
                        }]);
                        if (showResponsibilityAmountDeductionDate) {
                            col.push.apply(col, [{
                                title: "common.lbl.memberResponsible",
                                name: "noChargeProductIndicator",
                                render: (data: any, type: any, row: any, meta: any) => {
                                    let noChargeProductIndicator = trim(row.noChargeProductIndicator);
                                    if (noChargeProductIndicator === "M") {
                                        return '<input type="checkbox"  checked onclick="return false;"/>'
                                    } else {
                                        return '<input type="checkbox" onclick="return false;"/>'
                                    }
                                }
                            },
                            { title: "common.lbl.deductionDate", name: "deductionDate" }]);

                        }
                    }
                    else if (res.accountType === 'W' && !showFlag) {
                        col.push.apply(col, [{ className: "gwp-dt-detail-control", sortable: false },
                        { title: "billpay.modaldate", name: "modalDate" },
                        { title: "common.lbl.memberIdentifier", name: "participantIDNumber" },
                        { title: "common.lbl.name", name: "name" },
                        { title: "billpay.premiumBilled.benefitClassification", name: "benefitClassification" },
                        { title: "common.lbl.product", name: "productName" },
                        { title: "billpay.premiumBilled.coverageOption", name: "coverageOptionStr" },
                        { title: "common.lbl.plan", name: "plan" },
                        { title: "billpay.premiumBilled.volume", name: "volumeStr", type: "numeric" },
                        {
                            title: "common.lbl.premium",
                            name: "premiumStr",
                            type: "numeric",
                            render: (data: any, type: any, row: any, meta: any) => {
                                let typeOfCharge = row.typeOfCharge;
                                if (typeOfCharge === 'A') {
                                    return "<span right>" + data + "&nbsp;<span style='color: red;'>*</span>";
                                } else {
                                    return "<span right>" + data + "</span>";
                                }
                            }
                        }]);
                        if (showResponsibilityAmountDeductionDate) {
                            col.push.apply(col, [{
                                title: "common.lbl.memberResponsible",
                                name: "noChargeProductIndicator",
                                render: (data: any, type: any, row: any, meta: any) => {
                                    let noChargeProductIndicator = trim(row.noChargeProductIndicator);
                                    if (noChargeProductIndicator === "M") {
                                        return '<input type="checkbox"  checked onclick="return false;"/>'
                                    } else {
                                        return '<input type="checkbox" onclick="return false;"/>'
                                    }
                                }
                            },
                            { title: "common.lbl.deductionDate", name: "deductionDate" }]);

                        }
                    }
                    else if (res.accountType !== 'W' && showFlag) {
                        col.push.apply(col, [{ className: "gwp-dt-detail-control", sortable: false },
                        { title: "billpay.modaldate", name: "modalDate" },
                        { title: "common.lbl.name", name: "name" },
                        { title: "billpay.premiumBilled.benefitClassification", name: "benefitClassification" },
                        { title: "common.lbl.product", name: "productName" },
                        { title: "billpay.premiumBilled.coverageOption", name: "coverageOptionStr" },
                        { title: "common.lbl.plan", name: "plan" },
                        { title: "billpay.premiumBilled.volume", name: "volumeStr", type: "numeric" },
                        {
                            title: "common.lbl.premium",
                            name: "premiumStr",
                            type: "numeric",
                            render: (data: any, type: any, row: any, meta: any) => {
                                let typeOfCharge = row.typeOfCharge;
                                if (typeOfCharge === 'A') {
                                    return "<span right>" + data + "&nbsp;<span style='color: red;'>*</span>";
                                } else {
                                    return "<span right>" + data + "</span>";
                                }
                            }
                        }]);
                        if (showResponsibilityAmountDeductionDate) {
                            col.push.apply(col, [{
                                title: "common.lbl.memberResponsible",
                                name: "noChargeProductIndicator",
                                render: (data: any, type: any, row: any, meta: any) => {
                                    let noChargeProductIndicator = trim(row.noChargeProductIndicator);
                                    if (noChargeProductIndicator === "M") {
                                        return '<input type="checkbox"  checked onclick="return false;"/>'
                                    } else {
                                        return '<input type="checkbox" onclick="return false;"/>'
                                    }
                                }
                            }]);
                        }
                    }
                    else if (res.accountType !== 'W' && !showFlag) {
                        col.push.apply(col, [{ className: "gwp-dt-detail-control", sortable: false },
                        { title: "billpay.modaldate", name: "modalDate" },
                        { title: "common.lbl.memberIdentifier", name: "participantIDNumber" },
                        { title: "common.lbl.name", name: "name" },
                        { title: "billpay.premiumBilled.benefitClassification", name: "benefitClassification" },
                        { title: "common.lbl.product", name: "productName" },
                        { title: "billpay.premiumBilled.coverageOption", name: "coverageOptionStr" },
                        { title: "common.lbl.plan", name: "plan" },
                        { title: "billpay.premiumBilled.volume", name: "volumeStr", type: "numeric" },
                        {
                            title: "common.lbl.premium",
                            name: "premiumStr",
                            type: "numeric",
                            render: (data: any, type: any, row: any, meta: any) => {
                                let typeOfCharge = row.typeOfCharge;
                                if (typeOfCharge === 'A') {
                                    return "<span right>" + data + "&nbsp;<span style='color: red;'>*</span>";
                                } else {
                                    return "<span right>" + data + "</span>";
                                }
                            }
                        }]);
                        if (showResponsibilityAmountDeductionDate) {
                            col.push.apply(col, [{
                                title: "common.lbl.memberResponsible",
                                name: "noChargeProductIndicator",
                                render: (data: any, type: any, row: any, meta: any) => {
                                    let noChargeProductIndicator = trim(row.noChargeProductIndicator);
                                    if (noChargeProductIndicator === "M") {
                                        return '<input type="checkbox"  checked onclick="return false;"/>'
                                    } else {
                                        return '<input type="checkbox" onclick="return false;"/>'
                                    }
                                }
                            }]);
                        }
                    }


                    settblcol(col);

                }
            });
        }
    }, []);
    if (config === null) {
        return <></>
    }

    let validationschema = yup.object().shape({
        memberId: string()
            .test('notBothAtTheSameTime',
                INVALID_MESSAGES.LASTNAME_OR_MEMBERID_BOTH_ENTERED,
                function (memberId) {
                    const { lastName } = this.parent;
                    if (lastName && memberId) {
                        return false; // when user enters both userEmail & userName do not validate form
                    }
                    return true;
                })
            .when(['lastName'], {
                is: lastName => !lastName,
                then: string().required(INVALID_MESSAGES.LASTNAME_OR_MEMBERID_IS_REQUIRED)
            }),
        lastName: string()
            .test('notBothAtTheSameTime',
                INVALID_MESSAGES.LASTNAME_OR_MEMBERID_BOTH_ENTERED,
                function (lastName) {
                    const { memberId } = this.parent;
                    if (memberId && lastName) {
                        return false;
                    }
                    return true;
                })
            .when(['memberId'], {
                is: memberId => !memberId,
                then: string().required(INVALID_MESSAGES.LASTNAME_OR_MEMBERID_IS_REQUIRED),
            })
    }, [['memberId', 'lastName']]);
    const formValidate = (values: any): any => {

        return validate(validationschema, values, props);


    }
    const formSubmit = (values: any): void => {
        let req = {
            companyCode: companyCodePre,
            groupNumber: groupNumberPre,
            accountNumber: parseInt(accountNumberPre),
            agentNumber: agentNumberPre,
            agentIndicator: agentIndicatorPre,
            modalDateFrom: modalDateFromPre,
            modalDateThrough: modalDateThroughPre,
            invoiceNumber: invoiceNumberPre,
            lastName: values.lastName,
            participantIDNumber: values.memberId

        };
        ajax({
            url: '/api/premiumbill/view',
            params: req,
            method: 'get',
            success: (res: any) => {
                props.clearMessage()
                setConfig(res);
            }
        });

    }

    const showall = (formProps: any) => {
        ajax({
            url: '/api/premiumbill/view',
            params: {
                companyCode: companyCodePre,
                groupNumber: groupNumberPre,
                accountNumber: parseInt(accountNumberPre),
                participantIDNumber: participantIDNumberPre,
                agentNumber: agentNumberPre,
                agentIndicator: agentIndicatorPre,
                modalDateFrom: modalDateFromPre,
                modalDateThrough: modalDateThroughPre,
                invoiceNumber: invoiceNumberPre
            },
            method: 'get',
            success: (res: any) => {
                props.clearMessage()
                setConfig(res);
            }
        });
        props.resetForm(formProps);
    }

    const tableObj = useTable({
        id: "myAppNBTable",
        option: { paging: true, scrollCollapse: true },
        table: {
            columns: tblcol,
            data: config.premiumBilledDtoList
        },
        isDataLoaded: true
    });

    return <>
        <props.Row>
            <props.Col xs="8" md="8" sm="8">
                <props.PageHeader className="gwp-billingsummary-label" label={() => {
                    return <><GlobalizedText message="billpay.modaldate.from" />
                        <br /> {modalDateFromPre}
                    </>
                }} />
            </props.Col>
            <props.Col xs="4" md="4" sm="4">
                <props.Div className="gwp-float-right" style={{ marginRight: -15 + "px" }}>
                    {/* <props.Icon type="print" size="sm"></props.Icon> */}
                    <props.Span className="gwp-icon gwp-icon-print gwp-icon-sm" style={{ marginRight: -5 + "px" }}></props.Span>
                    <props.Button className="printButton" printVisible={false} onClick={() => props.print({ size: 'A5', orientation: 'landscape' })}><GlobalizedText message="common.print" /></props.Button>
                </props.Div>
            </props.Col>
        </props.Row>
        {(config.currency !== undefined && config.currency.currencyName !== undefined && config.currency.countryCode !== undefined) &&
            <props.Row>
                <props.Col sm="12" md="12" xs="12">
                    <GlobalizedText message="common.lbl.allcurrencyvalue"></GlobalizedText> {config.currency.currencyName} ({config.currency.countryCode})
                </props.Col>
            </props.Row>
        }
        <br />
        <props.Panel className="">
            <props.PanelBody>
                <props.Row>
                    <props.Col sm="6" md="6" xs="12">
                        <props.Row>
                            <props.Col sm="6" md="6" xs="12">
                                <props.DL>
                                    <props.DLI title="common.lbl.group" desc={removeLeftZero(config.groupName)}></props.DLI>
                                    <props.DLI title="common.lbl.account" desc={config.accountName}></props.DLI>
                                    <props.DLI condition={config.billingFrequency !== null && config.billingFrequency !== '' && (config.participantIDNumber === null || config.participantIDNumber === '' || config.participantIDNumber === '0000000000')} title="billpay.billfrequency" desc={config.billingFrequency}></props.DLI>
                                    <props.DLI condition={config.participantIDNumber !== null && config.participantIDNumber !== ''} title="common.lbl.member" desc={config.memberName}></props.DLI>
                                </props.DL>
                            </props.Col>
                            <props.Col sm="6" md="6" xs="12">
                                <props.DL>
                                    <props.DLI title="billpay.invoicenum" desc={config.invoiceNumber}></props.DLI>
                                </props.DL>
                            </props.Col>
                        </props.Row>
                    </props.Col>
                    <>{!showFlag && <props.Col sm="6" md="6" xs="12" className="panel-container-right premium-billed-bottom">
                        <React.Fragment>
                            <Formik initialValues={{
                                lastName: '',
                                memberId: '',
                            }}
                                validate={formValidate}
                                validateOnBlur={false}
                                validateOnChange={false}
                                onSubmit={formSubmit}
                            >
                                {formProps => {
                                    varFormPromp.current = formProps;
                                    return <Form id="searchByNameOrId" data-print-visible="false">
                                        <props.Panel>
                                            <props.PanelHeading>
                                                <props.Div style={{ fontSize: "12px", fontWeight: 400 }}><GlobalizedText message="billpay_enter" /></props.Div>
                                                <props.Div style={{ fontSize: "12px" }}><GlobalizedText message="common.lbl.jumpTo" /></props.Div>
                                            </props.PanelHeading>
                                            <props.PanelBody>
                                                <props.Row>
                                                    <props.Div>
                                                        <props.Col sm="12" md="5" >
                                                            <props.TextControl name="memberId" id="memberId" label="common.lbl.memberID" />
                                                        </props.Col >
                                                        <props.Col sm="12" md="2" style={{ paddingTop: "10px", marginRight: "5px", width: "20px" }}>
                                                            <br /><GlobalizedText message="common.lbl.or"></GlobalizedText>
                                                        </props.Col >
                                                        <props.Col sm="12" md="5" >
                                                            <props.TextControl name="lastName" id="lastName" label="common.lbl.lastName" />
                                                        </props.Col>
                                                    </props.Div></props.Row>
                                                <props.Row>
                                                    <props.Col sm="12" md="5" >
                                                        <props.Button type="submit" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                            <GlobalizedText message="common.lbl.retrieve" />
                                                        </props.Button>
                                                    </props.Col >
                                                </props.Row>
                                                <props.Row>
                                                    <props.Col sm="12" md="5" className="showAll">
                                                        <props.LinkButton onClick={() => showall(formProps)}><GlobalizedText message="common.lbl.showall" /></props.LinkButton>
                                                    </props.Col >
                                                </props.Row>
                                            </props.PanelBody>
                                        </props.Panel>
                                    </Form>
                                }
                                }
                            </Formik >
                        </React.Fragment >
                    </props.Col>}</>
                </props.Row>
            </props.PanelBody>
        </props.Panel>
        <props.Panel>
            <props.PanelBody>
                <props.Row>
                    <props.Col sm="12" md="11">
                        <props.Div className="gwp-sectitle"><GlobalizedText message="common.lbl.premium" />
                        </props.Div>
                    </props.Col>
                    <props.Col sm="12" md="1" style={{ marginTop: "13px" }}>
                        <GlobalizedText message="common.lbl.waived" />
                        <props.Span style={{ color: "red" }}>*</props.Span>
                    </props.Col>
                </props.Row>
                {config !== null ? <><props.Row>
                    <props.Col md="12" sm="12" xs="12">
                        <tableObj.Component />
                    </props.Col>
                </props.Row></> : ""}
            </props.PanelBody>
        </props.Panel>
    </>
});

