import React, { useState, useEffect } from 'react';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import { Form, Formik } from 'formik';
import { ajax, formatNumber, getFromStorage, isEmptyStr, parseDate, removeFromStorage, validate } from '../shared/utils';
import './css/billingpaymentdetail.css';
import { TableColumn } from '../shared/components';
import { INVALID_MESSAGES } from '../shared/yupschema';
import { Message, useMessage } from "../shared/message";
//import * as yup from 'yup';
//import moment from 'moment';

interface BillingPaymentDetailForm {
    lastName: string,
    firstName: string,
    agentNumber: string
}

const HISTORY_COLUMNS: Array<TableColumn> = [
    { sortable: false, className: "gwp-dt-detail-control" },
    { title: 'common.lbl.transactionDate', name: 'transactionDate', width: "20%", type: "date", sortable: true, order: { by: "desc" } },
    { title: 'common.lbl.applyDate', name: 'applyDate', width: "16%", type: "date" },
    {
        title: 'common.lbl.paymentMethod', name: 'paymentMethod', width: "20%", render(data: any) {
            return data === undefined ? '' : data;
        }
    },
    { title: 'common.lbl.paymentFrequency', name: 'paymentFrequency', width: "24%" },
    {
        title: 'common.lbl.payment.amount', name: 'paymentAmount', width: "20%", type: "numeric"
    }
]

export const BillingPaymentDetailComponent = withView((props: ViewComponentProps) => {

    const companyCode = props.getParam("companyCode");
    const policyNumber = props.getParam("policyNumber");
    const changePaymentMethodMsg = getFromStorage('changePaymentMethodSuccess', true);
    const makePaymentCallBack = getFromStorage('makepaymentcallback', true);
    const [config, setConfig] = useState<any>();
    const [data, setData] = useState<Array<any>>([]);
    const { messageService, validateProps } = useMessage("paymentDetail");

    useEffect(() => {
        if (config === undefined) {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
            ajax({
                url: '/api/billingPaymentDetail/view',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber
                },
                success: (res: any) => {
                    setConfig(res);
                    const resValues = {
                        companyCode: companyCode,
                        policyNumber: policyNumber,
                        fromDate: res.paymentDto.paymentStartDate,
                        throughDate: res.paymentDto.paymentEndDate
                    };
                    let initParams = props.getInitParams();
                    if (initParams !== undefined) {
                        //alert("initial is not undefined" + initParams.fromDate);                        
                        retrieve({
                            //...defaultInitialValues,
                            ...resValues,
                            ...initParams
                        }, columns, res, setData, props);
                    } else {
                        retrieve({ resValues }, columns, res, setData, props);
                    }
                    validateProps.clearMessage();
                    if (changePaymentMethodMsg?.changeMethod === 'success') {
                        let message = '<span class="gwp-bold">'
                        message += props.getGlobalizedText('common.msg.payment.info.update')
                        message += '</span>'
                        message += '<br/>'
                        message += '<div>'
                        message += props.getGlobalizedText('common.msg.payment.info.furtherChanges')
                        message += '</div>'
                        validateProps.showMessage("success", message);
                        removeFromStorage("changePaymentMethodSuccess", true);
                    }
                    if (makePaymentCallBack?.makepayment === 'success') {
                        let message = '<span class="gwp-bold">'
                        message += props.getGlobalizedText('payment.tlt.paysuccesst')
                        message += '</span>'
                        message += '<br/>'
                        message += '<div>'
                        message += props.getGlobalizedText('payment.tlt.paysuccessc')
                        message += '</div>'
                        validateProps.showMessage("success", message);
                        removeFromStorage("makepaymentcallback", true);
                    }
                },
                error: (error: any) => {
                    props.showMessage("error", error.response.data?.message);
                }
            });
        }
        return () => {
            props.clearMessage();
        }
    }, [config]);
    const pdfViewer = props.usePDFViewer({ ...props });

    if (config === undefined) {
        return <></>;
    }
    const defaultInitialValues = {
        companyCode: companyCode,
        policyNumber: policyNumber,
        fromDate: config.paymentDto.paymentStartDate,
        throughDate: config.paymentDto.paymentEndDate
    };
    // define Form Submitting function.
    const formSubmit = (values: any): void => {
        props.clearMessage();
        props.setInitParams(values);
        retrieve(values, columns, config, setData, props);
    }

    let fromDateRequiredMsg = props.getGlobalizedText(INVALID_MESSAGES.FROMDATE_IS_EMPTY) + " " + props.getDateFormat().toLowerCase() + ".";
    let throughDateRequiredMsg = props.getGlobalizedText(INVALID_MESSAGES.THROUGHDATE_IS_EMPTY) + " " + props.getDateFormat().toLowerCase() + ".";

    let byShowHistory = (values: any) => {
        let res: any = {};
        let dateFrom: any = parseDate(values.fromDate, props.getDateFormat());
        let dateThrough: any = parseDate(values.throughDate, props.getDateFormat());

        if (isEmptyStr(values.fromDate)) {
            res = { ...res, ...{ fromDate: fromDateRequiredMsg } };
        } else if (isEmptyStr(values.throughDate)) {
            if (dateFrom > new Date()) {
                res = { ...res, ...{ fromDate: INVALID_MESSAGES.CURRENT_DATE_WRONG } };
            }
        } else if (dateFrom > dateThrough) {
            res = { ...res, ...{ fromDate: INVALID_MESSAGES.DATES_WRONG } };
        } else if (dateFrom > new Date()) {
            res = { ...res, ...{ fromDate: INVALID_MESSAGES.CURRENT_DATE_WRONG } };
        }
        if (isEmptyStr(values.throughDate)) {
            res = { ...res, ...{ throughDate: throughDateRequiredMsg } };
        } else if (isEmptyStr(values.fromDate)) {
            if (dateThrough > new Date()) {
                res = { ...res, ...{ throughDate: INVALID_MESSAGES.CURRENT_DATE_WRONG } };
            }
        } else if (dateFrom > dateThrough) {
            res = { ...res, ...{ throughDate: INVALID_MESSAGES.DATES_WRONG } };
        } else if (dateThrough > new Date()) {
            res = { ...res, ...{ throughDate: INVALID_MESSAGES.CURRENT_DATE_WRONG } };
        }

        return res;
    }

    let columns = HISTORY_COLUMNS;
    let initialValue: BillingPaymentDetailForm = props.getInitParams() !== undefined ? props.getInitParams() : defaultInitialValues;

    return <div id="paymentDetailComp">
        <props.Row>
            <props.Col xs="12" sm="8" md="9">
                <div className="gwp-page-header ">
                    <div className="gwp-page-header-block"></div>
                    <div className="gwp-page-header-label gwp-label"><GlobalizedText message="common.lbl.policyNumber" /> {policyNumber}</div>
                </div>
            </props.Col>
            <props.Col xs="12" sm="4" md="3" >
                <div className="pd_currentDate gwp-bold gwp-btn-backto" ><GlobalizedText message="common.lbl.currentDate" /> <time>{config.paymentDto.currentAsOfDate}</time></div>
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col xs="12" sm="12" md="12" className="marginbottom10px">
                <GlobalizedText message="common.lbl.allcurrencyvalue" /> {config.paymentDto.currencyDesc}
            </props.Col>
        </props.Row>

        <props.Panel>
            <props.PanelBody>
                <props.Row>
                    <props.Col xs="12" sm="6" md="7">
                        <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                            <props.DLI title="common.lbl.payor" desc={() => {
                                return <>
                                    <props.Link condition={config.paymentDto.inactivePolicy === false} to={`/personalInformation?companyCode=${companyCode}&policyNumber=${policyNumber}&personalInfoType=2&baseRiderCode=0&roleTypeCode=PAY`}>{config.paymentDto.payorName}</props.Link>
                                    <props.Span condition={config.paymentDto.inactivePolicy === true}>{config.paymentDto.payorName}</props.Span>
                                </>
                            }} />
                            <props.DLI title="common.lbl.effectiveDate" desc={config.paymentDto.effectiveDate} />
                            <props.DLI condition={config.paymentDto.hasOwnProperty("paidToDate")} title="common.lbl.paidToDate" desc={config.paymentDto.paidToDate} />
                            <props.DLI title="common.lbl.status" desc={config.paymentDto.status} />
                        </props.DL>
                    </props.Col>
                    <props.Col xs="12" sm="6" md="5" className="panel-container-bottom" >
                        <props.Panel condition={config.paymentDto.showLastStatement}>
                            <props.PanelHeading>
                                <props.BootstrapIcon type="envelope" label="payment.lbl.lastStatement"></props.BootstrapIcon>
                            </props.PanelHeading>
                            <props.PanelBody>
                                <props.DL className="dl-horizontal gwp-align-left">
                                    <props.DLI title={config.paymentDto.lastStatementType} desc={() => {
                                        return <>
                                            <div className="width130px">
                                                <props.LinkButton containerClassName="margintop0px marginbottom5px" className="paddingleft0px" condition={config.paymentDto.showViewStatement} onClick={() => { pdfViewer.showPDF('/api/billingPaymentDetail/showPdf?lastStatementType=' + config.paymentDto.lastStatementType); }} >
                                                    <props.Icon type="view-statement"  ></props.Icon><GlobalizedText message="common.lbl.viewStatement" />
                                                </props.LinkButton>
                                            </div>
                                        </>
                                    }} />
                                    <props.DLI title="common.lbl.statementDate" desc={() => {
                                        return <>
                                            <span>{config.paymentDto.lastStatementDate}</span>
                                        </>
                                    }} />
                                </props.DL>
                            </props.PanelBody>
                        </props.Panel>
                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel>
        {/* Define message component */}
        <props.Row>
            <Message messageService={messageService}></Message>
        </props.Row>
        <props.Panel>
            <props.PanelHeading>
                <div className="pd-fontbold"><GlobalizedText message="payment.lbl.billPaymentInfo" /></div>
            </props.PanelHeading>
            <props.PanelBody>
                <props.Row>
                    <props.Col xs="12" sm="6" md="6">
                        <props.DL className="gwp-dl">
                            <props.DLI condition={config.paymentDto.hasOwnProperty("billingToDate")} title="payment.lbl.billingToDate" desc={() => {
                                return <>
                                    {config.paymentDto.billingToDate}
                                </>
                            }} />
                            <props.DLI title={() => {
                                return <>
                                    <div><GlobalizedText message="common.lbl.premium" />
                                        <props.Link condition={config.canMakePayment} to={`/makePayment/${config.paymentDto.companyCode}/${config.paymentDto.policyNumber}`} >
                                            <props.Icon type="dollar" label="common.lbl.makePayment"></props.Icon>
                                        </props.Link>
                                    </div>
                                </>
                            }} desc={() => {
                                return <>
                                    {typeof (config.paymentDto.modalPremium) === 'string' ? formatNumber(parseFloat(config.paymentDto.modalPremium)) : formatNumber(config.paymentDto.modalPremium)}
                                </>
                            }} />
                        </props.DL>
                    </props.Col>
                    <props.Col xs="12" sm="6" md="6">
                        <props.DL className="gwp-dl">
                            <props.DLI condition={config.paymentDto.hasOwnProperty("paymentFrequency")} title="common.lbl.paymentFrequency" desc={() => {
                                return <>{config.paymentDto.paymentFrequency}</>
                            }} />
                            <props.DLI condition={config.paymentDto.hasOwnProperty("paymentMethod")} title={() => {
                                return <>
                                    <div><GlobalizedText message="common.lbl.paymentMethod" />
                                        <props.Link condition={config.canChangePaymentMethod} to={"/changePaymentMethod/" + companyCode + "/" + policyNumber} >
                                            <props.Icon type="application-activities" label="common.lbl.changePaymentMethod"></props.Icon>
                                        </props.Link>
                                    </div>
                                </>
                            }} desc={() => {
                                return <>
                                    <>{config.paymentDto.paymentMethod}</>
                                </>
                            }} />
                            <props.DLI condition={!isEmptyStr(config.paymentDto.accountControlNumber)} title={() => {
                                return <>
                                    <div><GlobalizedText message="common.lbl.autoPaymentID" />
                                        <props.Link condition={config.canViewAccountInformation} to={"/changePaymentMethod/" + companyCode + "/" + policyNumber} >
                                            <props.Icon type="application-activities" label="common.lbl.accountInfo"></props.Icon>
                                        </props.Link>
                                    </div>
                                </>
                            }} desc={() => {
                                return <>
                                    <>{config.paymentDto.accountControlNumber}</>
                                </>
                            }} />

                        </props.DL>
                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel>

        <props.Panel>
            <props.PanelHeading>
                <div className="pd-fontbold"><GlobalizedText message="payment.lbl.paymentHistory" /></div>
            </props.PanelHeading>
            <props.PanelBody>
                <Formik
                    initialValues={{
                        ...initialValue,
                        companyCode: companyCode,
                        policyNumber: policyNumber,
                        fromDate: config.paymentDto.paymentStartDate,
                        throughDate: config.paymentDto.paymentEndDate
                    }}
                    {...props.generateNoramlFormikProps()}
                    validate={
                        //values => {
                        byShowHistory
                        /*if (isEmptyStr(values.throughDate)) {
                            return validate(byShowHistory1, values, props);
                        } else if (isEmptyStr(values.fromDate)) {
                            return validate(byShowHistory2, values, props);
                        } else {
                            return validate(byShowHistory3, values, props);
                        }
                    }*/}
                    onSubmit={formSubmit}>
                    {formProps =>
                        <Form>
                            <props.Row>
                                <props.Col xs="12" sm="4" md="5">
                                    <props.DateTimePickerControl containerClassName="form-inline" className="input-group" max={
                                        parseDate(formProps.values.throughDate, props.getDateFormat())
                                        //new Date(Date.parse(convertDate(formProps.values.throughDate, props.getDateFormat(), "mm/dd/yyyy")))
                                    } name="fromDate" label="common.fromdate" dateformat={props.getDateFormat()} />
                                </props.Col>
                                <props.Col xs="12" sm="4" md="5">
                                    <props.DateTimePickerControl containerClassName="form-inline" className="input-group" max={new Date()} min={
                                        parseDate(formProps.values.fromDate, props.getDateFormat())
                                        //new Date(Date.parse(convertDate(formProps.values.fromDate, props.getDateFormat(), "mm/dd/yyyy")))
                                    } name="throughDate" label="payment.lbl.endDate" dateformat={props.getDateFormat()} />
                                </props.Col>
                                <props.Col xs="12" sm="2" md="2" className="retrievemargintop">
                                    <props.Button id="historyRetrieve" type="submit" onClick={() => { formSubmit(formProps.values) }} withoutContainer={true}><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                                </props.Col>
                            </props.Row>
                        </Form>
                    }
                </Formik>
                <props.Table setInitParams={props.setInitParams} getInitParams={props.getInitParams} id="paymentHistoryTable" next={props.next} table={{
                    columns: HISTORY_COLUMNS,
                    data: data
                }} dateformat={props.getDateFormat()}></props.Table>
            </props.PanelBody>
        </props.Panel>
        <pdfViewer.Component></pdfViewer.Component>
    </div >
});

function retrieve(values: any, columns: any, config: any, setData: any, props: any,) {

    ajax({
        url: '/api/billingPaymentDetail/showHistory',
        //params: values,
        params: {
            companyCode: values.companyCode,
            policyNumber: values.policyNumber,
            fromDate: values.fromDate,
            throughDate: values.throughDate
        },
        success: (res: any) => {
            if (typeof (res.paymentAmount) === 'string') {
                setData(res.map((item: any) => {
                    return {
                        transactionDate: item.transactionDate,
                        applyDate: item.applyDate,
                        paymentMethod: item.paymentMethod,
                        paymentFrequency: item.paymentFrequency,
                        paymentAmount: typeof (item.paymentAmount) === 'string' ? formatNumber(parseFloat(item.paymentAmount)) : formatNumber(item.paymentAmount)
                    }
                }));
            } else {
                setData(res);
            }
        },
        error: (error: any) => {
            props.showMessage("error", "homepage.errorFields");
            //props.showMessage("error", error.response.data.message);
            //alert(error.response.data.message);
            //props.showFieldError("homepage.errorFields", convertErrorMessages(errorFields), formikHelpers);

        }
    });
}