import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import GlobalizedText from '../shared/globalization';
import { Message, useMessage } from "../shared/message";
import { ajax, ajaxTestForYup, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import {FeatureFlags} from '../shared/featureFlags';
import './css/addnewmember.css';

export const AddNewMemberComponent = withView(function (props: ViewComponentProps) {
    const varFormPromp = useRef<any>();

    const [config, setConfig] = useState<any | null>(null);
    const [calculateSSNIndicator, setCalculateSSNIndicator] = useState<any>('');

    const [accounts, setAccounts] = useState<Array<any>>([{ name: "common.lbl.pleaseselect", value: "0" }]);
    const [showDiscardSubmit, setShowDiscardSubmit] = useState<boolean>(false);
    const [showPayrollDef, setShowPayrollDef] = useState<boolean>(false);
    const [payrollDefinitionList, setPayrollDefinitionList] = useState<any>(null);
    const [selectePayroll, setSelectePayroll] = useState<any>('');

    const [showPayrollRequired, setShowPayrollRequired] = useState<boolean>(false);
    const [showPayrollWarning, setShowPayrollWarning] = useState<boolean>(false);
    const [showAddmemberSubmit, setShowAddmemberSubmit] = useState<boolean>(false);

    const [disableGroup, setDisableGroup] = useState<boolean>(false);
    const [disableAccount, setDisableAccount] = useState<boolean>(false);
    const [disableHireDate, setDisableHireDate] = useState<boolean>(false);
    const [disableMemberID, setDisableMemberID] = useState<boolean>(true);
    const [disableGovtID, setDisableGovtID] = useState<boolean>(false);

    const [validateFinalSubmit, setValidateFinalSubmit] = useState<boolean>(false);

    const { messageService, validateProps } = useMessage("addNewMember");
    const isUpdatePayrollDefinition = props.isFeatureEnabled(FeatureFlags.ENROLLMENT_UPDATEPAYROLL);
    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/addmember/view',
                success: (res: any) => {
                    setConfig(res);
                    if (res.groupList.length > 1) {
                        varFormPromp.current.setFieldValue("group", "0");
                    } else if (res.groupList.length === 1 && res.groupList[0].value !== '0') {
                        varFormPromp.current.setFieldValue("group", res.groupList[0].value);
                        let tmp = res.groupList[0].value.split("|");
                        ajax({
                            url: '/api/addmember/getAccountList',
                            params: {
                                companyCode: tmp[0],
                                groupNumber: tmp[1]
                            },
                            success: (res2) => {
                                setAccounts(res2);
                                varFormPromp.current.setFieldValue("account", res2[0].value);
                            }
                        });
                        checkCharacterSSNIndicator(tmp[1]);
                    }
                }
            });
            return () => {
            }
        }
    }, [config]);

    const formSubmit = (values: any): void => {
        props.clearMessage();
        validateProps.clearMessage();
        let tmp = values.group.split("|");
        if (showAddmemberSubmit) {
            linkEx();
        } else {
            if ("N" === calculateSSNIndicator) {
                if ("" !== values.memberID) {
                    if(isUpdatePayrollDefinition===true){
                        showPayrollDefinition(values);
                    }else{
                        linkEx();
                    }
                }
            } else {
                ajax({
                    url: '/api/addmember/generateMemberID',
                    params: {
                        groupNumber: tmp[1]
                    },
                    success: (res) => {
                        varFormPromp.current.setFieldValue("memberID", res.participantIDNumber);
                        if(isUpdatePayrollDefinition===true){
                            showPayrollDefinition(values);
                        }else{
                            linkEx();
                        }
                    }
                });
            }
        }
    }

    // define Form Validate function.
    const formValidate = (values: any): any => {

        if (false === validateFinalSubmit) {
            if ("N" === calculateSSNIndicator || "" === calculateSSNIndicator) {
                return validate(haveMemberIdSchema, values, props);
            } else {
                return validate(noMemberIdSchema, values, props);
            }
        }

        if (showAddmemberSubmit) {
            if ("" === values.payrollDefinition) {
                validateProps.showMessage("error", "common.msg.payrolldefinitionrequired");
                return validate(payrollDefinionSchema, values, props);
            }
        }
    }

    const haveMemberIdSchema = yup.object().shape({
        group: YupSchema.addNewMember.group,
        account: YupSchema.addNewMember.account,
        hireDate: YupSchema.addNewMember.hireDate,
        govtID: YupSchema.addNewMember.govtID(props.getGlobalizedText, "SSN"),
        memberID: yup.string().trim()
            .test("memberID", "addmember.duplicated.memberid",
                (v: any): Promise<boolean | yup.ValidationError> => {
                    let tmp = "0";
                    let companyAndGroupNumber = varFormPromp.current.values.group;
                    if (companyAndGroupNumber !== '0') {
                        tmp = companyAndGroupNumber.split("|");
                    }
                    return ajaxTestForYup({
                        url: '/api/addmember/checkMemberID',
                        params: {
                            participantIDNumber: varFormPromp.current.values.memberID,
                            groupNumber: tmp[1],
                        },
                        handleResult: (res: any) => {
                            return !res.memberFlag
                        }
                    });
                }).test("memberID", "addmember.memberid.input.error", (v: any) => {
                    let reg = /^\d+$/;
                    if (reg.test(v.toString().trim())) {
                        return true;
                    }
                    return false;
                }).test("memberID", "addmember.memberidallzeros.input.error", (v) => {
                    let tmp = Number(v);
                    if (tmp !== 0) {
                        return true;
                    }
                    return false;
                }).required("addmember.memberid.required")
    });

    const noMemberIdSchema = yup.object().shape({
        group: YupSchema.addNewMember.group,
        account: YupSchema.addNewMember.account,
        hireDate: YupSchema.addNewMember.hireDate,
        govtID: YupSchema.addNewMember.govtID(props.getGlobalizedText, "SSN"),
    });

    const payrollDefinionSchema = yup.object().shape({
        payrollDefinion: YupSchema.addNewMember.payrollDefinion
    });

    const groupChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target.value;
        if (companyAndGroupNumber === '0') {
            setAccounts([{ "name": "common.lbl.pleaseselect", "value": "0" }]);
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: '/api/addmember/getAccountList',
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1]
                },
                success: (res) => {
                    setAccounts(res);
                    setFieldValue("account", res[0].value);
                }
            });
            checkCharacterSSNIndicator(tmp[1]);
        }
    }

    const accountChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let accountNum = e.target.value;
        if ('0' === accountNum) {
            return;
        }
        let tmp = formValue.group.split("|");
        checkCharacterSSNIndicator(tmp[1]);
    }

    function checkCharacterSSNIndicator(tmpGroupNumber: any) {
        ajax({
            url: '/api/addmember/checkCharacterSSNIndicator',
            params: {
                groupNumber: tmpGroupNumber
            },
            success: (res) => {
                setCalculateSSNIndicator(res.characterSSNIndicator);

                if ("N" === res.characterSSNIndicator) {
                    setDisableMemberID(false);
                } else {
                    setDisableMemberID(true);
                }
            }
        });
    }

    function showPayrollDefinition(values: any) {
        let tmpGroupNumber = "";
        let companyAndGroupNumber = values.group;
        if (companyAndGroupNumber !== '0') {
            let tmp = companyAndGroupNumber.split("|");
            tmpGroupNumber = tmp[1];
        }
        ajax({
            url: '/api/addmember/getPayrollDefinitionList',
            params: {
                groupNumber: tmpGroupNumber,
                accountNumber: values.account,
                issueHireDate: values.hireDate
            },
            success: (res: any) => {
                setReadOnly();
                setShowPayrollDef(true);
                if (undefined === res) {
                    linkEx();
                } else if (0 !== res.length) {
                    setShowAddmemberSubmit(true);
                    setShowPayrollRequired(true);
                    setPayrollDefinitionList(res);

                    setValidateFinalSubmit(true);

                    return false;
                } else {
                    setShowDiscardSubmit(true);
                    setShowPayrollWarning(true);
                    return false;
                }
            }
        });
    }

    function setReadOnly() {
        setDisableGroup(true);
        setDisableAccount(true);
        setDisableHireDate(true);
        setDisableMemberID(true);
        setDisableGovtID(true);
    }

    function discard() {
        props.toHomePage();
    }

    function linkEx() {
        let tmpGroupNumber = "";
        let tmpCompanyCode = "";
        let companyAndGroupNumber = varFormPromp.current.values.group;
        if (companyAndGroupNumber !== '0') {
            let tmp = companyAndGroupNumber.split("|");
            tmpCompanyCode = tmp[0];
            tmpGroupNumber = tmp[1];
        }
        ajax({
            url: '/api/addmember/addNewMember',
            params: {
                participantIDNumber: varFormPromp.current.values.memberID,
                issueHireDate: varFormPromp.current.values.hireDate
            },
            success: (res: any) => {
                props.next(`/enrollment?companyCode=${tmpCompanyCode.toString().trim()}&groupNumber=${tmpGroupNumber.toString().trim()}&accountNumber=${varFormPromp.current.values.account}&anniversaryDate=${res.anniversaryDate}&participantIDNumber=${res.participantIDNumber}&govtID=${varFormPromp.current.values.govtID.toString().trim()}&payrollIdentifier=${selectePayroll.toString().trim()}&transactionNumber=&formType=addMember`);
            }
        });
    }

    return <React.Fragment>
        <Formik initialValues={{
            group: '',
            account: '',
            hireDate: '',
            memberID: '',
            govtID: '',
            payrollDefinition: ''
        }}
            validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps => {
                varFormPromp.current = formProps;
                return <Form id="addnewmember">
                    <props.Row>
                        <props.Col md="4" sm="4" xs="12">
                            <props.SelectControl name="group" id="group" required={true} label="common.lbl.group" disabled={disableGroup}
                                onChange={(e: any) => { groupChangeHandler(e, formProps.values, formProps.setFieldValue) }} sort={false}
                                options={config === null ? [{ name: "common.lbl.pleaseselect", value: "0" }] : config.groupList}>
                            </props.SelectControl>
                            <props.SelectControl name="account" id="account" required={true} label="common.lbl.account" disabled={disableAccount} sort={false}
                                onChange={(e: any) => { accountChangeHandler(e, formProps.values, formProps.setFieldValue) }} options={accounts} >
                            </props.SelectControl>
                            <props.DateTimePickerControl name="hireDate" id="hireDate" useLocalDate={false} required={true} disabled={disableHireDate}
                                dateformat={(config !== null && config.dateFormat !== undefined) ? config.dateFormat : 'MM/dd/yyyy'} label="addmember.issue.hire.date" />
                        </props.Col>
                        <props.Col md="4" sm="4" xs="12">
                            <props.TextControl name="memberID" id="memberID" required={true} disabled={disableMemberID} label="common.lbl.memberID" maxLength={10} ></props.TextControl>
                            <props.AutoFormatControl name="govtID" id="govtID" formatType="SSN" disabled={disableGovtID} label="common.lbl.govID"></props.AutoFormatControl>
                            <props.Button className='nextButton' id="nextSubmit" condition={showPayrollDef === false} type="submit"><GlobalizedText message="common.button.next" /></props.Button>
                        </props.Col>
                    </props.Row>

                    <div>&nbsp;</div>
                    <div className={showPayrollDef ? "panel panel-default" : "panel panel-default hidden"} id="payrollDefinitionDiv" >
                        <div className="panel-heading">
                            <span ><GlobalizedText message="common.lbl.payrolldefinition" /></span>
                            <label className="gwp-label">(<label className="required"><GlobalizedText message="common.lbl.requiredField" /></label>)</label>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div id="errorMessageType" className="success successField">
                                    <Message messageService={messageService}></Message>
                                    <label id="successText" className={showPayrollRequired ? "gwp-label-required" : "hidden"} >
                                        <GlobalizedText message="common.msg.payrolldefinitionwarn" />
                                    </label>
                                    <label id="nodata" className={showPayrollWarning ? "" : "hidden"} style={{ color: 'red' }}>
                                        <GlobalizedText message="common.msg.nopayrolldefinition" />
                                    </label>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <div className="form-group" id="radios">
                                        {payrollDefinitionList !== null && payrollDefinitionList.map((payrollDefinitionTmp: any, index: number) => {
                                            return <div className="table-row">
                                                <label>
                                                    <props.RadioControl name="payrollDefinition" id={`payrollDefinition${index}`}
                                                        label={payrollDefinitionTmp.payrollDescription} value={payrollDefinitionTmp.payrollIdentifier}
                                                        onChange={(v) => { setSelectePayroll(v.target.value) }}  ></props.RadioControl>
                                                </label>
                                            </div>
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gwp-btn-container">
                        <props.Button id="discardSubmit" condition={showDiscardSubmit} onClick={() => { discard() }}><GlobalizedText message="discard" /></props.Button>
                        <props.Button id="addmemberSubmit" condition={showAddmemberSubmit} type="submit"><GlobalizedText message="common.button.next" /></props.Button>
                    </div>
                </Form>
            }
            }
        </Formik >

    </React.Fragment >
});