/** 
	Access Permission file copied from base due to some restrictions from Data Table plugin where Multi column is not possible under one column
	In this file we added new 5 new column under Reports column B, C, E, F, G for user level access
*/
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from "react";
import { TableCell, TableObject } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, check, deSelectCheckbox, getChecked, mergeObject, selectCheckbox } from '../shared/utils';
import { ViewComponentProps } from '../shared/viewcomponent';
import './css/accesspermission.css';
import { appendTHextraColumn } from '../shared/components/reportTBExpandSufix';

let checkBoxSelectdTrace: any = {};
let requestBody: any[] = []
let companyCode = "";
let dataObj: any = {}

export const AccessPermissionComponent = (props: ViewComponentProps) => {

    const userID = props.getParam("userId")
    const [config, setConfig] = useState<any | null>(null);
    const [table, setTable] = useState<any | null>(null);
    const [accounts, setAccounts] = useState<any | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [companies, setCompanies] = useState<any | null>(null);
    const [companyCodeDefault, setCompanyCodeDefault] = useState<any | null>(null);
    const [displayedGroups, setDisplayedGroups] = useState<Array<number>>([]);
    const [tableAll, setTableAll] = useState<any | null>(null);
    const [filterRecByGrpAndAccState, setFilterRecByGrpAndAccState] = useState<string>(generateFilterKey("0", "0"));
    const [groups, setGroups] = useState<any >([{name:'All',value:'0'}]);

    useEffect(() => {
        appendTHextraColumn();
    });
    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/accesspermission/view',
                showMask: false,
                params: {
                    "userId": userID
                },
                success: (res: any) => {
                    setConfig(res);
                    const companyList:any = [(res.companies.length === 1) ? {name:res.companies[0].companyName,value:res.companies[0].companyCode} : {name:"Please select",value:"0"}];
                    if(res.companies.length > 1){
                        res.companies.map((item:any)=>{
                            companyList.push({name:item.companyName,value:item.companyCode});
                        })
                    } else {
                        loadGroups(companyList[0].value);
                    }
                    setCompanies(companyList);
                    if (companyCode === "") {
                        companyCode = res.companyCode;
                    }
                    loadDefaultSetting(companyList);
                }
            });
        }
    }, [config, userID]);

    //render checkbox
    function renderCheckbox(id: any, originalDisabledStatus: boolean, originalCheckedStatus: string, groupNumber: string, accountNumber: string) {

        let checkedtrace = null;
        let disabledtrace = null;
        if (checkBoxSelectdTrace && checkBoxSelectdTrace[id]) {
            checkedtrace = checkBoxSelectdTrace[id].checked;
            disabledtrace = checkBoxSelectdTrace[id].disabled;
        }
        checkedtrace = checkedtrace != null ? checkedtrace : originalCheckedStatus;
        disabledtrace = disabledtrace != null ? disabledtrace : originalDisabledStatus;
        let content = '<input type="checkbox" ' + (disabledtrace ? 'disabled' : '') + ' data-groupNumber="' + groupNumber + '" data-accountNumber="' + accountNumber + '" id="' + id + '" ' + (checkedtrace ? 'checked' : '') + '/>';
        return content
    }
    //Load default settings
    function loadDefaultSetting(companycodes: any) {
        setFilterRecByGrpAndAccState(generateFilterKey("0", "0"));
        const companies:any = [];
        if(companycodes.length > 0){
            companycodes.forEach((item:any)=>{
                if (item.value !== "0") {
                    companies.push(item.value);
                }
            })
        }

        requestBody = [];
        dataObj = {};
        if (companies.length > 0) {
            ajax({
                url: `/api/accesspermission/fetchAutorizedSettings?userId=${userID}&companyCodes=${companies}`,
                success: (res: any) => {
                    setTable(res);
                    setTableAll(res);
                }
            });
        } else {
            props.showMessage("error", props.getGlobalizedText("common.err.noCompanyAuthorizations"));
         }
    }

    function retrieveGroupAcc(companyCode: any, group: string, account: any) {
        ajax({
            url: `/api/accesspermission/fetchSettings/${userID}/${(companyCode === null || companyCode.length <= 0) ? companies[0].value : companyCode}/${group}/${account}`,
            success: (res: any) => {
                setTable(res);
                setTableAll(res);
            }
        });
    }
    const isValidGroupNumber = (groupNumber: string) => {
        return groups.filter((option: any) => { return option.value === groupNumber }).length > 0;
    }
    //loading account
    const groupChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        props.clearMessage();
        let groupNumber = e.target !== undefined ? e.target.value : e;
        if (groupNumber === '0' || groupNumber === '') {
            setAccounts([{ "name": "All", "value": "0" }]);
        } else {
            if (isValidGroupNumber(groupNumber)) {
                ajax({
                    url: `/api/accesspermission/account/${companyCodeDefault}/${groupNumber}`,
                    success: (res) => {
                        setAccounts(res);
                        if (res.length === 1) {
                            setFieldValue("accountNumber", res[0].value);
                        }
                    }
                });
            }
        }
    }

    const groupFromCompanyChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        props.clearMessage();
        let company = e.target !== undefined ? e.target.value : e;
        loadGroups(company)
        setAccounts([{ "name": "All", "value": "0" }]);
    }

    const loadGroups = (company: String) => {
        if (company === '0' || company === '') {
            setGroups([]);
        } else {
            setCompanyCodeDefault(company);
                ajax({
                    url: `/api/accesspermission/group`,
                    params:{"companyCode":company},
                    success: (res) => {
                        setGroups(res);

                    }
                });
        }
    }

    function generateFilterKey(groupNumber: String, accountNumber: String) {
        return `${groupNumber}-${accountNumber}`;
    }


   function selectAll() {
        checkOrUncheckAll(true);
    }

    function deselectAll() {
        checkOrUncheckAll(false);
    }

    function checkOrUncheckAll(state: boolean) {
        let objKey: any = "";
        const tempTable = [...tableAll];
        displayedGroups.forEach(rowIndex => {
            tempTable[rowIndex] = {...tableAll[rowIndex], coverage: state, claims: state, enrollment: state, billing: state, reports: state, suffixB: state, suffixC: state, suffixE: state, suffixF: state, suffixG: state, onlineForms: state};
            objKey = tempTable[rowIndex].groupNumber + "_" + tempTable[rowIndex].accountNumber;
            dataObj[objKey] = { coverage: state, claims: state, enrollment: state, billing: state, reports: state, suffixB: state, suffixC: state, suffixE: state, suffixF: state, suffixG: state, onlineForms: state };
        });
        setTableAll(tempTable);
    }

    function saveSetting() {
        setIsSubmitting(true)
        requestBody = [];
        let tempArr = Object.keys(dataObj);
        if (tempArr.length < 1) {
            props.showMessage("success", props.getGlobalizedText("common.msg.noChanges"));
            setIsSubmitting(false)
        } else {
            if (tableAll != null) {
                for (let tempTa of tableAll) {
                    let tempKey: any = tempTa.groupNumber + "_" + tempTa.accountNumber;
                    let tempObj = dataObj[tempKey]
                    if (tempObj === null || tempObj === undefined) {
                        continue
                    } else {
                        if (dataObj[tempKey].coverage !== tempTa.coverage || dataObj[tempKey].claims !== tempTa.claims || dataObj[tempKey].enrollment !== tempTa.enrollment || dataObj[tempKey].billing !== tempTa.billing || dataObj[tempKey].reports !== tempTa.reports || dataObj[tempKey].sufixBid !== tempTa.suffixB || dataObj[tempKey].sufixCid !== tempTa.suffixC || dataObj[tempKey].sufixEid !== tempTa.suffixE || dataObj[tempKey].sufixFid !== tempTa.suffixF || dataObj[tempKey].sufixGid !== tempTa.suffixG || dataObj[tempKey].onlineForms !== tempTa.onlineForms) {
					 		let reportsStatus = false;
					 		if(dataObj[tempKey].sufixBid || dataObj[tempKey].sufixCid || dataObj[tempKey].sufixEid || dataObj[tempKey].sufixFid || dataObj[tempKey].sufixGid){
								reportsStatus = true;
							}
					 		let temp = {
					            companyCode: companyCode,
					            groupNumber: tempTa.groupNumber,
					            accountNumber: tempTa.accountNumber,
						    coverage: dataObj[tempKey].coverage,
                                		    claims: dataObj[tempKey].claims,
					            enrollment: dataObj[tempKey].enrollment,
					            billing: dataObj[tempKey].billing,
					            reports: reportsStatus,
								suffixB: dataObj[tempKey].sufixBid !== undefined ? dataObj[tempKey].sufixBid : dataObj[tempKey].suffixB,
								suffixC: dataObj[tempKey].sufixCid !== undefined ? dataObj[tempKey].sufixCid : dataObj[tempKey].suffixC,
								suffixE: dataObj[tempKey].sufixEid !== undefined ? dataObj[tempKey].sufixEid : dataObj[tempKey].suffixE,
								suffixF: dataObj[tempKey].sufixFid !== undefined ? dataObj[tempKey].sufixFid : dataObj[tempKey].suffixF,
								suffixG: dataObj[tempKey].sufixGid !== undefined ? dataObj[tempKey].sufixGid : dataObj[tempKey].suffixG,
					            onlineForms: dataObj[tempKey].onlineForms
					        }
					        requestBody.push(temp);
					    }
                    }
                }
            }
            if (requestBody.length === 0) {
                props.showMessage("success", props.getGlobalizedText("common.msg.noChanges"));
                setIsSubmitting(false)
            } else if (!companyCodeDefault || companyCodeDefault === "Please select") {
                props.showMessage("error", props.getGlobalizedText("common.err.noCompanySelected"));
                setIsSubmitting(false)
            } else {
                //Call service
                let firstName = config.firstName;
                let lastName = config.lastName;
                let companyCode = config.companyCode;
                let userType = config.userType;
                ajax({
                    url: `/api/accesspermission/saveSettings/${userID}/${firstName}/${lastName}/${companyCode}/${userType}`,
                    data: requestBody,
                    method: 'post',
                    success: () => {
                        props.showMessage("success", props.getGlobalizedText("common.lbl.accsetsaved"));
                        dataObj = {}
                        setIsSubmitting(false)
                        //fetch default setting:
                        ajax({
                            url: `/api/accesspermission/fetchAutorizedSettings?userId=${userID}&companyCodes=${companyCodeDefault}`,
                            success: (res: any) => {
                                setTableAll([...res]);
                                setTable([...res])
                            }
                        });
                    }, error: () => {
                        setIsSubmitting(false)
                        props.showMessage("error", "The previous updates were unsuccessfully. The default setting is reloaded.");
                    }
                })
            }
        }
    }

    const tableObj = useMemo(() => props.useTable({
        id: "accessPermissionTable",
        option: { scrollCollapse: true, destroy: true, },
        table: {
            columns: [
                { className: "gwp-dt-detail-control", sortable: false },
                { title: 'common.lbl.groupNumber', name: "groupNumber" },
                { title: 'pd.lbl.groupname', name: "groupName", width: "240px" },
                { title: 'payment.lbl.accountNumber', name: 'accountNumber' },
                { title: 'common.lbl.accountName', name: 'accountName' },
                {
                    title: 'security.lbl.coverage', name: 'coverage', sortable: false, render: (data, type, rowData) => {
                        let accessid = ["coverage", rowData.groupNumber, rowData.accountNumber].join("_");
                        return renderCheckbox(accessid, false, rowData.coverage, rowData.groupNumber, rowData.accountNumber);
                    }, onChange: handleCheckbox
                },
                {
                    title: 'security.lbl.claims', name: 'claims', sortable: false, render: (data, type, rowData) => {
                        let accessid = ["claims", rowData.groupNumber, rowData.accountNumber].join("_");
                        return renderCheckbox(accessid, false, rowData.claims, rowData.groupNumber, rowData.accountNumber);
                    }, onChange: handleCheckbox
                },
                {
                    title: 'common.lbl.enrollment', name: 'enrollment', sortable: false, render: (data, type, rowData) => {
                        let enrollid = ["enrollment", rowData.groupNumber, rowData.accountNumber].join("_");
                        return renderCheckbox(enrollid, (!rowData.coverage && !rowData.claims), rowData.enrollment, rowData.groupNumber, rowData.accountNumber);
                    }, onChange: handleCheckbox
                },
                {
                    title: 'common.lbl.billing', name: 'billing', sortable: false, render: (data, type, rowData) => {
                        let billingid = ["billing", rowData.groupNumber, rowData.accountNumber].join("_");
                        return renderCheckbox(billingid, (!rowData.coverage && !rowData.claims), rowData.billing, rowData.groupNumber, rowData.accountNumber);
                    }, onChange: handleCheckbox
                },
                {
                    title: 'B', className: 'sufixB', name: 'sufixB', sortable: false, render: (data, type, rowData) => {
                        
    					let sufix_bid = ["sufixB", rowData.groupNumber, rowData.accountNumber].join("_");
    
                        return renderCheckbox(sufix_bid, (!rowData.coverage && !rowData.claims), rowData.suffixB, rowData.groupNumber, rowData.accountNumber);
                    }, onChange: handleCheckbox
                },
                {
                    title: 'C', className: '', name: 'sufixC', sortable: false, render: (data, type, rowData) => {
                        let sufix_cid = ["sufixC", rowData.groupNumber, rowData.accountNumber].join("_");
    
                        return renderCheckbox(sufix_cid, (!rowData.coverage && !rowData.claims), rowData.suffixC, rowData.groupNumber, rowData.accountNumber);
                    }, onChange: handleCheckbox
                },
                {
                    title: 'E', className: '', name: 'sufixE', sortable: false, render: (data, type, rowData) => {
                        let sufix_eid = ["sufixE", rowData.groupNumber, rowData.accountNumber].join("_");
    
                        return renderCheckbox(sufix_eid, (!rowData.coverage && !rowData.claims), rowData.suffixE, rowData.groupNumber, rowData.accountNumber);
                    }, onChange: handleCheckbox
                },
                {
                    title: 'F', className: '', name: 'sufixF', sortable: false, render: (data, type, rowData) => {
                        let sufix_fid = ["sufixF", rowData.groupNumber, rowData.accountNumber].join("_");
    
                        return renderCheckbox(sufix_fid, (!rowData.coverage && !rowData.claims), rowData.suffixF, rowData.groupNumber, rowData.accountNumber);
                    }, onChange: handleCheckbox
                },
                {
                    title: 'G', className: '', name: 'sufixG', sortable: false, render: (data, type, rowData) => {
                        let sufix_gid = ["sufixG", rowData.groupNumber, rowData.accountNumber].join("_");
                        return renderCheckbox(sufix_gid, (!rowData.coverage && !rowData.claims), rowData.suffixG, rowData.groupNumber, rowData.accountNumber);
                    }, onChange: handleCheckbox
                },
                {
                    title: 'common.lbl.onlineforms', name: 'onlineforms', width: "78px", sortable: false, render: (data, type, rowData) => {
                        let onlineFormsid = ["onlineForms", rowData.groupNumber, rowData.accountNumber].join("_");
                        return renderCheckbox(onlineFormsid, (!rowData.coverage && !rowData.claims), rowData.onlineForms, rowData.groupNumber, rowData.accountNumber);
                    }, onChange: handleCheckbox
                }
            ],
            data: tableAll
        },
        onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element | null) => {
            if (childNode === null) {
                return;
            }
            let coverage = childNode.querySelector("input[id^=coverage]");
            let claims = childNode.querySelector("input[id^=claims]");
            let billing = childNode.querySelector("input[id^=billing]");
            let enrollment = childNode.querySelector("input[id^=enrollment]");
            let reports = childNode.querySelector("input[id^=reports]");
            let sufixB = childNode.querySelector("input[id^=sufixC]");
            let sufixC = childNode.querySelector("input[id^=sufixC]");
            let sufixE = childNode.querySelector("input[id^=sufixE]");
            let sufixF = childNode.querySelector("input[id^=sufixF]");
            let sufixG = childNode.querySelector("input[id^=sufixG]");
            let onlineForms = childNode.querySelector("input[id^=onlineForms]");

            if (coverage !== null) {
                check(coverage, getChecked(document.getElementById(coverage.id)));
                coverage.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
            if (claims !== null) {
                check(claims, getChecked(document.getElementById(claims.id)));
                claims.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
            if (billing !== null) {
                check(billing, getChecked(document.getElementById(billing.id)));
                if (document.getElementById(billing.id)?.getAttribute("disabled") == null) {
                    billing.removeAttribute("disabled");
                } else {
                    billing.setAttribute("disabled", "disabled");
                }
                billing.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
            if (enrollment !== null) {
                check(enrollment, getChecked(document.getElementById(enrollment.id)));
                if (document.getElementById(enrollment.id)?.getAttribute("disabled") == null) {
                    enrollment.removeAttribute("disabled");
                } else {
                    enrollment.setAttribute("disabled", "disabled");
                }
                enrollment.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
            if (reports !== null) {
                check(reports, getChecked(document.getElementById(reports.id)));
                if (document.getElementById(reports.id)?.getAttribute("disabled") == null) {
                    reports.removeAttribute("disabled");
                } else {
                    reports.setAttribute("disabled", "disabled");
                }
                reports.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
            if (sufixB !== null) {
                check(sufixB, getChecked(document.getElementById(sufixB.id)));
                if (document.getElementById(sufixB.id)?.getAttribute("disabled") == null) {
                    sufixB.removeAttribute("disabled");
                } else {
                    sufixB.setAttribute("disabled", "disabled");
                }
                sufixB.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
            if (sufixC !== null) {
                check(sufixC, getChecked(document.getElementById(sufixC.id)));
                if (document.getElementById(sufixC.id)?.getAttribute("disabled") == null) {
                    sufixC.removeAttribute("disabled");
                } else {
                    sufixC.setAttribute("disabled", "disabled");
                }
                sufixC.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
            if (sufixE !== null) {
                check(sufixE, getChecked(document.getElementById(sufixE.id)));
                if (document.getElementById(sufixE.id)?.getAttribute("disabled") == null) {
                    sufixE.removeAttribute("disabled");
                } else {
                    sufixE.setAttribute("disabled", "disabled");
                }
                sufixE.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
            if (sufixF !== null) {
                check(sufixF, getChecked(document.getElementById(sufixF.id)));
                if (document.getElementById(sufixF.id)?.getAttribute("disabled") == null) {
                    sufixF.removeAttribute("disabled");
                } else {
                    sufixF.setAttribute("disabled", "disabled");
                }
                sufixF.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
            if (sufixG !== null) {
                check(sufixG, getChecked(document.getElementById(sufixG.id)));
                if (document.getElementById(sufixG.id)?.getAttribute("disabled") == null) {
                    sufixG.removeAttribute("disabled");
                } else {
                    sufixG.setAttribute("disabled", "disabled");
                }
                sufixG.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
            if (onlineForms !== null) {
                check(onlineForms, getChecked(document.getElementById(onlineForms.id)));
                if (document.getElementById(onlineForms.id)?.getAttribute("disabled") == null) {
                    onlineForms.removeAttribute("disabled");
                } else {
                    onlineForms.setAttribute("disabled", "disabled");
                }
                onlineForms.addEventListener("change", (e: any) => { handleCheckbox(e, rowData) });
            }
        },
        onRendered: () => {
            const [groupNumber, accountNumber] = filterRecByGrpAndAccState.split("-");
            applyFilter(tableObj, groupNumber, accountNumber, setDisplayedGroups);
        }
    }), [tableAll, props]);


    if (config === null) {
        return <></>
    }
    return <>
        <Formik initialValues={{
            groupNumber: '0',
            accountNumber: '0',
            companies: ''
        }} onSubmit={() => {
        }}>
            {formProps =>
                <Form>
                    <props.Row>
                        <props.Col sm="6" lg="3">
                            <props.SelectControl sort={false} onChange={(e: any) => { props.resetForm(formProps, mergeObject({ companies: e.target ? e.target.value : e })); groupFromCompanyChangeHandler(e, formProps.values, formProps.setFieldValue) }} name="companies" label="memlist.company" required={true} options={companies}  />
                        </props.Col>
                    </props.Row>
                    <props.HR />
                    <props.Row>
                        <props.Col sm="4" md="4" xs="12">
                            <props.H5><GlobalizedText message="common.lbl.groupaccount" /></props.H5>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col sm="6" lg="3">
                            <props.SelectControl sort={false} onChange={(e: any) => { props.resetForm(formProps, mergeObject({ groupNumber: e.target ? e.target.value : e })); groupChangeHandler(e, formProps.values, formProps.setFieldValue) }} name="groupNumber" label="common.lbl.group" required={true} options={groups} />
                        </props.Col>

                        <props.Col sm="6" lg="3">
                            <props.SelectControl sort={false} name="accountNumber" label="common.lbl.account" options={accounts} />
                        </props.Col>
                        <props.Col sm="12">
                            <props.Button id="retrieve" onClick={(e: any) => {
                                props.clearMessage();
                                retrieveGroupAcc(formProps.values.companies, formProps.values.groupNumber === "" ? "0" : formProps.values.groupNumber, `${formProps.values.accountNumber}`);
                                const [grpNumber, accNo] = [formProps.values.groupNumber, formProps.values.accountNumber];
                                applyFilter(tableObj, grpNumber, accNo, setDisplayedGroups);
                                setFilterRecByGrpAndAccState(generateFilterKey(grpNumber, accNo));
                            }}><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        <tableObj.Component />
        <props.Row>
            <props.Col sm="12" className="form-inline">
                <props.Button id="selectAll" onClick={() => selectAll()}><GlobalizedText message="common.lbl.selectAll" /></props.Button>
                <props.Button id="deselectAll" onClick={() => deselectAll()}><GlobalizedText message="common.lbl.deselectAll" /></props.Button>
                <props.Button id="save" type="submit" disabled={isSubmitting} onClick={() => saveSetting()}><GlobalizedText message="common.lbl.save" /></props.Button>
                <props.Button id="close" onClick={props.back} > <GlobalizedText message="common.lbl.close" /></props.Button>
            </props.Col>
        </props.Row>
    </>
};


const checkCheckboxes = (els: Array<Element>, checked: boolean) => {
    els.forEach((el) => {
        check(el, checked);
    });
}

const removeAttribute = (els: Array<Element>, attrName: string) => {
    els.forEach((el) => {
        el.removeAttribute(attrName);
    });
}

const setAttribute = (els: Array<Element>, attrName: string, value: string) => {
    els.forEach((el) => {
        el.setAttribute(attrName, value);
    });
}

const handleCheckbox = (event: any, row: any) => {
    let accessChecked = event.currentTarget.checked;
    let coverageid = ["coverage", row.groupNumber, row.accountNumber].join("_");
    let claimsid = ["claims", row.groupNumber, row.accountNumber].join("_");
    let enrollid = ["enrollment", row.groupNumber, row.accountNumber].join("_");
    let billingid = ["billing", row.groupNumber, row.accountNumber].join("_");
    let reportsid = ["reports", row.groupNumber, row.accountNumber].join("_");
    let onlineFormsid = ["onlineForms", row.groupNumber, row.accountNumber].join("_");

    let sufix_bid = ["sufixB", row.groupNumber, row.accountNumber].join("_");
    let sufix_cid = ["sufixC", row.groupNumber, row.accountNumber].join("_");
    let sufix_eid = ["sufixE", row.groupNumber, row.accountNumber].join("_");
    let sufix_fid = ["sufixF", row.groupNumber, row.accountNumber].join("_");
    let sufix_gid = ["sufixG", row.groupNumber, row.accountNumber].join("_");

    let coverageEle: any = document.querySelectorAll(`#${coverageid}`);
    let claimsEle: any = document.querySelectorAll(`#${claimsid}`);
    let enrollEle: any = document.querySelectorAll(`#${enrollid}`);
    let billingEle: any = document.querySelectorAll(`#${billingid}`);
    let reportsEle: any = document.querySelectorAll(`#${reportsid}`);
    let onlineFormsEle: any = document.querySelectorAll(`#${onlineFormsid}`);

    let sufixBidEle: any = document.querySelectorAll(`#${sufix_bid}`);
    let sufixCidEle: any = document.querySelectorAll(`#${sufix_cid}`);
    let sufixEidEle: any = document.querySelectorAll(`#${sufix_eid}`);
    let sufixFidEle: any = document.querySelectorAll(`#${sufix_fid}`);
    let sufixGidEle: any = document.querySelectorAll(`#${sufix_gid}`);


    if (event.currentTarget.getAttribute("id")?.startsWith("coverage") || event.currentTarget.getAttribute("id")?.startsWith("claims")) {
		if(event.currentTarget.getAttribute("id").startsWith("claims")){
            checkCheckboxes(claimsEle, accessChecked);
        } else{
            checkCheckboxes(coverageEle, accessChecked);
        }

        const coverage =document.querySelector("#"+coverageid+":checked");
        const claim =document.querySelector("#"+claimsid+":checked");

        if (accessChecked) {
            removeAttribute(enrollEle, "disabled");
            removeAttribute(billingEle, "disabled");
            removeAttribute(onlineFormsEle, "disabled");
            if (reportsEle) {
                removeAttribute(reportsEle, "disabled");
            }
            removeAttribute(sufixBidEle, "disabled");
            removeAttribute(sufixCidEle, "disabled");
            removeAttribute(sufixEidEle, "disabled");
            removeAttribute(sufixFidEle, "disabled");
            removeAttribute(sufixGidEle, "disabled");

        }
        if(coverage === null && claim === null) {
            checkCheckboxes(enrollEle, false);
            setAttribute(enrollEle, "disabled", "disabled");
            checkCheckboxes(billingEle, false);
            setAttribute(billingEle, "disabled", "disabled");
            checkCheckboxes(onlineFormsEle, false);
            setAttribute(onlineFormsEle, "disabled", "disabled");
            if (reportsEle) {
                checkCheckboxes(reportsEle, false);
                setAttribute(reportsEle, "disabled", "disabled");
            }

            checkCheckboxes(sufixBidEle, false);
            setAttribute(sufixBidEle, "disabled", "disabled");
            checkCheckboxes(sufixCidEle, false);
            setAttribute(sufixCidEle, "disabled", "disabled");
            checkCheckboxes(sufixEidEle, false);
            setAttribute(sufixEidEle, "disabled", "disabled");
            checkCheckboxes(sufixFidEle, false);
            setAttribute(sufixFidEle, "disabled", "disabled");
            checkCheckboxes(sufixGidEle, false);
            setAttribute(sufixGidEle, "disabled", "disabled");
        }
    } else if (event.currentTarget.getAttribute("id").startsWith("claims")) {
        checkCheckboxes(claimsEle, accessChecked);
    } else if (event.currentTarget.getAttribute("id")?.startsWith("billing")) {
        checkCheckboxes(billingEle, accessChecked);
    } else if (event.currentTarget.getAttribute("id")?.startsWith("enrollment")) {
        checkCheckboxes(enrollEle, accessChecked);
    } else if (event.currentTarget.getAttribute("id")?.startsWith("reports")) {
        checkCheckboxes(reportsEle, accessChecked);
    } else if (event.currentTarget.getAttribute("id")?.startsWith("onlineforms")) {
        checkCheckboxes(onlineFormsEle, accessChecked);
    } else if (event.currentTarget.getAttribute("id")?.startsWith("sufixB")) {
        checkCheckboxes(sufixBidEle, accessChecked);
    } else if (event.currentTarget.getAttribute("id")?.startsWith("sufixC")) {
        checkCheckboxes(sufixCidEle, accessChecked);
    } else if (event.currentTarget.getAttribute("id")?.startsWith("sufixE")) {
        checkCheckboxes(sufixEidEle, accessChecked);
    } else if (event.currentTarget.getAttribute("id")?.startsWith("sufixF")) {
        checkCheckboxes(sufixFidEle, accessChecked);
    } else if (event.currentTarget.getAttribute("id")?.startsWith("sufixG")) {
        checkCheckboxes(sufixGidEle, accessChecked);
    }
    updateDataObj(row.groupNumber, row.accountNumber, coverageEle, claimsEle, enrollEle, billingEle, reportsEle, onlineFormsEle, sufixBidEle, sufixCidEle, sufixEidEle, sufixFidEle, sufixGidEle);

}

//Update requestBody
const updateDataObj = (groupNumber: string, accountNumber: number, coverageEle: Array<Element>, claimsEle: Array<Element>, enrollmentEle: Array<Element>, billingEle: Array<Element>, reportsEle: Array<Element>, onlineFormsEle: Array<Element>, sufixBidEle: Array<Element>, sufixCidEle: Array<Element>, sufixEidEle: Array<Element>, sufixFidEle: Array<Element>, sufixGidEle: Array<Element>) => {
    let objKey: any = groupNumber + "_" + accountNumber;
    let coverageStatus: boolean = getChecked(coverageEle[0]);
    let claimsStatus: boolean = getChecked(claimsEle[0]);
    let enrollmentStatus: boolean = getChecked(enrollmentEle[0]);
    let billingStatus: boolean = getChecked(billingEle[0]);
    let onlineFormsStatus: boolean = getChecked(onlineFormsEle[0]);

    let sufixBidsStatus: boolean  = getChecked(sufixBidEle[0]);
    let sufixCidsStatus: boolean = getChecked(sufixCidEle[0]);
    let sufixEidsStatus: boolean = getChecked(sufixEidEle[0]);
    let sufixFidsStatus: boolean = getChecked(sufixFidEle[0]);
    let sufixGidsStatus: boolean = getChecked(sufixGidEle[0]);

    let reportsStatus: boolean = false;
    if (reportsEle.length > 0) {
        reportsStatus = getChecked(reportsEle[0]);
    }
    dataObj[objKey] = { coverage: coverageStatus ? true : false, claims: claimsStatus ? true : false, enrollment: enrollmentStatus ? true : false, billing: billingStatus ? true : false, reports: reportsStatus ? true : false, onlineForms: onlineFormsStatus ? true : false, sufixBid: sufixBidsStatus ? true : false, sufixCid: sufixCidsStatus ? true : false, sufixEid: sufixEidsStatus ? true : false, sufixFid: sufixFidsStatus ? true : false, sufixGid: sufixGidsStatus ? true : false }
}

function applyFilter(tableObj: TableObject, groupNumber: string, accountNumber: string, setDisplayedGroups: React.Dispatch<React.SetStateAction<number[]>>) {
    tableObj.filter(1, groupNumber === "" ? "0" : groupNumber === "0" ? "" : `^${groupNumber}$`, true, false);
    tableObj.filter(3, accountNumber === "0" ? "" : `^${accountNumber}$`, true, false);
    setDisplayedGroups([...tableObj.getOrder()]);
}