import React, { useEffect, useState } from "react";
import Enrollment from '../types/enrollmenttypes';
import * as yup from 'yup';
import { YupSchema } from "../../shared/yupschema";
import GlobalizedText from "../../shared/globalization";
import { RequiredHeader } from "./enrollment";
import { ajax, copyObjectExcept, formatNumber, isEmptyStr } from "../../shared/utils";
import {FeatureFlags} from '../../shared/featureFlags';
import EmailAddress4Personalinfo from '../../shared/emailAddress4Personalinfo';
interface MemberInformationForm {
    companyCode: string,
    groupNumber: string,
    accountNumber: string,
    participantIDNumber: string,
    payrollDefinition: string,
    govtID: string,
    maskedGovtID: string,
    transactionNumber: string,
    firstName: string,
    lastName: string,
    streetAddress: string,
    streetAddress2: string,
    streetAddress3: string,
    city: string,
    state: string,
    zip: string,
    phone: string,
    email?:string,
    maritalStatus: string,
    gender: string,
    birthDate: string,
    smoker: string,
    salary: string,
    salaryMode: string,
    hoursWeek: string,
    salaryEffectiveDate: string,
    hireDate: string,
    terminationDate: string,
    terminationReason: string,
    eftDisclaimer: string,
    bankAccountNumber: string,
    accountType: string,
    transitNumber: string,
    eftStartDate: string,
    eftStopDate: string,
    location: string,
    locationEffectiveDate: string,
    eftexists: string,
    certificateNumber: string,
    identificationNumber: string
}

const defaultInitialValues: MemberInformationForm = {
    companyCode: "",
    groupNumber: "",
    accountNumber: "",
    participantIDNumber: "",
    payrollDefinition: "",
    govtID: "",
    maskedGovtID: "",
    transactionNumber: "",
    firstName: "",
    lastName: "",
    streetAddress: "",
    streetAddress2: "",
    streetAddress3: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email:"",
    maritalStatus: "",
    gender: "",
    birthDate: "",
    smoker: "",
    salary: "",
    salaryMode: "",
    hoursWeek: "",
    salaryEffectiveDate: "",
    hireDate: "",
    terminationDate: "",
    terminationReason: "",
    eftDisclaimer: "",
    bankAccountNumber: "",
    accountType: "",
    transitNumber: "",
    eftStartDate: "",
    eftStopDate: "",
    location: "",
    locationEffectiveDate: "",
    eftexists: "",
    certificateNumber: "",
    identificationNumber: ""
};
export const Member = (props: Enrollment.Props) => {
    const [formType, setFormType] = useState<any>(null);
    const [memberInformation, setMemberInformation] = useState<any>(null);
    const [dateFormat, setDateFormat] = useState<any>(null);
    const [states, setStates] = useState<any>(null);
    const [zipRequired, setZipRequired] = useState<boolean>(false);
    const [eftRequired, setEftRequired] = useState<boolean>(false);
    const [eftError, setError] = useState<boolean>(false);
    const [eftDisabled, setEftDisabled] = useState<boolean>(false);
    const [showHoursWeek, setShowHoursWeek] = useState<boolean>(false);
    const [checkBoxMap, setCheckBoxMap] = useState<any | null>(null);//Check box values
    const [requireSalary, setRequireSalary] = useState<boolean>(false);
    const [updateSalary, setUpdateSalary] = useState<boolean>(false);
    const [displaySalary, setDisplaySalary] = useState<boolean>(false);
    const [maskGovermentId, setMaskGovermentId] = useState<boolean>(false);
    const [updateInfo, setUpdateInfo] = useState<boolean>(true);//updateMemberDependentDemograpicInformation
    const [updateDob, setUpdateDob] = useState<boolean>(true);
    const [updateIssueHireDate, setUpdateIssueHireDate] = useState<boolean>(true);
    const [showTerminationDateSecurity, setShowTerminationDateSecurity] = useState<boolean>(false);//displayMemberAccountTerminationDateReason
    const [updateTerminationDateSecurity, setUpdateTerminationDateSecurity] = useState<boolean>(false);//updateMemberAccountTerminationDateReason
    const [terminationReason, setTerminationReason] = useState<string>("");//For validate terminationDate
    const [terminationDateLimit, setTerminationDateLimit] = useState<any>(null);//For validate terminationDate
    const [location, setLocation] = useState<string>("");//For validate locationEffectiveDate
    const [streetAddressRequired, setStreetAddressRequired] = useState<boolean>(false);
    const [isEmailAllowed,setISEmailAllowd] = useState<boolean>(true);
    const isUpdatePayrollDefinition = props.isFeatureEnabled(FeatureFlags.ENROLLMENT_UPDATEPAYROLL);

    props.setValidation(yup.object().shape({
        firstName: YupSchema.registration.firstName,
        lastName: YupSchema.lastName,
        streetAddress: YupSchema.enrollment.personalInformation.streetAddress(streetAddressRequired, props.getGlobalizedText),
        city: YupSchema.enrollment.personalInformation.city(props.getGlobalizedText),
        state: YupSchema.enrollment.personalInformation.state(props.getGlobalizedText),
        zip: YupSchema.enrollment.personalInformation.zip(zipRequired, props.getGlobalizedText),
        email: YupSchema.enrollment.personalInformation.email(props.getGlobalizedText),
        maritalStatus: YupSchema.enrollment.personalInformation.maritalStatus(props.getGlobalizedText),        
        gender: YupSchema.enrollment.personalInformation.gender(props.getGlobalizedText),
        birthDate: YupSchema.enrollment.personalInformation.birthDate(props.getGlobalizedText),
        smoker: YupSchema.enrollment.personalInformation.smoker(props.getGlobalizedText),
        salary: YupSchema.enrollment.personalInformation.salary(requireSalary, props.getGlobalizedText),
        salaryMode: YupSchema.enrollment.personalInformation.salary(requireSalary, props.getGlobalizedText),
        hoursWeek: YupSchema.enrollment.personalInformation.hoursWeek(showHoursWeek, props.getGlobalizedText),
        salaryEffectiveDate: YupSchema.enrollment.personalInformation.salaryEffectiveDate(requireSalary, props.getGlobalizedText),
        hireDate: YupSchema.enrollment.personalInformation.hireDate(updateIssueHireDate, props.getGlobalizedText),
        terminationDate: YupSchema.enrollment.personalInformation.terminationDate(terminationReason, terminationDateLimit, updateTerminationDateSecurity, props.getGlobalizedText),
        terminationReason: YupSchema.enrollment.personalInformation.terminationReason(updateTerminationDateSecurity, props.getGlobalizedText),
        bankAccountNumber: YupSchema.enrollment.personalInformation.bankAccountNumber(eftRequired, props.getGlobalizedText),
        accountType: YupSchema.enrollment.personalInformation.accountType(eftRequired, props.getGlobalizedText),
        transitNumber: YupSchema.enrollment.personalInformation.transitNumber(eftRequired, props.transactionID, setError, props.getGlobalizedText),
        eftStartDate: YupSchema.enrollment.personalInformation.eftStartDate(eftRequired, props.getGlobalizedText),
        eftStopDate: YupSchema.enrollment.personalInformation.eftStopDate(eftRequired, props.getGlobalizedText),
        locationEffectiveDate: YupSchema.enrollment.personalInformation.locationEffectiveDate(location, props.getGlobalizedText)
    }));

    props.setPrepareData((values: any) => {
        return {
            memberInfo: {
                companyCode: memberInformation.companyCode,
                groupNumber: memberInformation.groupNumber,
                accountNumber: memberInformation.accountNumber,
                participantIDNumber: memberInformation.participantIDNumber,
                payrollDefinition: memberInformation.payrollDefinition,
                govtID: memberInformation.govtID,
                maskedGovtID: memberInformation.maskedGovtID,
                transactionNumber: memberInformation.transactionNumber,
                firstName: values.firstName,
                lastName: values.lastName,
                streetAddress: values.streetAddress,
                streetAddress2: values.streetAddress2,
                streetAddress3: values.streetAddress3,
                city: values.city,
                state: values.state,
                zip: values.zip,
                phone: values.phone,
                email:values.email,
                maritalStatus: values.maritalStatus,
                gender: values.gender,
                birthDate: values.birthDate,
                smoker: values.smoker,
                salary: values.salary.replaceAll(/,/, ''),
                salaryMode: values.salaryMode,
                hoursWeek: values.hoursWeek,
                salaryEffectiveDate: values.salaryEffectiveDate,
                hireDate: values.hireDate,
                terminationDate: values.terminationDate,
                terminationReason: values.terminationReason,
                eftDisclaimer: values.eftDisclaimer,
                bankAccountNumber: values.bankAccountNumber,
                accountType: values.accountType,
                transitNumber: values.transitNumber,
                eftStartDate: values.eftStartDate,
                eftStopDate: values.eftStopDate,
                location: values.location,
                locationEffectiveDate: values.locationEffectiveDate,
                eftexists: memberInformation.eftexists,
                certificateNumber: memberInformation.certificateNumber,
                identificationNumber: memberInformation.identificationNumber,
            }
        }
    });
    const enableZipRequired = (stateMap: any, value: any | string) => {
        setZipRequired(false);
        stateMap.map((state: any) => {
            if (state.value == value && state.zipCodeEdit != "") {
                setZipRequired(true);
            }
        })
    }

    const showSalaryWeeklyHours = (checked: any) => {
        if (checked == 'H') {
            setShowHoursWeek(true);
        } else {
            setShowHoursWeek(false);
        }
    }

    useEffect(() => {
        if (props.formValue === undefined || memberInformation === null) {
            let params = {
                transactionID: props.transactionID
            };
            ajax({
                url: '/api/enrollment/personalInformation/view',
                params: params,
                success: (res: any) => {
                    setFormType(res.formType);
                    setDateFormat(res.dateFormat);
                    setCheckBoxMap(res.checkBoxMap);
                    setStates(res.checkBoxMap.states);
                    setISEmailAllowd(res.isEmailAddressAllowed);
                    if (res.memberInformation != null) {
                        setMemberInformation(res.memberInformation);
                        props.setFormValue(res.memberInformation);
                        setLocation(res.memberInformation.location);
                        setTerminationReason(res.memberInformation.terminationReason);
                        enableZipRequired(res.checkBoxMap.states, res.memberInformation.state);
                        showSalaryWeeklyHours(res.memberInformation.salaryMode);
                        salaryFormat(res.memberInformation, parseInt(res.memberInformation.salary));
                    } else {
                        setMemberInformation(defaultInitialValues);
                        props.setFormValue(defaultInitialValues);
                    }
                    setEftRequired(res.memberInformation.eftDisclaimer == 'true');
                    setEftDisabled(res.memberInformation.eftexists == 'Y');
                    enableEftInformation(res.memberInformation.eftDisclaimer == 'true');
                    setUpdateSalary(res.securityMap.updateSalary);
                    setRequireSalary(res.securityMap.requireSalary);
                    setDisplaySalary(res.securityMap.displaySalary);
                    setMaskGovermentId(res.securityMap.maskGovermentId);
                    if (res.formType != 'addMember') {
                        setUpdateInfo(res.securityMap.updateMemberDependentDemograpicInformation);
                        setUpdateIssueHireDate(res.securityMap.updateIssueHireDate);
                        setUpdateDob(res.securityMap.updateDob);
                    } else {
                        setStreetAddressRequired(true);
                        setUpdateIssueHireDate(false);
                    }
                    setShowTerminationDateSecurity(res.securityMap.displayMemberAccountTerminationDateReason);
                    setUpdateTerminationDateSecurity(res.securityMap.updateMemberAccountTerminationDateReason);
                    setTerminationDateLimit(res.enrollmentTerminationDateLimit);

                }
            });
        }
    }, [props.formValue])

    if (props.formValue === undefined || props.formValue == null || memberInformation === null) {
        return <></>
    }

    const _enableEftInformation = (e: any | string) => {
        let checked = e.target !== undefined ? e.target.checked : e;
        enableEftInformation(checked);
    }

    function enableEftInformation(checked: any) {
        if (checked) {
            setEftDisabled(false);
            setEftRequired(true);
        } else {
            setEftDisabled(true);
            setEftRequired(false);
        }
    }

    function salaryFormat(formValue: any, value: any) {
        let re = /^[0-9]+.?[0-9]*/;
        if (re.test(value)) {
            let str = value.toString().replaceAll(",", "");
            str = str.toLocaleString(undefined, { maximumFractionDigits: 2 });
            let formatValue = copyObjectExcept({ ...formValue, ...{ salary: formatNumber(Number(str)) } }, true);
            props.setFormValue(formatValue);
        }
    }
    return <>
        <props.Panel>
            <props.PanelHeading>
                <RequiredHeader label="common.lbl.personalInformation" {...props} />
            </props.PanelHeading>
            <props.PanelBody>
                <props.Row>
                    <props.Condition condition={formType != 'addMember'}>
                        <props.Col xs="12" md="3" sm="3">
                            <props.DL>
                                <props.DLI title="common.lbl.group" desc={memberInformation.groupNumber} />
                            </props.DL>
                        </props.Col>
                        <props.Col xs="12" md="3" sm="3">
                            <props.DL>
                                <props.DLI title="common.lbl.account" desc={memberInformation.accountNumber + ""} />
                            </props.DL>
                        </props.Col>
                        <props.Col xs="12" md="3" sm="3">
                            <props.DL>
                                <props.DLI title="common.lbl.memberID" desc={memberInformation.participantIDNumber} />
                            </props.DL>
                        </props.Col>
                        <props.Col xs="12" md="3" sm="3">
                            <props.DL>
                                <props.DLI title="common.lbl.transNumber" desc={memberInformation.transactionNumber} />
                            </props.DL>
                        </props.Col>
                    </props.Condition>
                    <props.Condition condition={formType == 'addMember'}>
                        <props.Col xs="12" md="2" sm="2">
                            <props.DL>
                                <props.DLI title="common.lbl.group" desc={memberInformation.groupNumber} />
                            </props.DL>
                        </props.Col>
                        <props.Col xs="12" md="2" sm="2">
                            <props.DL>
                                <props.DLI title="common.lbl.account" desc={memberInformation.accountNumber + ""} />
                            </props.DL>
                        </props.Col>
                        <props.Col xs="12" md="2" sm="2">
                            <props.DL>
                                <props.DLI title="common.lbl.memberID" desc={memberInformation.participantIDNumber} />
                            </props.DL>
                        </props.Col>
                        <props.Condition condition={!isEmptyStr(memberInformation.payrollDefinition) && isUpdatePayrollDefinition===true}>
                            <props.Condition condition={!maskGovermentId}>
                                <props.Col xs="12" md="2" sm="2">
                                    <props.DL>
                                        <props.DLI title="common.lbl.govID" desc={memberInformation.govtID} />
                                    </props.DL>
                                </props.Col>
                            </props.Condition>
                            <props.Condition condition={maskGovermentId}>
                                <props.Col xs="12" md="2" sm="2">
                                    <props.DL>
                                        <props.DLI title="common.lbl.govID" desc={memberInformation.maskedGovtID} />
                                    </props.DL>
                                </props.Col>
                            </props.Condition>
                            <props.Col xs="12" md="2" sm="2">
                                <props.DL>
                                    <props.DLI title="common.lbl.payrolldefinition" desc={memberInformation.payrollDefinition} />
                                </props.DL>
                            </props.Col>
                        </props.Condition>
                        <props.Condition condition={!isEmptyStr(memberInformation.payrollDefinition) && isUpdatePayrollDefinition===true}>
                            <props.Col xs="12" md="2" sm="2">
                                <props.DL>
                                    <props.DLI title="common.lbl.transNumber" desc={memberInformation.transactionNumber} />
                                </props.DL>
                            </props.Col>
                        </props.Condition>
                        <props.Condition condition={isEmptyStr(memberInformation.payrollDefinition)|| isUpdatePayrollDefinition===false}>
                            <props.Condition condition={!maskGovermentId}>
                                <props.Col xs="12" md="3" sm="3">
                                    <props.DL>
                                        <props.DLI title="common.lbl.govID" desc={memberInformation.govtID} />
                                    </props.DL>
                                </props.Col>
                            </props.Condition>
                            <props.Condition condition={maskGovermentId}>
                                <props.Col xs="12" md="3" sm="3">
                                    <props.DL>
                                        <props.DLI title="common.lbl.govID" desc={memberInformation.maskedGovtID} />
                                    </props.DL>
                                </props.Col>
                            </props.Condition>
                            <props.Col xs="12" md="3" sm="3">
                                <props.DL>
                                    <props.DLI title="common.lbl.transNumber" desc={memberInformation.transactionNumber} />
                                </props.DL>
                            </props.Col>
                        </props.Condition>
                    </props.Condition>
                </props.Row>
                <props.Row>
                    <props.Col xs="12" md="6" sm="6">
                        <props.TextControl id="firstName" name="firstName" required={true} disabled={!updateInfo} label="common.lbl.firstName" ></props.TextControl>
                        <props.TextControl id="lastName" name="lastName" required={true} disabled={!updateInfo} label="common.lbl.lastName" ></props.TextControl>
                        <props.TextControl id="streetAddress" name="streetAddress" required={streetAddressRequired} disabled={!updateInfo} label="common.lbl.streetAddress"></props.TextControl>
                        <props.TextControl id="streetAddress2" name="streetAddress2" disabled={!updateInfo}></props.TextControl>
                        <props.TextControl id="streetAddress3" name="streetAddress3" disabled={!updateInfo}></props.TextControl>
                        <props.TextControl id="city" name="city" required={true} disabled={!updateInfo} label="common.lbl.city"></props.TextControl>
                        <props.SelectControl id="state" name="state" required={true} disabled={!updateInfo} label="common.lbl.stateCountry" sort={false} options={
                            (checkBoxMap == null || checkBoxMap.states.length === 0) ? checkBoxMap : checkBoxMap.states.map((record: any) => { return { name: record.name, value: record.value } })
                        } onChange={(e: any) => {
                            enableZipRequired(states, e.target.value);
                        }
                        }></props.SelectControl>
                        <props.TextControl id="zip" name="zip" required={zipRequired} disabled={!updateInfo} label="pi.zip.code"></props.TextControl>
                        <props.TextControl id="phone" name="phone" required={false} disabled={!updateInfo} label="pd.lbl.phone"></props.TextControl>
                        <EmailAddress4Personalinfo props={props} disabled={!updateInfo}  isShowEmail = {isEmailAllowed} />
                        <props.SelectControl id="maritalStatus" name="maritalStatus" required={true} disabled={!updateInfo} label="maritalstatus" sort={true} defaultOption="common.lbl.pleaseselect" options={
                            (checkBoxMap == null || checkBoxMap.MSTA.length === 0) ? checkBoxMap : checkBoxMap.MSTA.map((record: any) => { return { name: record.name, value: record.value } })
                        }></props.SelectControl>
                        <props.SelectControl id="gender" name="gender" required={true} disabled={!updateInfo} label="pi.gender" sort={true} defaultOption="common.lbl.pleaseselect" options={
                            (checkBoxMap == null || checkBoxMap.SEXCD.length === 0) ? checkBoxMap : checkBoxMap.SEXCD.map((record: any) => { return { name: record.name, value: record.value } })
                        }></props.SelectControl>
                        <props.DateTimePickerControl htmlFor="birthDate_input" id="birthDate" name="birthDate" disabled={!updateDob} max={new Date()} required={true} label="common.lbl.dob" dateformat={dateFormat} />
                        <props.SelectControl id="smoker" name="smoker" required={true} label="smoker" disabled={!updateInfo} sort={true} defaultOption="common.lbl.pleaseselect" options={
                            (checkBoxMap == null || checkBoxMap.SMKR.length === 0) ? checkBoxMap : checkBoxMap.SMKR.map((record: any) => { return { name: record.name, value: record.value } })
                        }></props.SelectControl>
                    </props.Col>
                    <props.Col xs="12" md="6" sm="6">
                        <props.Condition condition={displaySalary}>
                            <props.Div className="salaryStyle1">
                                <props.TextControl id="salary" name="salary" required={requireSalary} disabled={!updateSalary} label="salary" onBlur={(e: any) => {
                                    salaryFormat(props.formValue, e.target.value);
                                }}></props.TextControl>
                            </props.Div>
                            <props.Div className="salaryStyle2">/</props.Div>
                            <props.SelectControl label="" className="salaryStyle3" id="salaryMode" name="salaryMode" disabled={!updateSalary} sort={true} defaultOption="common.lbl.pleaseselect" options={
                                (checkBoxMap == null || checkBoxMap.SAMD.length === 0) ? checkBoxMap : checkBoxMap.SAMD.map((record: any) => { return { name: record.name, value: record.value } })
                            } onChange={(e: any) => {
                                showSalaryWeeklyHours(e.target.value)
                            }}></props.SelectControl>
                            <props.TextControl id="hoursWeek" name="hoursWeek" condition={showHoursWeek} disabled={!updateSalary} required={true} label="hoursweek"></props.TextControl>

                            <props.DateTimePickerControl htmlFor="salaryEffectiveDate_input" id="salaryEffectiveDate" name="salaryEffectiveDate" required={requireSalary} disabled={!updateSalary} label="salaryeffdate" dateformat={dateFormat} />
                        </props.Condition>


                        <props.DateTimePickerControl htmlFor="hireDate_input" id="hireDate" name="hireDate" required={true} disabled={!updateIssueHireDate} label="addmember.issue.hire.date" dateformat={dateFormat} />

                        <props.Condition condition={showTerminationDateSecurity}>
                            <props.DateTimePickerControl htmlFor="terminationDate_input" id="terminationDate" name="terminationDate" required={false} disabled={!updateTerminationDateSecurity} label="common.lbl.terminationDate" dateformat={dateFormat} />
                            <props.SelectControl id="terminationReason" name="terminationReason" disabled={!updateTerminationDateSecurity} label="terminationreason" sort={true} defaultOption="common.lbl.pleaseselect" options={
                                (checkBoxMap == null || checkBoxMap.GIAS_CODE_03.length === 0) ? checkBoxMap : checkBoxMap.GIAS_CODE_03.map((record: any) => { return { name: record.name, value: record.value } })
                            } onChange={(e: any) => {
                                setTerminationReason(e.target.value);
                            }}></props.SelectControl>
                        </props.Condition>


                        <props.Div><props.Label><GlobalizedText message="eftdisclaimer" /></props.Label></props.Div>
                        <props.CheckboxControl className="checkboxStyle" label="" id="eftDisclaimer" name="eftDisclaimer" value={"true"} disabled={!updateInfo || memberInformation.eftexists === 'Y'} onChange={(e: any) => {
                            _enableEftInformation(e)
                        }} />
                        <props.Div className="eftMsgStyle"></props.Div>
                        <props.Condition condition={memberInformation.eftexists == 'Y'}>
                            <props.Information message="eftWarn" />
                        </props.Condition>
                        <props.Condition condition={eftError}>
                            <props.Information message="transit_number_is_not_existing" />
                        </props.Condition>
                        <props.TextControl id="bankAccountNumber" name="bankAccountNumber" required={eftRequired} disabled={!updateInfo || eftDisabled} label="bankaccountnumber"></props.TextControl>
                        <props.SelectControl id="accountType" name="accountType" required={eftRequired} disabled={!updateInfo || eftDisabled} label="payment.lbl.accountType" sort={true} defaultOption="common.lbl.pleaseselect" options={
                            (checkBoxMap == null || checkBoxMap.BATZ.length === 0) ? checkBoxMap : checkBoxMap.BATZ.map((record: any) => { return { name: record.name, value: record.value } })
                        }></props.SelectControl>
                        <props.TextControl id="transitNumber" name="transitNumber" required={eftRequired} disabled={!updateInfo || eftDisabled} label="transitnumber"></props.TextControl>
                        <props.DateTimePickerControl htmlFor="eftStartDate_input" id="eftStartDate" name="eftStartDate" required={eftRequired} disabled={!updateInfo || eftDisabled} label="eftstartdate" dateformat={dateFormat} />
                        <props.DateTimePickerControl htmlFor="eftStopDate_input" id="eftStopDate" name="eftStopDate" disabled={!updateInfo || eftDisabled} label="eftstopdate" dateformat={dateFormat} />


                        <props.SelectControl id="location" name="location" disabled={!updateInfo} label="location" defaultOption={"common.lbl.pleaseselect"} sort={true} options={
                            (checkBoxMap == null || checkBoxMap.location.length === 0) ? checkBoxMap : checkBoxMap.location.map((record: any) => { return { name: record.name, value: record.value } })
                        } onChange={(e: any) => {
                            setLocation(e.target.value);
                        }}></props.SelectControl>
                        <props.DateTimePickerControl htmlFor="locationEffectiveDate_input" id="locationEffectiveDate" name="locationEffectiveDate" disabled={!updateInfo} label="locationeffdate" dateformat={dateFormat} />

                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel>
    </>
}