import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import GlobalizedText from '../../globalization';
import { Message, MessageService } from '../../message';
import { $, generateUUID } from '../../utils';
import { ConditionProps, withCondition } from './shared-component';

export interface ModalProps extends ConditionProps {
    /**
     * size of the modal dialog, default is middle.
     */
    size?: 'small' | 'large',
    /**
    * className of the modal dialog, default is null.
    */
    modalClassName?: string,

    /**
     * title displayed on the modal dialog. if leave it as empty, the title part would be removed.
     */
    title?: string | Function,

    /**
     * the close button displayed on the right top corner of the dialog, default is false
     */
    hasCloseBtn?: boolean

    /**
     * the JSX Element displayed on the footer.
     */
    footer?: () => JSX.Element,

    /**
     * Children is required, would be displayed in the modal's body
     */
    children: any,

    /**
     * id of the dialog, if leave it as empty, would use UUID instead.
     */
    id?: string

    /**
     * form properties
     */
    formProps?: any
    messageService?: MessageService
    onClose?: (e: any) => void
}

export const showModal = (selector: string, callback?: () => void) => {
    if (!$(`${selector}`).hasClass("modal fade in")) {
        if (callback !== undefined) {
            $(`${selector}`).on("shown.bs.modal", () => { window.setTimeout(callback, 50); $(`${selector}`).off("shown.bs.modal"); });
        }
        $(`${selector}`).modal('show');
    }
}

export const closeModal = (selector: string, callback?: () => void) => {
    if ($(`${selector}`).hasClass("modal fade in")) {
        if (callback !== undefined) {
            $(`${selector}`).on("hidden.bs.modal", () => { window.setTimeout(callback, 50); $(`${selector}`).off("hidden.bs.modal"); });
        }
        $(`${selector}`).modal('hide');
    }
}

/**
 * 
 * @param props 
 */
export const Modal = withCondition((props: ModalProps) => {
    let id = (props.id === undefined || props.id.trim() === '') ? generateUUID() : props.id;
    let size = props.size === undefined ? '' : (props.size === 'small' ? 'modal-sm' : 'modal-lg');
    let modalClassName = props.modalClassName === undefined ? 'modal-content' : "modal-content " + props.modalClassName;

    useEffect(() => {
        if (props.onClose !== undefined) {
            $("#" + id).off("hidden.bs.modal", props.onClose);
            $("#" + id).on("hidden.bs.modal", props.onClose);
        }
    })

    return <>
        <div id={id} className="modal fade" role="dialog">
            <div className={`modal-dialog ${size}`} role="document">
                <div className={modalClassName}>
                    {props.title !== undefined &&
                        <div className="modal-header">
                            {(props.hasCloseBtn !== undefined && props.hasCloseBtn === true) &&
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            }
                            <h4 className="modal-title" id={`title_${id}`}>
                                {typeof props.title === "string" &&
                                    <GlobalizedText message={props.title} />
                                }
                                {typeof props.title === "function" &&
                                    <>{props.title()}</>
                                }
                            </h4>
                        </div>
                    }
                    <div className="modal-message">
                        <Message messageService={props.messageService}></Message>
                    </div>
                    {props.formProps !== undefined &&
                        <Formik {...props.formProps}>
                            {formProps =>
                                <Form>
                                    <div className="modal-body">
                                        {props.children}
                                    </div>
                                    {props.footer !== undefined &&
                                        <div className="modal-footer">
                                            {props.footer()
                                            }
                                        </div>
                                    }
                                </Form>
                            }
                        </Formik>

                    }
                    {props.formProps === undefined &&
                        <>
                            <div className="modal-body">
                                {props.children}
                            </div>
                            {props.footer !== undefined &&
                                <div className="modal-footer">
                                    {props.footer()
                                    }
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    </>
});