
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import GlobalizedText from '../shared/globalization';
import { useMessage } from '../shared/message';
import { ajax, getFromStorage, isEmptyObject, removeFromStorage, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/help.css';
import { Form, Formik } from 'formik';
import { TableColumn } from '../shared/components';

let selectetedRowData: any = null;
let selectetedListData: any = null;

export const HelpComponent = withView((props: ViewComponentProps) => {
    const [config, setConfig] = useState<any>();
    const [disableButton, setDisableButton] = useState<boolean>(true);
    const [hasComponent, setHasComponent] = useState<boolean>(true);
    const [handlerFlag, setHandlerFlag] = useState<string | null>();
    const refFormPromp = useRef<any>();
    const tableColumns: Array<TableColumn> = [
        { name: 'name', title: 'common.lbl.helpLabel', order: { by: "asc" } },
        { name: 'lastChangeDate', title: 'common.lbl.updateDate' },
        { name: 'location', title: 'common.lbl.helpLocation' }
    ]

    const [helpTable, setHelpTable] = useState<any | null>(null);
    if (helpTable === null) {
        setHelpTable({
            columns: tableColumns,
            data: []
        });
    }

    const helplocationcallback = getFromStorage('helplocationcallback', true);

    let full = window.location.host;
    let parts = full.split(':');
    let hostname = parts[0];
    //let hostname = "portalgdemo.cnx.com";

    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: `/api/cms/onlinehelp/list`,
                params: { site: hostname },
                success: (res: any) => {
                    setConfig(res);
                    setHelpTable({
                        columns: tableColumns,
                        data: res.list.map((row: any) => {
                            return {
                                name: row.content.name,
                                lastChangeDate: row.lastChangeDate,
                                location: isEmptyObject(row.component) ? undefined : props.getGlobalizedText(row.component.name),
                                checked: false
                            }
                        })
                    });
                    if (helplocationcallback?.status === 'success') {
                        props.showMessage("success", helplocationcallback?.message);
                        removeFromStorage("helplocationcallback", true);
                    }
                    if (helplocationcallback?.status === 'error') {
                        props.showMessage("error", helplocationcallback?.message);
                        removeFromStorage("helplocationcallback", true);
                    }
                }
            });
        }

    }, [config]);

    if (config === undefined) {
        return <></>
    }


    // define Form Submitting function.
    const formSubmit = (values: any): void => {
        props.clearMessage();
        if (handlerFlag === 'edit') {
            edit(values);
        } else if (handlerFlag === 'add') {
            add(values);
        } else if (handlerFlag === 'delete') {
            remove(values);
        } else {
            search(values);
        }

    }
    function search(values: any) {
        ajax({
            url: '/api/cms/onlinehelp/list',
            params: { site: hostname },
            success: (res) => {
                //disable button
                setConfig(res);
                setDisableButton(true);
                setHasComponent(true);
                selectetedListData = null;
                setHandlerFlag("search");
                setHelpTable({
                    columns: tableColumns,
                    data: res.list.map((row: any) => {
                        return {
                            name: row.content.name,
                            lastChangeDate: row.lastChangeDate,
                            location: isEmptyObject(row.component) ? undefined : props.getGlobalizedText(row.component.name),
                            checked: false
                        }
                    })
                });
                if (helplocationcallback?.status === 'success') {
                    props.showMessage("success", helplocationcallback?.message);
                    removeFromStorage("helplocationcallback", true);
                }
                if (helplocationcallback?.status === 'error') {
                    props.showMessage("error", helplocationcallback?.message);
                    removeFromStorage("helplocationcallback", true);
                }
                refFormPromp.current.setFieldValue("subject", '');
                refFormPromp.current.setFieldValue("content", '');
                refFormPromp.current.setFieldValue("subjectEdit", selectetedListData.name);
                refFormPromp.current.setFieldValue("contentEdit", selectetedListData?.content?.content);
            }
        });
    }
    function add(values: any) {
        ajax({
            url: '/api/cms/onlinehelp/create',
            method: 'post',
            data: {
                "subject": values.subject,
                "content": values.content,
                "site": hostname
            },
            success: (res) => {
                closeCreateHelp();
                search(values);
            }
        });

    }
    function edit(values: any) {
        ajax({
            url: '/api/cms/onlinehelp/update',
            method: 'put',
            data: {
                "id": selectetedListData?.id,
                "subject": values.subjectEdit,
                "content": values.contentEdit
            },
            success: (res) => {
                closeEditHelp();
                search(values);
            }
        });

    }


    function remove(values: any) {
        ajax({
            url: `/api/cms/onlinehelp/delete`,
            params: { id: selectetedListData?.id },
            method: 'delete',
            success: () => {

                closeDeleteHelp();
                search(values);
            }
        });
    }
    function removeHelpLocation(values: any) {
        ajax({
            url: `/api/cms/onlinehelp/removeLocation`,
            params: { id: selectetedListData?.id },
            method: 'put',
            success: () => {
                search(values);
                props.showMessage("success", props.getGlobalizedText("common.msg.success_to_remove").replace("location", props.getGlobalizedText(selectetedListData.component.name)).replace("label", selectetedListData.name));
                closeRemoveHelpLocation();
            },
            error: () => {
                props.showMessage("error", props.getGlobalizedText("common.msg.fail_to_remove"));
                closeRemoveHelpLocation();
            }
        });
    }

    function closeCreateHelp() {
        props.closeModal("#createHelpModal");
    }
    function closeEditHelp() {
        props.closeModal("#editHelpModal");
    }
    function closeDeleteHelp() {
        props.closeModal("#deleteHelpModal");
    }
    function closeRemoveHelpLocation() {
        props.closeModal("#removeLocationModal");
    }

    function warnTitle(title: string) {
        return <><props.Icon type="warning" /><GlobalizedText message={title} /></>
    }
    function saveSelectedValue(row: any, list: any) {
        selectetedRowData = row;
        selectetedListData = list;
        if (selectetedRowData != null) {
            refFormPromp.current.setFieldValue("subject", '');
            refFormPromp.current.setFieldValue("content", '');
            refFormPromp.current.setFieldValue("subjectEdit", selectetedRowData?.name);
            refFormPromp.current.setFieldValue("contentEdit", selectetedRowData?.content?.content);
            //enable button
            setDisableButton(false);
            if (isEmptyObject(selectetedRowData.location)) {
                setHasComponent(true);
            } else {
                setHasComponent(false);
            }
        }
    }

    const createHelpValidateSchema = yup.object().shape({
        subject: yup.string().trim().required("common.msg.subjectRequired"),
        content: yup.string().trim().required("payment.msg.desc.req")
    });

    const editHelpValidateSchema = yup.object().shape({
        subjectEdit: yup.string().trim().required("common.msg.subjectRequired"),
        contentEdit: yup.string().trim().required("payment.msg.desc.req")
    });

    const createHelpMessage = useMessage("createHelp");
    const editHelpMessage = useMessage("editHelp");

    return <React.Fragment ><>

        <Formik initialValues={{
            subject: "",
            content: "",
            subjectEdit: "edit",
            contentEdit: "edit",
            helpLoca: "",
            id: ""
        }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={(values) => {
                if (handlerFlag === 'add' && (isEmptyObject(values.subject) || isEmptyObject(values.content))) {
                    return validate(createHelpValidateSchema, values, createHelpMessage.validateProps);
                }
                if (handlerFlag === 'edit' && (isEmptyObject(values.subjectEdit) || isEmptyObject(values.contentEdit))) {
                    return validate(editHelpValidateSchema, values, editHelpMessage.validateProps);
                }
            }}
            onReset={() => { createHelpMessage.validateProps.clearMessage(); editHelpMessage.validateProps.clearMessage(); }}
            onSubmit={formSubmit}
        >
            {formProps => {
                refFormPromp.current = formProps;
                return <Form>
                    <props.Div>
                        <props.Button onClick={() => props.showModal("#createHelpModal")}><GlobalizedText message="common.lbl.createNewHelp" /></props.Button>
                        <props.Button disabled={disableButton} onClick={() => props.showModal("#editHelpModal")}><GlobalizedText message="common.lbl.edit" /></props.Button>
                        <props.Button disabled={disableButton} onClick={() => props.showModal("#deleteHelpModal")}><GlobalizedText message="common.lbl.delete" /></props.Button>
                        <props.Button disabled={disableButton} onClick={() => props.next(`/helpLocation?id=${selectetedListData?.id}&name=${selectetedListData?.name}`)}><GlobalizedText message="common.lbl.selectHelpLoca" /></props.Button>
                        <props.Button disabled={hasComponent} onClick={() => props.showModal("#removeLocationModal")}><GlobalizedText message="common.lbl.removeHelpLoca" /></props.Button>
                    </props.Div>

                    <props.Modal messageService={createHelpMessage.messageService} id="createHelpModal" size="large" title="common.lbl.newHelp" footer={() => {
                        return <>
                            <props.Button type="submit" onClick={() => setHandlerFlag("add")}><GlobalizedText message="common.lbl.saveandclose" /></props.Button>
                            <props.Button data-dismiss="modal" onClick={() => {
                                createHelpMessage.validateProps.clearMessage();
                                props.resetForm(formProps, { "subject": '', "content": '' });
                            }}><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }}>
                        <props.TextControl id="subject" name="subject" label="common.lbl.subject" required={true} />
                        <props.ContentEditor onChange={(cev: string) => {
                            formProps.setFieldValue("content", cev);
                        }} {...props} enableMultipleImagesUploader={false} contentId={"help"} contentTypeId={"help"} id="content" name="content" html={formProps.values.content} label="common.lbl.coverageDescription" required={true}></props.ContentEditor>
                        <br /><GlobalizedText message="common.msg.requiredFields" />
                    </props.Modal>

                    <props.Modal id="deleteHelpModal" size="large" title={() => { return warnTitle("common.lbl.deleteHelp") }} footer={() => {
                        return <>
                            <props.Button onClick={() => remove(formProps.values)}><GlobalizedText message="common.lbl.ok" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }}>
                        <div className="fontMessage">{props.getGlobalizedText("common.msg.clickToDeleteQuestion").replace("name", selectetedRowData?.name)}</div><br />
                    </props.Modal>

                    <props.Modal messageService={editHelpMessage.messageService} id="editHelpModal" size="large" title="common.lbl.editHelp" footer={() => {
                        return <>
                            <props.Button type="submit" onClick={() => {
                                setHandlerFlag("edit");
                                if (formProps.values.contentEdit === undefined) {
                                    formProps.setFieldValue("contentEdit", selectetedListData?.content.content);
                                }
                            }
                                //edit(formProps.values)
                            }><GlobalizedText message="common.lbl.saveandclose" /></props.Button>
                            <props.Button //data-dismiss="modal"
                                onClick={() => {
                                    //formProps.setFieldValue("subjectEdit", selectetedListData?.name); formProps.setFieldValue("contentEdit", selectetedListData?.content?.content); 
                                    closeEditHelp();
                                    props.resetForm(formProps, { "subjectEdit": selectetedRowData?.name, "contentEdit": selectetedListData?.content.content });
                                    search(formProps.values);
                                    editHelpMessage.validateProps.clearMessage();

                                }}><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }}>
                        <props.TextControl id="subjectEdit" name="subjectEdit" label="common.lbl.subject" required={true} />
                        <props.ContentEditor onChange={(cev: string) => {
                            /*if (isEmptyObject(formProps.values.contentEdit)) {
                                formProps.setFieldValue("contentEdit", selectetedListData?.content.content);
                            } else {*/
                            formProps.setFieldValue("contentEdit", cev);
                            //}
                        }} {...props} enableMultipleImagesUploader={false} contentId={"faq"} contentTypeId={"faq"} id="contentEdit" name="contentEdit" html={selectetedListData?.content.content} label="common.lbl.coverageDescription" required={true}></props.ContentEditor>
                        <br /><GlobalizedText message="common.msg.requiredFields" />
                    </props.Modal>

                    <props.Modal size="large" id="removeLocationModal" title={() => { return warnTitle("common.lbl.removeHelpLoca") }} footer={() => {
                        return <>
                            <props.Button onClick={() => removeHelpLocation(formProps.values)}><GlobalizedText message="security.lbl.remove" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }}>
                        <div className="fontMessage">
                            <div dangerouslySetInnerHTML={{ __html: props.getGlobalizedText("common.msg.clickToRemoveHelp").replace("label", selectetedListData?.content?.name).replace("location", props.getGlobalizedText(selectetedListData?.component?.name)) }}></div>
                        </div>
                    </props.Modal>
                </Form>
            }
            }
        </Formik >

        <props.Table id="helpTable" select={{
            type: 'single', onSelect: (e: any, rows: number[]) => {
                saveSelectedValue(helpTable.data[rows[0]], config.list[rows[0]]);
                //selected.current = config.list[rows[0]];
                props.clearMessage();
            }
        }} table={helpTable}></props.Table>


    </>
    </React.Fragment >
});