import React, { useEffect, useState, useRef } from 'react';
import { withView, ViewComponentProps } from "../shared/viewcomponent"
import GlobalizedText from '../shared/globalization';
import { HelpBanner } from '../shared/helpbanner';
import { Formik, Form } from 'formik';
import { validate, ajax, print,saveToStorage, getFromStorage, removeFromStorage, generateRefreshSeed, formatNumber, getContextPath } from '../shared/utils';
import * as yup from 'yup';
import { YupSchema } from '../shared/yupschema';
import { PAYMENT_METHOD_CREDITCARD, PAYMENT_METHOD_EFT, translateCreditCardType, updateSelected, Wallet, WalletProps } from './wallet';
import { OnlineHelp } from '../shared/onlinehelp';
import './css/makepayment.css';
import { Message, useMessage } from "../shared/message";
import { FeatureFlags } from '../shared/featureFlags';

export const MakePaymentComponent = withView((props: ViewComponentProps) => {

    const policyNumber = props.getParam("policyNumber");
    const companyCode = props.getParam("companyCode");

    let changePaymentMethodMsg = getFromStorage('changePaymentMethodSuccess', true)

    const varFormPromp = useRef<any>();
    const messageService4Edit = useMessage("oepEdit");

    const [paymentResult, setPaymentResult] = useState<any | null>(null);
    const [config, setConfig] = useState<any | null>(null);

    const [hasPreferredCreditCard, setHasPreferredCreditCard] = useState<boolean>(false);
    const [hasPreferredBankAct, setHasPreferredBankAct] = useState<boolean>(false);

    const [paymentBO, setPaymentBO] = useState<any | null>(null);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>('');

    //flag which used to control credit card part display or not
    const [showCreditCardInfo, setShowCreditCardInfo] = useState<boolean>(false);
    //flag which used to control BankInfo part display or not
    const [showBankInfo, setShowBankInfo] = useState<boolean>(false);

    const [checkSaveInWallet, setCheckSaveInWallet] = useState<boolean>(true);

    const [showSaveInWallet, setShowSaveInWallet] = useState<boolean>(false);

    const [diabledSubmitBtn, setDiabledSubmitBtn] = useState<boolean>(true);

    const [diabledAmountInput, setDiabledAmountInput] = useState<boolean>(true);

    const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);

    const { messageService, validateProps } = useMessage("makePayment");

    const [newCc, setNewCc] = useState<any | null>(null);

    const [newBank, setNewBank] = useState<any | null>(null);

    const [walletProps, setWalletProps] = useState<WalletProps>(props);

    const [count, showWallet] = useState<number>(0);

    const isMessageEventRegistered = useRef<boolean>(false);

    const events = useRef<Array<any>>([]);

    useEffect(() => {
        openWallet();
    }, [count]);

    useEffect(() => {
        if (config === null) {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
            ajax({
                url: '/api/makePayment/view',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber
                },
                success: (res: any) => {

                    setHasPreferredCreditCard(res.hasPreferredCreditCard);
                    setHasPreferredBankAct(res.hasPreferredBankAct);
                    setPaymentBO(res.paymentDto);
                    if (res.accessCreditCardWallet === true && res.accessBankAccountWallet !== true) {
                        setShowCreditCardInfo(true);
                        setShowBankInfo(false);
                        setSelectedPaymentMethod(PAYMENT_METHOD_CREDITCARD);
                        if (res.hasPreferredCreditCard === true) {
                            setDiabledSubmitBtn(false);
                        } else {
                            setDiabledSubmitBtn(true);
                        }
                    }
                    if (res.accessCreditCardWallet !== true && res.accessBankAccountWallet === true) {
                        setShowCreditCardInfo(false);
                        setShowBankInfo(true);
                        setSelectedPaymentMethod(PAYMENT_METHOD_EFT);
                        if (res.hasPreferredBankAct === true) {
                            setDiabledSubmitBtn(false);
                        } else {
                            setDiabledSubmitBtn(true);
                        }
                    }
                    setConfig(res);
                }
            });

            if (changePaymentMethodMsg !== null && changePaymentMethodMsg.changeMethod === 'success') {
                let message = '<span class="gwp-bold">'
                message += props.getGlobalizedText('common.msg.payment.info.update')
                message += '</span>'
                message += '<br/>'
                message += '<div>'
                message += props.getGlobalizedText('common.msg.payment.info.furtherChanges')
                message += '</div>'
                validateProps.showMessage("success", message);
                removeFromStorage("changePaymentMethodSuccess", true);
            }

        } else {
            events.current.forEach((eventHandler) => {
                window.removeEventListener('message', eventHandler);
            });
            events.current.length = 0;
            let eventHanlder = receiveMessageFromIframe.bind({ showWallet: showWallet, setWalletProps: setWalletProps });
            events.current.push(eventHanlder);
            window.addEventListener('message', eventHanlder, false);
            isMessageEventRegistered.current = true;
        }
        return () => {
            events.current.forEach((eventHandler) => {
                window.removeEventListener('message', eventHandler);
            });
            events.current.length = 0;
        }
    }, [config]);

    function receiveMessageFromIframe(event: any) {
        let message = event.data.split(",");
        if (isWalletEnabled) {
            if (message[0] === 'ccCancel' || message[0] === 'ccFail') {
                props.closeModal('#addcciframe', () => props.showModal("#wallet"));
                props.closeModal('#editcciframe', () => props.showModal("#wallet"));
            } else if (message[0] === 'ccSuccess') {
                if (message[1] === 'addCreditCard') {
                    props.closeModal('#addcciframe', () => showCCWallet());
                } else {
                    props.closeModal('#editcciframe', () => showCCWallet());
                }
            }
        } else {
            document.getElementById("cashierModalFrame")?.setAttribute("src", "/mockcashier");
            props.closeModal("#cashierModal");
            if (message[0] === 'paymentSuccess') {
                closePaymentPage();
            }
        }
    }

    const otherAmountSchema = yup.object().shape({
        otherAmount: YupSchema.makePayment.otherAmount
    });

    const formValidate = (values: any): any => {
        if (values.amount === '3') {
            return validate(otherAmountSchema, values, validateProps);
        }
    }

    const formatBankNumber = (tmpObj: any): any => {
        if (tmpObj.bankAccountNumber.length > 4) {
            let numberOfX = tmpObj.bankAccountNumber.length - 4;
            let tmpX = '';
            for (let i = 0; i < numberOfX; i++) {
                tmpX += 'x';
            }
            return tmpX + tmpObj.bankAccountNumber.substring(numberOfX);
        } else {
            return tmpObj.bankAccountNumber;
        }
    }

    const formSubmit = (values: any): void => {

        let selectedAmount = paymentBO.modalPremium;
        if (values.amount === '1') {
            selectedAmount = paymentBO.modalPremium;
        } else if (values.amount === '2') {
            selectedAmount = paymentBO.minimumPremium;
        } else if (values.amount === '3') {
            selectedAmount = values.otherAmount;
        }

        if (isWalletEnabled) {


            let tmpClientProfileID = '';
            if (config.preferredCreditCard !== undefined) {
                tmpClientProfileID = config.preferredCreditCard.clientProfileID;
            }
            if (newCc !== null) {
                tmpClientProfileID = newCc.clientProfileID;
            }

            let tmpCreditCardToken = '';
            if (config.preferredCreditCard !== undefined) {
                tmpCreditCardToken = config.preferredCreditCard.creditCardToken;
            }
            if (newCc !== null) {
                tmpCreditCardToken = newCc.creditCardToken;
            }

            let tmpBankBranchNumber = '';
            if (config.preferredBankAct !== undefined) {
                tmpBankBranchNumber = config.preferredBankAct.bankBranchNumber;
            }
            if (newBank !== null) {
                tmpBankBranchNumber = newBank.bankBranchNumber;
            }

            let tmpBankRoutingNumber = '-1';
            if (config.preferredBankAct !== undefined) {
                tmpBankRoutingNumber = config.preferredBankAct.bankRoutingNumber;
            }
            if (newBank !== null) {
                tmpBankRoutingNumber = newBank.bankRoutingNumber;
            }

            let tmpBankAccountNumber = '0';
            if (config.preferredBankAct !== undefined) {
                tmpBankAccountNumber = config.preferredBankAct.bankAccountNumber;
            }
            if (newBank !== null) {
                tmpBankAccountNumber = newBank.bankAccountNumber;
            }

            ajax({
                url: '/api/makePayment/doSubmitPayment',
                method: 'post',
                params: {
                    companyCode: paymentBO.companyCode,
                    policyNumber: paymentBO.policyNumber,
                    paymentMethod: selectedPaymentMethod,
                    accountHolderName: paymentBO.payor.name,
                    amount: selectedAmount,
                    paymentSelection: selectedPaymentMethod,

                    clientProfileID: tmpClientProfileID,
                    creditCardToken: tmpCreditCardToken,

                    bankBranchNumber: tmpBankBranchNumber,
                    bankRoutingNumber: tmpBankRoutingNumber,
                    bankAccountNumber: tmpBankAccountNumber
                },
                success: (res) => {
                    if (res.result !== 'fail') {
                        setPaymentResult(res);
                        if (res.inAccountControl === "true") {
                            setShowSaveInWallet(false);
                        } else {
                            setShowSaveInWallet(true);
                        }
                        props.showModal("#paymentSuccessDig");
                    } else {
                        setShowErrorMsg(true);
                    }
                }
            });
        } else {
            setShowErrorMsg(false);
            let paymentSelection = '';
            if (selectedAmount === paymentBO.modalPremium) {
                paymentSelection = props.getGlobalizedText('common.lbl.modal.premium');
            }
            else if (selectedAmount === paymentBO.minimumPremium) {
                paymentSelection = props.getGlobalizedText('common.lbl.minimum.premium');
            } else {
                paymentSelection = props.getGlobalizedText('common.lbl.other.amount');
            }
            ajax({
                url: '/api/makePayment/getCashierURL',
                params: {
                    companyCode: paymentBO.companyCode,
                    policyNumber: paymentBO.policyNumber,
                    paymentMethod: selectedPaymentMethod,
                    accountHolderName: paymentBO.payor.name,
                    amount: selectedAmount,
                    paymentSelection: paymentSelection
                },
                success: (res) => {
                    document.getElementById("cashierModalFrame")?.setAttribute("src", getContextPath() + res.cashierURL);
                    props.showModal("#cashierModal");
                }, fail: () => {
                    setShowErrorMsg(true);
                }
            });

        }
    }

    function changePaymentWay(val: any) {

        setSelectedPaymentMethod(val);
        if (val === PAYMENT_METHOD_CREDITCARD) {
            setShowCreditCardInfo(true);
            setShowBankInfo(false);
        } else {
            setShowCreditCardInfo(false);
            setShowBankInfo(true);
        }

        //reset submit status
        if (val === PAYMENT_METHOD_CREDITCARD) {
            if (hasPreferredCreditCard === true || newCc !== null) {
                setDiabledSubmitBtn(false);
            } else {
                setDiabledSubmitBtn(true);
            }
        } else if (val === PAYMENT_METHOD_EFT) {
            if (hasPreferredBankAct === true || newBank !== null) {
                setDiabledSubmitBtn(false);
            } else {
                setDiabledSubmitBtn(true);
            }
        }
    }

    function changePremium(val: any) {
        if (val === '3') {
            setDiabledAmountInput(false);
        } else {
            setDiabledAmountInput(true);
        }
    }

    function getPaymentSelection() {
        if (varFormPromp.current.values.amount === '1') {
            return props.getGlobalizedText("common.lbl.modal.premium");
        } else if (varFormPromp.current.values.amount === '2') {
            return props.getGlobalizedText("common.lbl.minimum.premium");
        } else {
            return props.getGlobalizedText("common.lbl.other.amount");
        }
    }

    function showCCWallet() {
        ajax({
            url: '/api/wallet/loadCreditCards',
            params: {
                companyCode: companyCode,
                policyNumber: policyNumber,
                clientProfileID: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.clientProfileID,
                creditCardToken: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.creditCardToken,
                merchant: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.merchant,
            },
            success: (res) => {
                let preferInfo = new Map();
                let preferedCredit = (newCc !== null && newCc !== undefined) ? newCc : config.preferredCreditCard;
                preferInfo.set("companyCode", companyCode);
                preferInfo.set("policyNumber", policyNumber);
                preferInfo.set("preClientProfileID", (preferedCredit === undefined) ? "" : preferedCredit.clientProfileID);
                preferInfo.set("preCreditCardToken", (preferedCredit === undefined) ? "" : preferedCredit.creditCardToken);
                preferInfo.set("preMerchant", (preferedCredit === undefined) ? "" : preferedCredit.merchant);
                preferInfo.set("preferredBankAct", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.preferredBankAct);
                preferInfo.set("preRoutingNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankRoutingNumber);
                preferInfo.set("preAccountNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankAccountNumber);
                preferInfo.set('messageService4Edit', messageService4Edit);
                preferInfo.set('originPaymentCode', paymentBO.paymentCode);
                let tmpPaymentMethod = (varFormPromp.current.values.paymentWay !== '' ? varFormPromp.current.values.paymentWay : selectedPaymentMethod);
                let walletDto = updateSelected(res.walletDto, tmpPaymentMethod, preferInfo);
                setWalletProps({ ...props, walletDto: walletDto, paymentCode: tmpPaymentMethod, preferInfo: preferInfo, handleWalletResult: handleWalletResult });
                showWallet(generateRefreshSeed());
            }
        });
    }


    function openWallet() {
        props.showModal("#makePayment #wallet");
    }

    function handleWalletResult(walletData: any) {

        if (walletData.get("deleteCc") !== undefined) {
            showCCWallet();
        } else if (walletData.get("deleteBank") !== undefined) {
            showEftWallet();
        } else if (walletData.get("cancelFromWallet") !== undefined) {

            let deletedClientProfileID = walletData.get("deleteProfileID");
            let deletedCreditCardToken = walletData.get("deleteCcToken");
            if (newCc !== null
                && newCc.clientProfileID === deletedClientProfileID
                && newCc.creditCardToken === deletedCreditCardToken) {
                setNewCc(null);
            } else if (hasPreferredCreditCard === true
                && config.preferredCreditCard.clientProfileID === deletedClientProfileID
                && config.preferredCreditCard.creditCardToken === deletedCreditCardToken) {
                setHasPreferredCreditCard(false);
            }

            let bankRoutingNumber = walletData.get("delBankRoutingNumber");
            let bankBranchNumber = walletData.get("delBankBranchNumber");
            let bankAccountNumber = walletData.get("delBankAccountNumber");
            if (newBank !== null && newBank.bankRoutingNumber === bankRoutingNumber
                && newBank.bankBranchNumber === bankBranchNumber
                && newBank.bankAccountNumber === bankAccountNumber) {
                setNewBank(null);
            } else if (hasPreferredBankAct === true
                && config.preferredBankAct.bankRoutingNumber === bankRoutingNumber
                && config.preferredBankAct.bankBranchNumber === bankBranchNumber
                && config.preferredBankAct.bankAccountNumber === bankAccountNumber) {
                setHasPreferredBankAct(false);
            }

        } else if (walletData.get("selectFromWallet") !== undefined) {
            //continue
            if (walletData.get('showCc') !== null) {
                setNewCc(walletData.get('showCc'));
            } else if (walletData.get('showBank') !== null) {
                setNewBank(walletData.get('showBank'));
            }

            //reset submit button status
            let tmpPaymentMethod = (varFormPromp.current.values.paymentWay !== '' ? varFormPromp.current.values.paymentWay : selectedPaymentMethod);
            if (tmpPaymentMethod === PAYMENT_METHOD_CREDITCARD) {
                if (config.hasPreferredCreditCard === true || walletData.get('showCc') !== null) {
                    setDiabledSubmitBtn(false);
                } else {
                    setDiabledSubmitBtn(true);
                }
            } else if (tmpPaymentMethod === PAYMENT_METHOD_EFT) {
                if (hasPreferredBankAct === true || walletData.get('showBank') !== null) {
                    setDiabledSubmitBtn(false);
                } else {
                    setDiabledSubmitBtn(true);
                }
            }
        } else if (walletData.get("saveBank") !== undefined) {
            showEftWallet();
        }
    }

    const showEftWallet = () => {
        ajax({
            url: '/api/wallet/loadBankAccounts',
            params: {
                companyCode: companyCode,
                policyNumber: policyNumber,
                bankBranchNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankBranchNumber,
                bankRoutingNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankRoutingNumber,
                bankAccountNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankAccountNumber
            },
            success: (res) => {
                let preferInfo = new Map();
                preferInfo.set("companyCode", companyCode);
                preferInfo.set("policyNumber", policyNumber);
                preferInfo.set("preClientProfileID", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.clientProfileID);
                preferInfo.set("preCreditCardToken", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.creditCardToken);
                preferInfo.set("preMerchant", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.merchant);
                preferInfo.set("preferredBankAct", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.preferredBankAct);
                preferInfo.set("preRoutingNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankRoutingNumber);
                preferInfo.set("preAccountNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankAccountNumber);
                preferInfo.set('messageService4Edit', messageService4Edit);
                preferInfo.set('originPaymentCode', config.paymentDto.paymentCode);
                let tmpPaymentMethod = (varFormPromp.current.values.paymentWay !== '' ? varFormPromp.current.values.paymentWay : selectedPaymentMethod);
                setWalletProps({ ...props, walletDto: res.walletDto, paymentCode: tmpPaymentMethod, preferInfo: preferInfo, handleWalletResult: handleWalletResult });
                showWallet(generateRefreshSeed());
            }
        });
    }

    const closePaymentPage = (): void => {
        if (checkSaveInWallet === false) {
            let tmpPaymentMethod = (varFormPromp.current.values.paymentWay !== '' ? varFormPromp.current.values.paymentWay : selectedPaymentMethod);
            if (tmpPaymentMethod === PAYMENT_METHOD_EFT && (newBank !== null && newBank !== undefined)) {
                ajax({
                    url: `/api/wallet/deleteBankAccount`,
                    method: 'delete',
                    params: {
                        bankAccountNumber: newBank.bankAccountNumber,
                        bankBranchNumber: newBank.bankBranchNumber,
                        bankRoutingNumber: newBank.bankRoutingNumber,
                        policyNumber: policyNumber,
                        companyCode: companyCode,
                    }
                });
            }
            if (tmpPaymentMethod === PAYMENT_METHOD_CREDITCARD && (newCc !== null && newCc !== undefined)) {
                ajax({
                    url: `/api/wallet/deleteCreditCard`,
                    method: 'delete',
                    params: {
                        creditCardToken: newCc.creditCardToken,
                        merchant: newCc.merchant,
                        clientProfileID: newCc.clientProfileID,
                        policyNumber: policyNumber,
                        companyCode: companyCode,
                    }
                });
            }
        }
        saveToStorage("makepaymentcallback", { 'makepayment': 'success' }, true);
        props.back();
    }

    const isWalletEnabled = props.isFeatureEnabled(FeatureFlags.WALLET);

    return <React.Fragment>
        <Formik initialValues={{
            amount: '1',
            otherAmount: '',
            paymentWay: '',
            checkSaveInWallet: ''
        }}
            validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps => {
                varFormPromp.current = formProps;
                return <Form>
                    <props.Row>
                        <props.Col md="8" sm="7" >
                            <props.PageTitle>
                                <GlobalizedText message={"common.lbl.makePayment"} /> <OnlineHelp {...props} componentId={props.id}></OnlineHelp>
                            </props.PageTitle><br />
                            <props.PageHeader label={() => { return <><GlobalizedText message="common.lbl.policyNumber" />&nbsp;{policyNumber}</> }} />
                        </props.Col>
                        <props.Col md="4" sm="5" xs="12">
                            <HelpBanner className="helpBanner" {...props}></HelpBanner>
                        </props.Col>
                    </props.Row>

                    <props.Row className="gwp-currency-margin currencyMsg">
                        <props.Col md="6">
                            <props.DLI title={() => { return <> <GlobalizedText message="common.lbl.allcurrencyvalue" /> {paymentBO !== null ? paymentBO.currencyDesc : ''} </> }}></props.DLI>
                        </props.Col>
                    </props.Row>
                    <props.Panel>
                        <props.PanelBody>
                            <props.Container>
                                <props.Row>
                                    <props.Col md="6" sm="6" className="gwp-dl">
                                        <props.DLI title="common.lbl.payor" desc={() => {
                                            return <>
                                                <props.Link to={`/personalInformation?companyCode=${companyCode}&policyNumber=${policyNumber}&personalInfoType=2&baseRiderCode=0&roleTypeCode=PAY`} >{paymentBO !== null ? paymentBO.payor.name : ''}</props.Link> </>
                                        }}></props.DLI>
                                        <props.DLI title="common.lbl.effectiveDate" desc={paymentBO !== null ? paymentBO.effectiveDate : ''}></props.DLI>
                                        <props.DLI title="common.lbl.paidToDate" desc={paymentBO !== null ? paymentBO.paidToDate : ''}></props.DLI>
                                    </props.Col>
                                    <props.Col md="6" sm="6" className="gwp-dl">
                                        <props.DLI title={() => {
                                            return <>
                                                <GlobalizedText message="common.lbl.paymentMethod" />
                                                <props.Link condition={config !== null && config.canChangePaymentMethod === true} to={`/changePaymentMethod/${companyCode}/${policyNumber}`}>
                                                    <span className="gwp-icon gwp-icon-change-payment-method gwp-icon-sm iconSpace"></span>
                                                    <GlobalizedText message="common.lbl.changePaymentMethod" />
                                                </props.Link>
                                            </>
                                        }} desc={paymentBO !== null ? paymentBO.paymentMethod : ''}>

                                        </props.DLI>
                                        <props.DLI title="common.lbl.paymentFrequency" desc={paymentBO !== null ? paymentBO.paymentFrequency : ''}></props.DLI>
                                        <props.DLI title="payment.lbl.premiumAmount" desc={paymentBO !== null ? formatNumber(parseFloat(paymentBO.currentPremiumAmount)) : ''}></props.DLI>
                                    </props.Col>
                                </props.Row>
                            </props.Container>
                        </props.PanelBody>
                    </props.Panel>

                    <props.Row>
                        <Message messageService={messageService}></Message>
                    </props.Row>

                    <props.Panel>
                        <props.PanelHeading>
                            <GlobalizedText className='panelHeadSize' message="payment.lbl.paymentOption" />
                        </props.PanelHeading>
                        <props.PanelBody>
                            {showErrorMsg === true &&
                                <div className="alert gwp-page-message alert-danger">
                                    <span className="glyphicon glyphicon-exclamation-sign errorMsg" aria-hidden="true" ></span>
                                    <span><GlobalizedText message="payment.msg.paymentFailure" /></span>
                                </div>
                            }
                            <props.Container>
                                <props.Row>
                                    <props.Col md="6" className="information gwp-information">
                                        <props.DLI title={() => {
                                            return <>
                                                <span className="glyphicon glyphicon-warning-sign"></span>&nbsp;
                                                <GlobalizedText className='normalText' message="common.msg.apply.payment.policy" />
                                            </>
                                        }}></props.DLI>
                                    </props.Col>
                                </props.Row>
                                <props.Row>
                                    <props.Col md="6">
                                        <props.DLI title="common.lbl.payment.amount"></props.DLI>
                                    </props.Col>
                                </props.Row>

                                <props.Row>
                                    <props.Col md="6">
                                        <props.RadioControl id="amount_0" labelClass='normalText'
                                            onClick={(v: any) => {
                                                changePremium(v.target.value);
                                            }}
                                            label={`${paymentBO !== null ? formatNumber(parseFloat(paymentBO.modalPremium)) : ''} ${props.getGlobalizedText("common.lbl.modal.premium")}`} name="amount" value="1"></props.RadioControl>
                                        <props.RadioControl id="amount_1" labelClass='normalText'
                                            onClick={(v: any) => {
                                                changePremium(v.target.value);
                                            }}
                                            label={`${paymentBO !== null ? formatNumber(parseFloat(paymentBO.minimumPremium)) : ''} ${props.getGlobalizedText("common.lbl.minimum.premium")}`} name="amount" value="2"></props.RadioControl>
                                    </props.Col>
                                </props.Row>
                                <props.Row>
                                    <props.Col md="12">
                                        <props.RadioControl labelClass='normalText'
                                            containerClassName="gwp-inline" id="amount_3"
                                            onClick={(v: any) => {
                                                changePremium(v.target.value);
                                            }}
                                            disabled={paymentBO !== null ? !paymentBO.isFlexiblePremium : false}
                                            label="common.lbl.other.amount" name="amount" value="3"></props.RadioControl>
                                        <props.TextControl style={{ width: "100px" }} containerClassName="gwp-inline" disabled={diabledAmountInput} id="otherAmount" name="otherAmount"></props.TextControl>
                                    </props.Col>
                                </props.Row>

                                {(config !== null && (config.accessCreditCardWallet === true && config.accessBankAccountWallet === true) && isWalletEnabled) &&
                                    <>
                                        <props.Row>
                                            <props.Col sm="12" md="6">
                                                <props.HR />
                                            </props.Col>
                                            <props.Col sm="12" md="12">
                                                <b><GlobalizedText message="common.msg.payment.payway" /></b>
                                            </props.Col>
                                        </props.Row>
                                        <props.Row>
                                            {config !== null && config.accessCreditCardWallet === true &&
                                                <props.Col xs="12" sm="12" md="12">
                                                    <props.RadioControl onClick={(v: any) => { changePaymentWay(v.target.value); }}
                                                        id="paymentWayCC" labelClass='normalText' label="common.msg.payment.way.cc" name="paymentWay" value={PAYMENT_METHOD_CREDITCARD}></props.RadioControl>
                                                </props.Col>
                                            }

                                            {config !== null && config.accessBankAccountWallet === true &&
                                                <props.Col xs="12" sm="12" md="12">
                                                    <props.RadioControl onClick={(v: any) => { changePaymentWay(v.target.value); }}
                                                        id="paymentWayBank" labelClass='normalText' label="common.msg.payment.way.et" name="paymentWay" value={PAYMENT_METHOD_EFT}></props.RadioControl>
                                                </props.Col>
                                            }
                                        </props.Row>
                                    </>
                                }
                            </props.Container>
                        </props.PanelBody>
                    </props.Panel>

                    {(showCreditCardInfo === true && isWalletEnabled) && <>
                        <props.Panel>
                            <props.PanelHeading>
                                <div>
                                    <GlobalizedText className='panelHeadSize' message="common.lbl.credit.card.infor" />
                                    <a onClick={() => { showCCWallet() }} className='viewWallet' >
                                        <span className="gwp-icon gwp-icon-update gwp-icon-sm iconWalletSpace"></span>
                                        <GlobalizedText message="common.lbl.vieweditcc" />
                                    </a>
                                </div>
                            </props.PanelHeading>
                            <props.PanelBody className='panelCCBody'>
                                <props.Row>
                                    <props.Col sm="12" xs="12" md="12">
                                        {config !== null && (config.hasPreferredCreditCard === true || newCc !== null) &&
                                            <props.Information message="payment.lbl.precc.below" />
                                        }
                                        {config !== null && config.hasPreferredCreditCard !== true && config.hasTwoCreditCard === true && newCc === null &&
                                            <props.Information message="payment.msg.multipleAccInWt" />
                                        }
                                        {config !== null && config.hasPreferredCreditCard !== true && config.hasTwoCreditCard !== true && newCc === null &&
                                            <props.Information message="common.msg.creditCardRequired" />
                                        }
                                    </props.Col>
                                </props.Row>
                                {
                                    (config !== null && (config.hasPreferredCreditCard === true || newCc !== null)) &&
                                    <props.Row id="rowPreferCard">
                                        <props.Col xs="12" sm="3" md="3" id="bdiPreferCardType">
                                            <dl className="gwp-dl">
                                                <dt><GlobalizedText message="common.lbl.cred.card.type" /> </dt>
                                                <dd id="infoCreditType">{newCc === null ? (config !== null ? config.preferredCreditCard.creditCardTypeDesc : '') : translateCreditCardType(newCc.creditCardType)}
                                                </dd>
                                            </dl>
                                        </props.Col>
                                        <props.Col xs="12" sm="3" md="3" id="bdiPreferCardHold">
                                            <dl className="gwp-dl">
                                                <dt id="tlinfoCreditHolder">
                                                    <GlobalizedText message="common.msg.payment.nameOnCard" />
                                                </dt>
                                                <dd id="infoCreditHolder">{newCc === null ? (config !== null ? config.preferredCreditCard.accountHolderName : '') : newCc.accountHolderName}</dd>
                                            </dl>
                                        </props.Col>
                                        <props.Col xs="12" sm="3" md="3" id="bdiPreferCardNumber">
                                            <dl className="gwp-dl">
                                                <dt>
                                                    <GlobalizedText message="common.lbl.creditcardnumber" />
                                                </dt>
                                                <dd id="infoCreditCardNumber">
                                                    xxxxxxxxxxxx{newCc === null ? (config.preferredCreditCard.ccNumber.toString().length > 4 ? config.preferredCreditCard.ccNumber.substring(config.preferredCreditCard.ccNumber.toString().length - 5) : config.preferredCreditCard.ccNumber) :
                                                        newCc.ccNumber}</dd>
                                            </dl>
                                        </props.Col>
                                        <props.Col xs="12" sm="3" md="3" id="bdiPreferCardExpiration">
                                            <dl className="gwp-dl">
                                                <dt>
                                                    <GlobalizedText message="common.lbl.expirationDate" />
                                                </dt>
                                                <dd id="infoexpirationDate">{newCc === null ? (config.preferredCreditCard.ccExpireDateDesc) : ((newCc.ccExpirationMonth.length < 2 ? '0' : '') + newCc.ccExpirationMonth + '/' + newCc.ccExpirationYear)}</dd>
                                            </dl>
                                        </props.Col>
                                    </props.Row>
                                }
                            </props.PanelBody>
                        </props.Panel>
                    </>
                    }

                    {
                        (showBankInfo === true && isWalletEnabled) && <>
                            <props.Panel>
                                <props.PanelHeading>
                                    <div><GlobalizedText className='panelHeadSize' message="payment.lbl.bankInfo" />
                                        <a onClick={() => { showEftWallet() }} className='viewWallet' >
                                            <span className="gwp-icon gwp-icon-update gwp-icon-sm iconWalletSpace"></span>
                                            <GlobalizedText message="common.lbl.vieweditcc" />
                                        </a>
                                    </div>
                                </props.PanelHeading>
                                <props.PanelBody className='panelCCBody'>
                                    <props.Row>
                                        <props.Col sm="12">
                                            <div>
                                                {config !== null && (hasPreferredBankAct === true || newBank !== null) &&
                                                    <props.Information message="payment.lbl.enter.bankacc.nonext" />
                                                }
                                                {config !== null && hasPreferredBankAct !== true && config.hasTwoBankAct === true && newBank === null &&
                                                    <props.Information message="payment.msg.multipleAccInWt" />
                                                }
                                                {config !== null && hasPreferredBankAct !== true && config.hasTwoBankAct !== true && newBank === null &&
                                                    <props.Information message="payment.lbl.precc.null" />
                                                }
                                            </div>
                                        </props.Col>
                                    </props.Row>
                                    {
                                        config !== null && (hasPreferredBankAct === true || newBank !== null) &&
                                        <props.Row id="preEFTInfoDiv">
                                            <props.Col xs="12" sm="3" md="3">
                                                <props.DL >
                                                    <props.DLI title="common.lbl.bankName" desc={
                                                        newBank === null ? (config.preferredBankAct.bankName) : newBank.bankName
                                                    }></props.DLI>
                                                </props.DL>
                                            </props.Col>
                                            <props.Col xs="12" sm="3" md="3">
                                                <props.DL >
                                                    <props.DLI title='common.msg.payment.nameOnAccount' desc={
                                                        newBank === null ? (config.preferredBankAct.accountHolderName) : newBank.accountHolderName
                                                    }></props.DLI>
                                                </props.DL>
                                            </props.Col>
                                            <props.Col xs="12" sm="3" md="3">
                                                <props.DL >
                                                    <props.DLI title="payment.lbl.accountNumber" desc={formatBankNumber(newBank === null ? config.preferredBankAct : newBank)}></props.DLI>

                                                </props.DL>
                                            </props.Col>
                                            <props.Col xs="12" sm="3" md="3">
                                                <props.DL >
                                                    <props.DLI title="payment.lbl.accountType" desc={
                                                        newBank === null ? (config.preferredBankAct.accountTypeDesc) : newBank.accountTypeDesc
                                                    }></props.DLI>
                                                </props.DL>
                                            </props.Col>
                                        </props.Row>
                                    }
                                </props.PanelBody>
                            </props.Panel>
                        </>
                    }

                    <props.Row>
                        <props.Col sm="12">
                            {isWalletEnabled &&
                                <props.Button type="submit" disabled={diabledSubmitBtn}><GlobalizedText message="common.button.submit" /></props.Button>
                            }
                            {
                                !isWalletEnabled &&
                                <props.Button type="submit"><GlobalizedText message="common.button.submit" /></props.Button>
                            }
                            <props.Button onClick={props.back}><GlobalizedText message="common.lbl.close" /></props.Button>
                        </props.Col>
                    </props.Row>

                    {/* Modal for submit payment successfully */}
                    <props.Modal title="payment.tlt.accepted" size='large' id="paymentSuccessDig" footer={() => {
                        return <>
                            <p className='closeinDialog'><props.Button printVisible={false} onClick={closePaymentPage} data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button></p>
                        </>
                    }} >
                        <props.Row>
                            <props.Col xs="12" sm="12" md="12">
                                <div className="alert alert-success gwp-page-message paymentsuccdlgdv" role="alert" >
                                    <span aria-hidden="true" className="glyphicon glyphicon-ok paymentglyphicon successicon"></span>
                                    <GlobalizedText message="common.msg.complete.thank.you.for.payment" />
                                    <span className="printPlace">
                                        <props.Span className="gwp-icon gwp-icon-print gwp-icon-sm " ></props.Span>
                                        <props.LinkButton printVisible={false} onClick={() => print('',{cssSelector:"#paymentSuccessDig .modal-content", size: 'A5', orientation: 'landscape' })}><GlobalizedText message="common.print" /></props.LinkButton>
                                    </span>
                                </div>
                            </props.Col>
                        </props.Row>
                        <props.HR className="modalHr" />
                        <props.Row className="paymentsucst" >
                            <props.Col xs="12" sm="12" md="12">
                                <GlobalizedText message="common.msg.payment.infor.show.below" />
                            </props.Col>
                        </props.Row>
                        <props.Row >
                            <props.Col xs="12" sm="12" md="12">&nbsp;</props.Col>
                        </props.Row>
                        <props.Row className="modalContent" id="paymentInformation" data-print-style="margin:20px;">
                            <div className="gwp-page-title paddingl15">
                                <GlobalizedText message="common.lbl.payment.information" />
                            </div>
                            <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                                <props.DLI title="common.lbl.confir.num" desc={paymentResult !== null ? paymentResult.transActionId : ''}></props.DLI>
                                <props.DLI title="common.lbl.policyNumber" desc={policyNumber}></props.DLI>
                                <props.DLI title="common.lbl.payment.selection" desc={getPaymentSelection()}></props.DLI>
                                <props.DLI title="common.lbl.payment.amount" desc={paymentResult !== null ? paymentResult.amount : ''}></props.DLI>
                                <props.DLI title="common.lbl.paymentMethod" desc={selectedPaymentMethod === PAYMENT_METHOD_CREDITCARD ? 'Credit Card' : 'Bank Account'}></props.DLI>
                                <props.DLI title="common.lbl.posting.date" desc={paymentResult !== null ? paymentResult.postingDate : ''}></props.DLI>
                            </props.DL>
                        </props.Row>
                        <props.Div condition={showSaveInWallet}>
                            <props.HR className="paymentsuchr" />
                            <props.Row className="paymentsucst">
                                <props.Col xs="12" sm="12" md="12">
                                    <div className="form-group saveCheckboxDiv" >
                                        <props.CheckboxControl id="checkSaveInWallet" name="checkSaveInWallet"
                                            checked={checkSaveInWallet ? true : false}
                                            onChange={() => { setCheckSaveInWallet(!checkSaveInWallet); }}></props.CheckboxControl>&nbsp;
                                        <GlobalizedText message="common.msg.payment.walletfutureuse" />
                                    </div>
                                </props.Col>
                            </props.Row>
                        </props.Div>
                    </props.Modal>
                </Form>
            }
            }
        </Formik>

        {/**
         * if is wallet enabled, then show wallet component.
         */
            (walletProps.preferInfo !== undefined && isWalletEnabled) &&
            <Wallet {...walletProps} />
        }
        {!isWalletEnabled &&
            <props.Modal size="large" id="cashierModal">
                <iframe style={{ marginLeft: '15px', width: '96%', border: 'none', height: '550px' }} title="Mock Cashier" id="cashierModalFrame" src="/mockcashier" />
            </props.Modal>
        }
    </React.Fragment>
})