import React, { useEffect, useMemo, useRef, useState } from "react"
import GlobalizedText from "../../globalization"
import { downloadFile, generateUUID } from "../../utils"
import { ViewComponentProps } from "../../viewcomponent"

export interface PDFViewerProps extends ViewComponentProps {
    containerId?: string;
    title?: string;
    onClose?: string;
}

export interface PDFViewerObject {
    Component: () => JSX.Element;
    showPDF: (url?: string) => void;
}

export const usePDFViewer = (props: PDFViewerProps): PDFViewerObject => {
    const [pdfURL, setPDFURL] = useState<string>();
    const [count, setCount] = useState<number>(0);
    const c = useRef<number>(0);
    const id = useMemo(() => {
        return props.containerId === undefined ? generateUUID() : props.containerId
    }, [props.containerId]);
    useEffect(() => {
        if (pdfURL !== undefined) {
            if (document.body.getBoundingClientRect().width < 768) {
                downloadFile(pdfURL);
            } else {
                if (id !== undefined) {
                    props.showModal(`#${id}`);
                }
            }
        }
    }, [count]);
    return {
        Component: () => {
            return <props.Modal id={id} size="large" title={props.title === undefined ? "common.lbl.pdfFile" : props.title}
                footer={() => {
                    return <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                }}
            >
                <object style={{ width: "100%", height: "400px" }} type='application/pdf' data={pdfURL === undefined ? "" : pdfURL}>
                    <props.Div style={{ height: "400px" }}>
                        Sorry, you can't review your form for PDF Reader plugin NOT installed on this browser.<br />
                Please visit <a href='http://get.adobe.com/reader' rel="noopener noreferrer" target='_blank'><GlobalizedText message="common.lbl.clickThisLink" /></a> to get the latest version of PDF Reader.<br />
                        <props.LinkButton onClick={() => { downloadFile(pdfURL === undefined ? props.url : pdfURL, false, (message: string) => { alert(message) }) }}><GlobalizedText message="common.lbl.clickThisLink" /></props.LinkButton> to download the PDF
            </props.Div>
                </object>
            </props.Modal>
        },
        showPDF: (url?: string) => {
            if (url === undefined) {
                if (document.body.getBoundingClientRect().width < 768) {
                    downloadFile(url ? url : props.url ? props.url : '');
                } else {
                    if (id !== undefined && pdfURL !== undefined) {
                        props.showModal(`#${id}`);
                    } else {
                        console.error("PDF URL is not initialized.");
                    }
                }
            } else {
                setPDFURL(url);
                c.current = c.current + 1;
                setCount(c.current);
            }
        }
    }
}