import React, { useContext, useEffect } from 'react';
import { GlobalizationContext } from '../../globalization';
import { $ } from '../../utils';

export interface TooltipProps {
    error?: string,
    elementName: string
}
function getRealElement(el: any) {
    if (el.parent().hasClass("gwp-content-editor")) {
        el = el.parent().find(".tox.tox-tinymce");
    }
    else if (el.parent().hasClass("gwp-input")) {
        // resolve field level error message for react-widget.
        if (el.parent().parent().hasClass("rw-widget")) {
            el = el.parent();
        } else if (el.parent().parent().hasClass("gwp-input")) {
            el = el.parent().parent();
        }
        el = el.parent();
    }
    return el;
}

function clearErrors(el: any) {
    if (el.hasClass("has-error")) {
        el.removeClass("has-error");
    }
    if (el.parent().hasClass("has-error")) {
        el.parent().removeClass("has-error");
    }
    if (el.children().hasClass("has-error")) {
        el.children().removeClass("has-error");
    }
    if (el.data("bs.tooltip") !== undefined && el.data("bs.tooltip")["enabled"] === true) {
        el.tooltip("disable");
    }
    if (el.attr("type") === "radio" || el.attr("type") === "checkbox") {
        el.each((i: number, e: any) => {
            let ele = $(e);
            $("[for='" + ele.attr("id") + "']").css("color", "");
        });
    }
}

export const Tooltip = ({ error, elementName }: TooltipProps) => {
    const globalization = useContext(GlobalizationContext);
    // workaround to resolve if has []. in name.
    elementName = elementName.replace("[", "\\[").replace("]", "\\]").replace(".", "\\.");
    let el = getRealElement($("[name=\"" + elementName + "\"]"))

    useEffect(() => {
        if (el.length !== 0 && error) {
            let msg = globalization === null || globalization[error] === undefined ? error : globalization[error];
            let bstData;
            if (el.hasClass("gwp-input")) {
                el.addClass("has-error");
            } else {
                // resolve field level error message for react-widget.
                if (el.hasClass("rw-widget")) {
                    el.find("[name*=" + elementName + "]").parent().addClass("has-error");
                } else if (el.parent().hasClass("gwp-content-editor")) {
                    // handle error for content editor
                    el.addClass("has-error");
                } else {
                    el.parent().addClass("has-error");
                }
            }
            if (el.attr("type") === "radio" || el.attr("type") === "checkbox") {
                el.each((i: number, e: any) => {
                    let ele = $(e);
                    $("[for='" + ele.attr("id") + "']").css("color", "#a94442");
                    let placeTo = ele.offset().left + ele.width() + 200 >= $("body").width() || $("body").width() <= 768 ? "bottom" : "right";
                    if (ele.data("bs.tooltip") === undefined) {
                        // add trigger:"click" for debugging.
                        ele.tooltip({ title: msg, placement: placeTo, template: `<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>` });
                    } else if (ele.data("bs.tooltip")["enabled"] === false) {
                        bstData = ele.data("bs.tooltip");
                        bstData.options.title = msg;
                        ele.data("bs.tooltip", bstData);
                        ele.tooltip("enable");
                    } else {
                        bstData = ele.data("bs.tooltip");
                        bstData.options.title = msg;
                        ele.data("bs.tooltip", bstData);
                    }
                });

            } else {
                let placeTo = el.offset().left + el.width() + 200 >= $("body").width() || $("body").width() <= 768 ? "bottom" : "right";
                if (el.data("bs.tooltip") === undefined) {
                    // add trigger:"click" for debugging.
                    el.tooltip({ title: msg, placement: placeTo, template: `<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>` });
                } else if (el.data("bs.tooltip")["enabled"] === false) {
                    bstData = el.data("bs.tooltip");
                    bstData.options.title = msg;
                    el.data("bs.tooltip", bstData);
                    el.tooltip("enable");
                } else {
                    bstData = el.data("bs.tooltip");
                    bstData.options.title = msg;
                    el.data("bs.tooltip", bstData);
                }
            }
        }
    });

    if (el.length === 0 || error === undefined) {
        clearErrors(el);
        return <></>;
    }

    return <></>;
};