import React, { useEffect, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, generateUUID, $, removeClass, addClass, toggle } from '../shared/utils';
import { ViewComponentProps, withView } from "../shared/viewcomponent";
import { isEmptyStr } from '../shared/utils';
import './css/faq.css';
import { FAQ } from './types/faq';

export const FaqComponent = withView((props: ViewComponentProps) => {
    const [categories, setCategories] = useState<Array<FAQ.Category>>();
    const companyCode = props.getQueryParam("companyCode");
    const groupNumber = props.getQueryParam("groupNumber");
    const accountNumber = props.getQueryParam("accountNumber");
    const clientNumber = props.getQueryParam("clientNumber");
    let urlParams = "";
    if (!isEmptyStr(companyCode))
        urlParams = `&companyCode=${companyCode}&groupNumber=${groupNumber}&accountNumber=${accountNumber}&clientNumber=${clientNumber}`
    useEffect(() => {
        if (categories === undefined) {
            const params: any = {site: null};
            params["companyCode"] = companyCode;
            params["groupNumber"] = groupNumber;
            params["accountNumber"] = accountNumber;
            params["clientNumber"] = clientNumber;
            ajax({
                url: '/api/cms/faq/category/list',
                method: 'get',
                params: params,
                success: (res) => {
                    setCategories(res.categories);
                }
            });
        }
    }, [categories])
    return <>
        <props.Div className="second-level-title"><GlobalizedText message="common.lbl.answerToQuestions" /><props.Img src="/static/themes/blue/images/icon_FAQ.png"></props.Img></props.Div>

        <ul>
            {(categories !== undefined && categories.length > 0) && categories.map((category: FAQ.Category, index: number) => {
                return <li key={index}>
                    <props.Img src="/static/themes/blue/images/icon_item.png" /><props.Link to={`/faqQuestion?category=${category.id}${urlParams}`}>{category.name}</props.Link>
                </li>
            })}
        </ul>
    </>
});


export const FaqQuestionComponent = withView((props: ViewComponentProps) => {
    const [questions, setQuestions] = useState<Array<FAQ.Question>>();

    useEffect(() => {
        if (questions === undefined) {
            const params: any = {site: null};
            params["companyCode"] = props.getQueryParam("companyCode");
            params["groupNumber"] = props.getQueryParam("groupNumber");
            params["accountNumber"] = props.getQueryParam("accountNumber");
            params["clientNumber"] = props.getQueryParam("clientNumber");
            params["categoryId"] = props.getParam("category");
            ajax({
                url: '/api/cms/faq/question/list',
                params: params,
                success: (res) => {
                    props.setTitle(res.categoryName);
                    setQuestions(res.questions);
                }
            });
        }
    }, [questions]);
    return <>
        <props.Div className="questions">
            {questions !== undefined && questions.map((question: FAQ.Question, index: number) => {
                let qid = "#question_" + index;
                function onCollapseShow() {
                    removeClass(qid, "question-collapsed");
                    addClass(qid, "question-expanded");
                }
                function onCollapseHide() {
                    removeClass(qid, "question-expanded");
                    addClass(qid, "question-collapsed");
                }
                return <props.Row key={index}>
                    <props.Col sm="12" key={index}>
                        <props.Div className="questionContainer" >
                            <props.Div id={`question_${index}`} className="question question-collapsed" onClick={() => {
                                toggle("#editor_" + index, onCollapseShow, onCollapseHide);
                            }}><props.Span className="questionContent">{question.question}</props.Span></props.Div>
                            <props.Row>
                                <props.Col sm="8">
                                    <props.Div style={{ marginLeft: '25px' }} className="collapse answer" id={"editor_" + index}>
                                        {question.answer}
                                    </props.Div>
                                </props.Col>
                            </props.Row>
                        </props.Div>
                    </props.Col>
                </props.Row>
            })
            }
        </props.Div>
        <props.Row>
            <props.Col sm="12">
                <props.Button onClick={() => props.back()}><GlobalizedText message="common.lbl.backTo" /> <GlobalizedText message="common.lbl.faqCategory" /></props.Button>
            </props.Col>
        </props.Row>
    </>
});