
import { LabelProps, Label } from "./label";
import React, { useContext } from 'react';
import { FieldArrayRenderProps, useField } from "formik";
import GlobalizedText, { GlobalizationContext } from "../../globalization";
import { Tooltip } from "./tooltip";
import { FormGroup, FormGroupProps } from "./formgroup";
import { copyObjectExcept, getGlobalizedText } from "../../utils";
import { ConditionProps, withCondition } from "./shared-component";
import { DEFAULT_FORMAT_TYPE, FORMAT_MAPPER, FORMAT_TYPE } from "./govtidinput";

export type LabelFieldDisplayMode = 'vertical' | 'horizontal'
export interface FieldProps extends ConditionProps {
    id?: string;
    name: string;
    className?: string;
    onChange?: (e: any) => void;
    placeholder?: string;
    /**
     * indicate whether the label is left to the field or vertically displayed.
     */
    displayMode?: LabelFieldDisplayMode;
}

export interface ReadOnlyProps {
    readOnly?: boolean;
}

export interface FieldControlProps extends FieldProps, LabelProps, FormGroupProps {
    label?: string;
    /**
     * Extension of the label, will be append to the right of the label, the extension label will be wrapped by a pair of brackets
     */
    extLabel?: string;
    required?: boolean;
    containerClassName?: string;
    labelClass?: string;
}

export interface WrapperControlProps extends FieldControlProps {
    arrayHelpers?: FieldArrayRenderProps
    [key: string]: any;
}

/**
 * This high order component is used to render a form control with error message.
 * Must be used along with Formik, error message would be displayed when getting field error from Formik.
 * @param WrapperControl 
 */
export function withErrorControl<T extends WrapperControlProps>(WrapperControl: (props: T) => JSX.Element): (props: T) => JSX.Element {

    return withCondition(function (props: T): JSX.Element {
        const globalization = useContext(GlobalizationContext);
        let componentId: string | undefined = props.id;
        let newProps;
        let fieldConfig: any;
        let error;
        try {
            fieldConfig = useField(props.name);
            error = fieldConfig[1].error;
            if (error === undefined && props.arrayHelpers !== undefined && props.arrayHelpers.form.errors !== undefined) {
                error = props.arrayHelpers.form.errors[props.name]
            }
            newProps = copyObjectExcept({
                // remove onBlur handler for the control.
                ...fieldConfig[0], ...props, ...{ condition: undefined, required: undefined }, ...{ setValue: fieldConfig[2].setValue, "data-form-value": fieldConfig[0].value, onBlur: (e: any) => { fieldConfig[0].onBlur(e); if (props.onBlur !== undefined) { props.onBlur(e) } }, onChange: (e: any) => { fieldConfig[0].onChange(e); if (props.onChange !== undefined) { props.onChange(e) } } }
            }, false, "containerClassName", "arrayHelpers", "labelClass", "displayMode", "readonly");
            if (newProps.placeholder !== undefined) {
                newProps.placeholder = getGlobalizedText(globalization, newProps.placeholder);
            }
            if (props.displayMode === 'vertical') {
                newProps.className = newProps.className ? (newProps.className + " gwp-inline") : " gwp-inline"
            }
        } catch (e) {
            console.warn(`Form Control [${props.name}] is not embed in a formik component, validation would not be working.`)
            // this is not a form component, so the error message may not be working.
            newProps = copyObjectExcept(props, false, "containerClassName", "arrayHelpers", "labelClass", "displayMode", "readonly");
        }
        return <>
            <FormGroup containerClassName={props.containerClassName}>
                {props.label !== undefined &&
                    <><Label htmlFor={props.htmlFor !== undefined ? props.htmlFor : componentId} required={props.required} readonly={props.readonly}><GlobalizedText className={props.labelClass} message={props.label} />
                        {(props.showFormat !== false && props.dateformat !== undefined) ? ` (${props.dateformat.toLowerCase()})` : ``}
                        {(() => {
                            // add govt id type right to the input. 
                            if (props.showFormat !== false && props.formatType !== undefined) {
                                let formatType: FORMAT_TYPE = props.formatType;
                                if (formatType === undefined) {
                                    formatType = DEFAULT_FORMAT_TYPE;
                                }

                                return <>({FORMAT_MAPPER[formatType]})</>
                            }
                        })()}
                        {props.extLabel &&
                            <>{` (`}<GlobalizedText className={props.labelClass} message={props.extLabel} />{')'}</>
                        }
                    </Label>

                    </>
                }
                <WrapperControl {...newProps}></WrapperControl>
                {fieldConfig !== undefined &&
                    <Tooltip error={error} elementName={props.name}></Tooltip>
                }
            </FormGroup>
        </>
    });
}

export function withOutFormGroupControl<T extends WrapperControlProps>(WrapperControl: (props: T) => JSX.Element): (props: T) => JSX.Element {
    return function (props: T): JSX.Element {
        const fieldConfig = useField(props.name);
        // remove onBlur handler for the control.
        let fieldProps = { ...fieldConfig[0], ...{ onBlur: undefined } };
        return <>
            <WrapperControl {...fieldProps} {...props}></WrapperControl>
            {fieldConfig[1].error && <Tooltip error={fieldConfig[1].error} elementName={props.name}></Tooltip>}
        </>
    }
}