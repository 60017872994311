import React, { useEffect, useState } from 'react';
import { useTable } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { Message, useMessage } from '../shared/message';
import { ajax, formatNumber, getFromStorage, isEmptyStr, movePageToTop, removeFromStorage, trim } from '../shared/utils';
import { ViewComponentProps, withView } from "../shared/viewcomponent";
import { AgentsInfoPanel } from './agentsinfopanel';
import './css/policydetail.css';

export const PolicyDetailComponent = withView((props: ViewComponentProps) => {
    movePageToTop();

    let policyNumber = trim(props.getParam("policyNumber"));
    let companyCode = trim(props.getParam("companyCode"));
    let linkStyle = { margin: '5px 0', display: 'inline-block' };
    let changePaymentMethodMsg = getFromStorage('changePaymentMethodSuccess', true)
    let makePaymentCallBack = getFromStorage('makepaymentcallback', true)
    let changeSecondaryAddresseeCallBack = getFromStorage('changeSecondaryAddresseeCallBack', true)

    const [config, setConfig] = useState<any | null>(null);
    const [benefits, setBenefits] = useState<Array<any>>([]);
    const [loanTax, setLoanTax] = useState<Array<any>>([]);
    const [loanNoneTax, setLoanNoneTax] = useState<Array<any>>([]);
    const [showUpdateBeneLink, setShowUpdateBeneLink] = useState<number>(0)
    const [showViewBeneLink, setShowViewBeneLink] = useState<number>(0)
    const { messageService, validateProps } = useMessage("policyDetail");
    const showConsolidationBilling = props.isFeatureEnabled("base.consolidated.billing");
    useEffect(() => {
        if (config === null) {
            loadPolicyDetail(companyCode, policyNumber)
        }
    }, [config]);

    function loadPolicyDetail(returnedCompanyCode: any, returnedPolicyNumber: any) {
        ajax({
            url: '/api/policyDetail/view',
            params: {
                policyNumber: returnedPolicyNumber,
                companyCode: returnedCompanyCode
            },
            showMask: true,
            success: (res: any) => {
                setConfig(res);
                setBenefits(res.policyDetail.benefitList);
                if (res.policyDetail.loanDetailTaxQualifiedList !== undefined && res.policyDetail.loanDetailTaxQualifiedList.length > 0) {
                    setLoanTax(res.policyDetail.loanDetailTaxQualifiedList);
                }
                if (res.policyDetail.loanDetailNoneTaxQualifiedList !== undefined && res.policyDetail.loanDetailNoneTaxQualifiedList.length > 0) {
                    setLoanNoneTax(res.policyDetail.loanDetailNoneTaxQualifiedList);
                }
                if ((res.policyDetail.primaryBeneficiary !== undefined && res.policyDetail.primaryBeneficiary.length > 0) && (res.policyDetail.contigentBeneficiary !== undefined && res.policyDetail.contigentBeneficiary.length > 0)) {
                    if (res.canChangeBeneficiary === true) {
                        setShowUpdateBeneLink(1)

                    } else if (res.canChangeBeneficiary === false) {
                        setShowViewBeneLink(1)
                    }
                }
                if (changePaymentMethodMsg !== null && changePaymentMethodMsg.changeMethod === 'success') {
                    let message = '<span class="gwp-bold">'
                    message += props.getGlobalizedText('common.msg.payment.info.update')
                    message += '</span>'
                    message += '<br/>'
                    message += '<div>'
                    message += props.getGlobalizedText('common.msg.payment.info.furtherChanges')
                    message += '</div>'
                    validateProps.showMessage("success", message);
                    removeFromStorage("changePaymentMethodSuccess", true);
                }
                if (makePaymentCallBack !== null && makePaymentCallBack.makepayment === 'success') {
                    let message = '<span class="gwp-bold">'
                    message += props.getGlobalizedText('payment.tlt.paysuccesst')
                    message += '</span>'
                    message += '<br/>'
                    message += '<div>'
                    message += props.getGlobalizedText('payment.tlt.paysuccessc')
                    message += '</div>'
                    validateProps.showMessage("success", message);
                    removeFromStorage('makepaymentcallback', true)
                }
                if (changeSecondaryAddresseeCallBack !== null && changeSecondaryAddresseeCallBack.result === 'success') {
                    props.showMessage('success', 'addsecaddr.msg.update.success')
                    removeFromStorage('changeSecondaryAddresseeCallBack', true)
                }
            },
            fail: (res: any, errorMsg: any) => {
                props.showMessage('error', errorMsg)
            },
            error: (error: any) => {
                props.showMessage('error', 'msg.err.sys.failed')
            }
        });
    }

    function deleteSecaddr(returnedCompanyCode: any, returnedPolicyNumber: any, clientNumber: any) {
        ajax({
            url: '/api/insurance/deleteSecondaryAddressee',
            method: 'DELETE',
            params: {
                companyCode: trim(returnedCompanyCode),
                policyNumber: trim(returnedPolicyNumber),
                clientNumber: trim(clientNumber)
            },
            success: (res: any) => {
                loadPolicyDetail(returnedCompanyCode, returnedPolicyNumber)
                props.closeModal("#confirmDelete")
                props.showMessage('success', 'addsecaddr.msg.delete.success')
            }, fail: (res: any, errorMsg: any) => {
                props.showMessage('error', errorMsg)
            }, error: (error: any) => {
                props.showMessage('error', error.response.data.message)
            }
        })
    }
    function handerBeneURL() {
        removeFromStorage4BeneficiaryView()
        if (config.policyDetail.owner2 !== undefined) {
            props.showModal('#changeBene2Owners')
        } else {
            props.next(`/beneficiaryViewInformation?companyCode=${companyCode}&policyNumber=${policyNumber}&fromPage=${'from_policydetail'}`)
        }
    }

    function returnFooter() {
        return <>
            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
        </>
    }

    function removeFromStorage4BeneficiaryView() {
        removeFromStorage('hasPBDistributeEvent', true)
        removeFromStorage('hasCBDistributeEvent', true)
    }

    if (config === null) {
        return <></>;
    }

    const benefitsList = useTable({
        id: "benefitsListTable",
        delayAdjust: true,
        option: {
            paging: false
        },
        table: {
            columns: [
                { className: "gwp-dt-detail-control", sortable: false },
                { title: "pd.lbl.productname", name: "productName", onClick: (e: any, row: any) => { props.next(`/benefitDetail?companyCode=${companyCode}&policyNumber=${policyNumber}&baseRiderCode=${row.baseRiderCode}&planCode=${row.planCode}`) }, render: (data: any, type: any, row: any, meta: any) => { return `<a >${row.productName}</a>` }, sortable: false },
                {
                    title: "common.lbl.insured", name: "insured",
                    onClick: (e: any, row: any) => {
                        props.next(`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'INS'}&policyNumber=${policyNumber}&baseRiderCode=${row.baseRiderCode}`)
                    },
                    render: (data: any, type: any, row: any, meta: any) => {
                        if (config.showNameAsLink === true) {
                            return `<a >${row.insured.replaceAll("<wbr/>", "")}</a>`
                        } else { return `${row.insured.replaceAll("<wbr/>", "")}` }
                    }, sortable: false
                },
                {
                    title: "common.lbl.status", name: "status",
                    onClick: (e: any, row: any) => {
                        props.next(`/benefitClaimDetail?companyCode=${companyCode}&policyNumber=${policyNumber}&planCode=${trim(row.planCode)}&baseRiderCode=${row.baseRiderCode}`)
                    },
                    render: (data: any, type: any, row: any, meta: any) => {
                        if (row.statusCode === "D" || row.statusCode === "PD") {
                            return `<a >${row.status}</a>`
                        } else { return `${row.status}` }
                    }, sortable: false
                },
                { title: "common.lbl.effectiveDate", name: "effectiveDate", sortable: false },
                { title: "common.lbl.coverageAmount", name: "coverageAmount", render: (data: any, type: any, row: any, meta: any) => { if (row.insuranceType !== "F" && row.insuranceType !== "H" && row.insuranceType !== "A") { return formatNumber(row.coverageAmount) } else { return `` } }, sortable: false, type: 'numeric' },
                { title: "pd.lbl.netbenefitvalue", name: "netBenefitValue", sortable: false, type: 'numeric' },
            ],
            data: benefits
        }
    });
    const benefitListTable = <benefitsList.Component />;

    return <>
        <props.Row>
            <props.Col xs="12" md="9">
                <props.PageHeader label={() => {
                    return <><GlobalizedText message="common.lbl.policyNumber" /> {policyNumber}</>
                }} rightLabel={() => {
                    return <> <GlobalizedText message="common.lbl.currentDate" /><time>{" " + config.policyDetail.asOfDate}</time></>
                }} />
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col xs="12" md="8">
                <props.DL style={{ marginBottom: "0px" }} id="owner1" className="dl-horizontal">
                    <props.DLI title={() => { return <><GlobalizedText message="common.lbl.owner" />{config.policyDetail.owner2 !== undefined && <> 1</>}</> }}
                        desc={() => {
                            return <>
                                <props.Link condition={config.showNameAsLink === true} to="" onClick={() => {
                                    props.next(`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'OW1'}&policyNumber=${policyNumber}&baseRiderCode=${0}`)
                                }}>{config.policyDetail.owner.name.replaceAll("<wbr/>", "")}</props.Link>
                                <props.Div condition={config.showNameAsLink === false}>{config.policyDetail.owner.name.replaceAll("<wbr/>", "")}</props.Div>
                                <br />
                                <props.Div id="changeOW1DIV" condition={config.showChangeOwnerLink === true} style={linkStyle}>
                                    <props.Link to="" className="gwp-bold" onClick={() => {
                                        props.showModal("#changeOwnerDialog")
                                    }}>
                                        <props.Icon type="person-v2" label="changeow.lbl.title"></props.Icon>
                                    </props.Link>
                                </props.Div>
                            </>
                        }}
                    />
                    <props.DLI title="pd.lbl.address" desc={() => {
                        return <>
                            {config.policyDetail.owner.address.split("<br/>").map((o: any, i: number) => { return <React.Fragment key={i}>{o}<br /></React.Fragment> })}
                            <props.Div className="test1" style={{ margin: "10px 0 10px 0" }}>
                                <props.Link condition={config.canUpdatePersonalInfo === true && (config.policyDetail.owner.currentUser === true || config.isAdmin === true) && config.showNameAsLink} to="" onClick={() => {
                                    props.next(`/personalInformation?isUpdateMode=${true}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'OW1'}&policyNumber=${policyNumber}&baseRiderCode=${0}`)
                                }}>
                                    <props.Icon type="person" label="common.lbl.updatePersonalInfo"></props.Icon>
                                </props.Link>
                            </props.Div>
                        </>
                    }} />
                </props.DL>
                <br />
                <props.DL id="owner2" style={{ marginBottom: "0px" }} condition={config.policyDetail.owner2 !== undefined} className="dl-horizontal">
                    <props.DLI title={() => { return <><GlobalizedText message="common.lbl.owner" />{" 2"}</> }}
                        desc={() => {
                            return <>
                                <props.Div condition={config.showNameAsLink === true}><props.Link to="" onClick={() => {
                                    props.next(`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'OW2'}&policyNumber=${policyNumber}&baseRiderCode=${0}`)
                                }}>{config.policyDetail.owner2.name.replaceAll("<wbr/>", "")}</props.Link></props.Div>
                                <props.Div condition={config.showNameAsLink === false}>{config.policyDetail.owner2.name.replaceAll("<wbr/>", "")}</props.Div>
                            </>
                        }}
                    />
                    <props.DLI title="pd.lbl.address" desc={() => {
                        return <>
                            {config.policyDetail.owner2.address.split("<br/>").map((o: any, i: number) => { return <React.Fragment key={i}>{o}<br /></React.Fragment> })}
                            <props.Div className="test1" style={{ margin: "10px 0 10px 0" }}>
                                <props.Link condition={config.canUpdatePersonalInfo === true && (config.policyDetail.owner2.currentUser === true || config.isAdmin === true) && config.showNameAsLink === true} to={`/personalInformation?isUpdateMode=${true}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'OW2'}&policyNumber=${policyNumber}&baseRiderCode=${0}`}>
                                    <props.Icon type="person" label="common.lbl.updatePersonalInfo"></props.Icon>
                                </props.Link>
                            </props.Div>
                        </>
                    }} />
                </props.DL>
                <props.DL condition={config.allowAccessSecAddressee === true} className="dl-horizontal">
                    <props.DLI title="pd.lbl.secaddr.name"
                        desc={() => {
                            return <>
                                {config.showNameAsLink === true &&
                                    <props.Div><props.Link to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'SEC'}&policyNumber=${policyNumber}&baseRiderCode=${0}`}>{config.policyDetail.secondaryAddressee.name.replaceAll("<wbr/>", "")}</props.Link></props.Div>
                                }
                                {config.showNameAsLink === false &&
                                    <props.Div>{config.policyDetail.secondaryAddressee.name.replaceAll("<wbr/>", "")}</props.Div>
                                }
                                {config.allowUpdateSecAddressee === true &&
                                    <props.Div style={linkStyle}>
                                        <props.Link className="gwp-bold" to="" onClick={() => {
                                            props.showModal("#changeSecondaryAddresseeDialog")
                                        }}>
                                            <props.Icon type="person-v2" label="addsecaddr.lbl.change"></props.Icon>
                                        </props.Link>
                                    </props.Div>
                                }
                                {config.allowUpdateSecAddressee === false &&
                                    <props.Div style={linkStyle}>
                                        <props.Link className="gwp-bold" to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${'SEC'}&policyNumber=${policyNumber}&baseRiderCode=${0}`}>
                                            <props.Icon type="person-v2" label="addsecaddr.lbl.view"></props.Icon>
                                        </props.Link>
                                    </props.Div>
                                }
                            </>
                        }}
                    />
                </props.DL>
            </props.Col>
            {config.policyDetail.agentList !== undefined &&
                <props.Col xs="12" md="4">
                    <AgentsInfoPanel {...props} agents={config.policyDetail.agentList.map((agentInfo: any) => {
                        return { fullName: agentInfo.firstLastName, phone: agentInfo.phone, email: agentInfo.email }
                    })} from='policyDetail'></AgentsInfoPanel>
                </props.Col>
            }
        </props.Row>
        {/* Define message component */}
        <props.Row>
            <Message messageService={messageService}></Message>
        </props.Row>
        <props.Panel>
            <props.PanelHeading>
                <GlobalizedText message="pd.lbl.policyinfo" />
                <props.Div condition={config.canViewDocument === true} className="policy-document">
                    <props.Link to={`/policyDocument/${policyNumber}`}>
                        <props.Icon type="view-statement" label="common.lbl.viewPolicyDocuments"></props.Icon>
                    </props.Link>
                </props.Div>
            </props.PanelHeading>
            <props.PanelBody className="policy-information-body">
                <props.Row className="pd-policyinfo-content">
                    <props.Col xs="12" md="6">
                        <props.DL style={{ marginBottom: "0px" }}>
                            <props.DLI title="common.lbl.status" desc={config.policyDetail.status}></props.DLI>
                            <props.DLI title="pd.lbl.currency" desc={config.policyDetail.currencyDesc}></props.DLI>
                        </props.DL>
                        <props.DL>
                            <props.DLI condition={config.policyDetail.applicationNumber !== undefined} title="common.lbl.applicationNumber" desc={config.policyDetail.applicationNumber}></props.DLI>
                            <props.DLI condition={config.policyDetail.applicationDate !== undefined} title="pd.lbl.applicationdate" desc={config.policyDetail.applicationDate}></props.DLI>
                            <props.DLI condition={config.policyDetail.effectiveDate !== undefined} title="common.lbl.effectiveDate" desc={config.policyDetail.effectiveDate}></props.DLI>
                            <props.DLI condition={config.policyDetail.paidToDate !== undefined} title="common.lbl.paidToDate" desc={config.policyDetail.paidToDate}></props.DLI>
                        </props.DL>
                        <props.DL condition={config.policyDetail.primaryBeneficiary !== undefined && config.policyDetail.primaryBeneficiary.length > 0}>
                            <props.DLI title={() => {
                                return <>
                                    <GlobalizedText message="pd.lbl.primarybeneficiary" />
                                    {config.canChangeBeneficiary === true &&
                                        <props.Link to='' onClick={handerBeneURL}>
                                            <props.Icon type="view-all" label="pd.lbl.updateallbeneficiary"></props.Icon>
                                        </props.Link>
                                    }
                                    {(config.canChangeBeneficiary === false && (config.policyDetail.primaryBeneficiary.length > 0 || config.policyDetail.contigentBeneficiary.length > 0)) &&
                                        <props.Link to='' onClick={handerBeneURL}>
                                            <props.Icon type="view-all" label="pd.lbl.viewallbeneficiary"></props.Icon>
                                        </props.Link>
                                    }
                                </>
                            }} desc={() => {
                                return <>
                                    {config.policyDetail.primaryBeneficiary.map((row: any, index: number) => {
                                        if (index < 2) {
                                            return <React.Fragment key={index}>
                                                {config.showNameAsLink === true && <React.Fragment key={index}>
                                                    <props.Div><props.Link to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${row.relationRoleType}&policyNumber=${policyNumber}&baseRiderCode=${0}&relationshipSequenceNumber=${row.relationSequence}`}>{config.policyDetail.primaryBeneficiary[index].name.replaceAll("<wbr/>", "")}</props.Link>{" "}{config.policyDetail.primaryBeneficiary[index].percentage}{"%"}</props.Div>
                                                </React.Fragment>
                                                }
                                                {config.showNameAsLink === false &&
                                                    <props.Div>
                                                        {row.name.replaceAll("<wbr/>", "")}{" "}{config.policyDetail.primaryBeneficiary[index].percentage}{"%"}
                                                    </props.Div>
                                                }
                                            </React.Fragment>
                                        } else {
                                            return <React.Fragment key={index}></React.Fragment>
                                        }
                                    })}
                                    {(config.policyDetail.primaryBeneficiary !== undefined && config.policyDetail.primaryBeneficiary.length > 2) &&
                                        <GlobalizedText message="common.lbl.more" />
                                    }
                                </>
                            }} />
                        </props.DL>
                        <props.DL condition={config.policyDetail.contigentBeneficiary !== undefined && config.policyDetail.contigentBeneficiary.length > 0}>
                            <props.DLI title={
                                () => {
                                    return <>
                                        <GlobalizedText message="pd.lbl.contingentbeneficiary" />
                                        {(config.canChangeBeneficiary === true && showUpdateBeneLink === 0) &&
                                            <props.Link to='' onClick={handerBeneURL}>
                                                <props.Icon type="view-all" label="pd.lbl.updateallbeneficiary"></props.Icon>
                                            </props.Link>
                                        }
                                        {(config.canChangeBeneficiary === false && config.policyDetail.contigentBeneficiary.length > 0 && showViewBeneLink === 0) &&
                                            <props.Link to='' onClick={handerBeneURL}>
                                                <props.Icon type="view-all" label="pd.lbl.viewallbeneficiary"></props.Icon>
                                            </props.Link>
                                        }
                                    </>
                                }
                            } desc={() => {
                                return <>
                                    {config.policyDetail.contigentBeneficiary.map((row: any, index: number) => {
                                        if (index < 2) {
                                            return <React.Fragment key={index}>
                                                {config.showNameAsLink === true && <React.Fragment key={index}>
                                                    <props.Div><props.Link to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&roleTypeCode=${row.relationRoleType}&policyNumber=${policyNumber}&baseRiderCode=${0}&relationshipSequenceNumber=${row.relationSequence}`}>{config.policyDetail.contigentBeneficiary[index].name.replaceAll("<wbr/>", "")}</props.Link>{" "}{config.policyDetail.contigentBeneficiary[index].percentage}{"%"}</props.Div>
                                                </React.Fragment>
                                                }
                                                {config.showNameAsLink === false &&
                                                    <props.Div>
                                                        {row.name.replaceAll("<wbr/>", "")}{" "}{config.policyDetail.contigentBeneficiary[index].percentage}{"%"}
                                                    </props.Div>
                                                }
                                            </React.Fragment>
                                        } else {
                                            return <React.Fragment key={index}></React.Fragment>
                                        }
                                    })}
                                    {(config.policyDetail.contigentBeneficiary !== undefined && config.policyDetail.contigentBeneficiary.length > 2) &&
                                        <GlobalizedText message="common.lbl.more" />
                                    }
                                </>
                            }} />
                        </props.DL>
                        <props.DL condition={config.policyDetail.primaryBeneficiary === undefined && config.policyDetail.contigentBeneficiary === undefined && config.canChangeBeneficiary === true}>
                            <props.DLI title={
                                () => {
                                    return <>
                                        <props.Link to='' onClick={handerBeneURL}>
                                            <props.Icon type="view-all" label="pd.lbl.updateallbeneficiary"></props.Icon>
                                        </props.Link>
                                    </>
                                }
                            }></props.DLI>
                        </props.DL>
                    </props.Col>
                    <props.Col xs="12" md="6">
                        <props.DL>
                            <props.DLI title={
                                () => {
                                    return <><GlobalizedText message="common.lbl.premium" />
                                        <props.Link condition={config.canMakePayment === true} to={`/makePayment/${trim(companyCode)}/${trim(policyNumber)}?from=from_policyDetail`}>
                                            <props.Icon type="dollar" label="common.lbl.makePayment"></props.Icon>
                                        </props.Link>
                                    </>
                                }
                            } desc={config.policyDetail.premium}>
                            </props.DLI>
                            <props.DLI condition={!showConsolidationBilling || config.policyDetail.paymentMethod !== undefined} title={
                                () => {
                                    return <>
                                        <GlobalizedText message="common.lbl.paymentMethod" />
                                        <props.Link condition={config.canChangePaymentMethod === true} to={`/changePaymentMethod/${trim(companyCode)}/${trim(policyNumber)}`}>
                                            <props.Icon type="change-payment-method" label="common.lbl.changePaymentMethod"></props.Icon>
                                        </props.Link>
                                    </>
                                }
                            } desc={config.policyDetail.paymentMethod}>
                            </props.DLI>
                            <props.DLI condition={config.policyDetail.groupName !== undefined} title="pd.lbl.groupname" desc={() => {
                                return config.policyDetail.groupName
                            }}></props.DLI>
                            <props.DLI condition={config.policyDetail.accountControlNumber !== undefined} title={
                                () => {
                                    return <>
                                        <GlobalizedText message="common.lbl.autoPaymentID" />
                                        {config.canViewAccountInformation === true &&
                                            <props.Link to={`/changePaymentMethod/${trim(companyCode)}/${trim(policyNumber)}`}>
                                                <props.Icon type="view-statement" label="common.lbl.accountInfo"></props.Icon>
                                            </props.Link>
                                        }
                                    </>
                                }
                            } desc={config.policyDetail.accountControlNumber}></props.DLI>
                            <props.DLI condition={config.policyDetail.paymentFrequency !== undefined} title="common.lbl.paymentFrequency" desc={config.policyDetail.paymentFrequency}></props.DLI>
                            <props.DLI condition={config.policyDetail.lastPaymentDate !== undefined} title="common.lbl.lastPaymentDate" desc={config.policyDetail.lastPaymentDate}></props.DLI>
                        </props.DL>
                        {/* Show Dividend */}
                        <props.DL condition={config.policyDetail.primaryDividendOption !== undefined && !isEmptyStr(config.policyDetail.primaryDividendOption)}>
                            <props.DLI title={
                                () => {
                                    return <>
                                        <props.Div style={{ marginTop: -5 + "px" }}>
                                            <GlobalizedText message="pd.lbl.primary.dividendoption" />
                                            <props.LinkButton condition={config.policyDetail.isAllDividendAccumulationZero !== true && (config.policyDetail.dividendAccumulation !== undefined || config.policyDetail.couponAccumulation !== undefined) && (config.policyDetail.cprimaryDividendOption !== "2" && config.policyDetail.cprimaryDividendOption !== "6")} onClick={() => {
                                                props.showModal("#DividendDetailsDialog")
                                            }
                                            }>
                                                <props.Icon type="view-statement" label="pd.lbl.view.detail"></props.Icon>
                                            </props.LinkButton>
                                        </props.Div>
                                    </>
                                }
                            } desc={() => {
                                return <>
                                    {config.policyDetail.primaryDividendOption}
                                    <br />
                                    <props.DL className="pd-dividend-summary">
                                        <props.DLI condition={config.policyDetail.dividendAccumulation !== undefined && (config.policyDetail.cprimaryDividendOption === "3" || config.policyDetail.cprimaryDividendOption === "4" || config.policyDetail.cprimaryDividendOption === "5")} title="pd.lbl.dividendsummary" desc={
                                            () => {
                                                return <>
                                                    <props.Row condition={config.policyDetail.cprimaryDividendOption === "3"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.paidUpAdditionsAmount !== undefined} title="pd.lbl.paidupadditionsamount" desc={config.policyDetail.dividendAccumulation.paidUpAdditionsAmount}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.paidUpAdditionsExpirationDate !== undefined} title="pd.lbl.paidupadditionsexpdate" desc={config.policyDetail.dividendAccumulation.paidUpAdditionsExpirationDate}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                    <props.Row condition={config.policyDetail.cprimaryDividendOption === "4"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.onDepositAccumulation !== undefined} title="pd.lbl.ondepositaccumulation" desc={config.policyDetail.dividendAccumulation.onDepositAccumulation}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.intrestPaidTo !== undefined} title="pd.lbl.interestpaidtodate" desc={config.policyDetail.dividendAccumulation.intrestPaidTo}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                    <props.Row condition={config.policyDetail.cprimaryDividendOption === "5"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.oneYearTermAdditionsAmount !== undefined} title="pd.lbl.oneyeartermadditionsamout" desc={config.policyDetail.dividendAccumulation.oneYearTermAdditionsAmount}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.oneYearTermAdditionsExpriationDate !== undefined} title="pd.lbl.oneyeartermadditionsexpdate" desc={config.policyDetail.dividendAccumulation.oneYearTermAdditionsExpriationDate}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                </>
                                            }
                                        }></props.DLI>
                                        <props.DLI condition={config.policyDetail.couponAccumulation !== undefined && (config.policyDetail.cprimaryDividendOption === "3" || config.policyDetail.cprimaryDividendOption === "4" || config.policyDetail.cprimaryDividendOption === "5")} title="pd.lbl.couponsummary" desc={
                                            () => {
                                                return <>
                                                    <props.Row condition={config.policyDetail.cprimaryDividendOption === "3"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.paidUpAdditionsAmount !== undefined} title="pd.lbl.paidupadditionsamount" desc={config.policyDetail.couponAccumulation.paidUpAdditionsAmount}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.paidUpAdditionsExpirationDate !== undefined} title="pd.lbl.paidupadditionsexpdate" desc={config.policyDetail.couponAccumulation.paidUpAdditionsExpirationDate}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                    <props.Row condition={config.policyDetail.cprimaryDividendOption === "4"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.onDepositAccumulation !== undefined} title="pd.lbl.ondepositaccumulation" desc={config.policyDetail.couponAccumulation.onDepositAccumulation}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.intrestPaidTo !== undefined} title="pd.lbl.interestpaidtodate" desc={config.policyDetail.couponAccumulation.intrestPaidTo}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                    <props.Row condition={config.policyDetail.cprimaryDividendOption === "5"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.oneYearTermAdditionsAmount !== undefined} title="pd.lbl.oneyeartermadditionsamout" desc={config.policyDetail.couponAccumulation.oneYearTermAdditionsAmount}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.oneYearTermAdditionsExpriationDate !== undefined} title="pd.lbl.oneyeartermadditionsexpdate" desc={config.policyDetail.couponAccumulation.oneYearTermAdditionsExpriationDate}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                </>
                                            }
                                        }></props.DLI>
                                    </props.DL>
                                </>
                            }}></props.DLI>
                            {/* cprimaryDividendOption ===2 or cprimaryDividendOption === 6 */}
                            <props.DLI condition={config.policyDetail.cprimaryDividendOption === "2" || config.policyDetail.cprimaryDividendOption === "6"} title={
                                () => {
                                    return <>
                                        <props.Div style={{ marginTop: -15 + "px" }}>
                                            <GlobalizedText message="pd.lbl.second.dividendoption" />
                                            <props.LinkButton condition={config.policyDetail.isAllDividendAccumulationZero !== true && (config.policyDetail.dividendAccumulation !== undefined || config.policyDetail.couponAccumulation !== undefined) && (config.policyDetail.cprimaryDividendOption === "2" || config.policyDetail.cprimaryDividendOption === "6")} onClick={() => {
                                                props.showModal("#DividendDetailsDialog")
                                            }
                                            }>
                                                <props.Icon type="view-statement" label="pd.lbl.view.detail"></props.Icon>
                                            </props.LinkButton>
                                        </props.Div>
                                    </>
                                }
                            } desc={() => {
                                return <>
                                    {config.policyDetail.secondDividendOption}
                                    <br />
                                    <props.DL className="pd-dividend-summary">
                                        <props.DLI condition={config.policyDetail.dividendAccumulation !== undefined && (config.policyDetail.csecondDividendOption === "3" || config.policyDetail.csecondDividendOption === "4" || config.policyDetail.csecondDividendOption === "5")} title="pd.lbl.dividendsummary" desc={
                                            () => {
                                                return <>
                                                    <props.Row condition={config.policyDetail.csecondDividendOption === "3"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.paidUpAdditionsAmount !== undefined} title="pd.lbl.paidupadditionsamount" desc={config.policyDetail.dividendAccumulation.paidUpAdditionsAmount}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.paidUpAdditionsExpirationDate !== undefined} title="pd.lbl.paidupadditionsexpdate" desc={config.policyDetail.dividendAccumulation.paidUpAdditionsExpirationDate}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                    <props.Row condition={config.policyDetail.csecondDividendOption === "4"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.onDepositAccumulation !== undefined} title="pd.lbl.ondepositaccumulation" desc={config.policyDetail.dividendAccumulation.onDepositAccumulation}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.intrestPaidTo !== undefined} title="pd.lbl.interestpaidtodate" desc={config.policyDetail.dividendAccumulation.intrestPaidTo}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                    <props.Row condition={config.policyDetail.csecondDividendOption === "5"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.oneYearTermAdditionsAmount !== undefined} title="pd.lbl.oneyeartermadditionsamout" desc={config.policyDetail.dividendAccumulation.oneYearTermAdditionsAmount}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.dividendAccumulation.oneYearTermAdditionsExpriationDate !== undefined} title="pd.lbl.oneyeartermadditionsexpdate" desc={config.policyDetail.dividendAccumulation.oneYearTermAdditionsExpriationDate}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                </>
                                            }
                                        }></props.DLI>
                                        <props.DLI condition={config.policyDetail.couponAccumulation !== undefined && (config.policyDetail.csecondDividendOption === "3" || config.policyDetail.csecondDividendOption === "4" || config.policyDetail.csecondDividendOption === "5")} title="pd.lbl.couponsummary" desc={
                                            () => {
                                                return <>
                                                    <props.Row condition={config.policyDetail.csecondDividendOption === "3"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.paidUpAdditionsAmount !== undefined} title="pd.lbl.paidupadditionsamount" desc={config.policyDetail.couponAccumulation.paidUpAdditionsAmount}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.paidUpAdditionsExpirationDate !== undefined} title="pd.lbl.paidupadditionsexpdate" desc={config.policyDetail.couponAccumulation.paidUpAdditionsExpirationDate}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                    <props.Row condition={config.policyDetail.csecondDividendOption === "4"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.onDepositAccumulation !== undefined} title="pd.lbl.ondepositaccumulation" desc={config.policyDetail.couponAccumulation.onDepositAccumulation}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.intrestPaidTo !== undefined} title="pd.lbl.interestpaidtodate" desc={config.policyDetail.couponAccumulation.intrestPaidTo}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                    <props.Row condition={config.policyDetail.csecondDividendOption === "5"}>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.oneYearTermAdditionsAmount !== undefined} title="pd.lbl.oneyeartermadditionsamout" desc={config.policyDetail.couponAccumulation.oneYearTermAdditionsAmount}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                        <props.Col xs="12" md="6">
                                                            <props.DL>
                                                                <props.DLI condition={config.policyDetail.couponAccumulation.oneYearTermAdditionsExpriationDate !== undefined} title="pd.lbl.oneyeartermadditionsexpdate" desc={config.policyDetail.couponAccumulation.oneYearTermAdditionsExpriationDate}></props.DLI>
                                                            </props.DL>
                                                        </props.Col>
                                                    </props.Row>
                                                </>
                                            }
                                        }></props.DLI>
                                    </props.DL>
                                </>
                            }}></props.DLI>
                        </props.DL>
                        <props.DL condition={(config.policyDetail.netCashValue !== undefined) || (config.policyDetail.cashWithApplication !== undefined) || (config.policyDetail.currentLoanBalance !== undefined)}>
                            <props.DLI condition={config.policyDetail.netCashValue !== undefined} title="pd.lbl.netcashvalue" desc={config.policyDetail.netCashValue}></props.DLI>
                            <props.DLI condition={config.policyDetail.cashWithApplication !== undefined} title="pd.lbl.cashwithapplication" desc={config.policyDetail.cashWithApplication}></props.DLI>
                            <props.DLI condition={config.policyDetail.currentLoanBalance !== undefined && config.policyDetail.currentLoanBalance !== 0} title={
                                () => {
                                    return <>
                                        <props.Div style={{ marginTop: -15 + "px" }}>
                                            <GlobalizedText message="pd.lbl.currentloanbalance" />
                                            <props.LinkButton onClick={() => props.showModal("#loanDetailsDialog")}>
                                                <props.Icon type="view-statement" label="pd.lbl.view.detail"></props.Icon>
                                            </props.LinkButton>
                                        </props.Div>
                                    </>
                                }
                            } desc={config.policyDetail.currentLoanBalance}></props.DLI>
                        </props.DL>
                        <props.DL condition={config.policyDetail.taxQualifiedType !== undefined}>
                            <props.DLI title="pd.lbl.taxqualifiedtype" desc={config.policyDetail.taxQualifiedType}></props.DLI>
                        </props.DL>
                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel >
        {/** Benefit Table */}
        < props.Panel >
            <props.PanelHeading><GlobalizedText message="pd.lbl.benefits" /></props.PanelHeading>
            <props.PanelBody className="panel-body-table">
                {benefitListTable}
            </props.PanelBody>
        </props.Panel >
        {/* dividenddetail Modal*/}
        <props.Modal id="DividendDetailsDialog" size="large" title="common.lbl.dividendDetails" footer={returnFooter}>
            <p><GlobalizedText message="common.lbl.allcurrencyvalue" />{" " + config.policyDetail.currencyDesc}</p>
            <props.Table modal="DividendDetailsDialog" id="dividendDetailsTable" option={
                {
                    bSort: false,
                    bPaginate: false,
                    paging: false,
                    bLengthChange: false,
                    oLanguage: {
                        sInfoEmpty: "",
                        sInfo: ""
                    },
                    searching: false
                }
            }>
                <thead>
                    <tr>
                        <th className="visible-xs visible-sm gwp-dt-detail-control"></th>
                        <th></th>
                        <th><GlobalizedText message="pd.lbl.ondepositaccumulation" /></th>
                        <th><GlobalizedText message="pd.lbl.interestpaidtodate" /></th>
                        <th><GlobalizedText message="pd.lbl.paidupadditionsamount" /></th>
                        <th><GlobalizedText message="pd.lbl.paidupadditionsexpdate" /></th>
                        <th><GlobalizedText message="pd.lbl.oneyeartermadditionsamout" /></th>
                        <th><GlobalizedText message="pd.lbl.oneyeartermadditionsexpdate" /></th>
                    </tr>
                </thead>
                <tbody>
                    {config.policyDetail.dividendAccumulation !== undefined &&
                        <tr>
                            <th className="visible-xs visible-sm gwp-dt-detail-control"></th>
                            <th><GlobalizedText message="pd.lbl.dividendsummary" /></th>

                            <td align="right">{config.policyDetail.dividendAccumulation.onDepositAccumulation !== undefined && <props.Number value={config.policyDetail.dividendAccumulation.onDepositAccumulation} />}</td>
                            <td>{config.policyDetail.dividendAccumulation.intrestPaidTo !== undefined && <>{config.policyDetail.dividendAccumulation.intrestPaidTo}</>}</td>

                            <td align="right">{config.policyDetail.dividendAccumulation.paidUpAdditionsAmount !== undefined && <props.Number value={config.policyDetail.dividendAccumulation.paidUpAdditionsAmount} />}</td>
                            <td>{config.policyDetail.dividendAccumulation.paidUpAdditionsExpirationDate !== undefined && <>{config.policyDetail.dividendAccumulation.paidUpAdditionsExpirationDate}</>}</td>

                            <td align="right">{config.policyDetail.dividendAccumulation.oneYearTermAdditionsAmount !== undefined && <props.Number value={config.policyDetail.dividendAccumulation.oneYearTermAdditionsAmount} />}</td>
                            <td>{config.policyDetail.dividendAccumulation.oneYearTermAdditionsExpriationDate !== undefined && <>{config.policyDetail.dividendAccumulation.oneYearTermAdditionsExpriationDate}</>}</td>
                        </tr>
                    }
                    {config.policyDetail.couponAccumulation !== undefined &&
                        <tr>
                            <th className="visible-xs visible-sm gwp-dt-detail-control"></th>
                            <th><GlobalizedText message="pd.lbl.couponsummary" /></th>

                            <td align="right">{config.policyDetail.couponAccumulation.onDepositAccumulation !== undefined && <props.Number value={config.policyDetail.couponAccumulation.onDepositAccumulation} />}</td>
                            <td>{config.policyDetail.couponAccumulation.intrestPaidTo !== undefined && <>{config.policyDetail.couponAccumulation.intrestPaidTo}</>}</td>

                            <td align="right">{config.policyDetail.couponAccumulation.paidUpAdditionsAmount !== undefined && <props.Number value={config.policyDetail.couponAccumulation.paidUpAdditionsAmount} />}</td>
                            <td>{config.policyDetail.couponAccumulation.paidUpAdditionsExpirationDate !== undefined && <>{config.policyDetail.couponAccumulation.paidUpAdditionsExpirationDate}</>}</td>

                            <td align="right">{config.policyDetail.couponAccumulation.oneYearTermAdditionsAmount !== undefined && <props.Number value={config.policyDetail.couponAccumulation.oneYearTermAdditionsAmount} />}</td>
                            <td>{config.policyDetail.couponAccumulation.oneYearTermAdditionsExpriationDate !== undefined && <>{config.policyDetail.couponAccumulation.oneYearTermAdditionsExpriationDate}</>}</td>
                        </tr>
                    }
                </tbody>
            </props.Table>
        </props.Modal >
        {/* Load Detail */}
        <props.Modal id="loanDetailsDialog" title="common.lbl.loanDetails" footer={returnFooter}>
            <p><GlobalizedText message="common.lbl.allcurrencyvalue" />{" " + config.policyDetail.currencyDesc}</p>
            <props.Table modal={"loanDetailsDialog"} delayAdjust={true} condition={loanNoneTax !== undefined && loanNoneTax.length > 0} id="loanNoTaxTable" option={{ paging: false }} table={
                {
                    columns: [
                        { className: "gwp-dt-detail-control", sortable: false },
                        { title: "pd.lbl.loanbalance", name: "loanBalance", sortable: false, type: 'numeric' },
                        {
                            title: "pd.lbl.annualinterestrate", name: "annualIntrestRate", sortable: false, render: (data: any, type: any, row: any, meta: any) => {
                                let pasetmpAnnualIntrestRate = row.annualIntrestRate * 100
                                return `${formatNumber(pasetmpAnnualIntrestRate)}%`;
                            }, type: 'numeric'
                        },
                        { title: "pd.lbl.interestmethod", name: "intrestMethod", sortable: false },
                        { title: "pd.lbl.interestpaidtodate", name: "intrestPaidToDate", sortable: false }
                    ],
                    data: loanNoneTax
                }}>
            </props.Table>
            <props.Table modal={"loanDetailsDialog"} delayAdjust={true} condition={loanTax !== undefined && loanTax.length > 0 && trim(config.policyDetail.taxQualifiedType) !== "NON QUALIFIED"} id="loanTaxTable" option={{ paging: false }} table={
                {
                    columns: [
                        { className: "gwp-dt-detail-control", sortable: false },
                        { title: "pd.lbl.loanbalance", name: "loanBalance", sortable: false, type: 'numeric' },
                        {
                            title: "pd.lbl.annualinterestrate", name: "annualIntrestRate", sortable: false, render: (data: any, type: any, row: any, meta: any) => {
                                let pasetmpAnnualIntrestRate = row.annualIntrestRate * 100
                                return `${formatNumber(pasetmpAnnualIntrestRate)}%`;
                            }, type: 'numeric'
                        },
                        { title: "pd.lbl.interestmethod", name: "intrestMethod", sortable: false },
                        { title: "pd.lbl.interestpaidtodate", name: "intrestPaidToDate", sortable: false },
                        { title: "pd.lbl.typeofloan", name: "typeOfLoan", sortable: false },
                        { title: "pd.lbl.loanpaymentamount", name: "loanPaymentAmount", sortable: false, type: 'numeric' },
                        { title: "common.lbl.nextPaymentDate", name: "nextPaymentDueDate", sortable: false }
                    ],
                    data: loanTax
                }}>
            </props.Table>
            <props.Table modal={"loanDetailsDialog"} delayAdjust={true} condition={loanTax !== undefined && loanTax.length > 0 && trim(config.policyDetail.taxQualifiedType) === "NON QUALIFIED"} id="loanTaxTable_NonQualified" option={{ paging: false }} table={
                {
                    columns: [
                        { className: "gwp-dt-detail-control", sortable: false },
                        { title: "pd.lbl.loanbalance", name: "loanBalance", sortable: false, type: 'numeric' },
                        {
                            title: "pd.lbl.annualinterestrate", name: "annualIntrestRate", sortable: false, render: (data: any, type: any, row: any, meta: any) => {
                                let pasetmpAnnualIntrestRate = row.annualIntrestRate * 100
                                return `${formatNumber(pasetmpAnnualIntrestRate)}%`;
                            }, type: 'numeric'
                        },
                        { title: "pd.lbl.interestmethod", name: "intrestMethod", sortable: false },
                        { title: "pd.lbl.interestpaidtodate", name: "intrestPaidToDate", sortable: false }
                    ],
                    data: loanTax
                }}>
            </props.Table>
        </props.Modal>

        {/* Change Benefiary for 2 Owners */}
        <props.Modal id="changeBene2Owners" title="pd.lbl.changebeneficiary" footer={() => {
            return <>
                <props.Button onClick={() => {
                    props.closeModal("#changeBene2Owners")
                    props.next(`/beneficiaryViewInformation?companyCode=${companyCode}&policyNumber=${policyNumber}&fromPage=${'from_policydetail'}`)
                }}><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }} >
            <props.Row>
                <props.Col md="12" sm="12" xs="12">
                    <props.Span className="gwp-icon gwp-icon-warning gwp-icon-validate"></props.Span><GlobalizedText message="pd.msg.ownerTwoMsg" />
                </props.Col>
            </props.Row>
        </props.Modal>

        {/* Change Owner */}
        <props.Modal id="changeOwnerDialog" title="changeow.lbl.title" footer={() => {
            return <>
                {config.policyDetail.owner2 === undefined && <>
                    <props.Button onClick={() => {
                        props.closeModal("#changeOwnerDialog")
                        props.next(`/changeOwner?companyCode=${trim(companyCode)}&policyNumber=${trim(policyNumber)}`)
                    }}><GlobalizedText message="common.button.continue" /></props.Button>
                    <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                </>}
                <props.Button condition={config.policyDetail.owner2 !== undefined} data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>

            </>
        }} >
            <props.Row>
                <props.Col md="12" sm="12" xs="12" condition={config.policyDetail.owner2 === undefined}>
                    <props.Span className="gwp-icon gwp-icon-warning gwp-icon-validate"></props.Span>
                    <GlobalizedText message="changeow.msg.warn.changeOwner" />
                </props.Col>
                <props.Col md="12" sm="12" xs="12" condition={config.policyDetail.owner2 !== undefined}>
                    <props.Span className="gwp-icon gwp-icon-warning gwp-icon-validate"></props.Span>
                    <GlobalizedText message="changeow.msg.err.multiOwner" />
                </props.Col>
            </props.Row>
        </props.Modal>

        {/* Change secondary addressee */}
        <props.Modal id="changeSecondaryAddresseeDialog" title="pd.lbl.secaddr.name" footer={() => {
            return <>
                <props.Button condition={config.allowUpdateSecAddressee === true} onClick={() => {
                    props.closeModal("#changeSecondaryAddresseeDialog")
                    props.next(`/changeSecondaryAddressee?companyCode=${trim(companyCode)}&policyNumber=${trim(policyNumber)}&fromPage=${'policyDetail'}`)
                }}><GlobalizedText message="common.lbl.change" /></props.Button>
                <props.Button condition={config.canUpdatePersonalInfo === true} onClick={() => {
                    props.closeModal("#changeSecondaryAddresseeDialog")
                    props.next(`/personalInformation?personalInfoType=${2}&companyCode=${trim(companyCode)}&policyNumber=${trim(policyNumber)}&roleTypeCode=${'SEC'}&baseRiderCode=${0}&isUpdateMode=${true}`)
                }}><GlobalizedText message="common.lbl.update" /></props.Button>
                <props.Button condition={config.allowUpdateSecAddressee === true && config.allowDeleteSecAddressee === true} onClick={() => {
                    props.closeModal("#changeSecondaryAddresseeDialog")
                    props.showModal("#confirmDelete")
                }}><GlobalizedText message="common.lbl.delete" /></props.Button>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
            </>
        }} >
            <props.Row>
                <props.Col md="12" sm="12" xs="12">
                    <props.Span className="gwp-icon gwp-icon-warning gwp-icon-validate"></props.Span><GlobalizedText message="addsecaddr.msg.buttontip" />
                </props.Col>
            </props.Row>
            <props.Row style={{ marginTop: "20px" }}>
                <props.Col md="12" sm="12" xs="12">
                    <props.DL className="dl-horizontal" style={{ marginLeft: "20px" }}>
                        <props.DLI title="pd.lbl.secaddr.name" desc={config.policyDetail.secondaryAddressee !== undefined ? config.policyDetail.secondaryAddressee.name.replaceAll("<wbr/>", "") : ''}></props.DLI>
                        <props.DLI title="pd.lbl.address" desc={() => {
                            return <>
                                {config.policyDetail.secondaryAddressee !== undefined ? config.policyDetail.secondaryAddressee.address.split("<br/>").map((o: any, i: number) => { return <React.Fragment key={i}>{o}<br /></React.Fragment> }) : <></>}
                            </>
                        }}></props.DLI>
                    </props.DL>
                </props.Col>
            </props.Row>
        </props.Modal>

        {/* Confirm delete */}
        <props.Modal id="confirmDelete" title="openenrol.lbl.confirmDelete" footer={() => {
            return <>
                <props.Button onClick={() => {
                    deleteSecaddr(config.companyCode, config.policyNumber, config.policyDetail.secondaryAddressee.clientNumber)
                }}><GlobalizedText message="openenrol.lbl.confirmDelete" /></props.Button>
                <props.Button onClick={() => {
                    props.closeModal("#confirmDelete")
                    props.showModal("#changeSecondaryAddresseeDialog")
                }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
            </>
        }} >
            <props.Row>
                <props.Col md="12" sm="12" xs="12">
                    <props.Span className="gwp-icon gwp-icon-warning gwp-icon-validate"></props.Span><GlobalizedText message="addsecaddr.msg.deleteConfirm" />
                </props.Col>
            </props.Row>
        </props.Modal>
    </>

});