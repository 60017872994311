import React, { ReactNode } from 'react';
import GlobalizedText from '../../globalization';
import { withErrorControl, WrapperControlProps } from './field';
import { Div } from './layout';

/**
 * Label Properties
 */
export interface LabelProps {
    /**
     * Indicate whether the label is referenced by a required field.
     */
    required?: boolean;

    /**
     * Indicate whether the label is referenced by a field or a text;
     */
    readonly?: boolean;

    children?: ReactNode;

    htmlFor?: string;

    [key: string]: any;
}

export interface LabelTextControlProps extends LabelProps {
    message: string;
    text: string;
}



export function Label({ required, readonly, className, ...props }: LabelProps) {
    return <label {...props} className={(className === undefined ? "" : className) + " " + (required !== undefined && required === true ? "gwp-label-required" : (readonly !== undefined && readonly === true ? "gwp-label-ro" : "gwp-label"))}>{props.children}</label>
}

export interface LabelAddOnProps extends WrapperControlProps {
    checked?: boolean;

}

export interface H5Props {
    labelMsg?: string;
    children?: ReactNode;

}

export function H5({ labelMsg, ...props }: H5Props) {
    return <h5 className="text-left gwp-bold" >{props.children}</h5>
}

export const LabelAddOnControl = withErrorControl(({ required, labelMsg, chkBoxName, setValue, ...props }: LabelProps) => {
    return <div className="form-group">
        <label htmlFor={props.id} className="gwp-label-required"><GlobalizedText message={labelMsg} /></label>
        <input type="checkbox" className="checkall" name={chkBoxName} {...props}></input>
        {/* <CheckboxControl name={chkBoxName} checked={checked}></CheckboxControl> */}
    </div>
})

export const LabelTextControl = ({ required, message, text, ...props }: LabelTextControlProps) => {
    return <div className="form-group">
        <label {...props} className={`${required === true ? "gwp-label-required" : ""}`}><GlobalizedText message={message} /></label>
        <Div>{text}</Div>
    </div>
}