import React,{useState,useContext, useEffect} from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { ajax, downloadFile } from '../shared/utils';
import './css/commissionstatement.css';

export const CommissionStatementComponent = withView((props: ViewComponentProps) => {
    
    const [cStatements,setCStatements] = useState<any>(null);

    let from = props.getQueryParam("fromPage");
    let companyCode = props.getQueryParam("companyCode");
    let agentNumber = props.getQueryParam("agentNumber");

    useEffect(() => {
        if (cStatements  === null) {            
            ajax ({
                url: '/api/commissionStatement/view',
                showMask: true,
                    params: {
                        companyCode: companyCode,
                        agentNumber: agentNumber,
                        fromAgentSearch: from !== undefined ? true : false
                    },
                success : (res:any) => {
                    setCStatements (res.listOfCommissionStatement);
                    
                },
            });
        }
    },[cStatements]);

    if (cStatements === null)
    return <></>

    return (
        <React.Fragment>
             {cStatements && <>
                <props.Col className='divider'></props.Col>
                <props.Condition><props.Information message="common.msg.commissionStatementsInfo" /></props.Condition>
                <div className='container'>
                    <div className="statement-box">
                        {
                            cStatements.map((commissionStatement: any, index: number) =>
                                <div className="statement-group"  key ={index}>
                                    <a onClick={() => downloadFile(`/api/commissionStatement/downloadStatement/${commissionStatement}/`)} 
                                    className="list-statement-item">{commissionStatement}</a>
                                </div>
                            )
                        }
                    </div>
                </div>
            </>}
        </React.Fragment>
    );
});
