import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SecurityContext } from '../context/securityContext';
import '../pages/css/quickLink.css';
import GlobalizedText from './globalization';

const QuickLink = (props:any) => {
    const {personalDetails} = props;
    const { secureLinks, userAuth, showEnquiryForm, setShowEnquiryForm } = useContext(SecurityContext);
   
    return <div className='quick-link'>
        <div className='quick-link--title'><GlobalizedText message='common.quick.title' /></div>
        <div className='quick-link--links'>
            <ul>
                <li><Link to={`${process.env.PUBLIC_URL}/faq`}><GlobalizedText message='common.faq.title' /></Link></li>
                {secureLinks.includes("/UserInquiry") && <li>
                    <Link to={`${process.env.PUBLIC_URL}/UserInquiry`}><GlobalizedText message='common.mscenter.title' /></Link>
                </li>}
                {userAuth.needSubmitInq && <li>
                    <a onClick={() => setShowEnquiryForm(!showEnquiryForm)}><GlobalizedText message="common.lbl.submitInq" /></a>
                </li>}

                {(userAuth.isSendMessage && personalDetails.isMemberRegistered) && <li>   
                    <a id="theme_send_message_link" data-toggle="modal" data-target="#theme_send_message" title="" href='#root'><GlobalizedText message="common.lbl.sendmessage" /></a>                        
                </li>}  
            </ul>
        </div>
    </div>;
}

export default QuickLink;
