import React, { useState, useEffect } from 'react'
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import {ajax, downloadFile} from '../shared/utils';
import './css/groupreports.css';

export const GroupReportsComponent = withView((props: ViewComponentProps) => {
    const [groupReports, setGroupReports] = useState<any>(null);
    useEffect(() => {
        if (groupReports === null) {
            ajax({
                url: '/api/groupReports/view',
                success: (res) => {
                    setGroupReports(res.listOfReports);
                    console.log("RESPONSE", res);
                },
            });
        }
    }, [groupReports]);

    if (groupReports === null)
    return <></>;

    return (

        <React.Fragment>
             {groupReports && <>
                <props.Col className='divider'></props.Col>
                <props.Condition><props.Information message="common.lbl.reportsmessage" /></props.Condition>
                <div className='container'>
                    <div className="list-box">
                        {
                            groupReports.map((report: any, index: number) =>

                                <div className="list-group"  key ={index}>
                                    <a onClick={() => downloadFile(`/api/groupReports/downloadReport/${report}/`)} className="list-group-item">{report}</a>
                                </div>
                            )
                        }
                    </div>
                </div>
            </>}
        </React.Fragment>
    )
})
