import React, { useContext } from 'react';
import { Col } from './components';
import GlobalizedText, { GlobalizationContext } from './globalization';
import { getGlobalizedText } from './utils';
export type MessageType = "error" | "success";
export class MessageService {
    messageComponent?: any;
    setMessageComponent(messageComponent: any) {
        this.messageComponent = messageComponent;
    }
    showMessage(type: MessageType, message: string | JSX.Element) {
        this.messageComponent.setState({ type: type, message: message });
    }
    clearMessage() {
        this.messageComponent.setState({ type: null, message: null });
    }
}

export interface FieldsMessages {
    [fieldName: string]: string;
}

let messageServices: any = {};
export const useMessage = (id: string) => {
    if (messageServices[id] === undefined) {
        messageServices[id] = new MessageService();
    }
    let globalization = useContext(GlobalizationContext);
    let validateProps = {
        getGlobalizedText: (msg: string) => { return getGlobalizedText(globalization, msg) },
        showMessage: (type: MessageType, message: string | JSX.Element) => { messageServices[id].showMessage.call(messageServices[id], type, message) },
        clearMessage: () => messageServices[id].clearMessage.call(messageServices[id]),
        showFieldError: (pageLevelErrorMessage: string, fields: FieldsMessages, formProps: any) => {
            if (formProps === null) {
                throw new Error("formProps can not be null.");
            }
            messageServices[id].showMessage.call(messageServices[id], "error", pageLevelErrorMessage);
            for (let field in fields) {
                // for formProps
                if (formProps.getFieldHelpers !== undefined) {
                    formProps.getFieldHelpers(field).setError(fields[field]);
                }
                // for formikHelper
                if (formProps.setFieldError !== undefined) {
                    formProps.setFieldError(field, fields[field]);
                }
            }
        },
    }
    return {
        messageService: messageServices[id],
        validateProps: validateProps
    }
}

export interface MessageProps {
    messageService?: MessageService
}

interface MessageState {
    type: MessageType | null,
    message: string | JSX.Element | null
}

export class Message extends React.Component<MessageProps, MessageState>{
    constructor(props: MessageProps) {
        super(props);
        props.messageService?.setMessageComponent(this);
        this.state = { type: null, message: null };
    }
    render() {
        return <React.Fragment>
            {this.state.type !== null && this.state.message !== null &&
                <Col xs="12" sm="12">
                    <div className={"alert gwp-page-message " + (this.state.type === "success" ? "alert-success" : "alert-danger")}>
                        <span className={"glyphicon " + (this.state.type === "success" ? "glyphicon-ok" : "glyphicon-exclamation-sign")} style={Style} aria-hidden="true" />
                        {typeof (this.state.message) === "string" &&
                            <GlobalizedText message={this.state.message} />
                        }
                        {typeof (this.state.message) === "object" &&
                            this.state.message
                        }
                    </div>
                </Col>}
        </React.Fragment>
    }
}
let Style = {
    marginRight: "10px"
}