import React from 'react';
import { SharedComponents } from '..';
import GlobalizedText from '../../globalization';

export interface Owner{
    name:string;
    viewOwnerPersonalInfoUrl?:string;
    changeOwnerUrl?:string;
    address:string;
}

export interface OwnerInfoProps extends SharedComponents{
    owner:Owner;
    owner2?:Owner;
}

export const OwnerInfo = (props: OwnerInfoProps) => {
    const linkStyle = { margin: '5px 0', display: 'inline-block' };
    return <>
        <props.DL className="dl-horizontal">
            <props.DLI titleClass="gwp-text-leftAlign" title={() => { return <><GlobalizedText message="common.lbl.owner" />{props.owner2 && " 1"}</> }}
                desc={() => {
                    return <>
                        {props.owner.viewOwnerPersonalInfoUrl !== undefined &&
                            <div><props.Link to={props.owner.viewOwnerPersonalInfoUrl}>{props.owner.name}</props.Link></div>
                        }
                        {props.owner.viewOwnerPersonalInfoUrl === undefined &&
                            <div>{props.owner.name}</div>
                        }
                        {props.owner.changeOwnerUrl !== undefined &&
                            <div style={linkStyle}>
                                <props.Link to={props.owner.changeOwnerUrl}>
                                    <span className="gwp-icon gwp-icon-person-v2 gwp-icon-sm"></span>{" "}<GlobalizedText message="changeow.lbl.title" />
                                </props.Link>
                            </div>
                        }
                    </>
                }}
            />
            <props.DLI titleClass="gwp-text-leftAlign" title="pd.lbl.address" desc={() => { return <>{props.owner.address.split("<br/>").map((o: any, i: number) => { return <React.Fragment key={i}>{o}<br /></React.Fragment> })}</> }} />
        </props.DL>
        {props.owner2 !== undefined &&
            <props.DL className="dl-horizontal">
                <props.DLI titleClass="gwp-text-leftAlign" title={() => { return <><GlobalizedText message="common.lbl.owner" />{" 2"}</> }}
                    desc={() => {
                        return <>
                            {props.owner2?.viewOwnerPersonalInfoUrl !== undefined &&
                                <div><props.Link to={props.owner2.viewOwnerPersonalInfoUrl}>{props.owner2.name}</props.Link></div>
                            }
                            {props.owner2?.viewOwnerPersonalInfoUrl === undefined &&
                                <div>{props.owner2?.name}</div>
                            }
                            {props.owner2?.changeOwnerUrl !== undefined &&
                                <div style={linkStyle}>
                                    <props.Link to={props.owner2.changeOwnerUrl}>
                                        <span className="gwp-icon gwp-icon-person-v2 gwp-icon-sm"></span>{" "}<GlobalizedText message="changeow.lbl.title" />
                                    </props.Link>
                                </div>
                            }
                        </>
                    }}
                />
                <props.DLI titleClass="gwp-text-leftAlign" title="pd.lbl.address" desc={() => { return <>{props.owner2?.address.split("<br/>").map((o: any, i: number) => { return <React.Fragment key={i}>{o}<br /></React.Fragment> })}</> }} />
            </props.DL>
        }
    </>
}