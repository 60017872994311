import React, { useEffect, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { ajax, isEmptyObject } from '../shared/utils';
import GlobalizedText from '../shared/globalization';
import './css/appActivities.css'
import { useTable } from '../shared/components';


export const ApplicationActivitiesComponent = withView((props: ViewComponentProps) => {

    const [config, setConfig] = useState<any>();
    const companyCode: string = props.getParam("companyCode");
    const groupNumber: string = props.getParam("groupNumber");
    const accountNumber: number = parseInt(props.getParam("accountNumber"));
    const participantIDNumber: string = props.getParam("participantIDNumber");
    const fromPage: string = props.getParam("fromPage");
    const thisPage: string = 'applicationDetail';
    const applicationNumber = props.getParam("applicationNumber");

    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/applicationActivities/view',
                params: {
                    companyCode: props.getQueryParam("companyCode"),
                    groupNumber: props.getQueryParam("groupNumber"),
                    accountNumber: props.getQueryParam("accountNumber"),
                    participantIDNumber: props.getQueryParam("participantIDNumber"),
                    applicantNumber: props.getQueryParam("applicantNumber")
                },
                success: (data: any) => {
                    setConfig(data);
                }
            });
        }
    }, [config]);
    if (config === undefined) {
        return <></>
    }

    const tblActivity = useTable({
        id: 'tblActivity',
        option: {
            retrieve: true,
            bPaginate: false,
            bSort: false,
            bFilter: false,
            bLengthChange: false,
            language: { emptyTable: " " }
        },
        delayAdjust: false,
        childRows: 1,
        children: [
            <thead key={0}>
                <tr>
                    <th data-sortable={false} scope="col" className="gwp-details-control width0px padding0px"></th>
                    <th scope="col" data-width="20%"><GlobalizedText message="common.lbl.type" /></th>
                    <th scope="col" data-width="10%"><GlobalizedText message="common.lbl.date" /></th>
                    <th scope="col" data-width="10%"><GlobalizedText message="common.lbl.status" /> </th>
                    <th scope="col" data-width="10%"><GlobalizedText message="app.activities.reason" /></th>
                    <th scope="col" data-width="20%"><GlobalizedText message="app.activities.underwritingStatus" /></th>
                    <th scope="col" data-width="20%"><GlobalizedText message="app.activities.underwritingReason" /></th>
                    <th className="gwp-dt-child"></th>
                </tr>
            </thead>,
            <tbody key={1}>
                {config.activities?.map((appActivity: any, index: number) => {
                    return <tr key={index}>
                        <td data-gwp-child="no" className="gwp-details-control width0px padding0px"></td>
                        <td>{appActivity.activityType}</td>
                        <td>{appActivity.activityDate}</td>
                        <td>{appActivity.activityStatus}</td>
                        <td>{appActivity.activityReason}</td>
                        <td>{appActivity.activityUnderwritingStatus}</td>
                        <td>{appActivity.activityUnderwritingReason}</td>
                        <td className="gwp-dt-child"></td>
                    </tr>;
                })}
            </tbody>
        ]
    });

    return <>
        <props.Row>
            <props.Col xs="12" sm="12" md="12">
                <div className="gwp-page-header">
                    <div className="gwp-page-header-block"></div>
                    <div className="gwp-page-header-label gwp-label"><GlobalizedText message="common.lbl.applicationNumber" /> {config.applicationNumber}</div>
                </div>
            </props.Col>
        </props.Row>
        <props.Row >
            <props.Col xs="12" sm="8" md="8">
                <props.DL className="dl-horizontal gwp-dl app-detail-dl marginLeft180px marginButtom10px">
                    <props.DLI title="common.lbl.applicant" desc={() => {
                        if (config.showNameAsLink) {
                            return <props.Link to={`/personalInformation?isUpdateMode=false&personalInfoType=1&companyCode=${companyCode}&groupNumber=${groupNumber}&accountNumber=${accountNumber}&participantIDNumber=${participantIDNumber}&dependentSequenceNumber=0&from=${thisPage}`} >

                                {config.ownerName}
                            </props.Link>
                        } else {
                            return <>{config.ownerName}</>
                        }

                    }} />
                    <props.DLI title="pd.lbl.address" isHtml={true} desc={config.ownerAddress} />
                </props.DL>
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col xs="12" sm="12" md="12">
                <props.Panel>
                    <props.PanelHeading>
                        <div className="app-detail-title">
                            <GlobalizedText message="common.lbl.activities" />
                        </div>
                    </props.PanelHeading>
                    <props.PanelBody replaceClass={true} className="benefit-panel-body">
                        <tblActivity.Component></tblActivity.Component>
                    </props.PanelBody>
                </props.Panel>
            </props.Col>
        </props.Row>
    </>
});
