import { useField } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { ComboboxControl, createOptions, SelectControlOption } from '..';
import { GlobalizationContext } from '../../globalization';
import { SelectControl, SelectControlProps } from './select';

export interface GroupSelectControlProps extends SelectControlProps {
}

const MAX_SIZE: number = 2000;

export const GroupSelectControl = (props: GroupSelectControlProps) => {
    const globalization = useContext(GlobalizationContext);
    const [options, setOptions] = useState<Array<SelectControlOption>>([]);
    const [options2, setOptions2] = useState<Array<SelectControlOption>>([]);
    let fieldConfig: any;

    useEffect(() => {
        if (options.length === 0) {
            setOptions(createOptions(props, globalization));
        }
    }, [options, props, globalization]);

    try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        fieldConfig = useField(props.name);
    } catch (e) {

    }

    const onChangeForComcobox = (v: any | string) => {
        if (props.onChange) {
            props.onChange(v);
        }

        if (typeof v === "string") {
            setOptions2(options.filter((option) => {
                return option.name.toLowerCase().includes(v.toLowerCase());
            }));
        }
    }

    let defaultOption = undefined;

    if (fieldConfig) {
        let defaultOptions = options.filter((option) => {
            return option.value === fieldConfig[1].initialValue;
        });
        if (defaultOptions && defaultOptions.length > 0) {
            defaultOption = defaultOptions[0];
        }
    }
    if (options.length > 50) {
        return <ComboboxControl disableFilter={true} placeholder="Please enter the group name." defaultValue={defaultOption} countTop={MAX_SIZE} hideCaret={false} data={options2} valueField="value" textField="name" {...props} onChange={onChangeForComcobox} />;
    } else {
        return <SelectControl {...props} options={options}></SelectControl>;
    }
}