import React, { useEffect, useState, useContext } from 'react';
import { Button, DL, DLI, Modal, GoogleTranslate } from '../../../../shared/components';
import GlobalizedText from '../../../../shared/globalization';
import { FooterProps } from '../../../../shared/theme';
import { ajax } from '../../../../shared/utils';
import { SubmitYourInquiry } from './submitinquiry';
import { SecurityContext } from '../../../../context/securityContext';

const PORTAL_ADMIN_USER_ROLE = "01";

export function Footer(props: FooterProps) {
    const {showEnquiryForm} = useContext(SecurityContext);

    const [version, setVersion] = useState<any>();

    useEffect(() => {
        if (version === undefined) {
            ajax({
                url: '/api/version',
                success: (res) => {
                    setVersion(res);
                },
                error: (error: any) => {
                    setVersion({});
                },
                showMask: false,
            });
        }


    }, [version]);

    if (version === undefined) {
        return <></>
    }



    return <>
        <footer className="foot-container">
            <div className="container">
                <div className="col-wrap row">
                    <div className='footer-translator'>
                        <GoogleTranslate />
                    </div>
                    <div id="foot-copyright">
                        <p>&copy; <GlobalizedText message="common.lbl.copyright" />{props.user &&  props.user.userTypeRegCode == PORTAL_ADMIN_USER_ROLE && <a href="#root" data-toggle="modal" data-target="#version_popup" className="f-version">**</a>}</p>
                    </div>
                </div>
            </div>
        </footer>
        {showEnquiryForm && <SubmitYourInquiry user={props.user} supportedFileTypes={props.userService.rootComponent.state.supportedFileTypes
} />}
        <Modal title="common.lbl.portalversion" id="version_popup" footer={() => {
            return <>
                <Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></Button></>
        }}>
            <DL>
                <DLI condition={version.productVersion !== undefined} title="common.lbl.productversion" desc={version.productVersion} descId="productVersion"></DLI>
                <DLI condition={version.buildNumber !== undefined} title="common.lbl.buildnumber" desc={version.buildNumber} descId="buildNumber"></DLI>
                <DLI condition={version.buildTime !== undefined} title="common.lbl.buildtime" desc={version.buildTime} descId="buildTimestamp"></DLI>
				<dt><span>History</span></dt>
				<dd><a href="/delta">Delta File Link</a></dd>
            </DL>
        </Modal>
    </>
}