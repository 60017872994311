import React, { useEffect, useReducer, useState } from 'react';
import GlobalizedText from '../../globalization';
import { getContextPath } from '../../utils';
import { ViewComponentProps } from '../../viewcomponent';
import { withErrorControl, WrapperControlProps } from './field';
import { TextControl } from './input';
import { Label } from './label';
import { Div } from './layout';
import { SelectControl, SelectControlProps } from './select';

interface SecurityImageCategory {
    name: string;
    msgKey: string;
}

interface SecurityImageCategorySelectProps extends SelectControlProps {
    categories: Array<SecurityImageCategory>;
    images: Array<SecurityImage>;
    imageName: string;
    getGlobalizedText: (key: string) => string;
}

interface SecurityImage {
    category: SecurityImageCategory;
    name: string;
    msgKey: string;
    width: number;
    height: number;
}

interface SecurityImageProps {
    image: SecurityImage;
    currentImage?: string;
    getGlobalizedText: (key: string) => string;
    onClick: (e?: any) => void;
}

interface SecurityImageSelectProps extends WrapperControlProps {
    name: string;
    category: string;
    images: Array<SecurityImage>;
    getGlobalizedText: (key: string) => string;
}

export interface SecurityImagesCategoryControlProps extends ViewComponentProps {
    images: Array<SecurityImage>;
    categories: Array<SecurityImageCategory>;
    categoryName: string;
    imageName: string;
}
export interface SecurityImagesControlProps extends ViewComponentProps {
    images: Array<SecurityImage>;
    categories: Array<SecurityImageCategory>;
    categoryName: string;
    imageName: string;
    securityPhaseName: string;
}

export const SecurityImagesCategoryControl = (props: SecurityImagesCategoryControlProps) => {
    return <Div className="gwp-security-images">
        <Label required={false}><GlobalizedText message="regist.label.sec.img" /></Label>
        <SecurityImageCategorySelect required={true} getGlobalizedText={props.getGlobalizedText} images={props.images} label="common.lbl.category" name={props.categoryName} imageName={props.imageName} categories={props.categories} />

    </Div>
}
export const SecurityImagesControl = (props: SecurityImagesControlProps) => {
    return <Div className="gwp-security-images">
        <Label required={true}><GlobalizedText message="regist.label.sec.img" /></Label>
        <SecurityImageCategorySelect getGlobalizedText={props.getGlobalizedText} images={props.images} label="common.lbl.category" name={props.categoryName} imageName={props.imageName} categories={props.categories} />
        <TextControl id={props.securityPhaseName} required={true} label="regist.label.sec.img.caption" name={props.securityPhaseName}></TextControl>
    </Div>
}

const SecurityImageCategorySelect = ({ categories, containerClassName, getGlobalizedText, imageName, ...props }: SecurityImageCategorySelectProps) => {

    const [state, dispatch] = useReducer((state: any, action: any) => {
        return { categoryName: action.type }
    }, { categoryName: categories[0].name });
    useEffect(() => {
        let val = document.querySelector(`select[name=${props.name}]`)?.getAttribute("data-form-value");
        if (val !== undefined && val !== null && val !== '' && val !== state.categoryName) {
            dispatch({ type: val })
        }
    });
    return <>
        <SelectControl onChange={(e: any) => {
            dispatch({ type: e.target.value })
        }} {...props} options={categories.map((category: SecurityImageCategory) => { return { name: category.msgKey, value: category.name } })}></SelectControl>
        <SecurityImageSelect category={
            state.categoryName
        } getGlobalizedText={getGlobalizedText} name={imageName} images={props.images}></SecurityImageSelect>
    </>
}

const SecurityImageSelect = withErrorControl(({ condition, getGlobalizedText, images, setValue, ...props }: SecurityImageSelectProps) => {
    const [imageName, setImageName] = useState<string>(props['data-form-value']);
    const temImpages = images.filter((image: SecurityImage) => { return image.category.name === props.category });
    return <Div className="gwp-input gwp-security-image-select">
        <input type="text" title="imageName" {...props} />
        {temImpages.map((image: SecurityImage, index: number) => {
            return <SecurityImage currentImage={imageName} onClick={() => { setValue(image.name); setImageName(image.name); }} key={index} image={image} getGlobalizedText={getGlobalizedText} />
        })
        }
    </Div>

});

const SecurityImage = (props: SecurityImageProps) => {
    return <Div className="gwp-inline"><Div onClick={props.onClick} title={props.getGlobalizedText(props.image.msgKey)} className={`gwp-security-image ${props.currentImage === props.image.name ? 'selected' : ''}`}>
        <img width={(props.image.width > props.image.height) ? 76 : (76 * props.image.width) / props.image.height} height={((props.image.height > props.image.width) ? 76 : (76 * props.image.height) / props.image.width) - 4} src={getContextPath() + "/static/themes/blue/images/security_image/" + props.image.category.name + "/" + props.image.name + ".jpg"} alt={props.getGlobalizedText(props.image.msgKey)} />
    </Div></Div>

}