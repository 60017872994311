import React, { useState } from 'react';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { SearchSection } from './searchsection';

export const AddBeneficiaryComponent = withView((props: ViewComponentProps) => {
    let companyCode = props.getQueryParam("companyCode")
    let policyNumber = props.getQueryParam("policyNumber")

    return <>
        <SearchSection {...props} changeType={2} companyCode={companyCode} policyNumber={policyNumber} />
    </>

});