import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Information } from '..';
import GlobalizedText from '../../globalization';
import { $, ajax, copyObjectExcept, hideMask, isEmptyStr, loadMask, onPreventBootStrapBlockingFocusin, removeFromArray, showMask, uploadFile } from '../../utils';
import { withErrorControl, WrapperControlProps } from './field';
import { Label } from './label';

export interface ContentViewerProps {
    className?: string,
    html?: string,
    style?: any,
    inModal?: true
}

export const ContentViewer = (props: ContentViewerProps) => {
    return React.createElement("div", { className: props.className, dangerouslySetInnerHTML: { __html: props.html }, style: props.style });
}

export interface ContentEditorProps extends WrapperControlProps {
    html?: string,
    enableMultipleImagesUploader?: boolean,
    onSave?: (content: any) => void,
    onChange?: (content: any) => void,
    onClose?:()=>void,
    contentId?: string;
    contentTypeId?: string;
    modal?: string;
    needReset?: boolean;
    inline?: boolean;
}

export const closeExtraMenu = () => {
    // close extra menu if opened.
    if ($(".tox-toolbar__overflow").length > 0) {
        $(".tox-tbtn.tox-tbtn--enabled").click();
    }
    // close dialog if opened
    if ($(".tox-dialog").length > 0) {
        $(".tox-dialog .tox-button.tox-button--secondary").click();
    }
}

let imageList: Array<any> = [];

export const ContentEditor = withErrorControl((props: ContentEditorProps) => {
    const [images, setImages] = useState<Array<any>>([]);
    const [count, setCount] = useState<number>(0);
    const [content, setContent] = useState<any>(props.html);
    const [files, setFiles] = useState<string>("");
    const [config, setConfig] = useState<any>();

    useEffect(() => {
        if (props.needReset === true) {
            setImages([]);
            setFiles("");
            setContent("")
        }
    }, [props.needReset]);

    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/cms/content/loadProperties',
                success: (data) => {
                    setConfig(data);
                }
            })
        }
    }, [config]);


    let handleEditorChange = (newContent: any, editor: any) => {
        if (props.onChange !== undefined) {
            props.onChange(newContent);
        }
        setContent(newContent);
    }

    let handleSave = () => {
        if (props.onSave !== undefined) {
            props.onSave(content)
        }
    }
    let handleClose=()=>{
        if (props.onClose !== undefined) {
            props.onClose()
        }    
    }
    useLayoutEffect(() => {
        onPreventBootStrapBlockingFocusin();
    });

    if (config === undefined) {
        return <></>
    }

    imageList = images.length === 0 ? [] : images.map((image: any, index: number) => {
        return { title: image.name, value: image.location };
    });
    // let emiu = props.enableMultipleImagesUploader;
    let emiu = false;
    return <props.Div className="gwp-content-editor">
        {props.label === undefined &&  <>
            <Label htmlFor={props.id} className="hidden">hidden content</Label>
        </>
        }
        {(emiu !== false && images.length > 0) && images.map((image: any, index: number) => {
            return <div key={index} >
                <label style={{ display: 'inherit' }}>{image.name}</label>
                <img alt={image.name} title={image.location} style={{ width: '200px' }} src={image.location} />
                <props.Button onClick={() => {
                    let img = images;
                    setImages(removeFromArray(img, index));
                    let c = count + 1;
                    setCount(c);
                }}>X</props.Button>
            </div>
        })
        }
        {emiu !== false && <>
            <form id={`${props.id ? props.id : ''}_fileUpload`} name="fileUpload" encType="multipart/form-data">

                <input multiple={true} value={files} style={{ marginTop: '10px' }} onChange={(e) => {
                    setFiles(e.target.value);
                }} type="file" accept=".jpg, .png, .jpeg, .webp" id={`content_${props.id ? props.id : ''}_${props.contentId ? props.contentId : ''}_file`} name="file"></input>
                <props.Button onClick={() => {
                    if (isEmptyStr(files)) {
                        alert("Please choose a file first.");
                        return;
                    }
                    if (props.modal) {
                        showMask(props.modal + " .modal-body");
                    } else {
                        loadMask({ action: "show" });
                    }
                    uploadFile({
                        url: `/api/cms/attachment/upload/${props.contentTypeId}/${props.contentId}`,
                        form: document.getElementById(`${props.id ? props.id : ''}_fileUpload`),
                        showMask: false,
                        success: (res) => {
                            let img = images;
                            if (res instanceof Array) {
                                for (let a of res) {
                                    img.push(a);
                                }
                            } else {
                                img.push(res);
                            }
                            setImages(img);
                            let c = count + 1;
                            setCount(c);
                            setFiles("");
                            if (props.modal) {
                                hideMask(props.modal + " .modal-body");
                            } else {
                                loadMask({ action: "hide" });
                            }
                        }
                    });
                }}>Upload</props.Button>
            </form>

            <props.HR /></>
        }
        <Information message="common.msg.copypaste"/>
        {<Editor
            initialValue={content}
            id={props.id}
            textareaName={props.name}
            inline={props.inline === true}
            init={{
                gecko_spellcheck: true, // spelling check
                file_picker_types: 'file image media',
                images_upload_url: `/api/cms/attachment/uploadSingleFile/${props.contentTypeId ? props.contentTypeId : 'temp'}/${props.contentId ? props.contentId : 'temp'}`, // upload image automatically to the file system.
                branding: false,
                height: 300,
                relative_urls: false,
                menubar: false,
                image_list: (success: any) => {
                    success(imageList);
                },
                attachment_max_size: 100 * 1024 * 1024,
                attachment_assets_path: '/static/tiny/icons/',
                ...copyObjectExcept(config, false, "apiKey")

            }}
            apiKey={config.apiKey}

            value={props.html === undefined ? "" : props.html}
            onEditorChange={handleEditorChange}

        />}
        {props.onSave !== undefined &&
            <props.Div>
                <props.Row>
                <props.Col md="8" sm="8" xs="12" class="gwp-content-button" >
                    <props.Button onClick={handleSave}><GlobalizedText message="common.lbl.saveandclose" /></props.Button>
                    <props.Button onClick={handleClose}><GlobalizedText message="common.lbl.close" /></props.Button>
                </props.Col>    
                </props.Row>
            </props.Div>
        }
    </props.Div>
});