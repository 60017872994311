import React from 'react';
import { Container } from '../../../../shared/components';
export const DefaultLayout = (props: any) => {
    return <div className="gwp-portlet-container">
        <Container>
            {props?.children}
        </Container>
    </div>
}

export const EmptyLayout = (props: any) => {
    return <>
        {props?.children}
    </>
}
