import { Form, Formik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, offPreventBootStrapBlockingFocusin, saveToStorage, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { PortalCategory } from './portalcategories';
import './css/manageportalcategories.css';
import { closeExtraMenu, SelectControlOption } from '../shared/components';
import { YupSchema } from '../shared/yupschema';
import { useMessage } from '../shared/message';
import * as yup from 'yup';
import { CONTENT_PREVIEW_ID, CONTENT_PREVIEW_STORAGE_KEY, CONTENT_VIEWER_TYPE_PREVIEW } from './contentviewer';

interface ManagePortalCategoryView {
    portalCategories: Array<PortalCategory>,
    userTypes: Array<SelectControlOption>,
    contentTypeID: string
}

export const ManagePortalCategoriesComponent = withView((props: ViewComponentProps) => {

    const [selected, setSelected] = useState<PortalCategory>();
    const [view, setView] = useState<ManagePortalCategoryView>();
    const mode = useRef<CreateEditModalType>('create');
    const [count, setCount] = useState<number>(0);
    const deletedName = useRef<string>();

    const load = () => {
        ajax({
            url: '/api/cms/manageportalcategory/view',
            success: (res: any) => {
                setView(res);
                if (selected !== undefined) {
                    res.portalCategories.forEach((pc: any, i: number) => {
                        if (pc.id === selected.id) {
                            setSelected(pc);
                        }
                    })
                }
            }
        });
    }

    const refreshOpenModal = () => {
        let c = count + 1;
        setCount(c);
    }

    useEffect(() => {
        if (count !== 0) {
            props.showModal("#createPortalCategoryModal");
        }
    }, [count]);

    useEffect(() => {
        if (view === undefined) {
            load();
        }
    }, [view]);

    useEffect(() => {
        if (deletedName.current !== undefined) {
            props.showMessage("success", props.getGlobalizedText("delete.category.success").replace("[param1]", deletedName.current));
            deletedName.current = undefined;
        }
    }, [selected])


    if (view === undefined) {
        return <></>
    }

    return <>
        <props.Div>
            <Buttons {...props} mode={mode} suffix="top" refreshOpenModal={refreshOpenModal} setSeleted={setSelected} selected={selected} />
        </props.Div>
        <Formik initialValues={{
            portalCategory: ''
        }} onSubmit={(values: any) => {

        }}>
            <Form>
                {
                    view.portalCategories.map((pg, index) => {
                        return <props.Div className="portalCategory" style={{ marginLeft: `${(pg.fullPath.length - 1) * 20}px` }} key={index}>
                            <props.RadioControl id={`portalCategory_${index}`} onClick={(e: any) => {
                                setSelected(view.portalCategories.filter((category) => { return category.id === pg.id })[0]);
                            }} name="portalCategory" value={pg.id}></props.RadioControl>
                            <label htmlFor={`portalCategory_${index}`} className="portalCategoryLabel">{`${pg.name}`}<props.Span condition={pg.fullPath.length === 1}>{` - (${pg.userTypeNames.map((key: string) => { return props.getGlobalizedText(key) }).join(" / ")})`}</props.Span></label>
                        </props.Div>
                    })
                }
                {view.portalCategories.length === 0 && <GlobalizedText message="common.msg.nocatexist" />}
            </Form>
        </Formik>
        <props.Div>
            <Buttons {...props} mode={mode} suffix="bottom" setSeleted={setSelected} refreshOpenModal={refreshOpenModal} selected={selected} />
        </props.Div>

        <CreateEditModal {...props} mode={mode.current} selected={selected} load={load} contentTypeID={view.contentTypeID} userTypes={view.userTypes} />

        <props.Modal id="deletePortalCategoryModal" title={() => {
            return <><props.Icon type="warning"></props.Icon><GlobalizedText message="common.lbl.delPortalCate" /></>
        }} footer={() => {
            return <>
                <props.Button onClick={() => {
                    ajax({
                        url: '/api/cms/manageportalcategory/delete',
                        params: { id: selected?.id },
                        method: 'delete',
                        success: () => {
                            load();
                            deletedName.current = selected ? selected.name : '';
                            setSelected(undefined);
                            props.closeModal("#deletePortalCategoryModal");
                        }
                    });
                }}><GlobalizedText message="common.lbl.wpdelete" /></props.Button>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Div>
                <GlobalizedText message="common.msg.selectedToDel" /> <b> {`<${selected?.name}>`}</b>.<p />
                <GlobalizedText message="common.msg.deletedAll" /><p />
                <GlobalizedText message="common.msg.confirmDelete" />
            </props.Div>
        </props.Modal>
    </>

});

interface ButtonsProps extends ViewComponentProps {
    selected: any;
    suffix: string;
    setSeleted: (selected: any) => void;
    refreshOpenModal: () => void;
    mode: any;
}

const Buttons = (props: ButtonsProps) => {
    return <>
        <props.Button id={`createPortalCategoryButton_${props.suffix}`} onClick={() => { props.mode.current = 'create'; props.refreshOpenModal() }}>Create</props.Button>
        <props.Button id={`editPortalCategoryButton_${props.suffix}`} onClick={() => { props.mode.current = 'edit'; props.refreshOpenModal() }} disabled={props.selected === undefined}>Edit</props.Button>
        <props.Button id={`deletePortalCategoryButton_${props.suffix}`} onClick={() => { props.showModal("#deletePortalCategoryModal") }} disabled={props.selected === undefined}>Delete</props.Button>
        <props.Button id={`closePortalCategoryButton_${props.suffix}`} onClick={props.back}><GlobalizedText message="common.lbl.close" /></props.Button>
    </>
}

type CreateEditModalType = 'edit' | 'create'

interface CreateEditModalProps extends ViewComponentProps {
    mode: CreateEditModalType
    selected: any
    load: () => void
    contentTypeID: string
    userTypes: SelectControlOption[]
}

const CreateEditModal = (props: CreateEditModalProps) => {
    const { messageService, validateProps } = useMessage("managePortalCategoryModal");
    const [editContent, setEditContent] = useState<any>();
    const [openEdit, setOpenEdit] = useState<number>(0);
    useEffect(() => {
        if (openEdit > 0) {
            props.showModal("#editWebPageModal");
        }
    }, [openEdit]);

    const showUserTypes = () => {
        let userTypes = [];
        for (let i = 0; i < props.userTypes.length; i++) {
            userTypes.push(
                <React.Fragment key={i}>
                    <props.Col xs={6}>
                        <props.CheckboxControl id={`userType_${props.userTypes[i].value}`} name="userTypes" value={props.userTypes[i].value}></props.CheckboxControl><label htmlFor={`userType_${props.userTypes[i].value}`} className="portalCategoryLabel"><GlobalizedText message={props.userTypes[i].name} /></label>
                    </props.Col>
                </React.Fragment>
            )
        }
        return userTypes;
    }

    return <><Formik initialValues={
        {
            id: props.mode === 'edit' ? props.selected.id : undefined,
            level: props.selected !== undefined && props.selected.parentID !== undefined ? 'c' : 'p',
            name: props.mode === 'edit' ? props.selected.name : '',
            userTypes: props.selected !== undefined && props.selected.parentID === undefined && props.mode === 'edit' ? props.selected.userTypes : ['10000']
        }
    } enableReinitialize={true} onSubmit={(values: any, formHelpers) => {
        let data: any = {
            name: values.name,
            userTypes: values.userTypes.join("|"),
        };
        if (props.mode === 'create' && props.selected !== undefined && values.level === 'c') {
            data.parentID = props.selected.id;
        }
        if (props.mode === 'edit') {
            data.id = props.selected.id;
        }
        ajax({
            url: props.mode === 'edit' ? '/api/cms/manageportalcategory/update' : '/api/cms/manageportalcategory/create',
            method: props.mode === 'edit' ? 'put' : 'post',
            data: data,
            success: (res) => {
                props.load();
                props.closeModal("#createPortalCategoryModal");
                formHelpers.resetForm();
            }, error: () => {
                props.showMessage("error", "Get failure when " + (props.mode === 'edit' ? "updating" : "adding") + " category [" + values.name + "].");
            }
        });
    }}
        validate={
            (values) => {
                return validate(yup.object().shape(YupSchema.managePortalCategories), values, validateProps);
            }
        }
        {...props.generateNoramlFormikProps()}
    >
        {formProps =>
            < Form >
                <props.Modal messageService={messageService} id="createPortalCategoryModal" title={`${props.mode === 'edit' ? props.getGlobalizedText("common.lbl.editPortal") : props.getGlobalizedText("common.lbl.createCategory")}`} footer={() => {
                    return <>
                        <props.Button onClick={() => { }} type="submit"><GlobalizedText message="common.lbl.save" /></props.Button>
                        <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                    </>
                }}>
                    {props.mode === 'create' &&
                        <><props.Label><GlobalizedText message="common.lbl.level" /></props.Label>
                            <props.RadioControl value="p" name="level" id="level_p"></props.RadioControl><label htmlFor="level_p" className="portalCategoryLabel"><GlobalizedText message="common.lbl.parentLevel" /></label>
                            <props.RadioControl disabled={props.selected === undefined} value="c" name="level" id="level_c"></props.RadioControl><label htmlFor="level_c" className="portalCategoryLabel"><GlobalizedText message="common.lbl.childLevel" /></label>
                        </>
                    }
                    <props.TextControl required={true} id="name" name="name" label="Name" />
                    <props.Div condition={props.selected?.contentID !== undefined && props.mode === 'edit'} className="editPage"><props.LinkButton onClick={() => {
                        ajax({
                            url: `/api/cms/content/get/${props.selected?.contentID}`,
                            success: (res) => {
                                setEditContent(res);
                                saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: res.content }, false);
                                setOpenEdit(openEdit + 1);
                            }
                        });
                    }}><props.Icon type="update" label="common.lbl.editWebPage"></props.Icon></props.LinkButton></props.Div>
                    <props.Div condition={formProps.values.level === 'p'}>
                        <props.Label><GlobalizedText message="labelMessage.accessPermission" /></props.Label>
                        <props.Div className="selectUserLabel"><GlobalizedText message="common.lbl.selectUser" /></props.Div>
                        <props.Row>
                            {
                                showUserTypes()
                            }
                        </props.Row>
                    </props.Div>
                </props.Modal>
            </Form >
        }
    </Formik >
        {editContent !== undefined &&
            <props.Modal id="editWebPageModal" title="common.lbl.editWebPage" size="large" footer={() => {
                return <>
                    <props.Button disabled={props.selected?.contentID === undefined} onClick={() => {
                        window.open(`${process.env.PUBLIC_URL}/content?id=${CONTENT_PREVIEW_ID}&type=${CONTENT_VIEWER_TYPE_PREVIEW}`, '', 'height=600, width=960, top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no');
                    }}><GlobalizedText message="common.lbl.preview" /></props.Button>
                    <props.Button onClick={() => {
                        ajax({
                            url: '/api/cms/portalcategory/editPage',
                            method: 'post',
                            data: {
                                id: props.selected?.id,
                                contentID: props.selected?.contentID,
                                content: editContent.content
                            },
                            success: () => {
                                props.closeModal("#editWebPageModal");
                                closeExtraMenu();
                                props.showMessage("success", props.getGlobalizedText("common.msg.editpagesucc"));
                                props.load();
                            }
                        });
                    }}><GlobalizedText message="common.lbl.saveandclose" /></props.Button>
                    <props.Button data-dismiss="modal" onClick={() => { closeExtraMenu(); offPreventBootStrapBlockingFocusin() }}><GlobalizedText message="common.lbl.close" /></props.Button>

                </>
            }}>
                <props.ContentEditor contentId={props.selected?.contentID} contentTypeId={props.contentTypeID} html={editContent.content} onChange={(newContent: string) => {
                    setEditContent({ ...editContent, content: newContent });
                    saveToStorage(CONTENT_PREVIEW_STORAGE_KEY, { content: newContent }, false);
                }}  {...props} id="editWebPageEditor" />
            </props.Modal>
        }
    </>

}
