import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GlobalizationContext } from '../../globalization';
import { ajax, decodeAmpersand, getGlobalizedText, isEmptyStr } from '../../utils';
import { ReadOnlyProps, withErrorControl, WrapperControlProps } from './field';
import { FormGroupProps } from './formgroup';

export interface SelectControlOption {
    value: string;
    name: string;
}

export interface SelectControlProps extends WrapperControlProps, FormGroupProps, ReadOnlyProps {
    /**
     * Options that will be displayed in the dropdown
     */
    options?: Array<SelectControlOption>;

    /**
     * Default option name, the default option would always be displayed as the first option.
     */
    defaultOption?: string;

    children?: any;
    [propName: string]: any;

    /**
     * Sortable or not, default is true.
     */
    sort?: boolean;

    /**
     * Sort by name, default is true, if set to false, then will sort by value.
     */
    sortByName?: boolean;

    /**
     * Indicate that the selected item can be cleared or not.
     */
    clearable?: boolean;
}

export const createOptions = (props: SelectControlProps, globalization: any): Array<SelectControlOption> => {
    let options = props.options?.map((option: SelectControlOption) => {
        return { value: option.value, name: decodeAmpersand(globalization === null || globalization[option.name] === undefined ? option.name : globalization[option.name]) }
    });

    if (!options) {
        options = props.children?.map((child: React.ReactElement) => {
            if (typeof child.props.children !== "string") {
                throw new Error("Children of option node must be a text.");
            }
            return { value: child.props.value, name: child.props.children };
        });
    }
    let defaultOptionObj = undefined;

    if (props.defaultOption !== undefined) {
        options = options?.filter((option: SelectControlOption) => {
            if (props.defaultOption !== undefined) {
                if (option.name !== getGlobalizedText(globalization, props.defaultOption)) {
                    return option;
                } else {
                    defaultOptionObj = option;
                }
            }
        });
    }

    if (props.sort === undefined || props.sort === true) {
        options?.sort((first: SelectControlOption, next: SelectControlOption) => {
            if (props.sortByName !== false) {
                if (first.name === next.name) {
                    return 0;
                }
            } else {
                if (first.value === next.value) {
                    return 0;
                }
            }
            return props.sortByName !== false ? (first.name > next.name ? 1 : -1) : (first.value > next.value ? 1 : -1)
        });
    }

    if (defaultOptionObj !== undefined && options !== undefined) {
        options = [defaultOptionObj, ...options]
    }
    if (!options) {
        options = [];
    }
    return options;
}

export const SelectControl = withErrorControl(({ onChange, containerClassName, sort, condition, setValue, className, defaultOption, clearable, ...props }: SelectControlProps) => {
    const globalization = useContext(GlobalizationContext);
    let formik = useFormikContext();
    let options = createOptions({ sort: sort, defaultOption: defaultOption, ...props }, globalization);
    return <select onChange={(e) => { if (formik !== undefined) { formik.getFieldHelpers(props.name).setValue(e.target.value); } if (onChange !== undefined) { onChange(e) } }} value={formik ? formik.getFieldProps(props.name).value : undefined} className={`form-control gwp-select ${className !== undefined ? className : ''}`} {...props}>
        {options !== null && options !== undefined && options.length > 0 && options.map((o: any, index: number) => {
            return <option key={index} value={o.value}>{o.name}</option>

        })}
    </select>
});


export const NAME_TYPE_PERSON = "1";
export const NAME_TYPE_COMPANY = "2";
export interface NameTypeSelectControlProps extends SelectControlProps {
}
export const NameTypeSelectControl = (props: NameTypeSelectControlProps) => {
    const [options, setOptions] = useState<any[]>([]);
    let formik = useFormikContext();
    const init = useRef(false);
    useEffect(() => {
        if (options.length === 0 && init.current === false) {
            init.current = true;
            ajax({
                url: '/api/common/getNameTypes',
                showMask: false,
                success: (res) => {
                    setOptions(res);
                    if (formik !== undefined) {
                        if (isEmptyStr(formik.getFieldProps(props.name).value)) {
                            formik.getFieldHelpers(props.name).setValue(res[0].value);
                        }
                    }
                }
            });
        }
    }, [options, formik, props.name]);
    return <SelectControl {...{
        options: options, sort: false, label: "addbene.lbl.choseType", ...props
    }} />
}