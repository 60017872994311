import React, { useEffect } from 'react';
import { UserContext } from '../shared/authentication';
import { isEmptyStr } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';

export const RedirectComponent = withView((props: ViewComponentProps) => {

    const redirectURL = props.getParam("redirect");
    useEffect(() => {
        if (!isEmptyStr(redirectURL)) {
            props.next(redirectURL);
        }
    });

    return <>
        <UserContext.Consumer>
            {user => {
                if (user !== null) {
                    props.next(props.getDefaultHome())
                }
                return <props.Div> <h4>{'Redirecting...'}</h4></props.Div>
            }
            }
        </UserContext.Consumer>
    </>
});