import React, { Component } from 'react';
import { render } from 'react-dom';
import { Route, BrowserRouter as Router } from "react-router-dom";
import 'react-widgets/dist/css/react-widgets.css';
import { ConfigContext, loadConfiguration } from './config/config';
import RouteConfig, { ComponentConfig } from './config/route-config';
import SecurityContextProvider from './context/securityContext';
import './index.css';
import { AnalyticsObj } from './shared/analytics/analytics';
import { User, UserContext, UserService } from './shared/authentication';
import Body from './shared/body';
import { BreadCrumbService } from './shared/breadcrumb';
import { ErrorModal } from './shared/error';
import { GlobalizationContext, init as initGlobalization } from './shared/globalization';
import { MessageService } from './shared/message';
import './shared/polyfill';
import { DEFAULT_LAYOUT, DEFAULT_THEME, Footer, Header, Layout, ThemeConfig, ThemeService, ThemeState } from './shared/theme';
import { getContextPath, getFromStorage, getPathName, isEmptyStr, win, saveToStorage, ajax } from './shared/utils';

interface AppProps {

}

interface AppState {
  messages: string | null;
  theme: string | 'default';
  layout: string | 'default';
  user: any | null;
  numberFormat: string | null;
  components: { [componentId: string]: { theme: string } } | null;
  profiles: Array<string>;
  routeConfigs: ComponentConfig | null;
  [key: string]: any,
  expirationTime:number;
}

export class App extends Component<AppProps, AppState>  {
  state: AppState = {
    messages: null,
    theme: DEFAULT_THEME,
    layout: DEFAULT_LAYOUT,
    user: null,
    numberFormat: null,
    components: null,
    profiles: [],
    routeConfigs: null,
    defaultTheme: DEFAULT_THEME,
    defaultLayout: DEFAULT_LAYOUT,
    expirationTime: 1,
  }

  userService = new UserService(this);
  breadcrumb = new BreadCrumbService();
  themeService = new ThemeService();
  messageService = new MessageService();
  siteMessageService = new MessageService();
  isThemeSettled = false;
  allProtectedRouteConfigs: any = null;
  componentDidMount() {
    this.allProtectedRouteConfigs = { ...RouteConfig.authenticated, ...this.loadProfileComponentConfig(this.state.profiles) }
    // initialize globalization
    initGlobalization(this);
    // load configurations from server
    loadConfiguration(this, (conf: any) => {
      this.userService.loadUser((user: User) => {
        conf.user = user;
        this.initThemes(conf);
        initGlobalization(this);
      }, () => {
        try {
          if (this.breadcrumb.last().pathname !== window.location.pathname) {
            this.breadcrumb.clear();
          }
        } catch (e) {
          if (this.findComponent(this.allProtectedRouteConfigs) !== undefined) {
            win.location.href = getContextPath() + "/login?from=" + encodeURIComponent(window.location.pathname + window.location.search);
          }
        }
        this.initThemes(conf);
        initGlobalization(this);
      });
      AnalyticsObj.initialize(conf);

      // This part of code is responsible to change favicon icon based on theme
      // property from application.yml file
      const link = document.querySelector('link[rel="icon"]');

      if (link && conf.theme !== 'default') {
        link.setAttribute('href', `favicon_${conf.theme}.png`);
      }
    });
    ajax({
      url: '/api/token',
      success: (res) => {
        saveToStorage('_csrf', res);
      }
    });
  }

  initThemes(conf: any) {
    const isAuthen = conf.user !== null;
    conf.themeConfigs = this.loadThemeConfigs(conf);
    conf.routeConfigs = { ...{ ...isAuthen ? {} : RouteConfig.unauthenticated, ...!isAuthen ? {} : RouteConfig.authenticated, ...RouteConfig.all }, ...this.loadProfileComponentConfig(conf.profiles, isAuthen) };
    this.allProtectedRouteConfigs = { ...RouteConfig.unauthenticated, ...RouteConfig.authenticated, ...RouteConfig.all, ...this.loadProfileComponentConfig(conf.profiles) }
    let comp = this.findComponent(conf.routeConfigs);
    let theme: any = {};
    if (comp !== undefined && conf.components[comp.id] !== undefined) {
      theme = { themeName: conf.components[comp.id].theme ? conf.components[comp.id].theme : conf.theme, layout: conf.components[comp.id].layout ? conf.components[comp.id].layout : conf.layout, themeConfigs: conf.themeConfigs };
    } else {
      theme = { themeName: conf.theme, layout: conf.layout, themeConfigs: conf.themeConfigs }
    }
    if ((theme.themeName !== conf.theme && theme.layout !== conf.layout) || this.isThemeSettled === false) {
      this.setTheme(theme);
      this.isThemeSettled = true;
      conf.theme = theme.themeName;
      conf.layout = theme.layout;
    }
    this.setState(conf);
  }

  findComponent(componentConfig: ComponentConfig) {
    let pn = getPathName().replace(getContextPath(), "");
    for (let cc in componentConfig) {
      if (componentConfig[cc].regex?.test(pn)) {
        return componentConfig[cc];
      }
    }
  }

  setUser(user: any) {
    let conf = this.state;
    conf.user = user;
    this.initThemes(conf);
  }

  clearUser() {
    this.breadcrumb.clear();
    let conf = this.state;
    conf.user = null;
    const config = getFromStorage('gwp.config', true);
    if(!config.openIdConnect){
      this.initThemes(conf);
    }
  }

  setTheme(themeState: ThemeState) {
    this.themeService.setTheme(themeState);
  }

  getTheme(): ThemeState | null {
    return this.themeService.getTheme();
  }

  loadThemeConfigs(conf: AppState): ThemeConfig {
    let themeConfigs = require("./themes/themes.tsx").default;
    if (conf.profiles.length > 0) {
      for (let profile of conf.profiles) {
        if (!isEmptyStr(profile)) {
          try {
            themeConfigs = { ...themeConfigs, ...require(`./customized/${profile}/themes`).default };
          } catch (e) {
            console.error(`Getting error [${e}] when loading theme for [${profile}]`);
          }
        }
      }
    }
    return themeConfigs;
  }

  loadProfileComponentConfig(profile: Array<string>, isAuthenticated?: boolean): ComponentConfig {
    let result = {};
    for (let p of profile) {
      try {
        if (isAuthenticated === undefined) {
          result = { ...result, ...require(`./customized/${p}/pages`).authenticated, ...require(`./customized/${p}/pages`).unauthenticated }
        } else {
          result = { ...result, ...isAuthenticated ? require(`./customized/${p}/pages`).authenticated : require(`./customized/${p}/pages`).unauthenticated }
        }
        result = { ...result, ...require(`./customized/${p}/pages`).all }
      } catch (e) {
        console.error("Getting error when loading customized component.");
      }
    }
    return result;
  }

  render() {
    if (this.state.theme === null) {
      return <></>;
    }
    return <Router>
      <Route path='/openidlogin' component={() => {
          const config = getFromStorage('gwp.config', true);
          window.location.href = config.ssoRedirectUrl;
          return null;
      }}/>
      <SecurityContextProvider>
        <GlobalizationContext.Provider value={this.state.messages}>
          <UserContext.Provider value={this.state.user}>
            <ConfigContext.Provider value={this.state}>
              <Header siteTheme={this.state.theme} themeService={this.themeService} breadcrumb={this.breadcrumb} userService={this.userService} themeConfigs={this.state.themeConfigs} />
              <Layout themeConfigs={this.state.themeConfigs} themeService={this.themeService} siteTheme={this.state.theme}>
                {this.state.messages !== null &&
                  <Body routeConfigs={this.state.routeConfigs} siteTheme={this.state.theme} themeService={this.themeService} themeConfigs={this.state.themeConfigs} rootComponent={this} profiles={this.state.profiles} breadcrumb={this.breadcrumb} userService={this.userService} />
                }
              </Layout>
              <Footer user={this.state.user} messageService={this.siteMessageService} siteTheme={this.state.theme} themeService={this.themeService} themeConfigs={this.state.themeConfigs} userService={this.userService} />
              <ErrorModal />
            </ConfigContext.Provider>
          </UserContext.Provider>
        </GlobalizationContext.Provider>
      </SecurityContextProvider>
    </Router>
  }
}
render(<App />, document.getElementById('root'));
