import React, { HTMLAttributes } from 'react';
import { ConditionProps, withCondition } from './shared-component';

export interface PanelProps extends HTMLAttributes<HTMLDivElement>, ConditionProps {

}

export interface PanelHeadingProps extends HTMLAttributes<HTMLDivElement> {
    withUserIcon?: boolean;
}

export interface PanelBodyProps extends HTMLAttributes<HTMLDivElement> {
    replaceClass?: boolean;
}

export interface PanelFooterProps extends HTMLAttributes<HTMLDivElement> {

}
export const Panel = withCondition(({ className, condition, ...props }: PanelProps) => {
    return <div className={`panel panel-default ${className}`} {...props}>
        {props.children}
    </div>;
});
export function PanelHeading(props: PanelHeadingProps) {
    return <div className="panel-heading">
        {props.withUserIcon === true &&
            <>
                <span className="glyphicon glyphicon-user"></span>&nbsp;
                <span className="panel-title">
                    {props.children}
                </span>
            </>
        }
        {props.withUserIcon !== true &&
            <div className="panel-title">
                {props.children}
            </div>
        }
    </div>
}
export function PanelBody({ className, replaceClass, ...props }: PanelBodyProps) {
    if (replaceClass === true)
        return <div className={`${className ? className : ''}`} style={props.style}>{props.children}</div>
    return <div className={`panel-body ${className ? className : ''}`} style={props.style}>{props.children}</div>
}
export function PanelFooter({ className, ...props }: PanelFooterProps) {
    return <div className={`panel-footer ${className ? className : ''}`}>{props.children}</div>
}