import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../shared/authentication';
import GlobalizedText from '../shared/globalization';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { ajax, isMobileDevice } from '../shared/utils'
import './css/pendingTransactions.css'
export const PendingTransactionsComponent = withView((props: ViewComponentProps) => {

    let userContext = useContext(UserContext);
    const [isAdminPanelShowed, setIsAdminPanelShowed] = useState<boolean>(false);
    const [fromDateRequiredMsg, setFromDateRequiredMsg] = useState<any>();
    const msgPrefix = props.getGlobalizedText('homepage.thereAre');
    const msgSuffix = props.getGlobalizedText('common.lbl.pending4approve');
    useEffect(() => {
        if (fromDateRequiredMsg === undefined) {
            ajax({
                url: '/api/pendTrans/view',
                success: (res) => {
                    setIsAdminPanelShowed(res.adminPanelShowed);
                    setFromDateRequiredMsg(msgPrefix + " " + res.numberOfPendTrans + " " + msgSuffix);
                }
            });
        }
    });

    if (userContext !== null) {
        return <div id="pendingTransactions">
            <props.Panel condition={isAdminPanelShowed}>
                <props.PanelBody className="pfoeclass1">
                    <props.Div className="gwp-page-title">
                        <GlobalizedText message="common.lbl.pendingtransactions" />
                    </props.Div>
                    {isMobileDevice() && <>
                        <props.Div className="blankup"></props.Div>
                    </>}
                    <props.Span className="req-msg">
                        {fromDateRequiredMsg}
                    </props.Span>
                    {isMobileDevice() && <>
                        <props.Div className="blankdown"></props.Div>
                    </>}
                    <props.Button className="btn-viewAll" onClick={() => { props.next("/enrollmentTransactionList") }}><GlobalizedText message="common.lbl.viewall" /></props.Button>
                </props.PanelBody>
            </props.Panel>
        </div >
    } else {
        return <></>
    }
});