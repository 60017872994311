import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../shared/authentication';
import GlobalizedText from '../shared/globalization';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { ajax, isMobileDevice } from '../shared/utils'
import './css/firstlogupdate.css'
import { TableColumn } from "../shared/components";

export const FirstLogUpdateComponent = withView((props: ViewComponentProps) => {
    let userContext = useContext(UserContext);
    const [config, setConfig] = useState<any>();
    const [isAdminPanelShowed, setIsAdminPanelShowed] = useState<boolean>(false);
    const [fromDateRequiredMsg, setFromDateRequiredMsg] = useState<any>();
    const [deleteSuccessMsg, setDeleteSuccessMsg] = useState<any>();
    const [sizeOfTransList, setSizeOfTransList] = useState<any>();
    const [transactionFlag, setTransactionFlag] = useState<boolean>(false);
    const [selectetedRowData, setSelectetedRowData] = useState<any>();
    const [disablePendingBtnFlag, setDisablePendingBtnFlag] = useState<boolean>(true);
    const [isShowAdminLandingImage, setIsShowAdminLandingImage] = useState<boolean>(false);

    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/user/current',
                success: (res: any) => {
                    setConfig(res);
                    retrievePendTrans(res);
                    retrieveTransList(res);
                    if (res?.userTypeRegCode === '09') {
                        props.setPageTitle(props.getGlobalizedText("common.lbl.gAdminLandingPageMessage"));
                        setIsShowAdminLandingImage(true)
                    }
                    else{
                        props.setPageTitle(props.getGlobalizedText("firupd.welcomeloomis") + " " + res.firstName + " " + res.lastName + "!");
                    }
                }, error: (error: any) => {

                }
            });
        }
    }, [config]);
    function retrievePendTrans(formProps: any) {
        ajax({
            url: '/api/pendTrans/view',
            success: (res) => {
                setIsAdminPanelShowed(res.adminPanelShowed);
                setFromDateRequiredMsg(props.getGlobalizedText('homepage.thereAre') + " " + res.numberOfPendTrans + " " + props.getGlobalizedText('common.lbl.pending4approve'))
            }, error: (error) => {
            }
        });
    }
    function saveSelectedValue(row: any) {
        setSelectetedRowData(row);
        setDisablePendingBtnFlag(false);
    }
    const tableColumns: Array<TableColumn> = [
        {
            className: "gwp-dt-detail-control", title: "", sortable: false
        },
        { title: "common.lbl.transNumber", name: "transactionNumber", sortable: true },
        { title: "common.lbl.memberID", name: "memberId", sortable: true },
        { title: "common.lbl.memberName", name: "memberName", sortable: true },
        { title: "common.lbl.group", name: "groupNumber", sortable: true },
        { title: "common.lbl.account", name: "accountNumber", sortable: true },
        { title: "common.lbl.submitDate", name: "submitDate", sortable: true, type: "date" },
        { title: "common.lbl.type", name: "type", sortable: true },
        { title: "common.lbl.status", name: "status", sortable: true }
    ]
    const [pendingTranstable, setPendingTranstable] = useState<any | null>({ columns: tableColumns, data: [] });
    function retrieveTransList(formProps: any) {
        ajax({
            url: '/api/pendTrans/getTransList',
            success: (res: any) => {
                if (res.transactionList.length === 0) {
                    setTransactionFlag(false);
                } else {
                    setTransactionFlag(true);
                    setSizeOfTransList(res.sizeOfTransList);
                    setPendingTranstable({
                        columns: tableColumns,
                        data: res.transactionList.map((row: any) => {
                            return { ...row, ...{ checked: false } }
                        })
                    });
                    props.setTitle("common.lbl.enrollmenttransactionlist");
                }

            },
            error: () => {

            }
        });
    }
    function continueTransaction() {
        let type = selectetedRowData.type;

        if (type === 'Update Member Payroll Schedule') {
            props.next(`/updatepayrollschedule?companyCode=${selectetedRowData.companyCode.trim()}&groupNumber=${selectetedRowData.groupNumber.trim()}&accountNumber=${selectetedRowData.accountNumber}&participantIDNumber=${selectetedRowData.memberId}&transactionNumber=${selectetedRowData.transactionNumber.trim()}`);

        } else {
            props.next(`/enrollment?fromTransactionList=true&companyCode=${selectetedRowData.companyCode.trim()}&groupNumber=${selectetedRowData.groupNumber.trim()}&accountNumber=${selectetedRowData.accountNumber}&participantIDNumber=${selectetedRowData.memberId}&transactionNumber=${selectetedRowData.transactionNumber.trim()}&transactionIDList=${selectetedRowData.transactionNumber.trim()}&formAction=editTransaction`);
        }
    }
    function deleteTransaction() {
        props.showModal('#delPendingTrans');
    }
    function confirmDeletePendingTrans() {
        ajax({
            url: `/api/pendTrans/deleteTransaction`,
            method: 'delete',
            params: {
                transactionID: selectetedRowData.transactionNumber
            },
            success: (res: any) => {
                props.closeModal('#delPendingTrans');
                setDeleteSuccessMsg(props.getGlobalizedText("common.msg.transactionDeletedSuccessfully"));
                retrieveTransList(res);
                setDisablePendingBtnFlag(true);
            }
        });
    }
    if (userContext !== null) {
        const needFirstUpdate = userContext.needFirstUpdate;
        const needUpdatePwd = userContext.needUpdatePwd;
        let message = "";
        if (needFirstUpdate === true && needUpdatePwd === false) {
            message = "firupd.security.question.required";
        }
        if (needFirstUpdate === false && needUpdatePwd === true) {
            message = "firupd.need.update.password";
        }
        if (needFirstUpdate === true && needUpdatePwd === true) {
            message = "firupd.security.info.required";
        }
        return <props.Div>
            <props.Panel condition={needFirstUpdate === true || needUpdatePwd === true} className="aptclass">
                <props.PanelBody>
                    <props.Row>
                        <props.Col sm={8} md={9} xs="6">
                            <props.Icon type="warning" label="firupd.warning" labelClass="gwp-label-warning warning"></props.Icon>
                            <props.Div className="warning-message">
                                <GlobalizedText message={message}></GlobalizedText>
                            </props.Div>
                        </props.Col>
                        <props.Col sm={4} md={3} xs="6">
                            <props.Button containerClassName="gwp-btn-container" onClick={() => { props.next("/myprofile") }}><GlobalizedText message="common.lbl.update" /></props.Button>
                        </props.Col>
                    </props.Row>
                </props.PanelBody>
            </props.Panel>
            <props.Div  >
                {isShowAdminLandingImage ? <div className="gadminLandingImg"></div> : null}
            </props.Div>
            <props.Panel condition={isAdminPanelShowed} className="aptclass">
                <props.PanelBody className="pfoeclass1">
                    <props.Div className="gwp-page-title">
                        <GlobalizedText message="common.lbl.pendingtransactions" />
                    </props.Div>
                    {isMobileDevice() && <>
                        <props.Div className="blankup"></props.Div>
                    </>}
                    <props.Span className="req-msg">
                        {fromDateRequiredMsg}
                    </props.Span>
                    {isMobileDevice() && <>
                        <props.Div className="blankdown"></props.Div>
                    </>}
                    <props.Button className="btn-viewAll" onClick={() => { props.next("/enrollmentTransactionList") }}><GlobalizedText message="common.lbl.viewall" /></props.Button>
                </props.PanelBody>
            </props.Panel>
            <props.Panel condition={transactionFlag} className="aptclass">
                <props.PanelBody className="pfoeclass1">
                    <props.Div className="gwp-page-title">
                        <GlobalizedText message="common.lbl.transactionList" />
                    </props.Div>
                    <props.Div className="form-group1">
                        {deleteSuccessMsg}
                    </props.Div>
                    <props.Div className="information gwp-information">
                        <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                        <GlobalizedText message="homepage.thereAre" />
                        <props.Span className="gwp-bold gwp-required"> {sizeOfTransList} </props.Span>
                        <GlobalizedText message="pendTrans.lbl.pendTransLower" />
                    </props.Div>
                    <props.Div >
                        <props.Button disabled={disablePendingBtnFlag} onClick={() => { continueTransaction() }}><GlobalizedText message="common.button.continue" /></props.Button>
                        <props.Button disabled={disablePendingBtnFlag} onClick={() => { deleteTransaction() }}><GlobalizedText message="common.lbl.delete" /></props.Button>
                    </props.Div>
                    <props.Table id="pendingTransTable" table={pendingTranstable} select={{ type: 'single', onSelect: (e: any, rows: number[]) => { saveSelectedValue(pendingTranstable.data[rows[0]]) } }} dateformat="MM/dd/yyyy"></props.Table>
                </props.PanelBody>
            </props.Panel>
            {/* Delete Pending Transcation Dialog */}
            <props.Modal title={() => {
                return <>
                    <props.Icon type="warning"></props.Icon>
                    <GlobalizedText message="common.lbl.deleteTransactions"></GlobalizedText>
                </>

            }} id="delPendingTrans" footer={() => {
                return <>
                    <props.Button data-toggle="modal" data-target="#delPendingTrans" onClick={() => { confirmDeletePendingTrans() }} ><GlobalizedText message="common.lbl.delete" /></props.Button>
                    <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                </>
            }} >
                <props.Row>
                    <props.Col xs="12" sm="12" md="12" className="marginbottom10px">
                        <GlobalizedText message="trans.lbl.all.trans.delete" />
                    </props.Col>
                </props.Row>
                <props.Row>
                    <props.Col xs="12" sm="12" md="12" >
                        <GlobalizedText message="pendTrans.lbl.confirmDel" />
                    </props.Col>
                </props.Row>
            </props.Modal>
        </props.Div>

    } else {
        return <></>
    }
});