import { ThemeConfig } from "../../../shared/theme";
import * as theme from './flexbenefits';
const ThemeComponents: ThemeConfig = {
    'flexbenefits': {
        component: theme,
        css: ['/static/customized/themes/flexbenefits/css/extension.css', '/static/customized/themes/flexbenefits/css/common.css', '/static/customized/themes/flexbenefits/css/flexbenefits.css'],
        layouts: {
            'default': theme.DefaultLayout,
            'empty': theme.EmptyLayout
        }
    }
};

export default ThemeComponents;
