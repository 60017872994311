import React, { useEffect, useState } from 'react';
import { ajax } from '../../../shared/utils';
import { Form, Formik, useFormikContext } from 'formik';
import { SelectControl } from './select';

interface AccountFilterForm {
    companyCode: string;
    groupNumber: string;
    accountNumber: number;
}
const ALL_ACCOUNT_NUMBER = 0
const ALL_COMPANY_NUMBER = '0';
const ALL_GROUP_NUMBER = '0';
function setDisplayedValue(options: Array<any>, value: string | number, callback: Function) {
    for (let option of options) {
        if (option.value === value) {
            callback(option.name);
        }
    }
}
export const AccountFilter = ({...props}) => {
    const [isAllowed, setAllowed] = useState(false);
    const [config, setConfig] = useState<any | null>(null);
    const [groups, setGroups] = useState<any | null>(null);
    const [accounts, setAccounts] = useState<any | null>(null);
    const [companyName, setCompanyName] = useState<any | null>('');
    const [groupName, setGroupName] = useState<any | null>('');
    const [accountName, setAccountName] = useState<any | null>('');
	const whiteListedUserRoleTypes = [10001,10002,10003]; //Group Admin, CSR, Portal Admin
    const formSubmit = (values: AccountFilterForm, {  }: any) => {
    }
    function clearSetting(formProps: any) {
        formProps.setFieldValue('settings', {});
	}
    const api = { clearSetting: clearSetting, loadData: loadData, loadDataForForm: loadDataForForm, setGroups: setGroups, setAccounts: setAccounts, setCompanyName: setCompanyName, setGroupName: setGroupName, setAccountName: setAccountName };
    /**
     * Load security setting data
     * @param level         system level or group level
     * @param userTypeCode      user type code
     * @param companyCode   
     * @param groupNumber 
     * @param accountNumber 
     * @param loadSetting    load security setting or not, if false then just load the empty security settings.
     */
    function loadData(companyCode?: string, groupNumber?: string, accountNumber?: number, loadSetting?: boolean) {
        let getSetting = loadSetting !== undefined ? loadSetting : true;
		props.onChange(companyCode, groupNumber, accountNumber);
    }

    /**
     * Load security setting data by form
     * @param form 
     * @param loadSetting 
     */
    function loadDataForForm(form: AccountFilterForm, loadSetting?: boolean) {
        loadData(form.companyCode, form.groupNumber, form.accountNumber, loadSetting);
    }
    useEffect(() => {
		ajax({
			url: '/api/account/selection/view',
			params: {},
			success: (res: any) => {
				setConfig(res);
				setAllowed(whiteListedUserRoleTypes.includes(res.userTypeCode));
				props.onChange(ALL_COMPANY_NUMBER, ALL_GROUP_NUMBER, ALL_ACCOUNT_NUMBER);
			}
		});
    }, []);
    if ( !config ) {
        return <></>;
    }
    let groupOptions = groups !== null ? groups : config.groups;
    let accountOptions = accounts !== null ? accounts : config.accounts;
	let initialValues: AccountFilterForm = {
		companyCode: '0',
		groupNumber: ALL_GROUP_NUMBER,
		accountNumber: ALL_ACCOUNT_NUMBER
	}
	return (<>
		{ isAllowed && 
			<Formik initialValues={initialValues} onSubmit={formSubmit}>
			{formProps =>
                <Form>
					 <div id="scTabContent" className="tab-content">
                     	<CompanySelect options={config.companies} {...api} />
						<GroupSelect options={groupOptions} {...api} />
						<AccountSelect options={accountOptions} {...api} />
					</div>
				</Form>}
			</Formik>
		} 
		</>);
};

function CompanySelect(props: any) {
    let options = props.options.map((option: any) => {
        return { value: option.companyCode.trim(), name: option.fullCompanyName };
    });
    const formProps = useFormikContext<any>();
    useEffect(() => {
        setDisplayedValue(options, formProps.values.companyCode, props.setCompanyName);
    });

    function selectCompany(e: any) {
        let value = e.target.value;
        setDisplayedValue(options, value, props.setCompanyName);
        ajax({
            url: '/api/account/selection/group/' + value,
            success: (res: any) => {
                props.setGroups(res);
                formProps.setFieldValue("groupNumber", res[0].groupNumber);
                ajax({
                    url: '/api/account/selection/' + formProps.values.companyCode + '/' + res[0].groupNumber,
                    success: (res1: any) => {
                        props.setAccounts(res1);
                        formProps.setFieldValue("accountNumber", res1[0].accountNumber);
                        props.loadDataForForm({ ...formProps.values, companyCode: value, groupNumber: res[0].groupNumber, accoutNumber: res1[0].accountNumber });
                    }
                });
            }
        });
    }

    return <SelectControl sort={false} onChange={selectCompany} id="companyCode" name="companyCode" label="memlist.company" options={options}></SelectControl>
}
function GroupSelect(props: any) {
    let options = props.options.map((option: any) => {
        return { value: option.groupNumber.trim(), name: option.fullGroupName };
    });
    const formProps = useFormikContext<any>();
    useEffect(() => {
        setDisplayedValue(options, formProps.values.groupNumber, props.setGroupName);
    });

    function selectGroup(e: any) {
        let value = e.target.value;
        setDisplayedValue(options, value, props.setGroupName);
        ajax({
            url: '/api/account/selection/' + formProps.values.companyCode + '/' + value,
            success: (res: any) => {
                props.setAccounts(res);
                formProps.setFieldValue("accountNumber", res[0].accountNumber);
                props.loadDataForForm({ ...formProps.values, groupNumber: value, accountNumber: res[0].accountNumber });
            }
        });
    }
    return <SelectControl sort={false} onChange={selectGroup} name="groupNumber" label="common.lbl.group" options={options}></SelectControl>
}
function AccountSelect(props: any) {
    let options = props.options.map((option: any) => {
        return { value: option.accountNumber, name: option.fullAccountName };
    });
    const formProps = useFormikContext<any>();
    useEffect(() => {
        setDisplayedValue(options, formProps.values.accountNumber, props.setAccountName);
    });

    function selectAccount(e: any) {
        let value = e.target.value;
        setDisplayedValue(options, parseInt(`${value}`), props.setAccountName);
        props.loadDataForForm({ ...formProps.values, accountNumber: parseInt(`${value}`) });
    }
    return <SelectControl sort={false} onChange={selectAccount} id="accountNumber" name="accountNumber" label="common.lbl.account" options={options}></SelectControl>
}