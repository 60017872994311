import React, { ReactNode } from 'react';
import GlobalizedText from './globalization';
export const RequiredTitle = (messageKey: string) => {
    return <>
    <GlobalizedText message={messageKey}/>
</>
}
export const WarningTitle = (messageKey: string) => {
        return <>
            <span className="gwp-icon gwp-icon-warning gwp-icon-sm " ></span>
            <GlobalizedText message={messageKey} />
        </>
}
export interface IndicatedReqTitleProps {
    message: string;
    props:any
}
export const IndicatedReqTitle = (indicatedProps:IndicatedReqTitleProps) => {
    return <>
    <indicatedProps.props.Row>
        <indicatedProps.props.Col xs="12" sm="12" md="12" style={{ marginTop: -10 }}>
        <indicatedProps.props.Label className="gwp-label-required" ><GlobalizedText message={indicatedProps.message} /></indicatedProps.props.Label>
        </indicatedProps.props.Col>   
    </indicatedProps.props.Row>  
    </>
}