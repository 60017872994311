import { Form, Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { ajax, trim, validate } from '../shared/utils';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import * as yup from 'yup';
import { useEffect } from 'react';
import GlobalizedText from '../shared/globalization';
import './css/agentSearch.css'
import { TableCell } from '../shared/components';
interface AgentSearchForm {
    lastName: string,
    firstName: string,
    agentNumber: string,
    orgName: string
}

const retrieve = (values: any, config: any, setTable: any, props: any): void => {
    ajax({
        url: '/api/agentSearch/searchAgent',
        params: {
            agentNumber: values.agentNumber ? values.agentNumber : '',
            firstName: values.firstName ? values.firstName : '',
            lastName: values.lastName ? values.lastName : '',
            orgName: values.orgName ? values.orgName : ''
        },
        success: (data: any) => {
            setTable(data);
        },
        fail: (res: any) => { },
        error: (res: any) => { }

    });

}

const resetForm = (filterBy: string, nameType: string, formProps: any, viewProps: any, setTable: any, setIsEmpty?: any) => {
    //setTable({ data: [], columns: HISTORY_COLUMNS });
    formProps.setFieldValue("orgName", '');
    formProps.setFieldValue("firstName", '');
    formProps.setFieldValue("lastName", '');
    formProps.setFieldValue("agentNumber", '');
    //formProps.setFieldValue("nameType", '1');
    viewProps.clearMessage();
    viewProps.setInitParams({ filterBy: filterBy, nameType: nameType });
}

const defaultSearchOption: Array<any> = [
    {
        name: "common.lbl.name",
        value: "name",
        selected: true
    },
    {
        name: "common.lbl.agentNumber",
        value: "agentNumber",
        selected: false
    }
]

export const AgentSearchComponent = withView((props: ViewComponentProps) => {
    const [config, setConfig] = useState<any | null>(null);
    const [table, setTable] = useState<any | null>(null);

    const defaultInitialValues = {
        filterBy: 'name',
        nameType: '1',
        lastName: '',
        firstName: '',
        agentNumber: '',
        orgName: ''
    }

    let byAgentNumbervalidate = yup.object().shape({
        agentNumber: yup.string().trim().required("regist.msg.err.agent.number.empty")
    });
    let byNameCompanyValidate = yup.object().shape({
        orgName: yup.string().trim().required("regist.msg.err.agency.name.empty")
    });

    const byNamePersonValidate = (values: any) => {
        if (trim(values.firstName) === '' && trim(values.lastName) === '') {
            return {
                "firstName": "common.msg.personNameReq",
                "lastName": "common.msg.personNameReq"
            }
        }
    }

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/agentSearch/view',
                success: (res: any) => {
                    setConfig(res);
                    if (props.getInitParams() !== undefined) {
                        props.clearMessage();
                        //alert("test");
                        retrieve(props.getInitParams(), config, setTable, props);
                    }
                }
            });
        }
        return () => {
            props.clearMessage();
        }
    }, [config])
    if (config === null) {
        return <></>;
    }
    // define Form Submitting function.
    const formSubmit = (values: any): void => {
        props.clearMessage();
        props.setInitParams(values);
        retrieve(values, config, setTable, props);
    }
    let initialValue: AgentSearchForm = props.getInitParams() !== undefined ? props.getInitParams() : defaultInitialValues;

    return <React.Fragment>
        <Formik initialValues={initialValue}
            {...props.generateNoramlFormikProps()}
            validate={values => {
                if (values.filterBy === "agentNumber") {
                    return validate(byAgentNumbervalidate, values, props);
                }
                if (values.filterBy === "name" && values.nameType === '1') {
                    return validate(byNamePersonValidate, values, props);
                }
                if (values.filterBy === "name" && values.nameType === '2') {
                    return validate(byNameCompanyValidate, values, props);
                }
            }}
            onSubmit={formSubmit}>
            {formProps => <Form>
                <props.Row>
                    <props.Col sm={5} md={5} xs={12}>
                        <props.SelectControl name="filterBy" onChange={(e: any) => {
                            setTable(null);
                            resetForm(e.target.value, formProps.values.nameType, formProps, props, setTable);
                            //formSubmit(formProps.values);
                        }} options={defaultSearchOption} label="Search By" />
                        <props.SelectControl condition={formProps.values.filterBy === "name"} onChange={(e: any) => {
                            setTable(null);
                            resetForm(formProps.values.filterBy, e.target.value, formProps, props, setTable);
                            //formSubmit(formProps.values);
                        }} options={config.nameTypes !== undefined ? config.nameTypes : [{ name: "Person", value: "1" }, { name: "Company", value: "2" }]} name="nameType" label="payment.lbl.nametype" />
                    </props.Col>
                    <props.Col sm={7} md={7} xs={12}>
                        <props.Condition condition={formProps.values.filterBy === "name" && formProps.values.nameType === "1"} ><props.Information message="common.msg.personNameReq" /></props.Condition>
                        <props.LastNameAutoCompleteControl roleTypeCodes={["AGT"]} condition={formProps.values.filterBy === "name" && formProps.values.nameType === "1"} htmlFor="lastName_input" id="lastName" name="lastName" />
                        <props.TextControl required={true} condition={formProps.values.filterBy === "name" && formProps.values.nameType === "2"} id="orgName" name="orgName" label="regist.label.agency.name" />
                        <props.TextControl required={true} condition={formProps.values.filterBy === "agentNumber"} id="agentNumber" name="agentNumber" label="common.lbl.agentNumber" />
                        <props.FirstNameAutoCompleteControl roleTypeCodes={["AGT"]} condition={formProps.values.filterBy === "name" && formProps.values.nameType === "1"} htmlFor="firstName_input" id="firstName" name="firstName" />
                        <props.Button className={(formProps.values.filterBy === "name" && formProps.values.nameType === "1") ? '' : "margintop20px"} type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                    </props.Col>
                </props.Row>
                <hr />
            </Form>
            }
        </Formik>
        <props.Table id="agentsearchtable" setInitParams={props.setInitParams} getInitParams={props.getInitParams} next={props.next} dateformat={config.userDateFormat} onResponsiveDisplay={(rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element) => {
            childNode?.querySelectorAll("a")?.forEach((a: HTMLElement) => {
                let href = a.getAttribute("data-href");
                a.addEventListener("click", (e: any) => { if (href !== null) { props.next(href); e.preventDefault(); } });
            });
        }}>
            <thead>
                <tr>
                    <th className="gwp-dt-detail-control"><div className='hidden'>detail control</div></th>
                    <th data-width="20%"><GlobalizedText message="common.lbl.name" /></th>
                    <th data-width="12%"><GlobalizedText message="common.lbl.agentNumber" /></th>
                    <th data-width="8%"><GlobalizedText message="common.lbl.hireDate" /></th>
                    <th data-width="20%"><GlobalizedText message="pd.lbl.address" /></th>
                    <th data-width="10%"><GlobalizedText message="pd.lbl.phone" /></th>
                    <th data-width="12%"><GlobalizedText message="memlist.company" /></th>
                    <th data-width="16%"><GlobalizedText message="common.lbl.actions" /></th>
                </tr>
            </thead>
            <tbody>
                {table?.map((row: any, index: number) => {
                    let IWouldLikeOptions: Array<any> = [];
                    if (config.accessCommissionReport) { IWouldLikeOptions.push({ message: 'common.lbl.commissionReports', to: `/dashboardList?agentNumber=${row.agentNumber}&companyCode=${row.companyCode}&fromPage=from_agentsearch` }) }
                    if (config.accessCommissionStatement) { IWouldLikeOptions.push({ message: 'common.lbl.commissionStatements', to: `/commissionStatement?agentNumber=${row.agentNumber}&companyCode=${row.companyCode}&fromPage=from_agentsearch` }) }
                    if (config.accessDirectDeposit) { IWouldLikeOptions.push({ message: 'diredep.titlename', to: `/directDeposit?agentNumber=${row.agentNumber}&companyCode=${row.companyCode}&fromPage=from_agentsearch` }) }
                    if (config.accessInforceBusiness) { IWouldLikeOptions.push({ message: 'ib.lbl.module', to: `/inforceBusiness?agentNumber=${row.agentNumber}&name=${row.fullName}&companyCode=${row.companyCode}&fromPage=from_agentsearch` }) }
                    if (config.accessLicensing) { IWouldLikeOptions.push({ message: 'licensing.titlename', to: `/licensing?agentNumber=${row.agentNumber}&companyCode=${row.companyCode}&fromPage=from_agentsearch` }) }
                    if (config.accessNewBusinessApp) { IWouldLikeOptions.push({ message: 'common.nbapplications', to: `/newBusinessApplications?agentNumber=${row.agentNumber}&companyCode=${row.companyCode}&fromPage=from_agentsearch` }) }
                    if (config.accessPersonalInfo) { IWouldLikeOptions.push({ message: 'common.lbl.personalInformation', to: `/agentPersonalInformation?agentNumber=${row.agentNumber}&companyCode=${row.companyCode}&personalInfoType=${3}&clientNumber=${row.clientNumber}&fromPage=from_agentsearch` }) }
                    if (config.accessProductionReport) { IWouldLikeOptions.push({ message: 'common.lbl.productionReports', to: `/productionReports?agentNumber=${row.agentNumber}&companyCode=${row.companyCode}&fromPage=from_agentsearch` }) }
                    return <tr key={index}>
                        <td className="gwp-dt-detail-control"></td>
                        <td>{row.fullName}</td>
                        <td>{row.agentNumber}</td>
                        <td>{row.hireDate}</td>
                        <td dangerouslySetInnerHTML={{ __html: row.address }}></td>
                        <td>{row.dialNumber}</td>
                        <td>{row.companyDesc}</td>
                        <td><props.WouldSelect next={props.next} options={IWouldLikeOptions} /></td>
                    </tr>
                })
                }
            </tbody>
        </props.Table>
    </React.Fragment >
});
