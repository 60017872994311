import React, { useEffect, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
//import './css/cognosreport.css';
import { ajax, isEmptyObject, parseBoolean } from '../shared/utils';
import { Form, Formik } from 'formik';
//import GlobalizedText from '../shared/globalization';

let w: any = window;
export const win = w;
export const $: any = w.$;



export const AwsReportsComponent = withView((props: ViewComponentProps) => {

    const [config, setConfig] = useState<any>();
    const companyCode: string = props.getParam("companyCode");
    const agentNumber: string = props.getParam("agentNumber");
    const policyNumber: string = props.getParam("policyNumber");
    const directReports: boolean = parseBoolean(props.getParam("directReports"));
    const reportKey: string = props.getParam("reportKey");
    const fromPage: string = props.getParam("fromPage");
    const reportCategory: string = props.getParam("reportCategory");
    const thisPage: string = "cognosReport";
    const fromAgentSearch: boolean = parseBoolean(props.getParam("reportCategory"));
    const [url, setUrl] = useState<string>("https://us-east-1.quicksight.aws.amazon.com/embed/44601140ab324aaaaec502b84c134b24/dashboards/aab9e658-2559-48e5-b52c-8d954a29c4bb?code=AYABeDbVCbDhH2Z0wOChYv6ekI0AAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4ZGLViwIvMWeq0eg2SdOyWJS86qkEBfgLEiY79AhlHtMBb3lg9uKwfa_YJe4PV6sX6AAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDFTfv0AxYYxVQ1IDsAIBEIA7M0C0FRSK5h33q_NaKPWywwpVB2M6xNWbDRnrzRWKYSisirR1UI7t0VyKkrd0CsejtV0THznqK-xoWOUCAAAAAAwAABAAAAAAAAAAAAAAAAAAtzHKo5Iy7shlYcm9_LG4L_____8AAAABAAAAAAAAAAAAAAABAAAA5cVDsbyaq4FMukabqjSI88vr5u5LBD0R-xmn_UDoKDzEM6meQb-wkRSuAC9YMyrf74fJYvMMJAuA2Boqf0ItFNcMRL3oSwiA3ebL2QwQFFpSXWzyzYtr6jOnJBi5Nf_kVCwnmyAQn7bPBFStolGYYJD-kqU9AxVuoRm24BETJsP-TS5A6L6G7Kw_XrUBfaqgOtEAPfZXOrHTrtPxJS5WDoBF-a963p-2XA8tXL9pB27E7ElY8l1HI8BEcU6Hm9MzhDlrrnBeMg21jBxGgW5Aan5g-oIzDQqnd2CsSSD1TIsYqINB1wAelot34G5DULOTRMyXjN0O&identityprovider=quicksight&isauthcode=true");
    //const [isDir, setIsDir] = useState<boolean>(false);
    //aws Poc
    //const isAwsPoc: boolean = parseBoolean(props.getParam("isAwsPoc"));
    //let awsReportUrl: string = "https://us-east-1.quicksight.aws.amazon.com/embed/44601140ab324aaaaec502b84c134b24/dashboards/aab9e658-2559-48e5-b52c-8d954a29c4bb?code=AYABeDbVCbDhH2Z0wOChYv6ekI0AAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4ZGLViwIvMWeq0eg2SdOyWJS86qkEBfgLEiY79AhlHtMBb3lg9uKwfa_YJe4PV6sX6AAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDFTfv0AxYYxVQ1IDsAIBEIA7M0C0FRSK5h33q_NaKPWywwpVB2M6xNWbDRnrzRWKYSisirR1UI7t0VyKkrd0CsejtV0THznqK-xoWOUCAAAAAAwAABAAAAAAAAAAAAAAAAAAtzHKo5Iy7shlYcm9_LG4L_____8AAAABAAAAAAAAAAAAAAABAAAA5cVDsbyaq4FMukabqjSI88vr5u5LBD0R-xmn_UDoKDzEM6meQb-wkRSuAC9YMyrf74fJYvMMJAuA2Boqf0ItFNcMRL3oSwiA3ebL2QwQFFpSXWzyzYtr6jOnJBi5Nf_kVCwnmyAQn7bPBFStolGYYJD-kqU9AxVuoRm24BETJsP-TS5A6L6G7Kw_XrUBfaqgOtEAPfZXOrHTrtPxJS5WDoBF-a963p-2XA8tXL9pB27E7ElY8l1HI8BEcU6Hm9MzhDlrrnBeMg21jBxGgW5Aan5g-oIzDQqnd2CsSSD1TIsYqINB1wAelot34G5DULOTRMyXjN0O&identityprovider=quicksight&isauthcode=true";
    /*const AWS = require('aws-sdk');
    const https = require('https');

    var quicksight = new AWS.Service({
        apiConfig: require('./quicksight-2018-04-01.min.json'),
        region: 'us-east-1',
    });



    useEffect(() => {
        if (config === undefined) {
            quicksight.getDashboardEmbedUrl({
                'AwsAccountId': '133404862018',
                'DashboardId': 'aab9e658-2559-48e5-b52c-8d954a29c4bb',
                //'AdditionalDashboardIds': 'added-dashboard-id-1 added-dashboard-id-2 added-dashboard-id-3',
                'Namespace': 'default',
                'IdentityType': 'ANONYMOUS',
                'SessionLifetimeInMinutes': 100,
                'UndoRedoDisabled': false,
                'ResetDisabled': true
            }, function (err: any, data: any) {
                console.log('Errors: ');
                console.log(err);
                console.log('Response: ');
                console.log(data);
                setConfig(data);
            });
        }
    }, [config]);
    if (config === undefined) {
        return <></>;
    }
*/
    return <>
        <Formik initialValues={{
            url: ''
        }}
            {...props.generateNoramlFormikProps()}
            onSubmit={(values: any) => {
                // console.log(values);
                setUrl(values.url);
            }}
        >
            {formProps =>
                <Form>
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">

                            <props.TextControl labelClass="gwp-sectitle" name="url" label="AWS QuickSight Dashboard URL:" />
                            <props.Button type="submit">Submit</props.Button>
                            <hr />

                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <div className="report" id="AwsRepDiv">
                                <iframe id="awsReportFrame" width="100%" scrolling="no" height={window.screen.availHeight} src={url}></iframe>
                            </div>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
    </>
});
