import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState, useContext } from 'react';
import * as yup from 'yup';
import GlobalizedText from '../shared/globalization';
import { useMessage } from '../shared/message';
import { ajax, resetForm, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/managefaqcategory.css';
import { FAQ, loadCategories } from './types/faq';
import { SecurityContext } from '../context/securityContext';
import { UserTypeCode } from '../shared/constants/userTypeCode';

/**
 * Component of Manage FAQ Category
 */
export const ManageFAQCategoryComponent = withView((props: ViewComponentProps) => {

    const [categories, setCategories] = useState<Array<FAQ.Category>>();
    const selected = useRef<FAQ.Category>();
    const [count, setCount] = useState<number>(0);   // state used when updating the categories
    const [openEdit, setOpenEdit] = useState<number>(0);
    const allowedUserTypes = [UserTypeCode.PORTAL_ADMIN, UserTypeCode.CONTENT_MANAGEMENT_ADMIN];
    const {userAuth} = useContext(SecurityContext);
    const isUserNotAuthorized = (!userAuth || (userAuth.userTypeRegCode && !allowedUserTypes.includes(userAuth.userTypeRegCode)));
    useEffect(() => {
        if (categories === undefined && !isUserNotAuthorized) {
            loadCategories(setCategories);
        }
    }, [categories])

    useEffect(() => {
        if (openEdit > 0 && !isUserNotAuthorized) {
            props.showModal("#editCategory");
        }
    }, [openEdit]);
    if(isUserNotAuthorized)
        return <></>

    function editCategory() {
        setOpenEdit(openEdit + 1);
    }
    function deleteCategory() {
        ajax({
            url: `/api/cms/faq/category/delete/${selected.current?.id}`,
            method: 'delete',
            success: () => {
                loadCategories(setCategories);
                closeDeleteCategory();
            }
        });
    }
    function closeDeleteCategory() {
        props.closeModal("#deleteCategory");
    }
    function confirmDeleteCategory() {
        props.showModal("#deleteCategory");
    }
    function newCategory() {
        props.showModal("#newCategory");
    }

    const newCategoryValidateSchema = yup.object().shape({
        newCategoryName: yup.string().trim().required("common.msg.mustEnterName")
    });

    const editCategoryValidateSchema = yup.object().shape({
        name: yup.string().trim().required("common.msg.mustEnterName")
    });

    const newCategoryMessage = useMessage("newCategory");
    const editCategoryMessage = useMessage("editCategory");

    return <>
        <props.Link to={"/createFaqCategory"} >
            <props.Button><GlobalizedText message="common.lbl.creatNewCategory" /></props.Button>
        </props.Link>
        <props.Link to={`/editFaqCategory/${selected?.current?.id}`} >
            <props.Button disabled={selected.current === undefined}><GlobalizedText message="common.lbl.edit" /></props.Button>
        </props.Link>
        <props.Button onClick={confirmDeleteCategory} disabled={selected.current === undefined}><GlobalizedText message="common.lbl.delete" /></props.Button>
        <props.Button onClick={() => { props.next("/manageFaqQuestion") }}><GlobalizedText message="common.lbl.manaQuestions" /></props.Button>
        <props.Button onClick={() => { props.back() }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
        <Formik initialValues={{
            category: '',
        }}
            onSubmit={() => { }}
        >
            <Form>
                <ul>
                    {(categories !== undefined && categories.length > 0) && categories.map((cat: FAQ.Category, index: number) => {
                        return <li key={index}> <props.RadioControl onChange={(v) => { selected.current = categories.filter((c) => { return c.id === v.target.value })[0]; setCount(count + 1) }} id={`cate_${cat.id}`} label={cat.name} name="category" value={cat.id}></props.RadioControl></li>
                    })}
                    {(categories === undefined || categories.length === 0) &&
                        <GlobalizedText message="common.msg.noFAQCategory" />
                    }
                </ul>
            </Form>
        </Formik>

        <Formik initialValues={{
            name: '',
        }}
            validate={(values) => {
                return validate(editCategoryValidateSchema, values, editCategoryMessage.validateProps);
            }}
            onSubmit={(values, formikHelper) => {
                ajax({
                    url: '/api/cms/faq/category/update',
                    method: 'put',
                    data: {
                        id: selected.current?.id,
                        name: values.name
                    }, success: (res) => {
                        if (selected.current !== undefined) {
                            selected.current.name = values.name
                        }
                        formikHelper.resetForm();
                        loadCategories(setCategories);
                        props.closeModal("#editCategory");
                    }
                });
            }}
            validateOnBlur={false}
            validateOnChange={false}
            onReset={editCategoryMessage.validateProps.clearMessage}
        >
            {formProps =>
                <Form>
                    <props.Modal messageService={editCategoryMessage.messageService} id="editCategory" title="common.lbl.editCategory" footer={
                        () => {
                            return <>
                                <props.Button type="submit"><GlobalizedText message="common.lbl.save" /></props.Button>
                                <props.Button onClick={() => resetForm(formProps)} data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                            </>
                        }

                    }>
                        <props.Row>
                            <props.Col sm="12" md="12">
                                <props.Label><GlobalizedText message="common.lbl.oldCateName" /></props.Label>
                                <props.Span className="oldCateName">{selected.current?.name}</props.Span>
                                <props.TextControl maxLength={500} name="name" id="editCategoryName" label='common.lbl.newCategoryName' required={true} />
                            </props.Col>
                        </props.Row>
                    </props.Modal>
                </Form>
            }
        </Formik>

        {
            // New Category Modal
        }
        <Formik initialValues={{
            newCategoryName: '',
        }}
            validate={(values) => {
                return validate(newCategoryValidateSchema, values, newCategoryMessage.validateProps);
            }}
            onSubmit={(values, formikHelper) => {
                ajax({
                    url: '/api/cms/faq/category/create',
                    method: 'post',
                    data: {
                        name: values.newCategoryName
                    }, success: (res) => {
                        formikHelper.resetForm();
                        loadCategories(setCategories);
                        props.closeModal("#newCategory");
                    }
                });
            }}
            validateOnBlur={false}
            validateOnChange={false}
            onReset={newCategoryMessage.validateProps.clearMessage}
        >
            {formProps =>
                <Form>
                    <props.Modal messageService={newCategoryMessage.messageService} id="newCategory" title="common.lbl.creatNewCategory" footer={
                        () => {
                            return <>
                                <props.Button type="submit"><GlobalizedText message="common.lbl.save" /></props.Button>
                                <props.Button onClick={() => resetForm(formProps)} data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                            </>
                        }
                    }>
                        <props.TextControl maxLength={500} name="newCategoryName" id="newCategoryName" label="common.lbl.categoryName" required={true} />
                    </props.Modal>
                </Form>
            }
        </Formik>


        <props.Modal id="deleteCategory" title="common.msg.deleteCategoryTitle" footer={
            () => {
                return <>
                    <props.Button onClick={deleteCategory}><GlobalizedText message="common.lbl.delete" /></props.Button>
                    <props.Button onClick={closeDeleteCategory}><GlobalizedText message="common.lbl.close" /></props.Button>
                </>
            }
        }>
            <GlobalizedText message="common.msg.deleteCategory" />
        </props.Modal>
    </>
})

