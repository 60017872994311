import React, { useEffect, useState } from 'react';
import { ajax, mergeObject } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
export interface HelpBannerObject {
  id: string;
  content: string;
  contentType: string;
}

export const HelpBannerEditorComponent = withView((props: ViewComponentProps) => {

  const [content, setContent] = useState<HelpBannerObject>();
  const [create, setCreate] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  useEffect(() => {
    if (content === undefined) {
      ajax({
        url: '/api/cms/helpbanner/view',
        success: (res: any) => {
          setContent(res);
          if (res.id === undefined) {
            setCreate(true);
            ajax({
              url: '/api/cms/content/init',
              success: (res1: any) => {
                setContent({ id: res1.id, contentType: res.contentType, content: '' });
              }
            });
          }
        }
      });
    }
  }, [content]);

  let onSave = (newContent: string) => {
    setContent(mergeObject(content, { content: newContent }));
    if (content !== undefined) {
      if (create) {
        ajax({
          url: '/api/cms/content/create',
          method: 'post',
          data: {
            id: content.id,
            name: 'Help Banner',
            description: 'Help Banner',
            type: content?.contentType,
            content: newContent
          }, success: res => {
            setEditing(false)
          }
        });
      }
      else {
        ajax({
          url: '/api/cms/content/update',
          method: 'put',
          data: {
            id: content.id,
            type: content.contentType,
            content: newContent
          }, success: res => {
            setEditing(false)
          }
        });
      }
    }
  }
  let onClose= () => {
    setEditing(false);
  }
  if (content === undefined || content.id === undefined) {
    return <></>
  }

  if (editing === true) {
    return <>
      <props.ContentEditor contentId={content?.id} contentTypeId={content?.contentType} {...props} onSave={onSave} onClose={onClose} html={content?.content}></props.ContentEditor>
    </>
  } else {
    return <>
      <props.ContentViewer html={content?.content}></props.ContentViewer>
      <props.Button onClick={() => { setEditing(true) }}>Edit</props.Button>
    </>
  }
});