import React from 'react';
import { UserContext } from '../../../../shared/authentication';
import { Link } from '../../../../shared/components';
import GlobalizedText from '../../../../shared/globalization';
import { $, win } from '../../../../shared/utils';
import logo from './images/cnx_logo.png';
import Menu from './menu';
function SSOButton(props: any) {
    if (props.user !== null) {
        return <></>;
    }
    return <a href={`http://${win.location.host}/saml2/init/simplesamllocal`} className="btn-utility btn-uti-l">SAML2</a>;
}
function SSOButton1(props: any) {
    if (props.user !== null) {
        return <></>;
    }
    return <a href={`http://${win.location.host}/openidconnect/init`} className="btn-utility btn-uti-l">OpenID</a>;
}
function LoginButton(props: any) {
    if (props.user !== null) {
        return <></>;
    }
    return <Link to="/login" className="btn-utility btn-uti-l"><GlobalizedText message="common.lbl.login" /></Link>;
}
function LogoutButton(props: any) {
    if (props.user === null) {
        return <></>;
    }
    return <Link to="/login" onClick={() => { props.userService.signout() }} className="btn-utility btn-uti-l"><GlobalizedText message="common.lbl.logout" /></Link>;
}
function RegistrationButton(props: any) {
    if (props.user !== null) {
        return <></>;
    }
    return <Link to={"/registration"} forceRefresh={true} className="btn-utility btn-uti-l"><span><GlobalizedText message="login.lbl.signup" /></span></Link>;
}

function Name(props: any) {
    if (props.user === null) {
        return <></>;
    }
    return <Link to="/myprofile" className="u-split">{props.user.firstName} {props.user.lastName}</Link>
}

function Inquiry(props: any) {
    if (props.user === null) {
        return <a id="theme_submit_inquiry_header" data-toggle="modal" data-target="#theme_inquiry_popup" title="" href='#top'><GlobalizedText message="common.lbl.submitInq" /></a>
    }
    return props.user.needSubmitInq && <a id="theme_submit_inquiry_header" data-toggle="modal" data-target="#theme_inquiry_popup" title="" href='#top'><GlobalizedText message="common.lbl.submitInq" /></a>
}

function Search(props: any) {
    return <></>
    /*<form className="gwp-header-collaps-search" action=".">
    <input value="searchCenter:query" name="uri" type="hidden" />
    <input type="hidden" name="contentNode" value="com.concentrix.webportal.search" />
    <input type="hidden" name="layoutNode" value="com.concentrix.webportal.Search Portlet Window" />
    <div className="input-group">
        <input type="text" className="form-control form-search" name="query" placeholder="Search..." />
        <span className="input-group-btn">
            <button className="btn btn-default form-search" type="button">
                <span className="glyphicon glyphicon-search" aria-hidden="true"></span>
            </button>
        </span>
    </div>
</form>*/
}

export function Header(props: any) {
    return (
        <header className="gwp-header">
            <div className="gwp-header-logobar">
                <div className="container ">
                    <div className="navbar-uptop">
                        <div className="nav-container">
                            <div className="navbar-logo">
                                <a rel="noopener noreferrer" ><img src={logo} /></a>
                            </div>
                            <div className="navbar-utility">
                                <UserContext.Consumer>
                                    {user => (
                                        <>
                                        <Inquiry user={user} />
                                        <Name user={user} />
                                        </>
                                    )}
                                </UserContext.Consumer>
                                <a className="u-split u-social hidden-xs hidden-sm" href="http://www.facebook.com"><span className="u-facebook"></span><div className="hidden">facebook</div></a>
                                <a className="u-split u-social hidden-xs hidden-sm" href="http://www.twitter.com"><span className="u-twitter"></span><div className="hidden">twitter</div></a>
                                <UserContext.Consumer>
                                    {user => (
                                        <React.Fragment>
                                            {
                                                <> <SSOButton user={user} />
                                                    <SSOButton1 user={user} />
                                                </>
                                            }
                                            <LoginButton user={user} userService={props.userService} />
                                            <LogoutButton user={user} userService={props.userService} />
                                            <RegistrationButton user={user} />
                                        </React.Fragment>
                                    )}
                                </UserContext.Consumer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container gwp-header-menubar">
                <div className="gwp-header-menubar-header hidden-sm hidden-md hidden-lg">
                    <div className="row">
                        <div className="col-xs-9">
                            <Search />
                        </div>


                        <div className="col-xs-3">
                            <button type="button" className="navbar-toggle collapsed" onClick={() => { $("#navbar").collapse('toggle') }} aria-expanded="false" aria-controls="navbar">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                    </div>
                </div>

                <div id="navbar" className="collapse navbar-collapse gwp-header-menubar-body">
                    <div className="row">
                        <Menu breadcrumb={props.breadcrumb} />
                        <div className="col-md-4 col-sm-5 hidden-xs">
                            <Search />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}