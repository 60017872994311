import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Condition, ProgressBarPagesConfig } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, copyObjectExcept, isEmptyStr, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { INVALID_MESSAGES, YupSchema } from '../shared/yupschema';
import './css/forgotpassword.css';

interface ForgotPasswordForm {
    userID: string,
    questionID: string,
    answer: string,
    password: string,
    confirmPassword: string
}

const defaultInitialValues: ForgotPasswordForm = {
    userID: '',
    questionID: '',
    answer: '',
    password: '',
    confirmPassword: ''
};

const cfgProcessBar1: ProgressBarPagesConfig = {
    pages: [
        { name: 'forgpass.label.personal.and.security.information', status: "current" },
        { name: 'forgpass.label.password.reset', status: "unset" }
    ]
};
const cfgProcessBar2: ProgressBarPagesConfig = {
    pages: [
        { name: 'forgpass.label.personal.and.security.information', status: "unset" },
        { name: 'forgpass.label.password.reset', status: "current" }
    ]
};

export const ForgotPasswordComponent = withView((props: ViewComponentProps) => {
    const userID = props.getParam("userID");
    const loginError = props.getParam("loginError");
    const [config, setConfig] = useState<any | null>(null);
    const [step, setStep] = useState<string>("firstStep");
    const [question, setQuestion] = useState<any>("");
    const [questionID, setQuestionID] = useState<any>("");
    const [securityQuestions, setSecurityQuestions] = useState<Array<any>>([]);
    const [enableVerifyPwd, setEnableVerifyPwd] = useState<boolean>(true);
    const [pwdMaxLength, setPwdMaxLength] = useState<any>();
    const [pwdMinLength, setPwdMinLength] = useState<any>();
    const [validationRule, setValidationRule] = useState<any>();
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [password, setPassword] = useState<any>();
    const forgotPasswordForm = useRef<any>();

    const firstStepValidate = (values: ForgotPasswordForm, formikHelpers: any): any => {
        let result: any = {};
        if (isEmptyStr(values.userID)) {
            result.userID = INVALID_MESSAGES.FORGOT_PASSWORD_USERID_EMPTY;
        }
        if (isEmptyStr(values.answer)) {
            result.answer = INVALID_MESSAGES.FORGOT_PASSWORD_ANSWER_EMPTY;
        }
        return result;
    }

    let secondStepValidate = yup.object().shape({
        password: YupSchema.forgotPassword.password(enableVerifyPwd, pwdMaxLength, pwdMinLength, validationRule, props.getGlobalizedText),
        confirmPassword: YupSchema.forgotPassword.confirmPassword(password)
    });

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/forgotPassword/view',
                success: (res: any) => {
                    setConfig(res);
                    setEnableVerifyPwd(res.enableVerifyPwd);
                    setSecurityQuestions(res.securityQuestions);
                    setPwdMaxLength(res.pwdMaxLength);
                    setPwdMinLength(res.pwdMinLength);
                    setValidationRule(res.validationRule);
                    if (!isEmptyStr(userID)) {
                        checkUserID(userID, forgotPasswordForm.current, res.securityQuestions);
                    }
                }
            });
        }
    }, [config])

    if (config === null) {
        return <></>;
    }
    function checkUserID(value: any, fp: any, questions: any) {
        setQuestion(null);
        setQuestionID(null);
        fp.resetForm();
        fp.setFieldValue("userID", value);
        if (isEmptyStr(value)) {
            let result: any = {};
            result.userID = INVALID_MESSAGES.FORGOT_PASSWORD_USERID_EMPTY;
            props.showFieldError(INVALID_MESSAGES.HOMEPAGE_ERROR_FIELDS, result, fp);
            return;
        }

        ajax({
            url: '/api/forgotPassword/validateUser',
            params: {
                userID: value
            },
            success: (res) => {
                questions.forEach((sq: any) => {
                    if (sq.questionID == res) {
                        setQuestion(props.getGlobalizedText(sq.question));
                        setQuestionID(res);
                    }
                });
            },
            error: (res) => {
                props.showMessage("error", INVALID_MESSAGES.FORGOT_PASSWORD_SERVER_ERROR);
            },
            fail: (data, message) => {
                formatError(message, fp);
            }
        });
    }


    function formatError(msg: any, pro: any) {
        let result: any = {};
        if (msg == undefined) {
            return;
        }
        if (msg == "AnswerIncorrect") {
            result.answer = INVALID_MESSAGES.FORGOT_PASSWORD_ANSWER_INCORRECT;
            props.showFieldError(INVALID_MESSAGES.HOMEPAGE_ERROR_FIELDS, result, pro);
        }
        if (msg == "UserNotFound") {
            result.userID = INVALID_MESSAGES.FORGOT_PASSWORD_USERID_NOTFOUND;
            props.showFieldError(INVALID_MESSAGES.HOMEPAGE_ERROR_FIELDS, result, pro);
        }
        if (msg == "ServerError") {
            props.showMessage("error", INVALID_MESSAGES.FORGOT_PASSWORD_SERVER_ERROR);
        }
        if (msg == "UserForbidden") {
            props.showMessage("error", INVALID_MESSAGES.FORGOT_PASSWORD_FORBIDDEN);
            setHideForm(true);
        }
        if (msg == "QuestionNotSetup") {
            props.showMessage("error", INVALID_MESSAGES.FORGOT_PASSWORD_QUESTION_NOT_SETUP);
            setHideForm(true);
        }
        if (msg.indexOf("passwordInHistory") >= 0) {
            var errorMessage = props.getGlobalizedText(INVALID_MESSAGES.FORGOT_PASSWORD_IN_HISTORY)
                + ' ' + msg.substr(msg.indexOf("/") + 1) + ' '
                + props.getGlobalizedText(INVALID_MESSAGES.FORGOT_PASSWORD_IN_HISTORY_SUFFIX);

            result.password = errorMessage;
            result.confirmPassword = errorMessage;
            props.showFieldError(INVALID_MESSAGES.HOMEPAGE_ERROR_FIELDS, result, pro);
        }
    }

    let initialValue: ForgotPasswordForm = copyObjectExcept(props.getInitParams() !== undefined ? props.getInitParams() : { ...defaultInitialValues, ...{ userID: userID }, ...props.getInitParams() }, true);

    return (
        <React.Fragment>
            <Formik initialValues={initialValue}
                validate={values => {
                    if (step == "firstStep") {
                        return validate(firstStepValidate, values, props);

                    } else if (step == "secondStep") {
                        return validate(secondStepValidate, values, props);
                    }
                }}
                onSubmit={(values: any, formikHelpers: any) => {
                    if (step == "firstStep") {
                        if (questionID != "") {
                            ajax({
                                url: '/api/forgotPassword/securityCheck',
                                method: 'post',
                                data: {
                                    userID: values.userID,
                                    questionID: questionID,
                                    answer: values.answer
                                },
                                success: (res: any) => {
                                    setStep("secondStep");
                                },
                                error: (res) => {
                                    props.showMessage("error", INVALID_MESSAGES.FORGOT_PASSWORD_SERVER_ERROR);
                                },
                                fail: (data, message) => {
                                    formatError(message, formikHelpers);
                                }
                            });
                        }
                    } else {
                        ajax({
                            url: "/api/forgotPassword/resetPassword",
                            method: 'post',
                            data: {
                                userID: values.userID,
                                password: values.password,
                                confirmPassword: values.confirmPassword
                            },
                            success: (res: any) => {
                                props.showMessage("success", INVALID_MESSAGES.FORGOT_PASSWORD_RESET_SUCCESS);
                                setHideForm(true);
                            },
                            error: (res) => {
                                props.showMessage("error", INVALID_MESSAGES.FORGOT_PASSWORD_SERVER_ERROR);
                            },
                            fail: (data, message) => {
                                formatError(message, formikHelpers);
                            }
                        });
                    }
                }}
                {...props.generateNoramlFormikProps()}
            >
                {formProps => {
                    forgotPasswordForm.current = formProps
                    return <Form>
                        <props.Row>
                            <Condition condition={step == "firstStep"}>
                                <props.Col md="12" sm="12" xs="12">
                                    <props.Condition condition={!isEmptyStr(loginError)}>
                                        <props.Information className="alert gwp-page-message alert-danger forgot-password-alert-danger" message={loginError}></props.Information>
                                    </props.Condition>
                                    <props.ProgressBar id="registrationProgressBar1" config={cfgProcessBar1}></props.ProgressBar>
                                    <props.Condition condition={!hideForm}>
                                        <props.TextControl id="userID" name="userID" label="forgpass.label.user.id" onBlur={(e: any) => {
                                            checkUserID(e.target.value, formProps, securityQuestions);
                                        }}></props.TextControl>
                                        <props.Label><GlobalizedText message="forgpass.label.question" /></props.Label>
                                        <props.Div id="question">{question}</props.Div>
                                        <props.TextControl name="answer" id="answer" disabled={isEmptyStr(question)} label="forgpass.label.answer"></props.TextControl>
                                        <props.Button type="submit" disabled={isEmptyStr(question)}><GlobalizedText message="common.button.next" /></props.Button>
                                        <props.Button onClick={() => { props.next("/login") }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                                    </props.Condition>
                                </props.Col>
                            </Condition>

                            <Condition condition={step == "secondStep"}>
                                <props.Col md="12" sm="12" xs="12">
                                    <props.ProgressBar id="registrationProgressBar2" config={cfgProcessBar2}></props.ProgressBar>
                                    <props.Condition condition={!hideForm}>
                                        <props.PasswordControl name="password" id="password" label="forgpass.label.new.password" onChange={(e: any) => {
                                            setPassword(e.target.value);
                                        }}></props.PasswordControl>
                                        <props.PasswordControl name="confirmPassword" id="confirmPassword" label="forgpass.label.confirm.password"></props.PasswordControl>

                                        <props.Button type="submit" ><GlobalizedText message="common.button.submit" /></props.Button>
                                        <props.Button onClick={() => { props.next("/login") }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                                    </props.Condition>
                                </props.Col>
                            </Condition>
                        </props.Row>
                    </Form>
                }}
            </Formik>
        </React.Fragment>
    )
})