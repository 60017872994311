import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useEffect } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, formatNumber, isEmptyStr, isMobileDevice, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import * as yup from 'yup';
import './css/mockcashier.css';
import { YupSchema } from '../shared/yupschema';
export const MockCashierComponent = withView((props: ViewComponentProps) => {
    const companyCode = props.getParam("companyCode");
    const policyNumber = props.getParam("policyNumber");
    const paymentSelection = props.getParam("paymentSelection");
    const accountHolder = props.getParam("accountHolderName");
    const amount = props.getParam("amount");
    const transactionID = props.getParam("transactionID");
    const [ccTypes, setCcTypes] = useState<any>(null);
    const defaultInitialValues = {
        creditCardType: 'DISC',
        paymentMethod: 'cc',
        creditCardNumber: '',
        cvv2: '',
        expired: '',
        bankName: '',
        accountNumber: '',
        routingNumber: ''
    };

    useEffect(() => {
        if (ccTypes === null && companyCode) {
            setCcTypes([]);
            ajax({
                url: '/api/payment/getCcTypes',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber
                },
                success: (res: any) => {
                    setCcTypes(res.ccTypes);
                }
            });
        }
    }, [ccTypes]);

    const ccSchema = yup.object().shape({
        creditCardNumber: YupSchema.mocksavecard.ccNumber,
        cvv2: YupSchema.mocksavecard.cvv2,
        expired: YupSchema.mocksavecard.expirationDate,
        creditCardType: YupSchema.mocksavecard.creditCardType
    });

    const baSchema = yup.object().shape({
        bankName: YupSchema.changePaymentMethod.bankName,
        accountNumber: YupSchema.changePaymentMethod.accountNumber,
        routingNumber: YupSchema.changePaymentMethod.routingNumber
    });

    return <div id="mockcashier">
        <React.Fragment>
            <Formik initialValues={defaultInitialValues}
                validate={(values) => {
                    return validate(values.paymentMethod === 'cc' ? ccSchema : baSchema, values, props);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => {
                    if (values.paymentMethod === 'cc') {
                        props.next(`/makePaymentSuccess?paymentSelection=${paymentSelection}&transactionID=${new Date().getTime()}&amount=${amount}&creditCardNumber=${values.creditCardNumber.substring(values.creditCardNumber.length - 4)}&companyCode=${companyCode}&policyNumber=${policyNumber}&refID=${transactionID}`)
                    }
                    if (values.paymentMethod === 'ba') {
                        props.next(`/makePaymentSuccess?paymentSelection=${paymentSelection}&transactionID=${new Date().getTime()}&amount=${amount}&companyCode=${companyCode}&policyNumber=${policyNumber}&refID=${transactionID}`)
                    }
                }}
            >
                {formProps => {
                    return <Form>
                        <props.Panel >
                            <props.PanelHeading>
                                <GlobalizedText message="Mock Cashier" />
                            </props.PanelHeading>
                            <props.PanelBody>
                                {!isMobileDevice() && <>
                                    <props.Row>
                                        <props.Col xs="3" md="3">
                                            <props.DL>
                                                <props.DLI title="Amount" desc={formatNumber(parseFloat(amount))}></props.DLI>
                                            </props.DL>
                                        </props.Col>
                                        <props.Col xs="3" md="3">
                                            <props.DL>
                                                <props.DLI title="Account Holder Name" desc={accountHolder.replaceAll("+", " ")}></props.DLI>
                                            </props.DL>
                                        </props.Col>
                                        <props.Col xs="3" md="3">
                                            <props.DL>
                                                <props.DLI title="Company Code" desc={companyCode}></props.DLI>
                                            </props.DL>
                                        </props.Col>
                                        <props.Col xs="3" md="3">
                                            <props.DL>
                                                <props.DLI title="Policy Number" desc={policyNumber}></props.DLI>
                                            </props.DL>
                                        </props.Col>
                                    </props.Row>
                                    <props.Row>
                                        <props.Col xs="12" md="12">
                                            <props.RadioControl onChange={() => { props.resetForm(formProps, { paymentMethod: 'cc' }); props.clearMessage(); }} containerClassName="gwp-inline" name="paymentMethod" id="cc" label="Credit Card" value="cc"></props.RadioControl>
                                            <props.RadioControl onChange={() => { props.resetForm(formProps, { paymentMethod: 'ba' }); props.clearMessage(); }} containerClassName="gwp-inline" name="paymentMethod" id="ba" label="Bank Account" value="ba"></props.RadioControl>
                                        </props.Col>
                                    </props.Row>
                                    <props.Row>
                                        <props.Col xs="6" md="6">
                                            <props.DL>
                                                <props.SelectControl disabled={formProps.values.paymentMethod !== 'cc'} name="creditCardType" tabIndex={4} required={true} label="common.lbl.cred.card.type" options={ccTypes}>
                                                </props.SelectControl>
                                                <props.TextControl disabled={formProps.values.paymentMethod !== 'cc'} name="creditCardNumber" placeholder="41111111111111111" tabIndex={1} required={true} label="Credit Card Number" ></props.TextControl>
                                                <props.TextControl disabled={formProps.values.paymentMethod !== 'cc'} name="cvv2" placeholder="123" tabIndex={1} required={true} label="CVV2" ></props.TextControl>
                                                <props.TextControl disabled={formProps.values.paymentMethod !== 'cc'} name="expired" placeholder="01/2021" tabIndex={1} required={true} label="Expiration Date (mm/yyyy)" ></props.TextControl>
                                            </props.DL>
                                        </props.Col>

                                        <props.Col xs="6" md="6">
                                            <props.TextControl disabled={formProps.values.paymentMethod !== 'ba'} name="bankName" required={true} label="Bank Name" />
                                            <props.TextControl disabled={formProps.values.paymentMethod !== 'ba'} name="accountNumber" required={true} label="Account Number" />
                                            <props.TextControl disabled={formProps.values.paymentMethod !== 'ba'} name="routingNumber" required={true} label="Routing Number" />
                                        </props.Col>
                                    </props.Row>
                                </>}
                                {isMobileDevice() && <>
                                    <props.Row>
                                        <props.Col xs="12">
                                            <props.DL>
                                                <props.TextControl name="firstName" tabIndex={1} required={true} label="common.lbl.firstName" ></props.TextControl>
                                                <props.TextControl name="middleName" tabIndex={2} label="payment.mock.middle" ></props.TextControl>
                                                <props.TextControl name="lastName" tabIndex={3} required={true} label="common.lbl.lastName" ></props.TextControl>
                                                <props.TextControl name="ccNumber" tabIndex={5} required={true} label="payment.mock.ccnumber" ></props.TextControl>
                                                <props.TextControl name="expirationDate" tabIndex={6} required={true} label="payment.mock.label.expdate"></props.TextControl>
                                                <props.TextControl name="clientProfileID" tabIndex={7} required={true} label="payment.lbl.clientProfileId" ></props.TextControl>
                                                <props.TextControl name="creditCardToken" tabIndex={8} required={true} label="payment.lbl.creditCardToken" ></props.TextControl>
                                            </props.DL>
                                        </props.Col>
                                    </props.Row>
                                </>}
                                <props.Row>
                                    <props.Col xs="12">
                                        <props.Button type="submit" ><GlobalizedText message="payment.mock.succ" /></props.Button>
                                        <props.Button onClick={() => {
                                            props.next(`/makepaymentfailure?paymentSelection=${paymentSelection}&transactionID=${transactionID}&companyCode=${companyCode}&amount=${amount}&policyNumber=${policyNumber}&refID=${new Date().getTime()}`)
                                            //window.location.href = process.env.PUBLIC_URL + "/creditcardfail";
                                        }} ><GlobalizedText message="common.lbl.failed" /></props.Button>
                                        <props.Button onClick={() => {
                                            props.next(`/makepaymentcancel?paymentSelection=${paymentSelection}&transactionID=${transactionID}&companyCode=${companyCode}&amount=${amount}&policyNumber=${policyNumber}&refID=${new Date().getTime()}`)
                                            //window.location.href = process.env.PUBLIC_URL + "/creditcardcancel";
                                        }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
                                    </props.Col>
                                </props.Row>
                            </props.PanelBody>
                        </props.Panel >

                    </Form>
                }
                }
            </Formik>
        </React.Fragment>
    </div >
});