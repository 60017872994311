import * as yup from 'yup';

export const INVALID_MESSAGES_ITG = {
    MEMBER_ID_IS_EMPTY: "addmember.memberid.required",
  }
export const YupSchemaITG = {                                      
    submitInquiry: {
        groupNumber: yup.string().trim().required("regist.msg.err.group.number.required")
    },
    email1: (getGlobalizedText: (messageKey: string) => void) => {
        let requiredMsg = getGlobalizedText("Email") + " " + getGlobalizedText("message_information_required");
    },

}
