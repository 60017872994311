import React, { useEffect, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import { ajax, isEmptyStr } from '../shared/utils';
import './css/benefitclaimdetail.css';

export const BenefitClaimDetailComponent = withView((props: ViewComponentProps) => {
    const [config, setConfig] = useState<any>();
    const companyCode = props.getParam("companyCode");
    const policyNumber = props.getParam("policyNumber");
    const planCode = props.getParam("planCode");
    const baseRiderCode = props.getParam("baseRiderCode");
    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/benefitClaimDetail/view',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber,
                    planCode: planCode,
                    baseRiderCode: baseRiderCode
                },
                success: (res: any) => {
                    setConfig(res);
                }
            });
        }
    }, [config]);
    if (config === undefined) {
        return <></>
    }
    return <>
        <props.Row>
            <props.Col sm="8" md="8" xs="12">
                <props.PageHeader className="gwp-billingsummary-label" label={() => {
                    return <><GlobalizedText message="common.lbl.claimNumber" /> {config["claim"].claimNumber}
                    </>
                }} />
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col sm="12" md="12" xs="12">
                <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                    <props.DLI title="common.lbl.insured" desc={() => {
                        return <props.Link to={`/personalInformation?isUpdateMode=false&personalInfoType=2&companyCode=${companyCode}&policyNumber=${policyNumber}&baseRiderCode=${baseRiderCode}&roleTypeCode=${config.insuredRoleType}`}>{config.insuredName}</props.Link>
                    }} >
                    </props.DLI>
                    <props.DLI title="common.lbl.dob" desc={config["claim"].birthDate}></props.DLI>
                    <props.DLI title="pi.death.hdate" condition={config["claim"].claimType == 'D' && !isEmptyStr(config["claim"].deathDate)} desc={config["claim"].deathDate}></props.DLI>
                </props.DL>
            </props.Col>
        </props.Row>
        <props.Panel className="claimPanelStyle">
            <props.PanelBody>
                <props.Row>
                    <props.Col sm="6" md="6" xs="12">
                        <props.DL >
                            <props.DLI title="pd.lbl.productname" desc={config.productName}></props.DLI>
                            <props.DLI title="common.lbl.claimType" desc={config["claim"].claimTypeDesc}></props.DLI>
                            <props.DLI title="common.lbl.claimStatus" desc={config["claim"].claimStatusDesc}></props.DLI>
                        </props.DL>
                    </props.Col>
                    <props.Col sm="6" md="6" xs="12">
                        <props.DL >
                            <props.DLI title="common.lbl.notificationDate" desc={config["claim"].notifyDate}></props.DLI>
                            <props.DLI title="common.lbl.dateDeathCertificateReceived" condition={config["claim"].claimType == 'D' && !isEmptyStr(config["claim"].deathCertRcvdDate)} desc={config["claim"].deathCertRcvdDate}></props.DLI>
                            <props.DLI title="common.lbl.dateClaimApproved" condition={!isEmptyStr(config["claim"].claimApprDate)} desc={config["claim"].claimApprDate}></props.DLI>
                        </props.DL>
                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel>
    </>

});