import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { DEFAULT_FORMAT_TYPE, TableCell, useTable } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, isEmptyStr, removeLeftZeroForNumber, showTooltip, trim, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import './css/newbusinessapplication.css';
import { FeatureFlags } from '../shared/featureFlags';
export const NAME_TYPE_PERSON = "1";
export const NAME_TYPE_COMPANY = "2";

type Level = 'myApp' | 'drApp'
interface AgentParamsType {
    agentNumber: string,
    companyCode: string
}

interface AgentInfoType {
    name: string,
    agentNumber: string
}

interface SelectOptionType {
    name: string,
    value: string
}

interface NewBusinessAppForm {
    fromTab: string,
    nameType: string,
    filterBy: string,
    groupNumber: string,
    accountNumber: string,
    participantIDNumber: string
    lastName: string,
    firstName: string,
    policyNumber: string,
    activeStatus: string,
    statusType: string,
    inActiveStatus: string,
    agentNumber: string,
    receivedDate: string,
    applicationNumber: string,
    govtID: string,
    dialogName: string,
    selectDPagent: string,
    selectedDrAgents: string,
    companyName: string,
    selectedMyAgents: string
}

interface RequestDataType {
    fromWhichTab: string,
    nameType: string,
    agentNumber: string,
    companyCode: string,
    drAgents: Array<AgentParamsType>,
    myAppAgents: Array<AgentParamsType>,
    applicationNumber: string,
    govtID: string,
    groupNumber: string,
    accountNumber: number,
    participantIDNumber: string,
    policyNumber: string,
    status: string,
    receivedDate: string,
    fromAgentSearch: boolean,
    firstName: string,
    lastName: string,
    companyName: string
}

interface MultipleAgentsInfo {
    agentNumber: string,
    city: string,
    companyCode: string,
    name: string,
    stateCountry: string,
    streetAddress: string,
    checked: boolean
}

interface RequestBody4FetchAccount {
    fromWhichTab: string,
    agentNumber: string,
    companyCode: string,
    groupNumber: string,
    agents: Array<AgentParamsType>,
    fromAgentSearch: boolean
}


export const NewBusinessApplicationComponent = withView((props: ViewComponentProps) => {

    let companyCodePre = props.getQueryParam("companyCode")
    let agentNumberPre = props.getQueryParam("agentNumber")
    let isFromAgentSearch: boolean = props.getQueryParam("fromPage") === "from_agentsearch" ? true : false

    const [config, setConfig] = useState<any | null>(null);
    const myAppForm = useRef<any>();
    const myAppAgentFilterBy = useRef<Array<SelectOptionType>>([]);
    const drAppAgentFilterBy = useRef<Array<SelectOptionType>>([]);

    const myAppAgentsList = useRef<Array<any>>([]);
    const drAgentsList = useRef<Array<any>>([]);
    const [drAgentListTable, setDrAgentListTable] = useState<Array<any>>([])
    const [myAgentListTable, setMyAgentListTable] = useState<Array<any>>([])

    const [accounts, setAccounts] = useState<Array<any>>([])
    const [groupCompanyCode, setGroupCompanyCode] = useState<Array<any>>([])
    const [myAppGroupList, setMyAppGroupList] = useState<Array<SelectOptionType>>([{ name: "common.lbl.pleaseselect", value: "0" }]);
    const [drAppGroupList, setDrAppGroupList] = useState<Array<SelectOptionType>>([{ name: "common.lbl.pleaseselect", value: "0" }]);
    const [myAccountList, setMyAccountList] = useState<Array<SelectOptionType>>([{ name: "common.lbl.pleaseselect", value: "" }]);
    const [drAccountList, setDrAccountList] = useState<Array<SelectOptionType>>([{ name: "common.lbl.pleaseselect", value: "" }]);
    const statusTypeOptionList = useRef<Array<SelectOptionType>>([])
    const activeOptionList = useRef<Array<SelectOptionType>>([])
    const inactiveOptionList = useRef<Array<SelectOptionType>>([])

    const [nbTable, setNBTable] = useState<any | null>(null);
    const nbTab = useRef<Level>("myApp");

    const hiddenMemberID = useRef<boolean>(true)
    const hiddenMyCancelLink = useRef<boolean>(true)
    const hiddenDrCancelLink = useRef<boolean>(true)
    const hiddenSelectMultipleAgtsLink4MyApp = useRef<boolean>(true)

    const [disableSaveBtn, setdisableSaveBtn] = useState<boolean>(true);
    const [showSelectDPagent, setShowSelectDPagent] = useState<boolean>(true);
    const [showSelectMyAgent, setShowSelectMyAgent] = useState<boolean>(true);

    const [selectedDrAgentsList, setSelectedDrAgentsList] = useState<any | null>(null);
    const [selectedMyAgentsList, setSelectedMyAgentsList] = useState<any | null>(null);
    const selectedAgentCount = useRef<number>(0)

    const storedDrSelectedAgents = useRef<Array<MultipleAgentsInfo>>([]);
    const storedMySelectedAgents = useRef<Array<MultipleAgentsInfo>>([]);
    const storedSelectedRowNumbers = useRef<Array<number>>([]);

    const selectedAgentOption = useRef<Array<any>>([]);
    const selectedAgentOptionTmp = useRef<Array<any>>([]);
    const unSelectedAgentOptionTmp = useRef<Array<any>>([]);
    const [multiBtn, setMultiBtn] = useState<boolean>(true);
    const dataObj = useRef<Array<MultipleAgentsInfo>>([])

    const [currentTablePage, setCurrentTablePage] = useState<number>();
    const isPolicyNotesFeature = props.isFeatureEnabled(FeatureFlags.POLICY_NOTES);
    const requestBody = useRef<RequestDataType>({
        fromWhichTab: "myApp",
        nameType: '0',
        agentNumber: '',
        companyCode: '',
        drAgents: drAgentsList.current,
        myAppAgents: myAppAgentsList.current,
        applicationNumber: "",
        govtID: "",
        groupNumber: "",
        accountNumber: 0,
        participantIDNumber: "",
        policyNumber: "",
        status: "",
        receivedDate: "",
        fromAgentSearch: isFromAgentSearch,
        firstName: "",
        lastName: "",
        companyName: ""
    })
    const requestBodyInit = useRef<RequestDataType>({
        fromWhichTab: "",
        nameType: "0",
        agentNumber: "",
        companyCode: "",
        drAgents: drAgentsList.current,
        myAppAgents: myAppAgentsList.current,
        applicationNumber: "",
        govtID: "",
        groupNumber: "",
        accountNumber: 0,
        participantIDNumber: "",
        policyNumber: "",
        status: "",
        receivedDate: "",
        fromAgentSearch: isFromAgentSearch,
        firstName: "",
        lastName: "",
        companyName: ""
    })
    const requestBody4FetchAccount = useRef<RequestBody4FetchAccount>({
        fromWhichTab: 'myApp',
        agentNumber: '',
        companyCode: '',
        groupNumber: '',
        agents: [],
        fromAgentSearch: isFromAgentSearch
    })
    const defaultInitialValues = {
        fromTab: 'myApp',
        filterBy: "byAll",
        groupNumber: '',
        accountNumber: '',
        participantIDNumber: '',
        lastName: '',
        firstName: '',
        policyNumber: '',
        statusType: '',
        activeStatus: 'active',
        inActiveStatus: 'inactive',
        agentNumber: '',
        receivedDate: '',
        applicationNumber: '',
        govtID: '',
        dialogName: '',
        selectDPagent: '',
        selectedDrAgents: '',
        companyName: '',
        selectedMyAgents: ''
    };
    let initialValue: NewBusinessAppForm = props.getInitParams() !== undefined ? props.getInitParams() : defaultInitialValues;

    const filterByOptions = useRef<Array<any>>([])
    useEffect(() => {
        if (config === null) {
            let initParams = props.getInitParams();
            if (initParams !== undefined && initParams.filterBy !== undefined
                && 'newBusinessApplications' === initParams.storedPage) {
                setConfig(initParams.config)
                companyCodePre = initParams.companyCodePre
                agentNumberPre = initParams.agentNumberPre
                isFromAgentSearch = initParams.isFromAgentSearch

                setNBTable(initParams.nbTable)
                setMyAgentListTable(initParams.myAgentListTable)
                setMyAccountList(initParams.accountList)
                setMyAppGroupList(initParams.myAppGroupList)
                setDrAppGroupList(initParams.drAppGroupList)
                setGroupCompanyCode(initParams.groupCompanyCode)
                setMyAccountList(initParams.myAccountList)
                setDrAccountList(initParams.drAccountList)
                setdisableSaveBtn(initParams.disableSaveBtn)
                setShowSelectDPagent(initParams.showSelectDPagent)
                setShowSelectMyAgent(initParams.showSelectMyAgent)
                setSelectedMyAgentsList(initParams.selectedMyAgentsList)
                setSelectedDrAgentsList(initParams.selectedDrAgentsList)
                setMultiBtn(initParams.multiBtn)
                setCurrentTablePage(initParams.currentTablepage)
                setDrAgentListTable(initParams.storedDrSelectedAgents)
                setMyAgentListTable(initParams.storedMySelectedAgents)

                nbTab.current = initParams.nbTab
                filterByOptions.current = initParams.filterByOptions
                myAppAgentFilterBy.current = initParams.myAppAgentFilterBy
                drAppAgentFilterBy.current = initParams.drAppAgentFilterBy
                myAppAgentsList.current = initParams.myAppAgentsList
                drAgentsList.current = initParams.drAgentsList
                statusTypeOptionList.current = initParams.statusTypeOptionList
                activeOptionList.current = initParams.activeOptionList
                inactiveOptionList.current = initParams.inactiveOptionList
                hiddenMemberID.current = initParams.hiddenMemberID
                hiddenMyCancelLink.current = initParams.hiddenMyCancelLink
                hiddenDrCancelLink.current = initParams.hiddenDrCancelLink
                hiddenSelectMultipleAgtsLink4MyApp.current = initParams.hiddenSelectMultipleAgtsLink4MyApp
                selectedAgentCount.current = initParams.selectedAgentCount
                selectedAgentOption.current = initParams.selectedAgentOption
                selectedAgentOptionTmp.current = initParams.selectedAgentOptionTmp
                unSelectedAgentOptionTmp.current = initParams.unSelectedAgentOptionTmp
                storedSelectedRowNumbers.current = initParams.selectedRowNumbers
                storedDrSelectedAgents.current = initParams.storedDrSelectedAgents
                storedMySelectedAgents.current = initParams.storedMySelectedAgents
                dataObj.current = initParams.dataObj
                if (isFromAgentSearch) {
                    props.setPageTitle(props.getGlobalizedText("common.nbapplications") + " - " + initParams.config.agentName)
                }
            } else {
                ajax({
                    url: '/api/newBusiness/view',
                    params: {
                        companyCode: companyCodePre,
                        agentNumber: agentNumberPre,
                        fromAgentSearch: isFromAgentSearch
                    },
                    success: (res: any) => {
                        if (isFromAgentSearch) {
                            props.setPageTitle(props.getGlobalizedText("common.nbapplications") + " - " + res.agentName)
                        }

                        drAgentsList.current = res.drAgentList
                        myAppAgentsList.current = res.myAppAgentList
                        filterByOptions.current = res.myAppFilterBy
                        if (res.myAppAgent.length === 1) {
                            myAppAgentFilterBy.current = res.myAppAgent
                        } else {
                            let tmpMyAgents = {
                                name: "common.lbl.pleaseselect",
                                value: ""
                            }
                            myAppAgentFilterBy.current = [tmpMyAgents, ...res.myAppAgent]
                        }
                        let tmpPlaseSelect = {
                            name: "common.lbl.pleaseselect",
                            value: ""
                        }
                        drAppAgentFilterBy.current = [tmpPlaseSelect, ...res.directReport]
                        if (res.drAgentList !== undefined && res.drAgentList.length > 0) {
                            res.drAgentList.forEach((rowData: any) => {
                                let tmp: MultipleAgentsInfo = {
                                    agentNumber: rowData.agentNumber,
                                    city: rowData.city,
                                    companyCode: rowData.companyCode,
                                    name: rowData.name,
                                    stateCountry: rowData.stateCountry,
                                    streetAddress: rowData.streetAddress,
                                    checked: false
                                }
                                storedDrSelectedAgents.current.push(tmp)
                            })
                            setDrAgentListTable(res.drAgentList)
                        } else if (res.drAgentList === undefined || res.drAgentList.length === 0) {
                            storedDrSelectedAgents.current = []
                            setDrAgentListTable([])
                        }
                        if (res.myAppAgentList !== undefined && res.myAppAgentList.length > 0) {
                            res.myAppAgentList.forEach((rowData: any) => {
                                rowData.checked = false
                            })
                            storedMySelectedAgents.current = res.myAppAgentList
                            setMyAgentListTable(res.myAppAgentList)
                        } else if (res.myAppAgentList === undefined || res.myAppAgentList.length === 0) {
                            storedMySelectedAgents.current = []
                            setMyAgentListTable([])
                        }
                        if (res.myAppGroups !== undefined) {
                            setMyAppGroupList(res.myAppGroups)
                        }
                        if (res.accounts !== undefined) {
                            setMyAccountList(res.accounts)
                        }
                        if (res.myAppAgent.length > 1) {
                            hiddenSelectMultipleAgtsLink4MyApp.current = false
                        }
                        nbTab.current = "myApp"
                        statusTypeOptionList.current = res.statusTypeOptionList
                        activeOptionList.current = res.activeOptionList
                        inactiveOptionList.current = res.inactiveOptionList
                        requestBodyInit.current.fromWhichTab = "myApp"
                        requestBodyInit.current.agentNumber = res.agentNumber
                        requestBodyInit.current.companyCode = res.companyCode
                        requestBodyInit.current.fromAgentSearch = res.fromAgentSearch
                        //Loading NB all by default
                        ajax({
                            url: '/api/newBusiness/byAll',
                            method: "POST",
                            data: requestBodyInit.current,
                            success: (res2: any) => {
                                setNBTable(res2)
                            }
                        })
                        setConfig(res)
                    }
                })
            }
        }
    }, [config])

    function clearRequestBody() {
        requestBody.current.accountNumber = 0
        requestBody.current.agentNumber = config.agentNumber
        requestBody.current.applicationNumber = ''
        if (config.fromAgentSearch === true) {
            requestBody.current.companyCode = config.companyCode
        }
        requestBody.current.companyName = ''
        requestBody.current.drAgents = []
        requestBody.current.firstName = ''
        requestBody.current.fromAgentSearch = config.fromAgentSearch
        requestBody.current.fromWhichTab = nbTab.current
        requestBody.current.govtID = ''
        requestBody.current.groupNumber = ''
        requestBody.current.lastName = ''
        requestBody.current.myAppAgents = []
        requestBody.current.participantIDNumber = ''
        requestBody.current.policyNumber = ''
        requestBody.current.receivedDate = ''
        requestBody.current.status = ''
    }
    function clearRequestBody4FetchAccount() {
        requestBody4FetchAccount.current.fromWhichTab = ''
        requestBody4FetchAccount.current.agentNumber = ''
        requestBody4FetchAccount.current.companyCode = ''
        requestBody4FetchAccount.current.groupNumber = ''
        requestBody4FetchAccount.current.fromAgentSearch = isFromAgentSearch
        requestBody4FetchAccount.current.agents = []
    }

    function fetchGroupsForDrApp(formValues: NewBusinessAppForm) {
        requestBody4FetchAccount.current.fromWhichTab = 'drApp'
        requestBody4FetchAccount.current.agentNumber = config.agentNumber
        requestBody4FetchAccount.current.companyCode = config.companyCode
        // requestBody4FetchAccount.current.groupNumber = groupNum
        requestBody4FetchAccount.current.fromAgentSearch = config.fromAgentSearch
        requestBody4FetchAccount.current.agents = setDrOrDpAgentList(formValues)
        ajax({
            url: '/api/newBusiness/fetchGroupForDR',
            method: 'POST',
            data: requestBody4FetchAccount.current,
            success: (res: any) => {
                if (res.length === 1 && res[0].value === "0") {
                    setDrAppGroupList([{ name: "common.lbl.pleaseselect", value: "0" }])
                    setDrAccountList([{ name: "common.lbl.pleaseselect", value: "" }])
                } else if (res.length === 1 && res[0].value !== "0") {
                    let tmpGroupCompany = res[0].value.split("|")[0]
                    let tmpGroup = res[0].value.split("|")[1]
                    myAppForm.current.setFieldValue("groupNumber", res[0].value)
                    setDrAppGroupList(res)
                    loadAccounts4DrApp(tmpGroup, tmpGroupCompany, formValues)
                } else {
                    setDrAppGroupList(res)
                }
            }
        })
    }

    function loadAccounts4MyApp(groupNum: any, companyCode: any) {
        clearRequestBody4FetchAccount()
        requestBody4FetchAccount.current.fromWhichTab = 'myApp'
        requestBody4FetchAccount.current.agentNumber = config.agentNumber
        requestBody4FetchAccount.current.companyCode = companyCode
        requestBody4FetchAccount.current.groupNumber = groupNum
        requestBody4FetchAccount.current.fromAgentSearch = config.fromAgentSearch
        ajax({
            url: '/api/newBusiness/fetchAccounts',
            method: 'POST',
            data: requestBody4FetchAccount.current,
            success: (res: any) => {
                if (res[0].accountType !== undefined && res[0].accountType === "I") {
                    hiddenMemberID.current = false
                }
                myAppForm.current.setFieldValue("accountNumber", res[0].value);
                let tmpAccounts: Array<SelectOptionType> = []
                res.forEach((row: any) => {
                    let tmp: SelectOptionType = {
                        name: row.displayValue,
                        value: row.value
                    }
                    tmpAccounts.push(tmp)
                })
                setMyAccountList(tmpAccounts)
            }
        })
    }

    function loadAccounts4DrApp(groupNum: any, companyCode: any, formValues: NewBusinessAppForm) {
        requestBody4FetchAccount.current.fromWhichTab = 'drApp'
        requestBody4FetchAccount.current.agentNumber = config.agentNumber
        requestBody4FetchAccount.current.companyCode = companyCode
        requestBody4FetchAccount.current.groupNumber = groupNum
        requestBody4FetchAccount.current.fromAgentSearch = config.fromAgentSearch
        requestBody4FetchAccount.current.agents = setDrOrDpAgentList(formValues)
        ajax({
            url: '/api/newBusiness/fetchAccounts',
            method: 'POST',
            data: requestBody4FetchAccount.current,
            success: (res: any) => {
                if (res[0].accountType !== undefined && res[0].accountType === "I") {
                    hiddenMemberID.current = false
                }
                myAppForm.current.setFieldValue("accountNumber", res[0].value);
                let tmpAccounts: Array<SelectOptionType> = []
                res.forEach((row: any) => {
                    let tmp: SelectOptionType = {
                        name: row.displayValue,
                        value: row.value
                    }
                    tmpAccounts.push(tmp)
                })
                setDrAccountList(tmpAccounts)
            }
        })
    }

    function setDrOrDpAgentList(formValues: NewBusinessAppForm) {
        if (selectedDrAgentsList === null) {
            let tmpDragents: Array<AgentParamsType> = []
            let tmpCompanyCodeAndAgentNumber = formValues.selectDPagent.split("-")
            let tmpObj = {
                agentNumber: tmpCompanyCodeAndAgentNumber[0],
                companyCode: tmpCompanyCodeAndAgentNumber[1]
            }
            tmpDragents.push(tmpObj)
            return tmpDragents
            // requestBody.current.drAgents = tmpDragents
        } else {
            let tmpDragents: Array<AgentParamsType> = []
            selectedDrAgentsList.forEach((row: any, index: number) => {
                let tmpCompanyCodeAndAgentNumber: Array<any> = row.value.split("|")
                let tmpObj = {
                    agentNumber: trim(tmpCompanyCodeAndAgentNumber[0]),
                    companyCode: trim(tmpCompanyCodeAndAgentNumber[1])
                }
                tmpDragents.push(tmpObj)
            })
            return tmpDragents
            // requestBody.current.drAgents = tmpDragents
        }
    }

    function loadNBByAll(formValues: NewBusinessAppForm) {

        clearRequestBody()
        if (nbTab.current === "drApp") {
            requestBody.current.fromWhichTab = "drApp"
            requestBody.current.drAgents = setDrOrDpAgentList(formValues)
        } else {
            requestBody.current.fromWhichTab = "myApp"
        }
        ajax({
            url: '/api/newBusiness/byAll',
            method: "POST",
            data: requestBody.current,
            success: (res: any) => {
                setNBTable(res)
            }
        })
    }

    function loadNBByGroupAccount(formValues: NewBusinessAppForm) {
        clearRequestBody()
        if (nbTab.current === "drApp") {
            requestBody.current.fromWhichTab = "drApp"
            requestBody.current.drAgents = setDrOrDpAgentList(formValues)
        }
        requestBody.current.companyCode = formValues.groupNumber.split("|")[0]
        requestBody.current.groupNumber = formValues.groupNumber.split("|")[1]
        requestBody.current.accountNumber = parseInt(formValues.accountNumber)
        requestBody.current.participantIDNumber = formValues.participantIDNumber
        ajax({
            url: '/api/newBusiness/byGroup',
            method: "POST",
            data: requestBody.current,
            success: (res: any) => {
                setNBTable(res)
            }
        })
    }

    function loadNBByAppName(formValues: NewBusinessAppForm) {
        clearRequestBody()
        if (nbTab.current === "drApp") {
            requestBody.current.fromWhichTab = "drApp"
            requestBody.current.drAgents = setDrOrDpAgentList(formValues)
        }
        if (formValues.nameType === NAME_TYPE_PERSON) {
            requestBody.current.lastName = trim(formValues.lastName)
            requestBody.current.firstName = trim(formValues.firstName)
        } else if (formValues.nameType === NAME_TYPE_COMPANY) {
            requestBody.current.companyName = trim(formValues.companyName)
        }
        ajax({
            url: '/api/newBusiness/byAppName',
            method: "POST",
            data: requestBody.current,
            success: (res: any) => {
                setNBTable(res)
            }
        })
    }

    function loadNBByPolicy(formValues: NewBusinessAppForm) {
        clearRequestBody()
        if (nbTab.current === "drApp") {
            requestBody.current.fromWhichTab = "drApp"
            requestBody.current.drAgents = setDrOrDpAgentList(formValues)
        }
        requestBody.current.policyNumber = trim(formValues.policyNumber)
        ajax({
            url: '/api/newBusiness/byPolicy',
            method: "POST",
            data: requestBody.current,
            success: (res: any) => {
                setNBTable(res)
            }
        })
    }
    function loadNBByStatus(formValues: NewBusinessAppForm) {
        clearRequestBody()
        if (nbTab.current === "drApp") {
            requestBody.current.fromWhichTab = "drApp"
            requestBody.current.drAgents = setDrOrDpAgentList(formValues)
        }
        if (formValues.statusType === 'all') {
            requestBody.current.status = 'all'
        } else {
            if (formValues.statusType === 'active') {
                if (formValues.activeStatus === "0") {
                    requestBody.current.status = 'active'
                } else {
                    requestBody.current.status = formValues.activeStatus
                }
            } else if (formValues.statusType === 'inactive') {
                if (formValues.inActiveStatus === "0") {
                    requestBody.current.status = 'inactive'
                } else {
                    requestBody.current.status = formValues.inActiveStatus
                }

            }
        }
        ajax({
            url: '/api/newBusiness/byStatus',
            method: "POST",
            data: requestBody.current,
            success: (res: any) => {
                setNBTable(res)
            }
        })
    }
    function loadNBByAgent(formValues: NewBusinessAppForm) {
        clearRequestBody()
        if (selectedMyAgentsList === null) {
            let tmpMyAgents: Array<AgentParamsType> = []
            let tmpCompanyCodeAndAgentNumber = formValues.agentNumber.split("-")
            let tmpObj = {
                agentNumber: tmpCompanyCodeAndAgentNumber[0],
                companyCode: tmpCompanyCodeAndAgentNumber[1]
            }
            tmpMyAgents.push(tmpObj)
            requestBody.current.myAppAgents = tmpMyAgents
        } else {
            let tmpMyAgents: Array<AgentParamsType> = []
            selectedMyAgentsList.forEach((row: any, index: number) => {
                let tmpCompanyCodeAndAgentNumber: Array<any> = row.value.split("|")
                let tmpObj = {
                    agentNumber: trim(tmpCompanyCodeAndAgentNumber[0]),
                    companyCode: trim(tmpCompanyCodeAndAgentNumber[1])
                }
                tmpMyAgents.push(tmpObj)
            })
            requestBody.current.myAppAgents = tmpMyAgents
        }
        ajax({
            url: '/api/newBusiness/byAgent',
            method: "POST",
            data: requestBody.current,
            success: (res: any) => {
                setNBTable(res)
            }
        })
    }
    function loadNBByReceiveDate(formValues: NewBusinessAppForm) {
        clearRequestBody()
        if (nbTab.current === "drApp") {
            requestBody.current.fromWhichTab = "drApp"
            requestBody.current.drAgents = setDrOrDpAgentList(formValues)
        }
        requestBody.current.receivedDate = formValues.receivedDate
        ajax({
            url: '/api/newBusiness/byReceivedDate',
            method: "POST",
            data: requestBody.current,
            success: (res: any) => {
                setNBTable(res)
            }
        })
    }
    function loadNBByAppNum(formValues: NewBusinessAppForm) {
        clearRequestBody()
        if (nbTab.current === "drApp") {
            requestBody.current.fromWhichTab = "drApp"
            requestBody.current.drAgents = setDrOrDpAgentList(formValues)
        }
        requestBody.current.applicationNumber = trim(formValues.applicationNumber)
        ajax({
            url: '/api/newBusiness/byAppNumber',
            method: "POST",
            data: requestBody.current,
            success: (res: any) => {
                setNBTable(res)
            }
        })
    }
    function loadNBByGovtID(formValues: NewBusinessAppForm) {
        clearRequestBody()
        if (nbTab.current === "drApp") {
            requestBody.current.fromWhichTab = "drApp"
            requestBody.current.drAgents = setDrOrDpAgentList(formValues)
        }
        requestBody.current.govtID = trim(formValues.govtID)
        ajax({
            url: '/api/newBusiness/byGovtID',
            method: "POST",
            data: requestBody.current,
            success: (res: any) => {
                setNBTable(res)
            }
        })
    }
    const byGroupAccount = yup.object().shape({
        groupNumber: YupSchema.newBusinessApplication.groupNumber,
        accountNumber: YupSchema.newBusinessApplication.accountNumber
    });
    const byGroupAccountDr = yup.object().shape({
        groupNumber: YupSchema.newBusinessApplication.groupNumber,
        accountNumber: YupSchema.newBusinessApplication.accountNumber,
        selectDPagent: YupSchema.newBusinessApplication.dpAgent
    });
    const byPolicyNumber = yup.object().shape({
        policyNumber: YupSchema.policyNumber
    });
    const byPolicyNumberDr = yup.object().shape({
        policyNumber: YupSchema.policyNumber,
        selectDPagent: YupSchema.newBusinessApplication.dpAgent
    });
    const byApplicationNumber = yup.object().shape({
        applicationNumber: YupSchema.newBusinessApplication.applicationNumber
    });
    const byApplicationNumberDr = yup.object().shape({
        applicationNumber: YupSchema.newBusinessApplication.applicationNumber,
        selectDPagent: YupSchema.newBusinessApplication.dpAgent
    });
    const byApplicationNamePerson = yup.object().shape({
        lastName: YupSchema.lastName
    });
    const byApplicationNamePersonDr = yup.object().shape({
        lastName: YupSchema.lastName,
        selectDPagent: YupSchema.newBusinessApplication.dpAgent
    });
    const byApplicationNameCompany = yup.object().shape({
        companyName: YupSchema.companyName
    });
    const byApplicationNameCompanyDr = yup.object().shape({
        companyName: YupSchema.companyName,
        selectDPagent: YupSchema.newBusinessApplication.dpAgent
    });
    const byGovtID = yup.object().shape({
        govtID: YupSchema.govtID(DEFAULT_FORMAT_TYPE, props.getGlobalizedText)
    });
    const byGovtIDDr = yup.object().shape({
        govtID: YupSchema.govtID(DEFAULT_FORMAT_TYPE, props.getGlobalizedText),
        selectDPagent: YupSchema.newBusinessApplication.dpAgent
    });
    const byReceivedDate = yup.object().shape({
        receivedDate: YupSchema.newBusinessApplication.receivedDate
    });
    const byReceivedDateDr = yup.object().shape({
        receivedDate: YupSchema.newBusinessApplication.receivedDate,
        selectDPagent: YupSchema.newBusinessApplication.dpAgent
    });
    const byDpAgent = yup.object().shape({
        selectDPagent: YupSchema.newBusinessApplication.dpAgent
    });
    const byMyAgent = yup.object().shape({
        agentNumber: YupSchema.newBusinessApplication.myAgent
    });

    const formValidate = (values: any): any => {
        if (nbTab.current === "drApp" && showSelectDPagent === true) {
            if (values.filterBy === config.byGroup) {
                return validate(byGroupAccountDr, values, props);
            } else if (values.filterBy === config.byAppNumber) {
                return validate(byApplicationNumberDr, values, props);
            } else if (values.filterBy === config.byAppName && values.nameType === NAME_TYPE_PERSON) {
                return validate(byApplicationNamePersonDr, values, props);
            } else if (values.filterBy === config.byAppName && values.nameType === NAME_TYPE_COMPANY) {
                return validate(byApplicationNameCompanyDr, values, props);
            } else if (values.filterBy === config.byPolicy) {
                return validate(byPolicyNumberDr, values, props);
            } else if (values.filterBy === config.byReceivedDate) {
                return validate(byReceivedDateDr, values, props);
            } else if (values.filterBy === config.byGovtID) {
                return validate(byGovtIDDr, values, props);
            } else {
                return validate(byDpAgent, values, props);
            }
        } else {
            if (config.fromAgentSearch === false && (values.filterBy === config.byAgent && showSelectMyAgent === true)) {
                if (values.filterBy === config.byGroup) {
                    return validate(byGroupAccount, values, props);
                } else if (values.filterBy === config.byAppNumber) {
                    return validate(byApplicationNumber, values, props);
                } else if (values.filterBy === config.byAppName && values.nameType === NAME_TYPE_PERSON) {
                    return validate(byApplicationNamePerson, values, props);
                } else if (values.filterBy === config.byAppName && values.nameType === NAME_TYPE_COMPANY) {
                    return validate(byApplicationNameCompany, values, props);
                } else if (values.filterBy === config.byPolicy) {
                    return validate(byPolicyNumber, values, props);
                } else if (values.filterBy === config.byReceivedDate) {
                    return validate(byReceivedDate, values, props);
                } else if (values.filterBy === config.byGovtID) {
                    return validate(byGovtID, values, props);
                } else {
                    return validate(byMyAgent, values, props);
                }
            } else {
                if (values.filterBy === config.byGroup) {
                    return validate(byGroupAccount, values, props);
                } else if (values.filterBy === config.byAppNumber) {
                    return validate(byApplicationNumber, values, props);
                } else if (values.filterBy === config.byAppName && values.nameType === NAME_TYPE_PERSON) {
                    return validate(byApplicationNamePerson, values, props);
                } else if (values.filterBy === config.byAppName && values.nameType === NAME_TYPE_COMPANY) {
                    return validate(byApplicationNameCompany, values, props);
                } else if (values.filterBy === config.byPolicy) {
                    return validate(byPolicyNumber, values, props);
                } else if (values.filterBy === config.byReceivedDate) {
                    return validate(byReceivedDate, values, props);
                } else if (values.filterBy === config.byGovtID) {
                    return validate(byGovtID, values, props);
                }
            }
        }
    }
    const formSubmit = (values: NewBusinessAppForm): void => {
        props.clearMessage();
        if (values.filterBy === config.byGroup) {
            loadNBByGroupAccount(values);
        } else if (values.filterBy === config.byPolicy) {
            loadNBByPolicy(values);
        } else if (values.filterBy === config.byGovtID) {
            loadNBByGovtID(values);
        } else if (values.filterBy === config.byAppNumber) {
            loadNBByAppNum(values);
        } else if (values.filterBy === config.byStatus) {
            loadNBByStatus(values);
        } else if (values.filterBy === config.byAgent) {
            loadNBByAgent(values);
        } else if (values.filterBy === config.byAppName) {
            loadNBByAppName(values)
        } else if (values.filterBy === config.byReceivedDate) {
            loadNBByReceiveDate(values)
        } else {
            loadNBByAll(values);
        }
    }
    const groupChangeHandler = (e: any, formValue: NewBusinessAppForm, setFieldValue: (fieldName: string, value: any) => void) => {
        myAppForm.current.setFieldValue("participantIDNumber", "");
        hiddenMemberID.current = true
        if (nbTab.current === 'myApp') {
            if (e.target.value === '0') {
                myAppForm.current.setFieldValue("groupNumber", "0")
                setMyAccountList([{ name: "common.lbl.pleaseselect", value: "" }])
                myAppForm.current.setFieldValue("accountNumber", "");
            } else {
                let tmpCompanyCode = e.target.value.split("|")[0]
                let tmpGroupNum = e.target.value.split("|")[1]
                loadAccounts4MyApp(tmpGroupNum, tmpCompanyCode);
            }
        } else {// Dr App
            if (e.target.value === '0') {
                myAppForm.current.setFieldValue("groupNumber", "0")
                setDrAccountList([{ name: "common.lbl.pleaseselect", value: "" }])
                myAppForm.current.setFieldValue("accountNumber", "");
            } else {
                let tmpCompanyCode = e.target.value.split("|")[0]
                let tmpGroupNum = e.target.value.split("|")[1]
                loadAccounts4DrApp(tmpGroupNum, tmpCompanyCode, formValue)
            }
        }

    };
    const accountChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        myAppForm.current.setFieldValue("participantIDNumber", "");
        hiddenMemberID.current = true
        if (nbTab.current === 'myApp') {
            for (let i = 0; i < myAccountList.length; i++) {
                if (myAccountList[i].value === e.target.value) {
                    if ('I' === accounts[i].accountType) {
                        hiddenMemberID.current = false
                    }
                }
            }
        } else { // Dr App
            for (let i = 0; i < drAccountList.length; i++) {
                if (drAccountList[i].value === e.target.value) {
                    if ('I' === accounts[i].accountType) {
                        hiddenMemberID.current = false
                    }
                }
            }
        }

    };
    const statusTypeChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        if (e.target.value === "all") {
            myAppForm.current.setFieldValue("statusType", "all")
        } else if (e.target.value === "active") {
            myAppForm.current.setFieldValue("activeStatus", "0")
        } else {
            myAppForm.current.setFieldValue("inActiveStatus", "0")
        }
    }
    const filterByChangeHandler = (e: any, formValue: NewBusinessAppForm, setFieldValue: (fieldName: string, value: any) => void) => {
        redoFilterSelector();
        if (config.byGroup === e.target.value) {
            if (nbTab.current === 'myApp') { //My application tab
                if (myAppGroupList.length === 1 && myAppGroupList[0].value !== "0") {
                    setFieldValue("groupNumber", myAppGroupList[0].value)
                    let tmpGroupCompany = myAppGroupList[0].value.split("|")[0]
                    let tmpGroup = myAppGroupList[0].value.split("|")[1]
                    loadAccounts4MyApp(tmpGroup, tmpGroupCompany)
                } else {
                    setMyAppGroupList(config.myAppGroups)
                    setMyAccountList(config.accounts)
                }
            } else { // DR applications
                setDrAccountList([{ name: "common.lbl.pleaseselect", value: "" }])
                fetchGroupsForDrApp(formValue)
            }
        }
        if (config.byAgent === e.target.value) {
            if (myAppAgentFilterBy.current.length > 0) {
                setFieldValue("agentNumber", myAppAgentFilterBy.current[0].value)
            }
        }
    };
    const nameTypeChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        myAppForm.current.setFieldValue("lastName", '');
        myAppForm.current.setFieldValue("firstName", '');
        myAppForm.current.setFieldValue("companyName", '');
        props.clearMessage();
    };
    function redoFilterSelector() {
        pageClearAll()
        myAppForm.current.setFieldValue("statusType", 'all')
        myAppForm.current.setFieldValue("govtID", '')
        myAppForm.current.setFieldValue("lastName", '')
        myAppForm.current.setFieldValue("firstName", '')
        myAppForm.current.setFieldValue("companyName", '')
        myAppForm.current.setFieldValue("policyNumber", '')
        myAppForm.current.setFieldValue("groupNumber", '')
        myAppForm.current.setFieldValue("accountNumber", '')
        myAppForm.current.setFieldValue("receivedDate", '')
        myAppForm.current.setFieldValue("applicationNumber", '')
        myAppForm.current.setFieldValue("participantIDNumber", '')
        myAppForm.current.setFieldValue("agentNumber", '')
        hiddenMemberID.current = true
    }
    function pageClearAll() {
        props.clearMessage();
        //clear data of table
        setNBTable(null)
    }
    function changeTab(tabName: Level) {
        nbTab.current = tabName
        props.clearMessage()
        myAppForm.current.setFieldValue("filterBy", 'byAll')
        setShowSelectDPagent(true)
        setShowSelectMyAgent(true)
        hiddenDrCancelLink.current = true
        selectedAgentCount.current = 0
        redoFilterSelector()
        if (tabName === "drApp") {
            filterByOptions.current = config.drFilterBy
            myAppForm.current.setFieldValue("selectDPagent", drAppAgentFilterBy.current[0].value);
        } else {
            filterByOptions.current = config.myAppFilterBy
            loadNBByAll(initialValue)
        }
        cancelMultiAgents()
    }
    function showAllAgentInfo(tipnode: any, rowData: any, parentNode?: any) {
        let msg = ''
        if (rowData.groupAgentInfos === undefined) {
            ajax({
                url: '/api/newBusiness/queryAllAgentsShow',
                params: {
                    companyCode: trim(rowData.companyCode),
                    policyNumber: trim(rowData.policyNumberOrMemberID)
                },
                showMask: false,
                success: (res) => {
                    let agtNumber;
                    let agtName;
                    for (let agentRowData of res) {
                        agtNumber = agentRowData.agentNumber;
                        agtName = agentRowData.agentName;
                        msg += (agtNumber + " " + agtName + "<br/>");
                    }
                    showTooltip(tipnode, msg, undefined, parentNode);
                }
            })
        } else {
            msg = rowData.groupAgentInfos
            showTooltip(tipnode, msg, undefined, parentNode);
        }
    }
    //Define useTable
    const tableObj = useTable({
        id: "myAppNBTable",
        option: { paging: true, scrollCollapse: true, order: [[1, 'asc']] },
        next: props.next,
        dateformat: props.getDateFormat(),
        table: {
            columns: [
                { className: "gwp-dt-detail-control", sortable: false },
                {
                    title: 'common.lbl.applicationNumber', width: "13%", name: 'applicationNumber', render: (data: any, type: any, row: any, meta: any) => {
                        if (row.viewpolicy === "yes") {
                            return `<a id='policyNumber'>${row.applicationNumber}</a>`
                        } else {
                            return row.applicationNumber
                        }
                    },
                    onClick: (e: any, row: any) => {
                        saveDataBeforeRedirect();
                        if (row !== undefined && row !== null && row.displayApplicantName !== undefined && row.displayApplicantName !== '') {
                            if (row.viewpolicy === "yes") {
                                props.next(`/applicationDetail?companyCode=${trim(row.companyCode)}&groupNumber=${trim(row.groupNum)}&accountNumber=${trim(row.accountNum)}&participantIDNumber=${trim(row.policyNumberOrMemberID)}&applicantNumber=${trim(row.applicationNumber)}&accountType=${trim(row.accountType)}&agentIndicator=${trim(row.agentIndicator)}&applicantName=${trim(row.displayApplicantName)}`)
                            }
                        } else {
                            if (row !== undefined && row !== null) {
                                let policyNum = trim(row.policyNumberOrMemberID)
                                if (row.viewpolicy === "yes") {
                                    props.next(`/policyDetail/${trim(row.companyCode)}/${policyNum}`)
                                }
                            }
                        }
                    }
                },
                {
                    title: 'common.lbl.name', width: "8%", name: '',
                    render: (data: any, type: any, row: any, meta: any) => {
                        let retstr = ''
                        if (row.displayApplicantName === undefined || isEmptyStr(row.displayApplicantName)) {

                            if (!isEmptyStr(row.applicantName)) {
                                retstr += `<a id='owner1'>${row.applicantName}</a>`
                            }
                            if (row.owner2Name !== undefined && !isEmptyStr(row.owner2Name)) {
                                retstr += "<br/>"
                                retstr += `<a id='owner2'>${row.owner2Name}</a>`
                            }

                            return retstr
                        } else {
                            if (row.fullState === "Incomplete") {
                                return row.displayApplicantName
                            } else {
                                return `<a id='groupOwner'>${row.displayApplicantName}</a>`
                            }
                        }
                    },
                    onClick: (e: any, row: any) => {
                        saveDataBeforeRedirect()
                        let whichOwner = e.target.id
                        if (whichOwner === 'owner1') {
                            props.next(`/personalInformation/view?personalInfoType=${2}&companyCode=${trim(row.companyCode)}&policyNumber=${trim(row.policyNumberOrMemberID)}&roleTypeCode=${'OW1'}&baseRiderCode=${0}`)
                        } else if (whichOwner === 'owner2') {
                            props.next(`/personalInformation/view?personalInfoType=${2}&companyCode=${trim(row.companyCode)}&policyNumber=${trim(row.policyNumberOrMemberID)}&roleTypeCode=${'OW2'}&baseRiderCode=${0}`)
                        } else {
                            props.next(`/personalInformation/view?personalInfoType=${1}&companyCode=${trim(row.companyCode)}&groupNumber=${trim(row.groupNum)}&accountNumber=${trim(row.accountNum)}&participantIDNumber=${trim(row.policyNumberOrMemberID)}&dependentSequenceNumber=${0}`)
                        }
                    }
                },
                {
                    title: 'common.lbl.policyNumberMemberID', width: "20%", name: 'policyNumberOrMemberID',
                    render: (data: any, type: any, row: any, meta: any) => {
                        let viewPolicyCommentHnt = props.getGlobalizedText('inforce.view.policy.comments')
                        let returnStr = `<a id="viewStatement${meta.row}" title="${viewPolicyCommentHnt}"><span class="gwp-icon gwp-icon-view-statement gwp-icon-sm"></a> `
                        let policyNum = trim(row.policyNumberOrMemberID)
                        if (isPolicyNotesFeature === true && config.viewPolicyNotesInNewBusiness !== undefined && config.viewPolicyNotesInNewBusiness === true && row.policyNotesCount > 0) {
                            return policyNum + " " + returnStr
                        } else {
                            return policyNum
                        }
                    },
                    onClick: (e: any, row: any) => {
                        saveDataBeforeRedirect()
                        if (isPolicyNotesFeature === true && config.viewPolicyNotesInNewBusiness !== undefined && config.viewPolicyNotesInNewBusiness === true && row.policyNotesCount > 0) {
                            props.next(`/policyComments/view?companyCode=${trim(row.companyCode)}&policyNumber=${trim(row.policyNumberOrMemberID)}`)
                        }
                    }
                },
                {
                    title: 'common.lbl.group', width: "15%", name: 'groupNum', render: (data: any, type: any, row: any, meta: any) => {
                        if (row.displayApplicantName !== undefined || !isEmptyStr(row.displayApplicantName)) {
                            return removeLeftZeroForNumber(row.groupNum) + " - " + row.groupName
                        } else {
                            if (row.groupName.indexOf("/") !== -1) {
                                return row.groupName.split("/")[1] + " " + row.groupName.split("/")[0]
                            } else {
                                return row.groupName
                            }

                        }
                    }
                },
                {
                    title: 'common.lbl.account', width: "13%", name: 'accountNum', render: (data: any, type: any, row: any, meta: any) => {
                        if (isEmptyStr(row.accountNum) && isEmptyStr(row.accountName)) {
                            return ''
                        } else { return row.accountNum + " - " + row.accountName }
                    }
                },
                {
                    title: 'common.lbl.status', width: "6%", name: 'fullState', render: (data: any, type: any, row: any, meta: any) => {
                        return row.fullState
                    }
                },
                {
                    title: 'common.receieved.date', width: "12%", name: 'receivedDate', type: 'date'
                },
                {
                    title: 'common.lbl.agent', name: 'agentNumber',
                    width: "8%",
                    render: (data: any, type: any, row: any, meta: any) => {
                        if (row.agentIndicator === undefined || isEmptyStr(row.agentIndicator)) {
                            if (row.agentCount > 1) {
                                let viewAgent = props.getGlobalizedText("common.lbl.showAllAgent")
                                return `${row.agentNumber + row.agentName} <a class="showAgentInfo" id='showAllAgentNamesTableCell${meta.row}'>${viewAgent}</a>`
                            } else {
                                return row.agentNumber + row.agentName
                            }
                        } else {
                            if (row.showAllAgentCount > 1) {
                                let viewAgent = props.getGlobalizedText("common.lbl.showAllAgent")
                                return row.agentInfo + `<a class="showAgentInfo" id='showAllAgentNamesTableCell${meta.row}'>${viewAgent}</a>`
                            } else {
                                return row.agentInfo
                            }
                        }
                    },
                    eventTarget: (row: any, rowNumber: number) => {
                        return `#showAllAgentNamesTableCell${rowNumber}`
                    },
                    onClick: (e: any, row: any) => {
                        let tipnode = ''
                        tipnode += '#' + e.target.id
                        showAllAgentInfo(tipnode, row)
                    }
                }
            ],
            data: nbTable
        },
        onRendered: () => {
            if (config !== undefined && config !== null) {
                tableObj.page(currentTablePage)
            }
        },
        onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element | null) => {
            let id = '#showAllAgentNamesTableCell' + rowNumber
            let docID = '#viewStatement' + rowNumber
            childNode?.querySelector(id)?.addEventListener("click", () => {
                showAllAgentInfo(id, rowData, childNode)
            });
            childNode?.querySelector(docID)?.addEventListener("click", () => {
                props.next(`/policyComments/view?companyCode=${trim(rowData.companyCode)}&policyNumber=${trim(rowData.policyNumberOrMemberID)}`)
            });

        }
    });

    if (config === null) {
        return <></>
    }

    return <>
        <props.Div>
            <ul id="nbtab" className="nav nav-tabs" role="tablist">
                <li role="presentation" className={nbTab.current === 'myApp' ? 'active' : ''}>
                    <a href="#myApp" aria-controls="myApp" role="tab" data-toggle="tab" onClick={() => { changeTab("myApp") }} ><GlobalizedText message="nb.my.applications" /></a>
                </li>
                {config.directReport.length > 0 &&
                    <li role="presentation" className={nbTab.current === 'drApp' ? 'active' : ''} >
                        <a href="#drApp" aria-controls="drApp" role="tab" data-toggle="tab" onClick={() => { changeTab("drApp") }} ><GlobalizedText message="nb.dp.applications2" /></a>
                    </li>
                }
            </ul>
        </props.Div>
        <Formik initialValues={initialValue}
            enableReinitialize={true}
            validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}>
            {formProps => {
                myAppForm.current = formProps
                return <Form id="newBusinessApplications">
                    <props.Div className="tab-content" >
                        {/* Dr App */}
                        <props.Div role="tabpanel" className={`tab-pane fade in ${nbTab.current === 'drApp' ? 'active' : ''}`} id="drApp">
                            <props.Row>
                                <props.Col md="4" sm="4" xs="12">
                                    <props.SelectControl name="selectDPagent" label="common.lbl.directReport" defaultOption={"common.lbl.pleaseselect"} disabled={showSelectDPagent ? false : true} sort={false} options={drAppAgentFilterBy.current} />
                                </props.Col>
                                <props.Col md="4" sm="4" xs="12" className={hiddenDrCancelLink.current === true ? "hidden" : ""}>
                                    <props.Label><GlobalizedText message="ib.lbl.mdr" />(<props.Span id="selectedMultiNumber" className="gwp-required">{selectedAgentCount.current}</props.Span>)</props.Label>
                                    <props.SelectControl name="selectedDrAgents" style={{ marginTop: '-14px' }} label=""
                                        options={selectedDrAgentsList === null ? [{ name: "", value: "" }] : selectedDrAgentsList} />
                                </props.Col>
                                <props.Col md="4" sm="4" xs="12">
                                    <props.Div className="multiDrAgt">
                                        <a onClick={() => { openMultipleAgentsDialog() }}>
                                            <GlobalizedText message="common.lbl.selectFromAgentList" />
                                        </a>
                                        <br />
                                        <a className={hiddenDrCancelLink.current === true ? "hidden" : ""} onClick={() => { cancelMultiAgents() }} id="INB_cancelMulti_dr">
                                            <GlobalizedText message="common.lbl.cancelMulti" />
                                        </a>
                                    </props.Div>
                                </props.Col>
                            </props.Row>
                            <props.HR />
                        </props.Div>
                    </props.Div>
                    {/* My App */}
                    <props.Div role="tabpanel" className="tab-pane fade in active" id="myApp">
                        <props.Row>
                            <props.Col md="4" sm="4" xs="12">
                                <props.SelectControl name="filterBy" sort={true} defaultOption="common.lbl.all" onChange={(e: any) => { filterByChangeHandler(e, formProps.values, formProps.setFieldValue) }} label="nb.filter.nb.by" id="NBA_filterSel_myApp" options={filterByOptions.current} />
                                <props.NameTypeSelectControl onChange={(e: any) => { nameTypeChangeHandler(e, formProps.values, formProps.setFieldValue) }}
                                    condition={formProps.values.filterBy === config.byAppName} name="nameType" />
                            </props.Col>
                            <props.Col md="8" sm="8" xs="12">
                                {formProps.values.filterBy === config.byGroup && <>
                                    <props.SelectControl name="groupNumber" defaultOption={"common.lbl.pleaseselect"} sort={false} label="common.lbl.group" onChange={(e: any) => { groupChangeHandler(e, formProps.values, formProps.setFieldValue) }} required={true} options={nbTab.current === 'myApp' ? myAppGroupList : drAppGroupList} />
                                    <props.SelectControl name="accountNumber" label="common.lbl.account" onChange={(e: any) => { accountChangeHandler(e, formProps.values, formProps.setFieldValue) }} required={true} sort={false} options={nbTab.current === 'myApp' ? myAccountList : drAccountList} />
                                    <props.TextControl id="participantIDNumber" name="participantIDNumber" label="common.lbl.memberID" maxLength={10} condition={hiddenMemberID.current !== true}></props.TextControl>
                                </>}
                                {formProps.values.filterBy === config.byPolicy && <>
                                    <props.TextControl id="policyNumber" name="policyNumber" label="common.lbl.policyNumber" required={true}></props.TextControl>
                                </>}
                                {formProps.values.filterBy === config.byGovtID && <>
                                    <props.AutoFormatControl formatType="SSN" valueWithFormat={true} id="govtID" name="govtID" label="common.lbl.govID" required={true}></props.AutoFormatControl>
                                </>}
                                {formProps.values.filterBy === config.byAppName && formProps.values.nameType === NAME_TYPE_PERSON && <>
                                    <props.LastNameAutoCompleteControl roleTypeCodes={["OW1", "OW2", "PAY", "MBR"]} required={true} htmlFor="lastName_input" id="lastName" name="lastName" />
                                    <props.FirstNameAutoCompleteControl roleTypeCodes={["OW1", "OW2", "PAY", "MBR"]} htmlFor="firstName_input" id="firstName" name="firstName" />
                                </>}
                                {formProps.values.filterBy === config.byAppName && formProps.values.nameType === NAME_TYPE_COMPANY && <>
                                    <props.OrgNameAutoCompleteControl required={true} roleTypeCodes={["OW1", "OW2", "PAY"]} htmlFor="companyName_input" id="companyName" name="companyName" />
                                </>}
                                {formProps.values.filterBy === config.byAppNumber && <>
                                    <props.TextControl id="applicationNumber" name="applicationNumber" label="common.lbl.applicationNumber" required={true}></props.TextControl>
                                </>}
                                {formProps.values.filterBy === config.byReceivedDate && <>
                                    <props.DateTimePickerControl htmlFor="receivedDate_input" id="receivedDate" name="receivedDate" label="common.receieved.date" dateformat={config.dateFormat} required={true} />
                                </>}
                                {formProps.values.filterBy === config.byAgent && <>
                                    <props.Row>
                                        <props.Col md="5" sm="6" xs="12" className="selectControl">
                                            <props.SelectControl name="agentNumber" id="agentNumber" className="selectControl" label="common.lbl.agent" sort={false} required={true} disabled={showSelectMyAgent ? false : true} options={myAppAgentFilterBy.current} />
                                            <br />
                                            <props.Div id="myAppMultAgts" className={showSelectMyAgent ? "hidden" : ""}>
                                                <props.Label><GlobalizedText message="ib.lbl.mulan" />(<props.Span id="selectedMultiNumber" className="gwp-required">{selectedAgentCount.current}</props.Span>)</props.Label>
                                                <props.SelectControl name="selectedMyAgents" id="selectedMyAgents" style={{ marginTop: '-14px' }} label=""
                                                    options={selectedMyAgentsList === null ? [{ name: "", value: "" }] : selectedMyAgentsList} />
                                            </props.Div>
                                        </props.Col>
                                        <props.Col condition={!hiddenSelectMultipleAgtsLink4MyApp.current && !config.fromAgentSearch} md="3" sm="6" xs="12">
                                            <props.Div className="multiMyAgt">
                                                <a onClick={() => { openMultipleAgentsDialog() }}>
                                                    <GlobalizedText message="common.lbl.selectFromAgentList" />
                                                </a>
                                                <br />
                                                <a className={hiddenMyCancelLink.current === true ? "hidden" : ""} onClick={() => { cancelMultiAgents() }} id="NB_cancelMulti_my">
                                                    <GlobalizedText message="common.lbl.cancelMulti" />
                                                </a>
                                            </props.Div>
                                        </props.Col>
                                    </props.Row>

                                </>}
                                {formProps.values.filterBy === config.byStatus && <>
                                    <props.SelectControl name="statusType" label="ib.lbl.st" onChange={(e: any) => { statusTypeChangeHandler(e, formProps.values, formProps.setFieldValue) }} defaultOption={'common.lbl.all'} options={statusTypeOptionList.current} />
                                    {formProps.values.statusType === "active" &&
                                        <props.SelectControl name="activeStatus" label="common.lbl.status" options={activeOptionList.current} />
                                    }
                                    {formProps.values.statusType === "inactive" &&
                                        <props.SelectControl name="inActiveStatus" label="common.lbl.status" options={inactiveOptionList.current} />
                                    }
                                </>}
                                <props.Button type="submit" style={formProps.values.filterBy !== config.byAll ? undefined : { marginTop: "20px" }}><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                            </props.Col>
                        </props.Row>
                    </props.Div>
                    {/* Modal for selecting multiple agents_dr */}
                    <props.Modal title="ib.lbl.diatitle" size='large' id="selectMultipleAgents_dr" footer={() => {
                        return <>
                            <props.Button onClick={() => { saveAgentsToOption() }} disabled={disableSaveBtn}><GlobalizedText message="common.lbl.save" /></props.Button>
                            <props.Button onClick={() => { closeMultipleAgentsDialog() }} ><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }} >
                        <props.Row>
                            <props.Col xs="12" sm="12" md="12" >
                                <props.Information message="ib.lbl.dia.h"></props.Information>
                            </props.Col>
                            <props.Col xs="8" sm="5" md="5">
                                <props.TextControl id="dialogName" name="dialogName" onChange={(e: any) => { validateMultiBtn(e) }} label="common.lbl.jumpTo" placeholder="common.msg.typeAgentNameOrNumber"  ></props.TextControl>
                            </props.Col>
                            <props.Col xs="4" sm="7" md="7">
                                <props.Button style={{ marginTop: 19 }} disabled={multiBtn} onClick={() => { searchFromAgentListTable(formProps.values.dialogName) }}> <GlobalizedText message="common.lbl.retrieve" /></props.Button>
                            </props.Col>
                            <props.Col xs="12">
                                <br /><props.LinkButton onClick={() => { searchFromAgentListTable('') }}><GlobalizedText message='common.lbl.showall' /></props.LinkButton>
                            </props.Col>
                        </props.Row>
                        <props.Div style={{ maxHeight: 400, overflowY: "auto", overflowX: "hidden" }}>
                            <props.Table id="multipleAgentsTable" modal="selectMultipleAgents_dr" option={{ paging: false, scrollCollapse: true, order: [[3, 'asc']] }}
                                select={{
                                    type: 'multiple',
                                    selected: storedSelectedRowNumbers.current,
                                    onSelect: (e: any, selectedRows: Array<number>, unSelectedRows: Array<number>) => {
                                        handleOnselectEvent(selectedRows, unSelectedRows)
                                    }
                                }}
                                table={{
                                    columns: [
                                        { className: "gwp-dt-detail-control", sortable: false },
                                        { title: "common.lbl.name", name: "name", sortable: true },
                                        { title: "common.lbl.agentNumber", name: "agentNumber", sortable: true },
                                        { title: "common.lbl.streetAddress", name: "streetAddress", sortable: true },
                                        { title: "common.lbl.city", name: "city", sortable: true },
                                        { title: "common.lbl.stateCountry", name: "stateCountry", sortable: true }
                                    ],
                                    data: nbTab.current === "myApp" ? myAgentListTable : drAgentListTable
                                }}></props.Table>
                        </props.Div>
                    </props.Modal>
                </Form>
            }}
        </Formik>
        <props.HR />
        <props.Row>
            <props.Col xs="12">
                <props.Div className="gwp-innertitle  gwp-bold">
                    <GlobalizedText message="common.nbapplications" />
                </props.Div>
            </props.Col>
            <props.Col xs="12">
                <tableObj.Component />
            </props.Col>
        </props.Row>
    </>
    function openMultipleAgentsDialog() {
        if (nbTab.current === 'drApp') {
            if (selectedDrAgentsList === null) {
                searchFromAgentListTable('')
                storedSelectedRowNumbers.current = []
            }
        } else if (nbTab.current === 'myApp') {
            if (selectedMyAgentsList === null) {
                searchFromAgentListTable('')
                storedSelectedRowNumbers.current = []
            }
        }
        props.showModal('#selectMultipleAgents_dr');
        myAppForm.current.setFieldValue('dialogName', '')
        if (storedSelectedRowNumbers.current.length > 0) {
            setdisableSaveBtn(false);
        } else {
            setdisableSaveBtn(true);
        }
    }
    function closeMultipleAgentsDialog() {
        if (nbTab.current === 'drApp') {
            if (selectedDrAgentsList === null) {
                storedDrSelectedAgents.current.forEach(rowData => rowData.checked = false)
                setDrAgentListTable([])
            }
        } else if (nbTab.current === 'myApp') {
            if (selectedMyAgentsList === null) {
                storedMySelectedAgents.current.forEach(rowData => rowData.checked = false)
                setMyAgentListTable([])
            }
        }
        props.closeModal('#selectMultipleAgents_dr')
    }
    function searchFromAgentListTable(value: string) {
        storedSelectedRowNumbers.current = []
        dataObj.current = []
        if (nbTab.current === "drApp") {
            if (!isEmptyStr(value)) {
                for (let i = 0; i < storedDrSelectedAgents.current.length; i++) {
                    let tmp1 = storedDrSelectedAgents.current[i].agentNumber.toLowerCase()
                    let tmp2 = storedDrSelectedAgents.current[i].name.toLowerCase()
                    if (tmp1.indexOf(value.toLowerCase()) !== -1) {
                        let tmp3 = {
                            agentNumber: storedDrSelectedAgents.current[i].agentNumber,
                            city: storedDrSelectedAgents.current[i].city,
                            companyCode: storedDrSelectedAgents.current[i].companyCode,
                            name: storedDrSelectedAgents.current[i].name,
                            stateCountry: storedDrSelectedAgents.current[i].stateCountry,
                            streetAddress: storedDrSelectedAgents.current[i].streetAddress,
                            checked: storedDrSelectedAgents.current[i].checked
                        }
                        dataObj.current.push(tmp3)
                        // tmpArr1.push(storedDrSelectedAgents.current[i])
                    } else if (tmp2.indexOf(value.toLowerCase()) !== -1) {
                        let tmp4 = {
                            agentNumber: storedDrSelectedAgents.current[i].agentNumber,
                            city: storedDrSelectedAgents.current[i].city,
                            companyCode: storedDrSelectedAgents.current[i].companyCode,
                            name: storedDrSelectedAgents.current[i].name,
                            stateCountry: storedDrSelectedAgents.current[i].stateCountry,
                            streetAddress: storedDrSelectedAgents.current[i].streetAddress,
                            checked: storedDrSelectedAgents.current[i].checked
                        }
                        dataObj.current.push(tmp4)
                    }
                }
                for (let i = 0; i < dataObj.current.length; i++) {
                    if (dataObj.current[i].checked === true) {
                        storedSelectedRowNumbers.current.push(i)
                    }
                }
                setDrAgentListTable(dataObj.current)
            } else if (isEmptyStr(value)) {
                storedDrSelectedAgents.current.forEach((rowData: MultipleAgentsInfo, index: number) => {
                    let tmp = {
                        agentNumber: rowData.agentNumber,
                        city: rowData.city,
                        companyCode: rowData.companyCode,
                        name: rowData.name,
                        stateCountry: rowData.stateCountry,
                        streetAddress: rowData.streetAddress,
                        checked: rowData.checked
                    }
                    dataObj.current.push(tmp)
                    if (rowData.checked === true) {
                        storedSelectedRowNumbers.current.push(index)
                    }
                })
                setDrAgentListTable(storedDrSelectedAgents.current)
            }
        } else if (nbTab.current === "myApp") {
            if (!isEmptyStr(value)) {
                for (let i = 0; i < storedMySelectedAgents.current.length; i++) {
                    let tmp1 = storedMySelectedAgents.current[i].agentNumber.toLowerCase()
                    let tmp2 = storedMySelectedAgents.current[i].name.toLowerCase()
                    if (tmp1.indexOf(value.toLowerCase()) !== -1) {
                        let tmp3 = {
                            agentNumber: storedMySelectedAgents.current[i].agentNumber,
                            city: storedMySelectedAgents.current[i].city,
                            companyCode: storedMySelectedAgents.current[i].companyCode,
                            name: storedMySelectedAgents.current[i].name,
                            stateCountry: storedMySelectedAgents.current[i].stateCountry,
                            streetAddress: storedMySelectedAgents.current[i].streetAddress,
                            checked: storedMySelectedAgents.current[i].checked
                        }
                        dataObj.current.push(tmp3)
                    } else if (tmp2.indexOf(value.toLowerCase()) !== -1) {
                        let tmp4 = {
                            agentNumber: storedMySelectedAgents.current[i].agentNumber,
                            city: storedMySelectedAgents.current[i].city,
                            companyCode: storedMySelectedAgents.current[i].companyCode,
                            name: storedMySelectedAgents.current[i].name,
                            stateCountry: storedMySelectedAgents.current[i].stateCountry,
                            streetAddress: storedMySelectedAgents.current[i].streetAddress,
                            checked: storedMySelectedAgents.current[i].checked
                        }
                        dataObj.current.push(tmp4)
                    }
                }
                for (let i = 0; i < dataObj.current.length; i++) {
                    if (dataObj.current[i].checked === true) {
                        storedSelectedRowNumbers.current.push(i)
                    }
                }
                setMyAgentListTable(dataObj.current)
            } else if (isEmptyStr(value)) {
                storedMySelectedAgents.current.forEach((rowData: MultipleAgentsInfo, index: number) => {
                    let tmp = {
                        agentNumber: rowData.agentNumber,
                        city: rowData.city,
                        companyCode: rowData.companyCode,
                        name: rowData.name,
                        stateCountry: rowData.stateCountry,
                        streetAddress: rowData.streetAddress,
                        checked: rowData.checked
                    }
                    dataObj.current.push(tmp)
                    if (rowData.checked === true) {
                        storedSelectedRowNumbers.current.push(index)
                    }
                })
                setMyAgentListTable(storedMySelectedAgents.current)
            }
        }
    }
    //Handle Onselected
    function handleOnselectEvent(selectedRows: Array<number>, unSelectedRows: Array<number>) {
        if (nbTab.current === 'drApp') {
            if (selectedRows.length === 0) {
                //All rows unselected
                dataObj.current.forEach(rowData => rowData.checked = false)
            } else if (unSelectedRows.length === 0) {
                // All rows selected
                dataObj.current.forEach(rowData => rowData.checked = true)
            } else {
                // some rows selected, and some unselected
                selectedRows.forEach((row: number) => {
                    if (dataObj.current[row] !== undefined && dataObj.current[row].checked !== undefined) {
                        dataObj.current[row].checked = true
                    }
                })
                unSelectedRows.forEach((row: number) => {
                    if (dataObj.current[row] !== undefined && dataObj.current[row].checked !== undefined) {
                        dataObj.current[row].checked = false
                    }
                })
            }
            for (let selectedAgentRow of storedDrSelectedAgents.current) {
                for (let dataObjRow of dataObj.current) {
                    if (selectedAgentRow.agentNumber === dataObjRow.agentNumber && dataObjRow.checked === true) {
                        selectedAgentRow.checked = true
                    }
                    if (selectedAgentRow.agentNumber === dataObjRow.agentNumber && dataObjRow.checked === false) {
                        selectedAgentRow.checked = false
                    }
                }
            }

            if (selectedRows.length > 0) {
                setdisableSaveBtn(false);
            } else {
                setdisableSaveBtn(true);
            }
        } else { // nbTab === 'myApp'
            if (selectedRows.length === 0) {
                //All rows unselected
                dataObj.current.forEach(rowData => rowData.checked = false)
            } else if (unSelectedRows.length === 0) {
                // All rows selected
                dataObj.current.forEach(rowData => rowData.checked = true)
            } else {
                // some rows selected, and some unselected
                selectedRows.forEach((row: number) => {
                    if (dataObj.current[row] !== undefined && dataObj.current[row].checked !== undefined) {
                        dataObj.current[row].checked = true
                    }
                })
                unSelectedRows.forEach((row: number) => {
                    if (dataObj.current[row] !== undefined && dataObj.current[row].checked !== undefined) {
                        dataObj.current[row].checked = false
                    }

                })
            }
            for (let i = 0; i < storedMySelectedAgents.current.length; i++) {
                for (let j = 0; j < dataObj.current.length; j++) {
                    if (storedMySelectedAgents.current[i].agentNumber === dataObj.current[j].agentNumber && dataObj.current[j].checked === true) {
                        storedMySelectedAgents.current[i].checked = true
                    }
                    if (storedMySelectedAgents.current[i].agentNumber === dataObj.current[j].agentNumber && dataObj.current[j].checked === false) {
                        storedMySelectedAgents.current[i].checked = false
                    }
                }
            }
            if (selectedRows.length > 0) {
                setdisableSaveBtn(false);
            } else {
                setdisableSaveBtn(true);
            }
        }
    }
    function cancelMultiAgents() {
        if (nbTab.current === "drApp") {
            setDrAgentListTable([])
            selectedAgentCount.current = 0
            hiddenDrCancelLink.current = true
            setShowSelectDPagent(true);
            setSelectedDrAgentsList(null);
            storedSelectedRowNumbers.current = []
            storedDrSelectedAgents.current.forEach(rowData => rowData.checked = false)
        } else {
            setMyAgentListTable([])
            selectedAgentCount.current = 0
            hiddenMyCancelLink.current = true
            setShowSelectMyAgent(true);
            setSelectedMyAgentsList(null);
            storedSelectedRowNumbers.current = []
            storedMySelectedAgents.current.forEach(rowData => rowData.checked = false)
        }
    }
    function saveAgentsToOption() {
        storedSelectedRowNumbers.current = []
        let tmp: any[] = [];
        if (nbTab.current === "drApp") {
            for (let i = 0; i < storedDrSelectedAgents.current.length; i++) {
                if (storedDrSelectedAgents.current[i].checked === true) {
                    tmp.push({ name: storedDrSelectedAgents.current[i].name + " - " + storedDrSelectedAgents.current[i].agentNumber, value: storedDrSelectedAgents.current[i].agentNumber + "|" + storedDrSelectedAgents.current[i].companyCode });
                    storedSelectedRowNumbers.current.push(i)
                }
            }
            myAppForm.current.setFieldValue('selectDPagent', '')
            setSelectedDrAgentsList(tmp);
            hiddenDrCancelLink.current = false
            selectedAgentCount.current = tmp.length
            setShowSelectDPagent(false);
        } else {
            for (let i = 0; i < storedMySelectedAgents.current.length; i++) {
                if (storedMySelectedAgents.current[i].checked === true) {
                    tmp.push({ name: storedMySelectedAgents.current[i].name + " - " + storedMySelectedAgents.current[i].agentNumber, value: storedMySelectedAgents.current[i].agentNumber + "|" + storedMySelectedAgents.current[i].companyCode });
                    storedSelectedRowNumbers.current.push(i)
                }
            }
            myAppForm.current.setFieldValue('agentNumber', '')
            setSelectedMyAgentsList(tmp);
            hiddenMyCancelLink.current = false
            selectedAgentCount.current = tmp.length
            setShowSelectMyAgent(false);
        }
        props.closeModal('#selectMultipleAgents_dr');
    }
    function validateMultiBtn(e: any) {
        if (e.target.value.trim() !== '') {
            setMultiBtn(false);
        }
        if (e.target.value.trim() === '') {
            setMultiBtn(true);
        }

    }
    // save data for current page
    function saveDataBeforeRedirect() {
        props.setInitParams({
            storedPage: 'newBusinessApplications',
            ...myAppForm.current.values,
            filterByOptions: filterByOptions.current,
            companyCodePre: companyCodePre,
            agentNumberPre: agentNumberPre,
            isFromAgentSearch: isFromAgentSearch,
            config: config,
            myAppAgentFilterBy: myAppAgentFilterBy.current,
            drAppAgentFilterBy: drAppAgentFilterBy.current,
            myAppAgentsList: myAppAgentsList.current,
            drAgentsList: drAgentsList.current,
            nbTable: nbTable,
            storedDrSelectedAgents: storedDrSelectedAgents.current,
            storedMySelectedAgents: storedMySelectedAgents.current,
            nbTab: nbTab.current,
            accounts: accounts,
            groupCompanyCode: groupCompanyCode,
            myAppGroupList: myAppGroupList,
            drAppGroupList: drAppGroupList,
            myAccountList: myAccountList,
            drAccountList: drAccountList,
            statusTypeOptionList: statusTypeOptionList.current,
            activeOptionList: activeOptionList.current,
            inactiveOptionList: inactiveOptionList.current,
            hiddenMemberID: hiddenMemberID.current,
            hiddenMyCancelLink: hiddenMyCancelLink.current,
            hiddenDrCancelLink: hiddenDrCancelLink.current,
            hiddenSelectMultipleAgtsLink4MyApp: hiddenSelectMultipleAgtsLink4MyApp.current,
            disableSaveBtn: disableSaveBtn,
            showSelectDPagent: showSelectDPagent,
            showSelectMyAgent: showSelectMyAgent,
            selectedDrAgentsList: selectedDrAgentsList,
            selectedMyAgentsList: selectedMyAgentsList,
            selectedAgentCount: selectedAgentCount.current,
            selectedAgentOption: selectedAgentOption.current,
            selectedAgentOptionTmp: selectedAgentOptionTmp.current,
            unSelectedAgentOptionTmp: unSelectedAgentOptionTmp.current,
            multiBtn: multiBtn,
            selectedRowNumbers: storedSelectedRowNumbers.current,
            dataObj: dataObj.current,
            currentTablepage: tableObj.page()
        });
    }
});