import React, { useEffect } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, formatDate, formatNumber } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/makepaymentresult.css';
export const MakePaymentSuccessComponent = withView((props: ViewComponentProps) => {
    const companyCode = props.getParam("companyCode");
    const policyNumber = props.getParam("policyNumber");
    const transactionID = props.getParam("transactionID");
    const paymentSelection = props.getParam("paymentSelection");
    const creditCardNumber = props.getParam("creditCardNumber");
    const refID = props.getParam("refID");
    const amount = props.getParam("amount");

    useEffect(() => {
        ajax({
            url: '/api/mockPayment/paymentSuccess',
            method: 'POST',
            params: {
                companyCode: companyCode,
                policyNumber: policyNumber,
                transactionID: transactionID,
                paymentSelection: paymentSelection,
                creditCardNumber: creditCardNumber,
                refID: refID,
                amount: amount
            }
        });
    })

    return <div id="paymentResult">
        <props.Panel>
            <props.PanelBody >
                <div className="container">
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <div role="alert" className="alert alert-success gwp-page-message messageBanner">
                                <span aria-hidden="true" className="glyphicon glyphicon-ok messageIcon"></span>
                                <GlobalizedText message="common.msg.complete.thank.you.for.payment" />
                                <span className="printPlace">
                                    <props.Span className="gwp-icon gwp-icon-print gwp-icon-sm " ></props.Span>
                                    <props.LinkButton printVisible={false} onClick={() => props.print({ size: 'A5', orientation: 'landscape' })}><GlobalizedText message="common.print" /></props.LinkButton>
                                </span>
                            </div>
                        </props.Col>
                    </props.Row>
                    <props.HR className="hrstyle" />
                    <p id="currencyInfo" className="currencyInfo"></p>
                    <props.Information message="common.msg.payment.infor.show.below" showExclamationSign={false} />
                    <props.Row className="payout-information-body">
                        {/* <GlobalizedText className="gwp-page-title" message="common.lbl.payment.information" /> */}
                        <props.PageTitle><GlobalizedText message="common.lbl.payment.information" /></props.PageTitle>
                        <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                            <props.DLI title="common.lbl.confir.num" desc={() => {
                                return <>{transactionID}</>
                            }} />
                            <props.DLI title="common.lbl.policyNumber" desc={() => {
                                return <>{policyNumber}</>
                            }} />
                            <props.DLI title="common.lbl.payment.selection" desc={decodeURIComponent(paymentSelection)} />
                            <props.DLI title="common.lbl.payment.amount" desc={formatNumber(parseFloat(amount))} />
                            <props.DLI title="common.lbl.paymentMethod" desc={creditCardNumber ? 'Credit Card' : 'Bank Account'} />
                            <props.DLI title="common.lbl.posting.date" desc={formatDate(props.getDateFormat(), new Date())} />
                        </props.DL>
                        <div className="modal-footer2">
                            <props.Button className="btn btn-primary gwp-btn " id="close" onClick={() => { window.parent.postMessage('paymentSuccess', '*'); }} > <GlobalizedText message="common.lbl.close" /></props.Button>
                        </div>
                    </props.Row>
                </div>
            </props.PanelBody>
        </props.Panel >
    </div >
});