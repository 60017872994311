import React from 'react';
import GlobalizedText from '../shared/globalization';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/creditcardresult.css';
import { translateCreditCardType } from './wallet';
export const AddCreditCardSuccessComponent = withView((props: ViewComponentProps) => {
    const policyNumber = props.getParam("policyNumber");
    const transactionID = props.getParam("transactionID");
    const creditCardNumber = props.getParam("creditCardNumber");
    let ed = props.getParam("expirationDate").split("/");
    const expirationDate = ed[1] + "-" + ed[0];
    const creditCardType = props.getParam("creditCardType");
    const profileID = props.getParam("profileID");

    return <div id="paymentResult">
        <props.Panel>
            <props.PanelBody >
                <div className="container">
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <div role="alert" className="alert alert-success gwp-page-message messageBanner">
                                <span aria-hidden="true" className="glyphicon glyphicon-ok messageIcon"></span>
                                <GlobalizedText message="common.msg.credit.card.accept" />
                                <span className="printPlace">
                                    <props.Span className="gwp-icon gwp-icon-print gwp-icon-sm " ></props.Span>
                                    <props.LinkButton printVisible={false} onClick={() => props.print({ size: 'A5', orientation: 'landscape' })}><GlobalizedText message="common.print" /></props.LinkButton>
                                </span>
                            </div>
                        </props.Col>
                    </props.Row>
                    <props.HR className="hrstyle" />
                    <p id="currencyInfo" className="currencyInfo"></p>
                    <props.Information message="common.msg.credit.card.update.success" showExclamationSign={false} />
                    <props.Row className="payout-information-body">
                        {/* <GlobalizedText className="gwp-page-title" message="common.lbl.payment.information" /> */}
                        <props.PageTitle><GlobalizedText message="common.lbl.payment.information" /></props.PageTitle>
                        <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                            <props.DLI title="common.lbl.confir.num" desc={() => {
                                return <>{transactionID}</>
                            }} />
                            <props.DLI title="common.lbl.policyNumber" desc={() => {
                                return <>{policyNumber}</>
                            }} />
                            <props.DLI title="common.lbl.cred.card.type" desc={translateCreditCardType(creditCardType)} />
                            <props.DLI title="common.lbl.creditcardnumber" desc={'xxxxxxxxxxxx' + creditCardNumber} />
                            <props.DLI title="common.lbl.expirationDate" desc={props.getParam("expirationDate")} />
                        </props.DL>
                        <div className="modal-footer2">
                            <props.Button className="btn btn-primary gwp-btn " id="close" onClick={() => { window.parent.postMessage(`addCreditCardSuccess,${creditCardNumber},${creditCardType},${props.getParam("expirationDate")},${expirationDate},${transactionID}`, '*'); }} > <GlobalizedText message="common.lbl.close" /></props.Button>
                        </div>
                    </props.Row>
                </div>
            </props.PanelBody>
        </props.Panel >
    </div >
});