import React, { useEffect, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import './css/dashboard.css';
import { ajax, isEmptyObject, parseBoolean } from '../shared/utils';
import { Form, Formik } from 'formik';
import GlobalizedText from '../shared/globalization';
import { Config } from '../config/config';



let w: any = window;
export const win = w;
export const $: any = w.$;



export const DashboardComponent = withView((props: ViewComponentProps) => {

    const [config, setConfig] = useState<Array<any>>();
    const [suffixParams, setSuffixParams] = useState<any>();
    const [url, setUrl] = useState<string>("");
    const companyCode: string = props.getParam("companyCode");
    const agentNumber: string = props.getParam("agentNumber");
    const policyNumber: string = props.getParam("policyNumber");
    const directReports: boolean = parseBoolean(props.getParam("directReports"));
    const dashboardID: string = props.getParam("dashboardID");
    const dashboardName: string = props.getParam("dashboardName");



    useEffect(() => {
        if (config === undefined) {
            console.log("1");
            ajax({
                url: '/api/quickSight/getDashboardByID',
                params: {
                    "dashboardID": dashboardID
                },
                success: (res: any) => {
                    setConfig(res);
                    ajax({
                        url: '/api/quickSight/fetchDashboard',
                        params: {
                            "dashboardID": dashboardID,
                            "agentNumber": agentNumber,
                            "companyCode": companyCode,
                            "directReports": directReports,
                            "policyNumber": policyNumber
                        },
                        success: (resSuffix: any) => {
                            setSuffixParams(resSuffix);
                            ajax({
                                url: '/api/quickSight/getDashboardEmbedUrl',
                                params: {
                                    "dashboardId": dashboardID
                                },
                                success: (resURL: any) => {
                                    setUrl(resURL);
                                }
                            });
                        }
                    });
                }
            });
        }
    }, [config]);
    if (config === undefined) {
        return <></>;
    }


    function iframeLoad(obj: any) {
        if ($('#awsdbd').length == 0) {
            var iframe = $('<iframe></iframe>')
            iframe.attr("id", "awsdbd");
            iframe.attr("src", url?.split("?")[0] + suffixParams.defaultSuffix);
            //alert(url.split("?")[0] + suffixParams.defaultSuffix);
            iframe.attr("frameBorder", 0);
            iframe.attr("height", window.screen.availHeight);
            iframe.attr("width", "100%");
            iframe.attr("scrolling", "no");
            $('#AwsDashboardDiv').append(iframe);
        }
    }

    props.setTitle(decodeURIComponent(config[0].dashboardName));
    return <>
        <Formik initialValues={{
            url: ''
        }}
            {...props.generateNoramlFormikProps()}
            onSubmit={(values: any) => {
                // console.log(values);
                setUrl(values.url);
            }}
        >
            {formProps =>
                <Form>
                    <props.Row>
                        <props.Col xs="12" sm="12" md="12">
                            <div className="report" id="AwsDashboardDiv">
                                <iframe className="gwp-hidden" id="awsDashboardFrame" width="100%" scrolling="no" height={window.screen.availHeight} src={url} onLoad={(e: any) => iframeLoad(e)}></iframe>
                            </div>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
    </>
});
