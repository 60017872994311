import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { HelpBanner } from '../shared/helpbanner';
import * as yup from 'yup';
import { Message, useMessage } from '../shared/message';
import { ajax, isEmptyStr, movePageToTop, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import { AgentContactInfo } from './agentcontactinfo';

type Level = 'myPI' | 'drPI'

interface SelectOption {
    name: string,
    value: string
}

export const AgentPersonalInformationComponent = withView((props: ViewComponentProps) => {

    const { messageService, validateProps } = useMessage("agentInformation");
    const [message, SetMessage] = useState<any | null>(null);

    const [config, setConfig] = useState<any | null>(null)
    const [agentPersonalInfo, setAgentPersonalInfo] = useState<any | null>(null);
    const [drAgentPersonalInfo, setDrAgentPersonalInfo] = useState<any | null>(null);
    const [isViewAGTPersonalInfo, setIsViewAGTPersonalInfo] = useState<boolean>(true);
    const [personalInfoTab, setPersonalInfoTab] = useState<Level>('myPI');

    //For Agent
    let companyCode = props.getQueryParam("companyCode");
    let clientNumber = props.getQueryParam("clientNumber");
    let agentNumber = props.getQueryParam("agentNumber");

    // For Agent, design from which page
    let fromPage = props.getQueryParam("fromPage");
    const [myAgentList, setMyAgentList] = useState<Array<SelectOption>>([])
    const [drAgentList, setDrAgentList] = useState<Array<SelectOption>>([])

    const drAGTPersonalInfoForm = useRef<any>();

    const [returnAgentInfo, setReturnAgentInfo] = useState<any | null>(null);

    const [hasSingleAgent, setHasSingleAgent] = useState<boolean | null>(null);
    const [isAgencyLogin, setIsAgencyLogin] = useState<boolean | null>(false);
    const [isUpdateAble4DrAgent, setIsUpdateAble4DrAgent] = useState<boolean | null>(null);

    useEffect(() => {
        if (config === null) {
            fetchAgentPersonalInformation()
        } else {
            SetMessage(null)
        }
    }, [config])

    useEffect(() => {
        if (hasSingleAgent !== null && hasSingleAgent === true) {
            let tmpCompanyCode = config.agents[0].value.split("-")[1]
            let tmpAgentNumber = config.agents[0].value.split("-")[0]
            fetchAgentPersonalInformationFromCallBack(tmpCompanyCode, tmpAgentNumber)
        }
    }, [hasSingleAgent])

    useEffect(() => {
        if (isAgencyLogin === true) {
            fetchAgentPersonalInformationFromCallBack(config.companyCode, config.agentNumber)
        }
    }, [isAgencyLogin])

    useEffect(() => {
        if (message !== null && (fromPage === 'from_agentsearch' || fromPage === 'from_directdeposit')) {
            if (personalInfoTab === 'myPI') {
                fetchAgentPersonalInformation()
            } else {
                fetchDrAgentPersonalInformation(drAGTPersonalInfoForm.current.values.drAgent)
            }
        } else if (message !== null && fromPage === undefined) {
            if (personalInfoTab === 'myPI') {
                if (returnAgentInfo !== null) {
                    let tmpCompanyCode = returnAgentInfo.split("-")[1]
                    let tmpAgentNumber = returnAgentInfo.split("-")[0]
                    fetchAgentPersonalInformationFromCallBack(tmpCompanyCode, tmpAgentNumber)
                } else {
                    fetchAgentPersonalInformationFromCallBack(config.companyCode, config.agentNumber)
                }
            } else {
                fetchDrAgentPersonalInformation(drAGTPersonalInfoForm.current.values.drAgent)
            }

        }
    }, [message])

    useEffect(() => {
        if (returnAgentInfo !== null && !isEmptyStr(returnAgentInfo)) {
            let tmpCompanyCode = returnAgentInfo.split("-")[1]
            let tmpAgentNumber = returnAgentInfo.split("-")[0]
            if (personalInfoTab === 'myPI') {
                fetchAgentPersonalInformationFromCallBack(tmpCompanyCode, tmpAgentNumber)
            }
            // else {
            //     fetchDrAgentPersonalInformation(drAGTPersonalInfoForm.current.values.drAgent)
            // }
        }
    }, [returnAgentInfo])

    useEffect(() => {
        SetMessage(null)
    }, [drAgentPersonalInfo])

    function fetchAgentPersonalInformation() {
        movePageToTop()
        if (fromPage === 'from_agentsearch' || fromPage === 'from_directdeposit') {
            ajax({
                url: '/api/personalInfo/view',
                params: {
                    personalInfoType: 3,
                    companyCode: companyCode,
                    clientNumber: clientNumber,
                    agentNumber: agentNumber,
                    loadDirectReportAgents: true
                },
                showMask: true,
                success: (res: any) => {
                    setConfig(res)
                    setAgentPersonalInfo(res.person)
                    let tmpDrAgents = {
                        name: "common.lbl.pleaseselect",
                        value: ""
                    }
                    setDrAgentList([tmpDrAgents, ...res.reportAgents])
                }
            })
        } else {
            ajax({
                url: '/api/personalInfo/view',
                params: {
                    personalInfoType: 3
                },
                showMask: true,
                success: (res: any) => {
                    if (res.agentNumber !== undefined) { //Agency login
                        setConfig(res)
                        setIsAgencyLogin(true)
                    } else {
                        if (res.agents.length > 1) {
                            res.person.streetAddresses = []
                            res.person.city = ''
                            res.person.stateName = ''
                            res.person.countryCode = ''
                            res.person.zipCode = ''
                            res.person.cellPhone = ''
                            res.person.homePhone = ''
                            res.person.businessPhone = ''
                            res.person.facsimilePhone = ''
                            res.person.contactMethod = ''
                            res.person.emails = []
                            setConfig(res)
                            setAgentPersonalInfo(res.person)
                            let tmpDrAgents = {
                                name: "common.lbl.pleaseselect",
                                value: ""
                            }
                            setMyAgentList([tmpDrAgents, ...res.agents])
                            setHasSingleAgent(false)
                        }
                        else {
                            setConfig(res)
                            setHasSingleAgent(true)
                        }
                    }
                }
            })
        }
    }

    function fetchAgentPersonalInformationFromCallBack(returnedCompanyCode: string, returnedAgentNumber: string) {
        ajax({
            url: '/api/personalInfo/view',
            params: {
                personalInfoType: 3,
                companyCode: returnedCompanyCode,
                clientNumber: config.clientNumber,
                agentNumber: returnedAgentNumber,
                loadDirectReportAgents: true
            },
            showMask: true,
            success: (res: any) => {
                setConfig(res)
                setAgentPersonalInfo(res.person)
                if (res.hasReportAgent === true) {
                    let tmpDrAgents = {
                        name: "common.lbl.pleaseselect",
                        value: ""
                    }
                    setDrAgentList([tmpDrAgents, ...res.reportAgents])
                } else {
                    setDrAgentList([])
                }
            }
        })
    }

    const defaultInitialValues = {
        selectedDrAgent: ''
    }
    const drAgentformSubmit = (values: any): void => {
        fetchDrAgentPersonalInformation(values.drAgent)
    }
    const drAgentValidate = yup.object().shape({
        drAgent: YupSchema.newBusinessApplication.dpAgent
    });
    const formValidate = (values: any): any => {
        if (personalInfoTab === 'drPI') {
            return validate(drAgentValidate, values, props);
        }
    }

    function fetchDrAgentPersonalInformation(selectedDrAgent: any) {
        movePageToTop()
        let selectedAgentNumberTmp = selectedDrAgent.split('-')[0]
        let selectedCompanyCodeTmp = selectedDrAgent.split('-')[1]
        ajax({
            url: '/api/personalInfo/view',
            params: {
                personalInfoType: 3,
                companyCode: companyCode,
                clientNumber: clientNumber,
                agentNumber: agentNumber,
                directReportTab: true,
                selAgentNumber: selectedAgentNumberTmp,
                selCompanyCode: selectedCompanyCodeTmp
            },
            showMask: true,
            success: (res: any) => {
                setIsUpdateAble4DrAgent(res.updateAble)
                setDrAgentPersonalInfo(res.person)
                setIsViewAGTPersonalInfo(true)
            }
        })
    }

    function clearSuccessMessages() {
        SetMessage(null)
        validateProps.clearMessage()
    }

    function changeTab(tabName: Level) {
        clearSuccessMessages()
        setPersonalInfoTab(tabName)
        setDrAgentPersonalInfo(null)
        setAgentPersonalInfo(null)
        drAGTPersonalInfoForm.current.setFieldValue('drAgent', '')
        // setReturnAgentInfo(null)
        props.clearMessage()
        if (fromPage !== undefined && tabName === 'myPI') {
            fetchAgentPersonalInformation()
            setIsViewAGTPersonalInfo(true)
        } else if (fromPage !== undefined && tabName === 'drPI') {
            setIsViewAGTPersonalInfo(false)
        } else if (fromPage === undefined && tabName === 'myPI') {
            fetchAgentPersonalInformationFromCallBack(config.companyCode, config.agentNumber)
            setIsViewAGTPersonalInfo(true)
        } else {
            setIsViewAGTPersonalInfo(false)
        }
    }

    return <props.Div id="agentPersonalInfo">
        <props.Div id="minSrcHelp" className="minHide">
            <HelpBanner {...props}></HelpBanner>
        </props.Div>
        <props.Div>
            <ul id="personalInfoTabs" className="nav nav-tabs" role="tablist">
                <li role="presentation" className={personalInfoTab === 'myPI' ? 'active' : ''}>
                    <a href="#myPI" aria-controls="myPI" role="tab" data-toggle="tab" onClick={() => { changeTab('myPI') }} ><GlobalizedText message="pi.title.myp" /></a>
                </li>
                {(config !== null && config.hasReportAgent === true && config.reportAgents !== undefined) &&
                    <li role="presentation" className={personalInfoTab === 'drPI' ? 'active' : ''} >
                        <a href="#drPI" aria-controls="drPI" role="tab" data-toggle="tab" onClick={() => { changeTab('drPI') }} ><GlobalizedText message="pi.title.dr" /></a>
                    </li>
                }
            </ul>
        </props.Div>
        <props.Div className="tab-content" >
            {/* Dr App */}
            <props.Div role="tabpanel" className={`tab-pane fade in ${personalInfoTab === 'drPI' ? 'active' : ''}`} id="drPI">
                <props.Row>
                    <Formik
                        initialValues={defaultInitialValues}
                        enableReinitialize={true}
                        validate={formValidate}
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={drAgentformSubmit}
                    >
                        {formProps => {
                            drAGTPersonalInfoForm.current = formProps
                            return <Form id="newBusinessApplications">
                                <props.Col md="3" sm="12" xs="12">
                                    <props.SelectControl name="drAgent" label='common.lbl.directReport' options={drAgentList} sort={false} defaultOption={'common.lbl.pleaseselect'}
                                        onChange={() => {
                                            setDrAgentPersonalInfo(null)
                                            setIsViewAGTPersonalInfo(false)
                                            clearSuccessMessages()
                                        }}
                                    ></props.SelectControl>
                                </props.Col>
                                <props.Col md="3" sm="12" xs="12">
                                    <props.Button type="submit" style={{ marginTop: '18px' }}><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                                </props.Col>
                            </Form>
                        }}
                    </Formik>
                </props.Row>
                <props.HR />
            </props.Div>
        </props.Div>
        <props.Div role="tabpanel" className="tab-pane fade in active" id="myPI">
            {((agentPersonalInfo !== null || drAgentPersonalInfo !== null) && isViewAGTPersonalInfo === true) && <>
                <props.Row>
                    <props.Col md="9" sm="12" xs="12">
                        <props.Row>
                            <props.Col md="8" sm="6" xs="12">
                                <props.DL>
                                    <props.DLI title="common.lbl.name" desc={() => {
                                        if (personalInfoTab === 'myPI') {
                                            return agentPersonalInfo !== null ? agentPersonalInfo.name.replaceAll("<wbr/>", "") : ''
                                        } else {
                                            return drAgentPersonalInfo !== null ? drAgentPersonalInfo.name.replaceAll("<wbr/>", "") : ''
                                        }
                                    }}></props.DLI>
                                    <props.DLI title="pi.relation" desc={() => {
                                        if (personalInfoTab === 'myPI') {
                                            return agentPersonalInfo !== null ? agentPersonalInfo.relationship : ''
                                        } else {
                                            return drAgentPersonalInfo !== null ? drAgentPersonalInfo.relationship : ''
                                        }
                                    }}></props.DLI>
                                    <props.DLI title="common.lbl.govID" desc={() => {
                                        if (personalInfoTab === 'myPI') {
                                            return agentPersonalInfo !== null ? agentPersonalInfo.govtID : ''
                                        } else {
                                            return drAgentPersonalInfo !== null ? drAgentPersonalInfo.govtID : ''
                                        }
                                    }}></props.DLI>
                                </props.DL>
                            </props.Col>
                            <props.Col md="4" sm="6" xs="12">
                                <props.DL>
                                    <props.DLI condition={(agentPersonalInfo !== null && agentPersonalInfo.birthDate !== undefined) || (drAgentPersonalInfo !== null && drAgentPersonalInfo.birthDate !== undefined)} title="common.lbl.dob" desc={() => {
                                        if (personalInfoTab === 'myPI') {
                                            return (agentPersonalInfo !== null && agentPersonalInfo.birthDate !== undefined) ? agentPersonalInfo.birthDate : ''
                                        } else {
                                            return drAgentPersonalInfo !== null ? drAgentPersonalInfo.birthDate : ''
                                        }
                                    }}></props.DLI>
                                    <props.DLI condition={(agentPersonalInfo !== null && agentPersonalInfo.gender !== undefined && !isEmptyStr(agentPersonalInfo.gender)) || (drAgentPersonalInfo !== null && drAgentPersonalInfo.gender !== undefined && !isEmptyStr(drAgentPersonalInfo.gender))} title="pi.gender" desc={() => {
                                        if (personalInfoTab === 'myPI') {
                                            return agentPersonalInfo !== null ? agentPersonalInfo.gender : ''
                                        } else {
                                            return drAgentPersonalInfo !== null ? drAgentPersonalInfo.gender : ''
                                        }
                                    }}></props.DLI>
                                </props.DL>
                            </props.Col>
                        </props.Row>
                    </props.Col>
                    <props.Col md="3" sm="12" xs="12">
                        <props.Div id="maxSrcHelpBanner" className="maxHide">
                            <HelpBanner {...props}></HelpBanner>
                        </props.Div>
                    </props.Col>

                </props.Row>
            </>}
        </props.Div>
        <props.Row>
            <Message messageService={messageService}></Message>
        </props.Row>
        {((fromPage === 'from_agentsearch' || fromPage === 'from_directdeposit') && personalInfoTab === 'myPI' && agentPersonalInfo !== null) && <>
            <AgentContactInfo {...props} agentPersonalInfo={agentPersonalInfo} agentNumber={config.agentNumber} states={config.states} contactMethods={config.contactMethods} companyCode={companyCode} personalInfoType={3} updateMode={false} updateAble={config.updateAble} isAgentLoginDirectly={false} callBackFuntion={(returnedMsg: any) => {
                SetMessage(returnedMsg)
                // validateProps.showMessage('success', message)
            }} />
        </>}
        {((fromPage === 'from_agentsearch' || fromPage === 'from_directdeposit') && personalInfoTab === 'drPI' && drAgentPersonalInfo !== null && isUpdateAble4DrAgent !== null) && <>
            <AgentContactInfo {...props} agentPersonalInfo={drAgentPersonalInfo} agentNumber={drAGTPersonalInfoForm.current.values.drAgent.split("-")[0]} states={config.states} contactMethods={config.contactMethods} companyCode={drAGTPersonalInfoForm.current.values.drAgent.split("-")[1]} personalInfoType={3} updateMode={false} updateAble={isUpdateAble4DrAgent} isAgentLoginDirectly={false} isDrAgentSelected={true} callBackFuntion={(returnedMsg: any) => {
                SetMessage(returnedMsg)
            }} />
        </>}
        {(fromPage === undefined && personalInfoTab === 'myPI' && agentPersonalInfo !== null && hasSingleAgent === true) && <>
            <AgentContactInfo {...props} agentPersonalInfo={agentPersonalInfo} agentNumber={config.agentNumber} states={config.states} contactMethods={config.contactMethods} companyCode={config.companyCode} personalInfoType={3} updateMode={false} updateAble={config.updateAble} isAgentLoginDirectly={false} callBackFuntion={(returnedMsg: any) => {
                SetMessage(returnedMsg)
            }} />
        </>}
        {(fromPage === undefined && personalInfoTab === 'myPI' && agentPersonalInfo !== null && hasSingleAgent === false) && <>
            <AgentContactInfo {...props} agentPersonalInfo={agentPersonalInfo} agentNumber={config.agentNumber === undefined ? '' : config.agentNumber} states={config.states} contactMethods={config.contactMethods} companyCode={config.companyCode === undefined ? '' : config.companyCode} personalInfoType={3} updateMode={false} updateAble={config.updateAble} isAgentLoginDirectly={true} myAgentLists={myAgentList} callBackFuntion={(returnedMsg: any) => {
                SetMessage(returnedMsg)
            }}
                returnAgentNumberAndCompany={(agentNumberAndCompany: string) => {
                    setReturnAgentInfo(agentNumberAndCompany)
                }}
            />
        </>}
        {(fromPage === undefined && personalInfoTab === 'myPI' && agentPersonalInfo !== null && isAgencyLogin === true) && <>
            <AgentContactInfo {...props} agentPersonalInfo={agentPersonalInfo} agentNumber={config.agentNumber} states={config.states} contactMethods={config.contactMethods} companyCode={config.companyCode} personalInfoType={3} updateMode={false} updateAble={config.updateAble} isAgentLoginDirectly={false} callBackFuntion={(returnedMsg: any) => {
                SetMessage(returnedMsg)
            }} />
        </>}
        {(fromPage === undefined && personalInfoTab === 'drPI' && drAgentPersonalInfo !== null && isUpdateAble4DrAgent !== null) && <>
            <AgentContactInfo {...props} agentPersonalInfo={drAgentPersonalInfo} agentNumber={drAGTPersonalInfoForm.current.values.drAgent.split("-")[0]} states={config.states} contactMethods={config.contactMethods} companyCode={drAGTPersonalInfoForm.current.values.drAgent.split("-")[1]} personalInfoType={3} updateMode={false} updateAble={isUpdateAble4DrAgent} isAgentLoginDirectly={true} isDrAgentSelected={true} callBackFuntion={(returnedMsg: any) => {
                SetMessage(returnedMsg)
            }}
            />
        </>}
    </props.Div >
});