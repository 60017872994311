import React from 'react';
import GlobalizedText from '../../globalization';
import { FieldProps, ReadOnlyProps, withErrorControl, WrapperControlProps } from './field';
import { Div } from './layout';

export interface TextControlProps extends WrapperControlProps {

}
export interface InputAddonControlProps extends WrapperControlProps {
    addonMsg: string;
}

export interface TextareaControlProps extends WrapperControlProps {
    /**
     * Maximum number of characters can be entered.
     */
    maxLength?: number;

    /**
     * Indentify whether the remaining characters should be displayed or not, default is false, must be used along with maxLength.
     */
    showRemaining?: boolean;

    /**
     * inline style of remaining characters
     */
    styleOfRemaining?: any;
}

export interface PasswordControlProps extends WrapperControlProps {
}


export interface RadioControlProps extends WrapperControlProps {
    labelPosition?: 'left' | 'right';
}

export interface CheckboxControlProps extends WrapperControlProps {
    labelPosition?: 'left' | 'right';
}

export interface InputProps extends FieldProps, ReadOnlyProps {
    type: "text" | "password" | "email" | "radio" | "checkbox" | "hidden" | "date";
    value?: string;
    onBlur?: (e: any) => void;
    onFocus?: (e: any) => void;
}

export function Input({ type, onChange, ...props }: InputProps) {
    return <input type={type} {...props} className={`form-control gwp-input ${props.className ? props.className : ''}`} onChange={onChange} autoComplete="new-password" />
}

export const TextControl = withErrorControl(({ required, readOnly, label, containerClassName, condition, setValue, ...props }: TextControlProps) => {
    return <Input type="text" readOnly={readOnly} {...props}></Input>
});

export const TextareaControl = withErrorControl(({ required, readOnly, label, containerClassName, condition, styleOfRemaining, showRemaining, setValue, ...props }: TextareaControlProps) => {
    if (showRemaining === true && props.maxLength === undefined) {
        console.warn("Show remaining won't be working properly without [maxLength].");
    }
    let remaining = props.maxLength !== undefined ? props.maxLength - props['data-form-value'].length : 0;
    return <> <textarea {...props} readOnly={readOnly} className="form-control gwp-input" autoComplete="new-password"></textarea>
        <Div condition={showRemaining === true} style={{ ...styleOfRemaining }} className={`information gwp-information gwp-textarea-remaining`}><GlobalizedText message="common.lbl.charRemaining" />{`: ${remaining} `}</Div>
    </>
});

export const PasswordControl = withErrorControl(({ required, readonly, label, containerClassName, condition, setValue, ...props }: PasswordControlProps) => {
    return <Input type="password" {...props}></Input>
});

export function Radio({ className, labelPosition, condition, setValue, ...props }: any) {
    return <input type="radio" checked={compare(props['data-form-value'], props.value)} className={`form-control gwp-input ${labelPosition === 'left' ? '' : 'gwp-float-left'} ${className === undefined ? '' : className}`} {...props}></input>
}

export const RadioControl = withErrorControl((props: RadioControlProps) => {
    return <Radio {...props}></Radio>
});
function compare(val1: any, val2: any): boolean {
    let result = false;
    if (val1 === undefined && val2 === undefined) {
        return result;
    }
    if (typeof (val1) === typeof (val2)) {
        result = val1 === val2;
    } else {
        if (typeof (val1) === 'boolean' && ((val2 === 'true' && val1 === true) || (val2 === 'false' && val1 === false))) {
            result = true;
        }
        else if (typeof (val1) === 'number' && parseFloat(val2) === val1) {
            result = true;
        } else if (typeof (val1) === 'string' && "" + val2 === val1) {
            result = true
        }
    }
    return result;
}
export function Checkbox({ className, labelPosition, condition, setValue, ...props }: any) {


    let checked = false;
    if (props['data-form-value'] instanceof Array) {
        for (let v of props['data-form-value']) {
            if (compare(v, props.value)) {
                checked = true;
                break;
            }
        }
    } else {
        checked = compare(props['data-form-value'], props.value);
    }
    return <input type="checkbox" checked={checked} className={`form-control gwp-input ${labelPosition === 'left' ? '' : 'gwp-float-left'} ${className === undefined ? '' : className}`} {...props}></input>
}

export const CheckboxControl = withErrorControl((props: CheckboxControlProps) => {
    return <Checkbox {...props} />
});

export const InputAddonControl = withErrorControl(({ required, readonly, condition, label, containerClassName, addonMsg, maxLength, className, setValue, disabled, ...props }: TextControlProps) => {
    return <div className="input-group gwp-input gwp-inputAddOn">
        <input type="text" className={`form-control ${className === undefined ? '' : className}`} disabled={disabled} maxLength={maxLength}  {...props} />
        <div className="input-group-addon"><GlobalizedText message={addonMsg} /></div>
    </div>
})

export interface FileControlProps extends FieldProps {

}

export function File({ className, ...props }: any) {
    return <div className="input-group gwp-input" style={{ cursor: 'pointer' }} onClick={() => document.getElementsByName(props.name)[0]?.click()}>
        <label className="hidden" htmlFor={`file-text-${props.name}`}>file text</label>
        <input type='text' id={`file-text-${props.name}`} className='form-control gwp-file' readOnly={true} />
        <label className="hidden" htmlFor={props.id}>update file</label>
        <input type="file" name={props.name} style={{ display: 'none' }} onChange={(e: any) => {
            document.getElementById(`file-text-${props.name}`)?.setAttribute("value", e.target.value.replace(/^.+fakepath\\/, ''));
            props.onChange(e);
        }} id={props.id} />
        <div className="input-group-addon"><GlobalizedText message="register.lbl.browse" />
        </div>
    </div>
}

export const FileControl = withErrorControl((props: FileControlProps) => {
    return <File {...props} />
})

