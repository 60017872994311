import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, getFromStorage, simpleSort, trim, validate } from '../shared/utils';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import * as yup from 'yup';
import { TableColumn } from '../shared/components';
import './css/manageuserprofiles.css';

/**
 * Manage user profiles component
 */
export const ManageUserProfilesComponent = withView((props: ViewComponentProps) => {
    const config = getFromStorage('gwp.config', true);
    const [view, setView] = useState<any>();
    const [table, setTable] = useState<any>();
    let defaultInitialValues = {
        filterBy: '',
        userId: '',
        userTypeRegCode: '',
        nameType: '1',
        firstName: '',
        lastName: '',
        status: 'D'
    }
    useEffect(() => {
        if (view === undefined) {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
            ajax({
                url: '/api/manageUserProfile/view',
                success: res => {
                    setView(res);
                    //let initParams = props.getInitParams();

                    if (props.getInitParams() !== undefined) {
                        props.clearMessage();
                        retrieve(res, { ...defaultInitialValues, ...{ filterBy: res.filterBy.filter((userType: any) => userType.selected === true)[0].value, userTypeRegCode: simpleSort(res.userTypes, "name", true, props.getGlobalizedText)[0].value }, ...props.getInitParams() }, setTable, true);
                    }
                }
            });
        }
    }, [view]);

    if (view === undefined) {
        return <></>
    }

    let initialValues = { ...defaultInitialValues, ...{ filterBy: view.filterBy.filter((userType: any) => userType.selected === true)[0].value, userTypeRegCode: simpleSort(view.userTypes, "name", true, props.getGlobalizedText)[0].value }, ...props.getInitParams() };
    const byIdSchema = yup.object().shape({
        userId: yup.string().trim().required("forgpass.msg.err.user.id.empty")
    });

    const byNamePersonValidate = (values: any) => {
        if (trim(values.firstName) === '' && trim(values.lastName) === '') {
            return {
                "firstName": "common.msg.personNameReq",
                "lastName": "common.msg.personNameReq"
            }
        }
    }

    const byNameCompanyValidate = (values: any) => {
        if (trim(values.orgName) === '') {
            return {
                "orgName": "common.msg.payment.companyname.requir"
            }
        }
    }

    return <>
        <Formik initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                if (values.filterBy === view.byUserId) {
                    return validate(byIdSchema, values, props);
                }
                if (values.filterBy === view.byName && values.nameType === '1') {
                    return validate(byNamePersonValidate, values, props);
                }
                if (values.filterBy === view.byName && values.nameType === '2') {
                    return validate(byNameCompanyValidate, values, props);
                }
            }}
            onSubmit={(values) => {
                document.documentElement.scrollTop = document.body.scrollTop = 0;
                retrieve(view, values, setTable, true);
                props.setInitParams({ ...values });
            }}
        >
            {formProps =>
                <Form>

                    <props.Row>
                        <props.Col sm={5} md={5} xs={12}>
                            <props.SelectControl name="filterBy" onChange={(e: any) => {
                                setTable(null);
                                resetForm(e.target.value, formProps.values.nameType, formProps, props, setTable);
                            }} options={[...view.filterBy]} label="common.lbl.filterUserProfilesBy" />
                            <props.SelectControl condition={formProps.values.filterBy === view.byName} onChange={(e: any) => {
                                setTable(null);
                                resetForm(formProps.values.filterBy, e.target.value, formProps, props, setTable);
                                //formSubmit(formProps.values);
                            }} options={view.nameTypes !== undefined ? view.nameTypes : [{ name: "changeow.lbl.person", value: "1", selected: true }, { name: "memlist.company", value: "2", selected: true }]} name="nameType" label="payment.lbl.nametype" />
                        </props.Col>
                        <props.Col sm={7} md={7} xs={12}>
                            <props.Condition condition={formProps.values.filterBy === view.byName && formProps.values.nameType === "1"} ><props.Information message="common.msg.personNameReq" /></props.Condition>
                            <props.SelectControl condition={formProps.values.filterBy === view.byStatus} options={view.statusList !== undefined ? view.statusList : config.openIdConnect === true ? [{ name: "profile.lbl.disabled", value: "D", selected: true }] : [{ name: "profile.lbl.disabled", value: "D", selected: true }, { name: "profile.lbl.locked", value: "L", selected: false }]} name="status" label="common.lbl.status" />
                            <props.TextControl required={true} condition={formProps.values.filterBy === view.byUserId} id="userId" name="userId" label="groupAdmin.userID" />
                            <props.TextControl condition={formProps.values.filterBy === view.byName && formProps.values.nameType === "1"} id="lastName" name="lastName" label="common.lbl.lastName" />
                            <props.TextControl required={true} condition={formProps.values.filterBy === view.byName && formProps.values.nameType === "2"} id="orgName" name="orgName" label="changeow.lbl.companyName" />
                            <props.SelectControl condition={formProps.values.filterBy === view.byUserType} options={view.userTypes} name="userTypeRegCode" label="security.lbl.userType" />
                            <props.TextControl condition={formProps.values.filterBy === view.byName && formProps.values.nameType === "1"} id="firstName" name="firstName" label="common.lbl.firstName" />
                            <props.Button className={(formProps.values.filterBy === view.byName && formProps.values.nameType === "1") ? '' : "margintop20px"} type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>

                        </props.Col>
                    </props.Row>

                </Form>
            }
        </Formik>
        <props.HR />
        <props.Table id="userProfilesTable" table={
            {
                columns: config.openIdConnect ? tableColumnOpenIdConnect : tableColumn,
                data: table,
            }
        } ></props.Table>


    </>
});

const tableColumnOpenIdConnect: Array<TableColumn> = [
    {
        title: 'forgpass.label.user.id', name: 'userId', render: (data: any, type: any, row: any, meta: any): string => {
            return `<a dt-href='/profile/${data}'>${data}</a>`
        }, width: "18%"
    },
    { title: 'common.lbl.name', name: 'name', width: "20%" },
    { title: 'security.lbl.userType', name: 'userType', width: "18%" },
    { title: 'profile.lbl.account.disabled', name: 'accountDisabled', width: "18%" }
]

const tableColumn: Array<TableColumn> = [
    {
        title: 'forgpass.label.user.id', name: 'userId', render: (data: any, type: any, row: any, meta: any): string => {
            return `<a dt-href='/profile/${data}'>${data}</a>`
        }, width: "18%"
    },
    { title: 'common.lbl.name', name: 'name', width: "20%" },
    { title: 'security.lbl.userType', name: 'userType', width: "18%" },
    { title: 'profile.lbl.account.locked.out', name: 'accountLockedOut', width: "26%" },
    { title: 'profile.lbl.account.disabled', name: 'accountDisabled', width: "18%" }
]

const resetForm = (filterBy: string, nameType: string, formProps: any, viewProps: any, setTable: any) => {
    formProps.setFieldValue("userId", '');
    formProps.setFieldValue("userTypeRegCode", formProps.initialValues.userTypeRegCode);
    formProps.setFieldValue("firstName", '');
    formProps.setFieldValue("lastName", '');
    formProps.setFieldValue("orgName", '');
    viewProps.clearMessage();
    setTable({
        columns: tableColumn
    });
    viewProps.setInitParams({ filterBy: filterBy, nameType: nameType, status: formProps.values.status });
}

const retrieve = (view: any, values: any, setTable: any, isSubmitted?: boolean) => {
    let params = {};
    if (isSubmitted !== true) {
        return;
    }
    if (values.filterBy === view.byName && values.nameType === '1') {
        params = { firstName: values.firstName === '' ? null : values.firstName, lastName: values.lastName === '' ? null : values.lastName, orgName: '' };
    }
    if (values.filterBy === view.byName && values.nameType === '2') {
        params = { firstName: null, lastName: null, orgName: values.orgName };
    }
    if (values.filterBy === view.byUserId) {
        params = { userId: values.userId };
    }
    if (values.filterBy === view.byUserType) {
        params = { userTypeCode: values.userTypeRegCode };
    }
    if (values.filterBy === view.byStatus) {
        params = { status: values.status };
    }
    ajax({
        url: `/api/manageUserProfile/${values.filterBy}`,
        params: params,
        success: res => {
            setTable(res);
        }
    });
}