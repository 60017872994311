import { Form, Formik, useFormikContext, useField } from 'formik';
import React, { useEffect, useState } from 'react';
import GlobalizedText from '../shared/globalization';
import { ajax, $, mergeObject } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/securityauthorization.css';

interface SecurityAuthorizationEntity {
    access: number;
    children: Array<SecurityAuthorizationEntity>;
    functionCode: string;
    id: string;
    recid: number;
    name: string;
    order: number;
    parFunCode: string;
}
interface SecurityAuhtorizationData {
    systemLevel?: Array<SecurityAuthorizationEntity>,
    groupSpecific?: Array<SecurityAuthorizationEntity>
}
interface SecurityAuthorizationForm {
    userType: string;
    companyCode: string;
    groupNumber: string;
    accountNumber: number;
    level: Level;
    settings: any;
}
interface TreeGridRowProps {
    rowNum: string;
    parentRowNum?: string;
    editing?: boolean;
    rowData: SecurityAuthorizationEntity;
    data?: Array<SecurityAuthorizationEntity>;
    setData?: (value: any) => void;
    setSettings: (functionCode: string, access: number) => void;
}
interface CheckBoxProps {
    onChange: (e: any, props: CheckBoxProps) => void;
    functionType: 'allow' | 'deny';
    functionCode: string;
    hasChildren: boolean;
    parentFunctionCode: string;
    access: number;
}
interface TreeGridProps {
    id: string;
    companyCode?: string;
    groupNumber?: string;
    accountNumber?: number;
    groupName?: string;
    accountName?: string;
    columns: Array<TreeGridColumn>;
    editing?: boolean;
    data?: Array<SecurityAuthorizationEntity>;
    setData?: (value: any) => void;
}
interface TreeGridColumn {
    name: string;
}
type Level = 'systemLevel' | 'groupSpecific'

const ALL_GROUP_NUMBER = '0';
const ALL_COMPANY_CODE = '0';

function setDisplayedValue(options: Array<any>, value: string | number, callback: Function) {
    for (let option of options) {
        if (option.value === value) {
            callback(option.name);
        }
    }
}
function loadSettings(settings: any, saes?: Array<SecurityAuthorizationEntity>) {
    if (saes) {
        for (let sae of saes) {
            settings[sae.functionCode.trim()] = { access: sae.access, parentFunctionCode: sae.parFunCode.trim(), recid: sae.recid };
            if (sae.children) {
                loadSettings(settings, sae.children);
            }
        }
    }
}

function clearWhenSelecting(props: any, formProps: any) {
    props.clearMessage();
    props.clearSetting(formProps);
}

export const SecurityAuthorizationComponent = withView(function (props: ViewComponentProps) {
    const [config, setConfig] = useState<any | null>(null);
    const [editing, setEditing] = useState<boolean>(false);
    const [adding, setAdding] = useState<boolean>(false);
    const [groups, setGroups] = useState<any | null>(null);
    const [accounts, setAccounts] = useState<any | null>(null);
    const [companyName, setCompanyName] = useState<any | null>('');
    const [groupName, setGroupName] = useState<any | null>('');
    const [accountName, setAccountName] = useState<any | null>('');
    const [userTypeName, setUserTypeName] = useState<any | null>('');
    const [data, setData] = useState<SecurityAuhtorizationData>();
    const [userType, setUserType] = useState<string>('');
    const [tab, setTab] = useState<Level>('systemLevel');
    function clickEditOnSystem() {
        setEditing(true);
        props.clearMessage();
    }
    function clickCloseOnSystem() {
        setEditing(false);
    }
    function clickEditOnGroup() {
        setEditing(true);
        props.clearMessage();
    }
    function clickCloseOnGroup() {
        setAdding(false);
        setEditing(false);
    }
    function clickAdd(formValue: SecurityAuthorizationForm) {
        loadDataForForm(formValue, false);
        setAdding(true);
        setEditing(true);
        props.clearMessage();

    }
    function clearSetting(formProps: any) {
        formProps.setFieldValue('settings', {});
    }
    function clickTab(level: Level, formProps: any) {
        let formValue: SecurityAuthorizationForm = formProps.values;
        if (level === tab) {
            return;
        }
        setEditing(false);
        setTab(level);
        loadData(level, formValue.userType, formValue.companyCode, formValue.groupNumber, formValue.accountNumber);
        props.resetForm(formProps);
        props.clearMessage();
    }
    const api = { clearSetting: clearSetting, loadData: loadData, loadDataForForm: loadDataForForm, setGroups: setGroups, setAccounts: setAccounts, setCompanyName: setCompanyName, setGroupName: setGroupName, setAccountName: setAccountName, clearMessage: props.clearMessage };
    /**
     * Load security setting data
     * @param level         system level or group level
     * @param userTypeCode      user type code
     * @param companyCode   
     * @param groupNumber 
     * @param accountNumber 
     * @param loadSetting    load security setting or not, if false then just load the empty security settings.
     */
    function loadData(level: Level, userTypeCode: string, companyCode?: string, groupNumber?: string, accountNumber?: number, loadSetting?: boolean) {
        let getSetting = loadSetting !== undefined ? loadSetting : true
        ajax({
            url: level === 'systemLevel' ? `/api/securityauthorization/${level}/${userTypeCode}/true`
                : `/api/securityauthorization/${level}/${companyCode}/${groupNumber}/${accountNumber}/${userTypeCode}/${getSetting}`,
            success: (queryRes: Array<SecurityAuthorizationEntity>) => {
                let securityAuthorizationData: SecurityAuhtorizationData = {};
                securityAuthorizationData[level] = queryRes;
                setData(securityAuthorizationData);
                $(`#${level}`).treegrid({
                    expanderExpandedClass: 'glyphicon glyphicon-minus',
                    expanderCollapsedClass: 'glyphicon glyphicon-plus'
                });
            }
        });
    }

    /**
     * Load security setting data by form
     * @param form 
     * @param loadSetting 
     */
    function loadDataForForm(form: SecurityAuthorizationForm, loadSetting?: boolean) {
        loadData(form.level, form.userType, form.companyCode, form.groupNumber, form.accountNumber, loadSetting);
    }

    const formSubmit = (values: SecurityAuthorizationForm, { setSubmitting }: any) => {
        let access = [];
        for (let fc in values.settings) {
            access.push({
                recordID: values.settings[fc].recid === undefined ? "" : values.settings[fc].recid,
                access: values.settings[fc].access,
                functionCode: fc
            });
        }
        ajax({
            method: values.level === 'systemLevel' ? 'put' : 'post',
            url: values.level === 'systemLevel' ?
                `/api/securityauthorization/${values.level}/${values.userType}` :
                `/api/securityauthorization/${values.level}/${values.companyCode}/${values.groupNumber}/${values.accountNumber}/${values.userType}`,
            data: { functionAccesses: access },
            success: (res: any) => {
                props.showMessage("success", adding === true ? props.getGlobalizedText("common.msg.addSuccess") : props.getGlobalizedText("common.msg.updateSuccess"));
                loadDataForForm(values);
                setEditing(false);
                setAdding(false);
            },
            fail: (res: any) => {
                props.showMessage("error", res.message);
            }
        });
    }
    const removeSettings = (values: SecurityAuthorizationForm) => {
        ajax({
            method: 'delete',
            url: `/api/securityauthorization/${values.level}/${values.companyCode}/${values.groupNumber}/${values.accountNumber}/${values.userType}`,
            success: (res: any) => {
                props.showMessage("success", props.getGlobalizedText("common.msg.securityAuthorizationDeletedSuccessfully"));
                loadDataForForm(values);
                setEditing(false);
            },
            fail: (res: any) => {
                props.showMessage("error", res.message);
            }
        });
    }
    const showGroupSpecific = (formProps: any | string): boolean => {
        let userTypeCode = typeof formProps === 'string' ? formProps : formProps.values.userType;
        if (config.version === 1) {
            return false;
        }
        for (let ut of config.userTypes) {
            if (ut.userTypeCode === parseInt(userTypeCode) && ut.type === 1) {
                return false;
            }
        }
        return true;
    }
    const handleUserTypeChange = (e: any, formProps: any) => {
        setTab('systemLevel');
        setDisplayedValue(userTypeOptions, parseInt(`${e.target.value}`), setUserTypeName);
        props.resetForm(formProps, mergeObject(formProps.values, { userType: e.target.value }));
        loadDataForForm({ ...formProps.values, userType: e.target.value, ...{ level: 'systemLevel' } });
        setUserType(e.target.value);
        props.clearMessage();
    }
    useEffect(() => {
        if (data === undefined) {
            ajax({
                url: '/api/securityauthorization/view',
                success: (res: any) => {
                    setConfig(res);
                    loadData(tab, res.defaultUserType);
                    setUserType(res.defaultUserType);
                    let utos = res.userTypes.map((ut: any) => {
                        return { name: ut.userTypeName, value: ut.userTypeCode }
                    });
                    setDisplayedValue(utos, res.defaultUserType, setUserTypeName);
                }
            });
        }
    }, [data, tab])
    if (data === undefined || data[tab] === undefined) {
        return <></>;
    }
    let groupOptions = groups !== null ? groups : config.groups;
    let accountOptions = accounts !== null ? accounts : config.accounts;
    let userTypeOptions = config.userTypes.map((ut: any) => {
        return { name: ut.userTypeName, value: ut.userTypeCode }
    });
    let initialValues: SecurityAuthorizationForm = {
        userType: userType,
        companyCode: config.companies[0].companyCode,
        groupNumber: ALL_GROUP_NUMBER,
        accountNumber: 0,
        level: tab,
        settings: {}
    }
    //loadSettings(initialValues.settings,data[tab]);
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={formSubmit}
        >
            {formProps =>
                <Form>
                    <props.Container>
                        <props.Row>
                            <props.Col>
                                <props.SelectControl options={userTypeOptions} onChange={(e: any) => { handleUserTypeChange(e, formProps) }} name="userType" id="userType" label="security.lbl.userType"></props.SelectControl>
                            </props.Col>
                        </props.Row>
                        <props.Row>
                            <props.Col>
                                <ul id="sctab" className="nav nav-tabs" role="tablist">
                                    {showGroupSpecific(formProps) === true &&
                                        <li role="presentation" className={tab === 'groupSpecific' ? 'active' : ''}><a href="#groupSpecificTab" onClick={() => clickTab('groupSpecific', formProps)} aria-controls="groupSpecificTab" role="tab" data-toggle="tab"><GlobalizedText message="security.lbl.groupSpecific" /></a></li>
                                    }
                                    <li role="presentation" className={tab === 'systemLevel' ? 'active' : ''}><a href="#systemLevelTab" onClick={() => clickTab('systemLevel', formProps)} aria-controls="systemLevelTab" role="tab" data-toggle="tab"><GlobalizedText message="security.lbl.systemLevel" /></a></li>
                                </ul>
                                <div id="scTabContent" className="tab-content">
                                    <div role="tabpanel" className={`tab-pane fade in ${tab === 'groupSpecific' ? 'active' : ''}`} id="groupSpecificTab">
                                        <div id="groupDiv">
                                            {(editing !== true && adding !== true) &&
                                                <div>
                                                    <CompanySelect  {...props} options={config.companies} {...api} />
                                                    <GroupSelect {...props} options={groupOptions} {...api} />
                                                    <AccountSelect {...props} options={accountOptions} {...api} />
                                                    <div className="form-group btnGroupDiv">
                                                        {(data.groupSpecific !== undefined && data.groupSpecific.length === 0) &&
                                                            <props.Button onClick={() => { clickAdd(formProps.values) }}><GlobalizedText message="common.lbl.add" /></props.Button>
                                                        }
                                                        {(data.groupSpecific !== undefined && data.groupSpecific.length > 0) &&
                                                            <>
                                                                <props.Button onClick={clickEditOnGroup} ><GlobalizedText message="common.lbl.edit" /></props.Button>
                                                                <props.Button data-toggle="modal" data-target="#confirmDelete"><GlobalizedText message="security.lbl.remove" /></props.Button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {(editing === true || adding === true) &&
                                                <div>
                                                    <props.DL>
                                                        <props.DLI title="memlist.company" desc={companyName}></props.DLI>
                                                        <props.DLI title="common.lbl.group" desc={groupName}></props.DLI>
                                                        <props.DLI title="common.lbl.account" desc={accountName}></props.DLI>
                                                    </props.DL>
                                                    <div className="form-group" style={{ marginBottom: "20px" }}>
                                                        <props.Button type="submit"><GlobalizedText message="common.button.submit" /></props.Button>
                                                        <props.Button onClick={() => { clickCloseOnGroup(); if (adding === true) { setData({ 'groupSpecific': [] }); } }}><GlobalizedText message="common.lbl.close" /></props.Button>
                                                    </div>
                                                </div>
                                            }
                                            <div>
                                                <TreeGrid groupName={groupName} accountName={accountName} companyCode={formProps.values.companyCode} groupNumber={formProps.values.groupNumber} accountNumber={formProps.values.accountNumber} setData={setData} editing={editing} data={data?.groupSpecific} id="groupSpecific" columns={[{ name: "security.lbl.function" }, { name: "security.lbl.access" }]}></TreeGrid>
                                            </div>
                                        </div>
                                    </div>
                                    <div role="tabpanel" className={`tab-pane fade in ${tab === 'systemLevel' ? 'active' : ''}`} id="systemLevelTab">
                                        <div id="sysButtons" style={{ marginBottom: "20px" }}>
                                            {editing !== true &&
                                                <props.Button onClick={clickEditOnSystem}><GlobalizedText message="common.lbl.edit" /></props.Button>

                                            }
                                            {editing === true &&
                                                <React.Fragment>
                                                    <props.Button type="submit"><GlobalizedText message="common.button.submit" /></props.Button>
                                                    <props.Button onClick={clickCloseOnSystem}><GlobalizedText message="common.lbl.close" /></props.Button>
                                                </React.Fragment>
                                            }
                                        </div>
                                        <div>
                                            <TreeGrid setData={setData} editing={editing} data={data?.systemLevel} id="systemLevel" columns={[{ name: "security.lbl.function" }, { name: "security.lbl.access" }]}></TreeGrid>
                                        </div>
                                    </div>
                                </div>
                            </props.Col>
                        </props.Row>
                    </props.Container>
                    <props.Modal title="common.lbl.rmv.secu.auth" id="confirmDelete" footer={() => {
                        return <>
                            <props.Button onClick={() => { removeSettings(formProps.values) }} data-toggle="modal" data-target="#confirmDelete" id='removeAccess'><GlobalizedText message="security.lbl.remove" /></props.Button>
                            <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.cancel" /></props.Button>
                        </>
                    }}>
                        <p><GlobalizedText message="common.msg.rmv.secu.auth.warnning" /></p>
                        <props.DL className='dl-horizontal gwp-dl gwp-align-left'>
                            <props.DLI title="memlist.company" desc={companyName}></props.DLI>
                            <props.DLI title="common.lbl.group" desc={groupName}></props.DLI>
                            <props.DLI title="common.lbl.account" desc={accountName}></props.DLI>
                            <props.DLI title="security.lbl.userType" desc={userTypeName}></props.DLI>
                        </props.DL>
                        <p><GlobalizedText message="security.msg.confirmRemove" /></p>
                    </props.Modal>
                </Form>
            }
        </Formik>
    )
});

function CompanySelect(props: any) {
    let options = props.options.map((option: any) => {
        return { value: option.companyCode.trim(), name: option.fullCompanyName };
    });
    const formProps = useFormikContext<any>();
    useEffect(() => {
        setDisplayedValue(options, formProps.values.companyCode, props.setCompanyName);
    });

    function selectCompany(e: any) {
        clearWhenSelecting(props, formProps);
        let value = e.target.value;
        setDisplayedValue(options, value, props.setCompanyName);
        ajax({
            url: '/api/securityauthorization/group/' + value,
            success: (res: any) => {
                props.setGroups(res);
                formProps.setFieldValue("groupNumber", res[0].groupNumber);
                ajax({
                    url: '/api/securityauthorization/account/' + formProps.values.companyCode + '/' + res[0].groupNumber,
                    success: (res1: any) => {
                        props.setAccounts(res1);
                        formProps.setFieldValue("accountNumber", res1[0].accountNumber);
                        props.loadDataForForm({ ...formProps.values, companyCode: value, groupNumber: res[0].groupNumber, accoutNumber: res1[0].accountNumber });
                    }
                });
            }
        });
    }

    return <props.SelectControl sort={false} onChange={selectCompany} id="companyCode" name="companyCode" label="memlist.company" options={options}></props.SelectControl>
}
function GroupSelect(props: any) {
    let options = props.options.map((option: any) => {
        return { value: option.groupNumber.trim(), name: option.fullGroupName };
    });
    const formProps = useFormikContext<any>();
    useEffect(() => {
        setDisplayedValue(options, formProps.values.groupNumber, props.setGroupName);
    });

    function selectGroup(e: any) {
        let value = e.target.value;
        setDisplayedValue(options, value, props.setGroupName);
        ajax({
            url: '/api/securityauthorization/account/' + formProps.values.companyCode + '/' + value,
            success: (res: any) => {
                clearWhenSelecting(props, formProps);
                props.setAccounts(res);
                formProps.setFieldValue("accountNumber", res[0].accountNumber);
                props.loadDataForForm({ ...formProps.values, groupNumber: value, accountNumber: res[0].accountNumber });
            }
        });
    }
    return <props.SelectControl sort={false} onChange={selectGroup} name="groupNumber" label="common.lbl.group" options={options}></props.SelectControl>
}
function AccountSelect(props: any) {
    let options = props.options.map((option: any) => {
        return { value: option.accountNumber, name: option.fullAccountName };
    });
    const formProps = useFormikContext<any>();
    useEffect(() => {
        setDisplayedValue(options, formProps.values.accountNumber, props.setAccountName);
    });

    function selectAccount(e: any) {
        let value = e.target.value;
        setDisplayedValue(options, parseInt(`${value}`), props.setAccountName);
        clearWhenSelecting(props, formProps);
        props.loadDataForForm({ ...formProps.values, accountNumber: parseInt(`${value}`) });
    }
    return <props.SelectControl sort={false} onChange={selectAccount} id="accountNumber" name="accountNumber" label="common.lbl.account" options={options}></props.SelectControl>
}

const TreeGridRow = (props: TreeGridRowProps) => {
    const fieldConfig = useField('settings');
    const field = fieldConfig[0];
    const fieldHelper = fieldConfig[2];
    let functionAccess: number = 0;
    if (field.value[props.rowData.functionCode.trim()] === undefined) {
        props.setSettings(props.rowData.functionCode.trim(), 1);
        functionAccess = 1;
    } else {
        let fieldValue = field.value[props.rowData.functionCode.trim()];
        functionAccess = fieldValue.access;
    }
    const setValue = (e: any, ckProps: CheckBoxProps) => {
        let access = (ckProps.functionType === 'allow' && e.target.checked) ? 1 : 0;
        updateSetting(ckProps.functionCode, ckProps.parentFunctionCode, access, field.value);
    }

    const updateSetting = (functionCode: string, parentFunctionCode: string, access: number, settings: any) => {
        settings[functionCode].access = access;
        fieldHelper.setValue(settings);
        // update child functions
        for (let fc in settings) {
            if (settings[fc].parentFunctionCode === functionCode) {
                settings[fc].access = access;
                updateSetting(fc, functionCode, access, settings);
            }
        }
        updateParentSetting(parentFunctionCode, access, settings);
    }

    const updateParentSetting = (parentFunctionCode: string, access: number, settings: any) => {
        if (parentFunctionCode !== '-1') {
            if (access === 1) {
                settings[parentFunctionCode].access = access
            }
            /* else {
                for (let cfc in settings) {
                    if (settings[cfc].parentFunctionCode === parentFunctionCode) {
                        if (settings[cfc].access !== access) {
                            return;
                        }
                    }
                }
                settings[parentFunctionCode].access = access;
            }*/
            updateParentSetting(settings[parentFunctionCode].parentFunctionCode, access, settings);
        }
    }
    let parentClass = props.parentRowNum ? 'treegrid-parent-' + props.parentRowNum : '';
    let column1ClassName = "column1 " + ((props.rowData.children && props.rowData.children.length > 0) || !props.parentRowNum ? "gwp-bold" : "");
    return <>
        <tr className={`treegrid-${props.rowNum} ${parentClass}`}>
            <td className={column1ClassName}>{props.rowData.name}</td>
            {props.editing === true &&
                <td>
                    <div className="checkbox-div">
                        <CheckBox access={functionAccess} onChange={setValue} functionType="allow" functionCode={props.rowData.functionCode.trim()} parentFunctionCode={props.rowData.parFunCode.trim()} hasChildren={props.rowData.children !== undefined}></CheckBox>
                        <label htmlFor={`allow-${props.rowData.functionCode.trim()}`}><GlobalizedText message="common.lbl.allow" /></label>
                    </div>
                    <div className="checkbox-div">
                        <CheckBox access={functionAccess} onChange={setValue} functionType="deny" functionCode={props.rowData.functionCode.trim()} parentFunctionCode={props.rowData.parFunCode.trim()} hasChildren={props.rowData.children !== undefined}></CheckBox>
                        <label htmlFor={`deny-${props.rowData.functionCode.trim()}`}><GlobalizedText message="common.lbl.deny" /></label>
                    </div>
                </td>
            }
            {props.editing === false &&
                <td className="column2">
                    {props.rowData.access === 1 && <GlobalizedText message="common.lbl.allow" />}
                    {props.rowData.access === 0 && <GlobalizedText message="common.lbl.deny" />}
                </td>
            }
        </tr>
        {(props.rowData.children && props.rowData.children.length > 0) && props.rowData.children.map((childRowData: SecurityAuthorizationEntity, index: number) => {
            return <TreeGridRow {...props} key={index} editing={props.editing} rowData={childRowData} parentRowNum={props.rowNum} rowNum={`${props.rowNum}_${index}`}></TreeGridRow>;
        })
        }
    </>
}
const CheckBox = (props: CheckBoxProps) => {
    return <>
        {(props.access === 1 && props.functionType === 'allow') &&
            <input type="checkbox" id={`allow-${props.functionCode}`} onChange={(e) => { props.onChange(e, props) }} checked />
        }
        {(props.access === 0 && props.functionType === 'allow') &&
            <input type="checkbox" id={`allow-${props.functionCode}`} onChange={(e) => { props.onChange(e, props) }} />
        }
        {(props.access === 0 && props.functionType === 'deny') &&
            <input type="checkbox" id={`deny-${props.functionCode}`} onChange={(e) => { props.onChange(e, props) }} checked />
        }
        {(props.access === 1 && props.functionType === 'deny') &&
            <input type="checkbox" id={`deny-${props.functionCode}`} onChange={(e) => { props.onChange(e, props) }} />
        }
    </>
}

const TreeGrid = (props: TreeGridProps) => {
    const fieldConfig = useField('settings');
    const fieldHelper = fieldConfig[2];
    let settings: any = {};
    loadSettings(settings, props.data);
    const setSettings = (functionCode: string, access: number) => {
        settings[functionCode].access = access;
    }
    let data = props.data ? props.data : [];
    useEffect(() => {
        // once tree grid is loaded, then initialize the settings.
        let entities: Array<SecurityAuthorizationEntity> | undefined = props.data;
        if (entities !== undefined && entities.length > 0) {
            //if (field.value[entities[0].functionCode] === undefined) {
            loadSettings(settings, props.data);
            fieldHelper.setValue(settings);
            // }
        }
    }, [props.data]);

    return <table id={props.id} className="table gwp-table table-striped table-bordered dataTable table-hover" cellSpacing="0">
        <thead>
            <tr>
                {props.columns.map((col: TreeGridColumn, index: number) => {
                    return <th key={index} row-span="1" col-span="1" className={`colum${index + 1}`}>
                        <GlobalizedText message={col.name} />
                    </th>
                })}
            </tr>
        </thead>
        <tbody>
            {(() => {
                if (data.length === 0) {
                    if (props.companyCode === undefined || props.companyCode === ALL_COMPANY_CODE || props.groupNumber === ALL_GROUP_NUMBER) {
                        return <tr><td colSpan={2} className="gwp-align-center"><GlobalizedText message="security.msg.noMessage" /></td></tr>
                    } else {
                        return <tr><td colSpan={2} className="gwp-align-center"><GlobalizedText message="security.msg.noMessageDefaultTo" /> {` Group : ${props.groupName} / ${props.accountName}`}</td></tr>
                    }
                }
            })()
            }
            {data.map((rowData: SecurityAuthorizationEntity, index: number) => {
                return <TreeGridRow setSettings={setSettings} {...props} editing={props.editing} key={index} rowNum={`${index}`} rowData={rowData}></TreeGridRow>;
            })
            }
        </tbody>
    </table>;
}