import { Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from "react";
import { GroupSelectControl, showModal, useTable } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { CloseCircleOutlined } from "@ant-design/icons";
import { useMessage } from "../shared/message";
import { ajax, downloadFile, simpleSort, isEmptyStr, trim, getContextPath, parseDate, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import * as yup from 'yup';
import { INVALID_MESSAGES, YupSchema } from '../shared/yupschema';
import { INVALID_MESSAGES_ITG } from '../shared/yupSchemaITG';
import './css/userinquiry.css';
import { SecurityContext } from "../context/securityContext";
import { TableColumn } from '../shared/components';
import { UserContext } from '../shared/authentication';
import { UserTypeCode } from "../shared/constants/userTypeCode";


interface RequestBodyDataType {
    followUpAction: string,
    inquiryID: 0,
    status: string,
    newAssignedTo?: string
}

interface UserInquirySearchForm {
    lastName: string,
    inquiryID: number,
    status: string,
    startDate: string,
    endDate: string,
    messageId: string,
    groupId: string,
    memberId: string,
    firstName: string
}

const defaultStatusesByPA = ["New", "Processing", "Assigned", "Closed"];
const defaultStatusesByCSR = ["New", "Processing", "Re-Assigned", "Closed"];
const FLOWING_ACTION_MAX_LENGTH: number = 500;
const STATUS_CLOSED: string = 'Closed';
const CSR_REG_CODE: string = '05';

const optionvalues: any = [
    {
        "name": "common.lbl.messageId",
        "value": "MessageId",
        "selected": true
    },
    {
        "name": "common.lbl.memberID",
        "value": "memberId",
        "selected": false
    },
    {
        "name": "common.lbl.name",
        "value": "Name",
        "selected": false
    },

    {
        "name": "common.lbl.status",
        "value": "Status",
        "selected": false
    },
    {
        "name": "common.lbl.dateRange",
        "value": "dateRange",
        "selected": false
    }
];
const optionvaluesWithoutMemberId: any = [
    {
        "name": "common.lbl.messageId",
        "value": "MessageId",
        "selected": true
    },
    {
        "name": "common.lbl.name",
        "value": "Name",
        "selected": false
    },

    {
        "name": "common.lbl.status",
        "value": "Status",
        "selected": false
    },
    {
        "name": "common.lbl.dateRange",
        "value": "dateRange",
        "selected": false
    }
];
const optionstatusvalues: any = [
    {
        "name": "common.lbl.all",
        "value": "All",
        "selected": true
    },
    {
        "name": "common.lbl.new",
        "value": "New",
        "selected": false
    },
    {
        "name": "common.lbl.assigned",
        "value": "Assigned",
        "selected": false
    },
    {
        "name": "common.lbl.processing",
        "value": "Processing",
        "selected": false
    },
    {
        "name": "common.lbl.reAssigned",
        "value": "Re-assigned",
        "selected": false
    },
    {
        "name": "common.lbl.closed",
        "value": "Closed",
        "selected": false
    },
    {
        "name": "common.lbl.sent",
        "value": "Sent",
        "selected": false
    }
];

export const UserInquiryComponent = withView((props: ViewComponentProps) => {

    const isMounted = useRef(false);
    const [config, setConfig] = useState<any>();
    const { messageService, validateProps } = useMessage("userinquiry");
    const [userList, setUserList] = useState<Array<any>>([]);
    const [userInquiries, setUserInquiries] = useState<Array<any>>([]);
    const [selectedInquiries, setSelectedInquiries] = useState<Array<string>>([]);

    const [showNewGrid, setShowNewGrid] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<string>();
    const message = useMessage("userInquiryDialog");
    const [singleUserInquiry, setSingleUserInquiry] = useState<any>();
    const [count, setCount] = useState<number>(0);
    const [currentTablePage, setCurrentTablePage] = useState<number>();
    const [currentStatus, setCurrentStatus] = useState<Array<any>>([]);
    const [query, setQuery] = useState<any>('');
    const { userAuth } = useContext(SecurityContext);
    const [table, setTable] = useState<any>();

    const [attachment, setAttachment] = useState<any>();
    const [attachmentError, setAttachmentError] = useState<any>(false);
    const [currentInquiryAttachments, setCurrentInquiryAttachments] = useState<any>();
    const userInfo = useContext(UserContext);
    const canAccessCSR = userInfo?.userTypeRegCode === '05'


    let requestBody4ChangeAssignOwner: Array<RequestBodyDataType> = []
    let requestBody4UpdateUserInquiry: RequestBodyDataType = {
        followUpAction: '',
        inquiryID: 0,
        status: ''
    }

    let requestBody4SearchInquiry: UserInquirySearchForm = {
        lastName: '',
        inquiryID: 0,
        status: '',
        startDate: '',
        endDate: '',
        messageId: '',
        memberId: '',
        groupId: '',
        firstName: ''
    }
    let defaultSearchInitialValues = {
        filterBySearch: 'MessageId',
        messageId: '',
        memberId: '',
        groupId: '',
        firstName: '',
        lastName: '',
        statuses: '',
        fromDate: '',
        throughDate: ''

    }

    const followUpActionSchema = yup.object().shape({
        followUpAction: YupSchema.followUpAction
    });

    const loadAllInquiries = () => {
        ajax({
            url: '/api/userInquiry/loomis/getAllContactForms',
            success: (res: Array<any>) => {
                if (res != null) {
                    setUserInquiries(res);
                }
            }
        })
    };

    useEffect(() => {
        if (isMounted.current) return;
        if (!config && config === undefined) {                
            ajax({
                url: '/api/userInquiry/loomis/view',
                success: (res: any) => {
                    setConfig(res);
                    setCurrentStatus(res.showChangeAssignedOwner ? defaultStatusesByPA : defaultStatusesByCSR);
                    setShowNewGrid(res.showNewGrid)
                    loadAllInquiries();
                    if (res.showChangeAssignedOwner) {
                        loadAllUserList();
                    }
                }
            });
        }
        isMounted.current = true;
    }, []);

    //Retrive the values 
    const retrieve = (values: any, setTable: any, isSubmitted?: boolean) => {
        if (isSubmitted !== true) {
            return;
        }
        if (values.filterBySearch) {
            let groupValue = values.filterBySearch === "memberId" ? (values.groupId === "") ? config.groups[0].value : values.groupId : "";
            requestBody4SearchInquiry = {
                lastName: values.lastName,
                inquiryID: 0,
                status: values.filterBySearch === 'Status' ? values.statuses : '',
                startDate: values.fromDate,
                endDate: values.throughDate,
                messageId: values.messageId,
                memberId: values.memberId,
                firstName: values.firstName,
                groupId: groupValue,
            }
        }
        ajax({
            url: `/api/userInquiry/loomis/search`,
            params: requestBody4SearchInquiry,
            method: 'GET',
            success: (res: Array<any>) => {
                if (res != null) {
                    setUserInquiries(res);
                }
            }

        });

    }

    //Delete attachement values 
    const deleteAttachements = (messageID: any, filename: any) => {
        const updateAttachment = currentInquiryAttachments.length > 1 ? currentInquiryAttachments.filter((file: any) => file !== filename) : [];
        setCurrentInquiryAttachments(updateAttachment);

        ajax({
            url: `/api/userInquiry/loomis/delete/${messageID.toString()}/${filename}`,
            method: 'GET',
            success: (res: Array<any>) => {
                if (res != null) {
                    setCurrentTablePage(userInquiryLists.page());
                }
                loadAllInquiries();
            }
        });

    }

    const tableColumn: Array<TableColumn> = [
        {
            title: 'forgpass.label.user.id', name: 'userId', render: (data: any, type: any, row: any, meta: any): string => {
                return `<a dt-href='/profile/${data}'>${data}</a>`
            }, width: "18%"
        },
        { title: 'common.lbl.name', name: 'name', width: "20%" },
        { title: 'security.lbl.userType', name: 'userType', width: "18%" },
        { title: 'profile.lbl.account.disabled', name: 'accountDisabled', width: "18%" },
        { title: 'profile.lbl.account.locked.out', name: 'accountLockedOut', width: "26%" }
    ]

    const resetSearchForm = (filterBySearch: string, formSearchProps: any, viewProps: any) => {

        formSearchProps.setFieldValue("messageId", '');
        formSearchProps.setFieldValue("memberId", '');
        formSearchProps.setFieldValue("groupId", '');
        formSearchProps.setFieldValue("firstName", '');
        formSearchProps.setFieldValue("lastName", '');
        formSearchProps.setFieldValue("messageId", '');
        formSearchProps.setFieldValue("fromDate", '');
        formSearchProps.setFieldValue("throughDate", '');

        viewProps.clearMessage();
        viewProps.setInitParams({ filterBySearch: filterBySearch, status: formSearchProps.values.status });
    }
    const resetSearchForm1 = (status: string, formSearchProps: any, viewProps: any) => {

        formSearchProps.setFieldValue("status", '');
        viewProps.clearMessage();
        viewProps.setInitParams({ status: status });
    }

    // define validation for by name.
    const byNameSchema = (values: any) => {
        let result: any = {};
        if (isEmptyStr(values.lastName)) {
            result.lastName = INVALID_MESSAGES.LASTNAME_IS_EMPTY;
        }
        return result;
    }


    const byGroupSchema = (values: any): any => {
        let result: any = {};
        if (values.groupId === "0" || isEmptyStr(values.groupId)) {
            result.groupId = INVALID_MESSAGES.GRUOP_IS_EMPTY;
        }
        if (isEmptyStr(values.memberId)) {
            result.memberId = INVALID_MESSAGES_ITG.MEMBER_ID_IS_EMPTY;
        }
        return result;
    }

    useEffect(() => {
        if (singleUserInquiry !== undefined) {
            props.clearMessage();
            props.showModal("#processingSingleInquiryDialog")
        }
    }, [count, singleUserInquiry])


    function loadAllUserList() {
        ajax({
            url: '/api/userInquiry/loomis/getAllGroupUsers',
            success: (res2: any) => {
                setUserList(res2);
            }
        });
    }

    //rebuild requestBody
    function changeAssignedOwner() {
        requestBody4ChangeAssignOwner = [];
        selectedInquiries.forEach((row: any) => {
            let tmp: RequestBodyDataType = {
                followUpAction: row.followUpAction,
                inquiryID: row.inquiryID,
                status: row.status,
                newAssignedTo: userAuth.userTypeRegCode === '05' ? userAuth.userID : selectedUser
            }
            requestBody4ChangeAssignOwner.push(tmp)
        })
        if (requestBody4ChangeAssignOwner.length > 0) {
            ajax({
                url: '/api/userInquiry/loomis/assignInquiry',
                data: requestBody4ChangeAssignOwner,
                method: 'POST',
                success: () => {
                    loadAllInquiries()
                    setCurrentTablePage(userInquiryLists.page());
                    setSelectedUser(undefined)
                    userInquiryLists.selectAll(false)
                    props.showMessage("success", props.getGlobalizedText("common.msg.updateSuccess"));
                },
                fail: (error: any) => {
                    props.showMessage("error", props.getGlobalizedText("inquiry.assignment.update.fail"));
                }
            })
        }
    }

    function assignInqToMyself() {
        selectedInquiries.some((row: any) => {
            if (row.status !== STATUS_CLOSED) {
                changeAssignedOwner();
            }

        })
    }

    function updateUserInquiry(values: any, formProps: any) {
        requestBody4UpdateUserInquiry = {
            followUpAction: values.followUpAction,
            inquiryID: singleUserInquiry.inquiryID,
            status: values.status
        }
        ajax({
            url: '/api/userInquiry/loomis/updateInquiry',
            data: requestBody4UpdateUserInquiry,
            method: 'POST',
            success: (res) => {
                if (attachment !== "" && attachment !== undefined && attachment.imgCollection.length > 0) {
                    let formData = new FormData();
                    for (const key of Object.keys(attachment.imgCollection)) {
                        formData.append('file', attachment.imgCollection[key])
                    }
                    var messageID = parseInt(singleUserInquiry.inquiryID)
                    ajax({
                        url: '/api/userInquiry/loomis/upload/' + messageID,
                        method: 'POST',
                        data: formData,
                        success: (res) => {
                            loadAllInquiries()
                        }, error: (error: any) => {
                            messageService.showMessage("error", error.response.data.message);
                        }, showMask: false
                    });
                }
                setAttachment("");
                setAttachmentError("");
                setCurrentTablePage(userInquiryLists.page())
                props.closeModal("#processingSingleInquiryDialog")
                formProps.resetForm();
                loadAllInquiries()
            }, fail: () => { }
        })
    }

    const filterNotify = userInquiries.filter(notify => config?.notifyMessageId?.includes(notify.inquiryID.toString()));
    const filterRegular = userInquiries.filter(notify => !config?.notifyMessageId?.includes(notify.inquiryID.toString()));
    let updatedData = [...filterNotify, ...filterRegular];
    const soryUpdatedDataByNew = updatedData.filter((item) => item.status === 'New');
    const soryUpdatedDataByNonNewStatus = updatedData.filter((item) => item.status !== 'New');
    updatedData = [...soryUpdatedDataByNew, ...soryUpdatedDataByNonNewStatus];
    const filterData = query !== "" ? updatedData.filter(item => parseInt(item.inquiryID) === parseInt(query)) : updatedData;

    const userLists = useMemo(() => useTable({
        modal: "viewInquiryDialog",
        id: "changeAssignedOwnerTable",
        option: { paging: false, scrollCollapse: true },
        select: {
            type: 'single', prefix: 'users_', onSelect: (e: any, rows: number[]) => {
                if (rows.length > 0) {
                    setSelectedUser(userList[rows[0]].userID);
                } else {
                    setSelectedUser(undefined);
                }
            }
        },
        table: {
            columns: [
                { title: "common.lbl.name", name: "name" },
                { title: "pd.lbl.email", name: "email" },
            ], data: userList
        }
    }), [userList]);

    const truncateQuestion = (question: string) => {
        if (question.length > 99) {
            const truncQuestion = `${question.slice(0, 99)}...`;
            return truncQuestion;
        } else {
            return question
        };
    }

    const userInquiryLists = useMemo(() => {
        let count1: number = 0;
        return useTable({

            select: (config !== undefined && config.showChangeAssignedOwner) ? {
                type: "multiple", prefix: "inquiry_", onSelect: (e: any, rows: Array<number>) => {
                    let tmp: string[] = [];
                    rows.forEach((row) => {
                        tmp.push(userInquiries[row]);
                    });
                    setSelectedInquiries(tmp);
                }
            } : undefined,
            id: "userInquiryTable",
            dateformat: props.getDateFormat(),
            delayAdjust: true,

            table: (userAuth.userTypeRegCode !== UserTypeCode.PORTAL_ADMIN) ? ((showNewGrid) ? {
                columns: [
                    {
                        title: "labelMessage.messageID", name: "inquiryID",
                        onClick: (e: any, row: any) => {
                            if (row.status !== "Re-Assigned") {
                                openUpdateUserInquiryDialog(row, count1++)
                            }
                        },
                        render: (data: any, type: any, row: any, meta: any) => {
                            if (row.status !== "Re-Assigned") {
                                return `<a class=${config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New' ? "bold" : ""}>${row.inquiryID}</a>`
                            } else { return `${row.inquiryID}` }
                        }
                    },
                    {
                        title: "common.lbl.questions", name: "question", render: (data: any, type: any, row: any, meta: any) => {
                            let question = row.question.replace(/\\+u([0-9a-fA-F]{4})/g, (a: any, b: any) => String.fromCharCode(parseInt(b, 16)));
                            if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') {
                                return `<span class="bold">${truncateQuestion(question)} ${row.identify ? (row.attachments ? `<img src='${getContextPath()}/static/themes/blue/images/attachment.png' class="img-size-20" />` : "") : ""}</span>`
                            } else {
                                return `${truncateQuestion(question)} ${row.identify ? (row.attachments ? `<img src='${getContextPath()}/static/themes/blue/images/attachment.png' class="img-size-20" />` : "") : ""}`
                            }
                        }
                    },
                    { title: "common.lbl.submitDate", name: "displayCreatedDate", type: 'date', render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.displayCreatedDate}</span>` } else { return `${row.displayCreatedDate}` } } },
                    { title: "common.lbl.time", name: "createdTime", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.createdTime}</span>` } else { return `${row.createdTime}` } } },
                    { title: "common.lbl.status", name: "status", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.status}</span>` } else { return `${row.status}` } } },
                ],
                data: filterData
            } : {
                columns: [
                    { title: "common.lbl.memberID", name: "memberId", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.memberId.toString()) || row.status === 'New') { return `<span class="bold">${row.memberId}</span>` } else { return `${row.memberId}` } } },
                    {
                        title: "labelMessage.messageID", name: "inquiryID",
                        onClick: (e: any, row: any) => {
                            if (row.status !== "Re-Assigned") {
                                openUpdateUserInquiryDialog(row, count1++)
                            }
                        },
                        render: (data: any, type: any, row: any, meta: any) => { if (row.status !== "Re-Assigned") { return `<a >${row.inquiryID}</a>` } else { return `${row.inquiryID}` } }
                    },
                    { title: "common.lbl.name", name: "name", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.name}</span>` } else { return `${row.name}` } } },
                    { title: "common.lbl.group", name: "group", render: (data: any, type: any, row: any, meta: any) => { if (!row.groupVO.name) return ""; if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold" title="${row.groupVO.groupID}">${row.groupVO.name}</span>` } else { return `<span title="${row.groupVO.groupID}">${row.groupVO.name}</span>` } } },

                    {
                        title: "common.lbl.questions", name: "question", render: (data: any, type: any, row: any, meta: any) => {
                            let question = row.question.replace(/\\+u([0-9a-fA-F]{4})/g, (a: any, b: any) => String.fromCharCode(parseInt(b, 16)));
                            if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') {
                                return `<span class="bold">${truncateQuestion(question)} ${row.identify ? (row.attachments ? `<img src='${getContextPath()}/static/themes/blue/images/attachment.png' class="img-size-20" />` : "") : ""}</span>`
                            } else {
                                return `${truncateQuestion(question)} ${row.identify ? (row.attachments ? `<img src='${getContextPath()}/static/themes/blue/images/attachment.png' class="img-size-20" />` : "") : ""}`
                            }
                        }
                    },
                    { title: "common.lbl.submitDate", name: "displayCreatedDate", type: 'date', render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.displayCreatedDate}</span>` } else { return `${row.displayCreatedDate}` } } },
                    { title: "common.lbl.time", name: "createdTime", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.createdTime}</span>` } else { return `${row.createdTime}` } } },
                    { title: "security.lbl.userType", name: "userType", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.userType}</span>` } else { return `${row.userType}` } } },
                    { title: "common.lbl.assignedOwner", name: "assignedTo", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.assignedTo}</span>` } else { return `${row.assignedTo}` } } },
                    { title: "common.lbl.status", name: "status", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.status}</span>` } else { return `${row.status}` } } },
                ],
                data: filterData
            }) : {
                columns: [
                    { title: "common.lbl.memberID", name: "memberId", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.memberId.toString()) || row.status === 'New') { return `<span class="bold">${row.memberId}</span>` } else { return `${row.memberId}` } } },
                    {
                        title: "labelMessage.messageID", name: "inquiryID",
                        onClick: (e: any, row: any) => {
                            if (row.status !== "Re-Assigned") {
                                openUpdateUserInquiryDialog(row, count1++)
                            }
                        },
                        render: (data: any, type: any, row: any, meta: any) => { if (row.status !== "Re-Assigned") { return `<a >${row.inquiryID}</a>` } else { return `${row.inquiryID}` } }
                    },
                    { title: "common.lbl.name", name: "name", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.name}</span>` } else { return `${row.name}` } } },
                    { title: "common.lbl.group", name: "group", render: (data: any, type: any, row: any, meta: any) => { if (!row.groupVO.name) return ""; if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold" title="${row.groupVO.groupID}">${row.groupVO.name}</span>` } else { return `<span title="${row.groupVO.groupID}">${row.groupVO.name}</span>` } } },

                    {
                        title: "common.lbl.questions", name: "question", render: (data: any, type: any, row: any, meta: any) => {
                            let question = row.question.replace(/\\+u([0-9a-fA-F]{4})/g, (a: any, b: any) => String.fromCharCode(parseInt(b, 16)));
                            if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') {
                                return `<span class="bold">${truncateQuestion(question)} ${row.identify ? (row.attachments ? `<img src='${getContextPath()}/static/themes/blue/images/attachment.png' class="img-size-20" />` : "") : ""}</span>`
                            } else {
                                return `${truncateQuestion(question)} ${row.identify ? (row.attachments ? `<img src='${getContextPath()}/static/themes/blue/images/attachment.png' class="img-size-20" />` : "") : ""}`
                            }
                        }
                    },
                    { title: "common.lbl.submitDate", name: "displayCreatedDate", type: 'date', render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.displayCreatedDate}</span>` } else { return `${row.displayCreatedDate}` } } },
                    { title: "common.lbl.time", name: "createdTime", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.createdTime}</span>` } else { return `${row.createdTime}` } } },
                    { title: "security.lbl.userType", name: "userType", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.userType}</span>` } else { return `${row.userType}` } } },
                    { title: "common.lbl.assignedOwner", name: "assignedTo", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.assignedTo}</span>` } else { return `${row.assignedTo}` } } },
                    { title: "common.lbl.status", name: "status", render: (data: any, type: any, row: any, meta: any) => { if (config?.notifyMessageId?.includes(row.inquiryID.toString()) || row.status === 'New') { return `<span class="bold">${row.status}</span>` } else { return `${row.status}` } } },
                ],
                data: filterData
            },
            onRendered: () => {
                if (config !== undefined && currentStatus !== undefined) {
                    if (config.showChangeAssignedOwner === true) {
                        userInquiryLists.filter(12, currentStatus, true, false)
                        userInquiryLists.page(currentTablePage)
                    } else {
                        userInquiryLists.filter(12, currentStatus, true, false)
                        userInquiryLists.page(currentTablePage)
                    }
                }
            }
        })
    }, [userInquiries, query]);

    const userListTable = useMemo(() => <userLists.Component />, [userLists]);
    const userInquiryTable = useMemo(() => <userInquiryLists.Component />, [userInquiryLists]);
    const viewInquiryDialog = useMemo(() => <Formik initialValues={{ searchUser: "" }} onSubmit={() => {
    }}>
        {formProps =>
            <props.Modal messageService={message.messageService} title="common.lbl.changeAssignedOwner" id="viewInquiryDialog" footer={() => {
                return <>
                    <props.Button onClick={() => {
                        if (selectedUser === undefined) { message.validateProps.showMessage("error", "common.msg.assigneeRequired"); }
                        else { changeAssignedOwner(); formProps.resetForm(); props.closeModal("#viewInquiryDialog"); message.validateProps.clearMessage(); userLists.filter(2, "", true, false) }
                    }} className="btn btn-primary gwp-btn"><GlobalizedText message="common.lbl.ok" /></props.Button>
                    <props.Button data-dismiss="modal" onClick={() => { message.validateProps.clearMessage(); formProps.resetForm(); userLists.filter(2, "", true, false) }} ><GlobalizedText message="common.lbl.close" /></props.Button>
                </>
            }}>
                <props.Row>
                    <props.Col sm="12">
                        <props.ComboboxControl hiddenLabel="searchUsers" hiddenLabelFor="searchUsers_input" name="searchUsers" onChange={(v: string) => {
                            userLists.filter(2, v.trim(), true, false);
                        }} onValueChange={(val: string) => { return userList.filter((user: any) => { return user.name.toLowerCase().startsWith(val.toLowerCase()) }).map((user: any) => { return user.name }) }}></props.ComboboxControl>
                    </props.Col>
                </props.Row>
                <props.Div style={{ maxHeight: 400, overflowY: "auto", overflowX: "hidden" }}>
                    {userListTable}
                </props.Div>
            </props.Modal >
        }
    </Formik >, [userListTable, userLists, selectedUser])

    const openUpdateUserInquiryDialog = (row: any, count1: number) => {
        let tmp = userInquiries.find((value: any, index: number) => { return value.inquiryID === row.inquiryID })
        setSingleUserInquiry(tmp)

        //If attachment list exists, remove any starting pipe, then split the remaining list by pipe.
        setCurrentInquiryAttachments(tmp.attachments?.replace(/^\s*\|/, '').split("|"))

        setCount(count1)

    }
    const isValidFileUploaded = (file: any) => {
        const validExtensions = props.userService.rootComponent.state.supportedFileTypes.split(",");
        const fileExtension = file.name.split('.')[1];
        return validExtensions.includes(fileExtension.toLowerCase());
    }
    const onChangeFileHandeler = (e: any) => {
        if (e.target.files !== undefined && e.target.files.length > 0) {
            const attachedFileValidationError = [];
            for (const key of Object.keys(e.target.files)) {
                attachedFileValidationError.push(isValidFileUploaded(e.target.files[key]));
            }
            if (attachedFileValidationError.includes(false)) {
                setAttachmentError("Invalid file name");
                setAttachment("");
            } else {
                setAttachment({ imgCollection: e.target.files });
                setAttachmentError("");
            }
        }
    }

    function verifyInquiryStatus() {
        let countClosedStatus: number = 0
        selectedInquiries.some((row: any) => {
            if (row.status === STATUS_CLOSED) {
                countClosedStatus = 1
                return true
            }
        })
        if (countClosedStatus === 0) {
            if (userAuth.userTypeRegCode !== CSR_REG_CODE) {
                props.showModal("#viewInquiryDialog")
            }
        } else {
            props.showModal("#assignClosedInquiries")
        }
    }

    if (config === undefined) {
        return <></>
    }

    let searchInitialValues = { ...defaultSearchInitialValues };
    return <props.Div id="userinquiry">

        {/* Message Search Inquiry start  */}
        <Formik initialValues={searchInitialValues}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                if (values.filterBySearch == "Name") {
                    return validate(byNameSchema, values, props);
                }
                if (values.filterBySearch === "memberId") {
                    return validate(byGroupSchema, values, props);
                }
            }}
            onSubmit={(values) => {
                document.documentElement.scrollTop = document.body.scrollTop = 0;
                props.clearMessage();
                retrieve(values, setTable, true);
                props.setInitParams({ ...values });
            }}
        >
            {formSearchProps =>
                <Form>
                    <props.Row>
                        <props.Col sm={5} md={5} xs={12}>
                            <props.SelectControl sort={false} name="filterBySearch" defaultOption={"MessageId"} onChange={(e: any) => {
                                setTable(null);
                                resetSearchForm(e.target.value, formSearchProps, props);
                            }} options={(['05', '01'].includes(userAuth?.userTypeRegCode)) ? optionvalues : optionvaluesWithoutMemberId} label="common.lbl.searchForMessageBy" />
                            <props.TextControl condition={formSearchProps.values.filterBySearch === 'Name'} required={true} id="lastName" name="lastName" label="common.lbl.lastName" />
                            <props.TextControl condition={formSearchProps.values.filterBySearch === 'Name'} id="firstName" name="firstName" label="common.lbl.firstName" />
                            <props.TextControl condition={formSearchProps.values.filterBySearch === 'MessageId'} id="messageId" name="messageId" label="common.lbl.messageId" />

                            <props.Col xs="12" sm="4" md="5">
                                <props.Row>
                                    <props.DateTimePickerControl condition={formSearchProps.values.filterBySearch === 'dateRange'} containerClassName="form-inline" className="input-group" max={new Date()} name="fromDate" label="common.fromdate" dateformat={props.getDateFormat()} />
                                </props.Row>
                            </props.Col>
                            <props.Col xs="12" sm="7" md="7">
                                <props.DateTimePickerControl condition={formSearchProps.values.filterBySearch === 'dateRange'} containerClassName="form-inline" className="input-group" max={new Date()} min={
                                    parseDate(formSearchProps.values.fromDate, props.getDateFormat())
                                } name="throughDate" label="payment.lbl.endDate" dateformat={props.getDateFormat()} />
                            </props.Col>

                            <props.SelectControl sort={false} condition={formSearchProps.values.filterBySearch === 'Status'} name="statuses" onChange={(e: any) => {

                                resetSearchForm1(e.target.value, formSearchProps, props);
                            }} options={optionstatusvalues} label="common.lbl.status" />
                            <GroupSelectControl sort={false} condition={formSearchProps.values.filterBySearch === 'memberId'} onChange={(e: any) => { "" }} id="groupId" name="groupId" label="common.lbl.group" required={true} options={config.groups} />

                            <props.TextControl condition={formSearchProps.values.filterBySearch === 'memberId'} required={true} id="memberId" name="memberId" label="common.lbl.memberIdentifier" />

                            <props.Col sm={3} md={3} xs={12}>
                                <props.Row>
                                    <props.Button type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                                </props.Row>
                            </props.Col>
                        </props.Col>

                    </props.Row>

                </Form>
            }
        </Formik>

        {/* Message search Inquiry End */}
        <props.Col className='divider'></props.Col>

        <props.Row>
            <props.Col sm="12" md="3" xs="3">
                <props.Button condition={config.showChangeAssignedOwner === true && userAuth.userTypeRegCode !== '05'} disabled={selectedInquiries.length === 0} className="btn btn-primary gwp-btn" onClick={() => { userLists.selectAll(false); verifyInquiryStatus() }}><GlobalizedText message="common.lbl.changeAssignedOwner" /></props.Button>
            </props.Col>
        </props.Row >
        <props.Row>
            <props.Col sm="12" md="3" xs="3">
                <props.Button condition={config.showAssignedMyself === true} disabled={selectedInquiries.length === 0} className="btn btn-primary gwp-btn" onClick={() => { userLists.selectAll(false); assignInqToMyself(); verifyInquiryStatus() }}><GlobalizedText message="common.lbl.assignedMyself" /></props.Button>
            </props.Col>
        </props.Row >
        {/**User Inquiry table */}
        {userInquiryTable}

        {/** User Inquiry dialog */}
        {config.showChangeAssignedOwner === true &&
            viewInquiryDialog
        }

        {/** process Inquiry Dialog */}
        {singleUserInquiry !== undefined &&
            // processingUserInquiry
            <Formik
                initialValues={{
                    status: singleUserInquiry.status,
                    followUpAction: singleUserInquiry.followUpAction
                }}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize={true}
                onReset={props.clearMessage}
                validate={values => {
                    return validate(followUpActionSchema, values, props);
                }}
                onSubmit={(values, formProps) => {
                    // formProps.resetForm();
                    updateUserInquiry(values, formProps)
                }
                }
            >
                {formProps =>
                    <Form>
                        <props.Modal title={singleUserInquiry.status !== "Closed" ? "common.lbl.processUserInquiry" : "common.lbl.viewUserInquiry"} id="processingSingleInquiryDialog" messageService={props.messageService} footer={() => {
                            return <>
                                <props.Button condition={singleUserInquiry.status !== "Closed" && showNewGrid === false} type="submit"><GlobalizedText message="common.lbl.save" /></props.Button>
                                <props.Button onClick={() => {
                                    setAttachment("");
                                    setAttachmentError("");
                                    props.closeModal("#processingSingleInquiryDialog"); formProps.resetForm()
                                }}><GlobalizedText message="common.lbl.close" /></props.Button>
                            </>
                        }}>
                            <props.Row>
                                <props.Col sm="4" md="4" xs="4">
                                    <props.DL>
                                        <props.DLI title="common.lbl.firstName" desc={singleUserInquiry.firstName}></props.DLI>
                                        <props.DLI title="common.lbl.lastName" desc={singleUserInquiry.lastName}></props.DLI>
                                        <props.DLI title="pd.lbl.phone" desc={singleUserInquiry.contactPhone}></props.DLI>
                                        <props.DLI title="pi.email.address" desc={singleUserInquiry.contactEmail}></props.DLI>
                                    </props.DL>
                                </props.Col>
                                <props.Col sm="4" md="4" xs="4">
                                    <props.DL>
                                        <props.DLI title="common.lbl.assignedDate" condition={singleUserInquiry.status !== "Closed"} desc={singleUserInquiry.displayAssignedDate}></props.DLI>
                                        <props.DLI title="common.lbl.status" desc={singleUserInquiry.status}></props.DLI>
                                        <props.DLI condition={singleUserInquiry.status === "Closed"} title="common.lbl.closedDate" desc={singleUserInquiry.displayUpdatedDate}></props.DLI>
                                        <props.DLI title="common.lbl.assignedOwner" desc={singleUserInquiry.assignedTo}></props.DLI>

                                    </props.DL>

                                </props.Col>
                                <props.Col sm="4" md="4" xs="4">
                                    <props.DL>
                                        {currentInquiryAttachments && currentInquiryAttachments.length > 0 && currentInquiryAttachments.map((attachment: any, index: any) => (
                                            <p key={index} className="download-placeholder text-wrap attachment-list"> <>

                                                {singleUserInquiry.attachments &&
                                                    <> {(singleUserInquiry.status !== 'Closed' && userAuth.userTypeRegCode === '05' || userAuth.userTypeRegCode === '03') && <CloseCircleOutlined onClick={(e: any) => {
                                                        deleteAttachements(singleUserInquiry.messageId * 1, attachment)
                                                    }} />
                                                    } <img src={`${getContextPath()}/static/themes/blue/images/attachment.png`} /> </>}
                                                <span onClick={() => {
                                                    downloadFile(`/api/userInquiry/read/${(singleUserInquiry.messageId * 1).toString()}/${attachment}`)
                                                }}>{attachment}</span></>
                                            </p>

                                        ))}

                                    </props.DL>

                                </props.Col>
                                <props.Col sm="12" md="4" className="file-group" condition={(canAccessCSR && singleUserInquiry.status === "Processing" || canAccessCSR && singleUserInquiry.status === "New" || canAccessCSR && singleUserInquiry.status === "Assigned")}>
                                    <p>{attachmentError && attachmentError !== "" && attachmentError}</p>
                                    {attachment && Object.keys(attachment?.imgCollection).map((index: any, file: any) => (
                                        <>
                                            <p key={index} className="download-placeholder text-wrap attachment-list">
                                                <img src={`${getContextPath()}/static/themes/blue/images/attachment.png`} />
                                                <span>{attachment?.imgCollection[index].name}</span>
                                            </p>
                                        </>
                                    ))}
                                    <label className="btn btn-primary gwp-btn ">
                                        <GlobalizedText message={attachment ? "common.lbl.attached" : "common.lbl.attachment"} />
                                        <input name="attachment" type="file" className="hidden-type" defaultValue={attachment} onChange={onChangeFileHandeler} multiple />
                                    </label>
                                </props.Col>

                            </props.Row>

                            <props.Row>
                                <props.Col sm="12" md="12" className="gwp-dl mt-2">
                                    <props.DLI title="common.lbl.question" desc={singleUserInquiry.question}></props.DLI>
                                </props.Col>
                                <props.Col sm="12" md="12" className="gwp-dl">
                                    <props.DLI title="common.lbl.followupAction" desc={() => {
                                        return <props.Div id="followActionClosed" className="">
                                            {singleUserInquiry.followUpAction}
                                        </props.Div>
                                    }}>
                                    </props.DLI>
                                    <props.SelectControl condition={(config.showChangeAssignedOwner === true && singleUserInquiry.status !== "Closed")} sort={false} style={{ width: "60%" }} name="status" label="common.lbl.status" options={[{ name: singleUserInquiry.status, value: singleUserInquiry.status }, { name: "New", value: "New" }, { name: "Assigned", value: "Assigned" }, { name: "Processing", value: "Processing" }, { name: "Closed", value: "Closed" }]} onChange={(e: any) => formProps.setFieldValue("status", e.target.value)} ></props.SelectControl>
                                    <props.SelectControl condition={(config.showChangeAssignedOwner === false && singleUserInquiry.status === "Processing" && showNewGrid === false)} sort={false} style={{ width: "60%" }} name="status" label="common.lbl.status" options={[{ name: singleUserInquiry.status, value: singleUserInquiry.status }, { name: "Closed", value: "Closed" }]} onChange={(e: any) => formProps.setFieldValue("status", e.target.value)} ></props.SelectControl>
                                    <props.SelectControl condition={(config.showChangeAssignedOwner === false && singleUserInquiry.status !== "Closed" && singleUserInquiry.status !== "Processing" && showNewGrid === false)} sort={false} style={{ width: "60%" }} name="status" label="common.lbl.status" options={[{ name: singleUserInquiry.status, value: singleUserInquiry.status }, { name: "New", value: "New" }, { name: "Assigned", value: "Assigned" }, { name: "Processing", value: "Processing" }, { name: "Closed", value: "Closed" }]} onChange={(e: any) => formProps.setFieldValue("status", e.target.value)} ></props.SelectControl>
                                    <props.TextareaControl condition={singleUserInquiry.status !== "Closed" && showNewGrid === false} style={{ width: "100%", maxWidth: "100%" }} maxLength={FLOWING_ACTION_MAX_LENGTH} label="common.lbl.followupAction" name="followUpAction" showRemaining={true} required={true}></props.TextareaControl>
                                </props.Col>
                            </props.Row>

                        </props.Modal>
                    </Form>
                }
            </Formik>
        }

        {/**Error Modal. Please select one status */}
        < props.Modal id="alertDialog" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                <props.Col sm="12" md="12" xs="12">
                    <props.Div className="alert alert-warning">
                        <props.H5>
                            <props.Span className="gwp-icon gwp-icon-warning gwp-icon-sm">
                            </props.Span>
                            <GlobalizedText message="common.msg.itemRequired"></GlobalizedText>123
                        </props.H5>
                    </props.Div>
                </props.Col>
            </props.Row>
        </props.Modal >
        {/* ChangeAssingedOwner by status === Closed */}
        < props.Modal id="assignClosedInquiries" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                <props.Col sm="12" md="12" xs="12">
                    <props.Div className="alert alert-warning">
                        <props.H5>
                            <props.Span className="gwp-icon gwp-icon-warning gwp-icon-sm"></props.Span>{" "}
                            <GlobalizedText message="common.msg.canNotBeChange"></GlobalizedText>
                        </props.H5>
                    </props.Div>
                </props.Col>
            </props.Row>
        </props.Modal >

    </props.Div>
});