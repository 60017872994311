import React, { useEffect, useState } from 'react';
import { ajax, ajaxTest, ajaxTestForYup, isEmptyStr, ValidationResult } from '../../utils';
import * as yup from 'yup';
import { Icon, Label } from '..';
import GlobalizedText from '../../globalization';

interface CaptchaState {
    id: string,
    picture: string
}

export interface CaptchaObject {
    /**
     * Id of the captcha
     */
    id: string,

    /**
     * Verify the answer manually
     */
    verify: (value: string, callback: (data: any) => void) => void,

    /**
     * Return yup StringSchema
     */
    fieldSchema: yup.StringSchema

    /**
     * Return customized validation function
     */
    fieldValidate: (v: string) => Promise<ValidationResult> | string

    /**
     * Verification endpoint URL
     */
    verificationUrl: string,

    /**
     * Component of the captcha
     */
    Component: () => JSX.Element
}


const CAPTCHA_VERIFICATION_URL = '/api/captcha/verify'

export const useCatpcha = (name: string): CaptchaObject => {

    let [captcha, setCaptcha] = useState<CaptchaState>();

    const verify = (value: string, callback: (data: any) => void): void => {
        ajax({
            url: CAPTCHA_VERIFICATION_URL,
            params: {
                id: captcha?.id,
                answer: value
            },
            showMask: false,
            success: callback,
        });
    }

    const fieldValidate = (v: string): Promise<ValidationResult> | string => {
        if (isEmptyStr(v)) {
            return "regist.msg.err.captcha.empty";
        } else {
            return ajaxTest({
                url: '/api/captcha/verify',
                params: { answer: v, id: captcha?.id },
                handleResult: (res: any) => { if (res.result === true) { refreshCaptcha(); return true } return new ValidationResult(name, "regist.msg.err.captcha.invalid"); },
                handleError: (error: any) => {
                    if (error.response.data.message.includes("expired")) {
                        refreshCaptcha();
                        return new ValidationResult(name, "regist.msg.err.captcha.expired");
                    }
                    return new ValidationResult(name, "regist.msg.err.captcha.invalid");
                }
            });
        }
    }

    const fieldSchema: yup.StringSchema = yup.string().trim().test(
        'captcha',
        'regist.msg.err.captcha.invalid',
        (v: any): Promise<boolean | yup.ValidationError> => {
            return ajaxTestForYup({
                url: '/api/captcha/verify',
                params: { answer: v, id: captcha?.id },
                handleResult: (res: any) => { if (res.result === true) { refreshCaptcha() } return res.result },
                handleError: (error: any) => {
                    if (error.response.data.message.includes("expired")) {
                        refreshCaptcha();
                        return new yup.ValidationError("regist.msg.err.captcha.expired", "", name);
                    }
                    return false
                }
            });
        }
    ).required("regist.msg.err.captcha.empty");

    const refreshCaptcha = () => {
        ajax({
            url: '/api/captcha/generate',
            success: (res) => {
                setCaptcha(res);
            },
            showMask: false
        });
    }

    useEffect(() => {
        if (captcha === undefined) {
            refreshCaptcha();
        }
    }, [captcha])


    return {
        verify: verify,
        verificationUrl: CAPTCHA_VERIFICATION_URL,
        fieldSchema: fieldSchema,
        fieldValidate: fieldValidate,
        Component: () => {
            return captcha === undefined ? <></> :
                <div className="gwp-captcha">
                    <img alt="" onClick={refreshCaptcha} src={captcha.picture} />
                    <Icon type="refresh" size="sm" onClick={refreshCaptcha}></Icon>
                    <Label onClick={refreshCaptcha}><GlobalizedText message="regist.label.refresh.captcha" /></Label>
                </div>
        },
        id: captcha === undefined ? '' : captcha.id
    }
};