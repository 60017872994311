import { ThemeConfig } from "../../../shared/theme";
import * as theme from './gray';
const ThemeComponents: ThemeConfig = {
    'gray': {
        component: theme,
        css: ['/static/customized/themes/gray/css/extension.css', '/static/customized/themes/gray/css/common.css', '/static/customized/themes/gray/css/gray.css'],
        layouts: {
            'default': theme.DefaultLayout,
            'empty': theme.EmptyLayout
        }
    }
};

export default ThemeComponents;