import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';
import { NAME_TYPE_COMPANY, NAME_TYPE_PERSON } from '../shared/components';
import { FeatureFlags } from '../shared/featureFlags';
import GlobalizedText from '../shared/globalization';
import { HelpBanner } from '../shared/helpbanner';
import { useMessage } from "../shared/message";
import { OnlineHelp } from '../shared/onlinehelp';
import { ajax, convertDate, formatNumber, generateRefreshSeed, getContextPath, isEmptyArray, isEmptyObject, isEmptyStr, mergeObject, parseDate, saveToStorage, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import './css/changepaymentmethod.css';
import { PAYMENT_METHOD_CREDITCARD, PAYMENT_METHOD_EFT, PAYMENT_METHOD_PREMIUM_NOTICE, updateSelected, Wallet, WalletProps } from './wallet';

function submitRequest(params: any, s: (res: any) => void, f: (res: any) => void) {
    ajax({
        url: '/api/paymentMethod/changeMethod',
        method: 'post',
        params: params,
        success: (res) => {
            s(res);
        }, fail: (res: any) => {
            f(res);
        }
    });
}

const getNextPaymentStartDate = (config: any, formProps: any, setNextPaymentStartDate?: Function) => {
    let nextPaymentStartDate: string = config?.dateRange[formProps.values.newPaymentFrequency]?.nextPaymentStartDate;
    if (!config.paymentDto.canChangeMethodDueToDate) {
        nextPaymentStartDate = config.paymentDto.nextPaymentStartDate;
    }
    if (setNextPaymentStartDate != undefined) {
        setNextPaymentStartDate(nextPaymentStartDate);
    }
    return nextPaymentStartDate
}

const getNextPaymentEndDate = (config: any, formProps: any, setNextPaymentEndDate?: Function) => {
    let nextPaymentEndDate: string = config?.dateRange[formProps.values.newPaymentFrequency]?.nextPaymentEndDate;
    if (!config.paymentDto.canChangeMethodDueToDate) {
        nextPaymentEndDate = config.paymentDto.nextPaymentEndDate;
    }
    if (setNextPaymentEndDate != undefined) {
        setNextPaymentEndDate(nextPaymentEndDate);
    }
    return nextPaymentEndDate;
}

export const ChangePaymentMethodComponent = withView((props: ViewComponentProps) => {

    const policyNumber = props.getParam("policyNumber");
    const companyCode = props.getParam("companyCode");
    const varFormPromp = useRef<any>();
    const merchantEmptyError = useRef<any>();
    const paymentDateRange = useRef<any>();
    const dateRangeError = useRef<any>();
    const messageService4Edit = useMessage("oepEdit");

    const [creditCard, setCreditCard] = useState<CreditCardInformation>();

    //this is PaymentMethodViewResponse
    const [config, setConfig] = useState<any | null>(null);
    //payment get from PaymentMethodViewResponse.PaymentDto
    const [payment, setPayment] = useState<any | null>(null);
    //used to split address to multiple lines 
    const [addressArray, setAddressArray] = useState<any | null>(null);
    //disabled checkbox flag of authorizeBox
    const [authorizeBoxDisabled, setAuthorizeBoxDisabled] = useState<any>(false);
    //check checkbox flag of authorizeBox & CreditauthorizeBox
    const [checkAuthrizeBox, setCheckAuthrizeBox] = useState<any>(false);

    //disabled flag of submit button
    const [disabledSubmitFlag, setDisabledSubmitFlag] = useState<any>(true);

    //flag which used to control changing payment is active or not
    const [isPaymentMethodEdit, setIsPaymentMethodEdit] = useState<any>(false);

    //drop down list of payment method
    const [paymentMethodList, setPaymentMethodList] = useState<any | null>(null);
    //drop down list of payment frequency
    const [paymentFrequencyList, setPaymentFrequencyList] = useState<any | null>(null);

    //disabled dropdown flag of payment method
    const [disablePaymentMethodList, setDisablePaymentMethodList] = useState<boolean>(true);
    //disabled dropdown flag of payment frequency
    const [disablePaymentFrequencyList, setDisablePaymentFrequencyList] = useState<boolean>(true);

    //flag which used to control credit card part display or not
    const [showCreditCardInfo, setShowCreditCardInfo] = useState<boolean>(false);
    //flag which used to control DirectPremiumInfo part display or not
    const [showDirectPremiumInfo, setShowDirectPremiumInfo] = useState<boolean>(false);
    //flag which used to control BankInfo part display or not
    const [showBankInfo, setShowBankInfo] = useState<boolean>(false);

    //flag which used to control new premium amount part display or not
    const [showNewPremiumAmount, setShowNewPremiumAmount] = useState<boolean>(false);
    //new calculated PremiumAmount, getting from backend
    const [newPremiumAmount, setNewPremiumAmount] = useState<any>('');

    //label of datetimepicker, need add date format,so re-construnct at view again
    const [nextPaymentDateLable, setNextPaymentDateLable] = useState<any>("common.lbl.nextPaymentDate");

    //Parameters in bank wallet
    const [walletPaymentMethod, setWalletPaymentMethod] = useState<any>(null);

    const [showCc, setShowCc] = useState<any>(null);

    const [showBank, setShowBank] = useState<any>(null);

    const [cancelWithBankChange, setCancelWithBankChange] = useState<any>(null);

    const [cancelWithCcChange, setCancelWithCcChange] = useState<any>(null);

    const [cancelWithNoData, setCancelWithNoData] = useState<any>(false);

    const [showError, setShowError] = useState<boolean>(false);

    const [count, showWallet] = useState<number>(0);

    const [walletProps, setWalletProps] = useState<WalletProps>(props);

    const events = useRef<Array<any>>([]);

    const [nextPaymentDateMsg, setNextPaymentDateMsg] = useState<any>(null);

    const [nextPaymentStartDate, setNextPaymentStartDate] = useState<any>(null);
    const [nextPaymentEndDate, setNextPaymentEndDate] = useState<any>(null);

    const changePaymentFrequency = props.isFeatureEnabled(FeatureFlags.CHANGE_PAYMENT_FREQUENCY);

    function reload() {
        ajax({
            url: '/api/paymentMethod/view',
            params: {
                companyCode: companyCode,
                policyNumber: policyNumber
            },
            success: (res: any) => {
                paymentDateRange.current = res.dateRange[res.paymentDto.billingMode];
                setConfig(res);
                setPayment(res.paymentDto);
            }
        });
    }

    useEffect(() => {
        openWallet("");
    }, [count])

    useEffect(() => {
        if (config === null) {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
            ajax({
                url: '/api/paymentMethod/view',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber
                },
                success: (res: any) => {
                    paymentDateRange.current = res.dateRange[res.paymentDto.billingMode];
                    setConfig(res);
                    setPayment(res.paymentDto);
                    setPaymentMethodList(res.paymentDto.paymentMethods);
                    if (res.paymentDto.payor.address !== '') {
                        setAddressArray(res.paymentDto.payor.address.split("<br/>"));
                    }
                    showOrHideFields(res.paymentDto.paymentCode);
                    let tmpLable = props.getGlobalizedText("common.lbl.nextPaymentDate") + '(' + res.dateFormatToShow + ')';
                    setNextPaymentDateLable(tmpLable);
                    dateRangeError.current = props.getGlobalizedText("common.msg.npdInvalid")
                        + "(" + convertDate(paymentDateRange.current.nextPaymentStartDate, "yyyy-MM-dd", res.dateFormat)
                        + " to " + convertDate(paymentDateRange.current.nextPaymentEndDate, "yyyy-MM-dd", res.dateFormat) + ")";
                    merchantEmptyError.current = `The Merchant for the PlanCode:${res.paymentDto.planCode}  is Empty,Please contact your administrator to add.`;
                    setWalletPaymentMethod(res.paymentDto.paymentCode);
                    varFormPromp.current.setFieldValue("nextPaymentDate", res.paymentDto.hasEFTInfo ? res.paymentDto.nextPaymentDate : '');
                }
            });
        } else {
            if (payment !== null) {
                events.current.forEach((eventHandler) => {
                    window.removeEventListener('message', eventHandler);
                });
                events.current.length = 0;
                let eventHanlder = receiveMessageFromIframe.bind({ showWallet: showWallet, setWalletProps: setWalletProps });
                events.current.push(eventHanlder);
                window.addEventListener('message', eventHanlder, false);
            }
            setNextPaymentDateMsg(props.getGlobalizedText("common.msg.next.pay.date.req") + " " + config.dateFormatToShow);

        }
        return () => {
            events.current.forEach((eventHandler) => {
                window.removeEventListener('message', eventHandler);
            });
            events.current.length = 0;
        };
    }, [config, payment]);
    function successCallback(callbackProps: any) {
        ajax({
            url: '/api/wallet/loadCreditCards',
            params: {
                companyCode: companyCode,
                policyNumber: policyNumber,
                clientProfileID: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.clientProfileID,
                creditCardToken: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.creditCardToken,
                merchant: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.merchant,
            },
            success: (res) => {
                let preferInfo = new Map();
                preferInfo.set("companyCode", companyCode);
                preferInfo.set("policyNumber", policyNumber);
                preferInfo.set("preClientProfileID", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.clientProfileID);
                preferInfo.set("preCreditCardToken", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.creditCardToken);
                preferInfo.set("preMerchant", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.merchant);
                preferInfo.set("preferredBankAct", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.preferredBankAct);
                preferInfo.set("preRoutingNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankRoutingNumber);
                preferInfo.set("preAccountNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankAccountNumber);
                preferInfo.set('messageService4Edit', messageService4Edit);
                preferInfo.set('originPaymentCode', config.paymentDto.paymentCode);
                callbackProps.setWalletProps({ ...props, walletDto: res.walletDto, paymentCode: PAYMENT_METHOD_CREDITCARD, preferInfo: preferInfo, handleWalletResult: handleWalletResult });
                callbackProps.showWallet(generateRefreshSeed());
            }
        });
    }
    function receiveMessageFromIframe(this: any, event: any) {
        let message = event.data.split(",");
        if (message[0] === 'ccCancel' || message[0] === 'ccFail') {
            props.closeModal('#addcciframe', () => props.showModal("#wallet"));
            props.closeModal('#editcciframe', () => props.showModal("#wallet"));
        } else if (message[0] === 'ccSuccess') {
            if (message[1] === 'addCreditCard') {
                props.closeModal('#addcciframe', () => successCallback(this));
            } else {
                props.closeModal('#editcciframe', () => successCallback(this));
            }
        } else if (message[0] === "addCreditCardSuccess") {
            setCreditCard({
                creditCardNumber: message[1],
                creditCardType: message[2],
                expirationDateDisplayed: message[3],
                expirationDate: message[4],
                billingID: message[5]
            });
            props.closeModal("#saveCreditCardModal");
        } else if (message[0] === "addCreditCardFail") {
            props.closeModal("#saveCreditCardModal");
        } else if (message[0] === "addCreditCardCancel") {
            props.closeModal("#saveCreditCardModal");
        }
    }

    const newPaymentMethodChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let paymentMethodVal = e.target.value;
        if (canChangePaymentMethod(formValue) !== true) {
            return;
        }
        showOrHideFields(paymentMethodVal);
        setWalletPaymentMethod(paymentMethodVal);
        setCheckAuthrizeBox(false);
        if (paymentMethodVal === '') {
            setPaymentFrequencyList(null);
            setShowNewPremiumAmount(false);
        } else {
            ajax({
                url: '/api/paymentMethod/getPaymentFrequencyList',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber,
                    dateFormat: config.dateFormat,
                    paymentMethodCode: payment.paymentCode,
                    toMethod: paymentMethodVal,
                    currentFrequency: payment.billingMode
                },
                success: (res) => {
                    setPaymentFrequencyList(res);
                    setFieldValue("newPaymentFrequency", res[0].value);
                }
            });
        }
    }

    const newPaymentFrequencyChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let paymentFrequencydVal = e.target.value;
        if (paymentFrequencydVal === '') {
            setShowNewPremiumAmount(false);
        } else {
            calcNewPremiumAmount(formValue.newPaymentMethod, paymentFrequencydVal, (res) => {
                setShowNewPremiumAmount(true);
                setNewPremiumAmount(res.newPremiumAmount);
                if (formValue.newPaymentMethod === PAYMENT_METHOD_PREMIUM_NOTICE) {
                    changeSubmitButtonStatus(false, checkAuthrizeBox, varFormPromp.current);
                }
            });
        }
    }

    function calcNewPremiumAmount(currentMethodCode: any, paymentFrequency: any, callback: (res: any) => void) {
        if (currentMethodCode !== "" && paymentFrequency !== "" && paymentFrequency !== undefined) {
            ajax({
                url: '/api/paymentMethod/newPremiumAmount',
                params: {
                    companyCode: payment.companyCode,
                    policyNumber: payment.policyNumber,
                    paymentFrequency: paymentFrequency,
                    paymentMethod: currentMethodCode,
                    paidToDate: payment.paidToDate,
                    isFlexiblePremium: payment.isFlexiblePremium
                },
                success: (res) => {
                    callback(res);
                }, error: () => {
                    setShowError(true);
                }
            });
        }
    }

    function translateCCType(ccType: any) {
        ccType = ccType.trim()
        let creditCardTypeDesc: any
        if (ccType === "MC") {
            creditCardTypeDesc = "MasterCard"
        }
        else if (ccType === "DISC") {
            creditCardTypeDesc = "Discover"
        }
        else if (ccType === "VISA") {
            creditCardTypeDesc = "VISA"
        }
        return creditCardTypeDesc;
    }
    function changePaymentInfo() {
        setAuthorizeBoxDisabled(false);

        setIsPaymentMethodEdit(true);

        setDisablePaymentMethodList(false);
        setDisablePaymentFrequencyList(false);
    }

    function cancelChange() {
        props.clearMessage();
        setIsPaymentMethodEdit(false);
        setWalletPaymentMethod(payment.paymentCode)
        setDisablePaymentMethodList(true);
        setDisablePaymentFrequencyList(true);
        setShowNewPremiumAmount(false);

        varFormPromp.current.setFieldValue("newPaymentMethod", '');
        varFormPromp.current.setFieldValue("newPaymentFrequency", '');

        let selPaymentMethod = convertPaymentMethod(payment.paymentMethod);

        showOrHideFields(selPaymentMethod);
        let fromCancel = true;
        changeSubmitButtonStatus(fromCancel, checkAuthrizeBox, varFormPromp.current);
        refreshPreferInfo(fromCancel);
    }
    function refreshPreferInfo(fromCancel: boolean) {
        setShowBank(null);
        setShowCc(null);
    }
    function canChangePaymentMethod(formValues: any): boolean {
        let result = true;

        if (payment.paymentCode === formValues.newPaymentMethod && payment.billingMode === formValues.newPaymentFrequency) {
            return true;
        }
        let allowChangeMethodFromDPN = payment.allowChangeMethodFromDPN;
        let paymentMethodTmp = payment.paymentMethod;
        let selectedMethod = formValues.newPaymentMethod;
        if (paymentMethodTmp === 'Direct Premium Notice' && allowChangeMethodFromDPN === false && selectedMethod !== PAYMENT_METHOD_PREMIUM_NOTICE) {
            showResult('failure');
            return false;
        }
        let canChangeMethodDueToDate = payment.canChangeMethodDueToDate;
        if (canChangeMethodDueToDate === false) {
            showResult('failure');
            return false;
        }

        return result;
    }
    function showResult(data: string) {
        if (data === 'success') {
            props.showMessage("success", 'payment.lbl.infor.updated');
        } else {
            setShowError(true);
        }

    }

    function showOrHideFields(paymentCode: any) {
        if (PAYMENT_METHOD_CREDITCARD === paymentCode) {
            setShowCreditCardInfo(true);
            setShowDirectPremiumInfo(false);
            setShowBankInfo(false);
        } else if (PAYMENT_METHOD_PREMIUM_NOTICE === paymentCode) {
            setShowDirectPremiumInfo(true);
            setShowCreditCardInfo(false);
            setShowBankInfo(false);
        } else {
            setShowBankInfo(true);
            setShowCreditCardInfo(false);
            setShowDirectPremiumInfo(false);
        }
    }
    function changeSubmitButtonStatus(fromCancel: boolean, checked: boolean, formProps: any, newCCTmp?: any, newBankTmp?: any) {
        let paymentMethodTmp = formProps.values.newPaymentMethod;
        let paymentFrequency = formProps.values.newPaymentFrequency;
        if (fromCancel) {
            paymentMethodTmp = "";
            paymentFrequency = "";
        }
        //get payment method and frquency
        if ((paymentMethodTmp === null || paymentMethodTmp === "" || paymentMethodTmp === undefined)
            && disablePaymentMethodList === true) {
            if ((paymentFrequency === null || paymentFrequency === "" || paymentFrequency === undefined)
                && disablePaymentFrequencyList === true) {
                paymentMethodTmp = convertPaymentMethod(payment.paymentMethod);
                paymentFrequency = payment.billingMode;
            }
        }

        if (paymentMethodTmp === PAYMENT_METHOD_PREMIUM_NOTICE) {
            checked = true;
        }

        let bPanel = isPanelEnabled(paymentMethodTmp, newCCTmp, newBankTmp);
        if ((changePaymentFrequency && checked === true && bPanel === true && !isEmptyStr(paymentMethodTmp)
            && !isEmptyStr(paymentFrequency)) ||
            (!changePaymentFrequency && checked === true && bPanel === true && !isEmptyStr(paymentMethodTmp))) {
            setDisabledSubmitFlag(false);
        } else {
            setDisabledSubmitFlag(true);
        }
    }

    function isPanelEnabled(paymentMethodTmp: string, newCCTmp?: any, newBankTmp?: any) {
        let newCC = (newCCTmp !== null && newCCTmp !== undefined) ? newCCTmp : showCc;
        let newBank = (newBankTmp !== null && newBankTmp !== undefined) ? newBankTmp : showBank;
        let bPanel = false;
        if (paymentMethodTmp === PAYMENT_METHOD_CREDITCARD) {
            if (showCreditCardInfo === true && (config.hasPreferredCreditCard === true || (newCC !== null && newCC !== undefined))) {
                bPanel = true;
            }
        } else if (paymentMethodTmp === PAYMENT_METHOD_EFT) {
            if (showBankInfo === true && config !== null && (config.hasPreferredBankAct === true || (newBank !== null && newBank !== undefined))) {
                bPanel = true;
            }
        } else if (paymentMethodTmp === PAYMENT_METHOD_PREMIUM_NOTICE) {
            if (config !== null && config.canUpdatePersonalInfo === true) {
                bPanel = true;
            }
        }
        return bPanel
    }

    function handleWalletResult(walletData: any) {
        if (walletData.get("cancelFromWallet") !== undefined) {
            //cancel
            walletCancel(false, walletData);
        } else if (walletData.get("selectFromWallet") !== undefined) {
            //continue
            walletContinue(walletData);
        } else if (walletData.get("saveBank") !== undefined) {
            ajax({
                url: '/api/wallet/loadBankAccounts',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber,
                    bankBranchNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankBranchNumber,
                    bankRoutingNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankRoutingNumber,
                    bankAccountNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankAccountNumber
                },
                success: (res) => {
                    let preferInfo = new Map();
                    preferInfo.set("companyCode", companyCode);
                    preferInfo.set("policyNumber", policyNumber);
                    preferInfo.set("preClientProfileID", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.clientProfileID);
                    preferInfo.set("preCreditCardToken", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.creditCardToken);
                    preferInfo.set("preMerchant", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.merchant);
                    preferInfo.set("preferredBankAct", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.preferredBankAct);
                    preferInfo.set("preRoutingNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankRoutingNumber);
                    preferInfo.set("preAccountNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankAccountNumber);
                    preferInfo.set('messageService4Edit', messageService4Edit);
                    preferInfo.set('originPaymentCode', config.paymentDto.paymentCode);
                    setWalletProps({ ...props, walletDto: res.walletDto, paymentCode: walletPaymentMethod, preferInfo: preferInfo, handleWalletResult: handleWalletResult });
                    showWallet(generateRefreshSeed());
                }
            });

        } else if (walletData.get("deleteCc") !== undefined) {
            ajax({
                url: '/api/wallet/loadCreditCards',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber,
                    clientProfileID: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.clientProfileID,
                    creditCardToken: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.creditCardToken,
                    merchant: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.merchant,
                },
                success: (res) => {
                    let preferInfo = new Map();
                    preferInfo.set("companyCode", companyCode);
                    preferInfo.set("policyNumber", policyNumber);
                    preferInfo.set("preClientProfileID", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.clientProfileID);
                    preferInfo.set("preCreditCardToken", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.creditCardToken);
                    preferInfo.set("preMerchant", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.merchant);
                    preferInfo.set("preferredBankAct", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.preferredBankAct);
                    preferInfo.set("preRoutingNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankRoutingNumber);
                    preferInfo.set("preAccountNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankAccountNumber);
                    preferInfo.set('messageService4Edit', messageService4Edit);
                    preferInfo.set('originPaymentCode', config.paymentDto.paymentCode);
                    setWalletProps({ ...props, walletDto: res.walletDto, paymentCode: PAYMENT_METHOD_CREDITCARD, preferInfo: preferInfo, handleWalletResult: handleWalletResult });
                    if (walletData.get("walletHasCC") === false) {
                        setShowCc(null);
                        setCancelWithNoData(true);
                    }
                    showWallet(generateRefreshSeed());
                }
            });

        } else if (walletData.get("deleteBank") !== undefined) {
            ajax({
                url: '/api/wallet/loadBankAccounts',
                params: {
                    companyCode: companyCode,
                    policyNumber: policyNumber,
                    bankBranchNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankBranchNumber,
                    bankRoutingNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankRoutingNumber,
                    bankAccountNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankAccountNumber
                },
                success: (res) => {
                    let preferInfo = new Map();
                    preferInfo.set("companyCode", companyCode);
                    preferInfo.set("policyNumber", policyNumber);
                    preferInfo.set("preClientProfileID", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.clientProfileID);
                    preferInfo.set("preCreditCardToken", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.creditCardToken);
                    preferInfo.set("preMerchant", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.merchant);
                    preferInfo.set("preferredBankAct", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.preferredBankAct);
                    preferInfo.set("preRoutingNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankRoutingNumber);
                    preferInfo.set("preAccountNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankAccountNumber);
                    preferInfo.set('messageService4Edit', messageService4Edit);
                    preferInfo.set('originPaymentCode', config.paymentDto.paymentCode);
                    setWalletProps({ ...props, walletDto: res.walletDto, paymentCode: walletPaymentMethod, preferInfo: preferInfo, handleWalletResult: handleWalletResult });
                    showWallet(generateRefreshSeed());
                    if (walletData.get("walletHasBB") === false) {
                        setShowBank(undefined);
                        setCancelWithNoData(true);
                    }
                }
            });

        }
    }
    function walletContinue(walletData: any) {

        let newCcTmp: any;
        newCcTmp = walletData.get("showCc");
        let newBankTmp: any;
        newBankTmp = walletData.get("showBank");
        if (null !== newCcTmp || null !== newBankTmp) {
            if (config === null) {
                ajax({
                    url: '/api/paymentMethod/view',
                    params: {
                        companyCode: companyCode,
                        policyNumber: policyNumber
                    },
                    success: (res: any) => {
                        if (null === newCcTmp || undefined === newCcTmp) {
                            if (res.preferredBankAct === undefined || (newBankTmp.bankRoutingNumber !== res.preferredBankAct.bankRoutingNumber ||
                                newBankTmp.bankAccountNumber !== res.preferredBankAct.bankAccountNumber ||
                                newBankTmp.bankBranchNumber !== res.preferredBankAct.bankBranchNumber)) {
                                //bank 
                                setCancelWithBankChange(false);
                                setShowBank(newBankTmp);
                                varFormPromp.current.setFieldValue("nextPaymentDate", '');
                                changeSubmitButtonStatus(false, checkAuthrizeBox, varFormPromp.current, null, newBankTmp);
                            }
                        } else if (res.preferredCreditCard === undefined || (
                            newCcTmp.clientProfileID !== res.preferredCreditCard.clientProfileID ||
                            newCcTmp.creditCardToken !== res.preferredCreditCard.creditCardToken ||
                            newCcTmp.merchant !== res.preferredCreditCard.merchant)
                        ) {//credit card 
                            setCancelWithCcChange(false);
                            setShowCc(newCcTmp);
                            changeSubmitButtonStatus(false, checkAuthrizeBox, varFormPromp.current, newCcTmp);
                        }
                    }
                });
            } else {
                if (null === newCcTmp || undefined === newCcTmp) {
                    if (config.preferredBankAct === undefined || (newBankTmp.bankRoutingNumber !== config.preferredBankAct.bankRoutingNumber ||
                        newBankTmp.bankAccountNumber !== config.preferredBankAct.bankAccountNumber ||
                        newBankTmp.bankBranchNumber !== config.preferredBankAct.bankBranchNumber)) {
                        //bank 
                        setCancelWithBankChange(false);
                        setShowBank(newBankTmp);
                        varFormPromp.current.setFieldValue("nextPaymentDate", '');
                        changeSubmitButtonStatus(false, checkAuthrizeBox, varFormPromp.current, null, newBankTmp);
                    }
                } else if (config.preferredCreditCard === undefined || (
                    newCcTmp.clientProfileID !== config.preferredCreditCard.clientProfileID ||
                    newCcTmp.creditCardToken !== config.preferredCreditCard.creditCardToken ||
                    newCcTmp.merchant !== config.preferredCreditCard.merchant) || (
                        newCcTmp.clientProfileID !== showCc?.clientProfileID ||
                        newCcTmp.creditCardToken !== showCc?.creditCardToken ||
                        newCcTmp.merchant !== showCc?.merchant
                    )
                ) {//credit card 
                    setCancelWithCcChange(false);
                    setShowCc(newCcTmp);
                    changeSubmitButtonStatus(false, checkAuthrizeBox, varFormPromp.current, newCcTmp);
                }
            }
        }
    }
    function walletCancel(firstOpen: any, walletData: any) {
        if (!firstOpen) {
            let delBankAccountNumber: any;
            let delBankBranchNumber: any;
            let delBankRoutingNumber: any;
            let deleteCcToken: any;
            let deleteProfileID: any;
            let delMerchant: any;


            delBankAccountNumber = walletData.get("delBankAccountNumber");
            delBankBranchNumber = walletData.get("delBankBranchNumber");
            delBankRoutingNumber = walletData.get("delBankRoutingNumber");
            deleteCcToken = walletData.get("deleteCcToken");
            deleteProfileID = walletData.get("deleteProfileID");
            delMerchant = walletData.get("delMerchant");
            let eftTableData = walletData.get("eftTableData");
            let ccTableData = walletData.get("ccTableData");

            if (null !== showBank && undefined !== showBank && walletPaymentMethod === PAYMENT_METHOD_EFT) {
                if (delBankRoutingNumber === showBank.bankRoutingNumber &&
                    delBankAccountNumber === showBank.bankAccountNumber &&
                    delBankBranchNumber === showBank.bankBranchNumber) {
                    //delete an existing bank
                    setShowBank(null);
                    setCancelWithBankChange(true);

                }
            } else if (null !== showCc && undefined !== showCc && walletPaymentMethod === PAYMENT_METHOD_CREDITCARD) {
                if (deleteProfileID === showCc.clientProfileID &&
                    deleteCcToken === showCc.creditCardToken &&
                    delMerchant === showCc.merchant) {
                    //delete an existing cc
                    setShowCc(null);
                }
            }

            reload();
        }
    }
    function showCCWallet() {
        if (cancelWithCcChange && config.preferredCreditCard !== undefined) {//had cancel with change
            config.preferredCreditCard.clientProfileID = "";
            config.preferredCreditCard.creditCardToken = "";
            config.preferredCreditCard.merchant = ""
        }
        let preferInfo = new Map();
        preferInfo.set("companyCode", companyCode);
        preferInfo.set("policyNumber", policyNumber);
        ajax({
            url: '/api/wallet/loadCreditCards',
            params: {
                companyCode: companyCode,
                policyNumber: policyNumber,
                clientProfileID: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.clientProfileID,
                creditCardToken: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.creditCardToken,
                merchant: (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.merchant,
            },
            success: (res) => {
                let preferredCredit = (showCc !== null && showCc !== undefined) ? showCc : config.preferredCreditCard;
                preferInfo.set("preClientProfileID", (preferredCredit === undefined) ? "" : preferredCredit.clientProfileID);
                preferInfo.set("preCreditCardToken", (preferredCredit === undefined) ? "" : preferredCredit.creditCardToken);
                preferInfo.set("preMerchant", (preferredCredit === undefined) ? "" : preferredCredit.merchant);
                preferInfo.set("preferredBankAct", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.preferredBankAct);
                preferInfo.set("preRoutingNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankRoutingNumber);
                preferInfo.set("preAccountNumber", (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankAccountNumber);
                preferInfo.set('messageService4Edit', messageService4Edit);
                preferInfo.set('originPaymentCode', payment.paymentCode);
                let walletDto = updateSelected(res.walletDto, walletPaymentMethod, preferInfo);
                setWalletProps({ ...props, walletDto: walletDto, paymentCode: walletPaymentMethod, preferInfo: preferInfo, handleWalletResult: handleWalletResult });
                showWallet(generateRefreshSeed());

            }
        });
    }

    function showEftWallet() {
        if (cancelWithBankChange && config.preferredBankAct !== undefined) {//had cancel with change
            config.preferredBankAct.bankBranchNumber = "";
            config.preferredBankAct.bankRoutingNumber = "";
            config.preferredBankAct.bankAccountNumber = ""
        }
        let preferInfo = new Map();
        preferInfo.set("companyCode", payment.companyCode);
        preferInfo.set("policyNumber", payment.policyNumber);
        ajax({
            url: '/api/wallet/loadBankAccounts',
            params: {
                companyCode: payment.companyCode,
                policyNumber: payment.policyNumber,
                bankBranchNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankBranchNumber,
                bankRoutingNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankRoutingNumber,
                bankAccountNumber: (config.preferredBankAct === undefined) ? "" : config.preferredBankAct.bankAccountNumber
            },
            success: (res) => {
                let preferredBank = (showBank !== null && showBank !== undefined) ? showBank : config.preferredBankAct;
                preferInfo.set("preClientProfileID", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.clientProfileID);
                preferInfo.set("preCreditCardToken", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.creditCardToken);
                preferInfo.set("preMerchant", (config.preferredCreditCard === undefined) ? "" : config.preferredCreditCard.merchant);
                preferInfo.set("preferredBankAct", (preferredBank === undefined) ? "" : preferredBank.preferredBankAct);
                preferInfo.set("preRoutingNumber", (preferredBank === undefined) ? "" : preferredBank.bankRoutingNumber);
                preferInfo.set("preAccountNumber", (preferredBank === undefined) ? "" : preferredBank.bankAccountNumber);
                preferInfo.set('messageService4Edit', messageService4Edit);
                preferInfo.set('originPaymentCode', payment.paymentCode);
                let walletDto = updateSelected(res.walletDto, walletPaymentMethod, preferInfo);
                setWalletProps({ ...props, walletDto: walletDto, paymentCode: walletPaymentMethod, preferInfo: preferInfo, handleWalletResult: handleWalletResult });
                showWallet(generateRefreshSeed());
            }
        });
    }

    function openWallet(type: string) {
        props.showModal("#changePaymentMethod #wallet");
    }

    const directPremiumNoticeSchema = yup.object().shape({
        newPaymentFrequency: YupSchema.changePaymentMethod.newPaymentFrequency(changePaymentFrequency, props.getGlobalizedText),
    });
    const creditCardDeductionSchema = yup.object().shape({
        newPaymentFrequency: YupSchema.changePaymentMethod.newPaymentFrequency(changePaymentFrequency, props.getGlobalizedText),
        newPaymentMethod: yup.string().trim()
            .test("newPaymentMethod", merchantEmptyError.current, (v) => {
                let tmp: any;
                if (showCc !== null) { tmp = showCc.merchant.toString().trim(); } else {
                    tmp = config.preferredCreditCard.merchant.toString().trim();
                }
                if (tmp === '') {
                    return false;
                }
                return true;
            })
    });

    function stringToDate(dateStr: any, separator: any) {
        if (!separator) {
            separator = "-";
        }
        let dateArr = dateStr.split(separator);
        let year = parseInt(dateArr[0]);
        let month;
        if (dateArr[1].indexOf("0") === 0) {
            month = parseInt(dateArr[1].substring(1));
        } else {
            month = parseInt(dateArr[1]);
        }
        let day = parseInt(dateArr[2]);
        let date = new Date(year, month - 1, day);
        return date;
    }
    function dateToString(date: any) {
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString();
        let day = (date.getDate()).toString();
        if (month.length === 1) {
            month = "0" + month;
        }
        if (day.length === 1) {
            day = "0" + day;
        }
        let dateTime = year + "-" + month + "-" + day;
        return dateTime;
    }

    // define Form Validate function.
    const formValidate = (values: any): any => {
        if (payment.canChangeMethod === false) {
            showResult('failure');
            return;
        }

        let paymentMethodTmp = varFormPromp.current.values.newPaymentMethod;
        let bPanel = isPanelEnabled(paymentMethodTmp);
        if (!bPanel && paymentMethodTmp === '') {
            paymentMethodTmp = convertPaymentMethod(payment.paymentMethod);
        }

        if (!bPanel && paymentMethodTmp !== PAYMENT_METHOD_EFT) {
            return {};
        }

        if (paymentMethodTmp === PAYMENT_METHOD_PREMIUM_NOTICE) {
            return validate(directPremiumNoticeSchema, values, props);
        } else if (paymentMethodTmp === PAYMENT_METHOD_CREDITCARD) {
            return validate(creditCardDeductionSchema, values, props);
        } else if (paymentMethodTmp === PAYMENT_METHOD_EFT) {
            return validate(yup.object().shape({
                nextPaymentDate: YupSchema.changePaymentMethod.nextPaymentDate(
                    nextPaymentStartDate, nextPaymentEndDate, values.nextPaymentDate, props.getDateFormat(), props.getGlobalizedText)

            }), values, props);
        }
    }

    const formSubmit = (values: any): void => {
        let paymentMethodTmp = varFormPromp.current.values.newPaymentMethod;

        if (paymentMethodTmp === '') {
            paymentMethodTmp = convertPaymentMethod(payment.paymentMethod);
        }
        let paymentSequencyTmp = varFormPromp.current.values.newPaymentFrequency;
        if ((changePaymentFrequency && paymentSequencyTmp === '') || !changePaymentFrequency) {
            paymentSequencyTmp = payment.billingMode;
        }
        if (paymentMethodTmp === PAYMENT_METHOD_PREMIUM_NOTICE) {

            submitRequest({
                paymentMethod: PAYMENT_METHOD_PREMIUM_NOTICE,
                companyCode: payment.companyCode,
                policyNumber: payment.policyNumber,
                billingMode: paymentSequencyTmp
            }, (res) => {
                saveToStorage("changePaymentMethodSuccess", res, true);
                props.back();
            }, (res) => {
                showResult('fail');
            });
        } else if (paymentMethodTmp === PAYMENT_METHOD_CREDITCARD) {
            let expirationDate: any
            if (showCc !== null && showCc !== undefined && showCc.ccExpirationYear !== undefined && showCc.ccExpirationMonth !== undefined) {
                expirationDate = showCc.ccExpirationYear + "-" + (showCc.ccExpirationMonth.length < 2 ? '0' + showCc.ccExpirationMonth : showCc.ccExpirationMonth);
            } else {
                let expirationDateTmp = config.hasPreferredCreditCard === true ? config.preferredCreditCard.ccStdExpireDateDesc : '';
                let month = expirationDateTmp.split("-")[1];
                let year = expirationDateTmp.split("-")[0];
                year = year.substr(0, 4);
                expirationDate = (year + "-" + month);
            }
            submitRequest({
                paymentMethod: PAYMENT_METHOD_CREDITCARD,
                companyCode: payment.companyCode,
                policyNumber: payment.policyNumber,
                billingMode: paymentSequencyTmp,
                clientProfileID: (showCc === null || showCc === undefined || showCc.dbClientProfileID === undefined) ? (config.hasPreferredCreditCard === true ? config.preferredCreditCard.clientProfileID : '') : showCc.dbClientProfileID,
                expirationDate: expirationDate,
                accountControlNumber: payment.accountControlNumber,
                creditCardType: (showCc === null || showCc === undefined || showCc.creditCardType === undefined) ? (config.hasPreferredCreditCard === true ? config.preferredCreditCard.creditCardType : '') : translateCCType(showCc.creditCardType.trim()),
                accountHolderName: (showCc === null || showCc === undefined || showCc.accountHolderNamewithSlash === undefined) ? (config.preferredCreditCard.accountHolderNamewithSlash) : showCc.accountHolderNamewithSlash,
                creditCardNumber: (showCc === null || showCc === undefined || showCc.ccNumber === undefined) ? (config.hasPreferredCreditCard === true ? config.preferredCreditCard.ccNumber : '') : showCc.ccNumber,
                creditCardToken: (showCc === null || showCc === undefined || showCc.creditCardToken === undefined) ? (config.hasPreferredCreditCard === true ? config.preferredCreditCard.creditCardToken : '') : showCc.creditCardToken,
                merchant: (showCc === null || showCc === undefined || showCc.merchant === undefined) ? (config.preferredCreditCard.merchant.toString().trim()) : showCc.merchant,
                billingID: (showCc === null || showCc === undefined || showCc.ccNumber === undefined) ? (config.hasPreferredCreditCard === true ? config.preferredCreditCard.ccNumber : '') : showCc.ccNumber
            }, (res) => {
                if (res.changeMethod === 'success') {
                    saveToStorage("changePaymentMethodSuccess", res, true);
                    props.back();
                } else {
                    if (res.changeMethod !== null && res.changeMethod.toLowerCase().indexOf("modal premium payments needed for payment frequency change") !== -1) {
                        props.showMessage("error", "payment.msg.modalpremiumchange");
                    } else {
                        showResult('fail');
                    }
                }
            }, () => showResult('fail')
            );
        } else if (paymentMethodTmp === PAYMENT_METHOD_EFT) {
            let haccountHolderName = '';
            let hbankName = '';
            let hbankRoutingNumber = '-1';
            let hbankAccountNumber = '0';
            let haccountType = '';
            if (showBank !== null) {
                hbankRoutingNumber = showBank.bankRoutingNumber;
                hbankAccountNumber = showBank.bankAccountNumber;
                if (showBank.nameType === '1') {
                    haccountHolderName = showBank.lastName.toString().trim() + '/' + showBank.firstName.toString().trim();
                } else {
                    haccountHolderName = showBank.companyName;
                }
                hbankName = showBank.bankName;
                haccountType = showBank.accountType;
            } else if (config !== null && config.hasPreferredBankAct === true && config.preferredBankAct !== undefined) {
                hbankRoutingNumber = config.preferredBankAct.bankRoutingNumber;
                hbankAccountNumber = config.preferredBankAct.bankAccountNumber;
                if (config.preferredBankAct.nameType === '1') {
                    haccountHolderName = config.preferredBankAct.lastName.toString().trim() + '/' + config.preferredBankAct.firstName.toString().trim();
                } else {
                    haccountHolderName = config.preferredBankAct.companyName;
                }
                hbankName = config.preferredBankAct.bankName;
                haccountType = config.preferredBankAct.accountType;
            }
            submitRequest({
                paymentMethod: PAYMENT_METHOD_EFT,
                companyCode: payment.companyCode,
                policyNumber: payment.policyNumber,
                billingMode: paymentSequencyTmp,
                accountHolderName: haccountHolderName,
                bankName: hbankName,
                routingNumber: hbankRoutingNumber,
                accountNumber: hbankAccountNumber,
                accountType: haccountType,
                nextPaymentDate: varFormPromp.current.values.nextPaymentDate,
                accountControlNumber: payment.accountControlNumber
            }, (res) => {
                if (res.changeMethod === 'success') {
                    saveToStorage("changePaymentMethodSuccess", res, true);
                    props.back();
                } else {
                    if (res.changeMethod !== null && res.changeMethod.toLowerCase().indexOf("modal premium payments needed for payment frequency change") !== -1) {
                        props.showMessage("error", "payment.msg.modalpremiumchange");
                    } else {
                        showResult('fail');
                    }
                }
            }, (res) => showResult('fail')
            );
        }
    }

    function convertPaymentMethod(paymentMethodTmp: any): string {
        if (paymentMethodTmp === 'Credit Card Deduction') {
            paymentMethodTmp = PAYMENT_METHOD_CREDITCARD;
        }
        if (paymentMethodTmp === 'Electronic Funds Transfer') {
            paymentMethodTmp = PAYMENT_METHOD_EFT;
        }
        if (paymentMethodTmp === 'Direct Premium Notice') {
            paymentMethodTmp = PAYMENT_METHOD_PREMIUM_NOTICE;

        }
        return paymentMethodTmp;
    }

    const showNameForCreditCard = () => {
        if (config !== null && payment.paymentCode === PAYMENT_METHOD_CREDITCARD) {
            if (
                ((showCc === undefined || showCc === null) && config.preferredCreditCard !== undefined && config.preferredCreditCard.inAccountControl === 'true') ||
                (showCc !== undefined && showCc !== null && config.preferredCreditCard !== undefined && showCc.clientProfileID === config.preferredCreditCard.clientProfileID && showCc.creditCardToken === config.preferredCreditCard.creditCardToken && config.preferredCreditCard.inAccountControl === 'true')
            ) {
                return <GlobalizedText message="common.lbl.accountholder" />;
            }

        }
        return <GlobalizedText message="common.msg.payment.nameOnCard" />;
    }

    const wallet = useMemo(() => {
        if (walletProps.preferInfo) {
            return <Wallet {...walletProps} />
        } else {
            return <props.Div></props.Div>
        }
    }, [walletProps]);

    const isWalletEnabled = props.isFeatureEnabled(FeatureFlags.WALLET);

    return <React.Fragment>

        <props.Row>
            <props.Col md="8" sm="7">
                <props.PageTitle>
                    <GlobalizedText message={"common.lbl.payment.information"} /> <OnlineHelp {...props} componentId={props.id}></OnlineHelp>
                </props.PageTitle>
                <props.PageHeader label={() => { return <><GlobalizedText message="common.lbl.policyNumber" />&nbsp;{policyNumber}</> }} />
            </props.Col>
            <props.Col md="4" sm="5" xs="12">
                <HelpBanner className="helpBanner" {...props}></HelpBanner>
            </props.Col>
        </props.Row>

        <props.Row className="currency">
            <props.Col sm='12'>
                <GlobalizedText message="common.lbl.allcurrencyvalue" />&nbsp; {payment !== null ? payment.currencyDesc : ''}
            </props.Col>
        </props.Row>

        <props.Panel>
            <props.PanelBody>
                <props.Row>
                    <props.Col sm="6">
                        <props.DL >
                            <props.DLI title="common.lbl.payor" desc={() => { return <props.Link to={`/personalInformation?companyCode=${companyCode}&policyNumber=${policyNumber}&personalInfoType=2&baseRiderCode=0&roleTypeCode=PAY`} >{payment !== null ? payment.payor.name : ''}</props.Link> }} ></props.DLI>
                            <props.DLI title="common.lbl.effectiveDate" desc={payment !== null ? payment.effectiveDate : ''}></props.DLI>
                            <props.DLI title="common.lbl.paidToDate" desc={payment !== null ? payment.paidToDate : ''}></props.DLI>
                            <props.DLI title="common.lbl.status" desc={payment !== null ? payment.status : ''}></props.DLI>
                        </props.DL>
                    </props.Col>
                    <props.Col sm="6">
                        <props.DL >
                            <props.DLI title="common.lbl.lastPaymentDate" desc={payment !== null ? payment.lastPaymentDate : ''}></props.DLI>
                            <props.DLI title="payment.lbl.currentMethod" desc={payment !== null ? payment.paymentMethod : ''}></props.DLI>
                            <props.DLI title="payment.lbl.currentFrequency" desc={payment !== null ? payment.paymentFrequency : ''}></props.DLI>
                            <props.DLI title="payment.lbl.currentPremiumAmount" desc={payment !== null ? formatNumber(parseFloat(payment.currentPremiumAmount)) : ''}></props.DLI>
                        </props.DL>
                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel>

        <props.Condition condition={showError}>
            <props.Information className="alert gwp-page-message alert-danger forgot-password-alert-danger"
                message="payment.msg.paymentMethodFailure"></props.Information>
        </props.Condition>

        {isWalletEnabled && <>
            <Formik initialValues={{
                newPaymentMethod: "",
                newPaymentFrequency: "",
                nextPaymentDate: '',
                firstName: "",
                infoCreditType: "",
                infoCreditHolder: "",
                infoCreditCardNumber: "",
                infoexpirationDate: ""
            }}
                validate={formValidate}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={formSubmit}
            >
                {formProps => {
                    varFormPromp.current = formProps;
                    return <Form>
                        <props.Panel>
                            <props.PanelHeading>
                                <div><GlobalizedText className='panelHeadSize' message="payment.lbl.newMethod" />
                                    <props.LinkButton condition={isPaymentMethodEdit === false && config?.changePaymentMethodByLimitTable === true} onClick={() => { changePaymentInfo() }}>
                                        <props.Icon type='update' label="common.lbl.update" />
                                    </props.LinkButton>

                                    <props.LinkButton condition={isPaymentMethodEdit === true && config?.changePaymentMethodByLimitTable === true} onClick={() => { cancelChange() }}>
                                        <props.Icon type='update' label="common.lbl.cancel" />
                                    </props.LinkButton>

                                </div>
                            </props.PanelHeading>

                            <props.PanelBody>
                                <props.Row>
                                    <props.Col sm="12" >
                                        {payment !== null && payment.canChangeMethodDueToDate === true && changePaymentFrequency &&
                                            <props.Information message="payment.lbl.pls.verify.prem.amount" />
                                        }
                                    </props.Col>
                                    <props.Col xs="12" sm="6" md='6'>
                                        <props.SelectControl name="newPaymentMethod" disabled={disablePaymentMethodList} label="payment.lbl.newMethod"
                                            options={paymentMethodList === null ? [{ name: "", value: "" }] : paymentMethodList}
                                            onChange={(e: any) => {
                                                newPaymentMethodChangeHandler(e, formProps.values, formProps.setFieldValue);
                                                changeSubmitButtonStatus(false, checkAuthrizeBox, { values: mergeObject(formProps.values, { newPaymentMethod: e.target.value }) });
                                            }}>
                                        </props.SelectControl>
                                    </props.Col>
                                    {(showNewPremiumAmount === true) &&
                                        <props.Col xs="12" sm="6" md="6">
                                            <div className="inforce-detail-group1" id="newPremiumAmount" >
                                                <props.Label>
                                                    <GlobalizedText message="payment.lbl.newPremiumAmount" />
                                                </props.Label>
                                            </div>

                                            <div>{newPremiumAmount}</div>
                                        </props.Col>
                                    }
                                    {changePaymentFrequency &&
                                        <props.Col xs='12' md='12'>
                                            <props.SelectControl id="newPaymentFrequency" name="newPaymentFrequency" disabled={disablePaymentFrequencyList} label="New Payment Frequency"
                                                options={paymentFrequencyList === null ? [{ name: "", value: "" }] : paymentFrequencyList}
                                                onChange={(e: any) => { newPaymentFrequencyChangeHandler(e, formProps.values, formProps.setFieldValue); changeSubmitButtonStatus(false, checkAuthrizeBox, { values: mergeObject(formProps.values, { newPaymentFrequency: e.target.value }) }); }}>
                                            </props.SelectControl>
                                        </props.Col>}
                                </props.Row>


                                {showDirectPremiumInfo === true &&
                                    <>
                                        <div>
                                            <label className="gwp-label-dp" >
                                                <GlobalizedText message="payment.lbl.billingAddress" />
                                            </label>
                                        </div>
                                        <div className="inforce-detail-group">
                                            <props.Link to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${companyCode}&policyNumber=${payment.policyNumber}&roleTypeCode=${payment.payor.relationRoleType}&baseRiderCode=${0}`}>{payment !== null ? payment.payor.name : ''}</props.Link>
                                            {
                                                addressArray !== null && addressArray.map((addressObj: any, index: number) => {
                                                    return <div key={index}>{addressObj}</div>
                                                })
                                            }
                                            <br />
                                            <props.Link condition={config.canUpdatePersonalInfo} to={`/personalInformation?isUpdateMode=${true}&personalInfoType=${2}&companyCode=${companyCode}&policyNumber=${policyNumber}&roleTypeCode=${payment.payor.relationRoleType}&baseRiderCode=${0}`} >
                                                <props.Icon type='person' label="common.lbl.updatePersonalInfo" />
                                            </props.Link>
                                        </div>
                                    </>
                                }

                            </props.PanelBody>
                        </props.Panel>

                        {showCreditCardInfo === true && <>
                            <props.Panel>
                                <props.PanelHeading>
                                    <div>
                                        <GlobalizedText className='panelHeadSize' message="common.lbl.credit.card.infor" />
                                        <a onClick={() => { showCCWallet() }} className='viewWallet' >
                                            <span className="gwp-icon gwp-icon-update gwp-icon-sm iconWalletSpace"></span>
                                            <GlobalizedText message="common.lbl.vieweditcc" />
                                        </a>
                                    </div>
                                </props.PanelHeading>
                                <props.PanelBody>
                                    <props.Row>
                                        <props.Col sm="12" xs="12" md="12">
                                            {!cancelWithCcChange && config !== null && (config.hasPreferredCreditCard === true || (showCc !== undefined && showCc !== null)) &&
                                                <props.Information message="payment.lbl.precc.below" />
                                            }
                                            {((cancelWithCcChange && !cancelWithNoData) || (config !== null && config.hasPreferredCreditCard !== true && (showCc === undefined || showCc === null) && config.hasTwoCreditCard === true)) &&
                                                <props.Information message="payment.msg.multipleAccInWt" />
                                            }
                                            {config !== null && config.hasPreferredCreditCard !== true && config.hasTwoCreditCard !== true && (showCc === undefined || showCc === null) &&
                                                <props.Information message="common.msg.creditCardRequired" />
                                            }
                                        </props.Col>
                                    </props.Row>
                                    {
                                        (config !== null && !cancelWithCcChange && (config.hasPreferredCreditCard === true || (showCc !== null && showCc !== undefined))) &&
                                        <props.Row id="rowPreferCard">
                                            <props.Col xs="12" sm="3" md="3" id="bdiPreferCardType">
                                                <dl className="gwp-dl">
                                                    <dt><GlobalizedText message="common.lbl.cred.card.type" /> </dt>
                                                    <dd id="infoCreditType">{((showCc === null || showCc === undefined || showCc.creditCardType === undefined) ? (config !== null ? config.preferredCreditCard.creditCardTypeDesc : '') : translateCCType(showCc.creditCardType.trim()))}
                                                    </dd>
                                                </dl>
                                            </props.Col>
                                            <props.Col xs="12" sm="3" md="3" id="bdiPreferCardHold">
                                                <dl className="gwp-dl">
                                                    <dt id="tlinfoCreditHolder">
                                                        {showNameForCreditCard()}
                                                    </dt>
                                                    <dd id="infoCreditHolder">{(showCc === null || showCc === undefined) ? (config !== null ? config.preferredCreditCard.accountHolderName : '') : showCc.accountHolderName}</dd>
                                                </dl>
                                            </props.Col>
                                            <props.Col xs="12" sm="3" md="3" id="bdiPreferCardNumber">
                                                <dl className="gwp-dl">
                                                    <dt>
                                                        <GlobalizedText message="common.lbl.creditcardnumber" />
                                                    </dt>
                                                    <dd id="infoCreditCardNumber">
                                                        xxxxxxxxxxxx{(showCc === null || showCc === undefined) ? (config.preferredCreditCard.ccNumber.toString().length > 4 ? config.preferredCreditCard.ccNumber.substring(config.preferredCreditCard.ccNumber.toString().length - 4) : config.preferredCreditCard.ccNumber) : showCc.ccNumber}</dd>
                                                </dl>
                                            </props.Col>
                                            <props.Col xs="12" sm="3" md="3" id="bdiPreferCardExpiration">
                                                <dl className="gwp-dl">
                                                    <dt>
                                                        <GlobalizedText message="common.lbl.expirationDate" />
                                                    </dt>
                                                    <dd id="infoexpirationDate">{(showCc === null || showCc === undefined) ? (config.preferredCreditCard.ccExpireDateDesc) : showCc.ccExpireDateDesc}</dd>
                                                </dl>
                                            </props.Col>
                                        </props.Row>
                                    }
                                </props.PanelBody>
                            </props.Panel>
                        </>
                        }

                        {showBankInfo === true && <>
                            <props.Panel>
                                <props.PanelHeading>
                                    <div><GlobalizedText className='panelHeadSize' message="payment.lbl.bankInfo" />
                                        <a onClick={() => { showEftWallet() }} className='viewWallet' >
                                            <span className="gwp-icon gwp-icon-update gwp-icon-sm iconWalletSpace"></span>
                                            <GlobalizedText message="common.lbl.vieweditcc" />
                                        </a>
                                    </div>
                                </props.PanelHeading>
                                <props.PanelBody>
                                    <props.Row>
                                        <props.Col sm="12">
                                            <div>
                                                {!cancelWithBankChange && config !== null && (config.hasPreferredBankAct === true || (showBank !== undefined && showBank !== null)) &&
                                                    <props.Information message="payment.lbl.enter.bankacc.next" />
                                                }
                                                {(cancelWithBankChange || (config !== null && config.hasPreferredBankAct !== true && (showBank === undefined || showBank === null) && config.hasTwoBankAct === true)) &&
                                                    <props.Information message="payment.msg.multipleAccInWt" />
                                                }
                                                {(!cancelWithBankChange && config !== null && config.hasPreferredBankAct !== true && config.hasTwoBankAct !== true && (showBank === undefined || showBank === null)) &&
                                                    <props.Information message="payment.lbl.precc.null" />
                                                }
                                            </div>
                                        </props.Col>
                                    </props.Row>
                                    {
                                        config !== null && !cancelWithBankChange && (config.hasPreferredBankAct === true || (showBank !== undefined && showBank !== null)) &&
                                        <props.Row id="preEFTInfoDiv">
                                            <props.Col xs="12" sm="6" md="6">
                                                <props.DL >
                                                    <props.DLI title="payment.lbl.nametype" desc={
                                                        (showBank === undefined || showBank === null) ? ("1" === config.preferredBankAct.nameType ? 'Person' : 'Company') : ("1" === showBank.nameType ? 'Person' : 'Company')
                                                    }></props.DLI>
                                                    <props.DLI title="payment.lbl.accountHolder" desc={
                                                        (showBank === undefined || showBank === null) ? (config.preferredBankAct.accountHolderName) : showBank.accountHolderName
                                                    }></props.DLI>
                                                    <props.DLI title="common.lbl.bankName" desc={
                                                        (showBank === undefined || showBank === null) ? (config.preferredBankAct.bankName) : showBank.bankName
                                                    }></props.DLI>
                                                    <props.DLI title="common.lbl.routingNumber" desc={
                                                        (showBank === undefined || showBank === null) ? (config.preferredBankAct.bankRoutingNumber + "") : showBank.bankRoutingNumber + ""
                                                    }></props.DLI>
                                                </props.DL>
                                            </props.Col>
                                            <props.Col xs="12" sm="6" md="6">
                                                <props.DL >
                                                    <props.DLI title="payment.lbl.accountNumber" desc={
                                                        (showBank === undefined || showBank === null) ? (config.preferredBankAct.bankAccountNumber) : showBank.bankAccountNumber
                                                    }></props.DLI>
                                                    <props.DLI title="payment.lbl.accountType" desc={
                                                        (showBank === undefined || showBank === null) ? (config.preferredBankAct.accountTypeDesc) : showBank.accountTypeDesc
                                                    }></props.DLI>
                                                    <props.DateTimePickerControl label={nextPaymentDateLable} useLocalDate={false} required={true} showFormat={false}
                                                        dateformat={config.dateFormatToShow} min={parseDate(getNextPaymentStartDate(config, formProps, setNextPaymentStartDate), props.getDateFormat())}
                                                        max={parseDate(getNextPaymentEndDate(config, formProps, setNextPaymentEndDate), props.getDateFormat())} name="nextPaymentDate"
                                                    />
                                                    <div className="inforce-detail-group">
                                                        <label className="information gwp-information">
                                                            <span id="nextInfoImg" className="expdateicon"></span>
                                                            <span id="spanDateRang" className="daterange">
                                                                <GlobalizedText message="payment.lbl.valid.date.range" /> {getNextPaymentStartDate(config, formProps)} - {getNextPaymentEndDate(config, formProps)}
                                                            </span>
                                                        </label>

                                                    </div>
                                                </props.DL>
                                            </props.Col>
                                        </props.Row>
                                    }
                                </props.PanelBody>
                            </props.Panel>
                        </>
                        }
                        <props.Row>
                            <props.Col sm="12" xs="12" md="12">
                                <props.Div id="updateInfo">
                                </props.Div>
                            </props.Col>
                        </props.Row>
                        <props.Row>
                            <props.Col sm="12" xs="12" md="12">
                                {(showBankInfo === true || showCreditCardInfo === true) && <>
                                    <div id="submitControl" style={{ 'background': 'repeat scroll 0% 0% rgb(242, 242, 242)' }}>
                                        <div className="inforce-detail-group">
                                            <label><GlobalizedText message="payment.lbl.autoPaymentAuth" /></label>
                                        </div>
                                        {(navigator.userAgent.indexOf('Firefox') >= 0) && <>
                                            <props.CheckboxControl id="authrizeBox" name="authrizeBox" className="fireFoxPlace"
                                                checked={checkAuthrizeBox ? true : false}
                                                onChange={() => { setCheckAuthrizeBox(!checkAuthrizeBox); changeSubmitButtonStatus(false, !checkAuthrizeBox, varFormPromp.current); }}
                                                disabled={authorizeBoxDisabled} ></props.CheckboxControl>
                                        </>
                                        }
                                        {(navigator.userAgent.indexOf('Firefox') < 0) && <>
                                            <props.CheckboxControl id="authrizeBox" name="authrizeBox"
                                                checked={checkAuthrizeBox ? true : false}
                                                onChange={() => { setCheckAuthrizeBox(!checkAuthrizeBox); changeSubmitButtonStatus(false, !checkAuthrizeBox, varFormPromp.current); }}
                                                disabled={authorizeBoxDisabled} ></props.CheckboxControl>
                                        </>
                                        }
                                        <GlobalizedText message="payment.lbl.auth.auto.trans.fund" />

                                    </div>
                                </>
                                }
                            </props.Col>
                            <props.Col sm="12" xs="12" md="12">
                                <props.Button type="submit" disabled={disabledSubmitFlag}>
                                    <GlobalizedText message="common.button.submit" />
                                </props.Button>
                                <props.Button type="button" onClick={() => { props.back() }} >
                                    <GlobalizedText message="common.lbl.close" />
                                </props.Button>

                            </props.Col>
                        </props.Row>
                    </Form>
                }
                }
            </Formik>
            {wallet}
        </>
        }
        {(!isWalletEnabled && payment) &&
            <NewPaymentMethodForNoneWallet {...props} setCreditCard={setCreditCard} changePaymentFrequency={changePaymentFrequency} creditCard={creditCard} showResult={showResult} calcNewPremiumAmount={calcNewPremiumAmount} addressArray={addressArray} canChangePaymentMethod={canChangePaymentMethod} newPremiumAmount={newPremiumAmount} showDirectPremiumInfo={showDirectPremiumInfo} companyCode={companyCode} policyNumber={policyNumber} convertPaymentMethod={convertPaymentMethod} showOrHideFields={showOrHideFields} config={config} payment={payment} />
        }
    </React.Fragment>
});

export interface NewPaymentMethodForNoneWalletProps extends ViewComponentProps {
    config: any,
    payment: any,
    addressArray?: any,
    companyCode: string,
    policyNumber: string,
    showDirectPremiumInfo: boolean,
    newPremiumAmount: string,
    showOrHideFields: (paymentCode: any) => void,
    convertPaymentMethod(paymentMethodTmp: any): string,
    canChangePaymentMethod: (formValue: any) => boolean,
    calcNewPremiumAmount: (newMethod: string, frequencyVal: string, res: any) => void,
    showResult: (message: string) => void,
    creditCard?: CreditCardInformation,
    setCreditCard: (creditCard: CreditCardInformation | undefined) => void,
    changePaymentFrequency: boolean,
}

interface CreditCardInformation {
    creditCardNumber: string,
    creditCardType: string,
    expirationDate: string,
    billingID: string,
    expirationDateDisplayed: string
}

export const NewPaymentMethodForNoneWallet = (props: NewPaymentMethodForNoneWalletProps) => {

    //flag which used to control changing payment is active or not
    const [isPaymentMethodEdit, setIsPaymentMethodEdit] = useState<any>(false);

    //drop down list of payment frequency
    const [paymentFrequencyList, setPaymentFrequencyList] = useState<any | null>(null);

    //disabled dropdown flag of payment method
    const [disablePaymentMethodList, setDisablePaymentMethodList] = useState<boolean>(true);

    const [newPremiumAmount, setNewPremiumAmount] = useState<string>();

    const [submitable, setSubmitable] = useState<boolean>(false);

    const [bankAccountEditing, setBankAccountEditing] = useState<boolean>(false);
    const [nextPaymentStartDate, setNextPaymentStartDate] = useState<any>(null);
    const [nextPaymentEndDate, setNextPaymentEndDate] = useState<any>(null);

    const defaultForm = {
        newPaymentMethod: "",
        newPaymentFrequency: "",
        nextPaymentDate: props.payment?.nextPaymentDate ? props.payment?.nextPaymentDate : "",
        firstName: props.payment?.firstName ? props.payment?.firstName : "",
        lastName: props.payment?.lastName ? props.payment?.lastName : "",
        companyName: props.payment?.companyName ? props.payment?.companyName : "",
        routingNumber: props.payment?.bankRoutingNumber,
        bankName: props.payment?.bankName,
        accountNumber: props.payment?.bankAccountNumber,
        accountType: props.payment?.bankAccountTypeCode ? props.payment?.bankAccountTypeCode : "C",
        autoPaymentAuth: false,
        nameType: props.payment?.companyName ? NAME_TYPE_COMPANY : NAME_TYPE_PERSON,
    }

    const formValidate = (values: any): any => {
        if (values.newPaymentMethod === PAYMENT_METHOD_EFT || (isEmptyStr(values.newPaymentMethod) && props.payment?.paymentCode === PAYMENT_METHOD_EFT)) {
            let validateSchema: any = {};
            if (values.nameType === NAME_TYPE_COMPANY) {
                validateSchema = yup.object().shape({
                    companyName: YupSchema.changePaymentMethod.companyName,
                    accountNumber: YupSchema.changePaymentMethod.accountNumber,
                    bankName: YupSchema.changePaymentMethod.bankName,
                    routingNumber: YupSchema.changePaymentMethod.routingNumber,
                    accountType: YupSchema.changePaymentMethod.accountType,
                    nextPaymentDate: YupSchema.changePaymentMethod.nextPaymentDate(
                        nextPaymentStartDate, nextPaymentEndDate, values.nextPaymentDate, props.getDateFormat(), props.getGlobalizedText)
                });
            } else {
                validateSchema = yup.object().shape({
                    firstName: YupSchema.changePaymentMethod.firstName,
                    lastName: YupSchema.changePaymentMethod.lastName,
                    accountNumber: YupSchema.changePaymentMethod.accountNumber,
                    bankName: YupSchema.changePaymentMethod.bankName,
                    routingNumber: YupSchema.changePaymentMethod.routingNumber,
                    accountType: YupSchema.changePaymentMethod.accountType,
                    nextPaymentDate: YupSchema.changePaymentMethod.nextPaymentDate(
                        nextPaymentStartDate, nextPaymentEndDate, values.nextPaymentDate, props.getDateFormat(), props.getGlobalizedText)
                });
            }
            return validate(validateSchema, values, props);
        }
    }

    const formSubmit = (values: any): any => {
        if (values.newPaymentMethod === PAYMENT_METHOD_PREMIUM_NOTICE) {
            submitRequest({
                paymentMethod: PAYMENT_METHOD_PREMIUM_NOTICE,
                companyCode: props.payment?.companyCode,
                policyNumber: props.payment?.policyNumber,
                billingMode: (!props.changePaymentFrequency || isEmptyStr(values.newPaymentFrequency)) ? props.payment?.billingMode : values.newPaymentFrequency,
            }, (res) => {
                saveToStorage("changePaymentMethodSuccess", res, true);
                props.back();
            }, (res) => {
                props.showResult('fail');
            });
        }
        else if (showAddCreditLink(formPropsRef.current)) {
            submitRequest({
                paymentMethod: PAYMENT_METHOD_CREDITCARD,
                companyCode: props.payment?.companyCode,
                policyNumber: props.payment?.policyNumber,
                billingMode: (!props.changePaymentFrequency || isEmptyStr(values.newPaymentFrequency)) ? props.payment?.billingMode : values.newPaymentFrequency,
                expirationDate: props.creditCard?.expirationDate,
                accountControlNumber: props.payment?.accountControlNumber,
                creditCardType: props.creditCard?.creditCardType,
                billingID: props.creditCard?.billingID,
                creditCardNumber: props.creditCard?.creditCardNumber
            }, (res) => {
                if (res.changeMethod === 'success') {
                    saveToStorage("changePaymentMethodSuccess", res, true);
                    props.back();
                } else {
                    if (res.changeMethod !== null && res.changeMethod.toLowerCase().indexOf("modal premium payments needed for payment frequency change") !== -1) {
                        props.showMessage("error", "payment.msg.modalpremiumchange");
                    } else {
                        props.showResult('fail');
                    }
                }
            }, () => props.showResult('fail')
            );
        } else if (values.newPaymentMethod === PAYMENT_METHOD_EFT ||
            (isEmptyStr(values.newPaymentMethod) && props.payment?.paymentCode === PAYMENT_METHOD_EFT)
        ) {
            submitRequest({
                paymentMethod: PAYMENT_METHOD_EFT,
                companyCode: props.payment?.companyCode,
                policyNumber: props.payment?.policyNumber,
                billingMode: (!props.changePaymentFrequency || isEmptyStr(values.newPaymentFrequency)) ? props.payment?.billingMode : values.newPaymentFrequency,
                accountHolderName: values.nameType === NAME_TYPE_COMPANY ? values.companyName : (values.firstName + " " + values.lastName),
                bankName: values.bankName,
                routingNumber: values.routingNumber,
                accountNumber: values.accountNumber,
                accountType: values.accountType,
                nextPaymentDate: values.nextPaymentDate,
                accountControlNumber: props.payment?.accountControlNumber
            }, (res) => {
                if (res.changeMethod === 'success') {
                    saveToStorage("changePaymentMethodSuccess", res, true);
                    props.back();
                } else {
                    if (res.changeMethod !== null && res.changeMethod.toLowerCase().indexOf("modal premium payments needed for payment frequency change") !== -1) {
                        props.showMessage("error", "payment.msg.modalpremiumchange");
                    } else {
                        props.showResult('fail');
                    }
                }
            }, (res) => props.showResult('fail')
            );
        }
    }

    function changePaymentInfo() {
        setIsPaymentMethodEdit(true);
        setDisablePaymentMethodList(false);
        setSubmitable(false);
    }

    function cancelChange(formProps: any) {
        props.clearMessage();
        setIsPaymentMethodEdit(false);
        setDisablePaymentMethodList(true);
        setNewPremiumAmount(undefined);
        setSubmitable(false);
        let selPaymentMethod = props.convertPaymentMethod(props.payment.paymentMethod);
        props.showOrHideFields(selPaymentMethod);
        formProps.setValues(defaultForm);
    }

    const newPaymentMethodChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let paymentMethodVal = e.target.value;
        if (props.canChangePaymentMethod(formValue) !== true) {
            return;
        }
        props.showOrHideFields(paymentMethodVal);
        if (paymentMethodVal === PAYMENT_METHOD_EFT) {
            setBankAccountEditing(true);
        }
        setPaymentFrequencyList(null);
        setNewPremiumAmount(undefined);
        if (!isEmptyStr(paymentMethodVal)) {
            ajax({
                url: '/api/paymentMethod/getPaymentFrequencyList',
                params: {
                    companyCode: props.companyCode,
                    policyNumber: props.policyNumber,
                    dateFormat: props.config?.dateFormat,
                    paymentMethodCode: props.payment?.paymentCode,
                    toMethod: paymentMethodVal,
                    currentFrequency: props.payment?.billingMode
                },
                success: (res) => {
                    setPaymentFrequencyList(res);
                    setFieldValue("newPaymentFrequency", res[0].value);
                }
            });
        }
    }

    const newPaymentFrequencyChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let paymentFrequencydVal = e.target.value;
        if (!isEmptyStr(paymentFrequencydVal)) {
            props.calcNewPremiumAmount(formValue.newPaymentMethod, paymentFrequencydVal, (res: any) => {
                setNewPremiumAmount(res.newPremiumAmount);
            });
        }
    }

    function openSaveCardModal() {
        ajax({
            url: "/api/paymentMethod/getSaveCreditCardURL",
            params: {
                companyCode: props.companyCode,
                policyNumber: props.policyNumber,
                paymentSelection: '',
                accountHolderName: props.payment?.payor.name,
                amount: 0
            }, success: (res) => {
                document.getElementById("saveCreditCardModalFrame")?.setAttribute("src", getContextPath() + res.savingCreditCardURL);
                props.showModal("#saveCreditCardModal");
            }, fail: () => {
                props.showResult("fail");
            }
        });
    }

    function changeSubmitButtonStatus(formValue: any) {
        if (props.payment?.paymentCode === PAYMENT_METHOD_EFT && isEmptyStr(formValue.newPaymentMethod) && formValue.autoPaymentAuth && bankAccountEditing === true) {
            setSubmitable(true);
            return;
        }
        if (formValue.newPaymentMethod === PAYMENT_METHOD_PREMIUM_NOTICE) {
            if (props.changePaymentFrequency) {
                setSubmitable((!isEmptyStr(formValue.newPaymentMethod) && !isEmptyStr(formValue.newPaymentFrequency)) ? true : false);
            } else {
                setSubmitable(!isEmptyStr(formValue.newPaymentMethod) ? true : false);
            }
            return;
        }
        if (!formValue.autoPaymentAuth) {
            setSubmitable(false);
        } else {
            if (formValue.newPaymentMethod === PAYMENT_METHOD_EFT) {
                setSubmitable(true);
            }
            if (showAddCreditLink(formPropsRef.current)) {
                if (isEmptyObject(props.creditCard)) {
                    setSubmitable(false);
                } else {
                    setSubmitable(true);
                }
            }
        }
    }

    const showAddCreditLink = (formProps: any) => {
        return formProps.values.newPaymentMethod === PAYMENT_METHOD_CREDITCARD || (props.payment.paymentCode === PAYMENT_METHOD_CREDITCARD && (formProps.values.newPaymentMethod === PAYMENT_METHOD_CREDITCARD || isEmptyStr(formProps.values.newPaymentMethod)))
    }

    const formPropsRef = useRef<any>();

    useEffect(() => {
        if (props.creditCard && formPropsRef.current.values.autoPaymentAuth === true) {
            setSubmitable(true);
        } else {
            setSubmitable(false);
        }
    }, [props.creditCard]);

    useEffect(() => {
        changeSubmitButtonStatus(mergeObject(formPropsRef.current.values));
    }, [bankAccountEditing]);

    return <>
        <Formik initialValues={defaultForm}
            validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps => {
                formPropsRef.current = formProps;
                return <Form>
                    <props.Panel>
                        <props.PanelHeading>
                            <div><GlobalizedText className='panelHeadSize' message="payment.lbl.newMethod" />
                                <props.LinkButton condition={isPaymentMethodEdit === false && props.config.changePaymentMethodByLimitTable === true} onClick={() => { changePaymentInfo() }}>
                                    <props.BootstrapIcon type='pencil' label="common.lbl.update" />
                                </props.LinkButton>

                                <props.LinkButton condition={isPaymentMethodEdit === true && props.config.changePaymentMethodByLimitTable === true} onClick={() => { cancelChange(formProps) }}>
                                    <props.BootstrapIcon type='pencil' label="common.lbl.cancel" />
                                </props.LinkButton>
                            </div>
                        </props.PanelHeading>

                        <props.PanelBody>
                            <props.Row>
                                <props.Col sm="12" >
                                    {props.payment !== null && props.payment.canChangeMethodDueToDate === true &&
                                        <props.Information message="payment.lbl.pls.verify.prem.amount" />
                                    }
                                </props.Col>
                                <props.Col xs="12" sm="6" md='6'>
                                    <props.SelectControl name="newPaymentMethod" disabled={disablePaymentMethodList} label="payment.lbl.newMethod"
                                        options={props.payment?.paymentMethods === null ? [{ name: "", value: "" }] : props.payment?.paymentMethods}
                                        onChange={(e: any) => {
                                            newPaymentMethodChangeHandler(e, formProps.values, formProps.setFieldValue);
                                            changeSubmitButtonStatus(mergeObject(formProps.values, { newPaymentMethod: e.target.value }));
                                            props.resetForm(formProps, { newPaymentMethod: e.target.value, newPaymentFrequency: formProps.values.newPaymentFrequency });
                                            if (isEmptyStr(e.target.value)) {
                                                setBankAccountEditing(false);
                                            }
                                            props.setCreditCard(undefined);
                                        }}>
                                    </props.SelectControl>
                                </props.Col>
                                <props.Col condition={newPremiumAmount !== undefined && props.changePaymentFrequency} xs="12" sm="6" md="6">
                                    <div className="inforce-detail-group1" id="newPremiumAmount" >
                                        <props.Label>
                                            <GlobalizedText message="payment.lbl.newPremiumAmount" />
                                        </props.Label>
                                    </div>

                                    <div>{newPremiumAmount}</div>
                                </props.Col>

                                <props.Col condition={props.changePaymentFrequency} xs='12' md='12'>
                                    <props.SelectControl id="newPaymentFrequency" name="newPaymentFrequency" disabled={!isPaymentMethodEdit} label="New Payment Frequency"
                                        options={paymentFrequencyList === null ? [{ name: "", value: "" }] : paymentFrequencyList}
                                        onChange={(e: any) => { newPaymentFrequencyChangeHandler(e, formProps.values, formProps.setFieldValue); changeSubmitButtonStatus(mergeObject(formProps.values, { newPaymentFrequency: e.target.value })); }}>
                                    </props.SelectControl>
                                </props.Col>
                                <props.Col condition={showAddCreditLink(formProps)} xs="12">
                                    <props.LinkButton onClick={openSaveCardModal} className="addCreditCard">
                                        <props.BootstrapIcon type="plus-sign" label="common.lbl.credit.card.infor" />
                                    </props.LinkButton>
                                </props.Col>
                                <props.Col xs="12" sm="6" md="6">
                                    <props.DL condition={!isEmptyObject(props.creditCard) && showAddCreditLink(formProps)}>
                                        <props.DLI title="common.lbl.creditcardnumber" desc={'xxxxxxxxxxxx' + props.creditCard?.creditCardNumber}></props.DLI>
                                    </props.DL>
                                </props.Col>
                                <props.Col xs="12" sm="6" md="6">
                                    <props.DL condition={!isEmptyObject(props.creditCard) && showAddCreditLink(formProps)}>
                                        <props.DLI title="common.lbl.expirationDate" desc={props.creditCard?.expirationDateDisplayed}></props.DLI>
                                    </props.DL>
                                </props.Col>
                            </props.Row>

                            <props.Row condition={formProps.values.newPaymentMethod === PAYMENT_METHOD_PREMIUM_NOTICE ||
                                (props.payment?.paymentCode === PAYMENT_METHOD_PREMIUM_NOTICE && isEmptyStr(formProps.values.newPaymentMethod))}>
                                <props.Col xs='12' md='12'>
                                    <div>
                                        <label className="gwp-label-dp" >
                                            <GlobalizedText message="payment.lbl.billingAddress" />
                                        </label>
                                    </div>
                                    <div className="inforce-detail-group">
                                        <props.Link to={`/personalInformation?isUpdateMode=${false}&personalInfoType=${2}&companyCode=${props.companyCode}&policyNumber=${props.policyNumber}&roleTypeCode=${props.payment?.payor.relationRoleType}&baseRiderCode=${0}`}>{props.payment !== null ? props.payment?.payor.name : ''}</props.Link>
                                        {
                                            props.addressArray?.map((addressObj: any, index: number) => {
                                                return <div key={index}>{addressObj}</div>
                                            })
                                        }
                                        <br />
                                        <props.Link condition={props.config?.canUpdatePersonalInfo} to={`/personalInformation?isUpdateMode=${true}&personalInfoType=${2}&companyCode=${props.companyCode}&policyNumber=${props.policyNumber}&roleTypeCode=${props.payment?.payor.relationRoleType}&baseRiderCode=${0}`} >
                                            <props.Icon id="updatePersonalInfo" type='person' label="common.lbl.updatePersonalInfo" />
                                        </props.Link>
                                    </div>
                                </props.Col>
                            </props.Row>

                        </props.PanelBody>
                    </props.Panel>
                    <props.Panel condition={(formProps.values.newPaymentMethod === PAYMENT_METHOD_EFT && !isEmptyArray(paymentFrequencyList))
                        || (isEmptyStr(formProps.values.newPaymentMethod) && props.payment?.paymentCode === PAYMENT_METHOD_EFT)
                    }>
                        <props.PanelHeading>
                            <GlobalizedText message="payment.lbl.bankInfo" />
                            <props.LinkButton condition={!bankAccountEditing} onClick={(e) => { setBankAccountEditing(true); }}><props.BootstrapIcon type='pencil' label="common.lbl.update" /></props.LinkButton>
                            <props.LinkButton condition={bankAccountEditing} onClick={(e) => { setBankAccountEditing(false); props.resetForm(formProps, { newPaymentMethod: formProps.values.newPaymentMethod, newPaymentFrequency: formProps.values.newPaymentFrequency }); }}><props.BootstrapIcon type='pencil' label="common.lbl.cancel" /></props.LinkButton>
                        </props.PanelHeading>
                        <props.PanelBody>
                            <props.Row>
                                <props.Col xs="12">
                                    <GlobalizedText message="payment.lbl.enter.account.infor"></GlobalizedText>
                                </props.Col>
                            </props.Row>
                            <props.Row condition={!bankAccountEditing}>
                                <props.Col xs="12" sm="6" md="6">
                                    <props.DL>
                                        <props.DLI title="payment.lbl.accountHolder" desc={`${formProps.values.nameType === NAME_TYPE_COMPANY ? formProps.values.companyName : (formProps.values.firstName + " " + formProps.values.lastName)}`}></props.DLI>
                                        <props.DLI title="common.lbl.bankName" desc={formProps.values.bankName}></props.DLI>
                                        <props.DLI title="common.lbl.routingNumber" desc={formProps.values.routingNumber}></props.DLI>
                                    </props.DL>
                                </props.Col>
                                <props.Col xs="12" sm="6" md="6">
                                    <props.DL>
                                        <props.DLI title="payment.lbl.accountNumber" desc={formProps.values.accountNumber}></props.DLI>
                                        <props.DLI title="payment.lbl.accountType" desc={props.getGlobalizedText(props.payment?.bankAccountTypes.filter((ba: any) => { return ba.value === formProps.values.accountType })[0].name)}></props.DLI>
                                        <props.DLI title="common.lbl.nextPaymentDate" desc={formProps.values.nextPaymentDate}></props.DLI>
                                    </props.DL>
                                </props.Col>
                            </props.Row>
                            <props.Row condition={bankAccountEditing}>
                                <props.Col xs="12" sm="6" md="6">
                                    <props.NameTypeSelectControl onChange={(e: any) => {
                                        props.resetForm(formProps, { newPaymentMethod: formProps.values.newPaymentMethod, newPaymentFrequency: formProps.values.newPaymentFrequency, nameType: e.target.value });
                                    }} required={true} name="nameType" label="addbene.lbl.choseType" />
                                    <props.TextControl required={true} condition={formProps.values.nameType === NAME_TYPE_COMPANY} id="companyName" name="companyName" label="payment.lbl.companyName" />
                                    <props.TextControl required={true} condition={formProps.values.nameType === NAME_TYPE_PERSON} id="firstName" name="firstName" label="payment.lbl.firstName" />
                                    <props.TextControl required={true} condition={formProps.values.nameType === NAME_TYPE_PERSON} id="lastName" name="lastName" label="payment.lbl.lastName" />
                                    <props.TextControl required={true} id="bankName" name="bankName" label="common.lbl.bankName" />
                                    <props.TextControl required={true} id="routingNumber" name="routingNumber" label="common.lbl.routingNumber" />
                                </props.Col>
                                <props.Col xs="12" sm="6" md="6">
                                    <props.TextControl required={true} id="accountNumber" name="accountNumber" label="payment.lbl.accountNumber" />
                                    <props.SelectControl required={true} name="accountType" label="payment.lbl.accountType" options={props.payment?.bankAccountTypes} />
                                    <props.DateTimePickerControl required={true} htmlFor="nextPaymentDate_input" id="nextPaymentDate" name="nextPaymentDate" label="common.lbl.nextPaymentDate" dateformat={props.getDateFormat()} min={parseDate(getNextPaymentStartDate(props.config, formProps, setNextPaymentStartDate), props.getDateFormat())} max={parseDate(getNextPaymentEndDate(props.config, formProps, setNextPaymentEndDate), props.getDateFormat())} />
                                    <props.Information className="nextPaymentDateRange" fontWeight="bold" message={props.getGlobalizedText("payment.lbl.valid.date.range") + " " + getNextPaymentStartDate(props.config, formProps) + "-" + getNextPaymentEndDate(props.config, formProps)}>
                                    </props.Information>
                                    <props.Div><img alt="" src={getContextPath() + "/static/themes/blue/images/bankAccount.png"} width="230" height="110" /></props.Div>
                                </props.Col>
                            </props.Row>
                        </props.PanelBody>
                    </props.Panel>
                    <props.Row>
                        <props.Col sm="12" xs="12" md="12">
                            <props.Div id="updateInfo">
                            </props.Div>
                        </props.Col>
                    </props.Row>
                    <props.Row condition={
                        (formProps.values.newPaymentMethod !== PAYMENT_METHOD_PREMIUM_NOTICE && !(props.payment?.paymentCode === PAYMENT_METHOD_PREMIUM_NOTICE && isEmptyStr(formProps.values.newPaymentMethod)))
                    }>
                        <props.Col xs="12">
                            <props.Div id="submitControl">
                                <props.Label><GlobalizedText message="payment.lbl.autoPaymentAuth" /></props.Label>
                                <props.CheckboxControl onChange={(e) => { changeSubmitButtonStatus(mergeObject(formProps.values, { autoPaymentAuth: e.target.checked })) }} id="autoPaymentAuth" name="autoPaymentAuth" value={true} ></props.CheckboxControl><props.Label className="normal" htmlFor="autoPaymentAuth"><GlobalizedText message="payment.lbl.auth.auto.trans.fund" /></props.Label>
                            </props.Div>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col sm="12" xs="12" md="12">
                        </props.Col>
                        <props.Col sm="12" xs="12" md="12">
                            <props.Button type="submit" disabled={!submitable}>
                                <GlobalizedText message="common.button.submit" />
                            </props.Button>
                            <props.Button type="button" onClick={() => { props.back() }} >
                                <GlobalizedText message="common.lbl.close" />
                            </props.Button>

                        </props.Col>
                    </props.Row>
                </Form>
            }}
        </Formik>
        <props.Modal size="large" id="saveCreditCardModal">
            <iframe style={{ marginLeft: '15px', width: '96%', border: 'none', height: '490px' }} title="Mock Saving Card" id="saveCreditCardModalFrame" src={`${getContextPath()}/mocksavecreditcard`} />
        </props.Modal>
    </>
}