import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import ContactMethodsDropdown from '../shared/components/contactMethodsDropdown';
import DependentInput from '../shared/components/lib/dependentInput';
import GlobalizedText from '../shared/globalization';
import { Message, useMessage } from '../shared/message';
import { ajax, convertDate, isEmptyStr, saveToStorage, trim, validate } from '../shared/utils';
import { ViewComponentProps } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import './css/personalcontactinfo.css';

export type ChangeType = 1 //Change Onwer
    | 2 // CHange Bene
    | 3 //Add second Addresee
    | 4 // change second
    | 5 // personal infor

export interface PersonalContactInfoProps extends ViewComponentProps {
    personalInfo: any
    states: Array<any>
    contactMethods: Array<any>
    changeType: ChangeType
    middleName?: string
    gender?: string
    govtID?: string
    roleTypeCode?: string
    companyCode?: string
    policyNumber?: string
    nameType?: string
    fromPage?: string
    clientNumberFromInsurance?: string
    updateMode: boolean
    updatePersonalInfoBody?: any
    callBackFuntion?: Function
    updateAble?: boolean
    relationshipSequenceNumber?: any
}

interface SelectOption {
    name: string
    value: string
}
interface PersonalInfo {
    streetAddress0: string,
    streetAddress1: string,
    streetAddress2: string,
    city: string,
    stateContry: string,
    zipCode: string,
    cellNum: string,
    homeNum: string,
    busiNum: string,
    facNum: string,
    email1: string,
    email2: string,
    conMeth: string,
}
interface RequestBody4ChangeRelation {
    changeType: number,
    roleTypeCode: string,
    companyCode: string,
    policyNumber: string,
    clientNumber: string,
    nameType: string,
    govtID: string,
    prefComm: string,
    govtIDTC: string,
    firstName: string,
    middleName: string,
    lastName: string,
    companyName: string,
    genderCode: string,
    birthDate: string,
    addrLine1: string,
    addrLine2: string,
    addrLine3: string,
    city: string,
    addrState: string,
    addrCountry: string,
    zip: string,
    cellPhone: string,
    homePhone: string,
    businessPhone: string,
    facsimilePhone: string,
    email1: string,
    email2: string,
    newClient: true,
    accepted: string,
    addressSequenceNumber: string,
    recurringStartDay: string,
    recurringStartMonth: string,
    primaryAddressID: string,
}
interface UpdatePersonalDataType {
    streetAddresses: Array<string>
    city: string,
    countryCode: string,
    stateCode: string,
    zipCode: string,
    businessPhone: string,
    cellPhone: string,
    homePhone: string,
    facsimilePhone: string,
    contactMethod: string,
    emails: Array<string>,
}
interface RequestBody4UpdatePeronsalInfoType1 { //  For member
    personalInfoType: number,
    companyCode: string,
    groupNumber: string,
    accountNumber: number,
    participantIDNumber: string,
    dependentSequenceNumber: number,
    updatedPersonInfo: UpdatePersonalDataType
}
interface RequestBody4UpdatePeronsalInfoType2 { // OW1, OW2, Payor and Insured
    personalInfoType: number,
    companyCode: string,
    roleTypeCode: string,
    policyNumber: string,
    baseRiderCode: number,
    relationshipSequenceNumber?: any,
    updatedPersonInfo: UpdatePersonalDataType
}

export const PersonalContactInfo = (props: PersonalContactInfoProps) => {

    const defaultState2 = useRef<Array<any>>([])
    const disbaledBtn = useRef<boolean>(true)
    const [showEmail2, setShowEmail2] = useState<boolean>(false);
    const [age, setAge] = useState<number>(0);
    const [zipcodeRequirued, setZipcodeRequirued] = useState<boolean | null>(null);
    const personalContactInfoForm = useRef<any>();
    const { messageService, validateProps } = useMessage("personalContactInfo");
    const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);

    useEffect(() => {
        if (zipcodeRequirued === null) {
            setIsUpdateMode(props.updateMode)
            defaultState2.current.push({ name: '', value: '' })
            props.states.forEach((rowData: any, index: number) => {
                if (rowData.countryCode === props.personalInfo.countryCode && rowData.stateCode === props.personalInfo.state) {
                    if (isEmptyStr(rowData.zipCodeEdit) === false) {
                        setZipcodeRequirued(true)
                    }
                }
                let nameValue = {
                    name: trim(rowData.stateName),
                    value: rowData.countryCode + "|" + rowData.stateCode
                }
                defaultState2.current.push(nameValue)
            })
        }
    }, [zipcodeRequirued])

    const requestBody = useRef<RequestBody4ChangeRelation>({
        changeType: 0,
        roleTypeCode: '',
        companyCode: '',
        policyNumber: '',
        clientNumber: '',
        nameType: '',
        govtID: '',
        prefComm: '',
        govtIDTC: '',
        firstName: '',
        middleName: '',
        lastName: '',
        companyName: '',
        genderCode: '',
        birthDate: '',
        addrLine1: props.personalInfo.newClient ? '' : props.personalInfo.streetAddresses[0],
        addrLine2: props.personalInfo.newClient ? '' : props.personalInfo.streetAddresses[1],
        addrLine3: props.personalInfo.newClient ? '' : props.personalInfo.streetAddresses[2],
        city: props.personalInfo.newClient ? '' : props.personalInfo.city,
        addrState: props.personalInfo.newClient ? '' : props.personalInfo.state,
        addrCountry: props.personalInfo.newClient ? '' : props.personalInfo.countryCode,
        zip: props.personalInfo.newClient ? '' : props.personalInfo.zipCode,
        cellPhone: props.personalInfo.newClient ? '' : props.personalInfo.cellPhone,
        homePhone: props.personalInfo.newClient ? '' : props.personalInfo.homephone,
        businessPhone: props.personalInfo.newClient ? '' : props.personalInfo.businessPhone,
        facsimilePhone: props.personalInfo.newClient ? '' : props.personalInfo.facsimilePhone,
        email1: props.personalInfo.newClient ? '' : props.personalInfo.email1,
        email2: props.personalInfo.newClient ? '' : props.personalInfo.email2,
        newClient: true,
        accepted: 'true',
        addressSequenceNumber: '',
        recurringStartDay: '',
        recurringStartMonth: '',
        primaryAddressID: ''
    })

    const requestBody4UpdatePersonalInfoType1 = useRef<RequestBody4UpdatePeronsalInfoType1>({
        personalInfoType: 1,
        companyCode: props.companyCode !== undefined ? props.companyCode : '',
        groupNumber: (props.updatePersonalInfoBody !== undefined && props.updatePersonalInfoBody.groupNumber !== undefined) ? props.updatePersonalInfoBody.groupNumber : '',
        accountNumber: (props.updatePersonalInfoBody !== undefined && props.updatePersonalInfoBody.accountNumber !== undefined) ? props.updatePersonalInfoBody.accountNumber : '',
        participantIDNumber: (props.updatePersonalInfoBody !== undefined && props.updatePersonalInfoBody.participantIDNumber !== undefined) ? props.updatePersonalInfoBody.participantIDNumber : '',
        dependentSequenceNumber: (props.updatePersonalInfoBody !== undefined && props.updatePersonalInfoBody.dependentSequenceNumber !== undefined) ? props.updatePersonalInfoBody.dependentSequenceNumber : '',
        updatedPersonInfo: {
            streetAddresses: props.personalInfo.streetAddresses,
            city: props.personalInfo.city,
            countryCode: props.personalInfo.countryCode,
            stateCode: props.personalInfo.stateCode,
            zipCode: props.personalInfo.zipCode,
            businessPhone: props.personalInfo.businessPhone,
            cellPhone: props.personalInfo.cellPhone,
            homePhone: props.personalInfo.homePhone,
            facsimilePhone: props.personalInfo.facsimilePhone,
            contactMethod: props.personalInfo.contactMethod,
            emails: props.personalInfo.emails
        }
    })

    const requestBody4UpdatePersonalInfoType2 = useRef<RequestBody4UpdatePeronsalInfoType2>({
        personalInfoType: 2,
        companyCode: props.companyCode !== undefined ? props.companyCode : '',
        roleTypeCode: props.roleTypeCode !== undefined ? props.roleTypeCode : '',
        policyNumber: props.policyNumber !== undefined ? props.policyNumber : '',
        baseRiderCode: (props.updatePersonalInfoBody !== undefined && props.updatePersonalInfoBody.baseRiderCode !== undefined) ? props.updatePersonalInfoBody.baseRiderCode : 0,
        relationshipSequenceNumber: props.relationshipSequenceNumber !== undefined ? props.relationshipSequenceNumber : '',
        updatedPersonInfo: {
            streetAddresses: props.personalInfo.streetAddresses,
            city: props.personalInfo.city,
            countryCode: props.personalInfo.countryCode,
            stateCode: props.personalInfo.stateCode,
            zipCode: props.personalInfo.zipCode,
            businessPhone: props.personalInfo.businessPhone,
            cellPhone: props.personalInfo.cellPhone,
            homePhone: props.personalInfo.homePhone,
            facsimilePhone: props.personalInfo.facsimilePhone,
            contactMethod: props.personalInfo.contactMethod,
            emails: props.personalInfo.emails
        }
    })

    const defaultInitialValues = {
        streetAddress0: props.personalInfo.newClient ? '' : props.personalInfo.streetAddresses[0],
        streetAddress1: props.personalInfo.newClient ? '' : props.personalInfo.streetAddresses[1],
        streetAddress2: props.personalInfo.newClient ? '' : props.personalInfo.streetAddresses[2],
        city: props.personalInfo.newClient ? '' : props.personalInfo.city,
        stateContry: returnPersonalInfoData('stateContry'),
        state: props.personalInfo.newClient ? '' : props.personalInfo.state,
        zipCode: props.personalInfo.newClient ? '' : props.personalInfo.zipCode,
        cellNum: props.personalInfo.newClient ? '' : props.personalInfo.cellPhone,
        homeNum: props.personalInfo.newClient ? '' : props.personalInfo.homePhone,
        busiNum: props.personalInfo.newClient ? '' : props.personalInfo.businessPhone,
        facNum: props.personalInfo.newClient ? '' : props.personalInfo.facsimilePhone,
        email1: returnPersonalInfoData('email1'),
        email2: returnPersonalInfoData('email2'),
        changeAuth: disbaledBtn.current,
        conMeth: props.personalInfo.newClient ? '' : props.personalInfo.contactMethod
    }
    //Function return 
    function returnPersonalInfoData(dataType: string) {
        if (dataType === 'stateContry') {
            if (props.changeType === 5) {
                if (props.personalInfo.newClient) { //props.personalInfo.newClient
                    return ''
                } else {
                    return props.personalInfo.countryCode + "|" + props.personalInfo.stateCode
                }
            } else {
                if (props.personalInfo.newClient) {
                    return ''
                } else {
                    return props.personalInfo.countryCode + "|" + props.personalInfo.state
                }
            }
        } else if (dataType === 'email1') {
            if (props.changeType === 5) {
                if (props.personalInfo.newClient) {
                    return ''
                } else {
                    return props.personalInfo.emails[0]
                }
            } else {
                if (props.personalInfo.newClient) {
                    return ''
                } else {
                    return props.personalInfo.email1
                }
            }

        } else if (dataType === 'email2') {
            if (props.changeType === 5) {
                if (props.personalInfo.newClient) {
                    return ''
                } else {
                    return props.personalInfo.emails[1]
                }
            } else {
                if (props.personalInfo.newClient) {
                    return ''
                } else {
                    return props.personalInfo.email2
                }
            }

        }
    }
    const validateZipcopdeNotRequired = yup.object().shape({
        streetAddress0: YupSchema.personalInfomation.streetAddress0,
        city: YupSchema.personalInfomation.city,
        stateContry: YupSchema.personalInfomation.stateContry,
        cellNum: YupSchema.personalInfomation.phoneNumber,
        busiNum: YupSchema.personalInfomation.phoneNumber,
        homeNum: YupSchema.personalInfomation.phoneNumber,
        facNum: YupSchema.personalInfomation.phoneNumber,
        email1: YupSchema.email,
        email2: YupSchema.email,
        zipCode: YupSchema.personalInfomation.zip(false, props.getGlobalizedText)
    })
    const validateZipcopdeRequired = yup.object().shape({
        streetAddress0: YupSchema.personalInfomation.streetAddress0,
        city: YupSchema.personalInfomation.city,
        stateContry: YupSchema.personalInfomation.stateContry,
        cellNum: YupSchema.personalInfomation.phoneNumber,
        busiNum: YupSchema.personalInfomation.phoneNumber,
        homeNum: YupSchema.personalInfomation.phoneNumber,
        facNum: YupSchema.personalInfomation.phoneNumber,
        email1: YupSchema.email,
        email2: YupSchema.email,
        zipCode: YupSchema.personalInfomation.zip(true, props.getGlobalizedText)
    })

    function enableZipCodeRequired(value: any) {
        let tmpCountryCode = value.split("|")[0]
        let tmpStateCode = value.split("|")[1]
        let tmpZipCode = ''

        props.states.forEach((row: any, index: number) => {
            if (row.countryCode === tmpCountryCode && row.stateCode === tmpStateCode) {
                tmpZipCode = row.zipCodeEdit
            }
        })

        if (!isEmptyStr(tmpZipCode)) {
            setZipcodeRequirued(true)
        } else {
            setZipcodeRequirued(false)
        }
    }

    function returnedFooter() {
        return <props.Button data-dismiss="modal" onClick={props.back}><GlobalizedText message="common.lbl.close" /></props.Button>
    }

    const formValidate = (values: PersonalInfo): any => {
        if (!isEmptyStr(values.cellNum)) {
            values.cellNum = values.cellNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        }
        if (!isEmptyStr(values.busiNum)) {
            values.busiNum = values.busiNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        }
        if (!isEmptyStr(values.homeNum)) {
            values.homeNum = values.homeNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        }
        if (!isEmptyStr(values.facNum)) {
            values.facNum = values.facNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        }
        if (zipcodeRequirued === true) {
            return validate(validateZipcopdeRequired, values, validateProps)
        } else {
            return validate(validateZipcopdeNotRequired, values, validateProps)
        }
    }

    const formSubmit = (values: PersonalInfo): void => {
        if (props.changeType !== 5) {
            requestBody.current.changeType = props.changeType
            requestBody.current.roleTypeCode = props.roleTypeCode !== undefined ? props.roleTypeCode : ''
            requestBody.current.companyCode = props.companyCode !== undefined ? props.companyCode : ''
            requestBody.current.policyNumber = props.policyNumber !== undefined ? props.policyNumber : ''
            requestBody.current.clientNumber = props.personalInfo.newClient ? '' : props.personalInfo.clientNumber
            requestBody.current.nameType = props.nameType !== undefined ? props.nameType : ''
            requestBody.current.govtID = isEmptyStr(props.personalInfo.ssn) ? props.govtID : props.personalInfo.ssn
            requestBody.current.prefComm = values.conMeth
            requestBody.current.govtIDTC = props.personalInfo.newClient && props.nameType !== undefined ? props.nameType : ''
            requestBody.current.firstName = props.personalInfo.firstName
            requestBody.current.middleName = props.middleName !== undefined ? props.middleName : ''
            requestBody.current.lastName = props.personalInfo.lastName
            requestBody.current.companyName = props.personalInfo.newClient && props.personalInfo.name !== undefined ? props.personalInfo.name : ''
            requestBody.current.genderCode = props.gender !== undefined && props.gender !== '0' ? props.gender : ''
            requestBody.current.birthDate = convertDate(props.personalInfo.birthDate, "yyyy-MM-dd", props.getDateFormat())
            requestBody.current.addrLine1 = values.streetAddress0
            requestBody.current.addrLine2 = values.streetAddress1
            requestBody.current.addrLine3 = values.streetAddress2
            requestBody.current.city = values.city
            requestBody.current.addrState = values.stateContry.split("|")[1]
            requestBody.current.addrCountry = values.stateContry.split("|")[0]
            requestBody.current.zip = values.zipCode
            if (props.personalInfo.newClient) {
                requestBody.current.cellPhone = values.cellNum
                requestBody.current.homePhone = values.homeNum
                requestBody.current.businessPhone = values.busiNum
                requestBody.current.facsimilePhone = values.facNum
            } else {
                requestBody.current.cellPhone = isEmptyStr(values.cellNum) ? '' : values.cellNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
                requestBody.current.homePhone = isEmptyStr(values.homeNum) ? '' : values.homeNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
                requestBody.current.businessPhone = isEmptyStr(values.busiNum) ? '' : values.busiNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
                requestBody.current.facsimilePhone = isEmptyStr(values.facNum) ? '' : values.facNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
            }
            requestBody.current.email1 = values.email1
            requestBody.current.email2 = showEmail2 === true ? values.email2 : ''
            requestBody.current.newClient = props.personalInfo.newClient
            requestBody.current.accepted = "true"
            requestBody.current.addressSequenceNumber = props.personalInfo.newClient ? '' : props.personalInfo.addressSequenceNumber
            requestBody.current.recurringStartDay = props.personalInfo.newClient ? '' : props.personalInfo.recurringStartDay
            requestBody.current.recurringStartMonth = props.personalInfo.newClient ? '' : props.personalInfo.recurringStartMonth
            ajax({
                url: '/api/changeRelation/changeRequest',
                method: 'POST',
                data: requestBody.current,
                success: (res: any) => {
                    props.clearMessage()
                    //saveToStorage
                    if (props.changeType === 1) {
                        props.showModal("#changeOWSuccess")
                    } else if (props.changeType === 2) {
                        props.showModal("#AddBeneSuccess")
                    } else if (props.changeType === 3) {
                        saveToStorage("addSecondaryAddresseeCallBack", { 'result': 'success' }, true);
                        props.back();
                    } else if (props.changeType === 4) {
                        saveToStorage("changeSecondaryAddresseeCallBack", { 'result': 'success' }, true);
                        props.back();
                    }
                },
                fail: (res: any, errorMsg: any) => {
                    validateProps.showMessage('error', errorMsg)
                }, error: (error: any) => {
                    let fieldError: any = {};
                    fieldError.stateContry = props.getGlobalizedText('pi.msg.updateFailure')
                    props.showFieldError("homepage.errorFields", fieldError, personalContactInfoForm.current, messageService)
                    validateProps.showMessage('error', error.response.data.message)
                }
            })
        } else { //update personal information
            if (props.updatePersonalInfoBody !== undefined && props.updatePersonalInfoBody.personalInfoType === 1) {
                let tmpAddr: Array<string> = []
                tmpAddr.push(values.streetAddress0)
                tmpAddr.push(values.streetAddress1)
                tmpAddr.push(values.streetAddress2)
                requestBody4UpdatePersonalInfoType1.current.updatedPersonInfo.streetAddresses = tmpAddr
                requestBody4UpdatePersonalInfoType1.current.updatedPersonInfo.city = values.city
                requestBody4UpdatePersonalInfoType1.current.updatedPersonInfo.countryCode = values.stateContry.split("|")[0]
                requestBody4UpdatePersonalInfoType1.current.updatedPersonInfo.stateCode = values.stateContry.split("|")[1]
                requestBody4UpdatePersonalInfoType1.current.updatedPersonInfo.zipCode = values.zipCode
                requestBody4UpdatePersonalInfoType1.current.updatedPersonInfo.cellPhone = isEmptyStr(values.cellNum) ? '' : values.cellNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
                requestBody4UpdatePersonalInfoType1.current.updatedPersonInfo.homePhone = isEmptyStr(values.homeNum) ? '' : values.homeNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
                requestBody4UpdatePersonalInfoType1.current.updatedPersonInfo.businessPhone = isEmptyStr(values.busiNum) ? '' : values.busiNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
                requestBody4UpdatePersonalInfoType1.current.updatedPersonInfo.facsimilePhone = isEmptyStr(values.facNum) ? '' : values.facNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
                let tmpEmails: Array<any> = []
                tmpEmails.push(values.email1)
                tmpEmails.push(showEmail2 === true ? values.email2 : '')
                requestBody4UpdatePersonalInfoType1.current.updatedPersonInfo.emails = tmpEmails
                requestBody4UpdatePersonalInfoType1.current.updatedPersonInfo.contactMethod = values.conMeth
                ajax({
                    url: '/api/personalInfo/updatePersonalInfo',
                    method: 'POST',
                    data: requestBody4UpdatePersonalInfoType1.current,
                    success: handleSuccess,
                    fail: (errorMsg: any) => {
                        validateProps.showMessage('error', errorMsg)
                    },
                    error: (error: any) => {
                        validateProps.showMessage('error', error.response.data.message)
                    }
                })
            }
            if (props.updatePersonalInfoBody !== undefined && props.updatePersonalInfoBody.personalInfoType === 2) {
                let tmpAddr: Array<string> = []
                tmpAddr.push(values.streetAddress0)
                tmpAddr.push(values.streetAddress1)
                tmpAddr.push(values.streetAddress2)
                requestBody4UpdatePersonalInfoType2.current.updatedPersonInfo.streetAddresses = tmpAddr
                requestBody4UpdatePersonalInfoType2.current.updatedPersonInfo.city = values.city
                requestBody4UpdatePersonalInfoType2.current.updatedPersonInfo.countryCode = values.stateContry.split("|")[0]
                requestBody4UpdatePersonalInfoType2.current.updatedPersonInfo.stateCode = values.stateContry.split("|")[1]
                requestBody4UpdatePersonalInfoType2.current.updatedPersonInfo.zipCode = values.zipCode
                requestBody4UpdatePersonalInfoType2.current.updatedPersonInfo.cellPhone = isEmptyStr(values.cellNum) ? '' : values.cellNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
                requestBody4UpdatePersonalInfoType2.current.updatedPersonInfo.homePhone = isEmptyStr(values.homeNum) ? '' : values.homeNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
                requestBody4UpdatePersonalInfoType2.current.updatedPersonInfo.businessPhone = isEmptyStr(values.busiNum) ? '' : values.busiNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
                requestBody4UpdatePersonalInfoType2.current.updatedPersonInfo.facsimilePhone = isEmptyStr(values.facNum) ? '' : values.facNum.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
                let tmpEmails: Array<any> = []
                tmpEmails.push(values.email1)
                tmpEmails.push((!isEmptyStr(values.email2) || showEmail2 === true) ? values.email2 : '')
                requestBody4UpdatePersonalInfoType2.current.updatedPersonInfo.emails = tmpEmails
                requestBody4UpdatePersonalInfoType2.current.updatedPersonInfo.contactMethod = values.conMeth

                // requestBody4UpdatePersonalInfoType2.current.
                ajax({
                    url: '/api/personalInfo/updatePersonalInfo',
                    method: 'POST',
                    data: requestBody4UpdatePersonalInfoType2.current,
                    success: handleSuccess,
                    fail: (errorMsg: any) => {
                        validateProps.showMessage('error', errorMsg)
                    },
                    error: (error: any) => {
                        validateProps.showMessage('error', error.response.data.message)
                    }
                })
            }
        }
    }

    function handleSuccess(res: any) {
        setIsUpdateMode(false)
        if (props.callBackFuntion !== undefined) {
            let message = '<span class="gwp-bold">'
            message += props.getGlobalizedText('pi.msg.update.title')
            message += '</span>'
            message += '<br/>'
            message += '<div>'
            message += props.getGlobalizedText('pi.msg.if.change')
            message += '</div>'
            validateProps.showMessage('success', message)
            props.callBackFuntion('success')
        }
    }

    return <div id="personalContactInfo">
        <props.Row>
            <Message messageService={messageService}></Message>
        </props.Row>
        {(isUpdateMode === false && props.changeType === 5) && <>
            <props.Row>
                <props.Col sm="12" md="12" xs="12">
                    <props.Div id="personalContactInfo_UpdateFalse" className="gwp-innertitle gwp-bold"><GlobalizedText message="pi.contact.info" />
                        <props.Link condition={props.updateAble !== undefined && props.updateAble === true} id="updateLink" to="" onClick={() => {
                            setIsUpdateMode(true)
                            validateProps.clearMessage()
                        }} style={{ fontSize: "12px" }}>
                            <props.Span className="gwp-icon gwp-icon-update gwp-icon-sm" style={{ marginLeft: '8px' }}></props.Span>{" "}
                            <GlobalizedText message="common.lbl.update" />
                        </props.Link>
                    </props.Div>
                </props.Col>
                <props.Col sm="6" md="6" xs="12">
                    <props.DL className="relateinfo dd">
                        <props.DLI title="common.lbl.streetAddress" desc={() => {
                            return <>
                                {props.personalInfo.streetAddresses.map((row: any, index: number) => {
                                    if (isEmptyStr(row)) {
                                        return <div key={index}></div>
                                    } else {
                                        return <React.Fragment key={index}>{row}<br /></React.Fragment>
                                    }
                                })}
                            </>
                        }}></props.DLI>
                        <props.DLI title="common.lbl.city" desc={props.personalInfo.city}></props.DLI>
                        <props.DLI title="common.lbl.stateCountry" desc={props.personalInfo.stateName + " " + props.personalInfo.countryCode}></props.DLI>
                        <props.DLI title="pi.zip.code" desc={props.personalInfo.zipCode}></props.DLI>
                    </props.DL>
                </props.Col>
                <props.Col sm="6" md="6" xs="12">
                    <props.DL className="relateinfo dd">
                        <props.DLI title="pi.phone.cell" desc={props.personalInfo.cellPhone}></props.DLI>
                        <props.DLI title="pi.phone.home" desc={props.personalInfo.homePhone}></props.DLI>
                        <props.DLI title="pi.phone.business" desc={props.personalInfo.businessPhone}></props.DLI>
                        <props.DLI title="pi.phone.facsimile" desc={props.personalInfo.facsimilePhone}></props.DLI>
                        <props.DLI title="pi.prefered.contract.method" desc={() => {
                            for (let rowData of props.contactMethods) {
                                if (props.personalInfo.contactMethod === rowData.value) {
                                    return rowData.name
                                }
                            }
                        }}></props.DLI>
                    </props.DL>
                </props.Col>
                <props.Col sm="12" md="12" xs="12">
                    <div className="emailSplit"></div>
                </props.Col>
                {props.personalInfo.emails.map((row: any, index: number) => {
                    return <>
                        <props.Col key={index} sm="6" md="6" xs="12">
                            <props.DL className="relateinfo dd">
                                <props.DLI title={() => {
                                    return <>
                                        <GlobalizedText message="pi.email.address" />{" " + (index + 1)}
                                    </>
                                }} desc={props.personalInfo.emails[index]}></props.DLI>
                            </props.DL>
                        </props.Col>
                    </>
                })}
                <props.Col sm="12" md="12" xs="12">
                    <props.Button id="cancelUpdatePersonalInfo" onClick={props.back}><GlobalizedText message="common.lbl.close" /></props.Button>
                </props.Col>
            </props.Row>
        </>
        }

        {
            isUpdateMode === true && <>
                <props.Row>
                    <props.Col xs="12">
                        <props.Div className="gwp-innertitle  gwp-bold">
                            <GlobalizedText message="pi.contact.info" />
                        </props.Div>
                    </props.Col>
                    <props.Col id="contactWarn" xs="12">
                        <props.Span className="glyphicon glyphicon-exclamation-sign warnInfo" aria-hidden="true"></props.Span>{' '}
                        <props.Span className="warnInfo">
                            <GlobalizedText message="pi.msg.update.tip" />
                        </props.Span>
                    </props.Col>
                </props.Row>
                <Formik initialValues={defaultInitialValues}
                    enableReinitialize={true}
                    validate={formValidate}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={formSubmit}
                >
                    {formProps => {
                        personalContactInfoForm.current = formProps
                        return <Form id="personalContactInfo">
                            <props.Row>
                                <props.Col sm="6" md="6" xs="12">
                                    <props.TextControl id="streetAddress0" name="streetAddress0" maxlength="30" required={true} label="common.lbl.streetAddress"
                                        onChange={(e) => {
                                            requestBody.current.addrLine1 = e.target.value
                                        }}></props.TextControl>
                                    <props.TextControl id="streetAddress1" name="streetAddress1" maxlength="30" onChange={(e) => {
                                        requestBody.current.addrLine2 = e.target.value
                                    }}
                                    ></props.TextControl>
                                    <props.TextControl id="streetAddress2" name="streetAddress2" maxlength="30"></props.TextControl>
                                    <props.TextControl id="city" name="city" required={true} label="common.lbl.city"></props.TextControl>
                                    <props.SelectControl id="stateContry" name="stateContry" required={true} defaultOption={''} label="common.lbl.stateCountry" options={defaultState2.current}
                                        onChange={(e: any) => {
                                            enableZipCodeRequired(e.target.value)
                                            formProps.setErrors({})
                                            formProps.setFieldValue('zipCode', '')
                                            validateProps.clearMessage()
                                            setIsUpdateMode(true)
                                        }} />
                                    <props.TextControl id="zipCode" name="zipCode" required={zipcodeRequirued !== null && zipcodeRequirued === true} label="pi.zip.code"></props.TextControl>
                                </props.Col>
                                <props.Col sm="6" md="6" xs="12">
                                    <props.TextControl id="cellNumber" name="cellNum" required={false} label="pi.phone.cell"></props.TextControl>
                                    <props.TextControl id="honeNumber" name="homeNum" required={false} label="pi.phone.home"></props.TextControl>
                                    <props.TextControl id="busiNumber" name="busiNum" required={false} label="pi.phone.business"></props.TextControl>
                                    <props.TextControl id="facNumber" name="facNum" required={false} label="pi.phone.facsimile"></props.TextControl>
                                    
                                    <ContactMethodsDropdown {...props} />
                                </props.Col>
                            </props.Row>
                            <props.HR />
                            <props.Row id="emailLabel">
                                <props.Col sm="6" md="6" xs="12">
                                    <props.Label><GlobalizedText message="pi.email.address" /> 1</props.Label>
                                    <props.Link id="addEmail" to="#" onClick={() => { setShowEmail2(true) }}>
                                        <props.Icon type="add" onClick={() => { setShowEmail2(true) }} />
                                        <GlobalizedText message="common.lbl.add" />
                                    </props.Link>
                                    {/* <props.Icon type="add" label="common.lbl.add" onClick={() => { setShowEmail2(true) }}></props.Icon> */}
                                    <props.TextControl id="email1" name="email1" required={false}></props.TextControl>
                                </props.Col>
                                { }
                                <props.Col sm="6" md="6" xs="12" condition={() => {
                                    if (props.changeType === 5 && (!isEmptyStr(formProps.values.email2) || showEmail2 === true)) {
                                        return true
                                    } else {
                                        if (props.personalInfo.email2 !== undefined || showEmail2 === true) {
                                            return true
                                        }
                                    }
                                }} className="emailControl">
                                    <props.Label><GlobalizedText message="pi.email.address" /> 2</props.Label>
                                    <props.Link id="deleteEmail" to="#" onClick={() => {
                                        setShowEmail2(false)
                                        personalContactInfoForm.current.setFieldValue('email2', '')
                                    }}>
                                        <props.Icon type="delete" />
                                        <GlobalizedText message="common.lbl.delete" />
                                    </props.Link>
                                    <props.TextControl id="email2" name="email2" required={false}></props.TextControl>
                                </props.Col>
                            </props.Row>
                            <br />
                            <props.Row>
                                {props.changeType === 1 &&
                                    <props.Col sm="12" md="12" xs="12">
                                        <props.Div id="auDiv">
                                            <props.Div className="inforce-detail-group">
                                                <props.Label><GlobalizedText message="changeow.lbl.changeAuth" /></props.Label>
                                                <props.Div id="apa" style={{ margin: "2px 0 0" }}>
                                                    <props.CheckboxControl id="changeAuth" name="changeAuth" onChange={(e: any) => { disbaledBtn.current = !disbaledBtn.current }} checked={disbaledBtn.current ? false : true} />
                                                    <props.Span id="authMsg"><GlobalizedText message="changeow.msg.info.changeAuth" /></props.Span>
                                                </props.Div>
                                            </props.Div>
                                        </props.Div>
                                    </props.Col>
                                }
                                {props.changeType === 2 &&
                                    <props.Col sm="12" md="12" xs="12">
                                        <props.Div id="auDiv">
                                            <props.Div className="inforce-detail-group">
                                                <props.Label><GlobalizedText message="addbene.lbl.changeAuth" /></props.Label>
                                                <props.Div id="apa" style={{ margin: "2px 0 0" }}>
                                                    <props.CheckboxControl id="changeAuth" name="changeAuth" onChange={(e: any) => { disbaledBtn.current = !disbaledBtn.current }} checked={disbaledBtn.current ? false : true} />
                                                    <props.Span id="authMsg"><GlobalizedText message="addbene.msg.info.changeAuth" /></props.Span>
                                                </props.Div>
                                            </props.Div>
                                        </props.Div>
                                    </props.Col>
                                }
                                {(props.changeType === 3 || props.changeType === 4) &&
                                    <props.Col sm="12" md="12" xs="12">
                                        <props.Div id="auDiv">
                                            <props.Div className="inforce-detail-group">
                                                <props.Label><GlobalizedText message="addsecaddr.lbl.changeAuth" /></props.Label>
                                                <props.Div id="apa" style={{ margin: "2px 0 0" }}>
                                                    <props.CheckboxControl id="changeAuth" name="changeAuth" onChange={(e: any) => { disbaledBtn.current = !disbaledBtn.current }} checked={disbaledBtn.current ? false : true} />
                                                    <props.Span id="authMsg"><GlobalizedText message="addsecaddr.msg.info.changeAuth" /></props.Span>
                                                </props.Div>
                                            </props.Div>
                                        </props.Div>
                                    </props.Col>
                                }

                                <props.Col sm="12" md="12" xs="12">
                                    {props.changeType === 5 && <>
                                        <props.Button id="updatePersonalInfo" type="submit" className="form-pi btn btn-primary gwp-btn"><GlobalizedText message="common.lbl.save" /></props.Button>
                                        <props.Button id="cancelUpdatePersonalInfo" onClick={() => { validateProps.clearMessage(); setIsUpdateMode(false) }}><GlobalizedText message="common.lbl.close" /></props.Button>
                                    </>}
                                    {props.changeType !== 5 && <>
                                        <props.Button disabled={disbaledBtn.current} id="changeReqSubmit" type="submit" className="form-pi btn btn-primary gwp-btn"><GlobalizedText message="common.lbl.save" /></props.Button>
                                        <props.Button id="changeReqCancel" onClick={props.back}><GlobalizedText message="common.lbl.close" /></props.Button>
                                    </>}
                                </props.Col>
                            </props.Row >
                        </Form>
                    }}
                </Formik>
            </>
        }
        {/* Change Owner Modal */}
        <props.Modal id="changeOWSuccess" title="changeow.lbl.title" footer={returnedFooter}>
            <GlobalizedText message="changeow.msg.info.changeReqSuc" />
        </props.Modal>
        {/* Add Beneficiary Modal */}
        <props.Modal id="AddBeneSuccess" title="addbene.lbl.title" footer={returnedFooter}>
            <GlobalizedText message="addbene.msg.info.changeReqSuc" />
        </props.Modal>

    </div >
};