import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { ProgressBarPagesConfig } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, getFromStorage, isEmptyObject, isEmptyStr, trimFields, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import './css/registernewusers.css';
import './css/registration.css';

interface RegistrationUser {
    userTypeRegCode: string,
    nameType: string,
    firstName: string,
    lastName: string,
    //orgName: string,
    birthDate: string,
    govtID: string,
    polNumberOrMemberID: string,
    orgName: string,
    nationProvideID: string,
    providerInternalKey: string,
    email: string,
    phoneNumber: string,
    jobTitle: string,
    companyCode: string,
    agencyName: string,
    agencyNumber: string,
    agentNumber: string,
    userID: string,
    password: string,
    secImg: {
        category: {
            msgKey: string,
            name: string
        },
        msgKey: string,
        name: string
    },
    secCaption: string,    
    securityQuestion1: string,
    securityQuestion2: string,
    securityQuestion3: string,
    answer1: string,
    answer2: string,
    answer3: string,
    dateFormat: string,
    languageCode: string,
    step: number,
}

interface Step {
    first: () => void;
    second: () => void;
    third: () => void;
    forth: () => void;
    step: () => number;
    setStep: (step: number) => void;
}
interface PageProps extends ViewComponentProps {
    step: Step;
    registrationUser: RegistrationUser;
    setRegistrationUser: any;
    regConfig: any;
}

const cfgProcessBar3: ProgressBarPagesConfig = {
    pages: [
        { name: 'common.lbl.personalInformation', status: "unset" },
        { name: 'regist.label.login.and.security.information', status: "unset" },
        { name: 'regist.label.preference.information', status: "current" }
    ]
};
const cfgProcessBar1: ProgressBarPagesConfig = {
    pages: [
        { name: 'common.lbl.personalInformation', status: "current" },
        { name: 'regist.label.login.and.security.information', status: "unset" },
        { name: 'regist.label.preference.information', status: "unset" }
    ]
};
const cfgProcessBar2: ProgressBarPagesConfig = {
    pages: [
        { name: 'common.lbl.personalInformation', status: "unset" },
        { name: 'regist.label.login.and.security.information', status: "current" },
        { name: 'regist.label.preference.information', status: "unset" }
    ]
};

let initialUser: RegistrationUser = {
    "userTypeRegCode": "",
    "nameType": "",
    "firstName": "",
    "lastName": "",
    "orgName": "",
    "birthDate": "",
    "govtID": "",
    "polNumberOrMemberID": "",
    //"orgName": "",
    "nationProvideID": "",
    "providerInternalKey": "",
    "email": "",
    "phoneNumber": "",
    "jobTitle": "",
    "companyCode": "",
    "agencyName": "",
    "agencyNumber": "",
    "agentNumber": "",
    "userID": "",
    "password": "",
    "secImg": {
        "category": {
            "msgKey": "",
            "name": ""
        },
        "msgKey": "",
        "name": ""
    },
    "secCaption": "",    
    "securityQuestion1": "",
    "securityQuestion2": "",
    "securityQuestion3": "",
    "answer1": "",
    "answer2": "",
    "answer3": "",
    "dateFormat": "",
    "languageCode": "",
    "step": 1
}

export const RegistrationAdminComponent = withView((props: ViewComponentProps) => {
    const step: Step = useStep(props);
    const [registrationUser, setRegistrationUser] = useState<RegistrationUser>(initialUser);
    const [config, setConfig] = useState<any>();
    useEffect(() => {
        let user: RegistrationUser = props.getInitParams();
        if (user !== undefined && !isEmptyObject(user)) {
            setRegistrationUser(user);
            step.setStep(user.step);
        }
        if (config === undefined || props.isForceRefresh()) {
            ajax({
                url: '/api/registrationAdmin/view',
                success: (viewConfig) => {
                    setConfig(viewConfig);
                    if (props.isForceRefresh()) {
                        // refresh the component to initial status
                        props.setInitParams(undefined);
                        setRegistrationUser(initialUser);
                        step.first();
                        props.refreshed();
                    }

                }
            });
        }
    }, [config, props.isForceRefresh()]);

    if (config === undefined || step.step() !== registrationUser.step) {
        return <></>
    }
    return <>
        {config.userTypeRegCodes.length !== 0 && <>
            <props.Row>
                <props.Col sm='12'>
                    {step.step() === 1 && (config.userTypeRegCodes ? (config.userTypeRegCodes[0].value ? true : (config.userTypeRegCodes.value ? true : false)) : false) &&
                        <props.ProgressBar id="registrationProgressBar1" config={cfgProcessBar1}></props.ProgressBar>
                    }
                    {step.step() === 2 &&
                        <props.ProgressBar id="registrationProgressBar2" config={cfgProcessBar2}></props.ProgressBar>
                    }
                    {step.step() === 3 &&
                        <props.ProgressBar id="registrationProgressBar3" config={cfgProcessBar3}></props.ProgressBar>
                    }
                    {step.step() === 4 &&
                        <props.Panel>
                            <props.PanelBody>
                                <div className="h4">
                                    <span className="glyphicon glyphicon-ok" style={{ margin: '0 10px 0 0' }}></span>
                                    <GlobalizedText message="regist.label.register.complete" />
                                </div>
                            </props.PanelBody>
                        </props.Panel>
                    }
                </props.Col>
            </props.Row>
            <props.Row>
                <props.Col>
                    <props.Container>
                        {step.step() === 1 &&

                            <FirstPage {...props} regConfig={config} step={step} registrationUser={registrationUser} setRegistrationUser={setRegistrationUser} />
                        }
                        {step.step() === 2 &&
                            <SecondPage {...props} regConfig={config} step={step} registrationUser={registrationUser} setRegistrationUser={setRegistrationUser} />
                        }
                        {step.step() === 3 &&
                            <ThirdPage {...props} regConfig={config} step={step} registrationUser={registrationUser} setRegistrationUser={setRegistrationUser} />
                        }
                    </props.Container>
                </props.Col>
            </props.Row>
        </>
        }
    </>
});

const FirstPage = (props: PageProps) => {
    let byAdminUser = yup.object().shape({
        firstName: YupSchema.registration.firstName,
        lastName: YupSchema.registration.lastName,
        email: YupSchema.email,
        phoneNumber: YupSchema.phoneNumber
    });
    let byAdminUserNoPhone = yup.object().shape({
        firstName: YupSchema.registration.firstName,
        lastName: YupSchema.registration.lastName,
        email: YupSchema.email
    });

    let defaultOptions = {
        userTypeRegCode: props.regConfig.userTypeRegCodes[0].value,
    }

    let initialValues = {
        ...{
            ...props.registrationUser,
            userTypeRegCode: props.registrationUser.userTypeRegCode !== '' ? props.registrationUser.userTypeRegCode : props.regConfig.userTypeRegCodes[0].value,
        }
    }

    const convertErrorMessages = (errorMessages: Array<any>): any => {
        let result: any = {};
        errorMessages.forEach((e: any) => {
            result[e.fieldName] = e.message;
        });
        return result;
    }
    return (<React.Fragment>
        <Formik initialValues={initialValues}
            {...{ ...props.generateNoramlFormikProps(), ...{ onReset: props.clearMessage } }}
            validate={values => {
                if (isEmptyStr(values.phoneNumber)) {
                    return validate(byAdminUserNoPhone, values, props);
                } else {
                    return validate(byAdminUser, values, props);
                }
            }}
            onSubmit={(values: any, formikHelpers: any) => {
                ajax({
                    url: '/api/registrationAdmin/pageOneValidate',
                    data: { ...trimFields(values) },
                    method: 'post',
                    success: (res: any) => {
                        let user = { ...props.registrationUser, ...values, ...{ step: 2 } };
                        updateUser(props, user);
                        props.step.second();
                    },
                    fail: (res: any) => {
                        props.showFieldError("homepage.errorFields", convertErrorMessages(res.errorMessages), formikHelpers);
                    }
                });
            }}>
            {formProps => <Form>
                <Title {...props} title="common.lbl.personalInformation"></Title>
                <props.Row>
                    <props.Col md="6" sm="6" xs="12">
                        <props.SelectControl name="userTypeRegCode" onChange={(e: any) => { props.resetForm(formProps, { ...initialUser, ...defaultOptions, ...{ userTypeRegCode: e.target.value, } }); props.setInitParams(undefined); }} label="regist.label.chooserole" required={true} options={props.regConfig.userTypeRegCodes} />
                        <props.TextControl id="firstName" name="firstName" required={true} label="common.lbl.firstName" />
                        <props.TextControl id="lastName" name="lastName" required={true} label="common.lbl.lastName" />
                    </props.Col>
                    <props.Col md="6" sm="6" xs="12">
                        <props.TextControl id="email" name="email" label="regist.label.email" />
                        <props.TextControl id="phoneNumber" name="phoneNumber" label="regist.label.phone" />
                    </props.Col>
                </props.Row>
                <props.Row>
                    <props.Col sm='12'>
                        <props.Button type="submit" ><GlobalizedText message="common.button.next" /> </props.Button>
                        <props.Button className="cancelBtn" onClick={() => { props.back() }}><GlobalizedText message="common.lbl.cancel" /> </props.Button>
                    </props.Col>
                </props.Row>
            </Form>
            }
        </Formik>
    </React.Fragment >
    );
}

export const updateUser = (props: PageProps, user: RegistrationUser) => {
    props.setRegistrationUser(user);
    props.setInitParams(user);
}

export const SecondPage = (props: PageProps) => {
    const captcha = props.useCaptcha("securityCode");
    const config = getFromStorage('gwp.config', true);
    let previous = (e: any) => {
        updateUser(props, { ...props.registrationUser, ...{ step: 1 } });
        props.step.first();
    };
    let byPageTwoOption = {
        userID: YupSchema.registration.userID,
        password: YupSchema.registration.password(props.regConfig.validationRule, props.regConfig.pwdMinLength, props.regConfig.pwdMaxLength, "regist.msg.err.password.format.irregular", props.getGlobalizedText),
        cfmPassword: YupSchema.registration.confirmPassword("password"),
        secImage: YupSchema.registration.securityImage,
        secCaption: YupSchema.registration.securityImageCaption,        
        securityCode: captcha.fieldSchema
    };
    
    let byPageTwoOptionForOpenIdConnect = {
        userID: YupSchema.registration.userID,
        password: YupSchema.registration.password(props.regConfig.validationRule, props.regConfig.pwdMinLength, props.regConfig.pwdMaxLength, "regist.msg.err.aad.password.format.irregular", props.getGlobalizedText),
        cfmPassword: YupSchema.registration.confirmPassword("password"),       
        securityCode: captcha.fieldSchema
    };


    let byPageTwo = yup.object().shape(config.openIdConnect === false ? byPageTwoOption : byPageTwoOptionForOpenIdConnect);

    let initialValues = {
        ...props.registrationUser,
        category: props.registrationUser.secImg.category.name !== '' ? props.registrationUser.secImg.category.name : props.regConfig.categorys[0].name,
        secImage: props.registrationUser.secImg.name !== '' ? props.registrationUser.secImg.name : '',
        cfmPassword: '',
        securityCode: ''
    }
    return (<React.Fragment>
        <Formik initialValues={initialValues}
            {...{ ...props.generateNoramlFormikProps(), ...{ onReset: props.clearMessage } }}
            validate={values => {
                return validate(byPageTwo, values, props);
            }}
            onSubmit={(values: any, formikHelpers: any) => {
                let data = {
                    ...values,
                    ...{ secImg: { category: { name: values.category }, name: values.secImage } },
                }

                ajax({
                    url: '/api/registrationAdmin/pageTwoValidate',
                    data: trimFields(data),
                    method: 'post',
                    success: () => {
                        let user = { ...props.registrationUser, ...data, ...{ step: 3 } };
                        updateUser(props, user);
                        props.step.third();
                    },
                    fail: (res: any) => {
						if(res.errorMessages != null && res.errorMessages != undefined){
                            let result: any = {};
                            for (var prop in res.errorMessages) {    
                                var key =  res.errorMessages[prop]['fieldName'];
                                var value = res.errorMessages[prop]['message'];
                                result[key] = value;
                            };
                            props.showFieldError("homepage.errorFields", result, formikHelpers);
						}else{
                            props.showMessage("error", res.data.message);
                        }
                    },
                    error: (error: any) => {
                        props.showMessage("error", error.response.data.message);
                    }
                });
            }}>
            {formProps => <Form>
                <Title {...props} title="regist.label.login.and.security.information"></Title>
                <props.Row>
                    <props.Col md="6" sm="6" xs="12">
                        <props.TextControl id="userID" name="userID" required={true} label="groupAdmin.userID" />
                        <props.PasswordControl id="password" name="password" label="login.lbl.password" required={true} />
                        <props.PasswordControl id="cfmPassword" name="cfmPassword" label="regist.label.confirm.password" required={true} />
                        {config.openIdConnect === false && <props.SecurityImagesControl imageName="secImage" categoryName="category" securityPhaseName="secCaption" categories={props.regConfig.categorys} images={props.regConfig.securityImages} {...props}></props.SecurityImagesControl>}                    
                    </props.Col>
                    <props.Col md="6" sm="6" xs="12">
                        <props.TextControl id="securityCode" name="securityCode" required={true} label="regist.label.enter.captcha" />
                        <captcha.Component></captcha.Component>
                    </props.Col>
                </props.Row>
                <props.Row>
                    <props.Col sm='12'>
                        <props.Button className="previousBtn" onClick={previous}><GlobalizedText message="common.button.previous" /> </props.Button>
                        <props.Button type="submit" ><GlobalizedText message="common.button.next" /> </props.Button>
                        <props.Button className="cancelBtn" onClick={() => { props.back() }}><GlobalizedText message="common.lbl.cancel" /> </props.Button>
                    </props.Col>
                </props.Row>
            </Form>
            }
        </Formik>
    </React.Fragment >
    );
}
const ThirdPage = (props: PageProps) => {
    let previous = (e: any) => {
        updateUser(props, { ...props.registrationUser, ...{ step: 2, password: '' } });
        props.step.second();
    };
    let byPageThree = yup.object().shape({
        languageCode: YupSchema.registration.languageCode,
        dateFormat: YupSchema.registration.dateFormat
    });
    useEffect(() => {
        //document.querySelector("[name=password]").focus();
    });
    return (
        <>
            <Formik initialValues={{
                "dateFormat": props.registrationUser.dateFormat !== '' ? props.registrationUser.dateFormat : props.regConfig.prefDateFormats[0].value,
                "languageCode": props.registrationUser.languageCode !== '' ? props.registrationUser.languageCode : props.regConfig.languageCode[0].value
            }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={(values) => {
                    return validate(byPageThree, values, props);
                }}
                onSubmit={(values) => {
                    ajax({
                        url: '/api/registrationAdmin/register',
                        data: { ...trimFields(props.registrationUser), "dateFormat": values.dateFormat, "languageCode": values.languageCode },
                        method: 'post',
                        success: (res: any) => {
                            updateUser(props, { ...props.registrationUser, ...{ step: 4 } });
                            props.step.forth();
                        },
                        fail: (res: any) => {
                            props.showMessage("error", res.data.message);
                        },
                        error: (error: any) => {
                            props.showMessage("error", error.response.data.message);
                        }
                    });
                }}>
                {formProps => <Form>
                    <Title {...props} title="regist.label.preference.information"></Title>
                    <props.Row>
                        <props.Col md="6" sm="6" xs="12">
                            <props.SelectControl name="languageCode" label="regist.label.pref.language" required={true} options={props.regConfig.languageCode} />
                        </props.Col>
                        <props.Col md="6" sm="6" xs="12">
                            <props.SelectControl name="dateFormat" label="regist.label.pref.date.format" required={true} options={props.regConfig.prefDateFormats} />
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col sm='12'>
                            <props.Button className="previousBtn" onClick={previous}><GlobalizedText message="common.button.previous" /> </props.Button>
                            <props.Button type="submit"><GlobalizedText message="common.button.submit" /> </props.Button>
                            <props.Button className="cancelBtn" onClick={() => { props.back() }}><GlobalizedText message="common.lbl.cancel" /> </props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
                }
            </Formik>
        </>
    );
}

const Or = (props: ViewComponentProps) => {
    return <props.Div style={{ marginTop: '10px' }}><GlobalizedText message="common.lbl.or" /></props.Div>
}

interface TitleProps extends ViewComponentProps {
    title: string;
}

const Title = (props: TitleProps) => {
    return <><props.Row>
        <props.Col sm='12'>
            <props.Span><props.Span className="reginnertitle"><GlobalizedText message={props.title} /></props.Span>(<props.Span className="gwp-required">* </props.Span><GlobalizedText message="common.lbl.requiredField" />)</props.Span>
        </props.Col>
    </props.Row>
    </>;
}
const useStep = (props: any) => {
    const [step, setStep] = useState<number>(1);
    return {
        forth: function () {
            this.setStep(4);
            props.clearMessage();
        },
        third: function () {
            this.setStep(3);
            props.clearMessage();
        },
        second: function () {
            this.setStep(2);
            props.clearMessage();
        },
        first: function () {
            this.setStep(1);
            props.clearMessage();
        },
        step: function () {
            return step;
        },
        setStep: function (s: number) {
            if (s !== undefined) {
                setStep(s);
                window.scrollTo({ left: 0, top: 0 });
            }
        }
    }
}

