import React from 'react';
import { LinkProps as RouteLinkProps, useHistory } from 'react-router-dom';
import { Config } from '../../../config/config';
import { getContextPath, isEmptyStr, movePageToTop } from '../../utils';
import { ConditionProps, withCondition } from './shared-component';

export interface LinkProps extends RouteLinkProps, React.RefAttributes<HTMLAnchorElement>, ConditionProps {
    /**
     * Indicate that the link is a router link or not. Default is true
     */
    isRouterLink?: boolean
    forceRefresh?: boolean
}

export const Link = withCondition(({ to, condition, isRouterLink, forceRefresh, onClick, ...props }: LinkProps) => {
    let toStr = to !== undefined ? to.toString().toLowerCase() : "";
    if ((isRouterLink !== undefined && isRouterLink === false) || toStr.startsWith("tel:") || toStr.startsWith("mailto:")) {
        return <a href={`${Config.SERVICE}${to.toString()}`} {...props}>{props.children}</a>
    }
    let realTo: any = {};
    if (typeof (to) === "string") {
        if (to === "#") {
            realTo.pathname = to;
        } else if (isEmptyStr(to)) {
            realTo.pathname = to;
        } else {
            let pn = "";
            if (!to.startsWith(getContextPath())) {
                pn += getContextPath();
            }
            if (to.includes("?")) {
                realTo.pathname = pn + to.substring(0, to.indexOf("?"));
                realTo.search = to.substring(to.indexOf("?"));
            } else {
                realTo.pathname = pn + to;
            }
        }
    } else if (typeof (to) === "undefined") {
        realTo.pathname = "";
    } else {
        realTo = to;
    }
    if (forceRefresh === true) {
        realTo.state = { forceRefresh: true };
    }

    if (props.target !== undefined) {
        realTo.target = props.target
    }

    let history = useHistory();

    return <a data-href={realTo.pathname + (realTo.search ? realTo.search : '')} onClick={(e: any) => {
        if (onClick !== undefined) {
            onClick(e);
        }
        if (realTo.pathname === "#" || isEmptyStr(realTo.pathname)) {
            return;
        }
        history.push(realTo);
        if (realTo.pathname !== window.location.pathname) {
            movePageToTop();
        }
    }} {...props}>{props.children}</a>
})