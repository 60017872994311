import React, { ReactNode } from 'react';

export interface FormGroupProps {
    containerClassName?: string;
    children?: ReactNode;
}

export const FormGroup = (props: FormGroupProps) => {
    return <div className={`form-group ${props.containerClassName === undefined ? "" : props.containerClassName}`}>
        {props.children}
    </div>
}