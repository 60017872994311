import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { INIT_PARAM_PREFIX_TABLE, TableCell, TableColumn, useTable } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, validate, saveToStorage, copyObjectExcept } from '../shared/utils';
import './css/claiminquiry.css';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
interface Option {
    name: string
    value: string
}
interface ClaimInquiryViewResponse {
    filterBy: Array<Option>
    groups: Array<Option>
    accounts: Array<Option>
    statusList: Array<Option>
    byName: string
    byMember: string
    byId: string
    byStatus: string
    eobEnabled: boolean
}

interface ClaimInquiryForm {
    filterBy: string
    group: string
    accountNumber: string
    firstName: string
    lastName: string
    claimNumber: string
    participantIDNumber: string
    certiNum: string
    idNum: string
    status: string
    groupNumber: string
    companyCode: string
}

const retrieve = (values: ClaimInquiryForm, config: any, columns: any, setTableData: any, props: any): void => {
    if (values.group !== '0') {
        let companyAndGroup = values.group.split("|");
        values.groupNumber = companyAndGroup[1];
        values.companyCode = companyAndGroup[0];
    } else if (values.group === '0') {
        values.groupNumber = "";
        values.companyCode = "";
    }
    if (values.claimNumber !== '') {
        values.claimNumber = values.claimNumber.replace(/\s/g, "");
    }
    let params = copyObjectExcept(values, true, INIT_PARAM_PREFIX_TABLE);
    ajax({
        url: `/api/claiminquiry/${values.filterBy}`,
        params: params,
        success: (res) => {
            setTableData(res);
        }
    });
}

export const ClaimInquiryComponent = withView((props: ViewComponentProps) => {

    const [config, setConfig] = useState<ClaimInquiryViewResponse>();
    const [accounts, setAccounts] = useState<Array<Option>>([]);
    const [tableData, setTableData] = useState<Array<any>>();
    const pdfViewer = props.usePDFViewer({ ...props, ...{ url: '/api/claiminquiry/downloadEob/aaa' } });
    const eobViewer = props.EOBViewer({ ...props});
    const [eobEnabled, setEobEnabled] = useState<boolean>(true);
    props.clearMessage();
    const [TABLE_COLUMN, setTABLE_COLUMN] = useState<Array<TableColumn>>([
        { sortable: false, className: "gwp-dt-detail-control", width: "2%", title: "`<div class='hidden'>detail control</div>`" },
        {
            title: "common.lbl.claimNumber", name: "claimNumber", width: "8%", render: (data: any, type: any, row: any, meta: any): string => {
                return `<a dt-href='/claimDetail?companyCode=${row.companyCode.trim()}&groupNumber=${row.groupNumber}&accountNumber=${row.accountNumber}&participantIDNumber=${row.memberID}&julianYear=${row.julianYear}&sequenceNumber=${row.sequenceNumber}&suffix=${row.suffix}&claimNumber=${row.claimNumber}&adjustmentNumber=${row.adjustmentNumber}&providerPaymentNumber=${row.providerPaymentNumber}&dependentSequenceNumber=${row.dependentSequenceNumber}&julianDay=${row.julianDay}'>${row.claimNumber}</a>`
            }, onClick: (e: any, row: any): void => {
                let orderTableData = [];
                for (var i = 0; i < claimInquiryResult.getOrder().length; i++) {
                    if (tableData !== undefined) {
                        orderTableData.push(tableData[claimInquiryResult.getOrder()[i]]);
                    }
                }
                saveToStorage("claimresultlist", orderTableData, true);
            }
        },
        { title: "common.lbl.patient", name: "patient", width: "8%" },
        { title: "common.lbl.memberIdentifier", name: "memberID", width: "10%" },
        { title: "common.lbl.identificationNumber", name: "idNum", width: "10%" },
        { title: "benefit.certificate.number", name: "certiNum", width: "10%" },
        { title: "common.lbl.doctorfacility", name: "doctorFacility", width: "10%" },
        { title: "common.lbl.servicedate", name: "serviceDate", width: "10%", type: 'date' },
        { title: "common.lbl.submitted", name: "total", width: "8%", type: 'numeric' },
        { title: "common.lbl.paid", name: "paid", width: "8%", type: 'numeric' },
        { title: "common.lbl.status", name: "status", width: "8%" },
        {
            title: "common.lbl.eob", name: "eob", width: "8%", render: (data: any, type: any, row: any, meta: any): string => {
                var eobFlag = row.eobFlag;
                if (eobFlag) {
                    return "<span class='gwp-icon gwp-icon-view-statement gwp-icon-sm' style='cursor:pointer'></span>";
                } else {
                    return "";
                }
            }, onClick: (e: any, row: any): void => {
                eobViewer.showPDF(row);
            }
        },
    ]);
    const disableEob = (): void => {
        setTABLE_COLUMN(
            [
                { sortable: false, className: "gwp-dt-detail-control", width: "2%", title: "`<div class='hidden'>detail control</div>`" },
                {
                    title: "common.lbl.claimNumber", name: "claimNumber", width: "8%", render: (data: any, type: any, row: any, meta: any): string => {
                        return `<a dt-href='/claimDetail?companyCode=${row.companyCode.trim()}&groupNumber=${row.groupNumber}&accountNumber=${row.accountNumber}&participantIDNumber=${row.memberID}&julianYear=${row.julianYear}&sequenceNumber=${row.sequenceNumber}&suffix=${row.suffix}&claimNumber=${row.claimNumber}&adjustmentNumber=${row.adjustmentNumber}&providerPaymentNumber=${row.providerPaymentNumber}&dependentSequenceNumber=${row.dependentSequenceNumber}&julianDay=${row.julianDay}'>${row.claimNumber}</a>`
                    }, onClick: (e: any, row: any): void => {
                        let orderTableData = [];
                        for (var i = 0; i < claimInquiryResult.getOrder().length; i++) {
                            if (tableData !== undefined) {
                                orderTableData.push(tableData[claimInquiryResult.getOrder()[i]]);
                            }
                        }
                        saveToStorage("claimresultlist", orderTableData, true);
                    }
                },
                { title: "common.lbl.patient", name: "patient", width: "8%" },
                { title: "common.lbl.memberIdentifier", name: "memberID", width: "10%" },
                { title: "common.lbl.identificationNumber", name: "idNum", width: "10%" },
                { title: "benefit.certificate.number", name: "certiNum", width: "10%" },
                { title: "common.lbl.doctorfacility", name: "doctorFacility", width: "10%" },
                { title: "common.lbl.servicedate", name: "serviceDate", width: "10%", type: 'date' },
                { title: "common.lbl.submitted", name: "total", width: "8%", type: 'numeric' },
                { title: "common.lbl.paid", name: "paid", width: "8%", type: 'numeric' },
                { title: "common.lbl.status", name: "status", width: "8%" }
            ]
        )

    }
    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/claiminquiry/view',
                success: (res: ClaimInquiryViewResponse) => {
                    setConfig(res);
                    if (res.eobEnabled === false) {
                        disableEob()
                    }
                    if (res.groups.length === 1) {
                        // if only one group selected, then try to load the accounts.
                        handleGroupChange(res.groups[0].value);
                    } else {
                        setAccounts(res.accounts);
                    }
                    if (props.getInitParams() !== undefined) {
                        let values: ClaimInquiryForm = props.getInitParams();
                        handleGroupChange(values.group);
                        retrieve(values, config, TABLE_COLUMN, setTableData, props);
                    }
                }
            });
        }
    })

    const handleGroupChange = (e: any, setFieldValue?: (fieldName: string, value: any) => void) => {
        let companyAndGroupNumber = e.target !== undefined ? e.target.value : e;
        if (companyAndGroupNumber === '0') {
            if (config !== undefined) {
                setAccounts(config.accounts);
                if (setFieldValue !== undefined) {
                    setFieldValue("accountNumber", "0");
                }
                return;
            }
        } else {
            let tmp = companyAndGroupNumber.split("|");
            ajax({
                url: `/api/claiminquiry/accounts/${tmp[0]}/${tmp[1]}`,
                success: (res) => {
                    setAccounts(res);
                    if (setFieldValue !== undefined) {
                        setFieldValue("accountNumber", res[0].value === undefined ? 0 : res[0].value);
                    }
                }
            });
        }
    }

    const claimInquiryResult = useMemo(() => useTable({
        id: 'claimInquiryResult',
        table: {
            columns: TABLE_COLUMN,
            data: tableData
        },
        onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element) => {
            childNode?.querySelector(".gwp-icon.gwp-icon-view-statement")?.addEventListener("click", (e: any) => {
                pdfViewer.showPDF();
            });
        },
        dateformat: props.getDateFormat(),
        setInitParams: props.setInitParams,
        getInitParams: props.getInitParams
    }), [tableData, TABLE_COLUMN]);

    if (config === undefined || config.filterBy.length === 0) {
        return <></>
    }

    const byNameSchema = yup.object().shape({
        lastName: yup.string().trim().required("com.lastname.req")
    });
    const byMemberValidate = (values: ClaimInquiryForm) => {
        if (values.participantIDNumber.trim() === '' && values.idNum.trim() === '' && values.certiNum.trim() === '') {
            return {
                participantIDNumber: 'homepage.all.lbl.required',
                idNum: 'homepage.all.lbl.required',
                certiNum: 'homepage.all.lbl.required'
            }
        }
        if (values.participantIDNumber.trim() !== '' || values.idNum.trim() !== '' || values.certiNum.trim() !== '') {
            var enterValues = new Array();
            var count = 0;
            var invalidValues = new Array();
            var ivCount = 0;
            if ("" != values.participantIDNumber.trim()) {
                count = enterValues.push("participantIDNumber");
                if (Number(values.participantIDNumber.trim()) == 0) {
                    ivCount = invalidValues.push("participantIDNumber");
                }
            }
            if ("" != values.idNum.trim()) {
                count = enterValues.push("idNum");
                if (Number(values.idNum.trim()) == 0) {
                    ivCount = invalidValues.push("idNum");
                }
            }
            if ("" != values.certiNum.trim()) {
                count = enterValues.push("certiNum");
                if (Number(values.certiNum.trim()) == 0) {
                    ivCount = invalidValues.push("certiNum");
                }
            }
            if (count > 1) {
                if (enterValues.includes("participantIDNumber") && enterValues.includes("idNum") && enterValues.includes("certiNum")) {
                    return {
                        participantIDNumber: 'common.msg.enterIdentifier',
                        idNum: 'common.msg.enterIdentifier',
                        certiNum: 'common.msg.enterIdentifier'
                    }
                }
                if (enterValues.includes("participantIDNumber") && enterValues.includes("idNum")) {
                    return {
                        participantIDNumber: 'common.msg.enterIdentifier',
                        idNum: 'common.msg.enterIdentifier'
                    }
                }
                if (enterValues.includes("participantIDNumber") && enterValues.includes("certiNum")) {
                    return {
                        participantIDNumber: 'common.msg.enterIdentifier',
                        certiNum: 'common.msg.enterIdentifier'
                    }
                }
                if (enterValues.includes("idNum") && enterValues.includes("certiNum")) {
                    return {
                        idNum: 'common.msg.enterIdentifier',
                        certiNum: 'common.msg.enterIdentifier'
                    }
                }
            } else if (ivCount > 0) {
                for (var j = 0; j < invalidValues.length; j++) {
                    if ("participantIDNumber" == enterValues[j]) {
                        return {
                            participantIDNumber: 'common.msg.entryNotValid'
                        }
                    }
                    if ("idNum" == enterValues[j]) {
                        return {
                            idNum: 'common.msg.entryNotValid'
                        }
                    }
                    if ("certiNum" == enterValues[j]) {
                        return {
                            certiNum: 'common.msg.entryNotValid'
                        }
                    }
                }
            }
        }
    }
    const byIdSchema = yup.object().shape({
        claimNumber: yup.string().trim().required("com.claimnum.req")
    });

    const defaultInitialValues = {
        filterBy: config.filterBy[0].value,
        group: config.groups[0].value,
        accountNumber: config.accounts[0].value,
        firstName: '',
        lastName: '',
        claimNumber: '',
        participantIDNumber: '',
        certiNum: '',
        idNum: '',
        status: config.statusList[0].value,
        groupNumber: '',
        companyCode: ''
    }

    let initialValue: ClaimInquiryForm = props.getInitParams() !== undefined ? props.getInitParams() : defaultInitialValues;

    // define Form Submitting function.
    const formSubmit = (values: ClaimInquiryForm): void => {
        props.setInitParams(values);
        retrieve(values, config, TABLE_COLUMN, setTableData, props);
    }

    return <>
        <Formik initialValues={initialValue}
            validate={(values) => {
                if (values.filterBy === config.byName) {
                    return validate(byNameSchema, values, props);
                }
                if (values.filterBy === config.byId) {
                    return validate(byIdSchema, values, props);
                }
                if (values.filterBy === config.byMember) {
                    return validate(byMemberValidate, values, props);
                }
            }}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps =>
                <Form>
                    <props.Row>
                        <props.Col sm="6">
                            <props.SelectControl label="filter.claim.by" name="filterBy" options={config.filterBy} onChange={(e: any) => { props.resetForm(formProps, { ...defaultInitialValues, ...{ filterBy: e.target.value } }); setTableData([]); props.clearMessage(); handleGroupChange(config.groups[0].value); }}>
                            </props.SelectControl>
                            <props.TextControl condition={formProps.values.filterBy === config.byId} id="claimNumber" name="claimNumber" label="common.lbl.claimNumber" required={true}></props.TextControl>
                            <props.SelectControl sort={false} condition={formProps.values.filterBy !== config.byId} onChange={(e: any) => { handleGroupChange(e, formProps.setFieldValue) }} label="common.lbl.group" name="group" options={config.groups}>
                            </props.SelectControl>
                            <props.SelectControl sort={false} condition={formProps.values.filterBy !== config.byId} label="common.lbl.account" name="accountNumber" options={accounts}>
                            </props.SelectControl>
                            <props.Button condition={formProps.values.filterBy === config.byId} type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                        <props.Col sm="6">
                            <props.Condition condition={formProps.values.filterBy === config.byMember} ><props.Information message="homepage.all.lbl.required" /></props.Condition>
                            <props.TextControl condition={formProps.values.filterBy === config.byMember} id="participantIDNumber" name="participantIDNumber" label="common.lbl.memberIdentifier" maxLength={10}></props.TextControl>
                            <props.TextControl condition={formProps.values.filterBy === config.byMember} id="idNum" name="idNum" label="common.lbl.identificationNumber" maxLength={12}></props.TextControl>
                            <props.TextControl condition={formProps.values.filterBy === config.byMember} id="certiNum" name="certiNum" label="benefit.certificate.number" maxLength={10}></props.TextControl>
                            <props.TextControl condition={formProps.values.filterBy === config.byName} id="lastName" name="lastName" label="common.lbl.lastName" required={true}></props.TextControl>
                            <props.TextControl condition={formProps.values.filterBy === config.byName} id="firstName" name="firstName" label="common.lbl.firstName"></props.TextControl>
                            <props.SelectControl condition={formProps.values.filterBy === config.byStatus} name="status" label="common.lbl.status" options={config.statusList} />
                            <props.Button condition={formProps.values.filterBy !== config.byId} type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        <claimInquiryResult.Component />
        <pdfViewer.Component></pdfViewer.Component>
        <eobViewer.Component></eobViewer.Component>
    </>
});